export default [
  {
    timestamp_start: '2022-01-01T00:00:00',
    timestamp_stop: '2022-01-01T00:00:05',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 132656,
    tank_volume_start: 50000.0,
    tank_volume_stop: 49941.0,
  },
  {
    timestamp_start: '2022-01-01T00:00:20',
    timestamp_stop: '2022-01-01T00:00:25',
    quantity: 45.2,
    vehicle: 'DEM444',
    id: 132658,
    tank_volume_start: 49941.0,
    tank_volume_stop: 49895.8,
  },
  {
    timestamp_start: '2022-01-01T00:00:40',
    timestamp_stop: '2022-01-01T00:00:45',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 132660,
    tank_volume_start: 49895.8,
    tank_volume_stop: 49844.0,
  },
  {
    timestamp_start: '2022-01-01T00:01:00',
    timestamp_stop: '2022-01-01T00:01:06',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 132662,
    tank_volume_start: 49844.0,
    tank_volume_stop: 49785.9,
  },
  {
    timestamp_start: '2022-01-01T00:01:21',
    timestamp_stop: '2022-01-01T00:01:26',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 132664,
    tank_volume_start: 49785.9,
    tank_volume_stop: 49736.6,
  },
  {
    timestamp_start: '2022-01-01T00:01:41',
    timestamp_stop: '2022-01-01T00:01:46',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 132666,
    tank_volume_start: 49736.6,
    tank_volume_stop: 49682.7,
  },
  {
    timestamp_start: '2022-01-01T00:02:01',
    timestamp_stop: '2022-01-01T00:02:07',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 132668,
    tank_volume_start: 49682.7,
    tank_volume_stop: 49622.399999999994,
  },
  {
    timestamp_start: '2022-01-01T00:02:22',
    timestamp_stop: '2022-01-01T00:02:29',
    quantity: 64.2,
    vehicle: 'DEM666',
    id: 132670,
    tank_volume_start: 49622.399999999994,
    tank_volume_stop: 49558.2,
  },
  {
    timestamp_start: '2022-01-01T00:02:44',
    timestamp_stop: '2022-01-01T00:02:49',
    quantity: 48.5,
    vehicle: 'DEM444',
    id: 132672,
    tank_volume_start: 49558.2,
    tank_volume_stop: 49509.7,
  },
  {
    timestamp_start: '2022-01-01T00:03:04',
    timestamp_stop: '2022-01-01T00:03:08',
    quantity: 48.8,
    vehicle: 'DEM000',
    id: 132674,
    tank_volume_start: 49509.7,
    tank_volume_stop: 49460.899999999994,
  },
  {
    timestamp_start: '2022-01-01T00:03:23',
    timestamp_stop: '2022-01-01T00:03:28',
    quantity: 46.8,
    vehicle: 'DEM111',
    id: 132676,
    tank_volume_start: 49460.899999999994,
    tank_volume_stop: 49414.09999999999,
  },
  {
    timestamp_start: '2022-01-01T00:03:43',
    timestamp_stop: '2022-01-01T00:03:49',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 132678,
    tank_volume_start: 49414.09999999999,
    tank_volume_stop: 49359.399999999994,
  },
  {
    timestamp_start: '2022-01-01T00:04:04',
    timestamp_stop: '2022-01-01T00:04:09',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 132680,
    tank_volume_start: 49359.399999999994,
    tank_volume_stop: 49308.7,
  },
  {
    timestamp_start: '2022-01-01T00:04:24',
    timestamp_stop: '2022-01-01T00:04:29',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 132682,
    tank_volume_start: 49308.7,
    tank_volume_stop: 49251.899999999994,
  },
  {
    timestamp_start: '2022-01-01T00:04:44',
    timestamp_stop: '2022-01-01T00:04:50',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 132684,
    tank_volume_start: 49251.899999999994,
    tank_volume_stop: 49198.59999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:05',
    timestamp_stop: '2022-01-01T00:05:10',
    quantity: 50.1,
    vehicle: 'DEM555',
    id: 132686,
    tank_volume_start: 49198.59999999999,
    tank_volume_stop: 49148.49999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:25',
    timestamp_stop: '2022-01-01T00:05:30',
    quantity: 49.9,
    vehicle: 'DEM333',
    id: 132688,
    tank_volume_start: 49148.49999999999,
    tank_volume_stop: 49098.59999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:45',
    timestamp_stop: '2022-01-01T00:05:50',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 132690,
    tank_volume_start: 49098.59999999999,
    tank_volume_stop: 49043.09999999999,
  },
  {
    timestamp_start: '2022-01-01T00:06:05',
    timestamp_stop: '2022-01-01T00:06:10',
    quantity: 45.8,
    vehicle: 'DEM000',
    id: 132692,
    tank_volume_start: 49043.09999999999,
    tank_volume_stop: 48997.29999999999,
  },
  {
    timestamp_start: '2022-01-01T00:06:25',
    timestamp_stop: '2022-01-01T00:06:30',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 132694,
    tank_volume_start: 48997.29999999999,
    tank_volume_stop: 48941.29999999999,
  },
  {
    timestamp_start: '2022-01-01T00:06:45',
    timestamp_stop: '2022-01-01T00:06:50',
    quantity: 45.9,
    vehicle: 'DEM777',
    id: 132696,
    tank_volume_start: 48941.29999999999,
    tank_volume_stop: 48895.39999999999,
  },
  {
    timestamp_start: '2022-01-01T00:07:05',
    timestamp_stop: '2022-01-01T00:07:11',
    quantity: 56.8,
    vehicle: 'DEM000',
    id: 132698,
    tank_volume_start: 48895.39999999999,
    tank_volume_stop: 48838.599999999984,
  },
  {
    timestamp_start: '2022-01-01T00:07:26',
    timestamp_stop: '2022-01-01T00:07:31',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 132700,
    tank_volume_start: 48838.599999999984,
    tank_volume_stop: 48784.099999999984,
  },
  {
    timestamp_start: '2022-01-01T00:07:46',
    timestamp_stop: '2022-01-01T00:07:52',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 132702,
    tank_volume_start: 48784.099999999984,
    tank_volume_stop: 48726.19999999998,
  },
  {
    timestamp_start: '2022-01-01T00:08:07',
    timestamp_stop: '2022-01-01T00:08:13',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 132704,
    tank_volume_start: 48726.19999999998,
    tank_volume_stop: 48668.19999999998,
  },
  {
    timestamp_start: '2022-01-01T00:08:28',
    timestamp_stop: '2022-01-01T00:08:33',
    quantity: 48.5,
    vehicle: 'DEM111',
    id: 132706,
    tank_volume_start: 48668.19999999998,
    tank_volume_stop: 48619.69999999998,
  },
  {
    timestamp_start: '2022-01-01T00:08:48',
    timestamp_stop: '2022-01-01T00:08:53',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 132708,
    tank_volume_start: 48619.69999999998,
    tank_volume_stop: 48562.999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:09:08',
    timestamp_stop: '2022-01-01T00:09:13',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 132710,
    tank_volume_start: 48562.999999999985,
    tank_volume_stop: 48510.29999999999,
  },
  {
    timestamp_start: '2022-01-01T00:09:28',
    timestamp_stop: '2022-01-01T00:09:33',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 132712,
    tank_volume_start: 48510.29999999999,
    tank_volume_stop: 48461.89999999999,
  },
  {
    timestamp_start: '2022-01-01T00:09:48',
    timestamp_stop: '2022-01-01T00:09:53',
    quantity: 50.8,
    vehicle: 'DEM888',
    id: 132714,
    tank_volume_start: 48461.89999999999,
    tank_volume_stop: 48411.099999999984,
  },
  {
    timestamp_start: '2022-01-01T00:10:08',
    timestamp_stop: '2022-01-01T00:10:14',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 132716,
    tank_volume_start: 48411.099999999984,
    tank_volume_stop: 48354.999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:10:29',
    timestamp_stop: '2022-01-01T00:10:35',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 132718,
    tank_volume_start: 48354.999999999985,
    tank_volume_stop: 48299.099999999984,
  },
  {
    timestamp_start: '2022-01-01T00:10:50',
    timestamp_stop: '2022-01-01T00:10:55',
    quantity: 54.4,
    vehicle: 'DEM777',
    id: 132720,
    tank_volume_start: 48299.099999999984,
    tank_volume_stop: 48244.69999999998,
  },
  {
    timestamp_start: '2022-01-01T00:11:10',
    timestamp_stop: '2022-01-01T00:11:16',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 132722,
    tank_volume_start: 48244.69999999998,
    tank_volume_stop: 48188.099999999984,
  },
  {
    timestamp_start: '2022-01-01T00:11:31',
    timestamp_stop: '2022-01-01T00:11:36',
    quantity: 48.3,
    vehicle: 'DEM555',
    id: 132724,
    tank_volume_start: 48188.099999999984,
    tank_volume_stop: 48139.79999999998,
  },
  {
    timestamp_start: '2022-01-01T00:11:51',
    timestamp_stop: '2022-01-01T00:11:56',
    quantity: 57.0,
    vehicle: 'DEM555',
    id: 132726,
    tank_volume_start: 48139.79999999998,
    tank_volume_stop: 48082.79999999998,
  },
  {
    timestamp_start: '2022-01-01T00:12:11',
    timestamp_stop: '2022-01-01T00:12:17',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 132728,
    tank_volume_start: 48082.79999999998,
    tank_volume_stop: 48028.49999999998,
  },
  {
    timestamp_start: '2022-01-01T00:12:32',
    timestamp_stop: '2022-01-01T00:12:37',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 132730,
    tank_volume_start: 48028.49999999998,
    tank_volume_stop: 47974.59999999998,
  },
  {
    timestamp_start: '2022-01-01T00:12:52',
    timestamp_stop: '2022-01-01T00:12:57',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 132732,
    tank_volume_start: 47974.59999999998,
    tank_volume_stop: 47921.59999999998,
  },
  {
    timestamp_start: '2022-01-01T00:13:12',
    timestamp_stop: '2022-01-01T00:13:18',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 132734,
    tank_volume_start: 47921.59999999998,
    tank_volume_stop: 47867.59999999998,
  },
  {
    timestamp_start: '2022-01-01T00:13:33',
    timestamp_stop: '2022-01-01T00:13:38',
    quantity: 48.4,
    vehicle: 'DEM444',
    id: 132736,
    tank_volume_start: 47867.59999999998,
    tank_volume_stop: 47819.199999999975,
  },
  {
    timestamp_start: '2022-01-01T00:13:53',
    timestamp_stop: '2022-01-01T00:13:58',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 132738,
    tank_volume_start: 47819.199999999975,
    tank_volume_stop: 47767.39999999997,
  },
  {
    timestamp_start: '2022-01-01T00:14:13',
    timestamp_stop: '2022-01-01T00:14:18',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 132740,
    tank_volume_start: 47767.39999999997,
    tank_volume_stop: 47714.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:14:33',
    timestamp_stop: '2022-01-01T00:14:38',
    quantity: 50.8,
    vehicle: 'DEM777',
    id: 132742,
    tank_volume_start: 47714.59999999997,
    tank_volume_stop: 47663.79999999997,
  },
  {
    timestamp_start: '2022-01-01T00:14:53',
    timestamp_stop: '2022-01-01T00:14:59',
    quantity: 56.8,
    vehicle: 'DEM333',
    id: 132744,
    tank_volume_start: 47663.79999999997,
    tank_volume_stop: 47606.99999999996,
  },
  {
    timestamp_start: '2022-01-01T00:15:14',
    timestamp_stop: '2022-01-01T00:15:19',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 132746,
    tank_volume_start: 47606.99999999996,
    tank_volume_stop: 47556.49999999996,
  },
  {
    timestamp_start: '2022-01-01T00:15:34',
    timestamp_stop: '2022-01-01T00:15:39',
    quantity: 53.4,
    vehicle: 'DEM666',
    id: 132748,
    tank_volume_start: 47556.49999999996,
    tank_volume_stop: 47503.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:15:54',
    timestamp_stop: '2022-01-01T00:16:00',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 132750,
    tank_volume_start: 47503.09999999996,
    tank_volume_stop: 47448.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:16:15',
    timestamp_stop: '2022-01-01T00:16:20',
    quantity: 56.9,
    vehicle: 'DEM333',
    id: 132752,
    tank_volume_start: 47448.79999999996,
    tank_volume_stop: 47391.89999999996,
  },
  {
    timestamp_start: '2022-01-01T00:16:35',
    timestamp_stop: '2022-01-01T00:16:41',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 132754,
    tank_volume_start: 47391.89999999996,
    tank_volume_stop: 47336.999999999956,
  },
  {
    timestamp_start: '2022-01-01T00:16:56',
    timestamp_stop: '2022-01-01T00:17:02',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 132756,
    tank_volume_start: 47336.999999999956,
    tank_volume_stop: 47278.69999999995,
  },
  {
    timestamp_start: '2022-01-01T00:17:17',
    timestamp_stop: '2022-01-01T00:17:23',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 132758,
    tank_volume_start: 47278.69999999995,
    tank_volume_stop: 47218.29999999995,
  },
  {
    timestamp_start: '2022-01-01T00:17:38',
    timestamp_stop: '2022-01-01T00:17:43',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 132760,
    tank_volume_start: 47218.29999999995,
    tank_volume_stop: 47160.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:17:58',
    timestamp_stop: '2022-01-01T00:18:04',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 132762,
    tank_volume_start: 47160.19999999995,
    tank_volume_stop: 47108.39999999995,
  },
  {
    timestamp_start: '2022-01-01T00:18:19',
    timestamp_stop: '2022-01-01T00:18:24',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 132764,
    tank_volume_start: 47108.39999999995,
    tank_volume_stop: 47050.89999999995,
  },
  {
    timestamp_start: '2022-01-01T00:18:39',
    timestamp_stop: '2022-01-01T00:18:45',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 132766,
    tank_volume_start: 47050.89999999995,
    tank_volume_stop: 46993.39999999995,
  },
  {
    timestamp_start: '2022-01-01T00:19:00',
    timestamp_stop: '2022-01-01T00:19:06',
    quantity: 55.2,
    vehicle: 'DEM444',
    id: 132768,
    tank_volume_start: 46993.39999999995,
    tank_volume_stop: 46938.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:19:21',
    timestamp_stop: '2022-01-01T00:19:26',
    quantity: 53.5,
    vehicle: 'DEM888',
    id: 132770,
    tank_volume_start: 46938.19999999995,
    tank_volume_stop: 46884.69999999995,
  },
  {
    timestamp_start: '2022-01-01T00:19:41',
    timestamp_stop: '2022-01-01T00:19:46',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 132772,
    tank_volume_start: 46884.69999999995,
    tank_volume_stop: 46830.999999999956,
  },
  {
    timestamp_start: '2022-01-01T00:20:01',
    timestamp_stop: '2022-01-01T00:20:06',
    quantity: 49.8,
    vehicle: 'DEM333',
    id: 132774,
    tank_volume_start: 46830.999999999956,
    tank_volume_stop: 46781.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:20:21',
    timestamp_stop: '2022-01-01T00:20:26',
    quantity: 50.9,
    vehicle: 'DEM666',
    id: 132776,
    tank_volume_start: 46781.19999999995,
    tank_volume_stop: 46730.29999999995,
  },
  {
    timestamp_start: '2022-01-01T00:20:41',
    timestamp_stop: '2022-01-01T00:20:47',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 132778,
    tank_volume_start: 46730.29999999995,
    tank_volume_stop: 46677.79999999995,
  },
  {
    timestamp_start: '2022-01-01T00:21:02',
    timestamp_stop: '2022-01-01T00:21:07',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 132780,
    tank_volume_start: 46677.79999999995,
    tank_volume_stop: 46624.69999999995,
  },
  {
    timestamp_start: '2022-01-01T00:21:22',
    timestamp_stop: '2022-01-01T00:21:27',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 132782,
    tank_volume_start: 46624.69999999995,
    tank_volume_stop: 46572.39999999995,
  },
  {
    timestamp_start: '2022-01-01T00:21:42',
    timestamp_stop: '2022-01-01T00:21:48',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 132784,
    tank_volume_start: 46572.39999999995,
    tank_volume_stop: 46519.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:22:03',
    timestamp_stop: '2022-01-01T00:22:08',
    quantity: 50.1,
    vehicle: 'DEM000',
    id: 132786,
    tank_volume_start: 46519.19999999995,
    tank_volume_stop: 46469.099999999955,
  },
  {
    timestamp_start: '2022-01-01T00:22:23',
    timestamp_stop: '2022-01-01T00:22:29',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 132788,
    tank_volume_start: 46469.099999999955,
    tank_volume_stop: 46409.79999999995,
  },
  {
    timestamp_start: '2022-01-01T00:22:44',
    timestamp_stop: '2022-01-01T00:22:48',
    quantity: 47.5,
    vehicle: 'DEM333',
    id: 132790,
    tank_volume_start: 46409.79999999995,
    tank_volume_stop: 46362.29999999995,
  },
  {
    timestamp_start: '2022-01-01T00:23:03',
    timestamp_stop: '2022-01-01T00:23:09',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 132792,
    tank_volume_start: 46362.29999999995,
    tank_volume_stop: 46305.099999999955,
  },
  {
    timestamp_start: '2022-01-01T00:23:24',
    timestamp_stop: '2022-01-01T00:23:29',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 132794,
    tank_volume_start: 46305.099999999955,
    tank_volume_stop: 46253.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:23:44',
    timestamp_stop: '2022-01-01T00:23:50',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 132796,
    tank_volume_start: 46253.19999999995,
    tank_volume_stop: 46196.599999999955,
  },
  {
    timestamp_start: '2022-01-01T00:24:05',
    timestamp_stop: '2022-01-01T00:24:10',
    quantity: 46.7,
    vehicle: 'DEM888',
    id: 132798,
    tank_volume_start: 46196.599999999955,
    tank_volume_stop: 46149.89999999996,
  },
  {
    timestamp_start: '2022-01-01T00:24:25',
    timestamp_stop: '2022-01-01T00:24:31',
    quantity: 62.3,
    vehicle: 'DEM444',
    id: 132800,
    tank_volume_start: 46149.89999999996,
    tank_volume_stop: 46087.599999999955,
  },
  {
    timestamp_start: '2022-01-01T00:24:46',
    timestamp_stop: '2022-01-01T00:24:52',
    quantity: 59.0,
    vehicle: 'DEM444',
    id: 132802,
    tank_volume_start: 46087.599999999955,
    tank_volume_stop: 46028.599999999955,
  },
  {
    timestamp_start: '2022-01-01T00:25:07',
    timestamp_stop: '2022-01-01T00:25:12',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 132804,
    tank_volume_start: 46028.599999999955,
    tank_volume_stop: 45972.29999999995,
  },
  {
    timestamp_start: '2022-01-01T00:25:27',
    timestamp_stop: '2022-01-01T00:25:32',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 132806,
    tank_volume_start: 45972.29999999995,
    tank_volume_stop: 45920.599999999955,
  },
  {
    timestamp_start: '2022-01-01T00:25:47',
    timestamp_stop: '2022-01-01T00:25:53',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 132808,
    tank_volume_start: 45920.599999999955,
    tank_volume_stop: 45862.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:26:08',
    timestamp_stop: '2022-01-01T00:26:14',
    quantity: 61.9,
    vehicle: 'DEM222',
    id: 132810,
    tank_volume_start: 45862.39999999996,
    tank_volume_stop: 45800.499999999956,
  },
  {
    timestamp_start: '2022-01-01T00:26:29',
    timestamp_stop: '2022-01-01T00:26:35',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 132812,
    tank_volume_start: 45800.499999999956,
    tank_volume_stop: 45746.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:26:50',
    timestamp_stop: '2022-01-01T00:26:56',
    quantity: 57.7,
    vehicle: 'DEM111',
    id: 132814,
    tank_volume_start: 45746.79999999996,
    tank_volume_stop: 45689.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:27:11',
    timestamp_stop: '2022-01-01T00:27:17',
    quantity: 61.8,
    vehicle: 'DEM333',
    id: 132816,
    tank_volume_start: 45689.09999999996,
    tank_volume_stop: 45627.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:27:32',
    timestamp_stop: '2022-01-01T00:27:37',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 132818,
    tank_volume_start: 45627.29999999996,
    tank_volume_stop: 45571.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:27:52',
    timestamp_stop: '2022-01-01T00:27:58',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 132820,
    tank_volume_start: 45571.39999999996,
    tank_volume_stop: 45516.19999999996,
  },
  {
    timestamp_start: '2022-01-01T00:28:13',
    timestamp_stop: '2022-01-01T00:28:18',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 132822,
    tank_volume_start: 45516.19999999996,
    tank_volume_stop: 45467.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:28:33',
    timestamp_stop: '2022-01-01T00:28:38',
    quantity: 55.0,
    vehicle: 'DEM888',
    id: 132824,
    tank_volume_start: 45467.79999999996,
    tank_volume_stop: 45412.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:28:53',
    timestamp_stop: '2022-01-01T00:28:59',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 132826,
    tank_volume_start: 45412.79999999996,
    tank_volume_stop: 45358.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:29:14',
    timestamp_stop: '2022-01-01T00:29:19',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 132828,
    tank_volume_start: 45358.39999999996,
    tank_volume_stop: 45302.499999999956,
  },
  {
    timestamp_start: '2022-01-01T00:29:34',
    timestamp_stop: '2022-01-01T00:29:40',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 132830,
    tank_volume_start: 45302.499999999956,
    tank_volume_stop: 45248.19999999995,
  },
  {
    timestamp_start: '2022-01-01T00:29:55',
    timestamp_stop: '2022-01-01T00:30:00',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 132832,
    tank_volume_start: 45248.19999999995,
    tank_volume_stop: 45197.999999999956,
  },
  {
    timestamp_start: '2022-01-01T00:30:15',
    timestamp_stop: '2022-01-01T00:30:21',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 132834,
    tank_volume_start: 45197.999999999956,
    tank_volume_stop: 45139.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:30:36',
    timestamp_stop: '2022-01-01T00:30:41',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 132836,
    tank_volume_start: 45139.79999999996,
    tank_volume_stop: 45085.499999999956,
  },
  {
    timestamp_start: '2022-01-01T00:30:56',
    timestamp_stop: '2022-01-01T00:31:01',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 132838,
    tank_volume_start: 45085.499999999956,
    tank_volume_stop: 45030.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:31:16',
    timestamp_stop: '2022-01-01T00:31:22',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 132840,
    tank_volume_start: 45030.79999999996,
    tank_volume_stop: 44970.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:31:37',
    timestamp_stop: '2022-01-01T00:31:44',
    quantity: 63.2,
    vehicle: 'DEM222',
    id: 132842,
    tank_volume_start: 44970.79999999996,
    tank_volume_stop: 44907.59999999996,
  },
  {
    timestamp_start: '2022-01-01T00:31:59',
    timestamp_stop: '2022-01-01T00:32:04',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 132844,
    tank_volume_start: 44907.59999999996,
    tank_volume_stop: 44851.19999999996,
  },
  {
    timestamp_start: '2022-01-01T00:32:19',
    timestamp_stop: '2022-01-01T00:32:25',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 132846,
    tank_volume_start: 44851.19999999996,
    tank_volume_stop: 44798.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:32:40',
    timestamp_stop: '2022-01-01T00:32:45',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 132848,
    tank_volume_start: 44798.09999999996,
    tank_volume_stop: 44743.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:33:00',
    timestamp_stop: '2022-01-01T00:33:05',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 132850,
    tank_volume_start: 44743.29999999996,
    tank_volume_stop: 44691.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:33:20',
    timestamp_stop: '2022-01-01T00:33:26',
    quantity: 55.1,
    vehicle: 'DEM777',
    id: 132852,
    tank_volume_start: 44691.39999999996,
    tank_volume_stop: 44636.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:33:41',
    timestamp_stop: '2022-01-01T00:33:47',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 132854,
    tank_volume_start: 44636.29999999996,
    tank_volume_stop: 44579.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:34:02',
    timestamp_stop: '2022-01-01T00:34:07',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 132856,
    tank_volume_start: 44579.39999999996,
    tank_volume_stop: 44526.099999999955,
  },
  {
    timestamp_start: '2022-01-01T00:34:22',
    timestamp_stop: '2022-01-01T00:34:27',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 132858,
    tank_volume_start: 44526.099999999955,
    tank_volume_stop: 44471.89999999996,
  },
  {
    timestamp_start: '2022-01-01T00:34:42',
    timestamp_stop: '2022-01-01T00:34:48',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 132860,
    tank_volume_start: 44471.89999999996,
    tank_volume_stop: 44413.39999999996,
  },
  {
    timestamp_start: '2022-01-01T00:35:03',
    timestamp_stop: '2022-01-01T00:35:08',
    quantity: 51.3,
    vehicle: 'DEM555',
    id: 132862,
    tank_volume_start: 44413.39999999996,
    tank_volume_stop: 44362.099999999955,
  },
  {
    timestamp_start: '2022-01-01T00:35:23',
    timestamp_stop: '2022-01-01T00:35:29',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 132864,
    tank_volume_start: 44362.099999999955,
    tank_volume_stop: 44305.89999999996,
  },
  {
    timestamp_start: '2022-01-01T00:35:44',
    timestamp_stop: '2022-01-01T00:35:50',
    quantity: 63.6,
    vehicle: 'DEM111',
    id: 132866,
    tank_volume_start: 44305.89999999996,
    tank_volume_stop: 44242.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:36:05',
    timestamp_stop: '2022-01-01T00:36:10',
    quantity: 52.2,
    vehicle: 'DEM444',
    id: 132868,
    tank_volume_start: 44242.29999999996,
    tank_volume_stop: 44190.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:36:25',
    timestamp_stop: '2022-01-01T00:36:31',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 132870,
    tank_volume_start: 44190.09999999996,
    tank_volume_stop: 44135.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:36:46',
    timestamp_stop: '2022-01-01T00:36:52',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 132872,
    tank_volume_start: 44135.29999999996,
    tank_volume_stop: 44079.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:37:07',
    timestamp_stop: '2022-01-01T00:37:12',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 132874,
    tank_volume_start: 44079.09999999996,
    tank_volume_stop: 44020.899999999965,
  },
  {
    timestamp_start: '2022-01-01T00:37:27',
    timestamp_stop: '2022-01-01T00:37:33',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 132876,
    tank_volume_start: 44020.899999999965,
    tank_volume_stop: 43965.09999999996,
  },
  {
    timestamp_start: '2022-01-01T00:37:48',
    timestamp_stop: '2022-01-01T00:37:54',
    quantity: 56.4,
    vehicle: 'DEM777',
    id: 132878,
    tank_volume_start: 43965.09999999996,
    tank_volume_stop: 43908.69999999996,
  },
  {
    timestamp_start: '2022-01-01T00:38:09',
    timestamp_stop: '2022-01-01T00:38:14',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 132880,
    tank_volume_start: 43908.69999999996,
    tank_volume_stop: 43851.49999999996,
  },
  {
    timestamp_start: '2022-01-01T00:38:29',
    timestamp_stop: '2022-01-01T00:38:35',
    quantity: 58.8,
    vehicle: 'DEM222',
    id: 132882,
    tank_volume_start: 43851.49999999996,
    tank_volume_stop: 43792.69999999996,
  },
  {
    timestamp_start: '2022-01-01T00:38:50',
    timestamp_stop: '2022-01-01T00:38:56',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 132884,
    tank_volume_start: 43792.69999999996,
    tank_volume_stop: 43731.79999999996,
  },
  {
    timestamp_start: '2022-01-01T00:39:11',
    timestamp_stop: '2022-01-01T00:39:17',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 132886,
    tank_volume_start: 43731.79999999996,
    tank_volume_stop: 43679.69999999996,
  },
  {
    timestamp_start: '2022-01-01T00:39:32',
    timestamp_stop: '2022-01-01T00:39:37',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 132888,
    tank_volume_start: 43679.69999999996,
    tank_volume_stop: 43628.59999999996,
  },
  {
    timestamp_start: '2022-01-01T00:39:52',
    timestamp_stop: '2022-01-01T00:39:56',
    quantity: 46.3,
    vehicle: 'DEM333',
    id: 132890,
    tank_volume_start: 43628.59999999996,
    tank_volume_stop: 43582.29999999996,
  },
  {
    timestamp_start: '2022-01-01T00:40:11',
    timestamp_stop: '2022-01-01T00:40:17',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 132892,
    tank_volume_start: 43582.29999999996,
    tank_volume_stop: 43524.59999999996,
  },
  {
    timestamp_start: '2022-01-01T00:40:32',
    timestamp_stop: '2022-01-01T00:40:37',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 132894,
    tank_volume_start: 43524.59999999996,
    tank_volume_stop: 43471.99999999996,
  },
  {
    timestamp_start: '2022-01-01T00:40:52',
    timestamp_stop: '2022-01-01T00:40:57',
    quantity: 48.5,
    vehicle: 'DEM111',
    id: 132896,
    tank_volume_start: 43471.99999999996,
    tank_volume_stop: 43423.49999999996,
  },
  {
    timestamp_start: '2022-01-01T00:41:12',
    timestamp_stop: '2022-01-01T00:41:18',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 132898,
    tank_volume_start: 43423.49999999996,
    tank_volume_stop: 43369.29999999997,
  },
  {
    timestamp_start: '2022-01-01T00:41:33',
    timestamp_stop: '2022-01-01T00:41:38',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 132900,
    tank_volume_start: 43369.29999999997,
    tank_volume_stop: 43315.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:41:53',
    timestamp_stop: '2022-01-01T00:41:59',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 132902,
    tank_volume_start: 43315.59999999997,
    tank_volume_stop: 43257.29999999997,
  },
  {
    timestamp_start: '2022-01-01T00:42:14',
    timestamp_stop: '2022-01-01T00:42:19',
    quantity: 51.9,
    vehicle: 'DEM666',
    id: 132904,
    tank_volume_start: 43257.29999999997,
    tank_volume_stop: 43205.399999999965,
  },
  {
    timestamp_start: '2022-01-01T00:42:34',
    timestamp_stop: '2022-01-01T00:42:39',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 132906,
    tank_volume_start: 43205.399999999965,
    tank_volume_stop: 43150.19999999997,
  },
  {
    timestamp_start: '2022-01-01T00:42:54',
    timestamp_stop: '2022-01-01T00:43:01',
    quantity: 60.7,
    vehicle: 'DEM444',
    id: 132908,
    tank_volume_start: 43150.19999999997,
    tank_volume_stop: 43089.49999999997,
  },
  {
    timestamp_start: '2022-01-01T00:43:16',
    timestamp_stop: '2022-01-01T00:43:21',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 132910,
    tank_volume_start: 43089.49999999997,
    tank_volume_stop: 43031.99999999997,
  },
  {
    timestamp_start: '2022-01-01T00:43:36',
    timestamp_stop: '2022-01-01T00:43:41',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 132912,
    tank_volume_start: 43031.99999999997,
    tank_volume_stop: 42983.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:43:56',
    timestamp_stop: '2022-01-01T00:44:01',
    quantity: 48.6,
    vehicle: 'DEM000',
    id: 132914,
    tank_volume_start: 42983.59999999997,
    tank_volume_stop: 42934.99999999997,
  },
  {
    timestamp_start: '2022-01-01T00:44:16',
    timestamp_stop: '2022-01-01T00:44:22',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 132916,
    tank_volume_start: 42934.99999999997,
    tank_volume_stop: 42877.799999999974,
  },
  {
    timestamp_start: '2022-01-01T00:44:37',
    timestamp_stop: '2022-01-01T00:44:42',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 132918,
    tank_volume_start: 42877.799999999974,
    tank_volume_stop: 42822.799999999974,
  },
  {
    timestamp_start: '2022-01-01T00:44:57',
    timestamp_stop: '2022-01-01T00:45:03',
    quantity: 58.6,
    vehicle: 'DEM555',
    id: 132920,
    tank_volume_start: 42822.799999999974,
    tank_volume_stop: 42764.199999999975,
  },
  {
    timestamp_start: '2022-01-01T00:45:18',
    timestamp_stop: '2022-01-01T00:45:24',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 132922,
    tank_volume_start: 42764.199999999975,
    tank_volume_stop: 42707.49999999998,
  },
  {
    timestamp_start: '2022-01-01T00:45:39',
    timestamp_stop: '2022-01-01T00:45:43',
    quantity: 47.3,
    vehicle: 'DEM222',
    id: 132924,
    tank_volume_start: 42707.49999999998,
    tank_volume_stop: 42660.199999999975,
  },
  {
    timestamp_start: '2022-01-01T00:45:58',
    timestamp_stop: '2022-01-01T00:46:03',
    quantity: 49.4,
    vehicle: 'DEM444',
    id: 132926,
    tank_volume_start: 42660.199999999975,
    tank_volume_stop: 42610.799999999974,
  },
  {
    timestamp_start: '2022-01-01T00:46:18',
    timestamp_stop: '2022-01-01T00:46:24',
    quantity: 60.3,
    vehicle: 'DEM111',
    id: 132928,
    tank_volume_start: 42610.799999999974,
    tank_volume_stop: 42550.49999999997,
  },
  {
    timestamp_start: '2022-01-01T00:46:39',
    timestamp_stop: '2022-01-01T00:46:45',
    quantity: 56.3,
    vehicle: 'DEM555',
    id: 132930,
    tank_volume_start: 42550.49999999997,
    tank_volume_stop: 42494.19999999997,
  },
  {
    timestamp_start: '2022-01-01T00:47:00',
    timestamp_stop: '2022-01-01T00:47:05',
    quantity: 46.4,
    vehicle: 'DEM111',
    id: 132932,
    tank_volume_start: 42494.19999999997,
    tank_volume_stop: 42447.79999999997,
  },
  {
    timestamp_start: '2022-01-01T00:47:20',
    timestamp_stop: '2022-01-01T00:47:25',
    quantity: 52.2,
    vehicle: 'DEM333',
    id: 132934,
    tank_volume_start: 42447.79999999997,
    tank_volume_stop: 42395.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:47:40',
    timestamp_stop: '2022-01-01T00:47:45',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 132936,
    tank_volume_start: 42395.59999999997,
    tank_volume_stop: 42342.09999999997,
  },
  {
    timestamp_start: '2022-01-01T00:48:00',
    timestamp_stop: '2022-01-01T00:48:06',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 132938,
    tank_volume_start: 42342.09999999997,
    tank_volume_stop: 42285.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:48:21',
    timestamp_stop: '2022-01-01T00:48:27',
    quantity: 61.9,
    vehicle: 'DEM222',
    id: 132940,
    tank_volume_start: 42285.59999999997,
    tank_volume_stop: 42223.69999999997,
  },
  {
    timestamp_start: '2022-01-01T00:48:42',
    timestamp_stop: '2022-01-01T00:48:47',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 132942,
    tank_volume_start: 42223.69999999997,
    tank_volume_stop: 42170.69999999997,
  },
  {
    timestamp_start: '2022-01-01T00:49:02',
    timestamp_stop: '2022-01-01T00:49:07',
    quantity: 49.1,
    vehicle: 'DEM888',
    id: 132944,
    tank_volume_start: 42170.69999999997,
    tank_volume_stop: 42121.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:49:22',
    timestamp_stop: '2022-01-01T00:49:28',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 132946,
    tank_volume_start: 42121.59999999997,
    tank_volume_stop: 42063.39999999997,
  },
  {
    timestamp_start: '2022-01-01T00:49:43',
    timestamp_stop: '2022-01-01T00:49:49',
    quantity: 57.9,
    vehicle: 'DEM444',
    id: 132948,
    tank_volume_start: 42063.39999999997,
    tank_volume_stop: 42005.49999999997,
  },
  {
    timestamp_start: '2022-01-01T00:50:04',
    timestamp_stop: '2022-01-01T00:50:09',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 132950,
    tank_volume_start: 42005.49999999997,
    tank_volume_stop: 41953.19999999997,
  },
  {
    timestamp_start: '2022-01-01T00:50:24',
    timestamp_stop: '2022-01-01T00:50:30',
    quantity: 62.6,
    vehicle: 'DEM222',
    id: 132952,
    tank_volume_start: 41953.19999999997,
    tank_volume_stop: 41890.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:50:45',
    timestamp_stop: '2022-01-01T00:50:50',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 132954,
    tank_volume_start: 41890.59999999997,
    tank_volume_stop: 41840.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:51:05',
    timestamp_stop: '2022-01-01T00:51:12',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 132956,
    tank_volume_start: 41840.59999999997,
    tank_volume_stop: 41778.99999999997,
  },
  {
    timestamp_start: '2022-01-01T00:51:27',
    timestamp_stop: '2022-01-01T00:51:32',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 132958,
    tank_volume_start: 41778.99999999997,
    tank_volume_stop: 41724.59999999997,
  },
  {
    timestamp_start: '2022-01-01T00:51:47',
    timestamp_stop: '2022-01-01T00:51:52',
    quantity: 47.6,
    vehicle: 'DEM222',
    id: 132960,
    tank_volume_start: 41724.59999999997,
    tank_volume_stop: 41676.99999999997,
  },
  {
    timestamp_start: '2022-01-01T00:52:07',
    timestamp_stop: '2022-01-01T00:52:12',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 132962,
    tank_volume_start: 41676.99999999997,
    tank_volume_stop: 41620.299999999974,
  },
  {
    timestamp_start: '2022-01-01T00:52:27',
    timestamp_stop: '2022-01-01T00:52:33',
    quantity: 59.7,
    vehicle: 'DEM222',
    id: 132964,
    tank_volume_start: 41620.299999999974,
    tank_volume_stop: 41560.59999999998,
  },
  {
    timestamp_start: '2022-01-01T00:52:48',
    timestamp_stop: '2022-01-01T00:52:54',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 132966,
    tank_volume_start: 41560.59999999998,
    tank_volume_stop: 41502.99999999998,
  },
  {
    timestamp_start: '2022-01-01T00:53:09',
    timestamp_stop: '2022-01-01T00:53:14',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 132968,
    tank_volume_start: 41502.99999999998,
    tank_volume_stop: 41450.49999999998,
  },
  {
    timestamp_start: '2022-01-01T00:53:29',
    timestamp_stop: '2022-01-01T00:53:35',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 132970,
    tank_volume_start: 41450.49999999998,
    tank_volume_stop: 41397.39999999998,
  },
  {
    timestamp_start: '2022-01-01T00:53:50',
    timestamp_stop: '2022-01-01T00:53:55',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 132972,
    tank_volume_start: 41397.39999999998,
    tank_volume_stop: 41342.69999999998,
  },
  {
    timestamp_start: '2022-01-01T00:54:10',
    timestamp_stop: '2022-01-01T00:54:16',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 132974,
    tank_volume_start: 41342.69999999998,
    tank_volume_stop: 41289.999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:54:31',
    timestamp_stop: '2022-01-01T00:54:36',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 132976,
    tank_volume_start: 41289.999999999985,
    tank_volume_stop: 41230.89999999999,
  },
  {
    timestamp_start: '2022-01-01T00:54:51',
    timestamp_stop: '2022-01-01T00:54:57',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 132978,
    tank_volume_start: 41230.89999999999,
    tank_volume_stop: 41179.29999999999,
  },
  {
    timestamp_start: '2022-01-01T00:55:12',
    timestamp_stop: '2022-01-01T00:55:17',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 132980,
    tank_volume_start: 41179.29999999999,
    tank_volume_stop: 41128.89999999999,
  },
  {
    timestamp_start: '2022-01-01T00:55:32',
    timestamp_stop: '2022-01-01T00:55:37',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 132982,
    tank_volume_start: 41128.89999999999,
    tank_volume_stop: 41071.39999999999,
  },
  {
    timestamp_start: '2022-01-01T00:55:52',
    timestamp_stop: '2022-01-01T00:55:58',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 132984,
    tank_volume_start: 41071.39999999999,
    tank_volume_stop: 41016.599999999984,
  },
  {
    timestamp_start: '2022-01-01T00:56:13',
    timestamp_stop: '2022-01-01T00:56:18',
    quantity: 51.6,
    vehicle: 'DEM333',
    id: 132986,
    tank_volume_start: 41016.599999999984,
    tank_volume_stop: 40964.999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:56:33',
    timestamp_stop: '2022-01-01T00:56:39',
    quantity: 57.4,
    vehicle: 'DEM333',
    id: 132988,
    tank_volume_start: 40964.999999999985,
    tank_volume_stop: 40907.599999999984,
  },
  {
    timestamp_start: '2022-01-01T00:56:54',
    timestamp_stop: '2022-01-01T00:56:59',
    quantity: 47.9,
    vehicle: 'DEM444',
    id: 132990,
    tank_volume_start: 40907.599999999984,
    tank_volume_stop: 40859.69999999998,
  },
  {
    timestamp_start: '2022-01-01T00:57:14',
    timestamp_stop: '2022-01-01T00:57:18',
    quantity: 48.3,
    vehicle: 'DEM111',
    id: 132992,
    tank_volume_start: 40859.69999999998,
    tank_volume_stop: 40811.39999999998,
  },
  {
    timestamp_start: '2022-01-01T00:57:33',
    timestamp_stop: '2022-01-01T00:57:39',
    quantity: 59.0,
    vehicle: 'DEM777',
    id: 132994,
    tank_volume_start: 40811.39999999998,
    tank_volume_stop: 40752.39999999998,
  },
  {
    timestamp_start: '2022-01-01T00:57:54',
    timestamp_stop: '2022-01-01T00:58:00',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 132996,
    tank_volume_start: 40752.39999999998,
    tank_volume_stop: 40699.29999999998,
  },
  {
    timestamp_start: '2022-01-01T00:58:15',
    timestamp_stop: '2022-01-01T00:58:20',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 132998,
    tank_volume_start: 40699.29999999998,
    tank_volume_stop: 40646.39999999998,
  },
  {
    timestamp_start: '2022-01-01T00:58:35',
    timestamp_stop: '2022-01-01T00:58:41',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 133000,
    tank_volume_start: 40646.39999999998,
    tank_volume_stop: 40587.59999999998,
  },
  {
    timestamp_start: '2022-01-01T00:58:56',
    timestamp_stop: '2022-01-01T00:59:00',
    quantity: 46.9,
    vehicle: 'DEM666',
    id: 133002,
    tank_volume_start: 40587.59999999998,
    tank_volume_stop: 40540.699999999975,
  },
  {
    timestamp_start: '2022-01-01T00:59:15',
    timestamp_stop: '2022-01-01T00:59:20',
    quantity: 49.3,
    vehicle: 'DEM888',
    id: 133004,
    tank_volume_start: 40540.699999999975,
    tank_volume_stop: 40491.39999999997,
  },
  {
    timestamp_start: '2022-01-01T00:59:35',
    timestamp_stop: '2022-01-01T00:59:40',
    quantity: 49.4,
    vehicle: 'DEM444',
    id: 133006,
    tank_volume_start: 40491.39999999997,
    tank_volume_stop: 40441.99999999997,
  },
  {
    timestamp_start: '2022-01-01T00:59:55',
    timestamp_stop: '2022-01-01T01:00:01',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 133008,
    tank_volume_start: 40441.99999999997,
    tank_volume_stop: 40387.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:00:16',
    timestamp_stop: '2022-01-01T01:00:22',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 133010,
    tank_volume_start: 40387.299999999974,
    tank_volume_stop: 40325.59999999998,
  },
  {
    timestamp_start: '2022-01-01T01:00:37',
    timestamp_stop: '2022-01-01T01:00:42',
    quantity: 55.3,
    vehicle: 'DEM777',
    id: 133012,
    tank_volume_start: 40325.59999999998,
    tank_volume_stop: 40270.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:00:57',
    timestamp_stop: '2022-01-01T01:01:03',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 133014,
    tank_volume_start: 40270.299999999974,
    tank_volume_stop: 40219.59999999998,
  },
  {
    timestamp_start: '2022-01-01T01:01:18',
    timestamp_stop: '2022-01-01T01:01:23',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 133016,
    tank_volume_start: 40219.59999999998,
    tank_volume_stop: 40167.699999999975,
  },
  {
    timestamp_start: '2022-01-01T01:01:38',
    timestamp_stop: '2022-01-01T01:01:44',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 133018,
    tank_volume_start: 40167.699999999975,
    tank_volume_stop: 40107.49999999998,
  },
  {
    timestamp_start: '2022-01-01T01:01:59',
    timestamp_stop: '2022-01-01T01:02:03',
    quantity: 47.1,
    vehicle: 'DEM111',
    id: 133020,
    tank_volume_start: 40107.49999999998,
    tank_volume_stop: 40060.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:02:18',
    timestamp_stop: '2022-01-01T01:02:24',
    quantity: 56.3,
    vehicle: 'DEM555',
    id: 133022,
    tank_volume_start: 40060.39999999998,
    tank_volume_stop: 40004.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:02:39',
    timestamp_stop: '2022-01-01T01:02:45',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 133024,
    tank_volume_start: 40004.09999999998,
    tank_volume_stop: 39944.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:03:00',
    timestamp_stop: '2022-01-01T01:03:05',
    quantity: 48.7,
    vehicle: 'DEM222',
    id: 133026,
    tank_volume_start: 39944.09999999998,
    tank_volume_stop: 39895.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:03:20',
    timestamp_stop: '2022-01-01T01:03:25',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 133028,
    tank_volume_start: 39895.39999999998,
    tank_volume_stop: 39841.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:03:40',
    timestamp_stop: '2022-01-01T01:03:46',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 133030,
    tank_volume_start: 39841.39999999998,
    tank_volume_stop: 39783.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:04:01',
    timestamp_stop: '2022-01-01T01:04:07',
    quantity: 62.1,
    vehicle: 'DEM777',
    id: 133032,
    tank_volume_start: 39783.09999999998,
    tank_volume_stop: 39720.99999999998,
  },
  {
    timestamp_start: '2022-01-01T01:04:22',
    timestamp_stop: '2022-01-01T01:04:28',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 133034,
    tank_volume_start: 39720.99999999998,
    tank_volume_stop: 39667.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:04:43',
    timestamp_stop: '2022-01-01T01:04:48',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 133036,
    tank_volume_start: 39667.39999999998,
    tank_volume_stop: 39612.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:05:03',
    timestamp_stop: '2022-01-01T01:05:09',
    quantity: 61.8,
    vehicle: 'DEM333',
    id: 133038,
    tank_volume_start: 39612.09999999998,
    tank_volume_stop: 39550.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:05:24',
    timestamp_stop: '2022-01-01T01:05:30',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 133040,
    tank_volume_start: 39550.299999999974,
    tank_volume_stop: 39492.99999999997,
  },
  {
    timestamp_start: '2022-01-01T01:05:45',
    timestamp_stop: '2022-01-01T01:05:51',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 133042,
    tank_volume_start: 39492.99999999997,
    tank_volume_stop: 39437.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:06:06',
    timestamp_stop: '2022-01-01T01:06:12',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 133044,
    tank_volume_start: 39437.299999999974,
    tank_volume_stop: 39378.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:06:27',
    timestamp_stop: '2022-01-01T01:06:32',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 133046,
    tank_volume_start: 39378.49999999997,
    tank_volume_stop: 39324.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:06:47',
    timestamp_stop: '2022-01-01T01:06:52',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 133048,
    tank_volume_start: 39324.69999999997,
    tank_volume_stop: 39273.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:07:07',
    timestamp_stop: '2022-01-01T01:07:12',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 133050,
    tank_volume_start: 39273.59999999997,
    tank_volume_stop: 39226.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:07:27',
    timestamp_stop: '2022-01-01T01:07:33',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 133052,
    tank_volume_start: 39226.89999999997,
    tank_volume_stop: 39169.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:07:48',
    timestamp_stop: '2022-01-01T01:07:53',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 133054,
    tank_volume_start: 39169.39999999997,
    tank_volume_stop: 39115.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:08:08',
    timestamp_stop: '2022-01-01T01:08:13',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 133056,
    tank_volume_start: 39115.39999999997,
    tank_volume_stop: 39061.09999999997,
  },
  {
    timestamp_start: '2022-01-01T01:08:28',
    timestamp_stop: '2022-01-01T01:08:34',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 133058,
    tank_volume_start: 39061.09999999997,
    tank_volume_stop: 39004.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:08:49',
    timestamp_stop: '2022-01-01T01:08:54',
    quantity: 47.5,
    vehicle: 'DEM444',
    id: 133060,
    tank_volume_start: 39004.59999999997,
    tank_volume_stop: 38957.09999999997,
  },
  {
    timestamp_start: '2022-01-01T01:09:09',
    timestamp_stop: '2022-01-01T01:09:14',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 133062,
    tank_volume_start: 38957.09999999997,
    tank_volume_stop: 38900.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:09:29',
    timestamp_stop: '2022-01-01T01:09:35',
    quantity: 51.9,
    vehicle: 'DEM777',
    id: 133064,
    tank_volume_start: 38900.89999999997,
    tank_volume_stop: 38848.99999999997,
  },
  {
    timestamp_start: '2022-01-01T01:09:50',
    timestamp_stop: '2022-01-01T01:09:56',
    quantity: 59.2,
    vehicle: 'DEM555',
    id: 133066,
    tank_volume_start: 38848.99999999997,
    tank_volume_stop: 38789.799999999974,
  },
  {
    timestamp_start: '2022-01-01T01:10:11',
    timestamp_stop: '2022-01-01T01:10:15',
    quantity: 46.8,
    vehicle: 'DEM555',
    id: 133068,
    tank_volume_start: 38789.799999999974,
    tank_volume_stop: 38742.99999999997,
  },
  {
    timestamp_start: '2022-01-01T01:10:30',
    timestamp_stop: '2022-01-01T01:10:36',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 133070,
    tank_volume_start: 38742.99999999997,
    tank_volume_stop: 38688.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:10:51',
    timestamp_stop: '2022-01-01T01:10:56',
    quantity: 57.6,
    vehicle: 'DEM000',
    id: 133072,
    tank_volume_start: 38688.19999999997,
    tank_volume_stop: 38630.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:11:11',
    timestamp_stop: '2022-01-01T01:11:17',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 133074,
    tank_volume_start: 38630.59999999997,
    tank_volume_stop: 38578.09999999997,
  },
  {
    timestamp_start: '2022-01-01T01:11:32',
    timestamp_stop: '2022-01-01T01:11:38',
    quantity: 59.9,
    vehicle: 'DEM444',
    id: 133076,
    tank_volume_start: 38578.09999999997,
    tank_volume_stop: 38518.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:11:53',
    timestamp_stop: '2022-01-01T01:11:58',
    quantity: 49.7,
    vehicle: 'DEM000',
    id: 133078,
    tank_volume_start: 38518.19999999997,
    tank_volume_stop: 38468.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:12:13',
    timestamp_stop: '2022-01-01T01:12:18',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 133080,
    tank_volume_start: 38468.49999999997,
    tank_volume_stop: 38413.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:12:33',
    timestamp_stop: '2022-01-01T01:12:39',
    quantity: 62.7,
    vehicle: 'DEM444',
    id: 133082,
    tank_volume_start: 38413.19999999997,
    tank_volume_stop: 38350.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:12:54',
    timestamp_stop: '2022-01-01T01:13:00',
    quantity: 54.7,
    vehicle: 'DEM555',
    id: 133084,
    tank_volume_start: 38350.49999999997,
    tank_volume_stop: 38295.799999999974,
  },
  {
    timestamp_start: '2022-01-01T01:13:15',
    timestamp_stop: '2022-01-01T01:13:20',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 133086,
    tank_volume_start: 38295.799999999974,
    tank_volume_stop: 38242.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:13:35',
    timestamp_stop: '2022-01-01T01:13:41',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 133088,
    tank_volume_start: 38242.49999999997,
    tank_volume_stop: 38182.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:13:56',
    timestamp_stop: '2022-01-01T01:14:02',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 133090,
    tank_volume_start: 38182.89999999997,
    tank_volume_stop: 38128.99999999997,
  },
  {
    timestamp_start: '2022-01-01T01:14:17',
    timestamp_stop: '2022-01-01T01:14:22',
    quantity: 49.7,
    vehicle: 'DEM666',
    id: 133092,
    tank_volume_start: 38128.99999999997,
    tank_volume_stop: 38079.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:14:37',
    timestamp_stop: '2022-01-01T01:14:42',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 133094,
    tank_volume_start: 38079.299999999974,
    tank_volume_stop: 38024.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:14:57',
    timestamp_stop: '2022-01-01T01:15:03',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 133096,
    tank_volume_start: 38024.39999999997,
    tank_volume_stop: 37965.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:15:18',
    timestamp_stop: '2022-01-01T01:15:23',
    quantity: 55.1,
    vehicle: 'DEM444',
    id: 133098,
    tank_volume_start: 37965.59999999997,
    tank_volume_stop: 37910.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:15:38',
    timestamp_stop: '2022-01-01T01:15:44',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 133100,
    tank_volume_start: 37910.49999999997,
    tank_volume_stop: 37850.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:15:59',
    timestamp_stop: '2022-01-01T01:16:05',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 133102,
    tank_volume_start: 37850.39999999997,
    tank_volume_stop: 37793.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:16:20',
    timestamp_stop: '2022-01-01T01:16:25',
    quantity: 46.9,
    vehicle: 'DEM000',
    id: 133104,
    tank_volume_start: 37793.49999999997,
    tank_volume_stop: 37746.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:16:40',
    timestamp_stop: '2022-01-01T01:16:45',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 133106,
    tank_volume_start: 37746.59999999997,
    tank_volume_stop: 37692.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:17:00',
    timestamp_stop: '2022-01-01T01:17:05',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 133108,
    tank_volume_start: 37692.79999999997,
    tank_volume_stop: 37640.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:17:20',
    timestamp_stop: '2022-01-01T01:17:26',
    quantity: 55.8,
    vehicle: 'DEM777',
    id: 133110,
    tank_volume_start: 37640.69999999997,
    tank_volume_stop: 37584.899999999965,
  },
  {
    timestamp_start: '2022-01-01T01:17:41',
    timestamp_stop: '2022-01-01T01:17:47',
    quantity: 61.7,
    vehicle: 'DEM888',
    id: 133112,
    tank_volume_start: 37584.899999999965,
    tank_volume_stop: 37523.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:18:02',
    timestamp_stop: '2022-01-01T01:18:07',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 133114,
    tank_volume_start: 37523.19999999997,
    tank_volume_stop: 37472.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:18:22',
    timestamp_stop: '2022-01-01T01:18:27',
    quantity: 47.4,
    vehicle: 'DEM111',
    id: 133116,
    tank_volume_start: 37472.79999999997,
    tank_volume_stop: 37425.399999999965,
  },
  {
    timestamp_start: '2022-01-01T01:18:42',
    timestamp_stop: '2022-01-01T01:18:47',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 133118,
    tank_volume_start: 37425.399999999965,
    tank_volume_stop: 37378.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:19:02',
    timestamp_stop: '2022-01-01T01:19:07',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 133120,
    tank_volume_start: 37378.69999999997,
    tank_volume_stop: 37322.49999999997,
  },
  {
    timestamp_start: '2022-01-01T01:19:22',
    timestamp_stop: '2022-01-01T01:19:28',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 133122,
    tank_volume_start: 37322.49999999997,
    tank_volume_stop: 37267.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:19:43',
    timestamp_stop: '2022-01-01T01:19:48',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 133124,
    tank_volume_start: 37267.299999999974,
    tank_volume_stop: 37210.199999999975,
  },
  {
    timestamp_start: '2022-01-01T01:20:03',
    timestamp_stop: '2022-01-01T01:20:09',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 133126,
    tank_volume_start: 37210.199999999975,
    tank_volume_stop: 37154.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:20:24',
    timestamp_stop: '2022-01-01T01:20:29',
    quantity: 49.2,
    vehicle: 'DEM666',
    id: 133128,
    tank_volume_start: 37154.89999999997,
    tank_volume_stop: 37105.699999999975,
  },
  {
    timestamp_start: '2022-01-01T01:20:44',
    timestamp_stop: '2022-01-01T01:20:49',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 133130,
    tank_volume_start: 37105.699999999975,
    tank_volume_stop: 37052.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:21:04',
    timestamp_stop: '2022-01-01T01:21:10',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 133132,
    tank_volume_start: 37052.09999999998,
    tank_volume_stop: 36995.89999999998,
  },
  {
    timestamp_start: '2022-01-01T01:21:25',
    timestamp_stop: '2022-01-01T01:21:31',
    quantity: 64.6,
    vehicle: 'DEM444',
    id: 133134,
    tank_volume_start: 36995.89999999998,
    tank_volume_stop: 36931.29999999998,
  },
  {
    timestamp_start: '2022-01-01T01:21:46',
    timestamp_stop: '2022-01-01T01:21:52',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 133136,
    tank_volume_start: 36931.29999999998,
    tank_volume_stop: 36878.79999999998,
  },
  {
    timestamp_start: '2022-01-01T01:22:07',
    timestamp_stop: '2022-01-01T01:22:13',
    quantity: 60.3,
    vehicle: 'DEM333',
    id: 133138,
    tank_volume_start: 36878.79999999998,
    tank_volume_stop: 36818.49999999998,
  },
  {
    timestamp_start: '2022-01-01T01:22:28',
    timestamp_stop: '2022-01-01T01:22:33',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 133140,
    tank_volume_start: 36818.49999999998,
    tank_volume_stop: 36765.699999999975,
  },
  {
    timestamp_start: '2022-01-01T01:22:48',
    timestamp_stop: '2022-01-01T01:22:53',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 133142,
    tank_volume_start: 36765.699999999975,
    tank_volume_stop: 36711.699999999975,
  },
  {
    timestamp_start: '2022-01-01T01:23:08',
    timestamp_stop: '2022-01-01T01:23:14',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 133144,
    tank_volume_start: 36711.699999999975,
    tank_volume_stop: 36651.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:23:29',
    timestamp_stop: '2022-01-01T01:23:35',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 133146,
    tank_volume_start: 36651.299999999974,
    tank_volume_stop: 36592.799999999974,
  },
  {
    timestamp_start: '2022-01-01T01:23:50',
    timestamp_stop: '2022-01-01T01:23:56',
    quantity: 58.7,
    vehicle: 'DEM111',
    id: 133148,
    tank_volume_start: 36592.799999999974,
    tank_volume_stop: 36534.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:24:11',
    timestamp_stop: '2022-01-01T01:24:16',
    quantity: 51.9,
    vehicle: 'DEM888',
    id: 133150,
    tank_volume_start: 36534.09999999998,
    tank_volume_stop: 36482.199999999975,
  },
  {
    timestamp_start: '2022-01-01T01:24:31',
    timestamp_stop: '2022-01-01T01:24:37',
    quantity: 60.3,
    vehicle: 'DEM222',
    id: 133152,
    tank_volume_start: 36482.199999999975,
    tank_volume_stop: 36421.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:24:52',
    timestamp_stop: '2022-01-01T01:24:58',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 133154,
    tank_volume_start: 36421.89999999997,
    tank_volume_stop: 36367.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:25:13',
    timestamp_stop: '2022-01-01T01:25:18',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 133156,
    tank_volume_start: 36367.299999999974,
    tank_volume_stop: 36316.799999999974,
  },
  {
    timestamp_start: '2022-01-01T01:25:33',
    timestamp_stop: '2022-01-01T01:25:38',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 133158,
    tank_volume_start: 36316.799999999974,
    tank_volume_stop: 36264.699999999975,
  },
  {
    timestamp_start: '2022-01-01T01:25:53',
    timestamp_stop: '2022-01-01T01:25:58',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 133160,
    tank_volume_start: 36264.699999999975,
    tank_volume_stop: 36216.89999999997,
  },
  {
    timestamp_start: '2022-01-01T01:26:13',
    timestamp_stop: '2022-01-01T01:26:18',
    quantity: 50.1,
    vehicle: 'DEM000',
    id: 133162,
    tank_volume_start: 36216.89999999997,
    tank_volume_stop: 36166.799999999974,
  },
  {
    timestamp_start: '2022-01-01T01:26:33',
    timestamp_stop: '2022-01-01T01:26:38',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 133164,
    tank_volume_start: 36166.799999999974,
    tank_volume_stop: 36116.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:26:53',
    timestamp_stop: '2022-01-01T01:26:58',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 133166,
    tank_volume_start: 36116.299999999974,
    tank_volume_stop: 36061.99999999997,
  },
  {
    timestamp_start: '2022-01-01T01:27:13',
    timestamp_stop: '2022-01-01T01:27:19',
    quantity: 59.6,
    vehicle: 'DEM777',
    id: 133168,
    tank_volume_start: 36061.99999999997,
    tank_volume_stop: 36002.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:27:34',
    timestamp_stop: '2022-01-01T01:27:40',
    quantity: 53.0,
    vehicle: 'DEM777',
    id: 133170,
    tank_volume_start: 36002.39999999997,
    tank_volume_stop: 35949.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:27:55',
    timestamp_stop: '2022-01-01T01:28:00',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 133172,
    tank_volume_start: 35949.39999999997,
    tank_volume_stop: 35898.09999999997,
  },
  {
    timestamp_start: '2022-01-01T01:28:15',
    timestamp_stop: '2022-01-01T01:28:21',
    quantity: 64.5,
    vehicle: 'DEM666',
    id: 133174,
    tank_volume_start: 35898.09999999997,
    tank_volume_stop: 35833.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:28:36',
    timestamp_stop: '2022-01-01T01:28:41',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 133176,
    tank_volume_start: 35833.59999999997,
    tank_volume_stop: 35780.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:28:56',
    timestamp_stop: '2022-01-01T01:29:02',
    quantity: 50.9,
    vehicle: 'DEM444',
    id: 133178,
    tank_volume_start: 35780.79999999997,
    tank_volume_stop: 35729.899999999965,
  },
  {
    timestamp_start: '2022-01-01T01:29:17',
    timestamp_stop: '2022-01-01T01:29:21',
    quantity: 49.6,
    vehicle: 'DEM222',
    id: 133180,
    tank_volume_start: 35729.899999999965,
    tank_volume_stop: 35680.29999999997,
  },
  {
    timestamp_start: '2022-01-01T01:29:36',
    timestamp_stop: '2022-01-01T01:29:42',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 133182,
    tank_volume_start: 35680.29999999997,
    tank_volume_stop: 35623.29999999997,
  },
  {
    timestamp_start: '2022-01-01T01:29:57',
    timestamp_stop: '2022-01-01T01:30:03',
    quantity: 60.2,
    vehicle: 'DEM666',
    id: 133184,
    tank_volume_start: 35623.29999999997,
    tank_volume_stop: 35563.09999999997,
  },
  {
    timestamp_start: '2022-01-01T01:30:18',
    timestamp_stop: '2022-01-01T01:30:24',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 133186,
    tank_volume_start: 35563.09999999997,
    tank_volume_stop: 35507.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:30:39',
    timestamp_stop: '2022-01-01T01:30:44',
    quantity: 49.1,
    vehicle: 'DEM111',
    id: 133188,
    tank_volume_start: 35507.79999999997,
    tank_volume_stop: 35458.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:30:59',
    timestamp_stop: '2022-01-01T01:31:04',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 133190,
    tank_volume_start: 35458.69999999997,
    tank_volume_stop: 35403.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:31:19',
    timestamp_stop: '2022-01-01T01:31:24',
    quantity: 50.6,
    vehicle: 'DEM555',
    id: 133192,
    tank_volume_start: 35403.79999999997,
    tank_volume_stop: 35353.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:31:39',
    timestamp_stop: '2022-01-01T01:31:45',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 133194,
    tank_volume_start: 35353.19999999997,
    tank_volume_stop: 35293.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:32:00',
    timestamp_stop: '2022-01-01T01:32:06',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 133196,
    tank_volume_start: 35293.19999999997,
    tank_volume_stop: 35238.899999999965,
  },
  {
    timestamp_start: '2022-01-01T01:32:21',
    timestamp_stop: '2022-01-01T01:32:26',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 133198,
    tank_volume_start: 35238.899999999965,
    tank_volume_stop: 35180.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:32:41',
    timestamp_stop: '2022-01-01T01:32:47',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 133200,
    tank_volume_start: 35180.19999999997,
    tank_volume_stop: 35121.79999999997,
  },
  {
    timestamp_start: '2022-01-01T01:33:02',
    timestamp_stop: '2022-01-01T01:33:08',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 133202,
    tank_volume_start: 35121.79999999997,
    tank_volume_stop: 35068.99999999996,
  },
  {
    timestamp_start: '2022-01-01T01:33:23',
    timestamp_stop: '2022-01-01T01:33:28',
    quantity: 58.5,
    vehicle: 'DEM111',
    id: 133204,
    tank_volume_start: 35068.99999999996,
    tank_volume_stop: 35010.49999999996,
  },
  {
    timestamp_start: '2022-01-01T01:33:43',
    timestamp_stop: '2022-01-01T01:33:50',
    quantity: 62.2,
    vehicle: 'DEM333',
    id: 133206,
    tank_volume_start: 35010.49999999996,
    tank_volume_stop: 34948.29999999997,
  },
  {
    timestamp_start: '2022-01-01T01:34:05',
    timestamp_stop: '2022-01-01T01:34:10',
    quantity: 50.1,
    vehicle: 'DEM666',
    id: 133208,
    tank_volume_start: 34948.29999999997,
    tank_volume_stop: 34898.19999999997,
  },
  {
    timestamp_start: '2022-01-01T01:34:25',
    timestamp_stop: '2022-01-01T01:34:30',
    quantity: 49.8,
    vehicle: 'DEM555',
    id: 133210,
    tank_volume_start: 34898.19999999997,
    tank_volume_stop: 34848.399999999965,
  },
  {
    timestamp_start: '2022-01-01T01:34:45',
    timestamp_stop: '2022-01-01T01:34:50',
    quantity: 49.7,
    vehicle: 'DEM000',
    id: 133212,
    tank_volume_start: 34848.399999999965,
    tank_volume_stop: 34798.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:35:05',
    timestamp_stop: '2022-01-01T01:35:10',
    quantity: 53.0,
    vehicle: 'DEM777',
    id: 133214,
    tank_volume_start: 34798.69999999997,
    tank_volume_stop: 34745.69999999997,
  },
  {
    timestamp_start: '2022-01-01T01:35:25',
    timestamp_stop: '2022-01-01T01:35:30',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 133216,
    tank_volume_start: 34745.69999999997,
    tank_volume_stop: 34692.59999999997,
  },
  {
    timestamp_start: '2022-01-01T01:35:45',
    timestamp_stop: '2022-01-01T01:35:51',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 133218,
    tank_volume_start: 34692.59999999997,
    tank_volume_stop: 34637.39999999997,
  },
  {
    timestamp_start: '2022-01-01T01:36:06',
    timestamp_stop: '2022-01-01T01:36:10',
    quantity: 46.1,
    vehicle: 'DEM000',
    id: 133220,
    tank_volume_start: 34637.39999999997,
    tank_volume_stop: 34591.299999999974,
  },
  {
    timestamp_start: '2022-01-01T01:36:25',
    timestamp_stop: '2022-01-01T01:36:30',
    quantity: 49.2,
    vehicle: 'DEM111',
    id: 133222,
    tank_volume_start: 34591.299999999974,
    tank_volume_stop: 34542.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:36:45',
    timestamp_stop: '2022-01-01T01:36:51',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 133224,
    tank_volume_start: 34542.09999999998,
    tank_volume_stop: 34487.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:06',
    timestamp_stop: '2022-01-01T01:37:11',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 133226,
    tank_volume_start: 34487.09999999998,
    tank_volume_stop: 34433.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:26',
    timestamp_stop: '2022-01-01T01:37:32',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 133228,
    tank_volume_start: 34433.09999999998,
    tank_volume_stop: 34374.59999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:47',
    timestamp_stop: '2022-01-01T01:37:52',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 133230,
    tank_volume_start: 34374.59999999998,
    tank_volume_stop: 34320.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:38:07',
    timestamp_stop: '2022-01-01T01:38:13',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 133232,
    tank_volume_start: 34320.39999999998,
    tank_volume_stop: 34263.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:38:28',
    timestamp_stop: '2022-01-01T01:38:34',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 133234,
    tank_volume_start: 34263.39999999998,
    tank_volume_stop: 34205.19999999998,
  },
  {
    timestamp_start: '2022-01-01T01:38:49',
    timestamp_stop: '2022-01-01T01:38:54',
    quantity: 48.6,
    vehicle: 'DEM777',
    id: 133236,
    tank_volume_start: 34205.19999999998,
    tank_volume_stop: 34156.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:39:09',
    timestamp_stop: '2022-01-01T01:39:15',
    quantity: 58.9,
    vehicle: 'DEM333',
    id: 133238,
    tank_volume_start: 34156.599999999984,
    tank_volume_stop: 34097.69999999998,
  },
  {
    timestamp_start: '2022-01-01T01:39:30',
    timestamp_stop: '2022-01-01T01:39:35',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 133240,
    tank_volume_start: 34097.69999999998,
    tank_volume_stop: 34043.69999999998,
  },
  {
    timestamp_start: '2022-01-01T01:39:50',
    timestamp_stop: '2022-01-01T01:39:55',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 133242,
    tank_volume_start: 34043.69999999998,
    tank_volume_stop: 33992.499999999985,
  },
  {
    timestamp_start: '2022-01-01T01:40:10',
    timestamp_stop: '2022-01-01T01:40:15',
    quantity: 48.8,
    vehicle: 'DEM777',
    id: 133244,
    tank_volume_start: 33992.499999999985,
    tank_volume_stop: 33943.69999999998,
  },
  {
    timestamp_start: '2022-01-01T01:40:30',
    timestamp_stop: '2022-01-01T01:40:36',
    quantity: 55.6,
    vehicle: 'DEM333',
    id: 133246,
    tank_volume_start: 33943.69999999998,
    tank_volume_stop: 33888.099999999984,
  },
  {
    timestamp_start: '2022-01-01T01:40:51',
    timestamp_stop: '2022-01-01T01:40:56',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 133248,
    tank_volume_start: 33888.099999999984,
    tank_volume_stop: 33832.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:41:11',
    timestamp_stop: '2022-01-01T01:41:16',
    quantity: 47.5,
    vehicle: 'DEM222',
    id: 133250,
    tank_volume_start: 33832.599999999984,
    tank_volume_stop: 33785.099999999984,
  },
  {
    timestamp_start: '2022-01-01T01:41:31',
    timestamp_stop: '2022-01-01T01:41:36',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 133252,
    tank_volume_start: 33785.099999999984,
    tank_volume_stop: 33730.999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:41:51',
    timestamp_stop: '2022-01-01T01:41:58',
    quantity: 62.6,
    vehicle: 'DEM222',
    id: 133254,
    tank_volume_start: 33730.999999999985,
    tank_volume_stop: 33668.39999999999,
  },
  {
    timestamp_start: '2022-01-01T01:42:13',
    timestamp_stop: '2022-01-01T01:42:17',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 133256,
    tank_volume_start: 33668.39999999999,
    tank_volume_stop: 33622.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:42:32',
    timestamp_stop: '2022-01-01T01:42:38',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 133258,
    tank_volume_start: 33622.599999999984,
    tank_volume_stop: 33568.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:42:53',
    timestamp_stop: '2022-01-01T01:42:58',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 133260,
    tank_volume_start: 33568.599999999984,
    tank_volume_stop: 33515.89999999999,
  },
  {
    timestamp_start: '2022-01-01T01:43:13',
    timestamp_stop: '2022-01-01T01:43:18',
    quantity: 53.5,
    vehicle: 'DEM777',
    id: 133262,
    tank_volume_start: 33515.89999999999,
    tank_volume_stop: 33462.39999999999,
  },
  {
    timestamp_start: '2022-01-01T01:43:33',
    timestamp_stop: '2022-01-01T01:43:38',
    quantity: 46.1,
    vehicle: 'DEM666',
    id: 133264,
    tank_volume_start: 33462.39999999999,
    tank_volume_stop: 33416.29999999999,
  },
  {
    timestamp_start: '2022-01-01T01:43:53',
    timestamp_stop: '2022-01-01T01:43:58',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 133266,
    tank_volume_start: 33416.29999999999,
    tank_volume_stop: 33361.999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:44:13',
    timestamp_stop: '2022-01-01T01:44:19',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 133268,
    tank_volume_start: 33361.999999999985,
    tank_volume_stop: 33304.099999999984,
  },
  {
    timestamp_start: '2022-01-01T01:44:34',
    timestamp_stop: '2022-01-01T01:44:40',
    quantity: 57.8,
    vehicle: 'DEM888',
    id: 133270,
    tank_volume_start: 33304.099999999984,
    tank_volume_stop: 33246.29999999998,
  },
  {
    timestamp_start: '2022-01-01T01:44:55',
    timestamp_stop: '2022-01-01T01:45:01',
    quantity: 58.6,
    vehicle: 'DEM000',
    id: 133272,
    tank_volume_start: 33246.29999999998,
    tank_volume_stop: 33187.69999999998,
  },
  {
    timestamp_start: '2022-01-01T01:45:16',
    timestamp_stop: '2022-01-01T01:45:21',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 133274,
    tank_volume_start: 33187.69999999998,
    tank_volume_stop: 33132.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:45:36',
    timestamp_stop: '2022-01-01T01:45:42',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 133276,
    tank_volume_start: 33132.39999999998,
    tank_volume_stop: 33076.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:45:57',
    timestamp_stop: '2022-01-01T01:46:03',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 133278,
    tank_volume_start: 33076.39999999998,
    tank_volume_stop: 33017.49999999998,
  },
  {
    timestamp_start: '2022-01-01T01:46:18',
    timestamp_stop: '2022-01-01T01:46:23',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 133280,
    tank_volume_start: 33017.49999999998,
    tank_volume_stop: 32963.29999999998,
  },
  {
    timestamp_start: '2022-01-01T01:46:38',
    timestamp_stop: '2022-01-01T01:46:43',
    quantity: 50.7,
    vehicle: 'DEM000',
    id: 133282,
    tank_volume_start: 32963.29999999998,
    tank_volume_stop: 32912.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:46:58',
    timestamp_stop: '2022-01-01T01:47:03',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 133284,
    tank_volume_start: 32912.599999999984,
    tank_volume_stop: 32861.89999999999,
  },
  {
    timestamp_start: '2022-01-01T01:47:18',
    timestamp_stop: '2022-01-01T01:47:24',
    quantity: 59.6,
    vehicle: 'DEM777',
    id: 133286,
    tank_volume_start: 32861.89999999999,
    tank_volume_stop: 32802.29999999999,
  },
  {
    timestamp_start: '2022-01-01T01:47:39',
    timestamp_stop: '2022-01-01T01:47:44',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 133288,
    tank_volume_start: 32802.29999999999,
    tank_volume_stop: 32750.399999999987,
  },
  {
    timestamp_start: '2022-01-01T01:47:59',
    timestamp_stop: '2022-01-01T01:48:05',
    quantity: 60.0,
    vehicle: 'DEM888',
    id: 133290,
    tank_volume_start: 32750.399999999987,
    tank_volume_stop: 32690.399999999987,
  },
  {
    timestamp_start: '2022-01-01T01:48:20',
    timestamp_stop: '2022-01-01T01:48:26',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 133292,
    tank_volume_start: 32690.399999999987,
    tank_volume_stop: 32636.599999999988,
  },
  {
    timestamp_start: '2022-01-01T01:48:41',
    timestamp_stop: '2022-01-01T01:48:46',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 133294,
    tank_volume_start: 32636.599999999988,
    tank_volume_stop: 32587.099999999988,
  },
  {
    timestamp_start: '2022-01-01T01:49:01',
    timestamp_stop: '2022-01-01T01:49:07',
    quantity: 58.9,
    vehicle: 'DEM888',
    id: 133296,
    tank_volume_start: 32587.099999999988,
    tank_volume_stop: 32528.199999999986,
  },
  {
    timestamp_start: '2022-01-01T01:49:22',
    timestamp_stop: '2022-01-01T01:49:28',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 133298,
    tank_volume_start: 32528.199999999986,
    tank_volume_stop: 32469.299999999985,
  },
  {
    timestamp_start: '2022-01-01T01:49:43',
    timestamp_stop: '2022-01-01T01:49:48',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 133300,
    tank_volume_start: 32469.299999999985,
    tank_volume_stop: 32415.199999999986,
  },
  {
    timestamp_start: '2022-01-01T01:50:03',
    timestamp_stop: '2022-01-01T01:50:08',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 133302,
    tank_volume_start: 32415.199999999986,
    tank_volume_stop: 32362.199999999986,
  },
  {
    timestamp_start: '2022-01-01T01:50:23',
    timestamp_stop: '2022-01-01T01:50:28',
    quantity: 47.4,
    vehicle: 'DEM444',
    id: 133304,
    tank_volume_start: 32362.199999999986,
    tank_volume_stop: 32314.799999999985,
  },
  {
    timestamp_start: '2022-01-01T01:50:43',
    timestamp_stop: '2022-01-01T01:50:48',
    quantity: 50.4,
    vehicle: 'DEM333',
    id: 133306,
    tank_volume_start: 32314.799999999985,
    tank_volume_stop: 32264.399999999983,
  },
  {
    timestamp_start: '2022-01-01T01:51:03',
    timestamp_stop: '2022-01-01T01:51:09',
    quantity: 63.4,
    vehicle: 'DEM222',
    id: 133308,
    tank_volume_start: 32264.399999999983,
    tank_volume_stop: 32200.99999999998,
  },
  {
    timestamp_start: '2022-01-01T01:51:24',
    timestamp_stop: '2022-01-01T01:51:30',
    quantity: 52.2,
    vehicle: 'DEM444',
    id: 133310,
    tank_volume_start: 32200.99999999998,
    tank_volume_stop: 32148.79999999998,
  },
  {
    timestamp_start: '2022-01-01T01:51:45',
    timestamp_stop: '2022-01-01T01:51:50',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 133312,
    tank_volume_start: 32148.79999999998,
    tank_volume_stop: 32091.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:52:05',
    timestamp_stop: '2022-01-01T01:52:11',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 133314,
    tank_volume_start: 32091.09999999998,
    tank_volume_stop: 32036.29999999998,
  },
  {
    timestamp_start: '2022-01-01T01:52:26',
    timestamp_stop: '2022-01-01T01:52:31',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 133316,
    tank_volume_start: 32036.29999999998,
    tank_volume_stop: 31981.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:52:46',
    timestamp_stop: '2022-01-01T01:52:52',
    quantity: 60.3,
    vehicle: 'DEM333',
    id: 133318,
    tank_volume_start: 31981.39999999998,
    tank_volume_stop: 31921.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:53:07',
    timestamp_stop: '2022-01-01T01:53:13',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 133320,
    tank_volume_start: 31921.09999999998,
    tank_volume_stop: 31862.79999999998,
  },
  {
    timestamp_start: '2022-01-01T01:53:28',
    timestamp_stop: '2022-01-01T01:53:34',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 133322,
    tank_volume_start: 31862.79999999998,
    tank_volume_stop: 31808.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:53:49',
    timestamp_stop: '2022-01-01T01:53:54',
    quantity: 57.2,
    vehicle: 'DEM111',
    id: 133324,
    tank_volume_start: 31808.39999999998,
    tank_volume_stop: 31751.19999999998,
  },
  {
    timestamp_start: '2022-01-01T01:54:09',
    timestamp_stop: '2022-01-01T01:54:15',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 133326,
    tank_volume_start: 31751.19999999998,
    tank_volume_stop: 31691.299999999977,
  },
  {
    timestamp_start: '2022-01-01T01:54:30',
    timestamp_stop: '2022-01-01T01:54:36',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 133328,
    tank_volume_start: 31691.299999999977,
    tank_volume_stop: 31636.499999999978,
  },
  {
    timestamp_start: '2022-01-01T01:54:51',
    timestamp_stop: '2022-01-01T01:54:57',
    quantity: 59.0,
    vehicle: 'DEM222',
    id: 133330,
    tank_volume_start: 31636.499999999978,
    tank_volume_stop: 31577.499999999978,
  },
  {
    timestamp_start: '2022-01-01T01:55:12',
    timestamp_stop: '2022-01-01T01:55:18',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 133332,
    tank_volume_start: 31577.499999999978,
    tank_volume_stop: 31517.19999999998,
  },
  {
    timestamp_start: '2022-01-01T01:55:33',
    timestamp_stop: '2022-01-01T01:55:38',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 133334,
    tank_volume_start: 31517.19999999998,
    tank_volume_stop: 31463.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:55:53',
    timestamp_stop: '2022-01-01T01:55:59',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 133336,
    tank_volume_start: 31463.09999999998,
    tank_volume_stop: 31405.39999999998,
  },
  {
    timestamp_start: '2022-01-01T01:56:14',
    timestamp_stop: '2022-01-01T01:56:19',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 133338,
    tank_volume_start: 31405.39999999998,
    tank_volume_stop: 31353.19999999998,
  },
  {
    timestamp_start: '2022-01-01T01:56:34',
    timestamp_stop: '2022-01-01T01:56:40',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 133340,
    tank_volume_start: 31353.19999999998,
    tank_volume_stop: 31297.09999999998,
  },
  {
    timestamp_start: '2022-01-01T01:56:55',
    timestamp_stop: '2022-01-01T01:57:01',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 133342,
    tank_volume_start: 31297.09999999998,
    tank_volume_stop: 31236.99999999998,
  },
  {
    timestamp_start: '2022-01-01T01:57:16',
    timestamp_stop: '2022-01-01T01:57:22',
    quantity: 58.5,
    vehicle: 'DEM000',
    id: 133344,
    tank_volume_start: 31236.99999999998,
    tank_volume_stop: 31178.49999999998,
  },
  {
    timestamp_start: '2022-01-01T01:57:37',
    timestamp_stop: '2022-01-01T01:57:42',
    quantity: 50.3,
    vehicle: 'DEM111',
    id: 133346,
    tank_volume_start: 31178.49999999998,
    tank_volume_stop: 31128.199999999983,
  },
  {
    timestamp_start: '2022-01-01T01:57:57',
    timestamp_stop: '2022-01-01T01:58:02',
    quantity: 53.6,
    vehicle: 'DEM777',
    id: 133348,
    tank_volume_start: 31128.199999999983,
    tank_volume_stop: 31074.599999999984,
  },
  {
    timestamp_start: '2022-01-01T01:58:17',
    timestamp_stop: '2022-01-01T01:58:23',
    quantity: 62.7,
    vehicle: 'DEM777',
    id: 133350,
    tank_volume_start: 31074.599999999984,
    tank_volume_stop: 31011.899999999983,
  },
  {
    timestamp_start: '2022-01-01T01:58:38',
    timestamp_stop: '2022-01-01T01:58:44',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 133352,
    tank_volume_start: 31011.899999999983,
    tank_volume_stop: 30957.399999999983,
  },
  {
    timestamp_start: '2022-01-01T01:58:59',
    timestamp_stop: '2022-01-01T01:59:05',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 133354,
    tank_volume_start: 30957.399999999983,
    tank_volume_stop: 30898.49999999998,
  },
  {
    timestamp_start: '2022-01-01T01:59:20',
    timestamp_stop: '2022-01-01T01:59:25',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 133356,
    tank_volume_start: 30898.49999999998,
    tank_volume_stop: 30843.199999999983,
  },
  {
    timestamp_start: '2022-01-01T01:59:40',
    timestamp_stop: '2022-01-01T01:59:46',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 133358,
    tank_volume_start: 30843.199999999983,
    tank_volume_stop: 30789.29999999998,
  },
  {
    timestamp_start: '2022-01-01T02:00:01',
    timestamp_stop: '2022-01-01T02:00:06',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 133360,
    tank_volume_start: 30789.29999999998,
    tank_volume_stop: 30736.39999999998,
  },
  {
    timestamp_start: '2022-01-01T02:00:21',
    timestamp_stop: '2022-01-01T02:00:26',
    quantity: 52.1,
    vehicle: 'DEM777',
    id: 133362,
    tank_volume_start: 30736.39999999998,
    tank_volume_stop: 30684.29999999998,
  },
  {
    timestamp_start: '2022-01-01T02:00:41',
    timestamp_stop: '2022-01-01T02:00:46',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 133364,
    tank_volume_start: 30684.29999999998,
    tank_volume_stop: 30631.89999999998,
  },
  {
    timestamp_start: '2022-01-01T02:01:01',
    timestamp_stop: '2022-01-01T02:01:07',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 133366,
    tank_volume_start: 30631.89999999998,
    tank_volume_stop: 30579.29999999998,
  },
  {
    timestamp_start: '2022-01-01T02:01:22',
    timestamp_stop: '2022-01-01T02:01:27',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 133368,
    tank_volume_start: 30579.29999999998,
    tank_volume_stop: 30524.99999999998,
  },
  {
    timestamp_start: '2022-01-01T02:01:42',
    timestamp_stop: '2022-01-01T02:01:47',
    quantity: 49.2,
    vehicle: 'DEM444',
    id: 133370,
    tank_volume_start: 30524.99999999998,
    tank_volume_stop: 30475.79999999998,
  },
  {
    timestamp_start: '2022-01-01T02:02:02',
    timestamp_stop: '2022-01-01T02:02:08',
    quantity: 60.8,
    vehicle: 'DEM444',
    id: 133372,
    tank_volume_start: 30475.79999999998,
    tank_volume_stop: 30414.99999999998,
  },
  {
    timestamp_start: '2022-01-01T02:02:23',
    timestamp_stop: '2022-01-01T02:02:28',
    quantity: 45.8,
    vehicle: 'DEM000',
    id: 133374,
    tank_volume_start: 30414.99999999998,
    tank_volume_stop: 30369.199999999983,
  },
  {
    timestamp_start: '2022-01-01T02:02:43',
    timestamp_stop: '2022-01-01T02:02:48',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 133376,
    tank_volume_start: 30369.199999999983,
    tank_volume_stop: 30312.099999999984,
  },
  {
    timestamp_start: '2022-01-01T02:03:03',
    timestamp_stop: '2022-01-01T02:03:08',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 133378,
    tank_volume_start: 30312.099999999984,
    tank_volume_stop: 30260.199999999983,
  },
  {
    timestamp_start: '2022-01-01T02:03:23',
    timestamp_stop: '2022-01-01T02:03:29',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 133380,
    tank_volume_start: 30260.199999999983,
    tank_volume_stop: 30209.099999999984,
  },
  {
    timestamp_start: '2022-01-01T02:03:44',
    timestamp_stop: '2022-01-01T02:03:49',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 133382,
    tank_volume_start: 30209.099999999984,
    tank_volume_stop: 30155.099999999984,
  },
  {
    timestamp_start: '2022-01-01T02:04:04',
    timestamp_stop: '2022-01-01T02:04:09',
    quantity: 46.4,
    vehicle: 'DEM888',
    id: 133384,
    tank_volume_start: 30155.099999999984,
    tank_volume_stop: 30108.699999999983,
  },
  {
    timestamp_start: '2022-01-01T02:04:24',
    timestamp_stop: '2022-01-01T02:04:29',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 133386,
    tank_volume_start: 30108.699999999983,
    tank_volume_stop: 30053.79999999998,
  },
  {
    timestamp_start: '2022-01-01T02:04:44',
    timestamp_stop: '2022-01-01T02:04:50',
    quantity: 55.4,
    vehicle: 'DEM777',
    id: 133388,
    tank_volume_start: 30053.79999999998,
    tank_volume_stop: 29998.39999999998,
  },
  {
    timestamp_start: '2022-01-01T02:05:05',
    timestamp_stop: '2022-01-01T02:05:10',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 133390,
    tank_volume_start: 29998.39999999998,
    tank_volume_stop: 29943.499999999978,
  },
  {
    timestamp_start: '2022-01-01T02:05:25',
    timestamp_stop: '2022-01-01T02:05:30',
    quantity: 47.9,
    vehicle: 'DEM000',
    id: 133392,
    tank_volume_start: 29943.499999999978,
    tank_volume_stop: 29895.599999999977,
  },
  {
    timestamp_start: '2022-01-01T02:05:45',
    timestamp_stop: '2022-01-01T02:05:50',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 133394,
    tank_volume_start: 29895.599999999977,
    tank_volume_stop: 29841.599999999977,
  },
  {
    timestamp_start: '2022-01-01T02:06:05',
    timestamp_stop: '2022-01-01T02:06:10',
    quantity: 48.6,
    vehicle: 'DEM111',
    id: 133396,
    tank_volume_start: 29841.599999999977,
    tank_volume_stop: 29792.999999999978,
  },
  {
    timestamp_start: '2022-01-01T02:06:25',
    timestamp_stop: '2022-01-01T02:06:30',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 133398,
    tank_volume_start: 29792.999999999978,
    tank_volume_stop: 29740.099999999977,
  },
  {
    timestamp_start: '2022-01-01T02:06:45',
    timestamp_stop: '2022-01-01T02:06:51',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 133400,
    tank_volume_start: 29740.099999999977,
    tank_volume_stop: 29685.599999999977,
  },
  {
    timestamp_start: '2022-01-01T02:07:06',
    timestamp_stop: '2022-01-01T02:07:12',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 133402,
    tank_volume_start: 29685.599999999977,
    tank_volume_stop: 29629.099999999977,
  },
  {
    timestamp_start: '2022-01-01T02:07:27',
    timestamp_stop: '2022-01-01T02:07:32',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 133404,
    tank_volume_start: 29629.099999999977,
    tank_volume_stop: 29575.899999999976,
  },
  {
    timestamp_start: '2022-01-01T02:07:47',
    timestamp_stop: '2022-01-01T02:07:53',
    quantity: 59.7,
    vehicle: 'DEM111',
    id: 133406,
    tank_volume_start: 29575.899999999976,
    tank_volume_stop: 29516.199999999975,
  },
  {
    timestamp_start: '2022-01-01T02:08:08',
    timestamp_stop: '2022-01-01T02:08:14',
    quantity: 60.3,
    vehicle: 'DEM111',
    id: 133408,
    tank_volume_start: 29516.199999999975,
    tank_volume_stop: 29455.899999999976,
  },
  {
    timestamp_start: '2022-01-01T02:08:29',
    timestamp_stop: '2022-01-01T02:08:35',
    quantity: 62.7,
    vehicle: 'DEM222',
    id: 133410,
    tank_volume_start: 29455.899999999976,
    tank_volume_stop: 29393.199999999975,
  },
  {
    timestamp_start: '2022-01-01T02:08:50',
    timestamp_stop: '2022-01-01T02:08:55',
    quantity: 49.9,
    vehicle: 'DEM222',
    id: 133412,
    tank_volume_start: 29393.199999999975,
    tank_volume_stop: 29343.299999999974,
  },
  {
    timestamp_start: '2022-01-01T02:09:10',
    timestamp_stop: '2022-01-01T02:09:16',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 133414,
    tank_volume_start: 29343.299999999974,
    tank_volume_stop: 29287.299999999974,
  },
  {
    timestamp_start: '2022-01-01T02:09:31',
    timestamp_stop: '2022-01-01T02:09:36',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 133416,
    tank_volume_start: 29287.299999999974,
    tank_volume_stop: 29231.799999999974,
  },
  {
    timestamp_start: '2022-01-01T02:09:51',
    timestamp_stop: '2022-01-01T02:09:57',
    quantity: 59.8,
    vehicle: 'DEM555',
    id: 133418,
    tank_volume_start: 29231.799999999974,
    tank_volume_stop: 29171.999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:10:12',
    timestamp_stop: '2022-01-01T02:10:18',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 133420,
    tank_volume_start: 29171.999999999975,
    tank_volume_stop: 29117.699999999975,
  },
  {
    timestamp_start: '2022-01-01T02:10:33',
    timestamp_stop: '2022-01-01T02:10:38',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 133422,
    tank_volume_start: 29117.699999999975,
    tank_volume_stop: 29064.599999999977,
  },
  {
    timestamp_start: '2022-01-01T02:10:53',
    timestamp_stop: '2022-01-01T02:10:58',
    quantity: 52.2,
    vehicle: 'DEM222',
    id: 133424,
    tank_volume_start: 29064.599999999977,
    tank_volume_stop: 29012.399999999976,
  },
  {
    timestamp_start: '2022-01-01T02:11:13',
    timestamp_stop: '2022-01-01T02:11:19',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 133426,
    tank_volume_start: 29012.399999999976,
    tank_volume_stop: 28955.999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:11:34',
    timestamp_stop: '2022-01-01T02:11:39',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 133428,
    tank_volume_start: 28955.999999999975,
    tank_volume_stop: 28905.099999999973,
  },
  {
    timestamp_start: '2022-01-01T02:11:54',
    timestamp_stop: '2022-01-01T02:12:00',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 133430,
    tank_volume_start: 28905.099999999973,
    tank_volume_stop: 28845.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:12:15',
    timestamp_stop: '2022-01-01T02:12:21',
    quantity: 62.9,
    vehicle: 'DEM333',
    id: 133432,
    tank_volume_start: 28845.599999999973,
    tank_volume_stop: 28782.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:12:36',
    timestamp_stop: '2022-01-01T02:12:41',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 133434,
    tank_volume_start: 28782.69999999997,
    tank_volume_stop: 28731.99999999997,
  },
  {
    timestamp_start: '2022-01-01T02:12:56',
    timestamp_stop: '2022-01-01T02:13:02',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 133436,
    tank_volume_start: 28731.99999999997,
    tank_volume_stop: 28678.29999999997,
  },
  {
    timestamp_start: '2022-01-01T02:13:17',
    timestamp_stop: '2022-01-01T02:13:23',
    quantity: 64.8,
    vehicle: 'DEM000',
    id: 133438,
    tank_volume_start: 28678.29999999997,
    tank_volume_stop: 28613.49999999997,
  },
  {
    timestamp_start: '2022-01-01T02:13:38',
    timestamp_stop: '2022-01-01T02:13:43',
    quantity: 48.1,
    vehicle: 'DEM000',
    id: 133440,
    tank_volume_start: 28613.49999999997,
    tank_volume_stop: 28565.399999999972,
  },
  {
    timestamp_start: '2022-01-01T02:13:58',
    timestamp_stop: '2022-01-01T02:14:04',
    quantity: 60.8,
    vehicle: 'DEM111',
    id: 133442,
    tank_volume_start: 28565.399999999972,
    tank_volume_stop: 28504.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:14:19',
    timestamp_stop: '2022-01-01T02:14:24',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 133444,
    tank_volume_start: 28504.599999999973,
    tank_volume_stop: 28450.19999999997,
  },
  {
    timestamp_start: '2022-01-01T02:14:39',
    timestamp_stop: '2022-01-01T02:14:45',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 133446,
    tank_volume_start: 28450.19999999997,
    tank_volume_stop: 28397.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:15:00',
    timestamp_stop: '2022-01-01T02:15:05',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 133448,
    tank_volume_start: 28397.599999999973,
    tank_volume_stop: 28340.299999999974,
  },
  {
    timestamp_start: '2022-01-01T02:15:20',
    timestamp_stop: '2022-01-01T02:15:26',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 133450,
    tank_volume_start: 28340.299999999974,
    tank_volume_stop: 28288.999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:15:41',
    timestamp_stop: '2022-01-01T02:15:47',
    quantity: 60.7,
    vehicle: 'DEM222',
    id: 133452,
    tank_volume_start: 28288.999999999975,
    tank_volume_stop: 28228.299999999974,
  },
  {
    timestamp_start: '2022-01-01T02:16:02',
    timestamp_stop: '2022-01-01T02:16:07',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 133454,
    tank_volume_start: 28228.299999999974,
    tank_volume_stop: 28174.099999999973,
  },
  {
    timestamp_start: '2022-01-01T02:16:22',
    timestamp_stop: '2022-01-01T02:16:28',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 133456,
    tank_volume_start: 28174.099999999973,
    tank_volume_stop: 28118.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:16:43',
    timestamp_stop: '2022-01-01T02:16:48',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 133458,
    tank_volume_start: 28118.599999999973,
    tank_volume_stop: 28062.19999999997,
  },
  {
    timestamp_start: '2022-01-01T02:17:03',
    timestamp_stop: '2022-01-01T02:17:10',
    quantity: 63.5,
    vehicle: 'DEM444',
    id: 133460,
    tank_volume_start: 28062.19999999997,
    tank_volume_stop: 27998.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:17:25',
    timestamp_stop: '2022-01-01T02:17:30',
    quantity: 58.3,
    vehicle: 'DEM666',
    id: 133462,
    tank_volume_start: 27998.69999999997,
    tank_volume_stop: 27940.399999999972,
  },
  {
    timestamp_start: '2022-01-01T02:17:45',
    timestamp_stop: '2022-01-01T02:17:51',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 133464,
    tank_volume_start: 27940.399999999972,
    tank_volume_stop: 27884.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:18:06',
    timestamp_stop: '2022-01-01T02:18:11',
    quantity: 48.7,
    vehicle: 'DEM222',
    id: 133466,
    tank_volume_start: 27884.599999999973,
    tank_volume_stop: 27835.899999999972,
  },
  {
    timestamp_start: '2022-01-01T02:18:26',
    timestamp_stop: '2022-01-01T02:18:32',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 133468,
    tank_volume_start: 27835.899999999972,
    tank_volume_stop: 27779.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:18:47',
    timestamp_stop: '2022-01-01T02:18:52',
    quantity: 53.8,
    vehicle: 'DEM000',
    id: 133470,
    tank_volume_start: 27779.599999999973,
    tank_volume_stop: 27725.799999999974,
  },
  {
    timestamp_start: '2022-01-01T02:19:07',
    timestamp_stop: '2022-01-01T02:19:13',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 133472,
    tank_volume_start: 27725.799999999974,
    tank_volume_stop: 27664.799999999974,
  },
  {
    timestamp_start: '2022-01-01T02:19:28',
    timestamp_stop: '2022-01-01T02:19:33',
    quantity: 45.6,
    vehicle: 'DEM888',
    id: 133474,
    tank_volume_start: 27664.799999999974,
    tank_volume_stop: 27619.199999999975,
  },
  {
    timestamp_start: '2022-01-01T02:19:48',
    timestamp_stop: '2022-01-01T02:19:53',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 133476,
    tank_volume_start: 27619.199999999975,
    tank_volume_stop: 27565.599999999977,
  },
  {
    timestamp_start: '2022-01-01T02:20:08',
    timestamp_stop: '2022-01-01T02:20:13',
    quantity: 50.9,
    vehicle: 'DEM222',
    id: 133478,
    tank_volume_start: 27565.599999999977,
    tank_volume_stop: 27514.699999999975,
  },
  {
    timestamp_start: '2022-01-01T02:20:28',
    timestamp_stop: '2022-01-01T02:20:33',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 133480,
    tank_volume_start: 27514.699999999975,
    tank_volume_stop: 27462.399999999976,
  },
  {
    timestamp_start: '2022-01-01T02:20:48',
    timestamp_stop: '2022-01-01T02:20:54',
    quantity: 54.2,
    vehicle: 'DEM222',
    id: 133482,
    tank_volume_start: 27462.399999999976,
    tank_volume_stop: 27408.199999999975,
  },
  {
    timestamp_start: '2022-01-01T02:21:09',
    timestamp_stop: '2022-01-01T02:21:14',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 133484,
    tank_volume_start: 27408.199999999975,
    tank_volume_stop: 27351.399999999976,
  },
  {
    timestamp_start: '2022-01-01T02:21:29',
    timestamp_stop: '2022-01-01T02:21:36',
    quantity: 63.3,
    vehicle: 'DEM777',
    id: 133486,
    tank_volume_start: 27351.399999999976,
    tank_volume_stop: 27288.099999999977,
  },
  {
    timestamp_start: '2022-01-01T02:21:51',
    timestamp_stop: '2022-01-01T02:21:56',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 133488,
    tank_volume_start: 27288.099999999977,
    tank_volume_stop: 27236.699999999975,
  },
  {
    timestamp_start: '2022-01-01T02:22:11',
    timestamp_stop: '2022-01-01T02:22:16',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 133490,
    tank_volume_start: 27236.699999999975,
    tank_volume_stop: 27184.199999999975,
  },
  {
    timestamp_start: '2022-01-01T02:22:31',
    timestamp_stop: '2022-01-01T02:22:36',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 133492,
    tank_volume_start: 27184.199999999975,
    tank_volume_stop: 27130.799999999974,
  },
  {
    timestamp_start: '2022-01-01T02:22:51',
    timestamp_stop: '2022-01-01T02:22:56',
    quantity: 45.9,
    vehicle: 'DEM222',
    id: 133494,
    tank_volume_start: 27130.799999999974,
    tank_volume_stop: 27084.899999999972,
  },
  {
    timestamp_start: '2022-01-01T02:23:11',
    timestamp_stop: '2022-01-01T02:23:17',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 133496,
    tank_volume_start: 27084.899999999972,
    tank_volume_stop: 27023.299999999974,
  },
  {
    timestamp_start: '2022-01-01T02:23:32',
    timestamp_stop: '2022-01-01T02:23:37',
    quantity: 47.4,
    vehicle: 'DEM333',
    id: 133498,
    tank_volume_start: 27023.299999999974,
    tank_volume_stop: 26975.899999999972,
  },
  {
    timestamp_start: '2022-01-01T02:23:52',
    timestamp_stop: '2022-01-01T02:23:58',
    quantity: 63.3,
    vehicle: 'DEM555',
    id: 133500,
    tank_volume_start: 26975.899999999972,
    tank_volume_stop: 26912.599999999973,
  },
  {
    timestamp_start: '2022-01-01T02:24:13',
    timestamp_stop: '2022-01-01T02:24:19',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 133502,
    tank_volume_start: 26912.599999999973,
    tank_volume_stop: 26857.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:24:34',
    timestamp_stop: '2022-01-01T02:24:39',
    quantity: 53.5,
    vehicle: 'DEM888',
    id: 133504,
    tank_volume_start: 26857.69999999997,
    tank_volume_stop: 26804.19999999997,
  },
  {
    timestamp_start: '2022-01-01T02:24:54',
    timestamp_stop: '2022-01-01T02:24:59',
    quantity: 50.2,
    vehicle: 'DEM111',
    id: 133506,
    tank_volume_start: 26804.19999999997,
    tank_volume_stop: 26753.99999999997,
  },
  {
    timestamp_start: '2022-01-01T02:25:14',
    timestamp_stop: '2022-01-01T02:25:19',
    quantity: 49.7,
    vehicle: 'DEM555',
    id: 133508,
    tank_volume_start: 26753.99999999997,
    tank_volume_stop: 26704.29999999997,
  },
  {
    timestamp_start: '2022-01-01T02:25:34',
    timestamp_stop: '2022-01-01T02:25:40',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 133510,
    tank_volume_start: 26704.29999999997,
    tank_volume_stop: 26646.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:25:55',
    timestamp_stop: '2022-01-01T02:26:00',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 133512,
    tank_volume_start: 26646.69999999997,
    tank_volume_stop: 26590.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:26:15',
    timestamp_stop: '2022-01-01T02:26:21',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 133514,
    tank_volume_start: 26590.69999999997,
    tank_volume_stop: 26537.49999999997,
  },
  {
    timestamp_start: '2022-01-01T02:26:36',
    timestamp_stop: '2022-01-01T02:26:42',
    quantity: 62.5,
    vehicle: 'DEM000',
    id: 133516,
    tank_volume_start: 26537.49999999997,
    tank_volume_stop: 26474.99999999997,
  },
  {
    timestamp_start: '2022-01-01T02:26:57',
    timestamp_stop: '2022-01-01T02:27:03',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 133518,
    tank_volume_start: 26474.99999999997,
    tank_volume_stop: 26418.899999999972,
  },
  {
    timestamp_start: '2022-01-01T02:27:18',
    timestamp_stop: '2022-01-01T02:27:24',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 133520,
    tank_volume_start: 26418.899999999972,
    tank_volume_stop: 26358.69999999997,
  },
  {
    timestamp_start: '2022-01-01T02:27:39',
    timestamp_stop: '2022-01-01T02:27:44',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 133522,
    tank_volume_start: 26358.69999999997,
    tank_volume_stop: 26304.29999999997,
  },
  {
    timestamp_start: '2022-01-01T02:27:59',
    timestamp_stop: '2022-01-01T02:28:04',
    quantity: 53.5,
    vehicle: 'DEM777',
    id: 133524,
    tank_volume_start: 26304.29999999997,
    tank_volume_stop: 26250.79999999997,
  },
  {
    timestamp_start: '2022-01-01T02:28:19',
    timestamp_stop: '2022-01-01T02:28:25',
    quantity: 50.9,
    vehicle: 'DEM333',
    id: 133526,
    tank_volume_start: 26250.79999999997,
    tank_volume_stop: 26199.89999999997,
  },
  {
    timestamp_start: '2022-01-01T02:28:40',
    timestamp_stop: '2022-01-01T02:28:45',
    quantity: 50.7,
    vehicle: 'DEM777',
    id: 133528,
    tank_volume_start: 26199.89999999997,
    tank_volume_stop: 26149.199999999968,
  },
  {
    timestamp_start: '2022-01-01T02:29:00',
    timestamp_stop: '2022-01-01T02:29:05',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 133530,
    tank_volume_start: 26149.199999999968,
    tank_volume_stop: 26092.39999999997,
  },
  {
    timestamp_start: '2022-01-01T02:29:20',
    timestamp_stop: '2022-01-01T02:29:25',
    quantity: 51.5,
    vehicle: 'DEM444',
    id: 133532,
    tank_volume_start: 26092.39999999997,
    tank_volume_stop: 26040.89999999997,
  },
  {
    timestamp_start: '2022-01-01T02:29:40',
    timestamp_stop: '2022-01-01T02:29:46',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 133534,
    tank_volume_start: 26040.89999999997,
    tank_volume_stop: 25983.89999999997,
  },
  {
    timestamp_start: '2022-01-01T02:30:01',
    timestamp_stop: '2022-01-01T02:30:06',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 133536,
    tank_volume_start: 25983.89999999997,
    tank_volume_stop: 25930.999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:30:21',
    timestamp_stop: '2022-01-01T02:30:27',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 133538,
    tank_volume_start: 25930.999999999967,
    tank_volume_stop: 25877.099999999966,
  },
  {
    timestamp_start: '2022-01-01T02:30:42',
    timestamp_stop: '2022-01-01T02:30:47',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 133540,
    tank_volume_start: 25877.099999999966,
    tank_volume_stop: 25820.499999999967,
  },
  {
    timestamp_start: '2022-01-01T02:31:02',
    timestamp_stop: '2022-01-01T02:31:08',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 133542,
    tank_volume_start: 25820.499999999967,
    tank_volume_stop: 25766.499999999967,
  },
  {
    timestamp_start: '2022-01-01T02:31:23',
    timestamp_stop: '2022-01-01T02:31:28',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 133544,
    tank_volume_start: 25766.499999999967,
    tank_volume_stop: 25715.299999999967,
  },
  {
    timestamp_start: '2022-01-01T02:31:43',
    timestamp_stop: '2022-01-01T02:31:49',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 133546,
    tank_volume_start: 25715.299999999967,
    tank_volume_stop: 25657.299999999967,
  },
  {
    timestamp_start: '2022-01-01T02:32:04',
    timestamp_stop: '2022-01-01T02:32:09',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 133548,
    tank_volume_start: 25657.299999999967,
    tank_volume_stop: 25600.999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:32:24',
    timestamp_stop: '2022-01-01T02:32:29',
    quantity: 48.4,
    vehicle: 'DEM000',
    id: 133550,
    tank_volume_start: 25600.999999999967,
    tank_volume_stop: 25552.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:32:44',
    timestamp_stop: '2022-01-01T02:32:50',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 133552,
    tank_volume_start: 25552.599999999966,
    tank_volume_stop: 25497.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:33:05',
    timestamp_stop: '2022-01-01T02:33:10',
    quantity: 50.0,
    vehicle: 'DEM555',
    id: 133554,
    tank_volume_start: 25497.599999999966,
    tank_volume_stop: 25447.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:33:25',
    timestamp_stop: '2022-01-01T02:33:31',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 133556,
    tank_volume_start: 25447.599999999966,
    tank_volume_stop: 25389.499999999967,
  },
  {
    timestamp_start: '2022-01-01T02:33:46',
    timestamp_stop: '2022-01-01T02:33:52',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 133558,
    tank_volume_start: 25389.499999999967,
    tank_volume_stop: 25329.999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:34:07',
    timestamp_stop: '2022-01-01T02:34:12',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 133560,
    tank_volume_start: 25329.999999999967,
    tank_volume_stop: 25277.799999999967,
  },
  {
    timestamp_start: '2022-01-01T02:34:27',
    timestamp_stop: '2022-01-01T02:34:32',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 133562,
    tank_volume_start: 25277.799999999967,
    tank_volume_stop: 25224.399999999965,
  },
  {
    timestamp_start: '2022-01-01T02:34:47',
    timestamp_stop: '2022-01-01T02:34:53',
    quantity: 64.1,
    vehicle: 'DEM888',
    id: 133564,
    tank_volume_start: 25224.399999999965,
    tank_volume_stop: 25160.299999999967,
  },
  {
    timestamp_start: '2022-01-01T02:35:08',
    timestamp_stop: '2022-01-01T02:35:15',
    quantity: 60.4,
    vehicle: 'DEM333',
    id: 133566,
    tank_volume_start: 25160.299999999967,
    tank_volume_stop: 25099.899999999965,
  },
  {
    timestamp_start: '2022-01-01T02:35:30',
    timestamp_stop: '2022-01-01T02:35:35',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 133568,
    tank_volume_start: 25099.899999999965,
    tank_volume_stop: 25041.499999999964,
  },
  {
    timestamp_start: '2022-01-01T02:35:50',
    timestamp_stop: '2022-01-01T02:35:56',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 133570,
    tank_volume_start: 25041.499999999964,
    tank_volume_stop: 24987.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:36:11',
    timestamp_stop: '2022-01-01T02:36:16',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 133572,
    tank_volume_start: 24987.199999999964,
    tank_volume_stop: 24932.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:36:31',
    timestamp_stop: '2022-01-01T02:36:37',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 133574,
    tank_volume_start: 24932.599999999966,
    tank_volume_stop: 24874.399999999965,
  },
  {
    timestamp_start: '2022-01-01T02:36:52',
    timestamp_stop: '2022-01-01T02:36:59',
    quantity: 64.8,
    vehicle: 'DEM888',
    id: 133576,
    tank_volume_start: 24874.399999999965,
    tank_volume_stop: 24809.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:37:14',
    timestamp_stop: '2022-01-01T02:37:19',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 133578,
    tank_volume_start: 24809.599999999966,
    tank_volume_stop: 24759.699999999964,
  },
  {
    timestamp_start: '2022-01-01T02:37:34',
    timestamp_stop: '2022-01-01T02:37:39',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 133580,
    tank_volume_start: 24759.699999999964,
    tank_volume_stop: 24704.099999999966,
  },
  {
    timestamp_start: '2022-01-01T02:37:54',
    timestamp_stop: '2022-01-01T02:37:59',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 133582,
    tank_volume_start: 24704.099999999966,
    tank_volume_stop: 24654.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:38:14',
    timestamp_stop: '2022-01-01T02:38:19',
    quantity: 49.7,
    vehicle: 'DEM555',
    id: 133584,
    tank_volume_start: 24654.599999999966,
    tank_volume_stop: 24604.899999999965,
  },
  {
    timestamp_start: '2022-01-01T02:38:34',
    timestamp_stop: '2022-01-01T02:38:39',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 133586,
    tank_volume_start: 24604.899999999965,
    tank_volume_stop: 24550.899999999965,
  },
  {
    timestamp_start: '2022-01-01T02:38:54',
    timestamp_stop: '2022-01-01T02:39:00',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 133588,
    tank_volume_start: 24550.899999999965,
    tank_volume_stop: 24498.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:39:15',
    timestamp_stop: '2022-01-01T02:39:20',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 133590,
    tank_volume_start: 24498.199999999964,
    tank_volume_stop: 24443.399999999965,
  },
  {
    timestamp_start: '2022-01-01T02:39:35',
    timestamp_stop: '2022-01-01T02:39:40',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 133592,
    tank_volume_start: 24443.399999999965,
    tank_volume_stop: 24393.399999999965,
  },
  {
    timestamp_start: '2022-01-01T02:39:55',
    timestamp_stop: '2022-01-01T02:40:00',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 133594,
    tank_volume_start: 24393.399999999965,
    tank_volume_stop: 24342.799999999967,
  },
  {
    timestamp_start: '2022-01-01T02:40:15',
    timestamp_stop: '2022-01-01T02:40:21',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 133596,
    tank_volume_start: 24342.799999999967,
    tank_volume_stop: 24288.099999999966,
  },
  {
    timestamp_start: '2022-01-01T02:40:36',
    timestamp_stop: '2022-01-01T02:40:41',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 133598,
    tank_volume_start: 24288.099999999966,
    tank_volume_stop: 24235.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:40:56',
    timestamp_stop: '2022-01-01T02:41:02',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 133600,
    tank_volume_start: 24235.199999999964,
    tank_volume_stop: 24177.099999999966,
  },
  {
    timestamp_start: '2022-01-01T02:41:17',
    timestamp_stop: '2022-01-01T02:41:21',
    quantity: 46.9,
    vehicle: 'DEM333',
    id: 133602,
    tank_volume_start: 24177.099999999966,
    tank_volume_stop: 24130.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:41:36',
    timestamp_stop: '2022-01-01T02:41:43',
    quantity: 60.9,
    vehicle: 'DEM000',
    id: 133604,
    tank_volume_start: 24130.199999999964,
    tank_volume_stop: 24069.299999999963,
  },
  {
    timestamp_start: '2022-01-01T02:41:58',
    timestamp_stop: '2022-01-01T02:42:03',
    quantity: 52.5,
    vehicle: 'DEM888',
    id: 133606,
    tank_volume_start: 24069.299999999963,
    tank_volume_stop: 24016.799999999963,
  },
  {
    timestamp_start: '2022-01-01T02:42:18',
    timestamp_stop: '2022-01-01T02:42:23',
    quantity: 56.3,
    vehicle: 'DEM555',
    id: 133608,
    tank_volume_start: 24016.799999999963,
    tank_volume_stop: 23960.499999999964,
  },
  {
    timestamp_start: '2022-01-01T02:42:38',
    timestamp_stop: '2022-01-01T02:42:44',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 133610,
    tank_volume_start: 23960.499999999964,
    tank_volume_stop: 23905.499999999964,
  },
  {
    timestamp_start: '2022-01-01T02:42:59',
    timestamp_stop: '2022-01-01T02:43:04',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 133612,
    tank_volume_start: 23905.499999999964,
    tank_volume_stop: 23854.499999999964,
  },
  {
    timestamp_start: '2022-01-01T02:43:19',
    timestamp_stop: '2022-01-01T02:43:24',
    quantity: 49.7,
    vehicle: 'DEM333',
    id: 133614,
    tank_volume_start: 23854.499999999964,
    tank_volume_stop: 23804.799999999963,
  },
  {
    timestamp_start: '2022-01-01T02:43:39',
    timestamp_stop: '2022-01-01T02:43:44',
    quantity: 47.7,
    vehicle: 'DEM000',
    id: 133616,
    tank_volume_start: 23804.799999999963,
    tank_volume_stop: 23757.099999999962,
  },
  {
    timestamp_start: '2022-01-01T02:43:59',
    timestamp_stop: '2022-01-01T02:44:04',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 133618,
    tank_volume_start: 23757.099999999962,
    tank_volume_stop: 23701.89999999996,
  },
  {
    timestamp_start: '2022-01-01T02:44:19',
    timestamp_stop: '2022-01-01T02:44:25',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 133620,
    tank_volume_start: 23701.89999999996,
    tank_volume_stop: 23647.599999999962,
  },
  {
    timestamp_start: '2022-01-01T02:44:40',
    timestamp_stop: '2022-01-01T02:44:46',
    quantity: 59.0,
    vehicle: 'DEM222',
    id: 133622,
    tank_volume_start: 23647.599999999962,
    tank_volume_stop: 23588.599999999962,
  },
  {
    timestamp_start: '2022-01-01T02:45:01',
    timestamp_stop: '2022-01-01T02:45:05',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 133624,
    tank_volume_start: 23588.599999999962,
    tank_volume_stop: 23540.599999999962,
  },
  {
    timestamp_start: '2022-01-01T02:45:20',
    timestamp_stop: '2022-01-01T02:45:26',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 133626,
    tank_volume_start: 23540.599999999962,
    tank_volume_stop: 23488.099999999962,
  },
  {
    timestamp_start: '2022-01-01T02:45:41',
    timestamp_stop: '2022-01-01T02:45:47',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 133628,
    tank_volume_start: 23488.099999999962,
    tank_volume_stop: 23427.599999999962,
  },
  {
    timestamp_start: '2022-01-01T02:46:02',
    timestamp_stop: '2022-01-01T02:46:07',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 133630,
    tank_volume_start: 23427.599999999962,
    tank_volume_stop: 23370.799999999963,
  },
  {
    timestamp_start: '2022-01-01T02:46:22',
    timestamp_stop: '2022-01-01T02:46:28',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 133632,
    tank_volume_start: 23370.799999999963,
    tank_volume_stop: 23319.099999999962,
  },
  {
    timestamp_start: '2022-01-01T02:46:43',
    timestamp_stop: '2022-01-01T02:46:48',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 133634,
    tank_volume_start: 23319.099999999962,
    tank_volume_stop: 23263.999999999964,
  },
  {
    timestamp_start: '2022-01-01T02:47:03',
    timestamp_stop: '2022-01-01T02:47:09',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 133636,
    tank_volume_start: 23263.999999999964,
    tank_volume_stop: 23207.699999999964,
  },
  {
    timestamp_start: '2022-01-01T02:47:24',
    timestamp_stop: '2022-01-01T02:47:30',
    quantity: 63.4,
    vehicle: 'DEM222',
    id: 133638,
    tank_volume_start: 23207.699999999964,
    tank_volume_stop: 23144.299999999963,
  },
  {
    timestamp_start: '2022-01-01T02:47:45',
    timestamp_stop: '2022-01-01T02:47:50',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 133640,
    tank_volume_start: 23144.299999999963,
    tank_volume_stop: 23091.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:48:05',
    timestamp_stop: '2022-01-01T02:48:11',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 133642,
    tank_volume_start: 23091.199999999964,
    tank_volume_stop: 23034.799999999963,
  },
  {
    timestamp_start: '2022-01-01T02:48:26',
    timestamp_stop: '2022-01-01T02:48:32',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 133644,
    tank_volume_start: 23034.799999999963,
    tank_volume_stop: 22978.39999999996,
  },
  {
    timestamp_start: '2022-01-01T02:48:47',
    timestamp_stop: '2022-01-01T02:48:53',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 133646,
    tank_volume_start: 22978.39999999996,
    tank_volume_stop: 22918.99999999996,
  },
  {
    timestamp_start: '2022-01-01T02:49:08',
    timestamp_stop: '2022-01-01T02:49:13',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 133648,
    tank_volume_start: 22918.99999999996,
    tank_volume_stop: 22866.79999999996,
  },
  {
    timestamp_start: '2022-01-01T02:49:28',
    timestamp_stop: '2022-01-01T02:49:33',
    quantity: 47.6,
    vehicle: 'DEM444',
    id: 133650,
    tank_volume_start: 22866.79999999996,
    tank_volume_stop: 22819.19999999996,
  },
  {
    timestamp_start: '2022-01-01T02:49:48',
    timestamp_stop: '2022-01-01T02:49:53',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 133652,
    tank_volume_start: 22819.19999999996,
    tank_volume_stop: 22767.19999999996,
  },
  {
    timestamp_start: '2022-01-01T02:50:08',
    timestamp_stop: '2022-01-01T02:50:14',
    quantity: 61.3,
    vehicle: 'DEM000',
    id: 133654,
    tank_volume_start: 22767.19999999996,
    tank_volume_stop: 22705.89999999996,
  },
  {
    timestamp_start: '2022-01-01T02:50:29',
    timestamp_stop: '2022-01-01T02:50:34',
    quantity: 46.6,
    vehicle: 'DEM888',
    id: 133656,
    tank_volume_start: 22705.89999999996,
    tank_volume_stop: 22659.299999999963,
  },
  {
    timestamp_start: '2022-01-01T02:50:49',
    timestamp_stop: '2022-01-01T02:50:55',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 133658,
    tank_volume_start: 22659.299999999963,
    tank_volume_stop: 22595.699999999964,
  },
  {
    timestamp_start: '2022-01-01T02:51:10',
    timestamp_stop: '2022-01-01T02:51:15',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 133660,
    tank_volume_start: 22595.699999999964,
    tank_volume_stop: 22540.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:51:30',
    timestamp_stop: '2022-01-01T02:51:36',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 133662,
    tank_volume_start: 22540.199999999964,
    tank_volume_stop: 22487.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:51:51',
    timestamp_stop: '2022-01-01T02:51:57',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 133664,
    tank_volume_start: 22487.199999999964,
    tank_volume_stop: 22427.799999999963,
  },
  {
    timestamp_start: '2022-01-01T02:52:12',
    timestamp_stop: '2022-01-01T02:52:17',
    quantity: 48.7,
    vehicle: 'DEM666',
    id: 133666,
    tank_volume_start: 22427.799999999963,
    tank_volume_stop: 22379.099999999962,
  },
  {
    timestamp_start: '2022-01-01T02:52:32',
    timestamp_stop: '2022-01-01T02:52:36',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 133668,
    tank_volume_start: 22379.099999999962,
    tank_volume_stop: 22333.299999999963,
  },
  {
    timestamp_start: '2022-01-01T02:52:51',
    timestamp_stop: '2022-01-01T02:52:56',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 133670,
    tank_volume_start: 22333.299999999963,
    tank_volume_stop: 22281.199999999964,
  },
  {
    timestamp_start: '2022-01-01T02:53:11',
    timestamp_stop: '2022-01-01T02:53:16',
    quantity: 46.6,
    vehicle: 'DEM444',
    id: 133672,
    tank_volume_start: 22281.199999999964,
    tank_volume_stop: 22234.599999999966,
  },
  {
    timestamp_start: '2022-01-01T02:53:31',
    timestamp_stop: '2022-01-01T02:53:37',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 133674,
    tank_volume_start: 22234.599999999966,
    tank_volume_stop: 22178.399999999965,
  },
  {
    timestamp_start: '2022-01-01T02:53:52',
    timestamp_stop: '2022-01-01T02:53:57',
    quantity: 56.6,
    vehicle: 'DEM222',
    id: 133676,
    tank_volume_start: 22178.399999999965,
    tank_volume_stop: 22121.799999999967,
  },
  {
    timestamp_start: '2022-01-01T02:54:12',
    timestamp_stop: '2022-01-01T02:54:18',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 133678,
    tank_volume_start: 22121.799999999967,
    tank_volume_stop: 22063.199999999968,
  },
  {
    timestamp_start: '2022-01-01T02:54:33',
    timestamp_stop: '2022-01-01T02:54:39',
    quantity: 61.3,
    vehicle: 'DEM222',
    id: 133680,
    tank_volume_start: 22063.199999999968,
    tank_volume_stop: 22001.89999999997,
  },
  {
    timestamp_start: '2022-01-01T02:54:54',
    timestamp_stop: '2022-01-01T02:55:00',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 133682,
    tank_volume_start: 22001.89999999997,
    tank_volume_stop: 21946.199999999968,
  },
  {
    timestamp_start: '2022-01-01T02:55:15',
    timestamp_stop: '2022-01-01T02:55:20',
    quantity: 46.5,
    vehicle: 'DEM555',
    id: 133684,
    tank_volume_start: 21946.199999999968,
    tank_volume_stop: 21899.699999999968,
  },
  {
    timestamp_start: '2022-01-01T02:55:35',
    timestamp_stop: '2022-01-01T02:55:40',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 133686,
    tank_volume_start: 21899.699999999968,
    tank_volume_stop: 21844.799999999967,
  },
  {
    timestamp_start: '2022-01-01T02:55:55',
    timestamp_stop: '2022-01-01T02:56:01',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 133688,
    tank_volume_start: 21844.799999999967,
    tank_volume_stop: 21785.899999999965,
  },
  {
    timestamp_start: '2022-01-01T02:56:16',
    timestamp_stop: '2022-01-01T02:56:22',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 133690,
    tank_volume_start: 21785.899999999965,
    tank_volume_stop: 21729.999999999964,
  },
  {
    timestamp_start: '2022-01-01T02:56:37',
    timestamp_stop: '2022-01-01T02:56:43',
    quantity: 60.9,
    vehicle: 'DEM444',
    id: 133692,
    tank_volume_start: 21729.999999999964,
    tank_volume_stop: 21669.099999999962,
  },
  {
    timestamp_start: '2022-01-01T02:56:58',
    timestamp_stop: '2022-01-01T02:57:03',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 133694,
    tank_volume_start: 21669.099999999962,
    tank_volume_stop: 21614.19999999996,
  },
  {
    timestamp_start: '2022-01-01T02:57:18',
    timestamp_stop: '2022-01-01T02:57:24',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 133696,
    tank_volume_start: 21614.19999999996,
    tank_volume_stop: 21557.79999999996,
  },
  {
    timestamp_start: '2022-01-01T02:57:39',
    timestamp_stop: '2022-01-01T02:57:45',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 133698,
    tank_volume_start: 21557.79999999996,
    tank_volume_stop: 21496.79999999996,
  },
  {
    timestamp_start: '2022-01-01T02:58:00',
    timestamp_stop: '2022-01-01T02:58:05',
    quantity: 50.1,
    vehicle: 'DEM333',
    id: 133700,
    tank_volume_start: 21496.79999999996,
    tank_volume_stop: 21446.69999999996,
  },
  {
    timestamp_start: '2022-01-01T02:58:20',
    timestamp_stop: '2022-01-01T02:58:25',
    quantity: 53.9,
    vehicle: 'DEM666',
    id: 133702,
    tank_volume_start: 21446.69999999996,
    tank_volume_stop: 21392.79999999996,
  },
  {
    timestamp_start: '2022-01-01T02:58:40',
    timestamp_stop: '2022-01-01T02:58:46',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 133704,
    tank_volume_start: 21392.79999999996,
    tank_volume_stop: 21337.09999999996,
  },
  {
    timestamp_start: '2022-01-01T02:59:01',
    timestamp_stop: '2022-01-01T02:59:06',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 133706,
    tank_volume_start: 21337.09999999996,
    tank_volume_stop: 21283.09999999996,
  },
  {
    timestamp_start: '2022-01-01T02:59:21',
    timestamp_stop: '2022-01-01T02:59:27',
    quantity: 61.3,
    vehicle: 'DEM444',
    id: 133708,
    tank_volume_start: 21283.09999999996,
    tank_volume_stop: 21221.79999999996,
  },
  {
    timestamp_start: '2022-01-01T02:59:42',
    timestamp_stop: '2022-01-01T02:59:47',
    quantity: 50.1,
    vehicle: 'DEM000',
    id: 133710,
    tank_volume_start: 21221.79999999996,
    tank_volume_stop: 21171.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:00:02',
    timestamp_stop: '2022-01-01T03:00:08',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 133712,
    tank_volume_start: 21171.69999999996,
    tank_volume_stop: 21118.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:00:23',
    timestamp_stop: '2022-01-01T03:00:28',
    quantity: 56.7,
    vehicle: 'DEM666',
    id: 133714,
    tank_volume_start: 21118.69999999996,
    tank_volume_stop: 21061.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:00:43',
    timestamp_stop: '2022-01-01T03:00:50',
    quantity: 62.3,
    vehicle: 'DEM000',
    id: 133716,
    tank_volume_start: 21061.99999999996,
    tank_volume_stop: 20999.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:01:05',
    timestamp_stop: '2022-01-01T03:01:10',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 133718,
    tank_volume_start: 20999.69999999996,
    tank_volume_stop: 20946.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:01:25',
    timestamp_stop: '2022-01-01T03:01:31',
    quantity: 62.7,
    vehicle: 'DEM333',
    id: 133720,
    tank_volume_start: 20946.69999999996,
    tank_volume_stop: 20883.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:01:46',
    timestamp_stop: '2022-01-01T03:01:52',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 133722,
    tank_volume_start: 20883.99999999996,
    tank_volume_stop: 20829.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:07',
    timestamp_stop: '2022-01-01T03:02:11',
    quantity: 45.9,
    vehicle: 'DEM000',
    id: 133724,
    tank_volume_start: 20829.19999999996,
    tank_volume_stop: 20783.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:26',
    timestamp_stop: '2022-01-01T03:02:32',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 133726,
    tank_volume_start: 20783.29999999996,
    tank_volume_stop: 20724.79999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:47',
    timestamp_stop: '2022-01-01T03:02:52',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 133728,
    tank_volume_start: 20724.79999999996,
    tank_volume_stop: 20672.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:03:07',
    timestamp_stop: '2022-01-01T03:03:12',
    quantity: 47.0,
    vehicle: 'DEM444',
    id: 133730,
    tank_volume_start: 20672.29999999996,
    tank_volume_stop: 20625.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:03:27',
    timestamp_stop: '2022-01-01T03:03:32',
    quantity: 52.6,
    vehicle: 'DEM444',
    id: 133732,
    tank_volume_start: 20625.29999999996,
    tank_volume_stop: 20572.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:03:47',
    timestamp_stop: '2022-01-01T03:03:53',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 133734,
    tank_volume_start: 20572.69999999996,
    tank_volume_stop: 20516.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:04:08',
    timestamp_stop: '2022-01-01T03:04:13',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 133736,
    tank_volume_start: 20516.99999999996,
    tank_volume_stop: 20465.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:04:28',
    timestamp_stop: '2022-01-01T03:04:33',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 133738,
    tank_volume_start: 20465.69999999996,
    tank_volume_stop: 20414.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:04:48',
    timestamp_stop: '2022-01-01T03:04:54',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 133740,
    tank_volume_start: 20414.89999999996,
    tank_volume_stop: 20355.099999999962,
  },
  {
    timestamp_start: '2022-01-01T03:05:09',
    timestamp_stop: '2022-01-01T03:05:15',
    quantity: 61.5,
    vehicle: 'DEM000',
    id: 133742,
    tank_volume_start: 20355.099999999962,
    tank_volume_stop: 20293.599999999962,
  },
  {
    timestamp_start: '2022-01-01T03:05:30',
    timestamp_stop: '2022-01-01T03:05:36',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 133744,
    tank_volume_start: 20293.599999999962,
    tank_volume_stop: 20238.299999999963,
  },
  {
    timestamp_start: '2022-01-01T03:05:51',
    timestamp_stop: '2022-01-01T03:05:56',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 133746,
    tank_volume_start: 20238.299999999963,
    tank_volume_stop: 20187.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:06:11',
    timestamp_stop: '2022-01-01T03:06:17',
    quantity: 58.4,
    vehicle: 'DEM333',
    id: 133748,
    tank_volume_start: 20187.89999999996,
    tank_volume_stop: 20129.49999999996,
  },
  {
    timestamp_start: '2022-01-01T03:06:32',
    timestamp_stop: '2022-01-01T03:06:38',
    quantity: 62.3,
    vehicle: 'DEM222',
    id: 133750,
    tank_volume_start: 20129.49999999996,
    tank_volume_stop: 20067.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:06:53',
    timestamp_stop: '2022-01-01T03:06:58',
    quantity: 48.1,
    vehicle: 'DEM888',
    id: 133752,
    tank_volume_start: 20067.19999999996,
    tank_volume_stop: 20019.099999999962,
  },
  {
    timestamp_start: '2022-01-01T03:07:13',
    timestamp_stop: '2022-01-01T03:07:18',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 133754,
    tank_volume_start: 20019.099999999962,
    tank_volume_stop: 19963.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:07:33',
    timestamp_stop: '2022-01-01T03:07:38',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 133756,
    tank_volume_start: 39963.19999999996,
    tank_volume_stop: 39911.09999999996,
  },
  {
    timestamp_start: '2022-01-01T03:07:53',
    timestamp_stop: '2022-01-01T03:07:59',
    quantity: 52.1,
    vehicle: 'DEM777',
    id: 133758,
    tank_volume_start: 39911.09999999996,
    tank_volume_stop: 39858.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:08:14',
    timestamp_stop: '2022-01-01T03:08:18',
    quantity: 47.5,
    vehicle: 'DEM222',
    id: 133760,
    tank_volume_start: 39858.99999999996,
    tank_volume_stop: 39811.49999999996,
  },
  {
    timestamp_start: '2022-01-01T03:08:33',
    timestamp_stop: '2022-01-01T03:08:39',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 133762,
    tank_volume_start: 39811.49999999996,
    tank_volume_stop: 39755.59999999996,
  },
  {
    timestamp_start: '2022-01-01T03:08:54',
    timestamp_stop: '2022-01-01T03:08:59',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 133764,
    tank_volume_start: 39755.59999999996,
    tank_volume_stop: 39701.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:09:14',
    timestamp_stop: '2022-01-01T03:09:20',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 133766,
    tank_volume_start: 39701.99999999996,
    tank_volume_stop: 39648.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:09:35',
    timestamp_stop: '2022-01-01T03:09:40',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 133768,
    tank_volume_start: 39648.69999999996,
    tank_volume_stop: 39595.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:09:55',
    timestamp_stop: '2022-01-01T03:10:01',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 133770,
    tank_volume_start: 39595.29999999996,
    tank_volume_stop: 39538.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:10:16',
    timestamp_stop: '2022-01-01T03:10:21',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 133772,
    tank_volume_start: 39538.89999999996,
    tank_volume_stop: 39481.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:10:36',
    timestamp_stop: '2022-01-01T03:10:42',
    quantity: 59.6,
    vehicle: 'DEM666',
    id: 133774,
    tank_volume_start: 39481.29999999996,
    tank_volume_stop: 39421.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:10:57',
    timestamp_stop: '2022-01-01T03:11:03',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 133776,
    tank_volume_start: 39421.69999999996,
    tank_volume_stop: 39367.09999999996,
  },
  {
    timestamp_start: '2022-01-01T03:11:18',
    timestamp_stop: '2022-01-01T03:11:23',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 133778,
    tank_volume_start: 39367.09999999996,
    tank_volume_stop: 39312.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:11:38',
    timestamp_stop: '2022-01-01T03:11:44',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 133780,
    tank_volume_start: 39312.99999999996,
    tank_volume_stop: 39251.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:11:59',
    timestamp_stop: '2022-01-01T03:12:05',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 133782,
    tank_volume_start: 39251.99999999996,
    tank_volume_stop: 39197.29999999997,
  },
  {
    timestamp_start: '2022-01-01T03:12:20',
    timestamp_stop: '2022-01-01T03:12:25',
    quantity: 51.6,
    vehicle: 'DEM666',
    id: 133784,
    tank_volume_start: 39197.29999999997,
    tank_volume_stop: 39145.69999999997,
  },
  {
    timestamp_start: '2022-01-01T03:12:40',
    timestamp_stop: '2022-01-01T03:12:45',
    quantity: 53.8,
    vehicle: 'DEM555',
    id: 133786,
    tank_volume_start: 39145.69999999997,
    tank_volume_stop: 39091.899999999965,
  },
  {
    timestamp_start: '2022-01-01T03:13:00',
    timestamp_stop: '2022-01-01T03:13:06',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 133788,
    tank_volume_start: 39091.899999999965,
    tank_volume_stop: 39038.19999999997,
  },
  {
    timestamp_start: '2022-01-01T03:13:21',
    timestamp_stop: '2022-01-01T03:13:26',
    quantity: 56.6,
    vehicle: 'DEM666',
    id: 133790,
    tank_volume_start: 39038.19999999997,
    tank_volume_stop: 38981.59999999997,
  },
  {
    timestamp_start: '2022-01-01T03:13:41',
    timestamp_stop: '2022-01-01T03:13:47',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 133792,
    tank_volume_start: 38981.59999999997,
    tank_volume_stop: 38925.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:14:02',
    timestamp_stop: '2022-01-01T03:14:07',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 133794,
    tank_volume_start: 38925.39999999997,
    tank_volume_stop: 38871.799999999974,
  },
  {
    timestamp_start: '2022-01-01T03:14:22',
    timestamp_stop: '2022-01-01T03:14:27',
    quantity: 48.2,
    vehicle: 'DEM666',
    id: 133796,
    tank_volume_start: 38871.799999999974,
    tank_volume_stop: 38823.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:14:42',
    timestamp_stop: '2022-01-01T03:14:49',
    quantity: 64.5,
    vehicle: 'DEM222',
    id: 133798,
    tank_volume_start: 38823.59999999998,
    tank_volume_stop: 38759.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:15:04',
    timestamp_stop: '2022-01-01T03:15:09',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 133800,
    tank_volume_start: 38759.09999999998,
    tank_volume_stop: 38704.199999999975,
  },
  {
    timestamp_start: '2022-01-01T03:15:24',
    timestamp_stop: '2022-01-01T03:15:29',
    quantity: 47.3,
    vehicle: 'DEM777',
    id: 133802,
    tank_volume_start: 38704.199999999975,
    tank_volume_stop: 38656.89999999997,
  },
  {
    timestamp_start: '2022-01-01T03:15:44',
    timestamp_stop: '2022-01-01T03:15:49',
    quantity: 50.6,
    vehicle: 'DEM666',
    id: 133804,
    tank_volume_start: 38656.89999999997,
    tank_volume_stop: 38606.299999999974,
  },
  {
    timestamp_start: '2022-01-01T03:16:04',
    timestamp_stop: '2022-01-01T03:16:10',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 133806,
    tank_volume_start: 38606.299999999974,
    tank_volume_stop: 38548.299999999974,
  },
  {
    timestamp_start: '2022-01-01T03:16:25',
    timestamp_stop: '2022-01-01T03:16:30',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 133808,
    tank_volume_start: 38548.299999999974,
    tank_volume_stop: 38492.299999999974,
  },
  {
    timestamp_start: '2022-01-01T03:16:45',
    timestamp_stop: '2022-01-01T03:16:50',
    quantity: 48.1,
    vehicle: 'DEM333',
    id: 133810,
    tank_volume_start: 38492.299999999974,
    tank_volume_stop: 38444.199999999975,
  },
  {
    timestamp_start: '2022-01-01T03:17:05',
    timestamp_stop: '2022-01-01T03:17:10',
    quantity: 50.1,
    vehicle: 'DEM333',
    id: 133812,
    tank_volume_start: 38444.199999999975,
    tank_volume_stop: 38394.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:17:25',
    timestamp_stop: '2022-01-01T03:17:30',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 133814,
    tank_volume_start: 38394.09999999998,
    tank_volume_stop: 38341.99999999998,
  },
  {
    timestamp_start: '2022-01-01T03:17:45',
    timestamp_stop: '2022-01-01T03:17:51',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 133816,
    tank_volume_start: 38341.99999999998,
    tank_volume_stop: 38284.79999999998,
  },
  {
    timestamp_start: '2022-01-01T03:18:06',
    timestamp_stop: '2022-01-01T03:18:11',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 133818,
    tank_volume_start: 38284.79999999998,
    tank_volume_stop: 38230.39999999998,
  },
  {
    timestamp_start: '2022-01-01T03:18:26',
    timestamp_stop: '2022-01-01T03:18:32',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 133820,
    tank_volume_start: 38230.39999999998,
    tank_volume_stop: 38172.89999999998,
  },
  {
    timestamp_start: '2022-01-01T03:18:47',
    timestamp_stop: '2022-01-01T03:18:54',
    quantity: 63.3,
    vehicle: 'DEM000',
    id: 133822,
    tank_volume_start: 38172.89999999998,
    tank_volume_stop: 38109.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:19:09',
    timestamp_stop: '2022-01-01T03:19:14',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 133824,
    tank_volume_start: 38109.59999999998,
    tank_volume_stop: 38055.199999999975,
  },
  {
    timestamp_start: '2022-01-01T03:19:29',
    timestamp_stop: '2022-01-01T03:19:34',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 133826,
    tank_volume_start: 38055.199999999975,
    tank_volume_stop: 38004.89999999997,
  },
  {
    timestamp_start: '2022-01-01T03:19:49',
    timestamp_stop: '2022-01-01T03:19:54',
    quantity: 51.5,
    vehicle: 'DEM333',
    id: 133828,
    tank_volume_start: 38004.89999999997,
    tank_volume_stop: 37953.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:20:09',
    timestamp_stop: '2022-01-01T03:20:15',
    quantity: 62.3,
    vehicle: 'DEM444',
    id: 133830,
    tank_volume_start: 37953.39999999997,
    tank_volume_stop: 37891.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:20:30',
    timestamp_stop: '2022-01-01T03:20:36',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 133832,
    tank_volume_start: 37891.09999999997,
    tank_volume_stop: 37837.49999999997,
  },
  {
    timestamp_start: '2022-01-01T03:20:51',
    timestamp_stop: '2022-01-01T03:20:56',
    quantity: 49.6,
    vehicle: 'DEM777',
    id: 133834,
    tank_volume_start: 37837.49999999997,
    tank_volume_stop: 37787.89999999997,
  },
  {
    timestamp_start: '2022-01-01T03:21:11',
    timestamp_stop: '2022-01-01T03:21:16',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 133836,
    tank_volume_start: 37787.89999999997,
    tank_volume_stop: 37730.59999999997,
  },
  {
    timestamp_start: '2022-01-01T03:21:31',
    timestamp_stop: '2022-01-01T03:21:37',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 133838,
    tank_volume_start: 37730.59999999997,
    tank_volume_stop: 37672.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:21:52',
    timestamp_stop: '2022-01-01T03:21:58',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 133840,
    tank_volume_start: 37672.09999999997,
    tank_volume_stop: 37617.59999999997,
  },
  {
    timestamp_start: '2022-01-01T03:22:13',
    timestamp_stop: '2022-01-01T03:22:19',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 133842,
    tank_volume_start: 37617.59999999997,
    tank_volume_stop: 37558.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:22:34',
    timestamp_stop: '2022-01-01T03:22:39',
    quantity: 51.6,
    vehicle: 'DEM111',
    id: 133844,
    tank_volume_start: 37558.09999999997,
    tank_volume_stop: 37506.49999999997,
  },
  {
    timestamp_start: '2022-01-01T03:22:54',
    timestamp_stop: '2022-01-01T03:22:59',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 133846,
    tank_volume_start: 37506.49999999997,
    tank_volume_stop: 37453.799999999974,
  },
  {
    timestamp_start: '2022-01-01T03:23:14',
    timestamp_stop: '2022-01-01T03:23:20',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 133848,
    tank_volume_start: 37453.799999999974,
    tank_volume_stop: 37395.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:23:35',
    timestamp_stop: '2022-01-01T03:23:40',
    quantity: 49.2,
    vehicle: 'DEM111',
    id: 133850,
    tank_volume_start: 37395.09999999998,
    tank_volume_stop: 37345.89999999998,
  },
  {
    timestamp_start: '2022-01-01T03:23:55',
    timestamp_stop: '2022-01-01T03:24:00',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 133852,
    tank_volume_start: 37345.89999999998,
    tank_volume_stop: 37292.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:24:15',
    timestamp_stop: '2022-01-01T03:24:21',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 133854,
    tank_volume_start: 37292.59999999998,
    tank_volume_stop: 37235.39999999998,
  },
  {
    timestamp_start: '2022-01-01T03:24:36',
    timestamp_stop: '2022-01-01T03:24:41',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 133856,
    tank_volume_start: 37235.39999999998,
    tank_volume_stop: 37184.79999999998,
  },
  {
    timestamp_start: '2022-01-01T03:24:56',
    timestamp_stop: '2022-01-01T03:25:02',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 133858,
    tank_volume_start: 37184.79999999998,
    tank_volume_stop: 37128.39999999998,
  },
  {
    timestamp_start: '2022-01-01T03:25:17',
    timestamp_stop: '2022-01-01T03:25:23',
    quantity: 62.5,
    vehicle: 'DEM888',
    id: 133860,
    tank_volume_start: 37128.39999999998,
    tank_volume_stop: 37065.89999999998,
  },
  {
    timestamp_start: '2022-01-01T03:25:38',
    timestamp_stop: '2022-01-01T03:25:44',
    quantity: 59.2,
    vehicle: 'DEM888',
    id: 133862,
    tank_volume_start: 37065.89999999998,
    tank_volume_stop: 37006.69999999998,
  },
  {
    timestamp_start: '2022-01-01T03:25:59',
    timestamp_stop: '2022-01-01T03:26:04',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 133864,
    tank_volume_start: 37006.69999999998,
    tank_volume_stop: 36950.89999999998,
  },
  {
    timestamp_start: '2022-01-01T03:26:19',
    timestamp_stop: '2022-01-01T03:26:24',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 133866,
    tank_volume_start: 36950.89999999998,
    tank_volume_stop: 36903.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:26:39',
    timestamp_stop: '2022-01-01T03:26:45',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 133868,
    tank_volume_start: 36903.09999999998,
    tank_volume_stop: 36847.39999999998,
  },
  {
    timestamp_start: '2022-01-01T03:27:00',
    timestamp_stop: '2022-01-01T03:27:06',
    quantity: 59.0,
    vehicle: 'DEM888',
    id: 133870,
    tank_volume_start: 36847.39999999998,
    tank_volume_stop: 36788.39999999998,
  },
  {
    timestamp_start: '2022-01-01T03:27:21',
    timestamp_stop: '2022-01-01T03:27:26',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 133872,
    tank_volume_start: 36788.39999999998,
    tank_volume_stop: 36731.79999999998,
  },
  {
    timestamp_start: '2022-01-01T03:27:41',
    timestamp_stop: '2022-01-01T03:27:47',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 133874,
    tank_volume_start: 36731.79999999998,
    tank_volume_stop: 36679.89999999998,
  },
  {
    timestamp_start: '2022-01-01T03:28:02',
    timestamp_stop: '2022-01-01T03:28:07',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 133876,
    tank_volume_start: 36679.89999999998,
    tank_volume_stop: 36628.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:28:22',
    timestamp_stop: '2022-01-01T03:28:28',
    quantity: 62.9,
    vehicle: 'DEM222',
    id: 133878,
    tank_volume_start: 36628.59999999998,
    tank_volume_stop: 36565.699999999975,
  },
  {
    timestamp_start: '2022-01-01T03:28:43',
    timestamp_stop: '2022-01-01T03:28:48',
    quantity: 47.7,
    vehicle: 'DEM777',
    id: 133880,
    tank_volume_start: 36565.699999999975,
    tank_volume_stop: 36517.99999999998,
  },
  {
    timestamp_start: '2022-01-01T03:29:03',
    timestamp_stop: '2022-01-01T03:29:09',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 133882,
    tank_volume_start: 36517.99999999998,
    tank_volume_stop: 36458.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:29:24',
    timestamp_stop: '2022-01-01T03:29:29',
    quantity: 57.0,
    vehicle: 'DEM555',
    id: 133884,
    tank_volume_start: 36458.09999999998,
    tank_volume_stop: 36401.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:29:44',
    timestamp_stop: '2022-01-01T03:29:49',
    quantity: 49.8,
    vehicle: 'DEM444',
    id: 133886,
    tank_volume_start: 36401.09999999998,
    tank_volume_stop: 36351.299999999974,
  },
  {
    timestamp_start: '2022-01-01T03:30:04',
    timestamp_stop: '2022-01-01T03:30:10',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 133888,
    tank_volume_start: 36351.299999999974,
    tank_volume_stop: 36298.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:30:25',
    timestamp_stop: '2022-01-01T03:30:30',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 133890,
    tank_volume_start: 36298.39999999997,
    tank_volume_stop: 36243.59999999997,
  },
  {
    timestamp_start: '2022-01-01T03:30:45',
    timestamp_stop: '2022-01-01T03:30:50',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 133892,
    tank_volume_start: 36243.59999999997,
    tank_volume_stop: 36190.29999999997,
  },
  {
    timestamp_start: '2022-01-01T03:31:05',
    timestamp_stop: '2022-01-01T03:31:11',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 133894,
    tank_volume_start: 36190.29999999997,
    tank_volume_stop: 36134.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:31:26',
    timestamp_stop: '2022-01-01T03:31:32',
    quantity: 57.9,
    vehicle: 'DEM444',
    id: 133896,
    tank_volume_start: 36134.09999999997,
    tank_volume_stop: 36076.19999999997,
  },
  {
    timestamp_start: '2022-01-01T03:31:47',
    timestamp_stop: '2022-01-01T03:31:53',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 133898,
    tank_volume_start: 36076.19999999997,
    tank_volume_stop: 36018.59999999997,
  },
  {
    timestamp_start: '2022-01-01T03:32:08',
    timestamp_stop: '2022-01-01T03:32:14',
    quantity: 63.6,
    vehicle: 'DEM777',
    id: 133900,
    tank_volume_start: 36018.59999999997,
    tank_volume_stop: 35954.99999999997,
  },
  {
    timestamp_start: '2022-01-01T03:32:29',
    timestamp_stop: '2022-01-01T03:32:35',
    quantity: 55.6,
    vehicle: 'DEM777',
    id: 133902,
    tank_volume_start: 35954.99999999997,
    tank_volume_stop: 35899.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:32:50',
    timestamp_stop: '2022-01-01T03:32:55',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 133904,
    tank_volume_start: 35899.39999999997,
    tank_volume_stop: 35846.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:33:10',
    timestamp_stop: '2022-01-01T03:33:15',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 133906,
    tank_volume_start: 35846.09999999997,
    tank_volume_stop: 35795.49999999997,
  },
  {
    timestamp_start: '2022-01-01T03:33:30',
    timestamp_stop: '2022-01-01T03:33:35',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 133908,
    tank_volume_start: 35795.49999999997,
    tank_volume_stop: 35741.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:33:50',
    timestamp_stop: '2022-01-01T03:33:56',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 133910,
    tank_volume_start: 35741.09999999997,
    tank_volume_stop: 35688.19999999997,
  },
  {
    timestamp_start: '2022-01-01T03:34:11',
    timestamp_stop: '2022-01-01T03:34:16',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 133912,
    tank_volume_start: 35688.19999999997,
    tank_volume_stop: 35632.79999999997,
  },
  {
    timestamp_start: '2022-01-01T03:34:31',
    timestamp_stop: '2022-01-01T03:34:36',
    quantity: 47.7,
    vehicle: 'DEM666',
    id: 133914,
    tank_volume_start: 35632.79999999997,
    tank_volume_stop: 35585.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:34:51',
    timestamp_stop: '2022-01-01T03:34:56',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 133916,
    tank_volume_start: 35585.09999999997,
    tank_volume_stop: 35531.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:35:11',
    timestamp_stop: '2022-01-01T03:35:17',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 133918,
    tank_volume_start: 35531.09999999997,
    tank_volume_stop: 35474.29999999997,
  },
  {
    timestamp_start: '2022-01-01T03:35:32',
    timestamp_stop: '2022-01-01T03:35:37',
    quantity: 47.6,
    vehicle: 'DEM777',
    id: 133920,
    tank_volume_start: 35474.29999999997,
    tank_volume_stop: 35426.69999999997,
  },
  {
    timestamp_start: '2022-01-01T03:35:52',
    timestamp_stop: '2022-01-01T03:35:58',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 133922,
    tank_volume_start: 35426.69999999997,
    tank_volume_stop: 35363.09999999997,
  },
  {
    timestamp_start: '2022-01-01T03:36:13',
    timestamp_stop: '2022-01-01T03:36:18',
    quantity: 48.7,
    vehicle: 'DEM444',
    id: 133924,
    tank_volume_start: 35363.09999999997,
    tank_volume_stop: 35314.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:36:33',
    timestamp_stop: '2022-01-01T03:36:39',
    quantity: 61.7,
    vehicle: 'DEM777',
    id: 133926,
    tank_volume_start: 35314.39999999997,
    tank_volume_stop: 35252.699999999975,
  },
  {
    timestamp_start: '2022-01-01T03:36:54',
    timestamp_stop: '2022-01-01T03:36:59',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 133928,
    tank_volume_start: 35252.699999999975,
    tank_volume_stop: 35201.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:37:14',
    timestamp_stop: '2022-01-01T03:37:19',
    quantity: 49.5,
    vehicle: 'DEM000',
    id: 133930,
    tank_volume_start: 35201.09999999998,
    tank_volume_stop: 35151.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:37:34',
    timestamp_stop: '2022-01-01T03:37:40',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 133932,
    tank_volume_start: 35151.59999999998,
    tank_volume_stop: 35096.59999999998,
  },
  {
    timestamp_start: '2022-01-01T03:37:55',
    timestamp_stop: '2022-01-01T03:38:01',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 133934,
    tank_volume_start: 35096.59999999998,
    tank_volume_stop: 35039.699999999975,
  },
  {
    timestamp_start: '2022-01-01T03:38:16',
    timestamp_stop: '2022-01-01T03:38:22',
    quantity: 60.6,
    vehicle: 'DEM333',
    id: 133936,
    tank_volume_start: 35039.699999999975,
    tank_volume_stop: 34979.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:38:37',
    timestamp_stop: '2022-01-01T03:38:42',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 133938,
    tank_volume_start: 34979.09999999998,
    tank_volume_stop: 34925.199999999975,
  },
  {
    timestamp_start: '2022-01-01T03:38:57',
    timestamp_stop: '2022-01-01T03:39:03',
    quantity: 59.1,
    vehicle: 'DEM111',
    id: 133940,
    tank_volume_start: 34925.199999999975,
    tank_volume_stop: 34866.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:39:18',
    timestamp_stop: '2022-01-01T03:39:23',
    quantity: 48.8,
    vehicle: 'DEM888',
    id: 133942,
    tank_volume_start: 34866.09999999998,
    tank_volume_stop: 34817.299999999974,
  },
  {
    timestamp_start: '2022-01-01T03:39:38',
    timestamp_stop: '2022-01-01T03:39:43',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 133944,
    tank_volume_start: 34817.299999999974,
    tank_volume_stop: 34765.09999999998,
  },
  {
    timestamp_start: '2022-01-01T03:39:58',
    timestamp_stop: '2022-01-01T03:40:03',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 133946,
    tank_volume_start: 34765.09999999998,
    tank_volume_stop: 34711.49999999998,
  },
  {
    timestamp_start: '2022-01-01T03:40:18',
    timestamp_stop: '2022-01-01T03:40:23',
    quantity: 50.3,
    vehicle: 'DEM777',
    id: 133948,
    tank_volume_start: 34711.49999999998,
    tank_volume_stop: 34661.199999999975,
  },
  {
    timestamp_start: '2022-01-01T03:40:38',
    timestamp_stop: '2022-01-01T03:40:44',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 133950,
    tank_volume_start: 34661.199999999975,
    tank_volume_stop: 34603.39999999997,
  },
  {
    timestamp_start: '2022-01-01T03:40:59',
    timestamp_stop: '2022-01-01T03:41:04',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 133952,
    tank_volume_start: 34603.39999999997,
    tank_volume_stop: 34553.49999999997,
  },
  {
    timestamp_start: '2022-01-01T03:41:19',
    timestamp_stop: '2022-01-01T03:41:24',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 133954,
    tank_volume_start: 34553.49999999997,
    tank_volume_stop: 34500.19999999997,
  },
  {
    timestamp_start: '2022-01-01T03:41:39',
    timestamp_stop: '2022-01-01T03:41:45',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 133956,
    tank_volume_start: 34500.19999999997,
    tank_volume_stop: 34445.69999999997,
  },
  {
    timestamp_start: '2022-01-01T03:42:00',
    timestamp_stop: '2022-01-01T03:42:05',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 133958,
    tank_volume_start: 34445.69999999997,
    tank_volume_stop: 34395.399999999965,
  },
  {
    timestamp_start: '2022-01-01T03:42:20',
    timestamp_stop: '2022-01-01T03:42:26',
    quantity: 63.9,
    vehicle: 'DEM111',
    id: 133960,
    tank_volume_start: 34395.399999999965,
    tank_volume_stop: 34331.49999999996,
  },
  {
    timestamp_start: '2022-01-01T03:42:41',
    timestamp_stop: '2022-01-01T03:42:47',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 133962,
    tank_volume_start: 34331.49999999996,
    tank_volume_stop: 34275.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:43:02',
    timestamp_stop: '2022-01-01T03:43:08',
    quantity: 57.9,
    vehicle: 'DEM333',
    id: 133964,
    tank_volume_start: 34275.99999999996,
    tank_volume_stop: 34218.09999999996,
  },
  {
    timestamp_start: '2022-01-01T03:43:23',
    timestamp_stop: '2022-01-01T03:43:28',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 133966,
    tank_volume_start: 34218.09999999996,
    tank_volume_stop: 34166.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:43:43',
    timestamp_stop: '2022-01-01T03:43:48',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 133968,
    tank_volume_start: 34166.99999999996,
    tank_volume_stop: 34116.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:44:03',
    timestamp_stop: '2022-01-01T03:44:09',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 133970,
    tank_volume_start: 34116.19999999996,
    tank_volume_stop: 34058.49999999996,
  },
  {
    timestamp_start: '2022-01-01T03:44:24',
    timestamp_stop: '2022-01-01T03:44:29',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 133972,
    tank_volume_start: 34058.49999999996,
    tank_volume_stop: 34008.79999999997,
  },
  {
    timestamp_start: '2022-01-01T03:44:44',
    timestamp_stop: '2022-01-01T03:44:49',
    quantity: 51.8,
    vehicle: 'DEM444',
    id: 133974,
    tank_volume_start: 34008.79999999997,
    tank_volume_stop: 33956.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:45:04',
    timestamp_stop: '2022-01-01T03:45:10',
    quantity: 57.0,
    vehicle: 'DEM888',
    id: 133976,
    tank_volume_start: 33956.99999999996,
    tank_volume_stop: 33899.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:45:25',
    timestamp_stop: '2022-01-01T03:45:30',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 133978,
    tank_volume_start: 33899.99999999996,
    tank_volume_stop: 33840.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:45:45',
    timestamp_stop: '2022-01-01T03:45:51',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 133980,
    tank_volume_start: 33840.19999999996,
    tank_volume_stop: 33783.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:46:06',
    timestamp_stop: '2022-01-01T03:46:12',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 133982,
    tank_volume_start: 33783.29999999996,
    tank_volume_stop: 33729.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:46:27',
    timestamp_stop: '2022-01-01T03:46:32',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 133984,
    tank_volume_start: 33729.69999999996,
    tank_volume_stop: 33674.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:46:47',
    timestamp_stop: '2022-01-01T03:46:52',
    quantity: 45.5,
    vehicle: 'DEM333',
    id: 133986,
    tank_volume_start: 33674.89999999996,
    tank_volume_stop: 33629.39999999996,
  },
  {
    timestamp_start: '2022-01-01T03:47:07',
    timestamp_stop: '2022-01-01T03:47:13',
    quantity: 61.3,
    vehicle: 'DEM555',
    id: 133988,
    tank_volume_start: 33629.39999999996,
    tank_volume_stop: 33568.099999999955,
  },
  {
    timestamp_start: '2022-01-01T03:47:28',
    timestamp_stop: '2022-01-01T03:47:33',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 133990,
    tank_volume_start: 33568.099999999955,
    tank_volume_stop: 33514.099999999955,
  },
  {
    timestamp_start: '2022-01-01T03:47:48',
    timestamp_stop: '2022-01-01T03:47:53',
    quantity: 46.9,
    vehicle: 'DEM111',
    id: 133992,
    tank_volume_start: 33514.099999999955,
    tank_volume_stop: 33467.19999999995,
  },
  {
    timestamp_start: '2022-01-01T03:48:08',
    timestamp_stop: '2022-01-01T03:48:14',
    quantity: 62.2,
    vehicle: 'DEM000',
    id: 133994,
    tank_volume_start: 33467.19999999995,
    tank_volume_stop: 33404.999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:48:29',
    timestamp_stop: '2022-01-01T03:48:35',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 133996,
    tank_volume_start: 33404.999999999956,
    tank_volume_stop: 33344.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:48:50',
    timestamp_stop: '2022-01-01T03:48:55',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 133998,
    tank_volume_start: 33344.89999999996,
    tank_volume_stop: 33290.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:49:10',
    timestamp_stop: '2022-01-01T03:49:17',
    quantity: 63.5,
    vehicle: 'DEM444',
    id: 134000,
    tank_volume_start: 33290.29999999996,
    tank_volume_stop: 33226.79999999996,
  },
  {
    timestamp_start: '2022-01-01T03:49:32',
    timestamp_stop: '2022-01-01T03:49:37',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 134002,
    tank_volume_start: 33226.79999999996,
    tank_volume_stop: 33172.499999999956,
  },
  {
    timestamp_start: '2022-01-01T03:49:52',
    timestamp_stop: '2022-01-01T03:49:57',
    quantity: 45.2,
    vehicle: 'DEM222',
    id: 134004,
    tank_volume_start: 33172.499999999956,
    tank_volume_stop: 33127.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:50:12',
    timestamp_stop: '2022-01-01T03:50:17',
    quantity: 47.8,
    vehicle: 'DEM555',
    id: 134006,
    tank_volume_start: 33127.29999999996,
    tank_volume_stop: 33079.499999999956,
  },
  {
    timestamp_start: '2022-01-01T03:50:32',
    timestamp_stop: '2022-01-01T03:50:37',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 134008,
    tank_volume_start: 33079.499999999956,
    tank_volume_stop: 33024.89999999996,
  },
  {
    timestamp_start: '2022-01-01T03:50:52',
    timestamp_stop: '2022-01-01T03:50:57',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 134010,
    tank_volume_start: 33024.89999999996,
    tank_volume_stop: 32970.099999999955,
  },
  {
    timestamp_start: '2022-01-01T03:51:12',
    timestamp_stop: '2022-01-01T03:51:17',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 134012,
    tank_volume_start: 32970.099999999955,
    tank_volume_stop: 32920.599999999955,
  },
  {
    timestamp_start: '2022-01-01T03:51:32',
    timestamp_stop: '2022-01-01T03:51:38',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 134014,
    tank_volume_start: 32920.599999999955,
    tank_volume_stop: 32860.499999999956,
  },
  {
    timestamp_start: '2022-01-01T03:51:53',
    timestamp_stop: '2022-01-01T03:51:59',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 134016,
    tank_volume_start: 32860.499999999956,
    tank_volume_stop: 32802.999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:52:14',
    timestamp_stop: '2022-01-01T03:52:20',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 134018,
    tank_volume_start: 32802.999999999956,
    tank_volume_stop: 32748.199999999957,
  },
  {
    timestamp_start: '2022-01-01T03:52:35',
    timestamp_stop: '2022-01-01T03:52:40',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 134020,
    tank_volume_start: 32748.199999999957,
    tank_volume_stop: 32693.399999999958,
  },
  {
    timestamp_start: '2022-01-01T03:52:55',
    timestamp_stop: '2022-01-01T03:53:00',
    quantity: 46.6,
    vehicle: 'DEM666',
    id: 134022,
    tank_volume_start: 32693.399999999958,
    tank_volume_stop: 32646.79999999996,
  },
  {
    timestamp_start: '2022-01-01T03:53:15',
    timestamp_stop: '2022-01-01T03:53:20',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 134024,
    tank_volume_start: 32646.79999999996,
    tank_volume_stop: 32598.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:53:35',
    timestamp_stop: '2022-01-01T03:53:40',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 134026,
    tank_volume_start: 32598.29999999996,
    tank_volume_stop: 32540.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:53:55',
    timestamp_stop: '2022-01-01T03:54:01',
    quantity: 55.2,
    vehicle: 'DEM777',
    id: 134028,
    tank_volume_start: 32540.19999999996,
    tank_volume_stop: 32484.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:54:16',
    timestamp_stop: '2022-01-01T03:54:22',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 134030,
    tank_volume_start: 32484.99999999996,
    tank_volume_stop: 32427.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:54:37',
    timestamp_stop: '2022-01-01T03:54:43',
    quantity: 61.2,
    vehicle: 'DEM444',
    id: 134032,
    tank_volume_start: 32427.29999999996,
    tank_volume_stop: 32366.09999999996,
  },
  {
    timestamp_start: '2022-01-01T03:54:58',
    timestamp_stop: '2022-01-01T03:55:03',
    quantity: 48.1,
    vehicle: 'DEM333',
    id: 134034,
    tank_volume_start: 32366.09999999996,
    tank_volume_stop: 32317.99999999996,
  },
  {
    timestamp_start: '2022-01-01T03:55:18',
    timestamp_stop: '2022-01-01T03:55:23',
    quantity: 56.5,
    vehicle: 'DEM555',
    id: 134036,
    tank_volume_start: 32317.99999999996,
    tank_volume_stop: 32261.49999999996,
  },
  {
    timestamp_start: '2022-01-01T03:55:38',
    timestamp_stop: '2022-01-01T03:55:44',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 134038,
    tank_volume_start: 32261.49999999996,
    tank_volume_stop: 32205.59999999996,
  },
  {
    timestamp_start: '2022-01-01T03:55:59',
    timestamp_stop: '2022-01-01T03:56:05',
    quantity: 63.2,
    vehicle: 'DEM666',
    id: 134040,
    tank_volume_start: 32205.59999999996,
    tank_volume_stop: 32142.399999999958,
  },
  {
    timestamp_start: '2022-01-01T03:56:20',
    timestamp_stop: '2022-01-01T03:56:26',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 134042,
    tank_volume_start: 32142.399999999958,
    tank_volume_stop: 32088.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:56:41',
    timestamp_stop: '2022-01-01T03:56:46',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 134044,
    tank_volume_start: 32088.29999999996,
    tank_volume_stop: 32031.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:57:01',
    timestamp_stop: '2022-01-01T03:57:07',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 134046,
    tank_volume_start: 32031.29999999996,
    tank_volume_stop: 31972.899999999958,
  },
  {
    timestamp_start: '2022-01-01T03:57:22',
    timestamp_stop: '2022-01-01T03:57:28',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 134048,
    tank_volume_start: 31972.899999999958,
    tank_volume_stop: 31918.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:57:43',
    timestamp_stop: '2022-01-01T03:57:48',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 134050,
    tank_volume_start: 31918.29999999996,
    tank_volume_stop: 31863.59999999996,
  },
  {
    timestamp_start: '2022-01-01T03:58:03',
    timestamp_stop: '2022-01-01T03:58:09',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 134052,
    tank_volume_start: 31863.59999999996,
    tank_volume_stop: 31809.399999999958,
  },
  {
    timestamp_start: '2022-01-01T03:58:24',
    timestamp_stop: '2022-01-01T03:58:29',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 134054,
    tank_volume_start: 31809.399999999958,
    tank_volume_stop: 31754.29999999996,
  },
  {
    timestamp_start: '2022-01-01T03:58:44',
    timestamp_stop: '2022-01-01T03:58:50',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 134056,
    tank_volume_start: 31754.29999999996,
    tank_volume_stop: 31697.19999999996,
  },
  {
    timestamp_start: '2022-01-01T03:59:05',
    timestamp_stop: '2022-01-01T03:59:10',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 134058,
    tank_volume_start: 31697.19999999996,
    tank_volume_stop: 31641.69999999996,
  },
  {
    timestamp_start: '2022-01-01T03:59:25',
    timestamp_stop: '2022-01-01T03:59:31',
    quantity: 53.6,
    vehicle: 'DEM777',
    id: 134060,
    tank_volume_start: 31641.69999999996,
    tank_volume_stop: 31588.099999999962,
  },
  {
    timestamp_start: '2022-01-01T03:59:46',
    timestamp_stop: '2022-01-01T03:59:51',
    quantity: 50.8,
    vehicle: 'DEM777',
    id: 134062,
    tank_volume_start: 31588.099999999962,
    tank_volume_stop: 31537.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:00:06',
    timestamp_stop: '2022-01-01T04:00:12',
    quantity: 59.0,
    vehicle: 'DEM777',
    id: 134064,
    tank_volume_start: 31537.299999999963,
    tank_volume_stop: 31478.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:00:27',
    timestamp_stop: '2022-01-01T04:00:33',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 134066,
    tank_volume_start: 31478.299999999963,
    tank_volume_stop: 31419.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:00:48',
    timestamp_stop: '2022-01-01T04:00:52',
    quantity: 49.2,
    vehicle: 'DEM444',
    id: 134068,
    tank_volume_start: 31419.39999999996,
    tank_volume_stop: 31370.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:07',
    timestamp_stop: '2022-01-01T04:01:13',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 134070,
    tank_volume_start: 31370.19999999996,
    tank_volume_stop: 31314.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:28',
    timestamp_stop: '2022-01-01T04:01:33',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 134072,
    tank_volume_start: 31314.19999999996,
    tank_volume_stop: 31263.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:48',
    timestamp_stop: '2022-01-01T04:01:54',
    quantity: 54.1,
    vehicle: 'DEM777',
    id: 134074,
    tank_volume_start: 31263.29999999996,
    tank_volume_stop: 31209.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:02:09',
    timestamp_stop: '2022-01-01T04:02:14',
    quantity: 49.7,
    vehicle: 'DEM222',
    id: 134076,
    tank_volume_start: 31209.19999999996,
    tank_volume_stop: 31159.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:02:29',
    timestamp_stop: '2022-01-01T04:02:35',
    quantity: 62.9,
    vehicle: 'DEM333',
    id: 134078,
    tank_volume_start: 31159.49999999996,
    tank_volume_stop: 31096.59999999996,
  },
  {
    timestamp_start: '2022-01-01T04:02:50',
    timestamp_stop: '2022-01-01T04:02:56',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 134080,
    tank_volume_start: 31096.59999999996,
    tank_volume_stop: 31039.899999999958,
  },
  {
    timestamp_start: '2022-01-01T04:03:11',
    timestamp_stop: '2022-01-01T04:03:16',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 134082,
    tank_volume_start: 31039.899999999958,
    tank_volume_stop: 30982.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:03:31',
    timestamp_stop: '2022-01-01T04:03:37',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 134084,
    tank_volume_start: 30982.29999999996,
    tank_volume_stop: 30920.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:03:52',
    timestamp_stop: '2022-01-01T04:03:58',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 134086,
    tank_volume_start: 30920.09999999996,
    tank_volume_stop: 30865.399999999958,
  },
  {
    timestamp_start: '2022-01-01T04:04:13',
    timestamp_stop: '2022-01-01T04:04:18',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 134088,
    tank_volume_start: 30865.399999999958,
    tank_volume_stop: 30810.699999999957,
  },
  {
    timestamp_start: '2022-01-01T04:04:33',
    timestamp_stop: '2022-01-01T04:04:39',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 134090,
    tank_volume_start: 30810.699999999957,
    tank_volume_stop: 30750.199999999957,
  },
  {
    timestamp_start: '2022-01-01T04:04:54',
    timestamp_stop: '2022-01-01T04:05:00',
    quantity: 58.8,
    vehicle: 'DEM222',
    id: 134092,
    tank_volume_start: 30750.199999999957,
    tank_volume_stop: 30691.399999999958,
  },
  {
    timestamp_start: '2022-01-01T04:05:15',
    timestamp_stop: '2022-01-01T04:05:21',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 134094,
    tank_volume_start: 30691.399999999958,
    tank_volume_stop: 30634.59999999996,
  },
  {
    timestamp_start: '2022-01-01T04:05:36',
    timestamp_stop: '2022-01-01T04:05:41',
    quantity: 53.9,
    vehicle: 'DEM666',
    id: 134096,
    tank_volume_start: 30634.59999999996,
    tank_volume_stop: 30580.699999999957,
  },
  {
    timestamp_start: '2022-01-01T04:05:56',
    timestamp_stop: '2022-01-01T04:06:01',
    quantity: 49.1,
    vehicle: 'DEM777',
    id: 134098,
    tank_volume_start: 30580.699999999957,
    tank_volume_stop: 30531.59999999996,
  },
  {
    timestamp_start: '2022-01-01T04:06:16',
    timestamp_stop: '2022-01-01T04:06:21',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 134100,
    tank_volume_start: 30531.59999999996,
    tank_volume_stop: 30483.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:06:36',
    timestamp_stop: '2022-01-01T04:06:41',
    quantity: 47.9,
    vehicle: 'DEM000',
    id: 134102,
    tank_volume_start: 30483.29999999996,
    tank_volume_stop: 30435.399999999958,
  },
  {
    timestamp_start: '2022-01-01T04:06:56',
    timestamp_stop: '2022-01-01T04:07:01',
    quantity: 55.1,
    vehicle: 'DEM333',
    id: 134104,
    tank_volume_start: 30435.399999999958,
    tank_volume_stop: 30380.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:07:16',
    timestamp_stop: '2022-01-01T04:07:22',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 134106,
    tank_volume_start: 30380.29999999996,
    tank_volume_stop: 30320.79999999996,
  },
  {
    timestamp_start: '2022-01-01T04:07:37',
    timestamp_stop: '2022-01-01T04:07:43',
    quantity: 57.0,
    vehicle: 'DEM888',
    id: 134108,
    tank_volume_start: 30320.79999999996,
    tank_volume_stop: 30263.79999999996,
  },
  {
    timestamp_start: '2022-01-01T04:07:58',
    timestamp_stop: '2022-01-01T04:08:04',
    quantity: 61.9,
    vehicle: 'DEM111',
    id: 134110,
    tank_volume_start: 30263.79999999996,
    tank_volume_stop: 30201.899999999958,
  },
  {
    timestamp_start: '2022-01-01T04:08:19',
    timestamp_stop: '2022-01-01T04:08:25',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 134112,
    tank_volume_start: 30201.899999999958,
    tank_volume_stop: 30145.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:08:40',
    timestamp_stop: '2022-01-01T04:08:45',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 134114,
    tank_volume_start: 30145.09999999996,
    tank_volume_stop: 30094.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:09:00',
    timestamp_stop: '2022-01-01T04:09:07',
    quantity: 64.8,
    vehicle: 'DEM555',
    id: 134116,
    tank_volume_start: 30094.49999999996,
    tank_volume_stop: 30029.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:09:22',
    timestamp_stop: '2022-01-01T04:09:27',
    quantity: 52.8,
    vehicle: 'DEM333',
    id: 134118,
    tank_volume_start: 30029.69999999996,
    tank_volume_stop: 29976.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:09:42',
    timestamp_stop: '2022-01-01T04:09:48',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 134120,
    tank_volume_start: 29976.89999999996,
    tank_volume_stop: 29916.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:03',
    timestamp_stop: '2022-01-01T04:10:09',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 134122,
    tank_volume_start: 29916.89999999996,
    tank_volume_stop: 29858.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:24',
    timestamp_stop: '2022-01-01T04:10:30',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 134124,
    tank_volume_start: 29858.49999999996,
    tank_volume_stop: 29798.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:45',
    timestamp_stop: '2022-01-01T04:10:50',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 134126,
    tank_volume_start: 29798.99999999996,
    tank_volume_stop: 29740.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:05',
    timestamp_stop: '2022-01-01T04:11:10',
    quantity: 49.0,
    vehicle: 'DEM555',
    id: 134128,
    tank_volume_start: 29740.39999999996,
    tank_volume_stop: 29691.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:25',
    timestamp_stop: '2022-01-01T04:11:30',
    quantity: 46.5,
    vehicle: 'DEM333',
    id: 134130,
    tank_volume_start: 29691.39999999996,
    tank_volume_stop: 29644.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:45',
    timestamp_stop: '2022-01-01T04:11:50',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 134132,
    tank_volume_start: 29644.89999999996,
    tank_volume_stop: 29592.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:12:05',
    timestamp_stop: '2022-01-01T04:12:11',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 134134,
    tank_volume_start: 29592.89999999996,
    tank_volume_stop: 29534.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:12:26',
    timestamp_stop: '2022-01-01T04:12:31',
    quantity: 53.5,
    vehicle: 'DEM444',
    id: 134136,
    tank_volume_start: 29534.299999999963,
    tank_volume_stop: 29480.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:12:46',
    timestamp_stop: '2022-01-01T04:12:52',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 134138,
    tank_volume_start: 29480.799999999963,
    tank_volume_stop: 29429.999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:13:07',
    timestamp_stop: '2022-01-01T04:13:13',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 134140,
    tank_volume_start: 29429.999999999964,
    tank_volume_stop: 29369.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:13:28',
    timestamp_stop: '2022-01-01T04:13:33',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 134142,
    tank_volume_start: 29369.599999999962,
    tank_volume_stop: 29315.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:13:48',
    timestamp_stop: '2022-01-01T04:13:53',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 134144,
    tank_volume_start: 29315.599999999962,
    tank_volume_stop: 29264.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:14:08',
    timestamp_stop: '2022-01-01T04:14:13',
    quantity: 51.4,
    vehicle: 'DEM000',
    id: 134146,
    tank_volume_start: 29264.299999999963,
    tank_volume_stop: 29212.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:14:28',
    timestamp_stop: '2022-01-01T04:14:34',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 134148,
    tank_volume_start: 29212.89999999996,
    tank_volume_stop: 29158.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:14:49',
    timestamp_stop: '2022-01-01T04:14:54',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 134150,
    tank_volume_start: 29158.89999999996,
    tank_volume_stop: 29102.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:15:09',
    timestamp_stop: '2022-01-01T04:15:14',
    quantity: 48.2,
    vehicle: 'DEM444',
    id: 134152,
    tank_volume_start: 29102.099999999962,
    tank_volume_stop: 29053.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:15:29',
    timestamp_stop: '2022-01-01T04:15:35',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 134154,
    tank_volume_start: 29053.89999999996,
    tank_volume_stop: 28994.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:15:50',
    timestamp_stop: '2022-01-01T04:15:56',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 134156,
    tank_volume_start: 28994.99999999996,
    tank_volume_stop: 28939.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:16:11',
    timestamp_stop: '2022-01-01T04:16:16',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 134158,
    tank_volume_start: 28939.49999999996,
    tank_volume_stop: 28883.79999999996,
  },
  {
    timestamp_start: '2022-01-01T04:16:31',
    timestamp_stop: '2022-01-01T04:16:37',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 134160,
    tank_volume_start: 28883.79999999996,
    tank_volume_stop: 28826.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:16:52',
    timestamp_stop: '2022-01-01T04:16:57',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 134162,
    tank_volume_start: 28826.09999999996,
    tank_volume_stop: 28772.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:17:12',
    timestamp_stop: '2022-01-01T04:17:18',
    quantity: 59.8,
    vehicle: 'DEM111',
    id: 134164,
    tank_volume_start: 28772.99999999996,
    tank_volume_stop: 28713.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:17:33',
    timestamp_stop: '2022-01-01T04:17:39',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 134166,
    tank_volume_start: 28713.19999999996,
    tank_volume_stop: 28658.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:17:54',
    timestamp_stop: '2022-01-01T04:18:00',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 134168,
    tank_volume_start: 28658.69999999996,
    tank_volume_stop: 28597.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:18:15',
    timestamp_stop: '2022-01-01T04:18:20',
    quantity: 53.5,
    vehicle: 'DEM888',
    id: 134170,
    tank_volume_start: 28597.599999999962,
    tank_volume_stop: 28544.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:18:35',
    timestamp_stop: '2022-01-01T04:18:41',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 134172,
    tank_volume_start: 28544.099999999962,
    tank_volume_stop: 28489.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:18:56',
    timestamp_stop: '2022-01-01T04:19:01',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 134174,
    tank_volume_start: 28489.69999999996,
    tank_volume_stop: 28432.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:19:16',
    timestamp_stop: '2022-01-01T04:19:22',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 134176,
    tank_volume_start: 28432.49999999996,
    tank_volume_stop: 28377.79999999996,
  },
  {
    timestamp_start: '2022-01-01T04:19:37',
    timestamp_stop: '2022-01-01T04:19:42',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 134178,
    tank_volume_start: 28377.79999999996,
    tank_volume_stop: 28328.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:19:57',
    timestamp_stop: '2022-01-01T04:20:02',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 134180,
    tank_volume_start: 28328.29999999996,
    tank_volume_stop: 28273.59999999996,
  },
  {
    timestamp_start: '2022-01-01T04:20:17',
    timestamp_stop: '2022-01-01T04:20:22',
    quantity: 48.1,
    vehicle: 'DEM444',
    id: 134182,
    tank_volume_start: 28273.59999999996,
    tank_volume_stop: 28225.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:20:37',
    timestamp_stop: '2022-01-01T04:20:43',
    quantity: 62.7,
    vehicle: 'DEM000',
    id: 134184,
    tank_volume_start: 28225.49999999996,
    tank_volume_stop: 28162.79999999996,
  },
  {
    timestamp_start: '2022-01-01T04:20:58',
    timestamp_stop: '2022-01-01T04:21:04',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 134186,
    tank_volume_start: 28162.79999999996,
    tank_volume_stop: 28105.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:21:19',
    timestamp_stop: '2022-01-01T04:21:24',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 134188,
    tank_volume_start: 28105.29999999996,
    tank_volume_stop: 28054.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:21:39',
    timestamp_stop: '2022-01-01T04:21:45',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 134190,
    tank_volume_start: 28054.49999999996,
    tank_volume_stop: 27995.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:22:00',
    timestamp_stop: '2022-01-01T04:22:06',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 134192,
    tank_volume_start: 27995.09999999996,
    tank_volume_stop: 27937.899999999958,
  },
  {
    timestamp_start: '2022-01-01T04:22:21',
    timestamp_stop: '2022-01-01T04:22:26',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 134194,
    tank_volume_start: 27937.899999999958,
    tank_volume_stop: 27882.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:22:41',
    timestamp_stop: '2022-01-01T04:22:46',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 134196,
    tank_volume_start: 27882.29999999996,
    tank_volume_stop: 27831.59999999996,
  },
  {
    timestamp_start: '2022-01-01T04:23:01',
    timestamp_stop: '2022-01-01T04:23:07',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 134198,
    tank_volume_start: 27831.59999999996,
    tank_volume_stop: 27772.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:23:22',
    timestamp_stop: '2022-01-01T04:23:28',
    quantity: 59.3,
    vehicle: 'DEM888',
    id: 134200,
    tank_volume_start: 27772.49999999996,
    tank_volume_stop: 27713.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:23:43',
    timestamp_stop: '2022-01-01T04:23:49',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 134202,
    tank_volume_start: 27713.19999999996,
    tank_volume_stop: 27657.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:24:04',
    timestamp_stop: '2022-01-01T04:24:09',
    quantity: 51.8,
    vehicle: 'DEM777',
    id: 134204,
    tank_volume_start: 27657.49999999996,
    tank_volume_stop: 27605.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:24:24',
    timestamp_stop: '2022-01-01T04:24:29',
    quantity: 55.1,
    vehicle: 'DEM444',
    id: 134206,
    tank_volume_start: 27605.69999999996,
    tank_volume_stop: 27550.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:24:44',
    timestamp_stop: '2022-01-01T04:24:50',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 134208,
    tank_volume_start: 27550.599999999962,
    tank_volume_stop: 27497.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:25:05',
    timestamp_stop: '2022-01-01T04:25:10',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 134210,
    tank_volume_start: 27497.599999999962,
    tank_volume_stop: 27443.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:25:25',
    timestamp_stop: '2022-01-01T04:25:31',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 134212,
    tank_volume_start: 27443.099999999962,
    tank_volume_stop: 27388.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:25:46',
    timestamp_stop: '2022-01-01T04:25:52',
    quantity: 64.1,
    vehicle: 'DEM111',
    id: 134214,
    tank_volume_start: 27388.69999999996,
    tank_volume_stop: 27324.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:26:07',
    timestamp_stop: '2022-01-01T04:26:13',
    quantity: 58.6,
    vehicle: 'DEM777',
    id: 134216,
    tank_volume_start: 27324.599999999962,
    tank_volume_stop: 27265.999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:26:28',
    timestamp_stop: '2022-01-01T04:26:33',
    quantity: 52.6,
    vehicle: 'DEM222',
    id: 134218,
    tank_volume_start: 27265.999999999964,
    tank_volume_stop: 27213.399999999965,
  },
  {
    timestamp_start: '2022-01-01T04:26:48',
    timestamp_stop: '2022-01-01T04:26:54',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 134220,
    tank_volume_start: 27213.399999999965,
    tank_volume_stop: 27156.699999999964,
  },
  {
    timestamp_start: '2022-01-01T04:27:09',
    timestamp_stop: '2022-01-01T04:27:14',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 134222,
    tank_volume_start: 27156.699999999964,
    tank_volume_stop: 27104.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:27:29',
    timestamp_stop: '2022-01-01T04:27:35',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 134224,
    tank_volume_start: 27104.299999999963,
    tank_volume_stop: 27049.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:27:50',
    timestamp_stop: '2022-01-01T04:27:54',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 134226,
    tank_volume_start: 27049.89999999996,
    tank_volume_stop: 27001.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:28:09',
    timestamp_stop: '2022-01-01T04:28:15',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 134228,
    tank_volume_start: 27001.599999999962,
    tank_volume_stop: 26949.499999999964,
  },
  {
    timestamp_start: '2022-01-01T04:28:30',
    timestamp_stop: '2022-01-01T04:28:36',
    quantity: 62.7,
    vehicle: 'DEM888',
    id: 134230,
    tank_volume_start: 26949.499999999964,
    tank_volume_stop: 26886.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:28:51',
    timestamp_stop: '2022-01-01T04:28:56',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 134232,
    tank_volume_start: 26886.799999999963,
    tank_volume_stop: 26832.199999999964,
  },
  {
    timestamp_start: '2022-01-01T04:29:11',
    timestamp_stop: '2022-01-01T04:29:16',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 134234,
    tank_volume_start: 26832.199999999964,
    tank_volume_stop: 26780.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:29:31',
    timestamp_stop: '2022-01-01T04:29:37',
    quantity: 58.3,
    vehicle: 'DEM666',
    id: 134236,
    tank_volume_start: 26780.799999999963,
    tank_volume_stop: 26722.499999999964,
  },
  {
    timestamp_start: '2022-01-01T04:29:52',
    timestamp_stop: '2022-01-01T04:29:58',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 134238,
    tank_volume_start: 26722.499999999964,
    tank_volume_stop: 26669.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:30:13',
    timestamp_stop: '2022-01-01T04:30:18',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 134240,
    tank_volume_start: 26669.299999999963,
    tank_volume_stop: 26618.499999999964,
  },
  {
    timestamp_start: '2022-01-01T04:30:33',
    timestamp_stop: '2022-01-01T04:30:38',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 134242,
    tank_volume_start: 26618.499999999964,
    tank_volume_stop: 26560.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:30:53',
    timestamp_stop: '2022-01-01T04:30:59',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 134244,
    tank_volume_start: 26560.799999999963,
    tank_volume_stop: 26506.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:31:14',
    timestamp_stop: '2022-01-01T04:31:20',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 134246,
    tank_volume_start: 26506.39999999996,
    tank_volume_stop: 26446.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:31:35',
    timestamp_stop: '2022-01-01T04:31:41',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 134248,
    tank_volume_start: 26446.299999999963,
    tank_volume_stop: 26388.499999999964,
  },
  {
    timestamp_start: '2022-01-01T04:31:56',
    timestamp_stop: '2022-01-01T04:32:02',
    quantity: 60.3,
    vehicle: 'DEM777',
    id: 134250,
    tank_volume_start: 26388.499999999964,
    tank_volume_stop: 26328.199999999964,
  },
  {
    timestamp_start: '2022-01-01T04:32:17',
    timestamp_stop: '2022-01-01T04:32:22',
    quantity: 51.8,
    vehicle: 'DEM555',
    id: 134252,
    tank_volume_start: 26328.199999999964,
    tank_volume_stop: 26276.399999999965,
  },
  {
    timestamp_start: '2022-01-01T04:32:37',
    timestamp_stop: '2022-01-01T04:32:42',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 134254,
    tank_volume_start: 26276.399999999965,
    tank_volume_stop: 26221.199999999964,
  },
  {
    timestamp_start: '2022-01-01T04:32:57',
    timestamp_stop: '2022-01-01T04:33:03',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 134256,
    tank_volume_start: 26221.199999999964,
    tank_volume_stop: 26163.999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:33:18',
    timestamp_stop: '2022-01-01T04:33:23',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 134258,
    tank_volume_start: 26163.999999999964,
    tank_volume_stop: 26112.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:33:38',
    timestamp_stop: '2022-01-01T04:33:44',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 134260,
    tank_volume_start: 26112.599999999962,
    tank_volume_stop: 26055.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:33:59',
    timestamp_stop: '2022-01-01T04:34:05',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 134262,
    tank_volume_start: 26055.599999999962,
    tank_volume_stop: 25996.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:34:20',
    timestamp_stop: '2022-01-01T04:34:25',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 134264,
    tank_volume_start: 25996.799999999963,
    tank_volume_stop: 25942.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:34:40',
    timestamp_stop: '2022-01-01T04:34:45',
    quantity: 46.7,
    vehicle: 'DEM555',
    id: 134266,
    tank_volume_start: 25942.099999999962,
    tank_volume_stop: 25895.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:35:00',
    timestamp_stop: '2022-01-01T04:35:06',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 134268,
    tank_volume_start: 25895.39999999996,
    tank_volume_stop: 25836.299999999963,
  },
  {
    timestamp_start: '2022-01-01T04:35:21',
    timestamp_stop: '2022-01-01T04:35:27',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 134270,
    tank_volume_start: 25836.299999999963,
    tank_volume_stop: 25777.999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:35:42',
    timestamp_stop: '2022-01-01T04:35:47',
    quantity: 50.0,
    vehicle: 'DEM888',
    id: 134272,
    tank_volume_start: 25777.999999999964,
    tank_volume_stop: 25727.999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:36:02',
    timestamp_stop: '2022-01-01T04:36:07',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 134274,
    tank_volume_start: 25727.999999999964,
    tank_volume_stop: 25677.399999999965,
  },
  {
    timestamp_start: '2022-01-01T04:36:22',
    timestamp_stop: '2022-01-01T04:36:27',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 134276,
    tank_volume_start: 25677.399999999965,
    tank_volume_stop: 25621.699999999964,
  },
  {
    timestamp_start: '2022-01-01T04:36:42',
    timestamp_stop: '2022-01-01T04:36:48',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 134278,
    tank_volume_start: 25621.699999999964,
    tank_volume_stop: 25564.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:37:03',
    timestamp_stop: '2022-01-01T04:37:09',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 134280,
    tank_volume_start: 25564.799999999963,
    tank_volume_stop: 25509.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:37:24',
    timestamp_stop: '2022-01-01T04:37:29',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 134282,
    tank_volume_start: 25509.099999999962,
    tank_volume_stop: 25454.799999999963,
  },
  {
    timestamp_start: '2022-01-01T04:37:44',
    timestamp_stop: '2022-01-01T04:37:49',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 134284,
    tank_volume_start: 25454.799999999963,
    tank_volume_stop: 25402.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:38:04',
    timestamp_stop: '2022-01-01T04:38:09',
    quantity: 47.0,
    vehicle: 'DEM444',
    id: 134286,
    tank_volume_start: 25402.099999999962,
    tank_volume_stop: 25355.099999999962,
  },
  {
    timestamp_start: '2022-01-01T04:38:24',
    timestamp_stop: '2022-01-01T04:38:30',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 134288,
    tank_volume_start: 25355.099999999962,
    tank_volume_stop: 25292.19999999996,
  },
  {
    timestamp_start: '2022-01-01T04:38:45',
    timestamp_stop: '2022-01-01T04:38:51',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 134290,
    tank_volume_start: 25292.19999999996,
    tank_volume_stop: 25231.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:39:06',
    timestamp_stop: '2022-01-01T04:39:12',
    quantity: 51.9,
    vehicle: 'DEM777',
    id: 134292,
    tank_volume_start: 25231.29999999996,
    tank_volume_stop: 25179.399999999958,
  },
  {
    timestamp_start: '2022-01-01T04:39:27',
    timestamp_stop: '2022-01-01T04:39:32',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 134294,
    tank_volume_start: 25179.399999999958,
    tank_volume_stop: 25126.29999999996,
  },
  {
    timestamp_start: '2022-01-01T04:39:47',
    timestamp_stop: '2022-01-01T04:39:52',
    quantity: 51.8,
    vehicle: 'DEM777',
    id: 134296,
    tank_volume_start: 25126.29999999996,
    tank_volume_stop: 25074.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:40:07',
    timestamp_stop: '2022-01-01T04:40:12',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 134298,
    tank_volume_start: 25074.49999999996,
    tank_volume_stop: 25020.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:40:27',
    timestamp_stop: '2022-01-01T04:40:32',
    quantity: 49.3,
    vehicle: 'DEM111',
    id: 134300,
    tank_volume_start: 25020.89999999996,
    tank_volume_stop: 24971.599999999962,
  },
  {
    timestamp_start: '2022-01-01T04:40:47',
    timestamp_stop: '2022-01-01T04:40:54',
    quantity: 62.9,
    vehicle: 'DEM444',
    id: 134302,
    tank_volume_start: 24971.599999999962,
    tank_volume_stop: 24908.69999999996,
  },
  {
    timestamp_start: '2022-01-01T04:41:09',
    timestamp_stop: '2022-01-01T04:41:14',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 134304,
    tank_volume_start: 24908.69999999996,
    tank_volume_stop: 24852.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:41:29',
    timestamp_stop: '2022-01-01T04:41:35',
    quantity: 58.1,
    vehicle: 'DEM333',
    id: 134306,
    tank_volume_start: 24852.99999999996,
    tank_volume_stop: 24794.89999999996,
  },
  {
    timestamp_start: '2022-01-01T04:41:50',
    timestamp_stop: '2022-01-01T04:41:55',
    quantity: 53.5,
    vehicle: 'DEM000',
    id: 134308,
    tank_volume_start: 24794.89999999996,
    tank_volume_stop: 24741.39999999996,
  },
  {
    timestamp_start: '2022-01-01T04:42:10',
    timestamp_stop: '2022-01-01T04:42:16',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 134310,
    tank_volume_start: 24741.39999999996,
    tank_volume_stop: 24688.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:42:31',
    timestamp_stop: '2022-01-01T04:42:37',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 134312,
    tank_volume_start: 24688.99999999996,
    tank_volume_stop: 24628.99999999996,
  },
  {
    timestamp_start: '2022-01-01T04:42:52',
    timestamp_stop: '2022-01-01T04:42:58',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 134314,
    tank_volume_start: 24628.99999999996,
    tank_volume_stop: 24569.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:43:13',
    timestamp_stop: '2022-01-01T04:43:19',
    quantity: 61.6,
    vehicle: 'DEM666',
    id: 134316,
    tank_volume_start: 24569.09999999996,
    tank_volume_stop: 24507.49999999996,
  },
  {
    timestamp_start: '2022-01-01T04:43:34',
    timestamp_stop: '2022-01-01T04:43:39',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 134318,
    tank_volume_start: 24507.49999999996,
    tank_volume_stop: 24450.09999999996,
  },
  {
    timestamp_start: '2022-01-01T04:43:54',
    timestamp_stop: '2022-01-01T04:44:00',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 134320,
    tank_volume_start: 24450.09999999996,
    tank_volume_stop: 24393.699999999957,
  },
  {
    timestamp_start: '2022-01-01T04:44:15',
    timestamp_stop: '2022-01-01T04:44:20',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 134322,
    tank_volume_start: 24393.699999999957,
    tank_volume_stop: 24343.999999999956,
  },
  {
    timestamp_start: '2022-01-01T04:44:35',
    timestamp_stop: '2022-01-01T04:44:40',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 134324,
    tank_volume_start: 24343.999999999956,
    tank_volume_stop: 24295.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:44:55',
    timestamp_stop: '2022-01-01T04:45:01',
    quantity: 56.7,
    vehicle: 'DEM444',
    id: 134326,
    tank_volume_start: 24295.599999999955,
    tank_volume_stop: 24238.899999999954,
  },
  {
    timestamp_start: '2022-01-01T04:45:16',
    timestamp_stop: '2022-01-01T04:45:20',
    quantity: 47.1,
    vehicle: 'DEM000',
    id: 134328,
    tank_volume_start: 24238.899999999954,
    tank_volume_stop: 24191.799999999956,
  },
  {
    timestamp_start: '2022-01-01T04:45:35',
    timestamp_stop: '2022-01-01T04:45:41',
    quantity: 59.2,
    vehicle: 'DEM000',
    id: 134330,
    tank_volume_start: 24191.799999999956,
    tank_volume_stop: 24132.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:45:56',
    timestamp_stop: '2022-01-01T04:46:02',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 134332,
    tank_volume_start: 24132.599999999955,
    tank_volume_stop: 24078.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:46:17',
    timestamp_stop: '2022-01-01T04:46:22',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 134334,
    tank_volume_start: 24078.699999999953,
    tank_volume_stop: 24024.799999999952,
  },
  {
    timestamp_start: '2022-01-01T04:46:37',
    timestamp_stop: '2022-01-01T04:46:43',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 134336,
    tank_volume_start: 24024.799999999952,
    tank_volume_stop: 23965.799999999952,
  },
  {
    timestamp_start: '2022-01-01T04:46:58',
    timestamp_stop: '2022-01-01T04:47:04',
    quantity: 59.6,
    vehicle: 'DEM000',
    id: 134338,
    tank_volume_start: 23965.799999999952,
    tank_volume_stop: 23906.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:47:19',
    timestamp_stop: '2022-01-01T04:47:24',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 134340,
    tank_volume_start: 23906.199999999953,
    tank_volume_stop: 23851.899999999954,
  },
  {
    timestamp_start: '2022-01-01T04:47:39',
    timestamp_stop: '2022-01-01T04:47:44',
    quantity: 51.7,
    vehicle: 'DEM666',
    id: 134342,
    tank_volume_start: 23851.899999999954,
    tank_volume_stop: 23800.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:47:59',
    timestamp_stop: '2022-01-01T04:48:04',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 134344,
    tank_volume_start: 23800.199999999953,
    tank_volume_stop: 23750.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:48:19',
    timestamp_stop: '2022-01-01T04:48:25',
    quantity: 58.6,
    vehicle: 'DEM222',
    id: 134346,
    tank_volume_start: 23750.199999999953,
    tank_volume_stop: 23691.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:48:40',
    timestamp_stop: '2022-01-01T04:48:45',
    quantity: 49.8,
    vehicle: 'DEM888',
    id: 134348,
    tank_volume_start: 23691.599999999955,
    tank_volume_stop: 23641.799999999956,
  },
  {
    timestamp_start: '2022-01-01T04:49:00',
    timestamp_stop: '2022-01-01T04:49:06',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 134350,
    tank_volume_start: 23641.799999999956,
    tank_volume_stop: 23582.399999999954,
  },
  {
    timestamp_start: '2022-01-01T04:49:21',
    timestamp_stop: '2022-01-01T04:49:27',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 134352,
    tank_volume_start: 23582.399999999954,
    tank_volume_stop: 23529.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:49:42',
    timestamp_stop: '2022-01-01T04:49:47',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 134354,
    tank_volume_start: 23529.199999999953,
    tank_volume_stop: 23476.399999999954,
  },
  {
    timestamp_start: '2022-01-01T04:50:02',
    timestamp_stop: '2022-01-01T04:50:08',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 134356,
    tank_volume_start: 23476.399999999954,
    tank_volume_stop: 23419.899999999954,
  },
  {
    timestamp_start: '2022-01-01T04:50:23',
    timestamp_stop: '2022-01-01T04:50:28',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 134358,
    tank_volume_start: 23419.899999999954,
    tank_volume_stop: 23365.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:50:43',
    timestamp_stop: '2022-01-01T04:50:48',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 134360,
    tank_volume_start: 23365.599999999955,
    tank_volume_stop: 23313.099999999955,
  },
  {
    timestamp_start: '2022-01-01T04:51:03',
    timestamp_stop: '2022-01-01T04:51:09',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 134362,
    tank_volume_start: 23313.099999999955,
    tank_volume_stop: 23254.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:51:24',
    timestamp_stop: '2022-01-01T04:51:29',
    quantity: 49.4,
    vehicle: 'DEM222',
    id: 134364,
    tank_volume_start: 23254.699999999953,
    tank_volume_stop: 23205.299999999952,
  },
  {
    timestamp_start: '2022-01-01T04:51:44',
    timestamp_stop: '2022-01-01T04:51:50',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 134366,
    tank_volume_start: 23205.299999999952,
    tank_volume_stop: 23147.299999999952,
  },
  {
    timestamp_start: '2022-01-01T04:52:05',
    timestamp_stop: '2022-01-01T04:52:11',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 134368,
    tank_volume_start: 23147.299999999952,
    tank_volume_stop: 23086.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:52:26',
    timestamp_stop: '2022-01-01T04:52:32',
    quantity: 64.5,
    vehicle: 'DEM888',
    id: 134370,
    tank_volume_start: 23086.699999999953,
    tank_volume_stop: 23022.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:52:47',
    timestamp_stop: '2022-01-01T04:52:53',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 134372,
    tank_volume_start: 23022.199999999953,
    tank_volume_stop: 22969.799999999952,
  },
  {
    timestamp_start: '2022-01-01T04:53:08',
    timestamp_stop: '2022-01-01T04:53:13',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 134374,
    tank_volume_start: 22969.799999999952,
    tank_volume_stop: 22915.09999999995,
  },
  {
    timestamp_start: '2022-01-01T04:53:28',
    timestamp_stop: '2022-01-01T04:53:33',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 134376,
    tank_volume_start: 22915.09999999995,
    tank_volume_stop: 22862.59999999995,
  },
  {
    timestamp_start: '2022-01-01T04:53:48',
    timestamp_stop: '2022-01-01T04:53:53',
    quantity: 49.6,
    vehicle: 'DEM555',
    id: 134378,
    tank_volume_start: 22862.59999999995,
    tank_volume_stop: 22812.999999999953,
  },
  {
    timestamp_start: '2022-01-01T04:54:08',
    timestamp_stop: '2022-01-01T04:54:14',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 134380,
    tank_volume_start: 22812.999999999953,
    tank_volume_stop: 22758.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:54:29',
    timestamp_stop: '2022-01-01T04:54:34',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 134382,
    tank_volume_start: 22758.199999999953,
    tank_volume_stop: 22701.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:54:49',
    timestamp_stop: '2022-01-01T04:54:56',
    quantity: 62.9,
    vehicle: 'DEM222',
    id: 134384,
    tank_volume_start: 22701.599999999955,
    tank_volume_stop: 22638.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:55:11',
    timestamp_stop: '2022-01-01T04:55:16',
    quantity: 49.4,
    vehicle: 'DEM000',
    id: 134386,
    tank_volume_start: 22638.699999999953,
    tank_volume_stop: 22589.299999999952,
  },
  {
    timestamp_start: '2022-01-01T04:55:31',
    timestamp_stop: '2022-01-01T04:55:36',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 134388,
    tank_volume_start: 22589.299999999952,
    tank_volume_stop: 22537.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:55:51',
    timestamp_stop: '2022-01-01T04:55:56',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 134390,
    tank_volume_start: 22537.199999999953,
    tank_volume_stop: 22482.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:56:11',
    timestamp_stop: '2022-01-01T04:56:17',
    quantity: 58.8,
    vehicle: 'DEM444',
    id: 134392,
    tank_volume_start: 22482.699999999953,
    tank_volume_stop: 22423.899999999954,
  },
  {
    timestamp_start: '2022-01-01T04:56:32',
    timestamp_stop: '2022-01-01T04:56:38',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 134394,
    tank_volume_start: 22423.899999999954,
    tank_volume_stop: 22368.199999999953,
  },
  {
    timestamp_start: '2022-01-01T04:56:53',
    timestamp_stop: '2022-01-01T04:56:57',
    quantity: 46.5,
    vehicle: 'DEM888',
    id: 134396,
    tank_volume_start: 22368.199999999953,
    tank_volume_stop: 22321.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:57:12',
    timestamp_stop: '2022-01-01T04:57:17',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 134398,
    tank_volume_start: 22321.699999999953,
    tank_volume_stop: 22273.299999999952,
  },
  {
    timestamp_start: '2022-01-01T04:57:32',
    timestamp_stop: '2022-01-01T04:57:38',
    quantity: 59.7,
    vehicle: 'DEM666',
    id: 134400,
    tank_volume_start: 22273.299999999952,
    tank_volume_stop: 22213.59999999995,
  },
  {
    timestamp_start: '2022-01-01T04:57:53',
    timestamp_stop: '2022-01-01T04:58:00',
    quantity: 64.1,
    vehicle: 'DEM222',
    id: 134402,
    tank_volume_start: 22213.59999999995,
    tank_volume_stop: 22149.499999999953,
  },
  {
    timestamp_start: '2022-01-01T04:58:15',
    timestamp_stop: '2022-01-01T04:58:19',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 134404,
    tank_volume_start: 22149.499999999953,
    tank_volume_stop: 22103.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:58:34',
    timestamp_stop: '2022-01-01T04:58:39',
    quantity: 49.1,
    vehicle: 'DEM111',
    id: 134406,
    tank_volume_start: 22103.699999999953,
    tank_volume_stop: 22054.599999999955,
  },
  {
    timestamp_start: '2022-01-01T04:58:54',
    timestamp_stop: '2022-01-01T04:59:00',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 134408,
    tank_volume_start: 22054.599999999955,
    tank_volume_stop: 21999.399999999954,
  },
  {
    timestamp_start: '2022-01-01T04:59:15',
    timestamp_stop: '2022-01-01T04:59:19',
    quantity: 48.7,
    vehicle: 'DEM777',
    id: 134410,
    tank_volume_start: 21999.399999999954,
    tank_volume_stop: 21950.699999999953,
  },
  {
    timestamp_start: '2022-01-01T04:59:34',
    timestamp_stop: '2022-01-01T04:59:39',
    quantity: 50.4,
    vehicle: 'DEM555',
    id: 134412,
    tank_volume_start: 21950.699999999953,
    tank_volume_stop: 21900.299999999952,
  },
  {
    timestamp_start: '2022-01-01T04:59:54',
    timestamp_stop: '2022-01-01T04:59:59',
    quantity: 49.8,
    vehicle: 'DEM555',
    id: 134414,
    tank_volume_start: 21900.299999999952,
    tank_volume_stop: 21850.499999999953,
  },
  {
    timestamp_start: '2022-01-01T05:00:14',
    timestamp_stop: '2022-01-01T05:00:21',
    quantity: 63.6,
    vehicle: 'DEM000',
    id: 134416,
    tank_volume_start: 21850.499999999953,
    tank_volume_stop: 21786.899999999954,
  },
  {
    timestamp_start: '2022-01-01T05:00:36',
    timestamp_stop: '2022-01-01T05:00:41',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 134418,
    tank_volume_start: 21786.899999999954,
    tank_volume_stop: 21736.999999999953,
  },
  {
    timestamp_start: '2022-01-01T05:00:56',
    timestamp_stop: '2022-01-01T05:01:01',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 134420,
    tank_volume_start: 21736.999999999953,
    tank_volume_stop: 21680.999999999953,
  },
  {
    timestamp_start: '2022-01-01T05:01:16',
    timestamp_stop: '2022-01-01T05:01:22',
    quantity: 52.2,
    vehicle: 'DEM777',
    id: 134422,
    tank_volume_start: 21680.999999999953,
    tank_volume_stop: 21628.799999999952,
  },
  {
    timestamp_start: '2022-01-01T05:01:37',
    timestamp_stop: '2022-01-01T05:01:42',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 134424,
    tank_volume_start: 21628.799999999952,
    tank_volume_stop: 21577.39999999995,
  },
  {
    timestamp_start: '2022-01-01T05:01:57',
    timestamp_stop: '2022-01-01T05:02:02',
    quantity: 55.3,
    vehicle: 'DEM777',
    id: 134426,
    tank_volume_start: 21577.39999999995,
    tank_volume_stop: 21522.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:02:17',
    timestamp_stop: '2022-01-01T05:02:23',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 134428,
    tank_volume_start: 21522.09999999995,
    tank_volume_stop: 21466.299999999952,
  },
  {
    timestamp_start: '2022-01-01T05:02:38',
    timestamp_stop: '2022-01-01T05:02:43',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 134430,
    tank_volume_start: 21466.299999999952,
    tank_volume_stop: 21410.39999999995,
  },
  {
    timestamp_start: '2022-01-01T05:02:58',
    timestamp_stop: '2022-01-01T05:03:03',
    quantity: 50.0,
    vehicle: 'DEM333',
    id: 134432,
    tank_volume_start: 21410.39999999995,
    tank_volume_stop: 21360.39999999995,
  },
  {
    timestamp_start: '2022-01-01T05:03:18',
    timestamp_stop: '2022-01-01T05:03:25',
    quantity: 64.1,
    vehicle: 'DEM555',
    id: 134434,
    tank_volume_start: 21360.39999999995,
    tank_volume_stop: 21296.299999999952,
  },
  {
    timestamp_start: '2022-01-01T05:03:40',
    timestamp_stop: '2022-01-01T05:03:46',
    quantity: 59.2,
    vehicle: 'DEM555',
    id: 134436,
    tank_volume_start: 21296.299999999952,
    tank_volume_stop: 21237.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:04:01',
    timestamp_stop: '2022-01-01T05:04:07',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 134438,
    tank_volume_start: 21237.09999999995,
    tank_volume_stop: 21178.499999999953,
  },
  {
    timestamp_start: '2022-01-01T05:04:22',
    timestamp_stop: '2022-01-01T05:04:27',
    quantity: 55.4,
    vehicle: 'DEM444',
    id: 134440,
    tank_volume_start: 21178.499999999953,
    tank_volume_stop: 21123.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:04:42',
    timestamp_stop: '2022-01-01T05:04:47',
    quantity: 48.4,
    vehicle: 'DEM444',
    id: 134442,
    tank_volume_start: 21123.09999999995,
    tank_volume_stop: 21074.69999999995,
  },
  {
    timestamp_start: '2022-01-01T05:05:02',
    timestamp_stop: '2022-01-01T05:05:08',
    quantity: 59.3,
    vehicle: 'DEM333',
    id: 134444,
    tank_volume_start: 21074.69999999995,
    tank_volume_stop: 21015.39999999995,
  },
  {
    timestamp_start: '2022-01-01T05:05:23',
    timestamp_stop: '2022-01-01T05:05:28',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 134446,
    tank_volume_start: 21015.39999999995,
    tank_volume_stop: 20962.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:05:43',
    timestamp_stop: '2022-01-01T05:05:49',
    quantity: 57.9,
    vehicle: 'DEM444',
    id: 134448,
    tank_volume_start: 20962.49999999995,
    tank_volume_stop: 20904.599999999948,
  },
  {
    timestamp_start: '2022-01-01T05:06:04',
    timestamp_stop: '2022-01-01T05:06:10',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 134450,
    tank_volume_start: 20904.599999999948,
    tank_volume_stop: 20848.899999999947,
  },
  {
    timestamp_start: '2022-01-01T05:06:25',
    timestamp_stop: '2022-01-01T05:06:30',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 134452,
    tank_volume_start: 20848.899999999947,
    tank_volume_stop: 20797.899999999947,
  },
  {
    timestamp_start: '2022-01-01T05:06:45',
    timestamp_stop: '2022-01-01T05:06:50',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 134454,
    tank_volume_start: 20797.899999999947,
    tank_volume_stop: 20741.099999999948,
  },
  {
    timestamp_start: '2022-01-01T05:07:05',
    timestamp_stop: '2022-01-01T05:07:12',
    quantity: 64.3,
    vehicle: 'DEM555',
    id: 134456,
    tank_volume_start: 20741.099999999948,
    tank_volume_stop: 20676.79999999995,
  },
  {
    timestamp_start: '2022-01-01T05:07:27',
    timestamp_stop: '2022-01-01T05:07:32',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 134458,
    tank_volume_start: 20676.79999999995,
    tank_volume_stop: 20621.099999999948,
  },
  {
    timestamp_start: '2022-01-01T05:07:47',
    timestamp_stop: '2022-01-01T05:07:53',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 134460,
    tank_volume_start: 20621.099999999948,
    tank_volume_stop: 20565.899999999947,
  },
  {
    timestamp_start: '2022-01-01T05:08:08',
    timestamp_stop: '2022-01-01T05:08:13',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 134462,
    tank_volume_start: 20565.899999999947,
    tank_volume_stop: 20511.999999999945,
  },
  {
    timestamp_start: '2022-01-01T05:08:28',
    timestamp_stop: '2022-01-01T05:08:34',
    quantity: 60.9,
    vehicle: 'DEM111',
    id: 134464,
    tank_volume_start: 20511.999999999945,
    tank_volume_stop: 20451.099999999944,
  },
  {
    timestamp_start: '2022-01-01T05:08:49',
    timestamp_stop: '2022-01-01T05:08:55',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 134466,
    tank_volume_start: 20451.099999999944,
    tank_volume_stop: 20396.699999999943,
  },
  {
    timestamp_start: '2022-01-01T05:09:10',
    timestamp_stop: '2022-01-01T05:09:15',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 134468,
    tank_volume_start: 20396.699999999943,
    tank_volume_stop: 20342.399999999943,
  },
  {
    timestamp_start: '2022-01-01T05:09:30',
    timestamp_stop: '2022-01-01T05:09:35',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 134470,
    tank_volume_start: 20342.399999999943,
    tank_volume_stop: 20292.399999999943,
  },
  {
    timestamp_start: '2022-01-01T05:09:50',
    timestamp_stop: '2022-01-01T05:09:56',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 134472,
    tank_volume_start: 20292.399999999943,
    tank_volume_stop: 20235.49999999994,
  },
  {
    timestamp_start: '2022-01-01T05:10:11',
    timestamp_stop: '2022-01-01T05:10:15',
    quantity: 45.4,
    vehicle: 'DEM666',
    id: 134474,
    tank_volume_start: 20235.49999999994,
    tank_volume_stop: 20190.09999999994,
  },
  {
    timestamp_start: '2022-01-01T05:10:30',
    timestamp_stop: '2022-01-01T05:10:36',
    quantity: 56.3,
    vehicle: 'DEM666',
    id: 134476,
    tank_volume_start: 20190.09999999994,
    tank_volume_stop: 20133.79999999994,
  },
  {
    timestamp_start: '2022-01-01T05:10:51',
    timestamp_stop: '2022-01-01T05:10:57',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 134478,
    tank_volume_start: 20133.79999999994,
    tank_volume_stop: 20078.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:11:12',
    timestamp_stop: '2022-01-01T05:11:17',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 134480,
    tank_volume_start: 20078.59999999994,
    tank_volume_stop: 20022.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:11:32',
    timestamp_stop: '2022-01-01T05:11:39',
    quantity: 63.9,
    vehicle: 'DEM111',
    id: 134482,
    tank_volume_start: 20022.59999999994,
    tank_volume_stop: 19958.69999999994,
  },
  {
    timestamp_start: '2022-01-01T05:11:54',
    timestamp_stop: '2022-01-01T05:11:58',
    quantity: 48.6,
    vehicle: 'DEM000',
    id: 134484,
    tank_volume_start: 39958.69999999994,
    tank_volume_stop: 39910.09999999994,
  },
  {
    timestamp_start: '2022-01-01T05:12:13',
    timestamp_stop: '2022-01-01T05:12:19',
    quantity: 50.9,
    vehicle: 'DEM444',
    id: 134486,
    tank_volume_start: 39910.09999999994,
    tank_volume_stop: 39859.19999999994,
  },
  {
    timestamp_start: '2022-01-01T05:12:34',
    timestamp_stop: '2022-01-01T05:12:39',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 134488,
    tank_volume_start: 39859.19999999994,
    tank_volume_stop: 39804.69999999994,
  },
  {
    timestamp_start: '2022-01-01T05:12:54',
    timestamp_stop: '2022-01-01T05:13:00',
    quantity: 61.3,
    vehicle: 'DEM333',
    id: 134490,
    tank_volume_start: 39804.69999999994,
    tank_volume_stop: 39743.399999999936,
  },
  {
    timestamp_start: '2022-01-01T05:13:15',
    timestamp_stop: '2022-01-01T05:13:21',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 134492,
    tank_volume_start: 39743.399999999936,
    tank_volume_stop: 39687.29999999994,
  },
  {
    timestamp_start: '2022-01-01T05:13:36',
    timestamp_stop: '2022-01-01T05:13:41',
    quantity: 49.2,
    vehicle: 'DEM444',
    id: 134494,
    tank_volume_start: 39687.29999999994,
    tank_volume_stop: 39638.09999999994,
  },
  {
    timestamp_start: '2022-01-01T05:13:56',
    timestamp_stop: '2022-01-01T05:14:01',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 134496,
    tank_volume_start: 39638.09999999994,
    tank_volume_stop: 39586.99999999994,
  },
  {
    timestamp_start: '2022-01-01T05:14:16',
    timestamp_stop: '2022-01-01T05:14:21',
    quantity: 47.1,
    vehicle: 'DEM000',
    id: 134498,
    tank_volume_start: 39586.99999999994,
    tank_volume_stop: 39539.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:14:36',
    timestamp_stop: '2022-01-01T05:14:42',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 134500,
    tank_volume_start: 39539.89999999994,
    tank_volume_stop: 39479.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:14:57',
    timestamp_stop: '2022-01-01T05:15:03',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 134502,
    tank_volume_start: 39479.299999999945,
    tank_volume_stop: 39417.99999999994,
  },
  {
    timestamp_start: '2022-01-01T05:15:18',
    timestamp_stop: '2022-01-01T05:15:23',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 134504,
    tank_volume_start: 39417.99999999994,
    tank_volume_stop: 39364.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:15:38',
    timestamp_stop: '2022-01-01T05:15:44',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 134506,
    tank_volume_start: 39364.59999999994,
    tank_volume_stop: 39306.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:15:59',
    timestamp_stop: '2022-01-01T05:16:04',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 134508,
    tank_volume_start: 39306.89999999994,
    tank_volume_stop: 39254.49999999994,
  },
  {
    timestamp_start: '2022-01-01T05:16:19',
    timestamp_stop: '2022-01-01T05:16:25',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 134510,
    tank_volume_start: 39254.49999999994,
    tank_volume_stop: 39195.49999999994,
  },
  {
    timestamp_start: '2022-01-01T05:16:40',
    timestamp_stop: '2022-01-01T05:16:45',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 134512,
    tank_volume_start: 39195.49999999994,
    tank_volume_stop: 39142.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:17:00',
    timestamp_stop: '2022-01-01T05:17:06',
    quantity: 59.7,
    vehicle: 'DEM000',
    id: 134514,
    tank_volume_start: 39142.89999999994,
    tank_volume_stop: 39083.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:17:21',
    timestamp_stop: '2022-01-01T05:17:27',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 134516,
    tank_volume_start: 39083.199999999946,
    tank_volume_stop: 39023.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:17:42',
    timestamp_stop: '2022-01-01T05:17:48',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 134518,
    tank_volume_start: 39023.89999999994,
    tank_volume_stop: 38969.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:18:03',
    timestamp_stop: '2022-01-01T05:18:08',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 134520,
    tank_volume_start: 38969.39999999994,
    tank_volume_stop: 38910.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:18:23',
    timestamp_stop: '2022-01-01T05:18:29',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 134522,
    tank_volume_start: 38910.89999999994,
    tank_volume_stop: 38855.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:18:44',
    timestamp_stop: '2022-01-01T05:18:50',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 134524,
    tank_volume_start: 38855.39999999994,
    tank_volume_stop: 38798.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:19:05',
    timestamp_stop: '2022-01-01T05:19:11',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 134526,
    tank_volume_start: 38798.39999999994,
    tank_volume_stop: 38738.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:19:26',
    timestamp_stop: '2022-01-01T05:19:31',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 134528,
    tank_volume_start: 38738.89999999994,
    tank_volume_stop: 38684.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:19:46',
    timestamp_stop: '2022-01-01T05:19:52',
    quantity: 60.7,
    vehicle: 'DEM000',
    id: 134530,
    tank_volume_start: 38684.699999999946,
    tank_volume_stop: 38623.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:20:07',
    timestamp_stop: '2022-01-01T05:20:13',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 134532,
    tank_volume_start: 38623.99999999995,
    tank_volume_stop: 38568.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:20:28',
    timestamp_stop: '2022-01-01T05:20:33',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 134534,
    tank_volume_start: 38568.699999999946,
    tank_volume_stop: 38512.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:20:48',
    timestamp_stop: '2022-01-01T05:20:54',
    quantity: 53.8,
    vehicle: 'DEM000',
    id: 134536,
    tank_volume_start: 38512.799999999945,
    tank_volume_stop: 38458.99999999994,
  },
  {
    timestamp_start: '2022-01-01T05:21:09',
    timestamp_stop: '2022-01-01T05:21:14',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 134538,
    tank_volume_start: 38458.99999999994,
    tank_volume_stop: 38407.49999999994,
  },
  {
    timestamp_start: '2022-01-01T05:21:29',
    timestamp_stop: '2022-01-01T05:21:34',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 134540,
    tank_volume_start: 38407.49999999994,
    tank_volume_stop: 38354.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:21:49',
    timestamp_stop: '2022-01-01T05:21:54',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 134542,
    tank_volume_start: 38354.299999999945,
    tank_volume_stop: 38302.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:22:09',
    timestamp_stop: '2022-01-01T05:22:15',
    quantity: 60.3,
    vehicle: 'DEM777',
    id: 134544,
    tank_volume_start: 38302.699999999946,
    tank_volume_stop: 38242.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:22:30',
    timestamp_stop: '2022-01-01T05:22:35',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 134546,
    tank_volume_start: 38242.39999999994,
    tank_volume_stop: 38193.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:22:50',
    timestamp_stop: '2022-01-01T05:22:55',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 134548,
    tank_volume_start: 38193.39999999994,
    tank_volume_stop: 38140.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:23:10',
    timestamp_stop: '2022-01-01T05:23:16',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 134550,
    tank_volume_start: 38140.699999999946,
    tank_volume_stop: 38087.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:23:31',
    timestamp_stop: '2022-01-01T05:23:36',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 134552,
    tank_volume_start: 38087.39999999994,
    tank_volume_stop: 38033.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:23:51',
    timestamp_stop: '2022-01-01T05:23:56',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 134554,
    tank_volume_start: 38033.699999999946,
    tank_volume_stop: 37981.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:24:11',
    timestamp_stop: '2022-01-01T05:24:16',
    quantity: 47.5,
    vehicle: 'DEM666',
    id: 134556,
    tank_volume_start: 37981.09999999995,
    tank_volume_stop: 37933.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:24:31',
    timestamp_stop: '2022-01-01T05:24:37',
    quantity: 55.4,
    vehicle: 'DEM888',
    id: 134558,
    tank_volume_start: 37933.59999999995,
    tank_volume_stop: 37878.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:24:52',
    timestamp_stop: '2022-01-01T05:24:57',
    quantity: 51.6,
    vehicle: 'DEM555',
    id: 134560,
    tank_volume_start: 37878.199999999946,
    tank_volume_stop: 37826.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:25:12',
    timestamp_stop: '2022-01-01T05:25:18',
    quantity: 62.4,
    vehicle: 'DEM222',
    id: 134562,
    tank_volume_start: 37826.59999999995,
    tank_volume_stop: 37764.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:25:33',
    timestamp_stop: '2022-01-01T05:25:39',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 134564,
    tank_volume_start: 37764.199999999946,
    tank_volume_stop: 37703.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:25:54',
    timestamp_stop: '2022-01-01T05:25:59',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 134566,
    tank_volume_start: 37703.799999999945,
    tank_volume_stop: 37654.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:26:14',
    timestamp_stop: '2022-01-01T05:26:19',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 134568,
    tank_volume_start: 37654.299999999945,
    tank_volume_stop: 37608.49999999994,
  },
  {
    timestamp_start: '2022-01-01T05:26:34',
    timestamp_stop: '2022-01-01T05:26:39',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 134570,
    tank_volume_start: 37608.49999999994,
    tank_volume_stop: 37550.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:26:54',
    timestamp_stop: '2022-01-01T05:27:00',
    quantity: 58.8,
    vehicle: 'DEM777',
    id: 134572,
    tank_volume_start: 37550.89999999994,
    tank_volume_stop: 37492.09999999994,
  },
  {
    timestamp_start: '2022-01-01T05:27:15',
    timestamp_stop: '2022-01-01T05:27:22',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 134574,
    tank_volume_start: 37492.09999999994,
    tank_volume_stop: 37429.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:27:37',
    timestamp_stop: '2022-01-01T05:27:42',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 134576,
    tank_volume_start: 37429.89999999994,
    tank_volume_stop: 37372.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:27:57',
    timestamp_stop: '2022-01-01T05:28:02',
    quantity: 51.5,
    vehicle: 'DEM222',
    id: 134578,
    tank_volume_start: 37372.199999999946,
    tank_volume_stop: 37320.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:28:17',
    timestamp_stop: '2022-01-01T05:28:24',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 134580,
    tank_volume_start: 37320.699999999946,
    tank_volume_stop: 37258.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:28:39',
    timestamp_stop: '2022-01-01T05:28:44',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 134582,
    tank_volume_start: 37258.699999999946,
    tank_volume_stop: 37204.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:28:59',
    timestamp_stop: '2022-01-01T05:29:05',
    quantity: 59.7,
    vehicle: 'DEM666',
    id: 134584,
    tank_volume_start: 37204.39999999994,
    tank_volume_stop: 37144.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:29:20',
    timestamp_stop: '2022-01-01T05:29:26',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 134586,
    tank_volume_start: 37144.699999999946,
    tank_volume_stop: 37085.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:29:41',
    timestamp_stop: '2022-01-01T05:29:47',
    quantity: 55.8,
    vehicle: 'DEM333',
    id: 134588,
    tank_volume_start: 37085.199999999946,
    tank_volume_stop: 37029.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:30:02',
    timestamp_stop: '2022-01-01T05:30:07',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 134590,
    tank_volume_start: 37029.39999999994,
    tank_volume_stop: 36974.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:30:22',
    timestamp_stop: '2022-01-01T05:30:28',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 134592,
    tank_volume_start: 36974.199999999946,
    tank_volume_stop: 36918.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:30:43',
    timestamp_stop: '2022-01-01T05:30:48',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 134594,
    tank_volume_start: 36918.199999999946,
    tank_volume_stop: 36863.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:31:03',
    timestamp_stop: '2022-01-01T05:31:09',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 134596,
    tank_volume_start: 36863.89999999994,
    tank_volume_stop: 36808.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:31:24',
    timestamp_stop: '2022-01-01T05:31:30',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 134598,
    tank_volume_start: 36808.699999999946,
    tank_volume_stop: 36748.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:31:45',
    timestamp_stop: '2022-01-01T05:31:49',
    quantity: 47.6,
    vehicle: 'DEM777',
    id: 134600,
    tank_volume_start: 36748.699999999946,
    tank_volume_stop: 36701.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:32:04',
    timestamp_stop: '2022-01-01T05:32:09',
    quantity: 48.2,
    vehicle: 'DEM444',
    id: 134602,
    tank_volume_start: 36701.09999999995,
    tank_volume_stop: 36652.89999999995,
  },
  {
    timestamp_start: '2022-01-01T05:32:24',
    timestamp_stop: '2022-01-01T05:32:30',
    quantity: 52.9,
    vehicle: 'DEM333',
    id: 134604,
    tank_volume_start: 36652.89999999995,
    tank_volume_stop: 36599.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:32:45',
    timestamp_stop: '2022-01-01T05:32:50',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 134606,
    tank_volume_start: 36599.99999999995,
    tank_volume_stop: 36548.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:33:05',
    timestamp_stop: '2022-01-01T05:33:11',
    quantity: 61.8,
    vehicle: 'DEM333',
    id: 134608,
    tank_volume_start: 36548.59999999995,
    tank_volume_stop: 36486.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:33:26',
    timestamp_stop: '2022-01-01T05:33:31',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 134610,
    tank_volume_start: 36486.799999999945,
    tank_volume_stop: 36435.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:33:46',
    timestamp_stop: '2022-01-01T05:33:51',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 134612,
    tank_volume_start: 36435.39999999994,
    tank_volume_stop: 36384.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:34:06',
    timestamp_stop: '2022-01-01T05:34:11',
    quantity: 48.6,
    vehicle: 'DEM222',
    id: 134614,
    tank_volume_start: 36384.89999999994,
    tank_volume_stop: 36336.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:34:26',
    timestamp_stop: '2022-01-01T05:34:32',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 134616,
    tank_volume_start: 36336.299999999945,
    tank_volume_stop: 36279.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:34:47',
    timestamp_stop: '2022-01-01T05:34:52',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 134618,
    tank_volume_start: 36279.39999999994,
    tank_volume_stop: 36223.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:35:07',
    timestamp_stop: '2022-01-01T05:35:12',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 134620,
    tank_volume_start: 36223.59999999994,
    tank_volume_stop: 36174.69999999994,
  },
  {
    timestamp_start: '2022-01-01T05:35:27',
    timestamp_stop: '2022-01-01T05:35:32',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 134622,
    tank_volume_start: 36174.69999999994,
    tank_volume_stop: 36120.69999999994,
  },
  {
    timestamp_start: '2022-01-01T05:35:47',
    timestamp_stop: '2022-01-01T05:35:52',
    quantity: 48.5,
    vehicle: 'DEM111',
    id: 134624,
    tank_volume_start: 36120.69999999994,
    tank_volume_stop: 36072.19999999994,
  },
  {
    timestamp_start: '2022-01-01T05:36:07',
    timestamp_stop: '2022-01-01T05:36:13',
    quantity: 59.2,
    vehicle: 'DEM777',
    id: 134626,
    tank_volume_start: 36072.19999999994,
    tank_volume_stop: 36012.99999999994,
  },
  {
    timestamp_start: '2022-01-01T05:36:28',
    timestamp_stop: '2022-01-01T05:36:34',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 134628,
    tank_volume_start: 36012.99999999994,
    tank_volume_stop: 35951.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:36:49',
    timestamp_stop: '2022-01-01T05:36:54',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 134630,
    tank_volume_start: 35951.39999999994,
    tank_volume_stop: 35902.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:37:09',
    timestamp_stop: '2022-01-01T05:37:15',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 134632,
    tank_volume_start: 35902.299999999945,
    tank_volume_stop: 35848.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:37:30',
    timestamp_stop: '2022-01-01T05:37:36',
    quantity: 64.7,
    vehicle: 'DEM111',
    id: 134634,
    tank_volume_start: 35848.299999999945,
    tank_volume_stop: 35783.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:37:51',
    timestamp_stop: '2022-01-01T05:37:56',
    quantity: 50.1,
    vehicle: 'DEM888',
    id: 134636,
    tank_volume_start: 35783.59999999995,
    tank_volume_stop: 35733.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:38:11',
    timestamp_stop: '2022-01-01T05:38:16',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 134638,
    tank_volume_start: 35733.49999999995,
    tank_volume_stop: 35682.79999999995,
  },
  {
    timestamp_start: '2022-01-01T05:38:31',
    timestamp_stop: '2022-01-01T05:38:37',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 134640,
    tank_volume_start: 35682.79999999995,
    tank_volume_stop: 35623.39999999995,
  },
  {
    timestamp_start: '2022-01-01T05:38:52',
    timestamp_stop: '2022-01-01T05:38:58',
    quantity: 59.3,
    vehicle: 'DEM888',
    id: 134642,
    tank_volume_start: 35623.39999999995,
    tank_volume_stop: 35564.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:39:13',
    timestamp_stop: '2022-01-01T05:39:19',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 134644,
    tank_volume_start: 35564.09999999995,
    tank_volume_stop: 35505.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:39:34',
    timestamp_stop: '2022-01-01T05:39:40',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 134646,
    tank_volume_start: 35505.299999999945,
    tank_volume_stop: 35448.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:39:55',
    timestamp_stop: '2022-01-01T05:40:01',
    quantity: 59.0,
    vehicle: 'DEM666',
    id: 134648,
    tank_volume_start: 35448.199999999946,
    tank_volume_stop: 35389.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:40:16',
    timestamp_stop: '2022-01-01T05:40:21',
    quantity: 50.6,
    vehicle: 'DEM777',
    id: 134650,
    tank_volume_start: 35389.199999999946,
    tank_volume_stop: 35338.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:40:36',
    timestamp_stop: '2022-01-01T05:40:41',
    quantity: 49.0,
    vehicle: 'DEM444',
    id: 134652,
    tank_volume_start: 35338.59999999995,
    tank_volume_stop: 35289.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:40:56',
    timestamp_stop: '2022-01-01T05:41:02',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 134654,
    tank_volume_start: 35289.59999999995,
    tank_volume_stop: 35229.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:41:17',
    timestamp_stop: '2022-01-01T05:41:23',
    quantity: 60.0,
    vehicle: 'DEM555',
    id: 134656,
    tank_volume_start: 35229.49999999995,
    tank_volume_stop: 35169.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:41:38',
    timestamp_stop: '2022-01-01T05:41:43',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 134658,
    tank_volume_start: 35169.49999999995,
    tank_volume_stop: 35113.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:41:58',
    timestamp_stop: '2022-01-01T05:42:03',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 134660,
    tank_volume_start: 35113.99999999995,
    tank_volume_stop: 35064.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:42:18',
    timestamp_stop: '2022-01-01T05:42:23',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 134662,
    tank_volume_start: 35064.99999999995,
    tank_volume_stop: 35011.29999999995,
  },
  {
    timestamp_start: '2022-01-01T05:42:38',
    timestamp_stop: '2022-01-01T05:42:44',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 134664,
    tank_volume_start: 35011.29999999995,
    tank_volume_stop: 34956.89999999995,
  },
  {
    timestamp_start: '2022-01-01T05:42:59',
    timestamp_stop: '2022-01-01T05:43:04',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 134666,
    tank_volume_start: 34956.89999999995,
    tank_volume_stop: 34903.69999999995,
  },
  {
    timestamp_start: '2022-01-01T05:43:19',
    timestamp_stop: '2022-01-01T05:43:25',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 134668,
    tank_volume_start: 34903.69999999995,
    tank_volume_stop: 34848.89999999995,
  },
  {
    timestamp_start: '2022-01-01T05:43:40',
    timestamp_stop: '2022-01-01T05:43:45',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 134670,
    tank_volume_start: 34848.89999999995,
    tank_volume_stop: 34793.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:44:00',
    timestamp_stop: '2022-01-01T05:44:06',
    quantity: 57.6,
    vehicle: 'DEM333',
    id: 134672,
    tank_volume_start: 34793.59999999995,
    tank_volume_stop: 34735.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:44:21',
    timestamp_stop: '2022-01-01T05:44:27',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 134674,
    tank_volume_start: 34735.99999999995,
    tank_volume_stop: 34679.89999999995,
  },
  {
    timestamp_start: '2022-01-01T05:44:42',
    timestamp_stop: '2022-01-01T05:44:47',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 134676,
    tank_volume_start: 34679.89999999995,
    tank_volume_stop: 34627.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:45:02',
    timestamp_stop: '2022-01-01T05:45:07',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 134678,
    tank_volume_start: 34627.09999999995,
    tank_volume_stop: 34572.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:45:22',
    timestamp_stop: '2022-01-01T05:45:27',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 134680,
    tank_volume_start: 34572.299999999945,
    tank_volume_stop: 34520.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:45:42',
    timestamp_stop: '2022-01-01T05:45:48',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 134682,
    tank_volume_start: 34520.799999999945,
    tank_volume_stop: 34464.99999999994,
  },
  {
    timestamp_start: '2022-01-01T05:46:03',
    timestamp_stop: '2022-01-01T05:46:08',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 134684,
    tank_volume_start: 34464.99999999994,
    tank_volume_stop: 34410.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:46:23',
    timestamp_stop: '2022-01-01T05:46:30',
    quantity: 62.2,
    vehicle: 'DEM555',
    id: 134686,
    tank_volume_start: 34410.299999999945,
    tank_volume_stop: 34348.09999999995,
  },
  {
    timestamp_start: '2022-01-01T05:46:45',
    timestamp_stop: '2022-01-01T05:46:51',
    quantity: 60.3,
    vehicle: 'DEM888',
    id: 134688,
    tank_volume_start: 34348.09999999995,
    tank_volume_stop: 34287.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:47:06',
    timestamp_stop: '2022-01-01T05:47:11',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 134690,
    tank_volume_start: 34287.799999999945,
    tank_volume_stop: 34234.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:47:26',
    timestamp_stop: '2022-01-01T05:47:31',
    quantity: 51.6,
    vehicle: 'DEM333',
    id: 134692,
    tank_volume_start: 34234.299999999945,
    tank_volume_stop: 34182.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:47:46',
    timestamp_stop: '2022-01-01T05:47:51',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 134694,
    tank_volume_start: 34182.699999999946,
    tank_volume_stop: 34130.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:48:06',
    timestamp_stop: '2022-01-01T05:48:12',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 134696,
    tank_volume_start: 34130.89999999994,
    tank_volume_stop: 34075.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:48:27',
    timestamp_stop: '2022-01-01T05:48:33',
    quantity: 61.7,
    vehicle: 'DEM777',
    id: 134698,
    tank_volume_start: 34075.59999999994,
    tank_volume_stop: 34013.89999999994,
  },
  {
    timestamp_start: '2022-01-01T05:48:48',
    timestamp_stop: '2022-01-01T05:48:53',
    quantity: 49.2,
    vehicle: 'DEM222',
    id: 134700,
    tank_volume_start: 34013.89999999994,
    tank_volume_stop: 33964.699999999946,
  },
  {
    timestamp_start: '2022-01-01T05:49:08',
    timestamp_stop: '2022-01-01T05:49:14',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 134702,
    tank_volume_start: 33964.699999999946,
    tank_volume_stop: 33905.299999999945,
  },
  {
    timestamp_start: '2022-01-01T05:49:29',
    timestamp_stop: '2022-01-01T05:49:34',
    quantity: 51.5,
    vehicle: 'DEM222',
    id: 134704,
    tank_volume_start: 33905.299999999945,
    tank_volume_stop: 33853.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:49:49',
    timestamp_stop: '2022-01-01T05:49:55',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 134706,
    tank_volume_start: 33853.799999999945,
    tank_volume_stop: 33796.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:50:10',
    timestamp_stop: '2022-01-01T05:50:15',
    quantity: 55.8,
    vehicle: 'DEM777',
    id: 134708,
    tank_volume_start: 33796.59999999995,
    tank_volume_stop: 33740.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:50:30',
    timestamp_stop: '2022-01-01T05:50:36',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 134710,
    tank_volume_start: 33740.799999999945,
    tank_volume_stop: 33683.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:50:51',
    timestamp_stop: '2022-01-01T05:50:57',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 134712,
    tank_volume_start: 33683.59999999995,
    tank_volume_stop: 33624.89999999995,
  },
  {
    timestamp_start: '2022-01-01T05:51:12',
    timestamp_stop: '2022-01-01T05:51:17',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 134714,
    tank_volume_start: 33624.89999999995,
    tank_volume_stop: 33576.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:51:32',
    timestamp_stop: '2022-01-01T05:51:37',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 134716,
    tank_volume_start: 33576.49999999995,
    tank_volume_stop: 33520.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:51:52',
    timestamp_stop: '2022-01-01T05:51:58',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 134718,
    tank_volume_start: 33520.99999999995,
    tank_volume_stop: 33466.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:52:13',
    timestamp_stop: '2022-01-01T05:52:19',
    quantity: 60.3,
    vehicle: 'DEM222',
    id: 134720,
    tank_volume_start: 33466.49999999995,
    tank_volume_stop: 33406.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:52:34',
    timestamp_stop: '2022-01-01T05:52:39',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 134722,
    tank_volume_start: 33406.199999999946,
    tank_volume_stop: 33355.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:52:54',
    timestamp_stop: '2022-01-01T05:52:59',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 134724,
    tank_volume_start: 33355.59999999995,
    tank_volume_stop: 33304.99999999995,
  },
  {
    timestamp_start: '2022-01-01T05:53:14',
    timestamp_stop: '2022-01-01T05:53:19',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 134726,
    tank_volume_start: 33304.99999999995,
    tank_volume_stop: 33254.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:53:34',
    timestamp_stop: '2022-01-01T05:53:40',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 134728,
    tank_volume_start: 33254.199999999946,
    tank_volume_stop: 33198.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:53:55',
    timestamp_stop: '2022-01-01T05:54:00',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 134730,
    tank_volume_start: 33198.59999999995,
    tank_volume_stop: 33146.199999999946,
  },
  {
    timestamp_start: '2022-01-01T05:54:15',
    timestamp_stop: '2022-01-01T05:54:20',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 134732,
    tank_volume_start: 33146.199999999946,
    tank_volume_stop: 33092.49999999995,
  },
  {
    timestamp_start: '2022-01-01T05:54:35',
    timestamp_stop: '2022-01-01T05:54:40',
    quantity: 47.9,
    vehicle: 'DEM444',
    id: 134734,
    tank_volume_start: 33092.49999999995,
    tank_volume_stop: 33044.59999999995,
  },
  {
    timestamp_start: '2022-01-01T05:54:55',
    timestamp_stop: '2022-01-01T05:55:01',
    quantity: 60.8,
    vehicle: 'DEM000',
    id: 134736,
    tank_volume_start: 33044.59999999995,
    tank_volume_stop: 32983.799999999945,
  },
  {
    timestamp_start: '2022-01-01T05:55:16',
    timestamp_stop: '2022-01-01T05:55:22',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 134738,
    tank_volume_start: 32983.799999999945,
    tank_volume_stop: 32928.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:55:37',
    timestamp_stop: '2022-01-01T05:55:42',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 134740,
    tank_volume_start: 32928.39999999994,
    tank_volume_stop: 32870.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:55:57',
    timestamp_stop: '2022-01-01T05:56:03',
    quantity: 51.0,
    vehicle: 'DEM111',
    id: 134742,
    tank_volume_start: 32870.59999999994,
    tank_volume_stop: 32819.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:56:18',
    timestamp_stop: '2022-01-01T05:56:22',
    quantity: 45.0,
    vehicle: 'DEM888',
    id: 134744,
    tank_volume_start: 32819.59999999994,
    tank_volume_stop: 32774.59999999994,
  },
  {
    timestamp_start: '2022-01-01T05:56:37',
    timestamp_stop: '2022-01-01T05:56:42',
    quantity: 48.2,
    vehicle: 'DEM777',
    id: 134746,
    tank_volume_start: 32774.59999999994,
    tank_volume_stop: 32726.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:56:57',
    timestamp_stop: '2022-01-01T05:57:02',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 134748,
    tank_volume_start: 32726.39999999994,
    tank_volume_stop: 32670.39999999994,
  },
  {
    timestamp_start: '2022-01-01T05:57:17',
    timestamp_stop: '2022-01-01T05:57:22',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 134750,
    tank_volume_start: 32670.39999999994,
    tank_volume_stop: 32620.19999999994,
  },
  {
    timestamp_start: '2022-01-01T05:57:37',
    timestamp_stop: '2022-01-01T05:57:44',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 134752,
    tank_volume_start: 32620.19999999994,
    tank_volume_stop: 32559.999999999938,
  },
  {
    timestamp_start: '2022-01-01T05:57:59',
    timestamp_stop: '2022-01-01T05:58:05',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 134754,
    tank_volume_start: 32559.999999999938,
    tank_volume_stop: 32498.999999999938,
  },
  {
    timestamp_start: '2022-01-01T05:58:20',
    timestamp_stop: '2022-01-01T05:58:25',
    quantity: 58.4,
    vehicle: 'DEM444',
    id: 134756,
    tank_volume_start: 32498.999999999938,
    tank_volume_stop: 32440.599999999937,
  },
  {
    timestamp_start: '2022-01-01T05:58:40',
    timestamp_stop: '2022-01-01T05:58:46',
    quantity: 50.6,
    vehicle: 'DEM555',
    id: 134758,
    tank_volume_start: 32440.599999999937,
    tank_volume_stop: 32389.999999999938,
  },
  {
    timestamp_start: '2022-01-01T05:59:01',
    timestamp_stop: '2022-01-01T05:59:06',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 134760,
    tank_volume_start: 32389.999999999938,
    tank_volume_stop: 32331.799999999937,
  },
  {
    timestamp_start: '2022-01-01T05:59:21',
    timestamp_stop: '2022-01-01T05:59:26',
    quantity: 47.2,
    vehicle: 'DEM333',
    id: 134762,
    tank_volume_start: 32331.799999999937,
    tank_volume_stop: 32284.599999999937,
  },
  {
    timestamp_start: '2022-01-01T05:59:41',
    timestamp_stop: '2022-01-01T05:59:47',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 134764,
    tank_volume_start: 32284.599999999937,
    tank_volume_stop: 32224.999999999938,
  },
  {
    timestamp_start: '2022-01-01T06:00:02',
    timestamp_stop: '2022-01-01T06:00:07',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 134766,
    tank_volume_start: 32224.999999999938,
    tank_volume_stop: 32170.19999999994,
  },
  {
    timestamp_start: '2022-01-01T06:00:22',
    timestamp_stop: '2022-01-01T06:00:28',
    quantity: 58.4,
    vehicle: 'DEM333',
    id: 134768,
    tank_volume_start: 32170.19999999994,
    tank_volume_stop: 32111.799999999937,
  },
  {
    timestamp_start: '2022-01-01T06:00:43',
    timestamp_stop: '2022-01-01T06:00:49',
    quantity: 60.6,
    vehicle: 'DEM111',
    id: 134770,
    tank_volume_start: 32111.799999999937,
    tank_volume_stop: 32051.19999999994,
  },
  {
    timestamp_start: '2022-01-01T06:01:04',
    timestamp_stop: '2022-01-01T06:01:10',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 134772,
    tank_volume_start: 32051.19999999994,
    tank_volume_stop: 31994.09999999994,
  },
  {
    timestamp_start: '2022-01-01T06:01:25',
    timestamp_stop: '2022-01-01T06:01:30',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 134774,
    tank_volume_start: 31994.09999999994,
    tank_volume_stop: 31945.89999999994,
  },
  {
    timestamp_start: '2022-01-01T06:01:45',
    timestamp_stop: '2022-01-01T06:01:51',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 134776,
    tank_volume_start: 31945.89999999994,
    tank_volume_stop: 31884.89999999994,
  },
  {
    timestamp_start: '2022-01-01T06:02:06',
    timestamp_stop: '2022-01-01T06:02:11',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 134778,
    tank_volume_start: 31884.89999999994,
    tank_volume_stop: 31832.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:02:26',
    timestamp_stop: '2022-01-01T06:02:32',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 134780,
    tank_volume_start: 31832.59999999994,
    tank_volume_stop: 31778.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:02:47',
    timestamp_stop: '2022-01-01T06:02:52',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 134782,
    tank_volume_start: 31778.59999999994,
    tank_volume_stop: 31725.39999999994,
  },
  {
    timestamp_start: '2022-01-01T06:03:07',
    timestamp_stop: '2022-01-01T06:03:13',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 134784,
    tank_volume_start: 31725.39999999994,
    tank_volume_stop: 31667.999999999938,
  },
  {
    timestamp_start: '2022-01-01T06:03:28',
    timestamp_stop: '2022-01-01T06:03:33',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 134786,
    tank_volume_start: 31667.999999999938,
    tank_volume_stop: 31612.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:03:48',
    timestamp_stop: '2022-01-01T06:03:54',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 134788,
    tank_volume_start: 31612.099999999937,
    tank_volume_stop: 31559.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:04:09',
    timestamp_stop: '2022-01-01T06:04:15',
    quantity: 60.7,
    vehicle: 'DEM888',
    id: 134790,
    tank_volume_start: 31559.099999999937,
    tank_volume_stop: 31498.399999999936,
  },
  {
    timestamp_start: '2022-01-01T06:04:30',
    timestamp_stop: '2022-01-01T06:04:36',
    quantity: 59.3,
    vehicle: 'DEM777',
    id: 134792,
    tank_volume_start: 31498.399999999936,
    tank_volume_stop: 31439.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:04:51',
    timestamp_stop: '2022-01-01T06:04:57',
    quantity: 62.1,
    vehicle: 'DEM666',
    id: 134794,
    tank_volume_start: 31439.099999999937,
    tank_volume_stop: 31376.999999999938,
  },
  {
    timestamp_start: '2022-01-01T06:05:12',
    timestamp_stop: '2022-01-01T06:05:18',
    quantity: 61.5,
    vehicle: 'DEM000',
    id: 134796,
    tank_volume_start: 31376.999999999938,
    tank_volume_stop: 31315.499999999938,
  },
  {
    timestamp_start: '2022-01-01T06:05:33',
    timestamp_stop: '2022-01-01T06:05:38',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 134798,
    tank_volume_start: 31315.499999999938,
    tank_volume_stop: 31264.19999999994,
  },
  {
    timestamp_start: '2022-01-01T06:05:53',
    timestamp_stop: '2022-01-01T06:05:58',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 134800,
    tank_volume_start: 31264.19999999994,
    tank_volume_stop: 31212.39999999994,
  },
  {
    timestamp_start: '2022-01-01T06:06:13',
    timestamp_stop: '2022-01-01T06:06:18',
    quantity: 48.7,
    vehicle: 'DEM222',
    id: 134802,
    tank_volume_start: 31212.39999999994,
    tank_volume_stop: 31163.69999999994,
  },
  {
    timestamp_start: '2022-01-01T06:06:33',
    timestamp_stop: '2022-01-01T06:06:39',
    quantity: 57.8,
    vehicle: 'DEM555',
    id: 134804,
    tank_volume_start: 31163.69999999994,
    tank_volume_stop: 31105.89999999994,
  },
  {
    timestamp_start: '2022-01-01T06:06:54',
    timestamp_stop: '2022-01-01T06:06:59',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 134806,
    tank_volume_start: 31105.89999999994,
    tank_volume_stop: 31054.999999999938,
  },
  {
    timestamp_start: '2022-01-01T06:07:14',
    timestamp_stop: '2022-01-01T06:07:19',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 134808,
    tank_volume_start: 31054.999999999938,
    tank_volume_stop: 31000.299999999937,
  },
  {
    timestamp_start: '2022-01-01T06:07:34',
    timestamp_stop: '2022-01-01T06:07:40',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 134810,
    tank_volume_start: 31000.299999999937,
    tank_volume_stop: 30941.399999999936,
  },
  {
    timestamp_start: '2022-01-01T06:07:55',
    timestamp_stop: '2022-01-01T06:08:01',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 134812,
    tank_volume_start: 30941.399999999936,
    tank_volume_stop: 30883.999999999935,
  },
  {
    timestamp_start: '2022-01-01T06:08:16',
    timestamp_stop: '2022-01-01T06:08:22',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 134814,
    tank_volume_start: 30883.999999999935,
    tank_volume_stop: 30827.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:08:37',
    timestamp_stop: '2022-01-01T06:08:43',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 134816,
    tank_volume_start: 30827.099999999933,
    tank_volume_stop: 30767.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:08:58',
    timestamp_stop: '2022-01-01T06:09:04',
    quantity: 59.8,
    vehicle: 'DEM444',
    id: 134818,
    tank_volume_start: 30767.499999999935,
    tank_volume_stop: 30707.699999999935,
  },
  {
    timestamp_start: '2022-01-01T06:09:19',
    timestamp_stop: '2022-01-01T06:09:24',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 134820,
    tank_volume_start: 30707.699999999935,
    tank_volume_stop: 30655.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:09:39',
    timestamp_stop: '2022-01-01T06:09:44',
    quantity: 52.4,
    vehicle: 'DEM333',
    id: 134822,
    tank_volume_start: 30655.299999999934,
    tank_volume_stop: 30602.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:09:59',
    timestamp_stop: '2022-01-01T06:10:05',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 134824,
    tank_volume_start: 30602.899999999932,
    tank_volume_stop: 30548.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:10:20',
    timestamp_stop: '2022-01-01T06:10:26',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 134826,
    tank_volume_start: 30548.19999999993,
    tank_volume_stop: 30488.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:10:41',
    timestamp_stop: '2022-01-01T06:10:46',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 134828,
    tank_volume_start: 30488.19999999993,
    tank_volume_stop: 30433.69999999993,
  },
  {
    timestamp_start: '2022-01-01T06:11:01',
    timestamp_stop: '2022-01-01T06:11:06',
    quantity: 50.3,
    vehicle: 'DEM666',
    id: 134830,
    tank_volume_start: 30433.69999999993,
    tank_volume_stop: 30383.399999999932,
  },
  {
    timestamp_start: '2022-01-01T06:11:21',
    timestamp_stop: '2022-01-01T06:11:26',
    quantity: 46.8,
    vehicle: 'DEM666',
    id: 134832,
    tank_volume_start: 30383.399999999932,
    tank_volume_stop: 30336.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:11:41',
    timestamp_stop: '2022-01-01T06:11:46',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 134834,
    tank_volume_start: 30336.599999999933,
    tank_volume_stop: 30284.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:12:01',
    timestamp_stop: '2022-01-01T06:12:06',
    quantity: 47.5,
    vehicle: 'DEM333',
    id: 134836,
    tank_volume_start: 30284.19999999993,
    tank_volume_stop: 30236.69999999993,
  },
  {
    timestamp_start: '2022-01-01T06:12:21',
    timestamp_stop: '2022-01-01T06:12:27',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 134838,
    tank_volume_start: 30236.69999999993,
    tank_volume_stop: 30175.99999999993,
  },
  {
    timestamp_start: '2022-01-01T06:12:42',
    timestamp_stop: '2022-01-01T06:12:47',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 134840,
    tank_volume_start: 30175.99999999993,
    tank_volume_stop: 30123.49999999993,
  },
  {
    timestamp_start: '2022-01-01T06:13:02',
    timestamp_stop: '2022-01-01T06:13:08',
    quantity: 62.2,
    vehicle: 'DEM444',
    id: 134842,
    tank_volume_start: 30123.49999999993,
    tank_volume_stop: 30061.29999999993,
  },
  {
    timestamp_start: '2022-01-01T06:13:23',
    timestamp_stop: '2022-01-01T06:13:29',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 134844,
    tank_volume_start: 30061.29999999993,
    tank_volume_stop: 30004.39999999993,
  },
  {
    timestamp_start: '2022-01-01T06:13:44',
    timestamp_stop: '2022-01-01T06:13:50',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 134846,
    tank_volume_start: 30004.39999999993,
    tank_volume_stop: 29947.09999999993,
  },
  {
    timestamp_start: '2022-01-01T06:14:05',
    timestamp_stop: '2022-01-01T06:14:10',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 134848,
    tank_volume_start: 29947.09999999993,
    tank_volume_stop: 29895.99999999993,
  },
  {
    timestamp_start: '2022-01-01T06:14:25',
    timestamp_stop: '2022-01-01T06:14:30',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 134850,
    tank_volume_start: 29895.99999999993,
    tank_volume_stop: 29843.29999999993,
  },
  {
    timestamp_start: '2022-01-01T06:14:45',
    timestamp_stop: '2022-01-01T06:14:50',
    quantity: 45.8,
    vehicle: 'DEM777',
    id: 134852,
    tank_volume_start: 29843.29999999993,
    tank_volume_stop: 29797.49999999993,
  },
  {
    timestamp_start: '2022-01-01T06:15:05',
    timestamp_stop: '2022-01-01T06:15:10',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 134854,
    tank_volume_start: 29797.49999999993,
    tank_volume_stop: 29743.399999999932,
  },
  {
    timestamp_start: '2022-01-01T06:15:25',
    timestamp_stop: '2022-01-01T06:15:31',
    quantity: 55.6,
    vehicle: 'DEM444',
    id: 134856,
    tank_volume_start: 29743.399999999932,
    tank_volume_stop: 29687.799999999934,
  },
  {
    timestamp_start: '2022-01-01T06:15:46',
    timestamp_stop: '2022-01-01T06:15:52',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 134858,
    tank_volume_start: 29687.799999999934,
    tank_volume_stop: 29627.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:16:07',
    timestamp_stop: '2022-01-01T06:16:12',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 134860,
    tank_volume_start: 29627.899999999932,
    tank_volume_stop: 29571.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:16:27',
    timestamp_stop: '2022-01-01T06:16:32',
    quantity: 47.4,
    vehicle: 'DEM666',
    id: 134862,
    tank_volume_start: 29571.299999999934,
    tank_volume_stop: 29523.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:16:47',
    timestamp_stop: '2022-01-01T06:16:52',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 134864,
    tank_volume_start: 29523.899999999932,
    tank_volume_stop: 29471.69999999993,
  },
  {
    timestamp_start: '2022-01-01T06:17:07',
    timestamp_stop: '2022-01-01T06:17:12',
    quantity: 50.5,
    vehicle: 'DEM333',
    id: 134866,
    tank_volume_start: 29471.69999999993,
    tank_volume_stop: 29421.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:17:27',
    timestamp_stop: '2022-01-01T06:17:33',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 134868,
    tank_volume_start: 29421.19999999993,
    tank_volume_stop: 29364.79999999993,
  },
  {
    timestamp_start: '2022-01-01T06:17:48',
    timestamp_stop: '2022-01-01T06:17:54',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 134870,
    tank_volume_start: 29364.79999999993,
    tank_volume_stop: 29307.49999999993,
  },
  {
    timestamp_start: '2022-01-01T06:18:09',
    timestamp_stop: '2022-01-01T06:18:15',
    quantity: 62.6,
    vehicle: 'DEM444',
    id: 134872,
    tank_volume_start: 29307.49999999993,
    tank_volume_stop: 29244.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:18:30',
    timestamp_stop: '2022-01-01T06:18:35',
    quantity: 48.0,
    vehicle: 'DEM555',
    id: 134874,
    tank_volume_start: 29244.899999999932,
    tank_volume_stop: 29196.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:18:50',
    timestamp_stop: '2022-01-01T06:18:55',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 134876,
    tank_volume_start: 29196.899999999932,
    tank_volume_stop: 29146.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:19:10',
    timestamp_stop: '2022-01-01T06:19:16',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 134878,
    tank_volume_start: 29146.19999999993,
    tank_volume_stop: 29085.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:19:31',
    timestamp_stop: '2022-01-01T06:19:37',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 134880,
    tank_volume_start: 29085.19999999993,
    tank_volume_stop: 29026.29999999993,
  },
  {
    timestamp_start: '2022-01-01T06:19:52',
    timestamp_stop: '2022-01-01T06:19:57',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 134882,
    tank_volume_start: 29026.29999999993,
    tank_volume_stop: 28977.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:20:12',
    timestamp_stop: '2022-01-01T06:20:17',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 134884,
    tank_volume_start: 28977.19999999993,
    tank_volume_stop: 28923.99999999993,
  },
  {
    timestamp_start: '2022-01-01T06:20:32',
    timestamp_stop: '2022-01-01T06:20:37',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 134886,
    tank_volume_start: 28923.99999999993,
    tank_volume_stop: 28872.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:20:52',
    timestamp_stop: '2022-01-01T06:20:58',
    quantity: 56.1,
    vehicle: 'DEM444',
    id: 134888,
    tank_volume_start: 28872.19999999993,
    tank_volume_stop: 28816.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:21:13',
    timestamp_stop: '2022-01-01T06:21:18',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 134890,
    tank_volume_start: 28816.099999999933,
    tank_volume_stop: 28762.399999999932,
  },
  {
    timestamp_start: '2022-01-01T06:21:33',
    timestamp_stop: '2022-01-01T06:21:38',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 134892,
    tank_volume_start: 28762.399999999932,
    tank_volume_stop: 28710.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:21:53',
    timestamp_stop: '2022-01-01T06:21:58',
    quantity: 45.6,
    vehicle: 'DEM666',
    id: 134894,
    tank_volume_start: 28710.099999999933,
    tank_volume_stop: 28664.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:22:13',
    timestamp_stop: '2022-01-01T06:22:18',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 134896,
    tank_volume_start: 28664.499999999935,
    tank_volume_stop: 28613.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:22:33',
    timestamp_stop: '2022-01-01T06:22:39',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 134898,
    tank_volume_start: 28613.499999999935,
    tank_volume_stop: 28554.999999999935,
  },
  {
    timestamp_start: '2022-01-01T06:22:54',
    timestamp_stop: '2022-01-01T06:22:59',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 134900,
    tank_volume_start: 28554.999999999935,
    tank_volume_stop: 28500.699999999935,
  },
  {
    timestamp_start: '2022-01-01T06:23:14',
    timestamp_stop: '2022-01-01T06:23:20',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 134902,
    tank_volume_start: 28500.699999999935,
    tank_volume_stop: 28445.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:23:35',
    timestamp_stop: '2022-01-01T06:23:40',
    quantity: 52.2,
    vehicle: 'DEM333',
    id: 134903,
    tank_volume_start: 28445.499999999935,
    tank_volume_stop: 28393.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:23:55',
    timestamp_stop: '2022-01-01T06:24:01',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 134905,
    tank_volume_start: 28393.299999999934,
    tank_volume_stop: 28337.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:24:16',
    timestamp_stop: '2022-01-01T06:24:20',
    quantity: 46.7,
    vehicle: 'DEM333',
    id: 134907,
    tank_volume_start: 28337.599999999933,
    tank_volume_stop: 28290.899999999932,
  },
  {
    timestamp_start: '2022-01-01T06:24:35',
    timestamp_stop: '2022-01-01T06:24:40',
    quantity: 46.3,
    vehicle: 'DEM666',
    id: 134909,
    tank_volume_start: 28290.899999999932,
    tank_volume_stop: 28244.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:24:55',
    timestamp_stop: '2022-01-01T06:25:01',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 134911,
    tank_volume_start: 28244.599999999933,
    tank_volume_stop: 28184.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:25:16',
    timestamp_stop: '2022-01-01T06:25:21',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 134913,
    tank_volume_start: 28184.19999999993,
    tank_volume_stop: 28132.19999999993,
  },
  {
    timestamp_start: '2022-01-01T06:25:36',
    timestamp_stop: '2022-01-01T06:25:42',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 134915,
    tank_volume_start: 28132.19999999993,
    tank_volume_stop: 28076.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:25:57',
    timestamp_stop: '2022-01-01T06:26:02',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 134917,
    tank_volume_start: 28076.599999999933,
    tank_volume_stop: 28022.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:26:17',
    timestamp_stop: '2022-01-01T06:26:23',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 134919,
    tank_volume_start: 28022.499999999935,
    tank_volume_stop: 27966.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:26:38',
    timestamp_stop: '2022-01-01T06:26:43',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 134921,
    tank_volume_start: 27966.099999999933,
    tank_volume_stop: 27912.999999999935,
  },
  {
    timestamp_start: '2022-01-01T06:26:58',
    timestamp_stop: '2022-01-01T06:27:04',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 134923,
    tank_volume_start: 27912.999999999935,
    tank_volume_stop: 27858.699999999935,
  },
  {
    timestamp_start: '2022-01-01T06:27:19',
    timestamp_stop: '2022-01-01T06:27:24',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 134925,
    tank_volume_start: 27858.699999999935,
    tank_volume_stop: 27806.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:27:39',
    timestamp_stop: '2022-01-01T06:27:44',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 134927,
    tank_volume_start: 27806.499999999935,
    tank_volume_stop: 27753.899999999936,
  },
  {
    timestamp_start: '2022-01-01T06:27:59',
    timestamp_stop: '2022-01-01T06:28:05',
    quantity: 59.8,
    vehicle: 'DEM555',
    id: 134929,
    tank_volume_start: 27753.899999999936,
    tank_volume_stop: 27694.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:28:20',
    timestamp_stop: '2022-01-01T06:28:25',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 134931,
    tank_volume_start: 27694.099999999937,
    tank_volume_stop: 27643.499999999938,
  },
  {
    timestamp_start: '2022-01-01T06:28:40',
    timestamp_stop: '2022-01-01T06:28:46',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 134933,
    tank_volume_start: 27643.499999999938,
    tank_volume_stop: 27584.69999999994,
  },
  {
    timestamp_start: '2022-01-01T06:29:01',
    timestamp_stop: '2022-01-01T06:29:07',
    quantity: 59.9,
    vehicle: 'DEM444',
    id: 134935,
    tank_volume_start: 27584.69999999994,
    tank_volume_stop: 27524.799999999937,
  },
  {
    timestamp_start: '2022-01-01T06:29:22',
    timestamp_stop: '2022-01-01T06:29:28',
    quantity: 59.2,
    vehicle: 'DEM111',
    id: 134937,
    tank_volume_start: 27524.799999999937,
    tank_volume_stop: 27465.599999999937,
  },
  {
    timestamp_start: '2022-01-01T06:29:43',
    timestamp_stop: '2022-01-01T06:29:48',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 134939,
    tank_volume_start: 27465.599999999937,
    tank_volume_stop: 27414.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:30:03',
    timestamp_stop: '2022-01-01T06:30:08',
    quantity: 46.8,
    vehicle: 'DEM777',
    id: 134941,
    tank_volume_start: 27414.199999999935,
    tank_volume_stop: 27367.399999999936,
  },
  {
    timestamp_start: '2022-01-01T06:30:23',
    timestamp_stop: '2022-01-01T06:30:28',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 134943,
    tank_volume_start: 27367.399999999936,
    tank_volume_stop: 27316.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:30:43',
    timestamp_stop: '2022-01-01T06:30:48',
    quantity: 51.9,
    vehicle: 'DEM888',
    id: 134945,
    tank_volume_start: 27316.199999999935,
    tank_volume_stop: 27264.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:31:03',
    timestamp_stop: '2022-01-01T06:31:08',
    quantity: 47.7,
    vehicle: 'DEM666',
    id: 134947,
    tank_volume_start: 27264.299999999934,
    tank_volume_stop: 27216.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:31:23',
    timestamp_stop: '2022-01-01T06:31:28',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 134949,
    tank_volume_start: 27216.599999999933,
    tank_volume_stop: 27162.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:31:43',
    timestamp_stop: '2022-01-01T06:31:49',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 134951,
    tank_volume_start: 27162.299999999934,
    tank_volume_stop: 27102.999999999935,
  },
  {
    timestamp_start: '2022-01-01T06:32:04',
    timestamp_stop: '2022-01-01T06:32:10',
    quantity: 59.9,
    vehicle: 'DEM888',
    id: 134953,
    tank_volume_start: 27102.999999999935,
    tank_volume_stop: 27043.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:32:25',
    timestamp_stop: '2022-01-01T06:32:30',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 134955,
    tank_volume_start: 27043.099999999933,
    tank_volume_stop: 26990.799999999934,
  },
  {
    timestamp_start: '2022-01-01T06:32:45',
    timestamp_stop: '2022-01-01T06:32:51',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 134957,
    tank_volume_start: 26990.799999999934,
    tank_volume_stop: 26930.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:33:06',
    timestamp_stop: '2022-01-01T06:33:13',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 134959,
    tank_volume_start: 26930.199999999935,
    tank_volume_stop: 26868.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:33:28',
    timestamp_stop: '2022-01-01T06:33:33',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 134962,
    tank_volume_start: 26868.199999999935,
    tank_volume_stop: 26815.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:33:48',
    timestamp_stop: '2022-01-01T06:33:53',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 134964,
    tank_volume_start: 26815.499999999935,
    tank_volume_stop: 26760.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:34:08',
    timestamp_stop: '2022-01-01T06:34:14',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 134966,
    tank_volume_start: 26760.199999999935,
    tank_volume_stop: 26707.899999999936,
  },
  {
    timestamp_start: '2022-01-01T06:34:29',
    timestamp_stop: '2022-01-01T06:34:34',
    quantity: 48.2,
    vehicle: 'DEM666',
    id: 134967,
    tank_volume_start: 26707.899999999936,
    tank_volume_stop: 26659.699999999935,
  },
  {
    timestamp_start: '2022-01-01T06:34:49',
    timestamp_stop: '2022-01-01T06:34:54',
    quantity: 51.6,
    vehicle: 'DEM111',
    id: 134969,
    tank_volume_start: 26659.699999999935,
    tank_volume_stop: 26608.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:35:09',
    timestamp_stop: '2022-01-01T06:35:14',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 134971,
    tank_volume_start: 26608.099999999937,
    tank_volume_stop: 26555.599999999937,
  },
  {
    timestamp_start: '2022-01-01T06:35:29',
    timestamp_stop: '2022-01-01T06:35:35',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 134973,
    tank_volume_start: 26555.599999999937,
    tank_volume_stop: 26497.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:35:50',
    timestamp_stop: '2022-01-01T06:35:55',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 134975,
    tank_volume_start: 26497.099999999937,
    tank_volume_stop: 26446.099999999937,
  },
  {
    timestamp_start: '2022-01-01T06:36:10',
    timestamp_stop: '2022-01-01T06:36:15',
    quantity: 47.6,
    vehicle: 'DEM222',
    id: 134977,
    tank_volume_start: 26446.099999999937,
    tank_volume_stop: 26398.499999999938,
  },
  {
    timestamp_start: '2022-01-01T06:36:30',
    timestamp_stop: '2022-01-01T06:36:35',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 134979,
    tank_volume_start: 26398.499999999938,
    tank_volume_stop: 26340.39999999994,
  },
  {
    timestamp_start: '2022-01-01T06:36:50',
    timestamp_stop: '2022-01-01T06:36:56',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 134981,
    tank_volume_start: 26340.39999999994,
    tank_volume_stop: 26283.09999999994,
  },
  {
    timestamp_start: '2022-01-01T06:37:11',
    timestamp_stop: '2022-01-01T06:37:17',
    quantity: 62.1,
    vehicle: 'DEM888',
    id: 134983,
    tank_volume_start: 26283.09999999994,
    tank_volume_stop: 26220.99999999994,
  },
  {
    timestamp_start: '2022-01-01T06:37:32',
    timestamp_stop: '2022-01-01T06:37:39',
    quantity: 61.4,
    vehicle: 'DEM666',
    id: 134985,
    tank_volume_start: 26220.99999999994,
    tank_volume_stop: 26159.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:37:54',
    timestamp_stop: '2022-01-01T06:37:58',
    quantity: 49.1,
    vehicle: 'DEM888',
    id: 134987,
    tank_volume_start: 26159.59999999994,
    tank_volume_stop: 26110.49999999994,
  },
  {
    timestamp_start: '2022-01-01T06:38:13',
    timestamp_stop: '2022-01-01T06:38:19',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 134989,
    tank_volume_start: 26110.49999999994,
    tank_volume_stop: 26059.29999999994,
  },
  {
    timestamp_start: '2022-01-01T06:38:34',
    timestamp_stop: '2022-01-01T06:38:39',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 134991,
    tank_volume_start: 26059.29999999994,
    tank_volume_stop: 26008.89999999994,
  },
  {
    timestamp_start: '2022-01-01T06:38:54',
    timestamp_stop: '2022-01-01T06:38:59',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 134993,
    tank_volume_start: 26008.89999999994,
    tank_volume_stop: 25952.39999999994,
  },
  {
    timestamp_start: '2022-01-01T06:39:14',
    timestamp_stop: '2022-01-01T06:39:20',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 134995,
    tank_volume_start: 25952.39999999994,
    tank_volume_stop: 25898.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:39:35',
    timestamp_stop: '2022-01-01T06:39:41',
    quantity: 62.7,
    vehicle: 'DEM222',
    id: 134997,
    tank_volume_start: 25898.59999999994,
    tank_volume_stop: 25835.89999999994,
  },
  {
    timestamp_start: '2022-01-01T06:39:56',
    timestamp_stop: '2022-01-01T06:40:01',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 134999,
    tank_volume_start: 25835.89999999994,
    tank_volume_stop: 25783.29999999994,
  },
  {
    timestamp_start: '2022-01-01T06:40:16',
    timestamp_stop: '2022-01-01T06:40:22',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 135001,
    tank_volume_start: 25783.29999999994,
    tank_volume_stop: 25729.699999999943,
  },
  {
    timestamp_start: '2022-01-01T06:40:37',
    timestamp_stop: '2022-01-01T06:40:43',
    quantity: 64.6,
    vehicle: 'DEM000',
    id: 135003,
    tank_volume_start: 25729.699999999943,
    tank_volume_stop: 25665.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:40:58',
    timestamp_stop: '2022-01-01T06:41:04',
    quantity: 55.6,
    vehicle: 'DEM888',
    id: 135005,
    tank_volume_start: 25665.099999999944,
    tank_volume_stop: 25609.499999999945,
  },
  {
    timestamp_start: '2022-01-01T06:41:19',
    timestamp_stop: '2022-01-01T06:41:24',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 135007,
    tank_volume_start: 25609.499999999945,
    tank_volume_stop: 25552.299999999945,
  },
  {
    timestamp_start: '2022-01-01T06:41:39',
    timestamp_stop: '2022-01-01T06:41:44',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 135009,
    tank_volume_start: 25552.299999999945,
    tank_volume_stop: 25500.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:41:59',
    timestamp_stop: '2022-01-01T06:42:05',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 135011,
    tank_volume_start: 25500.099999999944,
    tank_volume_stop: 25440.499999999945,
  },
  {
    timestamp_start: '2022-01-01T06:42:20',
    timestamp_stop: '2022-01-01T06:42:26',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 135013,
    tank_volume_start: 25440.499999999945,
    tank_volume_stop: 25385.999999999945,
  },
  {
    timestamp_start: '2022-01-01T06:42:41',
    timestamp_stop: '2022-01-01T06:42:46',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 135015,
    tank_volume_start: 25385.999999999945,
    tank_volume_stop: 25332.799999999945,
  },
  {
    timestamp_start: '2022-01-01T06:43:01',
    timestamp_stop: '2022-01-01T06:43:08',
    quantity: 63.2,
    vehicle: 'DEM111',
    id: 135017,
    tank_volume_start: 25332.799999999945,
    tank_volume_stop: 25269.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:43:23',
    timestamp_stop: '2022-01-01T06:43:29',
    quantity: 64.0,
    vehicle: 'DEM555',
    id: 135019,
    tank_volume_start: 25269.599999999944,
    tank_volume_stop: 25205.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:43:44',
    timestamp_stop: '2022-01-01T06:43:50',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 135021,
    tank_volume_start: 25205.599999999944,
    tank_volume_stop: 25148.499999999945,
  },
  {
    timestamp_start: '2022-01-01T06:44:05',
    timestamp_stop: '2022-01-01T06:44:09',
    quantity: 47.9,
    vehicle: 'DEM222',
    id: 135023,
    tank_volume_start: 25148.499999999945,
    tank_volume_stop: 25100.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:44:24',
    timestamp_stop: '2022-01-01T06:44:30',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 135025,
    tank_volume_start: 25100.599999999944,
    tank_volume_stop: 25044.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:44:45',
    timestamp_stop: '2022-01-01T06:44:51',
    quantity: 55.8,
    vehicle: 'DEM777',
    id: 135027,
    tank_volume_start: 25044.599999999944,
    tank_volume_stop: 24988.799999999945,
  },
  {
    timestamp_start: '2022-01-01T06:45:06',
    timestamp_stop: '2022-01-01T06:45:11',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 135029,
    tank_volume_start: 24988.799999999945,
    tank_volume_stop: 24932.699999999946,
  },
  {
    timestamp_start: '2022-01-01T06:45:26',
    timestamp_stop: '2022-01-01T06:45:32',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 135031,
    tank_volume_start: 24932.699999999946,
    tank_volume_stop: 24879.999999999945,
  },
  {
    timestamp_start: '2022-01-01T06:45:47',
    timestamp_stop: '2022-01-01T06:45:52',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 135033,
    tank_volume_start: 24879.999999999945,
    tank_volume_stop: 24829.799999999945,
  },
  {
    timestamp_start: '2022-01-01T06:46:07',
    timestamp_stop: '2022-01-01T06:46:12',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 135035,
    tank_volume_start: 24829.799999999945,
    tank_volume_stop: 24771.999999999945,
  },
  {
    timestamp_start: '2022-01-01T06:46:27',
    timestamp_stop: '2022-01-01T06:46:33',
    quantity: 57.4,
    vehicle: 'DEM555',
    id: 135037,
    tank_volume_start: 24771.999999999945,
    tank_volume_stop: 24714.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:46:48',
    timestamp_stop: '2022-01-01T06:46:53',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 135039,
    tank_volume_start: 24714.599999999944,
    tank_volume_stop: 24660.399999999943,
  },
  {
    timestamp_start: '2022-01-01T06:47:08',
    timestamp_stop: '2022-01-01T06:47:13',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 135041,
    tank_volume_start: 24660.399999999943,
    tank_volume_stop: 24610.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:47:28',
    timestamp_stop: '2022-01-01T06:47:35',
    quantity: 62.2,
    vehicle: 'DEM111',
    id: 135043,
    tank_volume_start: 24610.099999999944,
    tank_volume_stop: 24547.899999999943,
  },
  {
    timestamp_start: '2022-01-01T06:47:50',
    timestamp_stop: '2022-01-01T06:47:55',
    quantity: 48.5,
    vehicle: 'DEM888',
    id: 135045,
    tank_volume_start: 24547.899999999943,
    tank_volume_stop: 24499.399999999943,
  },
  {
    timestamp_start: '2022-01-01T06:48:10',
    timestamp_stop: '2022-01-01T06:48:15',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 135047,
    tank_volume_start: 24499.399999999943,
    tank_volume_stop: 24443.199999999943,
  },
  {
    timestamp_start: '2022-01-01T06:48:30',
    timestamp_stop: '2022-01-01T06:48:36',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 135049,
    tank_volume_start: 24443.199999999943,
    tank_volume_stop: 24388.49999999994,
  },
  {
    timestamp_start: '2022-01-01T06:48:51',
    timestamp_stop: '2022-01-01T06:48:56',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 135051,
    tank_volume_start: 24388.49999999994,
    tank_volume_stop: 24330.79999999994,
  },
  {
    timestamp_start: '2022-01-01T06:49:11',
    timestamp_stop: '2022-01-01T06:49:16',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 135053,
    tank_volume_start: 24330.79999999994,
    tank_volume_stop: 24283.199999999943,
  },
  {
    timestamp_start: '2022-01-01T06:49:31',
    timestamp_stop: '2022-01-01T06:49:36',
    quantity: 52.1,
    vehicle: 'DEM222',
    id: 135055,
    tank_volume_start: 24283.199999999943,
    tank_volume_stop: 24231.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:49:51',
    timestamp_stop: '2022-01-01T06:49:57',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 135057,
    tank_volume_start: 24231.099999999944,
    tank_volume_stop: 24170.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:50:12',
    timestamp_stop: '2022-01-01T06:50:17',
    quantity: 46.5,
    vehicle: 'DEM777',
    id: 135059,
    tank_volume_start: 24170.599999999944,
    tank_volume_stop: 24124.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:50:32',
    timestamp_stop: '2022-01-01T06:50:38',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 135061,
    tank_volume_start: 24124.099999999944,
    tank_volume_stop: 24063.999999999945,
  },
  {
    timestamp_start: '2022-01-01T06:50:53',
    timestamp_stop: '2022-01-01T06:50:59',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 135063,
    tank_volume_start: 24063.999999999945,
    tank_volume_stop: 24008.899999999947,
  },
  {
    timestamp_start: '2022-01-01T06:51:14',
    timestamp_stop: '2022-01-01T06:51:20',
    quantity: 60.9,
    vehicle: 'DEM000',
    id: 135065,
    tank_volume_start: 24008.899999999947,
    tank_volume_stop: 23947.999999999945,
  },
  {
    timestamp_start: '2022-01-01T06:51:35',
    timestamp_stop: '2022-01-01T06:51:41',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 135067,
    tank_volume_start: 23947.999999999945,
    tank_volume_stop: 23889.599999999944,
  },
  {
    timestamp_start: '2022-01-01T06:51:56',
    timestamp_stop: '2022-01-01T06:52:01',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 135069,
    tank_volume_start: 23889.599999999944,
    tank_volume_stop: 23839.399999999943,
  },
  {
    timestamp_start: '2022-01-01T06:52:16',
    timestamp_stop: '2022-01-01T06:52:21',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 135071,
    tank_volume_start: 23839.399999999943,
    tank_volume_stop: 23783.099999999944,
  },
  {
    timestamp_start: '2022-01-01T06:52:36',
    timestamp_stop: '2022-01-01T06:52:42',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 135073,
    tank_volume_start: 23783.099999999944,
    tank_volume_stop: 23729.199999999943,
  },
  {
    timestamp_start: '2022-01-01T06:52:57',
    timestamp_stop: '2022-01-01T06:53:02',
    quantity: 51.8,
    vehicle: 'DEM000',
    id: 135075,
    tank_volume_start: 23729.199999999943,
    tank_volume_stop: 23677.399999999943,
  },
  {
    timestamp_start: '2022-01-01T06:53:17',
    timestamp_stop: '2022-01-01T06:53:23',
    quantity: 63.9,
    vehicle: 'DEM000',
    id: 135077,
    tank_volume_start: 23677.399999999943,
    tank_volume_stop: 23613.49999999994,
  },
  {
    timestamp_start: '2022-01-01T06:53:38',
    timestamp_stop: '2022-01-01T06:53:43',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 135079,
    tank_volume_start: 23613.49999999994,
    tank_volume_stop: 23562.29999999994,
  },
  {
    timestamp_start: '2022-01-01T06:53:58',
    timestamp_stop: '2022-01-01T06:54:04',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 135081,
    tank_volume_start: 23562.29999999994,
    tank_volume_stop: 23505.39999999994,
  },
  {
    timestamp_start: '2022-01-01T06:54:19',
    timestamp_stop: '2022-01-01T06:54:24',
    quantity: 45.8,
    vehicle: 'DEM444',
    id: 135083,
    tank_volume_start: 23505.39999999994,
    tank_volume_stop: 23459.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:54:39',
    timestamp_stop: '2022-01-01T06:54:44',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 135085,
    tank_volume_start: 23459.59999999994,
    tank_volume_stop: 23406.59999999994,
  },
  {
    timestamp_start: '2022-01-01T06:54:59',
    timestamp_stop: '2022-01-01T06:55:04',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 135087,
    tank_volume_start: 23406.59999999994,
    tank_volume_stop: 23354.69999999994,
  },
  {
    timestamp_start: '2022-01-01T06:55:19',
    timestamp_stop: '2022-01-01T06:55:24',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 135089,
    tank_volume_start: 23354.69999999994,
    tank_volume_stop: 23304.19999999994,
  },
  {
    timestamp_start: '2022-01-01T06:55:39',
    timestamp_stop: '2022-01-01T06:55:45',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 135091,
    tank_volume_start: 23304.19999999994,
    tank_volume_stop: 23244.69999999994,
  },
  {
    timestamp_start: '2022-01-01T06:56:00',
    timestamp_stop: '2022-01-01T06:56:06',
    quantity: 59.9,
    vehicle: 'DEM777',
    id: 135093,
    tank_volume_start: 23244.69999999994,
    tank_volume_stop: 23184.799999999937,
  },
  {
    timestamp_start: '2022-01-01T06:56:21',
    timestamp_stop: '2022-01-01T06:56:27',
    quantity: 57.9,
    vehicle: 'DEM444',
    id: 135095,
    tank_volume_start: 23184.799999999937,
    tank_volume_stop: 23126.899999999936,
  },
  {
    timestamp_start: '2022-01-01T06:56:42',
    timestamp_stop: '2022-01-01T06:56:47',
    quantity: 52.2,
    vehicle: 'DEM111',
    id: 135097,
    tank_volume_start: 23126.899999999936,
    tank_volume_stop: 23074.699999999935,
  },
  {
    timestamp_start: '2022-01-01T06:57:02',
    timestamp_stop: '2022-01-01T06:57:07',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 135099,
    tank_volume_start: 23074.699999999935,
    tank_volume_stop: 23024.399999999936,
  },
  {
    timestamp_start: '2022-01-01T06:57:22',
    timestamp_stop: '2022-01-01T06:57:28',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 135101,
    tank_volume_start: 23024.399999999936,
    tank_volume_stop: 22964.499999999935,
  },
  {
    timestamp_start: '2022-01-01T06:57:43',
    timestamp_stop: '2022-01-01T06:57:48',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 135103,
    tank_volume_start: 22964.499999999935,
    tank_volume_stop: 22916.199999999935,
  },
  {
    timestamp_start: '2022-01-01T06:58:03',
    timestamp_stop: '2022-01-01T06:58:08',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 135105,
    tank_volume_start: 22916.199999999935,
    tank_volume_stop: 22860.999999999935,
  },
  {
    timestamp_start: '2022-01-01T06:58:23',
    timestamp_stop: '2022-01-01T06:58:29',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 135107,
    tank_volume_start: 22860.999999999935,
    tank_volume_stop: 22802.099999999933,
  },
  {
    timestamp_start: '2022-01-01T06:58:44',
    timestamp_stop: '2022-01-01T06:58:50',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 135109,
    tank_volume_start: 22802.099999999933,
    tank_volume_stop: 22745.69999999993,
  },
  {
    timestamp_start: '2022-01-01T06:59:05',
    timestamp_stop: '2022-01-01T06:59:11',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 135111,
    tank_volume_start: 22745.69999999993,
    tank_volume_stop: 22687.599999999933,
  },
  {
    timestamp_start: '2022-01-01T06:59:26',
    timestamp_stop: '2022-01-01T06:59:31',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 135113,
    tank_volume_start: 22687.599999999933,
    tank_volume_stop: 22634.299999999934,
  },
  {
    timestamp_start: '2022-01-01T06:59:46',
    timestamp_stop: '2022-01-01T06:59:52',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 135115,
    tank_volume_start: 22634.299999999934,
    tank_volume_stop: 22579.299999999934,
  },
  {
    timestamp_start: '2022-01-01T07:00:07',
    timestamp_stop: '2022-01-01T07:00:13',
    quantity: 62.8,
    vehicle: 'DEM888',
    id: 135117,
    tank_volume_start: 22579.299999999934,
    tank_volume_stop: 22516.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:00:28',
    timestamp_stop: '2022-01-01T07:00:33',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 135119,
    tank_volume_start: 22516.499999999935,
    tank_volume_stop: 22463.999999999935,
  },
  {
    timestamp_start: '2022-01-01T07:00:48',
    timestamp_stop: '2022-01-01T07:00:53',
    quantity: 51.4,
    vehicle: 'DEM000',
    id: 135121,
    tank_volume_start: 22463.999999999935,
    tank_volume_stop: 22412.599999999933,
  },
  {
    timestamp_start: '2022-01-01T07:01:08',
    timestamp_stop: '2022-01-01T07:01:13',
    quantity: 46.3,
    vehicle: 'DEM888',
    id: 135123,
    tank_volume_start: 22412.599999999933,
    tank_volume_stop: 22366.299999999934,
  },
  {
    timestamp_start: '2022-01-01T07:01:28',
    timestamp_stop: '2022-01-01T07:01:34',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 135125,
    tank_volume_start: 22366.299999999934,
    tank_volume_stop: 22307.899999999932,
  },
  {
    timestamp_start: '2022-01-01T07:01:49',
    timestamp_stop: '2022-01-01T07:01:54',
    quantity: 54.2,
    vehicle: 'DEM111',
    id: 135127,
    tank_volume_start: 22307.899999999932,
    tank_volume_stop: 22253.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:02:09',
    timestamp_stop: '2022-01-01T07:02:15',
    quantity: 59.7,
    vehicle: 'DEM222',
    id: 135129,
    tank_volume_start: 22253.69999999993,
    tank_volume_stop: 22193.99999999993,
  },
  {
    timestamp_start: '2022-01-01T07:02:30',
    timestamp_stop: '2022-01-01T07:02:36',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 135131,
    tank_volume_start: 22193.99999999993,
    tank_volume_stop: 22138.29999999993,
  },
  {
    timestamp_start: '2022-01-01T07:02:51',
    timestamp_stop: '2022-01-01T07:02:56',
    quantity: 56.6,
    vehicle: 'DEM222',
    id: 135133,
    tank_volume_start: 22138.29999999993,
    tank_volume_stop: 22081.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:03:11',
    timestamp_stop: '2022-01-01T07:03:17',
    quantity: 61.0,
    vehicle: 'DEM666',
    id: 135135,
    tank_volume_start: 22081.69999999993,
    tank_volume_stop: 22020.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:03:32',
    timestamp_stop: '2022-01-01T07:03:37',
    quantity: 47.3,
    vehicle: 'DEM555',
    id: 135137,
    tank_volume_start: 22020.69999999993,
    tank_volume_stop: 21973.399999999932,
  },
  {
    timestamp_start: '2022-01-01T07:03:52',
    timestamp_stop: '2022-01-01T07:03:58',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 135139,
    tank_volume_start: 21973.399999999932,
    tank_volume_stop: 21916.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:04:13',
    timestamp_stop: '2022-01-01T07:04:19',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 135141,
    tank_volume_start: 21916.69999999993,
    tank_volume_stop: 21859.599999999933,
  },
  {
    timestamp_start: '2022-01-01T07:04:34',
    timestamp_stop: '2022-01-01T07:04:38',
    quantity: 46.7,
    vehicle: 'DEM333',
    id: 135143,
    tank_volume_start: 21859.599999999933,
    tank_volume_stop: 21812.899999999932,
  },
  {
    timestamp_start: '2022-01-01T07:04:53',
    timestamp_stop: '2022-01-01T07:04:58',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 135145,
    tank_volume_start: 21812.899999999932,
    tank_volume_stop: 21760.399999999932,
  },
  {
    timestamp_start: '2022-01-01T07:05:13',
    timestamp_stop: '2022-01-01T07:05:19',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 135147,
    tank_volume_start: 21760.399999999932,
    tank_volume_stop: 21706.899999999932,
  },
  {
    timestamp_start: '2022-01-01T07:05:34',
    timestamp_stop: '2022-01-01T07:05:39',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 135149,
    tank_volume_start: 21706.899999999932,
    tank_volume_stop: 21654.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:05:54',
    timestamp_stop: '2022-01-01T07:05:59',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 135151,
    tank_volume_start: 21654.49999999993,
    tank_volume_stop: 21606.79999999993,
  },
  {
    timestamp_start: '2022-01-01T07:06:14',
    timestamp_stop: '2022-01-01T07:06:19',
    quantity: 47.9,
    vehicle: 'DEM111',
    id: 135153,
    tank_volume_start: 21606.79999999993,
    tank_volume_stop: 21558.89999999993,
  },
  {
    timestamp_start: '2022-01-01T07:06:34',
    timestamp_stop: '2022-01-01T07:06:39',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 135155,
    tank_volume_start: 21558.89999999993,
    tank_volume_stop: 21505.89999999993,
  },
  {
    timestamp_start: '2022-01-01T07:06:54',
    timestamp_stop: '2022-01-01T07:06:59',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 135157,
    tank_volume_start: 21505.89999999993,
    tank_volume_stop: 21451.59999999993,
  },
  {
    timestamp_start: '2022-01-01T07:07:14',
    timestamp_stop: '2022-01-01T07:07:20',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 135159,
    tank_volume_start: 21451.59999999993,
    tank_volume_stop: 21395.29999999993,
  },
  {
    timestamp_start: '2022-01-01T07:07:35',
    timestamp_stop: '2022-01-01T07:07:40',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 135161,
    tank_volume_start: 21395.29999999993,
    tank_volume_stop: 21340.19999999993,
  },
  {
    timestamp_start: '2022-01-01T07:07:55',
    timestamp_stop: '2022-01-01T07:08:01',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 135163,
    tank_volume_start: 21340.19999999993,
    tank_volume_stop: 21284.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:08:16',
    timestamp_stop: '2022-01-01T07:08:22',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 135165,
    tank_volume_start: 21284.49999999993,
    tank_volume_stop: 21229.899999999932,
  },
  {
    timestamp_start: '2022-01-01T07:08:37',
    timestamp_stop: '2022-01-01T07:08:41',
    quantity: 49.6,
    vehicle: 'DEM777',
    id: 135167,
    tank_volume_start: 21229.899999999932,
    tank_volume_stop: 21180.299999999934,
  },
  {
    timestamp_start: '2022-01-01T07:08:56',
    timestamp_stop: '2022-01-01T07:09:02',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 135169,
    tank_volume_start: 21180.299999999934,
    tank_volume_stop: 21125.799999999934,
  },
  {
    timestamp_start: '2022-01-01T07:09:17',
    timestamp_stop: '2022-01-01T07:09:23',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 135171,
    tank_volume_start: 21125.799999999934,
    tank_volume_stop: 21069.899999999932,
  },
  {
    timestamp_start: '2022-01-01T07:09:38',
    timestamp_stop: '2022-01-01T07:09:43',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 135173,
    tank_volume_start: 21069.899999999932,
    tank_volume_stop: 21013.099999999933,
  },
  {
    timestamp_start: '2022-01-01T07:09:58',
    timestamp_stop: '2022-01-01T07:10:03',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 135175,
    tank_volume_start: 21013.099999999933,
    tank_volume_stop: 20965.299999999934,
  },
  {
    timestamp_start: '2022-01-01T07:10:18',
    timestamp_stop: '2022-01-01T07:10:24',
    quantity: 56.8,
    vehicle: 'DEM000',
    id: 135177,
    tank_volume_start: 20965.299999999934,
    tank_volume_stop: 20908.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:10:39',
    timestamp_stop: '2022-01-01T07:10:44',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 135179,
    tank_volume_start: 20908.499999999935,
    tank_volume_stop: 20855.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:10:59',
    timestamp_stop: '2022-01-01T07:11:05',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 135181,
    tank_volume_start: 20855.499999999935,
    tank_volume_stop: 20795.899999999936,
  },
  {
    timestamp_start: '2022-01-01T07:11:20',
    timestamp_stop: '2022-01-01T07:11:25',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 135183,
    tank_volume_start: 20795.899999999936,
    tank_volume_stop: 20741.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:11:40',
    timestamp_stop: '2022-01-01T07:11:46',
    quantity: 56.3,
    vehicle: 'DEM666',
    id: 135185,
    tank_volume_start: 20741.499999999935,
    tank_volume_stop: 20685.199999999935,
  },
  {
    timestamp_start: '2022-01-01T07:12:01',
    timestamp_stop: '2022-01-01T07:12:06',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 135187,
    tank_volume_start: 20685.199999999935,
    tank_volume_stop: 20631.199999999935,
  },
  {
    timestamp_start: '2022-01-01T07:12:21',
    timestamp_stop: '2022-01-01T07:12:27',
    quantity: 52.6,
    vehicle: 'DEM222',
    id: 135189,
    tank_volume_start: 20631.199999999935,
    tank_volume_stop: 20578.599999999937,
  },
  {
    timestamp_start: '2022-01-01T07:12:42',
    timestamp_stop: '2022-01-01T07:12:48',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 135191,
    tank_volume_start: 20578.599999999937,
    tank_volume_stop: 20519.299999999937,
  },
  {
    timestamp_start: '2022-01-01T07:13:03',
    timestamp_stop: '2022-01-01T07:13:08',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 135193,
    tank_volume_start: 20519.299999999937,
    tank_volume_stop: 20469.799999999937,
  },
  {
    timestamp_start: '2022-01-01T07:13:23',
    timestamp_stop: '2022-01-01T07:13:27',
    quantity: 48.3,
    vehicle: 'DEM222',
    id: 135195,
    tank_volume_start: 20469.799999999937,
    tank_volume_stop: 20421.499999999938,
  },
  {
    timestamp_start: '2022-01-01T07:13:42',
    timestamp_stop: '2022-01-01T07:13:48',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 135197,
    tank_volume_start: 20421.499999999938,
    tank_volume_stop: 20367.69999999994,
  },
  {
    timestamp_start: '2022-01-01T07:14:03',
    timestamp_stop: '2022-01-01T07:14:08',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 135199,
    tank_volume_start: 20367.69999999994,
    tank_volume_stop: 20315.19999999994,
  },
  {
    timestamp_start: '2022-01-01T07:14:23',
    timestamp_stop: '2022-01-01T07:14:28',
    quantity: 50.2,
    vehicle: 'DEM666',
    id: 135201,
    tank_volume_start: 20315.19999999994,
    tank_volume_stop: 20264.999999999938,
  },
  {
    timestamp_start: '2022-01-01T07:14:43',
    timestamp_stop: '2022-01-01T07:14:48',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 135203,
    tank_volume_start: 20264.999999999938,
    tank_volume_stop: 20215.999999999938,
  },
  {
    timestamp_start: '2022-01-01T07:15:03',
    timestamp_stop: '2022-01-01T07:15:08',
    quantity: 51.4,
    vehicle: 'DEM333',
    id: 135205,
    tank_volume_start: 20215.999999999938,
    tank_volume_stop: 20164.599999999937,
  },
  {
    timestamp_start: '2022-01-01T07:15:23',
    timestamp_stop: '2022-01-01T07:15:29',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 135207,
    tank_volume_start: 20164.599999999937,
    tank_volume_stop: 20109.899999999936,
  },
  {
    timestamp_start: '2022-01-01T07:15:44',
    timestamp_stop: '2022-01-01T07:15:49',
    quantity: 51.4,
    vehicle: 'DEM888',
    id: 135209,
    tank_volume_start: 20109.899999999936,
    tank_volume_stop: 20058.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:16:04',
    timestamp_stop: '2022-01-01T07:16:09',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 135211,
    tank_volume_start: 20058.499999999935,
    tank_volume_stop: 20008.999999999935,
  },
  {
    timestamp_start: '2022-01-01T07:16:24',
    timestamp_stop: '2022-01-01T07:16:29',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 135213,
    tank_volume_start: 20008.999999999935,
    tank_volume_stop: 19958.899999999936,
  },
  {
    timestamp_start: '2022-01-01T07:16:44',
    timestamp_stop: '2022-01-01T07:16:49',
    quantity: 51.7,
    vehicle: 'DEM888',
    id: 135215,
    tank_volume_start: 39958.899999999936,
    tank_volume_stop: 39907.19999999994,
  },
  {
    timestamp_start: '2022-01-01T07:17:04',
    timestamp_stop: '2022-01-01T07:17:09',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 135217,
    tank_volume_start: 39907.19999999994,
    tank_volume_stop: 39857.29999999994,
  },
  {
    timestamp_start: '2022-01-01T07:17:24',
    timestamp_stop: '2022-01-01T07:17:29',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 135219,
    tank_volume_start: 39857.29999999994,
    tank_volume_stop: 39808.399999999936,
  },
  {
    timestamp_start: '2022-01-01T07:17:44',
    timestamp_stop: '2022-01-01T07:17:49',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 135221,
    tank_volume_start: 39808.399999999936,
    tank_volume_stop: 39752.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:18:04',
    timestamp_stop: '2022-01-01T07:18:10',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 135223,
    tank_volume_start: 39752.499999999935,
    tank_volume_stop: 39696.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:18:25',
    timestamp_stop: '2022-01-01T07:18:31',
    quantity: 62.0,
    vehicle: 'DEM222',
    id: 135225,
    tank_volume_start: 39696.69999999993,
    tank_volume_stop: 39634.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:18:46',
    timestamp_stop: '2022-01-01T07:18:51',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 135227,
    tank_volume_start: 39634.69999999993,
    tank_volume_stop: 39581.19999999993,
  },
  {
    timestamp_start: '2022-01-01T07:19:06',
    timestamp_stop: '2022-01-01T07:19:12',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 135229,
    tank_volume_start: 39581.19999999993,
    tank_volume_stop: 39528.79999999993,
  },
  {
    timestamp_start: '2022-01-01T07:19:27',
    timestamp_stop: '2022-01-01T07:19:32',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 135231,
    tank_volume_start: 39528.79999999993,
    tank_volume_stop: 39476.09999999993,
  },
  {
    timestamp_start: '2022-01-01T07:19:47',
    timestamp_stop: '2022-01-01T07:19:52',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 135233,
    tank_volume_start: 39476.09999999993,
    tank_volume_stop: 39424.999999999935,
  },
  {
    timestamp_start: '2022-01-01T07:20:07',
    timestamp_stop: '2022-01-01T07:20:12',
    quantity: 45.7,
    vehicle: 'DEM666',
    id: 135235,
    tank_volume_start: 39424.999999999935,
    tank_volume_stop: 39379.29999999994,
  },
  {
    timestamp_start: '2022-01-01T07:20:27',
    timestamp_stop: '2022-01-01T07:20:33',
    quantity: 62.9,
    vehicle: 'DEM444',
    id: 135237,
    tank_volume_start: 39379.29999999994,
    tank_volume_stop: 39316.399999999936,
  },
  {
    timestamp_start: '2022-01-01T07:20:48',
    timestamp_stop: '2022-01-01T07:20:53',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 135239,
    tank_volume_start: 39316.399999999936,
    tank_volume_stop: 39265.09999999993,
  },
  {
    timestamp_start: '2022-01-01T07:21:08',
    timestamp_stop: '2022-01-01T07:21:13',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 135241,
    tank_volume_start: 39265.09999999993,
    tank_volume_stop: 39210.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:21:28',
    timestamp_stop: '2022-01-01T07:21:33',
    quantity: 48.6,
    vehicle: 'DEM444',
    id: 135243,
    tank_volume_start: 39210.69999999993,
    tank_volume_stop: 39162.09999999993,
  },
  {
    timestamp_start: '2022-01-01T07:21:48',
    timestamp_stop: '2022-01-01T07:21:54',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 135245,
    tank_volume_start: 39162.09999999993,
    tank_volume_stop: 39105.59999999993,
  },
  {
    timestamp_start: '2022-01-01T07:22:09',
    timestamp_stop: '2022-01-01T07:22:14',
    quantity: 49.9,
    vehicle: 'DEM666',
    id: 135247,
    tank_volume_start: 39105.59999999993,
    tank_volume_stop: 39055.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:22:29',
    timestamp_stop: '2022-01-01T07:22:34',
    quantity: 48.1,
    vehicle: 'DEM555',
    id: 135249,
    tank_volume_start: 39055.69999999993,
    tank_volume_stop: 39007.59999999993,
  },
  {
    timestamp_start: '2022-01-01T07:22:49',
    timestamp_stop: '2022-01-01T07:22:54',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 135251,
    tank_volume_start: 39007.59999999993,
    tank_volume_stop: 38952.899999999936,
  },
  {
    timestamp_start: '2022-01-01T07:23:09',
    timestamp_stop: '2022-01-01T07:23:15',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 135253,
    tank_volume_start: 38952.899999999936,
    tank_volume_stop: 38893.29999999994,
  },
  {
    timestamp_start: '2022-01-01T07:23:30',
    timestamp_stop: '2022-01-01T07:23:36',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 135255,
    tank_volume_start: 38893.29999999994,
    tank_volume_stop: 38835.999999999935,
  },
  {
    timestamp_start: '2022-01-01T07:23:51',
    timestamp_stop: '2022-01-01T07:23:57',
    quantity: 60.1,
    vehicle: 'DEM666',
    id: 135257,
    tank_volume_start: 38835.999999999935,
    tank_volume_stop: 38775.899999999936,
  },
  {
    timestamp_start: '2022-01-01T07:24:12',
    timestamp_stop: '2022-01-01T07:24:18',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 135259,
    tank_volume_start: 38775.899999999936,
    tank_volume_stop: 38716.79999999994,
  },
  {
    timestamp_start: '2022-01-01T07:24:33',
    timestamp_stop: '2022-01-01T07:24:39',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 135261,
    tank_volume_start: 38716.79999999994,
    tank_volume_stop: 38659.79999999994,
  },
  {
    timestamp_start: '2022-01-01T07:24:54',
    timestamp_stop: '2022-01-01T07:24:59',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 135263,
    tank_volume_start: 38659.79999999994,
    tank_volume_stop: 38602.19999999994,
  },
  {
    timestamp_start: '2022-01-01T07:25:14',
    timestamp_stop: '2022-01-01T07:25:20',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 135265,
    tank_volume_start: 38602.19999999994,
    tank_volume_stop: 38546.49999999994,
  },
  {
    timestamp_start: '2022-01-01T07:25:35',
    timestamp_stop: '2022-01-01T07:25:40',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 135267,
    tank_volume_start: 38546.49999999994,
    tank_volume_stop: 38492.39999999994,
  },
  {
    timestamp_start: '2022-01-01T07:25:55',
    timestamp_stop: '2022-01-01T07:26:01',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 135269,
    tank_volume_start: 38492.39999999994,
    tank_volume_stop: 38435.89999999994,
  },
  {
    timestamp_start: '2022-01-01T07:26:16',
    timestamp_stop: '2022-01-01T07:26:21',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 135271,
    tank_volume_start: 38435.89999999994,
    tank_volume_stop: 38381.59999999994,
  },
  {
    timestamp_start: '2022-01-01T07:26:36',
    timestamp_stop: '2022-01-01T07:26:43',
    quantity: 64.3,
    vehicle: 'DEM666',
    id: 135273,
    tank_volume_start: 38381.59999999994,
    tank_volume_stop: 38317.29999999994,
  },
  {
    timestamp_start: '2022-01-01T07:26:58',
    timestamp_stop: '2022-01-01T07:27:03',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 135275,
    tank_volume_start: 38317.29999999994,
    tank_volume_stop: 38263.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:27:18',
    timestamp_stop: '2022-01-01T07:27:24',
    quantity: 59.7,
    vehicle: 'DEM333',
    id: 135277,
    tank_volume_start: 38263.499999999935,
    tank_volume_stop: 38203.79999999994,
  },
  {
    timestamp_start: '2022-01-01T07:27:39',
    timestamp_stop: '2022-01-01T07:27:45',
    quantity: 55.1,
    vehicle: 'DEM777',
    id: 135279,
    tank_volume_start: 38203.79999999994,
    tank_volume_stop: 38148.69999999994,
  },
  {
    timestamp_start: '2022-01-01T07:28:00',
    timestamp_stop: '2022-01-01T07:28:05',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 135281,
    tank_volume_start: 38148.69999999994,
    tank_volume_stop: 38094.69999999994,
  },
  {
    timestamp_start: '2022-01-01T07:28:20',
    timestamp_stop: '2022-01-01T07:28:26',
    quantity: 62.2,
    vehicle: 'DEM555',
    id: 135283,
    tank_volume_start: 38094.69999999994,
    tank_volume_stop: 38032.49999999994,
  },
  {
    timestamp_start: '2022-01-01T07:28:41',
    timestamp_stop: '2022-01-01T07:28:47',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 135285,
    tank_volume_start: 38032.49999999994,
    tank_volume_stop: 37970.89999999994,
  },
  {
    timestamp_start: '2022-01-01T07:29:02',
    timestamp_stop: '2022-01-01T07:29:08',
    quantity: 51.3,
    vehicle: 'DEM555',
    id: 135287,
    tank_volume_start: 37970.89999999994,
    tank_volume_stop: 37919.59999999994,
  },
  {
    timestamp_start: '2022-01-01T07:29:23',
    timestamp_stop: '2022-01-01T07:29:27',
    quantity: 48.0,
    vehicle: 'DEM222',
    id: 135289,
    tank_volume_start: 37919.59999999994,
    tank_volume_stop: 37871.59999999994,
  },
  {
    timestamp_start: '2022-01-01T07:29:42',
    timestamp_stop: '2022-01-01T07:29:48',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 135291,
    tank_volume_start: 37871.59999999994,
    tank_volume_stop: 37814.79999999994,
  },
  {
    timestamp_start: '2022-01-01T07:30:03',
    timestamp_stop: '2022-01-01T07:30:08',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 135293,
    tank_volume_start: 37814.79999999994,
    tank_volume_stop: 37760.499999999935,
  },
  {
    timestamp_start: '2022-01-01T07:30:23',
    timestamp_stop: '2022-01-01T07:30:29',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 135295,
    tank_volume_start: 37760.499999999935,
    tank_volume_stop: 37707.999999999935,
  },
  {
    timestamp_start: '2022-01-01T07:30:44',
    timestamp_stop: '2022-01-01T07:30:49',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 135297,
    tank_volume_start: 37707.999999999935,
    tank_volume_stop: 37653.69999999993,
  },
  {
    timestamp_start: '2022-01-01T07:31:04',
    timestamp_stop: '2022-01-01T07:31:09',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 135299,
    tank_volume_start: 37653.69999999993,
    tank_volume_stop: 37601.89999999993,
  },
  {
    timestamp_start: '2022-01-01T07:31:24',
    timestamp_stop: '2022-01-01T07:31:30',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 135301,
    tank_volume_start: 37601.89999999993,
    tank_volume_stop: 37543.39999999993,
  },
  {
    timestamp_start: '2022-01-01T07:31:45',
    timestamp_stop: '2022-01-01T07:31:51',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 135303,
    tank_volume_start: 37543.39999999993,
    tank_volume_stop: 37480.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:32:06',
    timestamp_stop: '2022-01-01T07:32:12',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 135305,
    tank_volume_start: 37480.49999999993,
    tank_volume_stop: 37429.39999999993,
  },
  {
    timestamp_start: '2022-01-01T07:32:27',
    timestamp_stop: '2022-01-01T07:32:33',
    quantity: 64.8,
    vehicle: 'DEM222',
    id: 135307,
    tank_volume_start: 37429.39999999993,
    tank_volume_stop: 37364.599999999926,
  },
  {
    timestamp_start: '2022-01-01T07:32:48',
    timestamp_stop: '2022-01-01T07:32:54',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 135309,
    tank_volume_start: 37364.599999999926,
    tank_volume_stop: 37306.099999999926,
  },
  {
    timestamp_start: '2022-01-01T07:33:09',
    timestamp_stop: '2022-01-01T07:33:14',
    quantity: 49.6,
    vehicle: 'DEM777',
    id: 135311,
    tank_volume_start: 37306.099999999926,
    tank_volume_stop: 37256.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:33:29',
    timestamp_stop: '2022-01-01T07:33:35',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 135313,
    tank_volume_start: 37256.49999999993,
    tank_volume_stop: 37195.599999999926,
  },
  {
    timestamp_start: '2022-01-01T07:33:50',
    timestamp_stop: '2022-01-01T07:33:56',
    quantity: 60.4,
    vehicle: 'DEM666',
    id: 135315,
    tank_volume_start: 37195.599999999926,
    tank_volume_stop: 37135.199999999924,
  },
  {
    timestamp_start: '2022-01-01T07:34:11',
    timestamp_stop: '2022-01-01T07:34:16',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 135317,
    tank_volume_start: 37135.199999999924,
    tank_volume_stop: 37082.39999999992,
  },
  {
    timestamp_start: '2022-01-01T07:34:31',
    timestamp_stop: '2022-01-01T07:34:36',
    quantity: 47.7,
    vehicle: 'DEM888',
    id: 135319,
    tank_volume_start: 37082.39999999992,
    tank_volume_stop: 37034.699999999924,
  },
  {
    timestamp_start: '2022-01-01T07:34:51',
    timestamp_stop: '2022-01-01T07:34:56',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 135321,
    tank_volume_start: 37034.699999999924,
    tank_volume_stop: 36986.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:35:11',
    timestamp_stop: '2022-01-01T07:35:17',
    quantity: 58.0,
    vehicle: 'DEM888',
    id: 135323,
    tank_volume_start: 36986.49999999993,
    tank_volume_stop: 36928.49999999993,
  },
  {
    timestamp_start: '2022-01-01T07:35:32',
    timestamp_stop: '2022-01-01T07:35:37',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 135325,
    tank_volume_start: 36928.49999999993,
    tank_volume_stop: 36872.99999999993,
  },
  {
    timestamp_start: '2022-01-01T07:35:52',
    timestamp_stop: '2022-01-01T07:35:58',
    quantity: 61.8,
    vehicle: 'DEM333',
    id: 135327,
    tank_volume_start: 36872.99999999993,
    tank_volume_stop: 36811.199999999924,
  },
  {
    timestamp_start: '2022-01-01T07:36:13',
    timestamp_stop: '2022-01-01T07:36:19',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 135329,
    tank_volume_start: 36811.199999999924,
    tank_volume_stop: 36757.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:36:34',
    timestamp_stop: '2022-01-01T07:36:39',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 135331,
    tank_volume_start: 36757.89999999992,
    tank_volume_stop: 36706.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:36:54',
    timestamp_stop: '2022-01-01T07:36:59',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 135333,
    tank_volume_start: 36706.79999999992,
    tank_volume_stop: 36653.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:37:14',
    timestamp_stop: '2022-01-01T07:37:19',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 135335,
    tank_volume_start: 36653.99999999992,
    tank_volume_stop: 36602.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:37:34',
    timestamp_stop: '2022-01-01T07:37:40',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 135337,
    tank_volume_start: 36602.89999999992,
    tank_volume_stop: 36545.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:37:55',
    timestamp_stop: '2022-01-01T07:38:00',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 135339,
    tank_volume_start: 36545.79999999992,
    tank_volume_stop: 36495.49999999992,
  },
  {
    timestamp_start: '2022-01-01T07:38:15',
    timestamp_stop: '2022-01-01T07:38:20',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 135341,
    tank_volume_start: 36495.49999999992,
    tank_volume_stop: 36440.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:38:35',
    timestamp_stop: '2022-01-01T07:38:40',
    quantity: 47.3,
    vehicle: 'DEM888',
    id: 135343,
    tank_volume_start: 36440.79999999992,
    tank_volume_stop: 36393.49999999992,
  },
  {
    timestamp_start: '2022-01-01T07:38:55',
    timestamp_stop: '2022-01-01T07:39:00',
    quantity: 51.8,
    vehicle: 'DEM555',
    id: 135345,
    tank_volume_start: 36393.49999999992,
    tank_volume_stop: 36341.69999999992,
  },
  {
    timestamp_start: '2022-01-01T07:39:15',
    timestamp_stop: '2022-01-01T07:39:20',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 135347,
    tank_volume_start: 36341.69999999992,
    tank_volume_stop: 36290.09999999992,
  },
  {
    timestamp_start: '2022-01-01T07:39:35',
    timestamp_stop: '2022-01-01T07:39:41',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 135349,
    tank_volume_start: 36290.09999999992,
    tank_volume_stop: 36231.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:39:56',
    timestamp_stop: '2022-01-01T07:40:02',
    quantity: 58.4,
    vehicle: 'DEM444',
    id: 135351,
    tank_volume_start: 36231.89999999992,
    tank_volume_stop: 36173.49999999992,
  },
  {
    timestamp_start: '2022-01-01T07:40:17',
    timestamp_stop: '2022-01-01T07:40:23',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 135353,
    tank_volume_start: 36173.49999999992,
    tank_volume_stop: 36114.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:40:38',
    timestamp_stop: '2022-01-01T07:40:44',
    quantity: 59.8,
    vehicle: 'DEM555',
    id: 135355,
    tank_volume_start: 36114.99999999992,
    tank_volume_stop: 36055.19999999992,
  },
  {
    timestamp_start: '2022-01-01T07:40:59',
    timestamp_stop: '2022-01-01T07:41:04',
    quantity: 49.4,
    vehicle: 'DEM555',
    id: 135357,
    tank_volume_start: 36055.19999999992,
    tank_volume_stop: 36005.799999999916,
  },
  {
    timestamp_start: '2022-01-01T07:41:19',
    timestamp_stop: '2022-01-01T07:41:24',
    quantity: 50.7,
    vehicle: 'DEM000',
    id: 135359,
    tank_volume_start: 36005.799999999916,
    tank_volume_stop: 35955.09999999992,
  },
  {
    timestamp_start: '2022-01-01T07:41:39',
    timestamp_stop: '2022-01-01T07:41:44',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 135361,
    tank_volume_start: 35955.09999999992,
    tank_volume_stop: 35901.299999999916,
  },
  {
    timestamp_start: '2022-01-01T07:41:59',
    timestamp_stop: '2022-01-01T07:42:05',
    quantity: 60.0,
    vehicle: 'DEM888',
    id: 135363,
    tank_volume_start: 35901.299999999916,
    tank_volume_stop: 35841.299999999916,
  },
  {
    timestamp_start: '2022-01-01T07:42:20',
    timestamp_stop: '2022-01-01T07:42:26',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 135365,
    tank_volume_start: 35841.299999999916,
    tank_volume_stop: 35789.59999999992,
  },
  {
    timestamp_start: '2022-01-01T07:42:41',
    timestamp_stop: '2022-01-01T07:42:45',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 135367,
    tank_volume_start: 35789.59999999992,
    tank_volume_stop: 35740.69999999992,
  },
  {
    timestamp_start: '2022-01-01T07:43:00',
    timestamp_stop: '2022-01-01T07:43:06',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 135369,
    tank_volume_start: 35740.69999999992,
    tank_volume_stop: 35687.299999999916,
  },
  {
    timestamp_start: '2022-01-01T07:43:21',
    timestamp_stop: '2022-01-01T07:43:26',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 135371,
    tank_volume_start: 35687.299999999916,
    tank_volume_stop: 35633.19999999992,
  },
  {
    timestamp_start: '2022-01-01T07:43:41',
    timestamp_stop: '2022-01-01T07:43:47',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 135373,
    tank_volume_start: 35633.19999999992,
    tank_volume_stop: 35578.69999999992,
  },
  {
    timestamp_start: '2022-01-01T07:44:02',
    timestamp_stop: '2022-01-01T07:44:07',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 135375,
    tank_volume_start: 35578.69999999992,
    tank_volume_stop: 35524.399999999914,
  },
  {
    timestamp_start: '2022-01-01T07:44:22',
    timestamp_stop: '2022-01-01T07:44:28',
    quantity: 57.1,
    vehicle: 'DEM666',
    id: 135377,
    tank_volume_start: 35524.399999999914,
    tank_volume_stop: 35467.299999999916,
  },
  {
    timestamp_start: '2022-01-01T07:44:43',
    timestamp_stop: '2022-01-01T07:44:48',
    quantity: 56.5,
    vehicle: 'DEM222',
    id: 135379,
    tank_volume_start: 35467.299999999916,
    tank_volume_stop: 35410.799999999916,
  },
  {
    timestamp_start: '2022-01-01T07:45:03',
    timestamp_stop: '2022-01-01T07:45:09',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 135381,
    tank_volume_start: 35410.799999999916,
    tank_volume_stop: 35350.399999999914,
  },
  {
    timestamp_start: '2022-01-01T07:45:24',
    timestamp_stop: '2022-01-01T07:45:30',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 135383,
    tank_volume_start: 35350.399999999914,
    tank_volume_stop: 35296.399999999914,
  },
  {
    timestamp_start: '2022-01-01T07:45:45',
    timestamp_stop: '2022-01-01T07:45:50',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 135385,
    tank_volume_start: 35296.399999999914,
    tank_volume_stop: 35245.19999999992,
  },
  {
    timestamp_start: '2022-01-01T07:46:05',
    timestamp_stop: '2022-01-01T07:46:11',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 135387,
    tank_volume_start: 35245.19999999992,
    tank_volume_stop: 35188.19999999992,
  },
  {
    timestamp_start: '2022-01-01T07:46:26',
    timestamp_stop: '2022-01-01T07:46:31',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 135389,
    tank_volume_start: 35188.19999999992,
    tank_volume_stop: 35134.59999999992,
  },
  {
    timestamp_start: '2022-01-01T07:46:46',
    timestamp_stop: '2022-01-01T07:46:52',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 135391,
    tank_volume_start: 35134.59999999992,
    tank_volume_stop: 35077.49999999992,
  },
  {
    timestamp_start: '2022-01-01T07:47:07',
    timestamp_stop: '2022-01-01T07:47:13',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 135393,
    tank_volume_start: 35077.49999999992,
    tank_volume_stop: 35017.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:47:28',
    timestamp_stop: '2022-01-01T07:47:34',
    quantity: 63.1,
    vehicle: 'DEM000',
    id: 135395,
    tank_volume_start: 35017.89999999992,
    tank_volume_stop: 34954.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:47:49',
    timestamp_stop: '2022-01-01T07:47:55',
    quantity: 62.8,
    vehicle: 'DEM777',
    id: 135397,
    tank_volume_start: 34954.79999999992,
    tank_volume_stop: 34891.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:48:10',
    timestamp_stop: '2022-01-01T07:48:16',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 135399,
    tank_volume_start: 34891.99999999992,
    tank_volume_stop: 34835.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:48:31',
    timestamp_stop: '2022-01-01T07:48:37',
    quantity: 57.6,
    vehicle: 'DEM888',
    id: 135401,
    tank_volume_start: 34835.89999999992,
    tank_volume_stop: 34778.29999999992,
  },
  {
    timestamp_start: '2022-01-01T07:48:52',
    timestamp_stop: '2022-01-01T07:48:57',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 135403,
    tank_volume_start: 34778.29999999992,
    tank_volume_stop: 34729.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:49:12',
    timestamp_stop: '2022-01-01T07:49:17',
    quantity: 51.8,
    vehicle: 'DEM777',
    id: 135405,
    tank_volume_start: 34729.99999999992,
    tank_volume_stop: 34678.19999999992,
  },
  {
    timestamp_start: '2022-01-01T07:49:32',
    timestamp_stop: '2022-01-01T07:49:38',
    quantity: 58.5,
    vehicle: 'DEM333',
    id: 135407,
    tank_volume_start: 34678.19999999992,
    tank_volume_stop: 34619.69999999992,
  },
  {
    timestamp_start: '2022-01-01T07:49:53',
    timestamp_stop: '2022-01-01T07:49:58',
    quantity: 53.6,
    vehicle: 'DEM111',
    id: 135409,
    tank_volume_start: 34619.69999999992,
    tank_volume_stop: 34566.09999999992,
  },
  {
    timestamp_start: '2022-01-01T07:50:13',
    timestamp_stop: '2022-01-01T07:50:18',
    quantity: 48.1,
    vehicle: 'DEM333',
    id: 135411,
    tank_volume_start: 34566.09999999992,
    tank_volume_stop: 34517.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:50:33',
    timestamp_stop: '2022-01-01T07:50:39',
    quantity: 59.7,
    vehicle: 'DEM888',
    id: 135413,
    tank_volume_start: 34517.99999999992,
    tank_volume_stop: 34458.29999999992,
  },
  {
    timestamp_start: '2022-01-01T07:50:54',
    timestamp_stop: '2022-01-01T07:50:59',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 135415,
    tank_volume_start: 34458.29999999992,
    tank_volume_stop: 34400.099999999926,
  },
  {
    timestamp_start: '2022-01-01T07:51:14',
    timestamp_stop: '2022-01-01T07:51:20',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 135417,
    tank_volume_start: 34400.099999999926,
    tank_volume_stop: 34349.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:51:35',
    timestamp_stop: '2022-01-01T07:51:40',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 135419,
    tank_volume_start: 34349.79999999992,
    tank_volume_stop: 34294.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:51:55',
    timestamp_stop: '2022-01-01T07:52:00',
    quantity: 47.7,
    vehicle: 'DEM000',
    id: 135421,
    tank_volume_start: 34294.99999999992,
    tank_volume_stop: 34247.29999999992,
  },
  {
    timestamp_start: '2022-01-01T07:52:15',
    timestamp_stop: '2022-01-01T07:52:21',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 135423,
    tank_volume_start: 34247.29999999992,
    tank_volume_stop: 34186.39999999992,
  },
  {
    timestamp_start: '2022-01-01T07:52:36',
    timestamp_stop: '2022-01-01T07:52:42',
    quantity: 60.7,
    vehicle: 'DEM222',
    id: 135425,
    tank_volume_start: 34186.39999999992,
    tank_volume_stop: 34125.699999999924,
  },
  {
    timestamp_start: '2022-01-01T07:52:57',
    timestamp_stop: '2022-01-01T07:53:02',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 135427,
    tank_volume_start: 34125.699999999924,
    tank_volume_stop: 34072.599999999926,
  },
  {
    timestamp_start: '2022-01-01T07:53:17',
    timestamp_stop: '2022-01-01T07:53:23',
    quantity: 59.3,
    vehicle: 'DEM000',
    id: 135429,
    tank_volume_start: 34072.599999999926,
    tank_volume_stop: 34013.29999999992,
  },
  {
    timestamp_start: '2022-01-01T07:53:38',
    timestamp_stop: '2022-01-01T07:53:45',
    quantity: 63.7,
    vehicle: 'DEM333',
    id: 135431,
    tank_volume_start: 34013.29999999992,
    tank_volume_stop: 33949.599999999926,
  },
  {
    timestamp_start: '2022-01-01T07:54:00',
    timestamp_stop: '2022-01-01T07:54:04',
    quantity: 45.9,
    vehicle: 'DEM777',
    id: 135433,
    tank_volume_start: 33949.599999999926,
    tank_volume_stop: 33903.699999999924,
  },
  {
    timestamp_start: '2022-01-01T07:54:19',
    timestamp_stop: '2022-01-01T07:54:25',
    quantity: 53.8,
    vehicle: 'DEM777',
    id: 135435,
    tank_volume_start: 33903.699999999924,
    tank_volume_stop: 33849.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:54:40',
    timestamp_stop: '2022-01-01T07:54:45',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 135437,
    tank_volume_start: 33849.89999999992,
    tank_volume_stop: 33798.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:55:00',
    timestamp_stop: '2022-01-01T07:55:05',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 135439,
    tank_volume_start: 33798.89999999992,
    tank_volume_stop: 33747.49999999992,
  },
  {
    timestamp_start: '2022-01-01T07:55:20',
    timestamp_stop: '2022-01-01T07:55:26',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 135441,
    tank_volume_start: 33747.49999999992,
    tank_volume_stop: 33685.39999999992,
  },
  {
    timestamp_start: '2022-01-01T07:55:41',
    timestamp_stop: '2022-01-01T07:55:47',
    quantity: 60.3,
    vehicle: 'DEM555',
    id: 135443,
    tank_volume_start: 33685.39999999992,
    tank_volume_stop: 33625.09999999992,
  },
  {
    timestamp_start: '2022-01-01T07:56:02',
    timestamp_stop: '2022-01-01T07:56:07',
    quantity: 50.1,
    vehicle: 'DEM555',
    id: 135445,
    tank_volume_start: 33625.09999999992,
    tank_volume_stop: 33574.99999999992,
  },
  {
    timestamp_start: '2022-01-01T07:56:22',
    timestamp_stop: '2022-01-01T07:56:28',
    quantity: 60.1,
    vehicle: 'DEM666',
    id: 135447,
    tank_volume_start: 33574.99999999992,
    tank_volume_stop: 33514.89999999992,
  },
  {
    timestamp_start: '2022-01-01T07:56:43',
    timestamp_stop: '2022-01-01T07:56:49',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 135449,
    tank_volume_start: 33514.89999999992,
    tank_volume_stop: 33458.59999999992,
  },
  {
    timestamp_start: '2022-01-01T07:57:04',
    timestamp_stop: '2022-01-01T07:57:09',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 135451,
    tank_volume_start: 33458.59999999992,
    tank_volume_stop: 33405.39999999992,
  },
  {
    timestamp_start: '2022-01-01T07:57:24',
    timestamp_stop: '2022-01-01T07:57:29',
    quantity: 45.6,
    vehicle: 'DEM444',
    id: 135453,
    tank_volume_start: 33405.39999999992,
    tank_volume_stop: 33359.79999999992,
  },
  {
    timestamp_start: '2022-01-01T07:57:44',
    timestamp_stop: '2022-01-01T07:57:49',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 135455,
    tank_volume_start: 33359.79999999992,
    tank_volume_stop: 33308.199999999924,
  },
  {
    timestamp_start: '2022-01-01T07:58:04',
    timestamp_stop: '2022-01-01T07:58:10',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 135457,
    tank_volume_start: 33308.199999999924,
    tank_volume_stop: 33249.99999999993,
  },
  {
    timestamp_start: '2022-01-01T07:58:25',
    timestamp_stop: '2022-01-01T07:58:30',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 135459,
    tank_volume_start: 33249.99999999993,
    tank_volume_stop: 33195.29999999993,
  },
  {
    timestamp_start: '2022-01-01T07:58:45',
    timestamp_stop: '2022-01-01T07:58:50',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 135461,
    tank_volume_start: 33195.29999999993,
    tank_volume_stop: 33140.19999999993,
  },
  {
    timestamp_start: '2022-01-01T07:59:05',
    timestamp_stop: '2022-01-01T07:59:11',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 135463,
    tank_volume_start: 33140.19999999993,
    tank_volume_stop: 33085.39999999993,
  },
  {
    timestamp_start: '2022-01-01T07:59:26',
    timestamp_stop: '2022-01-01T07:59:32',
    quantity: 63.5,
    vehicle: 'DEM666',
    id: 135465,
    tank_volume_start: 33085.39999999993,
    tank_volume_stop: 33021.89999999993,
  },
  {
    timestamp_start: '2022-01-01T07:59:47',
    timestamp_stop: '2022-01-01T07:59:53',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 135467,
    tank_volume_start: 33021.89999999993,
    tank_volume_stop: 32965.69999999993,
  },
  {
    timestamp_start: '2022-01-01T08:00:08',
    timestamp_stop: '2022-01-01T08:00:13',
    quantity: 55.6,
    vehicle: 'DEM888',
    id: 135469,
    tank_volume_start: 32965.69999999993,
    tank_volume_stop: 32910.09999999993,
  },
  {
    timestamp_start: '2022-01-01T08:00:28',
    timestamp_stop: '2022-01-01T08:00:34',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 135471,
    tank_volume_start: 32910.09999999993,
    tank_volume_stop: 32855.499999999935,
  },
  {
    timestamp_start: '2022-01-01T08:00:49',
    timestamp_stop: '2022-01-01T08:00:54',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 135473,
    tank_volume_start: 32855.499999999935,
    tank_volume_stop: 32801.69999999993,
  },
  {
    timestamp_start: '2022-01-01T08:01:09',
    timestamp_stop: '2022-01-01T08:01:14',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 135475,
    tank_volume_start: 32801.69999999993,
    tank_volume_stop: 32751.49999999993,
  },
  {
    timestamp_start: '2022-01-01T08:01:29',
    timestamp_stop: '2022-01-01T08:01:35',
    quantity: 51.9,
    vehicle: 'DEM888',
    id: 135477,
    tank_volume_start: 32751.49999999993,
    tank_volume_stop: 32699.59999999993,
  },
  {
    timestamp_start: '2022-01-01T08:01:50',
    timestamp_stop: '2022-01-01T08:01:55',
    quantity: 50.1,
    vehicle: 'DEM444',
    id: 135479,
    tank_volume_start: 32699.59999999993,
    tank_volume_stop: 32649.49999999993,
  },
  {
    timestamp_start: '2022-01-01T08:02:10',
    timestamp_stop: '2022-01-01T08:02:15',
    quantity: 58.3,
    vehicle: 'DEM666',
    id: 135481,
    tank_volume_start: 32649.49999999993,
    tank_volume_stop: 32591.19999999993,
  },
  {
    timestamp_start: '2022-01-01T08:02:30',
    timestamp_stop: '2022-01-01T08:02:36',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 135483,
    tank_volume_start: 32591.19999999993,
    tank_volume_stop: 32531.399999999932,
  },
  {
    timestamp_start: '2022-01-01T08:02:51',
    timestamp_stop: '2022-01-01T08:02:56',
    quantity: 45.6,
    vehicle: 'DEM666',
    id: 135485,
    tank_volume_start: 32531.399999999932,
    tank_volume_stop: 32485.799999999934,
  },
  {
    timestamp_start: '2022-01-01T08:03:11',
    timestamp_stop: '2022-01-01T08:03:17',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 135487,
    tank_volume_start: 32485.799999999934,
    tank_volume_stop: 32429.199999999935,
  },
  {
    timestamp_start: '2022-01-01T08:03:32',
    timestamp_stop: '2022-01-01T08:03:37',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 135489,
    tank_volume_start: 32429.199999999935,
    tank_volume_stop: 32376.299999999934,
  },
  {
    timestamp_start: '2022-01-01T08:03:52',
    timestamp_stop: '2022-01-01T08:03:57',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 135491,
    tank_volume_start: 32376.299999999934,
    tank_volume_stop: 32322.299999999934,
  },
  {
    timestamp_start: '2022-01-01T08:04:12',
    timestamp_stop: '2022-01-01T08:04:18',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 135493,
    tank_volume_start: 32322.299999999934,
    tank_volume_stop: 32266.099999999933,
  },
  {
    timestamp_start: '2022-01-01T08:04:33',
    timestamp_stop: '2022-01-01T08:04:38',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 135495,
    tank_volume_start: 32266.099999999933,
    tank_volume_stop: 32212.999999999935,
  },
  {
    timestamp_start: '2022-01-01T08:04:53',
    timestamp_stop: '2022-01-01T08:04:59',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 135497,
    tank_volume_start: 32212.999999999935,
    tank_volume_stop: 32157.699999999935,
  },
  {
    timestamp_start: '2022-01-01T08:05:14',
    timestamp_stop: '2022-01-01T08:05:19',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 135499,
    tank_volume_start: 32157.699999999935,
    tank_volume_stop: 32104.999999999935,
  },
  {
    timestamp_start: '2022-01-01T08:05:34',
    timestamp_stop: '2022-01-01T08:05:39',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 135501,
    tank_volume_start: 32104.999999999935,
    tank_volume_stop: 32054.999999999935,
  },
  {
    timestamp_start: '2022-01-01T08:05:54',
    timestamp_stop: '2022-01-01T08:05:59',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 135503,
    tank_volume_start: 32054.999999999935,
    tank_volume_stop: 32001.799999999934,
  },
  {
    timestamp_start: '2022-01-01T08:06:14',
    timestamp_stop: '2022-01-01T08:06:20',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 135505,
    tank_volume_start: 32001.799999999934,
    tank_volume_stop: 31946.599999999933,
  },
  {
    timestamp_start: '2022-01-01T08:06:35',
    timestamp_stop: '2022-01-01T08:06:41',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 135507,
    tank_volume_start: 31946.599999999933,
    tank_volume_stop: 31889.299999999934,
  },
  {
    timestamp_start: '2022-01-01T08:06:56',
    timestamp_stop: '2022-01-01T08:07:01',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 135509,
    tank_volume_start: 31889.299999999934,
    tank_volume_stop: 31833.499999999935,
  },
  {
    timestamp_start: '2022-01-01T08:07:16',
    timestamp_stop: '2022-01-01T08:07:22',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 135511,
    tank_volume_start: 31833.499999999935,
    tank_volume_stop: 31778.799999999934,
  },
  {
    timestamp_start: '2022-01-01T08:07:37',
    timestamp_stop: '2022-01-01T08:07:42',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 135513,
    tank_volume_start: 31778.799999999934,
    tank_volume_stop: 31724.099999999933,
  },
  {
    timestamp_start: '2022-01-01T08:07:57',
    timestamp_stop: '2022-01-01T08:08:02',
    quantity: 49.8,
    vehicle: 'DEM222',
    id: 135515,
    tank_volume_start: 31724.099999999933,
    tank_volume_stop: 31674.299999999934,
  },
  {
    timestamp_start: '2022-01-01T08:08:17',
    timestamp_stop: '2022-01-01T08:08:22',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 135517,
    tank_volume_start: 31674.299999999934,
    tank_volume_stop: 31623.099999999933,
  },
  {
    timestamp_start: '2022-01-01T08:08:37',
    timestamp_stop: '2022-01-01T08:08:42',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 135519,
    tank_volume_start: 31623.099999999933,
    tank_volume_stop: 31570.099999999933,
  },
  {
    timestamp_start: '2022-01-01T08:08:57',
    timestamp_stop: '2022-01-01T08:09:03',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 135521,
    tank_volume_start: 31570.099999999933,
    tank_volume_stop: 31519.499999999935,
  },
  {
    timestamp_start: '2022-01-01T08:09:18',
    timestamp_stop: '2022-01-01T08:09:23',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 135523,
    tank_volume_start: 31519.499999999935,
    tank_volume_stop: 31464.199999999935,
  },
  {
    timestamp_start: '2022-01-01T08:09:38',
    timestamp_stop: '2022-01-01T08:09:44',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 135525,
    tank_volume_start: 31464.199999999935,
    tank_volume_stop: 31406.799999999934,
  },
  {
    timestamp_start: '2022-01-01T08:09:59',
    timestamp_stop: '2022-01-01T08:10:04',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 135527,
    tank_volume_start: 31406.799999999934,
    tank_volume_stop: 31350.399999999932,
  },
  {
    timestamp_start: '2022-01-01T08:10:19',
    timestamp_stop: '2022-01-01T08:10:24',
    quantity: 47.7,
    vehicle: 'DEM888',
    id: 135529,
    tank_volume_start: 31350.399999999932,
    tank_volume_stop: 31302.69999999993,
  },
  {
    timestamp_start: '2022-01-01T08:10:39',
    timestamp_stop: '2022-01-01T08:10:45',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 135531,
    tank_volume_start: 31302.69999999993,
    tank_volume_stop: 31247.29999999993,
  },
  {
    timestamp_start: '2022-01-01T08:11:00',
    timestamp_stop: '2022-01-01T08:11:05',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 135533,
    tank_volume_start: 31247.29999999993,
    tank_volume_stop: 31191.39999999993,
  },
  {
    timestamp_start: '2022-01-01T08:11:20',
    timestamp_stop: '2022-01-01T08:11:26',
    quantity: 55.4,
    vehicle: 'DEM222',
    id: 135535,
    tank_volume_start: 31191.39999999993,
    tank_volume_stop: 31135.999999999927,
  },
  {
    timestamp_start: '2022-01-01T08:11:41',
    timestamp_stop: '2022-01-01T08:11:47',
    quantity: 58.9,
    vehicle: 'DEM888',
    id: 135537,
    tank_volume_start: 31135.999999999927,
    tank_volume_stop: 31077.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:12:02',
    timestamp_stop: '2022-01-01T08:12:07',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 135539,
    tank_volume_start: 31077.099999999926,
    tank_volume_stop: 31026.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:12:22',
    timestamp_stop: '2022-01-01T08:12:27',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 135541,
    tank_volume_start: 31026.099999999926,
    tank_volume_stop: 30970.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:12:42',
    timestamp_stop: '2022-01-01T08:12:48',
    quantity: 60.1,
    vehicle: 'DEM111',
    id: 135543,
    tank_volume_start: 30970.499999999927,
    tank_volume_stop: 30910.39999999993,
  },
  {
    timestamp_start: '2022-01-01T08:13:03',
    timestamp_stop: '2022-01-01T08:13:09',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 135545,
    tank_volume_start: 30910.39999999993,
    tank_volume_stop: 30852.699999999928,
  },
  {
    timestamp_start: '2022-01-01T08:13:24',
    timestamp_stop: '2022-01-01T08:13:29',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 135547,
    tank_volume_start: 30852.699999999928,
    tank_volume_stop: 30801.699999999928,
  },
  {
    timestamp_start: '2022-01-01T08:13:44',
    timestamp_stop: '2022-01-01T08:13:49',
    quantity: 48.5,
    vehicle: 'DEM555',
    id: 135549,
    tank_volume_start: 30801.699999999928,
    tank_volume_stop: 30753.199999999928,
  },
  {
    timestamp_start: '2022-01-01T08:14:04',
    timestamp_stop: '2022-01-01T08:14:09',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 135551,
    tank_volume_start: 30753.199999999928,
    tank_volume_stop: 30701.799999999927,
  },
  {
    timestamp_start: '2022-01-01T08:14:24',
    timestamp_stop: '2022-01-01T08:14:29',
    quantity: 50.3,
    vehicle: 'DEM555',
    id: 135553,
    tank_volume_start: 30701.799999999927,
    tank_volume_stop: 30651.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:14:44',
    timestamp_stop: '2022-01-01T08:14:50',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 135555,
    tank_volume_start: 30651.499999999927,
    tank_volume_stop: 30599.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:15:05',
    timestamp_stop: '2022-01-01T08:15:10',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 135557,
    tank_volume_start: 30599.099999999926,
    tank_volume_stop: 30544.999999999927,
  },
  {
    timestamp_start: '2022-01-01T08:15:25',
    timestamp_stop: '2022-01-01T08:15:31',
    quantity: 59.4,
    vehicle: 'DEM666',
    id: 135559,
    tank_volume_start: 30544.999999999927,
    tank_volume_stop: 30485.599999999926,
  },
  {
    timestamp_start: '2022-01-01T08:15:46',
    timestamp_stop: '2022-01-01T08:15:52',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 135561,
    tank_volume_start: 30485.599999999926,
    tank_volume_stop: 30428.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:16:07',
    timestamp_stop: '2022-01-01T08:16:11',
    quantity: 45.0,
    vehicle: 'DEM666',
    id: 135563,
    tank_volume_start: 30428.699999999924,
    tank_volume_stop: 30383.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:16:26',
    timestamp_stop: '2022-01-01T08:16:31',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 135565,
    tank_volume_start: 30383.699999999924,
    tank_volume_stop: 30333.299999999923,
  },
  {
    timestamp_start: '2022-01-01T08:16:46',
    timestamp_stop: '2022-01-01T08:16:51',
    quantity: 48.5,
    vehicle: 'DEM333',
    id: 135567,
    tank_volume_start: 30333.299999999923,
    tank_volume_stop: 30284.799999999923,
  },
  {
    timestamp_start: '2022-01-01T08:17:06',
    timestamp_stop: '2022-01-01T08:17:12',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 135569,
    tank_volume_start: 30284.799999999923,
    tank_volume_stop: 30224.199999999924,
  },
  {
    timestamp_start: '2022-01-01T08:17:27',
    timestamp_stop: '2022-01-01T08:17:33',
    quantity: 62.8,
    vehicle: 'DEM222',
    id: 135571,
    tank_volume_start: 30224.199999999924,
    tank_volume_stop: 30161.399999999925,
  },
  {
    timestamp_start: '2022-01-01T08:17:48',
    timestamp_stop: '2022-01-01T08:17:54',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 135573,
    tank_volume_start: 30161.399999999925,
    tank_volume_stop: 30106.999999999924,
  },
  {
    timestamp_start: '2022-01-01T08:18:09',
    timestamp_stop: '2022-01-01T08:18:14',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 135575,
    tank_volume_start: 30106.999999999924,
    tank_volume_stop: 30051.499999999924,
  },
  {
    timestamp_start: '2022-01-01T08:18:29',
    timestamp_stop: '2022-01-01T08:18:35',
    quantity: 55.8,
    vehicle: 'DEM777',
    id: 135577,
    tank_volume_start: 30051.499999999924,
    tank_volume_stop: 29995.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:18:50',
    timestamp_stop: '2022-01-01T08:18:55',
    quantity: 46.3,
    vehicle: 'DEM222',
    id: 135579,
    tank_volume_start: 29995.699999999924,
    tank_volume_stop: 29949.399999999925,
  },
  {
    timestamp_start: '2022-01-01T08:19:10',
    timestamp_stop: '2022-01-01T08:19:15',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 135581,
    tank_volume_start: 29949.399999999925,
    tank_volume_stop: 29895.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:19:30',
    timestamp_stop: '2022-01-01T08:19:35',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 135583,
    tank_volume_start: 29895.099999999926,
    tank_volume_stop: 29843.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:19:50',
    timestamp_stop: '2022-01-01T08:19:55',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 135585,
    tank_volume_start: 29843.699999999924,
    tank_volume_stop: 29790.899999999925,
  },
  {
    timestamp_start: '2022-01-01T08:20:10',
    timestamp_stop: '2022-01-01T08:20:17',
    quantity: 63.5,
    vehicle: 'DEM000',
    id: 135587,
    tank_volume_start: 29790.899999999925,
    tank_volume_stop: 29727.399999999925,
  },
  {
    timestamp_start: '2022-01-01T08:20:32',
    timestamp_stop: '2022-01-01T08:20:37',
    quantity: 54.1,
    vehicle: 'DEM777',
    id: 135589,
    tank_volume_start: 29727.399999999925,
    tank_volume_stop: 29673.299999999927,
  },
  {
    timestamp_start: '2022-01-01T08:20:52',
    timestamp_stop: '2022-01-01T08:20:57',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 135591,
    tank_volume_start: 29673.299999999927,
    tank_volume_stop: 29623.199999999928,
  },
  {
    timestamp_start: '2022-01-01T08:21:12',
    timestamp_stop: '2022-01-01T08:21:17',
    quantity: 49.1,
    vehicle: 'DEM333',
    id: 135593,
    tank_volume_start: 29623.199999999928,
    tank_volume_stop: 29574.09999999993,
  },
  {
    timestamp_start: '2022-01-01T08:21:32',
    timestamp_stop: '2022-01-01T08:21:38',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 135595,
    tank_volume_start: 29574.09999999993,
    tank_volume_stop: 29511.89999999993,
  },
  {
    timestamp_start: '2022-01-01T08:21:53',
    timestamp_stop: '2022-01-01T08:22:00',
    quantity: 62.2,
    vehicle: 'DEM777',
    id: 135597,
    tank_volume_start: 29511.89999999993,
    tank_volume_stop: 29449.699999999928,
  },
  {
    timestamp_start: '2022-01-01T08:22:15',
    timestamp_stop: '2022-01-01T08:22:20',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 135599,
    tank_volume_start: 29449.699999999928,
    tank_volume_stop: 29391.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:22:35',
    timestamp_stop: '2022-01-01T08:22:41',
    quantity: 57.0,
    vehicle: 'DEM666',
    id: 135601,
    tank_volume_start: 29391.499999999927,
    tank_volume_stop: 29334.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:22:56',
    timestamp_stop: '2022-01-01T08:23:02',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 135603,
    tank_volume_start: 29334.499999999927,
    tank_volume_stop: 29277.999999999927,
  },
  {
    timestamp_start: '2022-01-01T08:23:17',
    timestamp_stop: '2022-01-01T08:23:22',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 135605,
    tank_volume_start: 29277.999999999927,
    tank_volume_stop: 29220.999999999927,
  },
  {
    timestamp_start: '2022-01-01T08:23:37',
    timestamp_stop: '2022-01-01T08:23:43',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 135607,
    tank_volume_start: 29220.999999999927,
    tank_volume_stop: 29166.799999999927,
  },
  {
    timestamp_start: '2022-01-01T08:23:58',
    timestamp_stop: '2022-01-01T08:24:03',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 135609,
    tank_volume_start: 29166.799999999927,
    tank_volume_stop: 29110.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:24:18',
    timestamp_stop: '2022-01-01T08:24:25',
    quantity: 61.1,
    vehicle: 'DEM222',
    id: 135611,
    tank_volume_start: 29110.499999999927,
    tank_volume_stop: 29049.39999999993,
  },
  {
    timestamp_start: '2022-01-01T08:24:40',
    timestamp_stop: '2022-01-01T08:24:45',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 135613,
    tank_volume_start: 29049.39999999993,
    tank_volume_stop: 28995.699999999928,
  },
  {
    timestamp_start: '2022-01-01T08:25:00',
    timestamp_stop: '2022-01-01T08:25:05',
    quantity: 47.5,
    vehicle: 'DEM777',
    id: 135615,
    tank_volume_start: 28995.699999999928,
    tank_volume_stop: 28948.199999999928,
  },
  {
    timestamp_start: '2022-01-01T08:25:20',
    timestamp_stop: '2022-01-01T08:25:25',
    quantity: 57.8,
    vehicle: 'DEM222',
    id: 135617,
    tank_volume_start: 28948.199999999928,
    tank_volume_stop: 28890.39999999993,
  },
  {
    timestamp_start: '2022-01-01T08:25:40',
    timestamp_stop: '2022-01-01T08:25:46',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 135619,
    tank_volume_start: 28890.39999999993,
    tank_volume_stop: 28835.39999999993,
  },
  {
    timestamp_start: '2022-01-01T08:26:01',
    timestamp_stop: '2022-01-01T08:26:06',
    quantity: 49.7,
    vehicle: 'DEM111',
    id: 135621,
    tank_volume_start: 28835.39999999993,
    tank_volume_stop: 28785.699999999928,
  },
  {
    timestamp_start: '2022-01-01T08:26:21',
    timestamp_stop: '2022-01-01T08:26:27',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 135623,
    tank_volume_start: 28785.699999999928,
    tank_volume_stop: 28725.799999999927,
  },
  {
    timestamp_start: '2022-01-01T08:26:42',
    timestamp_stop: '2022-01-01T08:26:47',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 135625,
    tank_volume_start: 28725.799999999927,
    tank_volume_stop: 28670.999999999927,
  },
  {
    timestamp_start: '2022-01-01T08:27:02',
    timestamp_stop: '2022-01-01T08:27:07',
    quantity: 47.9,
    vehicle: 'DEM333',
    id: 135627,
    tank_volume_start: 28670.999999999927,
    tank_volume_stop: 28623.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:27:22',
    timestamp_stop: '2022-01-01T08:27:28',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 135629,
    tank_volume_start: 28623.099999999926,
    tank_volume_stop: 28563.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:27:43',
    timestamp_stop: '2022-01-01T08:27:49',
    quantity: 55.6,
    vehicle: 'DEM444',
    id: 135631,
    tank_volume_start: 28563.699999999924,
    tank_volume_stop: 28508.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:28:04',
    timestamp_stop: '2022-01-01T08:28:10',
    quantity: 59.2,
    vehicle: 'DEM888',
    id: 135633,
    tank_volume_start: 28508.099999999926,
    tank_volume_stop: 28448.899999999925,
  },
  {
    timestamp_start: '2022-01-01T08:28:25',
    timestamp_stop: '2022-01-01T08:28:30',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 135635,
    tank_volume_start: 28448.899999999925,
    tank_volume_stop: 28394.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:28:45',
    timestamp_stop: '2022-01-01T08:28:50',
    quantity: 46.0,
    vehicle: 'DEM222',
    id: 135637,
    tank_volume_start: 28394.099999999926,
    tank_volume_stop: 28348.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:29:05',
    timestamp_stop: '2022-01-01T08:29:11',
    quantity: 61.0,
    vehicle: 'DEM666',
    id: 135639,
    tank_volume_start: 28348.099999999926,
    tank_volume_stop: 28287.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:29:26',
    timestamp_stop: '2022-01-01T08:29:31',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 135641,
    tank_volume_start: 28287.099999999926,
    tank_volume_stop: 28231.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:29:46',
    timestamp_stop: '2022-01-01T08:29:52',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 135643,
    tank_volume_start: 28231.099999999926,
    tank_volume_stop: 28178.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:30:07',
    timestamp_stop: '2022-01-01T08:30:13',
    quantity: 64.5,
    vehicle: 'DEM333',
    id: 135645,
    tank_volume_start: 28178.699999999924,
    tank_volume_stop: 28114.199999999924,
  },
  {
    timestamp_start: '2022-01-01T08:30:28',
    timestamp_stop: '2022-01-01T08:30:34',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 135647,
    tank_volume_start: 28114.199999999924,
    tank_volume_stop: 28055.399999999925,
  },
  {
    timestamp_start: '2022-01-01T08:30:49',
    timestamp_stop: '2022-01-01T08:30:55',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 135649,
    tank_volume_start: 28055.399999999925,
    tank_volume_stop: 27994.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:31:10',
    timestamp_stop: '2022-01-01T08:31:15',
    quantity: 46.2,
    vehicle: 'DEM000',
    id: 135651,
    tank_volume_start: 27994.699999999924,
    tank_volume_stop: 27948.499999999924,
  },
  {
    timestamp_start: '2022-01-01T08:31:30',
    timestamp_stop: '2022-01-01T08:31:36',
    quantity: 61.1,
    vehicle: 'DEM111',
    id: 135653,
    tank_volume_start: 27948.499999999924,
    tank_volume_stop: 27887.399999999925,
  },
  {
    timestamp_start: '2022-01-01T08:31:51',
    timestamp_stop: '2022-01-01T08:31:57',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 135655,
    tank_volume_start: 27887.399999999925,
    tank_volume_stop: 27825.799999999927,
  },
  {
    timestamp_start: '2022-01-01T08:32:12',
    timestamp_stop: '2022-01-01T08:32:17',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 135657,
    tank_volume_start: 27825.799999999927,
    tank_volume_stop: 27773.099999999926,
  },
  {
    timestamp_start: '2022-01-01T08:32:32',
    timestamp_stop: '2022-01-01T08:32:38',
    quantity: 58.6,
    vehicle: 'DEM777',
    id: 135659,
    tank_volume_start: 27773.099999999926,
    tank_volume_stop: 27714.499999999927,
  },
  {
    timestamp_start: '2022-01-01T08:32:53',
    timestamp_stop: '2022-01-01T08:32:58',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 135661,
    tank_volume_start: 27714.499999999927,
    tank_volume_stop: 27663.599999999926,
  },
  {
    timestamp_start: '2022-01-01T08:33:13',
    timestamp_stop: '2022-01-01T08:33:19',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 135663,
    tank_volume_start: 27663.599999999926,
    tank_volume_stop: 27607.699999999924,
  },
  {
    timestamp_start: '2022-01-01T08:33:34',
    timestamp_stop: '2022-01-01T08:33:39',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 135665,
    tank_volume_start: 27607.699999999924,
    tank_volume_stop: 27554.999999999924,
  },
  {
    timestamp_start: '2022-01-01T08:33:54',
    timestamp_stop: '2022-01-01T08:33:59',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 135667,
    tank_volume_start: 27554.999999999924,
    tank_volume_stop: 27508.299999999923,
  },
  {
    timestamp_start: '2022-01-01T08:34:14',
    timestamp_stop: '2022-01-01T08:34:19',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 135669,
    tank_volume_start: 27508.299999999923,
    tank_volume_stop: 27450.39999999992,
  },
  {
    timestamp_start: '2022-01-01T08:34:34',
    timestamp_stop: '2022-01-01T08:34:40',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 135671,
    tank_volume_start: 27450.39999999992,
    tank_volume_stop: 27395.599999999922,
  },
  {
    timestamp_start: '2022-01-01T08:34:55',
    timestamp_stop: '2022-01-01T08:35:00',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 135673,
    tank_volume_start: 27395.599999999922,
    tank_volume_stop: 27340.19999999992,
  },
  {
    timestamp_start: '2022-01-01T08:35:15',
    timestamp_stop: '2022-01-01T08:35:20',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 135675,
    tank_volume_start: 27340.19999999992,
    tank_volume_stop: 27290.69999999992,
  },
  {
    timestamp_start: '2022-01-01T08:35:35',
    timestamp_stop: '2022-01-01T08:35:42',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 135677,
    tank_volume_start: 27290.69999999992,
    tank_volume_stop: 27228.79999999992,
  },
  {
    timestamp_start: '2022-01-01T08:35:57',
    timestamp_stop: '2022-01-01T08:36:02',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 135679,
    tank_volume_start: 27228.79999999992,
    tank_volume_stop: 27174.49999999992,
  },
  {
    timestamp_start: '2022-01-01T08:36:17',
    timestamp_stop: '2022-01-01T08:36:22',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 135681,
    tank_volume_start: 27174.49999999992,
    tank_volume_stop: 27126.59999999992,
  },
  {
    timestamp_start: '2022-01-01T08:36:37',
    timestamp_stop: '2022-01-01T08:36:42',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 135683,
    tank_volume_start: 27126.59999999992,
    tank_volume_stop: 27072.99999999992,
  },
  {
    timestamp_start: '2022-01-01T08:36:57',
    timestamp_stop: '2022-01-01T08:37:03',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 135685,
    tank_volume_start: 27072.99999999992,
    tank_volume_stop: 27012.99999999992,
  },
  {
    timestamp_start: '2022-01-01T08:37:18',
    timestamp_stop: '2022-01-01T08:37:23',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 135687,
    tank_volume_start: 27012.99999999992,
    tank_volume_stop: 26961.89999999992,
  },
  {
    timestamp_start: '2022-01-01T08:37:38',
    timestamp_stop: '2022-01-01T08:37:44',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 135689,
    tank_volume_start: 26961.89999999992,
    tank_volume_stop: 26901.89999999992,
  },
  {
    timestamp_start: '2022-01-01T08:37:59',
    timestamp_stop: '2022-01-01T08:38:06',
    quantity: 64.2,
    vehicle: 'DEM888',
    id: 135691,
    tank_volume_start: 26901.89999999992,
    tank_volume_stop: 26837.69999999992,
  },
  {
    timestamp_start: '2022-01-01T08:38:21',
    timestamp_stop: '2022-01-01T08:38:26',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 135693,
    tank_volume_start: 26837.69999999992,
    tank_volume_stop: 26785.099999999922,
  },
  {
    timestamp_start: '2022-01-01T08:38:41',
    timestamp_stop: '2022-01-01T08:38:46',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 135695,
    tank_volume_start: 26785.099999999922,
    tank_volume_stop: 26730.39999999992,
  },
  {
    timestamp_start: '2022-01-01T08:39:01',
    timestamp_stop: '2022-01-01T08:39:07',
    quantity: 55.2,
    vehicle: 'DEM444',
    id: 135697,
    tank_volume_start: 26730.39999999992,
    tank_volume_stop: 26675.19999999992,
  },
  {
    timestamp_start: '2022-01-01T08:39:22',
    timestamp_stop: '2022-01-01T08:39:28',
    quantity: 60.3,
    vehicle: 'DEM777',
    id: 135699,
    tank_volume_start: 26675.19999999992,
    tank_volume_stop: 26614.89999999992,
  },
  {
    timestamp_start: '2022-01-01T08:39:43',
    timestamp_stop: '2022-01-01T08:39:48',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 135701,
    tank_volume_start: 26614.89999999992,
    tank_volume_stop: 26560.099999999922,
  },
  {
    timestamp_start: '2022-01-01T08:40:03',
    timestamp_stop: '2022-01-01T08:40:09',
    quantity: 50.4,
    vehicle: 'DEM444',
    id: 135703,
    tank_volume_start: 26560.099999999922,
    tank_volume_stop: 26509.69999999992,
  },
  {
    timestamp_start: '2022-01-01T08:40:24',
    timestamp_stop: '2022-01-01T08:40:29',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 135705,
    tank_volume_start: 26509.69999999992,
    tank_volume_stop: 26454.19999999992,
  },
  {
    timestamp_start: '2022-01-01T08:40:44',
    timestamp_stop: '2022-01-01T08:40:50',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 135707,
    tank_volume_start: 26454.19999999992,
    tank_volume_stop: 26394.89999999992,
  },
  {
    timestamp_start: '2022-01-01T08:41:05',
    timestamp_stop: '2022-01-01T08:41:10',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 135709,
    tank_volume_start: 26394.89999999992,
    tank_volume_stop: 26341.49999999992,
  },
  {
    timestamp_start: '2022-01-01T08:41:25',
    timestamp_stop: '2022-01-01T08:41:31',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 135711,
    tank_volume_start: 26341.49999999992,
    tank_volume_stop: 26281.59999999992,
  },
  {
    timestamp_start: '2022-01-01T08:41:46',
    timestamp_stop: '2022-01-01T08:41:52',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 135713,
    tank_volume_start: 26281.59999999992,
    tank_volume_stop: 26221.699999999917,
  },
  {
    timestamp_start: '2022-01-01T08:42:07',
    timestamp_stop: '2022-01-01T08:42:13',
    quantity: 58.7,
    vehicle: 'DEM000',
    id: 135715,
    tank_volume_start: 26221.699999999917,
    tank_volume_stop: 26162.999999999916,
  },
  {
    timestamp_start: '2022-01-01T08:42:28',
    timestamp_stop: '2022-01-01T08:42:34',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 135717,
    tank_volume_start: 26162.999999999916,
    tank_volume_stop: 26109.899999999918,
  },
  {
    timestamp_start: '2022-01-01T08:42:49',
    timestamp_stop: '2022-01-01T08:42:54',
    quantity: 50.0,
    vehicle: 'DEM333',
    id: 135719,
    tank_volume_start: 26109.899999999918,
    tank_volume_stop: 26059.899999999918,
  },
  {
    timestamp_start: '2022-01-01T08:43:09',
    timestamp_stop: '2022-01-01T08:43:15',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 135721,
    tank_volume_start: 26059.899999999918,
    tank_volume_stop: 25999.499999999916,
  },
  {
    timestamp_start: '2022-01-01T08:43:30',
    timestamp_stop: '2022-01-01T08:43:35',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 135723,
    tank_volume_start: 25999.499999999916,
    tank_volume_stop: 25944.399999999918,
  },
  {
    timestamp_start: '2022-01-01T08:43:50',
    timestamp_stop: '2022-01-01T08:43:55',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 135725,
    tank_volume_start: 25944.399999999918,
    tank_volume_stop: 25892.999999999916,
  },
  {
    timestamp_start: '2022-01-01T08:44:10',
    timestamp_stop: '2022-01-01T08:44:16',
    quantity: 58.5,
    vehicle: 'DEM111',
    id: 135727,
    tank_volume_start: 25892.999999999916,
    tank_volume_stop: 25834.499999999916,
  },
  {
    timestamp_start: '2022-01-01T08:44:31',
    timestamp_stop: '2022-01-01T08:44:36',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 135729,
    tank_volume_start: 25834.499999999916,
    tank_volume_stop: 25783.299999999916,
  },
  {
    timestamp_start: '2022-01-01T08:44:51',
    timestamp_stop: '2022-01-01T08:44:57',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 135731,
    tank_volume_start: 25783.299999999916,
    tank_volume_stop: 25729.899999999914,
  },
  {
    timestamp_start: '2022-01-01T08:45:12',
    timestamp_stop: '2022-01-01T08:45:18',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 135733,
    tank_volume_start: 25729.899999999914,
    tank_volume_stop: 25668.899999999914,
  },
  {
    timestamp_start: '2022-01-01T08:45:33',
    timestamp_stop: '2022-01-01T08:45:38',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 135735,
    tank_volume_start: 25668.899999999914,
    tank_volume_stop: 25616.799999999916,
  },
  {
    timestamp_start: '2022-01-01T08:45:53',
    timestamp_stop: '2022-01-01T08:45:58',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 135737,
    tank_volume_start: 25616.799999999916,
    tank_volume_stop: 25560.899999999914,
  },
  {
    timestamp_start: '2022-01-01T08:46:13',
    timestamp_stop: '2022-01-01T08:46:19',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 135739,
    tank_volume_start: 25560.899999999914,
    tank_volume_stop: 25502.399999999914,
  },
  {
    timestamp_start: '2022-01-01T08:46:34',
    timestamp_stop: '2022-01-01T08:46:39',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 135741,
    tank_volume_start: 25502.399999999914,
    tank_volume_stop: 25451.199999999913,
  },
  {
    timestamp_start: '2022-01-01T08:46:54',
    timestamp_stop: '2022-01-01T08:47:00',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 135743,
    tank_volume_start: 25451.199999999913,
    tank_volume_stop: 25396.499999999913,
  },
  {
    timestamp_start: '2022-01-01T08:47:15',
    timestamp_stop: '2022-01-01T08:47:20',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 135745,
    tank_volume_start: 25396.499999999913,
    tank_volume_stop: 25342.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:47:35',
    timestamp_stop: '2022-01-01T08:47:41',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 135747,
    tank_volume_start: 25342.59999999991,
    tank_volume_stop: 25283.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:47:56',
    timestamp_stop: '2022-01-01T08:48:02',
    quantity: 60.5,
    vehicle: 'DEM222',
    id: 135749,
    tank_volume_start: 25283.59999999991,
    tank_volume_stop: 25223.09999999991,
  },
  {
    timestamp_start: '2022-01-01T08:48:17',
    timestamp_stop: '2022-01-01T08:48:22',
    quantity: 51.3,
    vehicle: 'DEM555',
    id: 135751,
    tank_volume_start: 25223.09999999991,
    tank_volume_stop: 25171.799999999912,
  },
  {
    timestamp_start: '2022-01-01T08:48:37',
    timestamp_stop: '2022-01-01T08:48:43',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 135753,
    tank_volume_start: 25171.799999999912,
    tank_volume_stop: 25116.299999999912,
  },
  {
    timestamp_start: '2022-01-01T08:48:58',
    timestamp_stop: '2022-01-01T08:49:04',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 135755,
    tank_volume_start: 25116.299999999912,
    tank_volume_stop: 25056.799999999912,
  },
  {
    timestamp_start: '2022-01-01T08:49:19',
    timestamp_stop: '2022-01-01T08:49:25',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 135757,
    tank_volume_start: 25056.799999999912,
    tank_volume_stop: 24997.999999999913,
  },
  {
    timestamp_start: '2022-01-01T08:49:40',
    timestamp_stop: '2022-01-01T08:49:46',
    quantity: 60.4,
    vehicle: 'DEM666',
    id: 135759,
    tank_volume_start: 24997.999999999913,
    tank_volume_stop: 24937.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:50:01',
    timestamp_stop: '2022-01-01T08:50:06',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 135761,
    tank_volume_start: 24937.59999999991,
    tank_volume_stop: 24882.799999999912,
  },
  {
    timestamp_start: '2022-01-01T08:50:21',
    timestamp_stop: '2022-01-01T08:50:27',
    quantity: 61.1,
    vehicle: 'DEM222',
    id: 135763,
    tank_volume_start: 24882.799999999912,
    tank_volume_stop: 24821.699999999913,
  },
  {
    timestamp_start: '2022-01-01T08:50:42',
    timestamp_stop: '2022-01-01T08:50:48',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 135765,
    tank_volume_start: 24821.699999999913,
    tank_volume_stop: 24762.499999999913,
  },
  {
    timestamp_start: '2022-01-01T08:51:03',
    timestamp_stop: '2022-01-01T08:51:08',
    quantity: 50.5,
    vehicle: 'DEM222',
    id: 135767,
    tank_volume_start: 24762.499999999913,
    tank_volume_stop: 24711.999999999913,
  },
  {
    timestamp_start: '2022-01-01T08:51:23',
    timestamp_stop: '2022-01-01T08:51:29',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 135769,
    tank_volume_start: 24711.999999999913,
    tank_volume_stop: 24659.999999999913,
  },
  {
    timestamp_start: '2022-01-01T08:51:44',
    timestamp_stop: '2022-01-01T08:51:49',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 135771,
    tank_volume_start: 24659.999999999913,
    tank_volume_stop: 24600.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:52:04',
    timestamp_stop: '2022-01-01T08:52:10',
    quantity: 59.5,
    vehicle: 'DEM888',
    id: 135773,
    tank_volume_start: 24600.59999999991,
    tank_volume_stop: 24541.09999999991,
  },
  {
    timestamp_start: '2022-01-01T08:52:25',
    timestamp_stop: '2022-01-01T08:52:31',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 135775,
    tank_volume_start: 24541.09999999991,
    tank_volume_stop: 24484.19999999991,
  },
  {
    timestamp_start: '2022-01-01T08:52:46',
    timestamp_stop: '2022-01-01T08:52:52',
    quantity: 59.3,
    vehicle: 'DEM111',
    id: 135777,
    tank_volume_start: 24484.19999999991,
    tank_volume_stop: 24424.89999999991,
  },
  {
    timestamp_start: '2022-01-01T08:53:07',
    timestamp_stop: '2022-01-01T08:53:12',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 135779,
    tank_volume_start: 24424.89999999991,
    tank_volume_stop: 24370.89999999991,
  },
  {
    timestamp_start: '2022-01-01T08:53:27',
    timestamp_stop: '2022-01-01T08:53:33',
    quantity: 56.3,
    vehicle: 'DEM222',
    id: 135781,
    tank_volume_start: 24370.89999999991,
    tank_volume_stop: 24314.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:53:48',
    timestamp_stop: '2022-01-01T08:53:54',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 135783,
    tank_volume_start: 24314.59999999991,
    tank_volume_stop: 24254.19999999991,
  },
  {
    timestamp_start: '2022-01-01T08:54:09',
    timestamp_stop: '2022-01-01T08:54:14',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 135785,
    tank_volume_start: 24254.19999999991,
    tank_volume_stop: 24200.09999999991,
  },
  {
    timestamp_start: '2022-01-01T08:54:29',
    timestamp_stop: '2022-01-01T08:54:35',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 135787,
    tank_volume_start: 24200.09999999991,
    tank_volume_stop: 24149.59999999991,
  },
  {
    timestamp_start: '2022-01-01T08:54:50',
    timestamp_stop: '2022-01-01T08:54:55',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 135789,
    tank_volume_start: 24149.59999999991,
    tank_volume_stop: 24095.09999999991,
  },
  {
    timestamp_start: '2022-01-01T08:55:10',
    timestamp_stop: '2022-01-01T08:55:16',
    quantity: 63.9,
    vehicle: 'DEM444',
    id: 135791,
    tank_volume_start: 24095.09999999991,
    tank_volume_stop: 24031.19999999991,
  },
  {
    timestamp_start: '2022-01-01T08:55:31',
    timestamp_stop: '2022-01-01T08:55:36',
    quantity: 49.9,
    vehicle: 'DEM111',
    id: 135793,
    tank_volume_start: 24031.19999999991,
    tank_volume_stop: 23981.29999999991,
  },
  {
    timestamp_start: '2022-01-01T08:55:51',
    timestamp_stop: '2022-01-01T08:55:56',
    quantity: 47.0,
    vehicle: 'DEM333',
    id: 135795,
    tank_volume_start: 23981.29999999991,
    tank_volume_stop: 23934.29999999991,
  },
  {
    timestamp_start: '2022-01-01T08:56:11',
    timestamp_stop: '2022-01-01T08:56:17',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 135797,
    tank_volume_start: 23934.29999999991,
    tank_volume_stop: 23875.79999999991,
  },
  {
    timestamp_start: '2022-01-01T08:56:32',
    timestamp_stop: '2022-01-01T08:56:37',
    quantity: 55.3,
    vehicle: 'DEM666',
    id: 135799,
    tank_volume_start: 23875.79999999991,
    tank_volume_stop: 23820.49999999991,
  },
  {
    timestamp_start: '2022-01-01T08:56:52',
    timestamp_stop: '2022-01-01T08:56:59',
    quantity: 61.4,
    vehicle: 'DEM000',
    id: 135801,
    tank_volume_start: 23820.49999999991,
    tank_volume_stop: 23759.099999999908,
  },
  {
    timestamp_start: '2022-01-01T08:57:14',
    timestamp_stop: '2022-01-01T08:57:20',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 135803,
    tank_volume_start: 23759.099999999908,
    tank_volume_stop: 23698.899999999907,
  },
  {
    timestamp_start: '2022-01-01T08:57:35',
    timestamp_stop: '2022-01-01T08:57:40',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 135805,
    tank_volume_start: 23698.899999999907,
    tank_volume_stop: 23641.79999999991,
  },
  {
    timestamp_start: '2022-01-01T08:57:55',
    timestamp_stop: '2022-01-01T08:58:01',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 135807,
    tank_volume_start: 23641.79999999991,
    tank_volume_stop: 23588.899999999907,
  },
  {
    timestamp_start: '2022-01-01T08:58:16',
    timestamp_stop: '2022-01-01T08:58:22',
    quantity: 63.9,
    vehicle: 'DEM000',
    id: 135809,
    tank_volume_start: 23588.899999999907,
    tank_volume_stop: 23524.999999999905,
  },
  {
    timestamp_start: '2022-01-01T08:58:37',
    timestamp_stop: '2022-01-01T08:58:42',
    quantity: 53.5,
    vehicle: 'DEM000',
    id: 135811,
    tank_volume_start: 23524.999999999905,
    tank_volume_stop: 23471.499999999905,
  },
  {
    timestamp_start: '2022-01-01T08:58:57',
    timestamp_stop: '2022-01-01T08:59:02',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 135813,
    tank_volume_start: 23471.499999999905,
    tank_volume_stop: 23420.999999999905,
  },
  {
    timestamp_start: '2022-01-01T08:59:17',
    timestamp_stop: '2022-01-01T08:59:24',
    quantity: 61.5,
    vehicle: 'DEM777',
    id: 135815,
    tank_volume_start: 23420.999999999905,
    tank_volume_stop: 23359.499999999905,
  },
  {
    timestamp_start: '2022-01-01T08:59:39',
    timestamp_stop: '2022-01-01T08:59:44',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 135817,
    tank_volume_start: 23359.499999999905,
    tank_volume_stop: 23304.299999999905,
  },
  {
    timestamp_start: '2022-01-01T08:59:59',
    timestamp_stop: '2022-01-01T09:00:05',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 135819,
    tank_volume_start: 23304.299999999905,
    tank_volume_stop: 23244.399999999903,
  },
  {
    timestamp_start: '2022-01-01T09:00:20',
    timestamp_stop: '2022-01-01T09:00:25',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 135821,
    tank_volume_start: 23244.399999999903,
    tank_volume_stop: 23191.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:00:40',
    timestamp_stop: '2022-01-01T09:00:47',
    quantity: 63.0,
    vehicle: 'DEM666',
    id: 135823,
    tank_volume_start: 23191.099999999904,
    tank_volume_stop: 23128.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:01:02',
    timestamp_stop: '2022-01-01T09:01:06',
    quantity: 46.9,
    vehicle: 'DEM222',
    id: 135825,
    tank_volume_start: 23128.099999999904,
    tank_volume_stop: 23081.199999999903,
  },
  {
    timestamp_start: '2022-01-01T09:01:21',
    timestamp_stop: '2022-01-01T09:01:27',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 135827,
    tank_volume_start: 23081.199999999903,
    tank_volume_stop: 23027.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:01:42',
    timestamp_stop: '2022-01-01T09:01:47',
    quantity: 49.9,
    vehicle: 'DEM777',
    id: 135829,
    tank_volume_start: 23027.2999999999,
    tank_volume_stop: 22977.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:02:02',
    timestamp_stop: '2022-01-01T09:02:08',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 135831,
    tank_volume_start: 22977.3999999999,
    tank_volume_stop: 22919.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:02:23',
    timestamp_stop: '2022-01-01T09:02:28',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 135833,
    tank_volume_start: 22919.3999999999,
    tank_volume_stop: 22865.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:02:43',
    timestamp_stop: '2022-01-01T09:02:49',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 135835,
    tank_volume_start: 22865.2999999999,
    tank_volume_stop: 22807.199999999903,
  },
  {
    timestamp_start: '2022-01-01T09:03:04',
    timestamp_stop: '2022-01-01T09:03:09',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 135837,
    tank_volume_start: 22807.199999999903,
    tank_volume_stop: 22757.199999999903,
  },
  {
    timestamp_start: '2022-01-01T09:03:24',
    timestamp_stop: '2022-01-01T09:03:29',
    quantity: 48.4,
    vehicle: 'DEM555',
    id: 135839,
    tank_volume_start: 22757.199999999903,
    tank_volume_stop: 22708.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:03:44',
    timestamp_stop: '2022-01-01T09:03:48',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 135841,
    tank_volume_start: 22708.7999999999,
    tank_volume_stop: 22661.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:04:03',
    timestamp_stop: '2022-01-01T09:04:09',
    quantity: 54.2,
    vehicle: 'DEM222',
    id: 135843,
    tank_volume_start: 22661.0999999999,
    tank_volume_stop: 22606.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:04:24',
    timestamp_stop: '2022-01-01T09:04:29',
    quantity: 51.3,
    vehicle: 'DEM888',
    id: 135845,
    tank_volume_start: 22606.8999999999,
    tank_volume_stop: 22555.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:04:44',
    timestamp_stop: '2022-01-01T09:04:49',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 135847,
    tank_volume_start: 22555.5999999999,
    tank_volume_stop: 22503.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:05:04',
    timestamp_stop: '2022-01-01T09:05:09',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 135849,
    tank_volume_start: 22503.1999999999,
    tank_volume_stop: 22452.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:05:24',
    timestamp_stop: '2022-01-01T09:05:30',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 135851,
    tank_volume_start: 22452.0999999999,
    tank_volume_stop: 22398.9999999999,
  },
  {
    timestamp_start: '2022-01-01T09:05:45',
    timestamp_stop: '2022-01-01T09:05:50',
    quantity: 49.9,
    vehicle: 'DEM222',
    id: 135853,
    tank_volume_start: 22398.9999999999,
    tank_volume_stop: 22349.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:06:05',
    timestamp_stop: '2022-01-01T09:06:10',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 135855,
    tank_volume_start: 22349.0999999999,
    tank_volume_stop: 22298.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:06:25',
    timestamp_stop: '2022-01-01T09:06:30',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 135857,
    tank_volume_start: 22298.5999999999,
    tank_volume_stop: 22246.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:06:45',
    timestamp_stop: '2022-01-01T09:06:50',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 135859,
    tank_volume_start: 22246.1999999999,
    tank_volume_stop: 22195.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:07:05',
    timestamp_stop: '2022-01-01T09:07:10',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 135861,
    tank_volume_start: 22195.5999999999,
    tank_volume_stop: 22143.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:07:25',
    timestamp_stop: '2022-01-01T09:07:31',
    quantity: 61.7,
    vehicle: 'DEM222',
    id: 135863,
    tank_volume_start: 22143.5999999999,
    tank_volume_stop: 22081.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:07:46',
    timestamp_stop: '2022-01-01T09:07:52',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 135865,
    tank_volume_start: 22081.8999999999,
    tank_volume_stop: 22023.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:08:07',
    timestamp_stop: '2022-01-01T09:08:12',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 135867,
    tank_volume_start: 22023.1999999999,
    tank_volume_stop: 21970.799999999897,
  },
  {
    timestamp_start: '2022-01-01T09:08:27',
    timestamp_stop: '2022-01-01T09:08:32',
    quantity: 46.8,
    vehicle: 'DEM888',
    id: 135869,
    tank_volume_start: 21970.799999999897,
    tank_volume_stop: 21923.999999999898,
  },
  {
    timestamp_start: '2022-01-01T09:08:47',
    timestamp_stop: '2022-01-01T09:08:52',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 135871,
    tank_volume_start: 21923.999999999898,
    tank_volume_stop: 21870.299999999897,
  },
  {
    timestamp_start: '2022-01-01T09:09:07',
    timestamp_stop: '2022-01-01T09:09:13',
    quantity: 58.8,
    vehicle: 'DEM000',
    id: 135873,
    tank_volume_start: 21870.299999999897,
    tank_volume_stop: 21811.499999999898,
  },
  {
    timestamp_start: '2022-01-01T09:09:28',
    timestamp_stop: '2022-01-01T09:09:34',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 135875,
    tank_volume_start: 21811.499999999898,
    tank_volume_stop: 21758.6999999999,
  },
  {
    timestamp_start: '2022-01-01T09:09:49',
    timestamp_stop: '2022-01-01T09:09:54',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 135877,
    tank_volume_start: 21758.6999999999,
    tank_volume_stop: 21701.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:10:09',
    timestamp_stop: '2022-01-01T09:10:15',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 135879,
    tank_volume_start: 21701.5999999999,
    tank_volume_stop: 21645.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:10:30',
    timestamp_stop: '2022-01-01T09:10:36',
    quantity: 61.2,
    vehicle: 'DEM555',
    id: 135881,
    tank_volume_start: 21645.5999999999,
    tank_volume_stop: 21584.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:10:51',
    timestamp_stop: '2022-01-01T09:10:57',
    quantity: 63.7,
    vehicle: 'DEM888',
    id: 135883,
    tank_volume_start: 21584.3999999999,
    tank_volume_stop: 21520.6999999999,
  },
  {
    timestamp_start: '2022-01-01T09:11:12',
    timestamp_stop: '2022-01-01T09:11:18',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 135885,
    tank_volume_start: 21520.6999999999,
    tank_volume_stop: 21465.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:11:33',
    timestamp_stop: '2022-01-01T09:11:39',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 135887,
    tank_volume_start: 21465.1999999999,
    tank_volume_stop: 21409.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:11:54',
    timestamp_stop: '2022-01-01T09:11:59',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 135889,
    tank_volume_start: 21409.1999999999,
    tank_volume_stop: 21351.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:12:14',
    timestamp_stop: '2022-01-01T09:12:19',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 135891,
    tank_volume_start: 21351.3999999999,
    tank_volume_stop: 21300.499999999898,
  },
  {
    timestamp_start: '2022-01-01T09:12:34',
    timestamp_stop: '2022-01-01T09:12:40',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 135893,
    tank_volume_start: 21300.499999999898,
    tank_volume_stop: 21247.6999999999,
  },
  {
    timestamp_start: '2022-01-01T09:12:55',
    timestamp_stop: '2022-01-01T09:13:00',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 135895,
    tank_volume_start: 21247.6999999999,
    tank_volume_stop: 21193.299999999897,
  },
  {
    timestamp_start: '2022-01-01T09:13:15',
    timestamp_stop: '2022-01-01T09:13:20',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 135897,
    tank_volume_start: 21193.299999999897,
    tank_volume_stop: 21140.499999999898,
  },
  {
    timestamp_start: '2022-01-01T09:13:35',
    timestamp_stop: '2022-01-01T09:13:42',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 135899,
    tank_volume_start: 21140.499999999898,
    tank_volume_stop: 21078.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:13:57',
    timestamp_stop: '2022-01-01T09:14:02',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 135901,
    tank_volume_start: 21078.8999999999,
    tank_volume_stop: 21022.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:14:17',
    timestamp_stop: '2022-01-01T09:14:23',
    quantity: 54.4,
    vehicle: 'DEM888',
    id: 135903,
    tank_volume_start: 21022.2999999999,
    tank_volume_stop: 20967.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:14:38',
    timestamp_stop: '2022-01-01T09:14:42',
    quantity: 45.9,
    vehicle: 'DEM666',
    id: 135905,
    tank_volume_start: 20967.8999999999,
    tank_volume_stop: 20921.999999999898,
  },
  {
    timestamp_start: '2022-01-01T09:14:57',
    timestamp_stop: '2022-01-01T09:15:03',
    quantity: 59.6,
    vehicle: 'DEM777',
    id: 135907,
    tank_volume_start: 20921.999999999898,
    tank_volume_stop: 20862.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:15:18',
    timestamp_stop: '2022-01-01T09:15:23',
    quantity: 50.6,
    vehicle: 'DEM555',
    id: 135909,
    tank_volume_start: 20862.3999999999,
    tank_volume_stop: 20811.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:15:38',
    timestamp_stop: '2022-01-01T09:15:43',
    quantity: 48.4,
    vehicle: 'DEM000',
    id: 135911,
    tank_volume_start: 20811.7999999999,
    tank_volume_stop: 20763.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:15:58',
    timestamp_stop: '2022-01-01T09:16:04',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 135913,
    tank_volume_start: 20763.3999999999,
    tank_volume_stop: 20706.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:16:19',
    timestamp_stop: '2022-01-01T09:16:24',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 135915,
    tank_volume_start: 20706.0999999999,
    tank_volume_stop: 20654.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:16:39',
    timestamp_stop: '2022-01-01T09:16:44',
    quantity: 47.3,
    vehicle: 'DEM000',
    id: 135917,
    tank_volume_start: 20654.8999999999,
    tank_volume_stop: 20607.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:16:59',
    timestamp_stop: '2022-01-01T09:17:04',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 135919,
    tank_volume_start: 20607.5999999999,
    tank_volume_stop: 20557.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:17:19',
    timestamp_stop: '2022-01-01T09:17:24',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 135921,
    tank_volume_start: 20557.2999999999,
    tank_volume_stop: 20501.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:17:39',
    timestamp_stop: '2022-01-01T09:17:45',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 135923,
    tank_volume_start: 20501.5999999999,
    tank_volume_stop: 20446.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:18:00',
    timestamp_stop: '2022-01-01T09:18:05',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 135925,
    tank_volume_start: 20446.4999999999,
    tank_volume_stop: 20394.9999999999,
  },
  {
    timestamp_start: '2022-01-01T09:18:20',
    timestamp_stop: '2022-01-01T09:18:25',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 135927,
    tank_volume_start: 20394.9999999999,
    tank_volume_stop: 20341.899999999903,
  },
  {
    timestamp_start: '2022-01-01T09:18:40',
    timestamp_stop: '2022-01-01T09:18:46',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 135929,
    tank_volume_start: 20341.899999999903,
    tank_volume_stop: 20287.899999999903,
  },
  {
    timestamp_start: '2022-01-01T09:19:01',
    timestamp_stop: '2022-01-01T09:19:05',
    quantity: 45.2,
    vehicle: 'DEM666',
    id: 135931,
    tank_volume_start: 20287.899999999903,
    tank_volume_stop: 20242.699999999903,
  },
  {
    timestamp_start: '2022-01-01T09:19:20',
    timestamp_stop: '2022-01-01T09:19:25',
    quantity: 46.6,
    vehicle: 'DEM555',
    id: 135933,
    tank_volume_start: 20242.699999999903,
    tank_volume_stop: 20196.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:19:40',
    timestamp_stop: '2022-01-01T09:19:45',
    quantity: 47.9,
    vehicle: 'DEM555',
    id: 135935,
    tank_volume_start: 20196.099999999904,
    tank_volume_stop: 20148.199999999903,
  },
  {
    timestamp_start: '2022-01-01T09:20:00',
    timestamp_stop: '2022-01-01T09:20:05',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 135937,
    tank_volume_start: 20148.199999999903,
    tank_volume_stop: 20094.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:20:20',
    timestamp_stop: '2022-01-01T09:20:26',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 135939,
    tank_volume_start: 20094.599999999904,
    tank_volume_stop: 20037.999999999905,
  },
  {
    timestamp_start: '2022-01-01T09:20:41',
    timestamp_stop: '2022-01-01T09:20:47',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 135941,
    tank_volume_start: 20037.999999999905,
    tank_volume_stop: 19979.899999999907,
  },
  {
    timestamp_start: '2022-01-01T09:21:02',
    timestamp_stop: '2022-01-01T09:21:07',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 135943,
    tank_volume_start: 39979.89999999991,
    tank_volume_stop: 39922.69999999991,
  },
  {
    timestamp_start: '2022-01-01T09:21:22',
    timestamp_stop: '2022-01-01T09:21:28',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 135945,
    tank_volume_start: 39922.69999999991,
    tank_volume_stop: 39866.69999999991,
  },
  {
    timestamp_start: '2022-01-01T09:21:43',
    timestamp_stop: '2022-01-01T09:21:48',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 135947,
    tank_volume_start: 39866.69999999991,
    tank_volume_stop: 39814.19999999991,
  },
  {
    timestamp_start: '2022-01-01T09:22:03',
    timestamp_stop: '2022-01-01T09:22:09',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 135949,
    tank_volume_start: 39814.19999999991,
    tank_volume_stop: 39755.79999999991,
  },
  {
    timestamp_start: '2022-01-01T09:22:24',
    timestamp_stop: '2022-01-01T09:22:29',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 135951,
    tank_volume_start: 39755.79999999991,
    tank_volume_stop: 39703.69999999991,
  },
  {
    timestamp_start: '2022-01-01T09:22:44',
    timestamp_stop: '2022-01-01T09:22:50',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 135953,
    tank_volume_start: 39703.69999999991,
    tank_volume_stop: 39645.29999999991,
  },
  {
    timestamp_start: '2022-01-01T09:23:05',
    timestamp_stop: '2022-01-01T09:23:10',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 135955,
    tank_volume_start: 39645.29999999991,
    tank_volume_stop: 39593.69999999991,
  },
  {
    timestamp_start: '2022-01-01T09:23:25',
    timestamp_stop: '2022-01-01T09:23:31',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 135957,
    tank_volume_start: 39593.69999999991,
    tank_volume_stop: 39539.89999999991,
  },
  {
    timestamp_start: '2022-01-01T09:23:46',
    timestamp_stop: '2022-01-01T09:23:51',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 135959,
    tank_volume_start: 39539.89999999991,
    tank_volume_stop: 39484.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:24:06',
    timestamp_stop: '2022-01-01T09:24:11',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 135961,
    tank_volume_start: 39484.599999999904,
    tank_volume_stop: 39432.999999999905,
  },
  {
    timestamp_start: '2022-01-01T09:24:26',
    timestamp_stop: '2022-01-01T09:24:31',
    quantity: 48.7,
    vehicle: 'DEM111',
    id: 135963,
    tank_volume_start: 39432.999999999905,
    tank_volume_stop: 39384.29999999991,
  },
  {
    timestamp_start: '2022-01-01T09:24:46',
    timestamp_stop: '2022-01-01T09:24:51',
    quantity: 45.8,
    vehicle: 'DEM888',
    id: 135965,
    tank_volume_start: 39384.29999999991,
    tank_volume_stop: 39338.499999999905,
  },
  {
    timestamp_start: '2022-01-01T09:25:06',
    timestamp_stop: '2022-01-01T09:25:12',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 135967,
    tank_volume_start: 39338.499999999905,
    tank_volume_stop: 39278.999999999905,
  },
  {
    timestamp_start: '2022-01-01T09:25:27',
    timestamp_stop: '2022-01-01T09:25:32',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 135969,
    tank_volume_start: 39278.999999999905,
    tank_volume_stop: 39221.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:25:47',
    timestamp_stop: '2022-01-01T09:25:53',
    quantity: 58.4,
    vehicle: 'DEM111',
    id: 135971,
    tank_volume_start: 39221.1999999999,
    tank_volume_stop: 39162.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:26:08',
    timestamp_stop: '2022-01-01T09:26:14',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 135973,
    tank_volume_start: 39162.7999999999,
    tank_volume_stop: 39108.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:26:29',
    timestamp_stop: '2022-01-01T09:26:34',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 135975,
    tank_volume_start: 39108.4999999999,
    tank_volume_stop: 39053.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:26:49',
    timestamp_stop: '2022-01-01T09:26:55',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 135977,
    tank_volume_start: 39053.7999999999,
    tank_volume_stop: 38996.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:27:10',
    timestamp_stop: '2022-01-01T09:27:15',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 135979,
    tank_volume_start: 38996.599999999904,
    tank_volume_stop: 38946.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:27:30',
    timestamp_stop: '2022-01-01T09:27:35',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 135981,
    tank_volume_start: 38946.1999999999,
    tank_volume_stop: 38893.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:27:50',
    timestamp_stop: '2022-01-01T09:27:56',
    quantity: 62.6,
    vehicle: 'DEM444',
    id: 135983,
    tank_volume_start: 38893.7999999999,
    tank_volume_stop: 38831.1999999999,
  },
  {
    timestamp_start: '2022-01-01T09:28:11',
    timestamp_stop: '2022-01-01T09:28:18',
    quantity: 63.3,
    vehicle: 'DEM444',
    id: 135985,
    tank_volume_start: 38831.1999999999,
    tank_volume_stop: 38767.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:28:33',
    timestamp_stop: '2022-01-01T09:28:38',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 135987,
    tank_volume_start: 38767.8999999999,
    tank_volume_stop: 38713.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:28:53',
    timestamp_stop: '2022-01-01T09:28:59',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 135989,
    tank_volume_start: 38713.5999999999,
    tank_volume_stop: 38659.199999999895,
  },
  {
    timestamp_start: '2022-01-01T09:29:14',
    timestamp_stop: '2022-01-01T09:29:19',
    quantity: 52.1,
    vehicle: 'DEM666',
    id: 135991,
    tank_volume_start: 38659.199999999895,
    tank_volume_stop: 38607.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:29:34',
    timestamp_stop: '2022-01-01T09:29:40',
    quantity: 57.6,
    vehicle: 'DEM333',
    id: 135993,
    tank_volume_start: 38607.0999999999,
    tank_volume_stop: 38549.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:29:55',
    timestamp_stop: '2022-01-01T09:30:00',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 135995,
    tank_volume_start: 38549.4999999999,
    tank_volume_stop: 38492.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:30:15',
    timestamp_stop: '2022-01-01T09:30:20',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 135997,
    tank_volume_start: 38492.5999999999,
    tank_volume_stop: 38440.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:30:35',
    timestamp_stop: '2022-01-01T09:30:41',
    quantity: 59.2,
    vehicle: 'DEM444',
    id: 135999,
    tank_volume_start: 38440.4999999999,
    tank_volume_stop: 38381.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:30:56',
    timestamp_stop: '2022-01-01T09:31:02',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 136001,
    tank_volume_start: 38381.2999999999,
    tank_volume_stop: 38329.6999999999,
  },
  {
    timestamp_start: '2022-01-01T09:31:17',
    timestamp_stop: '2022-01-01T09:31:22',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 136003,
    tank_volume_start: 38329.6999999999,
    tank_volume_stop: 38279.6999999999,
  },
  {
    timestamp_start: '2022-01-01T09:31:37',
    timestamp_stop: '2022-01-01T09:31:42',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 136005,
    tank_volume_start: 38279.6999999999,
    tank_volume_stop: 38224.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:31:57',
    timestamp_stop: '2022-01-01T09:32:02',
    quantity: 47.2,
    vehicle: 'DEM222',
    id: 136007,
    tank_volume_start: 38224.599999999904,
    tank_volume_stop: 38177.39999999991,
  },
  {
    timestamp_start: '2022-01-01T09:32:17',
    timestamp_stop: '2022-01-01T09:32:23',
    quantity: 61.1,
    vehicle: 'DEM000',
    id: 136009,
    tank_volume_start: 38177.39999999991,
    tank_volume_stop: 38116.29999999991,
  },
  {
    timestamp_start: '2022-01-01T09:32:38',
    timestamp_stop: '2022-01-01T09:32:44',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 136011,
    tank_volume_start: 38116.29999999991,
    tank_volume_stop: 38053.39999999991,
  },
  {
    timestamp_start: '2022-01-01T09:32:59',
    timestamp_stop: '2022-01-01T09:33:05',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 136013,
    tank_volume_start: 38053.39999999991,
    tank_volume_stop: 37997.999999999905,
  },
  {
    timestamp_start: '2022-01-01T09:33:20',
    timestamp_stop: '2022-01-01T09:33:25',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 136015,
    tank_volume_start: 37997.999999999905,
    tank_volume_stop: 37944.39999999991,
  },
  {
    timestamp_start: '2022-01-01T09:33:40',
    timestamp_stop: '2022-01-01T09:33:46',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 136017,
    tank_volume_start: 37944.39999999991,
    tank_volume_stop: 37889.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:34:01',
    timestamp_stop: '2022-01-01T09:34:06',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 136019,
    tank_volume_start: 37889.599999999904,
    tank_volume_stop: 37832.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:34:21',
    timestamp_stop: '2022-01-01T09:34:27',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 136021,
    tank_volume_start: 37832.099999999904,
    tank_volume_stop: 37777.599999999904,
  },
  {
    timestamp_start: '2022-01-01T09:34:42',
    timestamp_stop: '2022-01-01T09:34:47',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 136023,
    tank_volume_start: 37777.599999999904,
    tank_volume_stop: 37722.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:35:02',
    timestamp_stop: '2022-01-01T09:35:08',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 136025,
    tank_volume_start: 37722.099999999904,
    tank_volume_stop: 37666.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:35:23',
    timestamp_stop: '2022-01-01T09:35:28',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 136027,
    tank_volume_start: 37666.099999999904,
    tank_volume_stop: 37615.499999999905,
  },
  {
    timestamp_start: '2022-01-01T09:35:43',
    timestamp_stop: '2022-01-01T09:35:48',
    quantity: 46.4,
    vehicle: 'DEM444',
    id: 136029,
    tank_volume_start: 37615.499999999905,
    tank_volume_stop: 37569.099999999904,
  },
  {
    timestamp_start: '2022-01-01T09:36:03',
    timestamp_stop: '2022-01-01T09:36:08',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 136031,
    tank_volume_start: 37569.099999999904,
    tank_volume_stop: 37518.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:36:23',
    timestamp_stop: '2022-01-01T09:36:28',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 136033,
    tank_volume_start: 37518.7999999999,
    tank_volume_stop: 37463.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:36:43',
    timestamp_stop: '2022-01-01T09:36:48',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 136035,
    tank_volume_start: 37463.8999999999,
    tank_volume_stop: 37411.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:37:03',
    timestamp_stop: '2022-01-01T09:37:09',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 136037,
    tank_volume_start: 37411.4999999999,
    tank_volume_stop: 37358.4999999999,
  },
  {
    timestamp_start: '2022-01-01T09:37:24',
    timestamp_stop: '2022-01-01T09:37:29',
    quantity: 51.7,
    vehicle: 'DEM666',
    id: 136039,
    tank_volume_start: 37358.4999999999,
    tank_volume_stop: 37306.7999999999,
  },
  {
    timestamp_start: '2022-01-01T09:37:44',
    timestamp_stop: '2022-01-01T09:37:50',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 136041,
    tank_volume_start: 37306.7999999999,
    tank_volume_stop: 37247.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:38:05',
    timestamp_stop: '2022-01-01T09:38:10',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 136043,
    tank_volume_start: 37247.8999999999,
    tank_volume_stop: 37191.8999999999,
  },
  {
    timestamp_start: '2022-01-01T09:38:25',
    timestamp_stop: '2022-01-01T09:38:31',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 136045,
    tank_volume_start: 37191.8999999999,
    tank_volume_stop: 37138.9999999999,
  },
  {
    timestamp_start: '2022-01-01T09:38:46',
    timestamp_stop: '2022-01-01T09:38:51',
    quantity: 52.7,
    vehicle: 'DEM555',
    id: 136047,
    tank_volume_start: 37138.9999999999,
    tank_volume_stop: 37086.2999999999,
  },
  {
    timestamp_start: '2022-01-01T09:39:06',
    timestamp_stop: '2022-01-01T09:39:11',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 136049,
    tank_volume_start: 37086.2999999999,
    tank_volume_stop: 37032.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:39:26',
    timestamp_stop: '2022-01-01T09:39:33',
    quantity: 64.0,
    vehicle: 'DEM222',
    id: 136051,
    tank_volume_start: 37032.3999999999,
    tank_volume_stop: 36968.3999999999,
  },
  {
    timestamp_start: '2022-01-01T09:39:48',
    timestamp_stop: '2022-01-01T09:39:53',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 136053,
    tank_volume_start: 36968.3999999999,
    tank_volume_stop: 36917.0999999999,
  },
  {
    timestamp_start: '2022-01-01T09:40:08',
    timestamp_stop: '2022-01-01T09:40:13',
    quantity: 48.3,
    vehicle: 'DEM000',
    id: 136055,
    tank_volume_start: 36917.0999999999,
    tank_volume_stop: 36868.799999999894,
  },
  {
    timestamp_start: '2022-01-01T09:40:28',
    timestamp_stop: '2022-01-01T09:40:33',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 136057,
    tank_volume_start: 36868.799999999894,
    tank_volume_stop: 36815.99999999989,
  },
  {
    timestamp_start: '2022-01-01T09:40:48',
    timestamp_stop: '2022-01-01T09:40:53',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 136059,
    tank_volume_start: 36815.99999999989,
    tank_volume_stop: 36761.89999999989,
  },
  {
    timestamp_start: '2022-01-01T09:41:08',
    timestamp_stop: '2022-01-01T09:41:14',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 136061,
    tank_volume_start: 36761.89999999989,
    tank_volume_stop: 36707.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:41:29',
    timestamp_stop: '2022-01-01T09:41:35',
    quantity: 60.0,
    vehicle: 'DEM000',
    id: 136063,
    tank_volume_start: 36707.59999999989,
    tank_volume_stop: 36647.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:41:50',
    timestamp_stop: '2022-01-01T09:41:55',
    quantity: 48.1,
    vehicle: 'DEM222',
    id: 136065,
    tank_volume_start: 36647.59999999989,
    tank_volume_stop: 36599.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:42:10',
    timestamp_stop: '2022-01-01T09:42:15',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 136067,
    tank_volume_start: 36599.49999999989,
    tank_volume_stop: 36546.299999999894,
  },
  {
    timestamp_start: '2022-01-01T09:42:30',
    timestamp_stop: '2022-01-01T09:42:35',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 136069,
    tank_volume_start: 36546.299999999894,
    tank_volume_stop: 36494.199999999895,
  },
  {
    timestamp_start: '2022-01-01T09:42:50',
    timestamp_stop: '2022-01-01T09:42:56',
    quantity: 62.9,
    vehicle: 'DEM888',
    id: 136071,
    tank_volume_start: 36494.199999999895,
    tank_volume_stop: 36431.299999999894,
  },
  {
    timestamp_start: '2022-01-01T09:43:11',
    timestamp_stop: '2022-01-01T09:43:17',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 136073,
    tank_volume_start: 36431.299999999894,
    tank_volume_stop: 36373.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:43:32',
    timestamp_stop: '2022-01-01T09:43:38',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 136075,
    tank_volume_start: 36373.49999999989,
    tank_volume_stop: 36314.99999999989,
  },
  {
    timestamp_start: '2022-01-01T09:43:53',
    timestamp_stop: '2022-01-01T09:43:58',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 136077,
    tank_volume_start: 36314.99999999989,
    tank_volume_stop: 36262.09999999989,
  },
  {
    timestamp_start: '2022-01-01T09:44:13',
    timestamp_stop: '2022-01-01T09:44:19',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 136079,
    tank_volume_start: 36262.09999999989,
    tank_volume_stop: 36205.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:44:34',
    timestamp_stop: '2022-01-01T09:44:40',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 136081,
    tank_volume_start: 36205.49999999989,
    tank_volume_stop: 36149.299999999894,
  },
  {
    timestamp_start: '2022-01-01T09:44:55',
    timestamp_stop: '2022-01-01T09:45:00',
    quantity: 58.8,
    vehicle: 'DEM777',
    id: 136083,
    tank_volume_start: 36149.299999999894,
    tank_volume_stop: 36090.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:45:15',
    timestamp_stop: '2022-01-01T09:45:22',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 136085,
    tank_volume_start: 36090.49999999989,
    tank_volume_stop: 36028.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:45:37',
    timestamp_stop: '2022-01-01T09:45:42',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 136087,
    tank_volume_start: 36028.49999999989,
    tank_volume_stop: 35973.39999999989,
  },
  {
    timestamp_start: '2022-01-01T09:45:57',
    timestamp_stop: '2022-01-01T09:46:03',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 136089,
    tank_volume_start: 35973.39999999989,
    tank_volume_stop: 35915.699999999895,
  },
  {
    timestamp_start: '2022-01-01T09:46:18',
    timestamp_stop: '2022-01-01T09:46:23',
    quantity: 51.1,
    vehicle: 'DEM222',
    id: 136091,
    tank_volume_start: 35915.699999999895,
    tank_volume_stop: 35864.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:46:38',
    timestamp_stop: '2022-01-01T09:46:44',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 136093,
    tank_volume_start: 35864.5999999999,
    tank_volume_stop: 35809.5999999999,
  },
  {
    timestamp_start: '2022-01-01T09:46:59',
    timestamp_stop: '2022-01-01T09:47:04',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 136095,
    tank_volume_start: 35809.5999999999,
    tank_volume_stop: 35754.299999999894,
  },
  {
    timestamp_start: '2022-01-01T09:47:19',
    timestamp_stop: '2022-01-01T09:47:25',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 136097,
    tank_volume_start: 35754.299999999894,
    tank_volume_stop: 35699.99999999989,
  },
  {
    timestamp_start: '2022-01-01T09:47:40',
    timestamp_stop: '2022-01-01T09:47:44',
    quantity: 46.3,
    vehicle: 'DEM444',
    id: 136099,
    tank_volume_start: 35699.99999999989,
    tank_volume_stop: 35653.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:47:59',
    timestamp_stop: '2022-01-01T09:48:05',
    quantity: 60.1,
    vehicle: 'DEM555',
    id: 136101,
    tank_volume_start: 35653.69999999989,
    tank_volume_stop: 35593.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:48:20',
    timestamp_stop: '2022-01-01T09:48:25',
    quantity: 45.6,
    vehicle: 'DEM777',
    id: 136103,
    tank_volume_start: 35593.59999999989,
    tank_volume_stop: 35547.99999999989,
  },
  {
    timestamp_start: '2022-01-01T09:48:40',
    timestamp_stop: '2022-01-01T09:48:45',
    quantity: 54.4,
    vehicle: 'DEM777',
    id: 136105,
    tank_volume_start: 35547.99999999989,
    tank_volume_stop: 35493.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:49:00',
    timestamp_stop: '2022-01-01T09:49:05',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 136107,
    tank_volume_start: 35493.59999999989,
    tank_volume_stop: 35441.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:49:20',
    timestamp_stop: '2022-01-01T09:49:26',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 136109,
    tank_volume_start: 35441.59999999989,
    tank_volume_stop: 35383.39999999989,
  },
  {
    timestamp_start: '2022-01-01T09:49:41',
    timestamp_stop: '2022-01-01T09:49:47',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 136111,
    tank_volume_start: 35383.39999999989,
    tank_volume_stop: 35324.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:50:02',
    timestamp_stop: '2022-01-01T09:50:07',
    quantity: 49.1,
    vehicle: 'DEM222',
    id: 136113,
    tank_volume_start: 35324.59999999989,
    tank_volume_stop: 35275.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:50:22',
    timestamp_stop: '2022-01-01T09:50:27',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 136115,
    tank_volume_start: 35275.49999999989,
    tank_volume_stop: 35227.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:50:42',
    timestamp_stop: '2022-01-01T09:50:47',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 136117,
    tank_volume_start: 35227.69999999989,
    tank_volume_stop: 35170.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:51:02',
    timestamp_stop: '2022-01-01T09:51:08',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 136119,
    tank_volume_start: 35170.69999999989,
    tank_volume_stop: 35113.99999999989,
  },
  {
    timestamp_start: '2022-01-01T09:51:23',
    timestamp_stop: '2022-01-01T09:51:29',
    quantity: 63.1,
    vehicle: 'DEM444',
    id: 136121,
    tank_volume_start: 35113.99999999989,
    tank_volume_stop: 35050.89999999989,
  },
  {
    timestamp_start: '2022-01-01T09:51:44',
    timestamp_stop: '2022-01-01T09:51:50',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 136123,
    tank_volume_start: 35050.89999999989,
    tank_volume_stop: 34991.799999999894,
  },
  {
    timestamp_start: '2022-01-01T09:52:05',
    timestamp_stop: '2022-01-01T09:52:10',
    quantity: 49.3,
    vehicle: 'DEM111',
    id: 136125,
    tank_volume_start: 34991.799999999894,
    tank_volume_stop: 34942.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:52:25',
    timestamp_stop: '2022-01-01T09:52:31',
    quantity: 59.6,
    vehicle: 'DEM777',
    id: 136127,
    tank_volume_start: 34942.49999999989,
    tank_volume_stop: 34882.89999999989,
  },
  {
    timestamp_start: '2022-01-01T09:52:46',
    timestamp_stop: '2022-01-01T09:52:51',
    quantity: 49.4,
    vehicle: 'DEM777',
    id: 136129,
    tank_volume_start: 34882.89999999989,
    tank_volume_stop: 34833.49999999989,
  },
  {
    timestamp_start: '2022-01-01T09:53:06',
    timestamp_stop: '2022-01-01T09:53:11',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 136131,
    tank_volume_start: 34833.49999999989,
    tank_volume_stop: 34782.39999999989,
  },
  {
    timestamp_start: '2022-01-01T09:53:26',
    timestamp_stop: '2022-01-01T09:53:32',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 136133,
    tank_volume_start: 34782.39999999989,
    tank_volume_stop: 34725.59999999989,
  },
  {
    timestamp_start: '2022-01-01T09:53:47',
    timestamp_stop: '2022-01-01T09:53:52',
    quantity: 52.9,
    vehicle: 'DEM333',
    id: 136135,
    tank_volume_start: 34725.59999999989,
    tank_volume_stop: 34672.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:54:07',
    timestamp_stop: '2022-01-01T09:54:12',
    quantity: 48.9,
    vehicle: 'DEM777',
    id: 136137,
    tank_volume_start: 34672.69999999989,
    tank_volume_stop: 34623.79999999989,
  },
  {
    timestamp_start: '2022-01-01T09:54:27',
    timestamp_stop: '2022-01-01T09:54:33',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 136139,
    tank_volume_start: 34623.79999999989,
    tank_volume_stop: 34564.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:54:48',
    timestamp_stop: '2022-01-01T09:54:53',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 136141,
    tank_volume_start: 34564.69999999989,
    tank_volume_stop: 34510.69999999989,
  },
  {
    timestamp_start: '2022-01-01T09:55:08',
    timestamp_stop: '2022-01-01T09:55:14',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 136143,
    tank_volume_start: 34510.69999999989,
    tank_volume_stop: 34451.79999999989,
  },
  {
    timestamp_start: '2022-01-01T09:55:29',
    timestamp_stop: '2022-01-01T09:55:35',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 136145,
    tank_volume_start: 34451.79999999989,
    tank_volume_stop: 34397.99999999988,
  },
  {
    timestamp_start: '2022-01-01T09:55:50',
    timestamp_stop: '2022-01-01T09:55:55',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 136147,
    tank_volume_start: 34397.99999999988,
    tank_volume_stop: 34342.09999999988,
  },
  {
    timestamp_start: '2022-01-01T09:56:10',
    timestamp_stop: '2022-01-01T09:56:15',
    quantity: 46.3,
    vehicle: 'DEM000',
    id: 136149,
    tank_volume_start: 34342.09999999988,
    tank_volume_stop: 34295.79999999988,
  },
  {
    timestamp_start: '2022-01-01T09:56:30',
    timestamp_stop: '2022-01-01T09:56:35',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 136151,
    tank_volume_start: 34295.79999999988,
    tank_volume_stop: 34240.59999999988,
  },
  {
    timestamp_start: '2022-01-01T09:56:50',
    timestamp_stop: '2022-01-01T09:56:55',
    quantity: 47.8,
    vehicle: 'DEM111',
    id: 136153,
    tank_volume_start: 34240.59999999988,
    tank_volume_stop: 34192.79999999988,
  },
  {
    timestamp_start: '2022-01-01T09:57:10',
    timestamp_stop: '2022-01-01T09:57:16',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 136155,
    tank_volume_start: 34192.79999999988,
    tank_volume_stop: 34137.29999999988,
  },
  {
    timestamp_start: '2022-01-01T09:57:31',
    timestamp_stop: '2022-01-01T09:57:36',
    quantity: 51.6,
    vehicle: 'DEM555',
    id: 136157,
    tank_volume_start: 34137.29999999988,
    tank_volume_stop: 34085.69999999988,
  },
  {
    timestamp_start: '2022-01-01T09:57:51',
    timestamp_stop: '2022-01-01T09:57:56',
    quantity: 53.8,
    vehicle: 'DEM444',
    id: 136159,
    tank_volume_start: 34085.69999999988,
    tank_volume_stop: 34031.89999999988,
  },
  {
    timestamp_start: '2022-01-01T09:58:11',
    timestamp_stop: '2022-01-01T09:58:16',
    quantity: 49.7,
    vehicle: 'DEM888',
    id: 136161,
    tank_volume_start: 34031.89999999988,
    tank_volume_stop: 33982.19999999988,
  },
  {
    timestamp_start: '2022-01-01T09:58:31',
    timestamp_stop: '2022-01-01T09:58:37',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 136163,
    tank_volume_start: 33982.19999999988,
    tank_volume_stop: 33924.59999999988,
  },
  {
    timestamp_start: '2022-01-01T09:58:52',
    timestamp_stop: '2022-01-01T09:58:57',
    quantity: 47.4,
    vehicle: 'DEM555',
    id: 136165,
    tank_volume_start: 33924.59999999988,
    tank_volume_stop: 33877.19999999988,
  },
  {
    timestamp_start: '2022-01-01T09:59:12',
    timestamp_stop: '2022-01-01T09:59:17',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 136167,
    tank_volume_start: 33877.19999999988,
    tank_volume_stop: 33822.49999999988,
  },
  {
    timestamp_start: '2022-01-01T09:59:32',
    timestamp_stop: '2022-01-01T09:59:37',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 136169,
    tank_volume_start: 33822.49999999988,
    tank_volume_stop: 33771.899999999885,
  },
  {
    timestamp_start: '2022-01-01T09:59:52',
    timestamp_stop: '2022-01-01T09:59:58',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 136171,
    tank_volume_start: 33771.899999999885,
    tank_volume_stop: 33710.29999999989,
  },
  {
    timestamp_start: '2022-01-01T10:00:13',
    timestamp_stop: '2022-01-01T10:00:19',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 136173,
    tank_volume_start: 33710.29999999989,
    tank_volume_stop: 33652.69999999989,
  },
  {
    timestamp_start: '2022-01-01T10:00:34',
    timestamp_stop: '2022-01-01T10:00:39',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 136175,
    tank_volume_start: 33652.69999999989,
    tank_volume_stop: 33601.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:00:54',
    timestamp_stop: '2022-01-01T10:00:59',
    quantity: 51.1,
    vehicle: 'DEM222',
    id: 136177,
    tank_volume_start: 33601.99999999989,
    tank_volume_stop: 33550.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:01:14',
    timestamp_stop: '2022-01-01T10:01:20',
    quantity: 59.2,
    vehicle: 'DEM666',
    id: 136179,
    tank_volume_start: 33550.89999999989,
    tank_volume_stop: 33491.699999999895,
  },
  {
    timestamp_start: '2022-01-01T10:01:35',
    timestamp_stop: '2022-01-01T10:01:41',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 136181,
    tank_volume_start: 33491.699999999895,
    tank_volume_stop: 33437.199999999895,
  },
  {
    timestamp_start: '2022-01-01T10:01:56',
    timestamp_stop: '2022-01-01T10:02:01',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 136183,
    tank_volume_start: 33437.199999999895,
    tank_volume_stop: 33381.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:02:16',
    timestamp_stop: '2022-01-01T10:02:22',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 136185,
    tank_volume_start: 33381.89999999989,
    tank_volume_stop: 33328.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:02:37',
    timestamp_stop: '2022-01-01T10:02:42',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 136187,
    tank_volume_start: 33328.99999999989,
    tank_volume_stop: 33276.799999999894,
  },
  {
    timestamp_start: '2022-01-01T10:02:57',
    timestamp_stop: '2022-01-01T10:03:02',
    quantity: 46.8,
    vehicle: 'DEM444',
    id: 136189,
    tank_volume_start: 33276.799999999894,
    tank_volume_stop: 33229.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:03:17',
    timestamp_stop: '2022-01-01T10:03:22',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 136191,
    tank_volume_start: 33229.99999999989,
    tank_volume_stop: 33177.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:03:37',
    timestamp_stop: '2022-01-01T10:03:43',
    quantity: 59.7,
    vehicle: 'DEM222',
    id: 136193,
    tank_volume_start: 33177.59999999989,
    tank_volume_stop: 33117.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:03:58',
    timestamp_stop: '2022-01-01T10:04:04',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 136195,
    tank_volume_start: 33117.89999999989,
    tank_volume_stop: 33058.299999999894,
  },
  {
    timestamp_start: '2022-01-01T10:04:19',
    timestamp_stop: '2022-01-01T10:04:24',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 136197,
    tank_volume_start: 33058.299999999894,
    tank_volume_stop: 33001.0999999999,
  },
  {
    timestamp_start: '2022-01-01T10:04:39',
    timestamp_stop: '2022-01-01T10:04:44',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 136199,
    tank_volume_start: 33001.0999999999,
    tank_volume_stop: 32953.199999999895,
  },
  {
    timestamp_start: '2022-01-01T10:04:59',
    timestamp_stop: '2022-01-01T10:05:05',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 136201,
    tank_volume_start: 32953.199999999895,
    tank_volume_stop: 32899.299999999894,
  },
  {
    timestamp_start: '2022-01-01T10:05:20',
    timestamp_stop: '2022-01-01T10:05:25',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 136203,
    tank_volume_start: 32899.299999999894,
    tank_volume_stop: 32849.299999999894,
  },
  {
    timestamp_start: '2022-01-01T10:05:40',
    timestamp_stop: '2022-01-01T10:05:45',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 136205,
    tank_volume_start: 32849.299999999894,
    tank_volume_stop: 32796.0999999999,
  },
  {
    timestamp_start: '2022-01-01T10:06:00',
    timestamp_stop: '2022-01-01T10:06:05',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 136207,
    tank_volume_start: 32796.0999999999,
    tank_volume_stop: 32742.799999999897,
  },
  {
    timestamp_start: '2022-01-01T10:06:20',
    timestamp_stop: '2022-01-01T10:06:27',
    quantity: 63.5,
    vehicle: 'DEM222',
    id: 136209,
    tank_volume_start: 32742.799999999897,
    tank_volume_stop: 32679.299999999897,
  },
  {
    timestamp_start: '2022-01-01T10:06:42',
    timestamp_stop: '2022-01-01T10:06:48',
    quantity: 60.1,
    vehicle: 'DEM444',
    id: 136211,
    tank_volume_start: 32679.299999999897,
    tank_volume_stop: 32619.1999999999,
  },
  {
    timestamp_start: '2022-01-01T10:07:03',
    timestamp_stop: '2022-01-01T10:07:08',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 136213,
    tank_volume_start: 32619.1999999999,
    tank_volume_stop: 32567.8999999999,
  },
  {
    timestamp_start: '2022-01-01T10:07:23',
    timestamp_stop: '2022-01-01T10:07:28',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 136215,
    tank_volume_start: 32567.8999999999,
    tank_volume_stop: 32515.999999999898,
  },
  {
    timestamp_start: '2022-01-01T10:07:43',
    timestamp_stop: '2022-01-01T10:07:49',
    quantity: 62.3,
    vehicle: 'DEM555',
    id: 136217,
    tank_volume_start: 32515.999999999898,
    tank_volume_stop: 32453.6999999999,
  },
  {
    timestamp_start: '2022-01-01T10:08:04',
    timestamp_stop: '2022-01-01T10:08:09',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 136219,
    tank_volume_start: 32453.6999999999,
    tank_volume_stop: 32405.799999999897,
  },
  {
    timestamp_start: '2022-01-01T10:08:24',
    timestamp_stop: '2022-01-01T10:08:30',
    quantity: 61.4,
    vehicle: 'DEM666',
    id: 136221,
    tank_volume_start: 32405.799999999897,
    tank_volume_stop: 32344.399999999896,
  },
  {
    timestamp_start: '2022-01-01T10:08:45',
    timestamp_stop: '2022-01-01T10:08:51',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 136223,
    tank_volume_start: 32344.399999999896,
    tank_volume_stop: 32281.499999999894,
  },
  {
    timestamp_start: '2022-01-01T10:09:06',
    timestamp_stop: '2022-01-01T10:09:13',
    quantity: 63.8,
    vehicle: 'DEM888',
    id: 136225,
    tank_volume_start: 32281.499999999894,
    tank_volume_stop: 32217.699999999895,
  },
  {
    timestamp_start: '2022-01-01T10:09:28',
    timestamp_stop: '2022-01-01T10:09:33',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 136227,
    tank_volume_start: 32217.699999999895,
    tank_volume_stop: 32160.399999999896,
  },
  {
    timestamp_start: '2022-01-01T10:09:48',
    timestamp_stop: '2022-01-01T10:09:54',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 136229,
    tank_volume_start: 32160.399999999896,
    tank_volume_stop: 32107.999999999894,
  },
  {
    timestamp_start: '2022-01-01T10:10:09',
    timestamp_stop: '2022-01-01T10:10:14',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 136231,
    tank_volume_start: 32107.999999999894,
    tank_volume_stop: 32050.799999999894,
  },
  {
    timestamp_start: '2022-01-01T10:10:29',
    timestamp_stop: '2022-01-01T10:10:34',
    quantity: 46.4,
    vehicle: 'DEM444',
    id: 136233,
    tank_volume_start: 32050.799999999894,
    tank_volume_stop: 32004.399999999892,
  },
  {
    timestamp_start: '2022-01-01T10:10:49',
    timestamp_stop: '2022-01-01T10:10:54',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 136235,
    tank_volume_start: 32004.399999999892,
    tank_volume_stop: 31950.19999999989,
  },
  {
    timestamp_start: '2022-01-01T10:11:09',
    timestamp_stop: '2022-01-01T10:11:14',
    quantity: 45.2,
    vehicle: 'DEM000',
    id: 136237,
    tank_volume_start: 31950.19999999989,
    tank_volume_stop: 31904.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:11:29',
    timestamp_stop: '2022-01-01T10:11:34',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 136239,
    tank_volume_start: 31904.99999999989,
    tank_volume_stop: 31853.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:11:49',
    timestamp_stop: '2022-01-01T10:11:55',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 136241,
    tank_volume_start: 31853.99999999989,
    tank_volume_stop: 31799.09999999989,
  },
  {
    timestamp_start: '2022-01-01T10:12:10',
    timestamp_stop: '2022-01-01T10:12:15',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 136243,
    tank_volume_start: 31799.09999999989,
    tank_volume_stop: 31742.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:12:30',
    timestamp_stop: '2022-01-01T10:12:36',
    quantity: 56.3,
    vehicle: 'DEM666',
    id: 136245,
    tank_volume_start: 31742.89999999989,
    tank_volume_stop: 31686.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:12:51',
    timestamp_stop: '2022-01-01T10:12:56',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 136247,
    tank_volume_start: 31686.59999999989,
    tank_volume_stop: 31633.79999999989,
  },
  {
    timestamp_start: '2022-01-01T10:13:11',
    timestamp_stop: '2022-01-01T10:13:17',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 136249,
    tank_volume_start: 31633.79999999989,
    tank_volume_stop: 31577.79999999989,
  },
  {
    timestamp_start: '2022-01-01T10:13:32',
    timestamp_stop: '2022-01-01T10:13:37',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 136251,
    tank_volume_start: 31577.79999999989,
    tank_volume_stop: 31525.29999999989,
  },
  {
    timestamp_start: '2022-01-01T10:13:52',
    timestamp_stop: '2022-01-01T10:13:57',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 136253,
    tank_volume_start: 31525.29999999989,
    tank_volume_stop: 31471.29999999989,
  },
  {
    timestamp_start: '2022-01-01T10:14:12',
    timestamp_stop: '2022-01-01T10:14:18',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 136255,
    tank_volume_start: 31471.29999999989,
    tank_volume_stop: 31414.79999999989,
  },
  {
    timestamp_start: '2022-01-01T10:14:33',
    timestamp_stop: '2022-01-01T10:14:39',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 136257,
    tank_volume_start: 31414.79999999989,
    tank_volume_stop: 31358.29999999989,
  },
  {
    timestamp_start: '2022-01-01T10:14:54',
    timestamp_stop: '2022-01-01T10:14:59',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 136259,
    tank_volume_start: 31358.29999999989,
    tank_volume_stop: 31302.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:15:14',
    timestamp_stop: '2022-01-01T10:15:19',
    quantity: 49.9,
    vehicle: 'DEM222',
    id: 136261,
    tank_volume_start: 31302.89999999989,
    tank_volume_stop: 31252.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:15:34',
    timestamp_stop: '2022-01-01T10:15:39',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 136263,
    tank_volume_start: 31252.999999999887,
    tank_volume_stop: 31201.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:15:54',
    timestamp_stop: '2022-01-01T10:16:00',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 136265,
    tank_volume_start: 31201.199999999888,
    tank_volume_stop: 31145.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:16:15',
    timestamp_stop: '2022-01-01T10:16:20',
    quantity: 47.2,
    vehicle: 'DEM222',
    id: 136267,
    tank_volume_start: 31145.199999999888,
    tank_volume_stop: 31097.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:16:35',
    timestamp_stop: '2022-01-01T10:16:40',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 136269,
    tank_volume_start: 31097.999999999887,
    tank_volume_stop: 31042.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:16:55',
    timestamp_stop: '2022-01-01T10:17:01',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 136271,
    tank_volume_start: 31042.199999999888,
    tank_volume_stop: 30986.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:17:16',
    timestamp_stop: '2022-01-01T10:17:21',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 136273,
    tank_volume_start: 30986.999999999887,
    tank_volume_stop: 30934.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:17:36',
    timestamp_stop: '2022-01-01T10:17:42',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 136275,
    tank_volume_start: 30934.199999999888,
    tank_volume_stop: 30877.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:17:57',
    timestamp_stop: '2022-01-01T10:18:02',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 136277,
    tank_volume_start: 30877.59999999989,
    tank_volume_stop: 30821.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:18:17',
    timestamp_stop: '2022-01-01T10:18:23',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 136279,
    tank_volume_start: 30821.199999999888,
    tank_volume_stop: 30762.299999999886,
  },
  {
    timestamp_start: '2022-01-01T10:18:38',
    timestamp_stop: '2022-01-01T10:18:44',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 136281,
    tank_volume_start: 30762.299999999886,
    tank_volume_stop: 30704.099999999886,
  },
  {
    timestamp_start: '2022-01-01T10:18:59',
    timestamp_stop: '2022-01-01T10:19:05',
    quantity: 55.1,
    vehicle: 'DEM333',
    id: 136283,
    tank_volume_start: 30704.099999999886,
    tank_volume_stop: 30648.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:19:20',
    timestamp_stop: '2022-01-01T10:19:25',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 136285,
    tank_volume_start: 30648.999999999887,
    tank_volume_stop: 30595.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:19:40',
    timestamp_stop: '2022-01-01T10:19:46',
    quantity: 59.2,
    vehicle: 'DEM777',
    id: 136287,
    tank_volume_start: 30595.89999999989,
    tank_volume_stop: 30536.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:20:01',
    timestamp_stop: '2022-01-01T10:20:06',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 136289,
    tank_volume_start: 30536.699999999888,
    tank_volume_stop: 30480.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:20:21',
    timestamp_stop: '2022-01-01T10:20:27',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 136291,
    tank_volume_start: 30480.999999999887,
    tank_volume_stop: 30426.499999999887,
  },
  {
    timestamp_start: '2022-01-01T10:20:42',
    timestamp_stop: '2022-01-01T10:20:48',
    quantity: 61.6,
    vehicle: 'DEM111',
    id: 136293,
    tank_volume_start: 30426.499999999887,
    tank_volume_stop: 30364.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:21:03',
    timestamp_stop: '2022-01-01T10:21:09',
    quantity: 62.2,
    vehicle: 'DEM333',
    id: 136295,
    tank_volume_start: 30364.89999999989,
    tank_volume_stop: 30302.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:21:24',
    timestamp_stop: '2022-01-01T10:21:31',
    quantity: 62.8,
    vehicle: 'DEM000',
    id: 136297,
    tank_volume_start: 30302.699999999888,
    tank_volume_stop: 30239.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:21:46',
    timestamp_stop: '2022-01-01T10:21:50',
    quantity: 47.3,
    vehicle: 'DEM555',
    id: 136299,
    tank_volume_start: 30239.89999999989,
    tank_volume_stop: 30192.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:22:05',
    timestamp_stop: '2022-01-01T10:22:11',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 136301,
    tank_volume_start: 30192.59999999989,
    tank_volume_stop: 30139.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:22:26',
    timestamp_stop: '2022-01-01T10:22:31',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 136303,
    tank_volume_start: 30139.59999999989,
    tank_volume_stop: 30086.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:22:46',
    timestamp_stop: '2022-01-01T10:22:51',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 136305,
    tank_volume_start: 30086.699999999888,
    tank_volume_stop: 30033.499999999887,
  },
  {
    timestamp_start: '2022-01-01T10:23:06',
    timestamp_stop: '2022-01-01T10:23:11',
    quantity: 49.2,
    vehicle: 'DEM777',
    id: 136307,
    tank_volume_start: 30033.499999999887,
    tank_volume_stop: 29984.299999999886,
  },
  {
    timestamp_start: '2022-01-01T10:23:26',
    timestamp_stop: '2022-01-01T10:23:32',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 136309,
    tank_volume_start: 29984.299999999886,
    tank_volume_stop: 29928.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:23:47',
    timestamp_stop: '2022-01-01T10:23:53',
    quantity: 61.2,
    vehicle: 'DEM111',
    id: 136311,
    tank_volume_start: 29928.699999999888,
    tank_volume_stop: 29867.499999999887,
  },
  {
    timestamp_start: '2022-01-01T10:24:08',
    timestamp_stop: '2022-01-01T10:24:14',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 136313,
    tank_volume_start: 29867.499999999887,
    tank_volume_stop: 29807.39999999989,
  },
  {
    timestamp_start: '2022-01-01T10:24:29',
    timestamp_stop: '2022-01-01T10:24:34',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 136315,
    tank_volume_start: 29807.39999999989,
    tank_volume_stop: 29751.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:24:49',
    timestamp_stop: '2022-01-01T10:24:55',
    quantity: 54.7,
    vehicle: 'DEM555',
    id: 136317,
    tank_volume_start: 29751.89999999989,
    tank_volume_stop: 29697.199999999888,
  },
  {
    timestamp_start: '2022-01-01T10:25:10',
    timestamp_stop: '2022-01-01T10:25:16',
    quantity: 59.1,
    vehicle: 'DEM777',
    id: 136319,
    tank_volume_start: 29697.199999999888,
    tank_volume_stop: 29638.09999999989,
  },
  {
    timestamp_start: '2022-01-01T10:25:31',
    timestamp_stop: '2022-01-01T10:25:37',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 136321,
    tank_volume_start: 29638.09999999989,
    tank_volume_stop: 29579.99999999989,
  },
  {
    timestamp_start: '2022-01-01T10:25:52',
    timestamp_stop: '2022-01-01T10:25:57',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 136323,
    tank_volume_start: 29579.99999999989,
    tank_volume_stop: 29522.29999999989,
  },
  {
    timestamp_start: '2022-01-01T10:26:12',
    timestamp_stop: '2022-01-01T10:26:18',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 136325,
    tank_volume_start: 29522.29999999989,
    tank_volume_stop: 29467.79999999989,
  },
  {
    timestamp_start: '2022-01-01T10:26:33',
    timestamp_stop: '2022-01-01T10:26:38',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 136327,
    tank_volume_start: 29467.79999999989,
    tank_volume_stop: 29413.09999999989,
  },
  {
    timestamp_start: '2022-01-01T10:26:53',
    timestamp_stop: '2022-01-01T10:26:59',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 136329,
    tank_volume_start: 29413.09999999989,
    tank_volume_stop: 29359.89999999989,
  },
  {
    timestamp_start: '2022-01-01T10:27:14',
    timestamp_stop: '2022-01-01T10:27:19',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 136331,
    tank_volume_start: 29359.89999999989,
    tank_volume_stop: 29303.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:27:34',
    timestamp_stop: '2022-01-01T10:27:40',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 136333,
    tank_volume_start: 29303.699999999888,
    tank_volume_stop: 29245.59999999989,
  },
  {
    timestamp_start: '2022-01-01T10:27:55',
    timestamp_stop: '2022-01-01T10:28:00',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 136335,
    tank_volume_start: 29245.59999999989,
    tank_volume_stop: 29193.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:28:15',
    timestamp_stop: '2022-01-01T10:28:21',
    quantity: 61.3,
    vehicle: 'DEM888',
    id: 136337,
    tank_volume_start: 29193.699999999888,
    tank_volume_stop: 29132.39999999989,
  },
  {
    timestamp_start: '2022-01-01T10:28:36',
    timestamp_stop: '2022-01-01T10:28:42',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 136339,
    tank_volume_start: 29132.39999999989,
    tank_volume_stop: 29073.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:28:57',
    timestamp_stop: '2022-01-01T10:29:03',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 136341,
    tank_volume_start: 29073.999999999887,
    tank_volume_stop: 29016.499999999887,
  },
  {
    timestamp_start: '2022-01-01T10:29:18',
    timestamp_stop: '2022-01-01T10:29:23',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 136343,
    tank_volume_start: 29016.499999999887,
    tank_volume_stop: 28961.099999999886,
  },
  {
    timestamp_start: '2022-01-01T10:29:38',
    timestamp_stop: '2022-01-01T10:29:44',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 136345,
    tank_volume_start: 28961.099999999886,
    tank_volume_stop: 28906.599999999886,
  },
  {
    timestamp_start: '2022-01-01T10:29:59',
    timestamp_stop: '2022-01-01T10:30:04',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 136347,
    tank_volume_start: 28906.599999999886,
    tank_volume_stop: 28851.799999999886,
  },
  {
    timestamp_start: '2022-01-01T10:30:19',
    timestamp_stop: '2022-01-01T10:30:25',
    quantity: 57.8,
    vehicle: 'DEM777',
    id: 136349,
    tank_volume_start: 28851.799999999886,
    tank_volume_stop: 28793.999999999887,
  },
  {
    timestamp_start: '2022-01-01T10:30:40',
    timestamp_stop: '2022-01-01T10:30:46',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 136351,
    tank_volume_start: 28793.999999999887,
    tank_volume_stop: 28735.099999999886,
  },
  {
    timestamp_start: '2022-01-01T10:31:01',
    timestamp_stop: '2022-01-01T10:31:07',
    quantity: 62.7,
    vehicle: 'DEM111',
    id: 136353,
    tank_volume_start: 28735.099999999886,
    tank_volume_stop: 28672.399999999885,
  },
  {
    timestamp_start: '2022-01-01T10:31:22',
    timestamp_stop: '2022-01-01T10:31:27',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 136355,
    tank_volume_start: 28672.399999999885,
    tank_volume_stop: 28620.799999999886,
  },
  {
    timestamp_start: '2022-01-01T10:31:42',
    timestamp_stop: '2022-01-01T10:31:48',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 136357,
    tank_volume_start: 28620.799999999886,
    tank_volume_stop: 28565.299999999886,
  },
  {
    timestamp_start: '2022-01-01T10:32:03',
    timestamp_stop: '2022-01-01T10:32:09',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 136359,
    tank_volume_start: 28565.299999999886,
    tank_volume_stop: 28503.699999999888,
  },
  {
    timestamp_start: '2022-01-01T10:32:24',
    timestamp_stop: '2022-01-01T10:32:29',
    quantity: 47.9,
    vehicle: 'DEM444',
    id: 136361,
    tank_volume_start: 28503.699999999888,
    tank_volume_stop: 28455.799999999886,
  },
  {
    timestamp_start: '2022-01-01T10:32:44',
    timestamp_stop: '2022-01-01T10:32:49',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 136363,
    tank_volume_start: 28455.799999999886,
    tank_volume_stop: 28402.399999999885,
  },
  {
    timestamp_start: '2022-01-01T10:33:04',
    timestamp_stop: '2022-01-01T10:33:10',
    quantity: 55.4,
    vehicle: 'DEM888',
    id: 136365,
    tank_volume_start: 28402.399999999885,
    tank_volume_stop: 28346.999999999884,
  },
  {
    timestamp_start: '2022-01-01T10:33:25',
    timestamp_stop: '2022-01-01T10:33:31',
    quantity: 63.7,
    vehicle: 'DEM000',
    id: 136367,
    tank_volume_start: 28346.999999999884,
    tank_volume_stop: 28283.299999999883,
  },
  {
    timestamp_start: '2022-01-01T10:33:46',
    timestamp_stop: '2022-01-01T10:33:51',
    quantity: 49.0,
    vehicle: 'DEM111',
    id: 136369,
    tank_volume_start: 28283.299999999883,
    tank_volume_stop: 28234.299999999883,
  },
  {
    timestamp_start: '2022-01-01T10:34:06',
    timestamp_stop: '2022-01-01T10:34:11',
    quantity: 53.7,
    vehicle: 'DEM222',
    id: 136371,
    tank_volume_start: 28234.299999999883,
    tank_volume_stop: 28180.599999999882,
  },
  {
    timestamp_start: '2022-01-01T10:34:26',
    timestamp_stop: '2022-01-01T10:34:32',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 136373,
    tank_volume_start: 28180.599999999882,
    tank_volume_stop: 28126.19999999988,
  },
  {
    timestamp_start: '2022-01-01T10:34:47',
    timestamp_stop: '2022-01-01T10:34:52',
    quantity: 51.7,
    vehicle: 'DEM555',
    id: 136375,
    tank_volume_start: 28126.19999999988,
    tank_volume_stop: 28074.49999999988,
  },
  {
    timestamp_start: '2022-01-01T10:35:07',
    timestamp_stop: '2022-01-01T10:35:12',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 136377,
    tank_volume_start: 28074.49999999988,
    tank_volume_stop: 28021.49999999988,
  },
  {
    timestamp_start: '2022-01-01T10:35:27',
    timestamp_stop: '2022-01-01T10:35:32',
    quantity: 48.3,
    vehicle: 'DEM777',
    id: 136379,
    tank_volume_start: 28021.49999999988,
    tank_volume_stop: 27973.19999999988,
  },
  {
    timestamp_start: '2022-01-01T10:35:47',
    timestamp_stop: '2022-01-01T10:35:53',
    quantity: 58.3,
    vehicle: 'DEM666',
    id: 136381,
    tank_volume_start: 27973.19999999988,
    tank_volume_stop: 27914.89999999988,
  },
  {
    timestamp_start: '2022-01-01T10:36:08',
    timestamp_stop: '2022-01-01T10:36:13',
    quantity: 51.9,
    vehicle: 'DEM555',
    id: 136383,
    tank_volume_start: 27914.89999999988,
    tank_volume_stop: 27862.99999999988,
  },
  {
    timestamp_start: '2022-01-01T10:36:28',
    timestamp_stop: '2022-01-01T10:36:34',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 136385,
    tank_volume_start: 27862.99999999988,
    tank_volume_stop: 27807.09999999988,
  },
  {
    timestamp_start: '2022-01-01T10:36:49',
    timestamp_stop: '2022-01-01T10:36:54',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 136387,
    tank_volume_start: 27807.09999999988,
    tank_volume_stop: 27756.29999999988,
  },
  {
    timestamp_start: '2022-01-01T10:37:09',
    timestamp_stop: '2022-01-01T10:37:14',
    quantity: 46.9,
    vehicle: 'DEM111',
    id: 136389,
    tank_volume_start: 27756.29999999988,
    tank_volume_stop: 27709.399999999878,
  },
  {
    timestamp_start: '2022-01-01T10:37:29',
    timestamp_stop: '2022-01-01T10:37:34',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 136391,
    tank_volume_start: 27709.399999999878,
    tank_volume_stop: 27653.499999999876,
  },
  {
    timestamp_start: '2022-01-01T10:37:49',
    timestamp_stop: '2022-01-01T10:37:55',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 136393,
    tank_volume_start: 27653.499999999876,
    tank_volume_stop: 27596.299999999876,
  },
  {
    timestamp_start: '2022-01-01T10:38:10',
    timestamp_stop: '2022-01-01T10:38:15',
    quantity: 51.7,
    vehicle: 'DEM555',
    id: 136395,
    tank_volume_start: 27596.299999999876,
    tank_volume_stop: 27544.599999999875,
  },
  {
    timestamp_start: '2022-01-01T10:38:30',
    timestamp_stop: '2022-01-01T10:38:35',
    quantity: 47.4,
    vehicle: 'DEM111',
    id: 136397,
    tank_volume_start: 27544.599999999875,
    tank_volume_stop: 27497.199999999873,
  },
  {
    timestamp_start: '2022-01-01T10:38:50',
    timestamp_stop: '2022-01-01T10:38:56',
    quantity: 59.0,
    vehicle: 'DEM555',
    id: 136399,
    tank_volume_start: 27497.199999999873,
    tank_volume_stop: 27438.199999999873,
  },
  {
    timestamp_start: '2022-01-01T10:39:11',
    timestamp_stop: '2022-01-01T10:39:16',
    quantity: 55.4,
    vehicle: 'DEM888',
    id: 136401,
    tank_volume_start: 27438.199999999873,
    tank_volume_stop: 27382.799999999872,
  },
  {
    timestamp_start: '2022-01-01T10:39:31',
    timestamp_stop: '2022-01-01T10:39:37',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 136403,
    tank_volume_start: 27382.799999999872,
    tank_volume_stop: 27328.299999999872,
  },
  {
    timestamp_start: '2022-01-01T10:39:52',
    timestamp_stop: '2022-01-01T10:39:57',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 136405,
    tank_volume_start: 27328.299999999872,
    tank_volume_stop: 27276.299999999872,
  },
  {
    timestamp_start: '2022-01-01T10:40:12',
    timestamp_stop: '2022-01-01T10:40:18',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 136407,
    tank_volume_start: 27276.299999999872,
    tank_volume_stop: 27217.499999999873,
  },
  {
    timestamp_start: '2022-01-01T10:40:33',
    timestamp_stop: '2022-01-01T10:40:38',
    quantity: 57.0,
    vehicle: 'DEM555',
    id: 136409,
    tank_volume_start: 27217.499999999873,
    tank_volume_stop: 27160.499999999873,
  },
  {
    timestamp_start: '2022-01-01T10:40:53',
    timestamp_stop: '2022-01-01T10:40:58',
    quantity: 49.2,
    vehicle: 'DEM777',
    id: 136411,
    tank_volume_start: 27160.499999999873,
    tank_volume_stop: 27111.299999999872,
  },
  {
    timestamp_start: '2022-01-01T10:41:13',
    timestamp_stop: '2022-01-01T10:41:18',
    quantity: 48.1,
    vehicle: 'DEM000',
    id: 136413,
    tank_volume_start: 27111.299999999872,
    tank_volume_stop: 27063.199999999873,
  },
  {
    timestamp_start: '2022-01-01T10:41:33',
    timestamp_stop: '2022-01-01T10:41:38',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 136415,
    tank_volume_start: 27063.199999999873,
    tank_volume_stop: 27015.399999999874,
  },
  {
    timestamp_start: '2022-01-01T10:41:53',
    timestamp_stop: '2022-01-01T10:41:58',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 136417,
    tank_volume_start: 27015.399999999874,
    tank_volume_stop: 26965.199999999873,
  },
  {
    timestamp_start: '2022-01-01T10:42:13',
    timestamp_stop: '2022-01-01T10:42:18',
    quantity: 50.2,
    vehicle: 'DEM333',
    id: 136419,
    tank_volume_start: 26965.199999999873,
    tank_volume_stop: 26914.999999999873,
  },
  {
    timestamp_start: '2022-01-01T10:42:33',
    timestamp_stop: '2022-01-01T10:42:38',
    quantity: 46.5,
    vehicle: 'DEM555',
    id: 136421,
    tank_volume_start: 26914.999999999873,
    tank_volume_stop: 26868.499999999873,
  },
  {
    timestamp_start: '2022-01-01T10:42:53',
    timestamp_stop: '2022-01-01T10:42:58',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 136423,
    tank_volume_start: 26868.499999999873,
    tank_volume_stop: 26815.09999999987,
  },
  {
    timestamp_start: '2022-01-01T10:43:13',
    timestamp_stop: '2022-01-01T10:43:19',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 136425,
    tank_volume_start: 26815.09999999987,
    tank_volume_stop: 26752.19999999987,
  },
  {
    timestamp_start: '2022-01-01T10:43:34',
    timestamp_stop: '2022-01-01T10:43:40',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 136427,
    tank_volume_start: 26752.19999999987,
    tank_volume_stop: 26697.09999999987,
  },
  {
    timestamp_start: '2022-01-01T10:43:55',
    timestamp_stop: '2022-01-01T10:44:00',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 136429,
    tank_volume_start: 26697.09999999987,
    tank_volume_stop: 26641.499999999873,
  },
  {
    timestamp_start: '2022-01-01T10:44:15',
    timestamp_stop: '2022-01-01T10:44:20',
    quantity: 46.4,
    vehicle: 'DEM000',
    id: 136431,
    tank_volume_start: 26641.499999999873,
    tank_volume_stop: 26595.09999999987,
  },
  {
    timestamp_start: '2022-01-01T10:44:35',
    timestamp_stop: '2022-01-01T10:44:41',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 136433,
    tank_volume_start: 26595.09999999987,
    tank_volume_stop: 26535.59999999987,
  },
  {
    timestamp_start: '2022-01-01T10:44:56',
    timestamp_stop: '2022-01-01T10:45:01',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 136435,
    tank_volume_start: 26535.59999999987,
    tank_volume_stop: 26484.89999999987,
  },
  {
    timestamp_start: '2022-01-01T10:45:16',
    timestamp_stop: '2022-01-01T10:45:22',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 136437,
    tank_volume_start: 26484.89999999987,
    tank_volume_stop: 26428.39999999987,
  },
  {
    timestamp_start: '2022-01-01T10:45:37',
    timestamp_stop: '2022-01-01T10:45:41',
    quantity: 45.6,
    vehicle: 'DEM888',
    id: 136439,
    tank_volume_start: 26428.39999999987,
    tank_volume_stop: 26382.799999999872,
  },
  {
    timestamp_start: '2022-01-01T10:45:56',
    timestamp_stop: '2022-01-01T10:46:02',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 136440,
    tank_volume_start: 26382.799999999872,
    tank_volume_stop: 26322.699999999873,
  },
  {
    timestamp_start: '2022-01-01T10:46:17',
    timestamp_stop: '2022-01-01T10:46:23',
    quantity: 59.3,
    vehicle: 'DEM888',
    id: 136443,
    tank_volume_start: 26322.699999999873,
    tank_volume_stop: 26263.399999999874,
  },
  {
    timestamp_start: '2022-01-01T10:46:38',
    timestamp_stop: '2022-01-01T10:46:44',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 136445,
    tank_volume_start: 26263.399999999874,
    tank_volume_stop: 26208.599999999875,
  },
  {
    timestamp_start: '2022-01-01T10:46:59',
    timestamp_stop: '2022-01-01T10:47:04',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 136447,
    tank_volume_start: 26208.599999999875,
    tank_volume_stop: 26151.599999999875,
  },
  {
    timestamp_start: '2022-01-01T10:47:19',
    timestamp_stop: '2022-01-01T10:47:25',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 136449,
    tank_volume_start: 26151.599999999875,
    tank_volume_stop: 26095.299999999876,
  },
  {
    timestamp_start: '2022-01-01T10:47:40',
    timestamp_stop: '2022-01-01T10:47:45',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 136451,
    tank_volume_start: 26095.299999999876,
    tank_volume_stop: 26044.399999999874,
  },
  {
    timestamp_start: '2022-01-01T10:48:00',
    timestamp_stop: '2022-01-01T10:48:06',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 136453,
    tank_volume_start: 26044.399999999874,
    tank_volume_stop: 25985.699999999873,
  },
  {
    timestamp_start: '2022-01-01T10:48:21',
    timestamp_stop: '2022-01-01T10:48:27',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 136455,
    tank_volume_start: 25985.699999999873,
    tank_volume_stop: 25926.199999999873,
  },
  {
    timestamp_start: '2022-01-01T10:48:42',
    timestamp_stop: '2022-01-01T10:48:47',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 136457,
    tank_volume_start: 25926.199999999873,
    tank_volume_stop: 25874.299999999872,
  },
  {
    timestamp_start: '2022-01-01T10:49:02',
    timestamp_stop: '2022-01-01T10:49:08',
    quantity: 55.3,
    vehicle: 'DEM666',
    id: 136459,
    tank_volume_start: 25874.299999999872,
    tank_volume_stop: 25818.999999999873,
  },
  {
    timestamp_start: '2022-01-01T10:49:23',
    timestamp_stop: '2022-01-01T10:49:28',
    quantity: 50.2,
    vehicle: 'DEM333',
    id: 136461,
    tank_volume_start: 25818.999999999873,
    tank_volume_stop: 25768.799999999872,
  },
  {
    timestamp_start: '2022-01-01T10:49:43',
    timestamp_stop: '2022-01-01T10:49:48',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 136463,
    tank_volume_start: 25768.799999999872,
    tank_volume_stop: 25713.999999999873,
  },
  {
    timestamp_start: '2022-01-01T10:50:03',
    timestamp_stop: '2022-01-01T10:50:08',
    quantity: 47.4,
    vehicle: 'DEM666',
    id: 136465,
    tank_volume_start: 25713.999999999873,
    tank_volume_stop: 25666.59999999987,
  },
  {
    timestamp_start: '2022-01-01T10:50:23',
    timestamp_stop: '2022-01-01T10:50:29',
    quantity: 61.3,
    vehicle: 'DEM222',
    id: 136467,
    tank_volume_start: 25666.59999999987,
    tank_volume_stop: 25605.299999999872,
  },
  {
    timestamp_start: '2022-01-01T10:50:44',
    timestamp_stop: '2022-01-01T10:50:49',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 136469,
    tank_volume_start: 25605.299999999872,
    tank_volume_stop: 25552.09999999987,
  },
  {
    timestamp_start: '2022-01-01T10:51:04',
    timestamp_stop: '2022-01-01T10:51:10',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 136471,
    tank_volume_start: 25552.09999999987,
    tank_volume_stop: 25495.69999999987,
  },
  {
    timestamp_start: '2022-01-01T10:51:25',
    timestamp_stop: '2022-01-01T10:51:30',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 136473,
    tank_volume_start: 25495.69999999987,
    tank_volume_stop: 25441.19999999987,
  },
  {
    timestamp_start: '2022-01-01T10:51:45',
    timestamp_stop: '2022-01-01T10:51:51',
    quantity: 58.7,
    vehicle: 'DEM000',
    id: 136475,
    tank_volume_start: 25441.19999999987,
    tank_volume_stop: 25382.49999999987,
  },
  {
    timestamp_start: '2022-01-01T10:52:06',
    timestamp_stop: '2022-01-01T10:52:12',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 136477,
    tank_volume_start: 25382.49999999987,
    tank_volume_stop: 25325.29999999987,
  },
  {
    timestamp_start: '2022-01-01T10:52:27',
    timestamp_stop: '2022-01-01T10:52:32',
    quantity: 52.3,
    vehicle: 'DEM666',
    id: 136479,
    tank_volume_start: 25325.29999999987,
    tank_volume_stop: 25272.99999999987,
  },
  {
    timestamp_start: '2022-01-01T10:52:47',
    timestamp_stop: '2022-01-01T10:52:52',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 136481,
    tank_volume_start: 25272.99999999987,
    tank_volume_stop: 25221.19999999987,
  },
  {
    timestamp_start: '2022-01-01T10:53:07',
    timestamp_stop: '2022-01-01T10:53:13',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 136483,
    tank_volume_start: 25221.19999999987,
    tank_volume_stop: 25166.79999999987,
  },
  {
    timestamp_start: '2022-01-01T10:53:28',
    timestamp_stop: '2022-01-01T10:53:34',
    quantity: 57.6,
    vehicle: 'DEM000',
    id: 136485,
    tank_volume_start: 25166.79999999987,
    tank_volume_stop: 25109.19999999987,
  },
  {
    timestamp_start: '2022-01-01T10:53:49',
    timestamp_stop: '2022-01-01T10:53:54',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 136487,
    tank_volume_start: 25109.19999999987,
    tank_volume_stop: 25057.49999999987,
  },
  {
    timestamp_start: '2022-01-01T10:54:09',
    timestamp_stop: '2022-01-01T10:54:14',
    quantity: 51.5,
    vehicle: 'DEM111',
    id: 136489,
    tank_volume_start: 25057.49999999987,
    tank_volume_stop: 25005.99999999987,
  },
  {
    timestamp_start: '2022-01-01T10:54:29',
    timestamp_stop: '2022-01-01T10:54:34',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 136491,
    tank_volume_start: 25005.99999999987,
    tank_volume_stop: 24952.099999999868,
  },
  {
    timestamp_start: '2022-01-01T10:54:49',
    timestamp_stop: '2022-01-01T10:54:55',
    quantity: 59.6,
    vehicle: 'DEM444',
    id: 136493,
    tank_volume_start: 24952.099999999868,
    tank_volume_stop: 24892.49999999987,
  },
  {
    timestamp_start: '2022-01-01T10:55:10',
    timestamp_stop: '2022-01-01T10:55:15',
    quantity: 50.5,
    vehicle: 'DEM000',
    id: 136495,
    tank_volume_start: 24892.49999999987,
    tank_volume_stop: 24841.99999999987,
  },
  {
    timestamp_start: '2022-01-01T10:55:30',
    timestamp_stop: '2022-01-01T10:55:35',
    quantity: 45.9,
    vehicle: 'DEM222',
    id: 136497,
    tank_volume_start: 24841.99999999987,
    tank_volume_stop: 24796.099999999868,
  },
  {
    timestamp_start: '2022-01-01T10:55:50',
    timestamp_stop: '2022-01-01T10:55:56',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 136499,
    tank_volume_start: 24796.099999999868,
    tank_volume_stop: 24739.29999999987,
  },
  {
    timestamp_start: '2022-01-01T10:56:11',
    timestamp_stop: '2022-01-01T10:56:16',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 136500,
    tank_volume_start: 24739.29999999987,
    tank_volume_stop: 24686.099999999868,
  },
  {
    timestamp_start: '2022-01-01T10:56:31',
    timestamp_stop: '2022-01-01T10:56:37',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 136502,
    tank_volume_start: 24686.099999999868,
    tank_volume_stop: 24627.49999999987,
  },
  {
    timestamp_start: '2022-01-01T10:56:52',
    timestamp_stop: '2022-01-01T10:56:58',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 136504,
    tank_volume_start: 24627.49999999987,
    tank_volume_stop: 24569.29999999987,
  },
  {
    timestamp_start: '2022-01-01T10:57:13',
    timestamp_stop: '2022-01-01T10:57:18',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 136507,
    tank_volume_start: 24569.29999999987,
    tank_volume_stop: 24514.899999999867,
  },
  {
    timestamp_start: '2022-01-01T10:57:33',
    timestamp_stop: '2022-01-01T10:57:38',
    quantity: 48.2,
    vehicle: 'DEM111',
    id: 136509,
    tank_volume_start: 24514.899999999867,
    tank_volume_stop: 24466.699999999866,
  },
  {
    timestamp_start: '2022-01-01T10:57:53',
    timestamp_stop: '2022-01-01T10:57:58',
    quantity: 49.8,
    vehicle: 'DEM777',
    id: 136510,
    tank_volume_start: 24466.699999999866,
    tank_volume_stop: 24416.899999999867,
  },
  {
    timestamp_start: '2022-01-01T10:58:13',
    timestamp_stop: '2022-01-01T10:58:18',
    quantity: 49.2,
    vehicle: 'DEM333',
    id: 136512,
    tank_volume_start: 24416.899999999867,
    tank_volume_stop: 24367.699999999866,
  },
  {
    timestamp_start: '2022-01-01T10:58:33',
    timestamp_stop: '2022-01-01T10:58:38',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 136514,
    tank_volume_start: 24367.699999999866,
    tank_volume_stop: 24312.399999999867,
  },
  {
    timestamp_start: '2022-01-01T10:58:53',
    timestamp_stop: '2022-01-01T10:58:59',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 136516,
    tank_volume_start: 24312.399999999867,
    tank_volume_stop: 24254.29999999987,
  },
  {
    timestamp_start: '2022-01-01T10:59:14',
    timestamp_stop: '2022-01-01T10:59:20',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 136518,
    tank_volume_start: 24254.29999999987,
    tank_volume_stop: 24195.19999999987,
  },
  {
    timestamp_start: '2022-01-01T10:59:35',
    timestamp_stop: '2022-01-01T10:59:40',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 136520,
    tank_volume_start: 24195.19999999987,
    tank_volume_stop: 24146.79999999987,
  },
  {
    timestamp_start: '2022-01-01T10:59:55',
    timestamp_stop: '2022-01-01T11:00:00',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 136522,
    tank_volume_start: 24146.79999999987,
    tank_volume_stop: 24090.399999999867,
  },
  {
    timestamp_start: '2022-01-01T11:00:15',
    timestamp_stop: '2022-01-01T11:00:22',
    quantity: 62.2,
    vehicle: 'DEM555',
    id: 136524,
    tank_volume_start: 24090.399999999867,
    tank_volume_stop: 24028.199999999866,
  },
  {
    timestamp_start: '2022-01-01T11:00:37',
    timestamp_stop: '2022-01-01T11:00:42',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 136526,
    tank_volume_start: 24028.199999999866,
    tank_volume_stop: 23975.399999999867,
  },
  {
    timestamp_start: '2022-01-01T11:00:57',
    timestamp_stop: '2022-01-01T11:01:03',
    quantity: 57.3,
    vehicle: 'DEM666',
    id: 136528,
    tank_volume_start: 23975.399999999867,
    tank_volume_stop: 23918.099999999868,
  },
  {
    timestamp_start: '2022-01-01T11:01:18',
    timestamp_stop: '2022-01-01T11:01:24',
    quantity: 59.8,
    vehicle: 'DEM666',
    id: 136530,
    tank_volume_start: 23918.099999999868,
    tank_volume_stop: 23858.29999999987,
  },
  {
    timestamp_start: '2022-01-01T11:01:39',
    timestamp_stop: '2022-01-01T11:01:43',
    quantity: 46.6,
    vehicle: 'DEM888',
    id: 136532,
    tank_volume_start: 23858.29999999987,
    tank_volume_stop: 23811.69999999987,
  },
  {
    timestamp_start: '2022-01-01T11:01:58',
    timestamp_stop: '2022-01-01T11:02:05',
    quantity: 63.8,
    vehicle: 'DEM777',
    id: 136534,
    tank_volume_start: 23811.69999999987,
    tank_volume_stop: 23747.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:02:20',
    timestamp_stop: '2022-01-01T11:02:25',
    quantity: 52.0,
    vehicle: 'DEM333',
    id: 136536,
    tank_volume_start: 23747.89999999987,
    tank_volume_stop: 23695.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:02:40',
    timestamp_stop: '2022-01-01T11:02:45',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 136538,
    tank_volume_start: 23695.89999999987,
    tank_volume_stop: 23641.49999999987,
  },
  {
    timestamp_start: '2022-01-01T11:03:00',
    timestamp_stop: '2022-01-01T11:03:06',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 136540,
    tank_volume_start: 23641.49999999987,
    tank_volume_stop: 23581.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:03:21',
    timestamp_stop: '2022-01-01T11:03:26',
    quantity: 48.9,
    vehicle: 'DEM333',
    id: 136543,
    tank_volume_start: 23581.39999999987,
    tank_volume_stop: 23532.49999999987,
  },
  {
    timestamp_start: '2022-01-01T11:03:41',
    timestamp_stop: '2022-01-01T11:03:46',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 136544,
    tank_volume_start: 23532.49999999987,
    tank_volume_stop: 23480.79999999987,
  },
  {
    timestamp_start: '2022-01-01T11:04:01',
    timestamp_stop: '2022-01-01T11:04:08',
    quantity: 60.8,
    vehicle: 'DEM111',
    id: 136546,
    tank_volume_start: 23480.79999999987,
    tank_volume_stop: 23419.99999999987,
  },
  {
    timestamp_start: '2022-01-01T11:04:23',
    timestamp_stop: '2022-01-01T11:04:28',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 136548,
    tank_volume_start: 23419.99999999987,
    tank_volume_stop: 23363.79999999987,
  },
  {
    timestamp_start: '2022-01-01T11:04:43',
    timestamp_stop: '2022-01-01T11:04:48',
    quantity: 53.5,
    vehicle: 'DEM777',
    id: 136550,
    tank_volume_start: 23363.79999999987,
    tank_volume_stop: 23310.29999999987,
  },
  {
    timestamp_start: '2022-01-01T11:05:03',
    timestamp_stop: '2022-01-01T11:05:10',
    quantity: 62.4,
    vehicle: 'DEM777',
    id: 136552,
    tank_volume_start: 23310.29999999987,
    tank_volume_stop: 23247.899999999867,
  },
  {
    timestamp_start: '2022-01-01T11:05:25',
    timestamp_stop: '2022-01-01T11:05:30',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 136554,
    tank_volume_start: 23247.899999999867,
    tank_volume_stop: 23191.499999999865,
  },
  {
    timestamp_start: '2022-01-01T11:05:45',
    timestamp_stop: '2022-01-01T11:05:50',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 136556,
    tank_volume_start: 23191.499999999865,
    tank_volume_stop: 23145.699999999866,
  },
  {
    timestamp_start: '2022-01-01T11:06:05',
    timestamp_stop: '2022-01-01T11:06:10',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 136558,
    tank_volume_start: 23145.699999999866,
    tank_volume_stop: 23093.699999999866,
  },
  {
    timestamp_start: '2022-01-01T11:06:25',
    timestamp_stop: '2022-01-01T11:06:31',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 136560,
    tank_volume_start: 23093.699999999866,
    tank_volume_stop: 23037.199999999866,
  },
  {
    timestamp_start: '2022-01-01T11:06:46',
    timestamp_stop: '2022-01-01T11:06:52',
    quantity: 59.8,
    vehicle: 'DEM777',
    id: 136562,
    tank_volume_start: 23037.199999999866,
    tank_volume_stop: 22977.399999999867,
  },
  {
    timestamp_start: '2022-01-01T11:07:07',
    timestamp_stop: '2022-01-01T11:07:12',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 136564,
    tank_volume_start: 22977.399999999867,
    tank_volume_stop: 22925.79999999987,
  },
  {
    timestamp_start: '2022-01-01T11:07:27',
    timestamp_stop: '2022-01-01T11:07:33',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 136566,
    tank_volume_start: 22925.79999999987,
    tank_volume_stop: 22866.599999999868,
  },
  {
    timestamp_start: '2022-01-01T11:07:48',
    timestamp_stop: '2022-01-01T11:07:54',
    quantity: 56.6,
    vehicle: 'DEM000',
    id: 136569,
    tank_volume_start: 22866.599999999868,
    tank_volume_stop: 22809.99999999987,
  },
  {
    timestamp_start: '2022-01-01T11:08:09',
    timestamp_stop: '2022-01-01T11:08:15',
    quantity: 60.3,
    vehicle: 'DEM777',
    id: 136571,
    tank_volume_start: 22809.99999999987,
    tank_volume_stop: 22749.69999999987,
  },
  {
    timestamp_start: '2022-01-01T11:08:30',
    timestamp_stop: '2022-01-01T11:08:35',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 136573,
    tank_volume_start: 22749.69999999987,
    tank_volume_stop: 22695.19999999987,
  },
  {
    timestamp_start: '2022-01-01T11:08:50',
    timestamp_stop: '2022-01-01T11:08:56',
    quantity: 59.3,
    vehicle: 'DEM333',
    id: 136575,
    tank_volume_start: 22695.19999999987,
    tank_volume_stop: 22635.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:09:11',
    timestamp_stop: '2022-01-01T11:09:17',
    quantity: 59.1,
    vehicle: 'DEM000',
    id: 136577,
    tank_volume_start: 22635.89999999987,
    tank_volume_stop: 22576.799999999872,
  },
  {
    timestamp_start: '2022-01-01T11:09:32',
    timestamp_stop: '2022-01-01T11:09:37',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 136579,
    tank_volume_start: 22576.799999999872,
    tank_volume_stop: 22521.999999999873,
  },
  {
    timestamp_start: '2022-01-01T11:09:52',
    timestamp_stop: '2022-01-01T11:09:58',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 136581,
    tank_volume_start: 22521.999999999873,
    tank_volume_stop: 22464.299999999872,
  },
  {
    timestamp_start: '2022-01-01T11:10:13',
    timestamp_stop: '2022-01-01T11:10:19',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 136583,
    tank_volume_start: 22464.299999999872,
    tank_volume_stop: 22409.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:10:34',
    timestamp_stop: '2022-01-01T11:10:39',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 136585,
    tank_volume_start: 22409.39999999987,
    tank_volume_stop: 22353.09999999987,
  },
  {
    timestamp_start: '2022-01-01T11:10:54',
    timestamp_stop: '2022-01-01T11:10:59',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 136587,
    tank_volume_start: 22353.09999999987,
    tank_volume_stop: 22302.299999999872,
  },
  {
    timestamp_start: '2022-01-01T11:11:14',
    timestamp_stop: '2022-01-01T11:11:20',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 136589,
    tank_volume_start: 22302.299999999872,
    tank_volume_stop: 22249.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:11:35',
    timestamp_stop: '2022-01-01T11:11:40',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 136591,
    tank_volume_start: 22249.199999999873,
    tank_volume_stop: 22196.899999999874,
  },
  {
    timestamp_start: '2022-01-01T11:11:55',
    timestamp_stop: '2022-01-01T11:12:00',
    quantity: 52.3,
    vehicle: 'DEM888',
    id: 136593,
    tank_volume_start: 22196.899999999874,
    tank_volume_stop: 22144.599999999875,
  },
  {
    timestamp_start: '2022-01-01T11:12:15',
    timestamp_stop: '2022-01-01T11:12:21',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 136595,
    tank_volume_start: 22144.599999999875,
    tank_volume_stop: 22089.699999999873,
  },
  {
    timestamp_start: '2022-01-01T11:12:36',
    timestamp_stop: '2022-01-01T11:12:42',
    quantity: 64.0,
    vehicle: 'DEM555',
    id: 136597,
    tank_volume_start: 22089.699999999873,
    tank_volume_stop: 22025.699999999873,
  },
  {
    timestamp_start: '2022-01-01T11:12:57',
    timestamp_stop: '2022-01-01T11:13:02',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 136599,
    tank_volume_start: 22025.699999999873,
    tank_volume_stop: 21970.699999999873,
  },
  {
    timestamp_start: '2022-01-01T11:13:17',
    timestamp_stop: '2022-01-01T11:13:23',
    quantity: 55.5,
    vehicle: 'DEM666',
    id: 136601,
    tank_volume_start: 21970.699999999873,
    tank_volume_stop: 21915.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:13:38',
    timestamp_stop: '2022-01-01T11:13:44',
    quantity: 60.4,
    vehicle: 'DEM333',
    id: 136603,
    tank_volume_start: 21915.199999999873,
    tank_volume_stop: 21854.799999999872,
  },
  {
    timestamp_start: '2022-01-01T11:13:59',
    timestamp_stop: '2022-01-01T11:14:05',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 136605,
    tank_volume_start: 21854.799999999872,
    tank_volume_stop: 21796.699999999873,
  },
  {
    timestamp_start: '2022-01-01T11:14:20',
    timestamp_stop: '2022-01-01T11:14:26',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 136607,
    tank_volume_start: 21796.699999999873,
    tank_volume_stop: 21739.099999999875,
  },
  {
    timestamp_start: '2022-01-01T11:14:41',
    timestamp_stop: '2022-01-01T11:14:47',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 136609,
    tank_volume_start: 21739.099999999875,
    tank_volume_stop: 21679.599999999875,
  },
  {
    timestamp_start: '2022-01-01T11:15:02',
    timestamp_stop: '2022-01-01T11:15:07',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 136611,
    tank_volume_start: 21679.599999999875,
    tank_volume_stop: 21621.099999999875,
  },
  {
    timestamp_start: '2022-01-01T11:15:22',
    timestamp_stop: '2022-01-01T11:15:28',
    quantity: 59.9,
    vehicle: 'DEM777',
    id: 136613,
    tank_volume_start: 21621.099999999875,
    tank_volume_stop: 21561.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:15:43',
    timestamp_stop: '2022-01-01T11:15:49',
    quantity: 53.4,
    vehicle: 'DEM666',
    id: 136615,
    tank_volume_start: 21561.199999999873,
    tank_volume_stop: 21507.799999999872,
  },
  {
    timestamp_start: '2022-01-01T11:16:04',
    timestamp_stop: '2022-01-01T11:16:10',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 136617,
    tank_volume_start: 21507.799999999872,
    tank_volume_stop: 21449.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:16:25',
    timestamp_stop: '2022-01-01T11:16:29',
    quantity: 47.0,
    vehicle: 'DEM333',
    id: 136619,
    tank_volume_start: 21449.199999999873,
    tank_volume_stop: 21402.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:16:44',
    timestamp_stop: '2022-01-01T11:16:49',
    quantity: 50.1,
    vehicle: 'DEM666',
    id: 136621,
    tank_volume_start: 21402.199999999873,
    tank_volume_stop: 21352.099999999875,
  },
  {
    timestamp_start: '2022-01-01T11:17:04',
    timestamp_stop: '2022-01-01T11:17:09',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 136623,
    tank_volume_start: 21352.099999999875,
    tank_volume_stop: 21300.199999999873,
  },
  {
    timestamp_start: '2022-01-01T11:17:24',
    timestamp_stop: '2022-01-01T11:17:30',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 136625,
    tank_volume_start: 21300.199999999873,
    tank_volume_stop: 21243.999999999873,
  },
  {
    timestamp_start: '2022-01-01T11:17:45',
    timestamp_stop: '2022-01-01T11:17:51',
    quantity: 61.0,
    vehicle: 'DEM777',
    id: 136627,
    tank_volume_start: 21243.999999999873,
    tank_volume_stop: 21182.999999999873,
  },
  {
    timestamp_start: '2022-01-01T11:18:06',
    timestamp_stop: '2022-01-01T11:18:11',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 136629,
    tank_volume_start: 21182.999999999873,
    tank_volume_stop: 21130.299999999872,
  },
  {
    timestamp_start: '2022-01-01T11:18:26',
    timestamp_stop: '2022-01-01T11:18:32',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 136631,
    tank_volume_start: 21130.299999999872,
    tank_volume_stop: 21074.59999999987,
  },
  {
    timestamp_start: '2022-01-01T11:18:47',
    timestamp_stop: '2022-01-01T11:18:52',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 136633,
    tank_volume_start: 21074.59999999987,
    tank_volume_stop: 21022.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:19:07',
    timestamp_stop: '2022-01-01T11:19:13',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 136635,
    tank_volume_start: 21022.89999999987,
    tank_volume_stop: 20966.69999999987,
  },
  {
    timestamp_start: '2022-01-01T11:19:28',
    timestamp_stop: '2022-01-01T11:19:33',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 136637,
    tank_volume_start: 20966.69999999987,
    tank_volume_stop: 20914.49999999987,
  },
  {
    timestamp_start: '2022-01-01T11:19:48',
    timestamp_stop: '2022-01-01T11:19:53',
    quantity: 46.8,
    vehicle: 'DEM666',
    id: 136639,
    tank_volume_start: 20914.49999999987,
    tank_volume_stop: 20867.69999999987,
  },
  {
    timestamp_start: '2022-01-01T11:20:08',
    timestamp_stop: '2022-01-01T11:20:13',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 136641,
    tank_volume_start: 20867.69999999987,
    tank_volume_stop: 20815.69999999987,
  },
  {
    timestamp_start: '2022-01-01T11:20:28',
    timestamp_stop: '2022-01-01T11:20:34',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 136643,
    tank_volume_start: 20815.69999999987,
    tank_volume_stop: 20757.59999999987,
  },
  {
    timestamp_start: '2022-01-01T11:20:49',
    timestamp_stop: '2022-01-01T11:20:53',
    quantity: 45.7,
    vehicle: 'DEM555',
    id: 136645,
    tank_volume_start: 20757.59999999987,
    tank_volume_stop: 20711.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:21:08',
    timestamp_stop: '2022-01-01T11:21:13',
    quantity: 47.8,
    vehicle: 'DEM555',
    id: 136647,
    tank_volume_start: 20711.89999999987,
    tank_volume_stop: 20664.09999999987,
  },
  {
    timestamp_start: '2022-01-01T11:21:28',
    timestamp_stop: '2022-01-01T11:21:33',
    quantity: 49.9,
    vehicle: 'DEM111',
    id: 136649,
    tank_volume_start: 20664.09999999987,
    tank_volume_stop: 20614.19999999987,
  },
  {
    timestamp_start: '2022-01-01T11:21:48',
    timestamp_stop: '2022-01-01T11:21:53',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 136650,
    tank_volume_start: 20614.19999999987,
    tank_volume_stop: 20560.19999999987,
  },
  {
    timestamp_start: '2022-01-01T11:22:08',
    timestamp_stop: '2022-01-01T11:22:13',
    quantity: 46.8,
    vehicle: 'DEM666',
    id: 136652,
    tank_volume_start: 20560.19999999987,
    tank_volume_stop: 20513.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:22:28',
    timestamp_stop: '2022-01-01T11:22:34',
    quantity: 59.9,
    vehicle: 'DEM000',
    id: 136654,
    tank_volume_start: 20513.39999999987,
    tank_volume_stop: 20453.49999999987,
  },
  {
    timestamp_start: '2022-01-01T11:22:49',
    timestamp_stop: '2022-01-01T11:22:54',
    quantity: 47.4,
    vehicle: 'DEM444',
    id: 136656,
    tank_volume_start: 20453.49999999987,
    tank_volume_stop: 20406.099999999868,
  },
  {
    timestamp_start: '2022-01-01T11:23:09',
    timestamp_stop: '2022-01-01T11:23:15',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 136658,
    tank_volume_start: 20406.099999999868,
    tank_volume_stop: 20347.99999999987,
  },
  {
    timestamp_start: '2022-01-01T11:23:30',
    timestamp_stop: '2022-01-01T11:23:35',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 136660,
    tank_volume_start: 20347.99999999987,
    tank_volume_stop: 20295.099999999868,
  },
  {
    timestamp_start: '2022-01-01T11:23:50',
    timestamp_stop: '2022-01-01T11:23:56',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 136662,
    tank_volume_start: 20295.099999999868,
    tank_volume_stop: 20237.599999999868,
  },
  {
    timestamp_start: '2022-01-01T11:24:11',
    timestamp_stop: '2022-01-01T11:24:17',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 136664,
    tank_volume_start: 20237.599999999868,
    tank_volume_stop: 20175.49999999987,
  },
  {
    timestamp_start: '2022-01-01T11:24:32',
    timestamp_stop: '2022-01-01T11:24:37',
    quantity: 48.1,
    vehicle: 'DEM888',
    id: 136666,
    tank_volume_start: 20175.49999999987,
    tank_volume_stop: 20127.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:24:52',
    timestamp_stop: '2022-01-01T11:24:57',
    quantity: 48.0,
    vehicle: 'DEM555',
    id: 136668,
    tank_volume_start: 20127.39999999987,
    tank_volume_stop: 20079.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:25:12',
    timestamp_stop: '2022-01-01T11:25:17',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 136670,
    tank_volume_start: 20079.39999999987,
    tank_volume_stop: 20025.39999999987,
  },
  {
    timestamp_start: '2022-01-01T11:25:32',
    timestamp_stop: '2022-01-01T11:25:37',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 136672,
    tank_volume_start: 20025.39999999987,
    tank_volume_stop: 19972.19999999987,
  },
  {
    timestamp_start: '2022-01-01T11:25:52',
    timestamp_stop: '2022-01-01T11:25:58',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 136674,
    tank_volume_start: 39972.199999999866,
    tank_volume_stop: 39915.09999999987,
  },
  {
    timestamp_start: '2022-01-01T11:26:13',
    timestamp_stop: '2022-01-01T11:26:19',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 136676,
    tank_volume_start: 39915.09999999987,
    tank_volume_stop: 39856.89999999987,
  },
  {
    timestamp_start: '2022-01-01T11:26:34',
    timestamp_stop: '2022-01-01T11:26:39',
    quantity: 49.1,
    vehicle: 'DEM222',
    id: 136678,
    tank_volume_start: 39856.89999999987,
    tank_volume_stop: 39807.79999999987,
  },
  {
    timestamp_start: '2022-01-01T11:26:54',
    timestamp_stop: '2022-01-01T11:26:59',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 136680,
    tank_volume_start: 39807.79999999987,
    tank_volume_stop: 39753.599999999875,
  },
  {
    timestamp_start: '2022-01-01T11:27:14',
    timestamp_stop: '2022-01-01T11:27:19',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 136682,
    tank_volume_start: 39753.599999999875,
    tank_volume_stop: 39701.499999999876,
  },
  {
    timestamp_start: '2022-01-01T11:27:34',
    timestamp_stop: '2022-01-01T11:27:39',
    quantity: 45.1,
    vehicle: 'DEM777',
    id: 136684,
    tank_volume_start: 39701.499999999876,
    tank_volume_stop: 39656.39999999988,
  },
  {
    timestamp_start: '2022-01-01T11:27:54',
    timestamp_stop: '2022-01-01T11:27:59',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 136686,
    tank_volume_start: 39656.39999999988,
    tank_volume_stop: 39607.999999999876,
  },
  {
    timestamp_start: '2022-01-01T11:28:14',
    timestamp_stop: '2022-01-01T11:28:20',
    quantity: 64.2,
    vehicle: 'DEM333',
    id: 136688,
    tank_volume_start: 39607.999999999876,
    tank_volume_stop: 39543.79999999988,
  },
  {
    timestamp_start: '2022-01-01T11:28:35',
    timestamp_stop: '2022-01-01T11:28:40',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 136690,
    tank_volume_start: 39543.79999999988,
    tank_volume_stop: 39497.999999999876,
  },
  {
    timestamp_start: '2022-01-01T11:28:55',
    timestamp_stop: '2022-01-01T11:29:00',
    quantity: 51.6,
    vehicle: 'DEM555',
    id: 136692,
    tank_volume_start: 39497.999999999876,
    tank_volume_stop: 39446.39999999988,
  },
  {
    timestamp_start: '2022-01-01T11:29:15',
    timestamp_stop: '2022-01-01T11:29:20',
    quantity: 48.9,
    vehicle: 'DEM000',
    id: 136694,
    tank_volume_start: 39446.39999999988,
    tank_volume_stop: 39397.499999999876,
  },
  {
    timestamp_start: '2022-01-01T11:29:35',
    timestamp_stop: '2022-01-01T11:29:40',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 136696,
    tank_volume_start: 39397.499999999876,
    tank_volume_stop: 39341.39999999988,
  },
  {
    timestamp_start: '2022-01-01T11:29:55',
    timestamp_stop: '2022-01-01T11:30:01',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 136698,
    tank_volume_start: 39341.39999999988,
    tank_volume_stop: 39286.19999999988,
  },
  {
    timestamp_start: '2022-01-01T11:30:16',
    timestamp_stop: '2022-01-01T11:30:21',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 136700,
    tank_volume_start: 39286.19999999988,
    tank_volume_stop: 39231.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:30:36',
    timestamp_stop: '2022-01-01T11:30:41',
    quantity: 46.9,
    vehicle: 'DEM555',
    id: 136702,
    tank_volume_start: 39231.69999999988,
    tank_volume_stop: 39184.79999999988,
  },
  {
    timestamp_start: '2022-01-01T11:30:56',
    timestamp_stop: '2022-01-01T11:31:01',
    quantity: 45.4,
    vehicle: 'DEM333',
    id: 136704,
    tank_volume_start: 39184.79999999988,
    tank_volume_stop: 39139.39999999988,
  },
  {
    timestamp_start: '2022-01-01T11:31:16',
    timestamp_stop: '2022-01-01T11:31:21',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 136706,
    tank_volume_start: 39139.39999999988,
    tank_volume_stop: 39087.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:31:36',
    timestamp_stop: '2022-01-01T11:31:41',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 136708,
    tank_volume_start: 39087.69999999988,
    tank_volume_stop: 39034.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:31:56',
    timestamp_stop: '2022-01-01T11:32:02',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 136710,
    tank_volume_start: 39034.69999999988,
    tank_volume_stop: 38974.79999999988,
  },
  {
    timestamp_start: '2022-01-01T11:32:17',
    timestamp_stop: '2022-01-01T11:32:23',
    quantity: 57.0,
    vehicle: 'DEM555',
    id: 136712,
    tank_volume_start: 38974.79999999988,
    tank_volume_stop: 38917.79999999988,
  },
  {
    timestamp_start: '2022-01-01T11:32:38',
    timestamp_stop: '2022-01-01T11:32:43',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 136714,
    tank_volume_start: 38917.79999999988,
    tank_volume_stop: 38862.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:32:58',
    timestamp_stop: '2022-01-01T11:33:04',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 136716,
    tank_volume_start: 38862.69999999988,
    tank_volume_stop: 38807.59999999988,
  },
  {
    timestamp_start: '2022-01-01T11:33:19',
    timestamp_stop: '2022-01-01T11:33:25',
    quantity: 61.6,
    vehicle: 'DEM555',
    id: 136718,
    tank_volume_start: 38807.59999999988,
    tank_volume_stop: 38745.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:33:40',
    timestamp_stop: '2022-01-01T11:33:46',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 136720,
    tank_volume_start: 38745.99999999988,
    tank_volume_stop: 38687.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:34:01',
    timestamp_stop: '2022-01-01T11:34:07',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 136722,
    tank_volume_start: 38687.399999999885,
    tank_volume_stop: 38625.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:34:22',
    timestamp_stop: '2022-01-01T11:34:28',
    quantity: 55.4,
    vehicle: 'DEM777',
    id: 136724,
    tank_volume_start: 38625.399999999885,
    tank_volume_stop: 38569.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:34:43',
    timestamp_stop: '2022-01-01T11:34:48',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 136726,
    tank_volume_start: 38569.99999999988,
    tank_volume_stop: 38514.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:35:03',
    timestamp_stop: '2022-01-01T11:35:08',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 136728,
    tank_volume_start: 38514.29999999989,
    tank_volume_stop: 38460.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:35:23',
    timestamp_stop: '2022-01-01T11:35:29',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 136730,
    tank_volume_start: 38460.99999999988,
    tank_volume_stop: 38409.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:35:44',
    timestamp_stop: '2022-01-01T11:35:49',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 136732,
    tank_volume_start: 38409.79999999989,
    tank_volume_stop: 38351.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:36:04',
    timestamp_stop: '2022-01-01T11:36:10',
    quantity: 52.6,
    vehicle: 'DEM444',
    id: 136734,
    tank_volume_start: 38351.29999999989,
    tank_volume_stop: 38298.69999999989,
  },
  {
    timestamp_start: '2022-01-01T11:36:25',
    timestamp_stop: '2022-01-01T11:36:30',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 136736,
    tank_volume_start: 38298.69999999989,
    tank_volume_stop: 38249.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:36:45',
    timestamp_stop: '2022-01-01T11:36:50',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 136738,
    tank_volume_start: 38249.79999999989,
    tank_volume_stop: 38199.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:37:05',
    timestamp_stop: '2022-01-01T11:37:10',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 136740,
    tank_volume_start: 38199.29999999989,
    tank_volume_stop: 38143.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:37:25',
    timestamp_stop: '2022-01-01T11:37:30',
    quantity: 46.3,
    vehicle: 'DEM777',
    id: 136742,
    tank_volume_start: 38143.49999999988,
    tank_volume_stop: 38097.19999999988,
  },
  {
    timestamp_start: '2022-01-01T11:37:45',
    timestamp_stop: '2022-01-01T11:37:50',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 136744,
    tank_volume_start: 38097.19999999988,
    tank_volume_stop: 38044.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:38:05',
    timestamp_stop: '2022-01-01T11:38:10',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 136746,
    tank_volume_start: 38044.69999999988,
    tank_volume_stop: 37990.19999999988,
  },
  {
    timestamp_start: '2022-01-01T11:38:25',
    timestamp_stop: '2022-01-01T11:38:30',
    quantity: 49.5,
    vehicle: 'DEM555',
    id: 136748,
    tank_volume_start: 37990.19999999988,
    tank_volume_stop: 37940.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:38:45',
    timestamp_stop: '2022-01-01T11:38:51',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 136750,
    tank_volume_start: 37940.69999999988,
    tank_volume_stop: 37882.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:39:06',
    timestamp_stop: '2022-01-01T11:39:11',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 136752,
    tank_volume_start: 37882.49999999988,
    tank_volume_stop: 37833.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:39:26',
    timestamp_stop: '2022-01-01T11:39:31',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 136754,
    tank_volume_start: 37833.69999999988,
    tank_volume_stop: 37786.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:39:46',
    timestamp_stop: '2022-01-01T11:39:51',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 136756,
    tank_volume_start: 37786.09999999988,
    tank_volume_stop: 37731.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:40:06',
    timestamp_stop: '2022-01-01T11:40:12',
    quantity: 59.0,
    vehicle: 'DEM777',
    id: 136758,
    tank_volume_start: 37731.399999999885,
    tank_volume_stop: 37672.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:40:27',
    timestamp_stop: '2022-01-01T11:40:32',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 136760,
    tank_volume_start: 37672.399999999885,
    tank_volume_stop: 37624.69999999989,
  },
  {
    timestamp_start: '2022-01-01T11:40:47',
    timestamp_stop: '2022-01-01T11:40:52',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 136762,
    tank_volume_start: 37624.69999999989,
    tank_volume_stop: 37571.899999999885,
  },
  {
    timestamp_start: '2022-01-01T11:41:07',
    timestamp_stop: '2022-01-01T11:41:13',
    quantity: 52.8,
    vehicle: 'DEM111',
    id: 136764,
    tank_volume_start: 37571.899999999885,
    tank_volume_stop: 37519.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:41:28',
    timestamp_stop: '2022-01-01T11:41:33',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 136766,
    tank_volume_start: 37519.09999999988,
    tank_volume_stop: 37468.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:41:48',
    timestamp_stop: '2022-01-01T11:41:53',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 136768,
    tank_volume_start: 37468.399999999885,
    tank_volume_stop: 37417.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:42:08',
    timestamp_stop: '2022-01-01T11:42:13',
    quantity: 47.5,
    vehicle: 'DEM444',
    id: 136770,
    tank_volume_start: 37417.49999999988,
    tank_volume_stop: 37369.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:42:28',
    timestamp_stop: '2022-01-01T11:42:33',
    quantity: 51.5,
    vehicle: 'DEM777',
    id: 136772,
    tank_volume_start: 37369.99999999988,
    tank_volume_stop: 37318.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:42:48',
    timestamp_stop: '2022-01-01T11:42:54',
    quantity: 64.1,
    vehicle: 'DEM555',
    id: 136774,
    tank_volume_start: 37318.49999999988,
    tank_volume_stop: 37254.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:43:09',
    timestamp_stop: '2022-01-01T11:43:15',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 136776,
    tank_volume_start: 37254.399999999885,
    tank_volume_stop: 37197.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:43:30',
    timestamp_stop: '2022-01-01T11:43:35',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 136778,
    tank_volume_start: 37197.09999999988,
    tank_volume_stop: 37145.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:43:50',
    timestamp_stop: '2022-01-01T11:43:56',
    quantity: 59.9,
    vehicle: 'DEM777',
    id: 136780,
    tank_volume_start: 37145.99999999988,
    tank_volume_stop: 37086.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:44:11',
    timestamp_stop: '2022-01-01T11:44:17',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 136782,
    tank_volume_start: 37086.09999999988,
    tank_volume_stop: 37028.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:44:32',
    timestamp_stop: '2022-01-01T11:44:38',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 136784,
    tank_volume_start: 37028.09999999988,
    tank_volume_stop: 36967.399999999885,
  },
  {
    timestamp_start: '2022-01-01T11:44:53',
    timestamp_stop: '2022-01-01T11:44:58',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 136786,
    tank_volume_start: 36967.399999999885,
    tank_volume_stop: 36912.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:45:13',
    timestamp_stop: '2022-01-01T11:45:19',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 136788,
    tank_volume_start: 36912.49999999988,
    tank_volume_stop: 36853.59999999988,
  },
  {
    timestamp_start: '2022-01-01T11:45:34',
    timestamp_stop: '2022-01-01T11:45:40',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 136790,
    tank_volume_start: 36853.59999999988,
    tank_volume_stop: 36792.59999999988,
  },
  {
    timestamp_start: '2022-01-01T11:45:55',
    timestamp_stop: '2022-01-01T11:46:00',
    quantity: 48.6,
    vehicle: 'DEM000',
    id: 136792,
    tank_volume_start: 36792.59999999988,
    tank_volume_stop: 36743.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:46:15',
    timestamp_stop: '2022-01-01T11:46:20',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 136794,
    tank_volume_start: 36743.99999999988,
    tank_volume_stop: 36691.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:46:35',
    timestamp_stop: '2022-01-01T11:46:41',
    quantity: 60.0,
    vehicle: 'DEM222',
    id: 136796,
    tank_volume_start: 36691.09999999988,
    tank_volume_stop: 36631.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:46:56',
    timestamp_stop: '2022-01-01T11:47:02',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 136798,
    tank_volume_start: 36631.09999999988,
    tank_volume_stop: 36579.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:47:17',
    timestamp_stop: '2022-01-01T11:47:21',
    quantity: 47.6,
    vehicle: 'DEM000',
    id: 136800,
    tank_volume_start: 36579.09999999988,
    tank_volume_stop: 36531.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:47:36',
    timestamp_stop: '2022-01-01T11:47:41',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 136802,
    tank_volume_start: 36531.49999999988,
    tank_volume_stop: 36480.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:47:56',
    timestamp_stop: '2022-01-01T11:48:02',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 136804,
    tank_volume_start: 36480.29999999989,
    tank_volume_stop: 36423.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:48:17',
    timestamp_stop: '2022-01-01T11:48:23',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 136806,
    tank_volume_start: 36423.59999999989,
    tank_volume_stop: 36369.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:48:38',
    timestamp_stop: '2022-01-01T11:48:43',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 136808,
    tank_volume_start: 36369.59999999989,
    tank_volume_stop: 36314.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:48:58',
    timestamp_stop: '2022-01-01T11:49:03',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 136810,
    tank_volume_start: 36314.29999999989,
    tank_volume_stop: 36261.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:49:18',
    timestamp_stop: '2022-01-01T11:49:24',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 136812,
    tank_volume_start: 36261.59999999989,
    tank_volume_stop: 36205.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:49:39',
    timestamp_stop: '2022-01-01T11:49:45',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 136814,
    tank_volume_start: 36205.59999999989,
    tank_volume_stop: 36146.49999999989,
  },
  {
    timestamp_start: '2022-01-01T11:50:00',
    timestamp_stop: '2022-01-01T11:50:05',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 136816,
    tank_volume_start: 36146.49999999989,
    tank_volume_stop: 36093.99999999989,
  },
  {
    timestamp_start: '2022-01-01T11:50:20',
    timestamp_stop: '2022-01-01T11:50:26',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 136818,
    tank_volume_start: 36093.99999999989,
    tank_volume_stop: 36035.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:50:41',
    timestamp_stop: '2022-01-01T11:50:46',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 136820,
    tank_volume_start: 36035.59999999989,
    tank_volume_stop: 35985.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:51:01',
    timestamp_stop: '2022-01-01T11:51:06',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 136822,
    tank_volume_start: 35985.29999999989,
    tank_volume_stop: 35931.899999999885,
  },
  {
    timestamp_start: '2022-01-01T11:51:21',
    timestamp_stop: '2022-01-01T11:51:26',
    quantity: 46.3,
    vehicle: 'DEM888',
    id: 136824,
    tank_volume_start: 35931.899999999885,
    tank_volume_stop: 35885.59999999988,
  },
  {
    timestamp_start: '2022-01-01T11:51:41',
    timestamp_stop: '2022-01-01T11:51:46',
    quantity: 46.9,
    vehicle: 'DEM888',
    id: 136826,
    tank_volume_start: 35885.59999999988,
    tank_volume_stop: 35838.69999999988,
  },
  {
    timestamp_start: '2022-01-01T11:52:01',
    timestamp_stop: '2022-01-01T11:52:07',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 136828,
    tank_volume_start: 35838.69999999988,
    tank_volume_stop: 35779.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:52:22',
    timestamp_stop: '2022-01-01T11:52:27',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 136830,
    tank_volume_start: 35779.99999999988,
    tank_volume_stop: 35725.59999999988,
  },
  {
    timestamp_start: '2022-01-01T11:52:42',
    timestamp_stop: '2022-01-01T11:52:48',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 136832,
    tank_volume_start: 35725.59999999988,
    tank_volume_stop: 35665.99999999988,
  },
  {
    timestamp_start: '2022-01-01T11:53:03',
    timestamp_stop: '2022-01-01T11:53:08',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 136834,
    tank_volume_start: 35665.99999999988,
    tank_volume_stop: 35611.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:53:23',
    timestamp_stop: '2022-01-01T11:53:29',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 136836,
    tank_volume_start: 35611.79999999989,
    tank_volume_stop: 35554.19999999989,
  },
  {
    timestamp_start: '2022-01-01T11:53:44',
    timestamp_stop: '2022-01-01T11:53:50',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 136838,
    tank_volume_start: 35554.19999999989,
    tank_volume_stop: 35496.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:54:05',
    timestamp_stop: '2022-01-01T11:54:11',
    quantity: 62.9,
    vehicle: 'DEM333',
    id: 136840,
    tank_volume_start: 35496.79999999989,
    tank_volume_stop: 35433.899999999885,
  },
  {
    timestamp_start: '2022-01-01T11:54:26',
    timestamp_stop: '2022-01-01T11:54:31',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 136842,
    tank_volume_start: 35433.899999999885,
    tank_volume_stop: 35383.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:54:46',
    timestamp_stop: '2022-01-01T11:54:51',
    quantity: 47.0,
    vehicle: 'DEM666',
    id: 136844,
    tank_volume_start: 35383.79999999989,
    tank_volume_stop: 35336.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:55:06',
    timestamp_stop: '2022-01-01T11:55:11',
    quantity: 55.2,
    vehicle: 'DEM111',
    id: 136846,
    tank_volume_start: 35336.79999999989,
    tank_volume_stop: 35281.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:55:26',
    timestamp_stop: '2022-01-01T11:55:31',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 136848,
    tank_volume_start: 35281.59999999989,
    tank_volume_stop: 35230.89999999989,
  },
  {
    timestamp_start: '2022-01-01T11:55:46',
    timestamp_stop: '2022-01-01T11:55:53',
    quantity: 63.8,
    vehicle: 'DEM777',
    id: 136850,
    tank_volume_start: 35230.89999999989,
    tank_volume_stop: 35167.09999999989,
  },
  {
    timestamp_start: '2022-01-01T11:56:08',
    timestamp_stop: '2022-01-01T11:56:14',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 136852,
    tank_volume_start: 35167.09999999989,
    tank_volume_stop: 35104.19999999989,
  },
  {
    timestamp_start: '2022-01-01T11:56:29',
    timestamp_stop: '2022-01-01T11:56:34',
    quantity: 48.1,
    vehicle: 'DEM555',
    id: 136854,
    tank_volume_start: 35104.19999999989,
    tank_volume_stop: 35056.09999999989,
  },
  {
    timestamp_start: '2022-01-01T11:56:49',
    timestamp_stop: '2022-01-01T11:56:54',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 136856,
    tank_volume_start: 35056.09999999989,
    tank_volume_stop: 35002.79999999989,
  },
  {
    timestamp_start: '2022-01-01T11:57:09',
    timestamp_stop: '2022-01-01T11:57:15',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 136858,
    tank_volume_start: 35002.79999999989,
    tank_volume_stop: 34945.09999999989,
  },
  {
    timestamp_start: '2022-01-01T11:57:30',
    timestamp_stop: '2022-01-01T11:57:36',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 136860,
    tank_volume_start: 34945.09999999989,
    tank_volume_stop: 34889.19999999989,
  },
  {
    timestamp_start: '2022-01-01T11:57:51',
    timestamp_stop: '2022-01-01T11:57:55',
    quantity: 48.6,
    vehicle: 'DEM111',
    id: 136862,
    tank_volume_start: 34889.19999999989,
    tank_volume_stop: 34840.59999999989,
  },
  {
    timestamp_start: '2022-01-01T11:58:10',
    timestamp_stop: '2022-01-01T11:58:15',
    quantity: 49.3,
    vehicle: 'DEM888',
    id: 136864,
    tank_volume_start: 34840.59999999989,
    tank_volume_stop: 34791.29999999989,
  },
  {
    timestamp_start: '2022-01-01T11:58:30',
    timestamp_stop: '2022-01-01T11:58:36',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 136866,
    tank_volume_start: 34791.29999999989,
    tank_volume_stop: 34734.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:58:51',
    timestamp_stop: '2022-01-01T11:58:56',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 136868,
    tank_volume_start: 34734.49999999988,
    tank_volume_stop: 34683.19999999988,
  },
  {
    timestamp_start: '2022-01-01T11:59:11',
    timestamp_stop: '2022-01-01T11:59:17',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 136870,
    tank_volume_start: 34683.19999999988,
    tank_volume_stop: 34629.09999999988,
  },
  {
    timestamp_start: '2022-01-01T11:59:32',
    timestamp_stop: '2022-01-01T11:59:37',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 136872,
    tank_volume_start: 34629.09999999988,
    tank_volume_stop: 34576.49999999988,
  },
  {
    timestamp_start: '2022-01-01T11:59:52',
    timestamp_stop: '2022-01-01T11:59:57',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 136874,
    tank_volume_start: 34576.49999999988,
    tank_volume_stop: 34521.899999999885,
  },
  {
    timestamp_start: '2022-01-01T12:00:12',
    timestamp_stop: '2022-01-01T12:00:18',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 136876,
    tank_volume_start: 34521.899999999885,
    tank_volume_stop: 34465.99999999988,
  },
  {
    timestamp_start: '2022-01-01T12:00:33',
    timestamp_stop: '2022-01-01T12:00:38',
    quantity: 46.3,
    vehicle: 'DEM888',
    id: 136878,
    tank_volume_start: 34465.99999999988,
    tank_volume_stop: 34419.69999999988,
  },
  {
    timestamp_start: '2022-01-01T12:00:53',
    timestamp_stop: '2022-01-01T12:00:58',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 136880,
    tank_volume_start: 34419.69999999988,
    tank_volume_stop: 34362.79999999988,
  },
  {
    timestamp_start: '2022-01-01T12:01:13',
    timestamp_stop: '2022-01-01T12:01:19',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 136882,
    tank_volume_start: 34362.79999999988,
    tank_volume_stop: 34306.29999999988,
  },
  {
    timestamp_start: '2022-01-01T12:01:34',
    timestamp_stop: '2022-01-01T12:01:39',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 136884,
    tank_volume_start: 34306.29999999988,
    tank_volume_stop: 34253.89999999988,
  },
  {
    timestamp_start: '2022-01-01T12:01:54',
    timestamp_stop: '2022-01-01T12:01:59',
    quantity: 49.4,
    vehicle: 'DEM555',
    id: 136886,
    tank_volume_start: 34253.89999999988,
    tank_volume_stop: 34204.499999999876,
  },
  {
    timestamp_start: '2022-01-01T12:02:14',
    timestamp_stop: '2022-01-01T12:02:20',
    quantity: 60.5,
    vehicle: 'DEM888',
    id: 136888,
    tank_volume_start: 34204.499999999876,
    tank_volume_stop: 34143.999999999876,
  },
  {
    timestamp_start: '2022-01-01T12:02:35',
    timestamp_stop: '2022-01-01T12:02:40',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 136890,
    tank_volume_start: 34143.999999999876,
    tank_volume_stop: 34090.79999999988,
  },
  {
    timestamp_start: '2022-01-01T12:02:55',
    timestamp_stop: '2022-01-01T12:03:01',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 136892,
    tank_volume_start: 34090.79999999988,
    tank_volume_stop: 34034.89999999988,
  },
  {
    timestamp_start: '2022-01-01T12:03:16',
    timestamp_stop: '2022-01-01T12:03:22',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 136894,
    tank_volume_start: 34034.89999999988,
    tank_volume_stop: 33978.599999999875,
  },
  {
    timestamp_start: '2022-01-01T12:03:37',
    timestamp_stop: '2022-01-01T12:03:42',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 136896,
    tank_volume_start: 33978.599999999875,
    tank_volume_stop: 33928.29999999987,
  },
  {
    timestamp_start: '2022-01-01T12:03:57',
    timestamp_stop: '2022-01-01T12:04:02',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 136898,
    tank_volume_start: 33928.29999999987,
    tank_volume_stop: 33877.599999999875,
  },
  {
    timestamp_start: '2022-01-01T12:04:17',
    timestamp_stop: '2022-01-01T12:04:22',
    quantity: 52.1,
    vehicle: 'DEM777',
    id: 136900,
    tank_volume_start: 33877.599999999875,
    tank_volume_stop: 33825.499999999876,
  },
  {
    timestamp_start: '2022-01-01T12:04:37',
    timestamp_stop: '2022-01-01T12:04:42',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 136902,
    tank_volume_start: 33825.499999999876,
    tank_volume_stop: 33773.89999999988,
  },
  {
    timestamp_start: '2022-01-01T12:04:57',
    timestamp_stop: '2022-01-01T12:05:02',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 136904,
    tank_volume_start: 33773.89999999988,
    tank_volume_stop: 33722.79999999988,
  },
  {
    timestamp_start: '2022-01-01T12:05:17',
    timestamp_stop: '2022-01-01T12:05:22',
    quantity: 48.4,
    vehicle: 'DEM222',
    id: 136906,
    tank_volume_start: 33722.79999999988,
    tank_volume_stop: 33674.39999999988,
  },
  {
    timestamp_start: '2022-01-01T12:05:37',
    timestamp_stop: '2022-01-01T12:05:43',
    quantity: 64.3,
    vehicle: 'DEM333',
    id: 136908,
    tank_volume_start: 33674.39999999988,
    tank_volume_stop: 33610.099999999875,
  },
  {
    timestamp_start: '2022-01-01T12:05:58',
    timestamp_stop: '2022-01-01T12:06:04',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 136910,
    tank_volume_start: 33610.099999999875,
    tank_volume_stop: 33557.099999999875,
  },
  {
    timestamp_start: '2022-01-01T12:06:19',
    timestamp_stop: '2022-01-01T12:06:24',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 136912,
    tank_volume_start: 33557.099999999875,
    tank_volume_stop: 33504.39999999988,
  },
  {
    timestamp_start: '2022-01-01T12:06:39',
    timestamp_stop: '2022-01-01T12:06:44',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 136914,
    tank_volume_start: 33504.39999999988,
    tank_volume_stop: 33452.79999999988,
  },
  {
    timestamp_start: '2022-01-01T12:06:59',
    timestamp_stop: '2022-01-01T12:07:05',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 136916,
    tank_volume_start: 33452.79999999988,
    tank_volume_stop: 33394.59999999988,
  },
  {
    timestamp_start: '2022-01-01T12:07:20',
    timestamp_stop: '2022-01-01T12:07:26',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 136918,
    tank_volume_start: 33394.59999999988,
    tank_volume_stop: 33335.79999999988,
  },
  {
    timestamp_start: '2022-01-01T12:07:41',
    timestamp_stop: '2022-01-01T12:07:46',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 136920,
    tank_volume_start: 33335.79999999988,
    tank_volume_stop: 33282.19999999988,
  },
  {
    timestamp_start: '2022-01-01T12:08:01',
    timestamp_stop: '2022-01-01T12:08:07',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 136922,
    tank_volume_start: 33282.19999999988,
    tank_volume_stop: 33229.99999999988,
  },
  {
    timestamp_start: '2022-01-01T12:08:22',
    timestamp_stop: '2022-01-01T12:08:27',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 136924,
    tank_volume_start: 33229.99999999988,
    tank_volume_stop: 33170.399999999885,
  },
  {
    timestamp_start: '2022-01-01T12:08:42',
    timestamp_stop: '2022-01-01T12:08:48',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 136926,
    tank_volume_start: 33170.399999999885,
    tank_volume_stop: 33112.79999999989,
  },
  {
    timestamp_start: '2022-01-01T12:09:03',
    timestamp_stop: '2022-01-01T12:09:08',
    quantity: 49.5,
    vehicle: 'DEM333',
    id: 136928,
    tank_volume_start: 33112.79999999989,
    tank_volume_stop: 33063.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:09:23',
    timestamp_stop: '2022-01-01T12:09:29',
    quantity: 58.6,
    vehicle: 'DEM000',
    id: 136930,
    tank_volume_start: 33063.29999999989,
    tank_volume_stop: 33004.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:09:44',
    timestamp_stop: '2022-01-01T12:09:50',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 136932,
    tank_volume_start: 33004.69999999989,
    tank_volume_stop: 32948.99999999989,
  },
  {
    timestamp_start: '2022-01-01T12:10:05',
    timestamp_stop: '2022-01-01T12:10:10',
    quantity: 49.8,
    vehicle: 'DEM444',
    id: 136934,
    tank_volume_start: 32948.99999999989,
    tank_volume_stop: 32899.19999999989,
  },
  {
    timestamp_start: '2022-01-01T12:10:25',
    timestamp_stop: '2022-01-01T12:10:30',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 136936,
    tank_volume_start: 32899.19999999989,
    tank_volume_stop: 32846.09999999989,
  },
  {
    timestamp_start: '2022-01-01T12:10:45',
    timestamp_stop: '2022-01-01T12:10:51',
    quantity: 59.7,
    vehicle: 'DEM222',
    id: 136938,
    tank_volume_start: 32846.09999999989,
    tank_volume_stop: 32786.39999999989,
  },
  {
    timestamp_start: '2022-01-01T12:11:06',
    timestamp_stop: '2022-01-01T12:11:11',
    quantity: 47.6,
    vehicle: 'DEM000',
    id: 136940,
    tank_volume_start: 32786.39999999989,
    tank_volume_stop: 32738.799999999894,
  },
  {
    timestamp_start: '2022-01-01T12:11:26',
    timestamp_stop: '2022-01-01T12:11:32',
    quantity: 63.3,
    vehicle: 'DEM111',
    id: 136942,
    tank_volume_start: 32738.799999999894,
    tank_volume_stop: 32675.499999999894,
  },
  {
    timestamp_start: '2022-01-01T12:11:47',
    timestamp_stop: '2022-01-01T12:11:53',
    quantity: 58.6,
    vehicle: 'DEM000',
    id: 136944,
    tank_volume_start: 32675.499999999894,
    tank_volume_stop: 32616.899999999896,
  },
  {
    timestamp_start: '2022-01-01T12:12:08',
    timestamp_stop: '2022-01-01T12:12:13',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 136946,
    tank_volume_start: 32616.899999999896,
    tank_volume_stop: 32563.699999999895,
  },
  {
    timestamp_start: '2022-01-01T12:12:28',
    timestamp_stop: '2022-01-01T12:12:33',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 136948,
    tank_volume_start: 32563.699999999895,
    tank_volume_stop: 32512.399999999896,
  },
  {
    timestamp_start: '2022-01-01T12:12:48',
    timestamp_stop: '2022-01-01T12:12:55',
    quantity: 62.6,
    vehicle: 'DEM555',
    id: 136950,
    tank_volume_start: 32512.399999999896,
    tank_volume_stop: 32449.799999999897,
  },
  {
    timestamp_start: '2022-01-01T12:13:10',
    timestamp_stop: '2022-01-01T12:13:15',
    quantity: 50.7,
    vehicle: 'DEM666',
    id: 136952,
    tank_volume_start: 32449.799999999897,
    tank_volume_stop: 32399.099999999897,
  },
  {
    timestamp_start: '2022-01-01T12:13:30',
    timestamp_stop: '2022-01-01T12:13:34',
    quantity: 45.9,
    vehicle: 'DEM222',
    id: 136954,
    tank_volume_start: 32399.099999999897,
    tank_volume_stop: 32353.199999999895,
  },
  {
    timestamp_start: '2022-01-01T12:13:49',
    timestamp_stop: '2022-01-01T12:13:55',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 136956,
    tank_volume_start: 32353.199999999895,
    tank_volume_stop: 32298.499999999894,
  },
  {
    timestamp_start: '2022-01-01T12:14:10',
    timestamp_stop: '2022-01-01T12:14:15',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 136958,
    tank_volume_start: 32298.499999999894,
    tank_volume_stop: 32243.699999999895,
  },
  {
    timestamp_start: '2022-01-01T12:14:30',
    timestamp_stop: '2022-01-01T12:14:35',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 136960,
    tank_volume_start: 32243.699999999895,
    tank_volume_stop: 32191.999999999894,
  },
  {
    timestamp_start: '2022-01-01T12:14:50',
    timestamp_stop: '2022-01-01T12:14:55',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 136962,
    tank_volume_start: 32191.999999999894,
    tank_volume_stop: 32140.099999999893,
  },
  {
    timestamp_start: '2022-01-01T12:15:10',
    timestamp_stop: '2022-01-01T12:15:16',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 136964,
    tank_volume_start: 32140.099999999893,
    tank_volume_stop: 32088.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:15:31',
    timestamp_stop: '2022-01-01T12:15:36',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 136966,
    tank_volume_start: 32088.299999999894,
    tank_volume_stop: 32035.199999999895,
  },
  {
    timestamp_start: '2022-01-01T12:15:51',
    timestamp_stop: '2022-01-01T12:15:57',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 136968,
    tank_volume_start: 32035.199999999895,
    tank_volume_stop: 31979.699999999895,
  },
  {
    timestamp_start: '2022-01-01T12:16:12',
    timestamp_stop: '2022-01-01T12:16:17',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 136970,
    tank_volume_start: 31979.699999999895,
    tank_volume_stop: 31926.499999999894,
  },
  {
    timestamp_start: '2022-01-01T12:16:32',
    timestamp_stop: '2022-01-01T12:16:37',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 136972,
    tank_volume_start: 31926.499999999894,
    tank_volume_stop: 31875.799999999894,
  },
  {
    timestamp_start: '2022-01-01T12:16:52',
    timestamp_stop: '2022-01-01T12:16:57',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 136974,
    tank_volume_start: 31875.799999999894,
    tank_volume_stop: 31825.099999999893,
  },
  {
    timestamp_start: '2022-01-01T12:17:12',
    timestamp_stop: '2022-01-01T12:17:18',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 136976,
    tank_volume_start: 31825.099999999893,
    tank_volume_stop: 31766.399999999892,
  },
  {
    timestamp_start: '2022-01-01T12:17:33',
    timestamp_stop: '2022-01-01T12:17:38',
    quantity: 49.2,
    vehicle: 'DEM888',
    id: 136978,
    tank_volume_start: 31766.399999999892,
    tank_volume_stop: 31717.19999999989,
  },
  {
    timestamp_start: '2022-01-01T12:17:53',
    timestamp_stop: '2022-01-01T12:17:58',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 136980,
    tank_volume_start: 31717.19999999989,
    tank_volume_stop: 31660.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:18:13',
    timestamp_stop: '2022-01-01T12:18:20',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 136982,
    tank_volume_start: 31660.899999999892,
    tank_volume_stop: 31599.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:18:35',
    timestamp_stop: '2022-01-01T12:18:40',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 136984,
    tank_volume_start: 31599.899999999892,
    tank_volume_stop: 31545.19999999989,
  },
  {
    timestamp_start: '2022-01-01T12:18:55',
    timestamp_stop: '2022-01-01T12:19:00',
    quantity: 48.9,
    vehicle: 'DEM666',
    id: 136986,
    tank_volume_start: 31545.19999999989,
    tank_volume_stop: 31496.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:19:15',
    timestamp_stop: '2022-01-01T12:19:20',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 136988,
    tank_volume_start: 31496.29999999989,
    tank_volume_stop: 31442.59999999989,
  },
  {
    timestamp_start: '2022-01-01T12:19:35',
    timestamp_stop: '2022-01-01T12:19:41',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 136990,
    tank_volume_start: 31442.59999999989,
    tank_volume_stop: 31386.89999999989,
  },
  {
    timestamp_start: '2022-01-01T12:19:56',
    timestamp_stop: '2022-01-01T12:20:01',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 136992,
    tank_volume_start: 31386.89999999989,
    tank_volume_stop: 31330.999999999887,
  },
  {
    timestamp_start: '2022-01-01T12:20:16',
    timestamp_stop: '2022-01-01T12:20:23',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 136994,
    tank_volume_start: 31330.999999999887,
    tank_volume_stop: 31269.299999999886,
  },
  {
    timestamp_start: '2022-01-01T12:20:38',
    timestamp_stop: '2022-01-01T12:20:43',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 136996,
    tank_volume_start: 31269.299999999886,
    tank_volume_stop: 31212.299999999886,
  },
  {
    timestamp_start: '2022-01-01T12:20:58',
    timestamp_stop: '2022-01-01T12:21:04',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 136998,
    tank_volume_start: 31212.299999999886,
    tank_volume_stop: 31159.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:21:19',
    timestamp_stop: '2022-01-01T12:21:24',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 137000,
    tank_volume_start: 31159.499999999887,
    tank_volume_stop: 31104.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:21:39',
    timestamp_stop: '2022-01-01T12:21:45',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 137002,
    tank_volume_start: 31104.499999999887,
    tank_volume_stop: 31049.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:22:00',
    timestamp_stop: '2022-01-01T12:22:05',
    quantity: 49.8,
    vehicle: 'DEM444',
    id: 137003,
    tank_volume_start: 31049.499999999887,
    tank_volume_stop: 30999.699999999888,
  },
  {
    timestamp_start: '2022-01-01T12:22:20',
    timestamp_stop: '2022-01-01T12:22:25',
    quantity: 49.7,
    vehicle: 'DEM333',
    id: 137005,
    tank_volume_start: 30999.699999999888,
    tank_volume_stop: 30949.999999999887,
  },
  {
    timestamp_start: '2022-01-01T12:22:40',
    timestamp_stop: '2022-01-01T12:22:45',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 137007,
    tank_volume_start: 30949.999999999887,
    tank_volume_stop: 30898.099999999886,
  },
  {
    timestamp_start: '2022-01-01T12:23:00',
    timestamp_stop: '2022-01-01T12:23:06',
    quantity: 62.3,
    vehicle: 'DEM888',
    id: 137009,
    tank_volume_start: 30898.099999999886,
    tank_volume_stop: 30835.799999999886,
  },
  {
    timestamp_start: '2022-01-01T12:23:21',
    timestamp_stop: '2022-01-01T12:23:27',
    quantity: 59.1,
    vehicle: 'DEM111',
    id: 137011,
    tank_volume_start: 30835.799999999886,
    tank_volume_stop: 30776.699999999888,
  },
  {
    timestamp_start: '2022-01-01T12:23:42',
    timestamp_stop: '2022-01-01T12:23:47',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 137013,
    tank_volume_start: 30776.699999999888,
    tank_volume_stop: 30720.999999999887,
  },
  {
    timestamp_start: '2022-01-01T12:24:02',
    timestamp_stop: '2022-01-01T12:24:08',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 137015,
    tank_volume_start: 30720.999999999887,
    tank_volume_stop: 30663.799999999886,
  },
  {
    timestamp_start: '2022-01-01T12:24:23',
    timestamp_stop: '2022-01-01T12:24:29',
    quantity: 63.2,
    vehicle: 'DEM888',
    id: 137017,
    tank_volume_start: 30663.799999999886,
    tank_volume_stop: 30600.599999999886,
  },
  {
    timestamp_start: '2022-01-01T12:24:44',
    timestamp_stop: '2022-01-01T12:24:51',
    quantity: 64.4,
    vehicle: 'DEM555',
    id: 137020,
    tank_volume_start: 30600.599999999886,
    tank_volume_stop: 30536.199999999884,
  },
  {
    timestamp_start: '2022-01-01T12:25:06',
    timestamp_stop: '2022-01-01T12:25:11',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 137022,
    tank_volume_start: 30536.199999999884,
    tank_volume_stop: 30481.299999999883,
  },
  {
    timestamp_start: '2022-01-01T12:25:26',
    timestamp_stop: '2022-01-01T12:25:32',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 137024,
    tank_volume_start: 30481.299999999883,
    tank_volume_stop: 30429.299999999883,
  },
  {
    timestamp_start: '2022-01-01T12:25:47',
    timestamp_stop: '2022-01-01T12:25:52',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 137026,
    tank_volume_start: 30429.299999999883,
    tank_volume_stop: 30375.599999999882,
  },
  {
    timestamp_start: '2022-01-01T12:26:07',
    timestamp_stop: '2022-01-01T12:26:13',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 137028,
    tank_volume_start: 30375.599999999882,
    tank_volume_stop: 30317.099999999882,
  },
  {
    timestamp_start: '2022-01-01T12:26:28',
    timestamp_stop: '2022-01-01T12:26:33',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 137030,
    tank_volume_start: 30317.099999999882,
    tank_volume_stop: 30262.999999999884,
  },
  {
    timestamp_start: '2022-01-01T12:26:48',
    timestamp_stop: '2022-01-01T12:26:54',
    quantity: 60.7,
    vehicle: 'DEM666',
    id: 137032,
    tank_volume_start: 30262.999999999884,
    tank_volume_stop: 30202.299999999883,
  },
  {
    timestamp_start: '2022-01-01T12:27:09',
    timestamp_stop: '2022-01-01T12:27:14',
    quantity: 46.8,
    vehicle: 'DEM222',
    id: 137034,
    tank_volume_start: 30202.299999999883,
    tank_volume_stop: 30155.499999999884,
  },
  {
    timestamp_start: '2022-01-01T12:27:29',
    timestamp_stop: '2022-01-01T12:27:34',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 137036,
    tank_volume_start: 30155.499999999884,
    tank_volume_stop: 30106.999999999884,
  },
  {
    timestamp_start: '2022-01-01T12:27:49',
    timestamp_stop: '2022-01-01T12:27:55',
    quantity: 63.6,
    vehicle: 'DEM888',
    id: 137038,
    tank_volume_start: 30106.999999999884,
    tank_volume_stop: 30043.399999999885,
  },
  {
    timestamp_start: '2022-01-01T12:28:10',
    timestamp_stop: '2022-01-01T12:28:15',
    quantity: 52.2,
    vehicle: 'DEM111',
    id: 137040,
    tank_volume_start: 30043.399999999885,
    tank_volume_stop: 29991.199999999884,
  },
  {
    timestamp_start: '2022-01-01T12:28:30',
    timestamp_stop: '2022-01-01T12:28:35',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 137042,
    tank_volume_start: 29991.199999999884,
    tank_volume_stop: 29940.799999999883,
  },
  {
    timestamp_start: '2022-01-01T12:28:50',
    timestamp_stop: '2022-01-01T12:28:55',
    quantity: 50.1,
    vehicle: 'DEM444',
    id: 137044,
    tank_volume_start: 29940.799999999883,
    tank_volume_stop: 29890.699999999884,
  },
  {
    timestamp_start: '2022-01-01T12:29:10',
    timestamp_stop: '2022-01-01T12:29:16',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 137045,
    tank_volume_start: 29890.699999999884,
    tank_volume_stop: 29836.599999999886,
  },
  {
    timestamp_start: '2022-01-01T12:29:31',
    timestamp_stop: '2022-01-01T12:29:36',
    quantity: 52.1,
    vehicle: 'DEM777',
    id: 137047,
    tank_volume_start: 29836.599999999886,
    tank_volume_stop: 29784.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:29:51',
    timestamp_stop: '2022-01-01T12:29:57',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 137049,
    tank_volume_start: 29784.499999999887,
    tank_volume_stop: 29727.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:30:12',
    timestamp_stop: '2022-01-01T12:30:17',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 137051,
    tank_volume_start: 29727.499999999887,
    tank_volume_stop: 29675.39999999989,
  },
  {
    timestamp_start: '2022-01-01T12:30:32',
    timestamp_stop: '2022-01-01T12:30:37',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 137053,
    tank_volume_start: 29675.39999999989,
    tank_volume_stop: 29626.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:30:52',
    timestamp_stop: '2022-01-01T12:30:57',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 137055,
    tank_volume_start: 29626.29999999989,
    tank_volume_stop: 29575.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:31:12',
    timestamp_stop: '2022-01-01T12:31:18',
    quantity: 55.3,
    vehicle: 'DEM666',
    id: 137057,
    tank_volume_start: 29575.29999999989,
    tank_volume_stop: 29519.99999999989,
  },
  {
    timestamp_start: '2022-01-01T12:31:33',
    timestamp_stop: '2022-01-01T12:31:38',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 137059,
    tank_volume_start: 29519.99999999989,
    tank_volume_stop: 29467.49999999989,
  },
  {
    timestamp_start: '2022-01-01T12:31:53',
    timestamp_stop: '2022-01-01T12:31:58',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 137061,
    tank_volume_start: 29467.49999999989,
    tank_volume_stop: 29411.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:32:13',
    timestamp_stop: '2022-01-01T12:32:18',
    quantity: 46.9,
    vehicle: 'DEM555',
    id: 137063,
    tank_volume_start: 29411.899999999892,
    tank_volume_stop: 29364.99999999989,
  },
  {
    timestamp_start: '2022-01-01T12:32:33',
    timestamp_stop: '2022-01-01T12:32:38',
    quantity: 47.3,
    vehicle: 'DEM444',
    id: 137065,
    tank_volume_start: 29364.99999999989,
    tank_volume_stop: 29317.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:32:53',
    timestamp_stop: '2022-01-01T12:32:57',
    quantity: 46.8,
    vehicle: 'DEM666',
    id: 137067,
    tank_volume_start: 29317.69999999989,
    tank_volume_stop: 29270.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:33:12',
    timestamp_stop: '2022-01-01T12:33:18',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 137069,
    tank_volume_start: 29270.899999999892,
    tank_volume_stop: 29211.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:33:33',
    timestamp_stop: '2022-01-01T12:33:38',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 137071,
    tank_volume_start: 29211.69999999989,
    tank_volume_stop: 29162.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:33:53',
    timestamp_stop: '2022-01-01T12:33:59',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 137073,
    tank_volume_start: 29162.899999999892,
    tank_volume_stop: 29108.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:34:14',
    timestamp_stop: '2022-01-01T12:34:19',
    quantity: 49.2,
    vehicle: 'DEM555',
    id: 137075,
    tank_volume_start: 29108.299999999894,
    tank_volume_stop: 29059.099999999893,
  },
  {
    timestamp_start: '2022-01-01T12:34:34',
    timestamp_stop: '2022-01-01T12:34:39',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 137077,
    tank_volume_start: 29059.099999999893,
    tank_volume_stop: 29002.099999999893,
  },
  {
    timestamp_start: '2022-01-01T12:34:54',
    timestamp_stop: '2022-01-01T12:35:00',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 137079,
    tank_volume_start: 29002.099999999893,
    tank_volume_stop: 28949.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:35:15',
    timestamp_stop: '2022-01-01T12:35:20',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 137081,
    tank_volume_start: 28949.299999999894,
    tank_volume_stop: 28897.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:35:35',
    timestamp_stop: '2022-01-01T12:35:40',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 137083,
    tank_volume_start: 28897.899999999892,
    tank_volume_stop: 28842.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:35:55',
    timestamp_stop: '2022-01-01T12:36:01',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 137085,
    tank_volume_start: 28842.69999999989,
    tank_volume_stop: 28783.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:36:16',
    timestamp_stop: '2022-01-01T12:36:21',
    quantity: 47.7,
    vehicle: 'DEM888',
    id: 137087,
    tank_volume_start: 28783.29999999989,
    tank_volume_stop: 28735.59999999989,
  },
  {
    timestamp_start: '2022-01-01T12:36:36',
    timestamp_stop: '2022-01-01T12:36:41',
    quantity: 49.8,
    vehicle: 'DEM555',
    id: 137089,
    tank_volume_start: 28735.59999999989,
    tank_volume_stop: 28685.79999999989,
  },
  {
    timestamp_start: '2022-01-01T12:36:56',
    timestamp_stop: '2022-01-01T12:37:02',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 137091,
    tank_volume_start: 28685.79999999989,
    tank_volume_stop: 28628.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:37:17',
    timestamp_stop: '2022-01-01T12:37:21',
    quantity: 48.6,
    vehicle: 'DEM444',
    id: 137093,
    tank_volume_start: 28628.69999999989,
    tank_volume_stop: 28580.099999999893,
  },
  {
    timestamp_start: '2022-01-01T12:37:36',
    timestamp_stop: '2022-01-01T12:37:42',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 137095,
    tank_volume_start: 28580.099999999893,
    tank_volume_stop: 28521.999999999894,
  },
  {
    timestamp_start: '2022-01-01T12:37:57',
    timestamp_stop: '2022-01-01T12:38:02',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 137097,
    tank_volume_start: 28521.999999999894,
    tank_volume_stop: 28470.599999999893,
  },
  {
    timestamp_start: '2022-01-01T12:38:17',
    timestamp_stop: '2022-01-01T12:38:22',
    quantity: 49.4,
    vehicle: 'DEM777',
    id: 137099,
    tank_volume_start: 28470.599999999893,
    tank_volume_stop: 28421.19999999989,
  },
  {
    timestamp_start: '2022-01-01T12:38:37',
    timestamp_stop: '2022-01-01T12:38:42',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 137101,
    tank_volume_start: 28421.19999999989,
    tank_volume_stop: 28371.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:38:57',
    timestamp_stop: '2022-01-01T12:39:03',
    quantity: 52.4,
    vehicle: 'DEM888',
    id: 137103,
    tank_volume_start: 28371.69999999989,
    tank_volume_stop: 28319.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:39:18',
    timestamp_stop: '2022-01-01T12:39:23',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 137105,
    tank_volume_start: 28319.29999999989,
    tank_volume_stop: 28266.79999999989,
  },
  {
    timestamp_start: '2022-01-01T12:39:38',
    timestamp_stop: '2022-01-01T12:39:43',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 137107,
    tank_volume_start: 28266.79999999989,
    tank_volume_stop: 28213.49999999989,
  },
  {
    timestamp_start: '2022-01-01T12:39:58',
    timestamp_stop: '2022-01-01T12:40:04',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 137109,
    tank_volume_start: 28213.49999999989,
    tank_volume_stop: 28155.79999999989,
  },
  {
    timestamp_start: '2022-01-01T12:40:19',
    timestamp_stop: '2022-01-01T12:40:24',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 137111,
    tank_volume_start: 28155.79999999989,
    tank_volume_stop: 28103.39999999989,
  },
  {
    timestamp_start: '2022-01-01T12:40:39',
    timestamp_stop: '2022-01-01T12:40:44',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 137113,
    tank_volume_start: 28103.39999999989,
    tank_volume_stop: 28052.89999999989,
  },
  {
    timestamp_start: '2022-01-01T12:40:59',
    timestamp_stop: '2022-01-01T12:41:05',
    quantity: 61.4,
    vehicle: 'DEM444',
    id: 137115,
    tank_volume_start: 28052.89999999989,
    tank_volume_stop: 27991.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:41:20',
    timestamp_stop: '2022-01-01T12:41:25',
    quantity: 47.5,
    vehicle: 'DEM333',
    id: 137117,
    tank_volume_start: 27991.499999999887,
    tank_volume_stop: 27943.999999999887,
  },
  {
    timestamp_start: '2022-01-01T12:41:40',
    timestamp_stop: '2022-01-01T12:41:45',
    quantity: 47.5,
    vehicle: 'DEM333',
    id: 137119,
    tank_volume_start: 27943.999999999887,
    tank_volume_stop: 27896.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:42:00',
    timestamp_stop: '2022-01-01T12:42:05',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 137121,
    tank_volume_start: 27896.499999999887,
    tank_volume_stop: 27842.599999999886,
  },
  {
    timestamp_start: '2022-01-01T12:42:20',
    timestamp_stop: '2022-01-01T12:42:25',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 137123,
    tank_volume_start: 27842.599999999886,
    tank_volume_stop: 27791.899999999885,
  },
  {
    timestamp_start: '2022-01-01T12:42:40',
    timestamp_stop: '2022-01-01T12:42:46',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 137125,
    tank_volume_start: 27791.899999999885,
    tank_volume_stop: 27731.999999999884,
  },
  {
    timestamp_start: '2022-01-01T12:43:01',
    timestamp_stop: '2022-01-01T12:43:07',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 137127,
    tank_volume_start: 27731.999999999884,
    tank_volume_stop: 27679.699999999884,
  },
  {
    timestamp_start: '2022-01-01T12:43:22',
    timestamp_stop: '2022-01-01T12:43:27',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 137129,
    tank_volume_start: 27679.699999999884,
    tank_volume_stop: 27625.299999999883,
  },
  {
    timestamp_start: '2022-01-01T12:43:42',
    timestamp_stop: '2022-01-01T12:43:48',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 137131,
    tank_volume_start: 27625.299999999883,
    tank_volume_stop: 27568.799999999883,
  },
  {
    timestamp_start: '2022-01-01T12:44:03',
    timestamp_stop: '2022-01-01T12:44:07',
    quantity: 48.7,
    vehicle: 'DEM888',
    id: 137133,
    tank_volume_start: 27568.799999999883,
    tank_volume_stop: 27520.099999999882,
  },
  {
    timestamp_start: '2022-01-01T12:44:22',
    timestamp_stop: '2022-01-01T12:44:29',
    quantity: 63.8,
    vehicle: 'DEM444',
    id: 137135,
    tank_volume_start: 27520.099999999882,
    tank_volume_stop: 27456.299999999883,
  },
  {
    timestamp_start: '2022-01-01T12:44:44',
    timestamp_stop: '2022-01-01T12:44:50',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 137137,
    tank_volume_start: 27456.299999999883,
    tank_volume_stop: 27399.499999999884,
  },
  {
    timestamp_start: '2022-01-01T12:45:05',
    timestamp_stop: '2022-01-01T12:45:10',
    quantity: 56.6,
    vehicle: 'DEM000',
    id: 137139,
    tank_volume_start: 27399.499999999884,
    tank_volume_stop: 27342.899999999885,
  },
  {
    timestamp_start: '2022-01-01T12:45:25',
    timestamp_stop: '2022-01-01T12:45:32',
    quantity: 63.6,
    vehicle: 'DEM777',
    id: 137141,
    tank_volume_start: 27342.899999999885,
    tank_volume_stop: 27279.299999999886,
  },
  {
    timestamp_start: '2022-01-01T12:45:47',
    timestamp_stop: '2022-01-01T12:45:52',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 137143,
    tank_volume_start: 27279.299999999886,
    tank_volume_stop: 27224.999999999887,
  },
  {
    timestamp_start: '2022-01-01T12:46:07',
    timestamp_stop: '2022-01-01T12:46:12',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 137145,
    tank_volume_start: 27224.999999999887,
    tank_volume_stop: 27172.499999999887,
  },
  {
    timestamp_start: '2022-01-01T12:46:27',
    timestamp_stop: '2022-01-01T12:46:32',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 137147,
    tank_volume_start: 27172.499999999887,
    tank_volume_stop: 27124.699999999888,
  },
  {
    timestamp_start: '2022-01-01T12:46:47',
    timestamp_stop: '2022-01-01T12:46:52',
    quantity: 45.0,
    vehicle: 'DEM777',
    id: 137149,
    tank_volume_start: 27124.699999999888,
    tank_volume_stop: 27079.699999999888,
  },
  {
    timestamp_start: '2022-01-01T12:47:07',
    timestamp_stop: '2022-01-01T12:47:11',
    quantity: 48.6,
    vehicle: 'DEM333',
    id: 137151,
    tank_volume_start: 27079.699999999888,
    tank_volume_stop: 27031.09999999989,
  },
  {
    timestamp_start: '2022-01-01T12:47:26',
    timestamp_stop: '2022-01-01T12:47:32',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 137153,
    tank_volume_start: 27031.09999999989,
    tank_volume_stop: 26976.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:47:47',
    timestamp_stop: '2022-01-01T12:47:52',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 137155,
    tank_volume_start: 26976.29999999989,
    tank_volume_stop: 26925.79999999989,
  },
  {
    timestamp_start: '2022-01-01T12:48:07',
    timestamp_stop: '2022-01-01T12:48:12',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 137157,
    tank_volume_start: 26925.79999999989,
    tank_volume_stop: 26874.49999999989,
  },
  {
    timestamp_start: '2022-01-01T12:48:27',
    timestamp_stop: '2022-01-01T12:48:33',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 137159,
    tank_volume_start: 26874.49999999989,
    tank_volume_stop: 26815.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:48:48',
    timestamp_stop: '2022-01-01T12:48:54',
    quantity: 57.4,
    vehicle: 'DEM000',
    id: 137161,
    tank_volume_start: 26815.69999999989,
    tank_volume_stop: 26758.29999999989,
  },
  {
    timestamp_start: '2022-01-01T12:49:09',
    timestamp_stop: '2022-01-01T12:49:14',
    quantity: 52.6,
    vehicle: 'DEM444',
    id: 137163,
    tank_volume_start: 26758.29999999989,
    tank_volume_stop: 26705.69999999989,
  },
  {
    timestamp_start: '2022-01-01T12:49:29',
    timestamp_stop: '2022-01-01T12:49:34',
    quantity: 45.8,
    vehicle: 'DEM777',
    id: 137165,
    tank_volume_start: 26705.69999999989,
    tank_volume_stop: 26659.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:49:49',
    timestamp_stop: '2022-01-01T12:49:54',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 137167,
    tank_volume_start: 26659.899999999892,
    tank_volume_stop: 26606.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:50:09',
    timestamp_stop: '2022-01-01T12:50:14',
    quantity: 51.0,
    vehicle: 'DEM222',
    id: 137169,
    tank_volume_start: 26606.899999999892,
    tank_volume_stop: 26555.899999999892,
  },
  {
    timestamp_start: '2022-01-01T12:50:29',
    timestamp_stop: '2022-01-01T12:50:34',
    quantity: 46.6,
    vehicle: 'DEM000',
    id: 137171,
    tank_volume_start: 26555.899999999892,
    tank_volume_stop: 26509.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:50:49',
    timestamp_stop: '2022-01-01T12:50:54',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 137173,
    tank_volume_start: 26509.299999999894,
    tank_volume_stop: 26453.599999999893,
  },
  {
    timestamp_start: '2022-01-01T12:51:09',
    timestamp_stop: '2022-01-01T12:51:14',
    quantity: 52.3,
    vehicle: 'DEM666',
    id: 137175,
    tank_volume_start: 26453.599999999893,
    tank_volume_stop: 26401.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:51:29',
    timestamp_stop: '2022-01-01T12:51:35',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 137177,
    tank_volume_start: 26401.299999999894,
    tank_volume_stop: 26346.199999999895,
  },
  {
    timestamp_start: '2022-01-01T12:51:50',
    timestamp_stop: '2022-01-01T12:51:56',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 137179,
    tank_volume_start: 26346.199999999895,
    tank_volume_stop: 26286.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:52:11',
    timestamp_stop: '2022-01-01T12:52:16',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 137181,
    tank_volume_start: 26286.299999999894,
    tank_volume_stop: 26231.199999999895,
  },
  {
    timestamp_start: '2022-01-01T12:52:31',
    timestamp_stop: '2022-01-01T12:52:37',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 137183,
    tank_volume_start: 26231.199999999895,
    tank_volume_stop: 26178.299999999894,
  },
  {
    timestamp_start: '2022-01-01T12:52:52',
    timestamp_stop: '2022-01-01T12:52:58',
    quantity: 62.6,
    vehicle: 'DEM000',
    id: 137185,
    tank_volume_start: 26178.299999999894,
    tank_volume_stop: 26115.699999999895,
  },
  {
    timestamp_start: '2022-01-01T12:53:13',
    timestamp_stop: '2022-01-01T12:53:19',
    quantity: 60.3,
    vehicle: 'DEM111',
    id: 137187,
    tank_volume_start: 26115.699999999895,
    tank_volume_stop: 26055.399999999896,
  },
  {
    timestamp_start: '2022-01-01T12:53:34',
    timestamp_stop: '2022-01-01T12:53:40',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 137189,
    tank_volume_start: 26055.399999999896,
    tank_volume_stop: 25999.799999999897,
  },
  {
    timestamp_start: '2022-01-01T12:53:55',
    timestamp_stop: '2022-01-01T12:54:00',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 137191,
    tank_volume_start: 25999.799999999897,
    tank_volume_stop: 25949.499999999898,
  },
  {
    timestamp_start: '2022-01-01T12:54:15',
    timestamp_stop: '2022-01-01T12:54:19',
    quantity: 47.0,
    vehicle: 'DEM666',
    id: 137193,
    tank_volume_start: 25949.499999999898,
    tank_volume_stop: 25902.499999999898,
  },
  {
    timestamp_start: '2022-01-01T12:54:34',
    timestamp_stop: '2022-01-01T12:54:40',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 137195,
    tank_volume_start: 25902.499999999898,
    tank_volume_stop: 25845.3999999999,
  },
  {
    timestamp_start: '2022-01-01T12:54:55',
    timestamp_stop: '2022-01-01T12:55:01',
    quantity: 59.7,
    vehicle: 'DEM111',
    id: 137197,
    tank_volume_start: 25845.3999999999,
    tank_volume_stop: 25785.6999999999,
  },
  {
    timestamp_start: '2022-01-01T12:55:16',
    timestamp_stop: '2022-01-01T12:55:21',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 137199,
    tank_volume_start: 25785.6999999999,
    tank_volume_stop: 25731.5999999999,
  },
  {
    timestamp_start: '2022-01-01T12:55:36',
    timestamp_stop: '2022-01-01T12:55:42',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 137201,
    tank_volume_start: 25731.5999999999,
    tank_volume_stop: 25672.0999999999,
  },
  {
    timestamp_start: '2022-01-01T12:55:57',
    timestamp_stop: '2022-01-01T12:56:02',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 137203,
    tank_volume_start: 25672.0999999999,
    tank_volume_stop: 25620.0999999999,
  },
  {
    timestamp_start: '2022-01-01T12:56:17',
    timestamp_stop: '2022-01-01T12:56:23',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 137205,
    tank_volume_start: 25620.0999999999,
    tank_volume_stop: 25562.0999999999,
  },
  {
    timestamp_start: '2022-01-01T12:56:38',
    timestamp_stop: '2022-01-01T12:56:44',
    quantity: 60.3,
    vehicle: 'DEM444',
    id: 137207,
    tank_volume_start: 25562.0999999999,
    tank_volume_stop: 25501.7999999999,
  },
  {
    timestamp_start: '2022-01-01T12:56:59',
    timestamp_stop: '2022-01-01T12:57:05',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 137209,
    tank_volume_start: 25501.7999999999,
    tank_volume_stop: 25440.8999999999,
  },
  {
    timestamp_start: '2022-01-01T12:57:20',
    timestamp_stop: '2022-01-01T12:57:26',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 137211,
    tank_volume_start: 25440.8999999999,
    tank_volume_stop: 25383.999999999898,
  },
  {
    timestamp_start: '2022-01-01T12:57:41',
    timestamp_stop: '2022-01-01T12:57:46',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 137213,
    tank_volume_start: 25383.999999999898,
    tank_volume_stop: 25335.099999999897,
  },
  {
    timestamp_start: '2022-01-01T12:58:01',
    timestamp_stop: '2022-01-01T12:58:07',
    quantity: 57.6,
    vehicle: 'DEM000',
    id: 137215,
    tank_volume_start: 25335.099999999897,
    tank_volume_stop: 25277.499999999898,
  },
  {
    timestamp_start: '2022-01-01T12:58:22',
    timestamp_stop: '2022-01-01T12:58:27',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 137217,
    tank_volume_start: 25277.499999999898,
    tank_volume_stop: 25227.099999999897,
  },
  {
    timestamp_start: '2022-01-01T12:58:42',
    timestamp_stop: '2022-01-01T12:58:47',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 137219,
    tank_volume_start: 25227.099999999897,
    tank_volume_stop: 25173.699999999895,
  },
  {
    timestamp_start: '2022-01-01T12:59:02',
    timestamp_stop: '2022-01-01T12:59:08',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 137221,
    tank_volume_start: 25173.699999999895,
    tank_volume_stop: 25118.999999999894,
  },
  {
    timestamp_start: '2022-01-01T12:59:23',
    timestamp_stop: '2022-01-01T12:59:28',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 137223,
    tank_volume_start: 25118.999999999894,
    tank_volume_stop: 25064.999999999894,
  },
  {
    timestamp_start: '2022-01-01T12:59:43',
    timestamp_stop: '2022-01-01T12:59:48',
    quantity: 49.8,
    vehicle: 'DEM111',
    id: 137225,
    tank_volume_start: 25064.999999999894,
    tank_volume_stop: 25015.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:00:03',
    timestamp_stop: '2022-01-01T13:00:08',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 137227,
    tank_volume_start: 25015.199999999895,
    tank_volume_stop: 24963.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:00:23',
    timestamp_stop: '2022-01-01T13:00:29',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 137229,
    tank_volume_start: 24963.499999999894,
    tank_volume_stop: 24905.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:00:44',
    timestamp_stop: '2022-01-01T13:00:50',
    quantity: 59.3,
    vehicle: 'DEM222',
    id: 137231,
    tank_volume_start: 24905.199999999895,
    tank_volume_stop: 24845.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:01:05',
    timestamp_stop: '2022-01-01T13:01:10',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 137233,
    tank_volume_start: 24845.899999999896,
    tank_volume_stop: 24792.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:01:25',
    timestamp_stop: '2022-01-01T13:01:31',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 137235,
    tank_volume_start: 24792.999999999894,
    tank_volume_stop: 24730.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:01:46',
    timestamp_stop: '2022-01-01T13:01:52',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 137237,
    tank_volume_start: 24730.999999999894,
    tank_volume_stop: 24678.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:02:07',
    timestamp_stop: '2022-01-01T13:02:13',
    quantity: 64.7,
    vehicle: 'DEM888',
    id: 137239,
    tank_volume_start: 24678.499999999894,
    tank_volume_stop: 24613.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:02:28',
    timestamp_stop: '2022-01-01T13:02:34',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 137241,
    tank_volume_start: 24613.799999999894,
    tank_volume_stop: 24559.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:02:49',
    timestamp_stop: '2022-01-01T13:02:54',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 137243,
    tank_volume_start: 24559.099999999893,
    tank_volume_stop: 24503.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:03:09',
    timestamp_stop: '2022-01-01T13:03:15',
    quantity: 62.4,
    vehicle: 'DEM888',
    id: 137245,
    tank_volume_start: 24503.499999999894,
    tank_volume_stop: 24441.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:03:30',
    timestamp_stop: '2022-01-01T13:03:36',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 137247,
    tank_volume_start: 24441.099999999893,
    tank_volume_stop: 24381.69999999989,
  },
  {
    timestamp_start: '2022-01-01T13:03:51',
    timestamp_stop: '2022-01-01T13:03:56',
    quantity: 50.8,
    vehicle: 'DEM222',
    id: 137249,
    tank_volume_start: 24381.69999999989,
    tank_volume_stop: 24330.899999999892,
  },
  {
    timestamp_start: '2022-01-01T13:04:11',
    timestamp_stop: '2022-01-01T13:04:16',
    quantity: 46.3,
    vehicle: 'DEM555',
    id: 137251,
    tank_volume_start: 24330.899999999892,
    tank_volume_stop: 24284.599999999893,
  },
  {
    timestamp_start: '2022-01-01T13:04:31',
    timestamp_stop: '2022-01-01T13:04:36',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 137253,
    tank_volume_start: 24284.599999999893,
    tank_volume_stop: 24232.599999999893,
  },
  {
    timestamp_start: '2022-01-01T13:04:51',
    timestamp_stop: '2022-01-01T13:04:58',
    quantity: 63.6,
    vehicle: 'DEM000',
    id: 137255,
    tank_volume_start: 24232.599999999893,
    tank_volume_stop: 24168.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:05:13',
    timestamp_stop: '2022-01-01T13:05:18',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 137257,
    tank_volume_start: 24168.999999999894,
    tank_volume_stop: 24110.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:05:33',
    timestamp_stop: '2022-01-01T13:05:38',
    quantity: 49.3,
    vehicle: 'DEM666',
    id: 137259,
    tank_volume_start: 24110.999999999894,
    tank_volume_stop: 24061.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:05:53',
    timestamp_stop: '2022-01-01T13:06:00',
    quantity: 61.7,
    vehicle: 'DEM666',
    id: 137261,
    tank_volume_start: 24061.699999999895,
    tank_volume_stop: 23999.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:06:15',
    timestamp_stop: '2022-01-01T13:06:20',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 137263,
    tank_volume_start: 23999.999999999894,
    tank_volume_stop: 23946.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:06:35',
    timestamp_stop: '2022-01-01T13:06:41',
    quantity: 60.2,
    vehicle: 'DEM333',
    id: 137265,
    tank_volume_start: 23946.199999999895,
    tank_volume_stop: 23885.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:06:56',
    timestamp_stop: '2022-01-01T13:07:01',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 137267,
    tank_volume_start: 23885.999999999894,
    tank_volume_stop: 23831.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:07:16',
    timestamp_stop: '2022-01-01T13:07:22',
    quantity: 55.2,
    vehicle: 'DEM111',
    id: 137269,
    tank_volume_start: 23831.099999999893,
    tank_volume_stop: 23775.899999999892,
  },
  {
    timestamp_start: '2022-01-01T13:07:37',
    timestamp_stop: '2022-01-01T13:07:42',
    quantity: 55.3,
    vehicle: 'DEM111',
    id: 137271,
    tank_volume_start: 23775.899999999892,
    tank_volume_stop: 23720.599999999893,
  },
  {
    timestamp_start: '2022-01-01T13:07:57',
    timestamp_stop: '2022-01-01T13:08:02',
    quantity: 50.5,
    vehicle: 'DEM000',
    id: 137273,
    tank_volume_start: 23720.599999999893,
    tank_volume_stop: 23670.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:08:17',
    timestamp_stop: '2022-01-01T13:08:24',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 137275,
    tank_volume_start: 23670.099999999893,
    tank_volume_stop: 23608.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:08:39',
    timestamp_stop: '2022-01-01T13:08:44',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 137277,
    tank_volume_start: 23608.799999999894,
    tank_volume_stop: 23552.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:08:59',
    timestamp_stop: '2022-01-01T13:09:05',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 137279,
    tank_volume_start: 23552.099999999893,
    tank_volume_stop: 23498.19999999989,
  },
  {
    timestamp_start: '2022-01-01T13:09:20',
    timestamp_stop: '2022-01-01T13:09:25',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 137281,
    tank_volume_start: 23498.19999999989,
    tank_volume_stop: 23443.899999999892,
  },
  {
    timestamp_start: '2022-01-01T13:09:40',
    timestamp_stop: '2022-01-01T13:09:45',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 137283,
    tank_volume_start: 23443.899999999892,
    tank_volume_stop: 23391.299999999894,
  },
  {
    timestamp_start: '2022-01-01T13:10:00',
    timestamp_stop: '2022-01-01T13:10:06',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 137285,
    tank_volume_start: 23391.299999999894,
    tank_volume_stop: 23337.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:10:21',
    timestamp_stop: '2022-01-01T13:10:26',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 137287,
    tank_volume_start: 23337.499999999894,
    tank_volume_stop: 23285.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:10:41',
    timestamp_stop: '2022-01-01T13:10:47',
    quantity: 61.5,
    vehicle: 'DEM222',
    id: 137289,
    tank_volume_start: 23285.799999999894,
    tank_volume_stop: 23224.299999999894,
  },
  {
    timestamp_start: '2022-01-01T13:11:02',
    timestamp_stop: '2022-01-01T13:11:08',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 137291,
    tank_volume_start: 23224.299999999894,
    tank_volume_stop: 23169.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:11:23',
    timestamp_stop: '2022-01-01T13:11:29',
    quantity: 61.7,
    vehicle: 'DEM888',
    id: 137293,
    tank_volume_start: 23169.799999999894,
    tank_volume_stop: 23108.099999999893,
  },
  {
    timestamp_start: '2022-01-01T13:11:44',
    timestamp_stop: '2022-01-01T13:11:49',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 137295,
    tank_volume_start: 23108.099999999893,
    tank_volume_stop: 23054.299999999894,
  },
  {
    timestamp_start: '2022-01-01T13:12:04',
    timestamp_stop: '2022-01-01T13:12:09',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 137297,
    tank_volume_start: 23054.299999999894,
    tank_volume_stop: 23003.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:12:24',
    timestamp_stop: '2022-01-01T13:12:30',
    quantity: 57.2,
    vehicle: 'DEM111',
    id: 137299,
    tank_volume_start: 23003.699999999895,
    tank_volume_stop: 22946.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:12:45',
    timestamp_stop: '2022-01-01T13:12:51',
    quantity: 58.7,
    vehicle: 'DEM444',
    id: 137301,
    tank_volume_start: 22946.499999999894,
    tank_volume_stop: 22887.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:13:06',
    timestamp_stop: '2022-01-01T13:13:11',
    quantity: 51.6,
    vehicle: 'DEM555',
    id: 137303,
    tank_volume_start: 22887.799999999894,
    tank_volume_stop: 22836.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:13:26',
    timestamp_stop: '2022-01-01T13:13:31',
    quantity: 47.4,
    vehicle: 'DEM444',
    id: 137305,
    tank_volume_start: 22836.199999999895,
    tank_volume_stop: 22788.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:13:46',
    timestamp_stop: '2022-01-01T13:13:52',
    quantity: 59.6,
    vehicle: 'DEM777',
    id: 137307,
    tank_volume_start: 22788.799999999894,
    tank_volume_stop: 22729.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:14:07',
    timestamp_stop: '2022-01-01T13:14:12',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 137309,
    tank_volume_start: 22729.199999999895,
    tank_volume_stop: 22673.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:14:27',
    timestamp_stop: '2022-01-01T13:14:32',
    quantity: 50.1,
    vehicle: 'DEM888',
    id: 137311,
    tank_volume_start: 22673.799999999894,
    tank_volume_stop: 22623.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:14:47',
    timestamp_stop: '2022-01-01T13:14:53',
    quantity: 55.3,
    vehicle: 'DEM777',
    id: 137313,
    tank_volume_start: 22623.699999999895,
    tank_volume_stop: 22568.399999999896,
  },
  {
    timestamp_start: '2022-01-01T13:15:08',
    timestamp_stop: '2022-01-01T13:15:13',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 137315,
    tank_volume_start: 22568.399999999896,
    tank_volume_stop: 22515.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:15:28',
    timestamp_stop: '2022-01-01T13:15:34',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 137317,
    tank_volume_start: 22515.899999999896,
    tank_volume_stop: 22458.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:15:49',
    timestamp_stop: '2022-01-01T13:15:54',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 137319,
    tank_volume_start: 22458.599999999897,
    tank_volume_stop: 22403.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:16:09',
    timestamp_stop: '2022-01-01T13:16:14',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 137321,
    tank_volume_start: 22403.099999999897,
    tank_volume_stop: 22350.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:16:29',
    timestamp_stop: '2022-01-01T13:16:34',
    quantity: 45.2,
    vehicle: 'DEM666',
    id: 137323,
    tank_volume_start: 22350.099999999897,
    tank_volume_stop: 22304.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:16:49',
    timestamp_stop: '2022-01-01T13:16:55',
    quantity: 59.8,
    vehicle: 'DEM888',
    id: 137325,
    tank_volume_start: 22304.899999999896,
    tank_volume_stop: 22245.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:17:10',
    timestamp_stop: '2022-01-01T13:17:16',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 137327,
    tank_volume_start: 22245.099999999897,
    tank_volume_stop: 22189.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:17:31',
    timestamp_stop: '2022-01-01T13:17:36',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 137329,
    tank_volume_start: 22189.699999999895,
    tank_volume_stop: 22134.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:17:51',
    timestamp_stop: '2022-01-01T13:17:57',
    quantity: 57.9,
    vehicle: 'DEM777',
    id: 137331,
    tank_volume_start: 22134.199999999895,
    tank_volume_stop: 22076.299999999894,
  },
  {
    timestamp_start: '2022-01-01T13:18:12',
    timestamp_stop: '2022-01-01T13:18:18',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 137333,
    tank_volume_start: 22076.299999999894,
    tank_volume_stop: 22017.599999999893,
  },
  {
    timestamp_start: '2022-01-01T13:18:33',
    timestamp_stop: '2022-01-01T13:18:39',
    quantity: 63.6,
    vehicle: 'DEM666',
    id: 137335,
    tank_volume_start: 22017.599999999893,
    tank_volume_stop: 21953.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:18:54',
    timestamp_stop: '2022-01-01T13:19:00',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 137337,
    tank_volume_start: 21953.999999999894,
    tank_volume_stop: 21899.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:19:15',
    timestamp_stop: '2022-01-01T13:19:20',
    quantity: 52.9,
    vehicle: 'DEM222',
    id: 137339,
    tank_volume_start: 21899.699999999895,
    tank_volume_stop: 21846.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:19:35',
    timestamp_stop: '2022-01-01T13:19:40',
    quantity: 49.2,
    vehicle: 'DEM666',
    id: 137341,
    tank_volume_start: 21846.799999999894,
    tank_volume_stop: 21797.599999999893,
  },
  {
    timestamp_start: '2022-01-01T13:19:55',
    timestamp_stop: '2022-01-01T13:20:00',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 137343,
    tank_volume_start: 21797.599999999893,
    tank_volume_stop: 21743.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:20:15',
    timestamp_stop: '2022-01-01T13:20:21',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 137345,
    tank_volume_start: 21743.999999999894,
    tank_volume_stop: 21685.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:20:36',
    timestamp_stop: '2022-01-01T13:20:41',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 137347,
    tank_volume_start: 21685.799999999894,
    tank_volume_stop: 21631.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:20:56',
    timestamp_stop: '2022-01-01T13:21:02',
    quantity: 59.0,
    vehicle: 'DEM111',
    id: 137349,
    tank_volume_start: 21631.199999999895,
    tank_volume_stop: 21572.199999999895,
  },
  {
    timestamp_start: '2022-01-01T13:21:17',
    timestamp_stop: '2022-01-01T13:21:23',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 137351,
    tank_volume_start: 21572.199999999895,
    tank_volume_stop: 21515.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:21:38',
    timestamp_stop: '2022-01-01T13:21:43',
    quantity: 50.8,
    vehicle: 'DEM666',
    id: 137353,
    tank_volume_start: 21515.499999999894,
    tank_volume_stop: 21464.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:21:58',
    timestamp_stop: '2022-01-01T13:22:03',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 137355,
    tank_volume_start: 21464.699999999895,
    tank_volume_stop: 21410.399999999896,
  },
  {
    timestamp_start: '2022-01-01T13:22:18',
    timestamp_stop: '2022-01-01T13:22:24',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 137357,
    tank_volume_start: 21410.399999999896,
    tank_volume_stop: 21354.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:22:39',
    timestamp_stop: '2022-01-01T13:22:44',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 137359,
    tank_volume_start: 21354.899999999896,
    tank_volume_stop: 21300.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:22:59',
    timestamp_stop: '2022-01-01T13:23:05',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 137361,
    tank_volume_start: 21300.599999999897,
    tank_volume_stop: 21241.799999999897,
  },
  {
    timestamp_start: '2022-01-01T13:23:20',
    timestamp_stop: '2022-01-01T13:23:26',
    quantity: 60.2,
    vehicle: 'DEM555',
    id: 137363,
    tank_volume_start: 21241.799999999897,
    tank_volume_stop: 21181.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:23:41',
    timestamp_stop: '2022-01-01T13:23:47',
    quantity: 55.2,
    vehicle: 'DEM777',
    id: 137365,
    tank_volume_start: 21181.599999999897,
    tank_volume_stop: 21126.399999999896,
  },
  {
    timestamp_start: '2022-01-01T13:24:02',
    timestamp_stop: '2022-01-01T13:24:08',
    quantity: 58.7,
    vehicle: 'DEM777',
    id: 137367,
    tank_volume_start: 21126.399999999896,
    tank_volume_stop: 21067.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:24:23',
    timestamp_stop: '2022-01-01T13:24:29',
    quantity: 61.9,
    vehicle: 'DEM444',
    id: 137369,
    tank_volume_start: 21067.699999999895,
    tank_volume_stop: 21005.799999999894,
  },
  {
    timestamp_start: '2022-01-01T13:24:44',
    timestamp_stop: '2022-01-01T13:24:50',
    quantity: 58.1,
    vehicle: 'DEM000',
    id: 137371,
    tank_volume_start: 21005.799999999894,
    tank_volume_stop: 20947.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:25:05',
    timestamp_stop: '2022-01-01T13:25:10',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 137373,
    tank_volume_start: 20947.699999999895,
    tank_volume_stop: 20894.499999999894,
  },
  {
    timestamp_start: '2022-01-01T13:25:25',
    timestamp_stop: '2022-01-01T13:25:31',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 137375,
    tank_volume_start: 20894.499999999894,
    tank_volume_stop: 20838.999999999894,
  },
  {
    timestamp_start: '2022-01-01T13:25:46',
    timestamp_stop: '2022-01-01T13:25:51',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 137377,
    tank_volume_start: 20838.999999999894,
    tank_volume_stop: 20787.399999999896,
  },
  {
    timestamp_start: '2022-01-01T13:26:06',
    timestamp_stop: '2022-01-01T13:26:12',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 137379,
    tank_volume_start: 20787.399999999896,
    tank_volume_stop: 20726.299999999897,
  },
  {
    timestamp_start: '2022-01-01T13:26:27',
    timestamp_stop: '2022-01-01T13:26:32',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 137381,
    tank_volume_start: 20726.299999999897,
    tank_volume_stop: 20674.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:26:47',
    timestamp_stop: '2022-01-01T13:26:53',
    quantity: 58.0,
    vehicle: 'DEM000',
    id: 137383,
    tank_volume_start: 20674.599999999897,
    tank_volume_stop: 20616.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:27:08',
    timestamp_stop: '2022-01-01T13:27:13',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 137385,
    tank_volume_start: 20616.599999999897,
    tank_volume_stop: 20560.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:27:28',
    timestamp_stop: '2022-01-01T13:27:34',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 137387,
    tank_volume_start: 20560.899999999896,
    tank_volume_stop: 20503.899999999896,
  },
  {
    timestamp_start: '2022-01-01T13:27:49',
    timestamp_stop: '2022-01-01T13:27:55',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 137389,
    tank_volume_start: 20503.899999999896,
    tank_volume_stop: 20448.599999999897,
  },
  {
    timestamp_start: '2022-01-01T13:28:10',
    timestamp_stop: '2022-01-01T13:28:14',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 137391,
    tank_volume_start: 20448.599999999897,
    tank_volume_stop: 20400.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:28:29',
    timestamp_stop: '2022-01-01T13:28:36',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 137393,
    tank_volume_start: 20400.099999999897,
    tank_volume_stop: 20338.499999999898,
  },
  {
    timestamp_start: '2022-01-01T13:28:51',
    timestamp_stop: '2022-01-01T13:28:56',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 137395,
    tank_volume_start: 20338.499999999898,
    tank_volume_stop: 20281.8999999999,
  },
  {
    timestamp_start: '2022-01-01T13:29:11',
    timestamp_stop: '2022-01-01T13:29:17',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 137397,
    tank_volume_start: 20281.8999999999,
    tank_volume_stop: 20228.3999999999,
  },
  {
    timestamp_start: '2022-01-01T13:29:32',
    timestamp_stop: '2022-01-01T13:29:38',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 137399,
    tank_volume_start: 20228.3999999999,
    tank_volume_stop: 20168.8999999999,
  },
  {
    timestamp_start: '2022-01-01T13:29:53',
    timestamp_stop: '2022-01-01T13:29:58',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 137401,
    tank_volume_start: 20168.8999999999,
    tank_volume_stop: 20115.999999999898,
  },
  {
    timestamp_start: '2022-01-01T13:30:13',
    timestamp_stop: '2022-01-01T13:30:19',
    quantity: 56.9,
    vehicle: 'DEM888',
    id: 137403,
    tank_volume_start: 20115.999999999898,
    tank_volume_stop: 20059.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:30:34',
    timestamp_stop: '2022-01-01T13:30:39',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 137405,
    tank_volume_start: 20059.099999999897,
    tank_volume_stop: 20002.099999999897,
  },
  {
    timestamp_start: '2022-01-01T13:30:54',
    timestamp_stop: '2022-01-01T13:31:00',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 137407,
    tank_volume_start: 20002.099999999897,
    tank_volume_stop: 19946.699999999895,
  },
  {
    timestamp_start: '2022-01-01T13:31:15',
    timestamp_stop: '2022-01-01T13:31:21',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 137409,
    tank_volume_start: 39946.699999999895,
    tank_volume_stop: 39888.5999999999,
  },
  {
    timestamp_start: '2022-01-01T13:31:36',
    timestamp_stop: '2022-01-01T13:31:42',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 137411,
    tank_volume_start: 39888.5999999999,
    tank_volume_stop: 39829.4999999999,
  },
  {
    timestamp_start: '2022-01-01T13:31:57',
    timestamp_stop: '2022-01-01T13:32:02',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 137413,
    tank_volume_start: 39829.4999999999,
    tank_volume_stop: 39779.9999999999,
  },
  {
    timestamp_start: '2022-01-01T13:32:17',
    timestamp_stop: '2022-01-01T13:32:22',
    quantity: 57.6,
    vehicle: 'DEM222',
    id: 137415,
    tank_volume_start: 39779.9999999999,
    tank_volume_stop: 39722.3999999999,
  },
  {
    timestamp_start: '2022-01-01T13:32:37',
    timestamp_stop: '2022-01-01T13:32:43',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 137417,
    tank_volume_start: 39722.3999999999,
    tank_volume_stop: 39667.4999999999,
  },
  {
    timestamp_start: '2022-01-01T13:32:58',
    timestamp_stop: '2022-01-01T13:33:03',
    quantity: 51.6,
    vehicle: 'DEM888',
    id: 137419,
    tank_volume_start: 39667.4999999999,
    tank_volume_stop: 39615.8999999999,
  },
  {
    timestamp_start: '2022-01-01T13:33:18',
    timestamp_stop: '2022-01-01T13:33:23',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 137421,
    tank_volume_start: 39615.8999999999,
    tank_volume_stop: 39562.2999999999,
  },
  {
    timestamp_start: '2022-01-01T13:33:38',
    timestamp_stop: '2022-01-01T13:33:45',
    quantity: 62.9,
    vehicle: 'DEM111',
    id: 137423,
    tank_volume_start: 39562.2999999999,
    tank_volume_stop: 39499.3999999999,
  },
  {
    timestamp_start: '2022-01-01T13:34:00',
    timestamp_stop: '2022-01-01T13:34:05',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 137425,
    tank_volume_start: 39499.3999999999,
    tank_volume_stop: 39448.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:34:20',
    timestamp_stop: '2022-01-01T13:34:25',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 137427,
    tank_volume_start: 39448.7999999999,
    tank_volume_stop: 39396.6999999999,
  },
  {
    timestamp_start: '2022-01-01T13:34:40',
    timestamp_stop: '2022-01-01T13:34:46',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 137429,
    tank_volume_start: 39396.6999999999,
    tank_volume_stop: 39335.6999999999,
  },
  {
    timestamp_start: '2022-01-01T13:35:01',
    timestamp_stop: '2022-01-01T13:35:06',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 137431,
    tank_volume_start: 39335.6999999999,
    tank_volume_stop: 39282.3999999999,
  },
  {
    timestamp_start: '2022-01-01T13:35:21',
    timestamp_stop: '2022-01-01T13:35:26',
    quantity: 50.9,
    vehicle: 'DEM333',
    id: 137433,
    tank_volume_start: 39282.3999999999,
    tank_volume_stop: 39231.4999999999,
  },
  {
    timestamp_start: '2022-01-01T13:35:41',
    timestamp_stop: '2022-01-01T13:35:47',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 137435,
    tank_volume_start: 39231.4999999999,
    tank_volume_stop: 39177.9999999999,
  },
  {
    timestamp_start: '2022-01-01T13:36:02',
    timestamp_stop: '2022-01-01T13:36:07',
    quantity: 51.9,
    vehicle: 'DEM777',
    id: 137437,
    tank_volume_start: 39177.9999999999,
    tank_volume_stop: 39126.0999999999,
  },
  {
    timestamp_start: '2022-01-01T13:36:22',
    timestamp_stop: '2022-01-01T13:36:27',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 137439,
    tank_volume_start: 39126.0999999999,
    tank_volume_stop: 39076.5999999999,
  },
  {
    timestamp_start: '2022-01-01T13:36:42',
    timestamp_stop: '2022-01-01T13:36:48',
    quantity: 62.2,
    vehicle: 'DEM000',
    id: 137441,
    tank_volume_start: 39076.5999999999,
    tank_volume_stop: 39014.3999999999,
  },
  {
    timestamp_start: '2022-01-01T13:37:03',
    timestamp_stop: '2022-01-01T13:37:08',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 137443,
    tank_volume_start: 39014.3999999999,
    tank_volume_stop: 38962.2999999999,
  },
  {
    timestamp_start: '2022-01-01T13:37:23',
    timestamp_stop: '2022-01-01T13:37:29',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 137445,
    tank_volume_start: 38962.2999999999,
    tank_volume_stop: 38906.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:37:44',
    timestamp_stop: '2022-01-01T13:37:49',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 137447,
    tank_volume_start: 38906.599999999904,
    tank_volume_stop: 38853.39999999991,
  },
  {
    timestamp_start: '2022-01-01T13:38:04',
    timestamp_stop: '2022-01-01T13:38:09',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 137449,
    tank_volume_start: 38853.39999999991,
    tank_volume_stop: 38802.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:38:24',
    timestamp_stop: '2022-01-01T13:38:30',
    quantity: 60.6,
    vehicle: 'DEM666',
    id: 137451,
    tank_volume_start: 38802.499999999905,
    tank_volume_stop: 38741.89999999991,
  },
  {
    timestamp_start: '2022-01-01T13:38:45',
    timestamp_stop: '2022-01-01T13:38:51',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 137453,
    tank_volume_start: 38741.89999999991,
    tank_volume_stop: 38684.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:39:06',
    timestamp_stop: '2022-01-01T13:39:11',
    quantity: 50.4,
    vehicle: 'DEM777',
    id: 137455,
    tank_volume_start: 38684.599999999904,
    tank_volume_stop: 38634.1999999999,
  },
  {
    timestamp_start: '2022-01-01T13:39:26',
    timestamp_stop: '2022-01-01T13:39:32',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 137457,
    tank_volume_start: 38634.1999999999,
    tank_volume_stop: 38577.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:39:47',
    timestamp_stop: '2022-01-01T13:39:52',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 137459,
    tank_volume_start: 38577.7999999999,
    tank_volume_stop: 38526.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:40:07',
    timestamp_stop: '2022-01-01T13:40:13',
    quantity: 56.8,
    vehicle: 'DEM000',
    id: 137461,
    tank_volume_start: 38526.599999999904,
    tank_volume_stop: 38469.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:40:28',
    timestamp_stop: '2022-01-01T13:40:34',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 137463,
    tank_volume_start: 38469.7999999999,
    tank_volume_stop: 38409.8999999999,
  },
  {
    timestamp_start: '2022-01-01T13:40:49',
    timestamp_stop: '2022-01-01T13:40:54',
    quantity: 59.1,
    vehicle: 'DEM111',
    id: 137465,
    tank_volume_start: 38409.8999999999,
    tank_volume_stop: 38350.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:41:09',
    timestamp_stop: '2022-01-01T13:41:15',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 137468,
    tank_volume_start: 38350.7999999999,
    tank_volume_stop: 38294.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:41:30',
    timestamp_stop: '2022-01-01T13:41:35',
    quantity: 49.6,
    vehicle: 'DEM444',
    id: 137470,
    tank_volume_start: 38294.7999999999,
    tank_volume_stop: 38245.1999999999,
  },
  {
    timestamp_start: '2022-01-01T13:41:50',
    timestamp_stop: '2022-01-01T13:41:55',
    quantity: 48.1,
    vehicle: 'DEM444',
    id: 137471,
    tank_volume_start: 38245.1999999999,
    tank_volume_stop: 38197.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:42:10',
    timestamp_stop: '2022-01-01T13:42:15',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 137473,
    tank_volume_start: 38197.099999999904,
    tank_volume_stop: 38143.7999999999,
  },
  {
    timestamp_start: '2022-01-01T13:42:30',
    timestamp_stop: '2022-01-01T13:42:35',
    quantity: 50.7,
    vehicle: 'DEM000',
    id: 137475,
    tank_volume_start: 38143.7999999999,
    tank_volume_stop: 38093.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:42:50',
    timestamp_stop: '2022-01-01T13:42:56',
    quantity: 61.6,
    vehicle: 'DEM888',
    id: 137477,
    tank_volume_start: 38093.099999999904,
    tank_volume_stop: 38031.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:43:11',
    timestamp_stop: '2022-01-01T13:43:16',
    quantity: 49.4,
    vehicle: 'DEM000',
    id: 137479,
    tank_volume_start: 38031.499999999905,
    tank_volume_stop: 37982.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:43:31',
    timestamp_stop: '2022-01-01T13:43:37',
    quantity: 54.9,
    vehicle: 'DEM555',
    id: 137481,
    tank_volume_start: 37982.099999999904,
    tank_volume_stop: 37927.1999999999,
  },
  {
    timestamp_start: '2022-01-01T13:43:52',
    timestamp_stop: '2022-01-01T13:43:57',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 137483,
    tank_volume_start: 37927.1999999999,
    tank_volume_stop: 37879.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:44:12',
    timestamp_stop: '2022-01-01T13:44:18',
    quantity: 63.2,
    vehicle: 'DEM000',
    id: 137485,
    tank_volume_start: 37879.499999999905,
    tank_volume_stop: 37816.29999999991,
  },
  {
    timestamp_start: '2022-01-01T13:44:33',
    timestamp_stop: '2022-01-01T13:44:39',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 137487,
    tank_volume_start: 37816.29999999991,
    tank_volume_stop: 37759.69999999991,
  },
  {
    timestamp_start: '2022-01-01T13:44:54',
    timestamp_stop: '2022-01-01T13:44:59',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 137489,
    tank_volume_start: 37759.69999999991,
    tank_volume_stop: 37707.49999999991,
  },
  {
    timestamp_start: '2022-01-01T13:45:14',
    timestamp_stop: '2022-01-01T13:45:19',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 137491,
    tank_volume_start: 37707.49999999991,
    tank_volume_stop: 37654.69999999991,
  },
  {
    timestamp_start: '2022-01-01T13:45:34',
    timestamp_stop: '2022-01-01T13:45:40',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 137493,
    tank_volume_start: 37654.69999999991,
    tank_volume_stop: 37597.59999999991,
  },
  {
    timestamp_start: '2022-01-01T13:45:55',
    timestamp_stop: '2022-01-01T13:46:01',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 137495,
    tank_volume_start: 37597.59999999991,
    tank_volume_stop: 37539.29999999991,
  },
  {
    timestamp_start: '2022-01-01T13:46:16',
    timestamp_stop: '2022-01-01T13:46:21',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 137497,
    tank_volume_start: 37539.29999999991,
    tank_volume_stop: 37484.79999999991,
  },
  {
    timestamp_start: '2022-01-01T13:46:36',
    timestamp_stop: '2022-01-01T13:46:41',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 137499,
    tank_volume_start: 37484.79999999991,
    tank_volume_stop: 37430.999999999905,
  },
  {
    timestamp_start: '2022-01-01T13:46:56',
    timestamp_stop: '2022-01-01T13:47:01',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 137501,
    tank_volume_start: 37430.999999999905,
    tank_volume_stop: 37382.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:47:16',
    timestamp_stop: '2022-01-01T13:47:23',
    quantity: 64.1,
    vehicle: 'DEM777',
    id: 137503,
    tank_volume_start: 37382.599999999904,
    tank_volume_stop: 37318.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:47:38',
    timestamp_stop: '2022-01-01T13:47:44',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 137505,
    tank_volume_start: 37318.499999999905,
    tank_volume_stop: 37257.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:47:59',
    timestamp_stop: '2022-01-01T13:48:04',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 137507,
    tank_volume_start: 37257.499999999905,
    tank_volume_stop: 37202.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:48:19',
    timestamp_stop: '2022-01-01T13:48:25',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 137509,
    tank_volume_start: 37202.099999999904,
    tank_volume_stop: 37148.999999999905,
  },
  {
    timestamp_start: '2022-01-01T13:48:40',
    timestamp_stop: '2022-01-01T13:48:44',
    quantity: 45.9,
    vehicle: 'DEM000',
    id: 137511,
    tank_volume_start: 37148.999999999905,
    tank_volume_stop: 37103.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:48:59',
    timestamp_stop: '2022-01-01T13:49:05',
    quantity: 62.7,
    vehicle: 'DEM000',
    id: 137513,
    tank_volume_start: 37103.099999999904,
    tank_volume_stop: 37040.39999999991,
  },
  {
    timestamp_start: '2022-01-01T13:49:20',
    timestamp_stop: '2022-01-01T13:49:26',
    quantity: 56.7,
    vehicle: 'DEM777',
    id: 137515,
    tank_volume_start: 37040.39999999991,
    tank_volume_stop: 36983.69999999991,
  },
  {
    timestamp_start: '2022-01-01T13:49:41',
    timestamp_stop: '2022-01-01T13:49:47',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 137517,
    tank_volume_start: 36983.69999999991,
    tank_volume_stop: 36921.59999999991,
  },
  {
    timestamp_start: '2022-01-01T13:50:02',
    timestamp_stop: '2022-01-01T13:50:08',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 137519,
    tank_volume_start: 36921.59999999991,
    tank_volume_stop: 36868.19999999991,
  },
  {
    timestamp_start: '2022-01-01T13:50:23',
    timestamp_stop: '2022-01-01T13:50:28',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 137521,
    tank_volume_start: 36868.19999999991,
    tank_volume_stop: 36811.89999999991,
  },
  {
    timestamp_start: '2022-01-01T13:50:43',
    timestamp_stop: '2022-01-01T13:50:50',
    quantity: 64.1,
    vehicle: 'DEM555',
    id: 137523,
    tank_volume_start: 36811.89999999991,
    tank_volume_stop: 36747.79999999991,
  },
  {
    timestamp_start: '2022-01-01T13:51:05',
    timestamp_stop: '2022-01-01T13:51:10',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 137525,
    tank_volume_start: 36747.79999999991,
    tank_volume_stop: 36690.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:51:25',
    timestamp_stop: '2022-01-01T13:51:30',
    quantity: 48.5,
    vehicle: 'DEM222',
    id: 137528,
    tank_volume_start: 36690.499999999905,
    tank_volume_stop: 36641.999999999905,
  },
  {
    timestamp_start: '2022-01-01T13:51:45',
    timestamp_stop: '2022-01-01T13:51:50',
    quantity: 51.4,
    vehicle: 'DEM333',
    id: 137529,
    tank_volume_start: 36641.999999999905,
    tank_volume_stop: 36590.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:52:05',
    timestamp_stop: '2022-01-01T13:52:11',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 137531,
    tank_volume_start: 36590.599999999904,
    tank_volume_stop: 36539.6999999999,
  },
  {
    timestamp_start: '2022-01-01T13:52:26',
    timestamp_stop: '2022-01-01T13:52:31',
    quantity: 59.6,
    vehicle: 'DEM000',
    id: 137533,
    tank_volume_start: 36539.6999999999,
    tank_volume_stop: 36480.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:52:46',
    timestamp_stop: '2022-01-01T13:52:52',
    quantity: 60.0,
    vehicle: 'DEM000',
    id: 137535,
    tank_volume_start: 36480.099999999904,
    tank_volume_stop: 36420.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:53:07',
    timestamp_stop: '2022-01-01T13:53:12',
    quantity: 48.1,
    vehicle: 'DEM777',
    id: 137537,
    tank_volume_start: 36420.099999999904,
    tank_volume_stop: 36371.999999999905,
  },
  {
    timestamp_start: '2022-01-01T13:53:27',
    timestamp_stop: '2022-01-01T13:53:32',
    quantity: 49.4,
    vehicle: 'DEM777',
    id: 137539,
    tank_volume_start: 36371.999999999905,
    tank_volume_stop: 36322.599999999904,
  },
  {
    timestamp_start: '2022-01-01T13:53:47',
    timestamp_stop: '2022-01-01T13:53:53',
    quantity: 59.1,
    vehicle: 'DEM888',
    id: 137541,
    tank_volume_start: 36322.599999999904,
    tank_volume_stop: 36263.499999999905,
  },
  {
    timestamp_start: '2022-01-01T13:54:08',
    timestamp_stop: '2022-01-01T13:54:14',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 137543,
    tank_volume_start: 36263.499999999905,
    tank_volume_stop: 36204.099999999904,
  },
  {
    timestamp_start: '2022-01-01T13:54:29',
    timestamp_stop: '2022-01-01T13:54:35',
    quantity: 57.1,
    vehicle: 'DEM666',
    id: 137546,
    tank_volume_start: 36204.099999999904,
    tank_volume_stop: 36146.999999999905,
  },
  {
    timestamp_start: '2022-01-01T13:54:50',
    timestamp_stop: '2022-01-01T13:54:55',
    quantity: 52.2,
    vehicle: 'DEM444',
    id: 137548,
    tank_volume_start: 36146.999999999905,
    tank_volume_stop: 36094.79999999991,
  },
  {
    timestamp_start: '2022-01-01T13:55:10',
    timestamp_stop: '2022-01-01T13:55:15',
    quantity: 47.6,
    vehicle: 'DEM111',
    id: 137550,
    tank_volume_start: 36094.79999999991,
    tank_volume_stop: 36047.19999999991,
  },
  {
    timestamp_start: '2022-01-01T13:55:30',
    timestamp_stop: '2022-01-01T13:55:35',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 137551,
    tank_volume_start: 36047.19999999991,
    tank_volume_stop: 35990.09999999991,
  },
  {
    timestamp_start: '2022-01-01T13:55:50',
    timestamp_stop: '2022-01-01T13:55:56',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 137554,
    tank_volume_start: 35990.09999999991,
    tank_volume_stop: 35932.49999999991,
  },
  {
    timestamp_start: '2022-01-01T13:56:11',
    timestamp_stop: '2022-01-01T13:56:17',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 137556,
    tank_volume_start: 35932.49999999991,
    tank_volume_stop: 35878.899999999914,
  },
  {
    timestamp_start: '2022-01-01T13:56:32',
    timestamp_stop: '2022-01-01T13:56:38',
    quantity: 59.2,
    vehicle: 'DEM777',
    id: 137558,
    tank_volume_start: 35878.899999999914,
    tank_volume_stop: 35819.69999999992,
  },
  {
    timestamp_start: '2022-01-01T13:56:53',
    timestamp_stop: '2022-01-01T13:56:58',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 137560,
    tank_volume_start: 35819.69999999992,
    tank_volume_stop: 35765.399999999914,
  },
  {
    timestamp_start: '2022-01-01T13:57:13',
    timestamp_stop: '2022-01-01T13:57:18',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 137562,
    tank_volume_start: 35765.399999999914,
    tank_volume_stop: 35713.299999999916,
  },
  {
    timestamp_start: '2022-01-01T13:57:33',
    timestamp_stop: '2022-01-01T13:57:38',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 137563,
    tank_volume_start: 35713.299999999916,
    tank_volume_stop: 35661.49999999991,
  },
  {
    timestamp_start: '2022-01-01T13:57:53',
    timestamp_stop: '2022-01-01T13:57:58',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 137565,
    tank_volume_start: 35661.49999999991,
    tank_volume_stop: 35611.299999999916,
  },
  {
    timestamp_start: '2022-01-01T13:58:13',
    timestamp_stop: '2022-01-01T13:58:20',
    quantity: 63.9,
    vehicle: 'DEM555',
    id: 137567,
    tank_volume_start: 35611.299999999916,
    tank_volume_stop: 35547.399999999914,
  },
  {
    timestamp_start: '2022-01-01T13:58:35',
    timestamp_stop: '2022-01-01T13:58:40',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 137570,
    tank_volume_start: 35547.399999999914,
    tank_volume_stop: 35499.19999999992,
  },
  {
    timestamp_start: '2022-01-01T13:58:55',
    timestamp_stop: '2022-01-01T13:59:00',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 137571,
    tank_volume_start: 35499.19999999992,
    tank_volume_stop: 35441.19999999992,
  },
  {
    timestamp_start: '2022-01-01T13:59:15',
    timestamp_stop: '2022-01-01T13:59:21',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 137574,
    tank_volume_start: 35441.19999999992,
    tank_volume_stop: 35386.59999999992,
  },
  {
    timestamp_start: '2022-01-01T13:59:36',
    timestamp_stop: '2022-01-01T13:59:42',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 137575,
    tank_volume_start: 35386.59999999992,
    tank_volume_stop: 35324.49999999992,
  },
  {
    timestamp_start: '2022-01-01T13:59:57',
    timestamp_stop: '2022-01-01T14:00:02',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 137578,
    tank_volume_start: 35324.49999999992,
    tank_volume_stop: 35273.69999999992,
  },
  {
    timestamp_start: '2022-01-01T14:00:17',
    timestamp_stop: '2022-01-01T14:00:23',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 137579,
    tank_volume_start: 35273.69999999992,
    tank_volume_stop: 35217.19999999992,
  },
  {
    timestamp_start: '2022-01-01T14:00:38',
    timestamp_stop: '2022-01-01T14:00:43',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 137581,
    tank_volume_start: 35217.19999999992,
    tank_volume_stop: 35163.899999999914,
  },
  {
    timestamp_start: '2022-01-01T14:00:58',
    timestamp_stop: '2022-01-01T14:01:03',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 137583,
    tank_volume_start: 35163.899999999914,
    tank_volume_stop: 35110.69999999992,
  },
  {
    timestamp_start: '2022-01-01T14:01:18',
    timestamp_stop: '2022-01-01T14:01:23',
    quantity: 47.1,
    vehicle: 'DEM666',
    id: 137585,
    tank_volume_start: 35110.69999999992,
    tank_volume_stop: 35063.59999999992,
  },
  {
    timestamp_start: '2022-01-01T14:01:38',
    timestamp_stop: '2022-01-01T14:01:44',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 137587,
    tank_volume_start: 35063.59999999992,
    tank_volume_stop: 35009.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:01:59',
    timestamp_stop: '2022-01-01T14:02:04',
    quantity: 59.7,
    vehicle: 'DEM555',
    id: 137589,
    tank_volume_start: 35009.99999999992,
    tank_volume_stop: 34950.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:02:19',
    timestamp_stop: '2022-01-01T14:02:25',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 137591,
    tank_volume_start: 34950.29999999992,
    tank_volume_stop: 34897.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:02:40',
    timestamp_stop: '2022-01-01T14:02:46',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 137593,
    tank_volume_start: 34897.99999999992,
    tank_volume_stop: 34838.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:03:01',
    timestamp_stop: '2022-01-01T14:03:07',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 137595,
    tank_volume_start: 34838.49999999992,
    tank_volume_stop: 34777.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:03:22',
    timestamp_stop: '2022-01-01T14:03:28',
    quantity: 63.1,
    vehicle: 'DEM333',
    id: 137598,
    tank_volume_start: 34777.99999999992,
    tank_volume_stop: 34714.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:03:43',
    timestamp_stop: '2022-01-01T14:03:49',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 137600,
    tank_volume_start: 34714.89999999992,
    tank_volume_stop: 34658.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:04:04',
    timestamp_stop: '2022-01-01T14:04:08',
    quantity: 47.2,
    vehicle: 'DEM888',
    id: 137602,
    tank_volume_start: 34658.199999999924,
    tank_volume_stop: 34610.99999999993,
  },
  {
    timestamp_start: '2022-01-01T14:04:23',
    timestamp_stop: '2022-01-01T14:04:29',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 137604,
    tank_volume_start: 34610.99999999993,
    tank_volume_stop: 34552.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:04:44',
    timestamp_stop: '2022-01-01T14:04:49',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 137606,
    tank_volume_start: 34552.199999999924,
    tank_volume_stop: 34501.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:05:04',
    timestamp_stop: '2022-01-01T14:05:10',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 137608,
    tank_volume_start: 34501.39999999992,
    tank_volume_stop: 34449.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:05:25',
    timestamp_stop: '2022-01-01T14:05:29',
    quantity: 47.7,
    vehicle: 'DEM666',
    id: 137610,
    tank_volume_start: 34449.79999999992,
    tank_volume_stop: 34402.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:05:44',
    timestamp_stop: '2022-01-01T14:05:50',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 137612,
    tank_volume_start: 34402.099999999926,
    tank_volume_stop: 34344.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:06:05',
    timestamp_stop: '2022-01-01T14:06:11',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 137614,
    tank_volume_start: 34344.79999999992,
    tank_volume_stop: 34287.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:06:26',
    timestamp_stop: '2022-01-01T14:06:32',
    quantity: 62.0,
    vehicle: 'DEM444',
    id: 137616,
    tank_volume_start: 34287.89999999992,
    tank_volume_stop: 34225.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:06:47',
    timestamp_stop: '2022-01-01T14:06:52',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 137618,
    tank_volume_start: 34225.89999999992,
    tank_volume_stop: 34171.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:07:07',
    timestamp_stop: '2022-01-01T14:07:13',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 137620,
    tank_volume_start: 34171.199999999924,
    tank_volume_stop: 34118.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:07:28',
    timestamp_stop: '2022-01-01T14:07:33',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 137622,
    tank_volume_start: 34118.29999999992,
    tank_volume_stop: 34063.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:07:48',
    timestamp_stop: '2022-01-01T14:07:54',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 137624,
    tank_volume_start: 34063.39999999992,
    tank_volume_stop: 34001.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:08:09',
    timestamp_stop: '2022-01-01T14:08:14',
    quantity: 50.9,
    vehicle: 'DEM222',
    id: 137626,
    tank_volume_start: 34001.29999999992,
    tank_volume_stop: 33950.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:08:29',
    timestamp_stop: '2022-01-01T14:08:35',
    quantity: 51.0,
    vehicle: 'DEM333',
    id: 137628,
    tank_volume_start: 33950.39999999992,
    tank_volume_stop: 33899.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:08:50',
    timestamp_stop: '2022-01-01T14:08:55',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 137630,
    tank_volume_start: 33899.39999999992,
    tank_volume_stop: 33846.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:09:10',
    timestamp_stop: '2022-01-01T14:09:16',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 137632,
    tank_volume_start: 33846.699999999924,
    tank_volume_stop: 33789.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:09:31',
    timestamp_stop: '2022-01-01T14:09:37',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 137634,
    tank_volume_start: 33789.699999999924,
    tank_volume_stop: 33729.599999999926,
  },
  {
    timestamp_start: '2022-01-01T14:09:52',
    timestamp_stop: '2022-01-01T14:09:57',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 137636,
    tank_volume_start: 33729.599999999926,
    tank_volume_stop: 33673.599999999926,
  },
  {
    timestamp_start: '2022-01-01T14:10:12',
    timestamp_stop: '2022-01-01T14:10:17',
    quantity: 51.5,
    vehicle: 'DEM888',
    id: 137638,
    tank_volume_start: 33673.599999999926,
    tank_volume_stop: 33622.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:10:32',
    timestamp_stop: '2022-01-01T14:10:37',
    quantity: 50.5,
    vehicle: 'DEM222',
    id: 137640,
    tank_volume_start: 33622.099999999926,
    tank_volume_stop: 33571.599999999926,
  },
  {
    timestamp_start: '2022-01-01T14:10:52',
    timestamp_stop: '2022-01-01T14:10:57',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 137642,
    tank_volume_start: 33571.599999999926,
    tank_volume_stop: 33520.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:11:12',
    timestamp_stop: '2022-01-01T14:11:19',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 137643,
    tank_volume_start: 33520.79999999992,
    tank_volume_stop: 33459.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:11:34',
    timestamp_stop: '2022-01-01T14:11:39',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 137646,
    tank_volume_start: 33459.49999999992,
    tank_volume_stop: 33407.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:11:54',
    timestamp_stop: '2022-01-01T14:11:59',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 137647,
    tank_volume_start: 33407.29999999992,
    tank_volume_stop: 33351.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:12:14',
    timestamp_stop: '2022-01-01T14:12:20',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 137649,
    tank_volume_start: 33351.79999999992,
    tank_volume_stop: 33294.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:12:35',
    timestamp_stop: '2022-01-01T14:12:41',
    quantity: 57.7,
    vehicle: 'DEM111',
    id: 137651,
    tank_volume_start: 33294.79999999992,
    tank_volume_stop: 33237.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:12:56',
    timestamp_stop: '2022-01-01T14:13:01',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 137654,
    tank_volume_start: 33237.099999999926,
    tank_volume_stop: 33187.599999999926,
  },
  {
    timestamp_start: '2022-01-01T14:13:16',
    timestamp_stop: '2022-01-01T14:13:21',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 137656,
    tank_volume_start: 33187.599999999926,
    tank_volume_stop: 33135.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:13:36',
    timestamp_stop: '2022-01-01T14:13:41',
    quantity: 47.5,
    vehicle: 'DEM111',
    id: 137658,
    tank_volume_start: 33135.699999999924,
    tank_volume_stop: 33088.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:13:56',
    timestamp_stop: '2022-01-01T14:14:02',
    quantity: 62.3,
    vehicle: 'DEM222',
    id: 137659,
    tank_volume_start: 33088.199999999924,
    tank_volume_stop: 33025.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:14:17',
    timestamp_stop: '2022-01-01T14:14:23',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 137662,
    tank_volume_start: 33025.89999999992,
    tank_volume_stop: 32966.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:14:38',
    timestamp_stop: '2022-01-01T14:14:43',
    quantity: 48.8,
    vehicle: 'DEM888',
    id: 137664,
    tank_volume_start: 32966.39999999992,
    tank_volume_stop: 32917.59999999992,
  },
  {
    timestamp_start: '2022-01-01T14:14:58',
    timestamp_stop: '2022-01-01T14:15:03',
    quantity: 49.5,
    vehicle: 'DEM555',
    id: 137666,
    tank_volume_start: 32917.59999999992,
    tank_volume_stop: 32868.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:15:18',
    timestamp_stop: '2022-01-01T14:15:23',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 137667,
    tank_volume_start: 32868.09999999992,
    tank_volume_stop: 32816.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:15:38',
    timestamp_stop: '2022-01-01T14:15:43',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 137669,
    tank_volume_start: 32816.39999999992,
    tank_volume_stop: 32762.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:15:58',
    timestamp_stop: '2022-01-01T14:16:03',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 137671,
    tank_volume_start: 32762.99999999992,
    tank_volume_stop: 32715.19999999992,
  },
  {
    timestamp_start: '2022-01-01T14:16:18',
    timestamp_stop: '2022-01-01T14:16:24',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 137673,
    tank_volume_start: 32715.19999999992,
    tank_volume_stop: 32659.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:16:39',
    timestamp_stop: '2022-01-01T14:16:44',
    quantity: 58.9,
    vehicle: 'DEM444',
    id: 137675,
    tank_volume_start: 32659.49999999992,
    tank_volume_stop: 32600.59999999992,
  },
  {
    timestamp_start: '2022-01-01T14:16:59',
    timestamp_stop: '2022-01-01T14:17:06',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 137677,
    tank_volume_start: 32600.59999999992,
    tank_volume_stop: 32539.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:17:21',
    timestamp_stop: '2022-01-01T14:17:26',
    quantity: 50.6,
    vehicle: 'DEM777',
    id: 137679,
    tank_volume_start: 32539.49999999992,
    tank_volume_stop: 32488.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:17:41',
    timestamp_stop: '2022-01-01T14:17:45',
    quantity: 48.4,
    vehicle: 'DEM444',
    id: 137681,
    tank_volume_start: 32488.89999999992,
    tank_volume_stop: 32440.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:18:00',
    timestamp_stop: '2022-01-01T14:18:06',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 137683,
    tank_volume_start: 32440.49999999992,
    tank_volume_stop: 32388.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:18:21',
    timestamp_stop: '2022-01-01T14:18:26',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 137685,
    tank_volume_start: 32388.29999999992,
    tank_volume_stop: 32331.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:18:41',
    timestamp_stop: '2022-01-01T14:18:47',
    quantity: 57.9,
    vehicle: 'DEM777',
    id: 137687,
    tank_volume_start: 32331.09999999992,
    tank_volume_stop: 32273.199999999917,
  },
  {
    timestamp_start: '2022-01-01T14:19:02',
    timestamp_stop: '2022-01-01T14:19:08',
    quantity: 60.0,
    vehicle: 'DEM000',
    id: 137689,
    tank_volume_start: 32273.199999999917,
    tank_volume_stop: 32213.199999999917,
  },
  {
    timestamp_start: '2022-01-01T14:19:23',
    timestamp_stop: '2022-01-01T14:19:29',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 137691,
    tank_volume_start: 32213.199999999917,
    tank_volume_stop: 32152.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:19:44',
    timestamp_stop: '2022-01-01T14:19:49',
    quantity: 45.8,
    vehicle: 'DEM777',
    id: 137693,
    tank_volume_start: 32152.09999999992,
    tank_volume_stop: 32106.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:20:04',
    timestamp_stop: '2022-01-01T14:20:09',
    quantity: 52.0,
    vehicle: 'DEM555',
    id: 137695,
    tank_volume_start: 32106.29999999992,
    tank_volume_stop: 32054.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:20:24',
    timestamp_stop: '2022-01-01T14:20:30',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 137697,
    tank_volume_start: 32054.29999999992,
    tank_volume_stop: 31997.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:20:45',
    timestamp_stop: '2022-01-01T14:20:50',
    quantity: 53.2,
    vehicle: 'DEM111',
    id: 137699,
    tank_volume_start: 31997.99999999992,
    tank_volume_stop: 31944.79999999992,
  },
  {
    timestamp_start: '2022-01-01T14:21:05',
    timestamp_stop: '2022-01-01T14:21:11',
    quantity: 58.4,
    vehicle: 'DEM888',
    id: 137701,
    tank_volume_start: 31944.79999999992,
    tank_volume_stop: 31886.399999999918,
  },
  {
    timestamp_start: '2022-01-01T14:21:26',
    timestamp_stop: '2022-01-01T14:21:30',
    quantity: 46.0,
    vehicle: 'DEM333',
    id: 137703,
    tank_volume_start: 31886.399999999918,
    tank_volume_stop: 31840.399999999918,
  },
  {
    timestamp_start: '2022-01-01T14:21:45',
    timestamp_stop: '2022-01-01T14:21:51',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 137705,
    tank_volume_start: 31840.399999999918,
    tank_volume_stop: 31787.999999999916,
  },
  {
    timestamp_start: '2022-01-01T14:22:06',
    timestamp_stop: '2022-01-01T14:22:11',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 137707,
    tank_volume_start: 31787.999999999916,
    tank_volume_stop: 31739.699999999917,
  },
  {
    timestamp_start: '2022-01-01T14:22:26',
    timestamp_stop: '2022-01-01T14:22:31',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 137709,
    tank_volume_start: 31739.699999999917,
    tank_volume_stop: 31686.999999999916,
  },
  {
    timestamp_start: '2022-01-01T14:22:46',
    timestamp_stop: '2022-01-01T14:22:52',
    quantity: 58.6,
    vehicle: 'DEM444',
    id: 137711,
    tank_volume_start: 31686.999999999916,
    tank_volume_stop: 31628.399999999918,
  },
  {
    timestamp_start: '2022-01-01T14:23:07',
    timestamp_stop: '2022-01-01T14:23:12',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 137713,
    tank_volume_start: 31628.399999999918,
    tank_volume_stop: 31572.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:23:27',
    timestamp_stop: '2022-01-01T14:23:32',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 137715,
    tank_volume_start: 31572.29999999992,
    tank_volume_stop: 31520.19999999992,
  },
  {
    timestamp_start: '2022-01-01T14:23:47',
    timestamp_stop: '2022-01-01T14:23:53',
    quantity: 51.6,
    vehicle: 'DEM888',
    id: 137717,
    tank_volume_start: 31520.19999999992,
    tank_volume_stop: 31468.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:24:08',
    timestamp_stop: '2022-01-01T14:24:13',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 137719,
    tank_volume_start: 31468.599999999922,
    tank_volume_stop: 31414.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:24:28',
    timestamp_stop: '2022-01-01T14:24:33',
    quantity: 53.0,
    vehicle: 'DEM777',
    id: 137721,
    tank_volume_start: 31414.499999999924,
    tank_volume_stop: 31361.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:24:48',
    timestamp_stop: '2022-01-01T14:24:54',
    quantity: 55.2,
    vehicle: 'DEM111',
    id: 137723,
    tank_volume_start: 31361.499999999924,
    tank_volume_stop: 31306.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:25:09',
    timestamp_stop: '2022-01-01T14:25:15',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 137725,
    tank_volume_start: 31306.299999999923,
    tank_volume_stop: 31244.799999999923,
  },
  {
    timestamp_start: '2022-01-01T14:25:30',
    timestamp_stop: '2022-01-01T14:25:35',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 137727,
    tank_volume_start: 31244.799999999923,
    tank_volume_stop: 31191.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:25:50',
    timestamp_stop: '2022-01-01T14:25:56',
    quantity: 53.2,
    vehicle: 'DEM111',
    id: 137729,
    tank_volume_start: 31191.299999999923,
    tank_volume_stop: 31138.099999999922,
  },
  {
    timestamp_start: '2022-01-01T14:26:11',
    timestamp_stop: '2022-01-01T14:26:16',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 137731,
    tank_volume_start: 31138.099999999922,
    tank_volume_stop: 31083.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:26:31',
    timestamp_stop: '2022-01-01T14:26:36',
    quantity: 47.8,
    vehicle: 'DEM111',
    id: 137733,
    tank_volume_start: 31083.599999999922,
    tank_volume_stop: 31035.799999999923,
  },
  {
    timestamp_start: '2022-01-01T14:26:51',
    timestamp_stop: '2022-01-01T14:26:56',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 137735,
    tank_volume_start: 31035.799999999923,
    tank_volume_stop: 30987.799999999923,
  },
  {
    timestamp_start: '2022-01-01T14:27:11',
    timestamp_stop: '2022-01-01T14:27:16',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 137737,
    tank_volume_start: 30987.799999999923,
    tank_volume_stop: 30930.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:27:31',
    timestamp_stop: '2022-01-01T14:27:37',
    quantity: 50.8,
    vehicle: 'DEM000',
    id: 137739,
    tank_volume_start: 30930.299999999923,
    tank_volume_stop: 30879.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:27:52',
    timestamp_stop: '2022-01-01T14:27:57',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 137741,
    tank_volume_start: 30879.499999999924,
    tank_volume_stop: 30824.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:28:12',
    timestamp_stop: '2022-01-01T14:28:17',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 137743,
    tank_volume_start: 30824.599999999922,
    tank_volume_stop: 30772.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:28:32',
    timestamp_stop: '2022-01-01T14:28:39',
    quantity: 64.3,
    vehicle: 'DEM444',
    id: 137745,
    tank_volume_start: 30772.89999999992,
    tank_volume_stop: 30708.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:28:54',
    timestamp_stop: '2022-01-01T14:28:59',
    quantity: 49.4,
    vehicle: 'DEM111',
    id: 137747,
    tank_volume_start: 30708.599999999922,
    tank_volume_stop: 30659.19999999992,
  },
  {
    timestamp_start: '2022-01-01T14:29:14',
    timestamp_stop: '2022-01-01T14:29:18',
    quantity: 48.9,
    vehicle: 'DEM111',
    id: 137749,
    tank_volume_start: 30659.19999999992,
    tank_volume_stop: 30610.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:29:33',
    timestamp_stop: '2022-01-01T14:29:39',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 137751,
    tank_volume_start: 30610.29999999992,
    tank_volume_stop: 30553.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:29:54',
    timestamp_stop: '2022-01-01T14:29:59',
    quantity: 50.6,
    vehicle: 'DEM666',
    id: 137753,
    tank_volume_start: 30553.49999999992,
    tank_volume_stop: 30502.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:30:14',
    timestamp_stop: '2022-01-01T14:30:20',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 137755,
    tank_volume_start: 30502.89999999992,
    tank_volume_stop: 30446.799999999923,
  },
  {
    timestamp_start: '2022-01-01T14:30:35',
    timestamp_stop: '2022-01-01T14:30:40',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 137757,
    tank_volume_start: 30446.799999999923,
    tank_volume_stop: 30397.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:30:55',
    timestamp_stop: '2022-01-01T14:31:00',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 137759,
    tank_volume_start: 30397.299999999923,
    tank_volume_stop: 30344.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:31:15',
    timestamp_stop: '2022-01-01T14:31:20',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 137761,
    tank_volume_start: 30344.499999999924,
    tank_volume_stop: 30291.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:31:35',
    timestamp_stop: '2022-01-01T14:31:41',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 137763,
    tank_volume_start: 30291.299999999923,
    tank_volume_stop: 30232.999999999924,
  },
  {
    timestamp_start: '2022-01-01T14:31:56',
    timestamp_stop: '2022-01-01T14:32:02',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 137765,
    tank_volume_start: 30232.999999999924,
    tank_volume_stop: 30174.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:32:17',
    timestamp_stop: '2022-01-01T14:32:22',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 137767,
    tank_volume_start: 30174.199999999924,
    tank_volume_stop: 30123.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:32:37',
    timestamp_stop: '2022-01-01T14:32:43',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 137769,
    tank_volume_start: 30123.199999999924,
    tank_volume_stop: 30063.399999999925,
  },
  {
    timestamp_start: '2022-01-01T14:32:58',
    timestamp_stop: '2022-01-01T14:33:03',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 137771,
    tank_volume_start: 30063.399999999925,
    tank_volume_stop: 30011.399999999925,
  },
  {
    timestamp_start: '2022-01-01T14:33:18',
    timestamp_stop: '2022-01-01T14:33:24',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 137773,
    tank_volume_start: 30011.399999999925,
    tank_volume_stop: 29957.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:33:39',
    timestamp_stop: '2022-01-01T14:33:44',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 137775,
    tank_volume_start: 29957.899999999925,
    tank_volume_stop: 29905.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:33:59',
    timestamp_stop: '2022-01-01T14:34:05',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 137777,
    tank_volume_start: 29905.699999999924,
    tank_volume_stop: 29849.999999999924,
  },
  {
    timestamp_start: '2022-01-01T14:34:20',
    timestamp_stop: '2022-01-01T14:34:24',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 137779,
    tank_volume_start: 29849.999999999924,
    tank_volume_stop: 29801.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:34:39',
    timestamp_stop: '2022-01-01T14:34:45',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 137781,
    tank_volume_start: 29801.599999999922,
    tank_volume_stop: 29748.799999999923,
  },
  {
    timestamp_start: '2022-01-01T14:35:00',
    timestamp_stop: '2022-01-01T14:35:05',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 137783,
    tank_volume_start: 29748.799999999923,
    tank_volume_stop: 29698.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:35:20',
    timestamp_stop: '2022-01-01T14:35:25',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 137785,
    tank_volume_start: 29698.39999999992,
    tank_volume_stop: 29642.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:35:40',
    timestamp_stop: '2022-01-01T14:35:46',
    quantity: 56.4,
    vehicle: 'DEM777',
    id: 137787,
    tank_volume_start: 29642.39999999992,
    tank_volume_stop: 29585.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:36:01',
    timestamp_stop: '2022-01-01T14:36:07',
    quantity: 61.0,
    vehicle: 'DEM333',
    id: 137789,
    tank_volume_start: 29585.99999999992,
    tank_volume_stop: 29524.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:36:22',
    timestamp_stop: '2022-01-01T14:36:27',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 137791,
    tank_volume_start: 29524.99999999992,
    tank_volume_stop: 29470.69999999992,
  },
  {
    timestamp_start: '2022-01-01T14:36:42',
    timestamp_stop: '2022-01-01T14:36:49',
    quantity: 61.7,
    vehicle: 'DEM777',
    id: 137793,
    tank_volume_start: 29470.69999999992,
    tank_volume_stop: 29408.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:37:04',
    timestamp_stop: '2022-01-01T14:37:09',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 137795,
    tank_volume_start: 29408.99999999992,
    tank_volume_stop: 29352.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:37:24',
    timestamp_stop: '2022-01-01T14:37:30',
    quantity: 57.1,
    vehicle: 'DEM111',
    id: 137797,
    tank_volume_start: 29352.99999999992,
    tank_volume_stop: 29295.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:37:45',
    timestamp_stop: '2022-01-01T14:37:51',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 137799,
    tank_volume_start: 29295.89999999992,
    tank_volume_stop: 29235.39999999992,
  },
  {
    timestamp_start: '2022-01-01T14:38:06',
    timestamp_stop: '2022-01-01T14:38:11',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 137801,
    tank_volume_start: 29235.39999999992,
    tank_volume_stop: 29182.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:38:26',
    timestamp_stop: '2022-01-01T14:38:32',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 137803,
    tank_volume_start: 29182.599999999922,
    tank_volume_stop: 29127.099999999922,
  },
  {
    timestamp_start: '2022-01-01T14:38:47',
    timestamp_stop: '2022-01-01T14:38:52',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 137805,
    tank_volume_start: 29127.099999999922,
    tank_volume_stop: 29076.099999999922,
  },
  {
    timestamp_start: '2022-01-01T14:39:07',
    timestamp_stop: '2022-01-01T14:39:12',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 137807,
    tank_volume_start: 29076.099999999922,
    tank_volume_stop: 29024.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:39:27',
    timestamp_stop: '2022-01-01T14:39:33',
    quantity: 63.1,
    vehicle: 'DEM777',
    id: 137809,
    tank_volume_start: 29024.499999999924,
    tank_volume_stop: 28961.399999999925,
  },
  {
    timestamp_start: '2022-01-01T14:39:48',
    timestamp_stop: '2022-01-01T14:39:54',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 137811,
    tank_volume_start: 28961.399999999925,
    tank_volume_stop: 28908.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:40:09',
    timestamp_stop: '2022-01-01T14:40:14',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 137813,
    tank_volume_start: 28908.899999999925,
    tank_volume_stop: 28856.999999999924,
  },
  {
    timestamp_start: '2022-01-01T14:40:29',
    timestamp_stop: '2022-01-01T14:40:34',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 137815,
    tank_volume_start: 28856.999999999924,
    tank_volume_stop: 28805.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:40:49',
    timestamp_stop: '2022-01-01T14:40:54',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 137817,
    tank_volume_start: 28805.499999999924,
    tank_volume_stop: 28751.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:41:09',
    timestamp_stop: '2022-01-01T14:41:15',
    quantity: 58.0,
    vehicle: 'DEM666',
    id: 137819,
    tank_volume_start: 28751.499999999924,
    tank_volume_stop: 28693.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:41:30',
    timestamp_stop: '2022-01-01T14:41:35',
    quantity: 52.6,
    vehicle: 'DEM333',
    id: 137821,
    tank_volume_start: 28693.499999999924,
    tank_volume_stop: 28640.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:41:50',
    timestamp_stop: '2022-01-01T14:41:56',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 137823,
    tank_volume_start: 28640.899999999925,
    tank_volume_stop: 28586.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:42:11',
    timestamp_stop: '2022-01-01T14:42:17',
    quantity: 64.0,
    vehicle: 'DEM222',
    id: 137825,
    tank_volume_start: 28586.099999999926,
    tank_volume_stop: 28522.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:42:32',
    timestamp_stop: '2022-01-01T14:42:39',
    quantity: 62.1,
    vehicle: 'DEM000',
    id: 137827,
    tank_volume_start: 28522.099999999926,
    tank_volume_stop: 28459.999999999927,
  },
  {
    timestamp_start: '2022-01-01T14:42:54',
    timestamp_stop: '2022-01-01T14:43:00',
    quantity: 61.9,
    vehicle: 'DEM000',
    id: 137829,
    tank_volume_start: 28459.999999999927,
    tank_volume_stop: 28398.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:43:15',
    timestamp_stop: '2022-01-01T14:43:20',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 137831,
    tank_volume_start: 28398.099999999926,
    tank_volume_stop: 28340.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:43:35',
    timestamp_stop: '2022-01-01T14:43:42',
    quantity: 65.0,
    vehicle: 'DEM777',
    id: 137833,
    tank_volume_start: 28340.699999999924,
    tank_volume_stop: 28275.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:43:57',
    timestamp_stop: '2022-01-01T14:44:02',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 137835,
    tank_volume_start: 28275.699999999924,
    tank_volume_stop: 28227.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:44:17',
    timestamp_stop: '2022-01-01T14:44:22',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 137837,
    tank_volume_start: 28227.699999999924,
    tank_volume_stop: 28176.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:44:37',
    timestamp_stop: '2022-01-01T14:44:43',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 137839,
    tank_volume_start: 28176.099999999926,
    tank_volume_stop: 28119.699999999924,
  },
  {
    timestamp_start: '2022-01-01T14:44:58',
    timestamp_stop: '2022-01-01T14:45:02',
    quantity: 49.6,
    vehicle: 'DEM333',
    id: 137841,
    tank_volume_start: 28119.699999999924,
    tank_volume_stop: 28070.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:45:17',
    timestamp_stop: '2022-01-01T14:45:23',
    quantity: 59.5,
    vehicle: 'DEM555',
    id: 137843,
    tank_volume_start: 28070.099999999926,
    tank_volume_stop: 28010.599999999926,
  },
  {
    timestamp_start: '2022-01-01T14:45:38',
    timestamp_stop: '2022-01-01T14:45:44',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 137845,
    tank_volume_start: 28010.599999999926,
    tank_volume_stop: 27954.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:45:59',
    timestamp_stop: '2022-01-01T14:46:04',
    quantity: 52.8,
    vehicle: 'DEM111',
    id: 137847,
    tank_volume_start: 27954.899999999925,
    tank_volume_stop: 27902.099999999926,
  },
  {
    timestamp_start: '2022-01-01T14:46:19',
    timestamp_stop: '2022-01-01T14:46:25',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 137849,
    tank_volume_start: 27902.099999999926,
    tank_volume_stop: 27846.799999999927,
  },
  {
    timestamp_start: '2022-01-01T14:46:40',
    timestamp_stop: '2022-01-01T14:46:45',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 137851,
    tank_volume_start: 27846.799999999927,
    tank_volume_stop: 27790.699999999928,
  },
  {
    timestamp_start: '2022-01-01T14:47:00',
    timestamp_stop: '2022-01-01T14:47:06',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 137853,
    tank_volume_start: 27790.699999999928,
    tank_volume_stop: 27734.199999999928,
  },
  {
    timestamp_start: '2022-01-01T14:47:21',
    timestamp_stop: '2022-01-01T14:47:27',
    quantity: 62.6,
    vehicle: 'DEM777',
    id: 137855,
    tank_volume_start: 27734.199999999928,
    tank_volume_stop: 27671.59999999993,
  },
  {
    timestamp_start: '2022-01-01T14:47:42',
    timestamp_stop: '2022-01-01T14:47:49',
    quantity: 64.1,
    vehicle: 'DEM111',
    id: 137857,
    tank_volume_start: 27671.59999999993,
    tank_volume_stop: 27607.49999999993,
  },
  {
    timestamp_start: '2022-01-01T14:48:04',
    timestamp_stop: '2022-01-01T14:48:09',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 137859,
    tank_volume_start: 27607.49999999993,
    tank_volume_stop: 27559.79999999993,
  },
  {
    timestamp_start: '2022-01-01T14:48:24',
    timestamp_stop: '2022-01-01T14:48:29',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 137861,
    tank_volume_start: 27559.79999999993,
    tank_volume_stop: 27500.79999999993,
  },
  {
    timestamp_start: '2022-01-01T14:48:44',
    timestamp_stop: '2022-01-01T14:48:51',
    quantity: 63.1,
    vehicle: 'DEM111',
    id: 137863,
    tank_volume_start: 27500.79999999993,
    tank_volume_stop: 27437.69999999993,
  },
  {
    timestamp_start: '2022-01-01T14:49:06',
    timestamp_stop: '2022-01-01T14:49:12',
    quantity: 61.4,
    vehicle: 'DEM222',
    id: 137865,
    tank_volume_start: 27437.69999999993,
    tank_volume_stop: 27376.29999999993,
  },
  {
    timestamp_start: '2022-01-01T14:49:27',
    timestamp_stop: '2022-01-01T14:49:32',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 137867,
    tank_volume_start: 27376.29999999993,
    tank_volume_stop: 27323.19999999993,
  },
  {
    timestamp_start: '2022-01-01T14:49:47',
    timestamp_stop: '2022-01-01T14:49:53',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 137869,
    tank_volume_start: 27323.19999999993,
    tank_volume_stop: 27267.69999999993,
  },
  {
    timestamp_start: '2022-01-01T14:50:08',
    timestamp_stop: '2022-01-01T14:50:13',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 137871,
    tank_volume_start: 27267.69999999993,
    tank_volume_stop: 27218.19999999993,
  },
  {
    timestamp_start: '2022-01-01T14:50:28',
    timestamp_stop: '2022-01-01T14:50:33',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 137873,
    tank_volume_start: 27218.19999999993,
    tank_volume_stop: 27165.29999999993,
  },
  {
    timestamp_start: '2022-01-01T14:50:48',
    timestamp_stop: '2022-01-01T14:50:53',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 137875,
    tank_volume_start: 27165.29999999993,
    tank_volume_stop: 27111.89999999993,
  },
  {
    timestamp_start: '2022-01-01T14:51:08',
    timestamp_stop: '2022-01-01T14:51:13',
    quantity: 46.3,
    vehicle: 'DEM888',
    id: 137877,
    tank_volume_start: 27111.89999999993,
    tank_volume_stop: 27065.59999999993,
  },
  {
    timestamp_start: '2022-01-01T14:51:28',
    timestamp_stop: '2022-01-01T14:51:33',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 137879,
    tank_volume_start: 27065.59999999993,
    tank_volume_stop: 27013.199999999928,
  },
  {
    timestamp_start: '2022-01-01T14:51:48',
    timestamp_stop: '2022-01-01T14:51:53',
    quantity: 46.7,
    vehicle: 'DEM333',
    id: 137881,
    tank_volume_start: 27013.199999999928,
    tank_volume_stop: 26966.499999999927,
  },
  {
    timestamp_start: '2022-01-01T14:52:08',
    timestamp_stop: '2022-01-01T14:52:13',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 137883,
    tank_volume_start: 26966.499999999927,
    tank_volume_stop: 26913.799999999927,
  },
  {
    timestamp_start: '2022-01-01T14:52:28',
    timestamp_stop: '2022-01-01T14:52:33',
    quantity: 48.5,
    vehicle: 'DEM666',
    id: 137885,
    tank_volume_start: 26913.799999999927,
    tank_volume_stop: 26865.299999999927,
  },
  {
    timestamp_start: '2022-01-01T14:52:48',
    timestamp_stop: '2022-01-01T14:52:54',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 137887,
    tank_volume_start: 26865.299999999927,
    tank_volume_stop: 26804.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:53:09',
    timestamp_stop: '2022-01-01T14:53:15',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 137889,
    tank_volume_start: 26804.899999999925,
    tank_volume_stop: 26748.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:53:30',
    timestamp_stop: '2022-01-01T14:53:35',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 137891,
    tank_volume_start: 26748.499999999924,
    tank_volume_stop: 26691.299999999923,
  },
  {
    timestamp_start: '2022-01-01T14:53:50',
    timestamp_stop: '2022-01-01T14:53:56',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 137893,
    tank_volume_start: 26691.299999999923,
    tank_volume_stop: 26638.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:54:11',
    timestamp_stop: '2022-01-01T14:54:16',
    quantity: 55.3,
    vehicle: 'DEM111',
    id: 137895,
    tank_volume_start: 26638.199999999924,
    tank_volume_stop: 26582.899999999925,
  },
  {
    timestamp_start: '2022-01-01T14:54:31',
    timestamp_stop: '2022-01-01T14:54:36',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 137897,
    tank_volume_start: 26582.899999999925,
    tank_volume_stop: 26530.199999999924,
  },
  {
    timestamp_start: '2022-01-01T14:54:51',
    timestamp_stop: '2022-01-01T14:54:56',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 137899,
    tank_volume_start: 26530.199999999924,
    tank_volume_stop: 26482.499999999924,
  },
  {
    timestamp_start: '2022-01-01T14:55:11',
    timestamp_stop: '2022-01-01T14:55:16',
    quantity: 49.9,
    vehicle: 'DEM000',
    id: 137901,
    tank_volume_start: 26482.499999999924,
    tank_volume_stop: 26432.599999999922,
  },
  {
    timestamp_start: '2022-01-01T14:55:31',
    timestamp_stop: '2022-01-01T14:55:37',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 137903,
    tank_volume_start: 26432.599999999922,
    tank_volume_stop: 26373.89999999992,
  },
  {
    timestamp_start: '2022-01-01T14:55:52',
    timestamp_stop: '2022-01-01T14:55:58',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 137905,
    tank_volume_start: 26373.89999999992,
    tank_volume_stop: 26316.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:56:13',
    timestamp_stop: '2022-01-01T14:56:18',
    quantity: 48.9,
    vehicle: 'DEM555',
    id: 137907,
    tank_volume_start: 26316.99999999992,
    tank_volume_stop: 26268.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:56:33',
    timestamp_stop: '2022-01-01T14:56:39',
    quantity: 61.1,
    vehicle: 'DEM666',
    id: 137909,
    tank_volume_start: 26268.09999999992,
    tank_volume_stop: 26206.99999999992,
  },
  {
    timestamp_start: '2022-01-01T14:56:54',
    timestamp_stop: '2022-01-01T14:56:59',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 137911,
    tank_volume_start: 26206.99999999992,
    tank_volume_stop: 26151.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:57:14',
    timestamp_stop: '2022-01-01T14:57:20',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 137913,
    tank_volume_start: 26151.09999999992,
    tank_volume_stop: 26096.699999999917,
  },
  {
    timestamp_start: '2022-01-01T14:57:35',
    timestamp_stop: '2022-01-01T14:57:39',
    quantity: 45.6,
    vehicle: 'DEM888',
    id: 137915,
    tank_volume_start: 26096.699999999917,
    tank_volume_stop: 26051.09999999992,
  },
  {
    timestamp_start: '2022-01-01T14:57:54',
    timestamp_stop: '2022-01-01T14:58:00',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 137917,
    tank_volume_start: 26051.09999999992,
    tank_volume_stop: 25998.199999999917,
  },
  {
    timestamp_start: '2022-01-01T14:58:15',
    timestamp_stop: '2022-01-01T14:58:20',
    quantity: 52.2,
    vehicle: 'DEM222',
    id: 137919,
    tank_volume_start: 25998.199999999917,
    tank_volume_stop: 25945.999999999916,
  },
  {
    timestamp_start: '2022-01-01T14:58:35',
    timestamp_stop: '2022-01-01T14:58:40',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 137921,
    tank_volume_start: 25945.999999999916,
    tank_volume_stop: 25891.699999999917,
  },
  {
    timestamp_start: '2022-01-01T14:58:55',
    timestamp_stop: '2022-01-01T14:59:01',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 137923,
    tank_volume_start: 25891.699999999917,
    tank_volume_stop: 25835.59999999992,
  },
  {
    timestamp_start: '2022-01-01T14:59:16',
    timestamp_stop: '2022-01-01T14:59:22',
    quantity: 62.1,
    vehicle: 'DEM777',
    id: 137925,
    tank_volume_start: 25835.59999999992,
    tank_volume_stop: 25773.49999999992,
  },
  {
    timestamp_start: '2022-01-01T14:59:37',
    timestamp_stop: '2022-01-01T14:59:42',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 137927,
    tank_volume_start: 25773.49999999992,
    tank_volume_stop: 25720.29999999992,
  },
  {
    timestamp_start: '2022-01-01T14:59:57',
    timestamp_stop: '2022-01-01T15:00:03',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 137929,
    tank_volume_start: 25720.29999999992,
    tank_volume_stop: 25666.99999999992,
  },
  {
    timestamp_start: '2022-01-01T15:00:18',
    timestamp_stop: '2022-01-01T15:00:24',
    quantity: 64.6,
    vehicle: 'DEM000',
    id: 137931,
    tank_volume_start: 25666.99999999992,
    tank_volume_stop: 25602.39999999992,
  },
  {
    timestamp_start: '2022-01-01T15:00:39',
    timestamp_stop: '2022-01-01T15:00:45',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 137933,
    tank_volume_start: 25602.39999999992,
    tank_volume_stop: 25544.099999999922,
  },
  {
    timestamp_start: '2022-01-01T15:01:00',
    timestamp_stop: '2022-01-01T15:01:06',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 137935,
    tank_volume_start: 25544.099999999922,
    tank_volume_stop: 25487.89999999992,
  },
  {
    timestamp_start: '2022-01-01T15:01:21',
    timestamp_stop: '2022-01-01T15:01:26',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 137937,
    tank_volume_start: 25487.89999999992,
    tank_volume_stop: 25431.69999999992,
  },
  {
    timestamp_start: '2022-01-01T15:01:41',
    timestamp_stop: '2022-01-01T15:01:47',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 137939,
    tank_volume_start: 25431.69999999992,
    tank_volume_stop: 25372.29999999992,
  },
  {
    timestamp_start: '2022-01-01T15:02:02',
    timestamp_stop: '2022-01-01T15:02:07',
    quantity: 48.7,
    vehicle: 'DEM111',
    id: 137941,
    tank_volume_start: 25372.29999999992,
    tank_volume_stop: 25323.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:02:22',
    timestamp_stop: '2022-01-01T15:02:28',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 137943,
    tank_volume_start: 25323.59999999992,
    tank_volume_stop: 25268.899999999918,
  },
  {
    timestamp_start: '2022-01-01T15:02:43',
    timestamp_stop: '2022-01-01T15:02:48',
    quantity: 51.3,
    vehicle: 'DEM888',
    id: 137945,
    tank_volume_start: 25268.899999999918,
    tank_volume_stop: 25217.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:03:03',
    timestamp_stop: '2022-01-01T15:03:08',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 137947,
    tank_volume_start: 25217.59999999992,
    tank_volume_stop: 25163.49999999992,
  },
  {
    timestamp_start: '2022-01-01T15:03:23',
    timestamp_stop: '2022-01-01T15:03:29',
    quantity: 58.7,
    vehicle: 'DEM111',
    id: 137949,
    tank_volume_start: 25163.49999999992,
    tank_volume_stop: 25104.79999999992,
  },
  {
    timestamp_start: '2022-01-01T15:03:44',
    timestamp_stop: '2022-01-01T15:03:50',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 137951,
    tank_volume_start: 25104.79999999992,
    tank_volume_stop: 25049.19999999992,
  },
  {
    timestamp_start: '2022-01-01T15:04:05',
    timestamp_stop: '2022-01-01T15:04:10',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 137953,
    tank_volume_start: 25049.19999999992,
    tank_volume_stop: 24994.19999999992,
  },
  {
    timestamp_start: '2022-01-01T15:04:25',
    timestamp_stop: '2022-01-01T15:04:31',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 137955,
    tank_volume_start: 24994.19999999992,
    tank_volume_stop: 24936.49999999992,
  },
  {
    timestamp_start: '2022-01-01T15:04:46',
    timestamp_stop: '2022-01-01T15:04:51',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 137957,
    tank_volume_start: 24936.49999999992,
    tank_volume_stop: 24882.49999999992,
  },
  {
    timestamp_start: '2022-01-01T15:05:06',
    timestamp_stop: '2022-01-01T15:05:11',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 137959,
    tank_volume_start: 24882.49999999992,
    tank_volume_stop: 24832.49999999992,
  },
  {
    timestamp_start: '2022-01-01T15:05:26',
    timestamp_stop: '2022-01-01T15:05:32',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 137961,
    tank_volume_start: 24832.49999999992,
    tank_volume_stop: 24776.39999999992,
  },
  {
    timestamp_start: '2022-01-01T15:05:47',
    timestamp_stop: '2022-01-01T15:05:53',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 137963,
    tank_volume_start: 24776.39999999992,
    tank_volume_stop: 24719.19999999992,
  },
  {
    timestamp_start: '2022-01-01T15:06:08',
    timestamp_stop: '2022-01-01T15:06:13',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 137965,
    tank_volume_start: 24719.19999999992,
    tank_volume_stop: 24663.79999999992,
  },
  {
    timestamp_start: '2022-01-01T15:06:28',
    timestamp_stop: '2022-01-01T15:06:33',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 137967,
    tank_volume_start: 24663.79999999992,
    tank_volume_stop: 24611.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:06:48',
    timestamp_stop: '2022-01-01T15:06:54',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 137969,
    tank_volume_start: 24611.59999999992,
    tank_volume_stop: 24552.99999999992,
  },
  {
    timestamp_start: '2022-01-01T15:07:09',
    timestamp_stop: '2022-01-01T15:07:15',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 137971,
    tank_volume_start: 24552.99999999992,
    tank_volume_stop: 24499.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:07:30',
    timestamp_stop: '2022-01-01T15:07:35',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 137973,
    tank_volume_start: 24499.59999999992,
    tank_volume_stop: 24447.199999999917,
  },
  {
    timestamp_start: '2022-01-01T15:07:50',
    timestamp_stop: '2022-01-01T15:07:55',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 137975,
    tank_volume_start: 24447.199999999917,
    tank_volume_stop: 24391.299999999916,
  },
  {
    timestamp_start: '2022-01-01T15:08:10',
    timestamp_stop: '2022-01-01T15:08:16',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 137977,
    tank_volume_start: 24391.299999999916,
    tank_volume_stop: 24339.199999999917,
  },
  {
    timestamp_start: '2022-01-01T15:08:31',
    timestamp_stop: '2022-01-01T15:08:36',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 137979,
    tank_volume_start: 24339.199999999917,
    tank_volume_stop: 24288.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:08:51',
    timestamp_stop: '2022-01-01T15:08:57',
    quantity: 63.2,
    vehicle: 'DEM666',
    id: 137981,
    tank_volume_start: 24288.59999999992,
    tank_volume_stop: 24225.399999999918,
  },
  {
    timestamp_start: '2022-01-01T15:09:12',
    timestamp_stop: '2022-01-01T15:09:17',
    quantity: 52.5,
    vehicle: 'DEM888',
    id: 137983,
    tank_volume_start: 24225.399999999918,
    tank_volume_stop: 24172.899999999918,
  },
  {
    timestamp_start: '2022-01-01T15:09:32',
    timestamp_stop: '2022-01-01T15:09:38',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 137985,
    tank_volume_start: 24172.899999999918,
    tank_volume_stop: 24117.09999999992,
  },
  {
    timestamp_start: '2022-01-01T15:09:53',
    timestamp_stop: '2022-01-01T15:09:57',
    quantity: 46.9,
    vehicle: 'DEM666',
    id: 137987,
    tank_volume_start: 24117.09999999992,
    tank_volume_stop: 24070.199999999917,
  },
  {
    timestamp_start: '2022-01-01T15:10:12',
    timestamp_stop: '2022-01-01T15:10:17',
    quantity: 49.6,
    vehicle: 'DEM444',
    id: 137989,
    tank_volume_start: 24070.199999999917,
    tank_volume_stop: 24020.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:10:32',
    timestamp_stop: '2022-01-01T15:10:37',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 137991,
    tank_volume_start: 24020.59999999992,
    tank_volume_stop: 23972.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:10:52',
    timestamp_stop: '2022-01-01T15:10:58',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 137993,
    tank_volume_start: 23972.59999999992,
    tank_volume_stop: 23919.199999999917,
  },
  {
    timestamp_start: '2022-01-01T15:11:13',
    timestamp_stop: '2022-01-01T15:11:18',
    quantity: 50.9,
    vehicle: 'DEM222',
    id: 137995,
    tank_volume_start: 23919.199999999917,
    tank_volume_stop: 23868.299999999916,
  },
  {
    timestamp_start: '2022-01-01T15:11:33',
    timestamp_stop: '2022-01-01T15:11:38',
    quantity: 54.4,
    vehicle: 'DEM777',
    id: 137997,
    tank_volume_start: 23868.299999999916,
    tank_volume_stop: 23813.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:11:53',
    timestamp_stop: '2022-01-01T15:11:59',
    quantity: 56.6,
    vehicle: 'DEM222',
    id: 137999,
    tank_volume_start: 23813.899999999914,
    tank_volume_stop: 23757.299999999916,
  },
  {
    timestamp_start: '2022-01-01T15:12:14',
    timestamp_stop: '2022-01-01T15:12:19',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 138001,
    tank_volume_start: 23757.299999999916,
    tank_volume_stop: 23702.599999999915,
  },
  {
    timestamp_start: '2022-01-01T15:12:34',
    timestamp_stop: '2022-01-01T15:12:40',
    quantity: 56.7,
    vehicle: 'DEM444',
    id: 138003,
    tank_volume_start: 23702.599999999915,
    tank_volume_stop: 23645.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:12:55',
    timestamp_stop: '2022-01-01T15:13:00',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 138005,
    tank_volume_start: 23645.899999999914,
    tank_volume_stop: 23595.199999999913,
  },
  {
    timestamp_start: '2022-01-01T15:13:15',
    timestamp_stop: '2022-01-01T15:13:21',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 138007,
    tank_volume_start: 23595.199999999913,
    tank_volume_stop: 23534.799999999912,
  },
  {
    timestamp_start: '2022-01-01T15:13:36',
    timestamp_stop: '2022-01-01T15:13:41',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 138009,
    tank_volume_start: 23534.799999999912,
    tank_volume_stop: 23483.199999999913,
  },
  {
    timestamp_start: '2022-01-01T15:13:56',
    timestamp_stop: '2022-01-01T15:14:02',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 138011,
    tank_volume_start: 23483.199999999913,
    tank_volume_stop: 23427.199999999913,
  },
  {
    timestamp_start: '2022-01-01T15:14:17',
    timestamp_stop: '2022-01-01T15:14:22',
    quantity: 54.2,
    vehicle: 'DEM888',
    id: 138013,
    tank_volume_start: 23427.199999999913,
    tank_volume_stop: 23372.999999999913,
  },
  {
    timestamp_start: '2022-01-01T15:14:37',
    timestamp_stop: '2022-01-01T15:14:42',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 138015,
    tank_volume_start: 23372.999999999913,
    tank_volume_stop: 23320.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:14:57',
    timestamp_stop: '2022-01-01T15:15:02',
    quantity: 48.0,
    vehicle: 'DEM000',
    id: 138017,
    tank_volume_start: 23320.59999999991,
    tank_volume_stop: 23272.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:15:17',
    timestamp_stop: '2022-01-01T15:15:22',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 138019,
    tank_volume_start: 23272.59999999991,
    tank_volume_stop: 23221.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:15:37',
    timestamp_stop: '2022-01-01T15:15:42',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 138021,
    tank_volume_start: 23221.59999999991,
    tank_volume_stop: 23170.39999999991,
  },
  {
    timestamp_start: '2022-01-01T15:15:57',
    timestamp_stop: '2022-01-01T15:16:03',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 138023,
    tank_volume_start: 23170.39999999991,
    tank_volume_stop: 23115.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:16:18',
    timestamp_stop: '2022-01-01T15:16:24',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 138025,
    tank_volume_start: 23115.69999999991,
    tank_volume_stop: 23057.39999999991,
  },
  {
    timestamp_start: '2022-01-01T15:16:39',
    timestamp_stop: '2022-01-01T15:16:44',
    quantity: 55.8,
    vehicle: 'DEM888',
    id: 138027,
    tank_volume_start: 23057.39999999991,
    tank_volume_stop: 23001.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:16:59',
    timestamp_stop: '2022-01-01T15:17:05',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 138029,
    tank_volume_start: 23001.59999999991,
    tank_volume_stop: 22946.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:17:20',
    timestamp_stop: '2022-01-01T15:17:26',
    quantity: 62.0,
    vehicle: 'DEM111',
    id: 138031,
    tank_volume_start: 22946.69999999991,
    tank_volume_stop: 22884.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:17:41',
    timestamp_stop: '2022-01-01T15:17:46',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 138033,
    tank_volume_start: 22884.69999999991,
    tank_volume_stop: 22837.99999999991,
  },
  {
    timestamp_start: '2022-01-01T15:18:01',
    timestamp_stop: '2022-01-01T15:18:06',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 138035,
    tank_volume_start: 22837.99999999991,
    tank_volume_stop: 22783.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:18:21',
    timestamp_stop: '2022-01-01T15:18:27',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 138037,
    tank_volume_start: 22783.29999999991,
    tank_volume_stop: 22726.099999999908,
  },
  {
    timestamp_start: '2022-01-01T15:18:42',
    timestamp_stop: '2022-01-01T15:18:47',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 138039,
    tank_volume_start: 22726.099999999908,
    tank_volume_stop: 22672.49999999991,
  },
  {
    timestamp_start: '2022-01-01T15:19:02',
    timestamp_stop: '2022-01-01T15:19:08',
    quantity: 62.3,
    vehicle: 'DEM888',
    id: 138041,
    tank_volume_start: 22672.49999999991,
    tank_volume_stop: 22610.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:19:23',
    timestamp_stop: '2022-01-01T15:19:29',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 138043,
    tank_volume_start: 22610.19999999991,
    tank_volume_stop: 22553.09999999991,
  },
  {
    timestamp_start: '2022-01-01T15:19:44',
    timestamp_stop: '2022-01-01T15:19:50',
    quantity: 61.2,
    vehicle: 'DEM000',
    id: 138045,
    tank_volume_start: 22553.09999999991,
    tank_volume_stop: 22491.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:20:05',
    timestamp_stop: '2022-01-01T15:20:11',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 138047,
    tank_volume_start: 22491.89999999991,
    tank_volume_stop: 22439.299999999912,
  },
  {
    timestamp_start: '2022-01-01T15:20:26',
    timestamp_stop: '2022-01-01T15:20:31',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 138049,
    tank_volume_start: 22439.299999999912,
    tank_volume_stop: 22384.499999999913,
  },
  {
    timestamp_start: '2022-01-01T15:20:46',
    timestamp_stop: '2022-01-01T15:20:52',
    quantity: 58.8,
    vehicle: 'DEM222',
    id: 138051,
    tank_volume_start: 22384.499999999913,
    tank_volume_stop: 22325.699999999913,
  },
  {
    timestamp_start: '2022-01-01T15:21:07',
    timestamp_stop: '2022-01-01T15:21:12',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 138053,
    tank_volume_start: 22325.699999999913,
    tank_volume_stop: 22272.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:21:27',
    timestamp_stop: '2022-01-01T15:21:33',
    quantity: 62.5,
    vehicle: 'DEM111',
    id: 138055,
    tank_volume_start: 22272.899999999914,
    tank_volume_stop: 22210.399999999914,
  },
  {
    timestamp_start: '2022-01-01T15:21:48',
    timestamp_stop: '2022-01-01T15:21:53',
    quantity: 45.8,
    vehicle: 'DEM444',
    id: 138057,
    tank_volume_start: 22210.399999999914,
    tank_volume_stop: 22164.599999999915,
  },
  {
    timestamp_start: '2022-01-01T15:22:08',
    timestamp_stop: '2022-01-01T15:22:14',
    quantity: 63.1,
    vehicle: 'DEM222',
    id: 138059,
    tank_volume_start: 22164.599999999915,
    tank_volume_stop: 22101.499999999916,
  },
  {
    timestamp_start: '2022-01-01T15:22:29',
    timestamp_stop: '2022-01-01T15:22:34',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 138061,
    tank_volume_start: 22101.499999999916,
    tank_volume_stop: 22051.099999999915,
  },
  {
    timestamp_start: '2022-01-01T15:22:49',
    timestamp_stop: '2022-01-01T15:22:54',
    quantity: 46.6,
    vehicle: 'DEM222',
    id: 138063,
    tank_volume_start: 22051.099999999915,
    tank_volume_stop: 22004.499999999916,
  },
  {
    timestamp_start: '2022-01-01T15:23:09',
    timestamp_stop: '2022-01-01T15:23:15',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 138065,
    tank_volume_start: 22004.499999999916,
    tank_volume_stop: 21949.799999999916,
  },
  {
    timestamp_start: '2022-01-01T15:23:30',
    timestamp_stop: '2022-01-01T15:23:35',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 138067,
    tank_volume_start: 21949.799999999916,
    tank_volume_stop: 21893.999999999916,
  },
  {
    timestamp_start: '2022-01-01T15:23:50',
    timestamp_stop: '2022-01-01T15:23:56',
    quantity: 59.1,
    vehicle: 'DEM888',
    id: 138069,
    tank_volume_start: 21893.999999999916,
    tank_volume_stop: 21834.899999999918,
  },
  {
    timestamp_start: '2022-01-01T15:24:11',
    timestamp_stop: '2022-01-01T15:24:16',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 138071,
    tank_volume_start: 21834.899999999918,
    tank_volume_stop: 21782.699999999917,
  },
  {
    timestamp_start: '2022-01-01T15:24:31',
    timestamp_stop: '2022-01-01T15:24:37',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 138073,
    tank_volume_start: 21782.699999999917,
    tank_volume_stop: 21722.59999999992,
  },
  {
    timestamp_start: '2022-01-01T15:24:52',
    timestamp_stop: '2022-01-01T15:24:58',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 138075,
    tank_volume_start: 21722.59999999992,
    tank_volume_stop: 21661.99999999992,
  },
  {
    timestamp_start: '2022-01-01T15:25:13',
    timestamp_stop: '2022-01-01T15:25:19',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 138077,
    tank_volume_start: 21661.99999999992,
    tank_volume_stop: 21608.99999999992,
  },
  {
    timestamp_start: '2022-01-01T15:25:34',
    timestamp_stop: '2022-01-01T15:25:38',
    quantity: 46.7,
    vehicle: 'DEM777',
    id: 138079,
    tank_volume_start: 21608.99999999992,
    tank_volume_stop: 21562.29999999992,
  },
  {
    timestamp_start: '2022-01-01T15:25:53',
    timestamp_stop: '2022-01-01T15:25:59',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 138081,
    tank_volume_start: 21562.29999999992,
    tank_volume_stop: 21508.399999999918,
  },
  {
    timestamp_start: '2022-01-01T15:26:14',
    timestamp_stop: '2022-01-01T15:26:19',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 138083,
    tank_volume_start: 21508.399999999918,
    tank_volume_stop: 21454.499999999916,
  },
  {
    timestamp_start: '2022-01-01T15:26:34',
    timestamp_stop: '2022-01-01T15:26:39',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 138085,
    tank_volume_start: 21454.499999999916,
    tank_volume_stop: 21404.099999999915,
  },
  {
    timestamp_start: '2022-01-01T15:26:54',
    timestamp_stop: '2022-01-01T15:27:00',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 138087,
    tank_volume_start: 21404.099999999915,
    tank_volume_stop: 21349.799999999916,
  },
  {
    timestamp_start: '2022-01-01T15:27:15',
    timestamp_stop: '2022-01-01T15:27:19',
    quantity: 49.2,
    vehicle: 'DEM222',
    id: 138089,
    tank_volume_start: 21349.799999999916,
    tank_volume_stop: 21300.599999999915,
  },
  {
    timestamp_start: '2022-01-01T15:27:34',
    timestamp_stop: '2022-01-01T15:27:40',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 138090,
    tank_volume_start: 21300.599999999915,
    tank_volume_stop: 21246.299999999916,
  },
  {
    timestamp_start: '2022-01-01T15:27:55',
    timestamp_stop: '2022-01-01T15:28:00',
    quantity: 48.3,
    vehicle: 'DEM777',
    id: 138092,
    tank_volume_start: 21246.299999999916,
    tank_volume_stop: 21197.999999999916,
  },
  {
    timestamp_start: '2022-01-01T15:28:15',
    timestamp_stop: '2022-01-01T15:28:20',
    quantity: 53.8,
    vehicle: 'DEM000',
    id: 138094,
    tank_volume_start: 21197.999999999916,
    tank_volume_stop: 21144.199999999917,
  },
  {
    timestamp_start: '2022-01-01T15:28:35',
    timestamp_stop: '2022-01-01T15:28:41',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 138096,
    tank_volume_start: 21144.199999999917,
    tank_volume_stop: 21085.999999999916,
  },
  {
    timestamp_start: '2022-01-01T15:28:56',
    timestamp_stop: '2022-01-01T15:29:01',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 138098,
    tank_volume_start: 21085.999999999916,
    tank_volume_stop: 21032.599999999915,
  },
  {
    timestamp_start: '2022-01-01T15:29:16',
    timestamp_stop: '2022-01-01T15:29:22',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 138100,
    tank_volume_start: 21032.599999999915,
    tank_volume_stop: 20975.099999999915,
  },
  {
    timestamp_start: '2022-01-01T15:29:37',
    timestamp_stop: '2022-01-01T15:29:42',
    quantity: 49.2,
    vehicle: 'DEM333',
    id: 138102,
    tank_volume_start: 20975.099999999915,
    tank_volume_stop: 20925.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:29:57',
    timestamp_stop: '2022-01-01T15:30:03',
    quantity: 62.9,
    vehicle: 'DEM444',
    id: 138104,
    tank_volume_start: 20925.899999999914,
    tank_volume_stop: 20862.999999999913,
  },
  {
    timestamp_start: '2022-01-01T15:30:18',
    timestamp_stop: '2022-01-01T15:30:24',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 138106,
    tank_volume_start: 20862.999999999913,
    tank_volume_stop: 20805.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:30:39',
    timestamp_stop: '2022-01-01T15:30:45',
    quantity: 64.4,
    vehicle: 'DEM333',
    id: 138108,
    tank_volume_start: 20805.59999999991,
    tank_volume_stop: 20741.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:31:00',
    timestamp_stop: '2022-01-01T15:31:06',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 138110,
    tank_volume_start: 20741.19999999991,
    tank_volume_stop: 20689.99999999991,
  },
  {
    timestamp_start: '2022-01-01T15:31:21',
    timestamp_stop: '2022-01-01T15:31:26',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 138112,
    tank_volume_start: 20689.99999999991,
    tank_volume_stop: 20636.79999999991,
  },
  {
    timestamp_start: '2022-01-01T15:31:41',
    timestamp_stop: '2022-01-01T15:31:47',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 138114,
    tank_volume_start: 20636.79999999991,
    tank_volume_stop: 20576.399999999907,
  },
  {
    timestamp_start: '2022-01-01T15:32:02',
    timestamp_stop: '2022-01-01T15:32:07',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 138116,
    tank_volume_start: 20576.399999999907,
    tank_volume_stop: 20520.599999999908,
  },
  {
    timestamp_start: '2022-01-01T15:32:22',
    timestamp_stop: '2022-01-01T15:32:29',
    quantity: 62.5,
    vehicle: 'DEM000',
    id: 138118,
    tank_volume_start: 20520.599999999908,
    tank_volume_stop: 20458.099999999908,
  },
  {
    timestamp_start: '2022-01-01T15:32:44',
    timestamp_stop: '2022-01-01T15:32:50',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 138120,
    tank_volume_start: 20458.099999999908,
    tank_volume_stop: 20398.099999999908,
  },
  {
    timestamp_start: '2022-01-01T15:33:05',
    timestamp_stop: '2022-01-01T15:33:11',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 138122,
    tank_volume_start: 20398.099999999908,
    tank_volume_stop: 20339.99999999991,
  },
  {
    timestamp_start: '2022-01-01T15:33:26',
    timestamp_stop: '2022-01-01T15:33:31',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 138124,
    tank_volume_start: 20339.99999999991,
    tank_volume_stop: 20282.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:33:46',
    timestamp_stop: '2022-01-01T15:33:52',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 138126,
    tank_volume_start: 20282.89999999991,
    tank_volume_stop: 20225.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:34:07',
    timestamp_stop: '2022-01-01T15:34:13',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 138128,
    tank_volume_start: 20225.19999999991,
    tank_volume_stop: 20169.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:34:28',
    timestamp_stop: '2022-01-01T15:34:33',
    quantity: 57.4,
    vehicle: 'DEM777',
    id: 138130,
    tank_volume_start: 20169.69999999991,
    tank_volume_stop: 20112.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:34:48',
    timestamp_stop: '2022-01-01T15:34:53',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 138132,
    tank_volume_start: 20112.29999999991,
    tank_volume_stop: 20061.399999999907,
  },
  {
    timestamp_start: '2022-01-01T15:35:08',
    timestamp_stop: '2022-01-01T15:35:14',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 138134,
    tank_volume_start: 20061.399999999907,
    tank_volume_stop: 20005.699999999906,
  },
  {
    timestamp_start: '2022-01-01T15:35:29',
    timestamp_stop: '2022-01-01T15:35:34',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 138136,
    tank_volume_start: 20005.699999999906,
    tank_volume_stop: 19954.899999999907,
  },
  {
    timestamp_start: '2022-01-01T15:35:49',
    timestamp_stop: '2022-01-01T15:35:54',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 138138,
    tank_volume_start: 39954.89999999991,
    tank_volume_stop: 39902.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:36:09',
    timestamp_stop: '2022-01-01T15:36:14',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 138140,
    tank_volume_start: 39902.29999999991,
    tank_volume_stop: 39851.999999999905,
  },
  {
    timestamp_start: '2022-01-01T15:36:29',
    timestamp_stop: '2022-01-01T15:36:34',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 138142,
    tank_volume_start: 39851.999999999905,
    tank_volume_stop: 39801.1999999999,
  },
  {
    timestamp_start: '2022-01-01T15:36:49',
    timestamp_stop: '2022-01-01T15:36:56',
    quantity: 63.8,
    vehicle: 'DEM333',
    id: 138144,
    tank_volume_start: 39801.1999999999,
    tank_volume_stop: 39737.3999999999,
  },
  {
    timestamp_start: '2022-01-01T15:37:11',
    timestamp_stop: '2022-01-01T15:37:17',
    quantity: 57.4,
    vehicle: 'DEM777',
    id: 138146,
    tank_volume_start: 39737.3999999999,
    tank_volume_stop: 39679.9999999999,
  },
  {
    timestamp_start: '2022-01-01T15:37:32',
    timestamp_stop: '2022-01-01T15:37:37',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 138148,
    tank_volume_start: 39679.9999999999,
    tank_volume_stop: 39625.9999999999,
  },
  {
    timestamp_start: '2022-01-01T15:37:52',
    timestamp_stop: '2022-01-01T15:37:57',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 138150,
    tank_volume_start: 39625.9999999999,
    tank_volume_stop: 39571.8999999999,
  },
  {
    timestamp_start: '2022-01-01T15:38:12',
    timestamp_stop: '2022-01-01T15:38:18',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 138152,
    tank_volume_start: 39571.8999999999,
    tank_volume_stop: 39514.5999999999,
  },
  {
    timestamp_start: '2022-01-01T15:38:33',
    timestamp_stop: '2022-01-01T15:38:39',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 138154,
    tank_volume_start: 39514.5999999999,
    tank_volume_stop: 39457.299999999894,
  },
  {
    timestamp_start: '2022-01-01T15:38:54',
    timestamp_stop: '2022-01-01T15:39:00',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 138156,
    tank_volume_start: 39457.299999999894,
    tank_volume_stop: 39399.0999999999,
  },
  {
    timestamp_start: '2022-01-01T15:39:15',
    timestamp_stop: '2022-01-01T15:39:20',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 138158,
    tank_volume_start: 39399.0999999999,
    tank_volume_stop: 39345.699999999895,
  },
  {
    timestamp_start: '2022-01-01T15:39:35',
    timestamp_stop: '2022-01-01T15:39:41',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 138160,
    tank_volume_start: 39345.699999999895,
    tank_volume_stop: 39289.699999999895,
  },
  {
    timestamp_start: '2022-01-01T15:39:56',
    timestamp_stop: '2022-01-01T15:40:01',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 138162,
    tank_volume_start: 39289.699999999895,
    tank_volume_stop: 39233.799999999894,
  },
  {
    timestamp_start: '2022-01-01T15:40:16',
    timestamp_stop: '2022-01-01T15:40:22',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 138164,
    tank_volume_start: 39233.799999999894,
    tank_volume_stop: 39178.5999999999,
  },
  {
    timestamp_start: '2022-01-01T15:40:37',
    timestamp_stop: '2022-01-01T15:40:42',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 138166,
    tank_volume_start: 39178.5999999999,
    tank_volume_stop: 39122.199999999895,
  },
  {
    timestamp_start: '2022-01-01T15:40:57',
    timestamp_stop: '2022-01-01T15:41:03',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 138168,
    tank_volume_start: 39122.199999999895,
    tank_volume_stop: 39067.9999999999,
  },
  {
    timestamp_start: '2022-01-01T15:41:18',
    timestamp_stop: '2022-01-01T15:41:23',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 138170,
    tank_volume_start: 39067.9999999999,
    tank_volume_stop: 39012.4999999999,
  },
  {
    timestamp_start: '2022-01-01T15:41:38',
    timestamp_stop: '2022-01-01T15:41:44',
    quantity: 52.7,
    vehicle: 'DEM555',
    id: 138172,
    tank_volume_start: 39012.4999999999,
    tank_volume_stop: 38959.7999999999,
  },
  {
    timestamp_start: '2022-01-01T15:41:59',
    timestamp_stop: '2022-01-01T15:42:04',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 138174,
    tank_volume_start: 38959.7999999999,
    tank_volume_stop: 38904.7999999999,
  },
  {
    timestamp_start: '2022-01-01T15:42:19',
    timestamp_stop: '2022-01-01T15:42:24',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 138176,
    tank_volume_start: 38904.7999999999,
    tank_volume_stop: 38856.9999999999,
  },
  {
    timestamp_start: '2022-01-01T15:42:39',
    timestamp_stop: '2022-01-01T15:42:45',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 138178,
    tank_volume_start: 38856.9999999999,
    tank_volume_stop: 38798.4999999999,
  },
  {
    timestamp_start: '2022-01-01T15:43:00',
    timestamp_stop: '2022-01-01T15:43:05',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138180,
    tank_volume_start: 38798.4999999999,
    tank_volume_stop: 38745.2999999999,
  },
  {
    timestamp_start: '2022-01-01T15:43:20',
    timestamp_stop: '2022-01-01T15:43:25',
    quantity: 46.4,
    vehicle: 'DEM333',
    id: 138182,
    tank_volume_start: 38745.2999999999,
    tank_volume_stop: 38698.8999999999,
  },
  {
    timestamp_start: '2022-01-01T15:43:40',
    timestamp_stop: '2022-01-01T15:43:45',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 138184,
    tank_volume_start: 38698.8999999999,
    tank_volume_stop: 38640.9999999999,
  },
  {
    timestamp_start: '2022-01-01T15:44:00',
    timestamp_stop: '2022-01-01T15:44:06',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 138186,
    tank_volume_start: 38640.9999999999,
    tank_volume_stop: 38585.4999999999,
  },
  {
    timestamp_start: '2022-01-01T15:44:21',
    timestamp_stop: '2022-01-01T15:44:27',
    quantity: 64.2,
    vehicle: 'DEM333',
    id: 138188,
    tank_volume_start: 38585.4999999999,
    tank_volume_stop: 38521.2999999999,
  },
  {
    timestamp_start: '2022-01-01T15:44:42',
    timestamp_stop: '2022-01-01T15:44:48',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 138190,
    tank_volume_start: 38521.2999999999,
    tank_volume_stop: 38463.099999999904,
  },
  {
    timestamp_start: '2022-01-01T15:45:03',
    timestamp_stop: '2022-01-01T15:45:09',
    quantity: 58.4,
    vehicle: 'DEM555',
    id: 138192,
    tank_volume_start: 38463.099999999904,
    tank_volume_stop: 38404.6999999999,
  },
  {
    timestamp_start: '2022-01-01T15:45:24',
    timestamp_stop: '2022-01-01T15:45:29',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 138195,
    tank_volume_start: 38404.6999999999,
    tank_volume_stop: 38351.499999999905,
  },
  {
    timestamp_start: '2022-01-01T15:45:44',
    timestamp_stop: '2022-01-01T15:45:50',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 138196,
    tank_volume_start: 38351.499999999905,
    tank_volume_stop: 38297.599999999904,
  },
  {
    timestamp_start: '2022-01-01T15:46:05',
    timestamp_stop: '2022-01-01T15:46:10',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 138198,
    tank_volume_start: 38297.599999999904,
    tank_volume_stop: 38245.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:46:25',
    timestamp_stop: '2022-01-01T15:46:31',
    quantity: 58.6,
    vehicle: 'DEM444',
    id: 138200,
    tank_volume_start: 38245.89999999991,
    tank_volume_stop: 38187.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:46:46',
    timestamp_stop: '2022-01-01T15:46:52',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 138203,
    tank_volume_start: 38187.29999999991,
    tank_volume_stop: 38126.39999999991,
  },
  {
    timestamp_start: '2022-01-01T15:47:07',
    timestamp_stop: '2022-01-01T15:47:12',
    quantity: 51.1,
    vehicle: 'DEM888',
    id: 138205,
    tank_volume_start: 38126.39999999991,
    tank_volume_stop: 38075.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:47:27',
    timestamp_stop: '2022-01-01T15:47:32',
    quantity: 49.4,
    vehicle: 'DEM222',
    id: 138207,
    tank_volume_start: 38075.29999999991,
    tank_volume_stop: 38025.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:47:47',
    timestamp_stop: '2022-01-01T15:47:52',
    quantity: 48.9,
    vehicle: 'DEM555',
    id: 138209,
    tank_volume_start: 38025.89999999991,
    tank_volume_stop: 37976.999999999905,
  },
  {
    timestamp_start: '2022-01-01T15:48:07',
    timestamp_stop: '2022-01-01T15:48:12',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 138211,
    tank_volume_start: 37976.999999999905,
    tank_volume_stop: 37921.999999999905,
  },
  {
    timestamp_start: '2022-01-01T15:48:27',
    timestamp_stop: '2022-01-01T15:48:32',
    quantity: 46.6,
    vehicle: 'DEM000',
    id: 138213,
    tank_volume_start: 37921.999999999905,
    tank_volume_stop: 37875.39999999991,
  },
  {
    timestamp_start: '2022-01-01T15:48:47',
    timestamp_stop: '2022-01-01T15:48:52',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138215,
    tank_volume_start: 37875.39999999991,
    tank_volume_stop: 37822.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:49:07',
    timestamp_stop: '2022-01-01T15:49:14',
    quantity: 62.7,
    vehicle: 'DEM333',
    id: 138217,
    tank_volume_start: 37822.19999999991,
    tank_volume_stop: 37759.49999999991,
  },
  {
    timestamp_start: '2022-01-01T15:49:29',
    timestamp_stop: '2022-01-01T15:49:34',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 138219,
    tank_volume_start: 37759.49999999991,
    tank_volume_stop: 37704.299999999916,
  },
  {
    timestamp_start: '2022-01-01T15:49:49',
    timestamp_stop: '2022-01-01T15:49:55',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 138221,
    tank_volume_start: 37704.299999999916,
    tank_volume_stop: 37649.799999999916,
  },
  {
    timestamp_start: '2022-01-01T15:50:10',
    timestamp_stop: '2022-01-01T15:50:16',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 138223,
    tank_volume_start: 37649.799999999916,
    tank_volume_stop: 37589.99999999991,
  },
  {
    timestamp_start: '2022-01-01T15:50:31',
    timestamp_stop: '2022-01-01T15:50:36',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 138225,
    tank_volume_start: 37589.99999999991,
    tank_volume_stop: 37533.49999999991,
  },
  {
    timestamp_start: '2022-01-01T15:50:51',
    timestamp_stop: '2022-01-01T15:50:57',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 138227,
    tank_volume_start: 37533.49999999991,
    tank_volume_stop: 37476.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:51:12',
    timestamp_stop: '2022-01-01T15:51:17',
    quantity: 51.2,
    vehicle: 'DEM222',
    id: 138229,
    tank_volume_start: 37476.19999999991,
    tank_volume_stop: 37424.99999999991,
  },
  {
    timestamp_start: '2022-01-01T15:51:32',
    timestamp_stop: '2022-01-01T15:51:38',
    quantity: 57.4,
    vehicle: 'DEM555',
    id: 138231,
    tank_volume_start: 37424.99999999991,
    tank_volume_stop: 37367.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:51:53',
    timestamp_stop: '2022-01-01T15:51:58',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 138233,
    tank_volume_start: 37367.59999999991,
    tank_volume_stop: 37315.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:52:13',
    timestamp_stop: '2022-01-01T15:52:18',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 138235,
    tank_volume_start: 37315.19999999991,
    tank_volume_stop: 37261.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:52:33',
    timestamp_stop: '2022-01-01T15:52:39',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 138237,
    tank_volume_start: 37261.89999999991,
    tank_volume_stop: 37206.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:52:54',
    timestamp_stop: '2022-01-01T15:52:59',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138239,
    tank_volume_start: 37206.69999999991,
    tank_volume_stop: 37153.49999999991,
  },
  {
    timestamp_start: '2022-01-01T15:53:14',
    timestamp_stop: '2022-01-01T15:53:19',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 138241,
    tank_volume_start: 37153.49999999991,
    tank_volume_stop: 37104.399999999914,
  },
  {
    timestamp_start: '2022-01-01T15:53:34',
    timestamp_stop: '2022-01-01T15:53:40',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 138243,
    tank_volume_start: 37104.399999999914,
    tank_volume_stop: 37046.19999999992,
  },
  {
    timestamp_start: '2022-01-01T15:53:55',
    timestamp_stop: '2022-01-01T15:54:01',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 138245,
    tank_volume_start: 37046.19999999992,
    tank_volume_stop: 36988.19999999992,
  },
  {
    timestamp_start: '2022-01-01T15:54:16',
    timestamp_stop: '2022-01-01T15:54:22',
    quantity: 64.5,
    vehicle: 'DEM111',
    id: 138247,
    tank_volume_start: 36988.19999999992,
    tank_volume_stop: 36923.69999999992,
  },
  {
    timestamp_start: '2022-01-01T15:54:37',
    timestamp_stop: '2022-01-01T15:54:43',
    quantity: 60.8,
    vehicle: 'DEM111',
    id: 138249,
    tank_volume_start: 36923.69999999992,
    tank_volume_stop: 36862.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:54:58',
    timestamp_stop: '2022-01-01T15:55:03',
    quantity: 50.3,
    vehicle: 'DEM555',
    id: 138251,
    tank_volume_start: 36862.899999999914,
    tank_volume_stop: 36812.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:55:18',
    timestamp_stop: '2022-01-01T15:55:23',
    quantity: 46.8,
    vehicle: 'DEM555',
    id: 138253,
    tank_volume_start: 36812.59999999991,
    tank_volume_stop: 36765.79999999991,
  },
  {
    timestamp_start: '2022-01-01T15:55:38',
    timestamp_stop: '2022-01-01T15:55:43',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 138255,
    tank_volume_start: 36765.79999999991,
    tank_volume_stop: 36712.89999999991,
  },
  {
    timestamp_start: '2022-01-01T15:55:58',
    timestamp_stop: '2022-01-01T15:56:03',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 138257,
    tank_volume_start: 36712.89999999991,
    tank_volume_stop: 36661.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:56:18',
    timestamp_stop: '2022-01-01T15:56:24',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 138259,
    tank_volume_start: 36661.29999999991,
    tank_volume_stop: 36608.19999999991,
  },
  {
    timestamp_start: '2022-01-01T15:56:39',
    timestamp_stop: '2022-01-01T15:56:44',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 138261,
    tank_volume_start: 36608.19999999991,
    tank_volume_stop: 36557.39999999991,
  },
  {
    timestamp_start: '2022-01-01T15:56:59',
    timestamp_stop: '2022-01-01T15:57:04',
    quantity: 50.7,
    vehicle: 'DEM000',
    id: 138263,
    tank_volume_start: 36557.39999999991,
    tank_volume_stop: 36506.69999999991,
  },
  {
    timestamp_start: '2022-01-01T15:57:19',
    timestamp_stop: '2022-01-01T15:57:24',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 138265,
    tank_volume_start: 36506.69999999991,
    tank_volume_stop: 36454.29999999991,
  },
  {
    timestamp_start: '2022-01-01T15:57:39',
    timestamp_stop: '2022-01-01T15:57:44',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 138266,
    tank_volume_start: 36454.29999999991,
    tank_volume_stop: 36400.09999999991,
  },
  {
    timestamp_start: '2022-01-01T15:57:59',
    timestamp_stop: '2022-01-01T15:58:05',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 138268,
    tank_volume_start: 36400.09999999991,
    tank_volume_stop: 36347.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:58:20',
    timestamp_stop: '2022-01-01T15:58:25',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 138271,
    tank_volume_start: 36347.899999999914,
    tank_volume_stop: 36293.09999999991,
  },
  {
    timestamp_start: '2022-01-01T15:58:40',
    timestamp_stop: '2022-01-01T15:58:46',
    quantity: 58.0,
    vehicle: 'DEM222',
    id: 138273,
    tank_volume_start: 36293.09999999991,
    tank_volume_stop: 36235.09999999991,
  },
  {
    timestamp_start: '2022-01-01T15:59:01',
    timestamp_stop: '2022-01-01T15:59:07',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 138274,
    tank_volume_start: 36235.09999999991,
    tank_volume_stop: 36176.59999999991,
  },
  {
    timestamp_start: '2022-01-01T15:59:22',
    timestamp_stop: '2022-01-01T15:59:28',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 138277,
    tank_volume_start: 36176.59999999991,
    tank_volume_stop: 36119.899999999914,
  },
  {
    timestamp_start: '2022-01-01T15:59:43',
    timestamp_stop: '2022-01-01T15:59:48',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 138279,
    tank_volume_start: 36119.899999999914,
    tank_volume_stop: 36068.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:00:03',
    timestamp_stop: '2022-01-01T16:00:09',
    quantity: 59.5,
    vehicle: 'DEM555',
    id: 138281,
    tank_volume_start: 36068.49999999991,
    tank_volume_stop: 36008.99999999991,
  },
  {
    timestamp_start: '2022-01-01T16:00:24',
    timestamp_stop: '2022-01-01T16:00:29',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 138283,
    tank_volume_start: 36008.99999999991,
    tank_volume_stop: 35951.59999999991,
  },
  {
    timestamp_start: '2022-01-01T16:00:44',
    timestamp_stop: '2022-01-01T16:00:50',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 138285,
    tank_volume_start: 35951.59999999991,
    tank_volume_stop: 35894.79999999991,
  },
  {
    timestamp_start: '2022-01-01T16:01:05',
    timestamp_stop: '2022-01-01T16:01:10',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 138287,
    tank_volume_start: 35894.79999999991,
    tank_volume_stop: 35840.39999999991,
  },
  {
    timestamp_start: '2022-01-01T16:01:25',
    timestamp_stop: '2022-01-01T16:01:31',
    quantity: 59.8,
    vehicle: 'DEM000',
    id: 138289,
    tank_volume_start: 35840.39999999991,
    tank_volume_stop: 35780.599999999904,
  },
  {
    timestamp_start: '2022-01-01T16:01:46',
    timestamp_stop: '2022-01-01T16:01:53',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 138291,
    tank_volume_start: 35780.599999999904,
    tank_volume_stop: 35717.6999999999,
  },
  {
    timestamp_start: '2022-01-01T16:02:08',
    timestamp_stop: '2022-01-01T16:02:13',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 138293,
    tank_volume_start: 35717.6999999999,
    tank_volume_stop: 35662.8999999999,
  },
  {
    timestamp_start: '2022-01-01T16:02:28',
    timestamp_stop: '2022-01-01T16:02:33',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 138295,
    tank_volume_start: 35662.8999999999,
    tank_volume_stop: 35610.8999999999,
  },
  {
    timestamp_start: '2022-01-01T16:02:48',
    timestamp_stop: '2022-01-01T16:02:54',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 138297,
    tank_volume_start: 35610.8999999999,
    tank_volume_stop: 35555.2999999999,
  },
  {
    timestamp_start: '2022-01-01T16:03:09',
    timestamp_stop: '2022-01-01T16:03:14',
    quantity: 51.6,
    vehicle: 'DEM666',
    id: 138299,
    tank_volume_start: 35555.2999999999,
    tank_volume_stop: 35503.6999999999,
  },
  {
    timestamp_start: '2022-01-01T16:03:29',
    timestamp_stop: '2022-01-01T16:03:35',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 138301,
    tank_volume_start: 35503.6999999999,
    tank_volume_stop: 35447.6999999999,
  },
  {
    timestamp_start: '2022-01-01T16:03:50',
    timestamp_stop: '2022-01-01T16:03:55',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 138303,
    tank_volume_start: 35447.6999999999,
    tank_volume_stop: 35393.099999999904,
  },
  {
    timestamp_start: '2022-01-01T16:04:10',
    timestamp_stop: '2022-01-01T16:04:15',
    quantity: 50.7,
    vehicle: 'DEM666',
    id: 138305,
    tank_volume_start: 35393.099999999904,
    tank_volume_stop: 35342.39999999991,
  },
  {
    timestamp_start: '2022-01-01T16:04:30',
    timestamp_stop: '2022-01-01T16:04:36',
    quantity: 61.2,
    vehicle: 'DEM222',
    id: 138307,
    tank_volume_start: 35342.39999999991,
    tank_volume_stop: 35281.19999999991,
  },
  {
    timestamp_start: '2022-01-01T16:04:51',
    timestamp_stop: '2022-01-01T16:04:58',
    quantity: 62.7,
    vehicle: 'DEM555',
    id: 138309,
    tank_volume_start: 35281.19999999991,
    tank_volume_stop: 35218.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:05:13',
    timestamp_stop: '2022-01-01T16:05:18',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 138311,
    tank_volume_start: 35218.49999999991,
    tank_volume_stop: 35163.399999999914,
  },
  {
    timestamp_start: '2022-01-01T16:05:33',
    timestamp_stop: '2022-01-01T16:05:39',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 138313,
    tank_volume_start: 35163.399999999914,
    tank_volume_stop: 35108.399999999914,
  },
  {
    timestamp_start: '2022-01-01T16:05:54',
    timestamp_stop: '2022-01-01T16:05:59',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 138315,
    tank_volume_start: 35108.399999999914,
    tank_volume_stop: 35056.09999999991,
  },
  {
    timestamp_start: '2022-01-01T16:06:14',
    timestamp_stop: '2022-01-01T16:06:19',
    quantity: 46.8,
    vehicle: 'DEM444',
    id: 138317,
    tank_volume_start: 35056.09999999991,
    tank_volume_stop: 35009.29999999991,
  },
  {
    timestamp_start: '2022-01-01T16:06:34',
    timestamp_stop: '2022-01-01T16:06:39',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 138319,
    tank_volume_start: 35009.29999999991,
    tank_volume_stop: 34957.999999999905,
  },
  {
    timestamp_start: '2022-01-01T16:06:54',
    timestamp_stop: '2022-01-01T16:06:58',
    quantity: 45.9,
    vehicle: 'DEM777',
    id: 138321,
    tank_volume_start: 34957.999999999905,
    tank_volume_stop: 34912.099999999904,
  },
  {
    timestamp_start: '2022-01-01T16:07:13',
    timestamp_stop: '2022-01-01T16:07:19',
    quantity: 55.6,
    vehicle: 'DEM777',
    id: 138322,
    tank_volume_start: 34912.099999999904,
    tank_volume_stop: 34856.499999999905,
  },
  {
    timestamp_start: '2022-01-01T16:07:34',
    timestamp_stop: '2022-01-01T16:07:40',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 138325,
    tank_volume_start: 34856.499999999905,
    tank_volume_stop: 34797.39999999991,
  },
  {
    timestamp_start: '2022-01-01T16:07:55',
    timestamp_stop: '2022-01-01T16:08:01',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 138327,
    tank_volume_start: 34797.39999999991,
    tank_volume_stop: 34737.29999999991,
  },
  {
    timestamp_start: '2022-01-01T16:08:16',
    timestamp_stop: '2022-01-01T16:08:21',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 138329,
    tank_volume_start: 34737.29999999991,
    tank_volume_stop: 34681.29999999991,
  },
  {
    timestamp_start: '2022-01-01T16:08:36',
    timestamp_stop: '2022-01-01T16:08:42',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 138331,
    tank_volume_start: 34681.29999999991,
    tank_volume_stop: 34626.79999999991,
  },
  {
    timestamp_start: '2022-01-01T16:08:57',
    timestamp_stop: '2022-01-01T16:09:03',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 138333,
    tank_volume_start: 34626.79999999991,
    tank_volume_stop: 34569.39999999991,
  },
  {
    timestamp_start: '2022-01-01T16:09:18',
    timestamp_stop: '2022-01-01T16:09:24',
    quantity: 63.7,
    vehicle: 'DEM444',
    id: 138335,
    tank_volume_start: 34569.39999999991,
    tank_volume_stop: 34505.69999999991,
  },
  {
    timestamp_start: '2022-01-01T16:09:39',
    timestamp_stop: '2022-01-01T16:09:44',
    quantity: 49.2,
    vehicle: 'DEM888',
    id: 138337,
    tank_volume_start: 34505.69999999991,
    tank_volume_stop: 34456.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:09:59',
    timestamp_stop: '2022-01-01T16:10:04',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 138339,
    tank_volume_start: 34456.49999999991,
    tank_volume_stop: 34403.799999999916,
  },
  {
    timestamp_start: '2022-01-01T16:10:19',
    timestamp_stop: '2022-01-01T16:10:24',
    quantity: 51.5,
    vehicle: 'DEM777',
    id: 138341,
    tank_volume_start: 34403.799999999916,
    tank_volume_stop: 34352.299999999916,
  },
  {
    timestamp_start: '2022-01-01T16:10:39',
    timestamp_stop: '2022-01-01T16:10:45',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 138343,
    tank_volume_start: 34352.299999999916,
    tank_volume_stop: 34294.399999999914,
  },
  {
    timestamp_start: '2022-01-01T16:11:00',
    timestamp_stop: '2022-01-01T16:11:06',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 138345,
    tank_volume_start: 34294.399999999914,
    tank_volume_stop: 34239.899999999914,
  },
  {
    timestamp_start: '2022-01-01T16:11:21',
    timestamp_stop: '2022-01-01T16:11:26',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 138347,
    tank_volume_start: 34239.899999999914,
    tank_volume_stop: 34183.299999999916,
  },
  {
    timestamp_start: '2022-01-01T16:11:41',
    timestamp_stop: '2022-01-01T16:11:47',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 138349,
    tank_volume_start: 34183.299999999916,
    tank_volume_stop: 34129.899999999914,
  },
  {
    timestamp_start: '2022-01-01T16:12:02',
    timestamp_stop: '2022-01-01T16:12:08',
    quantity: 62.4,
    vehicle: 'DEM222',
    id: 138351,
    tank_volume_start: 34129.899999999914,
    tank_volume_stop: 34067.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:12:23',
    timestamp_stop: '2022-01-01T16:12:29',
    quantity: 59.5,
    vehicle: 'DEM222',
    id: 138353,
    tank_volume_start: 34067.49999999991,
    tank_volume_stop: 34007.99999999991,
  },
  {
    timestamp_start: '2022-01-01T16:12:44',
    timestamp_stop: '2022-01-01T16:12:49',
    quantity: 50.6,
    vehicle: 'DEM222',
    id: 138355,
    tank_volume_start: 34007.99999999991,
    tank_volume_stop: 33957.399999999914,
  },
  {
    timestamp_start: '2022-01-01T16:13:04',
    timestamp_stop: '2022-01-01T16:13:10',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 138357,
    tank_volume_start: 33957.399999999914,
    tank_volume_stop: 33899.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:13:25',
    timestamp_stop: '2022-01-01T16:13:30',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 138359,
    tank_volume_start: 33899.49999999991,
    tank_volume_stop: 33845.49999999991,
  },
  {
    timestamp_start: '2022-01-01T16:13:45',
    timestamp_stop: '2022-01-01T16:13:51',
    quantity: 59.9,
    vehicle: 'DEM777',
    id: 138361,
    tank_volume_start: 33845.49999999991,
    tank_volume_stop: 33785.59999999991,
  },
  {
    timestamp_start: '2022-01-01T16:14:06',
    timestamp_stop: '2022-01-01T16:14:11',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 138363,
    tank_volume_start: 33785.59999999991,
    tank_volume_stop: 33730.79999999991,
  },
  {
    timestamp_start: '2022-01-01T16:14:26',
    timestamp_stop: '2022-01-01T16:14:33',
    quantity: 61.2,
    vehicle: 'DEM111',
    id: 138365,
    tank_volume_start: 33730.79999999991,
    tank_volume_stop: 33669.59999999991,
  },
  {
    timestamp_start: '2022-01-01T16:14:48',
    timestamp_stop: '2022-01-01T16:14:53',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 138367,
    tank_volume_start: 33669.59999999991,
    tank_volume_stop: 33614.79999999991,
  },
  {
    timestamp_start: '2022-01-01T16:15:08',
    timestamp_stop: '2022-01-01T16:15:13',
    quantity: 48.1,
    vehicle: 'DEM666',
    id: 138369,
    tank_volume_start: 33614.79999999991,
    tank_volume_stop: 33566.69999999991,
  },
  {
    timestamp_start: '2022-01-01T16:15:28',
    timestamp_stop: '2022-01-01T16:15:33',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 138371,
    tank_volume_start: 33566.69999999991,
    tank_volume_stop: 33511.89999999991,
  },
  {
    timestamp_start: '2022-01-01T16:15:48',
    timestamp_stop: '2022-01-01T16:15:53',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 138373,
    tank_volume_start: 33511.89999999991,
    tank_volume_stop: 33461.599999999904,
  },
  {
    timestamp_start: '2022-01-01T16:16:08',
    timestamp_stop: '2022-01-01T16:16:15',
    quantity: 64.9,
    vehicle: 'DEM000',
    id: 138375,
    tank_volume_start: 33461.599999999904,
    tank_volume_stop: 33396.6999999999,
  },
  {
    timestamp_start: '2022-01-01T16:16:30',
    timestamp_stop: '2022-01-01T16:16:36',
    quantity: 62.3,
    vehicle: 'DEM444',
    id: 138377,
    tank_volume_start: 33396.6999999999,
    tank_volume_stop: 33334.3999999999,
  },
  {
    timestamp_start: '2022-01-01T16:16:51',
    timestamp_stop: '2022-01-01T16:16:57',
    quantity: 56.9,
    vehicle: 'DEM888',
    id: 138379,
    tank_volume_start: 33334.3999999999,
    tank_volume_stop: 33277.4999999999,
  },
  {
    timestamp_start: '2022-01-01T16:17:12',
    timestamp_stop: '2022-01-01T16:17:17',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 138381,
    tank_volume_start: 33277.4999999999,
    tank_volume_stop: 33222.4999999999,
  },
  {
    timestamp_start: '2022-01-01T16:17:32',
    timestamp_stop: '2022-01-01T16:17:38',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 138383,
    tank_volume_start: 33222.4999999999,
    tank_volume_stop: 33163.3999999999,
  },
  {
    timestamp_start: '2022-01-01T16:17:53',
    timestamp_stop: '2022-01-01T16:17:59',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 138385,
    tank_volume_start: 33163.3999999999,
    tank_volume_stop: 33109.5999999999,
  },
  {
    timestamp_start: '2022-01-01T16:18:14',
    timestamp_stop: '2022-01-01T16:18:19',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 138387,
    tank_volume_start: 33109.5999999999,
    tank_volume_stop: 33054.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:18:34',
    timestamp_stop: '2022-01-01T16:18:40',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 138389,
    tank_volume_start: 33054.799999999894,
    tank_volume_stop: 32995.39999999989,
  },
  {
    timestamp_start: '2022-01-01T16:18:55',
    timestamp_stop: '2022-01-01T16:19:00',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 138391,
    tank_volume_start: 32995.39999999989,
    tank_volume_stop: 32942.89999999989,
  },
  {
    timestamp_start: '2022-01-01T16:19:15',
    timestamp_stop: '2022-01-01T16:19:21',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 138393,
    tank_volume_start: 32942.89999999989,
    tank_volume_stop: 32886.59999999989,
  },
  {
    timestamp_start: '2022-01-01T16:19:36',
    timestamp_stop: '2022-01-01T16:19:42',
    quantity: 63.1,
    vehicle: 'DEM333',
    id: 138395,
    tank_volume_start: 32886.59999999989,
    tank_volume_stop: 32823.49999999989,
  },
  {
    timestamp_start: '2022-01-01T16:19:57',
    timestamp_stop: '2022-01-01T16:20:02',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 138397,
    tank_volume_start: 32823.49999999989,
    tank_volume_stop: 32771.39999999989,
  },
  {
    timestamp_start: '2022-01-01T16:20:17',
    timestamp_stop: '2022-01-01T16:20:24',
    quantity: 61.8,
    vehicle: 'DEM666',
    id: 138399,
    tank_volume_start: 32771.39999999989,
    tank_volume_stop: 32709.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:20:39',
    timestamp_stop: '2022-01-01T16:20:44',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 138401,
    tank_volume_start: 32709.599999999893,
    tank_volume_stop: 32658.399999999892,
  },
  {
    timestamp_start: '2022-01-01T16:20:59',
    timestamp_stop: '2022-01-01T16:21:04',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 138403,
    tank_volume_start: 32658.399999999892,
    tank_volume_stop: 32608.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:21:19',
    timestamp_stop: '2022-01-01T16:21:24',
    quantity: 57.7,
    vehicle: 'DEM111',
    id: 138405,
    tank_volume_start: 32608.69999999989,
    tank_volume_stop: 32550.99999999989,
  },
  {
    timestamp_start: '2022-01-01T16:21:39',
    timestamp_stop: '2022-01-01T16:21:45',
    quantity: 57.8,
    vehicle: 'DEM888',
    id: 138407,
    tank_volume_start: 32550.99999999989,
    tank_volume_stop: 32493.19999999989,
  },
  {
    timestamp_start: '2022-01-01T16:22:00',
    timestamp_stop: '2022-01-01T16:22:05',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 138409,
    tank_volume_start: 32493.19999999989,
    tank_volume_stop: 32441.899999999892,
  },
  {
    timestamp_start: '2022-01-01T16:22:20',
    timestamp_stop: '2022-01-01T16:22:26',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 138411,
    tank_volume_start: 32441.899999999892,
    tank_volume_stop: 32389.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:22:41',
    timestamp_stop: '2022-01-01T16:22:47',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 138413,
    tank_volume_start: 32389.099999999893,
    tank_volume_stop: 32326.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:23:02',
    timestamp_stop: '2022-01-01T16:23:07',
    quantity: 52.9,
    vehicle: 'DEM222',
    id: 138415,
    tank_volume_start: 32326.999999999894,
    tank_volume_stop: 32274.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:23:22',
    timestamp_stop: '2022-01-01T16:23:28',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 138417,
    tank_volume_start: 32274.099999999893,
    tank_volume_stop: 32218.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:23:43',
    timestamp_stop: '2022-01-01T16:23:47',
    quantity: 46.2,
    vehicle: 'DEM888',
    id: 138419,
    tank_volume_start: 32218.299999999894,
    tank_volume_stop: 32172.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:24:02',
    timestamp_stop: '2022-01-01T16:24:08',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 138421,
    tank_volume_start: 32172.099999999893,
    tank_volume_stop: 32118.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:24:23',
    timestamp_stop: '2022-01-01T16:24:28',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 138423,
    tank_volume_start: 32118.799999999894,
    tank_volume_stop: 32066.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:24:43',
    timestamp_stop: '2022-01-01T16:24:48',
    quantity: 49.1,
    vehicle: 'DEM222',
    id: 138425,
    tank_volume_start: 32066.099999999893,
    tank_volume_stop: 32016.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:25:03',
    timestamp_stop: '2022-01-01T16:25:07',
    quantity: 45.4,
    vehicle: 'DEM777',
    id: 138427,
    tank_volume_start: 32016.999999999894,
    tank_volume_stop: 31971.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:25:22',
    timestamp_stop: '2022-01-01T16:25:27',
    quantity: 49.0,
    vehicle: 'DEM111',
    id: 138429,
    tank_volume_start: 31971.599999999893,
    tank_volume_stop: 31922.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:25:42',
    timestamp_stop: '2022-01-01T16:25:47',
    quantity: 50.9,
    vehicle: 'DEM222',
    id: 138431,
    tank_volume_start: 31922.599999999893,
    tank_volume_stop: 31871.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:26:02',
    timestamp_stop: '2022-01-01T16:26:08',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 138433,
    tank_volume_start: 31871.69999999989,
    tank_volume_stop: 31814.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:26:23',
    timestamp_stop: '2022-01-01T16:26:28',
    quantity: 48.6,
    vehicle: 'DEM222',
    id: 138435,
    tank_volume_start: 31814.69999999989,
    tank_volume_stop: 31766.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:26:43',
    timestamp_stop: '2022-01-01T16:26:48',
    quantity: 47.0,
    vehicle: 'DEM444',
    id: 138437,
    tank_volume_start: 31766.099999999893,
    tank_volume_stop: 31719.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:27:03',
    timestamp_stop: '2022-01-01T16:27:08',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 138438,
    tank_volume_start: 31719.099999999893,
    tank_volume_stop: 31666.899999999892,
  },
  {
    timestamp_start: '2022-01-01T16:27:23',
    timestamp_stop: '2022-01-01T16:27:28',
    quantity: 49.0,
    vehicle: 'DEM555',
    id: 138440,
    tank_volume_start: 31666.899999999892,
    tank_volume_stop: 31617.899999999892,
  },
  {
    timestamp_start: '2022-01-01T16:27:43',
    timestamp_stop: '2022-01-01T16:27:48',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 138442,
    tank_volume_start: 31617.899999999892,
    tank_volume_stop: 31567.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:28:03',
    timestamp_stop: '2022-01-01T16:28:09',
    quantity: 60.3,
    vehicle: 'DEM444',
    id: 138444,
    tank_volume_start: 31567.799999999894,
    tank_volume_stop: 31507.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:28:24',
    timestamp_stop: '2022-01-01T16:28:29',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 138446,
    tank_volume_start: 31507.499999999894,
    tank_volume_stop: 31453.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:28:44',
    timestamp_stop: '2022-01-01T16:28:49',
    quantity: 49.2,
    vehicle: 'DEM000',
    id: 138448,
    tank_volume_start: 31453.599999999893,
    tank_volume_stop: 31404.399999999892,
  },
  {
    timestamp_start: '2022-01-01T16:29:04',
    timestamp_stop: '2022-01-01T16:29:10',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 138450,
    tank_volume_start: 31404.399999999892,
    tank_volume_stop: 31349.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:29:25',
    timestamp_stop: '2022-01-01T16:29:30',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 138452,
    tank_volume_start: 31349.69999999989,
    tank_volume_stop: 31293.29999999989,
  },
  {
    timestamp_start: '2022-01-01T16:29:45',
    timestamp_stop: '2022-01-01T16:29:52',
    quantity: 64.0,
    vehicle: 'DEM777',
    id: 138454,
    tank_volume_start: 31293.29999999989,
    tank_volume_stop: 31229.29999999989,
  },
  {
    timestamp_start: '2022-01-01T16:30:07',
    timestamp_stop: '2022-01-01T16:30:12',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 138456,
    tank_volume_start: 31229.29999999989,
    tank_volume_stop: 31172.09999999989,
  },
  {
    timestamp_start: '2022-01-01T16:30:27',
    timestamp_stop: '2022-01-01T16:30:33',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 138458,
    tank_volume_start: 31172.09999999989,
    tank_volume_stop: 31115.99999999989,
  },
  {
    timestamp_start: '2022-01-01T16:30:48',
    timestamp_stop: '2022-01-01T16:30:54',
    quantity: 58.6,
    vehicle: 'DEM777',
    id: 138460,
    tank_volume_start: 31115.99999999989,
    tank_volume_stop: 31057.399999999892,
  },
  {
    timestamp_start: '2022-01-01T16:31:09',
    timestamp_stop: '2022-01-01T16:31:15',
    quantity: 58.0,
    vehicle: 'DEM222',
    id: 138462,
    tank_volume_start: 31057.399999999892,
    tank_volume_stop: 30999.399999999892,
  },
  {
    timestamp_start: '2022-01-01T16:31:30',
    timestamp_stop: '2022-01-01T16:31:35',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 138464,
    tank_volume_start: 30999.399999999892,
    tank_volume_stop: 30940.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:31:50',
    timestamp_stop: '2022-01-01T16:31:56',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 138466,
    tank_volume_start: 30940.69999999989,
    tank_volume_stop: 30884.49999999989,
  },
  {
    timestamp_start: '2022-01-01T16:32:11',
    timestamp_stop: '2022-01-01T16:32:16',
    quantity: 48.7,
    vehicle: 'DEM444',
    id: 138469,
    tank_volume_start: 30884.49999999989,
    tank_volume_stop: 30835.79999999989,
  },
  {
    timestamp_start: '2022-01-01T16:32:31',
    timestamp_stop: '2022-01-01T16:32:37',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 138470,
    tank_volume_start: 30835.79999999989,
    tank_volume_stop: 30778.99999999989,
  },
  {
    timestamp_start: '2022-01-01T16:32:52',
    timestamp_stop: '2022-01-01T16:32:57',
    quantity: 49.2,
    vehicle: 'DEM444',
    id: 138472,
    tank_volume_start: 30778.99999999989,
    tank_volume_stop: 30729.79999999989,
  },
  {
    timestamp_start: '2022-01-01T16:33:12',
    timestamp_stop: '2022-01-01T16:33:18',
    quantity: 63.1,
    vehicle: 'DEM555',
    id: 138474,
    tank_volume_start: 30729.79999999989,
    tank_volume_stop: 30666.69999999989,
  },
  {
    timestamp_start: '2022-01-01T16:33:33',
    timestamp_stop: '2022-01-01T16:33:38',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 138477,
    tank_volume_start: 30666.69999999989,
    tank_volume_stop: 30610.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:33:53',
    timestamp_stop: '2022-01-01T16:33:59',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 138478,
    tank_volume_start: 30610.599999999893,
    tank_volume_stop: 30554.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:34:14',
    timestamp_stop: '2022-01-01T16:34:19',
    quantity: 52.3,
    vehicle: 'DEM666',
    id: 138481,
    tank_volume_start: 30554.599999999893,
    tank_volume_stop: 30502.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:34:34',
    timestamp_stop: '2022-01-01T16:34:40',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 138483,
    tank_volume_start: 30502.299999999894,
    tank_volume_stop: 30447.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:34:55',
    timestamp_stop: '2022-01-01T16:35:01',
    quantity: 60.1,
    vehicle: 'DEM666',
    id: 138485,
    tank_volume_start: 30447.099999999893,
    tank_volume_stop: 30386.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:35:16',
    timestamp_stop: '2022-01-01T16:35:21',
    quantity: 47.1,
    vehicle: 'DEM555',
    id: 138487,
    tank_volume_start: 30386.999999999894,
    tank_volume_stop: 30339.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:35:36',
    timestamp_stop: '2022-01-01T16:35:41',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 138488,
    tank_volume_start: 30339.899999999896,
    tank_volume_stop: 30287.199999999895,
  },
  {
    timestamp_start: '2022-01-01T16:35:56',
    timestamp_stop: '2022-01-01T16:36:02',
    quantity: 59.7,
    vehicle: 'DEM111',
    id: 138490,
    tank_volume_start: 30287.199999999895,
    tank_volume_stop: 30227.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:36:17',
    timestamp_stop: '2022-01-01T16:36:21',
    quantity: 46.7,
    vehicle: 'DEM222',
    id: 138493,
    tank_volume_start: 30227.499999999894,
    tank_volume_stop: 30180.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:36:36',
    timestamp_stop: '2022-01-01T16:36:43',
    quantity: 64.3,
    vehicle: 'DEM222',
    id: 138494,
    tank_volume_start: 30180.799999999894,
    tank_volume_stop: 30116.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:36:58',
    timestamp_stop: '2022-01-01T16:37:03',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 138497,
    tank_volume_start: 30116.499999999894,
    tank_volume_stop: 30060.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:37:18',
    timestamp_stop: '2022-01-01T16:37:23',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 138499,
    tank_volume_start: 30060.999999999894,
    tank_volume_stop: 30010.699999999895,
  },
  {
    timestamp_start: '2022-01-01T16:37:38',
    timestamp_stop: '2022-01-01T16:37:44',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 138500,
    tank_volume_start: 30010.699999999895,
    tank_volume_stop: 29953.399999999896,
  },
  {
    timestamp_start: '2022-01-01T16:37:59',
    timestamp_stop: '2022-01-01T16:38:05',
    quantity: 61.9,
    vehicle: 'DEM555',
    id: 138502,
    tank_volume_start: 29953.399999999896,
    tank_volume_stop: 29891.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:38:20',
    timestamp_stop: '2022-01-01T16:38:26',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 138505,
    tank_volume_start: 29891.499999999894,
    tank_volume_stop: 29835.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:38:41',
    timestamp_stop: '2022-01-01T16:38:46',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 138507,
    tank_volume_start: 29835.299999999894,
    tank_volume_stop: 29780.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:39:01',
    timestamp_stop: '2022-01-01T16:39:06',
    quantity: 47.9,
    vehicle: 'DEM000',
    id: 138509,
    tank_volume_start: 29780.099999999893,
    tank_volume_stop: 29732.19999999989,
  },
  {
    timestamp_start: '2022-01-01T16:39:21',
    timestamp_stop: '2022-01-01T16:39:27',
    quantity: 60.2,
    vehicle: 'DEM666',
    id: 138511,
    tank_volume_start: 29732.19999999989,
    tank_volume_stop: 29671.99999999989,
  },
  {
    timestamp_start: '2022-01-01T16:39:42',
    timestamp_stop: '2022-01-01T16:39:48',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 138513,
    tank_volume_start: 29671.99999999989,
    tank_volume_stop: 29615.79999999989,
  },
  {
    timestamp_start: '2022-01-01T16:40:03',
    timestamp_stop: '2022-01-01T16:40:09',
    quantity: 59.4,
    vehicle: 'DEM777',
    id: 138515,
    tank_volume_start: 29615.79999999989,
    tank_volume_stop: 29556.39999999989,
  },
  {
    timestamp_start: '2022-01-01T16:40:24',
    timestamp_stop: '2022-01-01T16:40:29',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 138517,
    tank_volume_start: 29556.39999999989,
    tank_volume_stop: 29503.29999999989,
  },
  {
    timestamp_start: '2022-01-01T16:40:44',
    timestamp_stop: '2022-01-01T16:40:51',
    quantity: 64.5,
    vehicle: 'DEM888',
    id: 138519,
    tank_volume_start: 29503.29999999989,
    tank_volume_stop: 29438.79999999989,
  },
  {
    timestamp_start: '2022-01-01T16:41:06',
    timestamp_stop: '2022-01-01T16:41:10',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 138521,
    tank_volume_start: 29438.79999999989,
    tank_volume_stop: 29391.19999999989,
  },
  {
    timestamp_start: '2022-01-01T16:41:25',
    timestamp_stop: '2022-01-01T16:41:32',
    quantity: 62.1,
    vehicle: 'DEM666',
    id: 138523,
    tank_volume_start: 29391.19999999989,
    tank_volume_stop: 29329.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:41:47',
    timestamp_stop: '2022-01-01T16:41:53',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 138525,
    tank_volume_start: 29329.099999999893,
    tank_volume_stop: 29266.899999999892,
  },
  {
    timestamp_start: '2022-01-01T16:42:08',
    timestamp_stop: '2022-01-01T16:42:13',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 138527,
    tank_volume_start: 29266.899999999892,
    tank_volume_stop: 29215.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:42:28',
    timestamp_stop: '2022-01-01T16:42:34',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 138529,
    tank_volume_start: 29215.799999999894,
    tank_volume_stop: 29156.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:42:49',
    timestamp_stop: '2022-01-01T16:42:55',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 138531,
    tank_volume_start: 29156.999999999894,
    tank_volume_stop: 29094.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:43:10',
    timestamp_stop: '2022-01-01T16:43:16',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 138533,
    tank_volume_start: 29094.899999999896,
    tank_volume_stop: 29038.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:43:31',
    timestamp_stop: '2022-01-01T16:43:35',
    quantity: 46.3,
    vehicle: 'DEM888',
    id: 138535,
    tank_volume_start: 29038.899999999896,
    tank_volume_stop: 28992.599999999897,
  },
  {
    timestamp_start: '2022-01-01T16:43:50',
    timestamp_stop: '2022-01-01T16:43:56',
    quantity: 60.2,
    vehicle: 'DEM111',
    id: 138537,
    tank_volume_start: 28992.599999999897,
    tank_volume_stop: 28932.399999999896,
  },
  {
    timestamp_start: '2022-01-01T16:44:11',
    timestamp_stop: '2022-01-01T16:44:17',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 138539,
    tank_volume_start: 28932.399999999896,
    tank_volume_stop: 28875.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:44:32',
    timestamp_stop: '2022-01-01T16:44:37',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 138541,
    tank_volume_start: 28875.999999999894,
    tank_volume_stop: 28824.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:44:52',
    timestamp_stop: '2022-01-01T16:44:58',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 138543,
    tank_volume_start: 28824.499999999894,
    tank_volume_stop: 28766.699999999895,
  },
  {
    timestamp_start: '2022-01-01T16:45:13',
    timestamp_stop: '2022-01-01T16:45:19',
    quantity: 60.6,
    vehicle: 'DEM888',
    id: 138545,
    tank_volume_start: 28766.699999999895,
    tank_volume_stop: 28706.099999999897,
  },
  {
    timestamp_start: '2022-01-01T16:45:34',
    timestamp_stop: '2022-01-01T16:45:39',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 138547,
    tank_volume_start: 28706.099999999897,
    tank_volume_stop: 28654.599999999897,
  },
  {
    timestamp_start: '2022-01-01T16:45:54',
    timestamp_stop: '2022-01-01T16:46:00',
    quantity: 56.9,
    vehicle: 'DEM777',
    id: 138549,
    tank_volume_start: 28654.599999999897,
    tank_volume_stop: 28597.699999999895,
  },
  {
    timestamp_start: '2022-01-01T16:46:15',
    timestamp_stop: '2022-01-01T16:46:20',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 138551,
    tank_volume_start: 28597.699999999895,
    tank_volume_stop: 28541.399999999896,
  },
  {
    timestamp_start: '2022-01-01T16:46:35',
    timestamp_stop: '2022-01-01T16:46:40',
    quantity: 50.5,
    vehicle: 'DEM222',
    id: 138553,
    tank_volume_start: 28541.399999999896,
    tank_volume_stop: 28490.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:46:55',
    timestamp_stop: '2022-01-01T16:47:02',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 138555,
    tank_volume_start: 28490.899999999896,
    tank_volume_stop: 28427.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:47:17',
    timestamp_stop: '2022-01-01T16:47:23',
    quantity: 60.0,
    vehicle: 'DEM888',
    id: 138557,
    tank_volume_start: 28427.999999999894,
    tank_volume_stop: 28367.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:47:38',
    timestamp_stop: '2022-01-01T16:47:43',
    quantity: 57.8,
    vehicle: 'DEM777',
    id: 138559,
    tank_volume_start: 28367.999999999894,
    tank_volume_stop: 28310.199999999895,
  },
  {
    timestamp_start: '2022-01-01T16:47:58',
    timestamp_stop: '2022-01-01T16:48:04',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 138561,
    tank_volume_start: 28310.199999999895,
    tank_volume_stop: 28250.399999999896,
  },
  {
    timestamp_start: '2022-01-01T16:48:19',
    timestamp_stop: '2022-01-01T16:48:25',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 138563,
    tank_volume_start: 28250.399999999896,
    tank_volume_stop: 28194.699999999895,
  },
  {
    timestamp_start: '2022-01-01T16:48:40',
    timestamp_stop: '2022-01-01T16:48:46',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 138565,
    tank_volume_start: 28194.699999999895,
    tank_volume_stop: 28139.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:49:01',
    timestamp_stop: '2022-01-01T16:49:06',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 138567,
    tank_volume_start: 28139.299999999894,
    tank_volume_stop: 28082.899999999892,
  },
  {
    timestamp_start: '2022-01-01T16:49:21',
    timestamp_stop: '2022-01-01T16:49:27',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 138569,
    tank_volume_start: 28082.899999999892,
    tank_volume_stop: 28026.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:49:42',
    timestamp_stop: '2022-01-01T16:49:47',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 138571,
    tank_volume_start: 28026.099999999893,
    tank_volume_stop: 27972.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:50:02',
    timestamp_stop: '2022-01-01T16:50:08',
    quantity: 61.0,
    vehicle: 'DEM222',
    id: 138573,
    tank_volume_start: 27972.599999999893,
    tank_volume_stop: 27911.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:50:23',
    timestamp_stop: '2022-01-01T16:50:29',
    quantity: 60.2,
    vehicle: 'DEM555',
    id: 138575,
    tank_volume_start: 27911.599999999893,
    tank_volume_stop: 27851.399999999892,
  },
  {
    timestamp_start: '2022-01-01T16:50:44',
    timestamp_stop: '2022-01-01T16:50:50',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 138577,
    tank_volume_start: 27851.399999999892,
    tank_volume_stop: 27799.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:51:05',
    timestamp_stop: '2022-01-01T16:51:11',
    quantity: 64.0,
    vehicle: 'DEM111',
    id: 138579,
    tank_volume_start: 27799.599999999893,
    tank_volume_stop: 27735.599999999893,
  },
  {
    timestamp_start: '2022-01-01T16:51:26',
    timestamp_stop: '2022-01-01T16:51:31',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 138581,
    tank_volume_start: 27735.599999999893,
    tank_volume_stop: 27684.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:51:46',
    timestamp_stop: '2022-01-01T16:51:52',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 138583,
    tank_volume_start: 27684.999999999894,
    tank_volume_stop: 27627.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:52:07',
    timestamp_stop: '2022-01-01T16:52:13',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 138585,
    tank_volume_start: 27627.799999999894,
    tank_volume_stop: 27567.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:52:28',
    timestamp_stop: '2022-01-01T16:52:34',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 138587,
    tank_volume_start: 27567.299999999894,
    tank_volume_stop: 27509.799999999894,
  },
  {
    timestamp_start: '2022-01-01T16:52:49',
    timestamp_stop: '2022-01-01T16:52:54',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 138589,
    tank_volume_start: 27509.799999999894,
    tank_volume_stop: 27454.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:53:09',
    timestamp_stop: '2022-01-01T16:53:14',
    quantity: 47.0,
    vehicle: 'DEM000',
    id: 138591,
    tank_volume_start: 27454.299999999894,
    tank_volume_stop: 27407.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:53:29',
    timestamp_stop: '2022-01-01T16:53:34',
    quantity: 50.0,
    vehicle: 'DEM777',
    id: 138593,
    tank_volume_start: 27407.299999999894,
    tank_volume_stop: 27357.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:53:49',
    timestamp_stop: '2022-01-01T16:53:54',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 138595,
    tank_volume_start: 27357.299999999894,
    tank_volume_stop: 27303.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:54:09',
    timestamp_stop: '2022-01-01T16:54:15',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 138597,
    tank_volume_start: 27303.299999999894,
    tank_volume_stop: 27245.299999999894,
  },
  {
    timestamp_start: '2022-01-01T16:54:30',
    timestamp_stop: '2022-01-01T16:54:35',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 138599,
    tank_volume_start: 27245.299999999894,
    tank_volume_stop: 27191.999999999894,
  },
  {
    timestamp_start: '2022-01-01T16:54:50',
    timestamp_stop: '2022-01-01T16:54:57',
    quantity: 62.9,
    vehicle: 'DEM222',
    id: 138601,
    tank_volume_start: 27191.999999999894,
    tank_volume_stop: 27129.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:55:12',
    timestamp_stop: '2022-01-01T16:55:17',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 138603,
    tank_volume_start: 27129.099999999893,
    tank_volume_stop: 27072.099999999893,
  },
  {
    timestamp_start: '2022-01-01T16:55:32',
    timestamp_stop: '2022-01-01T16:55:38',
    quantity: 53.6,
    vehicle: 'DEM111',
    id: 138605,
    tank_volume_start: 27072.099999999893,
    tank_volume_stop: 27018.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:55:53',
    timestamp_stop: '2022-01-01T16:55:58',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 138607,
    tank_volume_start: 27018.499999999894,
    tank_volume_stop: 26960.399999999896,
  },
  {
    timestamp_start: '2022-01-01T16:56:13',
    timestamp_stop: '2022-01-01T16:56:19',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 138609,
    tank_volume_start: 26960.399999999896,
    tank_volume_stop: 26906.099999999897,
  },
  {
    timestamp_start: '2022-01-01T16:56:34',
    timestamp_stop: '2022-01-01T16:56:39',
    quantity: 50.5,
    vehicle: 'DEM444',
    id: 138611,
    tank_volume_start: 26906.099999999897,
    tank_volume_stop: 26855.599999999897,
  },
  {
    timestamp_start: '2022-01-01T16:56:54',
    timestamp_stop: '2022-01-01T16:56:59',
    quantity: 46.8,
    vehicle: 'DEM222',
    id: 138613,
    tank_volume_start: 26855.599999999897,
    tank_volume_stop: 26808.799999999897,
  },
  {
    timestamp_start: '2022-01-01T16:57:14',
    timestamp_stop: '2022-01-01T16:57:19',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 138615,
    tank_volume_start: 26808.799999999897,
    tank_volume_stop: 26752.099999999897,
  },
  {
    timestamp_start: '2022-01-01T16:57:34',
    timestamp_stop: '2022-01-01T16:57:40',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 138617,
    tank_volume_start: 26752.099999999897,
    tank_volume_stop: 26694.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:57:55',
    timestamp_stop: '2022-01-01T16:58:01',
    quantity: 59.7,
    vehicle: 'DEM444',
    id: 138619,
    tank_volume_start: 26694.899999999896,
    tank_volume_stop: 26635.199999999895,
  },
  {
    timestamp_start: '2022-01-01T16:58:16',
    timestamp_stop: '2022-01-01T16:58:22',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 138621,
    tank_volume_start: 26635.199999999895,
    tank_volume_stop: 26575.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:58:37',
    timestamp_stop: '2022-01-01T16:58:42',
    quantity: 51.4,
    vehicle: 'DEM333',
    id: 138623,
    tank_volume_start: 26575.899999999896,
    tank_volume_stop: 26524.499999999894,
  },
  {
    timestamp_start: '2022-01-01T16:58:57',
    timestamp_stop: '2022-01-01T16:59:03',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 138625,
    tank_volume_start: 26524.499999999894,
    tank_volume_stop: 26463.199999999895,
  },
  {
    timestamp_start: '2022-01-01T16:59:18',
    timestamp_stop: '2022-01-01T16:59:24',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 138627,
    tank_volume_start: 26463.199999999895,
    tank_volume_stop: 26403.599999999897,
  },
  {
    timestamp_start: '2022-01-01T16:59:39',
    timestamp_stop: '2022-01-01T16:59:44',
    quantity: 47.7,
    vehicle: 'DEM222',
    id: 138629,
    tank_volume_start: 26403.599999999897,
    tank_volume_stop: 26355.899999999896,
  },
  {
    timestamp_start: '2022-01-01T16:59:59',
    timestamp_stop: '2022-01-01T17:00:04',
    quantity: 49.5,
    vehicle: 'DEM555',
    id: 138631,
    tank_volume_start: 26355.899999999896,
    tank_volume_stop: 26306.399999999896,
  },
  {
    timestamp_start: '2022-01-01T17:00:19',
    timestamp_stop: '2022-01-01T17:00:25',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 138633,
    tank_volume_start: 26306.399999999896,
    tank_volume_stop: 26245.999999999894,
  },
  {
    timestamp_start: '2022-01-01T17:00:40',
    timestamp_stop: '2022-01-01T17:00:45',
    quantity: 49.8,
    vehicle: 'DEM222',
    id: 138635,
    tank_volume_start: 26245.999999999894,
    tank_volume_stop: 26196.199999999895,
  },
  {
    timestamp_start: '2022-01-01T17:01:00',
    timestamp_stop: '2022-01-01T17:01:06',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 138637,
    tank_volume_start: 26196.199999999895,
    tank_volume_stop: 26139.999999999894,
  },
  {
    timestamp_start: '2022-01-01T17:01:21',
    timestamp_stop: '2022-01-01T17:01:26',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138639,
    tank_volume_start: 26139.999999999894,
    tank_volume_stop: 26086.799999999894,
  },
  {
    timestamp_start: '2022-01-01T17:01:41',
    timestamp_stop: '2022-01-01T17:01:46',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 138641,
    tank_volume_start: 26086.799999999894,
    tank_volume_stop: 26032.399999999892,
  },
  {
    timestamp_start: '2022-01-01T17:02:01',
    timestamp_stop: '2022-01-01T17:02:08',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 138643,
    tank_volume_start: 26032.399999999892,
    tank_volume_stop: 25969.49999999989,
  },
  {
    timestamp_start: '2022-01-01T17:02:23',
    timestamp_stop: '2022-01-01T17:02:28',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 138645,
    tank_volume_start: 25969.49999999989,
    tank_volume_stop: 25917.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:02:43',
    timestamp_stop: '2022-01-01T17:02:49',
    quantity: 62.6,
    vehicle: 'DEM000',
    id: 138647,
    tank_volume_start: 25917.79999999989,
    tank_volume_stop: 25855.19999999989,
  },
  {
    timestamp_start: '2022-01-01T17:03:04',
    timestamp_stop: '2022-01-01T17:03:10',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 138649,
    tank_volume_start: 25855.19999999989,
    tank_volume_stop: 25795.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:03:25',
    timestamp_stop: '2022-01-01T17:03:31',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 138651,
    tank_volume_start: 25795.79999999989,
    tank_volume_stop: 25738.09999999989,
  },
  {
    timestamp_start: '2022-01-01T17:03:46',
    timestamp_stop: '2022-01-01T17:03:51',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 138653,
    tank_volume_start: 25738.09999999989,
    tank_volume_stop: 25685.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:04:06',
    timestamp_stop: '2022-01-01T17:04:11',
    quantity: 49.9,
    vehicle: 'DEM555',
    id: 138655,
    tank_volume_start: 25685.59999999989,
    tank_volume_stop: 25635.699999999888,
  },
  {
    timestamp_start: '2022-01-01T17:04:26',
    timestamp_stop: '2022-01-01T17:04:32',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 138657,
    tank_volume_start: 25635.699999999888,
    tank_volume_stop: 25574.799999999886,
  },
  {
    timestamp_start: '2022-01-01T17:04:47',
    timestamp_stop: '2022-01-01T17:04:52',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 138659,
    tank_volume_start: 25574.799999999886,
    tank_volume_stop: 25521.799999999886,
  },
  {
    timestamp_start: '2022-01-01T17:05:07',
    timestamp_stop: '2022-01-01T17:05:13',
    quantity: 57.9,
    vehicle: 'DEM111',
    id: 138661,
    tank_volume_start: 25521.799999999886,
    tank_volume_stop: 25463.899999999885,
  },
  {
    timestamp_start: '2022-01-01T17:05:28',
    timestamp_stop: '2022-01-01T17:05:33',
    quantity: 47.6,
    vehicle: 'DEM777',
    id: 138663,
    tank_volume_start: 25463.899999999885,
    tank_volume_stop: 25416.299999999886,
  },
  {
    timestamp_start: '2022-01-01T17:05:48',
    timestamp_stop: '2022-01-01T17:05:54',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 138665,
    tank_volume_start: 25416.299999999886,
    tank_volume_stop: 25354.099999999886,
  },
  {
    timestamp_start: '2022-01-01T17:06:09',
    timestamp_stop: '2022-01-01T17:06:15',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 138667,
    tank_volume_start: 25354.099999999886,
    tank_volume_stop: 25294.999999999887,
  },
  {
    timestamp_start: '2022-01-01T17:06:30',
    timestamp_stop: '2022-01-01T17:06:36',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 138669,
    tank_volume_start: 25294.999999999887,
    tank_volume_stop: 25238.099999999886,
  },
  {
    timestamp_start: '2022-01-01T17:06:51',
    timestamp_stop: '2022-01-01T17:06:56',
    quantity: 48.6,
    vehicle: 'DEM555',
    id: 138671,
    tank_volume_start: 25238.099999999886,
    tank_volume_stop: 25189.499999999887,
  },
  {
    timestamp_start: '2022-01-01T17:07:11',
    timestamp_stop: '2022-01-01T17:07:15',
    quantity: 47.2,
    vehicle: 'DEM222',
    id: 138673,
    tank_volume_start: 25189.499999999887,
    tank_volume_stop: 25142.299999999886,
  },
  {
    timestamp_start: '2022-01-01T17:07:30',
    timestamp_stop: '2022-01-01T17:07:36',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 138675,
    tank_volume_start: 25142.299999999886,
    tank_volume_stop: 25089.899999999885,
  },
  {
    timestamp_start: '2022-01-01T17:07:51',
    timestamp_stop: '2022-01-01T17:07:57',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 138677,
    tank_volume_start: 25089.899999999885,
    tank_volume_stop: 25027.999999999884,
  },
  {
    timestamp_start: '2022-01-01T17:08:12',
    timestamp_stop: '2022-01-01T17:08:17',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 138679,
    tank_volume_start: 25027.999999999884,
    tank_volume_stop: 24977.499999999884,
  },
  {
    timestamp_start: '2022-01-01T17:08:32',
    timestamp_stop: '2022-01-01T17:08:37',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 138681,
    tank_volume_start: 24977.499999999884,
    tank_volume_stop: 24922.899999999885,
  },
  {
    timestamp_start: '2022-01-01T17:08:52',
    timestamp_stop: '2022-01-01T17:08:57',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 138683,
    tank_volume_start: 24922.899999999885,
    tank_volume_stop: 24870.799999999886,
  },
  {
    timestamp_start: '2022-01-01T17:09:12',
    timestamp_stop: '2022-01-01T17:09:18',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 138685,
    tank_volume_start: 24870.799999999886,
    tank_volume_stop: 24813.099999999886,
  },
  {
    timestamp_start: '2022-01-01T17:09:33',
    timestamp_stop: '2022-01-01T17:09:40',
    quantity: 64.5,
    vehicle: 'DEM555',
    id: 138687,
    tank_volume_start: 24813.099999999886,
    tank_volume_stop: 24748.599999999886,
  },
  {
    timestamp_start: '2022-01-01T17:09:55',
    timestamp_stop: '2022-01-01T17:10:00',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 138689,
    tank_volume_start: 24748.599999999886,
    tank_volume_stop: 24691.599999999886,
  },
  {
    timestamp_start: '2022-01-01T17:10:15',
    timestamp_stop: '2022-01-01T17:10:21',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 138691,
    tank_volume_start: 24691.599999999886,
    tank_volume_stop: 24635.699999999884,
  },
  {
    timestamp_start: '2022-01-01T17:10:36',
    timestamp_stop: '2022-01-01T17:10:41',
    quantity: 47.2,
    vehicle: 'DEM333',
    id: 138693,
    tank_volume_start: 24635.699999999884,
    tank_volume_stop: 24588.499999999884,
  },
  {
    timestamp_start: '2022-01-01T17:10:56',
    timestamp_stop: '2022-01-01T17:11:01',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 138695,
    tank_volume_start: 24588.499999999884,
    tank_volume_stop: 24531.299999999883,
  },
  {
    timestamp_start: '2022-01-01T17:11:16',
    timestamp_stop: '2022-01-01T17:11:22',
    quantity: 54.4,
    vehicle: 'DEM777',
    id: 138697,
    tank_volume_start: 24531.299999999883,
    tank_volume_stop: 24476.89999999988,
  },
  {
    timestamp_start: '2022-01-01T17:11:37',
    timestamp_stop: '2022-01-01T17:11:42',
    quantity: 52.2,
    vehicle: 'DEM777',
    id: 138699,
    tank_volume_start: 24476.89999999988,
    tank_volume_stop: 24424.69999999988,
  },
  {
    timestamp_start: '2022-01-01T17:11:57',
    timestamp_stop: '2022-01-01T17:12:03',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 138701,
    tank_volume_start: 24424.69999999988,
    tank_volume_stop: 24365.99999999988,
  },
  {
    timestamp_start: '2022-01-01T17:12:18',
    timestamp_stop: '2022-01-01T17:12:24',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 138703,
    tank_volume_start: 24365.99999999988,
    tank_volume_stop: 24307.89999999988,
  },
  {
    timestamp_start: '2022-01-01T17:12:39',
    timestamp_stop: '2022-01-01T17:12:44',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 138705,
    tank_volume_start: 24307.89999999988,
    tank_volume_stop: 24259.599999999882,
  },
  {
    timestamp_start: '2022-01-01T17:12:59',
    timestamp_stop: '2022-01-01T17:13:04',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 138707,
    tank_volume_start: 24259.599999999882,
    tank_volume_stop: 24206.39999999988,
  },
  {
    timestamp_start: '2022-01-01T17:13:19',
    timestamp_stop: '2022-01-01T17:13:24',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 138709,
    tank_volume_start: 24206.39999999988,
    tank_volume_stop: 24153.49999999988,
  },
  {
    timestamp_start: '2022-01-01T17:13:39',
    timestamp_stop: '2022-01-01T17:13:44',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 138711,
    tank_volume_start: 24153.49999999988,
    tank_volume_stop: 24104.19999999988,
  },
  {
    timestamp_start: '2022-01-01T17:13:59',
    timestamp_stop: '2022-01-01T17:14:05',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 138713,
    tank_volume_start: 24104.19999999988,
    tank_volume_stop: 24043.79999999988,
  },
  {
    timestamp_start: '2022-01-01T17:14:20',
    timestamp_stop: '2022-01-01T17:14:25',
    quantity: 53.2,
    vehicle: 'DEM111',
    id: 138715,
    tank_volume_start: 24043.79999999988,
    tank_volume_stop: 23990.59999999988,
  },
  {
    timestamp_start: '2022-01-01T17:14:40',
    timestamp_stop: '2022-01-01T17:14:45',
    quantity: 47.9,
    vehicle: 'DEM111',
    id: 138717,
    tank_volume_start: 23990.59999999988,
    tank_volume_stop: 23942.699999999877,
  },
  {
    timestamp_start: '2022-01-01T17:15:00',
    timestamp_stop: '2022-01-01T17:15:06',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 138719,
    tank_volume_start: 23942.699999999877,
    tank_volume_stop: 23887.699999999877,
  },
  {
    timestamp_start: '2022-01-01T17:15:21',
    timestamp_stop: '2022-01-01T17:15:26',
    quantity: 47.9,
    vehicle: 'DEM333',
    id: 138721,
    tank_volume_start: 23887.699999999877,
    tank_volume_stop: 23839.799999999876,
  },
  {
    timestamp_start: '2022-01-01T17:15:41',
    timestamp_stop: '2022-01-01T17:15:46',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 138723,
    tank_volume_start: 23839.799999999876,
    tank_volume_stop: 23783.599999999875,
  },
  {
    timestamp_start: '2022-01-01T17:16:01',
    timestamp_stop: '2022-01-01T17:16:07',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 138725,
    tank_volume_start: 23783.599999999875,
    tank_volume_stop: 23727.499999999876,
  },
  {
    timestamp_start: '2022-01-01T17:16:22',
    timestamp_stop: '2022-01-01T17:16:27',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 138727,
    tank_volume_start: 23727.499999999876,
    tank_volume_stop: 23676.799999999876,
  },
  {
    timestamp_start: '2022-01-01T17:16:42',
    timestamp_stop: '2022-01-01T17:16:47',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 138729,
    tank_volume_start: 23676.799999999876,
    tank_volume_stop: 23623.699999999877,
  },
  {
    timestamp_start: '2022-01-01T17:17:02',
    timestamp_stop: '2022-01-01T17:17:08',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 138731,
    tank_volume_start: 23623.699999999877,
    tank_volume_stop: 23569.499999999876,
  },
  {
    timestamp_start: '2022-01-01T17:17:23',
    timestamp_stop: '2022-01-01T17:17:28',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 138733,
    tank_volume_start: 23569.499999999876,
    tank_volume_stop: 23514.799999999876,
  },
  {
    timestamp_start: '2022-01-01T17:17:43',
    timestamp_stop: '2022-01-01T17:17:48',
    quantity: 46.5,
    vehicle: 'DEM555',
    id: 138735,
    tank_volume_start: 23514.799999999876,
    tank_volume_stop: 23468.299999999876,
  },
  {
    timestamp_start: '2022-01-01T17:18:03',
    timestamp_stop: '2022-01-01T17:18:09',
    quantity: 60.3,
    vehicle: 'DEM555',
    id: 138737,
    tank_volume_start: 23468.299999999876,
    tank_volume_stop: 23407.999999999876,
  },
  {
    timestamp_start: '2022-01-01T17:18:24',
    timestamp_stop: '2022-01-01T17:18:29',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 138739,
    tank_volume_start: 23407.999999999876,
    tank_volume_stop: 23350.799999999876,
  },
  {
    timestamp_start: '2022-01-01T17:18:44',
    timestamp_stop: '2022-01-01T17:18:50',
    quantity: 57.4,
    vehicle: 'DEM000',
    id: 138741,
    tank_volume_start: 23350.799999999876,
    tank_volume_stop: 23293.399999999874,
  },
  {
    timestamp_start: '2022-01-01T17:19:05',
    timestamp_stop: '2022-01-01T17:19:11',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 138743,
    tank_volume_start: 23293.399999999874,
    tank_volume_stop: 23232.999999999873,
  },
  {
    timestamp_start: '2022-01-01T17:19:26',
    timestamp_stop: '2022-01-01T17:19:32',
    quantity: 59.5,
    vehicle: 'DEM555',
    id: 138745,
    tank_volume_start: 23232.999999999873,
    tank_volume_stop: 23173.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:19:47',
    timestamp_stop: '2022-01-01T17:19:53',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 138747,
    tank_volume_start: 23173.499999999873,
    tank_volume_stop: 23115.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:20:08',
    timestamp_stop: '2022-01-01T17:20:14',
    quantity: 61.2,
    vehicle: 'DEM111',
    id: 138749,
    tank_volume_start: 23115.09999999987,
    tank_volume_stop: 23053.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:20:29',
    timestamp_stop: '2022-01-01T17:20:34',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 138751,
    tank_volume_start: 23053.89999999987,
    tank_volume_stop: 23000.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:20:49',
    timestamp_stop: '2022-01-01T17:20:55',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 138753,
    tank_volume_start: 23000.89999999987,
    tank_volume_stop: 22942.69999999987,
  },
  {
    timestamp_start: '2022-01-01T17:21:10',
    timestamp_stop: '2022-01-01T17:21:15',
    quantity: 48.6,
    vehicle: 'DEM777',
    id: 138755,
    tank_volume_start: 22942.69999999987,
    tank_volume_stop: 22894.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:21:30',
    timestamp_stop: '2022-01-01T17:21:36',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 138757,
    tank_volume_start: 22894.09999999987,
    tank_volume_stop: 22838.19999999987,
  },
  {
    timestamp_start: '2022-01-01T17:21:51',
    timestamp_stop: '2022-01-01T17:21:55',
    quantity: 46.8,
    vehicle: 'DEM666',
    id: 138759,
    tank_volume_start: 22838.19999999987,
    tank_volume_stop: 22791.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:22:10',
    timestamp_stop: '2022-01-01T17:22:16',
    quantity: 52.1,
    vehicle: 'DEM222',
    id: 138761,
    tank_volume_start: 22791.39999999987,
    tank_volume_stop: 22739.299999999872,
  },
  {
    timestamp_start: '2022-01-01T17:22:31',
    timestamp_stop: '2022-01-01T17:22:37',
    quantity: 61.3,
    vehicle: 'DEM333',
    id: 138763,
    tank_volume_start: 22739.299999999872,
    tank_volume_stop: 22677.999999999873,
  },
  {
    timestamp_start: '2022-01-01T17:22:52',
    timestamp_stop: '2022-01-01T17:22:57',
    quantity: 48.2,
    vehicle: 'DEM444',
    id: 138765,
    tank_volume_start: 22677.999999999873,
    tank_volume_stop: 22629.799999999872,
  },
  {
    timestamp_start: '2022-01-01T17:23:12',
    timestamp_stop: '2022-01-01T17:23:18',
    quantity: 61.9,
    vehicle: 'DEM000',
    id: 138767,
    tank_volume_start: 22629.799999999872,
    tank_volume_stop: 22567.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:23:33',
    timestamp_stop: '2022-01-01T17:23:38',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 138769,
    tank_volume_start: 22567.89999999987,
    tank_volume_stop: 22512.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:23:53',
    timestamp_stop: '2022-01-01T17:23:58',
    quantity: 46.2,
    vehicle: 'DEM888',
    id: 138771,
    tank_volume_start: 22512.39999999987,
    tank_volume_stop: 22466.19999999987,
  },
  {
    timestamp_start: '2022-01-01T17:24:13',
    timestamp_stop: '2022-01-01T17:24:18',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 138773,
    tank_volume_start: 22466.19999999987,
    tank_volume_stop: 22415.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:24:33',
    timestamp_stop: '2022-01-01T17:24:39',
    quantity: 57.6,
    vehicle: 'DEM888',
    id: 138775,
    tank_volume_start: 22415.09999999987,
    tank_volume_stop: 22357.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:24:54',
    timestamp_stop: '2022-01-01T17:25:00',
    quantity: 61.5,
    vehicle: 'DEM777',
    id: 138777,
    tank_volume_start: 22357.499999999873,
    tank_volume_stop: 22295.999999999873,
  },
  {
    timestamp_start: '2022-01-01T17:25:15',
    timestamp_stop: '2022-01-01T17:25:20',
    quantity: 49.5,
    vehicle: 'DEM000',
    id: 138779,
    tank_volume_start: 22295.999999999873,
    tank_volume_stop: 22246.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:25:35',
    timestamp_stop: '2022-01-01T17:25:40',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 138781,
    tank_volume_start: 22246.499999999873,
    tank_volume_stop: 22190.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:25:55',
    timestamp_stop: '2022-01-01T17:26:01',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 138783,
    tank_volume_start: 22190.59999999987,
    tank_volume_stop: 22133.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:26:16',
    timestamp_stop: '2022-01-01T17:26:21',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 138785,
    tank_volume_start: 22133.89999999987,
    tank_volume_stop: 22080.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:26:36',
    timestamp_stop: '2022-01-01T17:26:42',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 138787,
    tank_volume_start: 22080.39999999987,
    tank_volume_stop: 22024.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:26:57',
    timestamp_stop: '2022-01-01T17:27:02',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 138789,
    tank_volume_start: 22024.59999999987,
    tank_volume_stop: 21972.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:27:17',
    timestamp_stop: '2022-01-01T17:27:22',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 138791,
    tank_volume_start: 21972.499999999873,
    tank_volume_stop: 21921.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:27:37',
    timestamp_stop: '2022-01-01T17:27:43',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 138793,
    tank_volume_start: 21921.09999999987,
    tank_volume_stop: 21867.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:27:58',
    timestamp_stop: '2022-01-01T17:28:03',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 138795,
    tank_volume_start: 21867.59999999987,
    tank_volume_stop: 21816.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:28:18',
    timestamp_stop: '2022-01-01T17:28:23',
    quantity: 54.2,
    vehicle: 'DEM111',
    id: 138797,
    tank_volume_start: 21816.89999999987,
    tank_volume_stop: 21762.69999999987,
  },
  {
    timestamp_start: '2022-01-01T17:28:38',
    timestamp_stop: '2022-01-01T17:28:44',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 138799,
    tank_volume_start: 21762.69999999987,
    tank_volume_stop: 21708.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:28:59',
    timestamp_stop: '2022-01-01T17:29:04',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 138801,
    tank_volume_start: 21708.39999999987,
    tank_volume_stop: 21653.799999999872,
  },
  {
    timestamp_start: '2022-01-01T17:29:19',
    timestamp_stop: '2022-01-01T17:29:25',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 138803,
    tank_volume_start: 21653.799999999872,
    tank_volume_stop: 21594.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:29:40',
    timestamp_stop: '2022-01-01T17:29:45',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 138805,
    tank_volume_start: 21594.39999999987,
    tank_volume_stop: 21542.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:30:00',
    timestamp_stop: '2022-01-01T17:30:06',
    quantity: 59.5,
    vehicle: 'DEM222',
    id: 138807,
    tank_volume_start: 21542.09999999987,
    tank_volume_stop: 21482.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:30:21',
    timestamp_stop: '2022-01-01T17:30:26',
    quantity: 45.0,
    vehicle: 'DEM111',
    id: 138809,
    tank_volume_start: 21482.59999999987,
    tank_volume_stop: 21437.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:30:41',
    timestamp_stop: '2022-01-01T17:30:46',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 138811,
    tank_volume_start: 21437.59999999987,
    tank_volume_stop: 21386.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:31:01',
    timestamp_stop: '2022-01-01T17:31:07',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 138813,
    tank_volume_start: 21386.89999999987,
    tank_volume_stop: 21325.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:31:22',
    timestamp_stop: '2022-01-01T17:31:27',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 138815,
    tank_volume_start: 21325.89999999987,
    tank_volume_stop: 21270.19999999987,
  },
  {
    timestamp_start: '2022-01-01T17:31:42',
    timestamp_stop: '2022-01-01T17:31:48',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 138817,
    tank_volume_start: 21270.19999999987,
    tank_volume_stop: 21213.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:32:03',
    timestamp_stop: '2022-01-01T17:32:09',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 138819,
    tank_volume_start: 21213.09999999987,
    tank_volume_stop: 21156.19999999987,
  },
  {
    timestamp_start: '2022-01-01T17:32:24',
    timestamp_stop: '2022-01-01T17:32:29',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 138821,
    tank_volume_start: 21156.19999999987,
    tank_volume_stop: 21104.09999999987,
  },
  {
    timestamp_start: '2022-01-01T17:32:44',
    timestamp_stop: '2022-01-01T17:32:50',
    quantity: 63.8,
    vehicle: 'DEM111',
    id: 138823,
    tank_volume_start: 21104.09999999987,
    tank_volume_stop: 21040.299999999872,
  },
  {
    timestamp_start: '2022-01-01T17:33:05',
    timestamp_stop: '2022-01-01T17:33:11',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 138825,
    tank_volume_start: 21040.299999999872,
    tank_volume_stop: 20986.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:33:26',
    timestamp_stop: '2022-01-01T17:33:31',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 138827,
    tank_volume_start: 20986.89999999987,
    tank_volume_stop: 20930.19999999987,
  },
  {
    timestamp_start: '2022-01-01T17:33:46',
    timestamp_stop: '2022-01-01T17:33:52',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 138829,
    tank_volume_start: 20930.19999999987,
    tank_volume_stop: 20872.49999999987,
  },
  {
    timestamp_start: '2022-01-01T17:34:07',
    timestamp_stop: '2022-01-01T17:34:13',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 138831,
    tank_volume_start: 20872.49999999987,
    tank_volume_stop: 20813.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:34:28',
    timestamp_stop: '2022-01-01T17:34:33',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 138833,
    tank_volume_start: 20813.89999999987,
    tank_volume_stop: 20763.299999999872,
  },
  {
    timestamp_start: '2022-01-01T17:34:48',
    timestamp_stop: '2022-01-01T17:34:54',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 138835,
    tank_volume_start: 20763.299999999872,
    tank_volume_stop: 20705.699999999873,
  },
  {
    timestamp_start: '2022-01-01T17:35:09',
    timestamp_stop: '2022-01-01T17:35:14',
    quantity: 48.0,
    vehicle: 'DEM111',
    id: 138837,
    tank_volume_start: 20705.699999999873,
    tank_volume_stop: 20657.699999999873,
  },
  {
    timestamp_start: '2022-01-01T17:35:29',
    timestamp_stop: '2022-01-01T17:35:33',
    quantity: 47.5,
    vehicle: 'DEM555',
    id: 138839,
    tank_volume_start: 20657.699999999873,
    tank_volume_stop: 20610.199999999873,
  },
  {
    timestamp_start: '2022-01-01T17:35:48',
    timestamp_stop: '2022-01-01T17:35:54',
    quantity: 59.7,
    vehicle: 'DEM555',
    id: 138841,
    tank_volume_start: 20610.199999999873,
    tank_volume_stop: 20550.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:36:09',
    timestamp_stop: '2022-01-01T17:36:16',
    quantity: 60.5,
    vehicle: 'DEM000',
    id: 138843,
    tank_volume_start: 20550.499999999873,
    tank_volume_stop: 20489.999999999873,
  },
  {
    timestamp_start: '2022-01-01T17:36:31',
    timestamp_stop: '2022-01-01T17:36:36',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 138845,
    tank_volume_start: 20489.999999999873,
    tank_volume_stop: 20438.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:36:51',
    timestamp_stop: '2022-01-01T17:36:56',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 138847,
    tank_volume_start: 20438.59999999987,
    tank_volume_stop: 20383.799999999872,
  },
  {
    timestamp_start: '2022-01-01T17:37:11',
    timestamp_stop: '2022-01-01T17:37:16',
    quantity: 49.1,
    vehicle: 'DEM333',
    id: 138849,
    tank_volume_start: 20383.799999999872,
    tank_volume_stop: 20334.699999999873,
  },
  {
    timestamp_start: '2022-01-01T17:37:31',
    timestamp_stop: '2022-01-01T17:37:36',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 138851,
    tank_volume_start: 20334.699999999873,
    tank_volume_stop: 20281.499999999873,
  },
  {
    timestamp_start: '2022-01-01T17:37:51',
    timestamp_stop: '2022-01-01T17:37:56',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 138853,
    tank_volume_start: 20281.499999999873,
    tank_volume_stop: 20233.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:38:11',
    timestamp_stop: '2022-01-01T17:38:17',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 138855,
    tank_volume_start: 20233.59999999987,
    tank_volume_stop: 20177.799999999872,
  },
  {
    timestamp_start: '2022-01-01T17:38:32',
    timestamp_stop: '2022-01-01T17:38:37',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 138857,
    tank_volume_start: 20177.799999999872,
    tank_volume_stop: 20124.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:38:52',
    timestamp_stop: '2022-01-01T17:38:57',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 138859,
    tank_volume_start: 20124.59999999987,
    tank_volume_stop: 20070.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:39:12',
    timestamp_stop: '2022-01-01T17:39:18',
    quantity: 57.9,
    vehicle: 'DEM333',
    id: 138861,
    tank_volume_start: 20070.59999999987,
    tank_volume_stop: 20012.69999999987,
  },
  {
    timestamp_start: '2022-01-01T17:39:33',
    timestamp_stop: '2022-01-01T17:39:39',
    quantity: 60.8,
    vehicle: 'DEM111',
    id: 138863,
    tank_volume_start: 20012.69999999987,
    tank_volume_stop: 19951.89999999987,
  },
  {
    timestamp_start: '2022-01-01T17:39:54',
    timestamp_stop: '2022-01-01T17:40:00',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 138865,
    tank_volume_start: 39951.89999999987,
    tank_volume_stop: 39897.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:40:15',
    timestamp_stop: '2022-01-01T17:40:20',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 138867,
    tank_volume_start: 39897.39999999987,
    tank_volume_stop: 39843.59999999987,
  },
  {
    timestamp_start: '2022-01-01T17:40:35',
    timestamp_stop: '2022-01-01T17:40:41',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 138869,
    tank_volume_start: 39843.59999999987,
    tank_volume_stop: 39789.39999999987,
  },
  {
    timestamp_start: '2022-01-01T17:40:56',
    timestamp_stop: '2022-01-01T17:41:01',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 138871,
    tank_volume_start: 39789.39999999987,
    tank_volume_stop: 39733.49999999987,
  },
  {
    timestamp_start: '2022-01-01T17:41:16',
    timestamp_stop: '2022-01-01T17:41:22',
    quantity: 60.2,
    vehicle: 'DEM888',
    id: 138873,
    tank_volume_start: 39733.49999999987,
    tank_volume_stop: 39673.29999999987,
  },
  {
    timestamp_start: '2022-01-01T17:41:37',
    timestamp_stop: '2022-01-01T17:41:42',
    quantity: 49.2,
    vehicle: 'DEM000',
    id: 138875,
    tank_volume_start: 39673.29999999987,
    tank_volume_stop: 39624.099999999875,
  },
  {
    timestamp_start: '2022-01-01T17:41:57',
    timestamp_stop: '2022-01-01T17:42:02',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 138877,
    tank_volume_start: 39624.099999999875,
    tank_volume_stop: 39570.999999999876,
  },
  {
    timestamp_start: '2022-01-01T17:42:17',
    timestamp_stop: '2022-01-01T17:42:23',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 138879,
    tank_volume_start: 39570.999999999876,
    tank_volume_stop: 39515.89999999988,
  },
  {
    timestamp_start: '2022-01-01T17:42:38',
    timestamp_stop: '2022-01-01T17:42:43',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 138881,
    tank_volume_start: 39515.89999999988,
    tank_volume_stop: 39467.69999999988,
  },
  {
    timestamp_start: '2022-01-01T17:42:58',
    timestamp_stop: '2022-01-01T17:43:03',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 138883,
    tank_volume_start: 39467.69999999988,
    tank_volume_stop: 39410.79999999988,
  },
  {
    timestamp_start: '2022-01-01T17:43:18',
    timestamp_stop: '2022-01-01T17:43:24',
    quantity: 50.8,
    vehicle: 'DEM000',
    id: 138885,
    tank_volume_start: 39410.79999999988,
    tank_volume_stop: 39359.999999999876,
  },
  {
    timestamp_start: '2022-01-01T17:43:39',
    timestamp_stop: '2022-01-01T17:43:44',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 138887,
    tank_volume_start: 39359.999999999876,
    tank_volume_stop: 39305.89999999988,
  },
  {
    timestamp_start: '2022-01-01T17:43:59',
    timestamp_stop: '2022-01-01T17:44:04',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 138889,
    tank_volume_start: 39305.89999999988,
    tank_volume_stop: 39253.89999999988,
  },
  {
    timestamp_start: '2022-01-01T17:44:19',
    timestamp_stop: '2022-01-01T17:44:25',
    quantity: 61.8,
    vehicle: 'DEM555',
    id: 138891,
    tank_volume_start: 39253.89999999988,
    tank_volume_stop: 39192.099999999875,
  },
  {
    timestamp_start: '2022-01-01T17:44:40',
    timestamp_stop: '2022-01-01T17:44:46',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 138893,
    tank_volume_start: 39192.099999999875,
    tank_volume_stop: 39138.39999999988,
  },
  {
    timestamp_start: '2022-01-01T17:45:01',
    timestamp_stop: '2022-01-01T17:45:07',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 138895,
    tank_volume_start: 39138.39999999988,
    tank_volume_stop: 39077.999999999876,
  },
  {
    timestamp_start: '2022-01-01T17:45:22',
    timestamp_stop: '2022-01-01T17:45:28',
    quantity: 58.7,
    vehicle: 'DEM555',
    id: 138897,
    tank_volume_start: 39077.999999999876,
    tank_volume_stop: 39019.29999999988,
  },
  {
    timestamp_start: '2022-01-01T17:45:43',
    timestamp_stop: '2022-01-01T17:45:48',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 138899,
    tank_volume_start: 39019.29999999988,
    tank_volume_stop: 38965.69999999988,
  },
  {
    timestamp_start: '2022-01-01T17:46:03',
    timestamp_stop: '2022-01-01T17:46:08',
    quantity: 55.1,
    vehicle: 'DEM444',
    id: 138901,
    tank_volume_start: 38965.69999999988,
    tank_volume_stop: 38910.59999999988,
  },
  {
    timestamp_start: '2022-01-01T17:46:23',
    timestamp_stop: '2022-01-01T17:46:30',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 138903,
    tank_volume_start: 38910.59999999988,
    tank_volume_stop: 38847.69999999988,
  },
  {
    timestamp_start: '2022-01-01T17:46:45',
    timestamp_stop: '2022-01-01T17:46:50',
    quantity: 47.9,
    vehicle: 'DEM555',
    id: 138905,
    tank_volume_start: 38847.69999999988,
    tank_volume_stop: 38799.79999999988,
  },
  {
    timestamp_start: '2022-01-01T17:47:05',
    timestamp_stop: '2022-01-01T17:47:10',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 138907,
    tank_volume_start: 38799.79999999988,
    tank_volume_stop: 38742.999999999876,
  },
  {
    timestamp_start: '2022-01-01T17:47:25',
    timestamp_stop: '2022-01-01T17:47:31',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 138909,
    tank_volume_start: 38742.999999999876,
    tank_volume_stop: 38687.29999999988,
  },
  {
    timestamp_start: '2022-01-01T17:47:46',
    timestamp_stop: '2022-01-01T17:47:52',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 138911,
    tank_volume_start: 38687.29999999988,
    tank_volume_stop: 38629.79999999988,
  },
  {
    timestamp_start: '2022-01-01T17:48:07',
    timestamp_stop: '2022-01-01T17:48:12',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 138913,
    tank_volume_start: 38629.79999999988,
    tank_volume_stop: 38572.59999999988,
  },
  {
    timestamp_start: '2022-01-01T17:48:27',
    timestamp_stop: '2022-01-01T17:48:32',
    quantity: 52.4,
    vehicle: 'DEM333',
    id: 138915,
    tank_volume_start: 38572.59999999988,
    tank_volume_stop: 38520.19999999988,
  },
  {
    timestamp_start: '2022-01-01T17:48:47',
    timestamp_stop: '2022-01-01T17:48:54',
    quantity: 60.6,
    vehicle: 'DEM888',
    id: 138917,
    tank_volume_start: 38520.19999999988,
    tank_volume_stop: 38459.59999999988,
  },
  {
    timestamp_start: '2022-01-01T17:49:09',
    timestamp_stop: '2022-01-01T17:49:14',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 138919,
    tank_volume_start: 38459.59999999988,
    tank_volume_stop: 38405.399999999885,
  },
  {
    timestamp_start: '2022-01-01T17:49:29',
    timestamp_stop: '2022-01-01T17:49:34',
    quantity: 47.7,
    vehicle: 'DEM888',
    id: 138921,
    tank_volume_start: 38405.399999999885,
    tank_volume_stop: 38357.69999999989,
  },
  {
    timestamp_start: '2022-01-01T17:49:49',
    timestamp_stop: '2022-01-01T17:49:54',
    quantity: 49.8,
    vehicle: 'DEM666',
    id: 138923,
    tank_volume_start: 38357.69999999989,
    tank_volume_stop: 38307.899999999885,
  },
  {
    timestamp_start: '2022-01-01T17:50:09',
    timestamp_stop: '2022-01-01T17:50:13',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 138925,
    tank_volume_start: 38307.899999999885,
    tank_volume_stop: 38261.19999999989,
  },
  {
    timestamp_start: '2022-01-01T17:50:28',
    timestamp_stop: '2022-01-01T17:50:33',
    quantity: 46.9,
    vehicle: 'DEM666',
    id: 138927,
    tank_volume_start: 38261.19999999989,
    tank_volume_stop: 38214.29999999989,
  },
  {
    timestamp_start: '2022-01-01T17:50:48',
    timestamp_stop: '2022-01-01T17:50:53',
    quantity: 50.0,
    vehicle: 'DEM555',
    id: 138929,
    tank_volume_start: 38214.29999999989,
    tank_volume_stop: 38164.29999999989,
  },
  {
    timestamp_start: '2022-01-01T17:51:08',
    timestamp_stop: '2022-01-01T17:51:14',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 138931,
    tank_volume_start: 38164.29999999989,
    tank_volume_stop: 38107.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:51:29',
    timestamp_stop: '2022-01-01T17:51:34',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 138933,
    tank_volume_start: 38107.79999999989,
    tank_volume_stop: 38055.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:51:49',
    timestamp_stop: '2022-01-01T17:51:55',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 138934,
    tank_volume_start: 38055.59999999989,
    tank_volume_stop: 37998.09999999989,
  },
  {
    timestamp_start: '2022-01-01T17:52:10',
    timestamp_stop: '2022-01-01T17:52:15',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 138936,
    tank_volume_start: 37998.09999999989,
    tank_volume_stop: 37945.39999999989,
  },
  {
    timestamp_start: '2022-01-01T17:52:30',
    timestamp_stop: '2022-01-01T17:52:35',
    quantity: 47.8,
    vehicle: 'DEM888',
    id: 138938,
    tank_volume_start: 37945.39999999989,
    tank_volume_stop: 37897.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:52:50',
    timestamp_stop: '2022-01-01T17:52:56',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 138940,
    tank_volume_start: 37897.59999999989,
    tank_volume_stop: 37838.69999999989,
  },
  {
    timestamp_start: '2022-01-01T17:53:11',
    timestamp_stop: '2022-01-01T17:53:16',
    quantity: 57.9,
    vehicle: 'DEM444',
    id: 138942,
    tank_volume_start: 37838.69999999989,
    tank_volume_stop: 37780.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:53:31',
    timestamp_stop: '2022-01-01T17:53:37',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 138944,
    tank_volume_start: 37780.79999999989,
    tank_volume_stop: 37720.899999999885,
  },
  {
    timestamp_start: '2022-01-01T17:53:52',
    timestamp_stop: '2022-01-01T17:53:58',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 138946,
    tank_volume_start: 37720.899999999885,
    tank_volume_stop: 37665.49999999988,
  },
  {
    timestamp_start: '2022-01-01T17:54:13',
    timestamp_stop: '2022-01-01T17:54:19',
    quantity: 62.0,
    vehicle: 'DEM000',
    id: 138948,
    tank_volume_start: 37665.49999999988,
    tank_volume_stop: 37603.49999999988,
  },
  {
    timestamp_start: '2022-01-01T17:54:34',
    timestamp_stop: '2022-01-01T17:54:39',
    quantity: 49.7,
    vehicle: 'DEM333',
    id: 138950,
    tank_volume_start: 37603.49999999988,
    tank_volume_stop: 37553.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:54:54',
    timestamp_stop: '2022-01-01T17:55:00',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 138952,
    tank_volume_start: 37553.79999999989,
    tank_volume_stop: 37496.09999999989,
  },
  {
    timestamp_start: '2022-01-01T17:55:15',
    timestamp_stop: '2022-01-01T17:55:21',
    quantity: 62.6,
    vehicle: 'DEM777',
    id: 138954,
    tank_volume_start: 37496.09999999989,
    tank_volume_stop: 37433.49999999989,
  },
  {
    timestamp_start: '2022-01-01T17:55:36',
    timestamp_stop: '2022-01-01T17:55:42',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 138956,
    tank_volume_start: 37433.49999999989,
    tank_volume_stop: 37377.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:55:57',
    timestamp_stop: '2022-01-01T17:56:03',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 138958,
    tank_volume_start: 37377.59999999989,
    tank_volume_stop: 37318.49999999989,
  },
  {
    timestamp_start: '2022-01-01T17:56:18',
    timestamp_stop: '2022-01-01T17:56:23',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 138960,
    tank_volume_start: 37318.49999999989,
    tank_volume_stop: 37263.49999999989,
  },
  {
    timestamp_start: '2022-01-01T17:56:38',
    timestamp_stop: '2022-01-01T17:56:43',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 138962,
    tank_volume_start: 37263.49999999989,
    tank_volume_stop: 37210.99999999989,
  },
  {
    timestamp_start: '2022-01-01T17:56:58',
    timestamp_stop: '2022-01-01T17:57:04',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 138964,
    tank_volume_start: 37210.99999999989,
    tank_volume_stop: 37150.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:57:19',
    timestamp_stop: '2022-01-01T17:57:25',
    quantity: 53.5,
    vehicle: 'DEM444',
    id: 138966,
    tank_volume_start: 37150.59999999989,
    tank_volume_stop: 37097.09999999989,
  },
  {
    timestamp_start: '2022-01-01T17:57:40',
    timestamp_stop: '2022-01-01T17:57:46',
    quantity: 59.2,
    vehicle: 'DEM666',
    id: 138968,
    tank_volume_start: 37097.09999999989,
    tank_volume_stop: 37037.89999999989,
  },
  {
    timestamp_start: '2022-01-01T17:58:01',
    timestamp_stop: '2022-01-01T17:58:06',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 138970,
    tank_volume_start: 37037.89999999989,
    tank_volume_stop: 36984.59999999989,
  },
  {
    timestamp_start: '2022-01-01T17:58:21',
    timestamp_stop: '2022-01-01T17:58:27',
    quantity: 61.4,
    vehicle: 'DEM333',
    id: 138972,
    tank_volume_start: 36984.59999999989,
    tank_volume_stop: 36923.19999999989,
  },
  {
    timestamp_start: '2022-01-01T17:58:42',
    timestamp_stop: '2022-01-01T17:58:48',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 138975,
    tank_volume_start: 36923.19999999989,
    tank_volume_stop: 36866.19999999989,
  },
  {
    timestamp_start: '2022-01-01T17:59:03',
    timestamp_stop: '2022-01-01T17:59:09',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 138977,
    tank_volume_start: 36866.19999999989,
    tank_volume_stop: 36809.79999999989,
  },
  {
    timestamp_start: '2022-01-01T17:59:24',
    timestamp_stop: '2022-01-01T17:59:29',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 138979,
    tank_volume_start: 36809.79999999989,
    tank_volume_stop: 36756.49999999988,
  },
  {
    timestamp_start: '2022-01-01T17:59:44',
    timestamp_stop: '2022-01-01T17:59:50',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 138980,
    tank_volume_start: 36756.49999999988,
    tank_volume_stop: 36697.19999999988,
  },
  {
    timestamp_start: '2022-01-01T18:00:05',
    timestamp_stop: '2022-01-01T18:00:11',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 138983,
    tank_volume_start: 36697.19999999988,
    tank_volume_stop: 36639.69999999988,
  },
  {
    timestamp_start: '2022-01-01T18:00:26',
    timestamp_stop: '2022-01-01T18:00:32',
    quantity: 59.8,
    vehicle: 'DEM666',
    id: 138985,
    tank_volume_start: 36639.69999999988,
    tank_volume_stop: 36579.89999999988,
  },
  {
    timestamp_start: '2022-01-01T18:00:47',
    timestamp_stop: '2022-01-01T18:00:52',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 138987,
    tank_volume_start: 36579.89999999988,
    tank_volume_stop: 36520.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:01:07',
    timestamp_stop: '2022-01-01T18:01:13',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 138989,
    tank_volume_start: 36520.39999999988,
    tank_volume_stop: 36465.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:01:28',
    timestamp_stop: '2022-01-01T18:01:34',
    quantity: 59.0,
    vehicle: 'DEM111',
    id: 138991,
    tank_volume_start: 36465.599999999875,
    tank_volume_stop: 36406.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:01:49',
    timestamp_stop: '2022-01-01T18:01:54',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 138993,
    tank_volume_start: 36406.599999999875,
    tank_volume_stop: 36350.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:02:09',
    timestamp_stop: '2022-01-01T18:02:16',
    quantity: 61.2,
    vehicle: 'DEM000',
    id: 138995,
    tank_volume_start: 36350.599999999875,
    tank_volume_stop: 36289.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:02:31',
    timestamp_stop: '2022-01-01T18:02:37',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 138997,
    tank_volume_start: 36289.39999999988,
    tank_volume_stop: 36229.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:02:52',
    timestamp_stop: '2022-01-01T18:02:57',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 138999,
    tank_volume_start: 36229.599999999875,
    tank_volume_stop: 36174.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:03:12',
    timestamp_stop: '2022-01-01T18:03:18',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 139001,
    tank_volume_start: 36174.19999999987,
    tank_volume_stop: 36118.29999999987,
  },
  {
    timestamp_start: '2022-01-01T18:03:33',
    timestamp_stop: '2022-01-01T18:03:38',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 139003,
    tank_volume_start: 36118.29999999987,
    tank_volume_stop: 36067.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:03:53',
    timestamp_stop: '2022-01-01T18:03:58',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 139005,
    tank_volume_start: 36067.599999999875,
    tank_volume_stop: 36014.499999999876,
  },
  {
    timestamp_start: '2022-01-01T18:04:13',
    timestamp_stop: '2022-01-01T18:04:19',
    quantity: 60.3,
    vehicle: 'DEM444',
    id: 139007,
    tank_volume_start: 36014.499999999876,
    tank_volume_stop: 35954.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:04:34',
    timestamp_stop: '2022-01-01T18:04:40',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 139009,
    tank_volume_start: 35954.19999999987,
    tank_volume_stop: 35896.099999999875,
  },
  {
    timestamp_start: '2022-01-01T18:04:55',
    timestamp_stop: '2022-01-01T18:05:01',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 139011,
    tank_volume_start: 35896.099999999875,
    tank_volume_stop: 35835.999999999876,
  },
  {
    timestamp_start: '2022-01-01T18:05:16',
    timestamp_stop: '2022-01-01T18:05:22',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 139013,
    tank_volume_start: 35835.999999999876,
    tank_volume_stop: 35775.099999999875,
  },
  {
    timestamp_start: '2022-01-01T18:05:37',
    timestamp_stop: '2022-01-01T18:05:43',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 139015,
    tank_volume_start: 35775.099999999875,
    tank_volume_stop: 35719.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:05:58',
    timestamp_stop: '2022-01-01T18:06:03',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 139017,
    tank_volume_start: 35719.39999999988,
    tank_volume_stop: 35662.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:06:18',
    timestamp_stop: '2022-01-01T18:06:25',
    quantity: 64.5,
    vehicle: 'DEM777',
    id: 139019,
    tank_volume_start: 35662.39999999988,
    tank_volume_stop: 35597.89999999988,
  },
  {
    timestamp_start: '2022-01-01T18:06:40',
    timestamp_stop: '2022-01-01T18:06:45',
    quantity: 49.1,
    vehicle: 'DEM000',
    id: 139021,
    tank_volume_start: 35597.89999999988,
    tank_volume_stop: 35548.79999999988,
  },
  {
    timestamp_start: '2022-01-01T18:07:00',
    timestamp_stop: '2022-01-01T18:07:05',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 139023,
    tank_volume_start: 35548.79999999988,
    tank_volume_stop: 35490.499999999876,
  },
  {
    timestamp_start: '2022-01-01T18:07:20',
    timestamp_stop: '2022-01-01T18:07:26',
    quantity: 55.2,
    vehicle: 'DEM777',
    id: 139025,
    tank_volume_start: 35490.499999999876,
    tank_volume_stop: 35435.29999999988,
  },
  {
    timestamp_start: '2022-01-01T18:07:41',
    timestamp_stop: '2022-01-01T18:07:47',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 139027,
    tank_volume_start: 35435.29999999988,
    tank_volume_stop: 35379.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:08:02',
    timestamp_stop: '2022-01-01T18:08:07',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 139029,
    tank_volume_start: 35379.39999999988,
    tank_volume_stop: 35320.499999999876,
  },
  {
    timestamp_start: '2022-01-01T18:08:22',
    timestamp_stop: '2022-01-01T18:08:28',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 139031,
    tank_volume_start: 35320.499999999876,
    tank_volume_stop: 35265.999999999876,
  },
  {
    timestamp_start: '2022-01-01T18:08:43',
    timestamp_stop: '2022-01-01T18:08:49',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 139033,
    tank_volume_start: 35265.999999999876,
    tank_volume_stop: 35209.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:09:04',
    timestamp_stop: '2022-01-01T18:09:09',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 139035,
    tank_volume_start: 35209.19999999987,
    tank_volume_stop: 35157.29999999987,
  },
  {
    timestamp_start: '2022-01-01T18:09:24',
    timestamp_stop: '2022-01-01T18:09:29',
    quantity: 49.4,
    vehicle: 'DEM333',
    id: 139037,
    tank_volume_start: 35157.29999999987,
    tank_volume_stop: 35107.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:09:44',
    timestamp_stop: '2022-01-01T18:09:50',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 139039,
    tank_volume_start: 35107.89999999987,
    tank_volume_stop: 35049.49999999987,
  },
  {
    timestamp_start: '2022-01-01T18:10:05',
    timestamp_stop: '2022-01-01T18:10:11',
    quantity: 62.6,
    vehicle: 'DEM000',
    id: 139041,
    tank_volume_start: 35049.49999999987,
    tank_volume_stop: 34986.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:10:26',
    timestamp_stop: '2022-01-01T18:10:31',
    quantity: 49.6,
    vehicle: 'DEM000',
    id: 139043,
    tank_volume_start: 34986.89999999987,
    tank_volume_stop: 34937.29999999987,
  },
  {
    timestamp_start: '2022-01-01T18:10:46',
    timestamp_stop: '2022-01-01T18:10:51',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 139045,
    tank_volume_start: 34937.29999999987,
    tank_volume_stop: 34883.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:11:06',
    timestamp_stop: '2022-01-01T18:11:12',
    quantity: 59.8,
    vehicle: 'DEM777',
    id: 139047,
    tank_volume_start: 34883.19999999987,
    tank_volume_stop: 34823.39999999987,
  },
  {
    timestamp_start: '2022-01-01T18:11:27',
    timestamp_stop: '2022-01-01T18:11:32',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 139049,
    tank_volume_start: 34823.39999999987,
    tank_volume_stop: 34771.69999999987,
  },
  {
    timestamp_start: '2022-01-01T18:11:47',
    timestamp_stop: '2022-01-01T18:11:53',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 139051,
    tank_volume_start: 34771.69999999987,
    tank_volume_stop: 34716.999999999876,
  },
  {
    timestamp_start: '2022-01-01T18:12:08',
    timestamp_stop: '2022-01-01T18:12:14',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 139053,
    tank_volume_start: 34716.999999999876,
    tank_volume_stop: 34656.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:12:29',
    timestamp_stop: '2022-01-01T18:12:34',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 139055,
    tank_volume_start: 34656.599999999875,
    tank_volume_stop: 34602.29999999987,
  },
  {
    timestamp_start: '2022-01-01T18:12:49',
    timestamp_stop: '2022-01-01T18:12:55',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 139057,
    tank_volume_start: 34602.29999999987,
    tank_volume_stop: 34546.79999999987,
  },
  {
    timestamp_start: '2022-01-01T18:13:10',
    timestamp_stop: '2022-01-01T18:13:15',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 139059,
    tank_volume_start: 34546.79999999987,
    tank_volume_stop: 34495.69999999987,
  },
  {
    timestamp_start: '2022-01-01T18:13:30',
    timestamp_stop: '2022-01-01T18:13:35',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 139061,
    tank_volume_start: 34495.69999999987,
    tank_volume_stop: 34446.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:13:50',
    timestamp_stop: '2022-01-01T18:13:55',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 139063,
    tank_volume_start: 34446.19999999987,
    tank_volume_stop: 34391.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:14:10',
    timestamp_stop: '2022-01-01T18:14:17',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 139065,
    tank_volume_start: 34391.599999999875,
    tank_volume_stop: 34329.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:14:32',
    timestamp_stop: '2022-01-01T18:14:37',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 139067,
    tank_volume_start: 34329.39999999988,
    tank_volume_stop: 34273.19999999988,
  },
  {
    timestamp_start: '2022-01-01T18:14:52',
    timestamp_stop: '2022-01-01T18:14:58',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 139069,
    tank_volume_start: 34273.19999999988,
    tank_volume_stop: 34213.99999999988,
  },
  {
    timestamp_start: '2022-01-01T18:15:13',
    timestamp_stop: '2022-01-01T18:15:19',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 139071,
    tank_volume_start: 34213.99999999988,
    tank_volume_stop: 34156.899999999885,
  },
  {
    timestamp_start: '2022-01-01T18:15:34',
    timestamp_stop: '2022-01-01T18:15:40',
    quantity: 57.6,
    vehicle: 'DEM666',
    id: 139073,
    tank_volume_start: 34156.899999999885,
    tank_volume_stop: 34099.29999999989,
  },
  {
    timestamp_start: '2022-01-01T18:15:55',
    timestamp_stop: '2022-01-01T18:16:00',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 139075,
    tank_volume_start: 34099.29999999989,
    tank_volume_stop: 34044.79999999989,
  },
  {
    timestamp_start: '2022-01-01T18:16:15',
    timestamp_stop: '2022-01-01T18:16:21',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 139077,
    tank_volume_start: 34044.79999999989,
    tank_volume_stop: 33985.29999999989,
  },
  {
    timestamp_start: '2022-01-01T18:16:36',
    timestamp_stop: '2022-01-01T18:16:42',
    quantity: 56.7,
    vehicle: 'DEM444',
    id: 139079,
    tank_volume_start: 33985.29999999989,
    tank_volume_stop: 33928.59999999989,
  },
  {
    timestamp_start: '2022-01-01T18:16:57',
    timestamp_stop: '2022-01-01T18:17:02',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 139081,
    tank_volume_start: 33928.59999999989,
    tank_volume_stop: 33877.19999999989,
  },
  {
    timestamp_start: '2022-01-01T18:17:17',
    timestamp_stop: '2022-01-01T18:17:23',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 139083,
    tank_volume_start: 33877.19999999989,
    tank_volume_stop: 33818.59999999989,
  },
  {
    timestamp_start: '2022-01-01T18:17:38',
    timestamp_stop: '2022-01-01T18:17:43',
    quantity: 50.2,
    vehicle: 'DEM666',
    id: 139085,
    tank_volume_start: 33818.59999999989,
    tank_volume_stop: 33768.39999999989,
  },
  {
    timestamp_start: '2022-01-01T18:17:58',
    timestamp_stop: '2022-01-01T18:18:03',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 139087,
    tank_volume_start: 33768.39999999989,
    tank_volume_stop: 33715.699999999895,
  },
  {
    timestamp_start: '2022-01-01T18:18:18',
    timestamp_stop: '2022-01-01T18:18:24',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 139089,
    tank_volume_start: 33715.699999999895,
    tank_volume_stop: 33658.299999999894,
  },
  {
    timestamp_start: '2022-01-01T18:18:39',
    timestamp_stop: '2022-01-01T18:18:45',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 139091,
    tank_volume_start: 33658.299999999894,
    tank_volume_stop: 33596.99999999989,
  },
  {
    timestamp_start: '2022-01-01T18:19:00',
    timestamp_stop: '2022-01-01T18:19:06',
    quantity: 63.5,
    vehicle: 'DEM111',
    id: 139093,
    tank_volume_start: 33596.99999999989,
    tank_volume_stop: 33533.49999999989,
  },
  {
    timestamp_start: '2022-01-01T18:19:21',
    timestamp_stop: '2022-01-01T18:19:27',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 139095,
    tank_volume_start: 33533.49999999989,
    tank_volume_stop: 33478.99999999989,
  },
  {
    timestamp_start: '2022-01-01T18:19:42',
    timestamp_stop: '2022-01-01T18:19:47',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 139097,
    tank_volume_start: 33478.99999999989,
    tank_volume_stop: 33424.99999999989,
  },
  {
    timestamp_start: '2022-01-01T18:20:02',
    timestamp_stop: '2022-01-01T18:20:07',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 139099,
    tank_volume_start: 33424.99999999989,
    tank_volume_stop: 33370.49999999989,
  },
  {
    timestamp_start: '2022-01-01T18:20:22',
    timestamp_stop: '2022-01-01T18:20:28',
    quantity: 55.9,
    vehicle: 'DEM111',
    id: 139101,
    tank_volume_start: 33370.49999999989,
    tank_volume_stop: 33314.59999999989,
  },
  {
    timestamp_start: '2022-01-01T18:20:43',
    timestamp_stop: '2022-01-01T18:20:48',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 139103,
    tank_volume_start: 33314.59999999989,
    tank_volume_stop: 33260.29999999989,
  },
  {
    timestamp_start: '2022-01-01T18:21:03',
    timestamp_stop: '2022-01-01T18:21:09',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 139105,
    tank_volume_start: 33260.29999999989,
    tank_volume_stop: 33206.99999999988,
  },
  {
    timestamp_start: '2022-01-01T18:21:24',
    timestamp_stop: '2022-01-01T18:21:29',
    quantity: 47.7,
    vehicle: 'DEM000',
    id: 139107,
    tank_volume_start: 33206.99999999988,
    tank_volume_stop: 33159.29999999989,
  },
  {
    timestamp_start: '2022-01-01T18:21:44',
    timestamp_stop: '2022-01-01T18:21:49',
    quantity: 55.8,
    vehicle: 'DEM888',
    id: 139109,
    tank_volume_start: 33159.29999999989,
    tank_volume_stop: 33103.49999999988,
  },
  {
    timestamp_start: '2022-01-01T18:22:04',
    timestamp_stop: '2022-01-01T18:22:09',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 139111,
    tank_volume_start: 33103.49999999988,
    tank_volume_stop: 33050.19999999988,
  },
  {
    timestamp_start: '2022-01-01T18:22:24',
    timestamp_stop: '2022-01-01T18:22:30',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 139113,
    tank_volume_start: 33050.19999999988,
    tank_volume_stop: 32994.99999999988,
  },
  {
    timestamp_start: '2022-01-01T18:22:45',
    timestamp_stop: '2022-01-01T18:22:51',
    quantity: 63.5,
    vehicle: 'DEM222',
    id: 139115,
    tank_volume_start: 32994.99999999988,
    tank_volume_stop: 32931.49999999988,
  },
  {
    timestamp_start: '2022-01-01T18:23:06',
    timestamp_stop: '2022-01-01T18:23:12',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 139117,
    tank_volume_start: 32931.49999999988,
    tank_volume_stop: 32876.49999999988,
  },
  {
    timestamp_start: '2022-01-01T18:23:27',
    timestamp_stop: '2022-01-01T18:23:33',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 139119,
    tank_volume_start: 32876.49999999988,
    tank_volume_stop: 32817.399999999885,
  },
  {
    timestamp_start: '2022-01-01T18:23:48',
    timestamp_stop: '2022-01-01T18:23:53',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 139121,
    tank_volume_start: 32817.399999999885,
    tank_volume_stop: 32765.399999999885,
  },
  {
    timestamp_start: '2022-01-01T18:24:08',
    timestamp_stop: '2022-01-01T18:24:14',
    quantity: 61.2,
    vehicle: 'DEM888',
    id: 139123,
    tank_volume_start: 32765.399999999885,
    tank_volume_stop: 32704.199999999884,
  },
  {
    timestamp_start: '2022-01-01T18:24:29',
    timestamp_stop: '2022-01-01T18:24:34',
    quantity: 53.5,
    vehicle: 'DEM777',
    id: 139125,
    tank_volume_start: 32704.199999999884,
    tank_volume_stop: 32650.699999999884,
  },
  {
    timestamp_start: '2022-01-01T18:24:49',
    timestamp_stop: '2022-01-01T18:24:55',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 139127,
    tank_volume_start: 32650.699999999884,
    tank_volume_stop: 32596.099999999886,
  },
  {
    timestamp_start: '2022-01-01T18:25:10',
    timestamp_stop: '2022-01-01T18:25:15',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 139129,
    tank_volume_start: 32596.099999999886,
    tank_volume_stop: 32546.199999999884,
  },
  {
    timestamp_start: '2022-01-01T18:25:30',
    timestamp_stop: '2022-01-01T18:25:36',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 139131,
    tank_volume_start: 32546.199999999884,
    tank_volume_stop: 32489.199999999884,
  },
  {
    timestamp_start: '2022-01-01T18:25:51',
    timestamp_stop: '2022-01-01T18:25:55',
    quantity: 47.0,
    vehicle: 'DEM777',
    id: 139133,
    tank_volume_start: 32489.199999999884,
    tank_volume_stop: 32442.199999999884,
  },
  {
    timestamp_start: '2022-01-01T18:26:10',
    timestamp_stop: '2022-01-01T18:26:16',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 139135,
    tank_volume_start: 32442.199999999884,
    tank_volume_stop: 32384.799999999883,
  },
  {
    timestamp_start: '2022-01-01T18:26:31',
    timestamp_stop: '2022-01-01T18:26:36',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 139137,
    tank_volume_start: 32384.799999999883,
    tank_volume_stop: 32332.499999999884,
  },
  {
    timestamp_start: '2022-01-01T18:26:51',
    timestamp_stop: '2022-01-01T18:26:56',
    quantity: 52.4,
    vehicle: 'DEM333',
    id: 139139,
    tank_volume_start: 32332.499999999884,
    tank_volume_stop: 32280.099999999882,
  },
  {
    timestamp_start: '2022-01-01T18:27:11',
    timestamp_stop: '2022-01-01T18:27:17',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 139141,
    tank_volume_start: 32280.099999999882,
    tank_volume_stop: 32222.499999999884,
  },
  {
    timestamp_start: '2022-01-01T18:27:32',
    timestamp_stop: '2022-01-01T18:27:39',
    quantity: 63.7,
    vehicle: 'DEM888',
    id: 139143,
    tank_volume_start: 32222.499999999884,
    tank_volume_stop: 32158.799999999883,
  },
  {
    timestamp_start: '2022-01-01T18:27:54',
    timestamp_stop: '2022-01-01T18:28:00',
    quantity: 59.7,
    vehicle: 'DEM555',
    id: 139145,
    tank_volume_start: 32158.799999999883,
    tank_volume_stop: 32099.099999999882,
  },
  {
    timestamp_start: '2022-01-01T18:28:15',
    timestamp_stop: '2022-01-01T18:28:20',
    quantity: 54.2,
    vehicle: 'DEM222',
    id: 139147,
    tank_volume_start: 32099.099999999882,
    tank_volume_stop: 32044.89999999988,
  },
  {
    timestamp_start: '2022-01-01T18:28:35',
    timestamp_stop: '2022-01-01T18:28:40',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 139149,
    tank_volume_start: 32044.89999999988,
    tank_volume_stop: 31992.099999999882,
  },
  {
    timestamp_start: '2022-01-01T18:28:55',
    timestamp_stop: '2022-01-01T18:29:01',
    quantity: 61.2,
    vehicle: 'DEM777',
    id: 139151,
    tank_volume_start: 31992.099999999882,
    tank_volume_stop: 31930.89999999988,
  },
  {
    timestamp_start: '2022-01-01T18:29:16',
    timestamp_stop: '2022-01-01T18:29:22',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 139153,
    tank_volume_start: 31930.89999999988,
    tank_volume_stop: 31879.299999999883,
  },
  {
    timestamp_start: '2022-01-01T18:29:37',
    timestamp_stop: '2022-01-01T18:29:41',
    quantity: 47.6,
    vehicle: 'DEM111',
    id: 139155,
    tank_volume_start: 31879.299999999883,
    tank_volume_stop: 31831.699999999884,
  },
  {
    timestamp_start: '2022-01-01T18:29:56',
    timestamp_stop: '2022-01-01T18:30:02',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 139157,
    tank_volume_start: 31831.699999999884,
    tank_volume_stop: 31774.799999999883,
  },
  {
    timestamp_start: '2022-01-01T18:30:17',
    timestamp_stop: '2022-01-01T18:30:22',
    quantity: 47.4,
    vehicle: 'DEM111',
    id: 139159,
    tank_volume_start: 31774.799999999883,
    tank_volume_stop: 31727.39999999988,
  },
  {
    timestamp_start: '2022-01-01T18:30:37',
    timestamp_stop: '2022-01-01T18:30:42',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 139161,
    tank_volume_start: 31727.39999999988,
    tank_volume_stop: 31672.99999999988,
  },
  {
    timestamp_start: '2022-01-01T18:30:57',
    timestamp_stop: '2022-01-01T18:31:03',
    quantity: 59.3,
    vehicle: 'DEM888',
    id: 139163,
    tank_volume_start: 31672.99999999988,
    tank_volume_stop: 31613.69999999988,
  },
  {
    timestamp_start: '2022-01-01T18:31:18',
    timestamp_stop: '2022-01-01T18:31:23',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 139165,
    tank_volume_start: 31613.69999999988,
    tank_volume_stop: 31561.599999999882,
  },
  {
    timestamp_start: '2022-01-01T18:31:38',
    timestamp_stop: '2022-01-01T18:31:43',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 139167,
    tank_volume_start: 31561.599999999882,
    tank_volume_stop: 31511.599999999882,
  },
  {
    timestamp_start: '2022-01-01T18:31:58',
    timestamp_stop: '2022-01-01T18:32:03',
    quantity: 47.0,
    vehicle: 'DEM555',
    id: 139169,
    tank_volume_start: 31511.599999999882,
    tank_volume_stop: 31464.599999999882,
  },
  {
    timestamp_start: '2022-01-01T18:32:18',
    timestamp_stop: '2022-01-01T18:32:23',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 139171,
    tank_volume_start: 31464.599999999882,
    tank_volume_stop: 31410.19999999988,
  },
  {
    timestamp_start: '2022-01-01T18:32:38',
    timestamp_stop: '2022-01-01T18:32:44',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 139173,
    tank_volume_start: 31410.19999999988,
    tank_volume_stop: 31355.49999999988,
  },
  {
    timestamp_start: '2022-01-01T18:32:59',
    timestamp_stop: '2022-01-01T18:33:04',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 139175,
    tank_volume_start: 31355.49999999988,
    tank_volume_stop: 31300.49999999988,
  },
  {
    timestamp_start: '2022-01-01T18:33:19',
    timestamp_stop: '2022-01-01T18:33:25',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 139177,
    tank_volume_start: 31300.49999999988,
    tank_volume_stop: 31244.29999999988,
  },
  {
    timestamp_start: '2022-01-01T18:33:40',
    timestamp_stop: '2022-01-01T18:33:46',
    quantity: 59.5,
    vehicle: 'DEM555',
    id: 139179,
    tank_volume_start: 31244.29999999988,
    tank_volume_stop: 31184.79999999988,
  },
  {
    timestamp_start: '2022-01-01T18:34:01',
    timestamp_stop: '2022-01-01T18:34:06',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 139181,
    tank_volume_start: 31184.79999999988,
    tank_volume_stop: 31131.899999999878,
  },
  {
    timestamp_start: '2022-01-01T18:34:21',
    timestamp_stop: '2022-01-01T18:34:26',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 139183,
    tank_volume_start: 31131.899999999878,
    tank_volume_stop: 31080.09999999988,
  },
  {
    timestamp_start: '2022-01-01T18:34:41',
    timestamp_stop: '2022-01-01T18:34:47',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 139185,
    tank_volume_start: 31080.09999999988,
    tank_volume_stop: 31026.899999999878,
  },
  {
    timestamp_start: '2022-01-01T18:35:02',
    timestamp_stop: '2022-01-01T18:35:07',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 139187,
    tank_volume_start: 31026.899999999878,
    tank_volume_stop: 30972.399999999878,
  },
  {
    timestamp_start: '2022-01-01T18:35:22',
    timestamp_stop: '2022-01-01T18:35:28',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 139189,
    tank_volume_start: 30972.399999999878,
    tank_volume_stop: 30914.499999999876,
  },
  {
    timestamp_start: '2022-01-01T18:35:43',
    timestamp_stop: '2022-01-01T18:35:49',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 139191,
    tank_volume_start: 30914.499999999876,
    tank_volume_stop: 30854.099999999875,
  },
  {
    timestamp_start: '2022-01-01T18:36:04',
    timestamp_stop: '2022-01-01T18:36:09',
    quantity: 49.4,
    vehicle: 'DEM777',
    id: 139193,
    tank_volume_start: 30854.099999999875,
    tank_volume_stop: 30804.699999999873,
  },
  {
    timestamp_start: '2022-01-01T18:36:24',
    timestamp_stop: '2022-01-01T18:36:30',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 139195,
    tank_volume_start: 30804.699999999873,
    tank_volume_stop: 30749.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:36:45',
    timestamp_stop: '2022-01-01T18:36:50',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 139197,
    tank_volume_start: 30749.499999999873,
    tank_volume_stop: 30692.699999999873,
  },
  {
    timestamp_start: '2022-01-01T18:37:05',
    timestamp_stop: '2022-01-01T18:37:10',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 139199,
    tank_volume_start: 30692.699999999873,
    tank_volume_stop: 30642.199999999873,
  },
  {
    timestamp_start: '2022-01-01T18:37:25',
    timestamp_stop: '2022-01-01T18:37:31',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 139201,
    tank_volume_start: 30642.199999999873,
    tank_volume_stop: 30588.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:37:46',
    timestamp_stop: '2022-01-01T18:37:51',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 139203,
    tank_volume_start: 30588.499999999873,
    tank_volume_stop: 30532.09999999987,
  },
  {
    timestamp_start: '2022-01-01T18:38:06',
    timestamp_stop: '2022-01-01T18:38:12',
    quantity: 61.9,
    vehicle: 'DEM555',
    id: 139205,
    tank_volume_start: 30532.09999999987,
    tank_volume_stop: 30470.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:38:27',
    timestamp_stop: '2022-01-01T18:38:33',
    quantity: 58.0,
    vehicle: 'DEM111',
    id: 139207,
    tank_volume_start: 30470.19999999987,
    tank_volume_stop: 30412.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:38:48',
    timestamp_stop: '2022-01-01T18:38:54',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 139209,
    tank_volume_start: 30412.19999999987,
    tank_volume_stop: 30357.39999999987,
  },
  {
    timestamp_start: '2022-01-01T18:39:09',
    timestamp_stop: '2022-01-01T18:39:14',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 139211,
    tank_volume_start: 30357.39999999987,
    tank_volume_stop: 30301.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:39:29',
    timestamp_stop: '2022-01-01T18:39:34',
    quantity: 48.1,
    vehicle: 'DEM222',
    id: 139213,
    tank_volume_start: 30301.89999999987,
    tank_volume_stop: 30253.799999999872,
  },
  {
    timestamp_start: '2022-01-01T18:39:49',
    timestamp_stop: '2022-01-01T18:39:54',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 139215,
    tank_volume_start: 30253.799999999872,
    tank_volume_stop: 30201.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:40:09',
    timestamp_stop: '2022-01-01T18:40:15',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 139217,
    tank_volume_start: 30201.89999999987,
    tank_volume_stop: 30146.09999999987,
  },
  {
    timestamp_start: '2022-01-01T18:40:30',
    timestamp_stop: '2022-01-01T18:40:36',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 139219,
    tank_volume_start: 30146.09999999987,
    tank_volume_stop: 30088.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:40:51',
    timestamp_stop: '2022-01-01T18:40:55',
    quantity: 46.6,
    vehicle: 'DEM000',
    id: 139221,
    tank_volume_start: 30088.89999999987,
    tank_volume_stop: 30042.299999999872,
  },
  {
    timestamp_start: '2022-01-01T18:41:10',
    timestamp_stop: '2022-01-01T18:41:15',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 139223,
    tank_volume_start: 30042.299999999872,
    tank_volume_stop: 29991.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:41:30',
    timestamp_stop: '2022-01-01T18:41:37',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 139225,
    tank_volume_start: 29991.499999999873,
    tank_volume_stop: 29927.899999999874,
  },
  {
    timestamp_start: '2022-01-01T18:41:52',
    timestamp_stop: '2022-01-01T18:41:57',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 139227,
    tank_volume_start: 29927.899999999874,
    tank_volume_stop: 29875.399999999874,
  },
  {
    timestamp_start: '2022-01-01T18:42:12',
    timestamp_stop: '2022-01-01T18:42:18',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 139229,
    tank_volume_start: 29875.399999999874,
    tank_volume_stop: 29817.899999999874,
  },
  {
    timestamp_start: '2022-01-01T18:42:33',
    timestamp_stop: '2022-01-01T18:42:38',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 139231,
    tank_volume_start: 29817.899999999874,
    tank_volume_stop: 29763.999999999873,
  },
  {
    timestamp_start: '2022-01-01T18:42:53',
    timestamp_stop: '2022-01-01T18:42:58',
    quantity: 48.8,
    vehicle: 'DEM000',
    id: 139233,
    tank_volume_start: 29763.999999999873,
    tank_volume_stop: 29715.199999999873,
  },
  {
    timestamp_start: '2022-01-01T18:43:13',
    timestamp_stop: '2022-01-01T18:43:18',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 139235,
    tank_volume_start: 29715.199999999873,
    tank_volume_stop: 29663.299999999872,
  },
  {
    timestamp_start: '2022-01-01T18:43:33',
    timestamp_stop: '2022-01-01T18:43:39',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 139237,
    tank_volume_start: 29663.299999999872,
    tank_volume_stop: 29604.199999999873,
  },
  {
    timestamp_start: '2022-01-01T18:43:54',
    timestamp_stop: '2022-01-01T18:44:00',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 139239,
    tank_volume_start: 29604.199999999873,
    tank_volume_stop: 29549.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:44:15',
    timestamp_stop: '2022-01-01T18:44:21',
    quantity: 62.3,
    vehicle: 'DEM444',
    id: 139241,
    tank_volume_start: 29549.499999999873,
    tank_volume_stop: 29487.199999999873,
  },
  {
    timestamp_start: '2022-01-01T18:44:36',
    timestamp_stop: '2022-01-01T18:44:41',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 139243,
    tank_volume_start: 29487.199999999873,
    tank_volume_stop: 29435.399999999874,
  },
  {
    timestamp_start: '2022-01-01T18:44:56',
    timestamp_stop: '2022-01-01T18:45:01',
    quantity: 45.4,
    vehicle: 'DEM555',
    id: 139245,
    tank_volume_start: 29435.399999999874,
    tank_volume_stop: 29389.999999999873,
  },
  {
    timestamp_start: '2022-01-01T18:45:16',
    timestamp_stop: '2022-01-01T18:45:21',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 139247,
    tank_volume_start: 29389.999999999873,
    tank_volume_stop: 29334.299999999872,
  },
  {
    timestamp_start: '2022-01-01T18:45:36',
    timestamp_stop: '2022-01-01T18:45:41',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 139249,
    tank_volume_start: 29334.299999999872,
    tank_volume_stop: 29281.799999999872,
  },
  {
    timestamp_start: '2022-01-01T18:45:56',
    timestamp_stop: '2022-01-01T18:46:02',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 139251,
    tank_volume_start: 29281.799999999872,
    tank_volume_stop: 29226.699999999873,
  },
  {
    timestamp_start: '2022-01-01T18:46:17',
    timestamp_stop: '2022-01-01T18:46:22',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 139253,
    tank_volume_start: 29226.699999999873,
    tank_volume_stop: 29173.399999999874,
  },
  {
    timestamp_start: '2022-01-01T18:46:37',
    timestamp_stop: '2022-01-01T18:46:43',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 139255,
    tank_volume_start: 29173.399999999874,
    tank_volume_stop: 29118.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:46:58',
    timestamp_stop: '2022-01-01T18:47:03',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 139257,
    tank_volume_start: 29118.499999999873,
    tank_volume_stop: 29065.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:47:18',
    timestamp_stop: '2022-01-01T18:47:24',
    quantity: 55.6,
    vehicle: 'DEM888',
    id: 139259,
    tank_volume_start: 29065.499999999873,
    tank_volume_stop: 29009.899999999874,
  },
  {
    timestamp_start: '2022-01-01T18:47:39',
    timestamp_stop: '2022-01-01T18:47:44',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 139261,
    tank_volume_start: 29009.899999999874,
    tank_volume_stop: 28953.799999999876,
  },
  {
    timestamp_start: '2022-01-01T18:47:59',
    timestamp_stop: '2022-01-01T18:48:05',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 139263,
    tank_volume_start: 28953.799999999876,
    tank_volume_stop: 28899.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:48:20',
    timestamp_stop: '2022-01-01T18:48:25',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 139265,
    tank_volume_start: 28899.599999999875,
    tank_volume_stop: 28847.999999999876,
  },
  {
    timestamp_start: '2022-01-01T18:48:40',
    timestamp_stop: '2022-01-01T18:48:45',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 139267,
    tank_volume_start: 28847.999999999876,
    tank_volume_stop: 28792.999999999876,
  },
  {
    timestamp_start: '2022-01-01T18:49:00',
    timestamp_stop: '2022-01-01T18:49:05',
    quantity: 47.3,
    vehicle: 'DEM666',
    id: 139269,
    tank_volume_start: 28792.999999999876,
    tank_volume_stop: 28745.699999999877,
  },
  {
    timestamp_start: '2022-01-01T18:49:20',
    timestamp_stop: '2022-01-01T18:49:26',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 139271,
    tank_volume_start: 28745.699999999877,
    tank_volume_stop: 28686.299999999876,
  },
  {
    timestamp_start: '2022-01-01T18:49:41',
    timestamp_stop: '2022-01-01T18:49:47',
    quantity: 57.0,
    vehicle: 'DEM666',
    id: 139273,
    tank_volume_start: 28686.299999999876,
    tank_volume_stop: 28629.299999999876,
  },
  {
    timestamp_start: '2022-01-01T18:50:02',
    timestamp_stop: '2022-01-01T18:50:07',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 139275,
    tank_volume_start: 28629.299999999876,
    tank_volume_stop: 28577.799999999876,
  },
  {
    timestamp_start: '2022-01-01T18:50:22',
    timestamp_stop: '2022-01-01T18:50:28',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 139277,
    tank_volume_start: 28577.799999999876,
    tank_volume_stop: 28517.399999999874,
  },
  {
    timestamp_start: '2022-01-01T18:50:43',
    timestamp_stop: '2022-01-01T18:50:48',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 139279,
    tank_volume_start: 28517.399999999874,
    tank_volume_stop: 28464.899999999874,
  },
  {
    timestamp_start: '2022-01-01T18:51:03',
    timestamp_stop: '2022-01-01T18:51:08',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 139281,
    tank_volume_start: 28464.899999999874,
    tank_volume_stop: 28413.099999999875,
  },
  {
    timestamp_start: '2022-01-01T18:51:23',
    timestamp_stop: '2022-01-01T18:51:28',
    quantity: 47.4,
    vehicle: 'DEM666',
    id: 139283,
    tank_volume_start: 28413.099999999875,
    tank_volume_stop: 28365.699999999873,
  },
  {
    timestamp_start: '2022-01-01T18:51:43',
    timestamp_stop: '2022-01-01T18:51:48',
    quantity: 50.3,
    vehicle: 'DEM555',
    id: 139285,
    tank_volume_start: 28365.699999999873,
    tank_volume_stop: 28315.399999999874,
  },
  {
    timestamp_start: '2022-01-01T18:52:03',
    timestamp_stop: '2022-01-01T18:52:08',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 139287,
    tank_volume_start: 28315.399999999874,
    tank_volume_stop: 28260.599999999875,
  },
  {
    timestamp_start: '2022-01-01T18:52:23',
    timestamp_stop: '2022-01-01T18:52:28',
    quantity: 48.7,
    vehicle: 'DEM888',
    id: 139289,
    tank_volume_start: 28260.599999999875,
    tank_volume_stop: 28211.899999999874,
  },
  {
    timestamp_start: '2022-01-01T18:52:43',
    timestamp_stop: '2022-01-01T18:52:49',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 139291,
    tank_volume_start: 28211.899999999874,
    tank_volume_stop: 28151.499999999873,
  },
  {
    timestamp_start: '2022-01-01T18:53:04',
    timestamp_stop: '2022-01-01T18:53:10',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 139293,
    tank_volume_start: 28151.499999999873,
    tank_volume_stop: 28099.59999999987,
  },
  {
    timestamp_start: '2022-01-01T18:53:25',
    timestamp_stop: '2022-01-01T18:53:29',
    quantity: 49.4,
    vehicle: 'DEM333',
    id: 139295,
    tank_volume_start: 28099.59999999987,
    tank_volume_stop: 28050.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:53:44',
    timestamp_stop: '2022-01-01T18:53:50',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 139297,
    tank_volume_start: 28050.19999999987,
    tank_volume_stop: 27992.49999999987,
  },
  {
    timestamp_start: '2022-01-01T18:54:05',
    timestamp_stop: '2022-01-01T18:54:12',
    quantity: 62.8,
    vehicle: 'DEM333',
    id: 139299,
    tank_volume_start: 27992.49999999987,
    tank_volume_stop: 27929.69999999987,
  },
  {
    timestamp_start: '2022-01-01T18:54:27',
    timestamp_stop: '2022-01-01T18:54:32',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 139301,
    tank_volume_start: 27929.69999999987,
    tank_volume_stop: 27871.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:54:47',
    timestamp_stop: '2022-01-01T18:54:52',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 139303,
    tank_volume_start: 27871.19999999987,
    tank_volume_stop: 27822.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:55:07',
    timestamp_stop: '2022-01-01T18:55:13',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 139305,
    tank_volume_start: 27822.89999999987,
    tank_volume_stop: 27762.39999999987,
  },
  {
    timestamp_start: '2022-01-01T18:55:28',
    timestamp_stop: '2022-01-01T18:55:34',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 139307,
    tank_volume_start: 27762.39999999987,
    tank_volume_stop: 27709.09999999987,
  },
  {
    timestamp_start: '2022-01-01T18:55:49',
    timestamp_stop: '2022-01-01T18:55:54',
    quantity: 52.2,
    vehicle: 'DEM777',
    id: 139309,
    tank_volume_start: 27709.09999999987,
    tank_volume_stop: 27656.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:56:09',
    timestamp_stop: '2022-01-01T18:56:14',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 139311,
    tank_volume_start: 27656.89999999987,
    tank_volume_stop: 27600.09999999987,
  },
  {
    timestamp_start: '2022-01-01T18:56:29',
    timestamp_stop: '2022-01-01T18:56:35',
    quantity: 59.1,
    vehicle: 'DEM888',
    id: 139313,
    tank_volume_start: 27600.09999999987,
    tank_volume_stop: 27540.999999999873,
  },
  {
    timestamp_start: '2022-01-01T18:56:50',
    timestamp_stop: '2022-01-01T18:56:56',
    quantity: 60.2,
    vehicle: 'DEM666',
    id: 139315,
    tank_volume_start: 27540.999999999873,
    tank_volume_stop: 27480.799999999872,
  },
  {
    timestamp_start: '2022-01-01T18:57:11',
    timestamp_stop: '2022-01-01T18:57:17',
    quantity: 54.9,
    vehicle: 'DEM555',
    id: 139317,
    tank_volume_start: 27480.799999999872,
    tank_volume_stop: 27425.89999999987,
  },
  {
    timestamp_start: '2022-01-01T18:57:32',
    timestamp_stop: '2022-01-01T18:57:37',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 139319,
    tank_volume_start: 27425.89999999987,
    tank_volume_stop: 27373.19999999987,
  },
  {
    timestamp_start: '2022-01-01T18:57:52',
    timestamp_stop: '2022-01-01T18:57:58',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 139321,
    tank_volume_start: 27373.19999999987,
    tank_volume_stop: 27317.49999999987,
  },
  {
    timestamp_start: '2022-01-01T18:58:13',
    timestamp_stop: '2022-01-01T18:58:19',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 139323,
    tank_volume_start: 27317.49999999987,
    tank_volume_stop: 27259.79999999987,
  },
  {
    timestamp_start: '2022-01-01T18:58:34',
    timestamp_stop: '2022-01-01T18:58:39',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 139325,
    tank_volume_start: 27259.79999999987,
    tank_volume_stop: 27202.899999999867,
  },
  {
    timestamp_start: '2022-01-01T18:58:54',
    timestamp_stop: '2022-01-01T18:58:59',
    quantity: 49.6,
    vehicle: 'DEM444',
    id: 139327,
    tank_volume_start: 27202.899999999867,
    tank_volume_stop: 27153.29999999987,
  },
  {
    timestamp_start: '2022-01-01T18:59:14',
    timestamp_stop: '2022-01-01T18:59:20',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 139329,
    tank_volume_start: 27153.29999999987,
    tank_volume_stop: 27091.99999999987,
  },
  {
    timestamp_start: '2022-01-01T18:59:35',
    timestamp_stop: '2022-01-01T18:59:41',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 139331,
    tank_volume_start: 27091.99999999987,
    tank_volume_stop: 27036.49999999987,
  },
  {
    timestamp_start: '2022-01-01T18:59:56',
    timestamp_stop: '2022-01-01T19:00:02',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 139333,
    tank_volume_start: 27036.49999999987,
    tank_volume_stop: 26978.79999999987,
  },
  {
    timestamp_start: '2022-01-01T19:00:17',
    timestamp_stop: '2022-01-01T19:00:22',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 139335,
    tank_volume_start: 26978.79999999987,
    tank_volume_stop: 26926.99999999987,
  },
  {
    timestamp_start: '2022-01-01T19:00:37',
    timestamp_stop: '2022-01-01T19:00:43',
    quantity: 59.7,
    vehicle: 'DEM666',
    id: 139337,
    tank_volume_start: 26926.99999999987,
    tank_volume_stop: 26867.29999999987,
  },
  {
    timestamp_start: '2022-01-01T19:00:58',
    timestamp_stop: '2022-01-01T19:01:04',
    quantity: 58.9,
    vehicle: 'DEM888',
    id: 139339,
    tank_volume_start: 26867.29999999987,
    tank_volume_stop: 26808.399999999867,
  },
  {
    timestamp_start: '2022-01-01T19:01:19',
    timestamp_stop: '2022-01-01T19:01:23',
    quantity: 47.3,
    vehicle: 'DEM111',
    id: 139341,
    tank_volume_start: 26808.399999999867,
    tank_volume_stop: 26761.099999999868,
  },
  {
    timestamp_start: '2022-01-01T19:01:38',
    timestamp_stop: '2022-01-01T19:01:44',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 139343,
    tank_volume_start: 26761.099999999868,
    tank_volume_stop: 26702.399999999867,
  },
  {
    timestamp_start: '2022-01-01T19:01:59',
    timestamp_stop: '2022-01-01T19:02:05',
    quantity: 61.6,
    vehicle: 'DEM111',
    id: 139345,
    tank_volume_start: 26702.399999999867,
    tank_volume_stop: 26640.79999999987,
  },
  {
    timestamp_start: '2022-01-01T19:02:20',
    timestamp_stop: '2022-01-01T19:02:26',
    quantity: 55.4,
    vehicle: 'DEM444',
    id: 139347,
    tank_volume_start: 26640.79999999987,
    tank_volume_stop: 26585.399999999867,
  },
  {
    timestamp_start: '2022-01-01T19:02:41',
    timestamp_stop: '2022-01-01T19:02:46',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 139349,
    tank_volume_start: 26585.399999999867,
    tank_volume_stop: 26530.899999999867,
  },
  {
    timestamp_start: '2022-01-01T19:03:01',
    timestamp_stop: '2022-01-01T19:03:07',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 139351,
    tank_volume_start: 26530.899999999867,
    tank_volume_stop: 26471.999999999865,
  },
  {
    timestamp_start: '2022-01-01T19:03:22',
    timestamp_stop: '2022-01-01T19:03:27',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 139353,
    tank_volume_start: 26471.999999999865,
    tank_volume_stop: 26423.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:03:42',
    timestamp_stop: '2022-01-01T19:03:48',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 139355,
    tank_volume_start: 26423.599999999864,
    tank_volume_stop: 26366.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:04:03',
    timestamp_stop: '2022-01-01T19:04:08',
    quantity: 55.6,
    vehicle: 'DEM555',
    id: 139357,
    tank_volume_start: 26366.399999999863,
    tank_volume_stop: 26310.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:04:23',
    timestamp_stop: '2022-01-01T19:04:29',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 139359,
    tank_volume_start: 26310.799999999865,
    tank_volume_stop: 26256.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:04:44',
    timestamp_stop: '2022-01-01T19:04:49',
    quantity: 49.5,
    vehicle: 'DEM000',
    id: 139361,
    tank_volume_start: 26256.799999999865,
    tank_volume_stop: 26207.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:05:04',
    timestamp_stop: '2022-01-01T19:05:08',
    quantity: 46.9,
    vehicle: 'DEM222',
    id: 139363,
    tank_volume_start: 26207.299999999865,
    tank_volume_stop: 26160.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:05:23',
    timestamp_stop: '2022-01-01T19:05:29',
    quantity: 56.8,
    vehicle: 'DEM000',
    id: 139365,
    tank_volume_start: 26160.399999999863,
    tank_volume_stop: 26103.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:05:44',
    timestamp_stop: '2022-01-01T19:05:50',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 139367,
    tank_volume_start: 26103.599999999864,
    tank_volume_stop: 26046.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:06:05',
    timestamp_stop: '2022-01-01T19:06:10',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 139369,
    tank_volume_start: 26046.799999999865,
    tank_volume_stop: 25992.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:06:25',
    timestamp_stop: '2022-01-01T19:06:30',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 139371,
    tank_volume_start: 25992.599999999864,
    tank_volume_stop: 25942.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:06:45',
    timestamp_stop: '2022-01-01T19:06:51',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 139373,
    tank_volume_start: 25942.599999999864,
    tank_volume_stop: 25887.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:07:06',
    timestamp_stop: '2022-01-01T19:07:11',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 139375,
    tank_volume_start: 25887.699999999862,
    tank_volume_stop: 25837.199999999862,
  },
  {
    timestamp_start: '2022-01-01T19:07:26',
    timestamp_stop: '2022-01-01T19:07:31',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 139377,
    tank_volume_start: 25837.199999999862,
    tank_volume_stop: 25782.99999999986,
  },
  {
    timestamp_start: '2022-01-01T19:07:46',
    timestamp_stop: '2022-01-01T19:07:52',
    quantity: 54.7,
    vehicle: 'DEM555',
    id: 139379,
    tank_volume_start: 25782.99999999986,
    tank_volume_stop: 25728.29999999986,
  },
  {
    timestamp_start: '2022-01-01T19:08:07',
    timestamp_stop: '2022-01-01T19:08:12',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 139381,
    tank_volume_start: 25728.29999999986,
    tank_volume_stop: 25674.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:08:27',
    timestamp_stop: '2022-01-01T19:08:32',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 139383,
    tank_volume_start: 25674.699999999862,
    tank_volume_stop: 25620.49999999986,
  },
  {
    timestamp_start: '2022-01-01T19:08:47',
    timestamp_stop: '2022-01-01T19:08:53',
    quantity: 51.8,
    vehicle: 'DEM555',
    id: 139385,
    tank_volume_start: 25620.49999999986,
    tank_volume_stop: 25568.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:09:08',
    timestamp_stop: '2022-01-01T19:09:13',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 139387,
    tank_volume_start: 25568.699999999862,
    tank_volume_stop: 25511.199999999862,
  },
  {
    timestamp_start: '2022-01-01T19:09:28',
    timestamp_stop: '2022-01-01T19:09:34',
    quantity: 61.1,
    vehicle: 'DEM666',
    id: 139389,
    tank_volume_start: 25511.199999999862,
    tank_volume_stop: 25450.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:09:49',
    timestamp_stop: '2022-01-01T19:09:55',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 139391,
    tank_volume_start: 25450.099999999864,
    tank_volume_stop: 25396.199999999862,
  },
  {
    timestamp_start: '2022-01-01T19:10:10',
    timestamp_stop: '2022-01-01T19:10:15',
    quantity: 48.4,
    vehicle: 'DEM000',
    id: 139393,
    tank_volume_start: 25396.199999999862,
    tank_volume_stop: 25347.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:10:30',
    timestamp_stop: '2022-01-01T19:10:35',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 139395,
    tank_volume_start: 25347.79999999986,
    tank_volume_stop: 25290.49999999986,
  },
  {
    timestamp_start: '2022-01-01T19:10:50',
    timestamp_stop: '2022-01-01T19:10:55',
    quantity: 49.1,
    vehicle: 'DEM777',
    id: 139397,
    tank_volume_start: 25290.49999999986,
    tank_volume_stop: 25241.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:11:10',
    timestamp_stop: '2022-01-01T19:11:16',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 139399,
    tank_volume_start: 25241.399999999863,
    tank_volume_stop: 25182.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:11:31',
    timestamp_stop: '2022-01-01T19:11:36',
    quantity: 47.8,
    vehicle: 'DEM111',
    id: 139401,
    tank_volume_start: 25182.599999999864,
    tank_volume_stop: 25134.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:11:51',
    timestamp_stop: '2022-01-01T19:11:56',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 139403,
    tank_volume_start: 25134.799999999865,
    tank_volume_stop: 25081.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:12:11',
    timestamp_stop: '2022-01-01T19:12:17',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 139405,
    tank_volume_start: 25081.099999999864,
    tank_volume_stop: 25024.999999999865,
  },
  {
    timestamp_start: '2022-01-01T19:12:32',
    timestamp_stop: '2022-01-01T19:12:37',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 139407,
    tank_volume_start: 25024.999999999865,
    tank_volume_stop: 24972.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:12:52',
    timestamp_stop: '2022-01-01T19:12:58',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 139409,
    tank_volume_start: 24972.099999999864,
    tank_volume_stop: 24916.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:13:13',
    timestamp_stop: '2022-01-01T19:13:18',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 139411,
    tank_volume_start: 24916.599999999864,
    tank_volume_stop: 24863.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:13:33',
    timestamp_stop: '2022-01-01T19:13:39',
    quantity: 58.6,
    vehicle: 'DEM777',
    id: 139413,
    tank_volume_start: 24863.299999999865,
    tank_volume_stop: 24804.699999999866,
  },
  {
    timestamp_start: '2022-01-01T19:13:54',
    timestamp_stop: '2022-01-01T19:13:59',
    quantity: 51.4,
    vehicle: 'DEM888',
    id: 139415,
    tank_volume_start: 24804.699999999866,
    tank_volume_stop: 24753.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:14:14',
    timestamp_stop: '2022-01-01T19:14:20',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 139417,
    tank_volume_start: 24753.299999999865,
    tank_volume_stop: 24699.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:14:35',
    timestamp_stop: '2022-01-01T19:14:40',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 139419,
    tank_volume_start: 24699.499999999865,
    tank_volume_stop: 24641.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:14:55',
    timestamp_stop: '2022-01-01T19:15:01',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 139421,
    tank_volume_start: 24641.499999999865,
    tank_volume_stop: 24588.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:15:16',
    timestamp_stop: '2022-01-01T19:15:20',
    quantity: 46.8,
    vehicle: 'DEM444',
    id: 139423,
    tank_volume_start: 24588.599999999864,
    tank_volume_stop: 24541.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:15:35',
    timestamp_stop: '2022-01-01T19:15:41',
    quantity: 52.1,
    vehicle: 'DEM444',
    id: 139425,
    tank_volume_start: 24541.799999999865,
    tank_volume_stop: 24489.699999999866,
  },
  {
    timestamp_start: '2022-01-01T19:15:56',
    timestamp_stop: '2022-01-01T19:16:01',
    quantity: 52.2,
    vehicle: 'DEM777',
    id: 139427,
    tank_volume_start: 24489.699999999866,
    tank_volume_stop: 24437.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:16:16',
    timestamp_stop: '2022-01-01T19:16:21',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 139428,
    tank_volume_start: 24437.499999999865,
    tank_volume_stop: 24382.399999999867,
  },
  {
    timestamp_start: '2022-01-01T19:16:36',
    timestamp_stop: '2022-01-01T19:16:42',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 139431,
    tank_volume_start: 24382.399999999867,
    tank_volume_stop: 24328.899999999867,
  },
  {
    timestamp_start: '2022-01-01T19:16:57',
    timestamp_stop: '2022-01-01T19:17:03',
    quantity: 62.9,
    vehicle: 'DEM222',
    id: 139433,
    tank_volume_start: 24328.899999999867,
    tank_volume_stop: 24265.999999999865,
  },
  {
    timestamp_start: '2022-01-01T19:17:18',
    timestamp_stop: '2022-01-01T19:17:23',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 139435,
    tank_volume_start: 24265.999999999865,
    tank_volume_stop: 24213.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:17:38',
    timestamp_stop: '2022-01-01T19:17:43',
    quantity: 46.4,
    vehicle: 'DEM222',
    id: 139437,
    tank_volume_start: 24213.499999999865,
    tank_volume_stop: 24167.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:17:58',
    timestamp_stop: '2022-01-01T19:18:03',
    quantity: 52.6,
    vehicle: 'DEM333',
    id: 139439,
    tank_volume_start: 24167.099999999864,
    tank_volume_stop: 24114.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:18:18',
    timestamp_stop: '2022-01-01T19:18:23',
    quantity: 51.0,
    vehicle: 'DEM111',
    id: 139441,
    tank_volume_start: 24114.499999999865,
    tank_volume_stop: 24063.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:18:38',
    timestamp_stop: '2022-01-01T19:18:43',
    quantity: 45.3,
    vehicle: 'DEM000',
    id: 139443,
    tank_volume_start: 24063.499999999865,
    tank_volume_stop: 24018.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:18:58',
    timestamp_stop: '2022-01-01T19:19:03',
    quantity: 50.4,
    vehicle: 'DEM555',
    id: 139445,
    tank_volume_start: 24018.199999999866,
    tank_volume_stop: 23967.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:19:18',
    timestamp_stop: '2022-01-01T19:19:24',
    quantity: 63.2,
    vehicle: 'DEM222',
    id: 139447,
    tank_volume_start: 23967.799999999865,
    tank_volume_stop: 23904.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:19:39',
    timestamp_stop: '2022-01-01T19:19:45',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 139449,
    tank_volume_start: 23904.599999999864,
    tank_volume_stop: 23845.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:20:00',
    timestamp_stop: '2022-01-01T19:20:06',
    quantity: 63.4,
    vehicle: 'DEM666',
    id: 139451,
    tank_volume_start: 23845.499999999865,
    tank_volume_stop: 23782.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:20:21',
    timestamp_stop: '2022-01-01T19:20:27',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 139453,
    tank_volume_start: 23782.099999999864,
    tank_volume_stop: 23726.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:20:42',
    timestamp_stop: '2022-01-01T19:20:48',
    quantity: 62.0,
    vehicle: 'DEM000',
    id: 139455,
    tank_volume_start: 23726.099999999864,
    tank_volume_stop: 23664.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:21:03',
    timestamp_stop: '2022-01-01T19:21:09',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 139457,
    tank_volume_start: 23664.099999999864,
    tank_volume_stop: 23608.199999999862,
  },
  {
    timestamp_start: '2022-01-01T19:21:24',
    timestamp_stop: '2022-01-01T19:21:30',
    quantity: 61.9,
    vehicle: 'DEM555',
    id: 139459,
    tank_volume_start: 23608.199999999862,
    tank_volume_stop: 23546.29999999986,
  },
  {
    timestamp_start: '2022-01-01T19:21:45',
    timestamp_stop: '2022-01-01T19:21:50',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 139461,
    tank_volume_start: 23546.29999999986,
    tank_volume_stop: 23492.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:22:05',
    timestamp_stop: '2022-01-01T19:22:11',
    quantity: 58.7,
    vehicle: 'DEM111',
    id: 139463,
    tank_volume_start: 23492.699999999862,
    tank_volume_stop: 23433.99999999986,
  },
  {
    timestamp_start: '2022-01-01T19:22:26',
    timestamp_stop: '2022-01-01T19:22:31',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 139465,
    tank_volume_start: 23433.99999999986,
    tank_volume_stop: 23380.899999999863,
  },
  {
    timestamp_start: '2022-01-01T19:22:46',
    timestamp_stop: '2022-01-01T19:22:52',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 139467,
    tank_volume_start: 23380.899999999863,
    tank_volume_stop: 23324.99999999986,
  },
  {
    timestamp_start: '2022-01-01T19:23:07',
    timestamp_stop: '2022-01-01T19:23:12',
    quantity: 50.2,
    vehicle: 'DEM888',
    id: 139469,
    tank_volume_start: 23324.99999999986,
    tank_volume_stop: 23274.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:23:27',
    timestamp_stop: '2022-01-01T19:23:32',
    quantity: 47.5,
    vehicle: 'DEM777',
    id: 139471,
    tank_volume_start: 23274.79999999986,
    tank_volume_stop: 23227.29999999986,
  },
  {
    timestamp_start: '2022-01-01T19:23:47',
    timestamp_stop: '2022-01-01T19:23:53',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 139473,
    tank_volume_start: 23227.29999999986,
    tank_volume_stop: 23169.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:24:08',
    timestamp_stop: '2022-01-01T19:24:13',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 139475,
    tank_volume_start: 23169.79999999986,
    tank_volume_stop: 23112.89999999986,
  },
  {
    timestamp_start: '2022-01-01T19:24:28',
    timestamp_stop: '2022-01-01T19:24:34',
    quantity: 57.9,
    vehicle: 'DEM777',
    id: 139477,
    tank_volume_start: 23112.89999999986,
    tank_volume_stop: 23054.999999999858,
  },
  {
    timestamp_start: '2022-01-01T19:24:49',
    timestamp_stop: '2022-01-01T19:24:54',
    quantity: 46.6,
    vehicle: 'DEM555',
    id: 139479,
    tank_volume_start: 23054.999999999858,
    tank_volume_stop: 23008.39999999986,
  },
  {
    timestamp_start: '2022-01-01T19:25:09',
    timestamp_stop: '2022-01-01T19:25:14',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 139480,
    tank_volume_start: 23008.39999999986,
    tank_volume_stop: 22951.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:25:29',
    timestamp_stop: '2022-01-01T19:25:35',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 139482,
    tank_volume_start: 22951.79999999986,
    tank_volume_stop: 22893.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:25:50',
    timestamp_stop: '2022-01-01T19:25:56',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 139484,
    tank_volume_start: 22893.699999999862,
    tank_volume_stop: 22833.199999999862,
  },
  {
    timestamp_start: '2022-01-01T19:26:11',
    timestamp_stop: '2022-01-01T19:26:17',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 139487,
    tank_volume_start: 22833.199999999862,
    tank_volume_stop: 22776.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:26:32',
    timestamp_stop: '2022-01-01T19:26:38',
    quantity: 61.0,
    vehicle: 'DEM555',
    id: 139489,
    tank_volume_start: 22776.599999999864,
    tank_volume_stop: 22715.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:26:53',
    timestamp_stop: '2022-01-01T19:26:58',
    quantity: 47.8,
    vehicle: 'DEM000',
    id: 139491,
    tank_volume_start: 22715.599999999864,
    tank_volume_stop: 22667.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:27:13',
    timestamp_stop: '2022-01-01T19:27:18',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 139493,
    tank_volume_start: 22667.799999999865,
    tank_volume_stop: 22614.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:27:33',
    timestamp_stop: '2022-01-01T19:27:38',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 139495,
    tank_volume_start: 22614.799999999865,
    tank_volume_stop: 22562.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:27:53',
    timestamp_stop: '2022-01-01T19:27:59',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 139496,
    tank_volume_start: 22562.099999999864,
    tank_volume_stop: 22500.999999999865,
  },
  {
    timestamp_start: '2022-01-01T19:28:14',
    timestamp_stop: '2022-01-01T19:28:20',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 139499,
    tank_volume_start: 22500.999999999865,
    tank_volume_stop: 22449.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:28:35',
    timestamp_stop: '2022-01-01T19:28:40',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 139501,
    tank_volume_start: 22449.299999999865,
    tank_volume_stop: 22392.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:28:55',
    timestamp_stop: '2022-01-01T19:29:00',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 139503,
    tank_volume_start: 22392.299999999865,
    tank_volume_stop: 22341.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:29:15',
    timestamp_stop: '2022-01-01T19:29:20',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 139505,
    tank_volume_start: 22341.799999999865,
    tank_volume_stop: 22292.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:29:35',
    timestamp_stop: '2022-01-01T19:29:40',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 139506,
    tank_volume_start: 22292.299999999865,
    tank_volume_stop: 22241.899999999863,
  },
  {
    timestamp_start: '2022-01-01T19:29:55',
    timestamp_stop: '2022-01-01T19:30:01',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 139508,
    tank_volume_start: 22241.899999999863,
    tank_volume_stop: 22189.99999999986,
  },
  {
    timestamp_start: '2022-01-01T19:30:16',
    timestamp_stop: '2022-01-01T19:30:21',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 139510,
    tank_volume_start: 22189.99999999986,
    tank_volume_stop: 22130.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:30:36',
    timestamp_stop: '2022-01-01T19:30:42',
    quantity: 51.7,
    vehicle: 'DEM666',
    id: 139512,
    tank_volume_start: 22130.399999999863,
    tank_volume_stop: 22078.699999999862,
  },
  {
    timestamp_start: '2022-01-01T19:30:57',
    timestamp_stop: '2022-01-01T19:31:02',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 139514,
    tank_volume_start: 22078.699999999862,
    tank_volume_stop: 22025.899999999863,
  },
  {
    timestamp_start: '2022-01-01T19:31:17',
    timestamp_stop: '2022-01-01T19:31:22',
    quantity: 55.3,
    vehicle: 'DEM111',
    id: 139516,
    tank_volume_start: 22025.899999999863,
    tank_volume_stop: 21970.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:31:37',
    timestamp_stop: '2022-01-01T19:31:43',
    quantity: 58.1,
    vehicle: 'DEM333',
    id: 139518,
    tank_volume_start: 21970.599999999864,
    tank_volume_stop: 21912.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:31:58',
    timestamp_stop: '2022-01-01T19:32:04',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 139520,
    tank_volume_start: 21912.499999999865,
    tank_volume_stop: 21855.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:32:19',
    timestamp_stop: '2022-01-01T19:32:25',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 139523,
    tank_volume_start: 21855.199999999866,
    tank_volume_stop: 21795.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:32:40',
    timestamp_stop: '2022-01-01T19:32:45',
    quantity: 51.0,
    vehicle: 'DEM555',
    id: 139525,
    tank_volume_start: 21795.799999999865,
    tank_volume_stop: 21744.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:33:00',
    timestamp_stop: '2022-01-01T19:33:06',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 139526,
    tank_volume_start: 21744.799999999865,
    tank_volume_stop: 21686.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:33:21',
    timestamp_stop: '2022-01-01T19:33:26',
    quantity: 48.8,
    vehicle: 'DEM111',
    id: 139529,
    tank_volume_start: 21686.499999999865,
    tank_volume_stop: 21637.699999999866,
  },
  {
    timestamp_start: '2022-01-01T19:33:41',
    timestamp_stop: '2022-01-01T19:33:47',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 139531,
    tank_volume_start: 21637.699999999866,
    tank_volume_stop: 21578.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:34:02',
    timestamp_stop: '2022-01-01T19:34:07',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 139533,
    tank_volume_start: 21578.199999999866,
    tank_volume_stop: 21523.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:34:22',
    timestamp_stop: '2022-01-01T19:34:27',
    quantity: 52.6,
    vehicle: 'DEM111',
    id: 139535,
    tank_volume_start: 21523.499999999865,
    tank_volume_stop: 21470.899999999867,
  },
  {
    timestamp_start: '2022-01-01T19:34:42',
    timestamp_stop: '2022-01-01T19:34:48',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 139537,
    tank_volume_start: 21470.899999999867,
    tank_volume_stop: 21413.399999999867,
  },
  {
    timestamp_start: '2022-01-01T19:35:03',
    timestamp_stop: '2022-01-01T19:35:09',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 139539,
    tank_volume_start: 21413.399999999867,
    tank_volume_stop: 21358.599999999868,
  },
  {
    timestamp_start: '2022-01-01T19:35:24',
    timestamp_stop: '2022-01-01T19:35:30',
    quantity: 63.3,
    vehicle: 'DEM555',
    id: 139541,
    tank_volume_start: 21358.599999999868,
    tank_volume_stop: 21295.29999999987,
  },
  {
    timestamp_start: '2022-01-01T19:35:45',
    timestamp_stop: '2022-01-01T19:35:50',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 139543,
    tank_volume_start: 21295.29999999987,
    tank_volume_stop: 21245.79999999987,
  },
  {
    timestamp_start: '2022-01-01T19:36:05',
    timestamp_stop: '2022-01-01T19:36:11',
    quantity: 60.9,
    vehicle: 'DEM888',
    id: 139545,
    tank_volume_start: 21245.79999999987,
    tank_volume_stop: 21184.899999999867,
  },
  {
    timestamp_start: '2022-01-01T19:36:26',
    timestamp_stop: '2022-01-01T19:36:32',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 139547,
    tank_volume_start: 21184.899999999867,
    tank_volume_stop: 21129.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:36:47',
    timestamp_stop: '2022-01-01T19:36:52',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 139549,
    tank_volume_start: 21129.199999999866,
    tank_volume_stop: 21077.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:37:07',
    timestamp_stop: '2022-01-01T19:37:12',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 139551,
    tank_volume_start: 21077.499999999865,
    tank_volume_stop: 21024.999999999865,
  },
  {
    timestamp_start: '2022-01-01T19:37:27',
    timestamp_stop: '2022-01-01T19:37:32',
    quantity: 50.3,
    vehicle: 'DEM111',
    id: 139553,
    tank_volume_start: 21024.999999999865,
    tank_volume_stop: 20974.699999999866,
  },
  {
    timestamp_start: '2022-01-01T19:37:47',
    timestamp_stop: '2022-01-01T19:37:53',
    quantity: 62.5,
    vehicle: 'DEM000',
    id: 139555,
    tank_volume_start: 20974.699999999866,
    tank_volume_stop: 20912.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:38:08',
    timestamp_stop: '2022-01-01T19:38:14',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 139557,
    tank_volume_start: 20912.199999999866,
    tank_volume_stop: 20852.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:38:29',
    timestamp_stop: '2022-01-01T19:38:35',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 139559,
    tank_volume_start: 20852.299999999865,
    tank_volume_stop: 20799.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:38:50',
    timestamp_stop: '2022-01-01T19:38:55',
    quantity: 50.8,
    vehicle: 'DEM777',
    id: 139561,
    tank_volume_start: 20799.499999999865,
    tank_volume_stop: 20748.699999999866,
  },
  {
    timestamp_start: '2022-01-01T19:39:10',
    timestamp_stop: '2022-01-01T19:39:15',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 139563,
    tank_volume_start: 20748.699999999866,
    tank_volume_stop: 20696.199999999866,
  },
  {
    timestamp_start: '2022-01-01T19:39:30',
    timestamp_stop: '2022-01-01T19:39:35',
    quantity: 47.7,
    vehicle: 'DEM000',
    id: 139565,
    tank_volume_start: 20696.199999999866,
    tank_volume_stop: 20648.499999999865,
  },
  {
    timestamp_start: '2022-01-01T19:39:50',
    timestamp_stop: '2022-01-01T19:39:55',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 139566,
    tank_volume_start: 20648.499999999865,
    tank_volume_stop: 20594.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:40:10',
    timestamp_stop: '2022-01-01T19:40:15',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 139568,
    tank_volume_start: 20594.599999999864,
    tank_volume_stop: 20546.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:40:30',
    timestamp_stop: '2022-01-01T19:40:35',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 139571,
    tank_volume_start: 20546.099999999864,
    tank_volume_stop: 20490.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:40:50',
    timestamp_stop: '2022-01-01T19:40:56',
    quantity: 50.9,
    vehicle: 'DEM444',
    id: 139573,
    tank_volume_start: 20490.399999999863,
    tank_volume_stop: 20439.49999999986,
  },
  {
    timestamp_start: '2022-01-01T19:41:11',
    timestamp_stop: '2022-01-01T19:41:15',
    quantity: 48.1,
    vehicle: 'DEM666',
    id: 139574,
    tank_volume_start: 20439.49999999986,
    tank_volume_stop: 20391.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:41:30',
    timestamp_stop: '2022-01-01T19:41:36',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 139576,
    tank_volume_start: 20391.399999999863,
    tank_volume_stop: 20336.099999999864,
  },
  {
    timestamp_start: '2022-01-01T19:41:51',
    timestamp_stop: '2022-01-01T19:41:56',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 139578,
    tank_volume_start: 20336.099999999864,
    tank_volume_stop: 20282.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:42:11',
    timestamp_stop: '2022-01-01T19:42:17',
    quantity: 59.2,
    vehicle: 'DEM888',
    id: 139580,
    tank_volume_start: 20282.799999999865,
    tank_volume_stop: 20223.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:42:32',
    timestamp_stop: '2022-01-01T19:42:37',
    quantity: 49.0,
    vehicle: 'DEM444',
    id: 139582,
    tank_volume_start: 20223.599999999864,
    tank_volume_stop: 20174.599999999864,
  },
  {
    timestamp_start: '2022-01-01T19:42:52',
    timestamp_stop: '2022-01-01T19:42:58',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 139584,
    tank_volume_start: 20174.599999999864,
    tank_volume_stop: 20117.399999999863,
  },
  {
    timestamp_start: '2022-01-01T19:43:13',
    timestamp_stop: '2022-01-01T19:43:19',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 139586,
    tank_volume_start: 20117.399999999863,
    tank_volume_stop: 20058.799999999865,
  },
  {
    timestamp_start: '2022-01-01T19:43:34',
    timestamp_stop: '2022-01-01T19:43:40',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 139588,
    tank_volume_start: 20058.799999999865,
    tank_volume_stop: 19999.899999999863,
  },
  {
    timestamp_start: '2022-01-01T19:43:55',
    timestamp_stop: '2022-01-01T19:43:59',
    quantity: 49.6,
    vehicle: 'DEM111',
    id: 139590,
    tank_volume_start: 39999.89999999986,
    tank_volume_stop: 39950.299999999865,
  },
  {
    timestamp_start: '2022-01-01T19:44:14',
    timestamp_stop: '2022-01-01T19:44:20',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 139592,
    tank_volume_start: 39950.299999999865,
    tank_volume_stop: 39899.49999999986,
  },
  {
    timestamp_start: '2022-01-01T19:44:35',
    timestamp_stop: '2022-01-01T19:44:40',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 139594,
    tank_volume_start: 39899.49999999986,
    tank_volume_stop: 39844.69999999986,
  },
  {
    timestamp_start: '2022-01-01T19:44:55',
    timestamp_stop: '2022-01-01T19:45:01',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 139596,
    tank_volume_start: 39844.69999999986,
    tank_volume_stop: 39788.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:45:16',
    timestamp_stop: '2022-01-01T19:45:21',
    quantity: 48.5,
    vehicle: 'DEM333',
    id: 139598,
    tank_volume_start: 39788.399999999856,
    tank_volume_stop: 39739.899999999856,
  },
  {
    timestamp_start: '2022-01-01T19:45:36',
    timestamp_stop: '2022-01-01T19:45:41',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 139600,
    tank_volume_start: 39739.899999999856,
    tank_volume_stop: 39686.59999999985,
  },
  {
    timestamp_start: '2022-01-01T19:45:56',
    timestamp_stop: '2022-01-01T19:46:02',
    quantity: 62.8,
    vehicle: 'DEM777',
    id: 139602,
    tank_volume_start: 39686.59999999985,
    tank_volume_stop: 39623.79999999985,
  },
  {
    timestamp_start: '2022-01-01T19:46:17',
    timestamp_stop: '2022-01-01T19:46:23',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 139604,
    tank_volume_start: 39623.79999999985,
    tank_volume_stop: 39566.99999999985,
  },
  {
    timestamp_start: '2022-01-01T19:46:38',
    timestamp_stop: '2022-01-01T19:46:43',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 139606,
    tank_volume_start: 39566.99999999985,
    tank_volume_stop: 39513.39999999985,
  },
  {
    timestamp_start: '2022-01-01T19:46:58',
    timestamp_stop: '2022-01-01T19:47:03',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 139608,
    tank_volume_start: 39513.39999999985,
    tank_volume_stop: 39464.89999999985,
  },
  {
    timestamp_start: '2022-01-01T19:47:18',
    timestamp_stop: '2022-01-01T19:47:23',
    quantity: 51.0,
    vehicle: 'DEM111',
    id: 139610,
    tank_volume_start: 39464.89999999985,
    tank_volume_stop: 39413.89999999985,
  },
  {
    timestamp_start: '2022-01-01T19:47:38',
    timestamp_stop: '2022-01-01T19:47:44',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 139612,
    tank_volume_start: 39413.89999999985,
    tank_volume_stop: 39358.79999999985,
  },
  {
    timestamp_start: '2022-01-01T19:47:59',
    timestamp_stop: '2022-01-01T19:48:05',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 139614,
    tank_volume_start: 39358.79999999985,
    tank_volume_stop: 39298.39999999985,
  },
  {
    timestamp_start: '2022-01-01T19:48:20',
    timestamp_stop: '2022-01-01T19:48:26',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 139616,
    tank_volume_start: 39298.39999999985,
    tank_volume_stop: 39236.19999999985,
  },
  {
    timestamp_start: '2022-01-01T19:48:41',
    timestamp_stop: '2022-01-01T19:48:46',
    quantity: 55.5,
    vehicle: 'DEM666',
    id: 139618,
    tank_volume_start: 39236.19999999985,
    tank_volume_stop: 39180.69999999985,
  },
  {
    timestamp_start: '2022-01-01T19:49:01',
    timestamp_stop: '2022-01-01T19:49:08',
    quantity: 61.2,
    vehicle: 'DEM444',
    id: 139620,
    tank_volume_start: 39180.69999999985,
    tank_volume_stop: 39119.499999999854,
  },
  {
    timestamp_start: '2022-01-01T19:49:23',
    timestamp_stop: '2022-01-01T19:49:28',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 139622,
    tank_volume_start: 39119.499999999854,
    tank_volume_stop: 39069.09999999985,
  },
  {
    timestamp_start: '2022-01-01T19:49:43',
    timestamp_stop: '2022-01-01T19:49:48',
    quantity: 49.9,
    vehicle: 'DEM000',
    id: 139624,
    tank_volume_start: 39069.09999999985,
    tank_volume_stop: 39019.19999999985,
  },
  {
    timestamp_start: '2022-01-01T19:50:03',
    timestamp_stop: '2022-01-01T19:50:09',
    quantity: 63.0,
    vehicle: 'DEM333',
    id: 139626,
    tank_volume_start: 39019.19999999985,
    tank_volume_stop: 38956.19999999985,
  },
  {
    timestamp_start: '2022-01-01T19:50:24',
    timestamp_stop: '2022-01-01T19:50:29',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 139628,
    tank_volume_start: 38956.19999999985,
    tank_volume_stop: 38900.19999999985,
  },
  {
    timestamp_start: '2022-01-01T19:50:44',
    timestamp_stop: '2022-01-01T19:50:50',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 139630,
    tank_volume_start: 38900.19999999985,
    tank_volume_stop: 38840.09999999985,
  },
  {
    timestamp_start: '2022-01-01T19:51:05',
    timestamp_stop: '2022-01-01T19:51:11',
    quantity: 53.7,
    vehicle: 'DEM111',
    id: 139632,
    tank_volume_start: 38840.09999999985,
    tank_volume_stop: 38786.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:51:26',
    timestamp_stop: '2022-01-01T19:51:31',
    quantity: 54.2,
    vehicle: 'DEM888',
    id: 139634,
    tank_volume_start: 38786.399999999856,
    tank_volume_stop: 38732.19999999986,
  },
  {
    timestamp_start: '2022-01-01T19:51:46',
    timestamp_stop: '2022-01-01T19:51:52',
    quantity: 57.0,
    vehicle: 'DEM666',
    id: 139636,
    tank_volume_start: 38732.19999999986,
    tank_volume_stop: 38675.19999999986,
  },
  {
    timestamp_start: '2022-01-01T19:52:07',
    timestamp_stop: '2022-01-01T19:52:12',
    quantity: 49.3,
    vehicle: 'DEM666',
    id: 139638,
    tank_volume_start: 38675.19999999986,
    tank_volume_stop: 38625.899999999856,
  },
  {
    timestamp_start: '2022-01-01T19:52:27',
    timestamp_stop: '2022-01-01T19:52:32',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 139640,
    tank_volume_start: 38625.899999999856,
    tank_volume_stop: 38570.29999999986,
  },
  {
    timestamp_start: '2022-01-01T19:52:47',
    timestamp_stop: '2022-01-01T19:52:53',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 139642,
    tank_volume_start: 38570.29999999986,
    tank_volume_stop: 38516.999999999854,
  },
  {
    timestamp_start: '2022-01-01T19:53:08',
    timestamp_stop: '2022-01-01T19:53:13',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 139644,
    tank_volume_start: 38516.999999999854,
    tank_volume_stop: 38465.59999999985,
  },
  {
    timestamp_start: '2022-01-01T19:53:28',
    timestamp_stop: '2022-01-01T19:53:34',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 139646,
    tank_volume_start: 38465.59999999985,
    tank_volume_stop: 38407.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:53:49',
    timestamp_stop: '2022-01-01T19:53:54',
    quantity: 48.7,
    vehicle: 'DEM222',
    id: 139648,
    tank_volume_start: 38407.399999999856,
    tank_volume_stop: 38358.69999999986,
  },
  {
    timestamp_start: '2022-01-01T19:54:09',
    timestamp_stop: '2022-01-01T19:54:14',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 139650,
    tank_volume_start: 38358.69999999986,
    tank_volume_stop: 38300.09999999986,
  },
  {
    timestamp_start: '2022-01-01T19:54:29',
    timestamp_stop: '2022-01-01T19:54:35',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 139652,
    tank_volume_start: 38300.09999999986,
    tank_volume_stop: 38246.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:54:50',
    timestamp_stop: '2022-01-01T19:54:55',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 139654,
    tank_volume_start: 38246.79999999986,
    tank_volume_stop: 38193.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:55:10',
    timestamp_stop: '2022-01-01T19:55:15',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 139656,
    tank_volume_start: 38193.79999999986,
    tank_volume_stop: 38145.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:55:30',
    timestamp_stop: '2022-01-01T19:55:36',
    quantity: 59.0,
    vehicle: 'DEM777',
    id: 139658,
    tank_volume_start: 38145.399999999856,
    tank_volume_stop: 38086.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:55:51',
    timestamp_stop: '2022-01-01T19:55:57',
    quantity: 63.1,
    vehicle: 'DEM777',
    id: 139660,
    tank_volume_start: 38086.399999999856,
    tank_volume_stop: 38023.29999999986,
  },
  {
    timestamp_start: '2022-01-01T19:56:12',
    timestamp_stop: '2022-01-01T19:56:18',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 139662,
    tank_volume_start: 38023.29999999986,
    tank_volume_stop: 37961.69999999986,
  },
  {
    timestamp_start: '2022-01-01T19:56:33',
    timestamp_stop: '2022-01-01T19:56:39',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 139664,
    tank_volume_start: 37961.69999999986,
    tank_volume_stop: 37903.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:56:54',
    timestamp_stop: '2022-01-01T19:57:00',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 139666,
    tank_volume_start: 37903.79999999986,
    tank_volume_stop: 37847.399999999856,
  },
  {
    timestamp_start: '2022-01-01T19:57:15',
    timestamp_stop: '2022-01-01T19:57:20',
    quantity: 49.2,
    vehicle: 'DEM111',
    id: 139668,
    tank_volume_start: 37847.399999999856,
    tank_volume_stop: 37798.19999999986,
  },
  {
    timestamp_start: '2022-01-01T19:57:35',
    timestamp_stop: '2022-01-01T19:57:40',
    quantity: 54.4,
    vehicle: 'DEM777',
    id: 139670,
    tank_volume_start: 37798.19999999986,
    tank_volume_stop: 37743.79999999986,
  },
  {
    timestamp_start: '2022-01-01T19:57:55',
    timestamp_stop: '2022-01-01T19:58:01',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 139672,
    tank_volume_start: 37743.79999999986,
    tank_volume_stop: 37682.899999999856,
  },
  {
    timestamp_start: '2022-01-01T19:58:16',
    timestamp_stop: '2022-01-01T19:58:22',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 139674,
    tank_volume_start: 37682.899999999856,
    tank_volume_stop: 37625.59999999985,
  },
  {
    timestamp_start: '2022-01-01T19:58:37',
    timestamp_stop: '2022-01-01T19:58:42',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 139676,
    tank_volume_start: 37625.59999999985,
    tank_volume_stop: 37573.09999999985,
  },
  {
    timestamp_start: '2022-01-01T19:58:57',
    timestamp_stop: '2022-01-01T19:59:02',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 139678,
    tank_volume_start: 37573.09999999985,
    tank_volume_stop: 37521.79999999985,
  },
  {
    timestamp_start: '2022-01-01T19:59:17',
    timestamp_stop: '2022-01-01T19:59:22',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 139680,
    tank_volume_start: 37521.79999999985,
    tank_volume_stop: 37471.29999999985,
  },
  {
    timestamp_start: '2022-01-01T19:59:37',
    timestamp_stop: '2022-01-01T19:59:43',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 139682,
    tank_volume_start: 37471.29999999985,
    tank_volume_stop: 37410.69999999985,
  },
  {
    timestamp_start: '2022-01-01T19:59:58',
    timestamp_stop: '2022-01-01T20:00:04',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 139684,
    tank_volume_start: 37410.69999999985,
    tank_volume_stop: 37357.69999999985,
  },
  {
    timestamp_start: '2022-01-01T20:00:19',
    timestamp_stop: '2022-01-01T20:00:24',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 139686,
    tank_volume_start: 37357.69999999985,
    tank_volume_stop: 37303.79999999985,
  },
  {
    timestamp_start: '2022-01-01T20:00:39',
    timestamp_stop: '2022-01-01T20:00:45',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 139688,
    tank_volume_start: 37303.79999999985,
    tank_volume_stop: 37249.39999999985,
  },
  {
    timestamp_start: '2022-01-01T20:01:00',
    timestamp_stop: '2022-01-01T20:01:04',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 139690,
    tank_volume_start: 37249.39999999985,
    tank_volume_stop: 37200.39999999985,
  },
  {
    timestamp_start: '2022-01-01T20:01:19',
    timestamp_stop: '2022-01-01T20:01:25',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 139692,
    tank_volume_start: 37200.39999999985,
    tank_volume_stop: 37148.599999999846,
  },
  {
    timestamp_start: '2022-01-01T20:01:40',
    timestamp_stop: '2022-01-01T20:01:45',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 139694,
    tank_volume_start: 37148.599999999846,
    tank_volume_stop: 37096.39999999985,
  },
  {
    timestamp_start: '2022-01-01T20:02:00',
    timestamp_stop: '2022-01-01T20:02:05',
    quantity: 55.8,
    vehicle: 'DEM888',
    id: 139696,
    tank_volume_start: 37096.39999999985,
    tank_volume_stop: 37040.599999999846,
  },
  {
    timestamp_start: '2022-01-01T20:02:20',
    timestamp_stop: '2022-01-01T20:02:26',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 139698,
    tank_volume_start: 37040.599999999846,
    tank_volume_stop: 36987.49999999985,
  },
  {
    timestamp_start: '2022-01-01T20:02:41',
    timestamp_stop: '2022-01-01T20:02:46',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 139700,
    tank_volume_start: 36987.49999999985,
    tank_volume_stop: 36934.099999999846,
  },
  {
    timestamp_start: '2022-01-01T20:03:01',
    timestamp_stop: '2022-01-01T20:03:07',
    quantity: 60.0,
    vehicle: 'DEM222',
    id: 139702,
    tank_volume_start: 36934.099999999846,
    tank_volume_stop: 36874.099999999846,
  },
  {
    timestamp_start: '2022-01-01T20:03:22',
    timestamp_stop: '2022-01-01T20:03:27',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 139704,
    tank_volume_start: 36874.099999999846,
    tank_volume_stop: 36820.89999999985,
  },
  {
    timestamp_start: '2022-01-01T20:03:42',
    timestamp_stop: '2022-01-01T20:03:48',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 139706,
    tank_volume_start: 36820.89999999985,
    tank_volume_stop: 36769.599999999846,
  },
  {
    timestamp_start: '2022-01-01T20:04:03',
    timestamp_stop: '2022-01-01T20:04:08',
    quantity: 59.5,
    vehicle: 'DEM777',
    id: 139708,
    tank_volume_start: 36769.599999999846,
    tank_volume_stop: 36710.099999999846,
  },
  {
    timestamp_start: '2022-01-01T20:04:23',
    timestamp_stop: '2022-01-01T20:04:29',
    quantity: 53.9,
    vehicle: 'DEM666',
    id: 139710,
    tank_volume_start: 36710.099999999846,
    tank_volume_stop: 36656.199999999844,
  },
  {
    timestamp_start: '2022-01-01T20:04:44',
    timestamp_stop: '2022-01-01T20:04:49',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 139712,
    tank_volume_start: 36656.199999999844,
    tank_volume_stop: 36600.699999999844,
  },
  {
    timestamp_start: '2022-01-01T20:05:04',
    timestamp_stop: '2022-01-01T20:05:10',
    quantity: 50.8,
    vehicle: 'DEM222',
    id: 139714,
    tank_volume_start: 36600.699999999844,
    tank_volume_stop: 36549.89999999984,
  },
  {
    timestamp_start: '2022-01-01T20:05:25',
    timestamp_stop: '2022-01-01T20:05:30',
    quantity: 59.2,
    vehicle: 'DEM000',
    id: 139716,
    tank_volume_start: 36549.89999999984,
    tank_volume_stop: 36490.699999999844,
  },
  {
    timestamp_start: '2022-01-01T20:05:45',
    timestamp_stop: '2022-01-01T20:05:51',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 139718,
    tank_volume_start: 36490.699999999844,
    tank_volume_stop: 36435.89999999984,
  },
  {
    timestamp_start: '2022-01-01T20:06:06',
    timestamp_stop: '2022-01-01T20:06:11',
    quantity: 47.4,
    vehicle: 'DEM777',
    id: 139720,
    tank_volume_start: 36435.89999999984,
    tank_volume_stop: 36388.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:06:26',
    timestamp_stop: '2022-01-01T20:06:31',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 139722,
    tank_volume_start: 36388.49999999984,
    tank_volume_stop: 36332.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:06:46',
    timestamp_stop: '2022-01-01T20:06:52',
    quantity: 58.5,
    vehicle: 'DEM333',
    id: 139724,
    tank_volume_start: 36332.19999999984,
    tank_volume_stop: 36273.69999999984,
  },
  {
    timestamp_start: '2022-01-01T20:07:07',
    timestamp_stop: '2022-01-01T20:07:12',
    quantity: 47.7,
    vehicle: 'DEM333',
    id: 139726,
    tank_volume_start: 36273.69999999984,
    tank_volume_stop: 36225.99999999984,
  },
  {
    timestamp_start: '2022-01-01T20:07:27',
    timestamp_stop: '2022-01-01T20:07:33',
    quantity: 62.8,
    vehicle: 'DEM111',
    id: 139728,
    tank_volume_start: 36225.99999999984,
    tank_volume_stop: 36163.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:07:48',
    timestamp_stop: '2022-01-01T20:07:54',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 139730,
    tank_volume_start: 36163.19999999984,
    tank_volume_stop: 36109.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:08:09',
    timestamp_stop: '2022-01-01T20:08:14',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 139732,
    tank_volume_start: 36109.49999999984,
    tank_volume_stop: 36055.89999999984,
  },
  {
    timestamp_start: '2022-01-01T20:08:29',
    timestamp_stop: '2022-01-01T20:08:34',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 139734,
    tank_volume_start: 36055.89999999984,
    tank_volume_stop: 36001.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:08:49',
    timestamp_stop: '2022-01-01T20:08:54',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 139736,
    tank_volume_start: 36001.49999999984,
    tank_volume_stop: 35950.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:09:09',
    timestamp_stop: '2022-01-01T20:09:15',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 139738,
    tank_volume_start: 35950.19999999984,
    tank_volume_stop: 35893.69999999984,
  },
  {
    timestamp_start: '2022-01-01T20:09:30',
    timestamp_stop: '2022-01-01T20:09:35',
    quantity: 48.2,
    vehicle: 'DEM000',
    id: 139740,
    tank_volume_start: 35893.69999999984,
    tank_volume_stop: 35845.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:09:50',
    timestamp_stop: '2022-01-01T20:09:56',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 139742,
    tank_volume_start: 35845.49999999984,
    tank_volume_stop: 35787.29999999984,
  },
  {
    timestamp_start: '2022-01-01T20:10:11',
    timestamp_stop: '2022-01-01T20:10:16',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 139744,
    tank_volume_start: 35787.29999999984,
    tank_volume_stop: 35732.39999999984,
  },
  {
    timestamp_start: '2022-01-01T20:10:31',
    timestamp_stop: '2022-01-01T20:10:37',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 139746,
    tank_volume_start: 35732.39999999984,
    tank_volume_stop: 35670.79999999984,
  },
  {
    timestamp_start: '2022-01-01T20:10:52',
    timestamp_stop: '2022-01-01T20:10:58',
    quantity: 59.7,
    vehicle: 'DEM444',
    id: 139748,
    tank_volume_start: 35670.79999999984,
    tank_volume_stop: 35611.099999999846,
  },
  {
    timestamp_start: '2022-01-01T20:11:13',
    timestamp_stop: '2022-01-01T20:11:19',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 139750,
    tank_volume_start: 35611.099999999846,
    tank_volume_stop: 35554.699999999844,
  },
  {
    timestamp_start: '2022-01-01T20:11:34',
    timestamp_stop: '2022-01-01T20:11:39',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 139752,
    tank_volume_start: 35554.699999999844,
    tank_volume_stop: 35500.199999999844,
  },
  {
    timestamp_start: '2022-01-01T20:11:54',
    timestamp_stop: '2022-01-01T20:12:00',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 139754,
    tank_volume_start: 35500.199999999844,
    tank_volume_stop: 35448.79999999984,
  },
  {
    timestamp_start: '2022-01-01T20:12:15',
    timestamp_stop: '2022-01-01T20:12:20',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 139756,
    tank_volume_start: 35448.79999999984,
    tank_volume_stop: 35392.89999999984,
  },
  {
    timestamp_start: '2022-01-01T20:12:35',
    timestamp_stop: '2022-01-01T20:12:41',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 139758,
    tank_volume_start: 35392.89999999984,
    tank_volume_stop: 35335.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:12:56',
    timestamp_stop: '2022-01-01T20:13:01',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 139760,
    tank_volume_start: 35335.59999999984,
    tank_volume_stop: 35282.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:13:16',
    timestamp_stop: '2022-01-01T20:13:21',
    quantity: 47.6,
    vehicle: 'DEM222',
    id: 139762,
    tank_volume_start: 35282.59999999984,
    tank_volume_stop: 35234.99999999984,
  },
  {
    timestamp_start: '2022-01-01T20:13:36',
    timestamp_stop: '2022-01-01T20:13:41',
    quantity: 47.3,
    vehicle: 'DEM888',
    id: 139764,
    tank_volume_start: 35234.99999999984,
    tank_volume_stop: 35187.69999999984,
  },
  {
    timestamp_start: '2022-01-01T20:13:56',
    timestamp_stop: '2022-01-01T20:14:01',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 139766,
    tank_volume_start: 35187.69999999984,
    tank_volume_stop: 35131.299999999836,
  },
  {
    timestamp_start: '2022-01-01T20:14:16',
    timestamp_stop: '2022-01-01T20:14:21',
    quantity: 50.1,
    vehicle: 'DEM333',
    id: 139768,
    tank_volume_start: 35131.299999999836,
    tank_volume_stop: 35081.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:14:36',
    timestamp_stop: '2022-01-01T20:14:42',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 139770,
    tank_volume_start: 35081.19999999984,
    tank_volume_stop: 35022.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:14:57',
    timestamp_stop: '2022-01-01T20:15:02',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 139772,
    tank_volume_start: 35022.19999999984,
    tank_volume_stop: 34970.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:15:17',
    timestamp_stop: '2022-01-01T20:15:23',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 139774,
    tank_volume_start: 34970.19999999984,
    tank_volume_stop: 34912.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:15:38',
    timestamp_stop: '2022-01-01T20:15:44',
    quantity: 56.3,
    vehicle: 'DEM555',
    id: 139776,
    tank_volume_start: 34912.59999999984,
    tank_volume_stop: 34856.299999999836,
  },
  {
    timestamp_start: '2022-01-01T20:15:59',
    timestamp_stop: '2022-01-01T20:16:05',
    quantity: 59.0,
    vehicle: 'DEM777',
    id: 139778,
    tank_volume_start: 34856.299999999836,
    tank_volume_stop: 34797.299999999836,
  },
  {
    timestamp_start: '2022-01-01T20:16:20',
    timestamp_stop: '2022-01-01T20:16:25',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 139780,
    tank_volume_start: 34797.299999999836,
    tank_volume_stop: 34742.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:16:40',
    timestamp_stop: '2022-01-01T20:16:46',
    quantity: 60.0,
    vehicle: 'DEM444',
    id: 139782,
    tank_volume_start: 34742.49999999983,
    tank_volume_stop: 34682.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:17:01',
    timestamp_stop: '2022-01-01T20:17:07',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 139784,
    tank_volume_start: 34682.49999999983,
    tank_volume_stop: 34628.799999999836,
  },
  {
    timestamp_start: '2022-01-01T20:17:22',
    timestamp_stop: '2022-01-01T20:17:27',
    quantity: 58.6,
    vehicle: 'DEM444',
    id: 139786,
    tank_volume_start: 34628.799999999836,
    tank_volume_stop: 34570.19999999984,
  },
  {
    timestamp_start: '2022-01-01T20:17:42',
    timestamp_stop: '2022-01-01T20:17:48',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 139788,
    tank_volume_start: 34570.19999999984,
    tank_volume_stop: 34515.799999999836,
  },
  {
    timestamp_start: '2022-01-01T20:18:03',
    timestamp_stop: '2022-01-01T20:18:09',
    quantity: 62.8,
    vehicle: 'DEM777',
    id: 139790,
    tank_volume_start: 34515.799999999836,
    tank_volume_stop: 34452.99999999983,
  },
  {
    timestamp_start: '2022-01-01T20:18:24',
    timestamp_stop: '2022-01-01T20:18:30',
    quantity: 61.8,
    vehicle: 'DEM000',
    id: 139792,
    tank_volume_start: 34452.99999999983,
    tank_volume_stop: 34391.19999999983,
  },
  {
    timestamp_start: '2022-01-01T20:18:45',
    timestamp_stop: '2022-01-01T20:18:50',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 139794,
    tank_volume_start: 34391.19999999983,
    tank_volume_stop: 34340.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:19:05',
    timestamp_stop: '2022-01-01T20:19:11',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 139796,
    tank_volume_start: 34340.89999999983,
    tank_volume_stop: 34287.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:19:26',
    timestamp_stop: '2022-01-01T20:19:31',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 139798,
    tank_volume_start: 34287.69999999983,
    tank_volume_stop: 34230.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:19:46',
    timestamp_stop: '2022-01-01T20:19:52',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 139800,
    tank_volume_start: 34230.59999999983,
    tank_volume_stop: 34171.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:20:07',
    timestamp_stop: '2022-01-01T20:20:13',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 139802,
    tank_volume_start: 34171.69999999983,
    tank_volume_stop: 34110.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:20:28',
    timestamp_stop: '2022-01-01T20:20:34',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 139804,
    tank_volume_start: 34110.69999999983,
    tank_volume_stop: 34050.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:20:49',
    timestamp_stop: '2022-01-01T20:20:55',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 139806,
    tank_volume_start: 34050.39999999983,
    tank_volume_stop: 33997.599999999824,
  },
  {
    timestamp_start: '2022-01-01T20:21:10',
    timestamp_stop: '2022-01-01T20:21:15',
    quantity: 56.3,
    vehicle: 'DEM666',
    id: 139808,
    tank_volume_start: 33997.599999999824,
    tank_volume_stop: 33941.29999999982,
  },
  {
    timestamp_start: '2022-01-01T20:21:30',
    timestamp_stop: '2022-01-01T20:21:36',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 139810,
    tank_volume_start: 33941.29999999982,
    tank_volume_stop: 33882.39999999982,
  },
  {
    timestamp_start: '2022-01-01T20:21:51',
    timestamp_stop: '2022-01-01T20:21:57',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 139812,
    tank_volume_start: 33882.39999999982,
    tank_volume_stop: 33820.29999999982,
  },
  {
    timestamp_start: '2022-01-01T20:22:12',
    timestamp_stop: '2022-01-01T20:22:18',
    quantity: 52.9,
    vehicle: 'DEM222',
    id: 139814,
    tank_volume_start: 33820.29999999982,
    tank_volume_stop: 33767.39999999982,
  },
  {
    timestamp_start: '2022-01-01T20:22:33',
    timestamp_stop: '2022-01-01T20:22:38',
    quantity: 48.8,
    vehicle: 'DEM333',
    id: 139816,
    tank_volume_start: 33767.39999999982,
    tank_volume_stop: 33718.59999999982,
  },
  {
    timestamp_start: '2022-01-01T20:22:53',
    timestamp_stop: '2022-01-01T20:22:58',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 139818,
    tank_volume_start: 33718.59999999982,
    tank_volume_stop: 33664.49999999982,
  },
  {
    timestamp_start: '2022-01-01T20:23:13',
    timestamp_stop: '2022-01-01T20:23:19',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 139820,
    tank_volume_start: 33664.49999999982,
    tank_volume_stop: 33607.09999999982,
  },
  {
    timestamp_start: '2022-01-01T20:23:34',
    timestamp_stop: '2022-01-01T20:23:39',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 139822,
    tank_volume_start: 33607.09999999982,
    tank_volume_stop: 33557.09999999982,
  },
  {
    timestamp_start: '2022-01-01T20:23:54',
    timestamp_stop: '2022-01-01T20:23:59',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 139824,
    tank_volume_start: 33557.09999999982,
    tank_volume_stop: 33503.89999999982,
  },
  {
    timestamp_start: '2022-01-01T20:24:14',
    timestamp_stop: '2022-01-01T20:24:19',
    quantity: 50.0,
    vehicle: 'DEM888',
    id: 139826,
    tank_volume_start: 33503.89999999982,
    tank_volume_stop: 33453.89999999982,
  },
  {
    timestamp_start: '2022-01-01T20:24:34',
    timestamp_stop: '2022-01-01T20:24:39',
    quantity: 51.7,
    vehicle: 'DEM888',
    id: 139828,
    tank_volume_start: 33453.89999999982,
    tank_volume_stop: 33402.19999999982,
  },
  {
    timestamp_start: '2022-01-01T20:24:54',
    timestamp_stop: '2022-01-01T20:25:00',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 139830,
    tank_volume_start: 33402.19999999982,
    tank_volume_stop: 33348.099999999824,
  },
  {
    timestamp_start: '2022-01-01T20:25:15',
    timestamp_stop: '2022-01-01T20:25:20',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 139832,
    tank_volume_start: 33348.099999999824,
    tank_volume_stop: 33293.599999999824,
  },
  {
    timestamp_start: '2022-01-01T20:25:35',
    timestamp_stop: '2022-01-01T20:25:41',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 139834,
    tank_volume_start: 33293.599999999824,
    tank_volume_stop: 33239.099999999824,
  },
  {
    timestamp_start: '2022-01-01T20:25:56',
    timestamp_stop: '2022-01-01T20:26:02',
    quantity: 60.1,
    vehicle: 'DEM111',
    id: 139836,
    tank_volume_start: 33239.099999999824,
    tank_volume_stop: 33178.999999999825,
  },
  {
    timestamp_start: '2022-01-01T20:26:17',
    timestamp_stop: '2022-01-01T20:26:22',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 139838,
    tank_volume_start: 33178.999999999825,
    tank_volume_stop: 33120.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:26:37',
    timestamp_stop: '2022-01-01T20:26:43',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 139840,
    tank_volume_start: 33120.89999999983,
    tank_volume_stop: 33067.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:26:58',
    timestamp_stop: '2022-01-01T20:27:03',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 139842,
    tank_volume_start: 33067.69999999983,
    tank_volume_stop: 33011.79999999983,
  },
  {
    timestamp_start: '2022-01-01T20:27:18',
    timestamp_stop: '2022-01-01T20:27:24',
    quantity: 61.2,
    vehicle: 'DEM888',
    id: 139844,
    tank_volume_start: 33011.79999999983,
    tank_volume_stop: 32950.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:27:39',
    timestamp_stop: '2022-01-01T20:27:45',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 139846,
    tank_volume_start: 32950.59999999983,
    tank_volume_stop: 32898.09999999983,
  },
  {
    timestamp_start: '2022-01-01T20:28:00',
    timestamp_stop: '2022-01-01T20:28:05',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 139848,
    tank_volume_start: 32898.09999999983,
    tank_volume_stop: 32843.399999999834,
  },
  {
    timestamp_start: '2022-01-01T20:28:20',
    timestamp_stop: '2022-01-01T20:28:25',
    quantity: 46.4,
    vehicle: 'DEM666',
    id: 139850,
    tank_volume_start: 32843.399999999834,
    tank_volume_stop: 32796.99999999983,
  },
  {
    timestamp_start: '2022-01-01T20:28:40',
    timestamp_stop: '2022-01-01T20:28:44',
    quantity: 45.3,
    vehicle: 'DEM777',
    id: 139852,
    tank_volume_start: 32796.99999999983,
    tank_volume_stop: 32751.699999999833,
  },
  {
    timestamp_start: '2022-01-01T20:28:59',
    timestamp_stop: '2022-01-01T20:29:05',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 139854,
    tank_volume_start: 32751.699999999833,
    tank_volume_stop: 32695.299999999832,
  },
  {
    timestamp_start: '2022-01-01T20:29:20',
    timestamp_stop: '2022-01-01T20:29:26',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 139856,
    tank_volume_start: 32695.299999999832,
    tank_volume_stop: 32639.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:29:41',
    timestamp_stop: '2022-01-01T20:29:46',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 139858,
    tank_volume_start: 32639.59999999983,
    tank_volume_stop: 32581.999999999833,
  },
  {
    timestamp_start: '2022-01-01T20:30:01',
    timestamp_stop: '2022-01-01T20:30:08',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 139860,
    tank_volume_start: 32581.999999999833,
    tank_volume_stop: 32519.899999999834,
  },
  {
    timestamp_start: '2022-01-01T20:30:23',
    timestamp_stop: '2022-01-01T20:30:28',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 139862,
    tank_volume_start: 32519.899999999834,
    tank_volume_stop: 32468.899999999834,
  },
  {
    timestamp_start: '2022-01-01T20:30:43',
    timestamp_stop: '2022-01-01T20:30:48',
    quantity: 58.4,
    vehicle: 'DEM333',
    id: 139864,
    tank_volume_start: 32468.899999999834,
    tank_volume_stop: 32410.499999999833,
  },
  {
    timestamp_start: '2022-01-01T20:31:03',
    timestamp_stop: '2022-01-01T20:31:10',
    quantity: 62.9,
    vehicle: 'DEM888',
    id: 139866,
    tank_volume_start: 32410.499999999833,
    tank_volume_stop: 32347.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:31:25',
    timestamp_stop: '2022-01-01T20:31:30',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 139868,
    tank_volume_start: 32347.59999999983,
    tank_volume_stop: 32294.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:31:45',
    timestamp_stop: '2022-01-01T20:31:51',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 139870,
    tank_volume_start: 32294.89999999983,
    tank_volume_stop: 32235.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:32:06',
    timestamp_stop: '2022-01-01T20:32:11',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 139872,
    tank_volume_start: 32235.59999999983,
    tank_volume_stop: 32183.19999999983,
  },
  {
    timestamp_start: '2022-01-01T20:32:26',
    timestamp_stop: '2022-01-01T20:32:31',
    quantity: 47.1,
    vehicle: 'DEM333',
    id: 139874,
    tank_volume_start: 32183.19999999983,
    tank_volume_stop: 32136.09999999983,
  },
  {
    timestamp_start: '2022-01-01T20:32:46',
    timestamp_stop: '2022-01-01T20:32:52',
    quantity: 63.4,
    vehicle: 'DEM666',
    id: 139876,
    tank_volume_start: 32136.09999999983,
    tank_volume_stop: 32072.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:33:07',
    timestamp_stop: '2022-01-01T20:33:14',
    quantity: 63.7,
    vehicle: 'DEM333',
    id: 139878,
    tank_volume_start: 32072.69999999983,
    tank_volume_stop: 32008.99999999983,
  },
  {
    timestamp_start: '2022-01-01T20:33:29',
    timestamp_stop: '2022-01-01T20:33:35',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 139880,
    tank_volume_start: 32008.99999999983,
    tank_volume_stop: 31947.99999999983,
  },
  {
    timestamp_start: '2022-01-01T20:33:50',
    timestamp_stop: '2022-01-01T20:33:56',
    quantity: 60.0,
    vehicle: 'DEM444',
    id: 139882,
    tank_volume_start: 31947.99999999983,
    tank_volume_stop: 31887.99999999983,
  },
  {
    timestamp_start: '2022-01-01T20:34:11',
    timestamp_stop: '2022-01-01T20:34:16',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 139884,
    tank_volume_start: 31887.99999999983,
    tank_volume_stop: 31832.29999999983,
  },
  {
    timestamp_start: '2022-01-01T20:34:31',
    timestamp_stop: '2022-01-01T20:34:36',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 139886,
    tank_volume_start: 31832.29999999983,
    tank_volume_stop: 31781.599999999828,
  },
  {
    timestamp_start: '2022-01-01T20:34:51',
    timestamp_stop: '2022-01-01T20:34:57',
    quantity: 61.3,
    vehicle: 'DEM333',
    id: 139888,
    tank_volume_start: 31781.599999999828,
    tank_volume_stop: 31720.29999999983,
  },
  {
    timestamp_start: '2022-01-01T20:35:12',
    timestamp_stop: '2022-01-01T20:35:18',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 139890,
    tank_volume_start: 31720.29999999983,
    tank_volume_stop: 31663.19999999983,
  },
  {
    timestamp_start: '2022-01-01T20:35:33',
    timestamp_stop: '2022-01-01T20:35:39',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 139892,
    tank_volume_start: 31663.19999999983,
    tank_volume_stop: 31608.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:35:54',
    timestamp_stop: '2022-01-01T20:35:59',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 139894,
    tank_volume_start: 31608.39999999983,
    tank_volume_stop: 31551.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:36:14',
    timestamp_stop: '2022-01-01T20:36:20',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 139896,
    tank_volume_start: 31551.49999999983,
    tank_volume_stop: 31498.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:36:35',
    timestamp_stop: '2022-01-01T20:36:40',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 139898,
    tank_volume_start: 31498.89999999983,
    tank_volume_stop: 31441.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:36:55',
    timestamp_stop: '2022-01-01T20:37:00',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 139900,
    tank_volume_start: 31441.59999999983,
    tank_volume_stop: 31390.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:37:15',
    timestamp_stop: '2022-01-01T20:37:21',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 139902,
    tank_volume_start: 31390.39999999983,
    tank_volume_stop: 31336.19999999983,
  },
  {
    timestamp_start: '2022-01-01T20:37:36',
    timestamp_stop: '2022-01-01T20:37:42',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 139904,
    tank_volume_start: 31336.19999999983,
    tank_volume_stop: 31278.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:37:57',
    timestamp_stop: '2022-01-01T20:38:02',
    quantity: 48.8,
    vehicle: 'DEM000',
    id: 139906,
    tank_volume_start: 31278.59999999983,
    tank_volume_stop: 31229.799999999832,
  },
  {
    timestamp_start: '2022-01-01T20:38:17',
    timestamp_stop: '2022-01-01T20:38:21',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 139908,
    tank_volume_start: 31229.799999999832,
    tank_volume_stop: 31180.299999999832,
  },
  {
    timestamp_start: '2022-01-01T20:38:36',
    timestamp_stop: '2022-01-01T20:38:42',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 139910,
    tank_volume_start: 31180.299999999832,
    tank_volume_stop: 31122.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:38:57',
    timestamp_stop: '2022-01-01T20:39:03',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 139912,
    tank_volume_start: 31122.39999999983,
    tank_volume_stop: 31068.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:39:18',
    timestamp_stop: '2022-01-01T20:39:24',
    quantity: 62.2,
    vehicle: 'DEM444',
    id: 139914,
    tank_volume_start: 31068.39999999983,
    tank_volume_stop: 31006.19999999983,
  },
  {
    timestamp_start: '2022-01-01T20:39:39',
    timestamp_stop: '2022-01-01T20:39:44',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 139916,
    tank_volume_start: 31006.19999999983,
    tank_volume_stop: 30950.69999999983,
  },
  {
    timestamp_start: '2022-01-01T20:39:59',
    timestamp_stop: '2022-01-01T20:40:05',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 139918,
    tank_volume_start: 30950.69999999983,
    tank_volume_stop: 30897.39999999983,
  },
  {
    timestamp_start: '2022-01-01T20:40:20',
    timestamp_stop: '2022-01-01T20:40:25',
    quantity: 47.9,
    vehicle: 'DEM555',
    id: 139920,
    tank_volume_start: 30897.39999999983,
    tank_volume_stop: 30849.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:40:40',
    timestamp_stop: '2022-01-01T20:40:45',
    quantity: 58.9,
    vehicle: 'DEM444',
    id: 139922,
    tank_volume_start: 30849.49999999983,
    tank_volume_stop: 30790.599999999828,
  },
  {
    timestamp_start: '2022-01-01T20:41:00',
    timestamp_stop: '2022-01-01T20:41:07',
    quantity: 62.1,
    vehicle: 'DEM666',
    id: 139924,
    tank_volume_start: 30790.599999999828,
    tank_volume_stop: 30728.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:41:22',
    timestamp_stop: '2022-01-01T20:41:26',
    quantity: 46.6,
    vehicle: 'DEM666',
    id: 139926,
    tank_volume_start: 30728.49999999983,
    tank_volume_stop: 30681.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:41:41',
    timestamp_stop: '2022-01-01T20:41:47',
    quantity: 59.4,
    vehicle: 'DEM777',
    id: 139928,
    tank_volume_start: 30681.89999999983,
    tank_volume_stop: 30622.49999999983,
  },
  {
    timestamp_start: '2022-01-01T20:42:02',
    timestamp_stop: '2022-01-01T20:42:08',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 139930,
    tank_volume_start: 30622.49999999983,
    tank_volume_stop: 30567.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:42:23',
    timestamp_stop: '2022-01-01T20:42:27',
    quantity: 47.8,
    vehicle: 'DEM888',
    id: 139932,
    tank_volume_start: 30567.89999999983,
    tank_volume_stop: 30520.09999999983,
  },
  {
    timestamp_start: '2022-01-01T20:42:42',
    timestamp_stop: '2022-01-01T20:42:48',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 139934,
    tank_volume_start: 30520.09999999983,
    tank_volume_stop: 30462.299999999832,
  },
  {
    timestamp_start: '2022-01-01T20:43:03',
    timestamp_stop: '2022-01-01T20:43:10',
    quantity: 63.0,
    vehicle: 'DEM666',
    id: 139936,
    tank_volume_start: 30462.299999999832,
    tank_volume_stop: 30399.299999999832,
  },
  {
    timestamp_start: '2022-01-01T20:43:25',
    timestamp_stop: '2022-01-01T20:43:30',
    quantity: 58.4,
    vehicle: 'DEM666',
    id: 139938,
    tank_volume_start: 30399.299999999832,
    tank_volume_stop: 30340.89999999983,
  },
  {
    timestamp_start: '2022-01-01T20:43:45',
    timestamp_stop: '2022-01-01T20:43:51',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 139940,
    tank_volume_start: 30340.89999999983,
    tank_volume_stop: 30287.59999999983,
  },
  {
    timestamp_start: '2022-01-01T20:44:06',
    timestamp_stop: '2022-01-01T20:44:11',
    quantity: 54.6,
    vehicle: 'DEM333',
    id: 139942,
    tank_volume_start: 30287.59999999983,
    tank_volume_stop: 30232.999999999833,
  },
  {
    timestamp_start: '2022-01-01T20:44:26',
    timestamp_stop: '2022-01-01T20:44:32',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 139944,
    tank_volume_start: 30232.999999999833,
    tank_volume_stop: 30174.699999999833,
  },
  {
    timestamp_start: '2022-01-01T20:44:47',
    timestamp_stop: '2022-01-01T20:44:53',
    quantity: 60.7,
    vehicle: 'DEM444',
    id: 139946,
    tank_volume_start: 30174.699999999833,
    tank_volume_stop: 30113.999999999833,
  },
  {
    timestamp_start: '2022-01-01T20:45:08',
    timestamp_stop: '2022-01-01T20:45:13',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 139948,
    tank_volume_start: 30113.999999999833,
    tank_volume_stop: 30060.999999999833,
  },
  {
    timestamp_start: '2022-01-01T20:45:28',
    timestamp_stop: '2022-01-01T20:45:35',
    quantity: 63.8,
    vehicle: 'DEM666',
    id: 139950,
    tank_volume_start: 30060.999999999833,
    tank_volume_stop: 29997.199999999833,
  },
  {
    timestamp_start: '2022-01-01T20:45:50',
    timestamp_stop: '2022-01-01T20:45:54',
    quantity: 46.3,
    vehicle: 'DEM444',
    id: 139952,
    tank_volume_start: 29997.199999999833,
    tank_volume_stop: 29950.899999999834,
  },
  {
    timestamp_start: '2022-01-01T20:46:09',
    timestamp_stop: '2022-01-01T20:46:16',
    quantity: 61.7,
    vehicle: 'DEM888',
    id: 139954,
    tank_volume_start: 29950.899999999834,
    tank_volume_stop: 29889.199999999833,
  },
  {
    timestamp_start: '2022-01-01T20:46:31',
    timestamp_stop: '2022-01-01T20:46:37',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 139956,
    tank_volume_start: 29889.199999999833,
    tank_volume_stop: 29825.599999999835,
  },
  {
    timestamp_start: '2022-01-01T20:46:52',
    timestamp_stop: '2022-01-01T20:46:58',
    quantity: 59.2,
    vehicle: 'DEM555',
    id: 139958,
    tank_volume_start: 29825.599999999835,
    tank_volume_stop: 29766.399999999834,
  },
  {
    timestamp_start: '2022-01-01T20:47:13',
    timestamp_stop: '2022-01-01T20:47:18',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 139960,
    tank_volume_start: 29766.399999999834,
    tank_volume_stop: 29715.799999999836,
  },
  {
    timestamp_start: '2022-01-01T20:47:33',
    timestamp_stop: '2022-01-01T20:47:39',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 139962,
    tank_volume_start: 29715.799999999836,
    tank_volume_stop: 29658.199999999837,
  },
  {
    timestamp_start: '2022-01-01T20:47:54',
    timestamp_stop: '2022-01-01T20:47:58',
    quantity: 45.8,
    vehicle: 'DEM111',
    id: 139964,
    tank_volume_start: 29658.199999999837,
    tank_volume_stop: 29612.399999999838,
  },
  {
    timestamp_start: '2022-01-01T20:48:13',
    timestamp_stop: '2022-01-01T20:48:19',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 139966,
    tank_volume_start: 29612.399999999838,
    tank_volume_stop: 29556.499999999836,
  },
  {
    timestamp_start: '2022-01-01T20:48:34',
    timestamp_stop: '2022-01-01T20:48:40',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 139968,
    tank_volume_start: 29556.499999999836,
    tank_volume_stop: 29498.999999999836,
  },
  {
    timestamp_start: '2022-01-01T20:48:55',
    timestamp_stop: '2022-01-01T20:49:00',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 139970,
    tank_volume_start: 29498.999999999836,
    tank_volume_stop: 29444.099999999835,
  },
  {
    timestamp_start: '2022-01-01T20:49:15',
    timestamp_stop: '2022-01-01T20:49:20',
    quantity: 46.9,
    vehicle: 'DEM555',
    id: 139972,
    tank_volume_start: 29444.099999999835,
    tank_volume_stop: 29397.199999999833,
  },
  {
    timestamp_start: '2022-01-01T20:49:35',
    timestamp_stop: '2022-01-01T20:49:40',
    quantity: 57.1,
    vehicle: 'DEM111',
    id: 139974,
    tank_volume_start: 29397.199999999833,
    tank_volume_stop: 29340.099999999835,
  },
  {
    timestamp_start: '2022-01-01T20:49:55',
    timestamp_stop: '2022-01-01T20:50:02',
    quantity: 64.7,
    vehicle: 'DEM444',
    id: 139976,
    tank_volume_start: 29340.099999999835,
    tank_volume_stop: 29275.399999999834,
  },
  {
    timestamp_start: '2022-01-01T20:50:17',
    timestamp_stop: '2022-01-01T20:50:23',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 139978,
    tank_volume_start: 29275.399999999834,
    tank_volume_stop: 29217.099999999835,
  },
  {
    timestamp_start: '2022-01-01T20:50:38',
    timestamp_stop: '2022-01-01T20:50:43',
    quantity: 48.5,
    vehicle: 'DEM444',
    id: 139980,
    tank_volume_start: 29217.099999999835,
    tank_volume_stop: 29168.599999999835,
  },
  {
    timestamp_start: '2022-01-01T20:50:58',
    timestamp_stop: '2022-01-01T20:51:03',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 139982,
    tank_volume_start: 29168.599999999835,
    tank_volume_stop: 29114.699999999833,
  },
  {
    timestamp_start: '2022-01-01T20:51:18',
    timestamp_stop: '2022-01-01T20:51:24',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 139984,
    tank_volume_start: 29114.699999999833,
    tank_volume_stop: 29059.899999999834,
  },
  {
    timestamp_start: '2022-01-01T20:51:39',
    timestamp_stop: '2022-01-01T20:51:45',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 139986,
    tank_volume_start: 29059.899999999834,
    tank_volume_stop: 28998.299999999836,
  },
  {
    timestamp_start: '2022-01-01T20:52:00',
    timestamp_stop: '2022-01-01T20:52:06',
    quantity: 59.8,
    vehicle: 'DEM444',
    id: 139988,
    tank_volume_start: 28998.299999999836,
    tank_volume_stop: 28938.499999999836,
  },
  {
    timestamp_start: '2022-01-01T20:52:21',
    timestamp_stop: '2022-01-01T20:52:26',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 139990,
    tank_volume_start: 28938.499999999836,
    tank_volume_stop: 28888.099999999835,
  },
  {
    timestamp_start: '2022-01-01T20:52:41',
    timestamp_stop: '2022-01-01T20:52:46',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 139992,
    tank_volume_start: 28888.099999999835,
    tank_volume_stop: 28831.099999999835,
  },
  {
    timestamp_start: '2022-01-01T20:53:01',
    timestamp_stop: '2022-01-01T20:53:07',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 139994,
    tank_volume_start: 28831.099999999835,
    tank_volume_stop: 28774.999999999836,
  },
  {
    timestamp_start: '2022-01-01T20:53:22',
    timestamp_stop: '2022-01-01T20:53:27',
    quantity: 50.5,
    vehicle: 'DEM777',
    id: 139996,
    tank_volume_start: 28774.999999999836,
    tank_volume_stop: 28724.499999999836,
  },
  {
    timestamp_start: '2022-01-01T20:53:42',
    timestamp_stop: '2022-01-01T20:53:48',
    quantity: 58.7,
    vehicle: 'DEM555',
    id: 139998,
    tank_volume_start: 28724.499999999836,
    tank_volume_stop: 28665.799999999836,
  },
  {
    timestamp_start: '2022-01-01T20:54:03',
    timestamp_stop: '2022-01-01T20:54:09',
    quantity: 60.5,
    vehicle: 'DEM111',
    id: 140000,
    tank_volume_start: 28665.799999999836,
    tank_volume_stop: 28605.299999999836,
  },
  {
    timestamp_start: '2022-01-01T20:54:24',
    timestamp_stop: '2022-01-01T20:54:29',
    quantity: 50.1,
    vehicle: 'DEM666',
    id: 140002,
    tank_volume_start: 28605.299999999836,
    tank_volume_stop: 28555.199999999837,
  },
  {
    timestamp_start: '2022-01-01T20:54:44',
    timestamp_stop: '2022-01-01T20:54:49',
    quantity: 50.1,
    vehicle: 'DEM777',
    id: 140004,
    tank_volume_start: 28555.199999999837,
    tank_volume_stop: 28505.09999999984,
  },
  {
    timestamp_start: '2022-01-01T20:55:04',
    timestamp_stop: '2022-01-01T20:55:10',
    quantity: 61.6,
    vehicle: 'DEM888',
    id: 140006,
    tank_volume_start: 28505.09999999984,
    tank_volume_stop: 28443.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:55:25',
    timestamp_stop: '2022-01-01T20:55:31',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 140008,
    tank_volume_start: 28443.49999999984,
    tank_volume_stop: 28385.79999999984,
  },
  {
    timestamp_start: '2022-01-01T20:55:46',
    timestamp_stop: '2022-01-01T20:55:51',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 140010,
    tank_volume_start: 28385.79999999984,
    tank_volume_stop: 28330.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:56:06',
    timestamp_stop: '2022-01-01T20:56:11',
    quantity: 49.0,
    vehicle: 'DEM888',
    id: 140012,
    tank_volume_start: 28330.59999999984,
    tank_volume_stop: 28281.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:56:26',
    timestamp_stop: '2022-01-01T20:56:31',
    quantity: 49.1,
    vehicle: 'DEM666',
    id: 140014,
    tank_volume_start: 28281.59999999984,
    tank_volume_stop: 28232.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:56:46',
    timestamp_stop: '2022-01-01T20:56:51',
    quantity: 52.0,
    vehicle: 'DEM000',
    id: 140016,
    tank_volume_start: 28232.49999999984,
    tank_volume_stop: 28180.49999999984,
  },
  {
    timestamp_start: '2022-01-01T20:57:06',
    timestamp_stop: '2022-01-01T20:57:13',
    quantity: 60.7,
    vehicle: 'DEM333',
    id: 140018,
    tank_volume_start: 28180.49999999984,
    tank_volume_stop: 28119.79999999984,
  },
  {
    timestamp_start: '2022-01-01T20:57:28',
    timestamp_stop: '2022-01-01T20:57:34',
    quantity: 59.9,
    vehicle: 'DEM777',
    id: 140020,
    tank_volume_start: 28119.79999999984,
    tank_volume_stop: 28059.899999999838,
  },
  {
    timestamp_start: '2022-01-01T20:57:49',
    timestamp_stop: '2022-01-01T20:57:54',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 140022,
    tank_volume_start: 28059.899999999838,
    tank_volume_stop: 28000.899999999838,
  },
  {
    timestamp_start: '2022-01-01T20:58:09',
    timestamp_stop: '2022-01-01T20:58:15',
    quantity: 51.5,
    vehicle: 'DEM444',
    id: 140024,
    tank_volume_start: 28000.899999999838,
    tank_volume_stop: 27949.399999999838,
  },
  {
    timestamp_start: '2022-01-01T20:58:30',
    timestamp_stop: '2022-01-01T20:58:35',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 140026,
    tank_volume_start: 27949.399999999838,
    tank_volume_stop: 27898.29999999984,
  },
  {
    timestamp_start: '2022-01-01T20:58:50',
    timestamp_stop: '2022-01-01T20:58:56',
    quantity: 61.7,
    vehicle: 'DEM555',
    id: 140028,
    tank_volume_start: 27898.29999999984,
    tank_volume_stop: 27836.59999999984,
  },
  {
    timestamp_start: '2022-01-01T20:59:11',
    timestamp_stop: '2022-01-01T20:59:16',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 140030,
    tank_volume_start: 27836.59999999984,
    tank_volume_stop: 27780.199999999837,
  },
  {
    timestamp_start: '2022-01-01T20:59:31',
    timestamp_stop: '2022-01-01T20:59:37',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 140032,
    tank_volume_start: 27780.199999999837,
    tank_volume_stop: 27728.09999999984,
  },
  {
    timestamp_start: '2022-01-01T20:59:52',
    timestamp_stop: '2022-01-01T20:59:57',
    quantity: 49.2,
    vehicle: 'DEM000',
    id: 140034,
    tank_volume_start: 27728.09999999984,
    tank_volume_stop: 27678.899999999838,
  },
  {
    timestamp_start: '2022-01-01T21:00:12',
    timestamp_stop: '2022-01-01T21:00:17',
    quantity: 58.8,
    vehicle: 'DEM444',
    id: 140036,
    tank_volume_start: 27678.899999999838,
    tank_volume_stop: 27620.09999999984,
  },
  {
    timestamp_start: '2022-01-01T21:00:32',
    timestamp_stop: '2022-01-01T21:00:39',
    quantity: 60.7,
    vehicle: 'DEM444',
    id: 140038,
    tank_volume_start: 27620.09999999984,
    tank_volume_stop: 27559.399999999838,
  },
  {
    timestamp_start: '2022-01-01T21:00:54',
    timestamp_stop: '2022-01-01T21:00:59',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 140040,
    tank_volume_start: 27559.399999999838,
    tank_volume_stop: 27509.899999999838,
  },
  {
    timestamp_start: '2022-01-01T21:01:14',
    timestamp_stop: '2022-01-01T21:01:19',
    quantity: 51.3,
    vehicle: 'DEM777',
    id: 140042,
    tank_volume_start: 27509.899999999838,
    tank_volume_stop: 27458.59999999984,
  },
  {
    timestamp_start: '2022-01-01T21:01:34',
    timestamp_stop: '2022-01-01T21:01:39',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 140044,
    tank_volume_start: 27458.59999999984,
    tank_volume_stop: 27407.49999999984,
  },
  {
    timestamp_start: '2022-01-01T21:01:54',
    timestamp_stop: '2022-01-01T21:02:00',
    quantity: 63.4,
    vehicle: 'DEM555',
    id: 140046,
    tank_volume_start: 27407.49999999984,
    tank_volume_stop: 27344.09999999984,
  },
  {
    timestamp_start: '2022-01-01T21:02:15',
    timestamp_stop: '2022-01-01T21:02:21',
    quantity: 57.3,
    vehicle: 'DEM333',
    id: 140048,
    tank_volume_start: 27344.09999999984,
    tank_volume_stop: 27286.79999999984,
  },
  {
    timestamp_start: '2022-01-01T21:02:36',
    timestamp_stop: '2022-01-01T21:02:41',
    quantity: 47.4,
    vehicle: 'DEM555',
    id: 140050,
    tank_volume_start: 27286.79999999984,
    tank_volume_stop: 27239.399999999838,
  },
  {
    timestamp_start: '2022-01-01T21:02:56',
    timestamp_stop: '2022-01-01T21:03:01',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 140052,
    tank_volume_start: 27239.399999999838,
    tank_volume_stop: 27182.399999999838,
  },
  {
    timestamp_start: '2022-01-01T21:03:16',
    timestamp_stop: '2022-01-01T21:03:22',
    quantity: 61.1,
    vehicle: 'DEM555',
    id: 140054,
    tank_volume_start: 27182.399999999838,
    tank_volume_stop: 27121.29999999984,
  },
  {
    timestamp_start: '2022-01-01T21:03:37',
    timestamp_stop: '2022-01-01T21:03:43',
    quantity: 52.5,
    vehicle: 'DEM888',
    id: 140056,
    tank_volume_start: 27121.29999999984,
    tank_volume_stop: 27068.79999999984,
  },
  {
    timestamp_start: '2022-01-01T21:03:58',
    timestamp_stop: '2022-01-01T21:04:03',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 140058,
    tank_volume_start: 27068.79999999984,
    tank_volume_stop: 27013.09999999984,
  },
  {
    timestamp_start: '2022-01-01T21:04:18',
    timestamp_stop: '2022-01-01T21:04:24',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 140060,
    tank_volume_start: 27013.09999999984,
    tank_volume_stop: 26954.399999999838,
  },
  {
    timestamp_start: '2022-01-01T21:04:39',
    timestamp_stop: '2022-01-01T21:04:44',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 140062,
    tank_volume_start: 26954.399999999838,
    tank_volume_stop: 26901.999999999836,
  },
  {
    timestamp_start: '2022-01-01T21:04:59',
    timestamp_stop: '2022-01-01T21:05:05',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 140064,
    tank_volume_start: 26901.999999999836,
    tank_volume_stop: 26845.599999999835,
  },
  {
    timestamp_start: '2022-01-01T21:05:20',
    timestamp_stop: '2022-01-01T21:05:26',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 140066,
    tank_volume_start: 26845.599999999835,
    tank_volume_stop: 26788.599999999835,
  },
  {
    timestamp_start: '2022-01-01T21:05:41',
    timestamp_stop: '2022-01-01T21:05:46',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 140068,
    tank_volume_start: 26788.599999999835,
    tank_volume_stop: 26732.099999999835,
  },
  {
    timestamp_start: '2022-01-01T21:06:01',
    timestamp_stop: '2022-01-01T21:06:07',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 140070,
    tank_volume_start: 26732.099999999835,
    tank_volume_stop: 26672.699999999833,
  },
  {
    timestamp_start: '2022-01-01T21:06:22',
    timestamp_stop: '2022-01-01T21:06:27',
    quantity: 49.8,
    vehicle: 'DEM333',
    id: 140072,
    tank_volume_start: 26672.699999999833,
    tank_volume_stop: 26622.899999999834,
  },
  {
    timestamp_start: '2022-01-01T21:06:42',
    timestamp_stop: '2022-01-01T21:06:48',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 140074,
    tank_volume_start: 26622.899999999834,
    tank_volume_stop: 26563.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:07:03',
    timestamp_stop: '2022-01-01T21:07:08',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 140076,
    tank_volume_start: 26563.299999999836,
    tank_volume_stop: 26510.599999999835,
  },
  {
    timestamp_start: '2022-01-01T21:07:23',
    timestamp_stop: '2022-01-01T21:07:29',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 140078,
    tank_volume_start: 26510.599999999835,
    tank_volume_stop: 26454.399999999834,
  },
  {
    timestamp_start: '2022-01-01T21:07:44',
    timestamp_stop: '2022-01-01T21:07:50',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 140080,
    tank_volume_start: 26454.399999999834,
    tank_volume_stop: 26394.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:08:05',
    timestamp_stop: '2022-01-01T21:08:10',
    quantity: 49.4,
    vehicle: 'DEM111',
    id: 140082,
    tank_volume_start: 26394.299999999836,
    tank_volume_stop: 26344.899999999834,
  },
  {
    timestamp_start: '2022-01-01T21:08:25',
    timestamp_stop: '2022-01-01T21:08:31',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 140084,
    tank_volume_start: 26344.899999999834,
    tank_volume_stop: 26284.399999999834,
  },
  {
    timestamp_start: '2022-01-01T21:08:46',
    timestamp_stop: '2022-01-01T21:08:52',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 140086,
    tank_volume_start: 26284.399999999834,
    tank_volume_stop: 26225.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:09:07',
    timestamp_stop: '2022-01-01T21:09:13',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 140088,
    tank_volume_start: 26225.299999999836,
    tank_volume_stop: 26167.099999999835,
  },
  {
    timestamp_start: '2022-01-01T21:09:28',
    timestamp_stop: '2022-01-01T21:09:33',
    quantity: 56.7,
    vehicle: 'DEM444',
    id: 140090,
    tank_volume_start: 26167.099999999835,
    tank_volume_stop: 26110.399999999834,
  },
  {
    timestamp_start: '2022-01-01T21:09:48',
    timestamp_stop: '2022-01-01T21:09:54',
    quantity: 51.5,
    vehicle: 'DEM111',
    id: 140092,
    tank_volume_start: 26110.399999999834,
    tank_volume_stop: 26058.899999999834,
  },
  {
    timestamp_start: '2022-01-01T21:10:09',
    timestamp_stop: '2022-01-01T21:10:14',
    quantity: 51.6,
    vehicle: 'DEM555',
    id: 140094,
    tank_volume_start: 26058.899999999834,
    tank_volume_stop: 26007.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:10:29',
    timestamp_stop: '2022-01-01T21:10:33',
    quantity: 45.7,
    vehicle: 'DEM777',
    id: 140096,
    tank_volume_start: 26007.299999999836,
    tank_volume_stop: 25961.599999999835,
  },
  {
    timestamp_start: '2022-01-01T21:10:48',
    timestamp_stop: '2022-01-01T21:10:54',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 140098,
    tank_volume_start: 25961.599999999835,
    tank_volume_stop: 25902.899999999834,
  },
  {
    timestamp_start: '2022-01-01T21:11:09',
    timestamp_stop: '2022-01-01T21:11:15',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 140100,
    tank_volume_start: 25902.899999999834,
    tank_volume_stop: 25847.199999999833,
  },
  {
    timestamp_start: '2022-01-01T21:11:30',
    timestamp_stop: '2022-01-01T21:11:35',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 140102,
    tank_volume_start: 25847.199999999833,
    tank_volume_stop: 25791.499999999833,
  },
  {
    timestamp_start: '2022-01-01T21:11:50',
    timestamp_stop: '2022-01-01T21:11:56',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 140104,
    tank_volume_start: 25791.499999999833,
    tank_volume_stop: 25737.199999999833,
  },
  {
    timestamp_start: '2022-01-01T21:12:11',
    timestamp_stop: '2022-01-01T21:12:17',
    quantity: 58.2,
    vehicle: 'DEM111',
    id: 140106,
    tank_volume_start: 25737.199999999833,
    tank_volume_stop: 25678.999999999833,
  },
  {
    timestamp_start: '2022-01-01T21:12:32',
    timestamp_stop: '2022-01-01T21:12:38',
    quantity: 59.3,
    vehicle: 'DEM111',
    id: 140108,
    tank_volume_start: 25678.999999999833,
    tank_volume_stop: 25619.699999999833,
  },
  {
    timestamp_start: '2022-01-01T21:12:53',
    timestamp_stop: '2022-01-01T21:12:58',
    quantity: 51.4,
    vehicle: 'DEM888',
    id: 140110,
    tank_volume_start: 25619.699999999833,
    tank_volume_stop: 25568.299999999832,
  },
  {
    timestamp_start: '2022-01-01T21:13:13',
    timestamp_stop: '2022-01-01T21:13:18',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 140112,
    tank_volume_start: 25568.299999999832,
    tank_volume_stop: 25511.699999999833,
  },
  {
    timestamp_start: '2022-01-01T21:13:33',
    timestamp_stop: '2022-01-01T21:13:39',
    quantity: 52.8,
    vehicle: 'DEM333',
    id: 140114,
    tank_volume_start: 25511.699999999833,
    tank_volume_stop: 25458.899999999834,
  },
  {
    timestamp_start: '2022-01-01T21:13:54',
    timestamp_stop: '2022-01-01T21:13:59',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 140116,
    tank_volume_start: 25458.899999999834,
    tank_volume_stop: 25402.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:14:14',
    timestamp_stop: '2022-01-01T21:14:20',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 140118,
    tank_volume_start: 25402.299999999836,
    tank_volume_stop: 25343.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:14:35',
    timestamp_stop: '2022-01-01T21:14:40',
    quantity: 45.8,
    vehicle: 'DEM111',
    id: 140120,
    tank_volume_start: 25343.299999999836,
    tank_volume_stop: 25297.499999999836,
  },
  {
    timestamp_start: '2022-01-01T21:14:55',
    timestamp_stop: '2022-01-01T21:15:00',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 140122,
    tank_volume_start: 25297.499999999836,
    tank_volume_stop: 25241.199999999837,
  },
  {
    timestamp_start: '2022-01-01T21:15:15',
    timestamp_stop: '2022-01-01T21:15:21',
    quantity: 54.7,
    vehicle: 'DEM111',
    id: 140124,
    tank_volume_start: 25241.199999999837,
    tank_volume_stop: 25186.499999999836,
  },
  {
    timestamp_start: '2022-01-01T21:15:36',
    timestamp_stop: '2022-01-01T21:15:41',
    quantity: 51.0,
    vehicle: 'DEM333',
    id: 140126,
    tank_volume_start: 25186.499999999836,
    tank_volume_stop: 25135.499999999836,
  },
  {
    timestamp_start: '2022-01-01T21:15:56',
    timestamp_stop: '2022-01-01T21:16:02',
    quantity: 60.7,
    vehicle: 'DEM333',
    id: 140128,
    tank_volume_start: 25135.499999999836,
    tank_volume_stop: 25074.799999999836,
  },
  {
    timestamp_start: '2022-01-01T21:16:17',
    timestamp_stop: '2022-01-01T21:16:22',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 140130,
    tank_volume_start: 25074.799999999836,
    tank_volume_stop: 25020.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:16:37',
    timestamp_stop: '2022-01-01T21:16:43',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 140132,
    tank_volume_start: 25020.299999999836,
    tank_volume_stop: 24962.499999999836,
  },
  {
    timestamp_start: '2022-01-01T21:16:58',
    timestamp_stop: '2022-01-01T21:17:04',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 140134,
    tank_volume_start: 24962.499999999836,
    tank_volume_stop: 24909.999999999836,
  },
  {
    timestamp_start: '2022-01-01T21:17:19',
    timestamp_stop: '2022-01-01T21:17:24',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 140136,
    tank_volume_start: 24909.999999999836,
    tank_volume_stop: 24852.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:17:39',
    timestamp_stop: '2022-01-01T21:17:45',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 140138,
    tank_volume_start: 24852.299999999836,
    tank_volume_stop: 24797.299999999836,
  },
  {
    timestamp_start: '2022-01-01T21:18:00',
    timestamp_stop: '2022-01-01T21:18:05',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 140140,
    tank_volume_start: 24797.299999999836,
    tank_volume_stop: 24742.799999999836,
  },
  {
    timestamp_start: '2022-01-01T21:18:20',
    timestamp_stop: '2022-01-01T21:18:25',
    quantity: 49.8,
    vehicle: 'DEM333',
    id: 140142,
    tank_volume_start: 24742.799999999836,
    tank_volume_stop: 24692.999999999836,
  },
  {
    timestamp_start: '2022-01-01T21:18:40',
    timestamp_stop: '2022-01-01T21:18:46',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 140144,
    tank_volume_start: 24692.999999999836,
    tank_volume_stop: 24633.899999999838,
  },
  {
    timestamp_start: '2022-01-01T21:19:01',
    timestamp_stop: '2022-01-01T21:19:07',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 140146,
    tank_volume_start: 24633.899999999838,
    tank_volume_stop: 24576.09999999984,
  },
  {
    timestamp_start: '2022-01-01T21:19:22',
    timestamp_stop: '2022-01-01T21:19:27',
    quantity: 46.9,
    vehicle: 'DEM111',
    id: 140148,
    tank_volume_start: 24576.09999999984,
    tank_volume_stop: 24529.199999999837,
  },
  {
    timestamp_start: '2022-01-01T21:19:42',
    timestamp_stop: '2022-01-01T21:19:47',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 140150,
    tank_volume_start: 24529.199999999837,
    tank_volume_stop: 24476.59999999984,
  },
  {
    timestamp_start: '2022-01-01T21:20:02',
    timestamp_stop: '2022-01-01T21:20:08',
    quantity: 58.4,
    vehicle: 'DEM888',
    id: 140152,
    tank_volume_start: 24476.59999999984,
    tank_volume_stop: 24418.199999999837,
  },
  {
    timestamp_start: '2022-01-01T21:20:23',
    timestamp_stop: '2022-01-01T21:20:28',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 140154,
    tank_volume_start: 24418.199999999837,
    tank_volume_stop: 24363.399999999838,
  },
  {
    timestamp_start: '2022-01-01T21:20:43',
    timestamp_stop: '2022-01-01T21:20:49',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 140156,
    tank_volume_start: 24363.399999999838,
    tank_volume_stop: 24305.79999999984,
  },
  {
    timestamp_start: '2022-01-01T21:21:04',
    timestamp_stop: '2022-01-01T21:21:10',
    quantity: 59.2,
    vehicle: 'DEM888',
    id: 140158,
    tank_volume_start: 24305.79999999984,
    tank_volume_stop: 24246.59999999984,
  },
  {
    timestamp_start: '2022-01-01T21:21:25',
    timestamp_stop: '2022-01-01T21:21:31',
    quantity: 62.6,
    vehicle: 'DEM000',
    id: 140160,
    tank_volume_start: 24246.59999999984,
    tank_volume_stop: 24183.99999999984,
  },
  {
    timestamp_start: '2022-01-01T21:21:46',
    timestamp_stop: '2022-01-01T21:21:51',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 140163,
    tank_volume_start: 24183.99999999984,
    tank_volume_stop: 24136.29999999984,
  },
  {
    timestamp_start: '2022-01-01T21:22:06',
    timestamp_stop: '2022-01-01T21:22:12',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 140165,
    tank_volume_start: 24136.29999999984,
    tank_volume_stop: 24076.69999999984,
  },
  {
    timestamp_start: '2022-01-01T21:22:27',
    timestamp_stop: '2022-01-01T21:22:32',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 140167,
    tank_volume_start: 24076.69999999984,
    tank_volume_stop: 24022.79999999984,
  },
  {
    timestamp_start: '2022-01-01T21:22:47',
    timestamp_stop: '2022-01-01T21:22:53',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 140169,
    tank_volume_start: 24022.79999999984,
    tank_volume_stop: 23969.29999999984,
  },
  {
    timestamp_start: '2022-01-01T21:23:08',
    timestamp_stop: '2022-01-01T21:23:13',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 140171,
    tank_volume_start: 23969.29999999984,
    tank_volume_stop: 23913.49999999984,
  },
  {
    timestamp_start: '2022-01-01T21:23:28',
    timestamp_stop: '2022-01-01T21:23:34',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 140173,
    tank_volume_start: 23913.49999999984,
    tank_volume_stop: 23858.39999999984,
  },
  {
    timestamp_start: '2022-01-01T21:23:49',
    timestamp_stop: '2022-01-01T21:23:55',
    quantity: 64.6,
    vehicle: 'DEM888',
    id: 140175,
    tank_volume_start: 23858.39999999984,
    tank_volume_stop: 23793.799999999843,
  },
  {
    timestamp_start: '2022-01-01T21:24:10',
    timestamp_stop: '2022-01-01T21:24:15',
    quantity: 46.8,
    vehicle: 'DEM333',
    id: 140177,
    tank_volume_start: 23793.799999999843,
    tank_volume_stop: 23746.999999999844,
  },
  {
    timestamp_start: '2022-01-01T21:24:30',
    timestamp_stop: '2022-01-01T21:24:36',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 140179,
    tank_volume_start: 23746.999999999844,
    tank_volume_stop: 23689.999999999844,
  },
  {
    timestamp_start: '2022-01-01T21:24:51',
    timestamp_stop: '2022-01-01T21:24:56',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 140181,
    tank_volume_start: 23689.999999999844,
    tank_volume_stop: 23635.399999999845,
  },
  {
    timestamp_start: '2022-01-01T21:25:11',
    timestamp_stop: '2022-01-01T21:25:16',
    quantity: 47.1,
    vehicle: 'DEM444',
    id: 140183,
    tank_volume_start: 23635.399999999845,
    tank_volume_stop: 23588.299999999846,
  },
  {
    timestamp_start: '2022-01-01T21:25:31',
    timestamp_stop: '2022-01-01T21:25:37',
    quantity: 60.1,
    vehicle: 'DEM000',
    id: 140185,
    tank_volume_start: 23588.299999999846,
    tank_volume_stop: 23528.199999999848,
  },
  {
    timestamp_start: '2022-01-01T21:25:52',
    timestamp_stop: '2022-01-01T21:25:57',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 140187,
    tank_volume_start: 23528.199999999848,
    tank_volume_stop: 23473.699999999848,
  },
  {
    timestamp_start: '2022-01-01T21:26:12',
    timestamp_stop: '2022-01-01T21:26:18',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 140189,
    tank_volume_start: 23473.699999999848,
    tank_volume_stop: 23418.799999999846,
  },
  {
    timestamp_start: '2022-01-01T21:26:33',
    timestamp_stop: '2022-01-01T21:26:38',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 140191,
    tank_volume_start: 23418.799999999846,
    tank_volume_stop: 23362.799999999846,
  },
  {
    timestamp_start: '2022-01-01T21:26:53',
    timestamp_stop: '2022-01-01T21:26:59',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 140193,
    tank_volume_start: 23362.799999999846,
    tank_volume_stop: 23307.999999999847,
  },
  {
    timestamp_start: '2022-01-01T21:27:14',
    timestamp_stop: '2022-01-01T21:27:18',
    quantity: 46.7,
    vehicle: 'DEM333',
    id: 140195,
    tank_volume_start: 23307.999999999847,
    tank_volume_stop: 23261.299999999846,
  },
  {
    timestamp_start: '2022-01-01T21:27:33',
    timestamp_stop: '2022-01-01T21:27:39',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 140197,
    tank_volume_start: 23261.299999999846,
    tank_volume_stop: 23201.899999999845,
  },
  {
    timestamp_start: '2022-01-01T21:27:54',
    timestamp_stop: '2022-01-01T21:28:00',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 140199,
    tank_volume_start: 23201.899999999845,
    tank_volume_stop: 23149.499999999844,
  },
  {
    timestamp_start: '2022-01-01T21:28:15',
    timestamp_stop: '2022-01-01T21:28:19',
    quantity: 45.1,
    vehicle: 'DEM111',
    id: 140201,
    tank_volume_start: 23149.499999999844,
    tank_volume_stop: 23104.399999999845,
  },
  {
    timestamp_start: '2022-01-01T21:28:34',
    timestamp_stop: '2022-01-01T21:28:39',
    quantity: 49.8,
    vehicle: 'DEM666',
    id: 140203,
    tank_volume_start: 23104.399999999845,
    tank_volume_stop: 23054.599999999846,
  },
  {
    timestamp_start: '2022-01-01T21:28:54',
    timestamp_stop: '2022-01-01T21:29:00',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 140205,
    tank_volume_start: 23054.599999999846,
    tank_volume_stop: 22999.899999999845,
  },
  {
    timestamp_start: '2022-01-01T21:29:15',
    timestamp_stop: '2022-01-01T21:29:21',
    quantity: 61.9,
    vehicle: 'DEM111',
    id: 140207,
    tank_volume_start: 22999.899999999845,
    tank_volume_stop: 22937.999999999844,
  },
  {
    timestamp_start: '2022-01-01T21:29:36',
    timestamp_stop: '2022-01-01T21:29:41',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 140209,
    tank_volume_start: 22937.999999999844,
    tank_volume_stop: 22882.399999999845,
  },
  {
    timestamp_start: '2022-01-01T21:29:56',
    timestamp_stop: '2022-01-01T21:30:02',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 140211,
    tank_volume_start: 22882.399999999845,
    tank_volume_stop: 22827.199999999844,
  },
  {
    timestamp_start: '2022-01-01T21:30:17',
    timestamp_stop: '2022-01-01T21:30:22',
    quantity: 49.1,
    vehicle: 'DEM444',
    id: 140213,
    tank_volume_start: 22827.199999999844,
    tank_volume_stop: 22778.099999999846,
  },
  {
    timestamp_start: '2022-01-01T21:30:37',
    timestamp_stop: '2022-01-01T21:30:43',
    quantity: 62.6,
    vehicle: 'DEM333',
    id: 140215,
    tank_volume_start: 22778.099999999846,
    tank_volume_stop: 22715.499999999847,
  },
  {
    timestamp_start: '2022-01-01T21:30:58',
    timestamp_stop: '2022-01-01T21:31:04',
    quantity: 59.6,
    vehicle: 'DEM666',
    id: 140217,
    tank_volume_start: 22715.499999999847,
    tank_volume_stop: 22655.89999999985,
  },
  {
    timestamp_start: '2022-01-01T21:31:19',
    timestamp_stop: '2022-01-01T21:31:24',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 140219,
    tank_volume_start: 22655.89999999985,
    tank_volume_stop: 22601.79999999985,
  },
  {
    timestamp_start: '2022-01-01T21:31:39',
    timestamp_stop: '2022-01-01T21:31:44',
    quantity: 49.6,
    vehicle: 'DEM111',
    id: 140221,
    tank_volume_start: 22601.79999999985,
    tank_volume_stop: 22552.19999999985,
  },
  {
    timestamp_start: '2022-01-01T21:31:59',
    timestamp_stop: '2022-01-01T21:32:05',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 140223,
    tank_volume_start: 22552.19999999985,
    tank_volume_stop: 22498.899999999852,
  },
  {
    timestamp_start: '2022-01-01T21:32:20',
    timestamp_stop: '2022-01-01T21:32:26',
    quantity: 61.5,
    vehicle: 'DEM777',
    id: 140225,
    tank_volume_start: 22498.899999999852,
    tank_volume_stop: 22437.399999999852,
  },
  {
    timestamp_start: '2022-01-01T21:32:41',
    timestamp_stop: '2022-01-01T21:32:47',
    quantity: 58.5,
    vehicle: 'DEM000',
    id: 140227,
    tank_volume_start: 22437.399999999852,
    tank_volume_stop: 22378.899999999852,
  },
  {
    timestamp_start: '2022-01-01T21:33:02',
    timestamp_stop: '2022-01-01T21:33:07',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 140229,
    tank_volume_start: 22378.899999999852,
    tank_volume_stop: 22324.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:33:22',
    timestamp_stop: '2022-01-01T21:33:28',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 140231,
    tank_volume_start: 22324.99999999985,
    tank_volume_stop: 22263.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:33:43',
    timestamp_stop: '2022-01-01T21:33:49',
    quantity: 60.8,
    vehicle: 'DEM555',
    id: 140233,
    tank_volume_start: 22263.99999999985,
    tank_volume_stop: 22203.19999999985,
  },
  {
    timestamp_start: '2022-01-01T21:34:04',
    timestamp_stop: '2022-01-01T21:34:10',
    quantity: 62.1,
    vehicle: 'DEM111',
    id: 140235,
    tank_volume_start: 22203.19999999985,
    tank_volume_stop: 22141.099999999853,
  },
  {
    timestamp_start: '2022-01-01T21:34:25',
    timestamp_stop: '2022-01-01T21:34:31',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 140237,
    tank_volume_start: 22141.099999999853,
    tank_volume_stop: 22087.499999999854,
  },
  {
    timestamp_start: '2022-01-01T21:34:46',
    timestamp_stop: '2022-01-01T21:34:51',
    quantity: 51.5,
    vehicle: 'DEM111',
    id: 140239,
    tank_volume_start: 22087.499999999854,
    tank_volume_stop: 22035.999999999854,
  },
  {
    timestamp_start: '2022-01-01T21:35:06',
    timestamp_stop: '2022-01-01T21:35:11',
    quantity: 52.1,
    vehicle: 'DEM222',
    id: 140241,
    tank_volume_start: 22035.999999999854,
    tank_volume_stop: 21983.899999999856,
  },
  {
    timestamp_start: '2022-01-01T21:35:26',
    timestamp_stop: '2022-01-01T21:35:32',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 140243,
    tank_volume_start: 21983.899999999856,
    tank_volume_stop: 21926.199999999855,
  },
  {
    timestamp_start: '2022-01-01T21:35:47',
    timestamp_stop: '2022-01-01T21:35:53',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 140245,
    tank_volume_start: 21926.199999999855,
    tank_volume_stop: 21867.399999999856,
  },
  {
    timestamp_start: '2022-01-01T21:36:08',
    timestamp_stop: '2022-01-01T21:36:13',
    quantity: 48.8,
    vehicle: 'DEM333',
    id: 140247,
    tank_volume_start: 21867.399999999856,
    tank_volume_stop: 21818.599999999857,
  },
  {
    timestamp_start: '2022-01-01T21:36:28',
    timestamp_stop: '2022-01-01T21:36:32',
    quantity: 48.0,
    vehicle: 'DEM222',
    id: 140249,
    tank_volume_start: 21818.599999999857,
    tank_volume_stop: 21770.599999999857,
  },
  {
    timestamp_start: '2022-01-01T21:36:47',
    timestamp_stop: '2022-01-01T21:36:54',
    quantity: 63.6,
    vehicle: 'DEM222',
    id: 140251,
    tank_volume_start: 21770.599999999857,
    tank_volume_stop: 21706.999999999858,
  },
  {
    timestamp_start: '2022-01-01T21:37:09',
    timestamp_stop: '2022-01-01T21:37:14',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 140253,
    tank_volume_start: 21706.999999999858,
    tank_volume_stop: 21654.89999999986,
  },
  {
    timestamp_start: '2022-01-01T21:37:29',
    timestamp_stop: '2022-01-01T21:37:35',
    quantity: 59.0,
    vehicle: 'DEM444',
    id: 140255,
    tank_volume_start: 21654.89999999986,
    tank_volume_stop: 21595.89999999986,
  },
  {
    timestamp_start: '2022-01-01T21:37:50',
    timestamp_stop: '2022-01-01T21:37:56',
    quantity: 61.8,
    vehicle: 'DEM111',
    id: 140257,
    tank_volume_start: 21595.89999999986,
    tank_volume_stop: 21534.09999999986,
  },
  {
    timestamp_start: '2022-01-01T21:38:11',
    timestamp_stop: '2022-01-01T21:38:17',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 140259,
    tank_volume_start: 21534.09999999986,
    tank_volume_stop: 21478.39999999986,
  },
  {
    timestamp_start: '2022-01-01T21:38:32',
    timestamp_stop: '2022-01-01T21:38:37',
    quantity: 49.0,
    vehicle: 'DEM333',
    id: 140261,
    tank_volume_start: 21478.39999999986,
    tank_volume_stop: 21429.39999999986,
  },
  {
    timestamp_start: '2022-01-01T21:38:52',
    timestamp_stop: '2022-01-01T21:38:57',
    quantity: 49.6,
    vehicle: 'DEM666',
    id: 140263,
    tank_volume_start: 21429.39999999986,
    tank_volume_stop: 21379.79999999986,
  },
  {
    timestamp_start: '2022-01-01T21:39:12',
    timestamp_stop: '2022-01-01T21:39:18',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 140265,
    tank_volume_start: 21379.79999999986,
    tank_volume_stop: 21316.89999999986,
  },
  {
    timestamp_start: '2022-01-01T21:39:33',
    timestamp_stop: '2022-01-01T21:39:38',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 140267,
    tank_volume_start: 21316.89999999986,
    tank_volume_stop: 21264.19999999986,
  },
  {
    timestamp_start: '2022-01-01T21:39:53',
    timestamp_stop: '2022-01-01T21:39:58',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 140269,
    tank_volume_start: 21264.19999999986,
    tank_volume_stop: 21210.09999999986,
  },
  {
    timestamp_start: '2022-01-01T21:40:13',
    timestamp_stop: '2022-01-01T21:40:19',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 140271,
    tank_volume_start: 21210.09999999986,
    tank_volume_stop: 21154.99999999986,
  },
  {
    timestamp_start: '2022-01-01T21:40:34',
    timestamp_stop: '2022-01-01T21:40:40',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 140273,
    tank_volume_start: 21154.99999999986,
    tank_volume_stop: 21099.09999999986,
  },
  {
    timestamp_start: '2022-01-01T21:40:55',
    timestamp_stop: '2022-01-01T21:41:00',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 140275,
    tank_volume_start: 21099.09999999986,
    tank_volume_stop: 21044.09999999986,
  },
  {
    timestamp_start: '2022-01-01T21:41:15',
    timestamp_stop: '2022-01-01T21:41:21',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 140277,
    tank_volume_start: 21044.09999999986,
    tank_volume_stop: 20989.49999999986,
  },
  {
    timestamp_start: '2022-01-01T21:41:36',
    timestamp_stop: '2022-01-01T21:41:41',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 140279,
    tank_volume_start: 20989.49999999986,
    tank_volume_stop: 20933.49999999986,
  },
  {
    timestamp_start: '2022-01-01T21:41:56',
    timestamp_stop: '2022-01-01T21:42:02',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 140281,
    tank_volume_start: 20933.49999999986,
    tank_volume_stop: 20876.199999999862,
  },
  {
    timestamp_start: '2022-01-01T21:42:17',
    timestamp_stop: '2022-01-01T21:42:23',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 140283,
    tank_volume_start: 20876.199999999862,
    tank_volume_stop: 20819.199999999862,
  },
  {
    timestamp_start: '2022-01-01T21:42:38',
    timestamp_stop: '2022-01-01T21:42:43',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 140285,
    tank_volume_start: 20819.199999999862,
    tank_volume_stop: 20768.99999999986,
  },
  {
    timestamp_start: '2022-01-01T21:42:58',
    timestamp_stop: '2022-01-01T21:43:03',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 140287,
    tank_volume_start: 20768.99999999986,
    tank_volume_stop: 20716.49999999986,
  },
  {
    timestamp_start: '2022-01-01T21:43:18',
    timestamp_stop: '2022-01-01T21:43:24',
    quantity: 63.9,
    vehicle: 'DEM777',
    id: 140289,
    tank_volume_start: 20716.49999999986,
    tank_volume_stop: 20652.59999999986,
  },
  {
    timestamp_start: '2022-01-01T21:43:39',
    timestamp_stop: '2022-01-01T21:43:45',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 140291,
    tank_volume_start: 20652.59999999986,
    tank_volume_stop: 20597.79999999986,
  },
  {
    timestamp_start: '2022-01-01T21:44:00',
    timestamp_stop: '2022-01-01T21:44:05',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 140293,
    tank_volume_start: 20597.79999999986,
    tank_volume_stop: 20544.39999999986,
  },
  {
    timestamp_start: '2022-01-01T21:44:20',
    timestamp_stop: '2022-01-01T21:44:25',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 140295,
    tank_volume_start: 20544.39999999986,
    tank_volume_stop: 20491.19999999986,
  },
  {
    timestamp_start: '2022-01-01T21:44:40',
    timestamp_stop: '2022-01-01T21:44:46',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 140297,
    tank_volume_start: 20491.19999999986,
    tank_volume_stop: 20439.499999999858,
  },
  {
    timestamp_start: '2022-01-01T21:45:01',
    timestamp_stop: '2022-01-01T21:45:06',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 140299,
    tank_volume_start: 20439.499999999858,
    tank_volume_stop: 20380.599999999857,
  },
  {
    timestamp_start: '2022-01-01T21:45:21',
    timestamp_stop: '2022-01-01T21:45:27',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 140301,
    tank_volume_start: 20380.599999999857,
    tank_volume_stop: 20326.799999999857,
  },
  {
    timestamp_start: '2022-01-01T21:45:42',
    timestamp_stop: '2022-01-01T21:45:47',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 140303,
    tank_volume_start: 20326.799999999857,
    tank_volume_stop: 20273.399999999856,
  },
  {
    timestamp_start: '2022-01-01T21:46:02',
    timestamp_stop: '2022-01-01T21:46:07',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 140305,
    tank_volume_start: 20273.399999999856,
    tank_volume_stop: 20225.599999999857,
  },
  {
    timestamp_start: '2022-01-01T21:46:22',
    timestamp_stop: '2022-01-01T21:46:28',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 140307,
    tank_volume_start: 20225.599999999857,
    tank_volume_stop: 20167.899999999856,
  },
  {
    timestamp_start: '2022-01-01T21:46:43',
    timestamp_stop: '2022-01-01T21:46:48',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 140309,
    tank_volume_start: 20167.899999999856,
    tank_volume_stop: 20110.399999999856,
  },
  {
    timestamp_start: '2022-01-01T21:47:03',
    timestamp_stop: '2022-01-01T21:47:08',
    quantity: 49.7,
    vehicle: 'DEM222',
    id: 140311,
    tank_volume_start: 20110.399999999856,
    tank_volume_stop: 20060.699999999855,
  },
  {
    timestamp_start: '2022-01-01T21:47:23',
    timestamp_stop: '2022-01-01T21:47:29',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 140313,
    tank_volume_start: 20060.699999999855,
    tank_volume_stop: 20005.799999999854,
  },
  {
    timestamp_start: '2022-01-01T21:47:44',
    timestamp_stop: '2022-01-01T21:47:50',
    quantity: 61.4,
    vehicle: 'DEM222',
    id: 140315,
    tank_volume_start: 20005.799999999854,
    tank_volume_stop: 19944.399999999852,
  },
  {
    timestamp_start: '2022-01-01T21:48:05',
    timestamp_stop: '2022-01-01T21:48:11',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 140317,
    tank_volume_start: 39944.39999999985,
    tank_volume_stop: 39887.29999999985,
  },
  {
    timestamp_start: '2022-01-01T21:48:26',
    timestamp_stop: '2022-01-01T21:48:32',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 140319,
    tank_volume_start: 39887.29999999985,
    tank_volume_stop: 39827.29999999985,
  },
  {
    timestamp_start: '2022-01-01T21:48:47',
    timestamp_stop: '2022-01-01T21:48:52',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 140321,
    tank_volume_start: 39827.29999999985,
    tank_volume_stop: 39771.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:49:07',
    timestamp_stop: '2022-01-01T21:49:12',
    quantity: 49.7,
    vehicle: 'DEM222',
    id: 140323,
    tank_volume_start: 39771.99999999985,
    tank_volume_stop: 39722.29999999985,
  },
  {
    timestamp_start: '2022-01-01T21:49:27',
    timestamp_stop: '2022-01-01T21:49:33',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 140325,
    tank_volume_start: 39722.29999999985,
    tank_volume_stop: 39666.59999999985,
  },
  {
    timestamp_start: '2022-01-01T21:49:48',
    timestamp_stop: '2022-01-01T21:49:54',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 140327,
    tank_volume_start: 39666.59999999985,
    tank_volume_stop: 39609.69999999985,
  },
  {
    timestamp_start: '2022-01-01T21:50:09',
    timestamp_stop: '2022-01-01T21:50:14',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 140329,
    tank_volume_start: 39609.69999999985,
    tank_volume_stop: 39551.89999999985,
  },
  {
    timestamp_start: '2022-01-01T21:50:29',
    timestamp_stop: '2022-01-01T21:50:36',
    quantity: 62.8,
    vehicle: 'DEM555',
    id: 140331,
    tank_volume_start: 39551.89999999985,
    tank_volume_stop: 39489.099999999846,
  },
  {
    timestamp_start: '2022-01-01T21:50:51',
    timestamp_stop: '2022-01-01T21:50:56',
    quantity: 58.4,
    vehicle: 'DEM444',
    id: 140333,
    tank_volume_start: 39489.099999999846,
    tank_volume_stop: 39430.699999999844,
  },
  {
    timestamp_start: '2022-01-01T21:51:11',
    timestamp_stop: '2022-01-01T21:51:17',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 140335,
    tank_volume_start: 39430.699999999844,
    tank_volume_stop: 39373.49999999985,
  },
  {
    timestamp_start: '2022-01-01T21:51:32',
    timestamp_stop: '2022-01-01T21:51:38',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 140337,
    tank_volume_start: 39373.49999999985,
    tank_volume_stop: 39312.199999999844,
  },
  {
    timestamp_start: '2022-01-01T21:51:53',
    timestamp_stop: '2022-01-01T21:52:00',
    quantity: 62.8,
    vehicle: 'DEM000',
    id: 140339,
    tank_volume_start: 39312.199999999844,
    tank_volume_stop: 39249.39999999984,
  },
  {
    timestamp_start: '2022-01-01T21:52:15',
    timestamp_stop: '2022-01-01T21:52:20',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 140341,
    tank_volume_start: 39249.39999999984,
    tank_volume_stop: 39196.09999999984,
  },
  {
    timestamp_start: '2022-01-01T21:52:35',
    timestamp_stop: '2022-01-01T21:52:40',
    quantity: 52.3,
    vehicle: 'DEM888',
    id: 140343,
    tank_volume_start: 39196.09999999984,
    tank_volume_stop: 39143.799999999836,
  },
  {
    timestamp_start: '2022-01-01T21:52:55',
    timestamp_stop: '2022-01-01T21:53:00',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 140345,
    tank_volume_start: 39143.799999999836,
    tank_volume_stop: 39092.59999999984,
  },
  {
    timestamp_start: '2022-01-01T21:53:15',
    timestamp_stop: '2022-01-01T21:53:21',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 140347,
    tank_volume_start: 39092.59999999984,
    tank_volume_stop: 39033.49999999984,
  },
  {
    timestamp_start: '2022-01-01T21:53:36',
    timestamp_stop: '2022-01-01T21:53:41',
    quantity: 50.0,
    vehicle: 'DEM222',
    id: 140349,
    tank_volume_start: 39033.49999999984,
    tank_volume_stop: 38983.49999999984,
  },
  {
    timestamp_start: '2022-01-01T21:53:56',
    timestamp_stop: '2022-01-01T21:54:01',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 140351,
    tank_volume_start: 38983.49999999984,
    tank_volume_stop: 38930.99999999984,
  },
  {
    timestamp_start: '2022-01-01T21:54:16',
    timestamp_stop: '2022-01-01T21:54:22',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 140353,
    tank_volume_start: 38930.99999999984,
    tank_volume_stop: 38874.89999999984,
  },
  {
    timestamp_start: '2022-01-01T21:54:37',
    timestamp_stop: '2022-01-01T21:54:43',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 140355,
    tank_volume_start: 38874.89999999984,
    tank_volume_stop: 38814.79999999984,
  },
  {
    timestamp_start: '2022-01-01T21:54:58',
    timestamp_stop: '2022-01-01T21:55:03',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 140357,
    tank_volume_start: 38814.79999999984,
    tank_volume_stop: 38761.199999999844,
  },
  {
    timestamp_start: '2022-01-01T21:55:18',
    timestamp_stop: '2022-01-01T21:55:23',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 140359,
    tank_volume_start: 38761.199999999844,
    tank_volume_stop: 38710.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:55:38',
    timestamp_stop: '2022-01-01T21:55:44',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 140361,
    tank_volume_start: 38710.99999999985,
    tank_volume_stop: 38657.199999999844,
  },
  {
    timestamp_start: '2022-01-01T21:55:59',
    timestamp_stop: '2022-01-01T21:56:05',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 140363,
    tank_volume_start: 38657.199999999844,
    tank_volume_stop: 38599.49999999985,
  },
  {
    timestamp_start: '2022-01-01T21:56:20',
    timestamp_stop: '2022-01-01T21:56:24',
    quantity: 47.5,
    vehicle: 'DEM222',
    id: 140365,
    tank_volume_start: 38599.49999999985,
    tank_volume_stop: 38551.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:56:39',
    timestamp_stop: '2022-01-01T21:56:45',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 140367,
    tank_volume_start: 38551.99999999985,
    tank_volume_stop: 38498.79999999985,
  },
  {
    timestamp_start: '2022-01-01T21:57:00',
    timestamp_stop: '2022-01-01T21:57:05',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 140369,
    tank_volume_start: 38498.79999999985,
    tank_volume_stop: 38440.69999999985,
  },
  {
    timestamp_start: '2022-01-01T21:57:20',
    timestamp_stop: '2022-01-01T21:57:25',
    quantity: 49.4,
    vehicle: 'DEM777',
    id: 140371,
    tank_volume_start: 38440.69999999985,
    tank_volume_stop: 38391.29999999985,
  },
  {
    timestamp_start: '2022-01-01T21:57:40',
    timestamp_stop: '2022-01-01T21:57:46',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 140373,
    tank_volume_start: 38391.29999999985,
    tank_volume_stop: 38335.49999999985,
  },
  {
    timestamp_start: '2022-01-01T21:58:01',
    timestamp_stop: '2022-01-01T21:58:07',
    quantity: 61.5,
    vehicle: 'DEM000',
    id: 140375,
    tank_volume_start: 38335.49999999985,
    tank_volume_stop: 38273.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:58:22',
    timestamp_stop: '2022-01-01T21:58:28',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 140377,
    tank_volume_start: 38273.99999999985,
    tank_volume_stop: 38213.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:58:43',
    timestamp_stop: '2022-01-01T21:58:48',
    quantity: 50.0,
    vehicle: 'DEM333',
    id: 140379,
    tank_volume_start: 38213.99999999985,
    tank_volume_stop: 38163.99999999985,
  },
  {
    timestamp_start: '2022-01-01T21:59:03',
    timestamp_stop: '2022-01-01T21:59:09',
    quantity: 56.5,
    vehicle: 'DEM222',
    id: 140381,
    tank_volume_start: 38163.99999999985,
    tank_volume_stop: 38107.49999999985,
  },
  {
    timestamp_start: '2022-01-01T21:59:24',
    timestamp_stop: '2022-01-01T21:59:29',
    quantity: 47.9,
    vehicle: 'DEM444',
    id: 140383,
    tank_volume_start: 38107.49999999985,
    tank_volume_stop: 38059.599999999846,
  },
  {
    timestamp_start: '2022-01-01T21:59:44',
    timestamp_stop: '2022-01-01T21:59:49',
    quantity: 59.2,
    vehicle: 'DEM555',
    id: 140385,
    tank_volume_start: 38059.599999999846,
    tank_volume_stop: 38000.39999999985,
  },
  {
    timestamp_start: '2022-01-01T22:00:04',
    timestamp_stop: '2022-01-01T22:00:10',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 140387,
    tank_volume_start: 38000.39999999985,
    tank_volume_stop: 37944.29999999985,
  },
  {
    timestamp_start: '2022-01-01T22:00:25',
    timestamp_stop: '2022-01-01T22:00:31',
    quantity: 63.7,
    vehicle: 'DEM777',
    id: 140389,
    tank_volume_start: 37944.29999999985,
    tank_volume_stop: 37880.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:00:46',
    timestamp_stop: '2022-01-01T22:00:51',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 140391,
    tank_volume_start: 37880.59999999985,
    tank_volume_stop: 37831.29999999985,
  },
  {
    timestamp_start: '2022-01-01T22:01:06',
    timestamp_stop: '2022-01-01T22:01:12',
    quantity: 58.3,
    vehicle: 'DEM666',
    id: 140393,
    tank_volume_start: 37831.29999999985,
    tank_volume_stop: 37772.99999999985,
  },
  {
    timestamp_start: '2022-01-01T22:01:27',
    timestamp_stop: '2022-01-01T22:01:33',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 140395,
    tank_volume_start: 37772.99999999985,
    tank_volume_stop: 37714.89999999985,
  },
  {
    timestamp_start: '2022-01-01T22:01:48',
    timestamp_stop: '2022-01-01T22:01:54',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 140397,
    tank_volume_start: 37714.89999999985,
    tank_volume_stop: 37652.69999999985,
  },
  {
    timestamp_start: '2022-01-01T22:02:09',
    timestamp_stop: '2022-01-01T22:02:15',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 140399,
    tank_volume_start: 37652.69999999985,
    tank_volume_stop: 37596.999999999854,
  },
  {
    timestamp_start: '2022-01-01T22:02:30',
    timestamp_stop: '2022-01-01T22:02:36',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 140401,
    tank_volume_start: 37596.999999999854,
    tank_volume_stop: 37537.999999999854,
  },
  {
    timestamp_start: '2022-01-01T22:02:51',
    timestamp_stop: '2022-01-01T22:02:56',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 140403,
    tank_volume_start: 37537.999999999854,
    tank_volume_stop: 37485.19999999985,
  },
  {
    timestamp_start: '2022-01-01T22:03:11',
    timestamp_stop: '2022-01-01T22:03:16',
    quantity: 50.4,
    vehicle: 'DEM444',
    id: 140405,
    tank_volume_start: 37485.19999999985,
    tank_volume_stop: 37434.79999999985,
  },
  {
    timestamp_start: '2022-01-01T22:03:31',
    timestamp_stop: '2022-01-01T22:03:37',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 140407,
    tank_volume_start: 37434.79999999985,
    tank_volume_stop: 37376.89999999985,
  },
  {
    timestamp_start: '2022-01-01T22:03:52',
    timestamp_stop: '2022-01-01T22:03:57',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 140409,
    tank_volume_start: 37376.89999999985,
    tank_volume_stop: 37328.69999999985,
  },
  {
    timestamp_start: '2022-01-01T22:04:12',
    timestamp_stop: '2022-01-01T22:04:18',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 140411,
    tank_volume_start: 37328.69999999985,
    tank_volume_stop: 37269.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:04:33',
    timestamp_stop: '2022-01-01T22:04:38',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 140413,
    tank_volume_start: 37269.499999999854,
    tank_volume_stop: 37216.19999999985,
  },
  {
    timestamp_start: '2022-01-01T22:04:53',
    timestamp_stop: '2022-01-01T22:04:59',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 140415,
    tank_volume_start: 37216.19999999985,
    tank_volume_stop: 37158.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:05:14',
    timestamp_stop: '2022-01-01T22:05:20',
    quantity: 63.1,
    vehicle: 'DEM333',
    id: 140417,
    tank_volume_start: 37158.59999999985,
    tank_volume_stop: 37095.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:05:35',
    timestamp_stop: '2022-01-01T22:05:40',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 140419,
    tank_volume_start: 37095.499999999854,
    tank_volume_stop: 37043.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:05:55',
    timestamp_stop: '2022-01-01T22:06:01',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 140421,
    tank_volume_start: 37043.499999999854,
    tank_volume_stop: 36989.399999999856,
  },
  {
    timestamp_start: '2022-01-01T22:06:16',
    timestamp_stop: '2022-01-01T22:06:20',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 140423,
    tank_volume_start: 36989.399999999856,
    tank_volume_stop: 36941.69999999986,
  },
  {
    timestamp_start: '2022-01-01T22:06:35',
    timestamp_stop: '2022-01-01T22:06:41',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 140425,
    tank_volume_start: 36941.69999999986,
    tank_volume_stop: 36888.399999999856,
  },
  {
    timestamp_start: '2022-01-01T22:06:56',
    timestamp_stop: '2022-01-01T22:07:02',
    quantity: 61.4,
    vehicle: 'DEM444',
    id: 140427,
    tank_volume_start: 36888.399999999856,
    tank_volume_stop: 36826.999999999854,
  },
  {
    timestamp_start: '2022-01-01T22:07:17',
    timestamp_stop: '2022-01-01T22:07:22',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 140429,
    tank_volume_start: 36826.999999999854,
    tank_volume_stop: 36771.09999999985,
  },
  {
    timestamp_start: '2022-01-01T22:07:37',
    timestamp_stop: '2022-01-01T22:07:43',
    quantity: 57.6,
    vehicle: 'DEM666',
    id: 140431,
    tank_volume_start: 36771.09999999985,
    tank_volume_stop: 36713.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:07:58',
    timestamp_stop: '2022-01-01T22:08:03',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 140433,
    tank_volume_start: 36713.499999999854,
    tank_volume_stop: 36664.19999999985,
  },
  {
    timestamp_start: '2022-01-01T22:08:18',
    timestamp_stop: '2022-01-01T22:08:24',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 140435,
    tank_volume_start: 36664.19999999985,
    tank_volume_stop: 36604.29999999985,
  },
  {
    timestamp_start: '2022-01-01T22:08:39',
    timestamp_stop: '2022-01-01T22:08:44',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 140437,
    tank_volume_start: 36604.29999999985,
    tank_volume_stop: 36550.19999999985,
  },
  {
    timestamp_start: '2022-01-01T22:08:59',
    timestamp_stop: '2022-01-01T22:09:05',
    quantity: 56.6,
    vehicle: 'DEM666',
    id: 140439,
    tank_volume_start: 36550.19999999985,
    tank_volume_stop: 36493.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:09:20',
    timestamp_stop: '2022-01-01T22:09:25',
    quantity: 50.9,
    vehicle: 'DEM666',
    id: 140441,
    tank_volume_start: 36493.59999999985,
    tank_volume_stop: 36442.69999999985,
  },
  {
    timestamp_start: '2022-01-01T22:09:40',
    timestamp_stop: '2022-01-01T22:09:45',
    quantity: 50.2,
    vehicle: 'DEM666',
    id: 140443,
    tank_volume_start: 36442.69999999985,
    tank_volume_stop: 36392.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:10:00',
    timestamp_stop: '2022-01-01T22:10:06',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 140445,
    tank_volume_start: 36392.499999999854,
    tank_volume_stop: 36339.79999999986,
  },
  {
    timestamp_start: '2022-01-01T22:10:21',
    timestamp_stop: '2022-01-01T22:10:26',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 140447,
    tank_volume_start: 36339.79999999986,
    tank_volume_stop: 36284.29999999986,
  },
  {
    timestamp_start: '2022-01-01T22:10:41',
    timestamp_stop: '2022-01-01T22:10:47',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 140449,
    tank_volume_start: 36284.29999999986,
    tank_volume_stop: 36227.899999999856,
  },
  {
    timestamp_start: '2022-01-01T22:11:02',
    timestamp_stop: '2022-01-01T22:11:07',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 140451,
    tank_volume_start: 36227.899999999856,
    tank_volume_stop: 36173.399999999856,
  },
  {
    timestamp_start: '2022-01-01T22:11:22',
    timestamp_stop: '2022-01-01T22:11:28',
    quantity: 57.4,
    vehicle: 'DEM000',
    id: 140453,
    tank_volume_start: 36173.399999999856,
    tank_volume_stop: 36115.999999999854,
  },
  {
    timestamp_start: '2022-01-01T22:11:43',
    timestamp_stop: '2022-01-01T22:11:48',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 140455,
    tank_volume_start: 36115.999999999854,
    tank_volume_stop: 36063.09999999985,
  },
  {
    timestamp_start: '2022-01-01T22:12:03',
    timestamp_stop: '2022-01-01T22:12:08',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 140457,
    tank_volume_start: 36063.09999999985,
    tank_volume_stop: 36010.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:12:23',
    timestamp_stop: '2022-01-01T22:12:29',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 140459,
    tank_volume_start: 36010.59999999985,
    tank_volume_stop: 35956.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:12:44',
    timestamp_stop: '2022-01-01T22:12:49',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 140461,
    tank_volume_start: 35956.59999999985,
    tank_volume_stop: 35904.499999999854,
  },
  {
    timestamp_start: '2022-01-01T22:13:04',
    timestamp_stop: '2022-01-01T22:13:09',
    quantity: 46.9,
    vehicle: 'DEM333',
    id: 140463,
    tank_volume_start: 35904.499999999854,
    tank_volume_stop: 35857.59999999985,
  },
  {
    timestamp_start: '2022-01-01T22:13:24',
    timestamp_stop: '2022-01-01T22:13:29',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 140465,
    tank_volume_start: 35857.59999999985,
    tank_volume_stop: 35805.399999999856,
  },
  {
    timestamp_start: '2022-01-01T22:13:44',
    timestamp_stop: '2022-01-01T22:13:49',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 140467,
    tank_volume_start: 35805.399999999856,
    tank_volume_stop: 35750.69999999986,
  },
  {
    timestamp_start: '2022-01-01T22:14:04',
    timestamp_stop: '2022-01-01T22:14:10',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 140469,
    tank_volume_start: 35750.69999999986,
    tank_volume_stop: 35694.69999999986,
  },
  {
    timestamp_start: '2022-01-01T22:14:25',
    timestamp_stop: '2022-01-01T22:14:31',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 140471,
    tank_volume_start: 35694.69999999986,
    tank_volume_stop: 35639.79999999986,
  },
  {
    timestamp_start: '2022-01-01T22:14:46',
    timestamp_stop: '2022-01-01T22:14:51',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 140473,
    tank_volume_start: 35639.79999999986,
    tank_volume_stop: 35585.19999999986,
  },
  {
    timestamp_start: '2022-01-01T22:15:06',
    timestamp_stop: '2022-01-01T22:15:12',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 140475,
    tank_volume_start: 35585.19999999986,
    tank_volume_stop: 35529.49999999986,
  },
  {
    timestamp_start: '2022-01-01T22:15:27',
    timestamp_stop: '2022-01-01T22:15:32',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 140477,
    tank_volume_start: 35529.49999999986,
    tank_volume_stop: 35476.39999999986,
  },
  {
    timestamp_start: '2022-01-01T22:15:47',
    timestamp_stop: '2022-01-01T22:15:53',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 140479,
    tank_volume_start: 35476.39999999986,
    tank_volume_stop: 35418.699999999866,
  },
  {
    timestamp_start: '2022-01-01T22:16:08',
    timestamp_stop: '2022-01-01T22:16:14',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 140480,
    tank_volume_start: 35418.699999999866,
    tank_volume_stop: 35359.09999999987,
  },
  {
    timestamp_start: '2022-01-01T22:16:29',
    timestamp_stop: '2022-01-01T22:16:35',
    quantity: 63.9,
    vehicle: 'DEM333',
    id: 140483,
    tank_volume_start: 35359.09999999987,
    tank_volume_stop: 35295.199999999866,
  },
  {
    timestamp_start: '2022-01-01T22:16:50',
    timestamp_stop: '2022-01-01T22:16:55',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 140485,
    tank_volume_start: 35295.199999999866,
    tank_volume_stop: 35248.49999999987,
  },
  {
    timestamp_start: '2022-01-01T22:17:10',
    timestamp_stop: '2022-01-01T22:17:16',
    quantity: 60.7,
    vehicle: 'DEM444',
    id: 140487,
    tank_volume_start: 35248.49999999987,
    tank_volume_stop: 35187.79999999987,
  },
  {
    timestamp_start: '2022-01-01T22:17:31',
    timestamp_stop: '2022-01-01T22:17:36',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 140489,
    tank_volume_start: 35187.79999999987,
    tank_volume_stop: 35130.79999999987,
  },
  {
    timestamp_start: '2022-01-01T22:17:51',
    timestamp_stop: '2022-01-01T22:17:57',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 140491,
    tank_volume_start: 35130.79999999987,
    tank_volume_stop: 35072.599999999875,
  },
  {
    timestamp_start: '2022-01-01T22:18:12',
    timestamp_stop: '2022-01-01T22:18:17',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 140493,
    tank_volume_start: 35072.599999999875,
    tank_volume_stop: 35024.29999999987,
  },
  {
    timestamp_start: '2022-01-01T22:18:32',
    timestamp_stop: '2022-01-01T22:18:37',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 140495,
    tank_volume_start: 35024.29999999987,
    tank_volume_stop: 34970.19999999987,
  },
  {
    timestamp_start: '2022-01-01T22:18:52',
    timestamp_stop: '2022-01-01T22:18:58',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 140497,
    tank_volume_start: 34970.19999999987,
    tank_volume_stop: 34914.29999999987,
  },
  {
    timestamp_start: '2022-01-01T22:19:13',
    timestamp_stop: '2022-01-01T22:19:19',
    quantity: 59.5,
    vehicle: 'DEM777',
    id: 140499,
    tank_volume_start: 34914.29999999987,
    tank_volume_stop: 34854.79999999987,
  },
  {
    timestamp_start: '2022-01-01T22:19:34',
    timestamp_stop: '2022-01-01T22:19:39',
    quantity: 48.7,
    vehicle: 'DEM444',
    id: 140501,
    tank_volume_start: 34854.79999999987,
    tank_volume_stop: 34806.099999999875,
  },
  {
    timestamp_start: '2022-01-01T22:19:54',
    timestamp_stop: '2022-01-01T22:20:00',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 140503,
    tank_volume_start: 34806.099999999875,
    tank_volume_stop: 34748.79999999987,
  },
  {
    timestamp_start: '2022-01-01T22:20:15',
    timestamp_stop: '2022-01-01T22:20:21',
    quantity: 59.7,
    vehicle: 'DEM777',
    id: 140505,
    tank_volume_start: 34748.79999999987,
    tank_volume_stop: 34689.099999999875,
  },
  {
    timestamp_start: '2022-01-01T22:20:36',
    timestamp_stop: '2022-01-01T22:20:41',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 140507,
    tank_volume_start: 34689.099999999875,
    tank_volume_stop: 34637.89999999988,
  },
  {
    timestamp_start: '2022-01-01T22:20:56',
    timestamp_stop: '2022-01-01T22:21:02',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 140509,
    tank_volume_start: 34637.89999999988,
    tank_volume_stop: 34577.79999999988,
  },
  {
    timestamp_start: '2022-01-01T22:21:17',
    timestamp_stop: '2022-01-01T22:21:22',
    quantity: 48.1,
    vehicle: 'DEM555',
    id: 140511,
    tank_volume_start: 34577.79999999988,
    tank_volume_stop: 34529.69999999988,
  },
  {
    timestamp_start: '2022-01-01T22:21:37',
    timestamp_stop: '2022-01-01T22:21:42',
    quantity: 53.8,
    vehicle: 'DEM777',
    id: 140513,
    tank_volume_start: 34529.69999999988,
    tank_volume_stop: 34475.89999999988,
  },
  {
    timestamp_start: '2022-01-01T22:21:57',
    timestamp_stop: '2022-01-01T22:22:02',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 140515,
    tank_volume_start: 34475.89999999988,
    tank_volume_stop: 34421.099999999875,
  },
  {
    timestamp_start: '2022-01-01T22:22:17',
    timestamp_stop: '2022-01-01T22:22:23',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 140517,
    tank_volume_start: 34421.099999999875,
    tank_volume_stop: 34366.79999999987,
  },
  {
    timestamp_start: '2022-01-01T22:22:38',
    timestamp_stop: '2022-01-01T22:22:44',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 140519,
    tank_volume_start: 34366.79999999987,
    tank_volume_stop: 34304.69999999987,
  },
  {
    timestamp_start: '2022-01-01T22:22:59',
    timestamp_stop: '2022-01-01T22:23:05',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 140521,
    tank_volume_start: 34304.69999999987,
    tank_volume_stop: 34245.099999999875,
  },
  {
    timestamp_start: '2022-01-01T22:23:20',
    timestamp_stop: '2022-01-01T22:23:25',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 140523,
    tank_volume_start: 34245.099999999875,
    tank_volume_stop: 34190.999999999876,
  },
  {
    timestamp_start: '2022-01-01T22:23:40',
    timestamp_stop: '2022-01-01T22:23:46',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 140525,
    tank_volume_start: 34190.999999999876,
    tank_volume_stop: 34132.499999999876,
  },
  {
    timestamp_start: '2022-01-01T22:24:01',
    timestamp_stop: '2022-01-01T22:24:07',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 140527,
    tank_volume_start: 34132.499999999876,
    tank_volume_stop: 34078.499999999876,
  },
  {
    timestamp_start: '2022-01-01T22:24:22',
    timestamp_stop: '2022-01-01T22:24:28',
    quantity: 64.5,
    vehicle: 'DEM777',
    id: 140529,
    tank_volume_start: 34078.499999999876,
    tank_volume_stop: 34013.999999999876,
  },
  {
    timestamp_start: '2022-01-01T22:24:43',
    timestamp_stop: '2022-01-01T22:24:49',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 140531,
    tank_volume_start: 34013.999999999876,
    tank_volume_stop: 33959.89999999988,
  },
  {
    timestamp_start: '2022-01-01T22:25:04',
    timestamp_stop: '2022-01-01T22:25:10',
    quantity: 62.7,
    vehicle: 'DEM666',
    id: 140533,
    tank_volume_start: 33959.89999999988,
    tank_volume_stop: 33897.19999999988,
  },
  {
    timestamp_start: '2022-01-01T22:25:25',
    timestamp_stop: '2022-01-01T22:25:31',
    quantity: 63.1,
    vehicle: 'DEM333',
    id: 140535,
    tank_volume_start: 33897.19999999988,
    tank_volume_stop: 33834.09999999988,
  },
  {
    timestamp_start: '2022-01-01T22:25:46',
    timestamp_stop: '2022-01-01T22:25:52',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 140537,
    tank_volume_start: 33834.09999999988,
    tank_volume_stop: 33775.899999999885,
  },
  {
    timestamp_start: '2022-01-01T22:26:07',
    timestamp_stop: '2022-01-01T22:26:12',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 140539,
    tank_volume_start: 33775.899999999885,
    tank_volume_stop: 33725.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:26:27',
    timestamp_stop: '2022-01-01T22:26:32',
    quantity: 52.6,
    vehicle: 'DEM333',
    id: 140541,
    tank_volume_start: 33725.69999999989,
    tank_volume_stop: 33673.09999999989,
  },
  {
    timestamp_start: '2022-01-01T22:26:47',
    timestamp_stop: '2022-01-01T22:26:54',
    quantity: 64.1,
    vehicle: 'DEM222',
    id: 140543,
    tank_volume_start: 33673.09999999989,
    tank_volume_stop: 33608.99999999989,
  },
  {
    timestamp_start: '2022-01-01T22:27:09',
    timestamp_stop: '2022-01-01T22:27:15',
    quantity: 61.1,
    vehicle: 'DEM666',
    id: 140545,
    tank_volume_start: 33608.99999999989,
    tank_volume_stop: 33547.89999999989,
  },
  {
    timestamp_start: '2022-01-01T22:27:30',
    timestamp_stop: '2022-01-01T22:27:35',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 140547,
    tank_volume_start: 33547.89999999989,
    tank_volume_stop: 33492.199999999895,
  },
  {
    timestamp_start: '2022-01-01T22:27:50',
    timestamp_stop: '2022-01-01T22:27:55',
    quantity: 46.6,
    vehicle: 'DEM333',
    id: 140549,
    tank_volume_start: 33492.199999999895,
    tank_volume_stop: 33445.5999999999,
  },
  {
    timestamp_start: '2022-01-01T22:28:10',
    timestamp_stop: '2022-01-01T22:28:16',
    quantity: 59.6,
    vehicle: 'DEM000',
    id: 140551,
    tank_volume_start: 33445.5999999999,
    tank_volume_stop: 33385.9999999999,
  },
  {
    timestamp_start: '2022-01-01T22:28:31',
    timestamp_stop: '2022-01-01T22:28:36',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 140553,
    tank_volume_start: 33385.9999999999,
    tank_volume_stop: 33331.2999999999,
  },
  {
    timestamp_start: '2022-01-01T22:28:51',
    timestamp_stop: '2022-01-01T22:28:57',
    quantity: 51.8,
    vehicle: 'DEM000',
    id: 140555,
    tank_volume_start: 33331.2999999999,
    tank_volume_stop: 33279.4999999999,
  },
  {
    timestamp_start: '2022-01-01T22:29:12',
    timestamp_stop: '2022-01-01T22:29:17',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 140557,
    tank_volume_start: 33279.4999999999,
    tank_volume_stop: 33221.199999999895,
  },
  {
    timestamp_start: '2022-01-01T22:29:32',
    timestamp_stop: '2022-01-01T22:29:38',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 140559,
    tank_volume_start: 33221.199999999895,
    tank_volume_stop: 33162.799999999894,
  },
  {
    timestamp_start: '2022-01-01T22:29:53',
    timestamp_stop: '2022-01-01T22:29:59',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 140561,
    tank_volume_start: 33162.799999999894,
    tank_volume_stop: 33108.49999999989,
  },
  {
    timestamp_start: '2022-01-01T22:30:14',
    timestamp_stop: '2022-01-01T22:30:18',
    quantity: 47.8,
    vehicle: 'DEM000',
    id: 140563,
    tank_volume_start: 33108.49999999989,
    tank_volume_stop: 33060.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:30:33',
    timestamp_stop: '2022-01-01T22:30:39',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 140565,
    tank_volume_start: 33060.69999999989,
    tank_volume_stop: 33009.79999999989,
  },
  {
    timestamp_start: '2022-01-01T22:30:54',
    timestamp_stop: '2022-01-01T22:30:59',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 140567,
    tank_volume_start: 33009.79999999989,
    tank_volume_stop: 32951.09999999989,
  },
  {
    timestamp_start: '2022-01-01T22:31:14',
    timestamp_stop: '2022-01-01T22:31:20',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 140569,
    tank_volume_start: 32951.09999999989,
    tank_volume_stop: 32891.09999999989,
  },
  {
    timestamp_start: '2022-01-01T22:31:35',
    timestamp_stop: '2022-01-01T22:31:42',
    quantity: 63.6,
    vehicle: 'DEM222',
    id: 140571,
    tank_volume_start: 32891.09999999989,
    tank_volume_stop: 32827.49999999989,
  },
  {
    timestamp_start: '2022-01-01T22:31:57',
    timestamp_stop: '2022-01-01T22:32:03',
    quantity: 63.5,
    vehicle: 'DEM111',
    id: 140573,
    tank_volume_start: 32827.49999999989,
    tank_volume_stop: 32763.99999999989,
  },
  {
    timestamp_start: '2022-01-01T22:32:18',
    timestamp_stop: '2022-01-01T22:32:24',
    quantity: 59.3,
    vehicle: 'DEM777',
    id: 140575,
    tank_volume_start: 32763.99999999989,
    tank_volume_stop: 32704.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:32:39',
    timestamp_stop: '2022-01-01T22:32:44',
    quantity: 47.4,
    vehicle: 'DEM555',
    id: 140577,
    tank_volume_start: 32704.69999999989,
    tank_volume_stop: 32657.29999999989,
  },
  {
    timestamp_start: '2022-01-01T22:32:59',
    timestamp_stop: '2022-01-01T22:33:04',
    quantity: 49.1,
    vehicle: 'DEM666',
    id: 140579,
    tank_volume_start: 32657.29999999989,
    tank_volume_stop: 32608.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:33:19',
    timestamp_stop: '2022-01-01T22:33:24',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 140581,
    tank_volume_start: 32608.19999999989,
    tank_volume_stop: 32558.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:33:39',
    timestamp_stop: '2022-01-01T22:33:44',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 140583,
    tank_volume_start: 32558.19999999989,
    tank_volume_stop: 32502.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:33:59',
    timestamp_stop: '2022-01-01T22:34:04',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 140585,
    tank_volume_start: 32502.19999999989,
    tank_volume_stop: 32450.49999999989,
  },
  {
    timestamp_start: '2022-01-01T22:34:19',
    timestamp_stop: '2022-01-01T22:34:24',
    quantity: 47.7,
    vehicle: 'DEM777',
    id: 140587,
    tank_volume_start: 32450.49999999989,
    tank_volume_stop: 32402.79999999989,
  },
  {
    timestamp_start: '2022-01-01T22:34:39',
    timestamp_stop: '2022-01-01T22:34:44',
    quantity: 47.7,
    vehicle: 'DEM666',
    id: 140589,
    tank_volume_start: 32402.79999999989,
    tank_volume_stop: 32355.09999999989,
  },
  {
    timestamp_start: '2022-01-01T22:34:59',
    timestamp_stop: '2022-01-01T22:35:05',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 140591,
    tank_volume_start: 32355.09999999989,
    tank_volume_stop: 32298.699999999888,
  },
  {
    timestamp_start: '2022-01-01T22:35:20',
    timestamp_stop: '2022-01-01T22:35:25',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 140593,
    tank_volume_start: 32298.699999999888,
    tank_volume_stop: 32242.199999999888,
  },
  {
    timestamp_start: '2022-01-01T22:35:40',
    timestamp_stop: '2022-01-01T22:35:46',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 140595,
    tank_volume_start: 32242.199999999888,
    tank_volume_stop: 32188.59999999989,
  },
  {
    timestamp_start: '2022-01-01T22:36:01',
    timestamp_stop: '2022-01-01T22:36:06',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 140597,
    tank_volume_start: 32188.59999999989,
    tank_volume_stop: 32138.29999999989,
  },
  {
    timestamp_start: '2022-01-01T22:36:21',
    timestamp_stop: '2022-01-01T22:36:27',
    quantity: 60.0,
    vehicle: 'DEM222',
    id: 140599,
    tank_volume_start: 32138.29999999989,
    tank_volume_stop: 32078.29999999989,
  },
  {
    timestamp_start: '2022-01-01T22:36:42',
    timestamp_stop: '2022-01-01T22:36:48',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 140601,
    tank_volume_start: 32078.29999999989,
    tank_volume_stop: 32019.39999999989,
  },
  {
    timestamp_start: '2022-01-01T22:37:03',
    timestamp_stop: '2022-01-01T22:37:08',
    quantity: 51.1,
    vehicle: 'DEM222',
    id: 140603,
    tank_volume_start: 32019.39999999989,
    tank_volume_stop: 31968.29999999989,
  },
  {
    timestamp_start: '2022-01-01T22:37:23',
    timestamp_stop: '2022-01-01T22:37:28',
    quantity: 53.1,
    vehicle: 'DEM333',
    id: 140605,
    tank_volume_start: 31968.29999999989,
    tank_volume_stop: 31915.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:37:43',
    timestamp_stop: '2022-01-01T22:37:49',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 140607,
    tank_volume_start: 31915.19999999989,
    tank_volume_stop: 31857.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:38:04',
    timestamp_stop: '2022-01-01T22:38:09',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 140609,
    tank_volume_start: 31857.69999999989,
    tank_volume_stop: 31804.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:38:24',
    timestamp_stop: '2022-01-01T22:38:30',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 140611,
    tank_volume_start: 31804.899999999892,
    tank_volume_stop: 31747.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:38:45',
    timestamp_stop: '2022-01-01T22:38:50',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 140613,
    tank_volume_start: 31747.399999999892,
    tank_volume_stop: 31697.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:39:05',
    timestamp_stop: '2022-01-01T22:39:11',
    quantity: 57.6,
    vehicle: 'DEM000',
    id: 140615,
    tank_volume_start: 31697.099999999893,
    tank_volume_stop: 31639.499999999894,
  },
  {
    timestamp_start: '2022-01-01T22:39:26',
    timestamp_stop: '2022-01-01T22:39:31',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 140617,
    tank_volume_start: 31639.499999999894,
    tank_volume_stop: 31587.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:39:46',
    timestamp_stop: '2022-01-01T22:39:51',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 140619,
    tank_volume_start: 31587.099999999893,
    tank_volume_stop: 31536.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:40:06',
    timestamp_stop: '2022-01-01T22:40:11',
    quantity: 50.4,
    vehicle: 'DEM555',
    id: 140621,
    tank_volume_start: 31536.299999999894,
    tank_volume_stop: 31485.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:40:26',
    timestamp_stop: '2022-01-01T22:40:31',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 140623,
    tank_volume_start: 31485.899999999892,
    tank_volume_stop: 31430.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:40:46',
    timestamp_stop: '2022-01-01T22:40:52',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 140625,
    tank_volume_start: 31430.099999999893,
    tank_volume_stop: 31374.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:41:07',
    timestamp_stop: '2022-01-01T22:41:13',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 140627,
    tank_volume_start: 31374.299999999894,
    tank_volume_stop: 31318.199999999895,
  },
  {
    timestamp_start: '2022-01-01T22:41:28',
    timestamp_stop: '2022-01-01T22:41:33',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 140629,
    tank_volume_start: 31318.199999999895,
    tank_volume_stop: 31263.799999999894,
  },
  {
    timestamp_start: '2022-01-01T22:41:48',
    timestamp_stop: '2022-01-01T22:41:53',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 140631,
    tank_volume_start: 31263.799999999894,
    tank_volume_stop: 31210.699999999895,
  },
  {
    timestamp_start: '2022-01-01T22:42:08',
    timestamp_stop: '2022-01-01T22:42:14',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 140633,
    tank_volume_start: 31210.699999999895,
    tank_volume_stop: 31157.399999999896,
  },
  {
    timestamp_start: '2022-01-01T22:42:29',
    timestamp_stop: '2022-01-01T22:42:35',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 140635,
    tank_volume_start: 31157.399999999896,
    tank_volume_stop: 31099.499999999894,
  },
  {
    timestamp_start: '2022-01-01T22:42:50',
    timestamp_stop: '2022-01-01T22:42:55',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 140637,
    tank_volume_start: 31099.499999999894,
    tank_volume_stop: 31044.499999999894,
  },
  {
    timestamp_start: '2022-01-01T22:43:10',
    timestamp_stop: '2022-01-01T22:43:15',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 140639,
    tank_volume_start: 31044.499999999894,
    tank_volume_stop: 30991.499999999894,
  },
  {
    timestamp_start: '2022-01-01T22:43:30',
    timestamp_stop: '2022-01-01T22:43:35',
    quantity: 50.2,
    vehicle: 'DEM333',
    id: 140641,
    tank_volume_start: 30991.499999999894,
    tank_volume_stop: 30941.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:43:50',
    timestamp_stop: '2022-01-01T22:43:56',
    quantity: 60.4,
    vehicle: 'DEM333',
    id: 140643,
    tank_volume_start: 30941.299999999894,
    tank_volume_stop: 30880.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:44:11',
    timestamp_stop: '2022-01-01T22:44:17',
    quantity: 57.1,
    vehicle: 'DEM666',
    id: 140645,
    tank_volume_start: 30880.899999999892,
    tank_volume_stop: 30823.799999999894,
  },
  {
    timestamp_start: '2022-01-01T22:44:32',
    timestamp_stop: '2022-01-01T22:44:38',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 140647,
    tank_volume_start: 30823.799999999894,
    tank_volume_stop: 30765.599999999893,
  },
  {
    timestamp_start: '2022-01-01T22:44:53',
    timestamp_stop: '2022-01-01T22:44:58',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 140649,
    tank_volume_start: 30765.599999999893,
    tank_volume_stop: 30710.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:45:13',
    timestamp_stop: '2022-01-01T22:45:19',
    quantity: 53.8,
    vehicle: 'DEM000',
    id: 140651,
    tank_volume_start: 30710.099999999893,
    tank_volume_stop: 30656.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:45:34',
    timestamp_stop: '2022-01-01T22:45:39',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 140653,
    tank_volume_start: 30656.299999999894,
    tank_volume_stop: 30601.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:45:54',
    timestamp_stop: '2022-01-01T22:46:00',
    quantity: 53.7,
    vehicle: 'DEM111',
    id: 140655,
    tank_volume_start: 30601.099999999893,
    tank_volume_stop: 30547.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:46:15',
    timestamp_stop: '2022-01-01T22:46:20',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 140657,
    tank_volume_start: 30547.399999999892,
    tank_volume_stop: 30492.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:46:35',
    timestamp_stop: '2022-01-01T22:46:41',
    quantity: 61.0,
    vehicle: 'DEM666',
    id: 140659,
    tank_volume_start: 30492.099999999893,
    tank_volume_stop: 30431.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:46:56',
    timestamp_stop: '2022-01-01T22:47:02',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 140661,
    tank_volume_start: 30431.099999999893,
    tank_volume_stop: 30373.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:47:17',
    timestamp_stop: '2022-01-01T22:47:23',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 140663,
    tank_volume_start: 30373.899999999892,
    tank_volume_stop: 30319.799999999894,
  },
  {
    timestamp_start: '2022-01-01T22:47:38',
    timestamp_stop: '2022-01-01T22:47:43',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 140665,
    tank_volume_start: 30319.799999999894,
    tank_volume_stop: 30261.799999999894,
  },
  {
    timestamp_start: '2022-01-01T22:47:58',
    timestamp_stop: '2022-01-01T22:48:03',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 140667,
    tank_volume_start: 30261.799999999894,
    tank_volume_stop: 30210.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:48:18',
    timestamp_stop: '2022-01-01T22:48:24',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 140669,
    tank_volume_start: 30210.899999999892,
    tank_volume_stop: 30159.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:48:39',
    timestamp_stop: '2022-01-01T22:48:44',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 140671,
    tank_volume_start: 30159.399999999892,
    tank_volume_stop: 30105.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:48:59',
    timestamp_stop: '2022-01-01T22:49:04',
    quantity: 48.6,
    vehicle: 'DEM222',
    id: 140673,
    tank_volume_start: 30105.299999999894,
    tank_volume_stop: 30056.699999999895,
  },
  {
    timestamp_start: '2022-01-01T22:49:19',
    timestamp_stop: '2022-01-01T22:49:24',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 140675,
    tank_volume_start: 30056.699999999895,
    tank_volume_stop: 30002.999999999894,
  },
  {
    timestamp_start: '2022-01-01T22:49:39',
    timestamp_stop: '2022-01-01T22:49:44',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 140677,
    tank_volume_start: 30002.999999999894,
    tank_volume_stop: 29951.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:49:59',
    timestamp_stop: '2022-01-01T22:50:06',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 140679,
    tank_volume_start: 29951.299999999894,
    tank_volume_stop: 29889.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:50:21',
    timestamp_stop: '2022-01-01T22:50:26',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 140681,
    tank_volume_start: 29889.399999999892,
    tank_volume_stop: 29833.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:50:41',
    timestamp_stop: '2022-01-01T22:50:46',
    quantity: 50.0,
    vehicle: 'DEM555',
    id: 140683,
    tank_volume_start: 29833.399999999892,
    tank_volume_stop: 29783.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:51:01',
    timestamp_stop: '2022-01-01T22:51:08',
    quantity: 63.5,
    vehicle: 'DEM222',
    id: 140685,
    tank_volume_start: 29783.399999999892,
    tank_volume_stop: 29719.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:51:23',
    timestamp_stop: '2022-01-01T22:51:29',
    quantity: 63.2,
    vehicle: 'DEM222',
    id: 140687,
    tank_volume_start: 29719.899999999892,
    tank_volume_stop: 29656.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:51:44',
    timestamp_stop: '2022-01-01T22:51:50',
    quantity: 57.7,
    vehicle: 'DEM111',
    id: 140689,
    tank_volume_start: 29656.69999999989,
    tank_volume_stop: 29598.99999999989,
  },
  {
    timestamp_start: '2022-01-01T22:52:05',
    timestamp_stop: '2022-01-01T22:52:10',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 140691,
    tank_volume_start: 29598.99999999989,
    tank_volume_stop: 29543.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:52:25',
    timestamp_stop: '2022-01-01T22:52:31',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 140693,
    tank_volume_start: 29543.69999999989,
    tank_volume_stop: 29489.49999999989,
  },
  {
    timestamp_start: '2022-01-01T22:52:46',
    timestamp_stop: '2022-01-01T22:52:50',
    quantity: 49.3,
    vehicle: 'DEM222',
    id: 140695,
    tank_volume_start: 29489.49999999989,
    tank_volume_stop: 29440.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:53:05',
    timestamp_stop: '2022-01-01T22:53:11',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 140697,
    tank_volume_start: 29440.19999999989,
    tank_volume_stop: 29389.599999999893,
  },
  {
    timestamp_start: '2022-01-01T22:53:26',
    timestamp_stop: '2022-01-01T22:53:31',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 140699,
    tank_volume_start: 29389.599999999893,
    tank_volume_stop: 29333.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:53:46',
    timestamp_stop: '2022-01-01T22:53:52',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 140701,
    tank_volume_start: 29333.899999999892,
    tank_volume_stop: 29272.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:54:07',
    timestamp_stop: '2022-01-01T22:54:13',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 140703,
    tank_volume_start: 29272.399999999892,
    tank_volume_stop: 29217.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:54:28',
    timestamp_stop: '2022-01-01T22:54:33',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 140705,
    tank_volume_start: 29217.299999999894,
    tank_volume_stop: 29162.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:54:48',
    timestamp_stop: '2022-01-01T22:54:54',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 140707,
    tank_volume_start: 29162.299999999894,
    tank_volume_stop: 29104.699999999895,
  },
  {
    timestamp_start: '2022-01-01T22:55:09',
    timestamp_stop: '2022-01-01T22:55:15',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 140709,
    tank_volume_start: 29104.699999999895,
    tank_volume_stop: 29047.499999999894,
  },
  {
    timestamp_start: '2022-01-01T22:55:30',
    timestamp_stop: '2022-01-01T22:55:36',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 140711,
    tank_volume_start: 29047.499999999894,
    tank_volume_stop: 28989.999999999894,
  },
  {
    timestamp_start: '2022-01-01T22:55:51',
    timestamp_stop: '2022-01-01T22:55:57',
    quantity: 64.0,
    vehicle: 'DEM777',
    id: 140713,
    tank_volume_start: 28989.999999999894,
    tank_volume_stop: 28925.999999999894,
  },
  {
    timestamp_start: '2022-01-01T22:56:12',
    timestamp_stop: '2022-01-01T22:56:18',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 140715,
    tank_volume_start: 28925.999999999894,
    tank_volume_stop: 28868.999999999894,
  },
  {
    timestamp_start: '2022-01-01T22:56:33',
    timestamp_stop: '2022-01-01T22:56:37',
    quantity: 48.7,
    vehicle: 'DEM333',
    id: 140717,
    tank_volume_start: 28868.999999999894,
    tank_volume_stop: 28820.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:56:52',
    timestamp_stop: '2022-01-01T22:56:58',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 140719,
    tank_volume_start: 28820.299999999894,
    tank_volume_stop: 28765.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:57:13',
    timestamp_stop: '2022-01-01T22:57:18',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 140721,
    tank_volume_start: 28765.399999999892,
    tank_volume_stop: 28712.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:57:33',
    timestamp_stop: '2022-01-01T22:57:38',
    quantity: 48.8,
    vehicle: 'DEM888',
    id: 140723,
    tank_volume_start: 28712.69999999989,
    tank_volume_stop: 28663.899999999892,
  },
  {
    timestamp_start: '2022-01-01T22:57:53',
    timestamp_stop: '2022-01-01T22:57:59',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 140725,
    tank_volume_start: 28663.899999999892,
    tank_volume_stop: 28609.299999999894,
  },
  {
    timestamp_start: '2022-01-01T22:58:14',
    timestamp_stop: '2022-01-01T22:58:19',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 140727,
    tank_volume_start: 28609.299999999894,
    tank_volume_stop: 28556.099999999893,
  },
  {
    timestamp_start: '2022-01-01T22:58:34',
    timestamp_stop: '2022-01-01T22:58:39',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 140729,
    tank_volume_start: 28556.099999999893,
    tank_volume_stop: 28500.399999999892,
  },
  {
    timestamp_start: '2022-01-01T22:58:54',
    timestamp_stop: '2022-01-01T22:59:00',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 140731,
    tank_volume_start: 28500.399999999892,
    tank_volume_stop: 28443.69999999989,
  },
  {
    timestamp_start: '2022-01-01T22:59:15',
    timestamp_stop: '2022-01-01T22:59:20',
    quantity: 47.5,
    vehicle: 'DEM555',
    id: 140733,
    tank_volume_start: 28443.69999999989,
    tank_volume_stop: 28396.19999999989,
  },
  {
    timestamp_start: '2022-01-01T22:59:35',
    timestamp_stop: '2022-01-01T22:59:40',
    quantity: 50.9,
    vehicle: 'DEM777',
    id: 140735,
    tank_volume_start: 28396.19999999989,
    tank_volume_stop: 28345.29999999989,
  },
  {
    timestamp_start: '2022-01-01T22:59:55',
    timestamp_stop: '2022-01-01T23:00:00',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 140737,
    tank_volume_start: 28345.29999999989,
    tank_volume_stop: 28296.39999999989,
  },
  {
    timestamp_start: '2022-01-01T23:00:15',
    timestamp_stop: '2022-01-01T23:00:21',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 140739,
    tank_volume_start: 28296.39999999989,
    tank_volume_stop: 28239.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:00:36',
    timestamp_stop: '2022-01-01T23:00:42',
    quantity: 63.6,
    vehicle: 'DEM222',
    id: 140741,
    tank_volume_start: 28239.59999999989,
    tank_volume_stop: 28175.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:00:57',
    timestamp_stop: '2022-01-01T23:01:02',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 140743,
    tank_volume_start: 28175.99999999989,
    tank_volume_stop: 28121.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:01:17',
    timestamp_stop: '2022-01-01T23:01:23',
    quantity: 61.2,
    vehicle: 'DEM888',
    id: 140745,
    tank_volume_start: 28121.29999999989,
    tank_volume_stop: 28060.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:01:38',
    timestamp_stop: '2022-01-01T23:01:43',
    quantity: 47.8,
    vehicle: 'DEM111',
    id: 140747,
    tank_volume_start: 28060.09999999989,
    tank_volume_stop: 28012.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:01:58',
    timestamp_stop: '2022-01-01T23:02:04',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 140749,
    tank_volume_start: 28012.29999999989,
    tank_volume_stop: 27956.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:02:19',
    timestamp_stop: '2022-01-01T23:02:24',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 140751,
    tank_volume_start: 27956.29999999989,
    tank_volume_stop: 27904.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:02:39',
    timestamp_stop: '2022-01-01T23:02:44',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 140753,
    tank_volume_start: 27904.19999999989,
    tank_volume_stop: 27850.79999999989,
  },
  {
    timestamp_start: '2022-01-01T23:02:59',
    timestamp_stop: '2022-01-01T23:03:05',
    quantity: 50.9,
    vehicle: 'DEM666',
    id: 140755,
    tank_volume_start: 27850.79999999989,
    tank_volume_stop: 27799.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:03:20',
    timestamp_stop: '2022-01-01T23:03:24',
    quantity: 48.3,
    vehicle: 'DEM000',
    id: 140757,
    tank_volume_start: 27799.89999999989,
    tank_volume_stop: 27751.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:03:39',
    timestamp_stop: '2022-01-01T23:03:45',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 140759,
    tank_volume_start: 27751.59999999989,
    tank_volume_stop: 27698.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:04:00',
    timestamp_stop: '2022-01-01T23:04:06',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 140761,
    tank_volume_start: 27698.99999999989,
    tank_volume_stop: 27639.899999999892,
  },
  {
    timestamp_start: '2022-01-01T23:04:21',
    timestamp_stop: '2022-01-01T23:04:25',
    quantity: 47.7,
    vehicle: 'DEM333',
    id: 140763,
    tank_volume_start: 27639.899999999892,
    tank_volume_stop: 27592.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:04:40',
    timestamp_stop: '2022-01-01T23:04:46',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 140764,
    tank_volume_start: 27592.19999999989,
    tank_volume_stop: 27536.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:05:01',
    timestamp_stop: '2022-01-01T23:05:06',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 140767,
    tank_volume_start: 27536.69999999989,
    tank_volume_stop: 27480.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:05:21',
    timestamp_stop: '2022-01-01T23:05:27',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 140769,
    tank_volume_start: 27480.99999999989,
    tank_volume_stop: 27420.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:05:42',
    timestamp_stop: '2022-01-01T23:05:48',
    quantity: 60.5,
    vehicle: 'DEM777',
    id: 140771,
    tank_volume_start: 27420.59999999989,
    tank_volume_stop: 27360.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:06:03',
    timestamp_stop: '2022-01-01T23:06:09',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 140773,
    tank_volume_start: 27360.09999999989,
    tank_volume_stop: 27303.699999999888,
  },
  {
    timestamp_start: '2022-01-01T23:06:24',
    timestamp_stop: '2022-01-01T23:06:30',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 140775,
    tank_volume_start: 27303.699999999888,
    tank_volume_stop: 27248.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:06:45',
    timestamp_stop: '2022-01-01T23:06:50',
    quantity: 57.8,
    vehicle: 'DEM777',
    id: 140777,
    tank_volume_start: 27248.89999999989,
    tank_volume_stop: 27191.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:07:05',
    timestamp_stop: '2022-01-01T23:07:10',
    quantity: 50.2,
    vehicle: 'DEM222',
    id: 140779,
    tank_volume_start: 27191.09999999989,
    tank_volume_stop: 27140.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:07:25',
    timestamp_stop: '2022-01-01T23:07:30',
    quantity: 46.7,
    vehicle: 'DEM888',
    id: 140781,
    tank_volume_start: 27140.89999999989,
    tank_volume_stop: 27094.199999999888,
  },
  {
    timestamp_start: '2022-01-01T23:07:45',
    timestamp_stop: '2022-01-01T23:07:50',
    quantity: 50.3,
    vehicle: 'DEM777',
    id: 140783,
    tank_volume_start: 27094.199999999888,
    tank_volume_stop: 27043.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:08:05',
    timestamp_stop: '2022-01-01T23:08:11',
    quantity: 57.1,
    vehicle: 'DEM666',
    id: 140785,
    tank_volume_start: 27043.89999999989,
    tank_volume_stop: 26986.79999999989,
  },
  {
    timestamp_start: '2022-01-01T23:08:26',
    timestamp_stop: '2022-01-01T23:08:31',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 140787,
    tank_volume_start: 26986.79999999989,
    tank_volume_stop: 26930.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:08:46',
    timestamp_stop: '2022-01-01T23:08:52',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 140789,
    tank_volume_start: 26930.69999999989,
    tank_volume_stop: 26879.79999999989,
  },
  {
    timestamp_start: '2022-01-01T23:09:07',
    timestamp_stop: '2022-01-01T23:09:12',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 140791,
    tank_volume_start: 26879.79999999989,
    tank_volume_stop: 26823.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:09:27',
    timestamp_stop: '2022-01-01T23:09:32',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 140793,
    tank_volume_start: 26823.09999999989,
    tank_volume_stop: 26770.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:09:47',
    timestamp_stop: '2022-01-01T23:09:53',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 140795,
    tank_volume_start: 26770.09999999989,
    tank_volume_stop: 26717.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:10:08',
    timestamp_stop: '2022-01-01T23:10:13',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 140797,
    tank_volume_start: 26717.59999999989,
    tank_volume_stop: 26660.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:10:28',
    timestamp_stop: '2022-01-01T23:10:34',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 140799,
    tank_volume_start: 26660.49999999989,
    tank_volume_stop: 26609.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:10:49',
    timestamp_stop: '2022-01-01T23:10:54',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 140801,
    tank_volume_start: 26609.49999999989,
    tank_volume_stop: 26557.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:11:09',
    timestamp_stop: '2022-01-01T23:11:15',
    quantity: 60.9,
    vehicle: 'DEM777',
    id: 140803,
    tank_volume_start: 26557.19999999989,
    tank_volume_stop: 26496.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:11:30',
    timestamp_stop: '2022-01-01T23:11:35',
    quantity: 53.0,
    vehicle: 'DEM333',
    id: 140805,
    tank_volume_start: 26496.29999999989,
    tank_volume_stop: 26443.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:11:50',
    timestamp_stop: '2022-01-01T23:11:55',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 140807,
    tank_volume_start: 26443.29999999989,
    tank_volume_stop: 26395.39999999989,
  },
  {
    timestamp_start: '2022-01-01T23:12:10',
    timestamp_stop: '2022-01-01T23:12:16',
    quantity: 63.0,
    vehicle: 'DEM555',
    id: 140808,
    tank_volume_start: 26395.39999999989,
    tank_volume_stop: 26332.39999999989,
  },
  {
    timestamp_start: '2022-01-01T23:12:31',
    timestamp_stop: '2022-01-01T23:12:36',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 140810,
    tank_volume_start: 26332.39999999989,
    tank_volume_stop: 26281.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:12:51',
    timestamp_stop: '2022-01-01T23:12:57',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 140812,
    tank_volume_start: 26281.29999999989,
    tank_volume_stop: 26224.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:13:12',
    timestamp_stop: '2022-01-01T23:13:18',
    quantity: 61.6,
    vehicle: 'DEM888',
    id: 140814,
    tank_volume_start: 26224.69999999989,
    tank_volume_stop: 26163.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:13:33',
    timestamp_stop: '2022-01-01T23:13:39',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 140816,
    tank_volume_start: 26163.099999999893,
    tank_volume_stop: 26105.399999999892,
  },
  {
    timestamp_start: '2022-01-01T23:13:54',
    timestamp_stop: '2022-01-01T23:13:59',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 140818,
    tank_volume_start: 26105.399999999892,
    tank_volume_stop: 26050.599999999893,
  },
  {
    timestamp_start: '2022-01-01T23:14:14',
    timestamp_stop: '2022-01-01T23:14:20',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 140821,
    tank_volume_start: 26050.599999999893,
    tank_volume_stop: 25990.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:14:35',
    timestamp_stop: '2022-01-01T23:14:41',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 140823,
    tank_volume_start: 25990.099999999893,
    tank_volume_stop: 25936.499999999894,
  },
  {
    timestamp_start: '2022-01-01T23:14:56',
    timestamp_stop: '2022-01-01T23:15:02',
    quantity: 58.4,
    vehicle: 'DEM444',
    id: 140825,
    tank_volume_start: 25936.499999999894,
    tank_volume_stop: 25878.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:15:17',
    timestamp_stop: '2022-01-01T23:15:22',
    quantity: 49.9,
    vehicle: 'DEM111',
    id: 140827,
    tank_volume_start: 25878.099999999893,
    tank_volume_stop: 25828.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:15:37',
    timestamp_stop: '2022-01-01T23:15:42',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 140829,
    tank_volume_start: 25828.19999999989,
    tank_volume_stop: 25775.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:15:57',
    timestamp_stop: '2022-01-01T23:16:03',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 140831,
    tank_volume_start: 25775.19999999989,
    tank_volume_stop: 25716.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:16:18',
    timestamp_stop: '2022-01-01T23:16:23',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 140833,
    tank_volume_start: 25716.49999999989,
    tank_volume_stop: 25662.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:16:38',
    timestamp_stop: '2022-01-01T23:16:44',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 140835,
    tank_volume_start: 25662.49999999989,
    tank_volume_stop: 25606.899999999892,
  },
  {
    timestamp_start: '2022-01-01T23:16:59',
    timestamp_stop: '2022-01-01T23:17:04',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 140837,
    tank_volume_start: 25606.899999999892,
    tank_volume_stop: 25555.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:17:19',
    timestamp_stop: '2022-01-01T23:17:24',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 140839,
    tank_volume_start: 25555.099999999893,
    tank_volume_stop: 25502.799999999894,
  },
  {
    timestamp_start: '2022-01-01T23:17:39',
    timestamp_stop: '2022-01-01T23:17:45',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 140841,
    tank_volume_start: 25502.799999999894,
    tank_volume_stop: 25444.999999999894,
  },
  {
    timestamp_start: '2022-01-01T23:18:00',
    timestamp_stop: '2022-01-01T23:18:05',
    quantity: 48.0,
    vehicle: 'DEM222',
    id: 140843,
    tank_volume_start: 25444.999999999894,
    tank_volume_stop: 25396.999999999894,
  },
  {
    timestamp_start: '2022-01-01T23:18:20',
    timestamp_stop: '2022-01-01T23:18:25',
    quantity: 55.6,
    vehicle: 'DEM888',
    id: 140845,
    tank_volume_start: 25396.999999999894,
    tank_volume_stop: 25341.399999999896,
  },
  {
    timestamp_start: '2022-01-01T23:18:40',
    timestamp_stop: '2022-01-01T23:18:45',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 140847,
    tank_volume_start: 25341.399999999896,
    tank_volume_stop: 25290.499999999894,
  },
  {
    timestamp_start: '2022-01-01T23:19:00',
    timestamp_stop: '2022-01-01T23:19:06',
    quantity: 51.4,
    vehicle: 'DEM888',
    id: 140849,
    tank_volume_start: 25290.499999999894,
    tank_volume_stop: 25239.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:19:21',
    timestamp_stop: '2022-01-01T23:19:27',
    quantity: 59.4,
    vehicle: 'DEM666',
    id: 140850,
    tank_volume_start: 25239.099999999893,
    tank_volume_stop: 25179.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:19:42',
    timestamp_stop: '2022-01-01T23:19:47',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 140853,
    tank_volume_start: 25179.69999999989,
    tank_volume_stop: 25124.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:20:02',
    timestamp_stop: '2022-01-01T23:20:07',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 140855,
    tank_volume_start: 25124.099999999893,
    tank_volume_stop: 25070.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:20:22',
    timestamp_stop: '2022-01-01T23:20:28',
    quantity: 58.0,
    vehicle: 'DEM888',
    id: 140857,
    tank_volume_start: 25070.19999999989,
    tank_volume_stop: 25012.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:20:43',
    timestamp_stop: '2022-01-01T23:20:49',
    quantity: 61.2,
    vehicle: 'DEM666',
    id: 140859,
    tank_volume_start: 25012.19999999989,
    tank_volume_stop: 24950.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:21:04',
    timestamp_stop: '2022-01-01T23:21:09',
    quantity: 47.5,
    vehicle: 'DEM888',
    id: 140861,
    tank_volume_start: 24950.99999999989,
    tank_volume_stop: 24903.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:21:24',
    timestamp_stop: '2022-01-01T23:21:30',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 140863,
    tank_volume_start: 24903.49999999989,
    tank_volume_stop: 24844.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:21:45',
    timestamp_stop: '2022-01-01T23:21:51',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 140865,
    tank_volume_start: 24844.59999999989,
    tank_volume_stop: 24788.699999999888,
  },
  {
    timestamp_start: '2022-01-01T23:22:06',
    timestamp_stop: '2022-01-01T23:22:11',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 140867,
    tank_volume_start: 24788.699999999888,
    tank_volume_stop: 24733.199999999888,
  },
  {
    timestamp_start: '2022-01-01T23:22:26',
    timestamp_stop: '2022-01-01T23:22:31',
    quantity: 49.3,
    vehicle: 'DEM111',
    id: 140869,
    tank_volume_start: 24733.199999999888,
    tank_volume_stop: 24683.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:22:46',
    timestamp_stop: '2022-01-01T23:22:52',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 140871,
    tank_volume_start: 24683.89999999989,
    tank_volume_stop: 24629.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:23:07',
    timestamp_stop: '2022-01-01T23:23:12',
    quantity: 56.7,
    vehicle: 'DEM222',
    id: 140873,
    tank_volume_start: 24629.09999999989,
    tank_volume_stop: 24572.39999999989,
  },
  {
    timestamp_start: '2022-01-01T23:23:27',
    timestamp_stop: '2022-01-01T23:23:33',
    quantity: 58.0,
    vehicle: 'DEM222',
    id: 140875,
    tank_volume_start: 24572.39999999989,
    tank_volume_stop: 24514.39999999989,
  },
  {
    timestamp_start: '2022-01-01T23:23:48',
    timestamp_stop: '2022-01-01T23:23:53',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 140877,
    tank_volume_start: 24514.39999999989,
    tank_volume_stop: 24460.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:24:08',
    timestamp_stop: '2022-01-01T23:24:14',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 140879,
    tank_volume_start: 24460.59999999989,
    tank_volume_stop: 24403.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:24:29',
    timestamp_stop: '2022-01-01T23:24:35',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 140881,
    tank_volume_start: 24403.59999999989,
    tank_volume_stop: 24345.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:24:50',
    timestamp_stop: '2022-01-01T23:24:56',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 140883,
    tank_volume_start: 24345.49999999989,
    tank_volume_stop: 24289.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:25:11',
    timestamp_stop: '2022-01-01T23:25:17',
    quantity: 60.5,
    vehicle: 'DEM888',
    id: 140885,
    tank_volume_start: 24289.19999999989,
    tank_volume_stop: 24228.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:25:32',
    timestamp_stop: '2022-01-01T23:25:37',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 140887,
    tank_volume_start: 24228.69999999989,
    tank_volume_stop: 24171.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:25:52',
    timestamp_stop: '2022-01-01T23:25:58',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 140889,
    tank_volume_start: 24171.19999999989,
    tank_volume_stop: 24116.899999999892,
  },
  {
    timestamp_start: '2022-01-01T23:26:13',
    timestamp_stop: '2022-01-01T23:26:19',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 140891,
    tank_volume_start: 24116.899999999892,
    tank_volume_stop: 24059.399999999892,
  },
  {
    timestamp_start: '2022-01-01T23:26:34',
    timestamp_stop: '2022-01-01T23:26:39',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 140893,
    tank_volume_start: 24059.399999999892,
    tank_volume_stop: 24001.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:26:54',
    timestamp_stop: '2022-01-01T23:27:00',
    quantity: 53.9,
    vehicle: 'DEM444',
    id: 140895,
    tank_volume_start: 24001.19999999989,
    tank_volume_stop: 23947.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:27:15',
    timestamp_stop: '2022-01-01T23:27:21',
    quantity: 62.3,
    vehicle: 'DEM222',
    id: 140897,
    tank_volume_start: 23947.29999999989,
    tank_volume_stop: 23884.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:27:36',
    timestamp_stop: '2022-01-01T23:27:42',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 140899,
    tank_volume_start: 23884.99999999989,
    tank_volume_stop: 23827.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:27:57',
    timestamp_stop: '2022-01-01T23:28:02',
    quantity: 48.7,
    vehicle: 'DEM222',
    id: 140901,
    tank_volume_start: 23827.29999999989,
    tank_volume_stop: 23778.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:28:17',
    timestamp_stop: '2022-01-01T23:28:22',
    quantity: 50.7,
    vehicle: 'DEM222',
    id: 140903,
    tank_volume_start: 23778.59999999989,
    tank_volume_stop: 23727.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:28:37',
    timestamp_stop: '2022-01-01T23:28:42',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 140905,
    tank_volume_start: 23727.89999999989,
    tank_volume_stop: 23672.79999999989,
  },
  {
    timestamp_start: '2022-01-01T23:28:57',
    timestamp_stop: '2022-01-01T23:29:03',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 140907,
    tank_volume_start: 23672.79999999989,
    tank_volume_stop: 23617.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:29:18',
    timestamp_stop: '2022-01-01T23:29:23',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 140909,
    tank_volume_start: 23617.99999999989,
    tank_volume_stop: 23565.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:29:38',
    timestamp_stop: '2022-01-01T23:29:43',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 140911,
    tank_volume_start: 23565.69999999989,
    tank_volume_stop: 23512.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:29:58',
    timestamp_stop: '2022-01-01T23:30:03',
    quantity: 51.8,
    vehicle: 'DEM444',
    id: 140913,
    tank_volume_start: 23512.99999999989,
    tank_volume_stop: 23461.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:30:18',
    timestamp_stop: '2022-01-01T23:30:24',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 140915,
    tank_volume_start: 23461.19999999989,
    tank_volume_stop: 23404.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:30:39',
    timestamp_stop: '2022-01-01T23:30:45',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 140917,
    tank_volume_start: 23404.49999999989,
    tank_volume_stop: 23346.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:31:00',
    timestamp_stop: '2022-01-01T23:31:05',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 140919,
    tank_volume_start: 23346.29999999989,
    tank_volume_stop: 23296.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:31:20',
    timestamp_stop: '2022-01-01T23:31:26',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 140921,
    tank_volume_start: 23296.59999999989,
    tank_volume_stop: 23238.29999999989,
  },
  {
    timestamp_start: '2022-01-01T23:31:41',
    timestamp_stop: '2022-01-01T23:31:46',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 140923,
    tank_volume_start: 23238.29999999989,
    tank_volume_stop: 23181.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:32:01',
    timestamp_stop: '2022-01-01T23:32:08',
    quantity: 63.5,
    vehicle: 'DEM555',
    id: 140925,
    tank_volume_start: 23181.49999999989,
    tank_volume_stop: 23117.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:32:23',
    timestamp_stop: '2022-01-01T23:32:28',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 140927,
    tank_volume_start: 23117.99999999989,
    tank_volume_stop: 23061.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:32:43',
    timestamp_stop: '2022-01-01T23:32:49',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 140929,
    tank_volume_start: 23061.59999999989,
    tank_volume_stop: 23007.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:33:04',
    timestamp_stop: '2022-01-01T23:33:10',
    quantity: 59.0,
    vehicle: 'DEM444',
    id: 140931,
    tank_volume_start: 23007.99999999989,
    tank_volume_stop: 22948.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:33:25',
    timestamp_stop: '2022-01-01T23:33:30',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 140933,
    tank_volume_start: 22948.99999999989,
    tank_volume_stop: 22895.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:33:45',
    timestamp_stop: '2022-01-01T23:33:51',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 140935,
    tank_volume_start: 22895.49999999989,
    tank_volume_stop: 22839.59999999989,
  },
  {
    timestamp_start: '2022-01-01T23:34:06',
    timestamp_stop: '2022-01-01T23:34:11',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 140937,
    tank_volume_start: 22839.59999999989,
    tank_volume_stop: 22785.199999999888,
  },
  {
    timestamp_start: '2022-01-01T23:34:26',
    timestamp_stop: '2022-01-01T23:34:32',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 140939,
    tank_volume_start: 22785.199999999888,
    tank_volume_stop: 22727.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:34:47',
    timestamp_stop: '2022-01-01T23:34:51',
    quantity: 47.0,
    vehicle: 'DEM000',
    id: 140941,
    tank_volume_start: 22727.09999999989,
    tank_volume_stop: 22680.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:35:06',
    timestamp_stop: '2022-01-01T23:35:12',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 140943,
    tank_volume_start: 22680.09999999989,
    tank_volume_stop: 22625.79999999989,
  },
  {
    timestamp_start: '2022-01-01T23:35:27',
    timestamp_stop: '2022-01-01T23:35:33',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 140945,
    tank_volume_start: 22625.79999999989,
    tank_volume_stop: 22568.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:35:48',
    timestamp_stop: '2022-01-01T23:35:53',
    quantity: 51.9,
    vehicle: 'DEM777',
    id: 140947,
    tank_volume_start: 22568.99999999989,
    tank_volume_stop: 22517.09999999989,
  },
  {
    timestamp_start: '2022-01-01T23:36:08',
    timestamp_stop: '2022-01-01T23:36:14',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 140949,
    tank_volume_start: 22517.09999999989,
    tank_volume_stop: 22459.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:36:29',
    timestamp_stop: '2022-01-01T23:36:35',
    quantity: 61.5,
    vehicle: 'DEM666',
    id: 140951,
    tank_volume_start: 22459.99999999989,
    tank_volume_stop: 22398.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:36:50',
    timestamp_stop: '2022-01-01T23:36:55',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 140953,
    tank_volume_start: 22398.49999999989,
    tank_volume_stop: 22341.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:37:10',
    timestamp_stop: '2022-01-01T23:37:16',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 140955,
    tank_volume_start: 22341.19999999989,
    tank_volume_stop: 22288.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:37:31',
    timestamp_stop: '2022-01-01T23:37:36',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 140957,
    tank_volume_start: 22288.69999999989,
    tank_volume_stop: 22238.399999999892,
  },
  {
    timestamp_start: '2022-01-01T23:37:51',
    timestamp_stop: '2022-01-01T23:37:56',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 140959,
    tank_volume_start: 22238.399999999892,
    tank_volume_stop: 22181.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:38:11',
    timestamp_stop: '2022-01-01T23:38:18',
    quantity: 62.3,
    vehicle: 'DEM777',
    id: 140961,
    tank_volume_start: 22181.19999999989,
    tank_volume_stop: 22118.899999999892,
  },
  {
    timestamp_start: '2022-01-01T23:38:33',
    timestamp_stop: '2022-01-01T23:38:38',
    quantity: 57.1,
    vehicle: 'DEM111',
    id: 140963,
    tank_volume_start: 22118.899999999892,
    tank_volume_stop: 22061.799999999894,
  },
  {
    timestamp_start: '2022-01-01T23:38:53',
    timestamp_stop: '2022-01-01T23:38:59',
    quantity: 61.2,
    vehicle: 'DEM444',
    id: 140965,
    tank_volume_start: 22061.799999999894,
    tank_volume_stop: 22000.599999999893,
  },
  {
    timestamp_start: '2022-01-01T23:39:14',
    timestamp_stop: '2022-01-01T23:39:20',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 140967,
    tank_volume_start: 22000.599999999893,
    tank_volume_stop: 21945.69999999989,
  },
  {
    timestamp_start: '2022-01-01T23:39:35',
    timestamp_stop: '2022-01-01T23:39:40',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 140969,
    tank_volume_start: 21945.69999999989,
    tank_volume_stop: 21891.099999999893,
  },
  {
    timestamp_start: '2022-01-01T23:39:55',
    timestamp_stop: '2022-01-01T23:40:01',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 140971,
    tank_volume_start: 21891.099999999893,
    tank_volume_stop: 21836.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:40:16',
    timestamp_stop: '2022-01-01T23:40:21',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 140973,
    tank_volume_start: 21836.19999999989,
    tank_volume_stop: 21780.19999999989,
  },
  {
    timestamp_start: '2022-01-01T23:40:36',
    timestamp_stop: '2022-01-01T23:40:42',
    quantity: 52.6,
    vehicle: 'DEM444',
    id: 140975,
    tank_volume_start: 21780.19999999989,
    tank_volume_stop: 21727.599999999893,
  },
  {
    timestamp_start: '2022-01-01T23:40:57',
    timestamp_stop: '2022-01-01T23:41:03',
    quantity: 58.3,
    vehicle: 'DEM000',
    id: 140977,
    tank_volume_start: 21727.599999999893,
    tank_volume_stop: 21669.299999999894,
  },
  {
    timestamp_start: '2022-01-01T23:41:18',
    timestamp_stop: '2022-01-01T23:41:24',
    quantity: 59.7,
    vehicle: 'DEM777',
    id: 140979,
    tank_volume_start: 21669.299999999894,
    tank_volume_stop: 21609.599999999893,
  },
  {
    timestamp_start: '2022-01-01T23:41:39',
    timestamp_stop: '2022-01-01T23:41:44',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 140981,
    tank_volume_start: 21609.599999999893,
    tank_volume_stop: 21559.299999999894,
  },
  {
    timestamp_start: '2022-01-01T23:41:59',
    timestamp_stop: '2022-01-01T23:42:04',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 140983,
    tank_volume_start: 21559.299999999894,
    tank_volume_stop: 21508.699999999895,
  },
  {
    timestamp_start: '2022-01-01T23:42:19',
    timestamp_stop: '2022-01-01T23:42:24',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 140985,
    tank_volume_start: 21508.699999999895,
    tank_volume_stop: 21455.899999999896,
  },
  {
    timestamp_start: '2022-01-01T23:42:39',
    timestamp_stop: '2022-01-01T23:42:44',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 140987,
    tank_volume_start: 21455.899999999896,
    tank_volume_stop: 21401.599999999897,
  },
  {
    timestamp_start: '2022-01-01T23:42:59',
    timestamp_stop: '2022-01-01T23:43:05',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 140989,
    tank_volume_start: 21401.599999999897,
    tank_volume_stop: 21344.799999999897,
  },
  {
    timestamp_start: '2022-01-01T23:43:20',
    timestamp_stop: '2022-01-01T23:43:25',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 140991,
    tank_volume_start: 21344.799999999897,
    tank_volume_stop: 21290.299999999897,
  },
  {
    timestamp_start: '2022-01-01T23:43:40',
    timestamp_stop: '2022-01-01T23:43:45',
    quantity: 49.6,
    vehicle: 'DEM888',
    id: 140993,
    tank_volume_start: 21290.299999999897,
    tank_volume_stop: 21240.6999999999,
  },
  {
    timestamp_start: '2022-01-01T23:44:00',
    timestamp_stop: '2022-01-01T23:44:06',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 140995,
    tank_volume_start: 21240.6999999999,
    tank_volume_stop: 21183.299999999897,
  },
  {
    timestamp_start: '2022-01-01T23:44:21',
    timestamp_stop: '2022-01-01T23:44:27',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 140997,
    tank_volume_start: 21183.299999999897,
    tank_volume_stop: 21129.6999999999,
  },
  {
    timestamp_start: '2022-01-01T23:44:42',
    timestamp_stop: '2022-01-01T23:44:47',
    quantity: 50.5,
    vehicle: 'DEM000',
    id: 140999,
    tank_volume_start: 21129.6999999999,
    tank_volume_stop: 21079.1999999999,
  },
  {
    timestamp_start: '2022-01-01T23:45:02',
    timestamp_stop: '2022-01-01T23:45:06',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 141001,
    tank_volume_start: 21079.1999999999,
    tank_volume_stop: 21031.5999999999,
  },
  {
    timestamp_start: '2022-01-01T23:45:21',
    timestamp_stop: '2022-01-01T23:45:26',
    quantity: 49.5,
    vehicle: 'DEM000',
    id: 141003,
    tank_volume_start: 21031.5999999999,
    tank_volume_stop: 20982.0999999999,
  },
  {
    timestamp_start: '2022-01-01T23:45:41',
    timestamp_stop: '2022-01-01T23:45:47',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 141005,
    tank_volume_start: 20982.0999999999,
    tank_volume_stop: 20925.5999999999,
  },
  {
    timestamp_start: '2022-01-01T23:46:02',
    timestamp_stop: '2022-01-01T23:46:08',
    quantity: 58.7,
    vehicle: 'DEM444',
    id: 141007,
    tank_volume_start: 20925.5999999999,
    tank_volume_stop: 20866.8999999999,
  },
  {
    timestamp_start: '2022-01-01T23:46:23',
    timestamp_stop: '2022-01-01T23:46:28',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 141009,
    tank_volume_start: 20866.8999999999,
    tank_volume_stop: 20813.2999999999,
  },
  {
    timestamp_start: '2022-01-01T23:46:43',
    timestamp_stop: '2022-01-01T23:46:49',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 141011,
    tank_volume_start: 20813.2999999999,
    tank_volume_stop: 20756.0999999999,
  },
  {
    timestamp_start: '2022-01-01T23:47:04',
    timestamp_stop: '2022-01-01T23:47:09',
    quantity: 46.7,
    vehicle: 'DEM222',
    id: 141013,
    tank_volume_start: 20756.0999999999,
    tank_volume_stop: 20709.3999999999,
  },
  {
    timestamp_start: '2022-01-01T23:47:24',
    timestamp_stop: '2022-01-01T23:47:29',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 141015,
    tank_volume_start: 20709.3999999999,
    tank_volume_stop: 20653.3999999999,
  },
  {
    timestamp_start: '2022-01-01T23:47:44',
    timestamp_stop: '2022-01-01T23:47:50',
    quantity: 61.5,
    vehicle: 'DEM111',
    id: 141017,
    tank_volume_start: 20653.3999999999,
    tank_volume_stop: 20591.8999999999,
  },
  {
    timestamp_start: '2022-01-01T23:48:05',
    timestamp_stop: '2022-01-01T23:48:11',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 141019,
    tank_volume_start: 20591.8999999999,
    tank_volume_stop: 20535.8999999999,
  },
  {
    timestamp_start: '2022-01-01T23:48:26',
    timestamp_stop: '2022-01-01T23:48:32',
    quantity: 60.6,
    vehicle: 'DEM444',
    id: 141021,
    tank_volume_start: 20535.8999999999,
    tank_volume_stop: 20475.2999999999,
  },
  {
    timestamp_start: '2022-01-01T23:48:47',
    timestamp_stop: '2022-01-01T23:48:52',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 141023,
    tank_volume_start: 20475.2999999999,
    tank_volume_stop: 20420.3999999999,
  },
  {
    timestamp_start: '2022-01-01T23:49:07',
    timestamp_stop: '2022-01-01T23:49:12',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 141025,
    tank_volume_start: 20420.3999999999,
    tank_volume_stop: 20371.0999999999,
  },
  {
    timestamp_start: '2022-01-01T23:49:27',
    timestamp_stop: '2022-01-01T23:49:33',
    quantity: 51.3,
    vehicle: 'DEM777',
    id: 141027,
    tank_volume_start: 20371.0999999999,
    tank_volume_stop: 20319.7999999999,
  },
  {
    timestamp_start: '2022-01-01T23:49:48',
    timestamp_stop: '2022-01-01T23:49:52',
    quantity: 49.7,
    vehicle: 'DEM333',
    id: 141029,
    tank_volume_start: 20319.7999999999,
    tank_volume_stop: 20270.0999999999,
  },
  {
    timestamp_start: '2022-01-01T23:50:07',
    timestamp_stop: '2022-01-01T23:50:13',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 141031,
    tank_volume_start: 20270.0999999999,
    tank_volume_stop: 20217.1999999999,
  },
  {
    timestamp_start: '2022-01-01T23:50:28',
    timestamp_stop: '2022-01-01T23:50:34',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 141033,
    tank_volume_start: 20217.1999999999,
    tank_volume_stop: 20159.499999999898,
  },
  {
    timestamp_start: '2022-01-01T23:50:49',
    timestamp_stop: '2022-01-01T23:50:54',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 141035,
    tank_volume_start: 20159.499999999898,
    tank_volume_stop: 20102.999999999898,
  },
  {
    timestamp_start: '2022-01-01T23:51:09',
    timestamp_stop: '2022-01-01T23:51:15',
    quantity: 59.2,
    vehicle: 'DEM000',
    id: 141037,
    tank_volume_start: 20102.999999999898,
    tank_volume_stop: 20043.799999999897,
  },
  {
    timestamp_start: '2022-01-01T23:51:30',
    timestamp_stop: '2022-01-01T23:51:36',
    quantity: 56.9,
    vehicle: 'DEM777',
    id: 141039,
    tank_volume_start: 20043.799999999897,
    tank_volume_stop: 19986.899999999896,
  },
  {
    timestamp_start: '2022-01-01T23:51:51',
    timestamp_stop: '2022-01-01T23:51:56',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 141041,
    tank_volume_start: 39986.89999999989,
    tank_volume_stop: 39930.799999999894,
  },
  {
    timestamp_start: '2022-01-01T23:52:11',
    timestamp_stop: '2022-01-01T23:52:18',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 141043,
    tank_volume_start: 39930.799999999894,
    tank_volume_stop: 39867.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:52:33',
    timestamp_stop: '2022-01-01T23:52:38',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 141045,
    tank_volume_start: 39867.89999999989,
    tank_volume_stop: 39811.89999999989,
  },
  {
    timestamp_start: '2022-01-01T23:52:53',
    timestamp_stop: '2022-01-01T23:52:59',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 141047,
    tank_volume_start: 39811.89999999989,
    tank_volume_stop: 39756.199999999895,
  },
  {
    timestamp_start: '2022-01-01T23:53:14',
    timestamp_stop: '2022-01-01T23:53:20',
    quantity: 62.7,
    vehicle: 'DEM444',
    id: 141049,
    tank_volume_start: 39756.199999999895,
    tank_volume_stop: 39693.4999999999,
  },
  {
    timestamp_start: '2022-01-01T23:53:35',
    timestamp_stop: '2022-01-01T23:53:40',
    quantity: 47.5,
    vehicle: 'DEM888',
    id: 141051,
    tank_volume_start: 39693.4999999999,
    tank_volume_stop: 39645.9999999999,
  },
  {
    timestamp_start: '2022-01-01T23:53:55',
    timestamp_stop: '2022-01-01T23:54:00',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 141053,
    tank_volume_start: 39645.9999999999,
    tank_volume_stop: 39593.2999999999,
  },
  {
    timestamp_start: '2022-01-01T23:54:15',
    timestamp_stop: '2022-01-01T23:54:20',
    quantity: 47.7,
    vehicle: 'DEM222',
    id: 141055,
    tank_volume_start: 39593.2999999999,
    tank_volume_stop: 39545.599999999904,
  },
  {
    timestamp_start: '2022-01-01T23:54:35',
    timestamp_stop: '2022-01-01T23:54:41',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 141057,
    tank_volume_start: 39545.599999999904,
    tank_volume_stop: 39489.599999999904,
  },
  {
    timestamp_start: '2022-01-01T23:54:56',
    timestamp_stop: '2022-01-01T23:55:01',
    quantity: 55.4,
    vehicle: 'DEM222',
    id: 141059,
    tank_volume_start: 39489.599999999904,
    tank_volume_stop: 39434.1999999999,
  },
  {
    timestamp_start: '2022-01-01T23:55:16',
    timestamp_stop: '2022-01-01T23:55:21',
    quantity: 45.9,
    vehicle: 'DEM222',
    id: 141061,
    tank_volume_start: 39434.1999999999,
    tank_volume_stop: 39388.2999999999,
  },
  {
    timestamp_start: '2022-01-01T23:55:36',
    timestamp_stop: '2022-01-01T23:55:41',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 141063,
    tank_volume_start: 39388.2999999999,
    tank_volume_stop: 39332.1999999999,
  },
  {
    timestamp_start: '2022-01-01T23:55:56',
    timestamp_stop: '2022-01-01T23:56:03',
    quantity: 63.9,
    vehicle: 'DEM888',
    id: 141065,
    tank_volume_start: 39332.1999999999,
    tank_volume_stop: 39268.2999999999,
  },
  {
    timestamp_start: '2022-01-01T23:56:18',
    timestamp_stop: '2022-01-01T23:56:23',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 141067,
    tank_volume_start: 39268.2999999999,
    tank_volume_stop: 39213.4999999999,
  },
  {
    timestamp_start: '2022-01-01T23:56:38',
    timestamp_stop: '2022-01-01T23:56:44',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 141069,
    tank_volume_start: 39213.4999999999,
    tank_volume_stop: 39150.5999999999,
  },
  {
    timestamp_start: '2022-01-01T23:56:59',
    timestamp_stop: '2022-01-01T23:57:06',
    quantity: 62.3,
    vehicle: 'DEM888',
    id: 141071,
    tank_volume_start: 39150.5999999999,
    tank_volume_stop: 39088.299999999894,
  },
  {
    timestamp_start: '2022-01-01T23:57:21',
    timestamp_stop: '2022-01-01T23:57:26',
    quantity: 57.3,
    vehicle: 'DEM333',
    id: 141073,
    tank_volume_start: 39088.299999999894,
    tank_volume_stop: 39030.99999999989,
  },
  {
    timestamp_start: '2022-01-01T23:57:41',
    timestamp_stop: '2022-01-01T23:57:46',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 141075,
    tank_volume_start: 39030.99999999989,
    tank_volume_stop: 38981.49999999989,
  },
  {
    timestamp_start: '2022-01-01T23:58:01',
    timestamp_stop: '2022-01-01T23:58:06',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 141077,
    tank_volume_start: 38981.49999999989,
    tank_volume_stop: 38930.799999999894,
  },
  {
    timestamp_start: '2022-01-01T23:58:21',
    timestamp_stop: '2022-01-01T23:58:27',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 141079,
    tank_volume_start: 38930.799999999894,
    tank_volume_stop: 38872.5999999999,
  },
  {
    timestamp_start: '2022-01-01T23:58:42',
    timestamp_stop: '2022-01-01T23:58:48',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 141081,
    tank_volume_start: 38872.5999999999,
    tank_volume_stop: 38817.8999999999,
  },
  {
    timestamp_start: '2022-01-01T23:59:03',
    timestamp_stop: '2022-01-01T23:59:08',
    quantity: 57.4,
    vehicle: 'DEM555',
    id: 141083,
    tank_volume_start: 38817.8999999999,
    tank_volume_stop: 38760.4999999999,
  },
  {
    timestamp_start: '2022-01-01T23:59:23',
    timestamp_stop: '2022-01-01T23:59:29',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 141085,
    tank_volume_start: 38760.4999999999,
    tank_volume_stop: 38702.7999999999,
  },
  {
    timestamp_start: '2022-01-01T23:59:44',
    timestamp_stop: '2022-01-01T23:59:49',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 141087,
    tank_volume_start: 38702.7999999999,
    tank_volume_stop: 38651.8999999999,
  },
];
