export default [
  {
    timestamp_start: '2022-01-01T00:00:00',
    timestamp_stop: '2022-01-01T00:00:06',
    quantity: 60.0,
    vehicle: 'DEM888',
    id: 132657,
    tank_volume_start: 5000.0,
    tank_volume_stop: 4941.0,
  },
  {
    timestamp_start: '2022-01-01T00:00:21',
    timestamp_stop: '2022-01-01T00:00:26',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 132659,
    tank_volume_start: 4941.0,
    tank_volume_stop: 4895.8,
  },
  {
    timestamp_start: '2022-01-01T00:00:41',
    timestamp_stop: '2022-01-01T00:00:48',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 132661,
    tank_volume_start: 4895.8,
    tank_volume_stop: 4844.0,
  },
  {
    timestamp_start: '2022-01-01T00:01:03',
    timestamp_stop: '2022-01-01T00:01:08',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 132663,
    tank_volume_start: 4844.0,
    tank_volume_stop: 4785.9,
  },
  {
    timestamp_start: '2022-01-01T00:01:23',
    timestamp_stop: '2022-01-01T00:01:29',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 132665,
    tank_volume_start: 4785.9,
    tank_volume_stop: 4736.599999999999,
  },
  {
    timestamp_start: '2022-01-01T00:01:44',
    timestamp_stop: '2022-01-01T00:01:50',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 132667,
    tank_volume_start: 4736.599999999999,
    tank_volume_stop: 4682.7,
  },
  {
    timestamp_start: '2022-01-01T00:02:05',
    timestamp_stop: '2022-01-01T00:02:10',
    quantity: 57.8,
    vehicle: 'DEM555',
    id: 132669,
    tank_volume_start: 4682.7,
    tank_volume_stop: 4622.4,
  },
  {
    timestamp_start: '2022-01-01T00:02:25',
    timestamp_stop: '2022-01-01T00:02:31',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 132671,
    tank_volume_start: 4622.4,
    tank_volume_stop: 4558.2,
  },
  {
    timestamp_start: '2022-01-01T00:02:46',
    timestamp_stop: '2022-01-01T00:02:52',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 132673,
    tank_volume_start: 4558.2,
    tank_volume_stop: 4509.7,
  },
  {
    timestamp_start: '2022-01-01T00:03:07',
    timestamp_stop: '2022-01-01T00:03:12',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 132675,
    tank_volume_start: 4509.7,
    tank_volume_stop: 4460.9,
  },
  {
    timestamp_start: '2022-01-01T00:03:27',
    timestamp_stop: '2022-01-01T00:03:32',
    quantity: 55.9,
    vehicle: 'DEM111',
    id: 132677,
    tank_volume_start: 4460.9,
    tank_volume_stop: 4414.099999999999,
  },
  {
    timestamp_start: '2022-01-01T00:03:47',
    timestamp_stop: '2022-01-01T00:03:53',
    quantity: 57.6,
    vehicle: 'DEM888',
    id: 132679,
    tank_volume_start: 4414.099999999999,
    tank_volume_stop: 4359.4,
  },
  {
    timestamp_start: '2022-01-01T00:04:08',
    timestamp_stop: '2022-01-01T00:04:13',
    quantity: 53.0,
    vehicle: 'DEM777',
    id: 132681,
    tank_volume_start: 4359.4,
    tank_volume_stop: 4308.7,
  },
  {
    timestamp_start: '2022-01-01T00:04:28',
    timestamp_stop: '2022-01-01T00:04:33',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 132683,
    tank_volume_start: 4308.7,
    tank_volume_stop: 4251.9,
  },
  {
    timestamp_start: '2022-01-01T00:04:48',
    timestamp_stop: '2022-01-01T00:04:53',
    quantity: 49.2,
    vehicle: 'DEM222',
    id: 132685,
    tank_volume_start: 4251.9,
    tank_volume_stop: 4198.599999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:08',
    timestamp_stop: '2022-01-01T00:05:14',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 132687,
    tank_volume_start: 4198.599999999999,
    tank_volume_stop: 4148.499999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:29',
    timestamp_stop: '2022-01-01T00:05:34',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 132689,
    tank_volume_start: 4148.499999999999,
    tank_volume_stop: 4098.599999999999,
  },
  {
    timestamp_start: '2022-01-01T00:05:49',
    timestamp_stop: '2022-01-01T00:05:55',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 132691,
    tank_volume_start: 4098.599999999999,
    tank_volume_stop: 4043.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T00:06:10',
    timestamp_stop: '2022-01-01T00:06:15',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 132693,
    tank_volume_start: 4043.0999999999995,
    tank_volume_stop: 3997.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T00:06:30',
    timestamp_stop: '2022-01-01T00:06:35',
    quantity: 48.9,
    vehicle: 'DEM111',
    id: 132695,
    tank_volume_start: 3997.2999999999993,
    tank_volume_stop: 3941.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T00:06:50',
    timestamp_stop: '2022-01-01T00:06:55',
    quantity: 50.3,
    vehicle: 'DEM777',
    id: 132697,
    tank_volume_start: 3941.2999999999993,
    tank_volume_stop: 3895.399999999999,
  },
  {
    timestamp_start: '2022-01-01T00:07:10',
    timestamp_stop: '2022-01-01T00:07:15',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 132699,
    tank_volume_start: 3895.399999999999,
    tank_volume_stop: 3838.599999999999,
  },
  {
    timestamp_start: '2022-01-01T00:07:30',
    timestamp_stop: '2022-01-01T00:07:36',
    quantity: 61.8,
    vehicle: 'DEM666',
    id: 132701,
    tank_volume_start: 3838.599999999999,
    tank_volume_stop: 3784.099999999999,
  },
  {
    timestamp_start: '2022-01-01T00:07:51',
    timestamp_stop: '2022-01-01T00:07:56',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 132703,
    tank_volume_start: 3784.099999999999,
    tank_volume_stop: 3726.199999999999,
  },
  {
    timestamp_start: '2022-01-01T00:08:11',
    timestamp_stop: '2022-01-01T00:08:18',
    quantity: 62.8,
    vehicle: 'DEM666',
    id: 132705,
    tank_volume_start: 3726.199999999999,
    tank_volume_stop: 3668.199999999999,
  },
  {
    timestamp_start: '2022-01-01T00:08:33',
    timestamp_stop: '2022-01-01T00:08:39',
    quantity: 60.9,
    vehicle: 'DEM000',
    id: 132707,
    tank_volume_start: 3668.199999999999,
    tank_volume_stop: 3619.699999999999,
  },
  {
    timestamp_start: '2022-01-01T00:08:54',
    timestamp_stop: '2022-01-01T00:08:59',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 132709,
    tank_volume_start: 3619.699999999999,
    tank_volume_stop: 3562.999999999999,
  },
  {
    timestamp_start: '2022-01-01T00:09:14',
    timestamp_stop: '2022-01-01T00:09:20',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 132711,
    tank_volume_start: 3562.999999999999,
    tank_volume_stop: 3510.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T00:09:35',
    timestamp_stop: '2022-01-01T00:09:41',
    quantity: 56.4,
    vehicle: 'DEM444',
    id: 132713,
    tank_volume_start: 3510.2999999999993,
    tank_volume_stop: 3461.899999999999,
  },
  {
    timestamp_start: '2022-01-01T00:09:56',
    timestamp_stop: '2022-01-01T00:10:02',
    quantity: 61.5,
    vehicle: 'DEM333',
    id: 132715,
    tank_volume_start: 3461.899999999999,
    tank_volume_stop: 3411.099999999999,
  },
  {
    timestamp_start: '2022-01-01T00:10:17',
    timestamp_stop: '2022-01-01T00:10:22',
    quantity: 55.3,
    vehicle: 'DEM777',
    id: 132717,
    tank_volume_start: 3411.099999999999,
    tank_volume_stop: 3354.999999999999,
  },
  {
    timestamp_start: '2022-01-01T00:10:37',
    timestamp_stop: '2022-01-01T00:10:43',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 132719,
    tank_volume_start: 3354.999999999999,
    tank_volume_stop: 3299.099999999999,
  },
  {
    timestamp_start: '2022-01-01T00:10:58',
    timestamp_stop: '2022-01-01T00:11:03',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 132721,
    tank_volume_start: 3299.099999999999,
    tank_volume_stop: 3244.699999999999,
  },
  {
    timestamp_start: '2022-01-01T00:11:18',
    timestamp_stop: '2022-01-01T00:11:24',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 132723,
    tank_volume_start: 3244.699999999999,
    tank_volume_stop: 3188.099999999999,
  },
  {
    timestamp_start: '2022-01-01T00:11:39',
    timestamp_stop: '2022-01-01T00:11:45',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 132725,
    tank_volume_start: 3188.099999999999,
    tank_volume_stop: 3139.799999999999,
  },
  {
    timestamp_start: '2022-01-01T00:12:00',
    timestamp_stop: '2022-01-01T00:12:05',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 132727,
    tank_volume_start: 3139.799999999999,
    tank_volume_stop: 3082.799999999999,
  },
  {
    timestamp_start: '2022-01-01T00:12:20',
    timestamp_stop: '2022-01-01T00:12:26',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 132729,
    tank_volume_start: 3082.799999999999,
    tank_volume_stop: 3028.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T00:12:41',
    timestamp_stop: '2022-01-01T00:12:46',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 132731,
    tank_volume_start: 3028.4999999999986,
    tank_volume_stop: 2974.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:13:01',
    timestamp_stop: '2022-01-01T00:13:07',
    quantity: 51.1,
    vehicle: 'DEM222',
    id: 132733,
    tank_volume_start: 2974.5999999999985,
    tank_volume_stop: 2921.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:13:22',
    timestamp_stop: '2022-01-01T00:13:27',
    quantity: 59.2,
    vehicle: 'DEM444',
    id: 132735,
    tank_volume_start: 2921.5999999999985,
    tank_volume_stop: 2867.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:13:42',
    timestamp_stop: '2022-01-01T00:13:47',
    quantity: 47.1,
    vehicle: 'DEM111',
    id: 132737,
    tank_volume_start: 2867.5999999999985,
    tank_volume_stop: 2819.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:14:02',
    timestamp_stop: '2022-01-01T00:14:08',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 132739,
    tank_volume_start: 2819.1999999999985,
    tank_volume_stop: 2767.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T00:14:23',
    timestamp_stop: '2022-01-01T00:14:29',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 132741,
    tank_volume_start: 2767.3999999999983,
    tank_volume_stop: 2714.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:14:44',
    timestamp_stop: '2022-01-01T00:14:50',
    quantity: 57.8,
    vehicle: 'DEM888',
    id: 132743,
    tank_volume_start: 2714.599999999998,
    tank_volume_stop: 2663.799999999998,
  },
  {
    timestamp_start: '2022-01-01T00:15:05',
    timestamp_stop: '2022-01-01T00:15:10',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 132745,
    tank_volume_start: 2663.799999999998,
    tank_volume_stop: 2606.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T00:15:25',
    timestamp_stop: '2022-01-01T00:15:31',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 132747,
    tank_volume_start: 2606.9999999999977,
    tank_volume_stop: 2556.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T00:15:46',
    timestamp_stop: '2022-01-01T00:15:51',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 132749,
    tank_volume_start: 2556.4999999999977,
    tank_volume_stop: 2503.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:16:06',
    timestamp_stop: '2022-01-01T00:16:12',
    quantity: 60.1,
    vehicle: 'DEM000',
    id: 132751,
    tank_volume_start: 2503.0999999999976,
    tank_volume_stop: 2448.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:16:27',
    timestamp_stop: '2022-01-01T00:16:33',
    quantity: 56.1,
    vehicle: 'DEM444',
    id: 132753,
    tank_volume_start: 2448.7999999999975,
    tank_volume_stop: 2391.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:16:48',
    timestamp_stop: '2022-01-01T00:16:53',
    quantity: 46.2,
    vehicle: 'DEM444',
    id: 132755,
    tank_volume_start: 2391.8999999999974,
    tank_volume_stop: 2336.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:17:08',
    timestamp_stop: '2022-01-01T00:17:13',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 132757,
    tank_volume_start: 2336.9999999999973,
    tank_volume_stop: 2278.699999999997,
  },
  {
    timestamp_start: '2022-01-01T00:17:28',
    timestamp_stop: '2022-01-01T00:17:34',
    quantity: 62.4,
    vehicle: 'DEM444',
    id: 132759,
    tank_volume_start: 2278.699999999997,
    tank_volume_stop: 2218.299999999997,
  },
  {
    timestamp_start: '2022-01-01T00:17:49',
    timestamp_stop: '2022-01-01T00:17:55',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 132761,
    tank_volume_start: 2218.299999999997,
    tank_volume_stop: 2160.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:18:10',
    timestamp_stop: '2022-01-01T00:18:15',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 132763,
    tank_volume_start: 2160.199999999997,
    tank_volume_stop: 2108.399999999997,
  },
  {
    timestamp_start: '2022-01-01T00:18:30',
    timestamp_stop: '2022-01-01T00:18:36',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 132765,
    tank_volume_start: 2108.399999999997,
    tank_volume_stop: 2050.899999999997,
  },
  {
    timestamp_start: '2022-01-01T00:18:51',
    timestamp_stop: '2022-01-01T00:18:57',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 132767,
    tank_volume_start: 2050.899999999997,
    tank_volume_stop: 1993.399999999997,
  },
  {
    timestamp_start: '2022-01-01T00:19:12',
    timestamp_stop: '2022-01-01T00:19:17',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 132769,
    tank_volume_start: 3993.399999999997,
    tank_volume_stop: 3938.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:19:32',
    timestamp_stop: '2022-01-01T00:19:37',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 132771,
    tank_volume_start: 3938.199999999997,
    tank_volume_stop: 3884.699999999997,
  },
  {
    timestamp_start: '2022-01-01T00:19:52',
    timestamp_stop: '2022-01-01T00:19:57',
    quantity: 47.8,
    vehicle: 'DEM666',
    id: 132773,
    tank_volume_start: 3884.699999999997,
    tank_volume_stop: 3830.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:20:12',
    timestamp_stop: '2022-01-01T00:20:18',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 132775,
    tank_volume_start: 3830.9999999999973,
    tank_volume_stop: 3781.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:20:33',
    timestamp_stop: '2022-01-01T00:20:39',
    quantity: 61.1,
    vehicle: 'DEM888',
    id: 132777,
    tank_volume_start: 3781.199999999997,
    tank_volume_stop: 3730.299999999997,
  },
  {
    timestamp_start: '2022-01-01T00:20:54',
    timestamp_stop: '2022-01-01T00:20:59',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 132779,
    tank_volume_start: 3730.299999999997,
    tank_volume_stop: 3677.799999999997,
  },
  {
    timestamp_start: '2022-01-01T00:21:14',
    timestamp_stop: '2022-01-01T00:21:19',
    quantity: 49.1,
    vehicle: 'DEM444',
    id: 132781,
    tank_volume_start: 3677.799999999997,
    tank_volume_stop: 3624.699999999997,
  },
  {
    timestamp_start: '2022-01-01T00:21:34',
    timestamp_stop: '2022-01-01T00:21:39',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 132783,
    tank_volume_start: 3624.699999999997,
    tank_volume_stop: 3572.399999999997,
  },
  {
    timestamp_start: '2022-01-01T00:21:54',
    timestamp_stop: '2022-01-01T00:22:00',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 132785,
    tank_volume_start: 3572.399999999997,
    tank_volume_stop: 3519.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:22:15',
    timestamp_stop: '2022-01-01T00:22:20',
    quantity: 48.2,
    vehicle: 'DEM111',
    id: 132787,
    tank_volume_start: 3519.199999999997,
    tank_volume_stop: 3469.099999999997,
  },
  {
    timestamp_start: '2022-01-01T00:22:35',
    timestamp_stop: '2022-01-01T00:22:40',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 132789,
    tank_volume_start: 3469.099999999997,
    tank_volume_stop: 3409.799999999997,
  },
  {
    timestamp_start: '2022-01-01T00:22:55',
    timestamp_stop: '2022-01-01T00:23:01',
    quantity: 61.2,
    vehicle: 'DEM333',
    id: 132791,
    tank_volume_start: 3409.799999999997,
    tank_volume_stop: 3362.299999999997,
  },
  {
    timestamp_start: '2022-01-01T00:23:16',
    timestamp_stop: '2022-01-01T00:23:21',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 132793,
    tank_volume_start: 3362.299999999997,
    tank_volume_stop: 3305.099999999997,
  },
  {
    timestamp_start: '2022-01-01T00:23:36',
    timestamp_stop: '2022-01-01T00:23:42',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 132795,
    tank_volume_start: 3305.099999999997,
    tank_volume_stop: 3253.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:23:57',
    timestamp_stop: '2022-01-01T00:24:03',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 132797,
    tank_volume_start: 3253.199999999997,
    tank_volume_stop: 3196.599999999997,
  },
  {
    timestamp_start: '2022-01-01T00:24:18',
    timestamp_stop: '2022-01-01T00:24:23',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 132799,
    tank_volume_start: 3196.599999999997,
    tank_volume_stop: 3149.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:24:38',
    timestamp_stop: '2022-01-01T00:24:44',
    quantity: 59.7,
    vehicle: 'DEM333',
    id: 132801,
    tank_volume_start: 3149.8999999999974,
    tank_volume_stop: 3087.599999999997,
  },
  {
    timestamp_start: '2022-01-01T00:24:59',
    timestamp_stop: '2022-01-01T00:25:05',
    quantity: 59.7,
    vehicle: 'DEM000',
    id: 132803,
    tank_volume_start: 3087.599999999997,
    tank_volume_stop: 3028.599999999997,
  },
  {
    timestamp_start: '2022-01-01T00:25:20',
    timestamp_stop: '2022-01-01T00:25:25',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 132805,
    tank_volume_start: 3028.599999999997,
    tank_volume_stop: 2972.299999999997,
  },
  {
    timestamp_start: '2022-01-01T00:25:40',
    timestamp_stop: '2022-01-01T00:25:45',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 132807,
    tank_volume_start: 2972.299999999997,
    tank_volume_stop: 2920.599999999997,
  },
  {
    timestamp_start: '2022-01-01T00:26:00',
    timestamp_stop: '2022-01-01T00:26:06',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 132809,
    tank_volume_start: 2920.599999999997,
    tank_volume_stop: 2862.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:26:21',
    timestamp_stop: '2022-01-01T00:26:26',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 132811,
    tank_volume_start: 2862.3999999999974,
    tank_volume_stop: 2800.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:26:41',
    timestamp_stop: '2022-01-01T00:26:46',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 132813,
    tank_volume_start: 2800.4999999999973,
    tank_volume_stop: 2746.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:27:01',
    timestamp_stop: '2022-01-01T00:27:08',
    quantity: 62.0,
    vehicle: 'DEM000',
    id: 132815,
    tank_volume_start: 2746.7999999999975,
    tank_volume_stop: 2689.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:27:23',
    timestamp_stop: '2022-01-01T00:27:28',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 132817,
    tank_volume_start: 2689.0999999999976,
    tank_volume_stop: 2627.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:27:43',
    timestamp_stop: '2022-01-01T00:27:48',
    quantity: 51.6,
    vehicle: 'DEM666',
    id: 132819,
    tank_volume_start: 2627.2999999999975,
    tank_volume_stop: 2571.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:28:03',
    timestamp_stop: '2022-01-01T00:28:09',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 132821,
    tank_volume_start: 2571.3999999999974,
    tank_volume_stop: 2516.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:28:24',
    timestamp_stop: '2022-01-01T00:28:29',
    quantity: 46.4,
    vehicle: 'DEM888',
    id: 132823,
    tank_volume_start: 2516.1999999999975,
    tank_volume_stop: 2467.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:28:44',
    timestamp_stop: '2022-01-01T00:28:50',
    quantity: 62.0,
    vehicle: 'DEM888',
    id: 132825,
    tank_volume_start: 2467.7999999999975,
    tank_volume_stop: 2412.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:29:05',
    timestamp_stop: '2022-01-01T00:29:11',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 132827,
    tank_volume_start: 2412.7999999999975,
    tank_volume_stop: 2358.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:29:26',
    timestamp_stop: '2022-01-01T00:29:31',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 132829,
    tank_volume_start: 2358.3999999999974,
    tank_volume_stop: 2302.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:29:46',
    timestamp_stop: '2022-01-01T00:29:51',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 132831,
    tank_volume_start: 2302.4999999999973,
    tank_volume_stop: 2248.199999999997,
  },
  {
    timestamp_start: '2022-01-01T00:30:06',
    timestamp_stop: '2022-01-01T00:30:12',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 132833,
    tank_volume_start: 2248.199999999997,
    tank_volume_stop: 2197.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:30:27',
    timestamp_stop: '2022-01-01T00:30:33',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 132835,
    tank_volume_start: 2197.9999999999973,
    tank_volume_stop: 2139.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:30:48',
    timestamp_stop: '2022-01-01T00:30:54',
    quantity: 62.2,
    vehicle: 'DEM666',
    id: 132837,
    tank_volume_start: 2139.7999999999975,
    tank_volume_stop: 2085.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T00:31:09',
    timestamp_stop: '2022-01-01T00:31:14',
    quantity: 48.1,
    vehicle: 'DEM444',
    id: 132839,
    tank_volume_start: 2085.4999999999973,
    tank_volume_stop: 2030.7999999999972,
  },
  {
    timestamp_start: '2022-01-01T00:31:29',
    timestamp_stop: '2022-01-01T00:31:34',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 132841,
    tank_volume_start: 2030.7999999999972,
    tank_volume_stop: 1970.7999999999972,
  },
  {
    timestamp_start: '2022-01-01T00:31:49',
    timestamp_stop: '2022-01-01T00:31:54',
    quantity: 46.3,
    vehicle: 'DEM222',
    id: 132843,
    tank_volume_start: 3970.7999999999975,
    tank_volume_stop: 3907.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:32:09',
    timestamp_stop: '2022-01-01T00:32:15',
    quantity: 60.7,
    vehicle: 'DEM111',
    id: 132845,
    tank_volume_start: 3907.5999999999976,
    tank_volume_stop: 3851.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:32:30',
    timestamp_stop: '2022-01-01T00:32:36',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 132847,
    tank_volume_start: 3851.1999999999975,
    tank_volume_stop: 3798.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:32:51',
    timestamp_stop: '2022-01-01T00:32:57',
    quantity: 61.0,
    vehicle: 'DEM222',
    id: 132849,
    tank_volume_start: 3798.0999999999976,
    tank_volume_stop: 3743.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:33:12',
    timestamp_stop: '2022-01-01T00:33:17',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 132851,
    tank_volume_start: 3743.2999999999975,
    tank_volume_stop: 3691.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:33:32',
    timestamp_stop: '2022-01-01T00:33:38',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 132853,
    tank_volume_start: 3691.3999999999974,
    tank_volume_stop: 3636.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:33:53',
    timestamp_stop: '2022-01-01T00:33:58',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 132855,
    tank_volume_start: 3636.2999999999975,
    tank_volume_stop: 3579.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:34:13',
    timestamp_stop: '2022-01-01T00:34:19',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 132857,
    tank_volume_start: 3579.3999999999974,
    tank_volume_stop: 3526.099999999997,
  },
  {
    timestamp_start: '2022-01-01T00:34:34',
    timestamp_stop: '2022-01-01T00:34:39',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 132859,
    tank_volume_start: 3526.099999999997,
    tank_volume_stop: 3471.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:34:54',
    timestamp_stop: '2022-01-01T00:34:59',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 132861,
    tank_volume_start: 3471.8999999999974,
    tank_volume_stop: 3413.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:35:14',
    timestamp_stop: '2022-01-01T00:35:19',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 132863,
    tank_volume_start: 3413.3999999999974,
    tank_volume_stop: 3362.099999999997,
  },
  {
    timestamp_start: '2022-01-01T00:35:34',
    timestamp_stop: '2022-01-01T00:35:40',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 132865,
    tank_volume_start: 3362.099999999997,
    tank_volume_stop: 3305.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T00:35:55',
    timestamp_stop: '2022-01-01T00:36:00',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 132867,
    tank_volume_start: 3305.8999999999974,
    tank_volume_stop: 3242.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:36:15',
    timestamp_stop: '2022-01-01T00:36:21',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 132869,
    tank_volume_start: 3242.2999999999975,
    tank_volume_stop: 3190.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:36:36',
    timestamp_stop: '2022-01-01T00:36:42',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 132871,
    tank_volume_start: 3190.0999999999976,
    tank_volume_stop: 3135.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:36:57',
    timestamp_stop: '2022-01-01T00:37:02',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 132873,
    tank_volume_start: 3135.2999999999975,
    tank_volume_stop: 3079.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:37:17',
    timestamp_stop: '2022-01-01T00:37:22',
    quantity: 52.9,
    vehicle: 'DEM666',
    id: 132875,
    tank_volume_start: 3079.0999999999976,
    tank_volume_stop: 3020.899999999998,
  },
  {
    timestamp_start: '2022-01-01T00:37:37',
    timestamp_stop: '2022-01-01T00:37:43',
    quantity: 54.6,
    vehicle: 'DEM333',
    id: 132877,
    tank_volume_start: 3020.899999999998,
    tank_volume_stop: 2965.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:37:58',
    timestamp_stop: '2022-01-01T00:38:03',
    quantity: 48.8,
    vehicle: 'DEM000',
    id: 132879,
    tank_volume_start: 2965.0999999999976,
    tank_volume_stop: 2908.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:38:18',
    timestamp_stop: '2022-01-01T00:38:23',
    quantity: 56.6,
    vehicle: 'DEM666',
    id: 132881,
    tank_volume_start: 2908.6999999999975,
    tank_volume_stop: 2851.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T00:38:38',
    timestamp_stop: '2022-01-01T00:38:44',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 132883,
    tank_volume_start: 2851.4999999999977,
    tank_volume_stop: 2792.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:38:59',
    timestamp_stop: '2022-01-01T00:39:04',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 132885,
    tank_volume_start: 2792.6999999999975,
    tank_volume_stop: 2731.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:39:19',
    timestamp_stop: '2022-01-01T00:39:25',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 132887,
    tank_volume_start: 2731.7999999999975,
    tank_volume_stop: 2679.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:39:40',
    timestamp_stop: '2022-01-01T00:39:45',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 132889,
    tank_volume_start: 2679.6999999999975,
    tank_volume_stop: 2628.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:40:00',
    timestamp_stop: '2022-01-01T00:40:06',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 132891,
    tank_volume_start: 2628.5999999999976,
    tank_volume_stop: 2582.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T00:40:21',
    timestamp_stop: '2022-01-01T00:40:27',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 132893,
    tank_volume_start: 2582.2999999999975,
    tank_volume_stop: 2524.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T00:40:42',
    timestamp_stop: '2022-01-01T00:40:47',
    quantity: 50.6,
    vehicle: 'DEM777',
    id: 132895,
    tank_volume_start: 2524.5999999999976,
    tank_volume_stop: 2471.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T00:41:02',
    timestamp_stop: '2022-01-01T00:41:07',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 132897,
    tank_volume_start: 2471.9999999999977,
    tank_volume_stop: 2423.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T00:41:22',
    timestamp_stop: '2022-01-01T00:41:28',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 132899,
    tank_volume_start: 2423.4999999999977,
    tank_volume_stop: 2369.299999999998,
  },
  {
    timestamp_start: '2022-01-01T00:41:43',
    timestamp_stop: '2022-01-01T00:41:49',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 132901,
    tank_volume_start: 2369.299999999998,
    tank_volume_stop: 2315.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:42:04',
    timestamp_stop: '2022-01-01T00:42:09',
    quantity: 48.1,
    vehicle: 'DEM555',
    id: 132903,
    tank_volume_start: 2315.599999999998,
    tank_volume_stop: 2257.299999999998,
  },
  {
    timestamp_start: '2022-01-01T00:42:24',
    timestamp_stop: '2022-01-01T00:42:29',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 132905,
    tank_volume_start: 2257.299999999998,
    tank_volume_stop: 2205.399999999998,
  },
  {
    timestamp_start: '2022-01-01T00:42:44',
    timestamp_stop: '2022-01-01T00:42:50',
    quantity: 50.7,
    vehicle: 'DEM222',
    id: 132907,
    tank_volume_start: 2205.399999999998,
    tank_volume_stop: 2150.199999999998,
  },
  {
    timestamp_start: '2022-01-01T00:43:05',
    timestamp_stop: '2022-01-01T00:43:11',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 132909,
    tank_volume_start: 2150.199999999998,
    tank_volume_stop: 2089.499999999998,
  },
  {
    timestamp_start: '2022-01-01T00:43:26',
    timestamp_stop: '2022-01-01T00:43:32',
    quantity: 62.2,
    vehicle: 'DEM333',
    id: 132911,
    tank_volume_start: 2089.499999999998,
    tank_volume_stop: 2031.9999999999982,
  },
  {
    timestamp_start: '2022-01-01T00:43:47',
    timestamp_stop: '2022-01-01T00:43:52',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 132913,
    tank_volume_start: 2031.9999999999982,
    tank_volume_stop: 1983.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:44:07',
    timestamp_stop: '2022-01-01T00:44:13',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 132915,
    tank_volume_start: 3983.599999999998,
    tank_volume_stop: 3934.999999999998,
  },
  {
    timestamp_start: '2022-01-01T00:44:28',
    timestamp_stop: '2022-01-01T00:44:34',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 132917,
    tank_volume_start: 3934.999999999998,
    tank_volume_stop: 3877.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T00:44:49',
    timestamp_stop: '2022-01-01T00:44:55',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 132919,
    tank_volume_start: 3877.7999999999984,
    tank_volume_stop: 3822.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T00:45:10',
    timestamp_stop: '2022-01-01T00:45:15',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 132921,
    tank_volume_start: 3822.7999999999984,
    tank_volume_stop: 3764.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:45:30',
    timestamp_stop: '2022-01-01T00:45:35',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 132923,
    tank_volume_start: 3764.1999999999985,
    tank_volume_stop: 3707.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T00:45:50',
    timestamp_stop: '2022-01-01T00:45:55',
    quantity: 50.1,
    vehicle: 'DEM444',
    id: 132925,
    tank_volume_start: 3707.4999999999986,
    tank_volume_stop: 3660.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:46:10',
    timestamp_stop: '2022-01-01T00:46:16',
    quantity: 59.7,
    vehicle: 'DEM000',
    id: 132927,
    tank_volume_start: 3660.1999999999985,
    tank_volume_stop: 3610.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T00:46:31',
    timestamp_stop: '2022-01-01T00:46:36',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 132929,
    tank_volume_start: 3610.7999999999984,
    tank_volume_stop: 3550.499999999998,
  },
  {
    timestamp_start: '2022-01-01T00:46:51',
    timestamp_stop: '2022-01-01T00:46:56',
    quantity: 50.4,
    vehicle: 'DEM888',
    id: 132931,
    tank_volume_start: 3550.499999999998,
    tank_volume_stop: 3494.199999999998,
  },
  {
    timestamp_start: '2022-01-01T00:47:11',
    timestamp_stop: '2022-01-01T00:47:17',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 132933,
    tank_volume_start: 3494.199999999998,
    tank_volume_stop: 3447.799999999998,
  },
  {
    timestamp_start: '2022-01-01T00:47:32',
    timestamp_stop: '2022-01-01T00:47:37',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 132935,
    tank_volume_start: 3447.799999999998,
    tank_volume_stop: 3395.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:47:52',
    timestamp_stop: '2022-01-01T00:47:58',
    quantity: 62.8,
    vehicle: 'DEM444',
    id: 132937,
    tank_volume_start: 3395.599999999998,
    tank_volume_stop: 3342.099999999998,
  },
  {
    timestamp_start: '2022-01-01T00:48:13',
    timestamp_stop: '2022-01-01T00:48:19',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 132939,
    tank_volume_start: 3342.099999999998,
    tank_volume_stop: 3285.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:48:34',
    timestamp_stop: '2022-01-01T00:48:39',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 132941,
    tank_volume_start: 3285.599999999998,
    tank_volume_stop: 3223.699999999998,
  },
  {
    timestamp_start: '2022-01-01T00:48:54',
    timestamp_stop: '2022-01-01T00:48:59',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 132943,
    tank_volume_start: 3223.699999999998,
    tank_volume_stop: 3170.699999999998,
  },
  {
    timestamp_start: '2022-01-01T00:49:14',
    timestamp_stop: '2022-01-01T00:49:20',
    quantity: 55.1,
    vehicle: 'DEM333',
    id: 132945,
    tank_volume_start: 3170.699999999998,
    tank_volume_stop: 3121.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:49:35',
    timestamp_stop: '2022-01-01T00:49:40',
    quantity: 49.2,
    vehicle: 'DEM111',
    id: 132947,
    tank_volume_start: 3121.599999999998,
    tank_volume_stop: 3063.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T00:49:55',
    timestamp_stop: '2022-01-01T00:50:00',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 132949,
    tank_volume_start: 3063.3999999999983,
    tank_volume_stop: 3005.499999999998,
  },
  {
    timestamp_start: '2022-01-01T00:50:15',
    timestamp_stop: '2022-01-01T00:50:21',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 132951,
    tank_volume_start: 3005.499999999998,
    tank_volume_stop: 2953.199999999998,
  },
  {
    timestamp_start: '2022-01-01T00:50:36',
    timestamp_stop: '2022-01-01T00:50:42',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 132953,
    tank_volume_start: 2953.199999999998,
    tank_volume_stop: 2890.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:50:57',
    timestamp_stop: '2022-01-01T00:51:03',
    quantity: 62.4,
    vehicle: 'DEM444',
    id: 132955,
    tank_volume_start: 2890.599999999998,
    tank_volume_stop: 2840.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:51:18',
    timestamp_stop: '2022-01-01T00:51:23',
    quantity: 54.2,
    vehicle: 'DEM111',
    id: 132957,
    tank_volume_start: 2840.599999999998,
    tank_volume_stop: 2778.999999999998,
  },
  {
    timestamp_start: '2022-01-01T00:51:38',
    timestamp_stop: '2022-01-01T00:51:44',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 132959,
    tank_volume_start: 2778.999999999998,
    tank_volume_stop: 2724.599999999998,
  },
  {
    timestamp_start: '2022-01-01T00:51:59',
    timestamp_stop: '2022-01-01T00:52:05',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 132961,
    tank_volume_start: 2724.599999999998,
    tank_volume_stop: 2676.999999999998,
  },
  {
    timestamp_start: '2022-01-01T00:52:20',
    timestamp_stop: '2022-01-01T00:52:25',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 132963,
    tank_volume_start: 2676.999999999998,
    tank_volume_stop: 2620.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T00:52:40',
    timestamp_stop: '2022-01-01T00:52:46',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 132965,
    tank_volume_start: 2620.2999999999984,
    tank_volume_stop: 2560.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:53:01',
    timestamp_stop: '2022-01-01T00:53:06',
    quantity: 47.2,
    vehicle: 'DEM222',
    id: 132967,
    tank_volume_start: 2560.5999999999985,
    tank_volume_stop: 2502.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T00:53:21',
    timestamp_stop: '2022-01-01T00:53:27',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 132969,
    tank_volume_start: 2502.9999999999986,
    tank_volume_stop: 2450.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T00:53:42',
    timestamp_stop: '2022-01-01T00:53:48',
    quantity: 62.6,
    vehicle: 'DEM111',
    id: 132971,
    tank_volume_start: 2450.4999999999986,
    tank_volume_stop: 2397.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T00:54:03',
    timestamp_stop: '2022-01-01T00:54:08',
    quantity: 49.1,
    vehicle: 'DEM666',
    id: 132973,
    tank_volume_start: 2397.3999999999987,
    tank_volume_stop: 2342.699999999999,
  },
  {
    timestamp_start: '2022-01-01T00:54:23',
    timestamp_stop: '2022-01-01T00:54:28',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 132975,
    tank_volume_start: 2342.699999999999,
    tank_volume_stop: 2289.999999999999,
  },
  {
    timestamp_start: '2022-01-01T00:54:43',
    timestamp_stop: '2022-01-01T00:54:49',
    quantity: 57.4,
    vehicle: 'DEM333',
    id: 132977,
    tank_volume_start: 2289.999999999999,
    tank_volume_stop: 2230.899999999999,
  },
  {
    timestamp_start: '2022-01-01T00:55:04',
    timestamp_stop: '2022-01-01T00:55:09',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 132979,
    tank_volume_start: 2230.899999999999,
    tank_volume_stop: 2179.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T00:55:24',
    timestamp_stop: '2022-01-01T00:55:30',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 132981,
    tank_volume_start: 2179.2999999999993,
    tank_volume_stop: 2128.899999999999,
  },
  {
    timestamp_start: '2022-01-01T00:55:45',
    timestamp_stop: '2022-01-01T00:55:51',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 132983,
    tank_volume_start: 2128.899999999999,
    tank_volume_stop: 2071.399999999999,
  },
  {
    timestamp_start: '2022-01-01T00:56:06',
    timestamp_stop: '2022-01-01T00:56:12',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 132985,
    tank_volume_start: 2071.399999999999,
    tank_volume_stop: 2016.5999999999992,
  },
  {
    timestamp_start: '2022-01-01T00:56:27',
    timestamp_stop: '2022-01-01T00:56:32',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 132987,
    tank_volume_start: 2016.5999999999992,
    tank_volume_stop: 1964.9999999999993,
  },
  {
    timestamp_start: '2022-01-01T00:56:47',
    timestamp_stop: '2022-01-01T00:56:52',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 132989,
    tank_volume_start: 3964.999999999999,
    tank_volume_stop: 3907.599999999999,
  },
  {
    timestamp_start: '2022-01-01T00:57:07',
    timestamp_stop: '2022-01-01T00:57:12',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 132991,
    tank_volume_start: 3907.599999999999,
    tank_volume_stop: 3859.699999999999,
  },
  {
    timestamp_start: '2022-01-01T00:57:27',
    timestamp_stop: '2022-01-01T00:57:32',
    quantity: 53.7,
    vehicle: 'DEM111',
    id: 132993,
    tank_volume_start: 3859.699999999999,
    tank_volume_stop: 3811.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T00:57:47',
    timestamp_stop: '2022-01-01T00:57:53',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 132995,
    tank_volume_start: 3811.3999999999987,
    tank_volume_stop: 3752.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T00:58:08',
    timestamp_stop: '2022-01-01T00:58:13',
    quantity: 52.9,
    vehicle: 'DEM666',
    id: 132997,
    tank_volume_start: 3752.3999999999987,
    tank_volume_stop: 3699.299999999999,
  },
  {
    timestamp_start: '2022-01-01T00:58:28',
    timestamp_stop: '2022-01-01T00:58:33',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 132999,
    tank_volume_start: 3699.299999999999,
    tank_volume_stop: 3646.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T00:58:48',
    timestamp_stop: '2022-01-01T00:58:53',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 133001,
    tank_volume_start: 3646.3999999999987,
    tank_volume_stop: 3587.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:59:08',
    timestamp_stop: '2022-01-01T00:59:14',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 133003,
    tank_volume_start: 3587.5999999999985,
    tank_volume_stop: 3540.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T00:59:29',
    timestamp_stop: '2022-01-01T00:59:34',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 133005,
    tank_volume_start: 3540.6999999999985,
    tank_volume_stop: 3491.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T00:59:49',
    timestamp_stop: '2022-01-01T00:59:54',
    quantity: 50.1,
    vehicle: 'DEM444',
    id: 133007,
    tank_volume_start: 3491.3999999999983,
    tank_volume_stop: 3441.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:00:09',
    timestamp_stop: '2022-01-01T01:00:14',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 133009,
    tank_volume_start: 3441.999999999998,
    tank_volume_stop: 3387.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:00:29',
    timestamp_stop: '2022-01-01T01:00:35',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 133011,
    tank_volume_start: 3387.2999999999984,
    tank_volume_stop: 3325.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:00:50',
    timestamp_stop: '2022-01-01T01:00:55',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 133013,
    tank_volume_start: 3325.5999999999985,
    tank_volume_stop: 3270.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:01:10',
    timestamp_stop: '2022-01-01T01:01:16',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 133015,
    tank_volume_start: 3270.2999999999984,
    tank_volume_stop: 3219.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:01:31',
    timestamp_stop: '2022-01-01T01:01:37',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 133017,
    tank_volume_start: 3219.5999999999985,
    tank_volume_stop: 3167.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:01:52',
    timestamp_stop: '2022-01-01T01:01:58',
    quantity: 61.7,
    vehicle: 'DEM888',
    id: 133019,
    tank_volume_start: 3167.6999999999985,
    tank_volume_stop: 3107.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:02:13',
    timestamp_stop: '2022-01-01T01:02:19',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 133021,
    tank_volume_start: 3107.4999999999986,
    tank_volume_stop: 3060.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:02:34',
    timestamp_stop: '2022-01-01T01:02:40',
    quantity: 64.4,
    vehicle: 'DEM888',
    id: 133023,
    tank_volume_start: 3060.3999999999987,
    tank_volume_stop: 3004.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:02:55',
    timestamp_stop: '2022-01-01T01:03:00',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 133025,
    tank_volume_start: 3004.0999999999985,
    tank_volume_stop: 2944.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:03:15',
    timestamp_stop: '2022-01-01T01:03:21',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 133027,
    tank_volume_start: 2944.0999999999985,
    tank_volume_stop: 2895.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:03:36',
    timestamp_stop: '2022-01-01T01:03:41',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 133029,
    tank_volume_start: 2895.3999999999987,
    tank_volume_stop: 2841.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:03:56',
    timestamp_stop: '2022-01-01T01:04:02',
    quantity: 58.4,
    vehicle: 'DEM666',
    id: 133031,
    tank_volume_start: 2841.3999999999987,
    tank_volume_stop: 2783.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:04:17',
    timestamp_stop: '2022-01-01T01:04:21',
    quantity: 45.6,
    vehicle: 'DEM555',
    id: 133033,
    tank_volume_start: 2783.0999999999985,
    tank_volume_stop: 2720.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:04:36',
    timestamp_stop: '2022-01-01T01:04:41',
    quantity: 47.6,
    vehicle: 'DEM888',
    id: 133035,
    tank_volume_start: 2720.9999999999986,
    tank_volume_stop: 2667.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:04:56',
    timestamp_stop: '2022-01-01T01:05:01',
    quantity: 51.6,
    vehicle: 'DEM666',
    id: 133037,
    tank_volume_start: 2667.3999999999987,
    tank_volume_stop: 2612.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:05:16',
    timestamp_stop: '2022-01-01T01:05:22',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 133039,
    tank_volume_start: 2612.0999999999985,
    tank_volume_stop: 2550.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:05:37',
    timestamp_stop: '2022-01-01T01:05:42',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 133041,
    tank_volume_start: 2550.2999999999984,
    tank_volume_stop: 2492.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:05:57',
    timestamp_stop: '2022-01-01T01:06:03',
    quantity: 61.1,
    vehicle: 'DEM111',
    id: 133043,
    tank_volume_start: 2492.999999999998,
    tank_volume_stop: 2437.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:06:18',
    timestamp_stop: '2022-01-01T01:06:24',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 133045,
    tank_volume_start: 2437.2999999999984,
    tank_volume_stop: 2378.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:06:39',
    timestamp_stop: '2022-01-01T01:06:45',
    quantity: 55.4,
    vehicle: 'DEM000',
    id: 133047,
    tank_volume_start: 2378.499999999998,
    tank_volume_stop: 2324.699999999998,
  },
  {
    timestamp_start: '2022-01-01T01:07:00',
    timestamp_stop: '2022-01-01T01:07:06',
    quantity: 59.0,
    vehicle: 'DEM111',
    id: 133049,
    tank_volume_start: 2324.699999999998,
    tank_volume_stop: 2273.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:07:21',
    timestamp_stop: '2022-01-01T01:07:27',
    quantity: 61.4,
    vehicle: 'DEM111',
    id: 133051,
    tank_volume_start: 2273.599999999998,
    tank_volume_stop: 2226.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:07:42',
    timestamp_stop: '2022-01-01T01:07:47',
    quantity: 48.9,
    vehicle: 'DEM555',
    id: 133053,
    tank_volume_start: 2226.8999999999983,
    tank_volume_stop: 2169.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:08:02',
    timestamp_stop: '2022-01-01T01:08:08',
    quantity: 62.1,
    vehicle: 'DEM111',
    id: 133055,
    tank_volume_start: 2169.3999999999983,
    tank_volume_stop: 2115.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:08:23',
    timestamp_stop: '2022-01-01T01:08:28',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 133057,
    tank_volume_start: 2115.3999999999983,
    tank_volume_stop: 2061.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:08:43',
    timestamp_stop: '2022-01-01T01:08:49',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 133059,
    tank_volume_start: 2061.099999999998,
    tank_volume_stop: 2004.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:09:04',
    timestamp_stop: '2022-01-01T01:09:09',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 133061,
    tank_volume_start: 2004.599999999998,
    tank_volume_stop: 1957.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:09:24',
    timestamp_stop: '2022-01-01T01:09:29',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 133063,
    tank_volume_start: 3957.099999999998,
    tank_volume_stop: 3900.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:09:44',
    timestamp_stop: '2022-01-01T01:09:50',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 133065,
    tank_volume_start: 3900.8999999999983,
    tank_volume_stop: 3848.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:10:05',
    timestamp_stop: '2022-01-01T01:10:11',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 133067,
    tank_volume_start: 3848.999999999998,
    tank_volume_stop: 3789.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:10:26',
    timestamp_stop: '2022-01-01T01:10:30',
    quantity: 45.5,
    vehicle: 'DEM000',
    id: 133069,
    tank_volume_start: 3789.7999999999984,
    tank_volume_stop: 3742.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:10:45',
    timestamp_stop: '2022-01-01T01:10:50',
    quantity: 51.7,
    vehicle: 'DEM000',
    id: 133071,
    tank_volume_start: 3742.999999999998,
    tank_volume_stop: 3688.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:11:05',
    timestamp_stop: '2022-01-01T01:11:11',
    quantity: 54.2,
    vehicle: 'DEM888',
    id: 133073,
    tank_volume_start: 3688.199999999998,
    tank_volume_stop: 3630.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:11:26',
    timestamp_stop: '2022-01-01T01:11:31',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 133075,
    tank_volume_start: 3630.599999999998,
    tank_volume_stop: 3578.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:11:46',
    timestamp_stop: '2022-01-01T01:11:52',
    quantity: 62.6,
    vehicle: 'DEM666',
    id: 133077,
    tank_volume_start: 3578.099999999998,
    tank_volume_stop: 3518.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:12:07',
    timestamp_stop: '2022-01-01T01:12:12',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 133079,
    tank_volume_start: 3518.199999999998,
    tank_volume_stop: 3468.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:12:27',
    timestamp_stop: '2022-01-01T01:12:33',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 133081,
    tank_volume_start: 3468.499999999998,
    tank_volume_stop: 3413.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:12:48',
    timestamp_stop: '2022-01-01T01:12:53',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 133083,
    tank_volume_start: 3413.199999999998,
    tank_volume_stop: 3350.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:13:08',
    timestamp_stop: '2022-01-01T01:13:13',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 133085,
    tank_volume_start: 3350.499999999998,
    tank_volume_stop: 3295.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:13:28',
    timestamp_stop: '2022-01-01T01:13:33',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 133087,
    tank_volume_start: 3295.7999999999984,
    tank_volume_stop: 3242.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:13:48',
    timestamp_stop: '2022-01-01T01:13:54',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 133089,
    tank_volume_start: 3242.499999999998,
    tank_volume_stop: 3182.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:14:09',
    timestamp_stop: '2022-01-01T01:14:14',
    quantity: 58.4,
    vehicle: 'DEM888',
    id: 133091,
    tank_volume_start: 3182.8999999999983,
    tank_volume_stop: 3128.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:14:29',
    timestamp_stop: '2022-01-01T01:14:35',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 133093,
    tank_volume_start: 3128.999999999998,
    tank_volume_stop: 3079.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:14:50',
    timestamp_stop: '2022-01-01T01:14:55',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 133095,
    tank_volume_start: 3079.2999999999984,
    tank_volume_stop: 3024.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:15:10',
    timestamp_stop: '2022-01-01T01:15:16',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 133097,
    tank_volume_start: 3024.3999999999983,
    tank_volume_stop: 2965.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:15:31',
    timestamp_stop: '2022-01-01T01:15:36',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 133099,
    tank_volume_start: 2965.599999999998,
    tank_volume_stop: 2910.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:15:51',
    timestamp_stop: '2022-01-01T01:15:57',
    quantity: 59.8,
    vehicle: 'DEM666',
    id: 133101,
    tank_volume_start: 2910.499999999998,
    tank_volume_stop: 2850.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:16:12',
    timestamp_stop: '2022-01-01T01:16:18',
    quantity: 56.3,
    vehicle: 'DEM666',
    id: 133103,
    tank_volume_start: 2850.3999999999983,
    tank_volume_stop: 2793.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:16:33',
    timestamp_stop: '2022-01-01T01:16:39',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 133105,
    tank_volume_start: 2793.499999999998,
    tank_volume_stop: 2746.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:16:54',
    timestamp_stop: '2022-01-01T01:16:59',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 133107,
    tank_volume_start: 2746.599999999998,
    tank_volume_stop: 2692.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:17:14',
    timestamp_stop: '2022-01-01T01:17:19',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 133109,
    tank_volume_start: 2692.799999999998,
    tank_volume_stop: 2640.699999999998,
  },
  {
    timestamp_start: '2022-01-01T01:17:34',
    timestamp_stop: '2022-01-01T01:17:39',
    quantity: 48.0,
    vehicle: 'DEM111',
    id: 133111,
    tank_volume_start: 2640.699999999998,
    tank_volume_stop: 2584.899999999998,
  },
  {
    timestamp_start: '2022-01-01T01:17:54',
    timestamp_stop: '2022-01-01T01:17:59',
    quantity: 48.1,
    vehicle: 'DEM000',
    id: 133113,
    tank_volume_start: 2584.899999999998,
    tank_volume_stop: 2523.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:18:14',
    timestamp_stop: '2022-01-01T01:18:19',
    quantity: 50.6,
    vehicle: 'DEM555',
    id: 133115,
    tank_volume_start: 2523.199999999998,
    tank_volume_stop: 2472.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:18:34',
    timestamp_stop: '2022-01-01T01:18:39',
    quantity: 49.0,
    vehicle: 'DEM555',
    id: 133117,
    tank_volume_start: 2472.799999999998,
    tank_volume_stop: 2425.399999999998,
  },
  {
    timestamp_start: '2022-01-01T01:18:54',
    timestamp_stop: '2022-01-01T01:18:59',
    quantity: 46.5,
    vehicle: 'DEM000',
    id: 133119,
    tank_volume_start: 2425.399999999998,
    tank_volume_stop: 2378.699999999998,
  },
  {
    timestamp_start: '2022-01-01T01:19:14',
    timestamp_stop: '2022-01-01T01:19:19',
    quantity: 49.6,
    vehicle: 'DEM888',
    id: 133121,
    tank_volume_start: 2378.699999999998,
    tank_volume_stop: 2322.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:19:34',
    timestamp_stop: '2022-01-01T01:19:40',
    quantity: 60.7,
    vehicle: 'DEM333',
    id: 133123,
    tank_volume_start: 2322.499999999998,
    tank_volume_stop: 2267.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:19:55',
    timestamp_stop: '2022-01-01T01:20:01',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 133125,
    tank_volume_start: 2267.2999999999984,
    tank_volume_stop: 2210.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:20:16',
    timestamp_stop: '2022-01-01T01:20:21',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 133127,
    tank_volume_start: 2210.1999999999985,
    tank_volume_stop: 2154.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:20:36',
    timestamp_stop: '2022-01-01T01:20:41',
    quantity: 53.8,
    vehicle: 'DEM555',
    id: 133129,
    tank_volume_start: 2154.8999999999983,
    tank_volume_stop: 2105.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:20:56',
    timestamp_stop: '2022-01-01T01:21:02',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 133131,
    tank_volume_start: 2105.6999999999985,
    tank_volume_stop: 2052.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:21:17',
    timestamp_stop: '2022-01-01T01:21:22',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 133133,
    tank_volume_start: 2052.0999999999985,
    tank_volume_stop: 1995.8999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:21:37',
    timestamp_stop: '2022-01-01T01:21:42',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 133135,
    tank_volume_start: 3995.8999999999987,
    tank_volume_stop: 3931.299999999999,
  },
  {
    timestamp_start: '2022-01-01T01:21:57',
    timestamp_stop: '2022-01-01T01:22:03',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 133137,
    tank_volume_start: 3931.299999999999,
    tank_volume_stop: 3878.799999999999,
  },
  {
    timestamp_start: '2022-01-01T01:22:18',
    timestamp_stop: '2022-01-01T01:22:24',
    quantity: 63.1,
    vehicle: 'DEM666',
    id: 133139,
    tank_volume_start: 3878.799999999999,
    tank_volume_stop: 3818.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:22:39',
    timestamp_stop: '2022-01-01T01:22:45',
    quantity: 58.5,
    vehicle: 'DEM111',
    id: 133141,
    tank_volume_start: 3818.4999999999986,
    tank_volume_stop: 3765.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:23:00',
    timestamp_stop: '2022-01-01T01:23:06',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 133143,
    tank_volume_start: 3765.6999999999985,
    tank_volume_stop: 3711.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:23:21',
    timestamp_stop: '2022-01-01T01:23:26',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 133145,
    tank_volume_start: 3711.6999999999985,
    tank_volume_stop: 3651.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:23:41',
    timestamp_stop: '2022-01-01T01:23:46',
    quantity: 50.4,
    vehicle: 'DEM777',
    id: 133147,
    tank_volume_start: 3651.2999999999984,
    tank_volume_stop: 3592.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:24:01',
    timestamp_stop: '2022-01-01T01:24:06',
    quantity: 49.6,
    vehicle: 'DEM777',
    id: 133149,
    tank_volume_start: 3592.7999999999984,
    tank_volume_stop: 3534.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:24:21',
    timestamp_stop: '2022-01-01T01:24:26',
    quantity: 52.1,
    vehicle: 'DEM222',
    id: 133151,
    tank_volume_start: 3534.0999999999985,
    tank_volume_stop: 3482.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:24:41',
    timestamp_stop: '2022-01-01T01:24:47',
    quantity: 51.3,
    vehicle: 'DEM888',
    id: 133153,
    tank_volume_start: 3482.1999999999985,
    tank_volume_stop: 3421.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:25:02',
    timestamp_stop: '2022-01-01T01:25:07',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 133155,
    tank_volume_start: 3421.8999999999983,
    tank_volume_stop: 3367.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:25:22',
    timestamp_stop: '2022-01-01T01:25:28',
    quantity: 63.0,
    vehicle: 'DEM444',
    id: 133157,
    tank_volume_start: 3367.2999999999984,
    tank_volume_stop: 3316.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:25:43',
    timestamp_stop: '2022-01-01T01:25:49',
    quantity: 59.6,
    vehicle: 'DEM444',
    id: 133159,
    tank_volume_start: 3316.7999999999984,
    tank_volume_stop: 3264.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:26:04',
    timestamp_stop: '2022-01-01T01:26:09',
    quantity: 49.5,
    vehicle: 'DEM333',
    id: 133161,
    tank_volume_start: 3264.6999999999985,
    tank_volume_stop: 3216.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:26:24',
    timestamp_stop: '2022-01-01T01:26:30',
    quantity: 61.1,
    vehicle: 'DEM888',
    id: 133163,
    tank_volume_start: 3216.8999999999983,
    tank_volume_stop: 3166.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:26:45',
    timestamp_stop: '2022-01-01T01:26:51',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 133165,
    tank_volume_start: 3166.7999999999984,
    tank_volume_stop: 3116.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:27:06',
    timestamp_stop: '2022-01-01T01:27:11',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 133167,
    tank_volume_start: 3116.2999999999984,
    tank_volume_stop: 3061.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:27:26',
    timestamp_stop: '2022-01-01T01:27:31',
    quantity: 47.7,
    vehicle: 'DEM222',
    id: 133169,
    tank_volume_start: 3061.999999999998,
    tank_volume_stop: 3002.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:27:46',
    timestamp_stop: '2022-01-01T01:27:51',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 133171,
    tank_volume_start: 3002.3999999999983,
    tank_volume_stop: 2949.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:28:06',
    timestamp_stop: '2022-01-01T01:28:11',
    quantity: 51.6,
    vehicle: 'DEM333',
    id: 133173,
    tank_volume_start: 2949.3999999999983,
    tank_volume_stop: 2898.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:28:26',
    timestamp_stop: '2022-01-01T01:28:32',
    quantity: 51.9,
    vehicle: 'DEM555',
    id: 133175,
    tank_volume_start: 2898.099999999998,
    tank_volume_stop: 2833.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:28:47',
    timestamp_stop: '2022-01-01T01:28:52',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 133177,
    tank_volume_start: 2833.599999999998,
    tank_volume_stop: 2780.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:29:07',
    timestamp_stop: '2022-01-01T01:29:12',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 133179,
    tank_volume_start: 2780.799999999998,
    tank_volume_stop: 2729.899999999998,
  },
  {
    timestamp_start: '2022-01-01T01:29:27',
    timestamp_stop: '2022-01-01T01:29:33',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 133181,
    tank_volume_start: 2729.899999999998,
    tank_volume_stop: 2680.299999999998,
  },
  {
    timestamp_start: '2022-01-01T01:29:48',
    timestamp_stop: '2022-01-01T01:29:53',
    quantity: 49.8,
    vehicle: 'DEM000',
    id: 133183,
    tank_volume_start: 2680.299999999998,
    tank_volume_stop: 2623.299999999998,
  },
  {
    timestamp_start: '2022-01-01T01:30:08',
    timestamp_stop: '2022-01-01T01:30:13',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 133185,
    tank_volume_start: 2623.299999999998,
    tank_volume_stop: 2563.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:30:28',
    timestamp_stop: '2022-01-01T01:30:33',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 133187,
    tank_volume_start: 2563.099999999998,
    tank_volume_stop: 2507.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:30:48',
    timestamp_stop: '2022-01-01T01:30:53',
    quantity: 47.5,
    vehicle: 'DEM333',
    id: 133189,
    tank_volume_start: 2507.799999999998,
    tank_volume_stop: 2458.699999999998,
  },
  {
    timestamp_start: '2022-01-01T01:31:08',
    timestamp_stop: '2022-01-01T01:31:14',
    quantity: 59.2,
    vehicle: 'DEM777',
    id: 133191,
    tank_volume_start: 2458.699999999998,
    tank_volume_stop: 2403.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:31:29',
    timestamp_stop: '2022-01-01T01:31:34',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 133193,
    tank_volume_start: 2403.799999999998,
    tank_volume_stop: 2353.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:31:49',
    timestamp_stop: '2022-01-01T01:31:55',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 133195,
    tank_volume_start: 2353.199999999998,
    tank_volume_stop: 2293.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:32:10',
    timestamp_stop: '2022-01-01T01:32:15',
    quantity: 56.1,
    vehicle: 'DEM444',
    id: 133197,
    tank_volume_start: 2293.199999999998,
    tank_volume_stop: 2238.899999999998,
  },
  {
    timestamp_start: '2022-01-01T01:32:30',
    timestamp_stop: '2022-01-01T01:32:36',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 133199,
    tank_volume_start: 2238.899999999998,
    tank_volume_stop: 2180.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:32:51',
    timestamp_stop: '2022-01-01T01:32:55',
    quantity: 47.5,
    vehicle: 'DEM444',
    id: 133201,
    tank_volume_start: 2180.199999999998,
    tank_volume_stop: 2121.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:33:10',
    timestamp_stop: '2022-01-01T01:33:16',
    quantity: 55.4,
    vehicle: 'DEM777',
    id: 133203,
    tank_volume_start: 2121.799999999998,
    tank_volume_stop: 2068.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:33:31',
    timestamp_stop: '2022-01-01T01:33:37',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 133205,
    tank_volume_start: 2068.9999999999977,
    tank_volume_stop: 2010.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:33:52',
    timestamp_stop: '2022-01-01T01:33:57',
    quantity: 49.8,
    vehicle: 'DEM111',
    id: 133207,
    tank_volume_start: 2010.4999999999977,
    tank_volume_stop: 1948.2999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:34:12',
    timestamp_stop: '2022-01-01T01:34:17',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 133209,
    tank_volume_start: 3948.2999999999975,
    tank_volume_stop: 3898.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T01:34:32',
    timestamp_stop: '2022-01-01T01:34:38',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 133211,
    tank_volume_start: 3898.1999999999975,
    tank_volume_stop: 3848.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T01:34:53',
    timestamp_stop: '2022-01-01T01:34:58',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 133213,
    tank_volume_start: 3848.3999999999974,
    tank_volume_stop: 3798.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T01:35:13',
    timestamp_stop: '2022-01-01T01:35:19',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 133215,
    tank_volume_start: 3798.6999999999975,
    tank_volume_stop: 3745.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T01:35:34',
    timestamp_stop: '2022-01-01T01:35:39',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 133217,
    tank_volume_start: 3745.6999999999975,
    tank_volume_stop: 3692.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T01:35:54',
    timestamp_stop: '2022-01-01T01:35:59',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 133219,
    tank_volume_start: 3692.5999999999976,
    tank_volume_stop: 3637.399999999998,
  },
  {
    timestamp_start: '2022-01-01T01:36:14',
    timestamp_stop: '2022-01-01T01:36:20',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 133221,
    tank_volume_start: 3637.399999999998,
    tank_volume_stop: 3591.299999999998,
  },
  {
    timestamp_start: '2022-01-01T01:36:35',
    timestamp_stop: '2022-01-01T01:36:40',
    quantity: 49.9,
    vehicle: 'DEM333',
    id: 133223,
    tank_volume_start: 3591.299999999998,
    tank_volume_stop: 3542.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:36:55',
    timestamp_stop: '2022-01-01T01:37:00',
    quantity: 54.1,
    vehicle: 'DEM777',
    id: 133225,
    tank_volume_start: 3542.099999999998,
    tank_volume_stop: 3487.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:15',
    timestamp_stop: '2022-01-01T01:37:21',
    quantity: 60.6,
    vehicle: 'DEM111',
    id: 133227,
    tank_volume_start: 3487.099999999998,
    tank_volume_stop: 3433.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:36',
    timestamp_stop: '2022-01-01T01:37:42',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 133229,
    tank_volume_start: 3433.099999999998,
    tank_volume_stop: 3374.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:37:57',
    timestamp_stop: '2022-01-01T01:38:03',
    quantity: 61.0,
    vehicle: 'DEM777',
    id: 133231,
    tank_volume_start: 3374.599999999998,
    tank_volume_stop: 3320.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:38:18',
    timestamp_stop: '2022-01-01T01:38:23',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 133233,
    tank_volume_start: 3320.3999999999983,
    tank_volume_stop: 3263.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:38:38',
    timestamp_stop: '2022-01-01T01:38:43',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 133235,
    tank_volume_start: 3263.3999999999983,
    tank_volume_stop: 3205.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:38:58',
    timestamp_stop: '2022-01-01T01:39:05',
    quantity: 64.9,
    vehicle: 'DEM222',
    id: 133237,
    tank_volume_start: 3205.1999999999985,
    tank_volume_stop: 3156.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:39:20',
    timestamp_stop: '2022-01-01T01:39:26',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 133239,
    tank_volume_start: 3156.5999999999985,
    tank_volume_stop: 3097.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:39:41',
    timestamp_stop: '2022-01-01T01:39:46',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 133241,
    tank_volume_start: 3097.6999999999985,
    tank_volume_stop: 3043.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:40:01',
    timestamp_stop: '2022-01-01T01:40:07',
    quantity: 56.3,
    vehicle: 'DEM444',
    id: 133243,
    tank_volume_start: 3043.6999999999985,
    tank_volume_stop: 2992.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:40:22',
    timestamp_stop: '2022-01-01T01:40:28',
    quantity: 57.8,
    vehicle: 'DEM555',
    id: 133245,
    tank_volume_start: 2992.4999999999986,
    tank_volume_stop: 2943.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:40:43',
    timestamp_stop: '2022-01-01T01:40:47',
    quantity: 45.9,
    vehicle: 'DEM666',
    id: 133247,
    tank_volume_start: 2943.6999999999985,
    tank_volume_stop: 2888.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:41:02',
    timestamp_stop: '2022-01-01T01:41:08',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 133249,
    tank_volume_start: 2888.0999999999985,
    tank_volume_stop: 2832.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:41:23',
    timestamp_stop: '2022-01-01T01:41:29',
    quantity: 59.2,
    vehicle: 'DEM444',
    id: 133251,
    tank_volume_start: 2832.5999999999985,
    tank_volume_stop: 2785.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:41:44',
    timestamp_stop: '2022-01-01T01:41:50',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 133253,
    tank_volume_start: 2785.0999999999985,
    tank_volume_stop: 2730.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:42:05',
    timestamp_stop: '2022-01-01T01:42:10',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 133255,
    tank_volume_start: 2730.9999999999986,
    tank_volume_stop: 2668.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:42:25',
    timestamp_stop: '2022-01-01T01:42:31',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 133257,
    tank_volume_start: 2668.3999999999987,
    tank_volume_stop: 2622.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:42:46',
    timestamp_stop: '2022-01-01T01:42:52',
    quantity: 61.9,
    vehicle: 'DEM111',
    id: 133259,
    tank_volume_start: 2622.5999999999985,
    tank_volume_stop: 2568.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:43:07',
    timestamp_stop: '2022-01-01T01:43:12',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 133261,
    tank_volume_start: 2568.5999999999985,
    tank_volume_stop: 2515.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:43:27',
    timestamp_stop: '2022-01-01T01:43:33',
    quantity: 56.5,
    vehicle: 'DEM555',
    id: 133263,
    tank_volume_start: 2515.8999999999987,
    tank_volume_stop: 2462.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:43:48',
    timestamp_stop: '2022-01-01T01:43:53',
    quantity: 52.8,
    vehicle: 'DEM111',
    id: 133265,
    tank_volume_start: 2462.3999999999987,
    tank_volume_stop: 2416.299999999999,
  },
  {
    timestamp_start: '2022-01-01T01:44:08',
    timestamp_stop: '2022-01-01T01:44:14',
    quantity: 59.5,
    vehicle: 'DEM111',
    id: 133267,
    tank_volume_start: 2416.299999999999,
    tank_volume_stop: 2361.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T01:44:29',
    timestamp_stop: '2022-01-01T01:44:35',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 133269,
    tank_volume_start: 2361.9999999999986,
    tank_volume_stop: 2304.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:44:50',
    timestamp_stop: '2022-01-01T01:44:55',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 133271,
    tank_volume_start: 2304.0999999999985,
    tank_volume_stop: 2246.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:45:10',
    timestamp_stop: '2022-01-01T01:45:15',
    quantity: 49.7,
    vehicle: 'DEM111',
    id: 133273,
    tank_volume_start: 2246.2999999999984,
    tank_volume_stop: 2187.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:45:30',
    timestamp_stop: '2022-01-01T01:45:37',
    quantity: 62.0,
    vehicle: 'DEM888',
    id: 133275,
    tank_volume_start: 2187.6999999999985,
    tank_volume_stop: 2132.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:45:52',
    timestamp_stop: '2022-01-01T01:45:57',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 133277,
    tank_volume_start: 2132.3999999999983,
    tank_volume_stop: 2076.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:46:12',
    timestamp_stop: '2022-01-01T01:46:18',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 133279,
    tank_volume_start: 2076.3999999999983,
    tank_volume_stop: 2017.4999999999982,
  },
  {
    timestamp_start: '2022-01-01T01:46:33',
    timestamp_stop: '2022-01-01T01:46:38',
    quantity: 47.9,
    vehicle: 'DEM111',
    id: 133281,
    tank_volume_start: 2017.4999999999982,
    tank_volume_stop: 1963.2999999999981,
  },
  {
    timestamp_start: '2022-01-01T01:46:53',
    timestamp_stop: '2022-01-01T01:46:58',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 133283,
    tank_volume_start: 3963.2999999999984,
    tank_volume_stop: 3912.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:47:13',
    timestamp_stop: '2022-01-01T01:47:18',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 133285,
    tank_volume_start: 3912.5999999999985,
    tank_volume_stop: 3861.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:47:33',
    timestamp_stop: '2022-01-01T01:47:39',
    quantity: 51.5,
    vehicle: 'DEM666',
    id: 133287,
    tank_volume_start: 3861.8999999999987,
    tank_volume_stop: 3802.299999999999,
  },
  {
    timestamp_start: '2022-01-01T01:47:54',
    timestamp_stop: '2022-01-01T01:48:00',
    quantity: 63.3,
    vehicle: 'DEM333',
    id: 133289,
    tank_volume_start: 3802.299999999999,
    tank_volume_stop: 3750.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:48:15',
    timestamp_stop: '2022-01-01T01:48:20',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 133291,
    tank_volume_start: 3750.3999999999987,
    tank_volume_stop: 3690.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T01:48:35',
    timestamp_stop: '2022-01-01T01:48:40',
    quantity: 50.5,
    vehicle: 'DEM333',
    id: 133293,
    tank_volume_start: 3690.3999999999987,
    tank_volume_stop: 3636.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:48:55',
    timestamp_stop: '2022-01-01T01:49:01',
    quantity: 57.3,
    vehicle: 'DEM666',
    id: 133295,
    tank_volume_start: 3636.5999999999985,
    tank_volume_stop: 3587.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:49:16',
    timestamp_stop: '2022-01-01T01:49:22',
    quantity: 58.9,
    vehicle: 'DEM888',
    id: 133297,
    tank_volume_start: 3587.0999999999985,
    tank_volume_stop: 3528.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:49:37',
    timestamp_stop: '2022-01-01T01:49:43',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 133299,
    tank_volume_start: 3528.1999999999985,
    tank_volume_stop: 3469.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:49:58',
    timestamp_stop: '2022-01-01T01:50:04',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 133301,
    tank_volume_start: 3469.2999999999984,
    tank_volume_stop: 3415.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:50:19',
    timestamp_stop: '2022-01-01T01:50:25',
    quantity: 60.8,
    vehicle: 'DEM222',
    id: 133303,
    tank_volume_start: 3415.1999999999985,
    tank_volume_stop: 3362.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:50:40',
    timestamp_stop: '2022-01-01T01:50:45',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 133305,
    tank_volume_start: 3362.1999999999985,
    tank_volume_stop: 3314.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:51:00',
    timestamp_stop: '2022-01-01T01:51:06',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 133307,
    tank_volume_start: 3314.7999999999984,
    tank_volume_stop: 3264.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:51:21',
    timestamp_stop: '2022-01-01T01:51:26',
    quantity: 50.4,
    vehicle: 'DEM888',
    id: 133309,
    tank_volume_start: 3264.3999999999983,
    tank_volume_stop: 3200.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:51:41',
    timestamp_stop: '2022-01-01T01:51:47',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 133311,
    tank_volume_start: 3200.999999999998,
    tank_volume_stop: 3148.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:52:02',
    timestamp_stop: '2022-01-01T01:52:08',
    quantity: 62.6,
    vehicle: 'DEM777',
    id: 133313,
    tank_volume_start: 3148.7999999999984,
    tank_volume_stop: 3091.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T01:52:23',
    timestamp_stop: '2022-01-01T01:52:29',
    quantity: 59.5,
    vehicle: 'DEM555',
    id: 133315,
    tank_volume_start: 3091.0999999999985,
    tank_volume_stop: 3036.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T01:52:44',
    timestamp_stop: '2022-01-01T01:52:50',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 133317,
    tank_volume_start: 3036.2999999999984,
    tank_volume_stop: 2981.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T01:53:05',
    timestamp_stop: '2022-01-01T01:53:11',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 133319,
    tank_volume_start: 2981.3999999999983,
    tank_volume_stop: 2921.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:53:26',
    timestamp_stop: '2022-01-01T01:53:32',
    quantity: 58.0,
    vehicle: 'DEM666',
    id: 133321,
    tank_volume_start: 2921.099999999998,
    tank_volume_stop: 2862.799999999998,
  },
  {
    timestamp_start: '2022-01-01T01:53:47',
    timestamp_stop: '2022-01-01T01:53:52',
    quantity: 49.7,
    vehicle: 'DEM000',
    id: 133323,
    tank_volume_start: 2862.799999999998,
    tank_volume_stop: 2808.399999999998,
  },
  {
    timestamp_start: '2022-01-01T01:54:07',
    timestamp_stop: '2022-01-01T01:54:13',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 133325,
    tank_volume_start: 2808.399999999998,
    tank_volume_stop: 2751.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:54:28',
    timestamp_stop: '2022-01-01T01:54:33',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 133327,
    tank_volume_start: 2751.199999999998,
    tank_volume_stop: 2691.299999999998,
  },
  {
    timestamp_start: '2022-01-01T01:54:48',
    timestamp_stop: '2022-01-01T01:54:54',
    quantity: 60.2,
    vehicle: 'DEM888',
    id: 133329,
    tank_volume_start: 2691.299999999998,
    tank_volume_stop: 2636.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:55:09',
    timestamp_stop: '2022-01-01T01:55:14',
    quantity: 49.9,
    vehicle: 'DEM222',
    id: 133331,
    tank_volume_start: 2636.4999999999977,
    tank_volume_stop: 2577.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:55:29',
    timestamp_stop: '2022-01-01T01:55:34',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 133333,
    tank_volume_start: 2577.4999999999977,
    tank_volume_stop: 2517.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T01:55:49',
    timestamp_stop: '2022-01-01T01:55:55',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 133335,
    tank_volume_start: 2517.1999999999975,
    tank_volume_stop: 2463.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T01:56:10',
    timestamp_stop: '2022-01-01T01:56:15',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 133337,
    tank_volume_start: 2463.0999999999976,
    tank_volume_stop: 2405.399999999998,
  },
  {
    timestamp_start: '2022-01-01T01:56:30',
    timestamp_stop: '2022-01-01T01:56:36',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 133339,
    tank_volume_start: 2405.399999999998,
    tank_volume_stop: 2353.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:56:51',
    timestamp_stop: '2022-01-01T01:56:56',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 133341,
    tank_volume_start: 2353.199999999998,
    tank_volume_stop: 2297.099999999998,
  },
  {
    timestamp_start: '2022-01-01T01:57:11',
    timestamp_stop: '2022-01-01T01:57:17',
    quantity: 58.5,
    vehicle: 'DEM333',
    id: 133343,
    tank_volume_start: 2297.099999999998,
    tank_volume_stop: 2236.999999999998,
  },
  {
    timestamp_start: '2022-01-01T01:57:32',
    timestamp_stop: '2022-01-01T01:57:38',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 133345,
    tank_volume_start: 2236.999999999998,
    tank_volume_stop: 2178.499999999998,
  },
  {
    timestamp_start: '2022-01-01T01:57:53',
    timestamp_stop: '2022-01-01T01:57:58',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 133347,
    tank_volume_start: 2178.499999999998,
    tank_volume_stop: 2128.199999999998,
  },
  {
    timestamp_start: '2022-01-01T01:58:13',
    timestamp_stop: '2022-01-01T01:58:18',
    quantity: 48.5,
    vehicle: 'DEM111',
    id: 133349,
    tank_volume_start: 2128.199999999998,
    tank_volume_stop: 2074.599999999998,
  },
  {
    timestamp_start: '2022-01-01T01:58:33',
    timestamp_stop: '2022-01-01T01:58:39',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 133351,
    tank_volume_start: 2074.599999999998,
    tank_volume_stop: 2011.899999999998,
  },
  {
    timestamp_start: '2022-01-01T01:58:54',
    timestamp_stop: '2022-01-01T01:58:59',
    quantity: 51.8,
    vehicle: 'DEM000',
    id: 133353,
    tank_volume_start: 2011.899999999998,
    tank_volume_stop: 1957.399999999998,
  },
  {
    timestamp_start: '2022-01-01T01:59:14',
    timestamp_stop: '2022-01-01T01:59:19',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 133355,
    tank_volume_start: 3957.399999999998,
    tank_volume_stop: 3898.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T01:59:34',
    timestamp_stop: '2022-01-01T01:59:39',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 133357,
    tank_volume_start: 3898.4999999999977,
    tank_volume_stop: 3843.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T01:59:54',
    timestamp_stop: '2022-01-01T02:00:00',
    quantity: 55.1,
    vehicle: 'DEM777',
    id: 133359,
    tank_volume_start: 3843.1999999999975,
    tank_volume_stop: 3789.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:00:15',
    timestamp_stop: '2022-01-01T02:00:20',
    quantity: 49.8,
    vehicle: 'DEM888',
    id: 133361,
    tank_volume_start: 3789.2999999999975,
    tank_volume_stop: 3736.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T02:00:35',
    timestamp_stop: '2022-01-01T02:00:40',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 133363,
    tank_volume_start: 3736.3999999999974,
    tank_volume_stop: 3684.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:00:55',
    timestamp_stop: '2022-01-01T02:01:01',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 133365,
    tank_volume_start: 3684.2999999999975,
    tank_volume_stop: 3631.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T02:01:16',
    timestamp_stop: '2022-01-01T02:01:21',
    quantity: 48.9,
    vehicle: 'DEM111',
    id: 133367,
    tank_volume_start: 3631.8999999999974,
    tank_volume_stop: 3579.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:01:36',
    timestamp_stop: '2022-01-01T02:01:42',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 133369,
    tank_volume_start: 3579.2999999999975,
    tank_volume_stop: 3524.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T02:01:57',
    timestamp_stop: '2022-01-01T02:02:03',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 133371,
    tank_volume_start: 3524.9999999999973,
    tank_volume_stop: 3475.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T02:02:18',
    timestamp_stop: '2022-01-01T02:02:23',
    quantity: 55.6,
    vehicle: 'DEM000',
    id: 133373,
    tank_volume_start: 3475.7999999999975,
    tank_volume_stop: 3414.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T02:02:38',
    timestamp_stop: '2022-01-01T02:02:43',
    quantity: 48.1,
    vehicle: 'DEM222',
    id: 133375,
    tank_volume_start: 3414.9999999999973,
    tank_volume_stop: 3369.199999999997,
  },
  {
    timestamp_start: '2022-01-01T02:02:58',
    timestamp_stop: '2022-01-01T02:03:04',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 133377,
    tank_volume_start: 3369.199999999997,
    tank_volume_stop: 3312.099999999997,
  },
  {
    timestamp_start: '2022-01-01T02:03:19',
    timestamp_stop: '2022-01-01T02:03:25',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 133379,
    tank_volume_start: 3312.099999999997,
    tank_volume_stop: 3260.199999999997,
  },
  {
    timestamp_start: '2022-01-01T02:03:40',
    timestamp_stop: '2022-01-01T02:03:45',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 133381,
    tank_volume_start: 3260.199999999997,
    tank_volume_stop: 3209.099999999997,
  },
  {
    timestamp_start: '2022-01-01T02:04:00',
    timestamp_stop: '2022-01-01T02:04:06',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 133383,
    tank_volume_start: 3209.099999999997,
    tank_volume_stop: 3155.099999999997,
  },
  {
    timestamp_start: '2022-01-01T02:04:21',
    timestamp_stop: '2022-01-01T02:04:27',
    quantity: 58.4,
    vehicle: 'DEM555',
    id: 133385,
    tank_volume_start: 3155.099999999997,
    tank_volume_stop: 3108.699999999997,
  },
  {
    timestamp_start: '2022-01-01T02:04:42',
    timestamp_stop: '2022-01-01T02:04:47',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 133387,
    tank_volume_start: 3108.699999999997,
    tank_volume_stop: 3053.799999999997,
  },
  {
    timestamp_start: '2022-01-01T02:05:02',
    timestamp_stop: '2022-01-01T02:05:07',
    quantity: 48.6,
    vehicle: 'DEM333',
    id: 133389,
    tank_volume_start: 3053.799999999997,
    tank_volume_stop: 2998.399999999997,
  },
  {
    timestamp_start: '2022-01-01T02:05:22',
    timestamp_stop: '2022-01-01T02:05:27',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 133391,
    tank_volume_start: 2998.399999999997,
    tank_volume_stop: 2943.499999999997,
  },
  {
    timestamp_start: '2022-01-01T02:05:42',
    timestamp_stop: '2022-01-01T02:05:48',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 133393,
    tank_volume_start: 2943.499999999997,
    tank_volume_stop: 2895.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:06:03',
    timestamp_stop: '2022-01-01T02:06:08',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 133395,
    tank_volume_start: 2895.5999999999967,
    tank_volume_stop: 2841.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:06:23',
    timestamp_stop: '2022-01-01T02:06:28',
    quantity: 47.4,
    vehicle: 'DEM222',
    id: 133397,
    tank_volume_start: 2841.5999999999967,
    tank_volume_stop: 2792.999999999997,
  },
  {
    timestamp_start: '2022-01-01T02:06:43',
    timestamp_stop: '2022-01-01T02:06:49',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 133399,
    tank_volume_start: 2792.999999999997,
    tank_volume_stop: 2740.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:07:04',
    timestamp_stop: '2022-01-01T02:07:10',
    quantity: 62.1,
    vehicle: 'DEM555',
    id: 133401,
    tank_volume_start: 2740.0999999999967,
    tank_volume_stop: 2685.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:07:25',
    timestamp_stop: '2022-01-01T02:07:30',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 133403,
    tank_volume_start: 2685.5999999999967,
    tank_volume_stop: 2629.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:07:45',
    timestamp_stop: '2022-01-01T02:07:50',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 133405,
    tank_volume_start: 2629.0999999999967,
    tank_volume_stop: 2575.899999999997,
  },
  {
    timestamp_start: '2022-01-01T02:08:05',
    timestamp_stop: '2022-01-01T02:08:11',
    quantity: 63.3,
    vehicle: 'DEM222',
    id: 133407,
    tank_volume_start: 2575.899999999997,
    tank_volume_stop: 2516.199999999997,
  },
  {
    timestamp_start: '2022-01-01T02:08:26',
    timestamp_stop: '2022-01-01T02:08:32',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 133409,
    tank_volume_start: 2516.199999999997,
    tank_volume_stop: 2455.899999999997,
  },
  {
    timestamp_start: '2022-01-01T02:08:47',
    timestamp_stop: '2022-01-01T02:08:52',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 133411,
    tank_volume_start: 2455.899999999997,
    tank_volume_stop: 2393.199999999997,
  },
  {
    timestamp_start: '2022-01-01T02:09:07',
    timestamp_stop: '2022-01-01T02:09:13',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 133413,
    tank_volume_start: 2393.199999999997,
    tank_volume_stop: 2343.299999999997,
  },
  {
    timestamp_start: '2022-01-01T02:09:28',
    timestamp_stop: '2022-01-01T02:09:34',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 133415,
    tank_volume_start: 2343.299999999997,
    tank_volume_stop: 2287.299999999997,
  },
  {
    timestamp_start: '2022-01-01T02:09:49',
    timestamp_stop: '2022-01-01T02:09:53',
    quantity: 46.7,
    vehicle: 'DEM777',
    id: 133417,
    tank_volume_start: 2287.299999999997,
    tank_volume_stop: 2231.799999999997,
  },
  {
    timestamp_start: '2022-01-01T02:10:08',
    timestamp_stop: '2022-01-01T02:10:13',
    quantity: 51.2,
    vehicle: 'DEM222',
    id: 133419,
    tank_volume_start: 2231.799999999997,
    tank_volume_stop: 2171.999999999997,
  },
  {
    timestamp_start: '2022-01-01T02:10:28',
    timestamp_stop: '2022-01-01T02:10:33',
    quantity: 48.5,
    vehicle: 'DEM333',
    id: 133421,
    tank_volume_start: 2171.999999999997,
    tank_volume_stop: 2117.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T02:10:48',
    timestamp_stop: '2022-01-01T02:10:53',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 133423,
    tank_volume_start: 2117.6999999999966,
    tank_volume_stop: 2064.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:11:08',
    timestamp_stop: '2022-01-01T02:11:14',
    quantity: 60.7,
    vehicle: 'DEM555',
    id: 133425,
    tank_volume_start: 2064.5999999999967,
    tank_volume_stop: 2012.3999999999967,
  },
  {
    timestamp_start: '2022-01-01T02:11:29',
    timestamp_stop: '2022-01-01T02:11:35',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 133427,
    tank_volume_start: 2012.3999999999967,
    tank_volume_stop: 1955.9999999999966,
  },
  {
    timestamp_start: '2022-01-01T02:11:50',
    timestamp_stop: '2022-01-01T02:11:55',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 133429,
    tank_volume_start: 3955.9999999999964,
    tank_volume_stop: 3905.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:12:10',
    timestamp_stop: '2022-01-01T02:12:16',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 133431,
    tank_volume_start: 3905.0999999999963,
    tank_volume_stop: 3845.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:12:31',
    timestamp_stop: '2022-01-01T02:12:36',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 133433,
    tank_volume_start: 3845.5999999999963,
    tank_volume_stop: 3782.699999999996,
  },
  {
    timestamp_start: '2022-01-01T02:12:51',
    timestamp_stop: '2022-01-01T02:12:57',
    quantity: 60.5,
    vehicle: 'DEM222',
    id: 133435,
    tank_volume_start: 3782.699999999996,
    tank_volume_stop: 3731.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T02:13:12',
    timestamp_stop: '2022-01-01T02:13:18',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 133437,
    tank_volume_start: 3731.9999999999964,
    tank_volume_stop: 3678.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T02:13:33',
    timestamp_stop: '2022-01-01T02:13:38',
    quantity: 47.6,
    vehicle: 'DEM555',
    id: 133439,
    tank_volume_start: 3678.2999999999965,
    tank_volume_stop: 3613.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T02:13:53',
    timestamp_stop: '2022-01-01T02:13:58',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 133441,
    tank_volume_start: 3613.4999999999964,
    tank_volume_stop: 3565.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T02:14:13',
    timestamp_stop: '2022-01-01T02:14:19',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 133443,
    tank_volume_start: 3565.3999999999965,
    tank_volume_stop: 3504.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:14:34',
    timestamp_stop: '2022-01-01T02:14:39',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 133445,
    tank_volume_start: 3504.5999999999963,
    tank_volume_stop: 3450.199999999996,
  },
  {
    timestamp_start: '2022-01-01T02:14:54',
    timestamp_stop: '2022-01-01T02:15:00',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 133447,
    tank_volume_start: 3450.199999999996,
    tank_volume_stop: 3397.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:15:15',
    timestamp_stop: '2022-01-01T02:15:21',
    quantity: 64.7,
    vehicle: 'DEM888',
    id: 133449,
    tank_volume_start: 3397.5999999999963,
    tank_volume_stop: 3340.299999999996,
  },
  {
    timestamp_start: '2022-01-01T02:15:36',
    timestamp_stop: '2022-01-01T02:15:42',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 133451,
    tank_volume_start: 3340.299999999996,
    tank_volume_stop: 3288.999999999996,
  },
  {
    timestamp_start: '2022-01-01T02:15:57',
    timestamp_stop: '2022-01-01T02:16:02',
    quantity: 50.4,
    vehicle: 'DEM555',
    id: 133453,
    tank_volume_start: 3288.999999999996,
    tank_volume_stop: 3228.299999999996,
  },
  {
    timestamp_start: '2022-01-01T02:16:17',
    timestamp_stop: '2022-01-01T02:16:22',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 133455,
    tank_volume_start: 3228.299999999996,
    tank_volume_stop: 3174.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:16:37',
    timestamp_stop: '2022-01-01T02:16:42',
    quantity: 49.0,
    vehicle: 'DEM555',
    id: 133457,
    tank_volume_start: 3174.0999999999963,
    tank_volume_stop: 3118.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T02:16:57',
    timestamp_stop: '2022-01-01T02:17:03',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 133459,
    tank_volume_start: 3118.5999999999963,
    tank_volume_stop: 3062.199999999996,
  },
  {
    timestamp_start: '2022-01-01T02:17:18',
    timestamp_stop: '2022-01-01T02:17:24',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 133461,
    tank_volume_start: 3062.199999999996,
    tank_volume_stop: 2998.699999999996,
  },
  {
    timestamp_start: '2022-01-01T02:17:39',
    timestamp_stop: '2022-01-01T02:17:44',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 133463,
    tank_volume_start: 2998.699999999996,
    tank_volume_stop: 2940.399999999996,
  },
  {
    timestamp_start: '2022-01-01T02:17:59',
    timestamp_stop: '2022-01-01T02:18:05',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 133465,
    tank_volume_start: 2940.399999999996,
    tank_volume_stop: 2884.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:18:20',
    timestamp_stop: '2022-01-01T02:18:25',
    quantity: 46.5,
    vehicle: 'DEM444',
    id: 133467,
    tank_volume_start: 2884.599999999996,
    tank_volume_stop: 2835.899999999996,
  },
  {
    timestamp_start: '2022-01-01T02:18:40',
    timestamp_stop: '2022-01-01T02:18:45',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 133469,
    tank_volume_start: 2835.899999999996,
    tank_volume_stop: 2779.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:19:00',
    timestamp_stop: '2022-01-01T02:19:06',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 133471,
    tank_volume_start: 2779.599999999996,
    tank_volume_stop: 2725.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:19:21',
    timestamp_stop: '2022-01-01T02:19:26',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 133473,
    tank_volume_start: 2725.7999999999956,
    tank_volume_stop: 2664.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:19:41',
    timestamp_stop: '2022-01-01T02:19:46',
    quantity: 48.0,
    vehicle: 'DEM888',
    id: 133475,
    tank_volume_start: 2664.7999999999956,
    tank_volume_stop: 2619.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:20:01',
    timestamp_stop: '2022-01-01T02:20:07',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 133477,
    tank_volume_start: 2619.1999999999957,
    tank_volume_stop: 2565.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:20:22',
    timestamp_stop: '2022-01-01T02:20:27',
    quantity: 51.1,
    vehicle: 'DEM888',
    id: 133479,
    tank_volume_start: 2565.599999999996,
    tank_volume_stop: 2514.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:20:42',
    timestamp_stop: '2022-01-01T02:20:47',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 133481,
    tank_volume_start: 2514.6999999999957,
    tank_volume_stop: 2462.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:21:02',
    timestamp_stop: '2022-01-01T02:21:08',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 133483,
    tank_volume_start: 2462.3999999999955,
    tank_volume_stop: 2408.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:21:23',
    timestamp_stop: '2022-01-01T02:21:28',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 133485,
    tank_volume_start: 2408.1999999999957,
    tank_volume_stop: 2351.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:21:43',
    timestamp_stop: '2022-01-01T02:21:49',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 133487,
    tank_volume_start: 2351.3999999999955,
    tank_volume_stop: 2288.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:22:04',
    timestamp_stop: '2022-01-01T02:22:10',
    quantity: 59.1,
    vehicle: 'DEM111',
    id: 133489,
    tank_volume_start: 2288.0999999999954,
    tank_volume_stop: 2236.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T02:22:25',
    timestamp_stop: '2022-01-01T02:22:29',
    quantity: 46.1,
    vehicle: 'DEM888',
    id: 133491,
    tank_volume_start: 2236.6999999999953,
    tank_volume_stop: 2184.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T02:22:44',
    timestamp_stop: '2022-01-01T02:22:50',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 133493,
    tank_volume_start: 2184.1999999999953,
    tank_volume_stop: 2130.799999999995,
  },
  {
    timestamp_start: '2022-01-01T02:23:05',
    timestamp_stop: '2022-01-01T02:23:10',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 133495,
    tank_volume_start: 2130.799999999995,
    tank_volume_stop: 2084.899999999995,
  },
  {
    timestamp_start: '2022-01-01T02:23:25',
    timestamp_stop: '2022-01-01T02:23:30',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 133497,
    tank_volume_start: 2084.899999999995,
    tank_volume_stop: 2023.2999999999952,
  },
  {
    timestamp_start: '2022-01-01T02:23:45',
    timestamp_stop: '2022-01-01T02:23:50',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 133499,
    tank_volume_start: 2023.2999999999952,
    tank_volume_stop: 1975.899999999995,
  },
  {
    timestamp_start: '2022-01-01T02:24:05',
    timestamp_stop: '2022-01-01T02:24:11',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 133501,
    tank_volume_start: 3975.899999999995,
    tank_volume_stop: 3912.599999999995,
  },
  {
    timestamp_start: '2022-01-01T02:24:26',
    timestamp_stop: '2022-01-01T02:24:31',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 133503,
    tank_volume_start: 3912.599999999995,
    tank_volume_stop: 3857.699999999995,
  },
  {
    timestamp_start: '2022-01-01T02:24:46',
    timestamp_stop: '2022-01-01T02:24:51',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 133505,
    tank_volume_start: 3857.699999999995,
    tank_volume_stop: 3804.199999999995,
  },
  {
    timestamp_start: '2022-01-01T02:25:06',
    timestamp_stop: '2022-01-01T02:25:12',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 133507,
    tank_volume_start: 3804.199999999995,
    tank_volume_stop: 3753.999999999995,
  },
  {
    timestamp_start: '2022-01-01T02:25:27',
    timestamp_stop: '2022-01-01T02:25:32',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 133509,
    tank_volume_start: 3753.999999999995,
    tank_volume_stop: 3704.299999999995,
  },
  {
    timestamp_start: '2022-01-01T02:25:47',
    timestamp_stop: '2022-01-01T02:25:53',
    quantity: 60.1,
    vehicle: 'DEM000',
    id: 133511,
    tank_volume_start: 3704.299999999995,
    tank_volume_stop: 3646.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T02:26:08',
    timestamp_stop: '2022-01-01T02:26:13',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 133513,
    tank_volume_start: 3646.6999999999953,
    tank_volume_stop: 3590.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T02:26:28',
    timestamp_stop: '2022-01-01T02:26:34',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 133515,
    tank_volume_start: 3590.6999999999953,
    tank_volume_stop: 3537.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:26:49',
    timestamp_stop: '2022-01-01T02:26:55',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 133517,
    tank_volume_start: 3537.4999999999955,
    tank_volume_stop: 3474.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:27:10',
    timestamp_stop: '2022-01-01T02:27:15',
    quantity: 51.5,
    vehicle: 'DEM111',
    id: 133519,
    tank_volume_start: 3474.9999999999955,
    tank_volume_stop: 3418.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:27:30',
    timestamp_stop: '2022-01-01T02:27:36',
    quantity: 57.4,
    vehicle: 'DEM777',
    id: 133521,
    tank_volume_start: 3418.8999999999955,
    tank_volume_stop: 3358.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:27:51',
    timestamp_stop: '2022-01-01T02:27:56',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 133523,
    tank_volume_start: 3358.6999999999957,
    tank_volume_stop: 3304.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:28:11',
    timestamp_stop: '2022-01-01T02:28:17',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 133525,
    tank_volume_start: 3304.2999999999956,
    tank_volume_stop: 3250.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:28:32',
    timestamp_stop: '2022-01-01T02:28:37',
    quantity: 46.8,
    vehicle: 'DEM444',
    id: 133527,
    tank_volume_start: 3250.7999999999956,
    tank_volume_stop: 3199.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:28:52',
    timestamp_stop: '2022-01-01T02:28:57',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 133529,
    tank_volume_start: 3199.8999999999955,
    tank_volume_stop: 3149.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:29:12',
    timestamp_stop: '2022-01-01T02:29:18',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 133531,
    tank_volume_start: 3149.1999999999957,
    tank_volume_stop: 3092.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:29:33',
    timestamp_stop: '2022-01-01T02:29:38',
    quantity: 47.9,
    vehicle: 'DEM888',
    id: 133533,
    tank_volume_start: 3092.3999999999955,
    tank_volume_stop: 3040.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:29:53',
    timestamp_stop: '2022-01-01T02:29:58',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 133535,
    tank_volume_start: 3040.8999999999955,
    tank_volume_stop: 2983.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:30:13',
    timestamp_stop: '2022-01-01T02:30:19',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 133537,
    tank_volume_start: 2983.8999999999955,
    tank_volume_stop: 2930.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:30:34',
    timestamp_stop: '2022-01-01T02:30:39',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 133539,
    tank_volume_start: 2930.9999999999955,
    tank_volume_stop: 2877.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:30:54',
    timestamp_stop: '2022-01-01T02:30:59',
    quantity: 52.1,
    vehicle: 'DEM111',
    id: 133541,
    tank_volume_start: 2877.0999999999954,
    tank_volume_stop: 2820.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:31:14',
    timestamp_stop: '2022-01-01T02:31:20',
    quantity: 60.6,
    vehicle: 'DEM111',
    id: 133543,
    tank_volume_start: 2820.4999999999955,
    tank_volume_stop: 2766.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:31:35',
    timestamp_stop: '2022-01-01T02:31:40',
    quantity: 47.3,
    vehicle: 'DEM888',
    id: 133545,
    tank_volume_start: 2766.4999999999955,
    tank_volume_stop: 2715.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:31:55',
    timestamp_stop: '2022-01-01T02:32:01',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 133547,
    tank_volume_start: 2715.2999999999956,
    tank_volume_stop: 2657.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:32:16',
    timestamp_stop: '2022-01-01T02:32:22',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 133549,
    tank_volume_start: 2657.2999999999956,
    tank_volume_stop: 2600.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:32:37',
    timestamp_stop: '2022-01-01T02:32:42',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 133551,
    tank_volume_start: 2600.9999999999955,
    tank_volume_stop: 2552.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:32:57',
    timestamp_stop: '2022-01-01T02:33:03',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 133553,
    tank_volume_start: 2552.5999999999954,
    tank_volume_stop: 2497.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:33:18',
    timestamp_stop: '2022-01-01T02:33:23',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 133555,
    tank_volume_start: 2497.5999999999954,
    tank_volume_stop: 2447.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:33:38',
    timestamp_stop: '2022-01-01T02:33:43',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 133557,
    tank_volume_start: 2447.5999999999954,
    tank_volume_stop: 2389.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:33:58',
    timestamp_stop: '2022-01-01T02:34:04',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 133559,
    tank_volume_start: 2389.4999999999955,
    tank_volume_stop: 2329.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:34:19',
    timestamp_stop: '2022-01-01T02:34:24',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 133561,
    tank_volume_start: 2329.9999999999955,
    tank_volume_stop: 2277.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:34:39',
    timestamp_stop: '2022-01-01T02:34:44',
    quantity: 49.6,
    vehicle: 'DEM888',
    id: 133563,
    tank_volume_start: 2277.7999999999956,
    tank_volume_stop: 2224.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:34:59',
    timestamp_stop: '2022-01-01T02:35:05',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 133565,
    tank_volume_start: 2224.3999999999955,
    tank_volume_stop: 2160.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:35:20',
    timestamp_stop: '2022-01-01T02:35:25',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 133567,
    tank_volume_start: 2160.2999999999956,
    tank_volume_stop: 2099.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:35:40',
    timestamp_stop: '2022-01-01T02:35:46',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 133569,
    tank_volume_start: 2099.8999999999955,
    tank_volume_stop: 2041.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:36:01',
    timestamp_stop: '2022-01-01T02:36:06',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 133571,
    tank_volume_start: 2041.4999999999955,
    tank_volume_stop: 1987.1999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:36:21',
    timestamp_stop: '2022-01-01T02:36:27',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 133573,
    tank_volume_start: 3987.1999999999953,
    tank_volume_stop: 3932.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:36:42',
    timestamp_stop: '2022-01-01T02:36:47',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 133575,
    tank_volume_start: 3932.5999999999954,
    tank_volume_stop: 3874.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:37:02',
    timestamp_stop: '2022-01-01T02:37:08',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 133577,
    tank_volume_start: 3874.3999999999955,
    tank_volume_stop: 3809.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:37:23',
    timestamp_stop: '2022-01-01T02:37:29',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 133579,
    tank_volume_start: 3809.5999999999954,
    tank_volume_stop: 3759.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T02:37:44',
    timestamp_stop: '2022-01-01T02:37:49',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 133581,
    tank_volume_start: 3759.6999999999953,
    tank_volume_stop: 3704.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:38:04',
    timestamp_stop: '2022-01-01T02:38:09',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 133583,
    tank_volume_start: 3704.0999999999954,
    tank_volume_stop: 3654.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T02:38:24',
    timestamp_stop: '2022-01-01T02:38:30',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 133585,
    tank_volume_start: 3654.5999999999954,
    tank_volume_stop: 3604.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:38:45',
    timestamp_stop: '2022-01-01T02:38:50',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 133587,
    tank_volume_start: 3604.8999999999955,
    tank_volume_stop: 3550.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:39:05',
    timestamp_stop: '2022-01-01T02:39:10',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 133589,
    tank_volume_start: 3550.8999999999955,
    tank_volume_stop: 3498.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:39:25',
    timestamp_stop: '2022-01-01T02:39:31',
    quantity: 62.8,
    vehicle: 'DEM888',
    id: 133591,
    tank_volume_start: 3498.1999999999957,
    tank_volume_stop: 3443.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:39:46',
    timestamp_stop: '2022-01-01T02:39:52',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 133593,
    tank_volume_start: 3443.3999999999955,
    tank_volume_stop: 3393.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:40:07',
    timestamp_stop: '2022-01-01T02:40:12',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 133595,
    tank_volume_start: 3393.3999999999955,
    tank_volume_stop: 3342.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:40:27',
    timestamp_stop: '2022-01-01T02:40:33',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 133597,
    tank_volume_start: 3342.7999999999956,
    tank_volume_stop: 3288.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:40:48',
    timestamp_stop: '2022-01-01T02:40:54',
    quantity: 63.3,
    vehicle: 'DEM555',
    id: 133599,
    tank_volume_start: 3288.099999999996,
    tank_volume_stop: 3235.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:41:09',
    timestamp_stop: '2022-01-01T02:41:15',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 133601,
    tank_volume_start: 3235.1999999999957,
    tank_volume_stop: 3177.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:41:30',
    timestamp_stop: '2022-01-01T02:41:36',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 133603,
    tank_volume_start: 3177.099999999996,
    tank_volume_stop: 3130.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:41:51',
    timestamp_stop: '2022-01-01T02:41:56',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 133605,
    tank_volume_start: 3130.1999999999957,
    tank_volume_stop: 3069.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:42:11',
    timestamp_stop: '2022-01-01T02:42:16',
    quantity: 50.0,
    vehicle: 'DEM444',
    id: 133607,
    tank_volume_start: 3069.2999999999956,
    tank_volume_stop: 3016.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:42:31',
    timestamp_stop: '2022-01-01T02:42:37',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 133609,
    tank_volume_start: 3016.7999999999956,
    tank_volume_stop: 2960.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:42:52',
    timestamp_stop: '2022-01-01T02:42:57',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 133611,
    tank_volume_start: 2960.4999999999955,
    tank_volume_stop: 2905.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:43:12',
    timestamp_stop: '2022-01-01T02:43:18',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 133613,
    tank_volume_start: 2905.4999999999955,
    tank_volume_stop: 2854.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:43:33',
    timestamp_stop: '2022-01-01T02:43:38',
    quantity: 59.0,
    vehicle: 'DEM888',
    id: 133615,
    tank_volume_start: 2854.4999999999955,
    tank_volume_stop: 2804.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:43:53',
    timestamp_stop: '2022-01-01T02:43:59',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 133617,
    tank_volume_start: 2804.7999999999956,
    tank_volume_stop: 2757.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:44:14',
    timestamp_stop: '2022-01-01T02:44:19',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 133619,
    tank_volume_start: 2757.099999999996,
    tank_volume_stop: 2701.899999999996,
  },
  {
    timestamp_start: '2022-01-01T02:44:34',
    timestamp_stop: '2022-01-01T02:44:40',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 133621,
    tank_volume_start: 2701.899999999996,
    tank_volume_stop: 2647.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:44:55',
    timestamp_stop: '2022-01-01T02:45:01',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 133623,
    tank_volume_start: 2647.599999999996,
    tank_volume_stop: 2588.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:45:16',
    timestamp_stop: '2022-01-01T02:45:21',
    quantity: 58.0,
    vehicle: 'DEM666',
    id: 133625,
    tank_volume_start: 2588.599999999996,
    tank_volume_stop: 2540.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:45:36',
    timestamp_stop: '2022-01-01T02:45:41',
    quantity: 50.9,
    vehicle: 'DEM888',
    id: 133627,
    tank_volume_start: 2540.599999999996,
    tank_volume_stop: 2488.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:45:56',
    timestamp_stop: '2022-01-01T02:46:02',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 133629,
    tank_volume_start: 2488.099999999996,
    tank_volume_stop: 2427.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:46:17',
    timestamp_stop: '2022-01-01T02:46:22',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 133631,
    tank_volume_start: 2427.599999999996,
    tank_volume_stop: 2370.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:46:37',
    timestamp_stop: '2022-01-01T02:46:43',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 133633,
    tank_volume_start: 2370.7999999999956,
    tank_volume_stop: 2319.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:46:58',
    timestamp_stop: '2022-01-01T02:47:03',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 133635,
    tank_volume_start: 2319.099999999996,
    tank_volume_stop: 2263.999999999996,
  },
  {
    timestamp_start: '2022-01-01T02:47:18',
    timestamp_stop: '2022-01-01T02:47:24',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 133637,
    tank_volume_start: 2263.999999999996,
    tank_volume_stop: 2207.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:47:39',
    timestamp_stop: '2022-01-01T02:47:45',
    quantity: 59.8,
    vehicle: 'DEM111',
    id: 133639,
    tank_volume_start: 2207.6999999999957,
    tank_volume_stop: 2144.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:48:00',
    timestamp_stop: '2022-01-01T02:48:05',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 133641,
    tank_volume_start: 2144.2999999999956,
    tank_volume_stop: 2091.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:48:20',
    timestamp_stop: '2022-01-01T02:48:25',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 133643,
    tank_volume_start: 2091.1999999999957,
    tank_volume_stop: 2034.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:48:40',
    timestamp_stop: '2022-01-01T02:48:46',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 133645,
    tank_volume_start: 2034.7999999999956,
    tank_volume_stop: 1978.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:49:01',
    timestamp_stop: '2022-01-01T02:49:07',
    quantity: 62.3,
    vehicle: 'DEM888',
    id: 133647,
    tank_volume_start: 3978.3999999999955,
    tank_volume_stop: 3918.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:49:22',
    timestamp_stop: '2022-01-01T02:49:27',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 133649,
    tank_volume_start: 3918.9999999999955,
    tank_volume_stop: 3866.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:49:42',
    timestamp_stop: '2022-01-01T02:49:47',
    quantity: 48.4,
    vehicle: 'DEM222',
    id: 133651,
    tank_volume_start: 3866.7999999999956,
    tank_volume_stop: 3819.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:50:02',
    timestamp_stop: '2022-01-01T02:50:07',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 133653,
    tank_volume_start: 3819.1999999999957,
    tank_volume_stop: 3767.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:50:22',
    timestamp_stop: '2022-01-01T02:50:28',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 133655,
    tank_volume_start: 3767.1999999999957,
    tank_volume_stop: 3705.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T02:50:43',
    timestamp_stop: '2022-01-01T02:50:48',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 133657,
    tank_volume_start: 3705.8999999999955,
    tank_volume_stop: 3659.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:51:03',
    timestamp_stop: '2022-01-01T02:51:08',
    quantity: 51.1,
    vehicle: 'DEM666',
    id: 133659,
    tank_volume_start: 3659.2999999999956,
    tank_volume_stop: 3595.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:51:23',
    timestamp_stop: '2022-01-01T02:51:29',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 133661,
    tank_volume_start: 3595.6999999999957,
    tank_volume_stop: 3540.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:51:44',
    timestamp_stop: '2022-01-01T02:51:49',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 133663,
    tank_volume_start: 3540.1999999999957,
    tank_volume_stop: 3487.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:52:04',
    timestamp_stop: '2022-01-01T02:52:10',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 133665,
    tank_volume_start: 3487.1999999999957,
    tank_volume_stop: 3427.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:52:25',
    timestamp_stop: '2022-01-01T02:52:30',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 133667,
    tank_volume_start: 3427.7999999999956,
    tank_volume_stop: 3379.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:52:45',
    timestamp_stop: '2022-01-01T02:52:50',
    quantity: 48.3,
    vehicle: 'DEM666',
    id: 133669,
    tank_volume_start: 3379.099999999996,
    tank_volume_stop: 3333.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:53:05',
    timestamp_stop: '2022-01-01T02:53:11',
    quantity: 60.8,
    vehicle: 'DEM666',
    id: 133671,
    tank_volume_start: 3333.2999999999956,
    tank_volume_stop: 3281.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:53:26',
    timestamp_stop: '2022-01-01T02:53:32',
    quantity: 52.2,
    vehicle: 'DEM111',
    id: 133673,
    tank_volume_start: 3281.1999999999957,
    tank_volume_stop: 3234.599999999996,
  },
  {
    timestamp_start: '2022-01-01T02:53:47',
    timestamp_stop: '2022-01-01T02:53:52',
    quantity: 59.5,
    vehicle: 'DEM222',
    id: 133675,
    tank_volume_start: 3234.599999999996,
    tank_volume_stop: 3178.399999999996,
  },
  {
    timestamp_start: '2022-01-01T02:54:07',
    timestamp_stop: '2022-01-01T02:54:13',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 133677,
    tank_volume_start: 3178.399999999996,
    tank_volume_stop: 3121.799999999996,
  },
  {
    timestamp_start: '2022-01-01T02:54:28',
    timestamp_stop: '2022-01-01T02:54:33',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 133679,
    tank_volume_start: 3121.799999999996,
    tank_volume_stop: 3063.199999999996,
  },
  {
    timestamp_start: '2022-01-01T02:54:48',
    timestamp_stop: '2022-01-01T02:54:53',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 133681,
    tank_volume_start: 3063.199999999996,
    tank_volume_stop: 3001.899999999996,
  },
  {
    timestamp_start: '2022-01-01T02:55:08',
    timestamp_stop: '2022-01-01T02:55:14',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 133683,
    tank_volume_start: 3001.899999999996,
    tank_volume_stop: 2946.199999999996,
  },
  {
    timestamp_start: '2022-01-01T02:55:29',
    timestamp_stop: '2022-01-01T02:55:34',
    quantity: 56.6,
    vehicle: 'DEM222',
    id: 133685,
    tank_volume_start: 2946.199999999996,
    tank_volume_stop: 2899.699999999996,
  },
  {
    timestamp_start: '2022-01-01T02:55:49',
    timestamp_stop: '2022-01-01T02:55:55',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 133687,
    tank_volume_start: 2899.699999999996,
    tank_volume_stop: 2844.799999999996,
  },
  {
    timestamp_start: '2022-01-01T02:56:10',
    timestamp_stop: '2022-01-01T02:56:15',
    quantity: 59.5,
    vehicle: 'DEM777',
    id: 133689,
    tank_volume_start: 2844.799999999996,
    tank_volume_stop: 2785.899999999996,
  },
  {
    timestamp_start: '2022-01-01T02:56:30',
    timestamp_stop: '2022-01-01T02:56:36',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 133691,
    tank_volume_start: 2785.899999999996,
    tank_volume_stop: 2729.999999999996,
  },
  {
    timestamp_start: '2022-01-01T02:56:51',
    timestamp_stop: '2022-01-01T02:56:56',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 133693,
    tank_volume_start: 2729.999999999996,
    tank_volume_stop: 2669.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:57:11',
    timestamp_stop: '2022-01-01T02:57:17',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 133695,
    tank_volume_start: 2669.099999999996,
    tank_volume_stop: 2614.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:57:32',
    timestamp_stop: '2022-01-01T02:57:37',
    quantity: 51.1,
    vehicle: 'DEM888',
    id: 133697,
    tank_volume_start: 2614.1999999999957,
    tank_volume_stop: 2557.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:57:52',
    timestamp_stop: '2022-01-01T02:57:57',
    quantity: 48.0,
    vehicle: 'DEM222',
    id: 133699,
    tank_volume_start: 2557.7999999999956,
    tank_volume_stop: 2496.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:58:12',
    timestamp_stop: '2022-01-01T02:58:17',
    quantity: 52.5,
    vehicle: 'DEM888',
    id: 133701,
    tank_volume_start: 2496.7999999999956,
    tank_volume_stop: 2446.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T02:58:32',
    timestamp_stop: '2022-01-01T02:58:38',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 133703,
    tank_volume_start: 2446.6999999999957,
    tank_volume_stop: 2392.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:58:53',
    timestamp_stop: '2022-01-01T02:58:59',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 133705,
    tank_volume_start: 2392.7999999999956,
    tank_volume_stop: 2337.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:59:14',
    timestamp_stop: '2022-01-01T02:59:19',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 133707,
    tank_volume_start: 2337.099999999996,
    tank_volume_stop: 2283.099999999996,
  },
  {
    timestamp_start: '2022-01-01T02:59:34',
    timestamp_stop: '2022-01-01T02:59:39',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 133709,
    tank_volume_start: 2283.099999999996,
    tank_volume_stop: 2221.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T02:59:54',
    timestamp_stop: '2022-01-01T03:00:00',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 133711,
    tank_volume_start: 2221.7999999999956,
    tank_volume_stop: 2171.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:00:15',
    timestamp_stop: '2022-01-01T03:00:21',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 133713,
    tank_volume_start: 2171.6999999999957,
    tank_volume_stop: 2118.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:00:36',
    timestamp_stop: '2022-01-01T03:00:41',
    quantity: 52.5,
    vehicle: 'DEM888',
    id: 133715,
    tank_volume_start: 2118.6999999999957,
    tank_volume_stop: 2061.999999999996,
  },
  {
    timestamp_start: '2022-01-01T03:00:56',
    timestamp_stop: '2022-01-01T03:01:01',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 133717,
    tank_volume_start: 2061.999999999996,
    tank_volume_stop: 1999.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:01:16',
    timestamp_stop: '2022-01-01T03:01:22',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 133719,
    tank_volume_start: 3999.699999999996,
    tank_volume_stop: 3946.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:01:37',
    timestamp_stop: '2022-01-01T03:01:42',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 133721,
    tank_volume_start: 3946.699999999996,
    tank_volume_stop: 3883.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:01:57',
    timestamp_stop: '2022-01-01T03:02:02',
    quantity: 47.4,
    vehicle: 'DEM777',
    id: 133723,
    tank_volume_start: 3883.9999999999964,
    tank_volume_stop: 3829.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:17',
    timestamp_stop: '2022-01-01T03:02:22',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 133725,
    tank_volume_start: 3829.199999999996,
    tank_volume_stop: 3783.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:37',
    timestamp_stop: '2022-01-01T03:02:42',
    quantity: 50.1,
    vehicle: 'DEM333',
    id: 133727,
    tank_volume_start: 3783.299999999996,
    tank_volume_stop: 3724.799999999996,
  },
  {
    timestamp_start: '2022-01-01T03:02:57',
    timestamp_stop: '2022-01-01T03:03:03',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 133729,
    tank_volume_start: 3724.799999999996,
    tank_volume_stop: 3672.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:03:18',
    timestamp_stop: '2022-01-01T03:03:25',
    quantity: 62.7,
    vehicle: 'DEM222',
    id: 133731,
    tank_volume_start: 3672.299999999996,
    tank_volume_stop: 3625.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:03:40',
    timestamp_stop: '2022-01-01T03:03:45',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 133733,
    tank_volume_start: 3625.299999999996,
    tank_volume_stop: 3572.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:04:00',
    timestamp_stop: '2022-01-01T03:04:06',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 133735,
    tank_volume_start: 3572.699999999996,
    tank_volume_stop: 3516.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:04:21',
    timestamp_stop: '2022-01-01T03:04:27',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 133737,
    tank_volume_start: 3516.9999999999964,
    tank_volume_stop: 3465.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:04:42',
    timestamp_stop: '2022-01-01T03:04:47',
    quantity: 56.4,
    vehicle: 'DEM777',
    id: 133739,
    tank_volume_start: 3465.699999999996,
    tank_volume_stop: 3414.899999999996,
  },
  {
    timestamp_start: '2022-01-01T03:05:02',
    timestamp_stop: '2022-01-01T03:05:08',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 133741,
    tank_volume_start: 3414.899999999996,
    tank_volume_stop: 3355.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:05:23',
    timestamp_stop: '2022-01-01T03:05:29',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 133743,
    tank_volume_start: 3355.099999999996,
    tank_volume_stop: 3293.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:05:44',
    timestamp_stop: '2022-01-01T03:05:49',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 133745,
    tank_volume_start: 3293.599999999996,
    tank_volume_stop: 3238.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:06:04',
    timestamp_stop: '2022-01-01T03:06:09',
    quantity: 47.1,
    vehicle: 'DEM666',
    id: 133747,
    tank_volume_start: 3238.2999999999956,
    tank_volume_stop: 3187.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:06:24',
    timestamp_stop: '2022-01-01T03:06:29',
    quantity: 48.4,
    vehicle: 'DEM000',
    id: 133749,
    tank_volume_start: 3187.8999999999955,
    tank_volume_stop: 3129.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:06:44',
    timestamp_stop: '2022-01-01T03:06:50',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 133751,
    tank_volume_start: 3129.4999999999955,
    tank_volume_stop: 3067.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:07:05',
    timestamp_stop: '2022-01-01T03:07:11',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 133753,
    tank_volume_start: 3067.1999999999953,
    tank_volume_stop: 3019.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:07:26',
    timestamp_stop: '2022-01-01T03:07:32',
    quantity: 60.8,
    vehicle: 'DEM555',
    id: 133755,
    tank_volume_start: 3019.0999999999954,
    tank_volume_stop: 2963.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:07:47',
    timestamp_stop: '2022-01-01T03:07:52',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 133757,
    tank_volume_start: 2963.1999999999953,
    tank_volume_stop: 2911.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:08:07',
    timestamp_stop: '2022-01-01T03:08:13',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 133759,
    tank_volume_start: 2911.0999999999954,
    tank_volume_stop: 2858.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:08:28',
    timestamp_stop: '2022-01-01T03:08:34',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 133761,
    tank_volume_start: 2858.9999999999955,
    tank_volume_stop: 2811.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:08:49',
    timestamp_stop: '2022-01-01T03:08:54',
    quantity: 55.6,
    vehicle: 'DEM333',
    id: 133763,
    tank_volume_start: 2811.4999999999955,
    tank_volume_stop: 2755.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:09:09',
    timestamp_stop: '2022-01-01T03:09:15',
    quantity: 58.4,
    vehicle: 'DEM555',
    id: 133765,
    tank_volume_start: 2755.5999999999954,
    tank_volume_stop: 2701.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:09:30',
    timestamp_stop: '2022-01-01T03:09:35',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 133767,
    tank_volume_start: 2701.9999999999955,
    tank_volume_stop: 2648.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:09:50',
    timestamp_stop: '2022-01-01T03:09:56',
    quantity: 56.6,
    vehicle: 'DEM222',
    id: 133769,
    tank_volume_start: 2648.6999999999953,
    tank_volume_stop: 2595.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:10:11',
    timestamp_stop: '2022-01-01T03:10:15',
    quantity: 46.2,
    vehicle: 'DEM777',
    id: 133771,
    tank_volume_start: 2595.299999999995,
    tank_volume_stop: 2538.899999999995,
  },
  {
    timestamp_start: '2022-01-01T03:10:30',
    timestamp_stop: '2022-01-01T03:10:35',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 133773,
    tank_volume_start: 2538.899999999995,
    tank_volume_stop: 2481.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:10:50',
    timestamp_stop: '2022-01-01T03:10:56',
    quantity: 61.2,
    vehicle: 'DEM888',
    id: 133775,
    tank_volume_start: 2481.299999999995,
    tank_volume_stop: 2421.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:11:11',
    timestamp_stop: '2022-01-01T03:11:17',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 133777,
    tank_volume_start: 2421.6999999999953,
    tank_volume_stop: 2367.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:11:32',
    timestamp_stop: '2022-01-01T03:11:38',
    quantity: 60.8,
    vehicle: 'DEM000',
    id: 133779,
    tank_volume_start: 2367.0999999999954,
    tank_volume_stop: 2312.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:11:53',
    timestamp_stop: '2022-01-01T03:11:58',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 133781,
    tank_volume_start: 2312.9999999999955,
    tank_volume_stop: 2251.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:12:13',
    timestamp_stop: '2022-01-01T03:12:18',
    quantity: 47.1,
    vehicle: 'DEM222',
    id: 133783,
    tank_volume_start: 2251.9999999999955,
    tank_volume_stop: 2197.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:12:33',
    timestamp_stop: '2022-01-01T03:12:40',
    quantity: 63.2,
    vehicle: 'DEM777',
    id: 133785,
    tank_volume_start: 2197.2999999999956,
    tank_volume_stop: 2145.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:12:55',
    timestamp_stop: '2022-01-01T03:13:00',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 133787,
    tank_volume_start: 2145.6999999999957,
    tank_volume_stop: 2091.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:13:15',
    timestamp_stop: '2022-01-01T03:13:21',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 133789,
    tank_volume_start: 2091.8999999999955,
    tank_volume_stop: 2038.1999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:13:36',
    timestamp_stop: '2022-01-01T03:13:41',
    quantity: 54.9,
    vehicle: 'DEM555',
    id: 133791,
    tank_volume_start: 2038.1999999999955,
    tank_volume_stop: 1981.5999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:13:56',
    timestamp_stop: '2022-01-01T03:14:02',
    quantity: 56.7,
    vehicle: 'DEM000',
    id: 133793,
    tank_volume_start: 3981.599999999996,
    tank_volume_stop: 3925.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:14:17',
    timestamp_stop: '2022-01-01T03:14:22',
    quantity: 49.1,
    vehicle: 'DEM222',
    id: 133795,
    tank_volume_start: 3925.399999999996,
    tank_volume_stop: 3871.799999999996,
  },
  {
    timestamp_start: '2022-01-01T03:14:37',
    timestamp_stop: '2022-01-01T03:14:43',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 133797,
    tank_volume_start: 3871.799999999996,
    tank_volume_stop: 3823.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:14:58',
    timestamp_stop: '2022-01-01T03:15:03',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 133799,
    tank_volume_start: 3823.5999999999963,
    tank_volume_stop: 3759.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:15:18',
    timestamp_stop: '2022-01-01T03:15:24',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 133801,
    tank_volume_start: 3759.0999999999963,
    tank_volume_stop: 3704.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:15:39',
    timestamp_stop: '2022-01-01T03:15:45',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 133803,
    tank_volume_start: 3704.199999999996,
    tank_volume_stop: 3656.899999999996,
  },
  {
    timestamp_start: '2022-01-01T03:16:00',
    timestamp_stop: '2022-01-01T03:16:04',
    quantity: 46.0,
    vehicle: 'DEM222',
    id: 133805,
    tank_volume_start: 3656.899999999996,
    tank_volume_stop: 3606.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:16:19',
    timestamp_stop: '2022-01-01T03:16:25',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 133807,
    tank_volume_start: 3606.299999999996,
    tank_volume_stop: 3548.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:16:40',
    timestamp_stop: '2022-01-01T03:16:45',
    quantity: 53.5,
    vehicle: 'DEM000',
    id: 133809,
    tank_volume_start: 3548.299999999996,
    tank_volume_stop: 3492.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:17:00',
    timestamp_stop: '2022-01-01T03:17:05',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 133811,
    tank_volume_start: 3492.299999999996,
    tank_volume_stop: 3444.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:17:20',
    timestamp_stop: '2022-01-01T03:17:26',
    quantity: 58.4,
    vehicle: 'DEM666',
    id: 133813,
    tank_volume_start: 3444.199999999996,
    tank_volume_stop: 3394.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:17:41',
    timestamp_stop: '2022-01-01T03:17:46',
    quantity: 50.8,
    vehicle: 'DEM000',
    id: 133815,
    tank_volume_start: 3394.0999999999963,
    tank_volume_stop: 3341.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:18:01',
    timestamp_stop: '2022-01-01T03:18:06',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 133817,
    tank_volume_start: 3341.9999999999964,
    tank_volume_stop: 3284.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:18:21',
    timestamp_stop: '2022-01-01T03:18:26',
    quantity: 48.9,
    vehicle: 'DEM333',
    id: 133819,
    tank_volume_start: 3284.7999999999965,
    tank_volume_stop: 3230.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:18:41',
    timestamp_stop: '2022-01-01T03:18:47',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 133821,
    tank_volume_start: 3230.3999999999965,
    tank_volume_stop: 3172.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:19:02',
    timestamp_stop: '2022-01-01T03:19:07',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 133823,
    tank_volume_start: 3172.8999999999965,
    tank_volume_stop: 3109.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:19:22',
    timestamp_stop: '2022-01-01T03:19:28',
    quantity: 60.0,
    vehicle: 'DEM333',
    id: 133825,
    tank_volume_start: 3109.5999999999963,
    tank_volume_stop: 3055.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:19:43',
    timestamp_stop: '2022-01-01T03:19:48',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 133827,
    tank_volume_start: 3055.199999999996,
    tank_volume_stop: 3004.899999999996,
  },
  {
    timestamp_start: '2022-01-01T03:20:03',
    timestamp_stop: '2022-01-01T03:20:08',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 133829,
    tank_volume_start: 3004.899999999996,
    tank_volume_stop: 2953.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:20:23',
    timestamp_stop: '2022-01-01T03:20:29',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 133831,
    tank_volume_start: 2953.399999999996,
    tank_volume_stop: 2891.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:20:44',
    timestamp_stop: '2022-01-01T03:20:49',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 133833,
    tank_volume_start: 2891.099999999996,
    tank_volume_stop: 2837.499999999996,
  },
  {
    timestamp_start: '2022-01-01T03:21:04',
    timestamp_stop: '2022-01-01T03:21:10',
    quantity: 60.2,
    vehicle: 'DEM666',
    id: 133835,
    tank_volume_start: 2837.499999999996,
    tank_volume_stop: 2787.899999999996,
  },
  {
    timestamp_start: '2022-01-01T03:21:25',
    timestamp_stop: '2022-01-01T03:21:30',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 133837,
    tank_volume_start: 2787.899999999996,
    tank_volume_stop: 2730.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:21:45',
    timestamp_stop: '2022-01-01T03:21:50',
    quantity: 46.2,
    vehicle: 'DEM222',
    id: 133839,
    tank_volume_start: 2730.599999999996,
    tank_volume_stop: 2672.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:22:05',
    timestamp_stop: '2022-01-01T03:22:11',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 133841,
    tank_volume_start: 2672.099999999996,
    tank_volume_stop: 2617.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:22:26',
    timestamp_stop: '2022-01-01T03:22:32',
    quantity: 64.9,
    vehicle: 'DEM888',
    id: 133843,
    tank_volume_start: 2617.599999999996,
    tank_volume_stop: 2558.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:22:47',
    timestamp_stop: '2022-01-01T03:22:52',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 133845,
    tank_volume_start: 2558.099999999996,
    tank_volume_stop: 2506.499999999996,
  },
  {
    timestamp_start: '2022-01-01T03:23:07',
    timestamp_stop: '2022-01-01T03:23:13',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 133847,
    tank_volume_start: 2506.499999999996,
    tank_volume_stop: 2453.799999999996,
  },
  {
    timestamp_start: '2022-01-01T03:23:28',
    timestamp_stop: '2022-01-01T03:23:34',
    quantity: 58.5,
    vehicle: 'DEM000',
    id: 133849,
    tank_volume_start: 2453.799999999996,
    tank_volume_stop: 2395.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:23:49',
    timestamp_stop: '2022-01-01T03:23:53',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 133851,
    tank_volume_start: 2395.0999999999963,
    tank_volume_stop: 2345.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:24:08',
    timestamp_stop: '2022-01-01T03:24:14',
    quantity: 60.4,
    vehicle: 'DEM333',
    id: 133853,
    tank_volume_start: 2345.8999999999965,
    tank_volume_stop: 2292.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:24:29',
    timestamp_stop: '2022-01-01T03:24:35',
    quantity: 55.4,
    vehicle: 'DEM222',
    id: 133855,
    tank_volume_start: 2292.5999999999963,
    tank_volume_stop: 2235.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:24:50',
    timestamp_stop: '2022-01-01T03:24:55',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 133857,
    tank_volume_start: 2235.3999999999965,
    tank_volume_stop: 2184.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:25:10',
    timestamp_stop: '2022-01-01T03:25:16',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 133859,
    tank_volume_start: 2184.7999999999965,
    tank_volume_stop: 2128.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:25:31',
    timestamp_stop: '2022-01-01T03:25:36',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 133861,
    tank_volume_start: 2128.3999999999965,
    tank_volume_stop: 2065.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:25:51',
    timestamp_stop: '2022-01-01T03:25:57',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 133863,
    tank_volume_start: 2065.8999999999965,
    tank_volume_stop: 2006.6999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:26:12',
    timestamp_stop: '2022-01-01T03:26:17',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 133865,
    tank_volume_start: 2006.6999999999964,
    tank_volume_stop: 1950.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:26:32',
    timestamp_stop: '2022-01-01T03:26:37',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 133867,
    tank_volume_start: 3950.8999999999965,
    tank_volume_stop: 3903.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:26:52',
    timestamp_stop: '2022-01-01T03:26:58',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 133869,
    tank_volume_start: 3903.0999999999963,
    tank_volume_stop: 3847.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:27:13',
    timestamp_stop: '2022-01-01T03:27:18',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 133871,
    tank_volume_start: 3847.3999999999965,
    tank_volume_stop: 3788.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:27:33',
    timestamp_stop: '2022-01-01T03:27:39',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 133873,
    tank_volume_start: 3788.3999999999965,
    tank_volume_stop: 3731.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:27:54',
    timestamp_stop: '2022-01-01T03:28:00',
    quantity: 60.7,
    vehicle: 'DEM888',
    id: 133875,
    tank_volume_start: 3731.7999999999965,
    tank_volume_stop: 3679.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T03:28:15',
    timestamp_stop: '2022-01-01T03:28:20',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 133877,
    tank_volume_start: 3679.8999999999965,
    tank_volume_stop: 3628.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:28:35',
    timestamp_stop: '2022-01-01T03:28:41',
    quantity: 62.3,
    vehicle: 'DEM333',
    id: 133879,
    tank_volume_start: 3628.5999999999963,
    tank_volume_stop: 3565.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:28:56',
    timestamp_stop: '2022-01-01T03:29:02',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 133881,
    tank_volume_start: 3565.699999999996,
    tank_volume_stop: 3517.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:29:17',
    timestamp_stop: '2022-01-01T03:29:22',
    quantity: 48.9,
    vehicle: 'DEM333',
    id: 133883,
    tank_volume_start: 3517.9999999999964,
    tank_volume_stop: 3458.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:29:37',
    timestamp_stop: '2022-01-01T03:29:42',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 133885,
    tank_volume_start: 3458.0999999999963,
    tank_volume_stop: 3401.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:29:57',
    timestamp_stop: '2022-01-01T03:30:03',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 133887,
    tank_volume_start: 3401.0999999999963,
    tank_volume_stop: 3351.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:30:18',
    timestamp_stop: '2022-01-01T03:30:24',
    quantity: 64.5,
    vehicle: 'DEM555',
    id: 133889,
    tank_volume_start: 3351.299999999996,
    tank_volume_stop: 3298.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:30:39',
    timestamp_stop: '2022-01-01T03:30:45',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 133891,
    tank_volume_start: 3298.399999999996,
    tank_volume_stop: 3243.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:31:00',
    timestamp_stop: '2022-01-01T03:31:05',
    quantity: 49.2,
    vehicle: 'DEM888',
    id: 133893,
    tank_volume_start: 3243.599999999996,
    tank_volume_stop: 3190.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:31:20',
    timestamp_stop: '2022-01-01T03:31:25',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 133895,
    tank_volume_start: 3190.2999999999956,
    tank_volume_stop: 3134.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:31:40',
    timestamp_stop: '2022-01-01T03:31:45',
    quantity: 45.5,
    vehicle: 'DEM777',
    id: 133897,
    tank_volume_start: 3134.099999999996,
    tank_volume_stop: 3076.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:32:00',
    timestamp_stop: '2022-01-01T03:32:05',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 133899,
    tank_volume_start: 3076.1999999999957,
    tank_volume_stop: 3018.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:32:20',
    timestamp_stop: '2022-01-01T03:32:25',
    quantity: 51.5,
    vehicle: 'DEM888',
    id: 133901,
    tank_volume_start: 3018.599999999996,
    tank_volume_stop: 2954.999999999996,
  },
  {
    timestamp_start: '2022-01-01T03:32:40',
    timestamp_stop: '2022-01-01T03:32:45',
    quantity: 51.6,
    vehicle: 'DEM111',
    id: 133903,
    tank_volume_start: 2954.999999999996,
    tank_volume_stop: 2899.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:33:00',
    timestamp_stop: '2022-01-01T03:33:05',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 133905,
    tank_volume_start: 2899.399999999996,
    tank_volume_stop: 2846.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:33:20',
    timestamp_stop: '2022-01-01T03:33:26',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 133907,
    tank_volume_start: 2846.099999999996,
    tank_volume_stop: 2795.499999999996,
  },
  {
    timestamp_start: '2022-01-01T03:33:41',
    timestamp_stop: '2022-01-01T03:33:46',
    quantity: 53.2,
    vehicle: 'DEM111',
    id: 133909,
    tank_volume_start: 2795.499999999996,
    tank_volume_stop: 2741.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:34:01',
    timestamp_stop: '2022-01-01T03:34:07',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 133911,
    tank_volume_start: 2741.099999999996,
    tank_volume_stop: 2688.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:34:22',
    timestamp_stop: '2022-01-01T03:34:27',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 133913,
    tank_volume_start: 2688.1999999999957,
    tank_volume_stop: 2632.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:34:42',
    timestamp_stop: '2022-01-01T03:34:49',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 133915,
    tank_volume_start: 2632.7999999999956,
    tank_volume_stop: 2585.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:35:04',
    timestamp_stop: '2022-01-01T03:35:09',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 133917,
    tank_volume_start: 2585.099999999996,
    tank_volume_stop: 2531.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:35:24',
    timestamp_stop: '2022-01-01T03:35:30',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 133919,
    tank_volume_start: 2531.099999999996,
    tank_volume_stop: 2474.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:35:45',
    timestamp_stop: '2022-01-01T03:35:50',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 133921,
    tank_volume_start: 2474.2999999999956,
    tank_volume_stop: 2426.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:36:05',
    timestamp_stop: '2022-01-01T03:36:11',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 133923,
    tank_volume_start: 2426.6999999999957,
    tank_volume_stop: 2363.099999999996,
  },
  {
    timestamp_start: '2022-01-01T03:36:26',
    timestamp_stop: '2022-01-01T03:36:31',
    quantity: 50.9,
    vehicle: 'DEM777',
    id: 133925,
    tank_volume_start: 2363.099999999996,
    tank_volume_stop: 2314.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:36:46',
    timestamp_stop: '2022-01-01T03:36:51',
    quantity: 53.6,
    vehicle: 'DEM777',
    id: 133927,
    tank_volume_start: 2314.399999999996,
    tank_volume_stop: 2252.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:37:06',
    timestamp_stop: '2022-01-01T03:37:12',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 133929,
    tank_volume_start: 2252.699999999996,
    tank_volume_stop: 2201.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:37:27',
    timestamp_stop: '2022-01-01T03:37:33',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 133931,
    tank_volume_start: 2201.0999999999963,
    tank_volume_stop: 2151.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:37:48',
    timestamp_stop: '2022-01-01T03:37:54',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 133933,
    tank_volume_start: 2151.5999999999963,
    tank_volume_stop: 2096.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:38:09',
    timestamp_stop: '2022-01-01T03:38:14',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 133935,
    tank_volume_start: 2096.5999999999963,
    tank_volume_stop: 2039.6999999999962,
  },
  {
    timestamp_start: '2022-01-01T03:38:29',
    timestamp_stop: '2022-01-01T03:38:34',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 133937,
    tank_volume_start: 2039.6999999999962,
    tank_volume_stop: 1979.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:38:49',
    timestamp_stop: '2022-01-01T03:38:55',
    quantity: 61.9,
    vehicle: 'DEM222',
    id: 133939,
    tank_volume_start: 3979.0999999999963,
    tank_volume_stop: 3925.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:39:10',
    timestamp_stop: '2022-01-01T03:39:15',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 133941,
    tank_volume_start: 3925.199999999996,
    tank_volume_stop: 3866.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:39:30',
    timestamp_stop: '2022-01-01T03:39:36',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 133943,
    tank_volume_start: 3866.0999999999963,
    tank_volume_stop: 3817.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:39:51',
    timestamp_stop: '2022-01-01T03:39:57',
    quantity: 61.8,
    vehicle: 'DEM222',
    id: 133945,
    tank_volume_start: 3817.299999999996,
    tank_volume_stop: 3765.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T03:40:12',
    timestamp_stop: '2022-01-01T03:40:18',
    quantity: 61.2,
    vehicle: 'DEM333',
    id: 133947,
    tank_volume_start: 3765.0999999999963,
    tank_volume_stop: 3711.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T03:40:33',
    timestamp_stop: '2022-01-01T03:40:39',
    quantity: 60.7,
    vehicle: 'DEM111',
    id: 133949,
    tank_volume_start: 3711.4999999999964,
    tank_volume_stop: 3661.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:40:54',
    timestamp_stop: '2022-01-01T03:41:00',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 133951,
    tank_volume_start: 3661.199999999996,
    tank_volume_stop: 3603.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:41:15',
    timestamp_stop: '2022-01-01T03:41:20',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 133953,
    tank_volume_start: 3603.399999999996,
    tank_volume_stop: 3553.499999999996,
  },
  {
    timestamp_start: '2022-01-01T03:41:35',
    timestamp_stop: '2022-01-01T03:41:40',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 133955,
    tank_volume_start: 3553.499999999996,
    tank_volume_stop: 3500.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:41:55',
    timestamp_stop: '2022-01-01T03:42:02',
    quantity: 63.3,
    vehicle: 'DEM777',
    id: 133957,
    tank_volume_start: 3500.1999999999957,
    tank_volume_stop: 3445.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T03:42:17',
    timestamp_stop: '2022-01-01T03:42:22',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 133959,
    tank_volume_start: 3445.6999999999957,
    tank_volume_stop: 3395.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:42:37',
    timestamp_stop: '2022-01-01T03:42:42',
    quantity: 50.7,
    vehicle: 'DEM222',
    id: 133961,
    tank_volume_start: 3395.3999999999955,
    tank_volume_stop: 3331.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:42:57',
    timestamp_stop: '2022-01-01T03:43:02',
    quantity: 49.3,
    vehicle: 'DEM888',
    id: 133963,
    tank_volume_start: 3331.4999999999955,
    tank_volume_stop: 3275.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:43:17',
    timestamp_stop: '2022-01-01T03:43:23',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 133965,
    tank_volume_start: 3275.9999999999955,
    tank_volume_stop: 3218.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:43:38',
    timestamp_stop: '2022-01-01T03:43:44',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 133967,
    tank_volume_start: 3218.0999999999954,
    tank_volume_stop: 3166.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:43:59',
    timestamp_stop: '2022-01-01T03:44:04',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 133969,
    tank_volume_start: 3166.9999999999955,
    tank_volume_stop: 3116.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:44:19',
    timestamp_stop: '2022-01-01T03:44:24',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 133971,
    tank_volume_start: 3116.1999999999953,
    tank_volume_stop: 3058.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:44:39',
    timestamp_stop: '2022-01-01T03:44:46',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 133973,
    tank_volume_start: 3058.4999999999955,
    tank_volume_stop: 3008.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:45:01',
    timestamp_stop: '2022-01-01T03:45:05',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 133975,
    tank_volume_start: 3008.7999999999956,
    tank_volume_stop: 2956.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:45:20',
    timestamp_stop: '2022-01-01T03:45:25',
    quantity: 49.9,
    vehicle: 'DEM222',
    id: 133977,
    tank_volume_start: 2956.9999999999955,
    tank_volume_stop: 2899.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:45:40',
    timestamp_stop: '2022-01-01T03:45:46',
    quantity: 61.1,
    vehicle: 'DEM222',
    id: 133979,
    tank_volume_start: 2899.9999999999955,
    tank_volume_stop: 2840.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:46:01',
    timestamp_stop: '2022-01-01T03:46:07',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 133981,
    tank_volume_start: 2840.1999999999953,
    tank_volume_stop: 2783.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:46:22',
    timestamp_stop: '2022-01-01T03:46:27',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 133983,
    tank_volume_start: 2783.299999999995,
    tank_volume_stop: 2729.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T03:46:42',
    timestamp_stop: '2022-01-01T03:46:47',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 133985,
    tank_volume_start: 2729.6999999999953,
    tank_volume_stop: 2674.899999999995,
  },
  {
    timestamp_start: '2022-01-01T03:47:02',
    timestamp_stop: '2022-01-01T03:47:09',
    quantity: 62.7,
    vehicle: 'DEM333',
    id: 133987,
    tank_volume_start: 2674.899999999995,
    tank_volume_stop: 2629.399999999995,
  },
  {
    timestamp_start: '2022-01-01T03:47:24',
    timestamp_stop: '2022-01-01T03:47:29',
    quantity: 51.9,
    vehicle: 'DEM000',
    id: 133989,
    tank_volume_start: 2629.399999999995,
    tank_volume_stop: 2568.099999999995,
  },
  {
    timestamp_start: '2022-01-01T03:47:44',
    timestamp_stop: '2022-01-01T03:47:49',
    quantity: 50.0,
    vehicle: 'DEM888',
    id: 133991,
    tank_volume_start: 2568.099999999995,
    tank_volume_stop: 2514.099999999995,
  },
  {
    timestamp_start: '2022-01-01T03:48:04',
    timestamp_stop: '2022-01-01T03:48:10',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 133993,
    tank_volume_start: 2514.099999999995,
    tank_volume_stop: 2467.199999999995,
  },
  {
    timestamp_start: '2022-01-01T03:48:25',
    timestamp_stop: '2022-01-01T03:48:30',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 133995,
    tank_volume_start: 2467.199999999995,
    tank_volume_stop: 2404.999999999995,
  },
  {
    timestamp_start: '2022-01-01T03:48:45',
    timestamp_stop: '2022-01-01T03:48:50',
    quantity: 50.5,
    vehicle: 'DEM444',
    id: 133997,
    tank_volume_start: 2404.999999999995,
    tank_volume_stop: 2344.899999999995,
  },
  {
    timestamp_start: '2022-01-01T03:49:05',
    timestamp_stop: '2022-01-01T03:49:10',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 133999,
    tank_volume_start: 2344.899999999995,
    tank_volume_stop: 2290.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:49:25',
    timestamp_stop: '2022-01-01T03:49:31',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 134001,
    tank_volume_start: 2290.299999999995,
    tank_volume_stop: 2226.799999999995,
  },
  {
    timestamp_start: '2022-01-01T03:49:46',
    timestamp_stop: '2022-01-01T03:49:51',
    quantity: 49.9,
    vehicle: 'DEM333',
    id: 134003,
    tank_volume_start: 2226.799999999995,
    tank_volume_stop: 2172.499999999995,
  },
  {
    timestamp_start: '2022-01-01T03:50:06',
    timestamp_stop: '2022-01-01T03:50:12',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 134005,
    tank_volume_start: 2172.499999999995,
    tank_volume_stop: 2127.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:50:27',
    timestamp_stop: '2022-01-01T03:50:33',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 134007,
    tank_volume_start: 2127.299999999995,
    tank_volume_stop: 2079.499999999995,
  },
  {
    timestamp_start: '2022-01-01T03:50:48',
    timestamp_stop: '2022-01-01T03:50:54',
    quantity: 61.0,
    vehicle: 'DEM333',
    id: 134009,
    tank_volume_start: 2079.499999999995,
    tank_volume_stop: 2024.899999999995,
  },
  {
    timestamp_start: '2022-01-01T03:51:09',
    timestamp_stop: '2022-01-01T03:51:15',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 134011,
    tank_volume_start: 2024.899999999995,
    tank_volume_stop: 1970.0999999999951,
  },
  {
    timestamp_start: '2022-01-01T03:51:30',
    timestamp_stop: '2022-01-01T03:51:35',
    quantity: 51.6,
    vehicle: 'DEM888',
    id: 134013,
    tank_volume_start: 3970.099999999995,
    tank_volume_stop: 3920.599999999995,
  },
  {
    timestamp_start: '2022-01-01T03:51:50',
    timestamp_stop: '2022-01-01T03:51:55',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 134015,
    tank_volume_start: 3920.599999999995,
    tank_volume_stop: 3860.499999999995,
  },
  {
    timestamp_start: '2022-01-01T03:52:10',
    timestamp_stop: '2022-01-01T03:52:16',
    quantity: 54.2,
    vehicle: 'DEM222',
    id: 134017,
    tank_volume_start: 3860.499999999995,
    tank_volume_stop: 3802.999999999995,
  },
  {
    timestamp_start: '2022-01-01T03:52:31',
    timestamp_stop: '2022-01-01T03:52:36',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 134019,
    tank_volume_start: 3802.999999999995,
    tank_volume_stop: 3748.199999999995,
  },
  {
    timestamp_start: '2022-01-01T03:52:51',
    timestamp_stop: '2022-01-01T03:52:56',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 134021,
    tank_volume_start: 3748.199999999995,
    tank_volume_stop: 3693.3999999999946,
  },
  {
    timestamp_start: '2022-01-01T03:53:11',
    timestamp_stop: '2022-01-01T03:53:16',
    quantity: 47.8,
    vehicle: 'DEM666',
    id: 134023,
    tank_volume_start: 3693.3999999999946,
    tank_volume_stop: 3646.7999999999947,
  },
  {
    timestamp_start: '2022-01-01T03:53:31',
    timestamp_stop: '2022-01-01T03:53:36',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 134025,
    tank_volume_start: 3646.7999999999947,
    tank_volume_stop: 3598.2999999999947,
  },
  {
    timestamp_start: '2022-01-01T03:53:51',
    timestamp_stop: '2022-01-01T03:53:57',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 134027,
    tank_volume_start: 3598.2999999999947,
    tank_volume_stop: 3540.199999999995,
  },
  {
    timestamp_start: '2022-01-01T03:54:12',
    timestamp_stop: '2022-01-01T03:54:18',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 134029,
    tank_volume_start: 3540.199999999995,
    tank_volume_stop: 3484.999999999995,
  },
  {
    timestamp_start: '2022-01-01T03:54:33',
    timestamp_stop: '2022-01-01T03:54:38',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 134031,
    tank_volume_start: 3484.999999999995,
    tank_volume_stop: 3427.299999999995,
  },
  {
    timestamp_start: '2022-01-01T03:54:53',
    timestamp_stop: '2022-01-01T03:54:58',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 134033,
    tank_volume_start: 3427.299999999995,
    tank_volume_stop: 3366.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:55:13',
    timestamp_stop: '2022-01-01T03:55:19',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 134035,
    tank_volume_start: 3366.0999999999954,
    tank_volume_stop: 3317.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:55:34',
    timestamp_stop: '2022-01-01T03:55:40',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 134037,
    tank_volume_start: 3317.9999999999955,
    tank_volume_stop: 3261.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:55:55',
    timestamp_stop: '2022-01-01T03:56:00',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 134039,
    tank_volume_start: 3261.4999999999955,
    tank_volume_stop: 3205.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T03:56:15',
    timestamp_stop: '2022-01-01T03:56:20',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 134041,
    tank_volume_start: 3205.5999999999954,
    tank_volume_stop: 3142.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:56:35',
    timestamp_stop: '2022-01-01T03:56:41',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 134043,
    tank_volume_start: 3142.3999999999955,
    tank_volume_stop: 3088.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:56:56',
    timestamp_stop: '2022-01-01T03:57:01',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 134045,
    tank_volume_start: 3088.2999999999956,
    tank_volume_stop: 3031.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:57:16',
    timestamp_stop: '2022-01-01T03:57:21',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 134047,
    tank_volume_start: 3031.2999999999956,
    tank_volume_stop: 2972.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T03:57:36',
    timestamp_stop: '2022-01-01T03:57:41',
    quantity: 50.8,
    vehicle: 'DEM666',
    id: 134049,
    tank_volume_start: 2972.8999999999955,
    tank_volume_stop: 2918.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T03:57:56',
    timestamp_stop: '2022-01-01T03:58:02',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 134051,
    tank_volume_start: 2918.2999999999956,
    tank_volume_stop: 2863.599999999996,
  },
  {
    timestamp_start: '2022-01-01T03:58:17',
    timestamp_stop: '2022-01-01T03:58:23',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 134053,
    tank_volume_start: 2863.599999999996,
    tank_volume_stop: 2809.399999999996,
  },
  {
    timestamp_start: '2022-01-01T03:58:38',
    timestamp_stop: '2022-01-01T03:58:44',
    quantity: 63.2,
    vehicle: 'DEM888',
    id: 134055,
    tank_volume_start: 2809.399999999996,
    tank_volume_stop: 2754.299999999996,
  },
  {
    timestamp_start: '2022-01-01T03:58:59',
    timestamp_stop: '2022-01-01T03:59:04',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 134057,
    tank_volume_start: 2754.299999999996,
    tank_volume_stop: 2697.199999999996,
  },
  {
    timestamp_start: '2022-01-01T03:59:19',
    timestamp_stop: '2022-01-01T03:59:25',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 134059,
    tank_volume_start: 2697.199999999996,
    tank_volume_stop: 2641.699999999996,
  },
  {
    timestamp_start: '2022-01-01T03:59:40',
    timestamp_stop: '2022-01-01T03:59:45',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 134061,
    tank_volume_start: 2641.699999999996,
    tank_volume_stop: 2588.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:00:00',
    timestamp_stop: '2022-01-01T04:00:06',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 134063,
    tank_volume_start: 2588.0999999999963,
    tank_volume_stop: 2537.299999999996,
  },
  {
    timestamp_start: '2022-01-01T04:00:21',
    timestamp_stop: '2022-01-01T04:00:27',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 134065,
    tank_volume_start: 2537.299999999996,
    tank_volume_stop: 2478.299999999996,
  },
  {
    timestamp_start: '2022-01-01T04:00:42',
    timestamp_stop: '2022-01-01T04:00:48',
    quantity: 63.0,
    vehicle: 'DEM333',
    id: 134067,
    tank_volume_start: 2478.299999999996,
    tank_volume_stop: 2419.399999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:03',
    timestamp_stop: '2022-01-01T04:01:09',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 134069,
    tank_volume_start: 2419.399999999996,
    tank_volume_stop: 2370.199999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:24',
    timestamp_stop: '2022-01-01T04:01:30',
    quantity: 55.6,
    vehicle: 'DEM555',
    id: 134071,
    tank_volume_start: 2370.199999999996,
    tank_volume_stop: 2314.199999999996,
  },
  {
    timestamp_start: '2022-01-01T04:01:45',
    timestamp_stop: '2022-01-01T04:01:51',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 134073,
    tank_volume_start: 2314.199999999996,
    tank_volume_stop: 2263.299999999996,
  },
  {
    timestamp_start: '2022-01-01T04:02:06',
    timestamp_stop: '2022-01-01T04:02:11',
    quantity: 53.8,
    vehicle: 'DEM444',
    id: 134075,
    tank_volume_start: 2263.299999999996,
    tank_volume_stop: 2209.199999999996,
  },
  {
    timestamp_start: '2022-01-01T04:02:26',
    timestamp_stop: '2022-01-01T04:02:32',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 134077,
    tank_volume_start: 2209.199999999996,
    tank_volume_stop: 2159.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:02:47',
    timestamp_stop: '2022-01-01T04:02:53',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 134079,
    tank_volume_start: 2159.4999999999964,
    tank_volume_stop: 2096.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:03:08',
    timestamp_stop: '2022-01-01T04:03:13',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 134081,
    tank_volume_start: 2096.5999999999963,
    tank_volume_stop: 2039.8999999999962,
  },
  {
    timestamp_start: '2022-01-01T04:03:28',
    timestamp_stop: '2022-01-01T04:03:33',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 134083,
    tank_volume_start: 2039.8999999999962,
    tank_volume_stop: 1982.2999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:03:48',
    timestamp_stop: '2022-01-01T04:03:53',
    quantity: 51.0,
    vehicle: 'DEM555',
    id: 134085,
    tank_volume_start: 3982.2999999999965,
    tank_volume_stop: 3920.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:04:08',
    timestamp_stop: '2022-01-01T04:04:14',
    quantity: 58.8,
    vehicle: 'DEM444',
    id: 134087,
    tank_volume_start: 3920.0999999999967,
    tank_volume_stop: 3865.399999999997,
  },
  {
    timestamp_start: '2022-01-01T04:04:29',
    timestamp_stop: '2022-01-01T04:04:35',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 134089,
    tank_volume_start: 3865.399999999997,
    tank_volume_stop: 3810.699999999997,
  },
  {
    timestamp_start: '2022-01-01T04:04:50',
    timestamp_stop: '2022-01-01T04:04:55',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 134091,
    tank_volume_start: 3810.699999999997,
    tank_volume_stop: 3750.199999999997,
  },
  {
    timestamp_start: '2022-01-01T04:05:10',
    timestamp_stop: '2022-01-01T04:05:16',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 134093,
    tank_volume_start: 3750.199999999997,
    tank_volume_stop: 3691.399999999997,
  },
  {
    timestamp_start: '2022-01-01T04:05:31',
    timestamp_stop: '2022-01-01T04:05:37',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 134095,
    tank_volume_start: 3691.399999999997,
    tank_volume_stop: 3634.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:05:52',
    timestamp_stop: '2022-01-01T04:05:57',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 134097,
    tank_volume_start: 3634.5999999999967,
    tank_volume_stop: 3580.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T04:06:12',
    timestamp_stop: '2022-01-01T04:06:18',
    quantity: 63.1,
    vehicle: 'DEM555',
    id: 134099,
    tank_volume_start: 3580.6999999999966,
    tank_volume_stop: 3531.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:06:33',
    timestamp_stop: '2022-01-01T04:06:38',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 134101,
    tank_volume_start: 3531.5999999999967,
    tank_volume_stop: 3483.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:06:53',
    timestamp_stop: '2022-01-01T04:06:59',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 134103,
    tank_volume_start: 3483.2999999999965,
    tank_volume_stop: 3435.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:07:14',
    timestamp_stop: '2022-01-01T04:07:19',
    quantity: 48.1,
    vehicle: 'DEM666',
    id: 134105,
    tank_volume_start: 3435.3999999999965,
    tank_volume_stop: 3380.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:07:34',
    timestamp_stop: '2022-01-01T04:07:40',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 134107,
    tank_volume_start: 3380.2999999999965,
    tank_volume_stop: 3320.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:07:55',
    timestamp_stop: '2022-01-01T04:08:01',
    quantity: 63.8,
    vehicle: 'DEM777',
    id: 134109,
    tank_volume_start: 3320.7999999999965,
    tank_volume_stop: 3263.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:08:16',
    timestamp_stop: '2022-01-01T04:08:22',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 134111,
    tank_volume_start: 3263.7999999999965,
    tank_volume_stop: 3201.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:08:37',
    timestamp_stop: '2022-01-01T04:08:42',
    quantity: 49.8,
    vehicle: 'DEM000',
    id: 134113,
    tank_volume_start: 3201.8999999999965,
    tank_volume_stop: 3145.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:08:57',
    timestamp_stop: '2022-01-01T04:09:02',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 134115,
    tank_volume_start: 3145.0999999999963,
    tank_volume_stop: 3094.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:09:17',
    timestamp_stop: '2022-01-01T04:09:23',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 134117,
    tank_volume_start: 3094.4999999999964,
    tank_volume_stop: 3029.699999999996,
  },
  {
    timestamp_start: '2022-01-01T04:09:38',
    timestamp_stop: '2022-01-01T04:09:43',
    quantity: 48.3,
    vehicle: 'DEM000',
    id: 134119,
    tank_volume_start: 3029.699999999996,
    tank_volume_stop: 2976.899999999996,
  },
  {
    timestamp_start: '2022-01-01T04:09:58',
    timestamp_stop: '2022-01-01T04:10:03',
    quantity: 51.6,
    vehicle: 'DEM888',
    id: 134121,
    tank_volume_start: 2976.899999999996,
    tank_volume_stop: 2916.899999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:18',
    timestamp_stop: '2022-01-01T04:10:23',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 134123,
    tank_volume_start: 2916.899999999996,
    tank_volume_stop: 2858.499999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:38',
    timestamp_stop: '2022-01-01T04:10:43',
    quantity: 49.9,
    vehicle: 'DEM777',
    id: 134125,
    tank_volume_start: 2858.499999999996,
    tank_volume_stop: 2798.999999999996,
  },
  {
    timestamp_start: '2022-01-01T04:10:58',
    timestamp_stop: '2022-01-01T04:11:03',
    quantity: 47.6,
    vehicle: 'DEM222',
    id: 134127,
    tank_volume_start: 2798.999999999996,
    tank_volume_stop: 2740.399999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:18',
    timestamp_stop: '2022-01-01T04:11:23',
    quantity: 49.2,
    vehicle: 'DEM666',
    id: 134129,
    tank_volume_start: 2740.399999999996,
    tank_volume_stop: 2691.399999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:38',
    timestamp_stop: '2022-01-01T04:11:44',
    quantity: 61.4,
    vehicle: 'DEM777',
    id: 134131,
    tank_volume_start: 2691.399999999996,
    tank_volume_stop: 2644.899999999996,
  },
  {
    timestamp_start: '2022-01-01T04:11:59',
    timestamp_stop: '2022-01-01T04:12:04',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 134133,
    tank_volume_start: 2644.899999999996,
    tank_volume_stop: 2592.899999999996,
  },
  {
    timestamp_start: '2022-01-01T04:12:19',
    timestamp_stop: '2022-01-01T04:12:25',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 134135,
    tank_volume_start: 2592.899999999996,
    tank_volume_stop: 2534.299999999996,
  },
  {
    timestamp_start: '2022-01-01T04:12:40',
    timestamp_stop: '2022-01-01T04:12:46',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 134137,
    tank_volume_start: 2534.299999999996,
    tank_volume_stop: 2480.799999999996,
  },
  {
    timestamp_start: '2022-01-01T04:13:01',
    timestamp_stop: '2022-01-01T04:13:06',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 134139,
    tank_volume_start: 2480.799999999996,
    tank_volume_stop: 2429.999999999996,
  },
  {
    timestamp_start: '2022-01-01T04:13:21',
    timestamp_stop: '2022-01-01T04:13:27',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 134141,
    tank_volume_start: 2429.999999999996,
    tank_volume_stop: 2369.599999999996,
  },
  {
    timestamp_start: '2022-01-01T04:13:42',
    timestamp_stop: '2022-01-01T04:13:48',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 134143,
    tank_volume_start: 2369.599999999996,
    tank_volume_stop: 2315.599999999996,
  },
  {
    timestamp_start: '2022-01-01T04:14:03',
    timestamp_stop: '2022-01-01T04:14:07',
    quantity: 47.6,
    vehicle: 'DEM666',
    id: 134145,
    tank_volume_start: 2315.599999999996,
    tank_volume_stop: 2264.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T04:14:22',
    timestamp_stop: '2022-01-01T04:14:28',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 134147,
    tank_volume_start: 2264.2999999999956,
    tank_volume_stop: 2212.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T04:14:43',
    timestamp_stop: '2022-01-01T04:14:49',
    quantity: 62.2,
    vehicle: 'DEM000',
    id: 134149,
    tank_volume_start: 2212.8999999999955,
    tank_volume_stop: 2158.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T04:15:04',
    timestamp_stop: '2022-01-01T04:15:10',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 134151,
    tank_volume_start: 2158.8999999999955,
    tank_volume_stop: 2102.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T04:15:25',
    timestamp_stop: '2022-01-01T04:15:30',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 134153,
    tank_volume_start: 2102.0999999999954,
    tank_volume_stop: 2053.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T04:15:45',
    timestamp_stop: '2022-01-01T04:15:51',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 134155,
    tank_volume_start: 2053.8999999999955,
    tank_volume_stop: 1994.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T04:16:06',
    timestamp_stop: '2022-01-01T04:16:12',
    quantity: 61.3,
    vehicle: 'DEM000',
    id: 134157,
    tank_volume_start: 3994.9999999999955,
    tank_volume_stop: 3939.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T04:16:27',
    timestamp_stop: '2022-01-01T04:16:32',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 134159,
    tank_volume_start: 3939.4999999999955,
    tank_volume_stop: 3883.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T04:16:47',
    timestamp_stop: '2022-01-01T04:16:53',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 134161,
    tank_volume_start: 3883.7999999999956,
    tank_volume_stop: 3826.099999999996,
  },
  {
    timestamp_start: '2022-01-01T04:17:08',
    timestamp_stop: '2022-01-01T04:17:15',
    quantity: 63.6,
    vehicle: 'DEM666',
    id: 134163,
    tank_volume_start: 3826.099999999996,
    tank_volume_stop: 3772.999999999996,
  },
  {
    timestamp_start: '2022-01-01T04:17:30',
    timestamp_stop: '2022-01-01T04:17:35',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 134165,
    tank_volume_start: 3772.999999999996,
    tank_volume_stop: 3713.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T04:17:50',
    timestamp_stop: '2022-01-01T04:17:55',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 134167,
    tank_volume_start: 3713.1999999999957,
    tank_volume_stop: 3658.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T04:18:10',
    timestamp_stop: '2022-01-01T04:18:16',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 134169,
    tank_volume_start: 3658.6999999999957,
    tank_volume_stop: 3597.599999999996,
  },
  {
    timestamp_start: '2022-01-01T04:18:31',
    timestamp_stop: '2022-01-01T04:18:36',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 134171,
    tank_volume_start: 3597.599999999996,
    tank_volume_stop: 3544.099999999996,
  },
  {
    timestamp_start: '2022-01-01T04:18:51',
    timestamp_stop: '2022-01-01T04:18:56',
    quantity: 48.2,
    vehicle: 'DEM444',
    id: 134173,
    tank_volume_start: 3544.099999999996,
    tank_volume_stop: 3489.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T04:19:11',
    timestamp_stop: '2022-01-01T04:19:16',
    quantity: 52.2,
    vehicle: 'DEM222',
    id: 134175,
    tank_volume_start: 3489.6999999999957,
    tank_volume_stop: 3432.499999999996,
  },
  {
    timestamp_start: '2022-01-01T04:19:31',
    timestamp_stop: '2022-01-01T04:19:36',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 134177,
    tank_volume_start: 3432.499999999996,
    tank_volume_stop: 3377.799999999996,
  },
  {
    timestamp_start: '2022-01-01T04:19:51',
    timestamp_stop: '2022-01-01T04:19:57',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 134179,
    tank_volume_start: 3377.799999999996,
    tank_volume_stop: 3328.299999999996,
  },
  {
    timestamp_start: '2022-01-01T04:20:12',
    timestamp_stop: '2022-01-01T04:20:18',
    quantity: 54.1,
    vehicle: 'DEM111',
    id: 134181,
    tank_volume_start: 3328.299999999996,
    tank_volume_stop: 3273.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:20:33',
    timestamp_stop: '2022-01-01T04:20:38',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 134183,
    tank_volume_start: 3273.5999999999963,
    tank_volume_stop: 3225.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:20:53',
    timestamp_stop: '2022-01-01T04:20:59',
    quantity: 61.7,
    vehicle: 'DEM666',
    id: 134185,
    tank_volume_start: 3225.4999999999964,
    tank_volume_stop: 3162.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:21:14',
    timestamp_stop: '2022-01-01T04:21:20',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 134187,
    tank_volume_start: 3162.7999999999965,
    tank_volume_stop: 3105.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:21:35',
    timestamp_stop: '2022-01-01T04:21:40',
    quantity: 50.6,
    vehicle: 'DEM555',
    id: 134189,
    tank_volume_start: 3105.2999999999965,
    tank_volume_stop: 3054.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T04:21:55',
    timestamp_stop: '2022-01-01T04:22:00',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 134191,
    tank_volume_start: 3054.4999999999964,
    tank_volume_stop: 2995.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T04:22:15',
    timestamp_stop: '2022-01-01T04:22:21',
    quantity: 50.2,
    vehicle: 'DEM222',
    id: 134193,
    tank_volume_start: 2995.0999999999963,
    tank_volume_stop: 2937.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:22:36',
    timestamp_stop: '2022-01-01T04:22:41',
    quantity: 58.0,
    vehicle: 'DEM111',
    id: 134195,
    tank_volume_start: 2937.8999999999965,
    tank_volume_stop: 2882.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T04:22:56',
    timestamp_stop: '2022-01-01T04:23:01',
    quantity: 47.9,
    vehicle: 'DEM222',
    id: 134197,
    tank_volume_start: 2882.2999999999965,
    tank_volume_stop: 2831.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:23:16',
    timestamp_stop: '2022-01-01T04:23:22',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 134199,
    tank_volume_start: 2831.5999999999967,
    tank_volume_stop: 2772.499999999997,
  },
  {
    timestamp_start: '2022-01-01T04:23:37',
    timestamp_stop: '2022-01-01T04:23:43',
    quantity: 60.6,
    vehicle: 'DEM555',
    id: 134201,
    tank_volume_start: 2772.499999999997,
    tank_volume_stop: 2713.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T04:23:58',
    timestamp_stop: '2022-01-01T04:24:03',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 134203,
    tank_volume_start: 2713.1999999999966,
    tank_volume_stop: 2657.499999999997,
  },
  {
    timestamp_start: '2022-01-01T04:24:18',
    timestamp_stop: '2022-01-01T04:24:23',
    quantity: 47.3,
    vehicle: 'DEM111',
    id: 134205,
    tank_volume_start: 2657.499999999997,
    tank_volume_stop: 2605.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T04:24:38',
    timestamp_stop: '2022-01-01T04:24:43',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 134207,
    tank_volume_start: 2605.6999999999966,
    tank_volume_stop: 2550.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:24:58',
    timestamp_stop: '2022-01-01T04:25:04',
    quantity: 60.9,
    vehicle: 'DEM444',
    id: 134209,
    tank_volume_start: 2550.5999999999967,
    tank_volume_stop: 2497.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:25:19',
    timestamp_stop: '2022-01-01T04:25:24',
    quantity: 51.2,
    vehicle: 'DEM000',
    id: 134211,
    tank_volume_start: 2497.5999999999967,
    tank_volume_stop: 2443.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:25:39',
    timestamp_stop: '2022-01-01T04:25:46',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 134213,
    tank_volume_start: 2443.0999999999967,
    tank_volume_stop: 2388.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T04:26:01',
    timestamp_stop: '2022-01-01T04:26:06',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 134215,
    tank_volume_start: 2388.6999999999966,
    tank_volume_stop: 2324.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T04:26:21',
    timestamp_stop: '2022-01-01T04:26:27',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 134217,
    tank_volume_start: 2324.5999999999967,
    tank_volume_stop: 2265.999999999997,
  },
  {
    timestamp_start: '2022-01-01T04:26:42',
    timestamp_stop: '2022-01-01T04:26:47',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 134219,
    tank_volume_start: 2265.999999999997,
    tank_volume_stop: 2213.399999999997,
  },
  {
    timestamp_start: '2022-01-01T04:27:02',
    timestamp_stop: '2022-01-01T04:27:07',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 134221,
    tank_volume_start: 2213.399999999997,
    tank_volume_stop: 2156.699999999997,
  },
  {
    timestamp_start: '2022-01-01T04:27:22',
    timestamp_stop: '2022-01-01T04:27:27',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 134223,
    tank_volume_start: 2156.699999999997,
    tank_volume_stop: 2104.299999999997,
  },
  {
    timestamp_start: '2022-01-01T04:27:42',
    timestamp_stop: '2022-01-01T04:27:47',
    quantity: 52.2,
    vehicle: 'DEM222',
    id: 134225,
    tank_volume_start: 2104.299999999997,
    tank_volume_stop: 2049.899999999997,
  },
  {
    timestamp_start: '2022-01-01T04:28:02',
    timestamp_stop: '2022-01-01T04:28:08',
    quantity: 59.8,
    vehicle: 'DEM000',
    id: 134227,
    tank_volume_start: 2049.899999999997,
    tank_volume_stop: 2001.599999999997,
  },
  {
    timestamp_start: '2022-01-01T04:28:23',
    timestamp_stop: '2022-01-01T04:28:29',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 134229,
    tank_volume_start: 2001.599999999997,
    tank_volume_stop: 1949.499999999997,
  },
  {
    timestamp_start: '2022-01-01T04:28:44',
    timestamp_stop: '2022-01-01T04:28:50',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 134231,
    tank_volume_start: 3949.4999999999973,
    tank_volume_stop: 3886.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:29:05',
    timestamp_stop: '2022-01-01T04:29:10',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 134233,
    tank_volume_start: 3886.7999999999975,
    tank_volume_stop: 3832.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:29:25',
    timestamp_stop: '2022-01-01T04:29:30',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 134235,
    tank_volume_start: 3832.1999999999975,
    tank_volume_stop: 3780.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:29:45',
    timestamp_stop: '2022-01-01T04:29:51',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 134237,
    tank_volume_start: 3780.7999999999975,
    tank_volume_stop: 3722.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:30:06',
    timestamp_stop: '2022-01-01T04:30:10',
    quantity: 46.0,
    vehicle: 'DEM111',
    id: 134239,
    tank_volume_start: 3722.4999999999973,
    tank_volume_stop: 3669.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:30:25',
    timestamp_stop: '2022-01-01T04:30:31',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 134241,
    tank_volume_start: 3669.2999999999975,
    tank_volume_stop: 3618.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:30:46',
    timestamp_stop: '2022-01-01T04:30:51',
    quantity: 48.2,
    vehicle: 'DEM777',
    id: 134243,
    tank_volume_start: 3618.4999999999973,
    tank_volume_stop: 3560.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:31:06',
    timestamp_stop: '2022-01-01T04:31:11',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 134245,
    tank_volume_start: 3560.7999999999975,
    tank_volume_stop: 3506.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:31:26',
    timestamp_stop: '2022-01-01T04:31:31',
    quantity: 52.2,
    vehicle: 'DEM666',
    id: 134247,
    tank_volume_start: 3506.3999999999974,
    tank_volume_stop: 3446.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:31:46',
    timestamp_stop: '2022-01-01T04:31:52',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 134249,
    tank_volume_start: 3446.2999999999975,
    tank_volume_stop: 3388.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:32:07',
    timestamp_stop: '2022-01-01T04:32:13',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 134251,
    tank_volume_start: 3388.4999999999973,
    tank_volume_stop: 3328.199999999997,
  },
  {
    timestamp_start: '2022-01-01T04:32:28',
    timestamp_stop: '2022-01-01T04:32:34',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 134253,
    tank_volume_start: 3328.199999999997,
    tank_volume_stop: 3276.399999999997,
  },
  {
    timestamp_start: '2022-01-01T04:32:49',
    timestamp_stop: '2022-01-01T04:32:54',
    quantity: 49.4,
    vehicle: 'DEM555',
    id: 134255,
    tank_volume_start: 3276.399999999997,
    tank_volume_stop: 3221.199999999997,
  },
  {
    timestamp_start: '2022-01-01T04:33:09',
    timestamp_stop: '2022-01-01T04:33:15',
    quantity: 62.6,
    vehicle: 'DEM555',
    id: 134257,
    tank_volume_start: 3221.199999999997,
    tank_volume_stop: 3163.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:33:30',
    timestamp_stop: '2022-01-01T04:33:36',
    quantity: 64.2,
    vehicle: 'DEM555',
    id: 134259,
    tank_volume_start: 3163.9999999999973,
    tank_volume_stop: 3112.599999999997,
  },
  {
    timestamp_start: '2022-01-01T04:33:51',
    timestamp_stop: '2022-01-01T04:33:56',
    quantity: 52.4,
    vehicle: 'DEM333',
    id: 134261,
    tank_volume_start: 3112.599999999997,
    tank_volume_stop: 3055.599999999997,
  },
  {
    timestamp_start: '2022-01-01T04:34:11',
    timestamp_stop: '2022-01-01T04:34:17',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 134263,
    tank_volume_start: 3055.599999999997,
    tank_volume_stop: 2996.799999999997,
  },
  {
    timestamp_start: '2022-01-01T04:34:32',
    timestamp_stop: '2022-01-01T04:34:37',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 134265,
    tank_volume_start: 2996.799999999997,
    tank_volume_stop: 2942.099999999997,
  },
  {
    timestamp_start: '2022-01-01T04:34:52',
    timestamp_stop: '2022-01-01T04:34:58',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 134267,
    tank_volume_start: 2942.099999999997,
    tank_volume_stop: 2895.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:35:13',
    timestamp_stop: '2022-01-01T04:35:19',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 134269,
    tank_volume_start: 2895.3999999999974,
    tank_volume_stop: 2836.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:35:34',
    timestamp_stop: '2022-01-01T04:35:40',
    quantity: 57.1,
    vehicle: 'DEM555',
    id: 134271,
    tank_volume_start: 2836.2999999999975,
    tank_volume_stop: 2777.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:35:55',
    timestamp_stop: '2022-01-01T04:36:01',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 134273,
    tank_volume_start: 2777.9999999999973,
    tank_volume_stop: 2727.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:36:16',
    timestamp_stop: '2022-01-01T04:36:21',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 134275,
    tank_volume_start: 2727.9999999999973,
    tank_volume_stop: 2677.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:36:36',
    timestamp_stop: '2022-01-01T04:36:42',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 134277,
    tank_volume_start: 2677.3999999999974,
    tank_volume_stop: 2621.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:36:57',
    timestamp_stop: '2022-01-01T04:37:02',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 134279,
    tank_volume_start: 2621.6999999999975,
    tank_volume_stop: 2564.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:37:17',
    timestamp_stop: '2022-01-01T04:37:24',
    quantity: 62.4,
    vehicle: 'DEM222',
    id: 134281,
    tank_volume_start: 2564.7999999999975,
    tank_volume_stop: 2509.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:37:39',
    timestamp_stop: '2022-01-01T04:37:44',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 134283,
    tank_volume_start: 2509.0999999999976,
    tank_volume_stop: 2454.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:37:59',
    timestamp_stop: '2022-01-01T04:38:05',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 134285,
    tank_volume_start: 2454.7999999999975,
    tank_volume_stop: 2402.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:38:20',
    timestamp_stop: '2022-01-01T04:38:25',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 134287,
    tank_volume_start: 2402.0999999999976,
    tank_volume_stop: 2355.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:38:40',
    timestamp_stop: '2022-01-01T04:38:45',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 134289,
    tank_volume_start: 2355.0999999999976,
    tank_volume_stop: 2292.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:39:00',
    timestamp_stop: '2022-01-01T04:39:06',
    quantity: 55.6,
    vehicle: 'DEM444',
    id: 134291,
    tank_volume_start: 2292.1999999999975,
    tank_volume_stop: 2231.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:39:21',
    timestamp_stop: '2022-01-01T04:39:26',
    quantity: 52.4,
    vehicle: 'DEM888',
    id: 134293,
    tank_volume_start: 2231.2999999999975,
    tank_volume_stop: 2179.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:39:41',
    timestamp_stop: '2022-01-01T04:39:47',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 134295,
    tank_volume_start: 2179.3999999999974,
    tank_volume_stop: 2126.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:40:02',
    timestamp_stop: '2022-01-01T04:40:07',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 134297,
    tank_volume_start: 2126.2999999999975,
    tank_volume_stop: 2074.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T04:40:22',
    timestamp_stop: '2022-01-01T04:40:27',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 134299,
    tank_volume_start: 2074.4999999999973,
    tank_volume_stop: 2020.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:40:42',
    timestamp_stop: '2022-01-01T04:40:47',
    quantity: 48.3,
    vehicle: 'DEM222',
    id: 134301,
    tank_volume_start: 2020.8999999999974,
    tank_volume_stop: 1971.5999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:41:02',
    timestamp_stop: '2022-01-01T04:41:08',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 134303,
    tank_volume_start: 3971.5999999999976,
    tank_volume_stop: 3908.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:41:23',
    timestamp_stop: '2022-01-01T04:41:28',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 134305,
    tank_volume_start: 3908.6999999999975,
    tank_volume_stop: 3852.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:41:43',
    timestamp_stop: '2022-01-01T04:41:48',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 134307,
    tank_volume_start: 3852.9999999999977,
    tank_volume_stop: 3794.899999999998,
  },
  {
    timestamp_start: '2022-01-01T04:42:03',
    timestamp_stop: '2022-01-01T04:42:08',
    quantity: 51.9,
    vehicle: 'DEM888',
    id: 134309,
    tank_volume_start: 3794.899999999998,
    tank_volume_stop: 3741.399999999998,
  },
  {
    timestamp_start: '2022-01-01T04:42:23',
    timestamp_stop: '2022-01-01T04:42:29',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 134311,
    tank_volume_start: 3741.399999999998,
    tank_volume_stop: 3688.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:42:44',
    timestamp_stop: '2022-01-01T04:42:50',
    quantity: 51.8,
    vehicle: 'DEM222',
    id: 134313,
    tank_volume_start: 3688.9999999999977,
    tank_volume_stop: 3628.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:43:05',
    timestamp_stop: '2022-01-01T04:43:10',
    quantity: 55.8,
    vehicle: 'DEM333',
    id: 134315,
    tank_volume_start: 3628.9999999999977,
    tank_volume_stop: 3569.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:43:25',
    timestamp_stop: '2022-01-01T04:43:31',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 134317,
    tank_volume_start: 3569.0999999999976,
    tank_volume_stop: 3507.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:43:46',
    timestamp_stop: '2022-01-01T04:43:51',
    quantity: 49.0,
    vehicle: 'DEM222',
    id: 134319,
    tank_volume_start: 3507.4999999999977,
    tank_volume_stop: 3450.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:44:06',
    timestamp_stop: '2022-01-01T04:44:11',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 134321,
    tank_volume_start: 3450.0999999999976,
    tank_volume_stop: 3393.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:44:26',
    timestamp_stop: '2022-01-01T04:44:32',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 134323,
    tank_volume_start: 3393.6999999999975,
    tank_volume_stop: 3343.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:44:47',
    timestamp_stop: '2022-01-01T04:44:52',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 134325,
    tank_volume_start: 3343.9999999999977,
    tank_volume_stop: 3295.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:45:07',
    timestamp_stop: '2022-01-01T04:45:13',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 134327,
    tank_volume_start: 3295.5999999999976,
    tank_volume_stop: 3238.899999999998,
  },
  {
    timestamp_start: '2022-01-01T04:45:28',
    timestamp_stop: '2022-01-01T04:45:32',
    quantity: 48.0,
    vehicle: 'DEM555',
    id: 134329,
    tank_volume_start: 3238.899999999998,
    tank_volume_stop: 3191.799999999998,
  },
  {
    timestamp_start: '2022-01-01T04:45:47',
    timestamp_stop: '2022-01-01T04:45:53',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 134331,
    tank_volume_start: 3191.799999999998,
    tank_volume_stop: 3132.599999999998,
  },
  {
    timestamp_start: '2022-01-01T04:46:08',
    timestamp_stop: '2022-01-01T04:46:14',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 134333,
    tank_volume_start: 3132.599999999998,
    tank_volume_stop: 3078.699999999998,
  },
  {
    timestamp_start: '2022-01-01T04:46:29',
    timestamp_stop: '2022-01-01T04:46:34',
    quantity: 57.9,
    vehicle: 'DEM333',
    id: 134335,
    tank_volume_start: 3078.699999999998,
    tank_volume_stop: 3024.799999999998,
  },
  {
    timestamp_start: '2022-01-01T04:46:49',
    timestamp_stop: '2022-01-01T04:46:54',
    quantity: 51.7,
    vehicle: 'DEM888',
    id: 134337,
    tank_volume_start: 3024.799999999998,
    tank_volume_stop: 2965.799999999998,
  },
  {
    timestamp_start: '2022-01-01T04:47:09',
    timestamp_stop: '2022-01-01T04:47:15',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 134339,
    tank_volume_start: 2965.799999999998,
    tank_volume_stop: 2906.199999999998,
  },
  {
    timestamp_start: '2022-01-01T04:47:30',
    timestamp_stop: '2022-01-01T04:47:35',
    quantity: 52.3,
    vehicle: 'DEM444',
    id: 134341,
    tank_volume_start: 2906.199999999998,
    tank_volume_stop: 2851.899999999998,
  },
  {
    timestamp_start: '2022-01-01T04:47:50',
    timestamp_stop: '2022-01-01T04:47:56',
    quantity: 58.0,
    vehicle: 'DEM000',
    id: 134343,
    tank_volume_start: 2851.899999999998,
    tank_volume_stop: 2800.199999999998,
  },
  {
    timestamp_start: '2022-01-01T04:48:11',
    timestamp_stop: '2022-01-01T04:48:16',
    quantity: 46.6,
    vehicle: 'DEM000',
    id: 134345,
    tank_volume_start: 2800.199999999998,
    tank_volume_stop: 2750.199999999998,
  },
  {
    timestamp_start: '2022-01-01T04:48:31',
    timestamp_stop: '2022-01-01T04:48:36',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 134347,
    tank_volume_start: 2750.199999999998,
    tank_volume_stop: 2691.599999999998,
  },
  {
    timestamp_start: '2022-01-01T04:48:51',
    timestamp_stop: '2022-01-01T04:48:57',
    quantity: 58.4,
    vehicle: 'DEM222',
    id: 134349,
    tank_volume_start: 2691.599999999998,
    tank_volume_stop: 2641.799999999998,
  },
  {
    timestamp_start: '2022-01-01T04:49:12',
    timestamp_stop: '2022-01-01T04:49:18',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 134351,
    tank_volume_start: 2641.799999999998,
    tank_volume_stop: 2582.399999999998,
  },
  {
    timestamp_start: '2022-01-01T04:49:33',
    timestamp_stop: '2022-01-01T04:49:38',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 134353,
    tank_volume_start: 2582.399999999998,
    tank_volume_stop: 2529.199999999998,
  },
  {
    timestamp_start: '2022-01-01T04:49:53',
    timestamp_stop: '2022-01-01T04:49:59',
    quantity: 59.0,
    vehicle: 'DEM444',
    id: 134355,
    tank_volume_start: 2529.199999999998,
    tank_volume_stop: 2476.399999999998,
  },
  {
    timestamp_start: '2022-01-01T04:50:14',
    timestamp_stop: '2022-01-01T04:50:19',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 134357,
    tank_volume_start: 2476.399999999998,
    tank_volume_stop: 2419.899999999998,
  },
  {
    timestamp_start: '2022-01-01T04:50:34',
    timestamp_stop: '2022-01-01T04:50:40',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 134359,
    tank_volume_start: 2419.899999999998,
    tank_volume_stop: 2365.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:50:55',
    timestamp_stop: '2022-01-01T04:51:00',
    quantity: 51.5,
    vehicle: 'DEM444',
    id: 134361,
    tank_volume_start: 2365.5999999999976,
    tank_volume_stop: 2313.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:51:15',
    timestamp_stop: '2022-01-01T04:51:21',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 134363,
    tank_volume_start: 2313.0999999999976,
    tank_volume_stop: 2254.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:51:36',
    timestamp_stop: '2022-01-01T04:51:41',
    quantity: 47.4,
    vehicle: 'DEM555',
    id: 134365,
    tank_volume_start: 2254.6999999999975,
    tank_volume_stop: 2205.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:51:56',
    timestamp_stop: '2022-01-01T04:52:00',
    quantity: 48.0,
    vehicle: 'DEM888',
    id: 134367,
    tank_volume_start: 2205.2999999999975,
    tank_volume_stop: 2147.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:52:15',
    timestamp_stop: '2022-01-01T04:52:21',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 134369,
    tank_volume_start: 2147.2999999999975,
    tank_volume_stop: 2086.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:52:36',
    timestamp_stop: '2022-01-01T04:52:41',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 134371,
    tank_volume_start: 2086.6999999999975,
    tank_volume_stop: 2022.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:52:56',
    timestamp_stop: '2022-01-01T04:53:01',
    quantity: 51.1,
    vehicle: 'DEM666',
    id: 134373,
    tank_volume_start: 2022.1999999999975,
    tank_volume_stop: 1969.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:53:16',
    timestamp_stop: '2022-01-01T04:53:22',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 134375,
    tank_volume_start: 3969.7999999999975,
    tank_volume_stop: 3915.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:53:37',
    timestamp_stop: '2022-01-01T04:53:42',
    quantity: 49.0,
    vehicle: 'DEM888',
    id: 134377,
    tank_volume_start: 3915.0999999999976,
    tank_volume_stop: 3862.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:53:57',
    timestamp_stop: '2022-01-01T04:54:03',
    quantity: 61.2,
    vehicle: 'DEM444',
    id: 134379,
    tank_volume_start: 3862.5999999999976,
    tank_volume_stop: 3812.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:54:18',
    timestamp_stop: '2022-01-01T04:54:24',
    quantity: 59.8,
    vehicle: 'DEM666',
    id: 134381,
    tank_volume_start: 3812.9999999999977,
    tank_volume_stop: 3758.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:54:39',
    timestamp_stop: '2022-01-01T04:54:44',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 134383,
    tank_volume_start: 3758.1999999999975,
    tank_volume_stop: 3701.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:54:59',
    timestamp_stop: '2022-01-01T04:55:05',
    quantity: 58.4,
    vehicle: 'DEM666',
    id: 134385,
    tank_volume_start: 3701.5999999999976,
    tank_volume_stop: 3638.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:55:20',
    timestamp_stop: '2022-01-01T04:55:26',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 134387,
    tank_volume_start: 3638.6999999999975,
    tank_volume_stop: 3589.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:55:41',
    timestamp_stop: '2022-01-01T04:55:45',
    quantity: 46.8,
    vehicle: 'DEM555',
    id: 134389,
    tank_volume_start: 3589.2999999999975,
    tank_volume_stop: 3537.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:56:00',
    timestamp_stop: '2022-01-01T04:56:07',
    quantity: 61.3,
    vehicle: 'DEM222',
    id: 134391,
    tank_volume_start: 3537.1999999999975,
    tank_volume_stop: 3482.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:56:22',
    timestamp_stop: '2022-01-01T04:56:28',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 134393,
    tank_volume_start: 3482.6999999999975,
    tank_volume_stop: 3423.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T04:56:43',
    timestamp_stop: '2022-01-01T04:56:48',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 134395,
    tank_volume_start: 3423.8999999999974,
    tank_volume_stop: 3368.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:57:03',
    timestamp_stop: '2022-01-01T04:57:09',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 134397,
    tank_volume_start: 3368.1999999999975,
    tank_volume_stop: 3321.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:57:24',
    timestamp_stop: '2022-01-01T04:57:30',
    quantity: 54.4,
    vehicle: 'DEM888',
    id: 134399,
    tank_volume_start: 3321.6999999999975,
    tank_volume_stop: 3273.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:57:45',
    timestamp_stop: '2022-01-01T04:57:49',
    quantity: 45.2,
    vehicle: 'DEM777',
    id: 134401,
    tank_volume_start: 3273.2999999999975,
    tank_volume_stop: 3213.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:58:04',
    timestamp_stop: '2022-01-01T04:58:10',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 134403,
    tank_volume_start: 3213.5999999999976,
    tank_volume_stop: 3149.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T04:58:25',
    timestamp_stop: '2022-01-01T04:58:30',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 134405,
    tank_volume_start: 3149.4999999999977,
    tank_volume_stop: 3103.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T04:58:45',
    timestamp_stop: '2022-01-01T04:58:51',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 134407,
    tank_volume_start: 3103.6999999999975,
    tank_volume_stop: 3054.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T04:59:06',
    timestamp_stop: '2022-01-01T04:59:12',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 134409,
    tank_volume_start: 3054.5999999999976,
    tank_volume_stop: 2999.399999999998,
  },
  {
    timestamp_start: '2022-01-01T04:59:27',
    timestamp_stop: '2022-01-01T04:59:33',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 134411,
    tank_volume_start: 2999.399999999998,
    tank_volume_stop: 2950.699999999998,
  },
  {
    timestamp_start: '2022-01-01T04:59:48',
    timestamp_stop: '2022-01-01T04:59:54',
    quantity: 59.7,
    vehicle: 'DEM333',
    id: 134413,
    tank_volume_start: 2950.699999999998,
    tank_volume_stop: 2900.299999999998,
  },
  {
    timestamp_start: '2022-01-01T05:00:09',
    timestamp_stop: '2022-01-01T05:00:14',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 134415,
    tank_volume_start: 2900.299999999998,
    tank_volume_stop: 2850.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T05:00:29',
    timestamp_stop: '2022-01-01T05:00:35',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 134417,
    tank_volume_start: 2850.4999999999977,
    tank_volume_stop: 2786.899999999998,
  },
  {
    timestamp_start: '2022-01-01T05:00:50',
    timestamp_stop: '2022-01-01T05:00:56',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 134419,
    tank_volume_start: 2786.899999999998,
    tank_volume_stop: 2736.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T05:01:11',
    timestamp_stop: '2022-01-01T05:01:16',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 134421,
    tank_volume_start: 2736.9999999999977,
    tank_volume_stop: 2680.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T05:01:31',
    timestamp_stop: '2022-01-01T05:01:37',
    quantity: 56.5,
    vehicle: 'DEM222',
    id: 134423,
    tank_volume_start: 2680.9999999999977,
    tank_volume_stop: 2628.799999999998,
  },
  {
    timestamp_start: '2022-01-01T05:01:52',
    timestamp_stop: '2022-01-01T05:01:57',
    quantity: 59.0,
    vehicle: 'DEM111',
    id: 134425,
    tank_volume_start: 2628.799999999998,
    tank_volume_stop: 2577.399999999998,
  },
  {
    timestamp_start: '2022-01-01T05:02:12',
    timestamp_stop: '2022-01-01T05:02:18',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 134427,
    tank_volume_start: 2577.399999999998,
    tank_volume_stop: 2522.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T05:02:33',
    timestamp_stop: '2022-01-01T05:02:39',
    quantity: 58.9,
    vehicle: 'DEM444',
    id: 134429,
    tank_volume_start: 2522.0999999999976,
    tank_volume_stop: 2466.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T05:02:54',
    timestamp_stop: '2022-01-01T05:03:00',
    quantity: 60.8,
    vehicle: 'DEM222',
    id: 134431,
    tank_volume_start: 2466.2999999999975,
    tank_volume_stop: 2410.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:03:15',
    timestamp_stop: '2022-01-01T05:03:20',
    quantity: 51.0,
    vehicle: 'DEM111',
    id: 134433,
    tank_volume_start: 2410.3999999999974,
    tank_volume_stop: 2360.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:03:35',
    timestamp_stop: '2022-01-01T05:03:40',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 134435,
    tank_volume_start: 2360.3999999999974,
    tank_volume_stop: 2296.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T05:03:55',
    timestamp_stop: '2022-01-01T05:04:01',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 134437,
    tank_volume_start: 2296.2999999999975,
    tank_volume_stop: 2237.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T05:04:16',
    timestamp_stop: '2022-01-01T05:04:21',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 134439,
    tank_volume_start: 2237.0999999999976,
    tank_volume_stop: 2178.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T05:04:36',
    timestamp_stop: '2022-01-01T05:04:41',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 134441,
    tank_volume_start: 2178.4999999999977,
    tank_volume_stop: 2123.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T05:04:56',
    timestamp_stop: '2022-01-01T05:05:02',
    quantity: 55.8,
    vehicle: 'DEM333',
    id: 134443,
    tank_volume_start: 2123.0999999999976,
    tank_volume_stop: 2074.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T05:05:17',
    timestamp_stop: '2022-01-01T05:05:22',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 134445,
    tank_volume_start: 2074.6999999999975,
    tank_volume_stop: 2015.3999999999976,
  },
  {
    timestamp_start: '2022-01-01T05:05:37',
    timestamp_stop: '2022-01-01T05:05:43',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 134447,
    tank_volume_start: 2015.3999999999976,
    tank_volume_stop: 1962.4999999999975,
  },
  {
    timestamp_start: '2022-01-01T05:05:58',
    timestamp_stop: '2022-01-01T05:06:03',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 134449,
    tank_volume_start: 3962.4999999999973,
    tank_volume_stop: 3904.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:06:18',
    timestamp_stop: '2022-01-01T05:06:23',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 134451,
    tank_volume_start: 3904.599999999997,
    tank_volume_stop: 3848.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:06:38',
    timestamp_stop: '2022-01-01T05:06:44',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 134453,
    tank_volume_start: 3848.8999999999974,
    tank_volume_stop: 3797.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:06:59',
    timestamp_stop: '2022-01-01T05:07:05',
    quantity: 60.5,
    vehicle: 'DEM777',
    id: 134455,
    tank_volume_start: 3797.8999999999974,
    tank_volume_stop: 3741.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:07:20',
    timestamp_stop: '2022-01-01T05:07:26',
    quantity: 61.6,
    vehicle: 'DEM000',
    id: 134457,
    tank_volume_start: 3741.099999999997,
    tank_volume_stop: 3676.799999999997,
  },
  {
    timestamp_start: '2022-01-01T05:07:41',
    timestamp_stop: '2022-01-01T05:07:46',
    quantity: 50.8,
    vehicle: 'DEM777',
    id: 134459,
    tank_volume_start: 3676.799999999997,
    tank_volume_stop: 3621.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:08:01',
    timestamp_stop: '2022-01-01T05:08:06',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 134461,
    tank_volume_start: 3621.099999999997,
    tank_volume_stop: 3565.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:08:21',
    timestamp_stop: '2022-01-01T05:08:27',
    quantity: 59.5,
    vehicle: 'DEM111',
    id: 134463,
    tank_volume_start: 3565.8999999999974,
    tank_volume_stop: 3511.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:08:42',
    timestamp_stop: '2022-01-01T05:08:48',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 134465,
    tank_volume_start: 3511.9999999999973,
    tank_volume_stop: 3451.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:09:03',
    timestamp_stop: '2022-01-01T05:09:09',
    quantity: 55.3,
    vehicle: 'DEM111',
    id: 134467,
    tank_volume_start: 3451.099999999997,
    tank_volume_stop: 3396.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:09:24',
    timestamp_stop: '2022-01-01T05:09:29',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 134469,
    tank_volume_start: 3396.699999999997,
    tank_volume_stop: 3342.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:09:44',
    timestamp_stop: '2022-01-01T05:09:49',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 134471,
    tank_volume_start: 3342.399999999997,
    tank_volume_stop: 3292.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:10:04',
    timestamp_stop: '2022-01-01T05:10:10',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 134473,
    tank_volume_start: 3292.399999999997,
    tank_volume_stop: 3235.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:10:25',
    timestamp_stop: '2022-01-01T05:10:30',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 134475,
    tank_volume_start: 3235.499999999997,
    tank_volume_stop: 3190.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:10:45',
    timestamp_stop: '2022-01-01T05:10:51',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 134477,
    tank_volume_start: 3190.0999999999967,
    tank_volume_stop: 3133.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:11:06',
    timestamp_stop: '2022-01-01T05:11:12',
    quantity: 56.3,
    vehicle: 'DEM444',
    id: 134479,
    tank_volume_start: 3133.7999999999965,
    tank_volume_stop: 3078.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:11:27',
    timestamp_stop: '2022-01-01T05:11:32',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 134481,
    tank_volume_start: 3078.5999999999967,
    tank_volume_stop: 3022.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:11:47',
    timestamp_stop: '2022-01-01T05:11:53',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 134483,
    tank_volume_start: 3022.5999999999967,
    tank_volume_stop: 2958.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:12:08',
    timestamp_stop: '2022-01-01T05:12:13',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 134485,
    tank_volume_start: 2958.6999999999966,
    tank_volume_stop: 2910.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:12:28',
    timestamp_stop: '2022-01-01T05:12:35',
    quantity: 62.7,
    vehicle: 'DEM111',
    id: 134487,
    tank_volume_start: 2910.0999999999967,
    tank_volume_stop: 2859.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:12:50',
    timestamp_stop: '2022-01-01T05:12:56',
    quantity: 59.9,
    vehicle: 'DEM444',
    id: 134489,
    tank_volume_start: 2859.1999999999966,
    tank_volume_stop: 2804.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:13:11',
    timestamp_stop: '2022-01-01T05:13:15',
    quantity: 45.5,
    vehicle: 'DEM000',
    id: 134491,
    tank_volume_start: 2804.6999999999966,
    tank_volume_stop: 2743.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:13:30',
    timestamp_stop: '2022-01-01T05:13:35',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 134493,
    tank_volume_start: 2743.3999999999965,
    tank_volume_stop: 2687.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:13:50',
    timestamp_stop: '2022-01-01T05:13:56',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 134495,
    tank_volume_start: 2687.2999999999965,
    tank_volume_stop: 2638.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:14:11',
    timestamp_stop: '2022-01-01T05:14:16',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 134497,
    tank_volume_start: 2638.0999999999967,
    tank_volume_stop: 2586.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:14:31',
    timestamp_stop: '2022-01-01T05:14:37',
    quantity: 52.9,
    vehicle: 'DEM333',
    id: 134499,
    tank_volume_start: 2586.999999999997,
    tank_volume_stop: 2539.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:14:52',
    timestamp_stop: '2022-01-01T05:14:58',
    quantity: 61.1,
    vehicle: 'DEM000',
    id: 134501,
    tank_volume_start: 2539.899999999997,
    tank_volume_stop: 2479.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:15:13',
    timestamp_stop: '2022-01-01T05:15:18',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 134503,
    tank_volume_start: 2479.299999999997,
    tank_volume_stop: 2417.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:15:33',
    timestamp_stop: '2022-01-01T05:15:38',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 134505,
    tank_volume_start: 2417.999999999997,
    tank_volume_stop: 2364.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:15:53',
    timestamp_stop: '2022-01-01T05:15:59',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 134507,
    tank_volume_start: 2364.5999999999967,
    tank_volume_stop: 2306.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:16:14',
    timestamp_stop: '2022-01-01T05:16:20',
    quantity: 62.4,
    vehicle: 'DEM444',
    id: 134509,
    tank_volume_start: 2306.899999999997,
    tank_volume_stop: 2254.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:16:35',
    timestamp_stop: '2022-01-01T05:16:40',
    quantity: 53.2,
    vehicle: 'DEM444',
    id: 134511,
    tank_volume_start: 2254.499999999997,
    tank_volume_stop: 2195.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:16:55',
    timestamp_stop: '2022-01-01T05:17:01',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 134513,
    tank_volume_start: 2195.499999999997,
    tank_volume_stop: 2142.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:17:16',
    timestamp_stop: '2022-01-01T05:17:21',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 134515,
    tank_volume_start: 2142.899999999997,
    tank_volume_stop: 2083.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:17:36',
    timestamp_stop: '2022-01-01T05:17:41',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 134517,
    tank_volume_start: 2083.199999999997,
    tank_volume_stop: 2023.8999999999971,
  },
  {
    timestamp_start: '2022-01-01T05:17:56',
    timestamp_stop: '2022-01-01T05:18:02',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 134519,
    tank_volume_start: 2023.8999999999971,
    tank_volume_stop: 1969.3999999999971,
  },
  {
    timestamp_start: '2022-01-01T05:18:17',
    timestamp_stop: '2022-01-01T05:18:22',
    quantity: 47.3,
    vehicle: 'DEM444',
    id: 134521,
    tank_volume_start: 3969.399999999997,
    tank_volume_stop: 3910.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:18:37',
    timestamp_stop: '2022-01-01T05:18:41',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 134523,
    tank_volume_start: 3910.899999999997,
    tank_volume_stop: 3855.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:18:56',
    timestamp_stop: '2022-01-01T05:19:03',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 134525,
    tank_volume_start: 3855.399999999997,
    tank_volume_stop: 3798.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:19:18',
    timestamp_stop: '2022-01-01T05:19:22',
    quantity: 48.6,
    vehicle: 'DEM888',
    id: 134527,
    tank_volume_start: 3798.399999999997,
    tank_volume_stop: 3738.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:19:37',
    timestamp_stop: '2022-01-01T05:19:42',
    quantity: 49.7,
    vehicle: 'DEM444',
    id: 134529,
    tank_volume_start: 3738.899999999997,
    tank_volume_stop: 3684.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:19:57',
    timestamp_stop: '2022-01-01T05:20:04',
    quantity: 61.9,
    vehicle: 'DEM222',
    id: 134531,
    tank_volume_start: 3684.699999999997,
    tank_volume_stop: 3623.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:20:19',
    timestamp_stop: '2022-01-01T05:20:23',
    quantity: 48.5,
    vehicle: 'DEM222',
    id: 134533,
    tank_volume_start: 3623.9999999999973,
    tank_volume_stop: 3568.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:20:38',
    timestamp_stop: '2022-01-01T05:20:43',
    quantity: 47.8,
    vehicle: 'DEM888',
    id: 134535,
    tank_volume_start: 3568.699999999997,
    tank_volume_stop: 3512.799999999997,
  },
  {
    timestamp_start: '2022-01-01T05:20:58',
    timestamp_stop: '2022-01-01T05:21:04',
    quantity: 60.9,
    vehicle: 'DEM333',
    id: 134537,
    tank_volume_start: 3512.799999999997,
    tank_volume_stop: 3458.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:21:19',
    timestamp_stop: '2022-01-01T05:21:25',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 134539,
    tank_volume_start: 3458.999999999997,
    tank_volume_stop: 3407.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:21:40',
    timestamp_stop: '2022-01-01T05:21:46',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 134541,
    tank_volume_start: 3407.499999999997,
    tank_volume_stop: 3354.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:22:01',
    timestamp_stop: '2022-01-01T05:22:06',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 134543,
    tank_volume_start: 3354.299999999997,
    tank_volume_stop: 3302.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:22:21',
    timestamp_stop: '2022-01-01T05:22:26',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 134545,
    tank_volume_start: 3302.699999999997,
    tank_volume_stop: 3242.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:22:41',
    timestamp_stop: '2022-01-01T05:22:47',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 134547,
    tank_volume_start: 3242.399999999997,
    tank_volume_stop: 3193.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:23:02',
    timestamp_stop: '2022-01-01T05:23:09',
    quantity: 64.3,
    vehicle: 'DEM666',
    id: 134549,
    tank_volume_start: 3193.399999999997,
    tank_volume_stop: 3140.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:23:24',
    timestamp_stop: '2022-01-01T05:23:30',
    quantity: 62.0,
    vehicle: 'DEM000',
    id: 134551,
    tank_volume_start: 3140.699999999997,
    tank_volume_stop: 3087.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:23:45',
    timestamp_stop: '2022-01-01T05:23:51',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 134553,
    tank_volume_start: 3087.399999999997,
    tank_volume_stop: 3033.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:24:06',
    timestamp_stop: '2022-01-01T05:24:11',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 134555,
    tank_volume_start: 3033.699999999997,
    tank_volume_stop: 2981.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:24:26',
    timestamp_stop: '2022-01-01T05:24:32',
    quantity: 55.6,
    vehicle: 'DEM333',
    id: 134557,
    tank_volume_start: 2981.099999999997,
    tank_volume_stop: 2933.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:24:47',
    timestamp_stop: '2022-01-01T05:24:53',
    quantity: 62.8,
    vehicle: 'DEM555',
    id: 134559,
    tank_volume_start: 2933.599999999997,
    tank_volume_stop: 2878.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:25:08',
    timestamp_stop: '2022-01-01T05:25:14',
    quantity: 60.6,
    vehicle: 'DEM333',
    id: 134561,
    tank_volume_start: 2878.199999999997,
    tank_volume_stop: 2826.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:25:29',
    timestamp_stop: '2022-01-01T05:25:34',
    quantity: 50.1,
    vehicle: 'DEM555',
    id: 134563,
    tank_volume_start: 2826.599999999997,
    tank_volume_stop: 2764.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:25:49',
    timestamp_stop: '2022-01-01T05:25:55',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 134565,
    tank_volume_start: 2764.199999999997,
    tank_volume_stop: 2703.799999999997,
  },
  {
    timestamp_start: '2022-01-01T05:26:10',
    timestamp_stop: '2022-01-01T05:26:15',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 134567,
    tank_volume_start: 2703.799999999997,
    tank_volume_stop: 2654.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:26:30',
    timestamp_stop: '2022-01-01T05:26:36',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 134569,
    tank_volume_start: 2654.299999999997,
    tank_volume_stop: 2608.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:26:51',
    timestamp_stop: '2022-01-01T05:26:56',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 134571,
    tank_volume_start: 2608.499999999997,
    tank_volume_stop: 2550.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:27:11',
    timestamp_stop: '2022-01-01T05:27:16',
    quantity: 47.8,
    vehicle: 'DEM000',
    id: 134573,
    tank_volume_start: 2550.899999999997,
    tank_volume_stop: 2492.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:27:31',
    timestamp_stop: '2022-01-01T05:27:36',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 134575,
    tank_volume_start: 2492.0999999999967,
    tank_volume_stop: 2429.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:27:51',
    timestamp_stop: '2022-01-01T05:27:58',
    quantity: 64.1,
    vehicle: 'DEM666',
    id: 134577,
    tank_volume_start: 2429.899999999997,
    tank_volume_stop: 2372.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:28:13',
    timestamp_stop: '2022-01-01T05:28:17',
    quantity: 47.0,
    vehicle: 'DEM111',
    id: 134579,
    tank_volume_start: 2372.199999999997,
    tank_volume_stop: 2320.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:28:32',
    timestamp_stop: '2022-01-01T05:28:38',
    quantity: 59.8,
    vehicle: 'DEM000',
    id: 134581,
    tank_volume_start: 2320.699999999997,
    tank_volume_stop: 2258.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:28:53',
    timestamp_stop: '2022-01-01T05:29:00',
    quantity: 64.8,
    vehicle: 'DEM555',
    id: 134583,
    tank_volume_start: 2258.699999999997,
    tank_volume_stop: 2204.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:29:15',
    timestamp_stop: '2022-01-01T05:29:21',
    quantity: 59.3,
    vehicle: 'DEM222',
    id: 134585,
    tank_volume_start: 2204.399999999997,
    tank_volume_stop: 2144.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:29:36',
    timestamp_stop: '2022-01-01T05:29:42',
    quantity: 59.3,
    vehicle: 'DEM222',
    id: 134587,
    tank_volume_start: 2144.699999999997,
    tank_volume_stop: 2085.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:29:57',
    timestamp_stop: '2022-01-01T05:30:01',
    quantity: 47.2,
    vehicle: 'DEM222',
    id: 134589,
    tank_volume_start: 2085.199999999997,
    tank_volume_stop: 2029.3999999999971,
  },
  {
    timestamp_start: '2022-01-01T05:30:16',
    timestamp_stop: '2022-01-01T05:30:22',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 134591,
    tank_volume_start: 2029.3999999999971,
    tank_volume_stop: 1974.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:30:37',
    timestamp_stop: '2022-01-01T05:30:43',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 134593,
    tank_volume_start: 3974.199999999997,
    tank_volume_stop: 3918.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:30:58',
    timestamp_stop: '2022-01-01T05:31:04',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 134595,
    tank_volume_start: 3918.199999999997,
    tank_volume_stop: 3863.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:31:19',
    timestamp_stop: '2022-01-01T05:31:25',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 134597,
    tank_volume_start: 3863.899999999997,
    tank_volume_stop: 3808.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:31:40',
    timestamp_stop: '2022-01-01T05:31:45',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 134599,
    tank_volume_start: 3808.699999999997,
    tank_volume_stop: 3748.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:32:00',
    timestamp_stop: '2022-01-01T05:32:05',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 134601,
    tank_volume_start: 3748.699999999997,
    tank_volume_stop: 3701.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:32:20',
    timestamp_stop: '2022-01-01T05:32:25',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 134603,
    tank_volume_start: 3701.099999999997,
    tank_volume_stop: 3652.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:32:40',
    timestamp_stop: '2022-01-01T05:32:46',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 134605,
    tank_volume_start: 3652.8999999999974,
    tank_volume_stop: 3599.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:33:01',
    timestamp_stop: '2022-01-01T05:33:06',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 134607,
    tank_volume_start: 3599.9999999999973,
    tank_volume_stop: 3548.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:33:21',
    timestamp_stop: '2022-01-01T05:33:26',
    quantity: 48.3,
    vehicle: 'DEM444',
    id: 134609,
    tank_volume_start: 3548.599999999997,
    tank_volume_stop: 3486.799999999997,
  },
  {
    timestamp_start: '2022-01-01T05:33:41',
    timestamp_stop: '2022-01-01T05:33:46',
    quantity: 48.3,
    vehicle: 'DEM111',
    id: 134611,
    tank_volume_start: 3486.799999999997,
    tank_volume_stop: 3435.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:34:01',
    timestamp_stop: '2022-01-01T05:34:07',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 134613,
    tank_volume_start: 3435.399999999997,
    tank_volume_stop: 3384.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:34:22',
    timestamp_stop: '2022-01-01T05:34:27',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 134615,
    tank_volume_start: 3384.899999999997,
    tank_volume_stop: 3336.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:34:42',
    timestamp_stop: '2022-01-01T05:34:48',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 134617,
    tank_volume_start: 3336.299999999997,
    tank_volume_stop: 3279.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:35:03',
    timestamp_stop: '2022-01-01T05:35:08',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 134619,
    tank_volume_start: 3279.399999999997,
    tank_volume_stop: 3223.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:35:23',
    timestamp_stop: '2022-01-01T05:35:27',
    quantity: 45.8,
    vehicle: 'DEM111',
    id: 134621,
    tank_volume_start: 3223.5999999999967,
    tank_volume_stop: 3174.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:35:42',
    timestamp_stop: '2022-01-01T05:35:48',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 134623,
    tank_volume_start: 3174.6999999999966,
    tank_volume_stop: 3120.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:36:03',
    timestamp_stop: '2022-01-01T05:36:09',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 134625,
    tank_volume_start: 3120.6999999999966,
    tank_volume_stop: 3072.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:36:24',
    timestamp_stop: '2022-01-01T05:36:29',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 134627,
    tank_volume_start: 3072.1999999999966,
    tank_volume_stop: 3012.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:36:44',
    timestamp_stop: '2022-01-01T05:36:50',
    quantity: 58.7,
    vehicle: 'DEM777',
    id: 134629,
    tank_volume_start: 3012.999999999997,
    tank_volume_stop: 2951.399999999997,
  },
  {
    timestamp_start: '2022-01-01T05:37:05',
    timestamp_stop: '2022-01-01T05:37:11',
    quantity: 61.5,
    vehicle: 'DEM111',
    id: 134631,
    tank_volume_start: 2951.399999999997,
    tank_volume_stop: 2902.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:37:26',
    timestamp_stop: '2022-01-01T05:37:31',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 134633,
    tank_volume_start: 2902.299999999997,
    tank_volume_stop: 2848.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:37:46',
    timestamp_stop: '2022-01-01T05:37:51',
    quantity: 50.3,
    vehicle: 'DEM777',
    id: 134635,
    tank_volume_start: 2848.299999999997,
    tank_volume_stop: 2783.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:38:06',
    timestamp_stop: '2022-01-01T05:38:12',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 134637,
    tank_volume_start: 2783.599999999997,
    tank_volume_stop: 2733.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:38:27',
    timestamp_stop: '2022-01-01T05:38:33',
    quantity: 61.6,
    vehicle: 'DEM111',
    id: 134639,
    tank_volume_start: 2733.4999999999973,
    tank_volume_stop: 2682.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T05:38:48',
    timestamp_stop: '2022-01-01T05:38:54',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 134641,
    tank_volume_start: 2682.7999999999975,
    tank_volume_stop: 2623.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T05:39:09',
    timestamp_stop: '2022-01-01T05:39:15',
    quantity: 56.6,
    vehicle: 'DEM111',
    id: 134643,
    tank_volume_start: 2623.3999999999974,
    tank_volume_stop: 2564.099999999997,
  },
  {
    timestamp_start: '2022-01-01T05:39:30',
    timestamp_stop: '2022-01-01T05:39:35',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 134645,
    tank_volume_start: 2564.099999999997,
    tank_volume_stop: 2505.299999999997,
  },
  {
    timestamp_start: '2022-01-01T05:39:50',
    timestamp_stop: '2022-01-01T05:39:55',
    quantity: 47.8,
    vehicle: 'DEM444',
    id: 134647,
    tank_volume_start: 2505.299999999997,
    tank_volume_stop: 2448.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:40:10',
    timestamp_stop: '2022-01-01T05:40:15',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 134649,
    tank_volume_start: 2448.199999999997,
    tank_volume_stop: 2389.199999999997,
  },
  {
    timestamp_start: '2022-01-01T05:40:30',
    timestamp_stop: '2022-01-01T05:40:35',
    quantity: 52.2,
    vehicle: 'DEM666',
    id: 134651,
    tank_volume_start: 2389.199999999997,
    tank_volume_stop: 2338.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:40:50',
    timestamp_stop: '2022-01-01T05:40:56',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 134653,
    tank_volume_start: 2338.599999999997,
    tank_volume_stop: 2289.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:41:11',
    timestamp_stop: '2022-01-01T05:41:16',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 134655,
    tank_volume_start: 2289.599999999997,
    tank_volume_stop: 2229.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:41:31',
    timestamp_stop: '2022-01-01T05:41:37',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 134657,
    tank_volume_start: 2229.4999999999973,
    tank_volume_stop: 2169.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:41:52',
    timestamp_stop: '2022-01-01T05:41:57',
    quantity: 49.9,
    vehicle: 'DEM555',
    id: 134659,
    tank_volume_start: 2169.4999999999973,
    tank_volume_stop: 2113.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:42:12',
    timestamp_stop: '2022-01-01T05:42:18',
    quantity: 59.0,
    vehicle: 'DEM666',
    id: 134661,
    tank_volume_start: 2113.9999999999973,
    tank_volume_stop: 2064.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T05:42:33',
    timestamp_stop: '2022-01-01T05:42:38',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 134663,
    tank_volume_start: 2064.9999999999973,
    tank_volume_stop: 2011.2999999999972,
  },
  {
    timestamp_start: '2022-01-01T05:42:53',
    timestamp_stop: '2022-01-01T05:42:59',
    quantity: 54.2,
    vehicle: 'DEM888',
    id: 134665,
    tank_volume_start: 2011.2999999999972,
    tank_volume_stop: 1956.8999999999971,
  },
  {
    timestamp_start: '2022-01-01T05:43:14',
    timestamp_stop: '2022-01-01T05:43:19',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 134667,
    tank_volume_start: 3956.899999999997,
    tank_volume_stop: 3903.699999999997,
  },
  {
    timestamp_start: '2022-01-01T05:43:34',
    timestamp_stop: '2022-01-01T05:43:40',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 134669,
    tank_volume_start: 3903.699999999997,
    tank_volume_stop: 3848.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:43:55',
    timestamp_stop: '2022-01-01T05:44:01',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 134671,
    tank_volume_start: 3848.899999999997,
    tank_volume_stop: 3793.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:44:16',
    timestamp_stop: '2022-01-01T05:44:21',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 134673,
    tank_volume_start: 3793.5999999999967,
    tank_volume_stop: 3735.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:44:36',
    timestamp_stop: '2022-01-01T05:44:42',
    quantity: 59.5,
    vehicle: 'DEM111',
    id: 134675,
    tank_volume_start: 3735.999999999997,
    tank_volume_stop: 3679.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:44:57',
    timestamp_stop: '2022-01-01T05:45:03',
    quantity: 61.0,
    vehicle: 'DEM777',
    id: 134677,
    tank_volume_start: 3679.899999999997,
    tank_volume_stop: 3627.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:45:18',
    timestamp_stop: '2022-01-01T05:45:23',
    quantity: 48.6,
    vehicle: 'DEM111',
    id: 134679,
    tank_volume_start: 3627.0999999999967,
    tank_volume_stop: 3572.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:45:38',
    timestamp_stop: '2022-01-01T05:45:44',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 134681,
    tank_volume_start: 3572.2999999999965,
    tank_volume_stop: 3520.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:45:59',
    timestamp_stop: '2022-01-01T05:46:05',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 134683,
    tank_volume_start: 3520.7999999999965,
    tank_volume_stop: 3464.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T05:46:20',
    timestamp_stop: '2022-01-01T05:46:25',
    quantity: 50.8,
    vehicle: 'DEM000',
    id: 134685,
    tank_volume_start: 3464.9999999999964,
    tank_volume_stop: 3410.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:46:40',
    timestamp_stop: '2022-01-01T05:46:46',
    quantity: 60.8,
    vehicle: 'DEM888',
    id: 134687,
    tank_volume_start: 3410.2999999999965,
    tank_volume_stop: 3348.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:47:01',
    timestamp_stop: '2022-01-01T05:47:06',
    quantity: 47.3,
    vehicle: 'DEM777',
    id: 134689,
    tank_volume_start: 3348.0999999999967,
    tank_volume_stop: 3287.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:47:21',
    timestamp_stop: '2022-01-01T05:47:26',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 134691,
    tank_volume_start: 3287.7999999999965,
    tank_volume_stop: 3234.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:47:41',
    timestamp_stop: '2022-01-01T05:47:47',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 134693,
    tank_volume_start: 3234.2999999999965,
    tank_volume_stop: 3182.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:48:02',
    timestamp_stop: '2022-01-01T05:48:08',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 134695,
    tank_volume_start: 3182.6999999999966,
    tank_volume_stop: 3130.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:48:23',
    timestamp_stop: '2022-01-01T05:48:28',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 134697,
    tank_volume_start: 3130.8999999999965,
    tank_volume_stop: 3075.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T05:48:43',
    timestamp_stop: '2022-01-01T05:48:49',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 134699,
    tank_volume_start: 3075.5999999999963,
    tank_volume_stop: 3013.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:49:04',
    timestamp_stop: '2022-01-01T05:49:10',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 134701,
    tank_volume_start: 3013.8999999999965,
    tank_volume_stop: 2964.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:49:25',
    timestamp_stop: '2022-01-01T05:49:30',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 134703,
    tank_volume_start: 2964.6999999999966,
    tank_volume_stop: 2905.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:49:45',
    timestamp_stop: '2022-01-01T05:49:50',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 134705,
    tank_volume_start: 2905.2999999999965,
    tank_volume_stop: 2853.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:50:05',
    timestamp_stop: '2022-01-01T05:50:11',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 134707,
    tank_volume_start: 2853.7999999999965,
    tank_volume_stop: 2796.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:50:26',
    timestamp_stop: '2022-01-01T05:50:31',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 134709,
    tank_volume_start: 2796.5999999999967,
    tank_volume_stop: 2740.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:50:46',
    timestamp_stop: '2022-01-01T05:50:51',
    quantity: 50.2,
    vehicle: 'DEM888',
    id: 134711,
    tank_volume_start: 2740.7999999999965,
    tank_volume_stop: 2683.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:51:06',
    timestamp_stop: '2022-01-01T05:51:11',
    quantity: 46.9,
    vehicle: 'DEM222',
    id: 134713,
    tank_volume_start: 2683.5999999999967,
    tank_volume_stop: 2624.899999999997,
  },
  {
    timestamp_start: '2022-01-01T05:51:26',
    timestamp_stop: '2022-01-01T05:51:31',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 134715,
    tank_volume_start: 2624.899999999997,
    tank_volume_stop: 2576.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:51:46',
    timestamp_stop: '2022-01-01T05:51:53',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 134717,
    tank_volume_start: 2576.499999999997,
    tank_volume_stop: 2520.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:52:08',
    timestamp_stop: '2022-01-01T05:52:14',
    quantity: 60.8,
    vehicle: 'DEM666',
    id: 134719,
    tank_volume_start: 2520.999999999997,
    tank_volume_stop: 2466.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:52:29',
    timestamp_stop: '2022-01-01T05:52:34',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 134721,
    tank_volume_start: 2466.499999999997,
    tank_volume_stop: 2406.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:52:49',
    timestamp_stop: '2022-01-01T05:52:55',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 134723,
    tank_volume_start: 2406.1999999999966,
    tank_volume_stop: 2355.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:53:10',
    timestamp_stop: '2022-01-01T05:53:16',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 134725,
    tank_volume_start: 2355.5999999999967,
    tank_volume_stop: 2304.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:53:31',
    timestamp_stop: '2022-01-01T05:53:36',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 134727,
    tank_volume_start: 2304.999999999997,
    tank_volume_stop: 2254.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:53:51',
    timestamp_stop: '2022-01-01T05:53:56',
    quantity: 50.7,
    vehicle: 'DEM222',
    id: 134729,
    tank_volume_start: 2254.1999999999966,
    tank_volume_stop: 2198.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:54:11',
    timestamp_stop: '2022-01-01T05:54:17',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 134731,
    tank_volume_start: 2198.5999999999967,
    tank_volume_stop: 2146.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:54:32',
    timestamp_stop: '2022-01-01T05:54:37',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 134733,
    tank_volume_start: 2146.1999999999966,
    tank_volume_stop: 2092.499999999997,
  },
  {
    timestamp_start: '2022-01-01T05:54:52',
    timestamp_stop: '2022-01-01T05:54:58',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 134735,
    tank_volume_start: 2092.499999999997,
    tank_volume_stop: 2044.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:55:13',
    timestamp_stop: '2022-01-01T05:55:19',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 134737,
    tank_volume_start: 2044.5999999999967,
    tank_volume_stop: 1983.7999999999968,
  },
  {
    timestamp_start: '2022-01-01T05:55:34',
    timestamp_stop: '2022-01-01T05:55:39',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 134739,
    tank_volume_start: 3983.7999999999965,
    tank_volume_stop: 3928.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:55:54',
    timestamp_stop: '2022-01-01T05:56:00',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 134741,
    tank_volume_start: 3928.3999999999965,
    tank_volume_stop: 3870.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T05:56:15',
    timestamp_stop: '2022-01-01T05:56:20',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 134743,
    tank_volume_start: 3870.5999999999963,
    tank_volume_stop: 3819.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T05:56:35',
    timestamp_stop: '2022-01-01T05:56:41',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 134745,
    tank_volume_start: 3819.5999999999963,
    tank_volume_stop: 3774.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T05:56:56',
    timestamp_stop: '2022-01-01T05:57:01',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 134747,
    tank_volume_start: 3774.5999999999963,
    tank_volume_stop: 3726.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:57:16',
    timestamp_stop: '2022-01-01T05:57:21',
    quantity: 47.6,
    vehicle: 'DEM888',
    id: 134749,
    tank_volume_start: 3726.3999999999965,
    tank_volume_stop: 3670.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T05:57:36',
    timestamp_stop: '2022-01-01T05:57:40',
    quantity: 46.5,
    vehicle: 'DEM555',
    id: 134751,
    tank_volume_start: 3670.3999999999965,
    tank_volume_stop: 3620.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T05:57:55',
    timestamp_stop: '2022-01-01T05:58:01',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 134753,
    tank_volume_start: 3620.1999999999966,
    tank_volume_stop: 3559.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:58:16',
    timestamp_stop: '2022-01-01T05:58:21',
    quantity: 52.3,
    vehicle: 'DEM444',
    id: 134755,
    tank_volume_start: 3559.999999999997,
    tank_volume_stop: 3498.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:58:36',
    timestamp_stop: '2022-01-01T05:58:42',
    quantity: 55.4,
    vehicle: 'DEM444',
    id: 134757,
    tank_volume_start: 3498.999999999997,
    tank_volume_stop: 3440.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T05:58:57',
    timestamp_stop: '2022-01-01T05:59:03',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 134759,
    tank_volume_start: 3440.5999999999967,
    tank_volume_stop: 3389.999999999997,
  },
  {
    timestamp_start: '2022-01-01T05:59:18',
    timestamp_stop: '2022-01-01T05:59:23',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 134761,
    tank_volume_start: 3389.999999999997,
    tank_volume_stop: 3331.799999999997,
  },
  {
    timestamp_start: '2022-01-01T05:59:38',
    timestamp_stop: '2022-01-01T05:59:43',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 134763,
    tank_volume_start: 3331.799999999997,
    tank_volume_stop: 3284.599999999997,
  },
  {
    timestamp_start: '2022-01-01T05:59:58',
    timestamp_stop: '2022-01-01T06:00:04',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 134765,
    tank_volume_start: 3284.599999999997,
    tank_volume_stop: 3224.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:00:19',
    timestamp_stop: '2022-01-01T06:00:25',
    quantity: 58.3,
    vehicle: 'DEM111',
    id: 134767,
    tank_volume_start: 3224.9999999999973,
    tank_volume_stop: 3170.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:00:40',
    timestamp_stop: '2022-01-01T06:00:45',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 134769,
    tank_volume_start: 3170.199999999997,
    tank_volume_stop: 3111.799999999997,
  },
  {
    timestamp_start: '2022-01-01T06:01:00',
    timestamp_stop: '2022-01-01T06:01:05',
    quantity: 49.8,
    vehicle: 'DEM777',
    id: 134771,
    tank_volume_start: 3111.799999999997,
    tank_volume_stop: 3051.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:01:20',
    timestamp_stop: '2022-01-01T06:01:26',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 134773,
    tank_volume_start: 3051.199999999997,
    tank_volume_stop: 2994.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:01:41',
    timestamp_stop: '2022-01-01T06:01:46',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 134775,
    tank_volume_start: 2994.099999999997,
    tank_volume_stop: 2945.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:02:01',
    timestamp_stop: '2022-01-01T06:02:06',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 134777,
    tank_volume_start: 2945.8999999999974,
    tank_volume_stop: 2884.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:02:21',
    timestamp_stop: '2022-01-01T06:02:26',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 134779,
    tank_volume_start: 2884.8999999999974,
    tank_volume_stop: 2832.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:02:41',
    timestamp_stop: '2022-01-01T06:02:46',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 134781,
    tank_volume_start: 2832.599999999997,
    tank_volume_stop: 2778.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:03:01',
    timestamp_stop: '2022-01-01T06:03:07',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 134783,
    tank_volume_start: 2778.599999999997,
    tank_volume_stop: 2725.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:03:22',
    timestamp_stop: '2022-01-01T06:03:27',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 134785,
    tank_volume_start: 2725.3999999999974,
    tank_volume_stop: 2667.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:03:42',
    timestamp_stop: '2022-01-01T06:03:47',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 134787,
    tank_volume_start: 2667.9999999999973,
    tank_volume_stop: 2612.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:04:02',
    timestamp_stop: '2022-01-01T06:04:08',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 134789,
    tank_volume_start: 2612.099999999997,
    tank_volume_stop: 2559.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:04:23',
    timestamp_stop: '2022-01-01T06:04:28',
    quantity: 52.0,
    vehicle: 'DEM555',
    id: 134791,
    tank_volume_start: 2559.099999999997,
    tank_volume_stop: 2498.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:04:43',
    timestamp_stop: '2022-01-01T06:04:49',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 134793,
    tank_volume_start: 2498.3999999999974,
    tank_volume_stop: 2439.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:05:04',
    timestamp_stop: '2022-01-01T06:05:10',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 134795,
    tank_volume_start: 2439.099999999997,
    tank_volume_stop: 2376.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:05:25',
    timestamp_stop: '2022-01-01T06:05:31',
    quantity: 61.6,
    vehicle: 'DEM000',
    id: 134797,
    tank_volume_start: 2376.9999999999973,
    tank_volume_stop: 2315.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:05:46',
    timestamp_stop: '2022-01-01T06:05:51',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 134799,
    tank_volume_start: 2315.4999999999973,
    tank_volume_stop: 2264.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:06:06',
    timestamp_stop: '2022-01-01T06:06:12',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 134801,
    tank_volume_start: 2264.199999999997,
    tank_volume_stop: 2212.399999999997,
  },
  {
    timestamp_start: '2022-01-01T06:06:27',
    timestamp_stop: '2022-01-01T06:06:32',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 134803,
    tank_volume_start: 2212.399999999997,
    tank_volume_stop: 2163.699999999997,
  },
  {
    timestamp_start: '2022-01-01T06:06:47',
    timestamp_stop: '2022-01-01T06:06:52',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 134805,
    tank_volume_start: 2163.699999999997,
    tank_volume_stop: 2105.899999999997,
  },
  {
    timestamp_start: '2022-01-01T06:07:07',
    timestamp_stop: '2022-01-01T06:07:13',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 134807,
    tank_volume_start: 2105.899999999997,
    tank_volume_stop: 2054.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:07:28',
    timestamp_stop: '2022-01-01T06:07:33',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 134809,
    tank_volume_start: 2054.999999999997,
    tank_volume_stop: 2000.2999999999968,
  },
  {
    timestamp_start: '2022-01-01T06:07:48',
    timestamp_stop: '2022-01-01T06:07:53',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 134811,
    tank_volume_start: 2000.2999999999968,
    tank_volume_stop: 1941.3999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:08:08',
    timestamp_stop: '2022-01-01T06:08:15',
    quantity: 63.7,
    vehicle: 'DEM888',
    id: 134813,
    tank_volume_start: 3941.399999999997,
    tank_volume_stop: 3883.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:08:30',
    timestamp_stop: '2022-01-01T06:08:35',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 134815,
    tank_volume_start: 3883.999999999997,
    tank_volume_stop: 3827.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:08:50',
    timestamp_stop: '2022-01-01T06:08:55',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 134817,
    tank_volume_start: 3827.0999999999967,
    tank_volume_stop: 3767.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:09:10',
    timestamp_stop: '2022-01-01T06:09:16',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 134819,
    tank_volume_start: 3767.499999999997,
    tank_volume_stop: 3707.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:09:31',
    timestamp_stop: '2022-01-01T06:09:37',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 134821,
    tank_volume_start: 3707.6999999999966,
    tank_volume_stop: 3655.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:09:52',
    timestamp_stop: '2022-01-01T06:09:57',
    quantity: 48.0,
    vehicle: 'DEM444',
    id: 134823,
    tank_volume_start: 3655.2999999999965,
    tank_volume_stop: 3602.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:10:12',
    timestamp_stop: '2022-01-01T06:10:17',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 134825,
    tank_volume_start: 3602.8999999999965,
    tank_volume_stop: 3548.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:10:32',
    timestamp_stop: '2022-01-01T06:10:39',
    quantity: 63.0,
    vehicle: 'DEM666',
    id: 134827,
    tank_volume_start: 3548.1999999999966,
    tank_volume_stop: 3488.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:10:54',
    timestamp_stop: '2022-01-01T06:10:59',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 134829,
    tank_volume_start: 3488.1999999999966,
    tank_volume_stop: 3433.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:11:14',
    timestamp_stop: '2022-01-01T06:11:20',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 134831,
    tank_volume_start: 3433.6999999999966,
    tank_volume_stop: 3383.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:11:35',
    timestamp_stop: '2022-01-01T06:11:40',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 134833,
    tank_volume_start: 3383.3999999999965,
    tank_volume_stop: 3336.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T06:11:55',
    timestamp_stop: '2022-01-01T06:12:00',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 134835,
    tank_volume_start: 3336.5999999999963,
    tank_volume_stop: 3284.199999999996,
  },
  {
    timestamp_start: '2022-01-01T06:12:15',
    timestamp_stop: '2022-01-01T06:12:21',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 134837,
    tank_volume_start: 3284.199999999996,
    tank_volume_stop: 3236.699999999996,
  },
  {
    timestamp_start: '2022-01-01T06:12:36',
    timestamp_stop: '2022-01-01T06:12:41',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 134839,
    tank_volume_start: 3236.699999999996,
    tank_volume_stop: 3175.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T06:12:56',
    timestamp_stop: '2022-01-01T06:13:02',
    quantity: 56.6,
    vehicle: 'DEM000',
    id: 134841,
    tank_volume_start: 3175.9999999999964,
    tank_volume_stop: 3123.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T06:13:17',
    timestamp_stop: '2022-01-01T06:13:22',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 134843,
    tank_volume_start: 3123.4999999999964,
    tank_volume_stop: 3061.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:13:37',
    timestamp_stop: '2022-01-01T06:13:44',
    quantity: 62.7,
    vehicle: 'DEM111',
    id: 134845,
    tank_volume_start: 3061.2999999999965,
    tank_volume_stop: 3004.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:13:59',
    timestamp_stop: '2022-01-01T06:14:04',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 134847,
    tank_volume_start: 3004.3999999999965,
    tank_volume_stop: 2947.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T06:14:19',
    timestamp_stop: '2022-01-01T06:14:25',
    quantity: 60.5,
    vehicle: 'DEM111',
    id: 134849,
    tank_volume_start: 2947.0999999999963,
    tank_volume_stop: 2895.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T06:14:40',
    timestamp_stop: '2022-01-01T06:14:46',
    quantity: 58.8,
    vehicle: 'DEM777',
    id: 134851,
    tank_volume_start: 2895.9999999999964,
    tank_volume_stop: 2843.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:15:01',
    timestamp_stop: '2022-01-01T06:15:07',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 134853,
    tank_volume_start: 2843.2999999999965,
    tank_volume_stop: 2797.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T06:15:22',
    timestamp_stop: '2022-01-01T06:15:27',
    quantity: 54.2,
    vehicle: 'DEM111',
    id: 134855,
    tank_volume_start: 2797.4999999999964,
    tank_volume_stop: 2743.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:15:42',
    timestamp_stop: '2022-01-01T06:15:47',
    quantity: 47.4,
    vehicle: 'DEM222',
    id: 134857,
    tank_volume_start: 2743.3999999999965,
    tank_volume_stop: 2687.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:16:02',
    timestamp_stop: '2022-01-01T06:16:07',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 134859,
    tank_volume_start: 2687.7999999999965,
    tank_volume_stop: 2627.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:16:22',
    timestamp_stop: '2022-01-01T06:16:29',
    quantity: 62.0,
    vehicle: 'DEM222',
    id: 134861,
    tank_volume_start: 2627.8999999999965,
    tank_volume_stop: 2571.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:16:44',
    timestamp_stop: '2022-01-01T06:16:49',
    quantity: 58.0,
    vehicle: 'DEM888',
    id: 134863,
    tank_volume_start: 2571.2999999999965,
    tank_volume_stop: 2523.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:17:04',
    timestamp_stop: '2022-01-01T06:17:10',
    quantity: 55.4,
    vehicle: 'DEM888',
    id: 134865,
    tank_volume_start: 2523.8999999999965,
    tank_volume_stop: 2471.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:17:25',
    timestamp_stop: '2022-01-01T06:17:30',
    quantity: 49.0,
    vehicle: 'DEM777',
    id: 134867,
    tank_volume_start: 2471.6999999999966,
    tank_volume_stop: 2421.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:17:45',
    timestamp_stop: '2022-01-01T06:17:51',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 134869,
    tank_volume_start: 2421.1999999999966,
    tank_volume_stop: 2364.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:18:06',
    timestamp_stop: '2022-01-01T06:18:11',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 134871,
    tank_volume_start: 2364.7999999999965,
    tank_volume_stop: 2307.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T06:18:26',
    timestamp_stop: '2022-01-01T06:18:31',
    quantity: 54.4,
    vehicle: 'DEM333',
    id: 134873,
    tank_volume_start: 2307.4999999999964,
    tank_volume_stop: 2244.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:18:46',
    timestamp_stop: '2022-01-01T06:18:52',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 134875,
    tank_volume_start: 2244.8999999999965,
    tank_volume_stop: 2196.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:19:07',
    timestamp_stop: '2022-01-01T06:19:12',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 134877,
    tank_volume_start: 2196.8999999999965,
    tank_volume_stop: 2146.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:19:27',
    timestamp_stop: '2022-01-01T06:19:32',
    quantity: 48.6,
    vehicle: 'DEM111',
    id: 134879,
    tank_volume_start: 2146.1999999999966,
    tank_volume_stop: 2085.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:19:47',
    timestamp_stop: '2022-01-01T06:19:53',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 134881,
    tank_volume_start: 2085.1999999999966,
    tank_volume_stop: 2026.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:20:08',
    timestamp_stop: '2022-01-01T06:20:14',
    quantity: 62.6,
    vehicle: 'DEM222',
    id: 134883,
    tank_volume_start: 2026.2999999999965,
    tank_volume_stop: 1977.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:20:29',
    timestamp_stop: '2022-01-01T06:20:35',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 134885,
    tank_volume_start: 3977.1999999999966,
    tank_volume_stop: 3923.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:20:50',
    timestamp_stop: '2022-01-01T06:20:55',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 134887,
    tank_volume_start: 3923.999999999997,
    tank_volume_stop: 3872.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:21:10',
    timestamp_stop: '2022-01-01T06:21:15',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 134889,
    tank_volume_start: 3872.1999999999966,
    tank_volume_stop: 3816.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:21:30',
    timestamp_stop: '2022-01-01T06:21:36',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 134891,
    tank_volume_start: 3816.0999999999967,
    tank_volume_stop: 3762.399999999997,
  },
  {
    timestamp_start: '2022-01-01T06:21:51',
    timestamp_stop: '2022-01-01T06:21:57',
    quantity: 57.8,
    vehicle: 'DEM222',
    id: 134893,
    tank_volume_start: 3762.399999999997,
    tank_volume_stop: 3710.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:22:12',
    timestamp_stop: '2022-01-01T06:22:18',
    quantity: 60.2,
    vehicle: 'DEM111',
    id: 134895,
    tank_volume_start: 3710.0999999999967,
    tank_volume_stop: 3664.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:22:33',
    timestamp_stop: '2022-01-01T06:22:38',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 134897,
    tank_volume_start: 3664.499999999997,
    tank_volume_stop: 3613.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:22:53',
    timestamp_stop: '2022-01-01T06:22:59',
    quantity: 61.5,
    vehicle: 'DEM555',
    id: 134899,
    tank_volume_start: 3613.499999999997,
    tank_volume_stop: 3554.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:23:14',
    timestamp_stop: '2022-01-01T06:23:20',
    quantity: 58.3,
    vehicle: 'DEM888',
    id: 134901,
    tank_volume_start: 3554.999999999997,
    tank_volume_stop: 3500.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:23:35',
    timestamp_stop: '2022-01-01T06:23:41',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 134904,
    tank_volume_start: 3500.6999999999966,
    tank_volume_stop: 3445.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:23:56',
    timestamp_stop: '2022-01-01T06:24:02',
    quantity: 59.7,
    vehicle: 'DEM555',
    id: 134906,
    tank_volume_start: 3445.499999999997,
    tank_volume_stop: 3393.299999999997,
  },
  {
    timestamp_start: '2022-01-01T06:24:17',
    timestamp_stop: '2022-01-01T06:24:22',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 134908,
    tank_volume_start: 3393.299999999997,
    tank_volume_stop: 3337.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:24:37',
    timestamp_stop: '2022-01-01T06:24:43',
    quantity: 53.1,
    vehicle: 'DEM555',
    id: 134910,
    tank_volume_start: 3337.599999999997,
    tank_volume_stop: 3290.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:24:58',
    timestamp_stop: '2022-01-01T06:25:03',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 134912,
    tank_volume_start: 3290.8999999999974,
    tank_volume_stop: 3244.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:25:18',
    timestamp_stop: '2022-01-01T06:25:23',
    quantity: 51.7,
    vehicle: 'DEM000',
    id: 134914,
    tank_volume_start: 3244.599999999997,
    tank_volume_stop: 3184.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:25:38',
    timestamp_stop: '2022-01-01T06:25:45',
    quantity: 61.3,
    vehicle: 'DEM222',
    id: 134916,
    tank_volume_start: 3184.199999999997,
    tank_volume_stop: 3132.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:26:00',
    timestamp_stop: '2022-01-01T06:26:04',
    quantity: 45.8,
    vehicle: 'DEM777',
    id: 134918,
    tank_volume_start: 3132.199999999997,
    tank_volume_stop: 3076.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:26:19',
    timestamp_stop: '2022-01-01T06:26:25',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 134920,
    tank_volume_start: 3076.599999999997,
    tank_volume_stop: 3022.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:26:40',
    timestamp_stop: '2022-01-01T06:26:45',
    quantity: 53.8,
    vehicle: 'DEM000',
    id: 134922,
    tank_volume_start: 3022.4999999999973,
    tank_volume_stop: 2966.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:27:00',
    timestamp_stop: '2022-01-01T06:27:05',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 134924,
    tank_volume_start: 2966.099999999997,
    tank_volume_stop: 2912.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:27:20',
    timestamp_stop: '2022-01-01T06:27:25',
    quantity: 48.7,
    vehicle: 'DEM000',
    id: 134926,
    tank_volume_start: 2912.9999999999973,
    tank_volume_stop: 2858.699999999997,
  },
  {
    timestamp_start: '2022-01-01T06:27:40',
    timestamp_stop: '2022-01-01T06:27:46',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 134928,
    tank_volume_start: 2858.699999999997,
    tank_volume_stop: 2806.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:28:01',
    timestamp_stop: '2022-01-01T06:28:06',
    quantity: 54.1,
    vehicle: 'DEM111',
    id: 134930,
    tank_volume_start: 2806.4999999999973,
    tank_volume_stop: 2753.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T06:28:21',
    timestamp_stop: '2022-01-01T06:28:27',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 134932,
    tank_volume_start: 2753.8999999999974,
    tank_volume_stop: 2694.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:28:42',
    timestamp_stop: '2022-01-01T06:28:48',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 134934,
    tank_volume_start: 2694.099999999997,
    tank_volume_stop: 2643.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:29:03',
    timestamp_stop: '2022-01-01T06:29:08',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 134936,
    tank_volume_start: 2643.4999999999973,
    tank_volume_stop: 2584.699999999997,
  },
  {
    timestamp_start: '2022-01-01T06:29:23',
    timestamp_stop: '2022-01-01T06:29:29',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 134938,
    tank_volume_start: 2584.699999999997,
    tank_volume_stop: 2524.799999999997,
  },
  {
    timestamp_start: '2022-01-01T06:29:44',
    timestamp_stop: '2022-01-01T06:29:49',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 134940,
    tank_volume_start: 2524.799999999997,
    tank_volume_stop: 2465.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:30:04',
    timestamp_stop: '2022-01-01T06:30:09',
    quantity: 50.6,
    vehicle: 'DEM222',
    id: 134942,
    tank_volume_start: 2465.599999999997,
    tank_volume_stop: 2414.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:30:24',
    timestamp_stop: '2022-01-01T06:30:29',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 134944,
    tank_volume_start: 2414.199999999997,
    tank_volume_stop: 2367.399999999997,
  },
  {
    timestamp_start: '2022-01-01T06:30:44',
    timestamp_stop: '2022-01-01T06:30:49',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 134946,
    tank_volume_start: 2367.399999999997,
    tank_volume_stop: 2316.199999999997,
  },
  {
    timestamp_start: '2022-01-01T06:31:04',
    timestamp_stop: '2022-01-01T06:31:10',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 134948,
    tank_volume_start: 2316.199999999997,
    tank_volume_stop: 2264.299999999997,
  },
  {
    timestamp_start: '2022-01-01T06:31:25',
    timestamp_stop: '2022-01-01T06:31:30',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 134950,
    tank_volume_start: 2264.299999999997,
    tank_volume_stop: 2216.599999999997,
  },
  {
    timestamp_start: '2022-01-01T06:31:45',
    timestamp_stop: '2022-01-01T06:31:50',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 134952,
    tank_volume_start: 2216.599999999997,
    tank_volume_stop: 2162.299999999997,
  },
  {
    timestamp_start: '2022-01-01T06:32:05',
    timestamp_stop: '2022-01-01T06:32:11',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 134954,
    tank_volume_start: 2162.299999999997,
    tank_volume_stop: 2102.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:32:26',
    timestamp_stop: '2022-01-01T06:32:32',
    quantity: 60.1,
    vehicle: 'DEM111',
    id: 134956,
    tank_volume_start: 2102.999999999997,
    tank_volume_stop: 2043.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:32:47',
    timestamp_stop: '2022-01-01T06:32:52',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 134958,
    tank_volume_start: 2043.0999999999967,
    tank_volume_stop: 1990.7999999999968,
  },
  {
    timestamp_start: '2022-01-01T06:33:07',
    timestamp_stop: '2022-01-01T06:33:12',
    quantity: 51.5,
    vehicle: 'DEM222',
    id: 134960,
    tank_volume_start: 3990.7999999999965,
    tank_volume_stop: 3930.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:33:27',
    timestamp_stop: '2022-01-01T06:33:32',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 134961,
    tank_volume_start: 3930.1999999999966,
    tank_volume_stop: 3868.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:33:47',
    timestamp_stop: '2022-01-01T06:33:53',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 134963,
    tank_volume_start: 3868.1999999999966,
    tank_volume_stop: 3815.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:34:08',
    timestamp_stop: '2022-01-01T06:34:14',
    quantity: 61.5,
    vehicle: 'DEM777',
    id: 134965,
    tank_volume_start: 3815.499999999997,
    tank_volume_stop: 3760.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:34:29',
    timestamp_stop: '2022-01-01T06:34:35',
    quantity: 60.1,
    vehicle: 'DEM444',
    id: 134968,
    tank_volume_start: 3760.1999999999966,
    tank_volume_stop: 3707.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T06:34:50',
    timestamp_stop: '2022-01-01T06:34:56',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 134970,
    tank_volume_start: 3707.8999999999965,
    tank_volume_stop: 3659.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T06:35:11',
    timestamp_stop: '2022-01-01T06:35:17',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 134972,
    tank_volume_start: 3659.6999999999966,
    tank_volume_stop: 3608.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:35:32',
    timestamp_stop: '2022-01-01T06:35:38',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 134974,
    tank_volume_start: 3608.0999999999967,
    tank_volume_stop: 3555.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:35:53',
    timestamp_stop: '2022-01-01T06:35:58',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 134976,
    tank_volume_start: 3555.5999999999967,
    tank_volume_stop: 3497.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:36:13',
    timestamp_stop: '2022-01-01T06:36:19',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 134978,
    tank_volume_start: 3497.0999999999967,
    tank_volume_stop: 3446.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:36:34',
    timestamp_stop: '2022-01-01T06:36:40',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 134980,
    tank_volume_start: 3446.0999999999967,
    tank_volume_stop: 3398.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:36:55',
    timestamp_stop: '2022-01-01T06:36:59',
    quantity: 48.0,
    vehicle: 'DEM888',
    id: 134982,
    tank_volume_start: 3398.499999999997,
    tank_volume_stop: 3340.399999999997,
  },
  {
    timestamp_start: '2022-01-01T06:37:14',
    timestamp_stop: '2022-01-01T06:37:20',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 134984,
    tank_volume_start: 3340.399999999997,
    tank_volume_stop: 3283.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:37:35',
    timestamp_stop: '2022-01-01T06:37:40',
    quantity: 46.5,
    vehicle: 'DEM111',
    id: 134986,
    tank_volume_start: 3283.0999999999967,
    tank_volume_stop: 3220.999999999997,
  },
  {
    timestamp_start: '2022-01-01T06:37:55',
    timestamp_stop: '2022-01-01T06:38:01',
    quantity: 60.3,
    vehicle: 'DEM444',
    id: 134988,
    tank_volume_start: 3220.999999999997,
    tank_volume_stop: 3159.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:38:16',
    timestamp_stop: '2022-01-01T06:38:21',
    quantity: 49.1,
    vehicle: 'DEM000',
    id: 134990,
    tank_volume_start: 3159.5999999999967,
    tank_volume_stop: 3110.499999999997,
  },
  {
    timestamp_start: '2022-01-01T06:38:36',
    timestamp_stop: '2022-01-01T06:38:41',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 134992,
    tank_volume_start: 3110.499999999997,
    tank_volume_stop: 3059.299999999997,
  },
  {
    timestamp_start: '2022-01-01T06:38:56',
    timestamp_stop: '2022-01-01T06:39:02',
    quantity: 57.6,
    vehicle: 'DEM888',
    id: 134994,
    tank_volume_start: 3059.299999999997,
    tank_volume_stop: 3008.899999999997,
  },
  {
    timestamp_start: '2022-01-01T06:39:17',
    timestamp_stop: '2022-01-01T06:39:22',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 134996,
    tank_volume_start: 3008.899999999997,
    tank_volume_stop: 2952.399999999997,
  },
  {
    timestamp_start: '2022-01-01T06:39:37',
    timestamp_stop: '2022-01-01T06:39:44',
    quantity: 63.1,
    vehicle: 'DEM777',
    id: 134998,
    tank_volume_start: 2952.399999999997,
    tank_volume_stop: 2898.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T06:39:59',
    timestamp_stop: '2022-01-01T06:40:05',
    quantity: 58.7,
    vehicle: 'DEM888',
    id: 135000,
    tank_volume_start: 2898.5999999999967,
    tank_volume_stop: 2835.899999999997,
  },
  {
    timestamp_start: '2022-01-01T06:40:20',
    timestamp_stop: '2022-01-01T06:40:26',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 135002,
    tank_volume_start: 2835.899999999997,
    tank_volume_stop: 2783.299999999997,
  },
  {
    timestamp_start: '2022-01-01T06:40:41',
    timestamp_stop: '2022-01-01T06:40:46',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 135004,
    tank_volume_start: 2783.299999999997,
    tank_volume_stop: 2729.699999999997,
  },
  {
    timestamp_start: '2022-01-01T06:41:01',
    timestamp_stop: '2022-01-01T06:41:07',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 135006,
    tank_volume_start: 2729.699999999997,
    tank_volume_stop: 2665.099999999997,
  },
  {
    timestamp_start: '2022-01-01T06:41:22',
    timestamp_stop: '2022-01-01T06:41:27',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 135008,
    tank_volume_start: 2665.099999999997,
    tank_volume_stop: 2609.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T06:41:42',
    timestamp_stop: '2022-01-01T06:41:47',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 135010,
    tank_volume_start: 2609.4999999999973,
    tank_volume_stop: 2552.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T06:42:02',
    timestamp_stop: '2022-01-01T06:42:08',
    quantity: 61.5,
    vehicle: 'DEM111',
    id: 135012,
    tank_volume_start: 2552.2999999999975,
    tank_volume_stop: 2500.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T06:42:23',
    timestamp_stop: '2022-01-01T06:42:29',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 135014,
    tank_volume_start: 2500.0999999999976,
    tank_volume_stop: 2440.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T06:42:44',
    timestamp_stop: '2022-01-01T06:42:50',
    quantity: 61.6,
    vehicle: 'DEM000',
    id: 135016,
    tank_volume_start: 2440.4999999999977,
    tank_volume_stop: 2385.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T06:43:05',
    timestamp_stop: '2022-01-01T06:43:10',
    quantity: 51.5,
    vehicle: 'DEM777',
    id: 135018,
    tank_volume_start: 2385.9999999999977,
    tank_volume_stop: 2332.799999999998,
  },
  {
    timestamp_start: '2022-01-01T06:43:25',
    timestamp_stop: '2022-01-01T06:43:30',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 135020,
    tank_volume_start: 2332.799999999998,
    tank_volume_stop: 2269.599999999998,
  },
  {
    timestamp_start: '2022-01-01T06:43:45',
    timestamp_stop: '2022-01-01T06:43:50',
    quantity: 49.5,
    vehicle: 'DEM000',
    id: 135022,
    tank_volume_start: 2269.599999999998,
    tank_volume_stop: 2205.599999999998,
  },
  {
    timestamp_start: '2022-01-01T06:44:05',
    timestamp_stop: '2022-01-01T06:44:12',
    quantity: 62.6,
    vehicle: 'DEM888',
    id: 135024,
    tank_volume_start: 2205.599999999998,
    tank_volume_stop: 2148.499999999998,
  },
  {
    timestamp_start: '2022-01-01T06:44:27',
    timestamp_stop: '2022-01-01T06:44:32',
    quantity: 54.6,
    vehicle: 'DEM777',
    id: 135026,
    tank_volume_start: 2148.499999999998,
    tank_volume_stop: 2100.599999999998,
  },
  {
    timestamp_start: '2022-01-01T06:44:47',
    timestamp_stop: '2022-01-01T06:44:52',
    quantity: 47.2,
    vehicle: 'DEM111',
    id: 135028,
    tank_volume_start: 2100.599999999998,
    tank_volume_stop: 2044.599999999998,
  },
  {
    timestamp_start: '2022-01-01T06:45:07',
    timestamp_stop: '2022-01-01T06:45:12',
    quantity: 51.7,
    vehicle: 'DEM888',
    id: 135030,
    tank_volume_start: 2044.599999999998,
    tank_volume_stop: 1988.7999999999981,
  },
  {
    timestamp_start: '2022-01-01T06:45:27',
    timestamp_stop: '2022-01-01T06:45:33',
    quantity: 57.1,
    vehicle: 'DEM111',
    id: 135032,
    tank_volume_start: 3988.7999999999984,
    tank_volume_stop: 3932.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T06:45:48',
    timestamp_stop: '2022-01-01T06:45:54',
    quantity: 60.3,
    vehicle: 'DEM444',
    id: 135034,
    tank_volume_start: 3932.6999999999985,
    tank_volume_stop: 3879.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T06:46:09',
    timestamp_stop: '2022-01-01T06:46:13',
    quantity: 45.4,
    vehicle: 'DEM777',
    id: 135036,
    tank_volume_start: 3879.9999999999986,
    tank_volume_stop: 3829.799999999999,
  },
  {
    timestamp_start: '2022-01-01T06:46:28',
    timestamp_stop: '2022-01-01T06:46:34',
    quantity: 61.4,
    vehicle: 'DEM222',
    id: 135038,
    tank_volume_start: 3829.799999999999,
    tank_volume_stop: 3771.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T06:46:49',
    timestamp_stop: '2022-01-01T06:46:55',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 135040,
    tank_volume_start: 3771.9999999999986,
    tank_volume_stop: 3714.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T06:47:10',
    timestamp_stop: '2022-01-01T06:47:16',
    quantity: 60.9,
    vehicle: 'DEM333',
    id: 135042,
    tank_volume_start: 3714.5999999999985,
    tank_volume_stop: 3660.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T06:47:31',
    timestamp_stop: '2022-01-01T06:47:36',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 135044,
    tank_volume_start: 3660.3999999999987,
    tank_volume_stop: 3610.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T06:47:51',
    timestamp_stop: '2022-01-01T06:47:57',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 135046,
    tank_volume_start: 3610.0999999999985,
    tank_volume_stop: 3547.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T06:48:12',
    timestamp_stop: '2022-01-01T06:48:18',
    quantity: 62.0,
    vehicle: 'DEM888',
    id: 135048,
    tank_volume_start: 3547.8999999999987,
    tank_volume_stop: 3499.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T06:48:33',
    timestamp_stop: '2022-01-01T06:48:40',
    quantity: 60.5,
    vehicle: 'DEM000',
    id: 135050,
    tank_volume_start: 3499.3999999999987,
    tank_volume_stop: 3443.199999999999,
  },
  {
    timestamp_start: '2022-01-01T06:48:55',
    timestamp_stop: '2022-01-01T06:49:00',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 135052,
    tank_volume_start: 3443.199999999999,
    tank_volume_stop: 3388.499999999999,
  },
  {
    timestamp_start: '2022-01-01T06:49:15',
    timestamp_stop: '2022-01-01T06:49:20',
    quantity: 51.4,
    vehicle: 'DEM666',
    id: 135054,
    tank_volume_start: 3388.499999999999,
    tank_volume_stop: 3330.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T06:49:35',
    timestamp_stop: '2022-01-01T06:49:40',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 135056,
    tank_volume_start: 3330.7999999999993,
    tank_volume_stop: 3283.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T06:49:55',
    timestamp_stop: '2022-01-01T06:50:00',
    quantity: 49.4,
    vehicle: 'DEM888',
    id: 135058,
    tank_volume_start: 3283.1999999999994,
    tank_volume_stop: 3231.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:50:15',
    timestamp_stop: '2022-01-01T06:50:21',
    quantity: 57.4,
    vehicle: 'DEM333',
    id: 135060,
    tank_volume_start: 3231.0999999999995,
    tank_volume_stop: 3170.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:50:36',
    timestamp_stop: '2022-01-01T06:50:41',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 135062,
    tank_volume_start: 3170.5999999999995,
    tank_volume_stop: 3124.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:50:56',
    timestamp_stop: '2022-01-01T06:51:02',
    quantity: 59.2,
    vehicle: 'DEM444',
    id: 135064,
    tank_volume_start: 3124.0999999999995,
    tank_volume_stop: 3063.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:51:17',
    timestamp_stop: '2022-01-01T06:51:23',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 135066,
    tank_volume_start: 3063.9999999999995,
    tank_volume_stop: 3008.8999999999996,
  },
  {
    timestamp_start: '2022-01-01T06:51:38',
    timestamp_stop: '2022-01-01T06:51:43',
    quantity: 46.2,
    vehicle: 'DEM222',
    id: 135068,
    tank_volume_start: 3008.8999999999996,
    tank_volume_stop: 2947.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:51:58',
    timestamp_stop: '2022-01-01T06:52:03',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 135070,
    tank_volume_start: 2947.9999999999995,
    tank_volume_stop: 2889.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:52:18',
    timestamp_stop: '2022-01-01T06:52:24',
    quantity: 60.6,
    vehicle: 'DEM222',
    id: 135072,
    tank_volume_start: 2889.5999999999995,
    tank_volume_stop: 2839.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T06:52:39',
    timestamp_stop: '2022-01-01T06:52:44',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 135074,
    tank_volume_start: 2839.3999999999996,
    tank_volume_stop: 2783.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T06:52:59',
    timestamp_stop: '2022-01-01T06:53:05',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 135076,
    tank_volume_start: 2783.0999999999995,
    tank_volume_stop: 2729.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T06:53:20',
    timestamp_stop: '2022-01-01T06:53:25',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 135078,
    tank_volume_start: 2729.1999999999994,
    tank_volume_stop: 2677.399999999999,
  },
  {
    timestamp_start: '2022-01-01T06:53:40',
    timestamp_stop: '2022-01-01T06:53:45',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 135080,
    tank_volume_start: 2677.399999999999,
    tank_volume_stop: 2613.499999999999,
  },
  {
    timestamp_start: '2022-01-01T06:54:00',
    timestamp_stop: '2022-01-01T06:54:06',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 135082,
    tank_volume_start: 2613.499999999999,
    tank_volume_stop: 2562.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T06:54:21',
    timestamp_stop: '2022-01-01T06:54:26',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 135084,
    tank_volume_start: 2562.2999999999993,
    tank_volume_stop: 2505.399999999999,
  },
  {
    timestamp_start: '2022-01-01T06:54:41',
    timestamp_stop: '2022-01-01T06:54:47',
    quantity: 59.9,
    vehicle: 'DEM444',
    id: 135086,
    tank_volume_start: 2505.399999999999,
    tank_volume_stop: 2459.599999999999,
  },
  {
    timestamp_start: '2022-01-01T06:55:02',
    timestamp_stop: '2022-01-01T06:55:07',
    quantity: 50.7,
    vehicle: 'DEM111',
    id: 135088,
    tank_volume_start: 2459.599999999999,
    tank_volume_stop: 2406.599999999999,
  },
  {
    timestamp_start: '2022-01-01T06:55:22',
    timestamp_stop: '2022-01-01T06:55:27',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 135090,
    tank_volume_start: 2406.599999999999,
    tank_volume_stop: 2354.699999999999,
  },
  {
    timestamp_start: '2022-01-01T06:55:42',
    timestamp_stop: '2022-01-01T06:55:48',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 135092,
    tank_volume_start: 2354.699999999999,
    tank_volume_stop: 2304.199999999999,
  },
  {
    timestamp_start: '2022-01-01T06:56:03',
    timestamp_stop: '2022-01-01T06:56:09',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 135094,
    tank_volume_start: 2304.199999999999,
    tank_volume_stop: 2244.699999999999,
  },
  {
    timestamp_start: '2022-01-01T06:56:24',
    timestamp_stop: '2022-01-01T06:56:30',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 135096,
    tank_volume_start: 2244.699999999999,
    tank_volume_stop: 2184.799999999999,
  },
  {
    timestamp_start: '2022-01-01T06:56:45',
    timestamp_stop: '2022-01-01T06:56:52',
    quantity: 62.4,
    vehicle: 'DEM555',
    id: 135098,
    tank_volume_start: 2184.799999999999,
    tank_volume_stop: 2126.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T06:57:07',
    timestamp_stop: '2022-01-01T06:57:13',
    quantity: 64.7,
    vehicle: 'DEM888',
    id: 135100,
    tank_volume_start: 2126.8999999999987,
    tank_volume_stop: 2074.699999999999,
  },
  {
    timestamp_start: '2022-01-01T06:57:28',
    timestamp_stop: '2022-01-01T06:57:33',
    quantity: 47.0,
    vehicle: 'DEM888',
    id: 135102,
    tank_volume_start: 2074.699999999999,
    tank_volume_stop: 2024.399999999999,
  },
  {
    timestamp_start: '2022-01-01T06:57:48',
    timestamp_stop: '2022-01-01T06:57:53',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 135104,
    tank_volume_start: 2024.399999999999,
    tank_volume_stop: 1964.4999999999989,
  },
  {
    timestamp_start: '2022-01-01T06:58:08',
    timestamp_stop: '2022-01-01T06:58:13',
    quantity: 47.5,
    vehicle: 'DEM111',
    id: 135106,
    tank_volume_start: 3964.499999999999,
    tank_volume_stop: 3916.199999999999,
  },
  {
    timestamp_start: '2022-01-01T06:58:28',
    timestamp_stop: '2022-01-01T06:58:34',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 135108,
    tank_volume_start: 3916.199999999999,
    tank_volume_stop: 3860.999999999999,
  },
  {
    timestamp_start: '2022-01-01T06:58:49',
    timestamp_stop: '2022-01-01T06:58:55',
    quantity: 59.9,
    vehicle: 'DEM222',
    id: 135110,
    tank_volume_start: 3860.999999999999,
    tank_volume_stop: 3802.099999999999,
  },
  {
    timestamp_start: '2022-01-01T06:59:10',
    timestamp_stop: '2022-01-01T06:59:15',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 135112,
    tank_volume_start: 3802.099999999999,
    tank_volume_stop: 3745.699999999999,
  },
  {
    timestamp_start: '2022-01-01T06:59:30',
    timestamp_stop: '2022-01-01T06:59:36',
    quantity: 64.2,
    vehicle: 'DEM333',
    id: 135114,
    tank_volume_start: 3745.699999999999,
    tank_volume_stop: 3687.599999999999,
  },
  {
    timestamp_start: '2022-01-01T06:59:51',
    timestamp_stop: '2022-01-01T06:59:57',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 135116,
    tank_volume_start: 3687.599999999999,
    tank_volume_stop: 3634.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:00:12',
    timestamp_stop: '2022-01-01T07:00:16',
    quantity: 46.2,
    vehicle: 'DEM333',
    id: 135118,
    tank_volume_start: 3634.299999999999,
    tank_volume_stop: 3579.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:00:31',
    timestamp_stop: '2022-01-01T07:00:37',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 135120,
    tank_volume_start: 3579.299999999999,
    tank_volume_stop: 3516.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:00:52',
    timestamp_stop: '2022-01-01T07:00:58',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 135122,
    tank_volume_start: 3516.4999999999986,
    tank_volume_stop: 3463.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:01:13',
    timestamp_stop: '2022-01-01T07:01:18',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 135124,
    tank_volume_start: 3463.9999999999986,
    tank_volume_stop: 3412.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:01:33',
    timestamp_stop: '2022-01-01T07:01:39',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 135126,
    tank_volume_start: 3412.5999999999985,
    tank_volume_stop: 3366.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T07:01:54',
    timestamp_stop: '2022-01-01T07:01:59',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 135128,
    tank_volume_start: 3366.2999999999984,
    tank_volume_stop: 3307.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T07:02:14',
    timestamp_stop: '2022-01-01T07:02:19',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 135130,
    tank_volume_start: 3307.8999999999983,
    tank_volume_stop: 3253.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:02:34',
    timestamp_stop: '2022-01-01T07:02:40',
    quantity: 63.7,
    vehicle: 'DEM777',
    id: 135132,
    tank_volume_start: 3253.6999999999985,
    tank_volume_stop: 3193.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:02:55',
    timestamp_stop: '2022-01-01T07:03:02',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 135134,
    tank_volume_start: 3193.9999999999986,
    tank_volume_stop: 3138.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:03:17',
    timestamp_stop: '2022-01-01T07:03:22',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 135136,
    tank_volume_start: 3138.299999999999,
    tank_volume_stop: 3081.699999999999,
  },
  {
    timestamp_start: '2022-01-01T07:03:37',
    timestamp_stop: '2022-01-01T07:03:43',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 135138,
    tank_volume_start: 3081.699999999999,
    tank_volume_stop: 3020.699999999999,
  },
  {
    timestamp_start: '2022-01-01T07:03:58',
    timestamp_stop: '2022-01-01T07:04:03',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 135140,
    tank_volume_start: 3020.699999999999,
    tank_volume_stop: 2973.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:04:18',
    timestamp_stop: '2022-01-01T07:04:24',
    quantity: 60.7,
    vehicle: 'DEM000',
    id: 135142,
    tank_volume_start: 2973.3999999999987,
    tank_volume_stop: 2916.699999999999,
  },
  {
    timestamp_start: '2022-01-01T07:04:39',
    timestamp_stop: '2022-01-01T07:04:45',
    quantity: 60.6,
    vehicle: 'DEM666',
    id: 135144,
    tank_volume_start: 2916.699999999999,
    tank_volume_stop: 2859.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:05:00',
    timestamp_stop: '2022-01-01T07:05:05',
    quantity: 49.3,
    vehicle: 'DEM555',
    id: 135146,
    tank_volume_start: 2859.599999999999,
    tank_volume_stop: 2812.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:05:20',
    timestamp_stop: '2022-01-01T07:05:25',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 135148,
    tank_volume_start: 2812.899999999999,
    tank_volume_stop: 2760.399999999999,
  },
  {
    timestamp_start: '2022-01-01T07:05:40',
    timestamp_stop: '2022-01-01T07:05:46',
    quantity: 62.9,
    vehicle: 'DEM555',
    id: 135150,
    tank_volume_start: 2760.399999999999,
    tank_volume_stop: 2706.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:06:01',
    timestamp_stop: '2022-01-01T07:06:06',
    quantity: 47.1,
    vehicle: 'DEM111',
    id: 135152,
    tank_volume_start: 2706.899999999999,
    tank_volume_stop: 2654.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:06:21',
    timestamp_stop: '2022-01-01T07:06:27',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 135154,
    tank_volume_start: 2654.499999999999,
    tank_volume_stop: 2606.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T07:06:42',
    timestamp_stop: '2022-01-01T07:06:48',
    quantity: 61.1,
    vehicle: 'DEM000',
    id: 135156,
    tank_volume_start: 2606.7999999999993,
    tank_volume_stop: 2558.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:07:03',
    timestamp_stop: '2022-01-01T07:07:08',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 135158,
    tank_volume_start: 2558.899999999999,
    tank_volume_stop: 2505.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:07:23',
    timestamp_stop: '2022-01-01T07:07:28',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 135160,
    tank_volume_start: 2505.899999999999,
    tank_volume_stop: 2451.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:07:43',
    timestamp_stop: '2022-01-01T07:07:48',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 135162,
    tank_volume_start: 2451.599999999999,
    tank_volume_stop: 2395.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:08:03',
    timestamp_stop: '2022-01-01T07:08:09',
    quantity: 59.9,
    vehicle: 'DEM888',
    id: 135164,
    tank_volume_start: 2395.299999999999,
    tank_volume_stop: 2340.199999999999,
  },
  {
    timestamp_start: '2022-01-01T07:08:24',
    timestamp_stop: '2022-01-01T07:08:29',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 135166,
    tank_volume_start: 2340.199999999999,
    tank_volume_stop: 2284.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:08:44',
    timestamp_stop: '2022-01-01T07:08:50',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 135168,
    tank_volume_start: 2284.499999999999,
    tank_volume_stop: 2229.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:09:05',
    timestamp_stop: '2022-01-01T07:09:11',
    quantity: 59.0,
    vehicle: 'DEM444',
    id: 135170,
    tank_volume_start: 2229.899999999999,
    tank_volume_stop: 2180.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T07:09:26',
    timestamp_stop: '2022-01-01T07:09:31',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 135172,
    tank_volume_start: 2180.2999999999993,
    tank_volume_stop: 2125.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T07:09:46',
    timestamp_stop: '2022-01-01T07:09:52',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 135174,
    tank_volume_start: 2125.7999999999993,
    tank_volume_stop: 2069.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:10:07',
    timestamp_stop: '2022-01-01T07:10:12',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 135176,
    tank_volume_start: 2069.899999999999,
    tank_volume_stop: 2013.0999999999992,
  },
  {
    timestamp_start: '2022-01-01T07:10:27',
    timestamp_stop: '2022-01-01T07:10:33',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 135178,
    tank_volume_start: 2013.0999999999992,
    tank_volume_stop: 1965.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T07:10:48',
    timestamp_stop: '2022-01-01T07:10:53',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 135180,
    tank_volume_start: 3965.2999999999993,
    tank_volume_stop: 3908.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:11:08',
    timestamp_stop: '2022-01-01T07:11:13',
    quantity: 53.6,
    vehicle: 'DEM111',
    id: 135182,
    tank_volume_start: 3908.499999999999,
    tank_volume_stop: 3855.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:11:28',
    timestamp_stop: '2022-01-01T07:11:35',
    quantity: 62.1,
    vehicle: 'DEM888',
    id: 135184,
    tank_volume_start: 3855.499999999999,
    tank_volume_stop: 3795.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:11:50',
    timestamp_stop: '2022-01-01T07:11:54',
    quantity: 47.3,
    vehicle: 'DEM666',
    id: 135186,
    tank_volume_start: 3795.899999999999,
    tank_volume_stop: 3741.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:12:09',
    timestamp_stop: '2022-01-01T07:12:15',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 135188,
    tank_volume_start: 3741.499999999999,
    tank_volume_stop: 3685.199999999999,
  },
  {
    timestamp_start: '2022-01-01T07:12:30',
    timestamp_stop: '2022-01-01T07:12:35',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 135190,
    tank_volume_start: 3685.199999999999,
    tank_volume_stop: 3631.199999999999,
  },
  {
    timestamp_start: '2022-01-01T07:12:50',
    timestamp_stop: '2022-01-01T07:12:56',
    quantity: 58.2,
    vehicle: 'DEM111',
    id: 135192,
    tank_volume_start: 3631.199999999999,
    tank_volume_stop: 3578.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:13:11',
    timestamp_stop: '2022-01-01T07:13:17',
    quantity: 62.3,
    vehicle: 'DEM000',
    id: 135194,
    tank_volume_start: 3578.599999999999,
    tank_volume_stop: 3519.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:13:32',
    timestamp_stop: '2022-01-01T07:13:37',
    quantity: 52.6,
    vehicle: 'DEM222',
    id: 135196,
    tank_volume_start: 3519.299999999999,
    tank_volume_stop: 3469.799999999999,
  },
  {
    timestamp_start: '2022-01-01T07:13:52',
    timestamp_stop: '2022-01-01T07:13:58',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 135198,
    tank_volume_start: 3469.799999999999,
    tank_volume_stop: 3421.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:14:13',
    timestamp_stop: '2022-01-01T07:14:18',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 135200,
    tank_volume_start: 3421.4999999999986,
    tank_volume_stop: 3367.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:14:33',
    timestamp_stop: '2022-01-01T07:14:38',
    quantity: 52.1,
    vehicle: 'DEM000',
    id: 135202,
    tank_volume_start: 3367.6999999999985,
    tank_volume_stop: 3315.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:14:53',
    timestamp_stop: '2022-01-01T07:15:00',
    quantity: 64.1,
    vehicle: 'DEM333',
    id: 135204,
    tank_volume_start: 3315.1999999999985,
    tank_volume_stop: 3264.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:15:15',
    timestamp_stop: '2022-01-01T07:15:20',
    quantity: 50.1,
    vehicle: 'DEM777',
    id: 135206,
    tank_volume_start: 3264.9999999999986,
    tank_volume_stop: 3215.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:15:35',
    timestamp_stop: '2022-01-01T07:15:40',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 135208,
    tank_volume_start: 3215.9999999999986,
    tank_volume_stop: 3164.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:15:55',
    timestamp_stop: '2022-01-01T07:16:00',
    quantity: 47.8,
    vehicle: 'DEM666',
    id: 135210,
    tank_volume_start: 3164.5999999999985,
    tank_volume_stop: 3109.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:16:15',
    timestamp_stop: '2022-01-01T07:16:21',
    quantity: 55.3,
    vehicle: 'DEM777',
    id: 135212,
    tank_volume_start: 3109.8999999999987,
    tank_volume_stop: 3058.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:16:36',
    timestamp_stop: '2022-01-01T07:16:40',
    quantity: 46.2,
    vehicle: 'DEM333',
    id: 135214,
    tank_volume_start: 3058.4999999999986,
    tank_volume_stop: 3008.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:16:55',
    timestamp_stop: '2022-01-01T07:17:02',
    quantity: 63.3,
    vehicle: 'DEM555',
    id: 135216,
    tank_volume_start: 3008.9999999999986,
    tank_volume_stop: 2958.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:17:17',
    timestamp_stop: '2022-01-01T07:17:22',
    quantity: 56.2,
    vehicle: 'DEM777',
    id: 135218,
    tank_volume_start: 2958.8999999999987,
    tank_volume_stop: 2907.199999999999,
  },
  {
    timestamp_start: '2022-01-01T07:17:37',
    timestamp_stop: '2022-01-01T07:17:43',
    quantity: 56.6,
    vehicle: 'DEM888',
    id: 135220,
    tank_volume_start: 2907.199999999999,
    tank_volume_stop: 2857.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:17:58',
    timestamp_stop: '2022-01-01T07:18:03',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 135222,
    tank_volume_start: 2857.299999999999,
    tank_volume_stop: 2808.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:18:18',
    timestamp_stop: '2022-01-01T07:18:24',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 135224,
    tank_volume_start: 2808.3999999999987,
    tank_volume_stop: 2752.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:18:39',
    timestamp_stop: '2022-01-01T07:18:45',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 135226,
    tank_volume_start: 2752.4999999999986,
    tank_volume_stop: 2696.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:19:00',
    timestamp_stop: '2022-01-01T07:19:06',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 135228,
    tank_volume_start: 2696.6999999999985,
    tank_volume_stop: 2634.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:19:21',
    timestamp_stop: '2022-01-01T07:19:26',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 135230,
    tank_volume_start: 2634.6999999999985,
    tank_volume_stop: 2581.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:19:41',
    timestamp_stop: '2022-01-01T07:19:46',
    quantity: 49.3,
    vehicle: 'DEM333',
    id: 135232,
    tank_volume_start: 2581.1999999999985,
    tank_volume_stop: 2528.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T07:20:01',
    timestamp_stop: '2022-01-01T07:20:07',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 135234,
    tank_volume_start: 2528.7999999999984,
    tank_volume_stop: 2476.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:20:22',
    timestamp_stop: '2022-01-01T07:20:28',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 135236,
    tank_volume_start: 2476.0999999999985,
    tank_volume_stop: 2424.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:20:43',
    timestamp_stop: '2022-01-01T07:20:49',
    quantity: 62.9,
    vehicle: 'DEM555',
    id: 135238,
    tank_volume_start: 2424.9999999999986,
    tank_volume_stop: 2379.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:21:04',
    timestamp_stop: '2022-01-01T07:21:10',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 135240,
    tank_volume_start: 2379.299999999999,
    tank_volume_stop: 2316.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:21:25',
    timestamp_stop: '2022-01-01T07:21:30',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 135242,
    tank_volume_start: 2316.3999999999987,
    tank_volume_stop: 2265.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:21:45',
    timestamp_stop: '2022-01-01T07:21:50',
    quantity: 45.7,
    vehicle: 'DEM222',
    id: 135244,
    tank_volume_start: 2265.0999999999985,
    tank_volume_stop: 2210.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:22:05',
    timestamp_stop: '2022-01-01T07:22:10',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 135246,
    tank_volume_start: 2210.6999999999985,
    tank_volume_stop: 2162.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:22:25',
    timestamp_stop: '2022-01-01T07:22:30',
    quantity: 49.0,
    vehicle: 'DEM666',
    id: 135248,
    tank_volume_start: 2162.0999999999985,
    tank_volume_stop: 2105.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:22:45',
    timestamp_stop: '2022-01-01T07:22:51',
    quantity: 58.0,
    vehicle: 'DEM000',
    id: 135250,
    tank_volume_start: 2105.5999999999985,
    tank_volume_stop: 2055.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:23:06',
    timestamp_stop: '2022-01-01T07:23:11',
    quantity: 46.4,
    vehicle: 'DEM222',
    id: 135252,
    tank_volume_start: 2055.6999999999985,
    tank_volume_stop: 2007.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:23:26',
    timestamp_stop: '2022-01-01T07:23:32',
    quantity: 61.5,
    vehicle: 'DEM000',
    id: 135254,
    tank_volume_start: 2007.5999999999985,
    tank_volume_stop: 1952.8999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:23:47',
    timestamp_stop: '2022-01-01T07:23:52',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 135256,
    tank_volume_start: 3952.8999999999987,
    tank_volume_stop: 3893.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:24:07',
    timestamp_stop: '2022-01-01T07:24:13',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 135258,
    tank_volume_start: 3893.299999999999,
    tank_volume_stop: 3835.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:24:28',
    timestamp_stop: '2022-01-01T07:24:33',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 135260,
    tank_volume_start: 3835.9999999999986,
    tank_volume_stop: 3775.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T07:24:48',
    timestamp_stop: '2022-01-01T07:24:54',
    quantity: 57.8,
    vehicle: 'DEM444',
    id: 135262,
    tank_volume_start: 3775.8999999999987,
    tank_volume_stop: 3716.799999999999,
  },
  {
    timestamp_start: '2022-01-01T07:25:09',
    timestamp_stop: '2022-01-01T07:25:15',
    quantity: 58.5,
    vehicle: 'DEM888',
    id: 135264,
    tank_volume_start: 3716.799999999999,
    tank_volume_stop: 3659.799999999999,
  },
  {
    timestamp_start: '2022-01-01T07:25:30',
    timestamp_stop: '2022-01-01T07:25:35',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 135266,
    tank_volume_start: 3659.799999999999,
    tank_volume_stop: 3602.199999999999,
  },
  {
    timestamp_start: '2022-01-01T07:25:50',
    timestamp_stop: '2022-01-01T07:25:56',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 135268,
    tank_volume_start: 3602.199999999999,
    tank_volume_stop: 3546.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:26:11',
    timestamp_stop: '2022-01-01T07:26:15',
    quantity: 47.3,
    vehicle: 'DEM777',
    id: 135270,
    tank_volume_start: 3546.499999999999,
    tank_volume_stop: 3492.399999999999,
  },
  {
    timestamp_start: '2022-01-01T07:26:30',
    timestamp_stop: '2022-01-01T07:26:36',
    quantity: 57.6,
    vehicle: 'DEM888',
    id: 135272,
    tank_volume_start: 3492.399999999999,
    tank_volume_stop: 3435.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:26:51',
    timestamp_stop: '2022-01-01T07:26:56',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 135274,
    tank_volume_start: 3435.899999999999,
    tank_volume_stop: 3381.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:27:11',
    timestamp_stop: '2022-01-01T07:27:17',
    quantity: 54.1,
    vehicle: 'DEM111',
    id: 135276,
    tank_volume_start: 3381.599999999999,
    tank_volume_stop: 3317.299999999999,
  },
  {
    timestamp_start: '2022-01-01T07:27:32',
    timestamp_stop: '2022-01-01T07:27:37',
    quantity: 51.9,
    vehicle: 'DEM333',
    id: 135278,
    tank_volume_start: 3317.299999999999,
    tank_volume_stop: 3263.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:27:52',
    timestamp_stop: '2022-01-01T07:27:58',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 135280,
    tank_volume_start: 3263.4999999999986,
    tank_volume_stop: 3203.799999999999,
  },
  {
    timestamp_start: '2022-01-01T07:28:13',
    timestamp_stop: '2022-01-01T07:28:18',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 135282,
    tank_volume_start: 3203.799999999999,
    tank_volume_stop: 3148.699999999999,
  },
  {
    timestamp_start: '2022-01-01T07:28:33',
    timestamp_stop: '2022-01-01T07:28:39',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 135284,
    tank_volume_start: 3148.699999999999,
    tank_volume_stop: 3094.699999999999,
  },
  {
    timestamp_start: '2022-01-01T07:28:54',
    timestamp_stop: '2022-01-01T07:28:59',
    quantity: 55.1,
    vehicle: 'DEM333',
    id: 135286,
    tank_volume_start: 3094.699999999999,
    tank_volume_stop: 3032.499999999999,
  },
  {
    timestamp_start: '2022-01-01T07:29:14',
    timestamp_stop: '2022-01-01T07:29:19',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 135288,
    tank_volume_start: 3032.499999999999,
    tank_volume_stop: 2970.899999999999,
  },
  {
    timestamp_start: '2022-01-01T07:29:34',
    timestamp_stop: '2022-01-01T07:29:40',
    quantity: 55.2,
    vehicle: 'DEM444',
    id: 135290,
    tank_volume_start: 2970.899999999999,
    tank_volume_stop: 2919.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:29:55',
    timestamp_stop: '2022-01-01T07:30:01',
    quantity: 63.5,
    vehicle: 'DEM222',
    id: 135292,
    tank_volume_start: 2919.599999999999,
    tank_volume_stop: 2871.599999999999,
  },
  {
    timestamp_start: '2022-01-01T07:30:16',
    timestamp_stop: '2022-01-01T07:30:22',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 135294,
    tank_volume_start: 2871.599999999999,
    tank_volume_stop: 2814.799999999999,
  },
  {
    timestamp_start: '2022-01-01T07:30:37',
    timestamp_stop: '2022-01-01T07:30:42',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 135296,
    tank_volume_start: 2814.799999999999,
    tank_volume_stop: 2760.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:30:57',
    timestamp_stop: '2022-01-01T07:31:02',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 135298,
    tank_volume_start: 2760.4999999999986,
    tank_volume_stop: 2707.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T07:31:17',
    timestamp_stop: '2022-01-01T07:31:22',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 135300,
    tank_volume_start: 2707.9999999999986,
    tank_volume_stop: 2653.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T07:31:37',
    timestamp_stop: '2022-01-01T07:31:43',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 135302,
    tank_volume_start: 2653.6999999999985,
    tank_volume_stop: 2601.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T07:31:58',
    timestamp_stop: '2022-01-01T07:32:03',
    quantity: 52.5,
    vehicle: 'DEM333',
    id: 135304,
    tank_volume_start: 2601.8999999999983,
    tank_volume_stop: 2543.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T07:32:18',
    timestamp_stop: '2022-01-01T07:32:23',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 135306,
    tank_volume_start: 2543.3999999999983,
    tank_volume_stop: 2480.499999999998,
  },
  {
    timestamp_start: '2022-01-01T07:32:38',
    timestamp_stop: '2022-01-01T07:32:43',
    quantity: 47.3,
    vehicle: 'DEM333',
    id: 135308,
    tank_volume_start: 2480.499999999998,
    tank_volume_stop: 2429.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T07:32:58',
    timestamp_stop: '2022-01-01T07:33:04',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 135310,
    tank_volume_start: 2429.3999999999983,
    tank_volume_stop: 2364.599999999998,
  },
  {
    timestamp_start: '2022-01-01T07:33:19',
    timestamp_stop: '2022-01-01T07:33:24',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 135312,
    tank_volume_start: 2364.599999999998,
    tank_volume_stop: 2306.099999999998,
  },
  {
    timestamp_start: '2022-01-01T07:33:39',
    timestamp_stop: '2022-01-01T07:33:44',
    quantity: 47.9,
    vehicle: 'DEM000',
    id: 135314,
    tank_volume_start: 2306.099999999998,
    tank_volume_stop: 2256.499999999998,
  },
  {
    timestamp_start: '2022-01-01T07:33:59',
    timestamp_stop: '2022-01-01T07:34:05',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 135316,
    tank_volume_start: 2256.499999999998,
    tank_volume_stop: 2195.599999999998,
  },
  {
    timestamp_start: '2022-01-01T07:34:20',
    timestamp_stop: '2022-01-01T07:34:25',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 135318,
    tank_volume_start: 2195.599999999998,
    tank_volume_stop: 2135.199999999998,
  },
  {
    timestamp_start: '2022-01-01T07:34:40',
    timestamp_stop: '2022-01-01T07:34:45',
    quantity: 52.6,
    vehicle: 'DEM444',
    id: 135320,
    tank_volume_start: 2135.199999999998,
    tank_volume_stop: 2082.399999999998,
  },
  {
    timestamp_start: '2022-01-01T07:35:00',
    timestamp_stop: '2022-01-01T07:35:06',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 135322,
    tank_volume_start: 2082.399999999998,
    tank_volume_stop: 2034.6999999999978,
  },
  {
    timestamp_start: '2022-01-01T07:35:21',
    timestamp_stop: '2022-01-01T07:35:26',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 135324,
    tank_volume_start: 2034.6999999999978,
    tank_volume_stop: 1986.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T07:35:41',
    timestamp_stop: '2022-01-01T07:35:47',
    quantity: 62.4,
    vehicle: 'DEM000',
    id: 135326,
    tank_volume_start: 3986.4999999999977,
    tank_volume_stop: 3928.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T07:36:02',
    timestamp_stop: '2022-01-01T07:36:08',
    quantity: 56.2,
    vehicle: 'DEM000',
    id: 135328,
    tank_volume_start: 3928.4999999999977,
    tank_volume_stop: 3872.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T07:36:23',
    timestamp_stop: '2022-01-01T07:36:29',
    quantity: 62.6,
    vehicle: 'DEM444',
    id: 135330,
    tank_volume_start: 3872.9999999999977,
    tank_volume_stop: 3811.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:36:44',
    timestamp_stop: '2022-01-01T07:36:50',
    quantity: 58.4,
    vehicle: 'DEM111',
    id: 135332,
    tank_volume_start: 3811.1999999999975,
    tank_volume_stop: 3757.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:37:05',
    timestamp_stop: '2022-01-01T07:37:11',
    quantity: 64.5,
    vehicle: 'DEM888',
    id: 135334,
    tank_volume_start: 3757.8999999999974,
    tank_volume_stop: 3706.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:37:26',
    timestamp_stop: '2022-01-01T07:37:32',
    quantity: 59.2,
    vehicle: 'DEM111',
    id: 135336,
    tank_volume_start: 3706.7999999999975,
    tank_volume_stop: 3653.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:37:47',
    timestamp_stop: '2022-01-01T07:37:54',
    quantity: 64.5,
    vehicle: 'DEM777',
    id: 135338,
    tank_volume_start: 3653.9999999999973,
    tank_volume_stop: 3602.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:38:09',
    timestamp_stop: '2022-01-01T07:38:14',
    quantity: 51.0,
    vehicle: 'DEM222',
    id: 135340,
    tank_volume_start: 3602.8999999999974,
    tank_volume_stop: 3545.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:38:29',
    timestamp_stop: '2022-01-01T07:38:35',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 135342,
    tank_volume_start: 3545.7999999999975,
    tank_volume_stop: 3495.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:38:50',
    timestamp_stop: '2022-01-01T07:38:55',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 135344,
    tank_volume_start: 3495.4999999999973,
    tank_volume_stop: 3440.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:39:10',
    timestamp_stop: '2022-01-01T07:39:15',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 135346,
    tank_volume_start: 3440.7999999999975,
    tank_volume_stop: 3393.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:39:30',
    timestamp_stop: '2022-01-01T07:39:36',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 135348,
    tank_volume_start: 3393.4999999999973,
    tank_volume_stop: 3341.699999999997,
  },
  {
    timestamp_start: '2022-01-01T07:39:51',
    timestamp_stop: '2022-01-01T07:39:56',
    quantity: 45.6,
    vehicle: 'DEM222',
    id: 135350,
    tank_volume_start: 3341.699999999997,
    tank_volume_stop: 3290.099999999997,
  },
  {
    timestamp_start: '2022-01-01T07:40:11',
    timestamp_stop: '2022-01-01T07:40:15',
    quantity: 47.1,
    vehicle: 'DEM000',
    id: 135352,
    tank_volume_start: 3290.099999999997,
    tank_volume_stop: 3231.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:40:30',
    timestamp_stop: '2022-01-01T07:40:35',
    quantity: 46.8,
    vehicle: 'DEM888',
    id: 135354,
    tank_volume_start: 3231.8999999999974,
    tank_volume_stop: 3173.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:40:50',
    timestamp_stop: '2022-01-01T07:40:56',
    quantity: 62.7,
    vehicle: 'DEM666',
    id: 135356,
    tank_volume_start: 3173.4999999999973,
    tank_volume_stop: 3114.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:41:11',
    timestamp_stop: '2022-01-01T07:41:17',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 135358,
    tank_volume_start: 3114.9999999999973,
    tank_volume_stop: 3055.199999999997,
  },
  {
    timestamp_start: '2022-01-01T07:41:32',
    timestamp_stop: '2022-01-01T07:41:37',
    quantity: 47.8,
    vehicle: 'DEM666',
    id: 135360,
    tank_volume_start: 3055.199999999997,
    tank_volume_stop: 3005.799999999997,
  },
  {
    timestamp_start: '2022-01-01T07:41:52',
    timestamp_stop: '2022-01-01T07:41:58',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 135362,
    tank_volume_start: 3005.799999999997,
    tank_volume_stop: 2955.099999999997,
  },
  {
    timestamp_start: '2022-01-01T07:42:13',
    timestamp_stop: '2022-01-01T07:42:19',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 135364,
    tank_volume_start: 2955.099999999997,
    tank_volume_stop: 2901.299999999997,
  },
  {
    timestamp_start: '2022-01-01T07:42:34',
    timestamp_stop: '2022-01-01T07:42:40',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 135366,
    tank_volume_start: 2901.299999999997,
    tank_volume_stop: 2841.299999999997,
  },
  {
    timestamp_start: '2022-01-01T07:42:55',
    timestamp_stop: '2022-01-01T07:43:00',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 135368,
    tank_volume_start: 2841.299999999997,
    tank_volume_stop: 2789.599999999997,
  },
  {
    timestamp_start: '2022-01-01T07:43:15',
    timestamp_stop: '2022-01-01T07:43:21',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 135370,
    tank_volume_start: 2789.599999999997,
    tank_volume_stop: 2740.699999999997,
  },
  {
    timestamp_start: '2022-01-01T07:43:36',
    timestamp_stop: '2022-01-01T07:43:42',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 135372,
    tank_volume_start: 2740.699999999997,
    tank_volume_stop: 2687.299999999997,
  },
  {
    timestamp_start: '2022-01-01T07:43:57',
    timestamp_stop: '2022-01-01T07:44:02',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 135374,
    tank_volume_start: 2687.299999999997,
    tank_volume_stop: 2633.199999999997,
  },
  {
    timestamp_start: '2022-01-01T07:44:17',
    timestamp_stop: '2022-01-01T07:44:22',
    quantity: 45.4,
    vehicle: 'DEM222',
    id: 135376,
    tank_volume_start: 2633.199999999997,
    tank_volume_stop: 2578.699999999997,
  },
  {
    timestamp_start: '2022-01-01T07:44:37',
    timestamp_stop: '2022-01-01T07:44:42',
    quantity: 49.1,
    vehicle: 'DEM000',
    id: 135378,
    tank_volume_start: 2578.699999999997,
    tank_volume_stop: 2524.399999999997,
  },
  {
    timestamp_start: '2022-01-01T07:44:57',
    timestamp_stop: '2022-01-01T07:45:01',
    quantity: 46.7,
    vehicle: 'DEM333',
    id: 135380,
    tank_volume_start: 2524.399999999997,
    tank_volume_stop: 2467.299999999997,
  },
  {
    timestamp_start: '2022-01-01T07:45:16',
    timestamp_stop: '2022-01-01T07:45:21',
    quantity: 49.4,
    vehicle: 'DEM222',
    id: 135382,
    tank_volume_start: 2467.299999999997,
    tank_volume_stop: 2410.799999999997,
  },
  {
    timestamp_start: '2022-01-01T07:45:36',
    timestamp_stop: '2022-01-01T07:45:41',
    quantity: 47.3,
    vehicle: 'DEM333',
    id: 135384,
    tank_volume_start: 2410.799999999997,
    tank_volume_stop: 2350.399999999997,
  },
  {
    timestamp_start: '2022-01-01T07:45:56',
    timestamp_stop: '2022-01-01T07:46:01',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 135386,
    tank_volume_start: 2350.399999999997,
    tank_volume_stop: 2296.399999999997,
  },
  {
    timestamp_start: '2022-01-01T07:46:16',
    timestamp_stop: '2022-01-01T07:46:23',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 135388,
    tank_volume_start: 2296.399999999997,
    tank_volume_stop: 2245.199999999997,
  },
  {
    timestamp_start: '2022-01-01T07:46:38',
    timestamp_stop: '2022-01-01T07:46:42',
    quantity: 46.1,
    vehicle: 'DEM222',
    id: 135390,
    tank_volume_start: 2245.199999999997,
    tank_volume_stop: 2188.199999999997,
  },
  {
    timestamp_start: '2022-01-01T07:46:57',
    timestamp_stop: '2022-01-01T07:47:03',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 135392,
    tank_volume_start: 2188.199999999997,
    tank_volume_stop: 2134.599999999997,
  },
  {
    timestamp_start: '2022-01-01T07:47:18',
    timestamp_stop: '2022-01-01T07:47:23',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 135394,
    tank_volume_start: 2134.599999999997,
    tank_volume_stop: 2077.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:47:38',
    timestamp_stop: '2022-01-01T07:47:43',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 135396,
    tank_volume_start: 2077.4999999999973,
    tank_volume_stop: 2017.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:47:58',
    timestamp_stop: '2022-01-01T07:48:03',
    quantity: 48.5,
    vehicle: 'DEM888',
    id: 135398,
    tank_volume_start: 2017.8999999999974,
    tank_volume_stop: 1954.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:48:18',
    timestamp_stop: '2022-01-01T07:48:24',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 135400,
    tank_volume_start: 3954.7999999999975,
    tank_volume_stop: 3891.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:48:39',
    timestamp_stop: '2022-01-01T07:48:44',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 135402,
    tank_volume_start: 3891.9999999999973,
    tank_volume_stop: 3835.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:48:59',
    timestamp_stop: '2022-01-01T07:49:04',
    quantity: 48.0,
    vehicle: 'DEM444',
    id: 135404,
    tank_volume_start: 3835.8999999999974,
    tank_volume_stop: 3778.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:49:19',
    timestamp_stop: '2022-01-01T07:49:25',
    quantity: 58.7,
    vehicle: 'DEM111',
    id: 135406,
    tank_volume_start: 3778.2999999999975,
    tank_volume_stop: 3729.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:49:40',
    timestamp_stop: '2022-01-01T07:49:45',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 135408,
    tank_volume_start: 3729.9999999999973,
    tank_volume_stop: 3678.199999999997,
  },
  {
    timestamp_start: '2022-01-01T07:50:00',
    timestamp_stop: '2022-01-01T07:50:06',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 135410,
    tank_volume_start: 3678.199999999997,
    tank_volume_stop: 3619.699999999997,
  },
  {
    timestamp_start: '2022-01-01T07:50:21',
    timestamp_stop: '2022-01-01T07:50:27',
    quantity: 58.4,
    vehicle: 'DEM333',
    id: 135412,
    tank_volume_start: 3619.699999999997,
    tank_volume_stop: 3566.099999999997,
  },
  {
    timestamp_start: '2022-01-01T07:50:42',
    timestamp_stop: '2022-01-01T07:50:48',
    quantity: 61.5,
    vehicle: 'DEM555',
    id: 135414,
    tank_volume_start: 3566.099999999997,
    tank_volume_stop: 3517.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:51:03',
    timestamp_stop: '2022-01-01T07:51:08',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 135416,
    tank_volume_start: 3517.9999999999973,
    tank_volume_stop: 3458.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:51:23',
    timestamp_stop: '2022-01-01T07:51:29',
    quantity: 58.6,
    vehicle: 'DEM888',
    id: 135418,
    tank_volume_start: 3458.2999999999975,
    tank_volume_stop: 3400.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T07:51:44',
    timestamp_stop: '2022-01-01T07:51:50',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 135420,
    tank_volume_start: 3400.0999999999976,
    tank_volume_stop: 3349.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:52:05',
    timestamp_stop: '2022-01-01T07:52:11',
    quantity: 61.3,
    vehicle: 'DEM111',
    id: 135422,
    tank_volume_start: 3349.7999999999975,
    tank_volume_stop: 3294.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:52:26',
    timestamp_stop: '2022-01-01T07:52:32',
    quantity: 63.1,
    vehicle: 'DEM000',
    id: 135424,
    tank_volume_start: 3294.9999999999973,
    tank_volume_stop: 3247.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:52:47',
    timestamp_stop: '2022-01-01T07:52:52',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 135426,
    tank_volume_start: 3247.2999999999975,
    tank_volume_stop: 3186.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:53:07',
    timestamp_stop: '2022-01-01T07:53:13',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 135428,
    tank_volume_start: 3186.3999999999974,
    tank_volume_stop: 3125.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:53:28',
    timestamp_stop: '2022-01-01T07:53:34',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 135430,
    tank_volume_start: 3125.6999999999975,
    tank_volume_stop: 3072.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T07:53:49',
    timestamp_stop: '2022-01-01T07:53:54',
    quantity: 48.8,
    vehicle: 'DEM444',
    id: 135432,
    tank_volume_start: 3072.5999999999976,
    tank_volume_stop: 3013.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:54:09',
    timestamp_stop: '2022-01-01T07:54:15',
    quantity: 62.0,
    vehicle: 'DEM444',
    id: 135434,
    tank_volume_start: 3013.2999999999975,
    tank_volume_stop: 2949.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T07:54:30',
    timestamp_stop: '2022-01-01T07:54:36',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 135436,
    tank_volume_start: 2949.5999999999976,
    tank_volume_stop: 2903.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:54:51',
    timestamp_stop: '2022-01-01T07:54:55',
    quantity: 45.6,
    vehicle: 'DEM555',
    id: 135438,
    tank_volume_start: 2903.6999999999975,
    tank_volume_stop: 2849.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:55:10',
    timestamp_stop: '2022-01-01T07:55:15',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 135440,
    tank_volume_start: 2849.8999999999974,
    tank_volume_stop: 2798.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:55:30',
    timestamp_stop: '2022-01-01T07:55:36',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 135442,
    tank_volume_start: 2798.8999999999974,
    tank_volume_stop: 2747.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:55:51',
    timestamp_stop: '2022-01-01T07:55:56',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 135444,
    tank_volume_start: 2747.4999999999973,
    tank_volume_stop: 2685.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:56:11',
    timestamp_stop: '2022-01-01T07:56:16',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 135446,
    tank_volume_start: 2685.3999999999974,
    tank_volume_stop: 2625.099999999997,
  },
  {
    timestamp_start: '2022-01-01T07:56:31',
    timestamp_stop: '2022-01-01T07:56:36',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 135448,
    tank_volume_start: 2625.099999999997,
    tank_volume_stop: 2574.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T07:56:51',
    timestamp_stop: '2022-01-01T07:56:57',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 135450,
    tank_volume_start: 2574.9999999999973,
    tank_volume_stop: 2514.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:57:12',
    timestamp_stop: '2022-01-01T07:57:18',
    quantity: 57.8,
    vehicle: 'DEM777',
    id: 135452,
    tank_volume_start: 2514.8999999999974,
    tank_volume_stop: 2458.599999999997,
  },
  {
    timestamp_start: '2022-01-01T07:57:33',
    timestamp_stop: '2022-01-01T07:57:38',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 135454,
    tank_volume_start: 2458.599999999997,
    tank_volume_stop: 2405.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T07:57:53',
    timestamp_stop: '2022-01-01T07:57:58',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 135456,
    tank_volume_start: 2405.3999999999974,
    tank_volume_stop: 2359.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:58:13',
    timestamp_stop: '2022-01-01T07:58:18',
    quantity: 46.8,
    vehicle: 'DEM888',
    id: 135458,
    tank_volume_start: 2359.7999999999975,
    tank_volume_stop: 2308.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T07:58:33',
    timestamp_stop: '2022-01-01T07:58:38',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 135460,
    tank_volume_start: 2308.1999999999975,
    tank_volume_stop: 2249.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T07:58:53',
    timestamp_stop: '2022-01-01T07:58:58',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 135462,
    tank_volume_start: 2249.9999999999977,
    tank_volume_stop: 2195.299999999998,
  },
  {
    timestamp_start: '2022-01-01T07:59:13',
    timestamp_stop: '2022-01-01T07:59:18',
    quantity: 47.6,
    vehicle: 'DEM111',
    id: 135464,
    tank_volume_start: 2195.299999999998,
    tank_volume_stop: 2140.199999999998,
  },
  {
    timestamp_start: '2022-01-01T07:59:33',
    timestamp_stop: '2022-01-01T07:59:39',
    quantity: 61.0,
    vehicle: 'DEM555',
    id: 135466,
    tank_volume_start: 2140.199999999998,
    tank_volume_stop: 2085.399999999998,
  },
  {
    timestamp_start: '2022-01-01T07:59:54',
    timestamp_stop: '2022-01-01T08:00:00',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 135468,
    tank_volume_start: 2085.399999999998,
    tank_volume_stop: 2021.8999999999978,
  },
  {
    timestamp_start: '2022-01-01T08:00:15',
    timestamp_stop: '2022-01-01T08:00:20',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 135470,
    tank_volume_start: 2021.8999999999978,
    tank_volume_stop: 1965.6999999999978,
  },
  {
    timestamp_start: '2022-01-01T08:00:35',
    timestamp_stop: '2022-01-01T08:00:41',
    quantity: 60.9,
    vehicle: 'DEM444',
    id: 135472,
    tank_volume_start: 3965.699999999998,
    tank_volume_stop: 3910.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:00:56',
    timestamp_stop: '2022-01-01T08:01:01',
    quantity: 53.1,
    vehicle: 'DEM222',
    id: 135474,
    tank_volume_start: 3910.099999999998,
    tank_volume_stop: 3855.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:01:16',
    timestamp_stop: '2022-01-01T08:01:21',
    quantity: 51.0,
    vehicle: 'DEM555',
    id: 135476,
    tank_volume_start: 3855.499999999998,
    tank_volume_stop: 3801.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:01:36',
    timestamp_stop: '2022-01-01T08:01:43',
    quantity: 62.0,
    vehicle: 'DEM888',
    id: 135478,
    tank_volume_start: 3801.699999999998,
    tank_volume_stop: 3751.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:01:58',
    timestamp_stop: '2022-01-01T08:02:04',
    quantity: 60.3,
    vehicle: 'DEM111',
    id: 135480,
    tank_volume_start: 3751.499999999998,
    tank_volume_stop: 3699.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:02:19',
    timestamp_stop: '2022-01-01T08:02:25',
    quantity: 62.5,
    vehicle: 'DEM666',
    id: 135482,
    tank_volume_start: 3699.599999999998,
    tank_volume_stop: 3649.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:02:40',
    timestamp_stop: '2022-01-01T08:02:45',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 135484,
    tank_volume_start: 3649.499999999998,
    tank_volume_stop: 3591.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:03:00',
    timestamp_stop: '2022-01-01T08:03:07',
    quantity: 63.5,
    vehicle: 'DEM111',
    id: 135486,
    tank_volume_start: 3591.199999999998,
    tank_volume_stop: 3531.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:03:22',
    timestamp_stop: '2022-01-01T08:03:27',
    quantity: 49.8,
    vehicle: 'DEM888',
    id: 135488,
    tank_volume_start: 3531.399999999998,
    tank_volume_stop: 3485.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:03:42',
    timestamp_stop: '2022-01-01T08:03:46',
    quantity: 45.2,
    vehicle: 'DEM444',
    id: 135490,
    tank_volume_start: 3485.799999999998,
    tank_volume_stop: 3429.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:04:01',
    timestamp_stop: '2022-01-01T08:04:07',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 135492,
    tank_volume_start: 3429.199999999998,
    tank_volume_stop: 3376.299999999998,
  },
  {
    timestamp_start: '2022-01-01T08:04:22',
    timestamp_stop: '2022-01-01T08:04:28',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 135494,
    tank_volume_start: 3376.299999999998,
    tank_volume_stop: 3322.299999999998,
  },
  {
    timestamp_start: '2022-01-01T08:04:43',
    timestamp_stop: '2022-01-01T08:04:49',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 135496,
    tank_volume_start: 3322.299999999998,
    tank_volume_stop: 3266.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:05:04',
    timestamp_stop: '2022-01-01T08:05:09',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 135498,
    tank_volume_start: 3266.099999999998,
    tank_volume_stop: 3212.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:05:24',
    timestamp_stop: '2022-01-01T08:05:29',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 135500,
    tank_volume_start: 3212.999999999998,
    tank_volume_stop: 3157.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:05:44',
    timestamp_stop: '2022-01-01T08:05:49',
    quantity: 47.5,
    vehicle: 'DEM111',
    id: 135502,
    tank_volume_start: 3157.699999999998,
    tank_volume_stop: 3104.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:06:04',
    timestamp_stop: '2022-01-01T08:06:09',
    quantity: 50.5,
    vehicle: 'DEM555',
    id: 135504,
    tank_volume_start: 3104.999999999998,
    tank_volume_stop: 3054.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:06:24',
    timestamp_stop: '2022-01-01T08:06:30',
    quantity: 57.0,
    vehicle: 'DEM555',
    id: 135506,
    tank_volume_start: 3054.999999999998,
    tank_volume_stop: 3001.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:06:45',
    timestamp_stop: '2022-01-01T08:06:51',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 135508,
    tank_volume_start: 3001.7999999999984,
    tank_volume_stop: 2946.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:07:06',
    timestamp_stop: '2022-01-01T08:07:12',
    quantity: 62.9,
    vehicle: 'DEM666',
    id: 135510,
    tank_volume_start: 2946.5999999999985,
    tank_volume_stop: 2889.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:07:27',
    timestamp_stop: '2022-01-01T08:07:33',
    quantity: 61.3,
    vehicle: 'DEM777',
    id: 135512,
    tank_volume_start: 2889.2999999999984,
    tank_volume_stop: 2833.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:07:48',
    timestamp_stop: '2022-01-01T08:07:53',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 135514,
    tank_volume_start: 2833.499999999998,
    tank_volume_stop: 2778.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:08:08',
    timestamp_stop: '2022-01-01T08:08:14',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 135516,
    tank_volume_start: 2778.7999999999984,
    tank_volume_stop: 2724.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:08:29',
    timestamp_stop: '2022-01-01T08:08:34',
    quantity: 47.1,
    vehicle: 'DEM111',
    id: 135518,
    tank_volume_start: 2724.0999999999985,
    tank_volume_stop: 2674.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:08:49',
    timestamp_stop: '2022-01-01T08:08:55',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 135520,
    tank_volume_start: 2674.2999999999984,
    tank_volume_stop: 2623.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:09:10',
    timestamp_stop: '2022-01-01T08:09:15',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 135522,
    tank_volume_start: 2623.0999999999985,
    tank_volume_stop: 2570.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:09:30',
    timestamp_stop: '2022-01-01T08:09:36',
    quantity: 60.8,
    vehicle: 'DEM777',
    id: 135524,
    tank_volume_start: 2570.0999999999985,
    tank_volume_stop: 2519.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T08:09:51',
    timestamp_stop: '2022-01-01T08:09:56',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 135526,
    tank_volume_start: 2519.4999999999986,
    tank_volume_stop: 2464.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:10:11',
    timestamp_stop: '2022-01-01T08:10:16',
    quantity: 45.3,
    vehicle: 'DEM666',
    id: 135528,
    tank_volume_start: 2464.1999999999985,
    tank_volume_stop: 2406.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:10:31',
    timestamp_stop: '2022-01-01T08:10:36',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 135530,
    tank_volume_start: 2406.7999999999984,
    tank_volume_stop: 2350.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:10:51',
    timestamp_stop: '2022-01-01T08:10:57',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 135532,
    tank_volume_start: 2350.3999999999983,
    tank_volume_stop: 2302.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:11:12',
    timestamp_stop: '2022-01-01T08:11:17',
    quantity: 51.5,
    vehicle: 'DEM333',
    id: 135534,
    tank_volume_start: 2302.6999999999985,
    tank_volume_stop: 2247.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:11:32',
    timestamp_stop: '2022-01-01T08:11:38',
    quantity: 57.8,
    vehicle: 'DEM222',
    id: 135536,
    tank_volume_start: 2247.2999999999984,
    tank_volume_stop: 2191.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:11:53',
    timestamp_stop: '2022-01-01T08:11:58',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 135538,
    tank_volume_start: 2191.3999999999983,
    tank_volume_stop: 2135.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:12:13',
    timestamp_stop: '2022-01-01T08:12:19',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 135540,
    tank_volume_start: 2135.999999999998,
    tank_volume_stop: 2077.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:12:34',
    timestamp_stop: '2022-01-01T08:12:39',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 135542,
    tank_volume_start: 2077.099999999998,
    tank_volume_stop: 2026.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:12:54',
    timestamp_stop: '2022-01-01T08:13:00',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 135544,
    tank_volume_start: 2026.099999999998,
    tank_volume_stop: 1970.4999999999982,
  },
  {
    timestamp_start: '2022-01-01T08:13:15',
    timestamp_stop: '2022-01-01T08:13:21',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 135546,
    tank_volume_start: 3970.499999999998,
    tank_volume_stop: 3910.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:13:36',
    timestamp_stop: '2022-01-01T08:13:41',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 135548,
    tank_volume_start: 3910.3999999999983,
    tank_volume_stop: 3852.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:13:56',
    timestamp_stop: '2022-01-01T08:14:01',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 135550,
    tank_volume_start: 3852.6999999999985,
    tank_volume_stop: 3801.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:14:16',
    timestamp_stop: '2022-01-01T08:14:22',
    quantity: 59.3,
    vehicle: 'DEM222',
    id: 135552,
    tank_volume_start: 3801.6999999999985,
    tank_volume_stop: 3753.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:14:37',
    timestamp_stop: '2022-01-01T08:14:42',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 135554,
    tank_volume_start: 3753.1999999999985,
    tank_volume_stop: 3701.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:14:57',
    timestamp_stop: '2022-01-01T08:15:03',
    quantity: 52.3,
    vehicle: 'DEM000',
    id: 135556,
    tank_volume_start: 3701.7999999999984,
    tank_volume_stop: 3651.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:15:18',
    timestamp_stop: '2022-01-01T08:15:23',
    quantity: 49.7,
    vehicle: 'DEM111',
    id: 135558,
    tank_volume_start: 3651.499999999998,
    tank_volume_stop: 3599.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:15:38',
    timestamp_stop: '2022-01-01T08:15:44',
    quantity: 63.2,
    vehicle: 'DEM222',
    id: 135560,
    tank_volume_start: 3599.099999999998,
    tank_volume_stop: 3544.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:15:59',
    timestamp_stop: '2022-01-01T08:16:04',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 135562,
    tank_volume_start: 3544.999999999998,
    tank_volume_stop: 3485.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:16:19',
    timestamp_stop: '2022-01-01T08:16:25',
    quantity: 59.6,
    vehicle: 'DEM666',
    id: 135564,
    tank_volume_start: 3485.599999999998,
    tank_volume_stop: 3428.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:16:40',
    timestamp_stop: '2022-01-01T08:16:45',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 135566,
    tank_volume_start: 3428.699999999998,
    tank_volume_stop: 3383.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:17:00',
    timestamp_stop: '2022-01-01T08:17:06',
    quantity: 59.7,
    vehicle: 'DEM000',
    id: 135568,
    tank_volume_start: 3383.699999999998,
    tank_volume_stop: 3333.299999999998,
  },
  {
    timestamp_start: '2022-01-01T08:17:21',
    timestamp_stop: '2022-01-01T08:17:26',
    quantity: 46.5,
    vehicle: 'DEM111',
    id: 135570,
    tank_volume_start: 3333.299999999998,
    tank_volume_stop: 3284.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:17:41',
    timestamp_stop: '2022-01-01T08:17:46',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 135572,
    tank_volume_start: 3284.799999999998,
    tank_volume_stop: 3224.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:18:01',
    timestamp_stop: '2022-01-01T08:18:07',
    quantity: 62.4,
    vehicle: 'DEM111',
    id: 135574,
    tank_volume_start: 3224.199999999998,
    tank_volume_stop: 3161.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:18:22',
    timestamp_stop: '2022-01-01T08:18:28',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 135576,
    tank_volume_start: 3161.399999999998,
    tank_volume_stop: 3106.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:18:43',
    timestamp_stop: '2022-01-01T08:18:48',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 135578,
    tank_volume_start: 3106.9999999999977,
    tank_volume_stop: 3051.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:19:03',
    timestamp_stop: '2022-01-01T08:19:09',
    quantity: 54.6,
    vehicle: 'DEM333',
    id: 135580,
    tank_volume_start: 3051.4999999999977,
    tank_volume_stop: 2995.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:19:24',
    timestamp_stop: '2022-01-01T08:19:30',
    quantity: 63.0,
    vehicle: 'DEM222',
    id: 135582,
    tank_volume_start: 2995.6999999999975,
    tank_volume_stop: 2949.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T08:19:45',
    timestamp_stop: '2022-01-01T08:19:51',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 135584,
    tank_volume_start: 2949.3999999999974,
    tank_volume_stop: 2895.099999999997,
  },
  {
    timestamp_start: '2022-01-01T08:20:06',
    timestamp_stop: '2022-01-01T08:20:11',
    quantity: 56.0,
    vehicle: 'DEM111',
    id: 135586,
    tank_volume_start: 2895.099999999997,
    tank_volume_stop: 2843.699999999997,
  },
  {
    timestamp_start: '2022-01-01T08:20:26',
    timestamp_stop: '2022-01-01T08:20:32',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 135588,
    tank_volume_start: 2843.699999999997,
    tank_volume_stop: 2790.899999999997,
  },
  {
    timestamp_start: '2022-01-01T08:20:47',
    timestamp_stop: '2022-01-01T08:20:52',
    quantity: 51.3,
    vehicle: 'DEM555',
    id: 135590,
    tank_volume_start: 2790.899999999997,
    tank_volume_stop: 2727.399999999997,
  },
  {
    timestamp_start: '2022-01-01T08:21:07',
    timestamp_stop: '2022-01-01T08:21:12',
    quantity: 50.4,
    vehicle: 'DEM777',
    id: 135592,
    tank_volume_start: 2727.399999999997,
    tank_volume_stop: 2673.299999999997,
  },
  {
    timestamp_start: '2022-01-01T08:21:27',
    timestamp_stop: '2022-01-01T08:21:33',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 135594,
    tank_volume_start: 2673.299999999997,
    tank_volume_stop: 2623.199999999997,
  },
  {
    timestamp_start: '2022-01-01T08:21:48',
    timestamp_stop: '2022-01-01T08:21:54',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 135596,
    tank_volume_start: 2623.199999999997,
    tank_volume_stop: 2574.099999999997,
  },
  {
    timestamp_start: '2022-01-01T08:22:09',
    timestamp_stop: '2022-01-01T08:22:15',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 135598,
    tank_volume_start: 2574.099999999997,
    tank_volume_stop: 2511.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T08:22:30',
    timestamp_stop: '2022-01-01T08:22:35',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 135600,
    tank_volume_start: 2511.8999999999974,
    tank_volume_stop: 2449.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:22:50',
    timestamp_stop: '2022-01-01T08:22:56',
    quantity: 56.3,
    vehicle: 'DEM222',
    id: 135602,
    tank_volume_start: 2449.6999999999975,
    tank_volume_stop: 2391.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:23:11',
    timestamp_stop: '2022-01-01T08:23:16',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 135604,
    tank_volume_start: 2391.4999999999977,
    tank_volume_stop: 2334.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:23:31',
    timestamp_stop: '2022-01-01T08:23:36',
    quantity: 50.7,
    vehicle: 'DEM000',
    id: 135606,
    tank_volume_start: 2334.4999999999977,
    tank_volume_stop: 2277.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:23:51',
    timestamp_stop: '2022-01-01T08:23:57',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 135608,
    tank_volume_start: 2277.9999999999977,
    tank_volume_stop: 2220.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:24:12',
    timestamp_stop: '2022-01-01T08:24:18',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 135610,
    tank_volume_start: 2220.9999999999977,
    tank_volume_stop: 2166.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:24:33',
    timestamp_stop: '2022-01-01T08:24:38',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 135612,
    tank_volume_start: 2166.799999999998,
    tank_volume_stop: 2110.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:24:53',
    timestamp_stop: '2022-01-01T08:24:58',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 135614,
    tank_volume_start: 2110.4999999999977,
    tank_volume_stop: 2049.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:25:13',
    timestamp_stop: '2022-01-01T08:25:18',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 135616,
    tank_volume_start: 2049.399999999998,
    tank_volume_stop: 1995.6999999999978,
  },
  {
    timestamp_start: '2022-01-01T08:25:33',
    timestamp_stop: '2022-01-01T08:25:39',
    quantity: 62.1,
    vehicle: 'DEM777',
    id: 135618,
    tank_volume_start: 3995.699999999998,
    tank_volume_stop: 3948.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:25:54',
    timestamp_stop: '2022-01-01T08:26:00',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 135620,
    tank_volume_start: 3948.199999999998,
    tank_volume_stop: 3890.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:26:15',
    timestamp_stop: '2022-01-01T08:26:21',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 135622,
    tank_volume_start: 3890.399999999998,
    tank_volume_stop: 3835.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:26:36',
    timestamp_stop: '2022-01-01T08:26:40',
    quantity: 46.9,
    vehicle: 'DEM222',
    id: 135624,
    tank_volume_start: 3835.399999999998,
    tank_volume_stop: 3785.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:26:55',
    timestamp_stop: '2022-01-01T08:27:01',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 135626,
    tank_volume_start: 3785.699999999998,
    tank_volume_stop: 3725.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:27:16',
    timestamp_stop: '2022-01-01T08:27:21',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 135628,
    tank_volume_start: 3725.799999999998,
    tank_volume_stop: 3670.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:27:36',
    timestamp_stop: '2022-01-01T08:27:42',
    quantity: 61.0,
    vehicle: 'DEM333',
    id: 135630,
    tank_volume_start: 3670.9999999999977,
    tank_volume_stop: 3623.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:27:57',
    timestamp_stop: '2022-01-01T08:28:02',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 135632,
    tank_volume_start: 3623.0999999999976,
    tank_volume_stop: 3563.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:28:17',
    timestamp_stop: '2022-01-01T08:28:23',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 135634,
    tank_volume_start: 3563.6999999999975,
    tank_volume_stop: 3508.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:28:38',
    timestamp_stop: '2022-01-01T08:28:44',
    quantity: 61.2,
    vehicle: 'DEM333',
    id: 135636,
    tank_volume_start: 3508.0999999999976,
    tank_volume_stop: 3448.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:28:59',
    timestamp_stop: '2022-01-01T08:29:05',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 135638,
    tank_volume_start: 3448.899999999998,
    tank_volume_stop: 3394.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:29:20',
    timestamp_stop: '2022-01-01T08:29:26',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 135640,
    tank_volume_start: 3394.0999999999976,
    tank_volume_stop: 3348.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:29:41',
    timestamp_stop: '2022-01-01T08:29:46',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 135642,
    tank_volume_start: 3348.0999999999976,
    tank_volume_stop: 3287.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:30:01',
    timestamp_stop: '2022-01-01T08:30:07',
    quantity: 53.0,
    vehicle: 'DEM333',
    id: 135644,
    tank_volume_start: 3287.0999999999976,
    tank_volume_stop: 3231.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:30:22',
    timestamp_stop: '2022-01-01T08:30:27',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 135646,
    tank_volume_start: 3231.0999999999976,
    tank_volume_stop: 3178.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:30:42',
    timestamp_stop: '2022-01-01T08:30:47',
    quantity: 51.3,
    vehicle: 'DEM555',
    id: 135648,
    tank_volume_start: 3178.6999999999975,
    tank_volume_stop: 3114.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:31:02',
    timestamp_stop: '2022-01-01T08:31:07',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 135650,
    tank_volume_start: 3114.1999999999975,
    tank_volume_stop: 3055.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T08:31:22',
    timestamp_stop: '2022-01-01T08:31:28',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 135652,
    tank_volume_start: 3055.3999999999974,
    tank_volume_stop: 2994.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:31:43',
    timestamp_stop: '2022-01-01T08:31:48',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 135654,
    tank_volume_start: 2994.6999999999975,
    tank_volume_stop: 2948.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:32:03',
    timestamp_stop: '2022-01-01T08:32:08',
    quantity: 52.3,
    vehicle: 'DEM666',
    id: 135656,
    tank_volume_start: 2948.4999999999977,
    tank_volume_stop: 2887.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:32:23',
    timestamp_stop: '2022-01-01T08:32:29',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 135658,
    tank_volume_start: 2887.399999999998,
    tank_volume_stop: 2825.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:32:44',
    timestamp_stop: '2022-01-01T08:32:49',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 135660,
    tank_volume_start: 2825.799999999998,
    tank_volume_stop: 2773.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:33:04',
    timestamp_stop: '2022-01-01T08:33:10',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 135662,
    tank_volume_start: 2773.099999999998,
    tank_volume_stop: 2714.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:33:25',
    timestamp_stop: '2022-01-01T08:33:30',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 135664,
    tank_volume_start: 2714.499999999998,
    tank_volume_stop: 2663.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:33:45',
    timestamp_stop: '2022-01-01T08:33:51',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 135666,
    tank_volume_start: 2663.599999999998,
    tank_volume_stop: 2607.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:34:06',
    timestamp_stop: '2022-01-01T08:34:12',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 135668,
    tank_volume_start: 2607.699999999998,
    tank_volume_stop: 2554.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:34:27',
    timestamp_stop: '2022-01-01T08:34:33',
    quantity: 60.8,
    vehicle: 'DEM666',
    id: 135670,
    tank_volume_start: 2554.999999999998,
    tank_volume_stop: 2508.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T08:34:48',
    timestamp_stop: '2022-01-01T08:34:53',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 135672,
    tank_volume_start: 2508.2999999999984,
    tank_volume_stop: 2450.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:35:08',
    timestamp_stop: '2022-01-01T08:35:14',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 135674,
    tank_volume_start: 2450.3999999999983,
    tank_volume_stop: 2395.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:35:29',
    timestamp_stop: '2022-01-01T08:35:34',
    quantity: 47.7,
    vehicle: 'DEM777',
    id: 135676,
    tank_volume_start: 2395.599999999998,
    tank_volume_stop: 2340.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:35:49',
    timestamp_stop: '2022-01-01T08:35:54',
    quantity: 56.9,
    vehicle: 'DEM777',
    id: 135678,
    tank_volume_start: 2340.199999999998,
    tank_volume_stop: 2290.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:36:09',
    timestamp_stop: '2022-01-01T08:36:14',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 135680,
    tank_volume_start: 2290.699999999998,
    tank_volume_stop: 2228.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:36:29',
    timestamp_stop: '2022-01-01T08:36:35',
    quantity: 56.4,
    vehicle: 'DEM666',
    id: 135682,
    tank_volume_start: 2228.799999999998,
    tank_volume_stop: 2174.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:36:50',
    timestamp_stop: '2022-01-01T08:36:56',
    quantity: 59.9,
    vehicle: 'DEM444',
    id: 135684,
    tank_volume_start: 2174.4999999999977,
    tank_volume_stop: 2126.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:37:11',
    timestamp_stop: '2022-01-01T08:37:16',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 135686,
    tank_volume_start: 2126.5999999999976,
    tank_volume_stop: 2072.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:37:31',
    timestamp_stop: '2022-01-01T08:37:37',
    quantity: 59.5,
    vehicle: 'DEM777',
    id: 135688,
    tank_volume_start: 2072.9999999999977,
    tank_volume_stop: 2012.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:37:52',
    timestamp_stop: '2022-01-01T08:37:57',
    quantity: 50.9,
    vehicle: 'DEM333',
    id: 135690,
    tank_volume_start: 2012.9999999999977,
    tank_volume_stop: 1961.8999999999978,
  },
  {
    timestamp_start: '2022-01-01T08:38:12',
    timestamp_stop: '2022-01-01T08:38:18',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 135692,
    tank_volume_start: 3961.899999999998,
    tank_volume_stop: 3901.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:38:33',
    timestamp_stop: '2022-01-01T08:38:39',
    quantity: 61.4,
    vehicle: 'DEM333',
    id: 135694,
    tank_volume_start: 3901.899999999998,
    tank_volume_stop: 3837.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:38:54',
    timestamp_stop: '2022-01-01T08:39:00',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 135696,
    tank_volume_start: 3837.699999999998,
    tank_volume_stop: 3785.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:39:15',
    timestamp_stop: '2022-01-01T08:39:21',
    quantity: 56.7,
    vehicle: 'DEM555',
    id: 135698,
    tank_volume_start: 3785.099999999998,
    tank_volume_stop: 3730.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:39:36',
    timestamp_stop: '2022-01-01T08:39:42',
    quantity: 61.9,
    vehicle: 'DEM111',
    id: 135700,
    tank_volume_start: 3730.3999999999983,
    tank_volume_stop: 3675.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T08:39:57',
    timestamp_stop: '2022-01-01T08:40:03',
    quantity: 59.3,
    vehicle: 'DEM555',
    id: 135702,
    tank_volume_start: 3675.1999999999985,
    tank_volume_stop: 3614.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T08:40:18',
    timestamp_stop: '2022-01-01T08:40:22',
    quantity: 46.9,
    vehicle: 'DEM333',
    id: 135704,
    tank_volume_start: 3614.8999999999983,
    tank_volume_stop: 3560.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:40:37',
    timestamp_stop: '2022-01-01T08:40:43',
    quantity: 58.0,
    vehicle: 'DEM222',
    id: 135706,
    tank_volume_start: 3560.099999999998,
    tank_volume_stop: 3509.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:40:58',
    timestamp_stop: '2022-01-01T08:41:04',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 135708,
    tank_volume_start: 3509.699999999998,
    tank_volume_stop: 3454.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:41:19',
    timestamp_stop: '2022-01-01T08:41:23',
    quantity: 45.1,
    vehicle: 'DEM111',
    id: 135710,
    tank_volume_start: 3454.199999999998,
    tank_volume_stop: 3394.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:41:38',
    timestamp_stop: '2022-01-01T08:41:44',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 135712,
    tank_volume_start: 3394.899999999998,
    tank_volume_stop: 3341.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:41:59',
    timestamp_stop: '2022-01-01T08:42:04',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 135714,
    tank_volume_start: 3341.4999999999977,
    tank_volume_stop: 3281.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:42:19',
    timestamp_stop: '2022-01-01T08:42:25',
    quantity: 61.1,
    vehicle: 'DEM000',
    id: 135716,
    tank_volume_start: 3281.5999999999976,
    tank_volume_stop: 3221.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:42:40',
    timestamp_stop: '2022-01-01T08:42:45',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 135718,
    tank_volume_start: 3221.6999999999975,
    tank_volume_stop: 3162.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:43:00',
    timestamp_stop: '2022-01-01T08:43:06',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 135720,
    tank_volume_start: 3162.9999999999977,
    tank_volume_stop: 3109.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:43:21',
    timestamp_stop: '2022-01-01T08:43:26',
    quantity: 48.7,
    vehicle: 'DEM000',
    id: 135722,
    tank_volume_start: 3109.899999999998,
    tank_volume_stop: 3059.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:43:41',
    timestamp_stop: '2022-01-01T08:43:46',
    quantity: 57.1,
    vehicle: 'DEM555',
    id: 135724,
    tank_volume_start: 3059.899999999998,
    tank_volume_stop: 2999.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:44:01',
    timestamp_stop: '2022-01-01T08:44:07',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 135726,
    tank_volume_start: 2999.4999999999977,
    tank_volume_stop: 2944.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:44:22',
    timestamp_stop: '2022-01-01T08:44:27',
    quantity: 50.2,
    vehicle: 'DEM222',
    id: 135728,
    tank_volume_start: 2944.399999999998,
    tank_volume_stop: 2892.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:44:42',
    timestamp_stop: '2022-01-01T08:44:47',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 135730,
    tank_volume_start: 2892.9999999999977,
    tank_volume_stop: 2834.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T08:45:02',
    timestamp_stop: '2022-01-01T08:45:08',
    quantity: 59.4,
    vehicle: 'DEM666',
    id: 135732,
    tank_volume_start: 2834.4999999999977,
    tank_volume_stop: 2783.299999999998,
  },
  {
    timestamp_start: '2022-01-01T08:45:23',
    timestamp_stop: '2022-01-01T08:45:29',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 135734,
    tank_volume_start: 2783.299999999998,
    tank_volume_stop: 2729.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:45:44',
    timestamp_stop: '2022-01-01T08:45:50',
    quantity: 52.2,
    vehicle: 'DEM777',
    id: 135736,
    tank_volume_start: 2729.899999999998,
    tank_volume_stop: 2668.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:46:05',
    timestamp_stop: '2022-01-01T08:46:10',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 135738,
    tank_volume_start: 2668.899999999998,
    tank_volume_stop: 2616.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:46:25',
    timestamp_stop: '2022-01-01T08:46:31',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 135740,
    tank_volume_start: 2616.799999999998,
    tank_volume_stop: 2560.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:46:46',
    timestamp_stop: '2022-01-01T08:46:51',
    quantity: 48.1,
    vehicle: 'DEM111',
    id: 135742,
    tank_volume_start: 2560.899999999998,
    tank_volume_stop: 2502.399999999998,
  },
  {
    timestamp_start: '2022-01-01T08:47:06',
    timestamp_stop: '2022-01-01T08:47:12',
    quantity: 60.6,
    vehicle: 'DEM222',
    id: 135744,
    tank_volume_start: 2502.399999999998,
    tank_volume_stop: 2451.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:47:27',
    timestamp_stop: '2022-01-01T08:47:32',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 135746,
    tank_volume_start: 2451.199999999998,
    tank_volume_stop: 2396.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:47:47',
    timestamp_stop: '2022-01-01T08:47:52',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 135748,
    tank_volume_start: 2396.499999999998,
    tank_volume_stop: 2342.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:48:07',
    timestamp_stop: '2022-01-01T08:48:13',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 135750,
    tank_volume_start: 2342.599999999998,
    tank_volume_stop: 2283.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:48:28',
    timestamp_stop: '2022-01-01T08:48:33',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 135752,
    tank_volume_start: 2283.599999999998,
    tank_volume_stop: 2223.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:48:48',
    timestamp_stop: '2022-01-01T08:48:54',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 135754,
    tank_volume_start: 2223.099999999998,
    tank_volume_stop: 2171.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:49:09',
    timestamp_stop: '2022-01-01T08:49:14',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 135756,
    tank_volume_start: 2171.799999999998,
    tank_volume_stop: 2116.299999999998,
  },
  {
    timestamp_start: '2022-01-01T08:49:29',
    timestamp_stop: '2022-01-01T08:49:35',
    quantity: 52.1,
    vehicle: 'DEM777',
    id: 135758,
    tank_volume_start: 2116.299999999998,
    tank_volume_stop: 2056.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:49:50',
    timestamp_stop: '2022-01-01T08:49:56',
    quantity: 63.6,
    vehicle: 'DEM222',
    id: 135760,
    tank_volume_start: 2056.799999999998,
    tank_volume_stop: 1997.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:50:11',
    timestamp_stop: '2022-01-01T08:50:16',
    quantity: 49.4,
    vehicle: 'DEM888',
    id: 135762,
    tank_volume_start: 3997.999999999998,
    tank_volume_stop: 3937.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:50:31',
    timestamp_stop: '2022-01-01T08:50:37',
    quantity: 62.6,
    vehicle: 'DEM444',
    id: 135764,
    tank_volume_start: 3937.599999999998,
    tank_volume_stop: 3882.799999999998,
  },
  {
    timestamp_start: '2022-01-01T08:50:52',
    timestamp_stop: '2022-01-01T08:50:57',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 135766,
    tank_volume_start: 3882.799999999998,
    tank_volume_stop: 3821.699999999998,
  },
  {
    timestamp_start: '2022-01-01T08:51:12',
    timestamp_stop: '2022-01-01T08:51:18',
    quantity: 53.5,
    vehicle: 'DEM000',
    id: 135768,
    tank_volume_start: 3821.699999999998,
    tank_volume_stop: 3762.499999999998,
  },
  {
    timestamp_start: '2022-01-01T08:51:33',
    timestamp_stop: '2022-01-01T08:51:39',
    quantity: 60.5,
    vehicle: 'DEM000',
    id: 135770,
    tank_volume_start: 3762.499999999998,
    tank_volume_stop: 3711.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:51:54',
    timestamp_stop: '2022-01-01T08:51:59',
    quantity: 48.8,
    vehicle: 'DEM111',
    id: 135772,
    tank_volume_start: 3711.999999999998,
    tank_volume_stop: 3659.999999999998,
  },
  {
    timestamp_start: '2022-01-01T08:52:14',
    timestamp_stop: '2022-01-01T08:52:19',
    quantity: 49.2,
    vehicle: 'DEM333',
    id: 135774,
    tank_volume_start: 3659.999999999998,
    tank_volume_stop: 3600.599999999998,
  },
  {
    timestamp_start: '2022-01-01T08:52:34',
    timestamp_stop: '2022-01-01T08:52:39',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 135776,
    tank_volume_start: 3600.599999999998,
    tank_volume_stop: 3541.099999999998,
  },
  {
    timestamp_start: '2022-01-01T08:52:54',
    timestamp_stop: '2022-01-01T08:53:00',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 135778,
    tank_volume_start: 3541.099999999998,
    tank_volume_stop: 3484.199999999998,
  },
  {
    timestamp_start: '2022-01-01T08:53:15',
    timestamp_stop: '2022-01-01T08:53:20',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 135780,
    tank_volume_start: 3484.199999999998,
    tank_volume_stop: 3424.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:53:35',
    timestamp_stop: '2022-01-01T08:53:40',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 135782,
    tank_volume_start: 3424.899999999998,
    tank_volume_stop: 3370.899999999998,
  },
  {
    timestamp_start: '2022-01-01T08:53:55',
    timestamp_stop: '2022-01-01T08:54:00',
    quantity: 48.8,
    vehicle: 'DEM666',
    id: 135784,
    tank_volume_start: 3370.899999999998,
    tank_volume_stop: 3314.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:54:15',
    timestamp_stop: '2022-01-01T08:54:21',
    quantity: 59.0,
    vehicle: 'DEM555',
    id: 135786,
    tank_volume_start: 3314.5999999999976,
    tank_volume_stop: 3254.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:54:36',
    timestamp_stop: '2022-01-01T08:54:41',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 135788,
    tank_volume_start: 3254.1999999999975,
    tank_volume_stop: 3200.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:54:56',
    timestamp_stop: '2022-01-01T08:55:01',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 135790,
    tank_volume_start: 3200.0999999999976,
    tank_volume_stop: 3149.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:55:16',
    timestamp_stop: '2022-01-01T08:55:21',
    quantity: 51.1,
    vehicle: 'DEM888',
    id: 135792,
    tank_volume_start: 3149.5999999999976,
    tank_volume_stop: 3095.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T08:55:36',
    timestamp_stop: '2022-01-01T08:55:42',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 135794,
    tank_volume_start: 3095.0999999999976,
    tank_volume_stop: 3031.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:55:57',
    timestamp_stop: '2022-01-01T08:56:01',
    quantity: 45.5,
    vehicle: 'DEM666',
    id: 135796,
    tank_volume_start: 3031.1999999999975,
    tank_volume_stop: 2981.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:56:16',
    timestamp_stop: '2022-01-01T08:56:22',
    quantity: 61.5,
    vehicle: 'DEM444',
    id: 135798,
    tank_volume_start: 2981.2999999999975,
    tank_volume_stop: 2934.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:56:37',
    timestamp_stop: '2022-01-01T08:56:43',
    quantity: 61.4,
    vehicle: 'DEM888',
    id: 135800,
    tank_volume_start: 2934.2999999999975,
    tank_volume_stop: 2875.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:56:58',
    timestamp_stop: '2022-01-01T08:57:04',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 135802,
    tank_volume_start: 2875.7999999999975,
    tank_volume_stop: 2820.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T08:57:19',
    timestamp_stop: '2022-01-01T08:57:24',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 135804,
    tank_volume_start: 2820.4999999999973,
    tank_volume_stop: 2759.099999999997,
  },
  {
    timestamp_start: '2022-01-01T08:57:39',
    timestamp_stop: '2022-01-01T08:57:45',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 135806,
    tank_volume_start: 2759.099999999997,
    tank_volume_stop: 2698.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T08:58:00',
    timestamp_stop: '2022-01-01T08:58:05',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 135808,
    tank_volume_start: 2698.8999999999974,
    tank_volume_stop: 2641.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T08:58:20',
    timestamp_stop: '2022-01-01T08:58:25',
    quantity: 49.4,
    vehicle: 'DEM222',
    id: 135810,
    tank_volume_start: 2641.7999999999975,
    tank_volume_stop: 2588.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T08:58:40',
    timestamp_stop: '2022-01-01T08:58:45',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 135812,
    tank_volume_start: 2588.8999999999974,
    tank_volume_stop: 2524.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T08:59:00',
    timestamp_stop: '2022-01-01T08:59:06',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 135814,
    tank_volume_start: 2524.9999999999973,
    tank_volume_stop: 2471.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T08:59:21',
    timestamp_stop: '2022-01-01T08:59:26',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 135816,
    tank_volume_start: 2471.4999999999973,
    tank_volume_stop: 2420.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T08:59:41',
    timestamp_stop: '2022-01-01T08:59:47',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 135818,
    tank_volume_start: 2420.9999999999973,
    tank_volume_stop: 2359.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:00:02',
    timestamp_stop: '2022-01-01T09:00:08',
    quantity: 61.3,
    vehicle: 'DEM444',
    id: 135820,
    tank_volume_start: 2359.4999999999973,
    tank_volume_stop: 2304.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:00:23',
    timestamp_stop: '2022-01-01T09:00:29',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 135822,
    tank_volume_start: 2304.2999999999975,
    tank_volume_stop: 2244.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:00:44',
    timestamp_stop: '2022-01-01T09:00:49',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 135824,
    tank_volume_start: 2244.3999999999974,
    tank_volume_stop: 2191.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:01:04',
    timestamp_stop: '2022-01-01T09:01:10',
    quantity: 64.0,
    vehicle: 'DEM555',
    id: 135826,
    tank_volume_start: 2191.099999999997,
    tank_volume_stop: 2128.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:01:25',
    timestamp_stop: '2022-01-01T09:01:31',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 135828,
    tank_volume_start: 2128.099999999997,
    tank_volume_stop: 2081.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:01:46',
    timestamp_stop: '2022-01-01T09:01:52',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 135830,
    tank_volume_start: 2081.199999999997,
    tank_volume_stop: 2027.299999999997,
  },
  {
    timestamp_start: '2022-01-01T09:02:07',
    timestamp_stop: '2022-01-01T09:02:12',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 135832,
    tank_volume_start: 2027.299999999997,
    tank_volume_stop: 1977.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:02:27',
    timestamp_stop: '2022-01-01T09:02:33',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 135834,
    tank_volume_start: 3977.399999999997,
    tank_volume_stop: 3919.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:02:48',
    timestamp_stop: '2022-01-01T09:02:53',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 135836,
    tank_volume_start: 3919.399999999997,
    tank_volume_stop: 3865.299999999997,
  },
  {
    timestamp_start: '2022-01-01T09:03:08',
    timestamp_stop: '2022-01-01T09:03:13',
    quantity: 50.7,
    vehicle: 'DEM777',
    id: 135838,
    tank_volume_start: 3865.299999999997,
    tank_volume_stop: 3807.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:03:28',
    timestamp_stop: '2022-01-01T09:03:33',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 135840,
    tank_volume_start: 3807.199999999997,
    tank_volume_stop: 3757.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:03:48',
    timestamp_stop: '2022-01-01T09:03:53',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 135842,
    tank_volume_start: 3757.199999999997,
    tank_volume_stop: 3708.799999999997,
  },
  {
    timestamp_start: '2022-01-01T09:04:08',
    timestamp_stop: '2022-01-01T09:04:15',
    quantity: 61.8,
    vehicle: 'DEM222',
    id: 135844,
    tank_volume_start: 3708.799999999997,
    tank_volume_stop: 3661.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:04:30',
    timestamp_stop: '2022-01-01T09:04:35',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 135846,
    tank_volume_start: 3661.099999999997,
    tank_volume_stop: 3606.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:04:50',
    timestamp_stop: '2022-01-01T09:04:56',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 135848,
    tank_volume_start: 3606.8999999999974,
    tank_volume_stop: 3555.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:05:11',
    timestamp_stop: '2022-01-01T09:05:17',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 135850,
    tank_volume_start: 3555.599999999997,
    tank_volume_stop: 3503.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:05:32',
    timestamp_stop: '2022-01-01T09:05:36',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 135852,
    tank_volume_start: 3503.199999999997,
    tank_volume_stop: 3452.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:05:51',
    timestamp_stop: '2022-01-01T09:05:57',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 135854,
    tank_volume_start: 3452.099999999997,
    tank_volume_stop: 3398.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:06:12',
    timestamp_stop: '2022-01-01T09:06:18',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 135856,
    tank_volume_start: 3398.9999999999973,
    tank_volume_stop: 3349.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:06:33',
    timestamp_stop: '2022-01-01T09:06:38',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 135858,
    tank_volume_start: 3349.099999999997,
    tank_volume_stop: 3298.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:06:53',
    timestamp_stop: '2022-01-01T09:06:58',
    quantity: 45.6,
    vehicle: 'DEM888',
    id: 135860,
    tank_volume_start: 3298.599999999997,
    tank_volume_stop: 3246.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:07:13',
    timestamp_stop: '2022-01-01T09:07:18',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 135862,
    tank_volume_start: 3246.199999999997,
    tank_volume_stop: 3195.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:07:33',
    timestamp_stop: '2022-01-01T09:07:39',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 135864,
    tank_volume_start: 3195.599999999997,
    tank_volume_stop: 3143.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:07:54',
    timestamp_stop: '2022-01-01T09:07:59',
    quantity: 52.3,
    vehicle: 'DEM666',
    id: 135866,
    tank_volume_start: 3143.599999999997,
    tank_volume_stop: 3081.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:08:14',
    timestamp_stop: '2022-01-01T09:08:19',
    quantity: 50.8,
    vehicle: 'DEM666',
    id: 135868,
    tank_volume_start: 3081.8999999999974,
    tank_volume_stop: 3023.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:08:34',
    timestamp_stop: '2022-01-01T09:08:39',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 135870,
    tank_volume_start: 3023.1999999999975,
    tank_volume_stop: 2970.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:08:54',
    timestamp_stop: '2022-01-01T09:09:00',
    quantity: 55.1,
    vehicle: 'DEM444',
    id: 135872,
    tank_volume_start: 2970.7999999999975,
    tank_volume_stop: 2923.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:09:15',
    timestamp_stop: '2022-01-01T09:09:20',
    quantity: 49.8,
    vehicle: 'DEM222',
    id: 135874,
    tank_volume_start: 2923.9999999999973,
    tank_volume_stop: 2870.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:09:35',
    timestamp_stop: '2022-01-01T09:09:40',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 135876,
    tank_volume_start: 2870.2999999999975,
    tank_volume_stop: 2811.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:09:55',
    timestamp_stop: '2022-01-01T09:09:59',
    quantity: 47.0,
    vehicle: 'DEM333',
    id: 135878,
    tank_volume_start: 2811.4999999999973,
    tank_volume_stop: 2758.699999999997,
  },
  {
    timestamp_start: '2022-01-01T09:10:14',
    timestamp_stop: '2022-01-01T09:10:21',
    quantity: 63.8,
    vehicle: 'DEM111',
    id: 135880,
    tank_volume_start: 2758.699999999997,
    tank_volume_stop: 2701.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:10:36',
    timestamp_stop: '2022-01-01T09:10:42',
    quantity: 58.5,
    vehicle: 'DEM888',
    id: 135882,
    tank_volume_start: 2701.599999999997,
    tank_volume_stop: 2645.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:10:57',
    timestamp_stop: '2022-01-01T09:11:02',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 135884,
    tank_volume_start: 2645.599999999997,
    tank_volume_stop: 2584.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:11:17',
    timestamp_stop: '2022-01-01T09:11:22',
    quantity: 46.6,
    vehicle: 'DEM444',
    id: 135886,
    tank_volume_start: 2584.3999999999974,
    tank_volume_stop: 2520.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:11:37',
    timestamp_stop: '2022-01-01T09:11:43',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 135888,
    tank_volume_start: 2520.6999999999975,
    tank_volume_stop: 2465.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:11:58',
    timestamp_stop: '2022-01-01T09:12:03',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 135890,
    tank_volume_start: 2465.1999999999975,
    tank_volume_stop: 2409.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:12:18',
    timestamp_stop: '2022-01-01T09:12:24',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 135892,
    tank_volume_start: 2409.1999999999975,
    tank_volume_stop: 2351.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:12:39',
    timestamp_stop: '2022-01-01T09:12:45',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 135894,
    tank_volume_start: 2351.3999999999974,
    tank_volume_stop: 2300.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:13:00',
    timestamp_stop: '2022-01-01T09:13:06',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 135896,
    tank_volume_start: 2300.4999999999973,
    tank_volume_stop: 2247.699999999997,
  },
  {
    timestamp_start: '2022-01-01T09:13:21',
    timestamp_stop: '2022-01-01T09:13:27',
    quantity: 63.1,
    vehicle: 'DEM555',
    id: 135898,
    tank_volume_start: 2247.699999999997,
    tank_volume_stop: 2193.299999999997,
  },
  {
    timestamp_start: '2022-01-01T09:13:42',
    timestamp_stop: '2022-01-01T09:13:48',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 135900,
    tank_volume_start: 2193.299999999997,
    tank_volume_stop: 2140.499999999997,
  },
  {
    timestamp_start: '2022-01-01T09:14:03',
    timestamp_stop: '2022-01-01T09:14:08',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 135902,
    tank_volume_start: 2140.499999999997,
    tank_volume_stop: 2078.899999999997,
  },
  {
    timestamp_start: '2022-01-01T09:14:23',
    timestamp_stop: '2022-01-01T09:14:28',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 135904,
    tank_volume_start: 2078.899999999997,
    tank_volume_stop: 2022.299999999997,
  },
  {
    timestamp_start: '2022-01-01T09:14:43',
    timestamp_stop: '2022-01-01T09:14:48',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 135906,
    tank_volume_start: 2022.299999999997,
    tank_volume_stop: 1967.899999999997,
  },
  {
    timestamp_start: '2022-01-01T09:15:03',
    timestamp_stop: '2022-01-01T09:15:08',
    quantity: 49.1,
    vehicle: 'DEM666',
    id: 135908,
    tank_volume_start: 3967.899999999997,
    tank_volume_stop: 3921.999999999997,
  },
  {
    timestamp_start: '2022-01-01T09:15:23',
    timestamp_stop: '2022-01-01T09:15:29',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 135910,
    tank_volume_start: 3921.999999999997,
    tank_volume_stop: 3862.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:15:44',
    timestamp_stop: '2022-01-01T09:15:49',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 135912,
    tank_volume_start: 3862.399999999997,
    tank_volume_stop: 3811.799999999997,
  },
  {
    timestamp_start: '2022-01-01T09:16:04',
    timestamp_stop: '2022-01-01T09:16:09',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 135914,
    tank_volume_start: 3811.799999999997,
    tank_volume_stop: 3763.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:16:24',
    timestamp_stop: '2022-01-01T09:16:30',
    quantity: 56.6,
    vehicle: 'DEM000',
    id: 135916,
    tank_volume_start: 3763.399999999997,
    tank_volume_stop: 3706.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:16:45',
    timestamp_stop: '2022-01-01T09:16:50',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 135918,
    tank_volume_start: 3706.0999999999967,
    tank_volume_stop: 3654.899999999997,
  },
  {
    timestamp_start: '2022-01-01T09:17:05',
    timestamp_stop: '2022-01-01T09:17:10',
    quantity: 48.3,
    vehicle: 'DEM777',
    id: 135920,
    tank_volume_start: 3654.899999999997,
    tank_volume_stop: 3607.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:17:25',
    timestamp_stop: '2022-01-01T09:17:30',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 135922,
    tank_volume_start: 3607.5999999999967,
    tank_volume_stop: 3557.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:17:45',
    timestamp_stop: '2022-01-01T09:17:51',
    quantity: 56.7,
    vehicle: 'DEM000',
    id: 135924,
    tank_volume_start: 3557.2999999999965,
    tank_volume_stop: 3501.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:18:06',
    timestamp_stop: '2022-01-01T09:18:11',
    quantity: 50.2,
    vehicle: 'DEM222',
    id: 135926,
    tank_volume_start: 3501.5999999999967,
    tank_volume_stop: 3446.499999999997,
  },
  {
    timestamp_start: '2022-01-01T09:18:26',
    timestamp_stop: '2022-01-01T09:18:32',
    quantity: 57.9,
    vehicle: 'DEM111',
    id: 135928,
    tank_volume_start: 3446.499999999997,
    tank_volume_stop: 3394.999999999997,
  },
  {
    timestamp_start: '2022-01-01T09:18:47',
    timestamp_stop: '2022-01-01T09:18:51',
    quantity: 48.0,
    vehicle: 'DEM444',
    id: 135930,
    tank_volume_start: 3394.999999999997,
    tank_volume_stop: 3341.899999999997,
  },
  {
    timestamp_start: '2022-01-01T09:19:06',
    timestamp_stop: '2022-01-01T09:19:12',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 135932,
    tank_volume_start: 3341.899999999997,
    tank_volume_stop: 3287.899999999997,
  },
  {
    timestamp_start: '2022-01-01T09:19:27',
    timestamp_stop: '2022-01-01T09:19:33',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 135934,
    tank_volume_start: 3287.899999999997,
    tank_volume_stop: 3242.699999999997,
  },
  {
    timestamp_start: '2022-01-01T09:19:48',
    timestamp_stop: '2022-01-01T09:19:53',
    quantity: 50.5,
    vehicle: 'DEM000',
    id: 135936,
    tank_volume_start: 3242.699999999997,
    tank_volume_stop: 3196.099999999997,
  },
  {
    timestamp_start: '2022-01-01T09:20:08',
    timestamp_stop: '2022-01-01T09:20:14',
    quantity: 60.8,
    vehicle: 'DEM333',
    id: 135938,
    tank_volume_start: 3196.099999999997,
    tank_volume_stop: 3148.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:20:29',
    timestamp_stop: '2022-01-01T09:20:33',
    quantity: 46.7,
    vehicle: 'DEM777',
    id: 135940,
    tank_volume_start: 3148.199999999997,
    tank_volume_stop: 3094.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:20:48',
    timestamp_stop: '2022-01-01T09:20:54',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 135942,
    tank_volume_start: 3094.599999999997,
    tank_volume_stop: 3037.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:21:09',
    timestamp_stop: '2022-01-01T09:21:15',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 135944,
    tank_volume_start: 3037.9999999999973,
    tank_volume_stop: 2979.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:21:30',
    timestamp_stop: '2022-01-01T09:21:35',
    quantity: 51.6,
    vehicle: 'DEM333',
    id: 135946,
    tank_volume_start: 2979.8999999999974,
    tank_volume_stop: 2922.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:21:50',
    timestamp_stop: '2022-01-01T09:21:56',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 135948,
    tank_volume_start: 2922.6999999999975,
    tank_volume_stop: 2866.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:22:11',
    timestamp_stop: '2022-01-01T09:22:17',
    quantity: 60.5,
    vehicle: 'DEM777',
    id: 135950,
    tank_volume_start: 2866.6999999999975,
    tank_volume_stop: 2814.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:22:32',
    timestamp_stop: '2022-01-01T09:22:37',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 135952,
    tank_volume_start: 2814.1999999999975,
    tank_volume_stop: 2755.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:22:52',
    timestamp_stop: '2022-01-01T09:22:57',
    quantity: 49.4,
    vehicle: 'DEM444',
    id: 135954,
    tank_volume_start: 2755.7999999999975,
    tank_volume_stop: 2703.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:23:12',
    timestamp_stop: '2022-01-01T09:23:19',
    quantity: 64.7,
    vehicle: 'DEM111',
    id: 135956,
    tank_volume_start: 2703.6999999999975,
    tank_volume_stop: 2645.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:23:34',
    timestamp_stop: '2022-01-01T09:23:39',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 135958,
    tank_volume_start: 2645.2999999999975,
    tank_volume_stop: 2593.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:23:54',
    timestamp_stop: '2022-01-01T09:24:01',
    quantity: 63.6,
    vehicle: 'DEM666',
    id: 135960,
    tank_volume_start: 2593.6999999999975,
    tank_volume_stop: 2539.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T09:24:16',
    timestamp_stop: '2022-01-01T09:24:21',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 135962,
    tank_volume_start: 2539.8999999999974,
    tank_volume_stop: 2484.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:24:36',
    timestamp_stop: '2022-01-01T09:24:41',
    quantity: 55.5,
    vehicle: 'DEM555',
    id: 135964,
    tank_volume_start: 2484.599999999997,
    tank_volume_stop: 2432.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:24:56',
    timestamp_stop: '2022-01-01T09:25:02',
    quantity: 55.6,
    vehicle: 'DEM555',
    id: 135966,
    tank_volume_start: 2432.9999999999973,
    tank_volume_stop: 2384.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T09:25:17',
    timestamp_stop: '2022-01-01T09:25:23',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 135968,
    tank_volume_start: 2384.2999999999975,
    tank_volume_stop: 2338.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:25:38',
    timestamp_stop: '2022-01-01T09:25:43',
    quantity: 53.6,
    vehicle: 'DEM666',
    id: 135970,
    tank_volume_start: 2338.4999999999973,
    tank_volume_stop: 2278.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T09:25:58',
    timestamp_stop: '2022-01-01T09:26:04',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 135972,
    tank_volume_start: 2278.9999999999973,
    tank_volume_stop: 2221.199999999997,
  },
  {
    timestamp_start: '2022-01-01T09:26:19',
    timestamp_stop: '2022-01-01T09:26:24',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 135974,
    tank_volume_start: 2221.199999999997,
    tank_volume_stop: 2162.799999999997,
  },
  {
    timestamp_start: '2022-01-01T09:26:39',
    timestamp_stop: '2022-01-01T09:26:44',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 135976,
    tank_volume_start: 2162.799999999997,
    tank_volume_stop: 2108.499999999997,
  },
  {
    timestamp_start: '2022-01-01T09:26:59',
    timestamp_stop: '2022-01-01T09:27:05',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 135978,
    tank_volume_start: 2108.499999999997,
    tank_volume_stop: 2053.799999999997,
  },
  {
    timestamp_start: '2022-01-01T09:27:20',
    timestamp_stop: '2022-01-01T09:27:25',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 135980,
    tank_volume_start: 2053.799999999997,
    tank_volume_stop: 1996.599999999997,
  },
  {
    timestamp_start: '2022-01-01T09:27:40',
    timestamp_stop: '2022-01-01T09:27:46',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 135982,
    tank_volume_start: 3996.5999999999967,
    tank_volume_stop: 3946.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T09:28:01',
    timestamp_stop: '2022-01-01T09:28:07',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 135984,
    tank_volume_start: 3946.1999999999966,
    tank_volume_stop: 3893.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:28:22',
    timestamp_stop: '2022-01-01T09:28:28',
    quantity: 62.1,
    vehicle: 'DEM888',
    id: 135986,
    tank_volume_start: 3893.7999999999965,
    tank_volume_stop: 3831.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T09:28:43',
    timestamp_stop: '2022-01-01T09:28:49',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 135988,
    tank_volume_start: 3831.1999999999966,
    tank_volume_stop: 3767.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:29:04',
    timestamp_stop: '2022-01-01T09:29:09',
    quantity: 52.3,
    vehicle: 'DEM444',
    id: 135990,
    tank_volume_start: 3767.8999999999965,
    tank_volume_stop: 3713.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:29:24',
    timestamp_stop: '2022-01-01T09:29:30',
    quantity: 64.1,
    vehicle: 'DEM555',
    id: 135992,
    tank_volume_start: 3713.5999999999963,
    tank_volume_stop: 3659.199999999996,
  },
  {
    timestamp_start: '2022-01-01T09:29:45',
    timestamp_stop: '2022-01-01T09:29:51',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 135994,
    tank_volume_start: 3659.199999999996,
    tank_volume_stop: 3607.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:30:06',
    timestamp_stop: '2022-01-01T09:30:12',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 135996,
    tank_volume_start: 3607.0999999999963,
    tank_volume_stop: 3549.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T09:30:27',
    timestamp_stop: '2022-01-01T09:30:33',
    quantity: 57.9,
    vehicle: 'DEM777',
    id: 135998,
    tank_volume_start: 3549.4999999999964,
    tank_volume_stop: 3492.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:30:48',
    timestamp_stop: '2022-01-01T09:30:54',
    quantity: 63.5,
    vehicle: 'DEM222',
    id: 136000,
    tank_volume_start: 3492.5999999999963,
    tank_volume_stop: 3440.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T09:31:09',
    timestamp_stop: '2022-01-01T09:31:14',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 136002,
    tank_volume_start: 3440.4999999999964,
    tank_volume_stop: 3381.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:31:29',
    timestamp_stop: '2022-01-01T09:31:35',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 136004,
    tank_volume_start: 3381.2999999999965,
    tank_volume_stop: 3329.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T09:31:50',
    timestamp_stop: '2022-01-01T09:31:55',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 136006,
    tank_volume_start: 3329.6999999999966,
    tank_volume_stop: 3279.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T09:32:10',
    timestamp_stop: '2022-01-01T09:32:16',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 136008,
    tank_volume_start: 3279.6999999999966,
    tank_volume_stop: 3224.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:32:31',
    timestamp_stop: '2022-01-01T09:32:36',
    quantity: 45.3,
    vehicle: 'DEM888',
    id: 136010,
    tank_volume_start: 3224.5999999999967,
    tank_volume_stop: 3177.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:32:51',
    timestamp_stop: '2022-01-01T09:32:55',
    quantity: 46.2,
    vehicle: 'DEM333',
    id: 136012,
    tank_volume_start: 3177.399999999997,
    tank_volume_stop: 3116.299999999997,
  },
  {
    timestamp_start: '2022-01-01T09:33:10',
    timestamp_stop: '2022-01-01T09:33:15',
    quantity: 49.9,
    vehicle: 'DEM333',
    id: 136014,
    tank_volume_start: 3116.299999999997,
    tank_volume_stop: 3053.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:33:30',
    timestamp_stop: '2022-01-01T09:33:35',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 136016,
    tank_volume_start: 3053.399999999997,
    tank_volume_stop: 2997.999999999997,
  },
  {
    timestamp_start: '2022-01-01T09:33:50',
    timestamp_stop: '2022-01-01T09:33:56',
    quantity: 59.1,
    vehicle: 'DEM555',
    id: 136018,
    tank_volume_start: 2997.999999999997,
    tank_volume_stop: 2944.399999999997,
  },
  {
    timestamp_start: '2022-01-01T09:34:11',
    timestamp_stop: '2022-01-01T09:34:16',
    quantity: 49.9,
    vehicle: 'DEM777',
    id: 136020,
    tank_volume_start: 2944.399999999997,
    tank_volume_stop: 2889.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:34:31',
    timestamp_stop: '2022-01-01T09:34:37',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 136022,
    tank_volume_start: 2889.5999999999967,
    tank_volume_stop: 2832.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:34:52',
    timestamp_stop: '2022-01-01T09:34:57',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 136024,
    tank_volume_start: 2832.0999999999967,
    tank_volume_stop: 2777.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:35:12',
    timestamp_stop: '2022-01-01T09:35:18',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 136026,
    tank_volume_start: 2777.5999999999967,
    tank_volume_stop: 2722.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:35:33',
    timestamp_stop: '2022-01-01T09:35:39',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 136028,
    tank_volume_start: 2722.0999999999967,
    tank_volume_stop: 2666.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:35:54',
    timestamp_stop: '2022-01-01T09:35:59',
    quantity: 54.4,
    vehicle: 'DEM888',
    id: 136030,
    tank_volume_start: 2666.0999999999967,
    tank_volume_stop: 2615.499999999997,
  },
  {
    timestamp_start: '2022-01-01T09:36:14',
    timestamp_stop: '2022-01-01T09:36:19',
    quantity: 53.1,
    vehicle: 'DEM333',
    id: 136032,
    tank_volume_start: 2615.499999999997,
    tank_volume_stop: 2569.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T09:36:34',
    timestamp_stop: '2022-01-01T09:36:40',
    quantity: 59.0,
    vehicle: 'DEM333',
    id: 136034,
    tank_volume_start: 2569.0999999999967,
    tank_volume_stop: 2518.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:36:55',
    timestamp_stop: '2022-01-01T09:37:01',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 136036,
    tank_volume_start: 2518.7999999999965,
    tank_volume_stop: 2463.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:37:16',
    timestamp_stop: '2022-01-01T09:37:21',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 136038,
    tank_volume_start: 2463.8999999999965,
    tank_volume_stop: 2411.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T09:37:36',
    timestamp_stop: '2022-01-01T09:37:41',
    quantity: 46.9,
    vehicle: 'DEM666',
    id: 136040,
    tank_volume_start: 2411.4999999999964,
    tank_volume_stop: 2358.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T09:37:56',
    timestamp_stop: '2022-01-01T09:38:02',
    quantity: 61.2,
    vehicle: 'DEM666',
    id: 136042,
    tank_volume_start: 2358.4999999999964,
    tank_volume_stop: 2306.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:38:17',
    timestamp_stop: '2022-01-01T09:38:23',
    quantity: 56.1,
    vehicle: 'DEM444',
    id: 136044,
    tank_volume_start: 2306.7999999999965,
    tank_volume_stop: 2247.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:38:38',
    timestamp_stop: '2022-01-01T09:38:44',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 136046,
    tank_volume_start: 2247.8999999999965,
    tank_volume_stop: 2191.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:38:59',
    timestamp_stop: '2022-01-01T09:39:05',
    quantity: 61.9,
    vehicle: 'DEM111',
    id: 136048,
    tank_volume_start: 2191.8999999999965,
    tank_volume_stop: 2138.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T09:39:20',
    timestamp_stop: '2022-01-01T09:39:24',
    quantity: 45.5,
    vehicle: 'DEM222',
    id: 136050,
    tank_volume_start: 2138.9999999999964,
    tank_volume_stop: 2086.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:39:39',
    timestamp_stop: '2022-01-01T09:39:45',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 136052,
    tank_volume_start: 2086.2999999999965,
    tank_volume_stop: 2032.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:40:00',
    timestamp_stop: '2022-01-01T09:40:06',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 136054,
    tank_volume_start: 2032.3999999999965,
    tank_volume_stop: 1968.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T09:40:21',
    timestamp_stop: '2022-01-01T09:40:27',
    quantity: 63.7,
    vehicle: 'DEM222',
    id: 136056,
    tank_volume_start: 3968.3999999999965,
    tank_volume_stop: 3917.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:40:42',
    timestamp_stop: '2022-01-01T09:40:48',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 136058,
    tank_volume_start: 3917.0999999999963,
    tank_volume_stop: 3868.799999999996,
  },
  {
    timestamp_start: '2022-01-01T09:41:03',
    timestamp_stop: '2022-01-01T09:41:08',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 136060,
    tank_volume_start: 3868.799999999996,
    tank_volume_stop: 3815.999999999996,
  },
  {
    timestamp_start: '2022-01-01T09:41:23',
    timestamp_stop: '2022-01-01T09:41:28',
    quantity: 47.5,
    vehicle: 'DEM555',
    id: 136062,
    tank_volume_start: 3815.999999999996,
    tank_volume_stop: 3761.899999999996,
  },
  {
    timestamp_start: '2022-01-01T09:41:43',
    timestamp_stop: '2022-01-01T09:41:49',
    quantity: 61.7,
    vehicle: 'DEM777',
    id: 136064,
    tank_volume_start: 3761.899999999996,
    tank_volume_stop: 3707.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:42:04',
    timestamp_stop: '2022-01-01T09:42:09',
    quantity: 49.8,
    vehicle: 'DEM111',
    id: 136066,
    tank_volume_start: 3707.599999999996,
    tank_volume_stop: 3647.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:42:24',
    timestamp_stop: '2022-01-01T09:42:29',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 136068,
    tank_volume_start: 3647.599999999996,
    tank_volume_stop: 3599.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:42:44',
    timestamp_stop: '2022-01-01T09:42:50',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 136070,
    tank_volume_start: 3599.499999999996,
    tank_volume_stop: 3546.299999999996,
  },
  {
    timestamp_start: '2022-01-01T09:43:05',
    timestamp_stop: '2022-01-01T09:43:10',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 136072,
    tank_volume_start: 3546.299999999996,
    tank_volume_stop: 3494.199999999996,
  },
  {
    timestamp_start: '2022-01-01T09:43:25',
    timestamp_stop: '2022-01-01T09:43:31',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 136074,
    tank_volume_start: 3494.199999999996,
    tank_volume_stop: 3431.299999999996,
  },
  {
    timestamp_start: '2022-01-01T09:43:46',
    timestamp_stop: '2022-01-01T09:43:51',
    quantity: 49.6,
    vehicle: 'DEM555',
    id: 136076,
    tank_volume_start: 3431.299999999996,
    tank_volume_stop: 3373.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:44:06',
    timestamp_stop: '2022-01-01T09:44:11',
    quantity: 48.5,
    vehicle: 'DEM666',
    id: 136078,
    tank_volume_start: 3373.499999999996,
    tank_volume_stop: 3314.999999999996,
  },
  {
    timestamp_start: '2022-01-01T09:44:26',
    timestamp_stop: '2022-01-01T09:44:32',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 136080,
    tank_volume_start: 3314.999999999996,
    tank_volume_stop: 3262.099999999996,
  },
  {
    timestamp_start: '2022-01-01T09:44:47',
    timestamp_stop: '2022-01-01T09:44:53',
    quantity: 63.6,
    vehicle: 'DEM555',
    id: 136082,
    tank_volume_start: 3262.099999999996,
    tank_volume_stop: 3205.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:45:08',
    timestamp_stop: '2022-01-01T09:45:13',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 136084,
    tank_volume_start: 3205.499999999996,
    tank_volume_stop: 3149.299999999996,
  },
  {
    timestamp_start: '2022-01-01T09:45:28',
    timestamp_stop: '2022-01-01T09:45:34',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 136086,
    tank_volume_start: 3149.299999999996,
    tank_volume_stop: 3090.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:45:49',
    timestamp_stop: '2022-01-01T09:45:54',
    quantity: 49.3,
    vehicle: 'DEM000',
    id: 136088,
    tank_volume_start: 3090.499999999996,
    tank_volume_stop: 3028.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:46:09',
    timestamp_stop: '2022-01-01T09:46:15',
    quantity: 61.7,
    vehicle: 'DEM222',
    id: 136090,
    tank_volume_start: 3028.499999999996,
    tank_volume_stop: 2973.399999999996,
  },
  {
    timestamp_start: '2022-01-01T09:46:30',
    timestamp_stop: '2022-01-01T09:46:35',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 136092,
    tank_volume_start: 2973.399999999996,
    tank_volume_stop: 2915.699999999996,
  },
  {
    timestamp_start: '2022-01-01T09:46:50',
    timestamp_stop: '2022-01-01T09:46:56',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 136094,
    tank_volume_start: 2915.699999999996,
    tank_volume_stop: 2864.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:47:11',
    timestamp_stop: '2022-01-01T09:47:16',
    quantity: 56.0,
    vehicle: 'DEM444',
    id: 136096,
    tank_volume_start: 2864.5999999999963,
    tank_volume_stop: 2809.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T09:47:31',
    timestamp_stop: '2022-01-01T09:47:37',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 136098,
    tank_volume_start: 2809.5999999999963,
    tank_volume_stop: 2754.299999999996,
  },
  {
    timestamp_start: '2022-01-01T09:47:52',
    timestamp_stop: '2022-01-01T09:47:58',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 136100,
    tank_volume_start: 2754.299999999996,
    tank_volume_stop: 2699.999999999996,
  },
  {
    timestamp_start: '2022-01-01T09:48:13',
    timestamp_stop: '2022-01-01T09:48:18',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 136102,
    tank_volume_start: 2699.999999999996,
    tank_volume_stop: 2653.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:48:33',
    timestamp_stop: '2022-01-01T09:48:40',
    quantity: 61.5,
    vehicle: 'DEM666',
    id: 136104,
    tank_volume_start: 2653.6999999999957,
    tank_volume_stop: 2593.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:48:55',
    timestamp_stop: '2022-01-01T09:49:00',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 136106,
    tank_volume_start: 2593.599999999996,
    tank_volume_stop: 2547.999999999996,
  },
  {
    timestamp_start: '2022-01-01T09:49:15',
    timestamp_stop: '2022-01-01T09:49:20',
    quantity: 48.8,
    vehicle: 'DEM777',
    id: 136108,
    tank_volume_start: 2547.999999999996,
    tank_volume_stop: 2493.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:49:35',
    timestamp_stop: '2022-01-01T09:49:41',
    quantity: 57.0,
    vehicle: 'DEM000',
    id: 136110,
    tank_volume_start: 2493.599999999996,
    tank_volume_stop: 2441.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:49:56',
    timestamp_stop: '2022-01-01T09:50:01',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 136112,
    tank_volume_start: 2441.599999999996,
    tank_volume_stop: 2383.399999999996,
  },
  {
    timestamp_start: '2022-01-01T09:50:16',
    timestamp_stop: '2022-01-01T09:50:21',
    quantity: 55.6,
    vehicle: 'DEM444',
    id: 136114,
    tank_volume_start: 2383.399999999996,
    tank_volume_stop: 2324.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:50:36',
    timestamp_stop: '2022-01-01T09:50:41',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 136116,
    tank_volume_start: 2324.599999999996,
    tank_volume_stop: 2275.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:50:56',
    timestamp_stop: '2022-01-01T09:51:01',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 136118,
    tank_volume_start: 2275.499999999996,
    tank_volume_stop: 2227.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:51:16',
    timestamp_stop: '2022-01-01T09:51:22',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 136120,
    tank_volume_start: 2227.6999999999957,
    tank_volume_stop: 2170.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:51:37',
    timestamp_stop: '2022-01-01T09:51:42',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 136122,
    tank_volume_start: 2170.6999999999957,
    tank_volume_stop: 2113.999999999996,
  },
  {
    timestamp_start: '2022-01-01T09:51:57',
    timestamp_stop: '2022-01-01T09:52:02',
    quantity: 52.7,
    vehicle: 'DEM222',
    id: 136124,
    tank_volume_start: 2113.999999999996,
    tank_volume_stop: 2050.899999999996,
  },
  {
    timestamp_start: '2022-01-01T09:52:17',
    timestamp_stop: '2022-01-01T09:52:23',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 136126,
    tank_volume_start: 2050.899999999996,
    tank_volume_stop: 1991.799999999996,
  },
  {
    timestamp_start: '2022-01-01T09:52:38',
    timestamp_stop: '2022-01-01T09:52:43',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 136128,
    tank_volume_start: 3991.799999999996,
    tank_volume_stop: 3942.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:52:58',
    timestamp_stop: '2022-01-01T09:53:03',
    quantity: 51.5,
    vehicle: 'DEM777',
    id: 136130,
    tank_volume_start: 3942.499999999996,
    tank_volume_stop: 3882.899999999996,
  },
  {
    timestamp_start: '2022-01-01T09:53:18',
    timestamp_stop: '2022-01-01T09:53:24',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 136132,
    tank_volume_start: 3882.899999999996,
    tank_volume_stop: 3833.499999999996,
  },
  {
    timestamp_start: '2022-01-01T09:53:39',
    timestamp_stop: '2022-01-01T09:53:44',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 136134,
    tank_volume_start: 3833.499999999996,
    tank_volume_stop: 3782.399999999996,
  },
  {
    timestamp_start: '2022-01-01T09:53:59',
    timestamp_stop: '2022-01-01T09:54:04',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 136136,
    tank_volume_start: 3782.399999999996,
    tank_volume_stop: 3725.599999999996,
  },
  {
    timestamp_start: '2022-01-01T09:54:19',
    timestamp_stop: '2022-01-01T09:54:25',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 136138,
    tank_volume_start: 3725.599999999996,
    tank_volume_stop: 3672.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:54:40',
    timestamp_stop: '2022-01-01T09:54:45',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 136140,
    tank_volume_start: 3672.6999999999957,
    tank_volume_stop: 3623.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T09:55:00',
    timestamp_stop: '2022-01-01T09:55:06',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 136142,
    tank_volume_start: 3623.7999999999956,
    tank_volume_stop: 3564.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:55:21',
    timestamp_stop: '2022-01-01T09:55:26',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 136144,
    tank_volume_start: 3564.6999999999957,
    tank_volume_stop: 3510.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T09:55:41',
    timestamp_stop: '2022-01-01T09:55:47',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 136146,
    tank_volume_start: 3510.6999999999957,
    tank_volume_stop: 3451.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T09:56:02',
    timestamp_stop: '2022-01-01T09:56:07',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 136148,
    tank_volume_start: 3451.7999999999956,
    tank_volume_stop: 3397.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T09:56:22',
    timestamp_stop: '2022-01-01T09:56:28',
    quantity: 62.6,
    vehicle: 'DEM222',
    id: 136150,
    tank_volume_start: 3397.9999999999955,
    tank_volume_stop: 3342.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T09:56:43',
    timestamp_stop: '2022-01-01T09:56:48',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 136152,
    tank_volume_start: 3342.0999999999954,
    tank_volume_stop: 3295.799999999995,
  },
  {
    timestamp_start: '2022-01-01T09:57:03',
    timestamp_stop: '2022-01-01T09:57:10',
    quantity: 62.8,
    vehicle: 'DEM888',
    id: 136154,
    tank_volume_start: 3295.799999999995,
    tank_volume_stop: 3240.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T09:57:25',
    timestamp_stop: '2022-01-01T09:57:30',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 136156,
    tank_volume_start: 3240.5999999999954,
    tank_volume_stop: 3192.799999999995,
  },
  {
    timestamp_start: '2022-01-01T09:57:45',
    timestamp_stop: '2022-01-01T09:57:50',
    quantity: 48.2,
    vehicle: 'DEM111',
    id: 136158,
    tank_volume_start: 3192.799999999995,
    tank_volume_stop: 3137.299999999995,
  },
  {
    timestamp_start: '2022-01-01T09:58:05',
    timestamp_stop: '2022-01-01T09:58:10',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 136160,
    tank_volume_start: 3137.299999999995,
    tank_volume_stop: 3085.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T09:58:25',
    timestamp_stop: '2022-01-01T09:58:30',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 136162,
    tank_volume_start: 3085.6999999999953,
    tank_volume_stop: 3031.899999999995,
  },
  {
    timestamp_start: '2022-01-01T09:58:45',
    timestamp_stop: '2022-01-01T09:58:51',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 136164,
    tank_volume_start: 3031.899999999995,
    tank_volume_stop: 2982.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T09:59:06',
    timestamp_stop: '2022-01-01T09:59:10',
    quantity: 46.5,
    vehicle: 'DEM333',
    id: 136166,
    tank_volume_start: 2982.1999999999953,
    tank_volume_stop: 2924.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T09:59:25',
    timestamp_stop: '2022-01-01T09:59:31',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 136168,
    tank_volume_start: 2924.5999999999954,
    tank_volume_stop: 2877.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T09:59:46',
    timestamp_stop: '2022-01-01T09:59:51',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 136170,
    tank_volume_start: 2877.1999999999953,
    tank_volume_stop: 2822.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:00:06',
    timestamp_stop: '2022-01-01T10:00:12',
    quantity: 57.8,
    vehicle: 'DEM000',
    id: 136172,
    tank_volume_start: 2822.4999999999955,
    tank_volume_stop: 2771.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:00:27',
    timestamp_stop: '2022-01-01T10:00:33',
    quantity: 60.6,
    vehicle: 'DEM444',
    id: 136174,
    tank_volume_start: 2771.8999999999955,
    tank_volume_stop: 2710.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:00:48',
    timestamp_stop: '2022-01-01T10:00:53',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 136176,
    tank_volume_start: 2710.2999999999956,
    tank_volume_stop: 2652.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:01:08',
    timestamp_stop: '2022-01-01T10:01:14',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 136178,
    tank_volume_start: 2652.6999999999957,
    tank_volume_stop: 2601.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:01:29',
    timestamp_stop: '2022-01-01T10:01:34',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 136180,
    tank_volume_start: 2601.999999999996,
    tank_volume_stop: 2550.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:01:49',
    timestamp_stop: '2022-01-01T10:01:55',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 136182,
    tank_volume_start: 2550.899999999996,
    tank_volume_stop: 2491.699999999996,
  },
  {
    timestamp_start: '2022-01-01T10:02:10',
    timestamp_stop: '2022-01-01T10:02:14',
    quantity: 48.0,
    vehicle: 'DEM555',
    id: 136184,
    tank_volume_start: 2491.699999999996,
    tank_volume_stop: 2437.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:02:29',
    timestamp_stop: '2022-01-01T10:02:35',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 136186,
    tank_volume_start: 2437.199999999996,
    tank_volume_stop: 2381.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:02:50',
    timestamp_stop: '2022-01-01T10:02:56',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 136188,
    tank_volume_start: 2381.899999999996,
    tank_volume_stop: 2328.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:03:11',
    timestamp_stop: '2022-01-01T10:03:16',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 136190,
    tank_volume_start: 2328.999999999996,
    tank_volume_stop: 2276.799999999996,
  },
  {
    timestamp_start: '2022-01-01T10:03:31',
    timestamp_stop: '2022-01-01T10:03:37',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 136192,
    tank_volume_start: 2276.799999999996,
    tank_volume_stop: 2229.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:03:52',
    timestamp_stop: '2022-01-01T10:03:57',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 136194,
    tank_volume_start: 2229.999999999996,
    tank_volume_stop: 2177.599999999996,
  },
  {
    timestamp_start: '2022-01-01T10:04:12',
    timestamp_stop: '2022-01-01T10:04:17',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 136196,
    tank_volume_start: 2177.599999999996,
    tank_volume_stop: 2117.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:04:32',
    timestamp_stop: '2022-01-01T10:04:38',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 136198,
    tank_volume_start: 2117.899999999996,
    tank_volume_stop: 2058.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:04:53',
    timestamp_stop: '2022-01-01T10:04:58',
    quantity: 51.8,
    vehicle: 'DEM111',
    id: 136200,
    tank_volume_start: 2058.299999999996,
    tank_volume_stop: 2001.099999999996,
  },
  {
    timestamp_start: '2022-01-01T10:05:13',
    timestamp_stop: '2022-01-01T10:05:18',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 136202,
    tank_volume_start: 2001.099999999996,
    tank_volume_stop: 1953.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:05:33',
    timestamp_stop: '2022-01-01T10:05:39',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 136204,
    tank_volume_start: 3953.199999999996,
    tank_volume_stop: 3899.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:05:54',
    timestamp_stop: '2022-01-01T10:05:59',
    quantity: 55.5,
    vehicle: 'DEM666',
    id: 136206,
    tank_volume_start: 3899.299999999996,
    tank_volume_stop: 3849.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:06:14',
    timestamp_stop: '2022-01-01T10:06:19',
    quantity: 50.2,
    vehicle: 'DEM666',
    id: 136208,
    tank_volume_start: 3849.299999999996,
    tank_volume_stop: 3796.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:06:34',
    timestamp_stop: '2022-01-01T10:06:39',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 136210,
    tank_volume_start: 3796.0999999999963,
    tank_volume_stop: 3742.799999999996,
  },
  {
    timestamp_start: '2022-01-01T10:06:54',
    timestamp_stop: '2022-01-01T10:06:59',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 136212,
    tank_volume_start: 3742.799999999996,
    tank_volume_stop: 3679.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:07:14',
    timestamp_stop: '2022-01-01T10:07:20',
    quantity: 54.7,
    vehicle: 'DEM777',
    id: 136214,
    tank_volume_start: 3679.299999999996,
    tank_volume_stop: 3619.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:07:35',
    timestamp_stop: '2022-01-01T10:07:41',
    quantity: 59.6,
    vehicle: 'DEM666',
    id: 136216,
    tank_volume_start: 3619.199999999996,
    tank_volume_stop: 3567.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:07:56',
    timestamp_stop: '2022-01-01T10:08:01',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 136218,
    tank_volume_start: 3567.899999999996,
    tank_volume_stop: 3515.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:08:16',
    timestamp_stop: '2022-01-01T10:08:22',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 136220,
    tank_volume_start: 3515.999999999996,
    tank_volume_stop: 3453.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:08:37',
    timestamp_stop: '2022-01-01T10:08:42',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 136222,
    tank_volume_start: 3453.6999999999957,
    tank_volume_stop: 3405.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:08:57',
    timestamp_stop: '2022-01-01T10:09:03',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 136224,
    tank_volume_start: 3405.7999999999956,
    tank_volume_stop: 3344.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:09:18',
    timestamp_stop: '2022-01-01T10:09:23',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 136226,
    tank_volume_start: 3344.3999999999955,
    tank_volume_stop: 3281.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:09:38',
    timestamp_stop: '2022-01-01T10:09:44',
    quantity: 56.3,
    vehicle: 'DEM111',
    id: 136228,
    tank_volume_start: 3281.4999999999955,
    tank_volume_stop: 3217.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T10:09:59',
    timestamp_stop: '2022-01-01T10:10:04',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 136230,
    tank_volume_start: 3217.6999999999953,
    tank_volume_stop: 3160.399999999995,
  },
  {
    timestamp_start: '2022-01-01T10:10:19',
    timestamp_stop: '2022-01-01T10:10:24',
    quantity: 49.6,
    vehicle: 'DEM000',
    id: 136232,
    tank_volume_start: 3160.399999999995,
    tank_volume_stop: 3107.999999999995,
  },
  {
    timestamp_start: '2022-01-01T10:10:39',
    timestamp_stop: '2022-01-01T10:10:45',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 136234,
    tank_volume_start: 3107.999999999995,
    tank_volume_stop: 3050.799999999995,
  },
  {
    timestamp_start: '2022-01-01T10:11:00',
    timestamp_stop: '2022-01-01T10:11:06',
    quantity: 56.5,
    vehicle: 'DEM555',
    id: 136236,
    tank_volume_start: 3050.799999999995,
    tank_volume_stop: 3004.399999999995,
  },
  {
    timestamp_start: '2022-01-01T10:11:21',
    timestamp_stop: '2022-01-01T10:11:26',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 136238,
    tank_volume_start: 3004.399999999995,
    tank_volume_stop: 2950.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T10:11:41',
    timestamp_stop: '2022-01-01T10:11:46',
    quantity: 50.3,
    vehicle: 'DEM555',
    id: 136240,
    tank_volume_start: 2950.1999999999953,
    tank_volume_stop: 2904.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:12:01',
    timestamp_stop: '2022-01-01T10:12:06',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 136242,
    tank_volume_start: 2904.9999999999955,
    tank_volume_stop: 2853.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:12:21',
    timestamp_stop: '2022-01-01T10:12:27',
    quantity: 60.7,
    vehicle: 'DEM111',
    id: 136244,
    tank_volume_start: 2853.9999999999955,
    tank_volume_stop: 2799.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T10:12:42',
    timestamp_stop: '2022-01-01T10:12:47',
    quantity: 49.0,
    vehicle: 'DEM444',
    id: 136246,
    tank_volume_start: 2799.0999999999954,
    tank_volume_stop: 2742.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:13:02',
    timestamp_stop: '2022-01-01T10:13:08',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 136248,
    tank_volume_start: 2742.8999999999955,
    tank_volume_stop: 2686.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T10:13:23',
    timestamp_stop: '2022-01-01T10:13:28',
    quantity: 53.0,
    vehicle: 'DEM333',
    id: 136250,
    tank_volume_start: 2686.5999999999954,
    tank_volume_stop: 2633.799999999995,
  },
  {
    timestamp_start: '2022-01-01T10:13:43',
    timestamp_stop: '2022-01-01T10:13:50',
    quantity: 61.4,
    vehicle: 'DEM111',
    id: 136252,
    tank_volume_start: 2633.799999999995,
    tank_volume_stop: 2577.799999999995,
  },
  {
    timestamp_start: '2022-01-01T10:14:05',
    timestamp_stop: '2022-01-01T10:14:10',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 136254,
    tank_volume_start: 2577.799999999995,
    tank_volume_stop: 2525.299999999995,
  },
  {
    timestamp_start: '2022-01-01T10:14:25',
    timestamp_stop: '2022-01-01T10:14:30',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 136256,
    tank_volume_start: 2525.299999999995,
    tank_volume_stop: 2471.299999999995,
  },
  {
    timestamp_start: '2022-01-01T10:14:45',
    timestamp_stop: '2022-01-01T10:14:51',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 136258,
    tank_volume_start: 2471.299999999995,
    tank_volume_stop: 2414.799999999995,
  },
  {
    timestamp_start: '2022-01-01T10:15:06',
    timestamp_stop: '2022-01-01T10:15:10',
    quantity: 47.5,
    vehicle: 'DEM555',
    id: 136260,
    tank_volume_start: 2414.799999999995,
    tank_volume_stop: 2358.299999999995,
  },
  {
    timestamp_start: '2022-01-01T10:15:25',
    timestamp_stop: '2022-01-01T10:15:30',
    quantity: 50.5,
    vehicle: 'DEM333',
    id: 136262,
    tank_volume_start: 2358.299999999995,
    tank_volume_stop: 2302.899999999995,
  },
  {
    timestamp_start: '2022-01-01T10:15:45',
    timestamp_stop: '2022-01-01T10:15:51',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 136264,
    tank_volume_start: 2302.899999999995,
    tank_volume_stop: 2252.999999999995,
  },
  {
    timestamp_start: '2022-01-01T10:16:06',
    timestamp_stop: '2022-01-01T10:16:12',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 136266,
    tank_volume_start: 2252.999999999995,
    tank_volume_stop: 2201.199999999995,
  },
  {
    timestamp_start: '2022-01-01T10:16:27',
    timestamp_stop: '2022-01-01T10:16:33',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 136268,
    tank_volume_start: 2201.199999999995,
    tank_volume_stop: 2145.199999999995,
  },
  {
    timestamp_start: '2022-01-01T10:16:48',
    timestamp_stop: '2022-01-01T10:16:53',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 136270,
    tank_volume_start: 2145.199999999995,
    tank_volume_stop: 2097.999999999995,
  },
  {
    timestamp_start: '2022-01-01T10:17:08',
    timestamp_stop: '2022-01-01T10:17:14',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 136272,
    tank_volume_start: 2097.999999999995,
    tank_volume_stop: 2042.199999999995,
  },
  {
    timestamp_start: '2022-01-01T10:17:29',
    timestamp_stop: '2022-01-01T10:17:34',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 136274,
    tank_volume_start: 2042.199999999995,
    tank_volume_stop: 1986.999999999995,
  },
  {
    timestamp_start: '2022-01-01T10:17:49',
    timestamp_stop: '2022-01-01T10:17:54',
    quantity: 46.8,
    vehicle: 'DEM111',
    id: 136276,
    tank_volume_start: 3986.999999999995,
    tank_volume_stop: 3934.199999999995,
  },
  {
    timestamp_start: '2022-01-01T10:18:09',
    timestamp_stop: '2022-01-01T10:18:15',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 136278,
    tank_volume_start: 3934.199999999995,
    tank_volume_stop: 3877.599999999995,
  },
  {
    timestamp_start: '2022-01-01T10:18:30',
    timestamp_stop: '2022-01-01T10:18:35',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 136280,
    tank_volume_start: 3877.599999999995,
    tank_volume_stop: 3821.199999999995,
  },
  {
    timestamp_start: '2022-01-01T10:18:50',
    timestamp_stop: '2022-01-01T10:18:56',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 136282,
    tank_volume_start: 3821.199999999995,
    tank_volume_stop: 3762.2999999999947,
  },
  {
    timestamp_start: '2022-01-01T10:19:11',
    timestamp_stop: '2022-01-01T10:19:16',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 136284,
    tank_volume_start: 3762.2999999999947,
    tank_volume_stop: 3704.099999999995,
  },
  {
    timestamp_start: '2022-01-01T10:19:31',
    timestamp_stop: '2022-01-01T10:19:37',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 136286,
    tank_volume_start: 3704.099999999995,
    tank_volume_stop: 3648.999999999995,
  },
  {
    timestamp_start: '2022-01-01T10:19:52',
    timestamp_stop: '2022-01-01T10:19:57',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 136288,
    tank_volume_start: 3648.999999999995,
    tank_volume_stop: 3595.899999999995,
  },
  {
    timestamp_start: '2022-01-01T10:20:12',
    timestamp_stop: '2022-01-01T10:20:18',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 136290,
    tank_volume_start: 3595.899999999995,
    tank_volume_stop: 3536.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T10:20:33',
    timestamp_stop: '2022-01-01T10:20:39',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 136292,
    tank_volume_start: 3536.6999999999953,
    tank_volume_stop: 3480.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:20:54',
    timestamp_stop: '2022-01-01T10:20:59',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 136294,
    tank_volume_start: 3480.9999999999955,
    tank_volume_stop: 3426.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:21:14',
    timestamp_stop: '2022-01-01T10:21:19',
    quantity: 46.9,
    vehicle: 'DEM777',
    id: 136296,
    tank_volume_start: 3426.4999999999955,
    tank_volume_stop: 3364.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:21:34',
    timestamp_stop: '2022-01-01T10:21:40',
    quantity: 64.6,
    vehicle: 'DEM333',
    id: 136298,
    tank_volume_start: 3364.8999999999955,
    tank_volume_stop: 3302.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:21:55',
    timestamp_stop: '2022-01-01T10:22:01',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 136300,
    tank_volume_start: 3302.6999999999957,
    tank_volume_stop: 3239.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:22:16',
    timestamp_stop: '2022-01-01T10:22:22',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 136302,
    tank_volume_start: 3239.8999999999955,
    tank_volume_stop: 3192.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T10:22:37',
    timestamp_stop: '2022-01-01T10:22:42',
    quantity: 56.5,
    vehicle: 'DEM555',
    id: 136304,
    tank_volume_start: 3192.5999999999954,
    tank_volume_stop: 3139.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T10:22:57',
    timestamp_stop: '2022-01-01T10:23:03',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 136306,
    tank_volume_start: 3139.5999999999954,
    tank_volume_stop: 3086.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T10:23:18',
    timestamp_stop: '2022-01-01T10:23:23',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 136308,
    tank_volume_start: 3086.6999999999953,
    tank_volume_stop: 3033.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T10:23:38',
    timestamp_stop: '2022-01-01T10:23:43',
    quantity: 54.4,
    vehicle: 'DEM888',
    id: 136310,
    tank_volume_start: 3033.4999999999955,
    tank_volume_stop: 2984.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:23:58',
    timestamp_stop: '2022-01-01T10:24:05',
    quantity: 63.0,
    vehicle: 'DEM111',
    id: 136312,
    tank_volume_start: 2984.2999999999956,
    tank_volume_stop: 2928.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:24:20',
    timestamp_stop: '2022-01-01T10:24:26',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 136314,
    tank_volume_start: 2928.6999999999957,
    tank_volume_stop: 2867.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:24:41',
    timestamp_stop: '2022-01-01T10:24:47',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 136316,
    tank_volume_start: 2867.499999999996,
    tank_volume_stop: 2807.399999999996,
  },
  {
    timestamp_start: '2022-01-01T10:25:02',
    timestamp_stop: '2022-01-01T10:25:07',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 136318,
    tank_volume_start: 2807.399999999996,
    tank_volume_stop: 2751.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:25:22',
    timestamp_stop: '2022-01-01T10:25:28',
    quantity: 51.7,
    vehicle: 'DEM555',
    id: 136320,
    tank_volume_start: 2751.899999999996,
    tank_volume_stop: 2697.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:25:43',
    timestamp_stop: '2022-01-01T10:25:48',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 136322,
    tank_volume_start: 2697.199999999996,
    tank_volume_stop: 2638.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:26:03',
    timestamp_stop: '2022-01-01T10:26:08',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 136324,
    tank_volume_start: 2638.0999999999963,
    tank_volume_stop: 2579.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:26:23',
    timestamp_stop: '2022-01-01T10:26:29',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 136326,
    tank_volume_start: 2579.9999999999964,
    tank_volume_stop: 2522.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:26:44',
    timestamp_stop: '2022-01-01T10:26:50',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 136328,
    tank_volume_start: 2522.2999999999965,
    tank_volume_stop: 2467.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:27:05',
    timestamp_stop: '2022-01-01T10:27:10',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 136330,
    tank_volume_start: 2467.7999999999965,
    tank_volume_stop: 2413.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T10:27:25',
    timestamp_stop: '2022-01-01T10:27:29',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 136332,
    tank_volume_start: 2413.0999999999967,
    tank_volume_stop: 2359.899999999997,
  },
  {
    timestamp_start: '2022-01-01T10:27:44',
    timestamp_stop: '2022-01-01T10:27:51',
    quantity: 63.4,
    vehicle: 'DEM777',
    id: 136334,
    tank_volume_start: 2359.899999999997,
    tank_volume_stop: 2303.699999999997,
  },
  {
    timestamp_start: '2022-01-01T10:28:06',
    timestamp_stop: '2022-01-01T10:28:12',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 136336,
    tank_volume_start: 2303.699999999997,
    tank_volume_stop: 2245.599999999997,
  },
  {
    timestamp_start: '2022-01-01T10:28:27',
    timestamp_stop: '2022-01-01T10:28:33',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 136338,
    tank_volume_start: 2245.599999999997,
    tank_volume_stop: 2193.699999999997,
  },
  {
    timestamp_start: '2022-01-01T10:28:48',
    timestamp_stop: '2022-01-01T10:28:53',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 136340,
    tank_volume_start: 2193.699999999997,
    tank_volume_stop: 2132.399999999997,
  },
  {
    timestamp_start: '2022-01-01T10:29:08',
    timestamp_stop: '2022-01-01T10:29:14',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 136342,
    tank_volume_start: 2132.399999999997,
    tank_volume_stop: 2073.999999999997,
  },
  {
    timestamp_start: '2022-01-01T10:29:29',
    timestamp_stop: '2022-01-01T10:29:34',
    quantity: 50.9,
    vehicle: 'DEM666',
    id: 136344,
    tank_volume_start: 2073.999999999997,
    tank_volume_stop: 2016.4999999999968,
  },
  {
    timestamp_start: '2022-01-01T10:29:49',
    timestamp_stop: '2022-01-01T10:29:54',
    quantity: 48.1,
    vehicle: 'DEM555',
    id: 136346,
    tank_volume_start: 2016.4999999999968,
    tank_volume_stop: 1961.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T10:30:09',
    timestamp_stop: '2022-01-01T10:30:15',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 136348,
    tank_volume_start: 3961.0999999999967,
    tank_volume_stop: 3906.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T10:30:30',
    timestamp_stop: '2022-01-01T10:30:36',
    quantity: 62.8,
    vehicle: 'DEM888',
    id: 136350,
    tank_volume_start: 3906.5999999999967,
    tank_volume_stop: 3851.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:30:51',
    timestamp_stop: '2022-01-01T10:30:57',
    quantity: 59.5,
    vehicle: 'DEM111',
    id: 136352,
    tank_volume_start: 3851.7999999999965,
    tank_volume_stop: 3793.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:31:12',
    timestamp_stop: '2022-01-01T10:31:17',
    quantity: 52.7,
    vehicle: 'DEM555',
    id: 136354,
    tank_volume_start: 3793.9999999999964,
    tank_volume_stop: 3735.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:31:32',
    timestamp_stop: '2022-01-01T10:31:38',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 136356,
    tank_volume_start: 3735.0999999999963,
    tank_volume_stop: 3672.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:31:53',
    timestamp_stop: '2022-01-01T10:31:58',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 136358,
    tank_volume_start: 3672.3999999999965,
    tank_volume_stop: 3620.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:32:13',
    timestamp_stop: '2022-01-01T10:32:18',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 136360,
    tank_volume_start: 3620.7999999999965,
    tank_volume_stop: 3565.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:32:33',
    timestamp_stop: '2022-01-01T10:32:38',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 136362,
    tank_volume_start: 3565.2999999999965,
    tank_volume_stop: 3503.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T10:32:53',
    timestamp_stop: '2022-01-01T10:32:59',
    quantity: 59.0,
    vehicle: 'DEM888',
    id: 136364,
    tank_volume_start: 3503.6999999999966,
    tank_volume_stop: 3455.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:33:14',
    timestamp_stop: '2022-01-01T10:33:21',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 136366,
    tank_volume_start: 3455.7999999999965,
    tank_volume_stop: 3402.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:33:36',
    timestamp_stop: '2022-01-01T10:33:42',
    quantity: 60.6,
    vehicle: 'DEM000',
    id: 136368,
    tank_volume_start: 3402.3999999999965,
    tank_volume_stop: 3346.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:33:57',
    timestamp_stop: '2022-01-01T10:34:02',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 136370,
    tank_volume_start: 3346.9999999999964,
    tank_volume_stop: 3283.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:34:17',
    timestamp_stop: '2022-01-01T10:34:22',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 136372,
    tank_volume_start: 3283.2999999999965,
    tank_volume_stop: 3234.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:34:37',
    timestamp_stop: '2022-01-01T10:34:43',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 136374,
    tank_volume_start: 3234.2999999999965,
    tank_volume_stop: 3180.5999999999967,
  },
  {
    timestamp_start: '2022-01-01T10:34:58',
    timestamp_stop: '2022-01-01T10:35:03',
    quantity: 58.8,
    vehicle: 'DEM222',
    id: 136376,
    tank_volume_start: 3180.5999999999967,
    tank_volume_stop: 3126.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T10:35:18',
    timestamp_stop: '2022-01-01T10:35:24',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 136378,
    tank_volume_start: 3126.1999999999966,
    tank_volume_stop: 3074.499999999997,
  },
  {
    timestamp_start: '2022-01-01T10:35:39',
    timestamp_stop: '2022-01-01T10:35:45',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 136380,
    tank_volume_start: 3074.499999999997,
    tank_volume_stop: 3021.499999999997,
  },
  {
    timestamp_start: '2022-01-01T10:36:00',
    timestamp_stop: '2022-01-01T10:36:05',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 136382,
    tank_volume_start: 3021.499999999997,
    tank_volume_stop: 2973.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T10:36:20',
    timestamp_stop: '2022-01-01T10:36:25',
    quantity: 49.0,
    vehicle: 'DEM666',
    id: 136384,
    tank_volume_start: 2973.1999999999966,
    tank_volume_stop: 2914.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:36:40',
    timestamp_stop: '2022-01-01T10:36:46',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 136386,
    tank_volume_start: 2914.8999999999965,
    tank_volume_stop: 2862.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:37:01',
    timestamp_stop: '2022-01-01T10:37:06',
    quantity: 49.9,
    vehicle: 'DEM777',
    id: 136388,
    tank_volume_start: 2862.9999999999964,
    tank_volume_stop: 2807.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:37:21',
    timestamp_stop: '2022-01-01T10:37:27',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 136390,
    tank_volume_start: 2807.0999999999963,
    tank_volume_stop: 2756.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:37:42',
    timestamp_stop: '2022-01-01T10:37:48',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 136392,
    tank_volume_start: 2756.299999999996,
    tank_volume_stop: 2709.399999999996,
  },
  {
    timestamp_start: '2022-01-01T10:38:03',
    timestamp_stop: '2022-01-01T10:38:08',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 136394,
    tank_volume_start: 2709.399999999996,
    tank_volume_stop: 2653.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:38:23',
    timestamp_stop: '2022-01-01T10:38:29',
    quantity: 61.0,
    vehicle: 'DEM222',
    id: 136396,
    tank_volume_start: 2653.499999999996,
    tank_volume_stop: 2596.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:38:44',
    timestamp_stop: '2022-01-01T10:38:50',
    quantity: 52.5,
    vehicle: 'DEM000',
    id: 136398,
    tank_volume_start: 2596.299999999996,
    tank_volume_stop: 2544.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:39:05',
    timestamp_stop: '2022-01-01T10:39:10',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 136400,
    tank_volume_start: 2544.5999999999963,
    tank_volume_stop: 2497.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:39:25',
    timestamp_stop: '2022-01-01T10:39:31',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 136402,
    tank_volume_start: 2497.199999999996,
    tank_volume_stop: 2438.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:39:46',
    timestamp_stop: '2022-01-01T10:39:51',
    quantity: 48.4,
    vehicle: 'DEM333',
    id: 136404,
    tank_volume_start: 2438.199999999996,
    tank_volume_stop: 2382.799999999996,
  },
  {
    timestamp_start: '2022-01-01T10:40:06',
    timestamp_stop: '2022-01-01T10:40:11',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 136406,
    tank_volume_start: 2382.799999999996,
    tank_volume_stop: 2328.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:40:26',
    timestamp_stop: '2022-01-01T10:40:31',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 136408,
    tank_volume_start: 2328.299999999996,
    tank_volume_stop: 2276.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:40:46',
    timestamp_stop: '2022-01-01T10:40:52',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 136410,
    tank_volume_start: 2276.299999999996,
    tank_volume_stop: 2217.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:41:07',
    timestamp_stop: '2022-01-01T10:41:13',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 136412,
    tank_volume_start: 2217.499999999996,
    tank_volume_stop: 2160.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:41:28',
    timestamp_stop: '2022-01-01T10:41:34',
    quantity: 59.9,
    vehicle: 'DEM000',
    id: 136414,
    tank_volume_start: 2160.499999999996,
    tank_volume_stop: 2111.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:41:49',
    timestamp_stop: '2022-01-01T10:41:54',
    quantity: 53.1,
    vehicle: 'DEM333',
    id: 136416,
    tank_volume_start: 2111.299999999996,
    tank_volume_stop: 2063.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:42:09',
    timestamp_stop: '2022-01-01T10:42:15',
    quantity: 57.9,
    vehicle: 'DEM333',
    id: 136418,
    tank_volume_start: 2063.199999999996,
    tank_volume_stop: 2015.3999999999962,
  },
  {
    timestamp_start: '2022-01-01T10:42:30',
    timestamp_stop: '2022-01-01T10:42:36',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 136420,
    tank_volume_start: 2015.3999999999962,
    tank_volume_stop: 1965.1999999999962,
  },
  {
    timestamp_start: '2022-01-01T10:42:51',
    timestamp_stop: '2022-01-01T10:42:56',
    quantity: 48.8,
    vehicle: 'DEM555',
    id: 136422,
    tank_volume_start: 3965.199999999996,
    tank_volume_stop: 3914.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:43:11',
    timestamp_stop: '2022-01-01T10:43:17',
    quantity: 57.1,
    vehicle: 'DEM444',
    id: 136424,
    tank_volume_start: 3914.9999999999964,
    tank_volume_stop: 3868.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:43:32',
    timestamp_stop: '2022-01-01T10:43:36',
    quantity: 46.6,
    vehicle: 'DEM888',
    id: 136426,
    tank_volume_start: 3868.4999999999964,
    tank_volume_stop: 3815.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:43:51',
    timestamp_stop: '2022-01-01T10:43:57',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 136428,
    tank_volume_start: 3815.0999999999963,
    tank_volume_stop: 3752.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:44:12',
    timestamp_stop: '2022-01-01T10:44:19',
    quantity: 60.4,
    vehicle: 'DEM777',
    id: 136430,
    tank_volume_start: 3752.199999999996,
    tank_volume_stop: 3697.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:44:34',
    timestamp_stop: '2022-01-01T10:44:39',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 136432,
    tank_volume_start: 3697.0999999999963,
    tank_volume_stop: 3641.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T10:44:54',
    timestamp_stop: '2022-01-01T10:45:00',
    quantity: 56.8,
    vehicle: 'DEM666',
    id: 136434,
    tank_volume_start: 3641.4999999999964,
    tank_volume_stop: 3595.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:45:15',
    timestamp_stop: '2022-01-01T10:45:21',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 136436,
    tank_volume_start: 3595.0999999999963,
    tank_volume_stop: 3535.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:45:36',
    timestamp_stop: '2022-01-01T10:45:41',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 136438,
    tank_volume_start: 3535.5999999999963,
    tank_volume_stop: 3484.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:45:56',
    timestamp_stop: '2022-01-01T10:46:01',
    quantity: 51.0,
    vehicle: 'DEM333',
    id: 136441,
    tank_volume_start: 3484.8999999999965,
    tank_volume_stop: 3428.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:46:16',
    timestamp_stop: '2022-01-01T10:46:22',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 136442,
    tank_volume_start: 3428.3999999999965,
    tank_volume_stop: 3382.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:46:37',
    timestamp_stop: '2022-01-01T10:46:42',
    quantity: 49.6,
    vehicle: 'DEM000',
    id: 136444,
    tank_volume_start: 3382.7999999999965,
    tank_volume_stop: 3322.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T10:46:57',
    timestamp_stop: '2022-01-01T10:47:03',
    quantity: 61.2,
    vehicle: 'DEM333',
    id: 136446,
    tank_volume_start: 3322.6999999999966,
    tank_volume_stop: 3263.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T10:47:18',
    timestamp_stop: '2022-01-01T10:47:23',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 136448,
    tank_volume_start: 3263.3999999999965,
    tank_volume_stop: 3208.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:47:38',
    timestamp_stop: '2022-01-01T10:47:44',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 136450,
    tank_volume_start: 3208.5999999999963,
    tank_volume_stop: 3151.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T10:47:59',
    timestamp_stop: '2022-01-01T10:48:04',
    quantity: 51.9,
    vehicle: 'DEM222',
    id: 136452,
    tank_volume_start: 3151.5999999999963,
    tank_volume_stop: 3095.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:48:19',
    timestamp_stop: '2022-01-01T10:48:24',
    quantity: 50.9,
    vehicle: 'DEM444',
    id: 136454,
    tank_volume_start: 3095.299999999996,
    tank_volume_stop: 3044.399999999996,
  },
  {
    timestamp_start: '2022-01-01T10:48:39',
    timestamp_stop: '2022-01-01T10:48:44',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 136456,
    tank_volume_start: 3044.399999999996,
    tank_volume_stop: 2985.699999999996,
  },
  {
    timestamp_start: '2022-01-01T10:48:59',
    timestamp_stop: '2022-01-01T10:49:04',
    quantity: 48.4,
    vehicle: 'DEM444',
    id: 136458,
    tank_volume_start: 2985.699999999996,
    tank_volume_stop: 2926.199999999996,
  },
  {
    timestamp_start: '2022-01-01T10:49:19',
    timestamp_stop: '2022-01-01T10:49:24',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 136460,
    tank_volume_start: 2926.199999999996,
    tank_volume_stop: 2874.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:49:39',
    timestamp_stop: '2022-01-01T10:49:44',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 136462,
    tank_volume_start: 2874.299999999996,
    tank_volume_stop: 2818.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:49:59',
    timestamp_stop: '2022-01-01T10:50:05',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 136464,
    tank_volume_start: 2818.999999999996,
    tank_volume_stop: 2768.799999999996,
  },
  {
    timestamp_start: '2022-01-01T10:50:20',
    timestamp_stop: '2022-01-01T10:50:27',
    quantity: 65.0,
    vehicle: 'DEM777',
    id: 136466,
    tank_volume_start: 2768.799999999996,
    tank_volume_stop: 2713.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:50:42',
    timestamp_stop: '2022-01-01T10:50:48',
    quantity: 61.0,
    vehicle: 'DEM555',
    id: 136468,
    tank_volume_start: 2713.999999999996,
    tank_volume_stop: 2666.599999999996,
  },
  {
    timestamp_start: '2022-01-01T10:51:03',
    timestamp_stop: '2022-01-01T10:51:08',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 136470,
    tank_volume_start: 2666.599999999996,
    tank_volume_stop: 2605.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:51:23',
    timestamp_stop: '2022-01-01T10:51:29',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 136472,
    tank_volume_start: 2605.2999999999956,
    tank_volume_stop: 2552.099999999996,
  },
  {
    timestamp_start: '2022-01-01T10:51:44',
    timestamp_stop: '2022-01-01T10:51:50',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 136474,
    tank_volume_start: 2552.099999999996,
    tank_volume_stop: 2495.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:52:05',
    timestamp_stop: '2022-01-01T10:52:10',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 136476,
    tank_volume_start: 2495.6999999999957,
    tank_volume_stop: 2441.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:52:25',
    timestamp_stop: '2022-01-01T10:52:31',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 136478,
    tank_volume_start: 2441.1999999999957,
    tank_volume_stop: 2382.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:52:46',
    timestamp_stop: '2022-01-01T10:52:51',
    quantity: 51.9,
    vehicle: 'DEM777',
    id: 136480,
    tank_volume_start: 2382.499999999996,
    tank_volume_stop: 2325.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:53:06',
    timestamp_stop: '2022-01-01T10:53:11',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 136482,
    tank_volume_start: 2325.299999999996,
    tank_volume_stop: 2272.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:53:26',
    timestamp_stop: '2022-01-01T10:53:31',
    quantity: 47.4,
    vehicle: 'DEM222',
    id: 136484,
    tank_volume_start: 2272.999999999996,
    tank_volume_stop: 2221.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:53:46',
    timestamp_stop: '2022-01-01T10:53:51',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 136486,
    tank_volume_start: 2221.1999999999957,
    tank_volume_stop: 2166.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:54:06',
    timestamp_stop: '2022-01-01T10:54:12',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 136488,
    tank_volume_start: 2166.7999999999956,
    tank_volume_stop: 2109.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T10:54:27',
    timestamp_stop: '2022-01-01T10:54:33',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 136490,
    tank_volume_start: 2109.1999999999957,
    tank_volume_stop: 2057.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:54:48',
    timestamp_stop: '2022-01-01T10:54:53',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 136492,
    tank_volume_start: 2057.499999999996,
    tank_volume_stop: 2005.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:55:08',
    timestamp_stop: '2022-01-01T10:55:14',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 136494,
    tank_volume_start: 2005.999999999996,
    tank_volume_stop: 1952.0999999999958,
  },
  {
    timestamp_start: '2022-01-01T10:55:29',
    timestamp_stop: '2022-01-01T10:55:35',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 136496,
    tank_volume_start: 3952.099999999996,
    tank_volume_stop: 3892.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:55:50',
    timestamp_stop: '2022-01-01T10:55:56',
    quantity: 60.2,
    vehicle: 'DEM666',
    id: 136498,
    tank_volume_start: 3892.499999999996,
    tank_volume_stop: 3841.999999999996,
  },
  {
    timestamp_start: '2022-01-01T10:56:11',
    timestamp_stop: '2022-01-01T10:56:17',
    quantity: 62.3,
    vehicle: 'DEM888',
    id: 136501,
    tank_volume_start: 3841.999999999996,
    tank_volume_stop: 3796.099999999996,
  },
  {
    timestamp_start: '2022-01-01T10:56:32',
    timestamp_stop: '2022-01-01T10:56:37',
    quantity: 48.2,
    vehicle: 'DEM333',
    id: 136503,
    tank_volume_start: 3796.099999999996,
    tank_volume_stop: 3739.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T10:56:52',
    timestamp_stop: '2022-01-01T10:56:57',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 136505,
    tank_volume_start: 3739.2999999999956,
    tank_volume_stop: 3686.099999999996,
  },
  {
    timestamp_start: '2022-01-01T10:57:12',
    timestamp_stop: '2022-01-01T10:57:17',
    quantity: 48.2,
    vehicle: 'DEM333',
    id: 136506,
    tank_volume_start: 3686.099999999996,
    tank_volume_stop: 3627.499999999996,
  },
  {
    timestamp_start: '2022-01-01T10:57:32',
    timestamp_stop: '2022-01-01T10:57:39',
    quantity: 64.1,
    vehicle: 'DEM666',
    id: 136508,
    tank_volume_start: 3627.499999999996,
    tank_volume_stop: 3569.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:57:54',
    timestamp_stop: '2022-01-01T10:57:59',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 136511,
    tank_volume_start: 3569.299999999996,
    tank_volume_stop: 3514.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:58:14',
    timestamp_stop: '2022-01-01T10:58:20',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 136513,
    tank_volume_start: 3514.899999999996,
    tank_volume_stop: 3466.699999999996,
  },
  {
    timestamp_start: '2022-01-01T10:58:35',
    timestamp_stop: '2022-01-01T10:58:41',
    quantity: 60.4,
    vehicle: 'DEM777',
    id: 136515,
    tank_volume_start: 3466.699999999996,
    tank_volume_stop: 3416.899999999996,
  },
  {
    timestamp_start: '2022-01-01T10:58:56',
    timestamp_stop: '2022-01-01T10:59:01',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 136517,
    tank_volume_start: 3416.899999999996,
    tank_volume_stop: 3367.699999999996,
  },
  {
    timestamp_start: '2022-01-01T10:59:16',
    timestamp_stop: '2022-01-01T10:59:22',
    quantity: 60.9,
    vehicle: 'DEM000',
    id: 136519,
    tank_volume_start: 3367.699999999996,
    tank_volume_stop: 3312.399999999996,
  },
  {
    timestamp_start: '2022-01-01T10:59:37',
    timestamp_stop: '2022-01-01T10:59:42',
    quantity: 52.0,
    vehicle: 'DEM555',
    id: 136521,
    tank_volume_start: 3312.399999999996,
    tank_volume_stop: 3254.299999999996,
  },
  {
    timestamp_start: '2022-01-01T10:59:57',
    timestamp_stop: '2022-01-01T11:00:03',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 136523,
    tank_volume_start: 3254.299999999996,
    tank_volume_stop: 3195.199999999996,
  },
  {
    timestamp_start: '2022-01-01T11:00:18',
    timestamp_stop: '2022-01-01T11:00:24',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 136525,
    tank_volume_start: 3195.199999999996,
    tank_volume_stop: 3146.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:00:39',
    timestamp_stop: '2022-01-01T11:00:43',
    quantity: 46.1,
    vehicle: 'DEM666',
    id: 136527,
    tank_volume_start: 3146.799999999996,
    tank_volume_stop: 3090.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:00:58',
    timestamp_stop: '2022-01-01T11:01:04',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 136529,
    tank_volume_start: 3090.399999999996,
    tank_volume_stop: 3028.199999999996,
  },
  {
    timestamp_start: '2022-01-01T11:01:19',
    timestamp_stop: '2022-01-01T11:01:25',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 136531,
    tank_volume_start: 3028.199999999996,
    tank_volume_stop: 2975.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:01:40',
    timestamp_stop: '2022-01-01T11:01:45',
    quantity: 51.7,
    vehicle: 'DEM888',
    id: 136533,
    tank_volume_start: 2975.399999999996,
    tank_volume_stop: 2918.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:02:00',
    timestamp_stop: '2022-01-01T11:02:06',
    quantity: 58.1,
    vehicle: 'DEM222',
    id: 136535,
    tank_volume_start: 2918.099999999996,
    tank_volume_stop: 2858.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:02:21',
    timestamp_stop: '2022-01-01T11:02:26',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 136537,
    tank_volume_start: 2858.2999999999956,
    tank_volume_stop: 2811.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:02:41',
    timestamp_stop: '2022-01-01T11:02:46',
    quantity: 47.0,
    vehicle: 'DEM444',
    id: 136539,
    tank_volume_start: 2811.6999999999957,
    tank_volume_stop: 2747.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:03:01',
    timestamp_stop: '2022-01-01T11:03:06',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 136541,
    tank_volume_start: 2747.8999999999955,
    tank_volume_stop: 2695.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:03:21',
    timestamp_stop: '2022-01-01T11:03:27',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 136542,
    tank_volume_start: 2695.8999999999955,
    tank_volume_stop: 2641.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:03:42',
    timestamp_stop: '2022-01-01T11:03:47',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 136545,
    tank_volume_start: 2641.4999999999955,
    tank_volume_stop: 2581.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:04:02',
    timestamp_stop: '2022-01-01T11:04:08',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 136547,
    tank_volume_start: 2581.3999999999955,
    tank_volume_stop: 2532.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:04:23',
    timestamp_stop: '2022-01-01T11:04:29',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 136549,
    tank_volume_start: 2532.4999999999955,
    tank_volume_stop: 2480.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:04:44',
    timestamp_stop: '2022-01-01T11:04:49',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 136551,
    tank_volume_start: 2480.7999999999956,
    tank_volume_stop: 2419.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:05:04',
    timestamp_stop: '2022-01-01T11:05:10',
    quantity: 59.9,
    vehicle: 'DEM555',
    id: 136553,
    tank_volume_start: 2419.9999999999955,
    tank_volume_stop: 2363.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:05:25',
    timestamp_stop: '2022-01-01T11:05:31',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 136555,
    tank_volume_start: 2363.7999999999956,
    tank_volume_stop: 2310.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:05:46',
    timestamp_stop: '2022-01-01T11:05:51',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 136557,
    tank_volume_start: 2310.2999999999956,
    tank_volume_stop: 2247.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:06:06',
    timestamp_stop: '2022-01-01T11:06:12',
    quantity: 62.3,
    vehicle: 'DEM555',
    id: 136559,
    tank_volume_start: 2247.8999999999955,
    tank_volume_stop: 2191.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:06:27',
    timestamp_stop: '2022-01-01T11:06:32',
    quantity: 50.2,
    vehicle: 'DEM888',
    id: 136561,
    tank_volume_start: 2191.4999999999955,
    tank_volume_stop: 2145.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:06:47',
    timestamp_stop: '2022-01-01T11:06:52',
    quantity: 48.7,
    vehicle: 'DEM777',
    id: 136563,
    tank_volume_start: 2145.6999999999953,
    tank_volume_stop: 2093.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:07:07',
    timestamp_stop: '2022-01-01T11:07:13',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 136565,
    tank_volume_start: 2093.6999999999953,
    tank_volume_stop: 2037.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:07:28',
    timestamp_stop: '2022-01-01T11:07:32',
    quantity: 45.5,
    vehicle: 'DEM000',
    id: 136567,
    tank_volume_start: 2037.1999999999953,
    tank_volume_stop: 1977.3999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:07:47',
    timestamp_stop: '2022-01-01T11:07:52',
    quantity: 48.3,
    vehicle: 'DEM000',
    id: 136568,
    tank_volume_start: 3977.399999999995,
    tank_volume_stop: 3925.799999999995,
  },
  {
    timestamp_start: '2022-01-01T11:08:07',
    timestamp_stop: '2022-01-01T11:08:12',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 136570,
    tank_volume_start: 3925.799999999995,
    tank_volume_stop: 3866.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T11:08:27',
    timestamp_stop: '2022-01-01T11:08:33',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 136572,
    tank_volume_start: 3866.5999999999954,
    tank_volume_stop: 3809.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:08:48',
    timestamp_stop: '2022-01-01T11:08:54',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 136574,
    tank_volume_start: 3809.9999999999955,
    tank_volume_stop: 3749.6999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:09:09',
    timestamp_stop: '2022-01-01T11:09:14',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 136576,
    tank_volume_start: 3749.6999999999953,
    tank_volume_stop: 3695.1999999999953,
  },
  {
    timestamp_start: '2022-01-01T11:09:29',
    timestamp_stop: '2022-01-01T11:09:34',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 136578,
    tank_volume_start: 3695.1999999999953,
    tank_volume_stop: 3635.899999999995,
  },
  {
    timestamp_start: '2022-01-01T11:09:49',
    timestamp_stop: '2022-01-01T11:09:55',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 136580,
    tank_volume_start: 3635.899999999995,
    tank_volume_stop: 3576.799999999995,
  },
  {
    timestamp_start: '2022-01-01T11:10:10',
    timestamp_stop: '2022-01-01T11:10:16',
    quantity: 60.2,
    vehicle: 'DEM333',
    id: 136582,
    tank_volume_start: 3576.799999999995,
    tank_volume_stop: 3521.999999999995,
  },
  {
    timestamp_start: '2022-01-01T11:10:31',
    timestamp_stop: '2022-01-01T11:10:36',
    quantity: 50.3,
    vehicle: 'DEM111',
    id: 136584,
    tank_volume_start: 3521.999999999995,
    tank_volume_stop: 3464.299999999995,
  },
  {
    timestamp_start: '2022-01-01T11:10:51',
    timestamp_stop: '2022-01-01T11:10:56',
    quantity: 48.3,
    vehicle: 'DEM777',
    id: 136586,
    tank_volume_start: 3464.299999999995,
    tank_volume_stop: 3409.399999999995,
  },
  {
    timestamp_start: '2022-01-01T11:11:11',
    timestamp_stop: '2022-01-01T11:11:16',
    quantity: 58.5,
    vehicle: 'DEM000',
    id: 136588,
    tank_volume_start: 3409.399999999995,
    tank_volume_stop: 3353.099999999995,
  },
  {
    timestamp_start: '2022-01-01T11:11:31',
    timestamp_stop: '2022-01-01T11:11:37',
    quantity: 55.8,
    vehicle: 'DEM888',
    id: 136590,
    tank_volume_start: 3353.099999999995,
    tank_volume_stop: 3302.2999999999947,
  },
  {
    timestamp_start: '2022-01-01T11:11:52',
    timestamp_stop: '2022-01-01T11:11:58',
    quantity: 63.3,
    vehicle: 'DEM000',
    id: 136592,
    tank_volume_start: 3302.2999999999947,
    tank_volume_stop: 3249.199999999995,
  },
  {
    timestamp_start: '2022-01-01T11:12:13',
    timestamp_stop: '2022-01-01T11:12:19',
    quantity: 52.7,
    vehicle: 'DEM000',
    id: 136594,
    tank_volume_start: 3249.199999999995,
    tank_volume_stop: 3196.8999999999946,
  },
  {
    timestamp_start: '2022-01-01T11:12:34',
    timestamp_stop: '2022-01-01T11:12:40',
    quantity: 58.3,
    vehicle: 'DEM777',
    id: 136596,
    tank_volume_start: 3196.8999999999946,
    tank_volume_stop: 3144.5999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:12:55',
    timestamp_stop: '2022-01-01T11:13:00',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 136598,
    tank_volume_start: 3144.5999999999945,
    tank_volume_stop: 3089.6999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:13:15',
    timestamp_stop: '2022-01-01T11:13:20',
    quantity: 51.3,
    vehicle: 'DEM777',
    id: 136600,
    tank_volume_start: 3089.6999999999944,
    tank_volume_stop: 3025.6999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:13:35',
    timestamp_stop: '2022-01-01T11:13:41',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 136602,
    tank_volume_start: 3025.6999999999944,
    tank_volume_stop: 2970.6999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:13:56',
    timestamp_stop: '2022-01-01T11:14:01',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 136604,
    tank_volume_start: 2970.6999999999944,
    tank_volume_stop: 2915.1999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:14:16',
    timestamp_stop: '2022-01-01T11:14:21',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 136606,
    tank_volume_start: 2915.1999999999944,
    tank_volume_stop: 2854.7999999999943,
  },
  {
    timestamp_start: '2022-01-01T11:14:36',
    timestamp_stop: '2022-01-01T11:14:42',
    quantity: 61.7,
    vehicle: 'DEM222',
    id: 136608,
    tank_volume_start: 2854.7999999999943,
    tank_volume_stop: 2796.6999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:14:57',
    timestamp_stop: '2022-01-01T11:15:03',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 136610,
    tank_volume_start: 2796.6999999999944,
    tank_volume_stop: 2739.0999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:15:18',
    timestamp_stop: '2022-01-01T11:15:24',
    quantity: 60.3,
    vehicle: 'DEM222',
    id: 136612,
    tank_volume_start: 2739.0999999999945,
    tank_volume_stop: 2679.5999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:15:39',
    timestamp_stop: '2022-01-01T11:15:44',
    quantity: 48.1,
    vehicle: 'DEM666',
    id: 136614,
    tank_volume_start: 2679.5999999999945,
    tank_volume_stop: 2621.0999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:15:59',
    timestamp_stop: '2022-01-01T11:16:05',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 136616,
    tank_volume_start: 2621.0999999999945,
    tank_volume_stop: 2561.1999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:16:20',
    timestamp_stop: '2022-01-01T11:16:26',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 136618,
    tank_volume_start: 2561.1999999999944,
    tank_volume_stop: 2507.7999999999943,
  },
  {
    timestamp_start: '2022-01-01T11:16:41',
    timestamp_stop: '2022-01-01T11:16:46',
    quantity: 56.6,
    vehicle: 'DEM666',
    id: 136620,
    tank_volume_start: 2507.7999999999943,
    tank_volume_stop: 2449.1999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:17:01',
    timestamp_stop: '2022-01-01T11:17:07',
    quantity: 60.9,
    vehicle: 'DEM888',
    id: 136622,
    tank_volume_start: 2449.1999999999944,
    tank_volume_stop: 2402.1999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:17:22',
    timestamp_stop: '2022-01-01T11:17:27',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 136624,
    tank_volume_start: 2402.1999999999944,
    tank_volume_stop: 2352.0999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:17:42',
    timestamp_stop: '2022-01-01T11:17:48',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 136626,
    tank_volume_start: 2352.0999999999945,
    tank_volume_stop: 2300.1999999999944,
  },
  {
    timestamp_start: '2022-01-01T11:18:03',
    timestamp_stop: '2022-01-01T11:18:08',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 136628,
    tank_volume_start: 2300.1999999999944,
    tank_volume_stop: 2243.9999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:18:23',
    timestamp_stop: '2022-01-01T11:18:28',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 136630,
    tank_volume_start: 2243.9999999999945,
    tank_volume_stop: 2182.9999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:18:43',
    timestamp_stop: '2022-01-01T11:18:49',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 136632,
    tank_volume_start: 2182.9999999999945,
    tank_volume_stop: 2130.2999999999947,
  },
  {
    timestamp_start: '2022-01-01T11:19:04',
    timestamp_stop: '2022-01-01T11:19:10',
    quantity: 55.2,
    vehicle: 'DEM666',
    id: 136634,
    tank_volume_start: 2130.2999999999947,
    tank_volume_stop: 2074.599999999995,
  },
  {
    timestamp_start: '2022-01-01T11:19:25',
    timestamp_stop: '2022-01-01T11:19:30',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 136636,
    tank_volume_start: 2074.599999999995,
    tank_volume_stop: 2022.8999999999949,
  },
  {
    timestamp_start: '2022-01-01T11:19:45',
    timestamp_stop: '2022-01-01T11:19:50',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 136638,
    tank_volume_start: 2022.8999999999949,
    tank_volume_stop: 1966.6999999999948,
  },
  {
    timestamp_start: '2022-01-01T11:20:05',
    timestamp_stop: '2022-01-01T11:20:11',
    quantity: 57.2,
    vehicle: 'DEM000',
    id: 136640,
    tank_volume_start: 3966.699999999995,
    tank_volume_stop: 3914.499999999995,
  },
  {
    timestamp_start: '2022-01-01T11:20:26',
    timestamp_stop: '2022-01-01T11:20:32',
    quantity: 58.7,
    vehicle: 'DEM444',
    id: 136642,
    tank_volume_start: 3914.499999999995,
    tank_volume_stop: 3867.699999999995,
  },
  {
    timestamp_start: '2022-01-01T11:20:47',
    timestamp_stop: '2022-01-01T11:20:52',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 136644,
    tank_volume_start: 3867.699999999995,
    tank_volume_stop: 3815.699999999995,
  },
  {
    timestamp_start: '2022-01-01T11:21:07',
    timestamp_stop: '2022-01-01T11:21:12',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 136646,
    tank_volume_start: 3815.699999999995,
    tank_volume_stop: 3757.599999999995,
  },
  {
    timestamp_start: '2022-01-01T11:21:27',
    timestamp_stop: '2022-01-01T11:21:33',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 136648,
    tank_volume_start: 3757.599999999995,
    tank_volume_stop: 3711.899999999995,
  },
  {
    timestamp_start: '2022-01-01T11:21:48',
    timestamp_stop: '2022-01-01T11:21:54',
    quantity: 58.2,
    vehicle: 'DEM777',
    id: 136651,
    tank_volume_start: 3711.899999999995,
    tank_volume_stop: 3664.099999999995,
  },
  {
    timestamp_start: '2022-01-01T11:22:09',
    timestamp_stop: '2022-01-01T11:22:15',
    quantity: 58.0,
    vehicle: 'DEM666',
    id: 136653,
    tank_volume_start: 3664.099999999995,
    tank_volume_stop: 3614.199999999995,
  },
  {
    timestamp_start: '2022-01-01T11:22:30',
    timestamp_stop: '2022-01-01T11:22:36',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 136655,
    tank_volume_start: 3614.199999999995,
    tank_volume_stop: 3560.199999999995,
  },
  {
    timestamp_start: '2022-01-01T11:22:51',
    timestamp_stop: '2022-01-01T11:22:57',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 136657,
    tank_volume_start: 3560.199999999995,
    tank_volume_stop: 3513.3999999999946,
  },
  {
    timestamp_start: '2022-01-01T11:23:12',
    timestamp_stop: '2022-01-01T11:23:17',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 136659,
    tank_volume_start: 3513.3999999999946,
    tank_volume_stop: 3453.4999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:23:32',
    timestamp_stop: '2022-01-01T11:23:37',
    quantity: 48.0,
    vehicle: 'DEM222',
    id: 136661,
    tank_volume_start: 3453.4999999999945,
    tank_volume_stop: 3406.0999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:23:52',
    timestamp_stop: '2022-01-01T11:23:58',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 136663,
    tank_volume_start: 3406.0999999999945,
    tank_volume_stop: 3347.9999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:24:13',
    timestamp_stop: '2022-01-01T11:24:18',
    quantity: 51.5,
    vehicle: 'DEM222',
    id: 136665,
    tank_volume_start: 3347.9999999999945,
    tank_volume_stop: 3295.0999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:24:33',
    timestamp_stop: '2022-01-01T11:24:39',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 136667,
    tank_volume_start: 3295.0999999999945,
    tank_volume_stop: 3237.5999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:24:54',
    timestamp_stop: '2022-01-01T11:24:59',
    quantity: 49.2,
    vehicle: 'DEM888',
    id: 136669,
    tank_volume_start: 3237.5999999999945,
    tank_volume_stop: 3175.4999999999945,
  },
  {
    timestamp_start: '2022-01-01T11:25:14',
    timestamp_stop: '2022-01-01T11:25:18',
    quantity: 46.3,
    vehicle: 'DEM444',
    id: 136671,
    tank_volume_start: 3175.4999999999945,
    tank_volume_stop: 3127.3999999999946,
  },
  {
    timestamp_start: '2022-01-01T11:25:33',
    timestamp_stop: '2022-01-01T11:25:39',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 136673,
    tank_volume_start: 3127.3999999999946,
    tank_volume_stop: 3079.3999999999946,
  },
  {
    timestamp_start: '2022-01-01T11:25:54',
    timestamp_stop: '2022-01-01T11:25:59',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 136675,
    tank_volume_start: 3079.3999999999946,
    tank_volume_stop: 3025.3999999999946,
  },
  {
    timestamp_start: '2022-01-01T11:26:14',
    timestamp_stop: '2022-01-01T11:26:20',
    quantity: 55.9,
    vehicle: 'DEM444',
    id: 136677,
    tank_volume_start: 3025.3999999999946,
    tank_volume_stop: 2972.199999999995,
  },
  {
    timestamp_start: '2022-01-01T11:26:35',
    timestamp_stop: '2022-01-01T11:26:40',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 136679,
    tank_volume_start: 2972.199999999995,
    tank_volume_stop: 2915.099999999995,
  },
  {
    timestamp_start: '2022-01-01T11:26:55',
    timestamp_stop: '2022-01-01T11:27:01',
    quantity: 60.5,
    vehicle: 'DEM111',
    id: 136681,
    tank_volume_start: 2915.099999999995,
    tank_volume_stop: 2856.899999999995,
  },
  {
    timestamp_start: '2022-01-01T11:27:16',
    timestamp_stop: '2022-01-01T11:27:22',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 136683,
    tank_volume_start: 2856.899999999995,
    tank_volume_stop: 2807.799999999995,
  },
  {
    timestamp_start: '2022-01-01T11:27:37',
    timestamp_stop: '2022-01-01T11:27:42',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 136685,
    tank_volume_start: 2807.799999999995,
    tank_volume_stop: 2753.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T11:27:57',
    timestamp_stop: '2022-01-01T11:28:03',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 136687,
    tank_volume_start: 2753.5999999999954,
    tank_volume_stop: 2701.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:28:18',
    timestamp_stop: '2022-01-01T11:28:23',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 136689,
    tank_volume_start: 2701.4999999999955,
    tank_volume_stop: 2656.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:28:38',
    timestamp_stop: '2022-01-01T11:28:43',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 136691,
    tank_volume_start: 2656.3999999999955,
    tank_volume_stop: 2607.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:28:58',
    timestamp_stop: '2022-01-01T11:29:03',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 136693,
    tank_volume_start: 2607.9999999999955,
    tank_volume_stop: 2543.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:29:18',
    timestamp_stop: '2022-01-01T11:29:23',
    quantity: 51.8,
    vehicle: 'DEM555',
    id: 136695,
    tank_volume_start: 2543.7999999999956,
    tank_volume_stop: 2497.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:29:38',
    timestamp_stop: '2022-01-01T11:29:43',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 136697,
    tank_volume_start: 2497.9999999999955,
    tank_volume_stop: 2446.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:29:58',
    timestamp_stop: '2022-01-01T11:30:04',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 136699,
    tank_volume_start: 2446.3999999999955,
    tank_volume_stop: 2397.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:30:19',
    timestamp_stop: '2022-01-01T11:30:24',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 136701,
    tank_volume_start: 2397.4999999999955,
    tank_volume_stop: 2341.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:30:39',
    timestamp_stop: '2022-01-01T11:30:45',
    quantity: 56.4,
    vehicle: 'DEM888',
    id: 136703,
    tank_volume_start: 2341.3999999999955,
    tank_volume_stop: 2286.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:31:00',
    timestamp_stop: '2022-01-01T11:31:06',
    quantity: 62.7,
    vehicle: 'DEM888',
    id: 136705,
    tank_volume_start: 2286.1999999999957,
    tank_volume_stop: 2231.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:31:21',
    timestamp_stop: '2022-01-01T11:31:26',
    quantity: 46.9,
    vehicle: 'DEM444',
    id: 136707,
    tank_volume_start: 2231.6999999999957,
    tank_volume_stop: 2184.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:31:41',
    timestamp_stop: '2022-01-01T11:31:47',
    quantity: 62.2,
    vehicle: 'DEM111',
    id: 136709,
    tank_volume_start: 2184.7999999999956,
    tank_volume_stop: 2139.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T11:32:02',
    timestamp_stop: '2022-01-01T11:32:08',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 136711,
    tank_volume_start: 2139.3999999999955,
    tank_volume_stop: 2087.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:32:23',
    timestamp_stop: '2022-01-01T11:32:28',
    quantity: 52.3,
    vehicle: 'DEM444',
    id: 136713,
    tank_volume_start: 2087.6999999999957,
    tank_volume_stop: 2034.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:32:43',
    timestamp_stop: '2022-01-01T11:32:48',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 136715,
    tank_volume_start: 2034.6999999999957,
    tank_volume_stop: 1974.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:33:03',
    timestamp_stop: '2022-01-01T11:33:09',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 136717,
    tank_volume_start: 3974.7999999999956,
    tank_volume_stop: 3917.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T11:33:24',
    timestamp_stop: '2022-01-01T11:33:29',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 136719,
    tank_volume_start: 3917.7999999999956,
    tank_volume_stop: 3862.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:33:44',
    timestamp_stop: '2022-01-01T11:33:49',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 136721,
    tank_volume_start: 3862.6999999999957,
    tank_volume_stop: 3807.599999999996,
  },
  {
    timestamp_start: '2022-01-01T11:34:04',
    timestamp_stop: '2022-01-01T11:34:09',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 136723,
    tank_volume_start: 3807.599999999996,
    tank_volume_stop: 3745.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:34:24',
    timestamp_stop: '2022-01-01T11:34:29',
    quantity: 52.0,
    vehicle: 'DEM000',
    id: 136725,
    tank_volume_start: 3745.999999999996,
    tank_volume_stop: 3687.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:34:44',
    timestamp_stop: '2022-01-01T11:34:50',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 136727,
    tank_volume_start: 3687.399999999996,
    tank_volume_stop: 3625.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:35:05',
    timestamp_stop: '2022-01-01T11:35:11',
    quantity: 57.9,
    vehicle: 'DEM111',
    id: 136729,
    tank_volume_start: 3625.399999999996,
    tank_volume_stop: 3569.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:35:26',
    timestamp_stop: '2022-01-01T11:35:31',
    quantity: 47.2,
    vehicle: 'DEM555',
    id: 136731,
    tank_volume_start: 3569.999999999996,
    tank_volume_stop: 3514.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:35:46',
    timestamp_stop: '2022-01-01T11:35:51',
    quantity: 57.7,
    vehicle: 'DEM111',
    id: 136733,
    tank_volume_start: 3514.299999999996,
    tank_volume_stop: 3460.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:36:06',
    timestamp_stop: '2022-01-01T11:36:13',
    quantity: 62.4,
    vehicle: 'DEM555',
    id: 136735,
    tank_volume_start: 3460.999999999996,
    tank_volume_stop: 3409.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:36:28',
    timestamp_stop: '2022-01-01T11:36:33',
    quantity: 55.7,
    vehicle: 'DEM222',
    id: 136737,
    tank_volume_start: 3409.799999999996,
    tank_volume_stop: 3351.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:36:48',
    timestamp_stop: '2022-01-01T11:36:54',
    quantity: 61.0,
    vehicle: 'DEM000',
    id: 136739,
    tank_volume_start: 3351.299999999996,
    tank_volume_stop: 3298.699999999996,
  },
  {
    timestamp_start: '2022-01-01T11:37:09',
    timestamp_stop: '2022-01-01T11:37:15',
    quantity: 61.1,
    vehicle: 'DEM222',
    id: 136741,
    tank_volume_start: 3298.699999999996,
    tank_volume_stop: 3249.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:37:30',
    timestamp_stop: '2022-01-01T11:37:36',
    quantity: 61.1,
    vehicle: 'DEM111',
    id: 136743,
    tank_volume_start: 3249.799999999996,
    tank_volume_stop: 3199.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:37:51',
    timestamp_stop: '2022-01-01T11:37:57',
    quantity: 50.7,
    vehicle: 'DEM666',
    id: 136745,
    tank_volume_start: 3199.299999999996,
    tank_volume_stop: 3143.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:38:12',
    timestamp_stop: '2022-01-01T11:38:17',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 136747,
    tank_volume_start: 3143.499999999996,
    tank_volume_stop: 3097.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:38:32',
    timestamp_stop: '2022-01-01T11:38:38',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 136749,
    tank_volume_start: 3097.1999999999957,
    tank_volume_stop: 3044.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:38:53',
    timestamp_stop: '2022-01-01T11:38:59',
    quantity: 61.2,
    vehicle: 'DEM666',
    id: 136751,
    tank_volume_start: 3044.6999999999957,
    tank_volume_stop: 2990.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:39:14',
    timestamp_stop: '2022-01-01T11:39:20',
    quantity: 59.8,
    vehicle: 'DEM111',
    id: 136753,
    tank_volume_start: 2990.1999999999957,
    tank_volume_stop: 2940.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:39:35',
    timestamp_stop: '2022-01-01T11:39:41',
    quantity: 58.4,
    vehicle: 'DEM777',
    id: 136755,
    tank_volume_start: 2940.6999999999957,
    tank_volume_stop: 2882.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:39:56',
    timestamp_stop: '2022-01-01T11:40:01',
    quantity: 54.8,
    vehicle: 'DEM777',
    id: 136757,
    tank_volume_start: 2882.499999999996,
    tank_volume_stop: 2833.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:40:16',
    timestamp_stop: '2022-01-01T11:40:22',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 136759,
    tank_volume_start: 2833.6999999999957,
    tank_volume_stop: 2786.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:40:37',
    timestamp_stop: '2022-01-01T11:40:43',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 136761,
    tank_volume_start: 2786.099999999996,
    tank_volume_stop: 2731.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:40:58',
    timestamp_stop: '2022-01-01T11:41:02',
    quantity: 45.8,
    vehicle: 'DEM555',
    id: 136763,
    tank_volume_start: 2731.399999999996,
    tank_volume_stop: 2672.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:41:17',
    timestamp_stop: '2022-01-01T11:41:22',
    quantity: 48.9,
    vehicle: 'DEM000',
    id: 136765,
    tank_volume_start: 2672.399999999996,
    tank_volume_stop: 2624.699999999996,
  },
  {
    timestamp_start: '2022-01-01T11:41:37',
    timestamp_stop: '2022-01-01T11:41:43',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 136767,
    tank_volume_start: 2624.699999999996,
    tank_volume_stop: 2571.899999999996,
  },
  {
    timestamp_start: '2022-01-01T11:41:58',
    timestamp_stop: '2022-01-01T11:42:04',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 136769,
    tank_volume_start: 2571.899999999996,
    tank_volume_stop: 2519.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:42:19',
    timestamp_stop: '2022-01-01T11:42:24',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 136771,
    tank_volume_start: 2519.099999999996,
    tank_volume_stop: 2468.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:42:39',
    timestamp_stop: '2022-01-01T11:42:45',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 136773,
    tank_volume_start: 2468.399999999996,
    tank_volume_stop: 2417.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:43:00',
    timestamp_stop: '2022-01-01T11:43:05',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 136775,
    tank_volume_start: 2417.499999999996,
    tank_volume_stop: 2369.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:43:20',
    timestamp_stop: '2022-01-01T11:43:27',
    quantity: 63.6,
    vehicle: 'DEM000',
    id: 136777,
    tank_volume_start: 2369.999999999996,
    tank_volume_stop: 2318.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:43:42',
    timestamp_stop: '2022-01-01T11:43:48',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 136779,
    tank_volume_start: 2318.499999999996,
    tank_volume_stop: 2254.399999999996,
  },
  {
    timestamp_start: '2022-01-01T11:44:03',
    timestamp_stop: '2022-01-01T11:44:08',
    quantity: 58.8,
    vehicle: 'DEM000',
    id: 136781,
    tank_volume_start: 2254.399999999996,
    tank_volume_stop: 2197.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:44:23',
    timestamp_stop: '2022-01-01T11:44:29',
    quantity: 57.0,
    vehicle: 'DEM888',
    id: 136783,
    tank_volume_start: 2197.099999999996,
    tank_volume_stop: 2145.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:44:44',
    timestamp_stop: '2022-01-01T11:44:49',
    quantity: 50.2,
    vehicle: 'DEM111',
    id: 136785,
    tank_volume_start: 2145.999999999996,
    tank_volume_stop: 2086.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:45:04',
    timestamp_stop: '2022-01-01T11:45:10',
    quantity: 56.1,
    vehicle: 'DEM444',
    id: 136787,
    tank_volume_start: 2086.099999999996,
    tank_volume_stop: 2028.0999999999958,
  },
  {
    timestamp_start: '2022-01-01T11:45:25',
    timestamp_stop: '2022-01-01T11:45:30',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 136789,
    tank_volume_start: 2028.0999999999958,
    tank_volume_stop: 1967.3999999999958,
  },
  {
    timestamp_start: '2022-01-01T11:45:45',
    timestamp_stop: '2022-01-01T11:45:50',
    quantity: 50.0,
    vehicle: 'DEM111',
    id: 136791,
    tank_volume_start: 3967.399999999996,
    tank_volume_stop: 3912.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:46:05',
    timestamp_stop: '2022-01-01T11:46:11',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 136793,
    tank_volume_start: 3912.499999999996,
    tank_volume_stop: 3853.599999999996,
  },
  {
    timestamp_start: '2022-01-01T11:46:26',
    timestamp_stop: '2022-01-01T11:46:30',
    quantity: 47.8,
    vehicle: 'DEM777',
    id: 136795,
    tank_volume_start: 3853.599999999996,
    tank_volume_stop: 3792.599999999996,
  },
  {
    timestamp_start: '2022-01-01T11:46:45',
    timestamp_stop: '2022-01-01T11:46:50',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 136797,
    tank_volume_start: 3792.599999999996,
    tank_volume_stop: 3743.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:47:05',
    timestamp_stop: '2022-01-01T11:47:11',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 136799,
    tank_volume_start: 3743.999999999996,
    tank_volume_stop: 3691.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:47:26',
    timestamp_stop: '2022-01-01T11:47:31',
    quantity: 47.7,
    vehicle: 'DEM333',
    id: 136801,
    tank_volume_start: 3691.099999999996,
    tank_volume_stop: 3631.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:47:46',
    timestamp_stop: '2022-01-01T11:47:51',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 136803,
    tank_volume_start: 3631.099999999996,
    tank_volume_stop: 3579.099999999996,
  },
  {
    timestamp_start: '2022-01-01T11:48:06',
    timestamp_stop: '2022-01-01T11:48:12',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 136805,
    tank_volume_start: 3579.099999999996,
    tank_volume_stop: 3531.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:48:27',
    timestamp_stop: '2022-01-01T11:48:32',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 136807,
    tank_volume_start: 3531.499999999996,
    tank_volume_stop: 3480.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:48:47',
    timestamp_stop: '2022-01-01T11:48:52',
    quantity: 52.1,
    vehicle: 'DEM666',
    id: 136809,
    tank_volume_start: 3480.299999999996,
    tank_volume_stop: 3423.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:49:07',
    timestamp_stop: '2022-01-01T11:49:13',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 136811,
    tank_volume_start: 3423.5999999999963,
    tank_volume_stop: 3369.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:49:28',
    timestamp_stop: '2022-01-01T11:49:33',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 136813,
    tank_volume_start: 3369.5999999999963,
    tank_volume_stop: 3314.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:49:48',
    timestamp_stop: '2022-01-01T11:49:53',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 136815,
    tank_volume_start: 3314.299999999996,
    tank_volume_stop: 3261.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:50:08',
    timestamp_stop: '2022-01-01T11:50:14',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 136817,
    tank_volume_start: 3261.5999999999963,
    tank_volume_stop: 3205.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:50:29',
    timestamp_stop: '2022-01-01T11:50:35',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 136819,
    tank_volume_start: 3205.5999999999963,
    tank_volume_stop: 3146.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T11:50:50',
    timestamp_stop: '2022-01-01T11:50:56',
    quantity: 61.0,
    vehicle: 'DEM777',
    id: 136821,
    tank_volume_start: 3146.4999999999964,
    tank_volume_stop: 3093.9999999999964,
  },
  {
    timestamp_start: '2022-01-01T11:51:11',
    timestamp_stop: '2022-01-01T11:51:17',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 136823,
    tank_volume_start: 3093.9999999999964,
    tank_volume_stop: 3035.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:51:32',
    timestamp_stop: '2022-01-01T11:51:37',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 136825,
    tank_volume_start: 3035.5999999999963,
    tank_volume_stop: 2985.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:51:52',
    timestamp_stop: '2022-01-01T11:51:57',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 136827,
    tank_volume_start: 2985.299999999996,
    tank_volume_stop: 2931.899999999996,
  },
  {
    timestamp_start: '2022-01-01T11:52:12',
    timestamp_stop: '2022-01-01T11:52:17',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 136829,
    tank_volume_start: 2931.899999999996,
    tank_volume_stop: 2885.599999999996,
  },
  {
    timestamp_start: '2022-01-01T11:52:32',
    timestamp_stop: '2022-01-01T11:52:38',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 136831,
    tank_volume_start: 2885.599999999996,
    tank_volume_stop: 2838.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T11:52:53',
    timestamp_stop: '2022-01-01T11:52:59',
    quantity: 61.3,
    vehicle: 'DEM222',
    id: 136833,
    tank_volume_start: 2838.6999999999957,
    tank_volume_stop: 2779.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:53:14',
    timestamp_stop: '2022-01-01T11:53:19',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 136835,
    tank_volume_start: 2779.999999999996,
    tank_volume_stop: 2725.599999999996,
  },
  {
    timestamp_start: '2022-01-01T11:53:34',
    timestamp_stop: '2022-01-01T11:53:41',
    quantity: 64.5,
    vehicle: 'DEM333',
    id: 136837,
    tank_volume_start: 2725.599999999996,
    tank_volume_stop: 2665.999999999996,
  },
  {
    timestamp_start: '2022-01-01T11:53:56',
    timestamp_stop: '2022-01-01T11:54:01',
    quantity: 52.9,
    vehicle: 'DEM666',
    id: 136839,
    tank_volume_start: 2665.999999999996,
    tank_volume_stop: 2611.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:54:16',
    timestamp_stop: '2022-01-01T11:54:22',
    quantity: 62.4,
    vehicle: 'DEM111',
    id: 136841,
    tank_volume_start: 2611.799999999996,
    tank_volume_stop: 2554.199999999996,
  },
  {
    timestamp_start: '2022-01-01T11:54:37',
    timestamp_stop: '2022-01-01T11:54:42',
    quantity: 48.8,
    vehicle: 'DEM333',
    id: 136843,
    tank_volume_start: 2554.199999999996,
    tank_volume_stop: 2496.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:54:57',
    timestamp_stop: '2022-01-01T11:55:02',
    quantity: 46.3,
    vehicle: 'DEM444',
    id: 136845,
    tank_volume_start: 2496.799999999996,
    tank_volume_stop: 2433.899999999996,
  },
  {
    timestamp_start: '2022-01-01T11:55:17',
    timestamp_stop: '2022-01-01T11:55:23',
    quantity: 62.2,
    vehicle: 'DEM444',
    id: 136847,
    tank_volume_start: 2433.899999999996,
    tank_volume_stop: 2383.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:55:38',
    timestamp_stop: '2022-01-01T11:55:43',
    quantity: 55.6,
    vehicle: 'DEM111',
    id: 136849,
    tank_volume_start: 2383.799999999996,
    tank_volume_stop: 2336.799999999996,
  },
  {
    timestamp_start: '2022-01-01T11:55:58',
    timestamp_stop: '2022-01-01T11:56:03',
    quantity: 47.2,
    vehicle: 'DEM555',
    id: 136851,
    tank_volume_start: 2336.799999999996,
    tank_volume_stop: 2281.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:56:18',
    timestamp_stop: '2022-01-01T11:56:24',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 136853,
    tank_volume_start: 2281.5999999999963,
    tank_volume_stop: 2230.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T11:56:39',
    timestamp_stop: '2022-01-01T11:56:45',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 136855,
    tank_volume_start: 2230.8999999999965,
    tank_volume_stop: 2167.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:57:00',
    timestamp_stop: '2022-01-01T11:57:06',
    quantity: 58.9,
    vehicle: 'DEM333',
    id: 136857,
    tank_volume_start: 2167.0999999999963,
    tank_volume_stop: 2104.199999999996,
  },
  {
    timestamp_start: '2022-01-01T11:57:21',
    timestamp_stop: '2022-01-01T11:57:25',
    quantity: 48.2,
    vehicle: 'DEM666',
    id: 136859,
    tank_volume_start: 2104.199999999996,
    tank_volume_stop: 2056.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:57:40',
    timestamp_stop: '2022-01-01T11:57:45',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 136861,
    tank_volume_start: 2056.0999999999963,
    tank_volume_stop: 2002.7999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:58:00',
    timestamp_stop: '2022-01-01T11:58:06',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 136863,
    tank_volume_start: 2002.7999999999963,
    tank_volume_stop: 1945.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:58:21',
    timestamp_stop: '2022-01-01T11:58:27',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 136865,
    tank_volume_start: 3945.0999999999963,
    tank_volume_stop: 3889.199999999996,
  },
  {
    timestamp_start: '2022-01-01T11:58:42',
    timestamp_stop: '2022-01-01T11:58:47',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 136867,
    tank_volume_start: 3889.199999999996,
    tank_volume_stop: 3840.5999999999963,
  },
  {
    timestamp_start: '2022-01-01T11:59:02',
    timestamp_stop: '2022-01-01T11:59:06',
    quantity: 45.7,
    vehicle: 'DEM888',
    id: 136869,
    tank_volume_start: 3840.5999999999963,
    tank_volume_stop: 3791.299999999996,
  },
  {
    timestamp_start: '2022-01-01T11:59:21',
    timestamp_stop: '2022-01-01T11:59:27',
    quantity: 58.4,
    vehicle: 'DEM888',
    id: 136871,
    tank_volume_start: 3791.299999999996,
    tank_volume_stop: 3734.499999999996,
  },
  {
    timestamp_start: '2022-01-01T11:59:42',
    timestamp_stop: '2022-01-01T11:59:47',
    quantity: 48.8,
    vehicle: 'DEM222',
    id: 136873,
    tank_volume_start: 3734.499999999996,
    tank_volume_stop: 3683.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T12:00:02',
    timestamp_stop: '2022-01-01T12:00:07',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 136875,
    tank_volume_start: 3683.1999999999957,
    tank_volume_stop: 3629.099999999996,
  },
  {
    timestamp_start: '2022-01-01T12:00:22',
    timestamp_stop: '2022-01-01T12:00:27',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 136877,
    tank_volume_start: 3629.099999999996,
    tank_volume_stop: 3576.499999999996,
  },
  {
    timestamp_start: '2022-01-01T12:00:42',
    timestamp_stop: '2022-01-01T12:00:48',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 136879,
    tank_volume_start: 3576.499999999996,
    tank_volume_stop: 3521.899999999996,
  },
  {
    timestamp_start: '2022-01-01T12:01:03',
    timestamp_stop: '2022-01-01T12:01:09',
    quantity: 61.6,
    vehicle: 'DEM555',
    id: 136881,
    tank_volume_start: 3521.899999999996,
    tank_volume_stop: 3465.999999999996,
  },
  {
    timestamp_start: '2022-01-01T12:01:24',
    timestamp_stop: '2022-01-01T12:01:29',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 136883,
    tank_volume_start: 3465.999999999996,
    tank_volume_stop: 3419.6999999999957,
  },
  {
    timestamp_start: '2022-01-01T12:01:44',
    timestamp_stop: '2022-01-01T12:01:49',
    quantity: 50.9,
    vehicle: 'DEM444',
    id: 136885,
    tank_volume_start: 3419.6999999999957,
    tank_volume_stop: 3362.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:02:04',
    timestamp_stop: '2022-01-01T12:02:10',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 136887,
    tank_volume_start: 3362.7999999999956,
    tank_volume_stop: 3306.2999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:02:25',
    timestamp_stop: '2022-01-01T12:02:30',
    quantity: 57.8,
    vehicle: 'DEM888',
    id: 136889,
    tank_volume_start: 3306.2999999999956,
    tank_volume_stop: 3253.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:02:45',
    timestamp_stop: '2022-01-01T12:02:51',
    quantity: 51.2,
    vehicle: 'DEM555',
    id: 136891,
    tank_volume_start: 3253.8999999999955,
    tank_volume_stop: 3204.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:03:06',
    timestamp_stop: '2022-01-01T12:03:10',
    quantity: 47.0,
    vehicle: 'DEM000',
    id: 136893,
    tank_volume_start: 3204.4999999999955,
    tank_volume_stop: 3143.9999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:03:25',
    timestamp_stop: '2022-01-01T12:03:31',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 136895,
    tank_volume_start: 3143.9999999999955,
    tank_volume_stop: 3090.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:03:46',
    timestamp_stop: '2022-01-01T12:03:51',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 136897,
    tank_volume_start: 3090.7999999999956,
    tank_volume_stop: 3034.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:04:06',
    timestamp_stop: '2022-01-01T12:04:12',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 136899,
    tank_volume_start: 3034.8999999999955,
    tank_volume_stop: 2978.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T12:04:27',
    timestamp_stop: '2022-01-01T12:04:32',
    quantity: 52.0,
    vehicle: 'DEM333',
    id: 136901,
    tank_volume_start: 2978.5999999999954,
    tank_volume_stop: 2928.299999999995,
  },
  {
    timestamp_start: '2022-01-01T12:04:47',
    timestamp_stop: '2022-01-01T12:04:53',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 136903,
    tank_volume_start: 2928.299999999995,
    tank_volume_stop: 2877.5999999999954,
  },
  {
    timestamp_start: '2022-01-01T12:05:08',
    timestamp_stop: '2022-01-01T12:05:14',
    quantity: 60.5,
    vehicle: 'DEM666',
    id: 136905,
    tank_volume_start: 2877.5999999999954,
    tank_volume_stop: 2825.4999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:05:29',
    timestamp_stop: '2022-01-01T12:05:35',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 136907,
    tank_volume_start: 2825.4999999999955,
    tank_volume_stop: 2773.8999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:05:50',
    timestamp_stop: '2022-01-01T12:05:55',
    quantity: 51.2,
    vehicle: 'DEM222',
    id: 136909,
    tank_volume_start: 2773.8999999999955,
    tank_volume_stop: 2722.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:06:10',
    timestamp_stop: '2022-01-01T12:06:16',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 136911,
    tank_volume_start: 2722.7999999999956,
    tank_volume_stop: 2674.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:06:31',
    timestamp_stop: '2022-01-01T12:06:37',
    quantity: 64.0,
    vehicle: 'DEM000',
    id: 136913,
    tank_volume_start: 2674.3999999999955,
    tank_volume_stop: 2610.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T12:06:52',
    timestamp_stop: '2022-01-01T12:06:57',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 136915,
    tank_volume_start: 2610.0999999999954,
    tank_volume_stop: 2557.0999999999954,
  },
  {
    timestamp_start: '2022-01-01T12:07:12',
    timestamp_stop: '2022-01-01T12:07:18',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 136917,
    tank_volume_start: 2557.0999999999954,
    tank_volume_stop: 2504.3999999999955,
  },
  {
    timestamp_start: '2022-01-01T12:07:33',
    timestamp_stop: '2022-01-01T12:07:38',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 136919,
    tank_volume_start: 2504.3999999999955,
    tank_volume_stop: 2452.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:07:53',
    timestamp_stop: '2022-01-01T12:07:58',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 136921,
    tank_volume_start: 2452.7999999999956,
    tank_volume_stop: 2394.599999999996,
  },
  {
    timestamp_start: '2022-01-01T12:08:13',
    timestamp_stop: '2022-01-01T12:08:19',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 136923,
    tank_volume_start: 2394.599999999996,
    tank_volume_stop: 2335.7999999999956,
  },
  {
    timestamp_start: '2022-01-01T12:08:34',
    timestamp_stop: '2022-01-01T12:08:40',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 136925,
    tank_volume_start: 2335.7999999999956,
    tank_volume_stop: 2282.1999999999957,
  },
  {
    timestamp_start: '2022-01-01T12:08:55',
    timestamp_stop: '2022-01-01T12:09:00',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 136927,
    tank_volume_start: 2282.1999999999957,
    tank_volume_stop: 2229.999999999996,
  },
  {
    timestamp_start: '2022-01-01T12:09:15',
    timestamp_stop: '2022-01-01T12:09:20',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 136929,
    tank_volume_start: 2229.999999999996,
    tank_volume_stop: 2170.399999999996,
  },
  {
    timestamp_start: '2022-01-01T12:09:35',
    timestamp_stop: '2022-01-01T12:09:41',
    quantity: 55.2,
    vehicle: 'DEM444',
    id: 136931,
    tank_volume_start: 2170.399999999996,
    tank_volume_stop: 2112.799999999996,
  },
  {
    timestamp_start: '2022-01-01T12:09:56',
    timestamp_stop: '2022-01-01T12:10:01',
    quantity: 55.7,
    vehicle: 'DEM555',
    id: 136933,
    tank_volume_start: 2112.799999999996,
    tank_volume_stop: 2063.299999999996,
  },
  {
    timestamp_start: '2022-01-01T12:10:16',
    timestamp_stop: '2022-01-01T12:10:22',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 136935,
    tank_volume_start: 2063.299999999996,
    tank_volume_stop: 2004.6999999999962,
  },
  {
    timestamp_start: '2022-01-01T12:10:37',
    timestamp_stop: '2022-01-01T12:10:43',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 136937,
    tank_volume_start: 2004.6999999999962,
    tank_volume_stop: 1948.9999999999961,
  },
  {
    timestamp_start: '2022-01-01T12:10:58',
    timestamp_stop: '2022-01-01T12:11:03',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 136939,
    tank_volume_start: 3948.9999999999964,
    tank_volume_stop: 3899.199999999996,
  },
  {
    timestamp_start: '2022-01-01T12:11:18',
    timestamp_stop: '2022-01-01T12:11:24',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 136941,
    tank_volume_start: 3899.199999999996,
    tank_volume_stop: 3846.0999999999963,
  },
  {
    timestamp_start: '2022-01-01T12:11:39',
    timestamp_stop: '2022-01-01T12:11:44',
    quantity: 51.8,
    vehicle: 'DEM777',
    id: 136943,
    tank_volume_start: 3846.0999999999963,
    tank_volume_stop: 3786.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:11:59',
    timestamp_stop: '2022-01-01T12:12:05',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 136945,
    tank_volume_start: 3786.3999999999965,
    tank_volume_stop: 3738.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:12:20',
    timestamp_stop: '2022-01-01T12:12:26',
    quantity: 59.0,
    vehicle: 'DEM000',
    id: 136947,
    tank_volume_start: 3738.7999999999965,
    tank_volume_stop: 3675.4999999999964,
  },
  {
    timestamp_start: '2022-01-01T12:12:41',
    timestamp_stop: '2022-01-01T12:12:46',
    quantity: 50.8,
    vehicle: 'DEM666',
    id: 136949,
    tank_volume_start: 3675.4999999999964,
    tank_volume_stop: 3616.8999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:13:01',
    timestamp_stop: '2022-01-01T12:13:07',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 136951,
    tank_volume_start: 3616.8999999999965,
    tank_volume_stop: 3563.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T12:13:22',
    timestamp_stop: '2022-01-01T12:13:27',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 136953,
    tank_volume_start: 3563.6999999999966,
    tank_volume_stop: 3512.3999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:13:42',
    timestamp_stop: '2022-01-01T12:13:47',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 136955,
    tank_volume_start: 3512.3999999999965,
    tank_volume_stop: 3449.7999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:14:02',
    timestamp_stop: '2022-01-01T12:14:08',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 136957,
    tank_volume_start: 3449.7999999999965,
    tank_volume_stop: 3399.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T12:14:23',
    timestamp_stop: '2022-01-01T12:14:29',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 136959,
    tank_volume_start: 3399.0999999999967,
    tank_volume_stop: 3353.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T12:14:44',
    timestamp_stop: '2022-01-01T12:14:51',
    quantity: 63.7,
    vehicle: 'DEM222',
    id: 136961,
    tank_volume_start: 3353.1999999999966,
    tank_volume_stop: 3298.499999999997,
  },
  {
    timestamp_start: '2022-01-01T12:15:06',
    timestamp_stop: '2022-01-01T12:15:12',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 136963,
    tank_volume_start: 3298.499999999997,
    tank_volume_stop: 3243.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T12:15:27',
    timestamp_stop: '2022-01-01T12:15:33',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 136965,
    tank_volume_start: 3243.6999999999966,
    tank_volume_stop: 3191.999999999997,
  },
  {
    timestamp_start: '2022-01-01T12:15:48',
    timestamp_stop: '2022-01-01T12:15:54',
    quantity: 58.7,
    vehicle: 'DEM000',
    id: 136967,
    tank_volume_start: 3191.999999999997,
    tank_volume_stop: 3140.0999999999967,
  },
  {
    timestamp_start: '2022-01-01T12:16:09',
    timestamp_stop: '2022-01-01T12:16:14',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 136969,
    tank_volume_start: 3140.0999999999967,
    tank_volume_stop: 3088.2999999999965,
  },
  {
    timestamp_start: '2022-01-01T12:16:29',
    timestamp_stop: '2022-01-01T12:16:35',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 136971,
    tank_volume_start: 3088.2999999999965,
    tank_volume_stop: 3035.1999999999966,
  },
  {
    timestamp_start: '2022-01-01T12:16:50',
    timestamp_stop: '2022-01-01T12:16:56',
    quantity: 64.0,
    vehicle: 'DEM777',
    id: 136973,
    tank_volume_start: 3035.1999999999966,
    tank_volume_stop: 2979.6999999999966,
  },
  {
    timestamp_start: '2022-01-01T12:17:11',
    timestamp_stop: '2022-01-01T12:17:17',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 136975,
    tank_volume_start: 2979.6999999999966,
    tank_volume_stop: 2926.499999999997,
  },
  {
    timestamp_start: '2022-01-01T12:17:32',
    timestamp_stop: '2022-01-01T12:17:37',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 136977,
    tank_volume_start: 2926.499999999997,
    tank_volume_stop: 2875.799999999997,
  },
  {
    timestamp_start: '2022-01-01T12:17:52',
    timestamp_stop: '2022-01-01T12:17:58',
    quantity: 55.9,
    vehicle: 'DEM777',
    id: 136979,
    tank_volume_start: 2875.799999999997,
    tank_volume_stop: 2825.099999999997,
  },
  {
    timestamp_start: '2022-01-01T12:18:13',
    timestamp_stop: '2022-01-01T12:18:18',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 136981,
    tank_volume_start: 2825.099999999997,
    tank_volume_stop: 2766.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T12:18:33',
    timestamp_stop: '2022-01-01T12:18:38',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 136983,
    tank_volume_start: 2766.3999999999974,
    tank_volume_stop: 2717.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T12:18:53',
    timestamp_stop: '2022-01-01T12:18:59',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 136985,
    tank_volume_start: 2717.1999999999975,
    tank_volume_stop: 2660.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T12:19:14',
    timestamp_stop: '2022-01-01T12:19:20',
    quantity: 57.3,
    vehicle: 'DEM444',
    id: 136987,
    tank_volume_start: 2660.8999999999974,
    tank_volume_stop: 2599.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T12:19:35',
    timestamp_stop: '2022-01-01T12:19:40',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 136989,
    tank_volume_start: 2599.8999999999974,
    tank_volume_stop: 2545.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T12:19:55',
    timestamp_stop: '2022-01-01T12:20:01',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 136991,
    tank_volume_start: 2545.1999999999975,
    tank_volume_stop: 2496.2999999999975,
  },
  {
    timestamp_start: '2022-01-01T12:20:16',
    timestamp_stop: '2022-01-01T12:20:21',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 136993,
    tank_volume_start: 2496.2999999999975,
    tank_volume_stop: 2442.5999999999976,
  },
  {
    timestamp_start: '2022-01-01T12:20:36',
    timestamp_stop: '2022-01-01T12:20:42',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 136995,
    tank_volume_start: 2442.5999999999976,
    tank_volume_stop: 2386.899999999998,
  },
  {
    timestamp_start: '2022-01-01T12:20:57',
    timestamp_stop: '2022-01-01T12:21:03',
    quantity: 60.9,
    vehicle: 'DEM666',
    id: 136997,
    tank_volume_start: 2386.899999999998,
    tank_volume_stop: 2330.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:21:18',
    timestamp_stop: '2022-01-01T12:21:24',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 136999,
    tank_volume_start: 2330.9999999999977,
    tank_volume_stop: 2269.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:21:39',
    timestamp_stop: '2022-01-01T12:21:45',
    quantity: 64.5,
    vehicle: 'DEM444',
    id: 137001,
    tank_volume_start: 2269.299999999998,
    tank_volume_stop: 2212.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:22:00',
    timestamp_stop: '2022-01-01T12:22:07',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 137004,
    tank_volume_start: 2212.299999999998,
    tank_volume_stop: 2159.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:22:22',
    timestamp_stop: '2022-01-01T12:22:28',
    quantity: 59.8,
    vehicle: 'DEM777',
    id: 137006,
    tank_volume_start: 2159.4999999999977,
    tank_volume_stop: 2104.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:22:43',
    timestamp_stop: '2022-01-01T12:22:48',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 137008,
    tank_volume_start: 2104.4999999999977,
    tank_volume_stop: 2049.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:23:03',
    timestamp_stop: '2022-01-01T12:23:08',
    quantity: 47.4,
    vehicle: 'DEM444',
    id: 137010,
    tank_volume_start: 2049.4999999999977,
    tank_volume_stop: 1999.6999999999978,
  },
  {
    timestamp_start: '2022-01-01T12:23:23',
    timestamp_stop: '2022-01-01T12:23:29',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 137012,
    tank_volume_start: 3999.699999999998,
    tank_volume_stop: 3949.999999999998,
  },
  {
    timestamp_start: '2022-01-01T12:23:44',
    timestamp_stop: '2022-01-01T12:23:48',
    quantity: 46.4,
    vehicle: 'DEM111',
    id: 137014,
    tank_volume_start: 3949.999999999998,
    tank_volume_stop: 3898.099999999998,
  },
  {
    timestamp_start: '2022-01-01T12:24:03',
    timestamp_stop: '2022-01-01T12:24:09',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 137016,
    tank_volume_start: 3898.099999999998,
    tank_volume_stop: 3835.799999999998,
  },
  {
    timestamp_start: '2022-01-01T12:24:24',
    timestamp_stop: '2022-01-01T12:24:29',
    quantity: 47.4,
    vehicle: 'DEM666',
    id: 137018,
    tank_volume_start: 3835.799999999998,
    tank_volume_stop: 3776.699999999998,
  },
  {
    timestamp_start: '2022-01-01T12:24:44',
    timestamp_stop: '2022-01-01T12:24:49',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 137019,
    tank_volume_start: 3776.699999999998,
    tank_volume_stop: 3720.999999999998,
  },
  {
    timestamp_start: '2022-01-01T12:25:04',
    timestamp_stop: '2022-01-01T12:25:10',
    quantity: 55.3,
    vehicle: 'DEM888',
    id: 137021,
    tank_volume_start: 3720.999999999998,
    tank_volume_stop: 3663.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T12:25:25',
    timestamp_stop: '2022-01-01T12:25:30',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 137023,
    tank_volume_start: 3663.7999999999984,
    tank_volume_stop: 3600.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:25:45',
    timestamp_stop: '2022-01-01T12:25:50',
    quantity: 46.2,
    vehicle: 'DEM222',
    id: 137025,
    tank_volume_start: 3600.5999999999985,
    tank_volume_stop: 3536.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:26:05',
    timestamp_stop: '2022-01-01T12:26:10',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 137027,
    tank_volume_start: 3536.1999999999985,
    tank_volume_stop: 3481.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T12:26:25',
    timestamp_stop: '2022-01-01T12:26:31',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 137029,
    tank_volume_start: 3481.2999999999984,
    tank_volume_stop: 3429.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T12:26:46',
    timestamp_stop: '2022-01-01T12:26:51',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 137031,
    tank_volume_start: 3429.2999999999984,
    tank_volume_stop: 3375.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:27:06',
    timestamp_stop: '2022-01-01T12:27:12',
    quantity: 63.0,
    vehicle: 'DEM666',
    id: 137033,
    tank_volume_start: 3375.5999999999985,
    tank_volume_stop: 3317.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:27:27',
    timestamp_stop: '2022-01-01T12:27:33',
    quantity: 52.6,
    vehicle: 'DEM666',
    id: 137035,
    tank_volume_start: 3317.0999999999985,
    tank_volume_stop: 3262.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:27:48',
    timestamp_stop: '2022-01-01T12:27:53',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 137037,
    tank_volume_start: 3262.9999999999986,
    tank_volume_stop: 3202.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:28:08',
    timestamp_stop: '2022-01-01T12:28:15',
    quantity: 64.1,
    vehicle: 'DEM000',
    id: 137039,
    tank_volume_start: 3202.299999999999,
    tank_volume_stop: 3155.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:28:30',
    timestamp_stop: '2022-01-01T12:28:35',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 137041,
    tank_volume_start: 3155.4999999999986,
    tank_volume_stop: 3106.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:28:50',
    timestamp_stop: '2022-01-01T12:28:56',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 137043,
    tank_volume_start: 3106.9999999999986,
    tank_volume_stop: 3043.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T12:29:11',
    timestamp_stop: '2022-01-01T12:29:17',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 137046,
    tank_volume_start: 3043.3999999999987,
    tank_volume_stop: 2991.199999999999,
  },
  {
    timestamp_start: '2022-01-01T12:29:32',
    timestamp_stop: '2022-01-01T12:29:37',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 137048,
    tank_volume_start: 2991.199999999999,
    tank_volume_stop: 2940.799999999999,
  },
  {
    timestamp_start: '2022-01-01T12:29:52',
    timestamp_stop: '2022-01-01T12:29:57',
    quantity: 50.2,
    vehicle: 'DEM444',
    id: 137050,
    tank_volume_start: 2940.799999999999,
    tank_volume_stop: 2890.699999999999,
  },
  {
    timestamp_start: '2022-01-01T12:30:12',
    timestamp_stop: '2022-01-01T12:30:18',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 137052,
    tank_volume_start: 2890.699999999999,
    tank_volume_stop: 2836.599999999999,
  },
  {
    timestamp_start: '2022-01-01T12:30:33',
    timestamp_stop: '2022-01-01T12:30:39',
    quantity: 64.1,
    vehicle: 'DEM555',
    id: 137054,
    tank_volume_start: 2836.599999999999,
    tank_volume_stop: 2784.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:30:54',
    timestamp_stop: '2022-01-01T12:31:00',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 137056,
    tank_volume_start: 2784.499999999999,
    tank_volume_stop: 2727.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:31:15',
    timestamp_stop: '2022-01-01T12:31:21',
    quantity: 62.1,
    vehicle: 'DEM111',
    id: 137058,
    tank_volume_start: 2727.499999999999,
    tank_volume_stop: 2675.399999999999,
  },
  {
    timestamp_start: '2022-01-01T12:31:36',
    timestamp_stop: '2022-01-01T12:31:42',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 137060,
    tank_volume_start: 2675.399999999999,
    tank_volume_stop: 2626.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:31:57',
    timestamp_stop: '2022-01-01T12:32:02',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 137062,
    tank_volume_start: 2626.2999999999993,
    tank_volume_stop: 2575.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:32:17',
    timestamp_stop: '2022-01-01T12:32:23',
    quantity: 62.7,
    vehicle: 'DEM444',
    id: 137064,
    tank_volume_start: 2575.2999999999993,
    tank_volume_stop: 2519.999999999999,
  },
  {
    timestamp_start: '2022-01-01T12:32:38',
    timestamp_stop: '2022-01-01T12:32:44',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 137066,
    tank_volume_start: 2519.999999999999,
    tank_volume_stop: 2467.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:32:59',
    timestamp_stop: '2022-01-01T12:33:05',
    quantity: 57.2,
    vehicle: 'DEM444',
    id: 137068,
    tank_volume_start: 2467.499999999999,
    tank_volume_stop: 2411.899999999999,
  },
  {
    timestamp_start: '2022-01-01T12:33:20',
    timestamp_stop: '2022-01-01T12:33:25',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 137070,
    tank_volume_start: 2411.899999999999,
    tank_volume_stop: 2364.999999999999,
  },
  {
    timestamp_start: '2022-01-01T12:33:40',
    timestamp_stop: '2022-01-01T12:33:46',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 137072,
    tank_volume_start: 2364.999999999999,
    tank_volume_stop: 2317.699999999999,
  },
  {
    timestamp_start: '2022-01-01T12:34:01',
    timestamp_stop: '2022-01-01T12:34:06',
    quantity: 54.3,
    vehicle: 'DEM666',
    id: 137074,
    tank_volume_start: 2317.699999999999,
    tank_volume_stop: 2270.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T12:34:21',
    timestamp_stop: '2022-01-01T12:34:28',
    quantity: 62.6,
    vehicle: 'DEM333',
    id: 137076,
    tank_volume_start: 2270.8999999999987,
    tank_volume_stop: 2211.699999999999,
  },
  {
    timestamp_start: '2022-01-01T12:34:43',
    timestamp_stop: '2022-01-01T12:34:48',
    quantity: 53.2,
    vehicle: 'DEM111',
    id: 137078,
    tank_volume_start: 2211.699999999999,
    tank_volume_stop: 2162.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T12:35:03',
    timestamp_stop: '2022-01-01T12:35:08',
    quantity: 51.2,
    vehicle: 'DEM888',
    id: 137080,
    tank_volume_start: 2162.8999999999987,
    tank_volume_stop: 2108.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:35:23',
    timestamp_stop: '2022-01-01T12:35:28',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 137082,
    tank_volume_start: 2108.299999999999,
    tank_volume_stop: 2059.099999999999,
  },
  {
    timestamp_start: '2022-01-01T12:35:43',
    timestamp_stop: '2022-01-01T12:35:49',
    quantity: 56.8,
    vehicle: 'DEM777',
    id: 137084,
    tank_volume_start: 2059.099999999999,
    tank_volume_stop: 2002.099999999999,
  },
  {
    timestamp_start: '2022-01-01T12:36:04',
    timestamp_stop: '2022-01-01T12:36:10',
    quantity: 62.6,
    vehicle: 'DEM666',
    id: 137086,
    tank_volume_start: 2002.099999999999,
    tank_volume_stop: 1949.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:36:25',
    timestamp_stop: '2022-01-01T12:36:31',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 137088,
    tank_volume_start: 3949.2999999999993,
    tank_volume_stop: 3897.899999999999,
  },
  {
    timestamp_start: '2022-01-01T12:36:46',
    timestamp_stop: '2022-01-01T12:36:51',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 137090,
    tank_volume_start: 3897.899999999999,
    tank_volume_stop: 3842.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T12:37:06',
    timestamp_stop: '2022-01-01T12:37:11',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 137092,
    tank_volume_start: 3842.6999999999994,
    tank_volume_stop: 3783.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:37:26',
    timestamp_stop: '2022-01-01T12:37:32',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 137094,
    tank_volume_start: 3783.2999999999993,
    tank_volume_stop: 3735.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T12:37:47',
    timestamp_stop: '2022-01-01T12:37:52',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 137096,
    tank_volume_start: 3735.5999999999995,
    tank_volume_stop: 3685.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:38:07',
    timestamp_stop: '2022-01-01T12:38:13',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 137098,
    tank_volume_start: 3685.7999999999993,
    tank_volume_stop: 3628.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T12:38:28',
    timestamp_stop: '2022-01-01T12:38:33',
    quantity: 50.7,
    vehicle: 'DEM666',
    id: 137100,
    tank_volume_start: 3628.6999999999994,
    tank_volume_stop: 3580.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T12:38:48',
    timestamp_stop: '2022-01-01T12:38:53',
    quantity: 48.7,
    vehicle: 'DEM888',
    id: 137102,
    tank_volume_start: 3580.0999999999995,
    tank_volume_stop: 3521.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T12:39:08',
    timestamp_stop: '2022-01-01T12:39:14',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 137104,
    tank_volume_start: 3521.9999999999995,
    tank_volume_stop: 3470.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T12:39:29',
    timestamp_stop: '2022-01-01T12:39:34',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 137106,
    tank_volume_start: 3470.5999999999995,
    tank_volume_stop: 3421.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T12:39:49',
    timestamp_stop: '2022-01-01T12:39:54',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 137108,
    tank_volume_start: 3421.1999999999994,
    tank_volume_stop: 3371.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T12:40:09',
    timestamp_stop: '2022-01-01T12:40:15',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 137110,
    tank_volume_start: 3371.6999999999994,
    tank_volume_stop: 3319.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:40:30',
    timestamp_stop: '2022-01-01T12:40:36',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 137112,
    tank_volume_start: 3319.2999999999993,
    tank_volume_stop: 3266.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:40:51',
    timestamp_stop: '2022-01-01T12:40:56',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 137114,
    tank_volume_start: 3266.7999999999993,
    tank_volume_stop: 3213.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:41:11',
    timestamp_stop: '2022-01-01T12:41:16',
    quantity: 47.3,
    vehicle: 'DEM111',
    id: 137116,
    tank_volume_start: 3213.499999999999,
    tank_volume_stop: 3155.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T12:41:31',
    timestamp_stop: '2022-01-01T12:41:36',
    quantity: 51.5,
    vehicle: 'DEM444',
    id: 137118,
    tank_volume_start: 3155.7999999999993,
    tank_volume_stop: 3103.399999999999,
  },
  {
    timestamp_start: '2022-01-01T12:41:51',
    timestamp_stop: '2022-01-01T12:41:57',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 137120,
    tank_volume_start: 3103.399999999999,
    tank_volume_stop: 3052.899999999999,
  },
  {
    timestamp_start: '2022-01-01T12:42:12',
    timestamp_stop: '2022-01-01T12:42:17',
    quantity: 55.4,
    vehicle: 'DEM666',
    id: 137122,
    tank_volume_start: 3052.899999999999,
    tank_volume_stop: 2991.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:42:32',
    timestamp_stop: '2022-01-01T12:42:38',
    quantity: 53.1,
    vehicle: 'DEM333',
    id: 137124,
    tank_volume_start: 2991.499999999999,
    tank_volume_stop: 2943.999999999999,
  },
  {
    timestamp_start: '2022-01-01T12:42:53',
    timestamp_stop: '2022-01-01T12:42:58',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 137126,
    tank_volume_start: 2943.999999999999,
    tank_volume_stop: 2896.499999999999,
  },
  {
    timestamp_start: '2022-01-01T12:43:13',
    timestamp_stop: '2022-01-01T12:43:19',
    quantity: 57.1,
    vehicle: 'DEM333',
    id: 137128,
    tank_volume_start: 2896.499999999999,
    tank_volume_stop: 2842.599999999999,
  },
  {
    timestamp_start: '2022-01-01T12:43:34',
    timestamp_stop: '2022-01-01T12:43:39',
    quantity: 48.2,
    vehicle: 'DEM777',
    id: 137130,
    tank_volume_start: 2842.599999999999,
    tank_volume_stop: 2791.899999999999,
  },
  {
    timestamp_start: '2022-01-01T12:43:54',
    timestamp_stop: '2022-01-01T12:43:59',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 137132,
    tank_volume_start: 2791.899999999999,
    tank_volume_stop: 2731.999999999999,
  },
  {
    timestamp_start: '2022-01-01T12:44:14',
    timestamp_stop: '2022-01-01T12:44:20',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 137134,
    tank_volume_start: 2731.999999999999,
    tank_volume_stop: 2679.699999999999,
  },
  {
    timestamp_start: '2022-01-01T12:44:35',
    timestamp_stop: '2022-01-01T12:44:40',
    quantity: 55.5,
    vehicle: 'DEM777',
    id: 137136,
    tank_volume_start: 2679.699999999999,
    tank_volume_stop: 2625.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:44:55',
    timestamp_stop: '2022-01-01T12:45:00',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 137138,
    tank_volume_start: 2625.299999999999,
    tank_volume_stop: 2568.799999999999,
  },
  {
    timestamp_start: '2022-01-01T12:45:15',
    timestamp_stop: '2022-01-01T12:45:20',
    quantity: 46.6,
    vehicle: 'DEM777',
    id: 137140,
    tank_volume_start: 2568.799999999999,
    tank_volume_stop: 2520.099999999999,
  },
  {
    timestamp_start: '2022-01-01T12:45:35',
    timestamp_stop: '2022-01-01T12:45:41',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 137142,
    tank_volume_start: 2520.099999999999,
    tank_volume_stop: 2456.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:45:56',
    timestamp_stop: '2022-01-01T12:46:01',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 137144,
    tank_volume_start: 2456.299999999999,
    tank_volume_stop: 2399.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:46:16',
    timestamp_stop: '2022-01-01T12:46:22',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 137146,
    tank_volume_start: 2399.4999999999986,
    tank_volume_stop: 2342.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T12:46:37',
    timestamp_stop: '2022-01-01T12:46:42',
    quantity: 49.2,
    vehicle: 'DEM555',
    id: 137148,
    tank_volume_start: 2342.8999999999987,
    tank_volume_stop: 2279.299999999999,
  },
  {
    timestamp_start: '2022-01-01T12:46:57',
    timestamp_stop: '2022-01-01T12:47:03',
    quantity: 63.9,
    vehicle: 'DEM666',
    id: 137150,
    tank_volume_start: 2279.299999999999,
    tank_volume_stop: 2224.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:47:18',
    timestamp_stop: '2022-01-01T12:47:24',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 137152,
    tank_volume_start: 2224.9999999999986,
    tank_volume_stop: 2172.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:47:39',
    timestamp_stop: '2022-01-01T12:47:45',
    quantity: 61.2,
    vehicle: 'DEM333',
    id: 137154,
    tank_volume_start: 2172.4999999999986,
    tank_volume_stop: 2124.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:48:00',
    timestamp_stop: '2022-01-01T12:48:05',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 137156,
    tank_volume_start: 2124.6999999999985,
    tank_volume_stop: 2079.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:48:20',
    timestamp_stop: '2022-01-01T12:48:25',
    quantity: 51.6,
    vehicle: 'DEM111',
    id: 137158,
    tank_volume_start: 2079.6999999999985,
    tank_volume_stop: 2031.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T12:48:40',
    timestamp_stop: '2022-01-01T12:48:45',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 137160,
    tank_volume_start: 2031.0999999999985,
    tank_volume_stop: 1976.2999999999986,
  },
  {
    timestamp_start: '2022-01-01T12:49:00',
    timestamp_stop: '2022-01-01T12:49:06',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 137162,
    tank_volume_start: 3976.2999999999984,
    tank_volume_stop: 3925.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T12:49:21',
    timestamp_stop: '2022-01-01T12:49:27',
    quantity: 61.5,
    vehicle: 'DEM888',
    id: 137164,
    tank_volume_start: 3925.7999999999984,
    tank_volume_stop: 3874.499999999998,
  },
  {
    timestamp_start: '2022-01-01T12:49:42',
    timestamp_stop: '2022-01-01T12:49:47',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 137166,
    tank_volume_start: 3874.499999999998,
    tank_volume_stop: 3815.699999999998,
  },
  {
    timestamp_start: '2022-01-01T12:50:02',
    timestamp_stop: '2022-01-01T12:50:08',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 137168,
    tank_volume_start: 3815.699999999998,
    tank_volume_stop: 3758.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:50:23',
    timestamp_stop: '2022-01-01T12:50:28',
    quantity: 48.9,
    vehicle: 'DEM222',
    id: 137170,
    tank_volume_start: 3758.299999999998,
    tank_volume_stop: 3705.699999999998,
  },
  {
    timestamp_start: '2022-01-01T12:50:43',
    timestamp_stop: '2022-01-01T12:50:48',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 137172,
    tank_volume_start: 3705.699999999998,
    tank_volume_stop: 3659.899999999998,
  },
  {
    timestamp_start: '2022-01-01T12:51:03',
    timestamp_stop: '2022-01-01T12:51:09',
    quantity: 59.6,
    vehicle: 'DEM111',
    id: 137174,
    tank_volume_start: 3659.899999999998,
    tank_volume_stop: 3606.899999999998,
  },
  {
    timestamp_start: '2022-01-01T12:51:24',
    timestamp_stop: '2022-01-01T12:51:30',
    quantity: 59.7,
    vehicle: 'DEM666',
    id: 137176,
    tank_volume_start: 3606.899999999998,
    tank_volume_stop: 3555.899999999998,
  },
  {
    timestamp_start: '2022-01-01T12:51:45',
    timestamp_stop: '2022-01-01T12:51:50',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 137178,
    tank_volume_start: 3555.899999999998,
    tank_volume_stop: 3509.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:52:05',
    timestamp_stop: '2022-01-01T12:52:11',
    quantity: 58.9,
    vehicle: 'DEM000',
    id: 137180,
    tank_volume_start: 3509.299999999998,
    tank_volume_stop: 3453.599999999998,
  },
  {
    timestamp_start: '2022-01-01T12:52:26',
    timestamp_stop: '2022-01-01T12:52:31',
    quantity: 54.6,
    vehicle: 'DEM222',
    id: 137182,
    tank_volume_start: 3453.599999999998,
    tank_volume_stop: 3401.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:52:46',
    timestamp_stop: '2022-01-01T12:52:52',
    quantity: 58.9,
    vehicle: 'DEM555',
    id: 137184,
    tank_volume_start: 3401.299999999998,
    tank_volume_stop: 3346.199999999998,
  },
  {
    timestamp_start: '2022-01-01T12:53:07',
    timestamp_stop: '2022-01-01T12:53:13',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 137186,
    tank_volume_start: 3346.199999999998,
    tank_volume_stop: 3286.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:53:28',
    timestamp_stop: '2022-01-01T12:53:32',
    quantity: 45.5,
    vehicle: 'DEM555',
    id: 137188,
    tank_volume_start: 3286.299999999998,
    tank_volume_stop: 3231.199999999998,
  },
  {
    timestamp_start: '2022-01-01T12:53:47',
    timestamp_stop: '2022-01-01T12:53:53',
    quantity: 53.4,
    vehicle: 'DEM333',
    id: 137190,
    tank_volume_start: 3231.199999999998,
    tank_volume_stop: 3178.299999999998,
  },
  {
    timestamp_start: '2022-01-01T12:54:08',
    timestamp_stop: '2022-01-01T12:54:13',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 137192,
    tank_volume_start: 3178.299999999998,
    tank_volume_stop: 3115.699999999998,
  },
  {
    timestamp_start: '2022-01-01T12:54:28',
    timestamp_stop: '2022-01-01T12:54:33',
    quantity: 49.6,
    vehicle: 'DEM555',
    id: 137194,
    tank_volume_start: 3115.699999999998,
    tank_volume_stop: 3055.399999999998,
  },
  {
    timestamp_start: '2022-01-01T12:54:48',
    timestamp_stop: '2022-01-01T12:54:52',
    quantity: 46.2,
    vehicle: 'DEM777',
    id: 137196,
    tank_volume_start: 3055.399999999998,
    tank_volume_stop: 2999.799999999998,
  },
  {
    timestamp_start: '2022-01-01T12:55:07',
    timestamp_stop: '2022-01-01T12:55:13',
    quantity: 53.9,
    vehicle: 'DEM333',
    id: 137198,
    tank_volume_start: 2999.799999999998,
    tank_volume_stop: 2949.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:55:28',
    timestamp_stop: '2022-01-01T12:55:34',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 137200,
    tank_volume_start: 2949.4999999999977,
    tank_volume_stop: 2902.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:55:49',
    timestamp_stop: '2022-01-01T12:55:54',
    quantity: 49.2,
    vehicle: 'DEM888',
    id: 137202,
    tank_volume_start: 2902.4999999999977,
    tank_volume_stop: 2845.399999999998,
  },
  {
    timestamp_start: '2022-01-01T12:56:09',
    timestamp_stop: '2022-01-01T12:56:13',
    quantity: 46.8,
    vehicle: 'DEM777',
    id: 137204,
    tank_volume_start: 2845.399999999998,
    tank_volume_stop: 2785.699999999998,
  },
  {
    timestamp_start: '2022-01-01T12:56:28',
    timestamp_stop: '2022-01-01T12:56:34',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 137206,
    tank_volume_start: 2785.699999999998,
    tank_volume_stop: 2731.599999999998,
  },
  {
    timestamp_start: '2022-01-01T12:56:49',
    timestamp_stop: '2022-01-01T12:56:55',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 137208,
    tank_volume_start: 2731.599999999998,
    tank_volume_stop: 2672.099999999998,
  },
  {
    timestamp_start: '2022-01-01T12:57:10',
    timestamp_stop: '2022-01-01T12:57:15',
    quantity: 49.8,
    vehicle: 'DEM666',
    id: 137210,
    tank_volume_start: 2672.099999999998,
    tank_volume_stop: 2620.099999999998,
  },
  {
    timestamp_start: '2022-01-01T12:57:30',
    timestamp_stop: '2022-01-01T12:57:35',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 137212,
    tank_volume_start: 2620.099999999998,
    tank_volume_stop: 2562.099999999998,
  },
  {
    timestamp_start: '2022-01-01T12:57:50',
    timestamp_stop: '2022-01-01T12:57:54',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 137214,
    tank_volume_start: 2562.099999999998,
    tank_volume_stop: 2501.799999999998,
  },
  {
    timestamp_start: '2022-01-01T12:58:09',
    timestamp_stop: '2022-01-01T12:58:15',
    quantity: 55.4,
    vehicle: 'DEM888',
    id: 137216,
    tank_volume_start: 2501.799999999998,
    tank_volume_stop: 2440.899999999998,
  },
  {
    timestamp_start: '2022-01-01T12:58:30',
    timestamp_stop: '2022-01-01T12:58:36',
    quantity: 64.2,
    vehicle: 'DEM000',
    id: 137218,
    tank_volume_start: 2440.899999999998,
    tank_volume_stop: 2383.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:58:51',
    timestamp_stop: '2022-01-01T12:58:57',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 137220,
    tank_volume_start: 2383.9999999999977,
    tank_volume_stop: 2335.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T12:59:12',
    timestamp_stop: '2022-01-01T12:59:18',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 137222,
    tank_volume_start: 2335.0999999999976,
    tank_volume_stop: 2277.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T12:59:33',
    timestamp_stop: '2022-01-01T12:59:38',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 137224,
    tank_volume_start: 2277.4999999999977,
    tank_volume_stop: 2227.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T12:59:53',
    timestamp_stop: '2022-01-01T12:59:59',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 137226,
    tank_volume_start: 2227.0999999999976,
    tank_volume_stop: 2173.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:00:14',
    timestamp_stop: '2022-01-01T13:00:20',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 137228,
    tank_volume_start: 2173.6999999999975,
    tank_volume_stop: 2118.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:00:35',
    timestamp_stop: '2022-01-01T13:00:40',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 137230,
    tank_volume_start: 2118.9999999999977,
    tank_volume_stop: 2064.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:00:55',
    timestamp_stop: '2022-01-01T13:01:01',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 137232,
    tank_volume_start: 2064.9999999999977,
    tank_volume_stop: 2015.1999999999978,
  },
  {
    timestamp_start: '2022-01-01T13:01:16',
    timestamp_stop: '2022-01-01T13:01:21',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 137234,
    tank_volume_start: 2015.1999999999978,
    tank_volume_stop: 1963.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:01:36',
    timestamp_stop: '2022-01-01T13:01:42',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 137236,
    tank_volume_start: 3963.4999999999977,
    tank_volume_stop: 3905.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:01:57',
    timestamp_stop: '2022-01-01T13:02:02',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 137238,
    tank_volume_start: 3905.1999999999975,
    tank_volume_stop: 3845.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:02:17',
    timestamp_stop: '2022-01-01T13:02:22',
    quantity: 48.4,
    vehicle: 'DEM777',
    id: 137240,
    tank_volume_start: 3845.8999999999974,
    tank_volume_stop: 3792.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:02:37',
    timestamp_stop: '2022-01-01T13:02:42',
    quantity: 52.4,
    vehicle: 'DEM222',
    id: 137242,
    tank_volume_start: 3792.9999999999973,
    tank_volume_stop: 3730.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:02:57',
    timestamp_stop: '2022-01-01T13:03:02',
    quantity: 49.8,
    vehicle: 'DEM888',
    id: 137244,
    tank_volume_start: 3730.9999999999973,
    tank_volume_stop: 3678.4999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:03:17',
    timestamp_stop: '2022-01-01T13:03:23',
    quantity: 56.7,
    vehicle: 'DEM777',
    id: 137246,
    tank_volume_start: 3678.4999999999973,
    tank_volume_stop: 3613.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:03:38',
    timestamp_stop: '2022-01-01T13:03:43',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 137248,
    tank_volume_start: 3613.7999999999975,
    tank_volume_stop: 3559.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T13:03:58',
    timestamp_stop: '2022-01-01T13:04:04',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 137250,
    tank_volume_start: 3559.0999999999976,
    tank_volume_stop: 3503.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:04:19',
    timestamp_stop: '2022-01-01T13:04:25',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 137252,
    tank_volume_start: 3503.4999999999977,
    tank_volume_stop: 3441.0999999999976,
  },
  {
    timestamp_start: '2022-01-01T13:04:40',
    timestamp_stop: '2022-01-01T13:04:45',
    quantity: 52.6,
    vehicle: 'DEM333',
    id: 137254,
    tank_volume_start: 3441.0999999999976,
    tank_volume_stop: 3381.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:05:00',
    timestamp_stop: '2022-01-01T13:05:06',
    quantity: 61.2,
    vehicle: 'DEM555',
    id: 137256,
    tank_volume_start: 3381.6999999999975,
    tank_volume_stop: 3330.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:05:21',
    timestamp_stop: '2022-01-01T13:05:27',
    quantity: 56.3,
    vehicle: 'DEM222',
    id: 137258,
    tank_volume_start: 3330.8999999999974,
    tank_volume_stop: 3284.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:05:42',
    timestamp_stop: '2022-01-01T13:05:46',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 137260,
    tank_volume_start: 3284.599999999997,
    tank_volume_stop: 3232.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:06:01',
    timestamp_stop: '2022-01-01T13:06:06',
    quantity: 48.5,
    vehicle: 'DEM333',
    id: 137262,
    tank_volume_start: 3232.599999999997,
    tank_volume_stop: 3168.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:06:21',
    timestamp_stop: '2022-01-01T13:06:26',
    quantity: 48.1,
    vehicle: 'DEM222',
    id: 137264,
    tank_volume_start: 3168.9999999999973,
    tank_volume_stop: 3110.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:06:41',
    timestamp_stop: '2022-01-01T13:06:47',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 137266,
    tank_volume_start: 3110.9999999999973,
    tank_volume_stop: 3061.699999999997,
  },
  {
    timestamp_start: '2022-01-01T13:07:02',
    timestamp_stop: '2022-01-01T13:07:08',
    quantity: 60.8,
    vehicle: 'DEM333',
    id: 137268,
    tank_volume_start: 3061.699999999997,
    tank_volume_stop: 2999.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:07:23',
    timestamp_stop: '2022-01-01T13:07:29',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 137270,
    tank_volume_start: 2999.9999999999973,
    tank_volume_stop: 2946.199999999997,
  },
  {
    timestamp_start: '2022-01-01T13:07:44',
    timestamp_stop: '2022-01-01T13:07:49',
    quantity: 51.3,
    vehicle: 'DEM444',
    id: 137272,
    tank_volume_start: 2946.199999999997,
    tank_volume_stop: 2885.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:08:04',
    timestamp_stop: '2022-01-01T13:08:10',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 137274,
    tank_volume_start: 2885.9999999999973,
    tank_volume_stop: 2831.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:08:25',
    timestamp_stop: '2022-01-01T13:08:30',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 137276,
    tank_volume_start: 2831.099999999997,
    tank_volume_stop: 2775.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:08:45',
    timestamp_stop: '2022-01-01T13:08:51',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 137278,
    tank_volume_start: 2775.8999999999974,
    tank_volume_stop: 2720.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:09:06',
    timestamp_stop: '2022-01-01T13:09:12',
    quantity: 58.8,
    vehicle: 'DEM777',
    id: 137280,
    tank_volume_start: 2720.599999999997,
    tank_volume_stop: 2670.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:09:27',
    timestamp_stop: '2022-01-01T13:09:32',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 137282,
    tank_volume_start: 2670.099999999997,
    tank_volume_stop: 2608.799999999997,
  },
  {
    timestamp_start: '2022-01-01T13:09:47',
    timestamp_stop: '2022-01-01T13:09:53',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 137284,
    tank_volume_start: 2608.799999999997,
    tank_volume_stop: 2552.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:10:08',
    timestamp_stop: '2022-01-01T13:10:15',
    quantity: 63.8,
    vehicle: 'DEM444',
    id: 137286,
    tank_volume_start: 2552.099999999997,
    tank_volume_stop: 2498.199999999997,
  },
  {
    timestamp_start: '2022-01-01T13:10:30',
    timestamp_stop: '2022-01-01T13:10:36',
    quantity: 62.0,
    vehicle: 'DEM333',
    id: 137288,
    tank_volume_start: 2498.199999999997,
    tank_volume_stop: 2443.899999999997,
  },
  {
    timestamp_start: '2022-01-01T13:10:51',
    timestamp_stop: '2022-01-01T13:10:56',
    quantity: 55.0,
    vehicle: 'DEM222',
    id: 137290,
    tank_volume_start: 2443.899999999997,
    tank_volume_stop: 2391.299999999997,
  },
  {
    timestamp_start: '2022-01-01T13:11:11',
    timestamp_stop: '2022-01-01T13:11:17',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 137292,
    tank_volume_start: 2391.299999999997,
    tank_volume_stop: 2337.499999999997,
  },
  {
    timestamp_start: '2022-01-01T13:11:32',
    timestamp_stop: '2022-01-01T13:11:38',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 137294,
    tank_volume_start: 2337.499999999997,
    tank_volume_stop: 2285.799999999997,
  },
  {
    timestamp_start: '2022-01-01T13:11:53',
    timestamp_stop: '2022-01-01T13:11:58',
    quantity: 48.5,
    vehicle: 'DEM666',
    id: 137296,
    tank_volume_start: 2285.799999999997,
    tank_volume_stop: 2224.299999999997,
  },
  {
    timestamp_start: '2022-01-01T13:12:13',
    timestamp_stop: '2022-01-01T13:12:18',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 137298,
    tank_volume_start: 2224.299999999997,
    tank_volume_stop: 2169.799999999997,
  },
  {
    timestamp_start: '2022-01-01T13:12:33',
    timestamp_stop: '2022-01-01T13:12:39',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 137300,
    tank_volume_start: 2169.799999999997,
    tank_volume_stop: 2108.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:12:54',
    timestamp_stop: '2022-01-01T13:12:59',
    quantity: 45.1,
    vehicle: 'DEM222',
    id: 137302,
    tank_volume_start: 2108.099999999997,
    tank_volume_stop: 2054.299999999997,
  },
  {
    timestamp_start: '2022-01-01T13:13:14',
    timestamp_stop: '2022-01-01T13:13:20',
    quantity: 59.3,
    vehicle: 'DEM111',
    id: 137304,
    tank_volume_start: 2054.299999999997,
    tank_volume_stop: 2003.699999999997,
  },
  {
    timestamp_start: '2022-01-01T13:13:35',
    timestamp_stop: '2022-01-01T13:13:40',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 137306,
    tank_volume_start: 2003.699999999997,
    tank_volume_stop: 1946.499999999997,
  },
  {
    timestamp_start: '2022-01-01T13:13:55',
    timestamp_stop: '2022-01-01T13:14:01',
    quantity: 58.9,
    vehicle: 'DEM444',
    id: 137308,
    tank_volume_start: 3946.4999999999973,
    tank_volume_stop: 3887.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:14:16',
    timestamp_stop: '2022-01-01T13:14:22',
    quantity: 62.3,
    vehicle: 'DEM666',
    id: 137310,
    tank_volume_start: 3887.7999999999975,
    tank_volume_stop: 3836.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:14:37',
    timestamp_stop: '2022-01-01T13:14:43',
    quantity: 61.9,
    vehicle: 'DEM222',
    id: 137312,
    tank_volume_start: 3836.1999999999975,
    tank_volume_stop: 3788.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:14:58',
    timestamp_stop: '2022-01-01T13:15:03',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 137314,
    tank_volume_start: 3788.7999999999975,
    tank_volume_stop: 3729.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:15:18',
    timestamp_stop: '2022-01-01T13:15:24',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 137316,
    tank_volume_start: 3729.1999999999975,
    tank_volume_stop: 3673.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:15:39',
    timestamp_stop: '2022-01-01T13:15:45',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 137318,
    tank_volume_start: 3673.7999999999975,
    tank_volume_stop: 3623.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:16:00',
    timestamp_stop: '2022-01-01T13:16:05',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 137320,
    tank_volume_start: 3623.6999999999975,
    tank_volume_stop: 3568.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:16:20',
    timestamp_stop: '2022-01-01T13:16:26',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 137322,
    tank_volume_start: 3568.3999999999974,
    tank_volume_stop: 3515.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:16:41',
    timestamp_stop: '2022-01-01T13:16:47',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 137324,
    tank_volume_start: 3515.8999999999974,
    tank_volume_stop: 3458.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:17:02',
    timestamp_stop: '2022-01-01T13:17:07',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 137326,
    tank_volume_start: 3458.599999999997,
    tank_volume_stop: 3403.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:17:22',
    timestamp_stop: '2022-01-01T13:17:27',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 137328,
    tank_volume_start: 3403.099999999997,
    tank_volume_stop: 3350.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:17:42',
    timestamp_stop: '2022-01-01T13:17:48',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 137330,
    tank_volume_start: 3350.099999999997,
    tank_volume_stop: 3304.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:18:03',
    timestamp_stop: '2022-01-01T13:18:08',
    quantity: 52.0,
    vehicle: 'DEM555',
    id: 137332,
    tank_volume_start: 3304.8999999999974,
    tank_volume_stop: 3245.099999999997,
  },
  {
    timestamp_start: '2022-01-01T13:18:23',
    timestamp_stop: '2022-01-01T13:18:29',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 137334,
    tank_volume_start: 3245.099999999997,
    tank_volume_stop: 3189.699999999997,
  },
  {
    timestamp_start: '2022-01-01T13:18:44',
    timestamp_stop: '2022-01-01T13:18:50',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 137336,
    tank_volume_start: 3189.699999999997,
    tank_volume_stop: 3134.199999999997,
  },
  {
    timestamp_start: '2022-01-01T13:19:05',
    timestamp_stop: '2022-01-01T13:19:10',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 137338,
    tank_volume_start: 3134.199999999997,
    tank_volume_stop: 3076.299999999997,
  },
  {
    timestamp_start: '2022-01-01T13:19:25',
    timestamp_stop: '2022-01-01T13:19:31',
    quantity: 56.9,
    vehicle: 'DEM222',
    id: 137340,
    tank_volume_start: 3076.299999999997,
    tank_volume_stop: 3017.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:19:46',
    timestamp_stop: '2022-01-01T13:19:52',
    quantity: 62.5,
    vehicle: 'DEM666',
    id: 137342,
    tank_volume_start: 3017.599999999997,
    tank_volume_stop: 2953.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:20:07',
    timestamp_stop: '2022-01-01T13:20:12',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 137344,
    tank_volume_start: 2953.9999999999973,
    tank_volume_stop: 2899.699999999997,
  },
  {
    timestamp_start: '2022-01-01T13:20:27',
    timestamp_stop: '2022-01-01T13:20:33',
    quantity: 60.5,
    vehicle: 'DEM666',
    id: 137346,
    tank_volume_start: 2899.699999999997,
    tank_volume_stop: 2846.799999999997,
  },
  {
    timestamp_start: '2022-01-01T13:20:48',
    timestamp_stop: '2022-01-01T13:20:54',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 137348,
    tank_volume_start: 2846.799999999997,
    tank_volume_stop: 2797.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:21:09',
    timestamp_stop: '2022-01-01T13:21:14',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 137350,
    tank_volume_start: 2797.599999999997,
    tank_volume_stop: 2743.9999999999973,
  },
  {
    timestamp_start: '2022-01-01T13:21:29',
    timestamp_stop: '2022-01-01T13:21:34',
    quantity: 49.1,
    vehicle: 'DEM888',
    id: 137352,
    tank_volume_start: 2743.9999999999973,
    tank_volume_stop: 2685.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:21:49',
    timestamp_stop: '2022-01-01T13:21:55',
    quantity: 60.3,
    vehicle: 'DEM888',
    id: 137354,
    tank_volume_start: 2685.7999999999975,
    tank_volume_stop: 2631.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:22:10',
    timestamp_stop: '2022-01-01T13:22:16',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 137356,
    tank_volume_start: 2631.1999999999975,
    tank_volume_stop: 2572.1999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:22:31',
    timestamp_stop: '2022-01-01T13:22:37',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 137358,
    tank_volume_start: 2572.1999999999975,
    tank_volume_stop: 2515.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:22:52',
    timestamp_stop: '2022-01-01T13:22:57',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 137360,
    tank_volume_start: 2515.4999999999977,
    tank_volume_stop: 2464.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:23:12',
    timestamp_stop: '2022-01-01T13:23:18',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 137362,
    tank_volume_start: 2464.6999999999975,
    tank_volume_stop: 2410.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:23:33',
    timestamp_stop: '2022-01-01T13:23:39',
    quantity: 58.0,
    vehicle: 'DEM111',
    id: 137364,
    tank_volume_start: 2410.3999999999974,
    tank_volume_stop: 2354.8999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:23:54',
    timestamp_stop: '2022-01-01T13:23:59',
    quantity: 50.5,
    vehicle: 'DEM222',
    id: 137366,
    tank_volume_start: 2354.8999999999974,
    tank_volume_stop: 2300.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:24:14',
    timestamp_stop: '2022-01-01T13:24:19',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 137368,
    tank_volume_start: 2300.599999999997,
    tank_volume_stop: 2241.799999999997,
  },
  {
    timestamp_start: '2022-01-01T13:24:34',
    timestamp_stop: '2022-01-01T13:24:40',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 137370,
    tank_volume_start: 2241.799999999997,
    tank_volume_stop: 2181.599999999997,
  },
  {
    timestamp_start: '2022-01-01T13:24:55',
    timestamp_stop: '2022-01-01T13:25:00',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 137372,
    tank_volume_start: 2181.599999999997,
    tank_volume_stop: 2126.3999999999974,
  },
  {
    timestamp_start: '2022-01-01T13:25:15',
    timestamp_stop: '2022-01-01T13:25:20',
    quantity: 45.8,
    vehicle: 'DEM111',
    id: 137374,
    tank_volume_start: 2126.3999999999974,
    tank_volume_stop: 2067.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:25:35',
    timestamp_stop: '2022-01-01T13:25:39',
    quantity: 45.9,
    vehicle: 'DEM888',
    id: 137376,
    tank_volume_start: 2067.6999999999975,
    tank_volume_stop: 2005.7999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:25:54',
    timestamp_stop: '2022-01-01T13:25:59',
    quantity: 53.3,
    vehicle: 'DEM444',
    id: 137378,
    tank_volume_start: 2005.7999999999975,
    tank_volume_stop: 1947.6999999999975,
  },
  {
    timestamp_start: '2022-01-01T13:26:14',
    timestamp_stop: '2022-01-01T13:26:19',
    quantity: 48.0,
    vehicle: 'DEM666',
    id: 137380,
    tank_volume_start: 3947.6999999999975,
    tank_volume_stop: 3894.4999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:26:34',
    timestamp_stop: '2022-01-01T13:26:40',
    quantity: 58.1,
    vehicle: 'DEM555',
    id: 137382,
    tank_volume_start: 3894.4999999999977,
    tank_volume_stop: 3838.9999999999977,
  },
  {
    timestamp_start: '2022-01-01T13:26:55',
    timestamp_stop: '2022-01-01T13:27:01',
    quantity: 57.0,
    vehicle: 'DEM666',
    id: 137384,
    tank_volume_start: 3838.9999999999977,
    tank_volume_stop: 3787.399999999998,
  },
  {
    timestamp_start: '2022-01-01T13:27:16',
    timestamp_stop: '2022-01-01T13:27:21',
    quantity: 51.5,
    vehicle: 'DEM888',
    id: 137386,
    tank_volume_start: 3787.399999999998,
    tank_volume_stop: 3726.299999999998,
  },
  {
    timestamp_start: '2022-01-01T13:27:36',
    timestamp_stop: '2022-01-01T13:27:42',
    quantity: 62.3,
    vehicle: 'DEM777',
    id: 137388,
    tank_volume_start: 3726.299999999998,
    tank_volume_stop: 3674.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:27:57',
    timestamp_stop: '2022-01-01T13:28:02',
    quantity: 46.0,
    vehicle: 'DEM777',
    id: 137390,
    tank_volume_start: 3674.599999999998,
    tank_volume_stop: 3616.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:28:17',
    timestamp_stop: '2022-01-01T13:28:23',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 137392,
    tank_volume_start: 3616.599999999998,
    tank_volume_stop: 3560.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:28:38',
    timestamp_stop: '2022-01-01T13:28:43',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 137394,
    tank_volume_start: 3560.8999999999983,
    tank_volume_stop: 3503.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:28:58',
    timestamp_stop: '2022-01-01T13:29:04',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 137396,
    tank_volume_start: 3503.8999999999983,
    tank_volume_stop: 3448.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:29:19',
    timestamp_stop: '2022-01-01T13:29:23',
    quantity: 45.2,
    vehicle: 'DEM222',
    id: 137398,
    tank_volume_start: 3448.599999999998,
    tank_volume_stop: 3400.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:29:38',
    timestamp_stop: '2022-01-01T13:29:44',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 137400,
    tank_volume_start: 3400.099999999998,
    tank_volume_stop: 3338.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:29:59',
    timestamp_stop: '2022-01-01T13:30:05',
    quantity: 58.3,
    vehicle: 'DEM444',
    id: 137402,
    tank_volume_start: 3338.499999999998,
    tank_volume_stop: 3281.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:30:20',
    timestamp_stop: '2022-01-01T13:30:26',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 137404,
    tank_volume_start: 3281.8999999999983,
    tank_volume_stop: 3228.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:30:41',
    timestamp_stop: '2022-01-01T13:30:46',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 137406,
    tank_volume_start: 3228.3999999999983,
    tank_volume_stop: 3168.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:31:01',
    timestamp_stop: '2022-01-01T13:31:07',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 137408,
    tank_volume_start: 3168.8999999999983,
    tank_volume_stop: 3115.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:31:22',
    timestamp_stop: '2022-01-01T13:31:26',
    quantity: 47.7,
    vehicle: 'DEM444',
    id: 137410,
    tank_volume_start: 3115.999999999998,
    tank_volume_stop: 3059.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:31:41',
    timestamp_stop: '2022-01-01T13:31:48',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 137412,
    tank_volume_start: 3059.099999999998,
    tank_volume_stop: 3002.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:32:03',
    timestamp_stop: '2022-01-01T13:32:08',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 137414,
    tank_volume_start: 3002.099999999998,
    tank_volume_stop: 2946.699999999998,
  },
  {
    timestamp_start: '2022-01-01T13:32:23',
    timestamp_stop: '2022-01-01T13:32:28',
    quantity: 50.7,
    vehicle: 'DEM777',
    id: 137416,
    tank_volume_start: 2946.699999999998,
    tank_volume_stop: 2888.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:32:43',
    timestamp_stop: '2022-01-01T13:32:47',
    quantity: 47.1,
    vehicle: 'DEM222',
    id: 137418,
    tank_volume_start: 2888.599999999998,
    tank_volume_stop: 2829.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:33:02',
    timestamp_stop: '2022-01-01T13:33:08',
    quantity: 51.6,
    vehicle: 'DEM111',
    id: 137420,
    tank_volume_start: 2829.499999999998,
    tank_volume_stop: 2779.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:33:23',
    timestamp_stop: '2022-01-01T13:33:27',
    quantity: 47.0,
    vehicle: 'DEM888',
    id: 137422,
    tank_volume_start: 2779.999999999998,
    tank_volume_stop: 2722.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:33:42',
    timestamp_stop: '2022-01-01T13:33:47',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 137424,
    tank_volume_start: 2722.3999999999983,
    tank_volume_stop: 2667.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:34:02',
    timestamp_stop: '2022-01-01T13:34:07',
    quantity: 50.6,
    vehicle: 'DEM333',
    id: 137426,
    tank_volume_start: 2667.499999999998,
    tank_volume_stop: 2615.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:34:22',
    timestamp_stop: '2022-01-01T13:34:28',
    quantity: 52.6,
    vehicle: 'DEM555',
    id: 137428,
    tank_volume_start: 2615.8999999999983,
    tank_volume_stop: 2562.2999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:34:43',
    timestamp_stop: '2022-01-01T13:34:49',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 137430,
    tank_volume_start: 2562.2999999999984,
    tank_volume_stop: 2499.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:35:04',
    timestamp_stop: '2022-01-01T13:35:09',
    quantity: 54.7,
    vehicle: 'DEM333',
    id: 137432,
    tank_volume_start: 2499.3999999999983,
    tank_volume_stop: 2448.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:35:24',
    timestamp_stop: '2022-01-01T13:35:29',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 137434,
    tank_volume_start: 2448.7999999999984,
    tank_volume_stop: 2396.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:35:44',
    timestamp_stop: '2022-01-01T13:35:49',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 137436,
    tank_volume_start: 2396.6999999999985,
    tank_volume_stop: 2335.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:36:04',
    timestamp_stop: '2022-01-01T13:36:09',
    quantity: 46.0,
    vehicle: 'DEM333',
    id: 137438,
    tank_volume_start: 2335.6999999999985,
    tank_volume_stop: 2282.3999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:36:24',
    timestamp_stop: '2022-01-01T13:36:30',
    quantity: 59.2,
    vehicle: 'DEM444',
    id: 137440,
    tank_volume_start: 2282.3999999999983,
    tank_volume_stop: 2231.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:36:45',
    timestamp_stop: '2022-01-01T13:36:50',
    quantity: 59.3,
    vehicle: 'DEM000',
    id: 137442,
    tank_volume_start: 2231.499999999998,
    tank_volume_stop: 2177.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:37:05',
    timestamp_stop: '2022-01-01T13:37:11',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 137444,
    tank_volume_start: 2177.999999999998,
    tank_volume_stop: 2126.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:37:26',
    timestamp_stop: '2022-01-01T13:37:31',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 137446,
    tank_volume_start: 2126.099999999998,
    tank_volume_stop: 2076.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:37:46',
    timestamp_stop: '2022-01-01T13:37:52',
    quantity: 61.5,
    vehicle: 'DEM777',
    id: 137448,
    tank_volume_start: 2076.599999999998,
    tank_volume_stop: 2014.399999999998,
  },
  {
    timestamp_start: '2022-01-01T13:38:07',
    timestamp_stop: '2022-01-01T13:38:12',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 137450,
    tank_volume_start: 2014.399999999998,
    tank_volume_stop: 1962.2999999999981,
  },
  {
    timestamp_start: '2022-01-01T13:38:27',
    timestamp_stop: '2022-01-01T13:38:32',
    quantity: 49.1,
    vehicle: 'DEM444',
    id: 137452,
    tank_volume_start: 3962.2999999999984,
    tank_volume_stop: 3906.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:38:47',
    timestamp_stop: '2022-01-01T13:38:52',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 137454,
    tank_volume_start: 3906.5999999999985,
    tank_volume_stop: 3853.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T13:39:07',
    timestamp_stop: '2022-01-01T13:39:12',
    quantity: 49.1,
    vehicle: 'DEM111',
    id: 137456,
    tank_volume_start: 3853.3999999999987,
    tank_volume_stop: 3802.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:39:27',
    timestamp_stop: '2022-01-01T13:39:32',
    quantity: 49.0,
    vehicle: 'DEM888',
    id: 137458,
    tank_volume_start: 3802.4999999999986,
    tank_volume_stop: 3741.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T13:39:47',
    timestamp_stop: '2022-01-01T13:39:53',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 137460,
    tank_volume_start: 3741.8999999999987,
    tank_volume_stop: 3684.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:40:08',
    timestamp_stop: '2022-01-01T13:40:14',
    quantity: 61.9,
    vehicle: 'DEM666',
    id: 137462,
    tank_volume_start: 3684.5999999999985,
    tank_volume_stop: 3634.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:40:29',
    timestamp_stop: '2022-01-01T13:40:34',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 137464,
    tank_volume_start: 3634.1999999999985,
    tank_volume_stop: 3577.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:40:49',
    timestamp_stop: '2022-01-01T13:40:54',
    quantity: 47.9,
    vehicle: 'DEM444',
    id: 137466,
    tank_volume_start: 3577.7999999999984,
    tank_volume_stop: 3526.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:41:09',
    timestamp_stop: '2022-01-01T13:41:15',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 137467,
    tank_volume_start: 3526.5999999999985,
    tank_volume_stop: 3469.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:41:30',
    timestamp_stop: '2022-01-01T13:41:35',
    quantity: 53.6,
    vehicle: 'DEM222',
    id: 137469,
    tank_volume_start: 3469.7999999999984,
    tank_volume_stop: 3409.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:41:50',
    timestamp_stop: '2022-01-01T13:41:56',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 137472,
    tank_volume_start: 3409.8999999999983,
    tank_volume_stop: 3350.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:42:11',
    timestamp_stop: '2022-01-01T13:42:16',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 137474,
    tank_volume_start: 3350.7999999999984,
    tank_volume_stop: 3294.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:42:31',
    timestamp_stop: '2022-01-01T13:42:37',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 137476,
    tank_volume_start: 3294.7999999999984,
    tank_volume_stop: 3245.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:42:52',
    timestamp_stop: '2022-01-01T13:42:57',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 137478,
    tank_volume_start: 3245.1999999999985,
    tank_volume_stop: 3197.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:43:12',
    timestamp_stop: '2022-01-01T13:43:18',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 137480,
    tank_volume_start: 3197.0999999999985,
    tank_volume_stop: 3143.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:43:33',
    timestamp_stop: '2022-01-01T13:43:38',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 137482,
    tank_volume_start: 3143.7999999999984,
    tank_volume_stop: 3093.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:43:53',
    timestamp_stop: '2022-01-01T13:43:59',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 137484,
    tank_volume_start: 3093.0999999999985,
    tank_volume_stop: 3031.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:44:14',
    timestamp_stop: '2022-01-01T13:44:20',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 137486,
    tank_volume_start: 3031.4999999999986,
    tank_volume_stop: 2982.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:44:35',
    timestamp_stop: '2022-01-01T13:44:40',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 137488,
    tank_volume_start: 2982.0999999999985,
    tank_volume_stop: 2927.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:44:55',
    timestamp_stop: '2022-01-01T13:45:01',
    quantity: 59.3,
    vehicle: 'DEM444',
    id: 137490,
    tank_volume_start: 2927.1999999999985,
    tank_volume_stop: 2879.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:45:16',
    timestamp_stop: '2022-01-01T13:45:22',
    quantity: 61.9,
    vehicle: 'DEM666',
    id: 137492,
    tank_volume_start: 2879.4999999999986,
    tank_volume_stop: 2816.299999999999,
  },
  {
    timestamp_start: '2022-01-01T13:45:37',
    timestamp_stop: '2022-01-01T13:45:43',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 137494,
    tank_volume_start: 2816.299999999999,
    tank_volume_stop: 2759.699999999999,
  },
  {
    timestamp_start: '2022-01-01T13:45:58',
    timestamp_stop: '2022-01-01T13:46:03',
    quantity: 50.1,
    vehicle: 'DEM444',
    id: 137496,
    tank_volume_start: 2759.699999999999,
    tank_volume_stop: 2707.499999999999,
  },
  {
    timestamp_start: '2022-01-01T13:46:18',
    timestamp_stop: '2022-01-01T13:46:23',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 137498,
    tank_volume_start: 2707.499999999999,
    tank_volume_stop: 2654.699999999999,
  },
  {
    timestamp_start: '2022-01-01T13:46:38',
    timestamp_stop: '2022-01-01T13:46:44',
    quantity: 64.0,
    vehicle: 'DEM111',
    id: 137500,
    tank_volume_start: 2654.699999999999,
    tank_volume_stop: 2597.599999999999,
  },
  {
    timestamp_start: '2022-01-01T13:46:59',
    timestamp_stop: '2022-01-01T13:47:05',
    quantity: 56.4,
    vehicle: 'DEM777',
    id: 137502,
    tank_volume_start: 2597.599999999999,
    tank_volume_stop: 2539.299999999999,
  },
  {
    timestamp_start: '2022-01-01T13:47:20',
    timestamp_stop: '2022-01-01T13:47:26',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 137504,
    tank_volume_start: 2539.299999999999,
    tank_volume_stop: 2484.799999999999,
  },
  {
    timestamp_start: '2022-01-01T13:47:41',
    timestamp_stop: '2022-01-01T13:47:46',
    quantity: 57.9,
    vehicle: 'DEM111',
    id: 137506,
    tank_volume_start: 2484.799999999999,
    tank_volume_stop: 2430.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:48:01',
    timestamp_stop: '2022-01-01T13:48:06',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 137508,
    tank_volume_start: 2430.9999999999986,
    tank_volume_stop: 2382.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:48:21',
    timestamp_stop: '2022-01-01T13:48:28',
    quantity: 60.8,
    vehicle: 'DEM777',
    id: 137510,
    tank_volume_start: 2382.5999999999985,
    tank_volume_stop: 2318.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:48:43',
    timestamp_stop: '2022-01-01T13:48:49',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 137512,
    tank_volume_start: 2318.4999999999986,
    tank_volume_stop: 2257.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:49:04',
    timestamp_stop: '2022-01-01T13:49:09',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 137514,
    tank_volume_start: 2257.4999999999986,
    tank_volume_stop: 2202.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:49:24',
    timestamp_stop: '2022-01-01T13:49:29',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 137516,
    tank_volume_start: 2202.0999999999985,
    tank_volume_stop: 2148.9999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:49:44',
    timestamp_stop: '2022-01-01T13:49:49',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 137518,
    tank_volume_start: 2148.9999999999986,
    tank_volume_stop: 2103.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:50:04',
    timestamp_stop: '2022-01-01T13:50:10',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 137520,
    tank_volume_start: 2103.0999999999985,
    tank_volume_stop: 2040.3999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:50:25',
    timestamp_stop: '2022-01-01T13:50:30',
    quantity: 55.0,
    vehicle: 'DEM555',
    id: 137522,
    tank_volume_start: 2040.3999999999985,
    tank_volume_stop: 1983.6999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:50:45',
    timestamp_stop: '2022-01-01T13:50:50',
    quantity: 46.1,
    vehicle: 'DEM555',
    id: 137524,
    tank_volume_start: 3983.6999999999985,
    tank_volume_stop: 3921.5999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:51:05',
    timestamp_stop: '2022-01-01T13:51:10',
    quantity: 46.3,
    vehicle: 'DEM111',
    id: 137526,
    tank_volume_start: 3921.5999999999985,
    tank_volume_stop: 3868.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:51:25',
    timestamp_stop: '2022-01-01T13:51:30',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 137527,
    tank_volume_start: 3868.1999999999985,
    tank_volume_stop: 3811.8999999999983,
  },
  {
    timestamp_start: '2022-01-01T13:51:45',
    timestamp_stop: '2022-01-01T13:51:52',
    quantity: 61.7,
    vehicle: 'DEM666',
    id: 137530,
    tank_volume_start: 3811.8999999999983,
    tank_volume_stop: 3747.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:52:07',
    timestamp_stop: '2022-01-01T13:52:12',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 137532,
    tank_volume_start: 3747.7999999999984,
    tank_volume_stop: 3690.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:52:27',
    timestamp_stop: '2022-01-01T13:52:33',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 137534,
    tank_volume_start: 3690.499999999998,
    tank_volume_stop: 3641.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:52:48',
    timestamp_stop: '2022-01-01T13:52:53',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 137536,
    tank_volume_start: 3641.999999999998,
    tank_volume_stop: 3590.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:53:08',
    timestamp_stop: '2022-01-01T13:53:13',
    quantity: 45.3,
    vehicle: 'DEM666',
    id: 137538,
    tank_volume_start: 3590.599999999998,
    tank_volume_stop: 3539.699999999998,
  },
  {
    timestamp_start: '2022-01-01T13:53:28',
    timestamp_stop: '2022-01-01T13:53:33',
    quantity: 54.0,
    vehicle: 'DEM000',
    id: 137540,
    tank_volume_start: 3539.699999999998,
    tank_volume_stop: 3480.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:53:48',
    timestamp_stop: '2022-01-01T13:53:53',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 137542,
    tank_volume_start: 3480.099999999998,
    tank_volume_stop: 3420.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:54:08',
    timestamp_stop: '2022-01-01T13:54:14',
    quantity: 55.6,
    vehicle: 'DEM555',
    id: 137544,
    tank_volume_start: 3420.099999999998,
    tank_volume_stop: 3371.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:54:29',
    timestamp_stop: '2022-01-01T13:54:34',
    quantity: 55.7,
    vehicle: 'DEM888',
    id: 137545,
    tank_volume_start: 3371.999999999998,
    tank_volume_stop: 3322.599999999998,
  },
  {
    timestamp_start: '2022-01-01T13:54:49',
    timestamp_stop: '2022-01-01T13:54:55',
    quantity: 50.7,
    vehicle: 'DEM222',
    id: 137547,
    tank_volume_start: 3322.599999999998,
    tank_volume_stop: 3263.499999999998,
  },
  {
    timestamp_start: '2022-01-01T13:55:10',
    timestamp_stop: '2022-01-01T13:55:15',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 137549,
    tank_volume_start: 3263.499999999998,
    tank_volume_stop: 3204.099999999998,
  },
  {
    timestamp_start: '2022-01-01T13:55:30',
    timestamp_stop: '2022-01-01T13:55:35',
    quantity: 49.1,
    vehicle: 'DEM333',
    id: 137552,
    tank_volume_start: 3204.099999999998,
    tank_volume_stop: 3146.999999999998,
  },
  {
    timestamp_start: '2022-01-01T13:55:50',
    timestamp_stop: '2022-01-01T13:55:55',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 137553,
    tank_volume_start: 3146.999999999998,
    tank_volume_stop: 3094.7999999999984,
  },
  {
    timestamp_start: '2022-01-01T13:56:10',
    timestamp_stop: '2022-01-01T13:56:16',
    quantity: 62.1,
    vehicle: 'DEM222',
    id: 137555,
    tank_volume_start: 3094.7999999999984,
    tank_volume_stop: 3047.1999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:56:31',
    timestamp_stop: '2022-01-01T13:56:37',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 137557,
    tank_volume_start: 3047.1999999999985,
    tank_volume_stop: 2990.0999999999985,
  },
  {
    timestamp_start: '2022-01-01T13:56:52',
    timestamp_stop: '2022-01-01T13:56:58',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 137559,
    tank_volume_start: 2990.0999999999985,
    tank_volume_stop: 2932.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:57:13',
    timestamp_stop: '2022-01-01T13:57:19',
    quantity: 60.6,
    vehicle: 'DEM666',
    id: 137561,
    tank_volume_start: 2932.4999999999986,
    tank_volume_stop: 2878.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T13:57:34',
    timestamp_stop: '2022-01-01T13:57:39',
    quantity: 53.8,
    vehicle: 'DEM555',
    id: 137564,
    tank_volume_start: 2878.8999999999987,
    tank_volume_stop: 2819.699999999999,
  },
  {
    timestamp_start: '2022-01-01T13:57:54',
    timestamp_stop: '2022-01-01T13:57:59',
    quantity: 47.3,
    vehicle: 'DEM000',
    id: 137566,
    tank_volume_start: 2819.699999999999,
    tank_volume_stop: 2765.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T13:58:14',
    timestamp_stop: '2022-01-01T13:58:20',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 137568,
    tank_volume_start: 2765.3999999999987,
    tank_volume_stop: 2713.299999999999,
  },
  {
    timestamp_start: '2022-01-01T13:58:35',
    timestamp_stop: '2022-01-01T13:58:40',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 137569,
    tank_volume_start: 2713.299999999999,
    tank_volume_stop: 2661.4999999999986,
  },
  {
    timestamp_start: '2022-01-01T13:58:55',
    timestamp_stop: '2022-01-01T13:59:00',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 137572,
    tank_volume_start: 2661.4999999999986,
    tank_volume_stop: 2611.299999999999,
  },
  {
    timestamp_start: '2022-01-01T13:59:15',
    timestamp_stop: '2022-01-01T13:59:21',
    quantity: 61.6,
    vehicle: 'DEM444',
    id: 137573,
    tank_volume_start: 2611.299999999999,
    tank_volume_stop: 2547.3999999999987,
  },
  {
    timestamp_start: '2022-01-01T13:59:36',
    timestamp_stop: '2022-01-01T13:59:42',
    quantity: 51.9,
    vehicle: 'DEM111',
    id: 137576,
    tank_volume_start: 2547.3999999999987,
    tank_volume_stop: 2499.199999999999,
  },
  {
    timestamp_start: '2022-01-01T13:59:57',
    timestamp_stop: '2022-01-01T14:00:03',
    quantity: 64.6,
    vehicle: 'DEM555',
    id: 137577,
    tank_volume_start: 2499.199999999999,
    tank_volume_stop: 2441.199999999999,
  },
  {
    timestamp_start: '2022-01-01T14:00:18',
    timestamp_stop: '2022-01-01T14:00:23',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 137580,
    tank_volume_start: 2441.199999999999,
    tank_volume_stop: 2386.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:00:38',
    timestamp_stop: '2022-01-01T14:00:44',
    quantity: 59.4,
    vehicle: 'DEM666',
    id: 137582,
    tank_volume_start: 2386.599999999999,
    tank_volume_stop: 2324.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:00:59',
    timestamp_stop: '2022-01-01T14:01:04',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 137584,
    tank_volume_start: 2324.499999999999,
    tank_volume_stop: 2273.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:01:19',
    timestamp_stop: '2022-01-01T14:01:25',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 137586,
    tank_volume_start: 2273.699999999999,
    tank_volume_stop: 2217.199999999999,
  },
  {
    timestamp_start: '2022-01-01T14:01:40',
    timestamp_stop: '2022-01-01T14:01:45',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 137588,
    tank_volume_start: 2217.199999999999,
    tank_volume_stop: 2163.8999999999987,
  },
  {
    timestamp_start: '2022-01-01T14:02:00',
    timestamp_stop: '2022-01-01T14:02:05',
    quantity: 56.6,
    vehicle: 'DEM000',
    id: 137590,
    tank_volume_start: 2163.8999999999987,
    tank_volume_stop: 2110.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:02:20',
    timestamp_stop: '2022-01-01T14:02:25',
    quantity: 45.1,
    vehicle: 'DEM888',
    id: 137592,
    tank_volume_start: 2110.699999999999,
    tank_volume_stop: 2063.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:02:40',
    timestamp_stop: '2022-01-01T14:02:46',
    quantity: 60.9,
    vehicle: 'DEM666',
    id: 137594,
    tank_volume_start: 2063.599999999999,
    tank_volume_stop: 2009.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:03:01',
    timestamp_stop: '2022-01-01T14:03:07',
    quantity: 55.7,
    vehicle: 'DEM000',
    id: 137596,
    tank_volume_start: 2009.999999999999,
    tank_volume_stop: 1950.299999999999,
  },
  {
    timestamp_start: '2022-01-01T14:03:22',
    timestamp_stop: '2022-01-01T14:03:26',
    quantity: 48.2,
    vehicle: 'DEM000',
    id: 137597,
    tank_volume_start: 3950.2999999999993,
    tank_volume_stop: 3897.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:03:41',
    timestamp_stop: '2022-01-01T14:03:46',
    quantity: 49.0,
    vehicle: 'DEM777',
    id: 137599,
    tank_volume_start: 3897.999999999999,
    tank_volume_stop: 3838.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:04:01',
    timestamp_stop: '2022-01-01T14:04:06',
    quantity: 51.7,
    vehicle: 'DEM000',
    id: 137601,
    tank_volume_start: 3838.499999999999,
    tank_volume_stop: 3777.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:04:21',
    timestamp_stop: '2022-01-01T14:04:28',
    quantity: 63.4,
    vehicle: 'DEM666',
    id: 137603,
    tank_volume_start: 3777.999999999999,
    tank_volume_stop: 3714.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:04:43',
    timestamp_stop: '2022-01-01T14:04:48',
    quantity: 49.2,
    vehicle: 'DEM000',
    id: 137605,
    tank_volume_start: 3714.899999999999,
    tank_volume_stop: 3658.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:05:03',
    timestamp_stop: '2022-01-01T14:05:08',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 137607,
    tank_volume_start: 3658.1999999999994,
    tank_volume_stop: 3610.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:05:23',
    timestamp_stop: '2022-01-01T14:05:29',
    quantity: 58.3,
    vehicle: 'DEM000',
    id: 137609,
    tank_volume_start: 3610.9999999999995,
    tank_volume_stop: 3552.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:05:44',
    timestamp_stop: '2022-01-01T14:05:49',
    quantity: 52.3,
    vehicle: 'DEM000',
    id: 137611,
    tank_volume_start: 3552.1999999999994,
    tank_volume_stop: 3501.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:06:04',
    timestamp_stop: '2022-01-01T14:06:09',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 137613,
    tank_volume_start: 3501.399999999999,
    tank_volume_stop: 3449.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:06:24',
    timestamp_stop: '2022-01-01T14:06:30',
    quantity: 58.3,
    vehicle: 'DEM000',
    id: 137615,
    tank_volume_start: 3449.7999999999993,
    tank_volume_stop: 3402.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:06:45',
    timestamp_stop: '2022-01-01T14:06:51',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 137617,
    tank_volume_start: 3402.0999999999995,
    tank_volume_stop: 3344.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:07:06',
    timestamp_stop: '2022-01-01T14:07:12',
    quantity: 57.3,
    vehicle: 'DEM555',
    id: 137619,
    tank_volume_start: 3344.7999999999993,
    tank_volume_stop: 3287.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:07:27',
    timestamp_stop: '2022-01-01T14:07:31',
    quantity: 49.3,
    vehicle: 'DEM000',
    id: 137621,
    tank_volume_start: 3287.899999999999,
    tank_volume_stop: 3225.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:07:46',
    timestamp_stop: '2022-01-01T14:07:53',
    quantity: 60.8,
    vehicle: 'DEM555',
    id: 137623,
    tank_volume_start: 3225.899999999999,
    tank_volume_stop: 3171.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:08:08',
    timestamp_stop: '2022-01-01T14:08:13',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 137625,
    tank_volume_start: 3171.1999999999994,
    tank_volume_stop: 3118.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:08:28',
    timestamp_stop: '2022-01-01T14:08:33',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 137627,
    tank_volume_start: 3118.2999999999993,
    tank_volume_stop: 3063.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:08:48',
    timestamp_stop: '2022-01-01T14:08:54',
    quantity: 54.1,
    vehicle: 'DEM111',
    id: 137629,
    tank_volume_start: 3063.399999999999,
    tank_volume_stop: 3001.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:09:09',
    timestamp_stop: '2022-01-01T14:09:15',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 137631,
    tank_volume_start: 3001.2999999999993,
    tank_volume_stop: 2950.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:09:30',
    timestamp_stop: '2022-01-01T14:09:36',
    quantity: 59.5,
    vehicle: 'DEM111',
    id: 137633,
    tank_volume_start: 2950.399999999999,
    tank_volume_stop: 2899.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:09:51',
    timestamp_stop: '2022-01-01T14:09:56',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 137635,
    tank_volume_start: 2899.399999999999,
    tank_volume_stop: 2846.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:10:11',
    timestamp_stop: '2022-01-01T14:10:17',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 137637,
    tank_volume_start: 2846.6999999999994,
    tank_volume_stop: 2789.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:10:32',
    timestamp_stop: '2022-01-01T14:10:37',
    quantity: 49.6,
    vehicle: 'DEM555',
    id: 137639,
    tank_volume_start: 2789.6999999999994,
    tank_volume_stop: 2729.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:10:52',
    timestamp_stop: '2022-01-01T14:10:58',
    quantity: 62.4,
    vehicle: 'DEM666',
    id: 137641,
    tank_volume_start: 2729.5999999999995,
    tank_volume_stop: 2673.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:11:13',
    timestamp_stop: '2022-01-01T14:11:18',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 137644,
    tank_volume_start: 2673.5999999999995,
    tank_volume_stop: 2622.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:11:33',
    timestamp_stop: '2022-01-01T14:11:40',
    quantity: 64.2,
    vehicle: 'DEM222',
    id: 137645,
    tank_volume_start: 2622.0999999999995,
    tank_volume_stop: 2571.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:11:55',
    timestamp_stop: '2022-01-01T14:12:00',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 137648,
    tank_volume_start: 2571.5999999999995,
    tank_volume_stop: 2520.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:12:15',
    timestamp_stop: '2022-01-01T14:12:21',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 137650,
    tank_volume_start: 2520.7999999999993,
    tank_volume_stop: 2459.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:12:36',
    timestamp_stop: '2022-01-01T14:12:40',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 137652,
    tank_volume_start: 2459.499999999999,
    tank_volume_stop: 2407.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:12:55',
    timestamp_stop: '2022-01-01T14:13:01',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 137653,
    tank_volume_start: 2407.2999999999993,
    tank_volume_stop: 2351.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:13:16',
    timestamp_stop: '2022-01-01T14:13:21',
    quantity: 51.1,
    vehicle: 'DEM444',
    id: 137655,
    tank_volume_start: 2351.7999999999993,
    tank_volume_stop: 2294.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:13:36',
    timestamp_stop: '2022-01-01T14:13:41',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 137657,
    tank_volume_start: 2294.7999999999993,
    tank_volume_stop: 2237.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:13:56',
    timestamp_stop: '2022-01-01T14:14:01',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 137660,
    tank_volume_start: 2237.0999999999995,
    tank_volume_stop: 2187.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:14:16',
    timestamp_stop: '2022-01-01T14:14:21',
    quantity: 48.5,
    vehicle: 'DEM555',
    id: 137661,
    tank_volume_start: 2187.5999999999995,
    tank_volume_stop: 2135.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:14:36',
    timestamp_stop: '2022-01-01T14:14:41',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 137663,
    tank_volume_start: 2135.6999999999994,
    tank_volume_stop: 2088.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:14:56',
    timestamp_stop: '2022-01-01T14:15:03',
    quantity: 64.0,
    vehicle: 'DEM666',
    id: 137665,
    tank_volume_start: 2088.1999999999994,
    tank_volume_stop: 2025.8999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:15:18',
    timestamp_stop: '2022-01-01T14:15:24',
    quantity: 61.0,
    vehicle: 'DEM666',
    id: 137668,
    tank_volume_start: 2025.8999999999994,
    tank_volume_stop: 1966.3999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:15:39',
    timestamp_stop: '2022-01-01T14:15:44',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 137670,
    tank_volume_start: 3966.3999999999996,
    tank_volume_stop: 3917.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:15:59',
    timestamp_stop: '2022-01-01T14:16:05',
    quantity: 59.2,
    vehicle: 'DEM666',
    id: 137672,
    tank_volume_start: 3917.5999999999995,
    tank_volume_stop: 3868.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:16:20',
    timestamp_stop: '2022-01-01T14:16:25',
    quantity: 47.9,
    vehicle: 'DEM222',
    id: 137674,
    tank_volume_start: 3868.0999999999995,
    tank_volume_stop: 3816.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:16:40',
    timestamp_stop: '2022-01-01T14:16:46',
    quantity: 59.9,
    vehicle: 'DEM111',
    id: 137676,
    tank_volume_start: 3816.3999999999996,
    tank_volume_stop: 3762.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:17:01',
    timestamp_stop: '2022-01-01T14:17:06',
    quantity: 53.3,
    vehicle: 'DEM333',
    id: 137678,
    tank_volume_start: 3762.9999999999995,
    tank_volume_stop: 3715.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:17:21',
    timestamp_stop: '2022-01-01T14:17:27',
    quantity: 62.8,
    vehicle: 'DEM555',
    id: 137680,
    tank_volume_start: 3715.1999999999994,
    tank_volume_stop: 3659.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:17:42',
    timestamp_stop: '2022-01-01T14:17:48',
    quantity: 57.8,
    vehicle: 'DEM222',
    id: 137682,
    tank_volume_start: 3659.4999999999995,
    tank_volume_stop: 3600.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:18:03',
    timestamp_stop: '2022-01-01T14:18:09',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 137684,
    tank_volume_start: 3600.5999999999995,
    tank_volume_stop: 3539.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:18:24',
    timestamp_stop: '2022-01-01T14:18:29',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 137686,
    tank_volume_start: 3539.4999999999995,
    tank_volume_stop: 3488.8999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:18:44',
    timestamp_stop: '2022-01-01T14:18:49',
    quantity: 45.9,
    vehicle: 'DEM444',
    id: 137688,
    tank_volume_start: 3488.8999999999996,
    tank_volume_stop: 3440.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:19:04',
    timestamp_stop: '2022-01-01T14:19:10',
    quantity: 58.8,
    vehicle: 'DEM333',
    id: 137690,
    tank_volume_start: 3440.4999999999995,
    tank_volume_stop: 3388.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:19:25',
    timestamp_stop: '2022-01-01T14:19:30',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 137692,
    tank_volume_start: 3388.2999999999997,
    tank_volume_stop: 3331.1,
  },
  {
    timestamp_start: '2022-01-01T14:19:45',
    timestamp_stop: '2022-01-01T14:19:51',
    quantity: 52.9,
    vehicle: 'DEM666',
    id: 137694,
    tank_volume_start: 3331.1,
    tank_volume_stop: 3273.2,
  },
  {
    timestamp_start: '2022-01-01T14:20:06',
    timestamp_stop: '2022-01-01T14:20:12',
    quantity: 62.1,
    vehicle: 'DEM666',
    id: 137696,
    tank_volume_start: 3273.2,
    tank_volume_stop: 3213.2,
  },
  {
    timestamp_start: '2022-01-01T14:20:27',
    timestamp_stop: '2022-01-01T14:20:32',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 137698,
    tank_volume_start: 3213.2,
    tank_volume_stop: 3152.1,
  },
  {
    timestamp_start: '2022-01-01T14:20:47',
    timestamp_stop: '2022-01-01T14:20:53',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 137700,
    tank_volume_start: 3152.1,
    tank_volume_stop: 3106.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:21:08',
    timestamp_stop: '2022-01-01T14:21:13',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 137702,
    tank_volume_start: 3106.2999999999997,
    tank_volume_stop: 3054.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:21:28',
    timestamp_stop: '2022-01-01T14:21:33',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 137704,
    tank_volume_start: 3054.2999999999997,
    tank_volume_stop: 2997.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:21:48',
    timestamp_stop: '2022-01-01T14:21:54',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 137706,
    tank_volume_start: 2997.9999999999995,
    tank_volume_stop: 2944.7999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:22:09',
    timestamp_stop: '2022-01-01T14:22:14',
    quantity: 51.1,
    vehicle: 'DEM333',
    id: 137708,
    tank_volume_start: 2944.7999999999997,
    tank_volume_stop: 2886.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:22:29',
    timestamp_stop: '2022-01-01T14:22:34',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 137710,
    tank_volume_start: 2886.3999999999996,
    tank_volume_stop: 2840.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:22:49',
    timestamp_stop: '2022-01-01T14:22:55',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 137712,
    tank_volume_start: 2840.3999999999996,
    tank_volume_stop: 2787.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:23:10',
    timestamp_stop: '2022-01-01T14:23:15',
    quantity: 49.1,
    vehicle: 'DEM222',
    id: 137714,
    tank_volume_start: 2787.9999999999995,
    tank_volume_stop: 2739.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:23:30',
    timestamp_stop: '2022-01-01T14:23:35',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 137716,
    tank_volume_start: 2739.6999999999994,
    tank_volume_stop: 2686.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:23:50',
    timestamp_stop: '2022-01-01T14:23:56',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 137718,
    tank_volume_start: 2686.9999999999995,
    tank_volume_stop: 2628.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:24:11',
    timestamp_stop: '2022-01-01T14:24:16',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 137720,
    tank_volume_start: 2628.3999999999996,
    tank_volume_stop: 2572.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:24:31',
    timestamp_stop: '2022-01-01T14:24:37',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 137722,
    tank_volume_start: 2572.2999999999997,
    tank_volume_stop: 2520.2,
  },
  {
    timestamp_start: '2022-01-01T14:24:52',
    timestamp_stop: '2022-01-01T14:24:58',
    quantity: 59.8,
    vehicle: 'DEM000',
    id: 137724,
    tank_volume_start: 2520.2,
    tank_volume_stop: 2468.6,
  },
  {
    timestamp_start: '2022-01-01T14:25:13',
    timestamp_stop: '2022-01-01T14:25:18',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 137726,
    tank_volume_start: 2468.6,
    tank_volume_stop: 2414.5,
  },
  {
    timestamp_start: '2022-01-01T14:25:33',
    timestamp_stop: '2022-01-01T14:25:39',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 137728,
    tank_volume_start: 2414.5,
    tank_volume_stop: 2361.5,
  },
  {
    timestamp_start: '2022-01-01T14:25:54',
    timestamp_stop: '2022-01-01T14:25:59',
    quantity: 47.7,
    vehicle: 'DEM333',
    id: 137730,
    tank_volume_start: 2361.5,
    tank_volume_stop: 2306.3,
  },
  {
    timestamp_start: '2022-01-01T14:26:14',
    timestamp_stop: '2022-01-01T14:26:19',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 137732,
    tank_volume_start: 2306.3,
    tank_volume_stop: 2244.8,
  },
  {
    timestamp_start: '2022-01-01T14:26:34',
    timestamp_stop: '2022-01-01T14:26:39',
    quantity: 55.1,
    vehicle: 'DEM555',
    id: 137734,
    tank_volume_start: 2244.8,
    tank_volume_stop: 2191.3,
  },
  {
    timestamp_start: '2022-01-01T14:26:54',
    timestamp_stop: '2022-01-01T14:27:00',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 137736,
    tank_volume_start: 2191.3,
    tank_volume_stop: 2138.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T14:27:15',
    timestamp_stop: '2022-01-01T14:27:19',
    quantity: 47.4,
    vehicle: 'DEM111',
    id: 137738,
    tank_volume_start: 2138.1000000000004,
    tank_volume_stop: 2083.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T14:27:34',
    timestamp_stop: '2022-01-01T14:27:40',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 137740,
    tank_volume_start: 2083.6000000000004,
    tank_volume_stop: 2035.8000000000004,
  },
  {
    timestamp_start: '2022-01-01T14:27:55',
    timestamp_stop: '2022-01-01T14:28:01',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 137742,
    tank_volume_start: 2035.8000000000004,
    tank_volume_stop: 1987.8000000000004,
  },
  {
    timestamp_start: '2022-01-01T14:28:16',
    timestamp_stop: '2022-01-01T14:28:21',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 137744,
    tank_volume_start: 3987.8,
    tank_volume_stop: 3930.3,
  },
  {
    timestamp_start: '2022-01-01T14:28:36',
    timestamp_stop: '2022-01-01T14:28:42',
    quantity: 52.9,
    vehicle: 'DEM888',
    id: 137746,
    tank_volume_start: 3930.3,
    tank_volume_stop: 3879.5,
  },
  {
    timestamp_start: '2022-01-01T14:28:57',
    timestamp_stop: '2022-01-01T14:29:02',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 137748,
    tank_volume_start: 3879.5,
    tank_volume_stop: 3824.6,
  },
  {
    timestamp_start: '2022-01-01T14:29:17',
    timestamp_stop: '2022-01-01T14:29:23',
    quantity: 60.2,
    vehicle: 'DEM555',
    id: 137750,
    tank_volume_start: 3824.6,
    tank_volume_stop: 3772.9,
  },
  {
    timestamp_start: '2022-01-01T14:29:38',
    timestamp_stop: '2022-01-01T14:29:44',
    quantity: 60.9,
    vehicle: 'DEM111',
    id: 137752,
    tank_volume_start: 3772.9,
    tank_volume_stop: 3708.6,
  },
  {
    timestamp_start: '2022-01-01T14:29:59',
    timestamp_stop: '2022-01-01T14:30:05',
    quantity: 54.2,
    vehicle: 'DEM444',
    id: 137754,
    tank_volume_start: 3708.6,
    tank_volume_stop: 3659.2,
  },
  {
    timestamp_start: '2022-01-01T14:30:20',
    timestamp_stop: '2022-01-01T14:30:25',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 137756,
    tank_volume_start: 3659.2,
    tank_volume_stop: 3610.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:30:40',
    timestamp_stop: '2022-01-01T14:30:46',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 137758,
    tank_volume_start: 3610.2999999999997,
    tank_volume_stop: 3553.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:31:01',
    timestamp_stop: '2022-01-01T14:31:06',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 137760,
    tank_volume_start: 3553.4999999999995,
    tank_volume_stop: 3502.8999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:31:21',
    timestamp_stop: '2022-01-01T14:31:28',
    quantity: 62.3,
    vehicle: 'DEM666',
    id: 137762,
    tank_volume_start: 3502.8999999999996,
    tank_volume_stop: 3446.7999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:31:43',
    timestamp_stop: '2022-01-01T14:31:48',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 137764,
    tank_volume_start: 3446.7999999999997,
    tank_volume_stop: 3397.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:32:03',
    timestamp_stop: '2022-01-01T14:32:09',
    quantity: 60.3,
    vehicle: 'DEM888',
    id: 137766,
    tank_volume_start: 3397.2999999999997,
    tank_volume_stop: 3344.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:32:24',
    timestamp_stop: '2022-01-01T14:32:29',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 137768,
    tank_volume_start: 3344.4999999999995,
    tank_volume_stop: 3291.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T14:32:44',
    timestamp_stop: '2022-01-01T14:32:50',
    quantity: 61.2,
    vehicle: 'DEM666',
    id: 137770,
    tank_volume_start: 3291.2999999999997,
    tank_volume_stop: 3232.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:33:05',
    timestamp_stop: '2022-01-01T14:33:11',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 137772,
    tank_volume_start: 3232.9999999999995,
    tank_volume_stop: 3174.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:33:26',
    timestamp_stop: '2022-01-01T14:33:31',
    quantity: 47.9,
    vehicle: 'DEM333',
    id: 137774,
    tank_volume_start: 3174.1999999999994,
    tank_volume_stop: 3123.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:33:46',
    timestamp_stop: '2022-01-01T14:33:51',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 137776,
    tank_volume_start: 3123.1999999999994,
    tank_volume_stop: 3063.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:34:06',
    timestamp_stop: '2022-01-01T14:34:12',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 137778,
    tank_volume_start: 3063.399999999999,
    tank_volume_stop: 3011.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:34:27',
    timestamp_stop: '2022-01-01T14:34:32',
    quantity: 49.3,
    vehicle: 'DEM333',
    id: 137780,
    tank_volume_start: 3011.399999999999,
    tank_volume_stop: 2957.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:34:47',
    timestamp_stop: '2022-01-01T14:34:52',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 137782,
    tank_volume_start: 2957.899999999999,
    tank_volume_stop: 2905.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:35:07',
    timestamp_stop: '2022-01-01T14:35:13',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 137784,
    tank_volume_start: 2905.6999999999994,
    tank_volume_stop: 2849.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:35:28',
    timestamp_stop: '2022-01-01T14:35:34',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 137786,
    tank_volume_start: 2849.9999999999995,
    tank_volume_stop: 2801.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:35:49',
    timestamp_stop: '2022-01-01T14:35:54',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 137788,
    tank_volume_start: 2801.5999999999995,
    tank_volume_stop: 2748.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:36:09',
    timestamp_stop: '2022-01-01T14:36:15',
    quantity: 59.3,
    vehicle: 'DEM666',
    id: 137790,
    tank_volume_start: 2748.7999999999993,
    tank_volume_stop: 2698.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:36:30',
    timestamp_stop: '2022-01-01T14:36:36',
    quantity: 58.5,
    vehicle: 'DEM888',
    id: 137792,
    tank_volume_start: 2698.399999999999,
    tank_volume_stop: 2642.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:36:51',
    timestamp_stop: '2022-01-01T14:36:57',
    quantity: 60.3,
    vehicle: 'DEM555',
    id: 137794,
    tank_volume_start: 2642.399999999999,
    tank_volume_stop: 2585.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:37:12',
    timestamp_stop: '2022-01-01T14:37:18',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 137796,
    tank_volume_start: 2585.999999999999,
    tank_volume_stop: 2524.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:37:33',
    timestamp_stop: '2022-01-01T14:37:38',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 137798,
    tank_volume_start: 2524.999999999999,
    tank_volume_stop: 2470.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:37:53',
    timestamp_stop: '2022-01-01T14:38:00',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 137800,
    tank_volume_start: 2470.699999999999,
    tank_volume_stop: 2408.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:38:15',
    timestamp_stop: '2022-01-01T14:38:20',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 137802,
    tank_volume_start: 2408.999999999999,
    tank_volume_stop: 2352.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:38:35',
    timestamp_stop: '2022-01-01T14:38:41',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 137804,
    tank_volume_start: 2352.999999999999,
    tank_volume_stop: 2295.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:38:56',
    timestamp_stop: '2022-01-01T14:39:01',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 137806,
    tank_volume_start: 2295.899999999999,
    tank_volume_stop: 2235.399999999999,
  },
  {
    timestamp_start: '2022-01-01T14:39:16',
    timestamp_stop: '2022-01-01T14:39:22',
    quantity: 59.1,
    vehicle: 'DEM888',
    id: 137808,
    tank_volume_start: 2235.399999999999,
    tank_volume_stop: 2182.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:39:37',
    timestamp_stop: '2022-01-01T14:39:42',
    quantity: 52.9,
    vehicle: 'DEM555',
    id: 137810,
    tank_volume_start: 2182.599999999999,
    tank_volume_stop: 2127.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:39:57',
    timestamp_stop: '2022-01-01T14:40:03',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 137812,
    tank_volume_start: 2127.099999999999,
    tank_volume_stop: 2076.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:40:18',
    timestamp_stop: '2022-01-01T14:40:23',
    quantity: 53.4,
    vehicle: 'DEM888',
    id: 137814,
    tank_volume_start: 2076.099999999999,
    tank_volume_stop: 2024.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:40:38',
    timestamp_stop: '2022-01-01T14:40:44',
    quantity: 55.2,
    vehicle: 'DEM111',
    id: 137816,
    tank_volume_start: 2024.499999999999,
    tank_volume_stop: 1961.3999999999992,
  },
  {
    timestamp_start: '2022-01-01T14:40:59',
    timestamp_stop: '2022-01-01T14:41:04',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 137818,
    tank_volume_start: 3961.399999999999,
    tank_volume_stop: 3908.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:41:19',
    timestamp_stop: '2022-01-01T14:41:25',
    quantity: 52.7,
    vehicle: 'DEM111',
    id: 137820,
    tank_volume_start: 3908.899999999999,
    tank_volume_stop: 3856.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:41:40',
    timestamp_stop: '2022-01-01T14:41:46',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 137822,
    tank_volume_start: 3856.999999999999,
    tank_volume_stop: 3805.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:42:01',
    timestamp_stop: '2022-01-01T14:42:06',
    quantity: 51.8,
    vehicle: 'DEM444',
    id: 137824,
    tank_volume_start: 3805.499999999999,
    tank_volume_stop: 3751.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:42:21',
    timestamp_stop: '2022-01-01T14:42:26',
    quantity: 50.6,
    vehicle: 'DEM777',
    id: 137826,
    tank_volume_start: 3751.499999999999,
    tank_volume_stop: 3693.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:42:41',
    timestamp_stop: '2022-01-01T14:42:46',
    quantity: 53.8,
    vehicle: 'DEM222',
    id: 137828,
    tank_volume_start: 3693.499999999999,
    tank_volume_stop: 3640.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:43:01',
    timestamp_stop: '2022-01-01T14:43:07',
    quantity: 54.1,
    vehicle: 'DEM555',
    id: 137830,
    tank_volume_start: 3640.899999999999,
    tank_volume_stop: 3586.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:43:22',
    timestamp_stop: '2022-01-01T14:43:27',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 137832,
    tank_volume_start: 3586.099999999999,
    tank_volume_stop: 3522.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:43:42',
    timestamp_stop: '2022-01-01T14:43:48',
    quantity: 58.5,
    vehicle: 'DEM222',
    id: 137834,
    tank_volume_start: 3522.099999999999,
    tank_volume_stop: 3459.999999999999,
  },
  {
    timestamp_start: '2022-01-01T14:44:03',
    timestamp_stop: '2022-01-01T14:44:08',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 137836,
    tank_volume_start: 3459.999999999999,
    tank_volume_stop: 3398.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:44:23',
    timestamp_stop: '2022-01-01T14:44:28',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 137838,
    tank_volume_start: 3398.099999999999,
    tank_volume_stop: 3340.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:44:43',
    timestamp_stop: '2022-01-01T14:44:49',
    quantity: 61.8,
    vehicle: 'DEM000',
    id: 137840,
    tank_volume_start: 3340.699999999999,
    tank_volume_stop: 3275.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:45:04',
    timestamp_stop: '2022-01-01T14:45:09',
    quantity: 51.3,
    vehicle: 'DEM777',
    id: 137842,
    tank_volume_start: 3275.699999999999,
    tank_volume_stop: 3227.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:45:24',
    timestamp_stop: '2022-01-01T14:45:30',
    quantity: 60.5,
    vehicle: 'DEM000',
    id: 137844,
    tank_volume_start: 3227.699999999999,
    tank_volume_stop: 3176.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:45:45',
    timestamp_stop: '2022-01-01T14:45:50',
    quantity: 48.6,
    vehicle: 'DEM444',
    id: 137846,
    tank_volume_start: 3176.099999999999,
    tank_volume_stop: 3119.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:46:05',
    timestamp_stop: '2022-01-01T14:46:11',
    quantity: 54.9,
    vehicle: 'DEM444',
    id: 137848,
    tank_volume_start: 3119.699999999999,
    tank_volume_stop: 3070.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:46:26',
    timestamp_stop: '2022-01-01T14:46:32',
    quantity: 60.1,
    vehicle: 'DEM666',
    id: 137850,
    tank_volume_start: 3070.099999999999,
    tank_volume_stop: 3010.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:46:47',
    timestamp_stop: '2022-01-01T14:46:52',
    quantity: 54.0,
    vehicle: 'DEM111',
    id: 137852,
    tank_volume_start: 3010.599999999999,
    tank_volume_stop: 2954.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:47:07',
    timestamp_stop: '2022-01-01T14:47:13',
    quantity: 61.2,
    vehicle: 'DEM555',
    id: 137854,
    tank_volume_start: 2954.899999999999,
    tank_volume_stop: 2902.099999999999,
  },
  {
    timestamp_start: '2022-01-01T14:47:28',
    timestamp_stop: '2022-01-01T14:47:33',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 137856,
    tank_volume_start: 2902.099999999999,
    tank_volume_stop: 2846.799999999999,
  },
  {
    timestamp_start: '2022-01-01T14:47:48',
    timestamp_stop: '2022-01-01T14:47:54',
    quantity: 54.6,
    vehicle: 'DEM333',
    id: 137858,
    tank_volume_start: 2846.799999999999,
    tank_volume_stop: 2790.699999999999,
  },
  {
    timestamp_start: '2022-01-01T14:48:09',
    timestamp_stop: '2022-01-01T14:48:15',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 137860,
    tank_volume_start: 2790.699999999999,
    tank_volume_stop: 2734.199999999999,
  },
  {
    timestamp_start: '2022-01-01T14:48:30',
    timestamp_stop: '2022-01-01T14:48:35',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 137862,
    tank_volume_start: 2734.199999999999,
    tank_volume_stop: 2671.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:48:50',
    timestamp_stop: '2022-01-01T14:48:56',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 137864,
    tank_volume_start: 2671.599999999999,
    tank_volume_stop: 2607.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:49:11',
    timestamp_stop: '2022-01-01T14:49:16',
    quantity: 57.1,
    vehicle: 'DEM555',
    id: 137866,
    tank_volume_start: 2607.499999999999,
    tank_volume_stop: 2559.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:49:31',
    timestamp_stop: '2022-01-01T14:49:37',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 137868,
    tank_volume_start: 2559.7999999999993,
    tank_volume_stop: 2500.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:49:52',
    timestamp_stop: '2022-01-01T14:49:58',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 137870,
    tank_volume_start: 2500.7999999999993,
    tank_volume_stop: 2437.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:50:13',
    timestamp_stop: '2022-01-01T14:50:19',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 137872,
    tank_volume_start: 2437.6999999999994,
    tank_volume_stop: 2376.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:50:34',
    timestamp_stop: '2022-01-01T14:50:39',
    quantity: 52.7,
    vehicle: 'DEM555',
    id: 137874,
    tank_volume_start: 2376.2999999999993,
    tank_volume_stop: 2323.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:50:54',
    timestamp_stop: '2022-01-01T14:50:59',
    quantity: 53.0,
    vehicle: 'DEM222',
    id: 137876,
    tank_volume_start: 2323.1999999999994,
    tank_volume_stop: 2267.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:51:14',
    timestamp_stop: '2022-01-01T14:51:20',
    quantity: 61.4,
    vehicle: 'DEM111',
    id: 137878,
    tank_volume_start: 2267.6999999999994,
    tank_volume_stop: 2218.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:51:35',
    timestamp_stop: '2022-01-01T14:51:40',
    quantity: 50.0,
    vehicle: 'DEM333',
    id: 137880,
    tank_volume_start: 2218.1999999999994,
    tank_volume_stop: 2165.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:51:55',
    timestamp_stop: '2022-01-01T14:52:01',
    quantity: 53.5,
    vehicle: 'DEM222',
    id: 137882,
    tank_volume_start: 2165.2999999999993,
    tank_volume_stop: 2111.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:52:16',
    timestamp_stop: '2022-01-01T14:52:21',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 137884,
    tank_volume_start: 2111.899999999999,
    tank_volume_stop: 2065.599999999999,
  },
  {
    timestamp_start: '2022-01-01T14:52:36',
    timestamp_stop: '2022-01-01T14:52:42',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 137886,
    tank_volume_start: 2065.599999999999,
    tank_volume_stop: 2013.199999999999,
  },
  {
    timestamp_start: '2022-01-01T14:52:57',
    timestamp_stop: '2022-01-01T14:53:03',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 137888,
    tank_volume_start: 2013.199999999999,
    tank_volume_stop: 1966.4999999999989,
  },
  {
    timestamp_start: '2022-01-01T14:53:18',
    timestamp_stop: '2022-01-01T14:53:23',
    quantity: 54.5,
    vehicle: 'DEM222',
    id: 137890,
    tank_volume_start: 3966.499999999999,
    tank_volume_stop: 3913.7999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:53:38',
    timestamp_stop: '2022-01-01T14:53:44',
    quantity: 53.7,
    vehicle: 'DEM222',
    id: 137892,
    tank_volume_start: 3913.7999999999993,
    tank_volume_stop: 3865.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:53:59',
    timestamp_stop: '2022-01-01T14:54:04',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 137894,
    tank_volume_start: 3865.2999999999993,
    tank_volume_stop: 3804.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:54:19',
    timestamp_stop: '2022-01-01T14:54:24',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 137896,
    tank_volume_start: 3804.899999999999,
    tank_volume_stop: 3748.499999999999,
  },
  {
    timestamp_start: '2022-01-01T14:54:39',
    timestamp_stop: '2022-01-01T14:54:45',
    quantity: 60.8,
    vehicle: 'DEM111',
    id: 137898,
    tank_volume_start: 3748.499999999999,
    tank_volume_stop: 3691.2999999999993,
  },
  {
    timestamp_start: '2022-01-01T14:55:00',
    timestamp_stop: '2022-01-01T14:55:06',
    quantity: 59.3,
    vehicle: 'DEM111',
    id: 137900,
    tank_volume_start: 3691.2999999999993,
    tank_volume_stop: 3638.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:55:21',
    timestamp_stop: '2022-01-01T14:55:27',
    quantity: 52.8,
    vehicle: 'DEM555',
    id: 137902,
    tank_volume_start: 3638.1999999999994,
    tank_volume_stop: 3582.899999999999,
  },
  {
    timestamp_start: '2022-01-01T14:55:42',
    timestamp_stop: '2022-01-01T14:55:47',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 137904,
    tank_volume_start: 3582.899999999999,
    tank_volume_stop: 3530.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:56:02',
    timestamp_stop: '2022-01-01T14:56:07',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 137906,
    tank_volume_start: 3530.1999999999994,
    tank_volume_stop: 3482.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:56:22',
    timestamp_stop: '2022-01-01T14:56:28',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 137908,
    tank_volume_start: 3482.4999999999995,
    tank_volume_stop: 3432.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:56:43',
    timestamp_stop: '2022-01-01T14:56:49',
    quantity: 64.0,
    vehicle: 'DEM888',
    id: 137910,
    tank_volume_start: 3432.5999999999995,
    tank_volume_stop: 3373.8999999999996,
  },
  {
    timestamp_start: '2022-01-01T14:57:04',
    timestamp_stop: '2022-01-01T14:57:10',
    quantity: 53.0,
    vehicle: 'DEM111',
    id: 137912,
    tank_volume_start: 3373.8999999999996,
    tank_volume_stop: 3316.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:57:25',
    timestamp_stop: '2022-01-01T14:57:30',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 137914,
    tank_volume_start: 3316.9999999999995,
    tank_volume_stop: 3268.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:57:45',
    timestamp_stop: '2022-01-01T14:57:52',
    quantity: 64.5,
    vehicle: 'DEM111',
    id: 137916,
    tank_volume_start: 3268.0999999999995,
    tank_volume_stop: 3206.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:58:07',
    timestamp_stop: '2022-01-01T14:58:12',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 137918,
    tank_volume_start: 3206.9999999999995,
    tank_volume_stop: 3151.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:58:27',
    timestamp_stop: '2022-01-01T14:58:33',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 137920,
    tank_volume_start: 3151.0999999999995,
    tank_volume_stop: 3096.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:58:48',
    timestamp_stop: '2022-01-01T14:58:53',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 137922,
    tank_volume_start: 3096.6999999999994,
    tank_volume_stop: 3051.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:59:08',
    timestamp_stop: '2022-01-01T14:59:14',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 137924,
    tank_volume_start: 3051.0999999999995,
    tank_volume_stop: 2998.1999999999994,
  },
  {
    timestamp_start: '2022-01-01T14:59:29',
    timestamp_stop: '2022-01-01T14:59:34',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 137926,
    tank_volume_start: 2998.1999999999994,
    tank_volume_stop: 2945.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T14:59:49',
    timestamp_stop: '2022-01-01T14:59:55',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 137928,
    tank_volume_start: 2945.9999999999995,
    tank_volume_stop: 2891.6999999999994,
  },
  {
    timestamp_start: '2022-01-01T15:00:10',
    timestamp_stop: '2022-01-01T15:00:15',
    quantity: 57.1,
    vehicle: 'DEM888',
    id: 137930,
    tank_volume_start: 2891.6999999999994,
    tank_volume_stop: 2835.5999999999995,
  },
  {
    timestamp_start: '2022-01-01T15:00:30',
    timestamp_stop: '2022-01-01T15:00:37',
    quantity: 63.9,
    vehicle: 'DEM666',
    id: 137932,
    tank_volume_start: 2835.5999999999995,
    tank_volume_stop: 2773.4999999999995,
  },
  {
    timestamp_start: '2022-01-01T15:00:52',
    timestamp_stop: '2022-01-01T15:00:58',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 137934,
    tank_volume_start: 2773.4999999999995,
    tank_volume_stop: 2720.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T15:01:13',
    timestamp_stop: '2022-01-01T15:01:18',
    quantity: 49.2,
    vehicle: 'DEM444',
    id: 137936,
    tank_volume_start: 2720.2999999999997,
    tank_volume_stop: 2666.9999999999995,
  },
  {
    timestamp_start: '2022-01-01T15:01:33',
    timestamp_stop: '2022-01-01T15:01:38',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 137938,
    tank_volume_start: 2666.9999999999995,
    tank_volume_stop: 2602.3999999999996,
  },
  {
    timestamp_start: '2022-01-01T15:01:53',
    timestamp_stop: '2022-01-01T15:01:59',
    quantity: 56.9,
    vehicle: 'DEM777',
    id: 137940,
    tank_volume_start: 2602.3999999999996,
    tank_volume_stop: 2544.0999999999995,
  },
  {
    timestamp_start: '2022-01-01T15:02:14',
    timestamp_stop: '2022-01-01T15:02:19',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 137942,
    tank_volume_start: 2544.0999999999995,
    tank_volume_stop: 2487.8999999999996,
  },
  {
    timestamp_start: '2022-01-01T15:02:34',
    timestamp_stop: '2022-01-01T15:02:41',
    quantity: 62.1,
    vehicle: 'DEM888',
    id: 137944,
    tank_volume_start: 2487.8999999999996,
    tank_volume_stop: 2431.7,
  },
  {
    timestamp_start: '2022-01-01T15:02:56',
    timestamp_stop: '2022-01-01T15:03:02',
    quantity: 63.3,
    vehicle: 'DEM444',
    id: 137946,
    tank_volume_start: 2431.7,
    tank_volume_stop: 2372.2999999999997,
  },
  {
    timestamp_start: '2022-01-01T15:03:17',
    timestamp_stop: '2022-01-01T15:03:22',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 137948,
    tank_volume_start: 2372.2999999999997,
    tank_volume_stop: 2323.6,
  },
  {
    timestamp_start: '2022-01-01T15:03:37',
    timestamp_stop: '2022-01-01T15:03:42',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 137950,
    tank_volume_start: 2323.6,
    tank_volume_stop: 2268.9,
  },
  {
    timestamp_start: '2022-01-01T15:03:57',
    timestamp_stop: '2022-01-01T15:04:02',
    quantity: 50.6,
    vehicle: 'DEM777',
    id: 137952,
    tank_volume_start: 2268.9,
    tank_volume_stop: 2217.6,
  },
  {
    timestamp_start: '2022-01-01T15:04:17',
    timestamp_stop: '2022-01-01T15:04:22',
    quantity: 49.9,
    vehicle: 'DEM666',
    id: 137954,
    tank_volume_start: 2217.6,
    tank_volume_stop: 2163.5,
  },
  {
    timestamp_start: '2022-01-01T15:04:37',
    timestamp_stop: '2022-01-01T15:04:43',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 137956,
    tank_volume_start: 2163.5,
    tank_volume_stop: 2104.8,
  },
  {
    timestamp_start: '2022-01-01T15:04:58',
    timestamp_stop: '2022-01-01T15:05:03',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 137958,
    tank_volume_start: 2104.8,
    tank_volume_stop: 2049.2000000000003,
  },
  {
    timestamp_start: '2022-01-01T15:05:18',
    timestamp_stop: '2022-01-01T15:05:24',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 137960,
    tank_volume_start: 2049.2000000000003,
    tank_volume_stop: 1994.2000000000003,
  },
  {
    timestamp_start: '2022-01-01T15:05:39',
    timestamp_stop: '2022-01-01T15:05:44',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 137962,
    tank_volume_start: 3994.2000000000003,
    tank_volume_stop: 3936.5000000000005,
  },
  {
    timestamp_start: '2022-01-01T15:05:59',
    timestamp_stop: '2022-01-01T15:06:05',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 137964,
    tank_volume_start: 3936.5000000000005,
    tank_volume_stop: 3882.5000000000005,
  },
  {
    timestamp_start: '2022-01-01T15:06:20',
    timestamp_stop: '2022-01-01T15:06:24',
    quantity: 46.2,
    vehicle: 'DEM888',
    id: 137966,
    tank_volume_start: 3882.5000000000005,
    tank_volume_stop: 3832.5000000000005,
  },
  {
    timestamp_start: '2022-01-01T15:06:39',
    timestamp_stop: '2022-01-01T15:06:44',
    quantity: 47.8,
    vehicle: 'DEM555',
    id: 137968,
    tank_volume_start: 3832.5000000000005,
    tank_volume_stop: 3776.4000000000005,
  },
  {
    timestamp_start: '2022-01-01T15:06:59',
    timestamp_stop: '2022-01-01T15:07:04',
    quantity: 52.8,
    vehicle: 'DEM000',
    id: 137970,
    tank_volume_start: 3776.4000000000005,
    tank_volume_stop: 3719.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T15:07:19',
    timestamp_stop: '2022-01-01T15:07:24',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 137972,
    tank_volume_start: 3719.2000000000007,
    tank_volume_stop: 3663.8000000000006,
  },
  {
    timestamp_start: '2022-01-01T15:07:39',
    timestamp_stop: '2022-01-01T15:07:44',
    quantity: 46.5,
    vehicle: 'DEM333',
    id: 137974,
    tank_volume_start: 3663.8000000000006,
    tank_volume_stop: 3611.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:07:59',
    timestamp_stop: '2022-01-01T15:08:05',
    quantity: 54.5,
    vehicle: 'DEM444',
    id: 137976,
    tank_volume_start: 3611.600000000001,
    tank_volume_stop: 3553.000000000001,
  },
  {
    timestamp_start: '2022-01-01T15:08:20',
    timestamp_stop: '2022-01-01T15:08:25',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 137978,
    tank_volume_start: 3553.000000000001,
    tank_volume_stop: 3499.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:08:40',
    timestamp_stop: '2022-01-01T15:08:46',
    quantity: 56.9,
    vehicle: 'DEM111',
    id: 137980,
    tank_volume_start: 3499.600000000001,
    tank_volume_stop: 3447.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T15:09:01',
    timestamp_stop: '2022-01-01T15:09:07',
    quantity: 63.8,
    vehicle: 'DEM000',
    id: 137982,
    tank_volume_start: 3447.2000000000007,
    tank_volume_stop: 3391.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T15:09:22',
    timestamp_stop: '2022-01-01T15:09:28',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 137984,
    tank_volume_start: 3391.3000000000006,
    tank_volume_stop: 3339.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T15:09:43',
    timestamp_stop: '2022-01-01T15:09:48',
    quantity: 50.3,
    vehicle: 'DEM444',
    id: 137986,
    tank_volume_start: 3339.2000000000007,
    tank_volume_stop: 3288.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:10:03',
    timestamp_stop: '2022-01-01T15:10:08',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 137988,
    tank_volume_start: 3288.600000000001,
    tank_volume_stop: 3225.400000000001,
  },
  {
    timestamp_start: '2022-01-01T15:10:23',
    timestamp_stop: '2022-01-01T15:10:29',
    quantity: 54.0,
    vehicle: 'DEM666',
    id: 137990,
    tank_volume_start: 3225.400000000001,
    tank_volume_stop: 3172.900000000001,
  },
  {
    timestamp_start: '2022-01-01T15:10:44',
    timestamp_stop: '2022-01-01T15:10:49',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 137992,
    tank_volume_start: 3172.900000000001,
    tank_volume_stop: 3117.100000000001,
  },
  {
    timestamp_start: '2022-01-01T15:11:04',
    timestamp_stop: '2022-01-01T15:11:10',
    quantity: 57.4,
    vehicle: 'DEM888',
    id: 137994,
    tank_volume_start: 3117.100000000001,
    tank_volume_stop: 3070.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T15:11:25',
    timestamp_stop: '2022-01-01T15:11:30',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 137996,
    tank_volume_start: 3070.2000000000007,
    tank_volume_stop: 3020.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:11:45',
    timestamp_stop: '2022-01-01T15:11:52',
    quantity: 64.5,
    vehicle: 'DEM222',
    id: 137998,
    tank_volume_start: 3020.600000000001,
    tank_volume_stop: 2972.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:12:07',
    timestamp_stop: '2022-01-01T15:12:13',
    quantity: 61.7,
    vehicle: 'DEM555',
    id: 138000,
    tank_volume_start: 2972.600000000001,
    tank_volume_stop: 2919.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T15:12:28',
    timestamp_stop: '2022-01-01T15:12:33',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 138002,
    tank_volume_start: 2919.2000000000007,
    tank_volume_stop: 2868.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T15:12:48',
    timestamp_stop: '2022-01-01T15:12:54',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 138004,
    tank_volume_start: 2868.3000000000006,
    tank_volume_stop: 2813.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T15:13:09',
    timestamp_stop: '2022-01-01T15:13:14',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 138006,
    tank_volume_start: 2813.9000000000005,
    tank_volume_stop: 2757.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T15:13:29',
    timestamp_stop: '2022-01-01T15:13:34',
    quantity: 49.7,
    vehicle: 'DEM555',
    id: 138008,
    tank_volume_start: 2757.3000000000006,
    tank_volume_stop: 2702.600000000001,
  },
  {
    timestamp_start: '2022-01-01T15:13:49',
    timestamp_stop: '2022-01-01T15:13:54',
    quantity: 56.4,
    vehicle: 'DEM000',
    id: 138010,
    tank_volume_start: 2702.600000000001,
    tank_volume_stop: 2645.900000000001,
  },
  {
    timestamp_start: '2022-01-01T15:14:09',
    timestamp_stop: '2022-01-01T15:14:15',
    quantity: 51.9,
    vehicle: 'DEM888',
    id: 138012,
    tank_volume_start: 2645.900000000001,
    tank_volume_stop: 2595.200000000001,
  },
  {
    timestamp_start: '2022-01-01T15:14:30',
    timestamp_stop: '2022-01-01T15:14:36',
    quantity: 58.8,
    vehicle: 'DEM444',
    id: 138014,
    tank_volume_start: 2595.200000000001,
    tank_volume_stop: 2534.800000000001,
  },
  {
    timestamp_start: '2022-01-01T15:14:51',
    timestamp_stop: '2022-01-01T15:14:56',
    quantity: 56.3,
    vehicle: 'DEM555',
    id: 138016,
    tank_volume_start: 2534.800000000001,
    tank_volume_stop: 2483.200000000001,
  },
  {
    timestamp_start: '2022-01-01T15:15:11',
    timestamp_stop: '2022-01-01T15:15:17',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 138018,
    tank_volume_start: 2483.200000000001,
    tank_volume_stop: 2427.200000000001,
  },
  {
    timestamp_start: '2022-01-01T15:15:32',
    timestamp_stop: '2022-01-01T15:15:38',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 138020,
    tank_volume_start: 2427.200000000001,
    tank_volume_stop: 2373.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:15:53',
    timestamp_stop: '2022-01-01T15:16:00',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 138022,
    tank_volume_start: 2373.0000000000014,
    tank_volume_stop: 2320.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:16:15',
    timestamp_stop: '2022-01-01T15:16:21',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 138024,
    tank_volume_start: 2320.6000000000013,
    tank_volume_stop: 2272.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:16:36',
    timestamp_stop: '2022-01-01T15:16:42',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 138026,
    tank_volume_start: 2272.6000000000013,
    tank_volume_stop: 2221.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:16:57',
    timestamp_stop: '2022-01-01T15:17:02',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 138028,
    tank_volume_start: 2221.6000000000013,
    tank_volume_stop: 2170.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:17:17',
    timestamp_stop: '2022-01-01T15:17:23',
    quantity: 53.8,
    vehicle: 'DEM888',
    id: 138030,
    tank_volume_start: 2170.4000000000015,
    tank_volume_stop: 2115.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:17:38',
    timestamp_stop: '2022-01-01T15:17:44',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 138032,
    tank_volume_start: 2115.7000000000016,
    tank_volume_stop: 2057.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:17:59',
    timestamp_stop: '2022-01-01T15:18:05',
    quantity: 60.4,
    vehicle: 'DEM000',
    id: 138034,
    tank_volume_start: 2057.4000000000015,
    tank_volume_stop: 2001.6000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:18:20',
    timestamp_stop: '2022-01-01T15:18:26',
    quantity: 58.1,
    vehicle: 'DEM333',
    id: 138036,
    tank_volume_start: 2001.6000000000015,
    tank_volume_stop: 1946.7000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:18:41',
    timestamp_stop: '2022-01-01T15:18:46',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 138038,
    tank_volume_start: 3946.7000000000016,
    tank_volume_stop: 3884.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:19:01',
    timestamp_stop: '2022-01-01T15:19:06',
    quantity: 50.1,
    vehicle: 'DEM333',
    id: 138040,
    tank_volume_start: 3884.7000000000016,
    tank_volume_stop: 3838.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:19:21',
    timestamp_stop: '2022-01-01T15:19:27',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 138042,
    tank_volume_start: 3838.000000000002,
    tank_volume_stop: 3783.300000000002,
  },
  {
    timestamp_start: '2022-01-01T15:19:42',
    timestamp_stop: '2022-01-01T15:19:48',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 138044,
    tank_volume_start: 3783.300000000002,
    tank_volume_stop: 3726.100000000002,
  },
  {
    timestamp_start: '2022-01-01T15:20:03',
    timestamp_stop: '2022-01-01T15:20:08',
    quantity: 52.5,
    vehicle: 'DEM777',
    id: 138046,
    tank_volume_start: 3726.100000000002,
    tank_volume_stop: 3672.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T15:20:23',
    timestamp_stop: '2022-01-01T15:20:29',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 138048,
    tank_volume_start: 3672.5000000000023,
    tank_volume_stop: 3610.200000000002,
  },
  {
    timestamp_start: '2022-01-01T15:20:44',
    timestamp_stop: '2022-01-01T15:20:49',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 138050,
    tank_volume_start: 3610.200000000002,
    tank_volume_stop: 3553.100000000002,
  },
  {
    timestamp_start: '2022-01-01T15:21:04',
    timestamp_stop: '2022-01-01T15:21:10',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 138052,
    tank_volume_start: 3553.100000000002,
    tank_volume_stop: 3491.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T15:21:25',
    timestamp_stop: '2022-01-01T15:21:31',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 138054,
    tank_volume_start: 3491.9000000000024,
    tank_volume_stop: 3439.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T15:21:46',
    timestamp_stop: '2022-01-01T15:21:51',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 138056,
    tank_volume_start: 3439.3000000000025,
    tank_volume_stop: 3384.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T15:22:06',
    timestamp_stop: '2022-01-01T15:22:11',
    quantity: 55.3,
    vehicle: 'DEM000',
    id: 138058,
    tank_volume_start: 3384.5000000000023,
    tank_volume_stop: 3325.700000000002,
  },
  {
    timestamp_start: '2022-01-01T15:22:26',
    timestamp_stop: '2022-01-01T15:22:33',
    quantity: 63.4,
    vehicle: 'DEM666',
    id: 138060,
    tank_volume_start: 3325.700000000002,
    tank_volume_stop: 3272.900000000002,
  },
  {
    timestamp_start: '2022-01-01T15:22:48',
    timestamp_stop: '2022-01-01T15:22:54',
    quantity: 61.4,
    vehicle: 'DEM111',
    id: 138062,
    tank_volume_start: 3272.900000000002,
    tank_volume_stop: 3210.400000000002,
  },
  {
    timestamp_start: '2022-01-01T15:23:09',
    timestamp_stop: '2022-01-01T15:23:14',
    quantity: 47.6,
    vehicle: 'DEM888',
    id: 138064,
    tank_volume_start: 3210.400000000002,
    tank_volume_stop: 3164.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:23:29',
    timestamp_stop: '2022-01-01T15:23:35',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 138066,
    tank_volume_start: 3164.6000000000017,
    tank_volume_stop: 3101.500000000002,
  },
  {
    timestamp_start: '2022-01-01T15:23:50',
    timestamp_stop: '2022-01-01T15:23:55',
    quantity: 55.6,
    vehicle: 'DEM444',
    id: 138068,
    tank_volume_start: 3101.500000000002,
    tank_volume_stop: 3051.1000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:24:10',
    timestamp_stop: '2022-01-01T15:24:15',
    quantity: 49.8,
    vehicle: 'DEM000',
    id: 138070,
    tank_volume_start: 3051.1000000000017,
    tank_volume_stop: 3004.500000000002,
  },
  {
    timestamp_start: '2022-01-01T15:24:30',
    timestamp_stop: '2022-01-01T15:24:36',
    quantity: 58.8,
    vehicle: 'DEM777',
    id: 138072,
    tank_volume_start: 3004.500000000002,
    tank_volume_stop: 2949.800000000002,
  },
  {
    timestamp_start: '2022-01-01T15:24:51',
    timestamp_stop: '2022-01-01T15:24:57',
    quantity: 58.3,
    vehicle: 'DEM111',
    id: 138074,
    tank_volume_start: 2949.800000000002,
    tank_volume_stop: 2894.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:25:12',
    timestamp_stop: '2022-01-01T15:25:17',
    quantity: 54.8,
    vehicle: 'DEM333',
    id: 138076,
    tank_volume_start: 2894.000000000002,
    tank_volume_stop: 2834.900000000002,
  },
  {
    timestamp_start: '2022-01-01T15:25:32',
    timestamp_stop: '2022-01-01T15:25:37',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 138078,
    tank_volume_start: 2834.900000000002,
    tank_volume_stop: 2782.700000000002,
  },
  {
    timestamp_start: '2022-01-01T15:25:52',
    timestamp_stop: '2022-01-01T15:25:57',
    quantity: 45.7,
    vehicle: 'DEM222',
    id: 138080,
    tank_volume_start: 2782.700000000002,
    tank_volume_stop: 2722.600000000002,
  },
  {
    timestamp_start: '2022-01-01T15:26:12',
    timestamp_stop: '2022-01-01T15:26:18',
    quantity: 64.3,
    vehicle: 'DEM777',
    id: 138082,
    tank_volume_start: 2722.600000000002,
    tank_volume_stop: 2662.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T15:26:33',
    timestamp_stop: '2022-01-01T15:26:38',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 138084,
    tank_volume_start: 2662.0000000000023,
    tank_volume_stop: 2609.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T15:26:53',
    timestamp_stop: '2022-01-01T15:26:59',
    quantity: 56.7,
    vehicle: 'DEM777',
    id: 138086,
    tank_volume_start: 2609.0000000000023,
    tank_volume_stop: 2562.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T15:27:14',
    timestamp_stop: '2022-01-01T15:27:20',
    quantity: 63.1,
    vehicle: 'DEM888',
    id: 138088,
    tank_volume_start: 2562.3000000000025,
    tank_volume_stop: 2508.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T15:27:35',
    timestamp_stop: '2022-01-01T15:27:42',
    quantity: 64.2,
    vehicle: 'DEM555',
    id: 138091,
    tank_volume_start: 2508.4000000000024,
    tank_volume_stop: 2454.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T15:27:57',
    timestamp_stop: '2022-01-01T15:28:03',
    quantity: 59.5,
    vehicle: 'DEM000',
    id: 138093,
    tank_volume_start: 2454.5000000000023,
    tank_volume_stop: 2404.100000000002,
  },
  {
    timestamp_start: '2022-01-01T15:28:18',
    timestamp_stop: '2022-01-01T15:28:23',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 138095,
    tank_volume_start: 2404.100000000002,
    tank_volume_stop: 2349.800000000002,
  },
  {
    timestamp_start: '2022-01-01T15:28:38',
    timestamp_stop: '2022-01-01T15:28:44',
    quantity: 61.1,
    vehicle: 'DEM333',
    id: 138097,
    tank_volume_start: 2349.800000000002,
    tank_volume_stop: 2300.600000000002,
  },
  {
    timestamp_start: '2022-01-01T15:28:59',
    timestamp_stop: '2022-01-01T15:29:05',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 138099,
    tank_volume_start: 2300.600000000002,
    tank_volume_stop: 2246.300000000002,
  },
  {
    timestamp_start: '2022-01-01T15:29:20',
    timestamp_stop: '2022-01-01T15:29:26',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 138101,
    tank_volume_start: 2246.300000000002,
    tank_volume_stop: 2198.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:29:41',
    timestamp_stop: '2022-01-01T15:29:47',
    quantity: 52.6,
    vehicle: 'DEM222',
    id: 138103,
    tank_volume_start: 2198.000000000002,
    tank_volume_stop: 2144.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:30:02',
    timestamp_stop: '2022-01-01T15:30:07',
    quantity: 55.9,
    vehicle: 'DEM888',
    id: 138105,
    tank_volume_start: 2144.2000000000016,
    tank_volume_stop: 2086.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:30:22',
    timestamp_stop: '2022-01-01T15:30:28',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 138107,
    tank_volume_start: 2086.000000000002,
    tank_volume_stop: 2032.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:30:43',
    timestamp_stop: '2022-01-01T15:30:48',
    quantity: 46.3,
    vehicle: 'DEM000',
    id: 138109,
    tank_volume_start: 2032.6000000000017,
    tank_volume_stop: 1975.1000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:31:03',
    timestamp_stop: '2022-01-01T15:31:09',
    quantity: 60.6,
    vehicle: 'DEM222',
    id: 138111,
    tank_volume_start: 3975.1000000000017,
    tank_volume_stop: 3925.900000000002,
  },
  {
    timestamp_start: '2022-01-01T15:31:24',
    timestamp_stop: '2022-01-01T15:31:30',
    quantity: 58.7,
    vehicle: 'DEM222',
    id: 138113,
    tank_volume_start: 3925.900000000002,
    tank_volume_stop: 3863.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:31:45',
    timestamp_stop: '2022-01-01T15:31:51',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 138115,
    tank_volume_start: 3863.000000000002,
    tank_volume_stop: 3805.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:32:06',
    timestamp_stop: '2022-01-01T15:32:11',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 138117,
    tank_volume_start: 3805.6000000000017,
    tank_volume_stop: 3741.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:32:26',
    timestamp_stop: '2022-01-01T15:32:31',
    quantity: 55.5,
    vehicle: 'DEM222',
    id: 138119,
    tank_volume_start: 3741.2000000000016,
    tank_volume_stop: 3690.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:32:46',
    timestamp_stop: '2022-01-01T15:32:52',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 138121,
    tank_volume_start: 3690.000000000002,
    tank_volume_stop: 3636.800000000002,
  },
  {
    timestamp_start: '2022-01-01T15:33:07',
    timestamp_stop: '2022-01-01T15:33:13',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 138123,
    tank_volume_start: 3636.800000000002,
    tank_volume_stop: 3576.400000000002,
  },
  {
    timestamp_start: '2022-01-01T15:33:28',
    timestamp_stop: '2022-01-01T15:33:33',
    quantity: 55.2,
    vehicle: 'DEM000',
    id: 138125,
    tank_volume_start: 3576.400000000002,
    tank_volume_stop: 3520.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:33:48',
    timestamp_stop: '2022-01-01T15:33:54',
    quantity: 53.2,
    vehicle: 'DEM777',
    id: 138127,
    tank_volume_start: 3520.6000000000017,
    tank_volume_stop: 3458.1000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:34:09',
    timestamp_stop: '2022-01-01T15:34:15',
    quantity: 60.4,
    vehicle: 'DEM666',
    id: 138129,
    tank_volume_start: 3458.1000000000017,
    tank_volume_stop: 3398.1000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:34:30',
    timestamp_stop: '2022-01-01T15:34:35',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 138131,
    tank_volume_start: 3398.1000000000017,
    tank_volume_stop: 3340.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:34:50',
    timestamp_stop: '2022-01-01T15:34:55',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 138133,
    tank_volume_start: 3340.000000000002,
    tank_volume_stop: 3282.900000000002,
  },
  {
    timestamp_start: '2022-01-01T15:35:10',
    timestamp_stop: '2022-01-01T15:35:16',
    quantity: 60.7,
    vehicle: 'DEM000',
    id: 138135,
    tank_volume_start: 3282.900000000002,
    tank_volume_stop: 3225.200000000002,
  },
  {
    timestamp_start: '2022-01-01T15:35:31',
    timestamp_stop: '2022-01-01T15:35:37',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 138137,
    tank_volume_start: 3225.200000000002,
    tank_volume_stop: 3169.700000000002,
  },
  {
    timestamp_start: '2022-01-01T15:35:52',
    timestamp_stop: '2022-01-01T15:35:57',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 138139,
    tank_volume_start: 3169.700000000002,
    tank_volume_stop: 3112.300000000002,
  },
  {
    timestamp_start: '2022-01-01T15:36:12',
    timestamp_stop: '2022-01-01T15:36:18',
    quantity: 54.9,
    vehicle: 'DEM888',
    id: 138141,
    tank_volume_start: 3112.300000000002,
    tank_volume_stop: 3061.400000000002,
  },
  {
    timestamp_start: '2022-01-01T15:36:33',
    timestamp_stop: '2022-01-01T15:36:38',
    quantity: 50.1,
    vehicle: 'DEM666',
    id: 138143,
    tank_volume_start: 3061.400000000002,
    tank_volume_stop: 3005.700000000002,
  },
  {
    timestamp_start: '2022-01-01T15:36:53',
    timestamp_stop: '2022-01-01T15:36:58',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 138145,
    tank_volume_start: 3005.700000000002,
    tank_volume_stop: 2954.900000000002,
  },
  {
    timestamp_start: '2022-01-01T15:37:13',
    timestamp_stop: '2022-01-01T15:37:19',
    quantity: 60.9,
    vehicle: 'DEM111',
    id: 138147,
    tank_volume_start: 2954.900000000002,
    tank_volume_stop: 2902.300000000002,
  },
  {
    timestamp_start: '2022-01-01T15:37:34',
    timestamp_stop: '2022-01-01T15:37:39',
    quantity: 52.8,
    vehicle: 'DEM333',
    id: 138149,
    tank_volume_start: 2902.300000000002,
    tank_volume_stop: 2852.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:37:54',
    timestamp_stop: '2022-01-01T15:38:00',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 138151,
    tank_volume_start: 2852.000000000002,
    tank_volume_stop: 2801.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:38:15',
    timestamp_stop: '2022-01-01T15:38:21',
    quantity: 57.2,
    vehicle: 'DEM555',
    id: 138153,
    tank_volume_start: 2801.2000000000016,
    tank_volume_stop: 2737.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:38:36',
    timestamp_stop: '2022-01-01T15:38:42',
    quantity: 61.0,
    vehicle: 'DEM333',
    id: 138155,
    tank_volume_start: 2737.4000000000015,
    tank_volume_stop: 2680.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:38:57',
    timestamp_stop: '2022-01-01T15:39:02',
    quantity: 51.8,
    vehicle: 'DEM777',
    id: 138157,
    tank_volume_start: 2680.0000000000014,
    tank_volume_stop: 2626.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:39:17',
    timestamp_stop: '2022-01-01T15:39:23',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 138159,
    tank_volume_start: 2626.0000000000014,
    tank_volume_stop: 2571.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:39:38',
    timestamp_stop: '2022-01-01T15:39:43',
    quantity: 51.5,
    vehicle: 'DEM333',
    id: 138161,
    tank_volume_start: 2571.9000000000015,
    tank_volume_stop: 2514.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:39:58',
    timestamp_stop: '2022-01-01T15:40:03',
    quantity: 52.4,
    vehicle: 'DEM777',
    id: 138163,
    tank_volume_start: 2514.6000000000013,
    tank_volume_stop: 2457.300000000001,
  },
  {
    timestamp_start: '2022-01-01T15:40:18',
    timestamp_stop: '2022-01-01T15:40:23',
    quantity: 54.8,
    vehicle: 'DEM888',
    id: 138165,
    tank_volume_start: 2457.300000000001,
    tank_volume_stop: 2399.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:40:38',
    timestamp_stop: '2022-01-01T15:40:44',
    quantity: 56.5,
    vehicle: 'DEM000',
    id: 138167,
    tank_volume_start: 2399.1000000000013,
    tank_volume_stop: 2345.700000000001,
  },
  {
    timestamp_start: '2022-01-01T15:40:59',
    timestamp_stop: '2022-01-01T15:41:05',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 138169,
    tank_volume_start: 2345.700000000001,
    tank_volume_stop: 2289.700000000001,
  },
  {
    timestamp_start: '2022-01-01T15:41:20',
    timestamp_stop: '2022-01-01T15:41:25',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 138171,
    tank_volume_start: 2289.700000000001,
    tank_volume_stop: 2233.800000000001,
  },
  {
    timestamp_start: '2022-01-01T15:41:40',
    timestamp_stop: '2022-01-01T15:41:46',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 138173,
    tank_volume_start: 2233.800000000001,
    tank_volume_stop: 2178.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:42:01',
    timestamp_stop: '2022-01-01T15:42:05',
    quantity: 45.8,
    vehicle: 'DEM444',
    id: 138175,
    tank_volume_start: 2178.6000000000013,
    tank_volume_stop: 2122.200000000001,
  },
  {
    timestamp_start: '2022-01-01T15:42:20',
    timestamp_stop: '2022-01-01T15:42:26',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 138177,
    tank_volume_start: 2122.200000000001,
    tank_volume_stop: 2068.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:42:41',
    timestamp_stop: '2022-01-01T15:42:47',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 138179,
    tank_volume_start: 2068.0000000000014,
    tank_volume_stop: 2012.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:43:02',
    timestamp_stop: '2022-01-01T15:43:07',
    quantity: 57.4,
    vehicle: 'DEM000',
    id: 138181,
    tank_volume_start: 2012.5000000000014,
    tank_volume_stop: 1959.8000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:43:22',
    timestamp_stop: '2022-01-01T15:43:28',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 138183,
    tank_volume_start: 3959.800000000001,
    tank_volume_stop: 3904.800000000001,
  },
  {
    timestamp_start: '2022-01-01T15:43:43',
    timestamp_stop: '2022-01-01T15:43:48',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 138185,
    tank_volume_start: 3904.800000000001,
    tank_volume_stop: 3857.000000000001,
  },
  {
    timestamp_start: '2022-01-01T15:44:03',
    timestamp_stop: '2022-01-01T15:44:08',
    quantity: 51.2,
    vehicle: 'DEM888',
    id: 138187,
    tank_volume_start: 3857.000000000001,
    tank_volume_stop: 3798.500000000001,
  },
  {
    timestamp_start: '2022-01-01T15:44:23',
    timestamp_stop: '2022-01-01T15:44:28',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 138189,
    tank_volume_start: 3798.500000000001,
    tank_volume_stop: 3745.300000000001,
  },
  {
    timestamp_start: '2022-01-01T15:44:43',
    timestamp_stop: '2022-01-01T15:44:49',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 138191,
    tank_volume_start: 3745.300000000001,
    tank_volume_stop: 3698.900000000001,
  },
  {
    timestamp_start: '2022-01-01T15:45:04',
    timestamp_stop: '2022-01-01T15:45:09',
    quantity: 48.2,
    vehicle: 'DEM333',
    id: 138193,
    tank_volume_start: 3698.900000000001,
    tank_volume_stop: 3641.000000000001,
  },
  {
    timestamp_start: '2022-01-01T15:45:24',
    timestamp_stop: '2022-01-01T15:45:29',
    quantity: 57.7,
    vehicle: 'DEM666',
    id: 138194,
    tank_volume_start: 3641.000000000001,
    tank_volume_stop: 3585.500000000001,
  },
  {
    timestamp_start: '2022-01-01T15:45:44',
    timestamp_stop: '2022-01-01T15:45:50',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 138197,
    tank_volume_start: 3585.500000000001,
    tank_volume_stop: 3521.300000000001,
  },
  {
    timestamp_start: '2022-01-01T15:46:05',
    timestamp_stop: '2022-01-01T15:46:10',
    quantity: 53.6,
    vehicle: 'DEM000',
    id: 138199,
    tank_volume_start: 3521.300000000001,
    tank_volume_stop: 3463.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:46:25',
    timestamp_stop: '2022-01-01T15:46:30',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 138201,
    tank_volume_start: 3463.1000000000013,
    tank_volume_stop: 3404.700000000001,
  },
  {
    timestamp_start: '2022-01-01T15:46:45',
    timestamp_stop: '2022-01-01T15:46:50',
    quantity: 45.1,
    vehicle: 'DEM111',
    id: 138202,
    tank_volume_start: 3404.700000000001,
    tank_volume_stop: 3351.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:47:05',
    timestamp_stop: '2022-01-01T15:47:10',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 138204,
    tank_volume_start: 3351.5000000000014,
    tank_volume_stop: 3297.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:47:25',
    timestamp_stop: '2022-01-01T15:47:31',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 138206,
    tank_volume_start: 3297.6000000000013,
    tank_volume_stop: 3245.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:47:46',
    timestamp_stop: '2022-01-01T15:47:51',
    quantity: 47.8,
    vehicle: 'DEM777',
    id: 138208,
    tank_volume_start: 3245.9000000000015,
    tank_volume_stop: 3187.3000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:48:06',
    timestamp_stop: '2022-01-01T15:48:11',
    quantity: 56.8,
    vehicle: 'DEM333',
    id: 138210,
    tank_volume_start: 3187.3000000000015,
    tank_volume_stop: 3126.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:48:26',
    timestamp_stop: '2022-01-01T15:48:31',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 138212,
    tank_volume_start: 3126.4000000000015,
    tank_volume_stop: 3075.3000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:48:46',
    timestamp_stop: '2022-01-01T15:48:52',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 138214,
    tank_volume_start: 3075.3000000000015,
    tank_volume_stop: 3025.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:49:07',
    timestamp_stop: '2022-01-01T15:49:13',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 138216,
    tank_volume_start: 3025.9000000000015,
    tank_volume_stop: 2977.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:49:28',
    timestamp_stop: '2022-01-01T15:49:33',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 138218,
    tank_volume_start: 2977.0000000000014,
    tank_volume_stop: 2922.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T15:49:48',
    timestamp_stop: '2022-01-01T15:49:54',
    quantity: 54.0,
    vehicle: 'DEM555',
    id: 138220,
    tank_volume_start: 2922.0000000000014,
    tank_volume_stop: 2875.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:50:09',
    timestamp_stop: '2022-01-01T15:50:14',
    quantity: 50.2,
    vehicle: 'DEM333',
    id: 138222,
    tank_volume_start: 2875.4000000000015,
    tank_volume_stop: 2822.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:50:29',
    timestamp_stop: '2022-01-01T15:50:35',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 138224,
    tank_volume_start: 2822.2000000000016,
    tank_volume_stop: 2759.500000000002,
  },
  {
    timestamp_start: '2022-01-01T15:50:50',
    timestamp_stop: '2022-01-01T15:50:55',
    quantity: 58.1,
    vehicle: 'DEM666',
    id: 138226,
    tank_volume_start: 2759.500000000002,
    tank_volume_stop: 2704.300000000002,
  },
  {
    timestamp_start: '2022-01-01T15:51:10',
    timestamp_stop: '2022-01-01T15:51:17',
    quantity: 62.9,
    vehicle: 'DEM777',
    id: 138228,
    tank_volume_start: 2704.300000000002,
    tank_volume_stop: 2649.800000000002,
  },
  {
    timestamp_start: '2022-01-01T15:51:32',
    timestamp_stop: '2022-01-01T15:51:37',
    quantity: 50.3,
    vehicle: 'DEM222',
    id: 138230,
    tank_volume_start: 2649.800000000002,
    tank_volume_stop: 2590.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:51:52',
    timestamp_stop: '2022-01-01T15:51:57',
    quantity: 48.8,
    vehicle: 'DEM666',
    id: 138232,
    tank_volume_start: 2590.000000000002,
    tank_volume_stop: 2533.500000000002,
  },
  {
    timestamp_start: '2022-01-01T15:52:12',
    timestamp_stop: '2022-01-01T15:52:17',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 138234,
    tank_volume_start: 2533.500000000002,
    tank_volume_stop: 2476.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:52:32',
    timestamp_stop: '2022-01-01T15:52:37',
    quantity: 51.5,
    vehicle: 'DEM777',
    id: 138236,
    tank_volume_start: 2476.2000000000016,
    tank_volume_stop: 2425.000000000002,
  },
  {
    timestamp_start: '2022-01-01T15:52:52',
    timestamp_stop: '2022-01-01T15:52:58',
    quantity: 55.9,
    vehicle: 'DEM000',
    id: 138238,
    tank_volume_start: 2425.000000000002,
    tank_volume_stop: 2367.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T15:53:13',
    timestamp_stop: '2022-01-01T15:53:19',
    quantity: 56.7,
    vehicle: 'DEM555',
    id: 138240,
    tank_volume_start: 2367.6000000000017,
    tank_volume_stop: 2315.2000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:53:34',
    timestamp_stop: '2022-01-01T15:53:39',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 138242,
    tank_volume_start: 2315.2000000000016,
    tank_volume_stop: 2261.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:53:54',
    timestamp_stop: '2022-01-01T15:53:59',
    quantity: 46.0,
    vehicle: 'DEM333',
    id: 138244,
    tank_volume_start: 2261.9000000000015,
    tank_volume_stop: 2206.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:54:14',
    timestamp_stop: '2022-01-01T15:54:20',
    quantity: 63.3,
    vehicle: 'DEM222',
    id: 138246,
    tank_volume_start: 2206.7000000000016,
    tank_volume_stop: 2153.500000000002,
  },
  {
    timestamp_start: '2022-01-01T15:54:35',
    timestamp_stop: '2022-01-01T15:54:40',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 138248,
    tank_volume_start: 2153.500000000002,
    tank_volume_stop: 2104.400000000002,
  },
  {
    timestamp_start: '2022-01-01T15:54:55',
    timestamp_stop: '2022-01-01T15:55:00',
    quantity: 46.9,
    vehicle: 'DEM000',
    id: 138250,
    tank_volume_start: 2104.400000000002,
    tank_volume_stop: 2046.2000000000019,
  },
  {
    timestamp_start: '2022-01-01T15:55:15',
    timestamp_stop: '2022-01-01T15:55:21',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 138252,
    tank_volume_start: 2046.2000000000019,
    tank_volume_stop: 1988.2000000000019,
  },
  {
    timestamp_start: '2022-01-01T15:55:36',
    timestamp_stop: '2022-01-01T15:55:42',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 138254,
    tank_volume_start: 3988.2000000000016,
    tank_volume_stop: 3923.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T15:55:57',
    timestamp_stop: '2022-01-01T15:56:02',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 138256,
    tank_volume_start: 3923.7000000000016,
    tank_volume_stop: 3862.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:56:17',
    timestamp_stop: '2022-01-01T15:56:22',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138258,
    tank_volume_start: 3862.9000000000015,
    tank_volume_stop: 3812.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:56:37',
    timestamp_stop: '2022-01-01T15:56:42',
    quantity: 52.9,
    vehicle: 'DEM444',
    id: 138260,
    tank_volume_start: 3812.6000000000013,
    tank_volume_stop: 3765.800000000001,
  },
  {
    timestamp_start: '2022-01-01T15:56:57',
    timestamp_stop: '2022-01-01T15:57:03',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 138262,
    tank_volume_start: 3765.800000000001,
    tank_volume_stop: 3712.900000000001,
  },
  {
    timestamp_start: '2022-01-01T15:57:18',
    timestamp_stop: '2022-01-01T15:57:24',
    quantity: 61.7,
    vehicle: 'DEM000',
    id: 138264,
    tank_volume_start: 3712.900000000001,
    tank_volume_stop: 3661.300000000001,
  },
  {
    timestamp_start: '2022-01-01T15:57:39',
    timestamp_stop: '2022-01-01T15:57:45',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 138267,
    tank_volume_start: 3661.300000000001,
    tank_volume_stop: 3608.200000000001,
  },
  {
    timestamp_start: '2022-01-01T15:58:00',
    timestamp_stop: '2022-01-01T15:58:05',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 138269,
    tank_volume_start: 3608.200000000001,
    tank_volume_stop: 3557.400000000001,
  },
  {
    timestamp_start: '2022-01-01T15:58:20',
    timestamp_stop: '2022-01-01T15:58:25',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 138270,
    tank_volume_start: 3557.400000000001,
    tank_volume_stop: 3506.700000000001,
  },
  {
    timestamp_start: '2022-01-01T15:58:40',
    timestamp_stop: '2022-01-01T15:58:46',
    quantity: 64.5,
    vehicle: 'DEM333',
    id: 138272,
    tank_volume_start: 3506.700000000001,
    tank_volume_stop: 3454.300000000001,
  },
  {
    timestamp_start: '2022-01-01T15:59:01',
    timestamp_stop: '2022-01-01T15:59:06',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 138275,
    tank_volume_start: 3454.300000000001,
    tank_volume_stop: 3400.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T15:59:21',
    timestamp_stop: '2022-01-01T15:59:27',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 138276,
    tank_volume_start: 3400.1000000000013,
    tank_volume_stop: 3347.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T15:59:42',
    timestamp_stop: '2022-01-01T15:59:47',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 138278,
    tank_volume_start: 3347.9000000000015,
    tank_volume_stop: 3293.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:00:02',
    timestamp_stop: '2022-01-01T16:00:08',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 138280,
    tank_volume_start: 3293.1000000000013,
    tank_volume_stop: 3235.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:00:23',
    timestamp_stop: '2022-01-01T16:00:29',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 138282,
    tank_volume_start: 3235.1000000000013,
    tank_volume_stop: 3176.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:00:44',
    timestamp_stop: '2022-01-01T16:00:49',
    quantity: 49.8,
    vehicle: 'DEM444',
    id: 138284,
    tank_volume_start: 3176.6000000000013,
    tank_volume_stop: 3119.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:01:04',
    timestamp_stop: '2022-01-01T16:01:10',
    quantity: 59.7,
    vehicle: 'DEM000',
    id: 138286,
    tank_volume_start: 3119.9000000000015,
    tank_volume_stop: 3068.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:01:25',
    timestamp_stop: '2022-01-01T16:01:30',
    quantity: 50.2,
    vehicle: 'DEM111',
    id: 138288,
    tank_volume_start: 3068.5000000000014,
    tank_volume_stop: 3009.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:01:45',
    timestamp_stop: '2022-01-01T16:01:51',
    quantity: 62.2,
    vehicle: 'DEM555',
    id: 138290,
    tank_volume_start: 3009.0000000000014,
    tank_volume_stop: 2951.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:02:06',
    timestamp_stop: '2022-01-01T16:02:11',
    quantity: 49.2,
    vehicle: 'DEM777',
    id: 138292,
    tank_volume_start: 2951.6000000000013,
    tank_volume_stop: 2894.800000000001,
  },
  {
    timestamp_start: '2022-01-01T16:02:26',
    timestamp_stop: '2022-01-01T16:02:31',
    quantity: 48.6,
    vehicle: 'DEM000',
    id: 138294,
    tank_volume_start: 2894.800000000001,
    tank_volume_stop: 2840.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:02:46',
    timestamp_stop: '2022-01-01T16:02:51',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 138296,
    tank_volume_start: 2840.400000000001,
    tank_volume_stop: 2780.600000000001,
  },
  {
    timestamp_start: '2022-01-01T16:03:06',
    timestamp_stop: '2022-01-01T16:03:12',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 138298,
    tank_volume_start: 2780.600000000001,
    tank_volume_stop: 2717.7000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:03:27',
    timestamp_stop: '2022-01-01T16:03:33',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 138300,
    tank_volume_start: 2717.7000000000007,
    tank_volume_stop: 2662.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:03:48',
    timestamp_stop: '2022-01-01T16:03:53',
    quantity: 53.1,
    vehicle: 'DEM888',
    id: 138302,
    tank_volume_start: 2662.9000000000005,
    tank_volume_stop: 2610.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:04:08',
    timestamp_stop: '2022-01-01T16:04:14',
    quantity: 54.1,
    vehicle: 'DEM777',
    id: 138304,
    tank_volume_start: 2610.9000000000005,
    tank_volume_stop: 2555.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T16:04:29',
    timestamp_stop: '2022-01-01T16:04:34',
    quantity: 57.4,
    vehicle: 'DEM777',
    id: 138306,
    tank_volume_start: 2555.3000000000006,
    tank_volume_stop: 2503.7000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:04:49',
    timestamp_stop: '2022-01-01T16:04:55',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 138308,
    tank_volume_start: 2503.7000000000007,
    tank_volume_stop: 2447.7000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:05:10',
    timestamp_stop: '2022-01-01T16:05:15',
    quantity: 49.0,
    vehicle: 'DEM555',
    id: 138310,
    tank_volume_start: 2447.7000000000007,
    tank_volume_stop: 2393.100000000001,
  },
  {
    timestamp_start: '2022-01-01T16:05:30',
    timestamp_stop: '2022-01-01T16:05:36',
    quantity: 59.7,
    vehicle: 'DEM888',
    id: 138312,
    tank_volume_start: 2393.100000000001,
    tank_volume_stop: 2342.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:05:51',
    timestamp_stop: '2022-01-01T16:05:56',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 138314,
    tank_volume_start: 2342.400000000001,
    tank_volume_stop: 2281.200000000001,
  },
  {
    timestamp_start: '2022-01-01T16:06:11',
    timestamp_stop: '2022-01-01T16:06:17',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 138316,
    tank_volume_start: 2281.200000000001,
    tank_volume_stop: 2218.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:06:32',
    timestamp_stop: '2022-01-01T16:06:38',
    quantity: 61.5,
    vehicle: 'DEM000',
    id: 138318,
    tank_volume_start: 2218.5000000000014,
    tank_volume_stop: 2163.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:06:53',
    timestamp_stop: '2022-01-01T16:06:58',
    quantity: 56.0,
    vehicle: 'DEM888',
    id: 138320,
    tank_volume_start: 2163.4000000000015,
    tank_volume_stop: 2108.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:07:13',
    timestamp_stop: '2022-01-01T16:07:19',
    quantity: 51.8,
    vehicle: 'DEM666',
    id: 138323,
    tank_volume_start: 2108.4000000000015,
    tank_volume_stop: 2056.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:07:34',
    timestamp_stop: '2022-01-01T16:07:39',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 138324,
    tank_volume_start: 2056.1000000000013,
    tank_volume_stop: 2009.3000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:07:54',
    timestamp_stop: '2022-01-01T16:07:59',
    quantity: 47.7,
    vehicle: 'DEM888',
    id: 138326,
    tank_volume_start: 2009.3000000000013,
    tank_volume_stop: 1958.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:08:14',
    timestamp_stop: '2022-01-01T16:08:19',
    quantity: 49.2,
    vehicle: 'DEM777',
    id: 138328,
    tank_volume_start: 3958.0000000000014,
    tank_volume_stop: 3912.1000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:08:34',
    timestamp_stop: '2022-01-01T16:08:39',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 138330,
    tank_volume_start: 3912.1000000000013,
    tank_volume_stop: 3856.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:08:54',
    timestamp_stop: '2022-01-01T16:09:00',
    quantity: 63.6,
    vehicle: 'DEM888',
    id: 138332,
    tank_volume_start: 3856.5000000000014,
    tank_volume_stop: 3797.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:09:15',
    timestamp_stop: '2022-01-01T16:09:21',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 138334,
    tank_volume_start: 3797.4000000000015,
    tank_volume_stop: 3737.3000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:09:36',
    timestamp_stop: '2022-01-01T16:09:41',
    quantity: 50.7,
    vehicle: 'DEM888',
    id: 138336,
    tank_volume_start: 3737.3000000000015,
    tank_volume_stop: 3681.3000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:09:56',
    timestamp_stop: '2022-01-01T16:10:02',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 138338,
    tank_volume_start: 3681.3000000000015,
    tank_volume_stop: 3626.8000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:10:17',
    timestamp_stop: '2022-01-01T16:10:22',
    quantity: 55.5,
    vehicle: 'DEM666',
    id: 138340,
    tank_volume_start: 3626.8000000000015,
    tank_volume_stop: 3569.4000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:10:37',
    timestamp_stop: '2022-01-01T16:10:42',
    quantity: 47.2,
    vehicle: 'DEM444',
    id: 138342,
    tank_volume_start: 3569.4000000000015,
    tank_volume_stop: 3505.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T16:10:57',
    timestamp_stop: '2022-01-01T16:11:02',
    quantity: 56.1,
    vehicle: 'DEM111',
    id: 138344,
    tank_volume_start: 3505.7000000000016,
    tank_volume_stop: 3456.500000000002,
  },
  {
    timestamp_start: '2022-01-01T16:11:17',
    timestamp_stop: '2022-01-01T16:11:23',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 138346,
    tank_volume_start: 3456.500000000002,
    tank_volume_stop: 3403.800000000002,
  },
  {
    timestamp_start: '2022-01-01T16:11:38',
    timestamp_stop: '2022-01-01T16:11:44',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 138348,
    tank_volume_start: 3403.800000000002,
    tank_volume_stop: 3352.300000000002,
  },
  {
    timestamp_start: '2022-01-01T16:11:59',
    timestamp_stop: '2022-01-01T16:12:04',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 138350,
    tank_volume_start: 3352.300000000002,
    tank_volume_stop: 3294.400000000002,
  },
  {
    timestamp_start: '2022-01-01T16:12:19',
    timestamp_stop: '2022-01-01T16:12:24',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 138352,
    tank_volume_start: 3294.400000000002,
    tank_volume_stop: 3239.900000000002,
  },
  {
    timestamp_start: '2022-01-01T16:12:39',
    timestamp_stop: '2022-01-01T16:12:44',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 138354,
    tank_volume_start: 3239.900000000002,
    tank_volume_stop: 3183.300000000002,
  },
  {
    timestamp_start: '2022-01-01T16:12:59',
    timestamp_stop: '2022-01-01T16:13:04',
    quantity: 49.4,
    vehicle: 'DEM000',
    id: 138356,
    tank_volume_start: 3183.300000000002,
    tank_volume_stop: 3129.900000000002,
  },
  {
    timestamp_start: '2022-01-01T16:13:19',
    timestamp_stop: '2022-01-01T16:13:25',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 138358,
    tank_volume_start: 3129.900000000002,
    tank_volume_stop: 3067.500000000002,
  },
  {
    timestamp_start: '2022-01-01T16:13:40',
    timestamp_stop: '2022-01-01T16:13:46',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 138360,
    tank_volume_start: 3067.500000000002,
    tank_volume_stop: 3008.000000000002,
  },
  {
    timestamp_start: '2022-01-01T16:14:01',
    timestamp_stop: '2022-01-01T16:14:07',
    quantity: 61.7,
    vehicle: 'DEM222',
    id: 138362,
    tank_volume_start: 3008.000000000002,
    tank_volume_stop: 2957.400000000002,
  },
  {
    timestamp_start: '2022-01-01T16:14:22',
    timestamp_stop: '2022-01-01T16:14:28',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 138364,
    tank_volume_start: 2957.400000000002,
    tank_volume_stop: 2899.500000000002,
  },
  {
    timestamp_start: '2022-01-01T16:14:43',
    timestamp_stop: '2022-01-01T16:14:49',
    quantity: 61.7,
    vehicle: 'DEM111',
    id: 138366,
    tank_volume_start: 2899.500000000002,
    tank_volume_stop: 2845.500000000002,
  },
  {
    timestamp_start: '2022-01-01T16:15:04',
    timestamp_stop: '2022-01-01T16:15:08',
    quantity: 45.4,
    vehicle: 'DEM222',
    id: 138368,
    tank_volume_start: 2845.500000000002,
    tank_volume_stop: 2785.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T16:15:23',
    timestamp_stop: '2022-01-01T16:15:29',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 138370,
    tank_volume_start: 2785.6000000000017,
    tank_volume_stop: 2730.8000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:15:44',
    timestamp_stop: '2022-01-01T16:15:49',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 138372,
    tank_volume_start: 2730.8000000000015,
    tank_volume_stop: 2669.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T16:16:04',
    timestamp_stop: '2022-01-01T16:16:10',
    quantity: 64.7,
    vehicle: 'DEM888',
    id: 138374,
    tank_volume_start: 2669.6000000000017,
    tank_volume_stop: 2614.8000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:16:25',
    timestamp_stop: '2022-01-01T16:16:31',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 138376,
    tank_volume_start: 2614.8000000000015,
    tank_volume_stop: 2566.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T16:16:46',
    timestamp_stop: '2022-01-01T16:16:52',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 138378,
    tank_volume_start: 2566.7000000000016,
    tank_volume_stop: 2511.9000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:17:07',
    timestamp_stop: '2022-01-01T16:17:11',
    quantity: 49.4,
    vehicle: 'DEM666',
    id: 138380,
    tank_volume_start: 2511.9000000000015,
    tank_volume_stop: 2461.6000000000013,
  },
  {
    timestamp_start: '2022-01-01T16:17:26',
    timestamp_stop: '2022-01-01T16:17:32',
    quantity: 59.2,
    vehicle: 'DEM666',
    id: 138382,
    tank_volume_start: 2461.6000000000013,
    tank_volume_stop: 2396.700000000001,
  },
  {
    timestamp_start: '2022-01-01T16:17:47',
    timestamp_stop: '2022-01-01T16:17:53',
    quantity: 57.9,
    vehicle: 'DEM555',
    id: 138384,
    tank_volume_start: 2396.700000000001,
    tank_volume_stop: 2334.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:18:08',
    timestamp_stop: '2022-01-01T16:18:13',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 138386,
    tank_volume_start: 2334.400000000001,
    tank_volume_stop: 2277.500000000001,
  },
  {
    timestamp_start: '2022-01-01T16:18:28',
    timestamp_stop: '2022-01-01T16:18:34',
    quantity: 60.1,
    vehicle: 'DEM222',
    id: 138388,
    tank_volume_start: 2277.500000000001,
    tank_volume_stop: 2222.500000000001,
  },
  {
    timestamp_start: '2022-01-01T16:18:49',
    timestamp_stop: '2022-01-01T16:18:55',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 138390,
    tank_volume_start: 2222.500000000001,
    tank_volume_stop: 2163.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:19:10',
    timestamp_stop: '2022-01-01T16:19:16',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 138392,
    tank_volume_start: 2163.400000000001,
    tank_volume_stop: 2109.600000000001,
  },
  {
    timestamp_start: '2022-01-01T16:19:31',
    timestamp_stop: '2022-01-01T16:19:37',
    quantity: 62.7,
    vehicle: 'DEM888',
    id: 138394,
    tank_volume_start: 2109.600000000001,
    tank_volume_stop: 2054.8000000000006,
  },
  {
    timestamp_start: '2022-01-01T16:19:52',
    timestamp_stop: '2022-01-01T16:19:58',
    quantity: 61.3,
    vehicle: 'DEM000',
    id: 138396,
    tank_volume_start: 2054.8000000000006,
    tank_volume_stop: 1995.4000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:20:13',
    timestamp_stop: '2022-01-01T16:20:19',
    quantity: 56.2,
    vehicle: 'DEM888',
    id: 138398,
    tank_volume_start: 3995.4000000000005,
    tank_volume_stop: 3942.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:20:34',
    timestamp_stop: '2022-01-01T16:20:38',
    quantity: 45.5,
    vehicle: 'DEM333',
    id: 138400,
    tank_volume_start: 3942.9000000000005,
    tank_volume_stop: 3886.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:20:53',
    timestamp_stop: '2022-01-01T16:20:58',
    quantity: 48.4,
    vehicle: 'DEM555',
    id: 138402,
    tank_volume_start: 3886.6000000000004,
    tank_volume_stop: 3823.5000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:21:13',
    timestamp_stop: '2022-01-01T16:21:19',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 138404,
    tank_volume_start: 3823.5000000000005,
    tank_volume_stop: 3771.4000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:21:34',
    timestamp_stop: '2022-01-01T16:21:39',
    quantity: 48.5,
    vehicle: 'DEM111',
    id: 138406,
    tank_volume_start: 3771.4000000000005,
    tank_volume_stop: 3709.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:21:54',
    timestamp_stop: '2022-01-01T16:21:59',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 138408,
    tank_volume_start: 3709.6000000000004,
    tank_volume_stop: 3658.4000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:22:14',
    timestamp_stop: '2022-01-01T16:22:20',
    quantity: 59.7,
    vehicle: 'DEM333',
    id: 138410,
    tank_volume_start: 3658.4000000000005,
    tank_volume_stop: 3608.7000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:22:35',
    timestamp_stop: '2022-01-01T16:22:41',
    quantity: 58.1,
    vehicle: 'DEM000',
    id: 138412,
    tank_volume_start: 3608.7000000000007,
    tank_volume_stop: 3551.000000000001,
  },
  {
    timestamp_start: '2022-01-01T16:22:56',
    timestamp_stop: '2022-01-01T16:23:02',
    quantity: 63.9,
    vehicle: 'DEM444',
    id: 138414,
    tank_volume_start: 3551.000000000001,
    tank_volume_stop: 3493.2000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:23:17',
    timestamp_stop: '2022-01-01T16:23:22',
    quantity: 52.4,
    vehicle: 'DEM111',
    id: 138416,
    tank_volume_start: 3493.2000000000007,
    tank_volume_stop: 3441.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:23:37',
    timestamp_stop: '2022-01-01T16:23:44',
    quantity: 63.4,
    vehicle: 'DEM111',
    id: 138418,
    tank_volume_start: 3441.9000000000005,
    tank_volume_stop: 3389.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:23:59',
    timestamp_stop: '2022-01-01T16:24:04',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 138420,
    tank_volume_start: 3389.1000000000004,
    tank_volume_stop: 3327.0000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:24:19',
    timestamp_stop: '2022-01-01T16:24:24',
    quantity: 49.8,
    vehicle: 'DEM444',
    id: 138422,
    tank_volume_start: 3327.0000000000005,
    tank_volume_stop: 3274.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:24:39',
    timestamp_stop: '2022-01-01T16:24:44',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 138424,
    tank_volume_start: 3274.1000000000004,
    tank_volume_stop: 3218.3,
  },
  {
    timestamp_start: '2022-01-01T16:24:59',
    timestamp_stop: '2022-01-01T16:25:05',
    quantity: 54.2,
    vehicle: 'DEM555',
    id: 138426,
    tank_volume_start: 3218.3,
    tank_volume_stop: 3172.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:25:20',
    timestamp_stop: '2022-01-01T16:25:26',
    quantity: 60.8,
    vehicle: 'DEM444',
    id: 138428,
    tank_volume_start: 3172.1000000000004,
    tank_volume_stop: 3118.8,
  },
  {
    timestamp_start: '2022-01-01T16:25:41',
    timestamp_stop: '2022-01-01T16:25:46',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 138430,
    tank_volume_start: 3118.8,
    tank_volume_stop: 3066.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:26:01',
    timestamp_stop: '2022-01-01T16:26:06',
    quantity: 51.0,
    vehicle: 'DEM333',
    id: 138432,
    tank_volume_start: 3066.1000000000004,
    tank_volume_stop: 3017.0000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:26:21',
    timestamp_stop: '2022-01-01T16:26:27',
    quantity: 60.7,
    vehicle: 'DEM222',
    id: 138434,
    tank_volume_start: 3017.0000000000005,
    tank_volume_stop: 2971.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:26:42',
    timestamp_stop: '2022-01-01T16:26:48',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 138436,
    tank_volume_start: 2971.6000000000004,
    tank_volume_stop: 2922.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:27:03',
    timestamp_stop: '2022-01-01T16:27:09',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 138439,
    tank_volume_start: 2922.6000000000004,
    tank_volume_stop: 2871.7000000000003,
  },
  {
    timestamp_start: '2022-01-01T16:27:24',
    timestamp_stop: '2022-01-01T16:27:29',
    quantity: 50.5,
    vehicle: 'DEM888',
    id: 138441,
    tank_volume_start: 2871.7000000000003,
    tank_volume_stop: 2814.7000000000003,
  },
  {
    timestamp_start: '2022-01-01T16:27:44',
    timestamp_stop: '2022-01-01T16:27:50',
    quantity: 64.7,
    vehicle: 'DEM111',
    id: 138443,
    tank_volume_start: 2814.7000000000003,
    tank_volume_stop: 2766.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:28:05',
    timestamp_stop: '2022-01-01T16:28:10',
    quantity: 46.2,
    vehicle: 'DEM111',
    id: 138445,
    tank_volume_start: 2766.1000000000004,
    tank_volume_stop: 2719.1000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:28:25',
    timestamp_stop: '2022-01-01T16:28:30',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 138447,
    tank_volume_start: 2719.1000000000004,
    tank_volume_stop: 2666.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:28:45',
    timestamp_stop: '2022-01-01T16:28:50',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 138449,
    tank_volume_start: 2666.9000000000005,
    tank_volume_stop: 2617.9000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:29:05',
    timestamp_stop: '2022-01-01T16:29:11',
    quantity: 60.4,
    vehicle: 'DEM666',
    id: 138451,
    tank_volume_start: 2617.9000000000005,
    tank_volume_stop: 2567.8000000000006,
  },
  {
    timestamp_start: '2022-01-01T16:29:26',
    timestamp_stop: '2022-01-01T16:29:33',
    quantity: 62.6,
    vehicle: 'DEM111',
    id: 138453,
    tank_volume_start: 2567.8000000000006,
    tank_volume_stop: 2507.5000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:29:48',
    timestamp_stop: '2022-01-01T16:29:54',
    quantity: 62.1,
    vehicle: 'DEM444',
    id: 138455,
    tank_volume_start: 2507.5000000000005,
    tank_volume_stop: 2453.6000000000004,
  },
  {
    timestamp_start: '2022-01-01T16:30:09',
    timestamp_stop: '2022-01-01T16:30:14',
    quantity: 49.8,
    vehicle: 'DEM888',
    id: 138457,
    tank_volume_start: 2453.6000000000004,
    tank_volume_stop: 2404.4000000000005,
  },
  {
    timestamp_start: '2022-01-01T16:30:29',
    timestamp_stop: '2022-01-01T16:30:34',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 138459,
    tank_volume_start: 2404.4000000000005,
    tank_volume_stop: 2349.7000000000007,
  },
  {
    timestamp_start: '2022-01-01T16:30:49',
    timestamp_stop: '2022-01-01T16:30:54',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 138461,
    tank_volume_start: 2349.7000000000007,
    tank_volume_stop: 2293.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T16:31:09',
    timestamp_stop: '2022-01-01T16:31:15',
    quantity: 53.6,
    vehicle: 'DEM555',
    id: 138463,
    tank_volume_start: 2293.3000000000006,
    tank_volume_stop: 2229.3000000000006,
  },
  {
    timestamp_start: '2022-01-01T16:31:30',
    timestamp_stop: '2022-01-01T16:31:36',
    quantity: 62.5,
    vehicle: 'DEM777',
    id: 138465,
    tank_volume_start: 2229.3000000000006,
    tank_volume_stop: 2172.100000000001,
  },
  {
    timestamp_start: '2022-01-01T16:31:51',
    timestamp_stop: '2022-01-01T16:31:56',
    quantity: 47.0,
    vehicle: 'DEM000',
    id: 138467,
    tank_volume_start: 2172.100000000001,
    tank_volume_stop: 2116.000000000001,
  },
  {
    timestamp_start: '2022-01-01T16:32:11',
    timestamp_stop: '2022-01-01T16:32:17',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 138468,
    tank_volume_start: 2116.000000000001,
    tank_volume_stop: 2057.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:32:32',
    timestamp_stop: '2022-01-01T16:32:37',
    quantity: 58.8,
    vehicle: 'DEM000',
    id: 138471,
    tank_volume_start: 2057.400000000001,
    tank_volume_stop: 1999.400000000001,
  },
  {
    timestamp_start: '2022-01-01T16:32:52',
    timestamp_stop: '2022-01-01T16:32:58',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 138473,
    tank_volume_start: 3999.400000000001,
    tank_volume_stop: 3940.700000000001,
  },
  {
    timestamp_start: '2022-01-01T16:33:13',
    timestamp_stop: '2022-01-01T16:33:18',
    quantity: 49.1,
    vehicle: 'DEM111',
    id: 138475,
    tank_volume_start: 3940.700000000001,
    tank_volume_stop: 3884.5000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:33:33',
    timestamp_stop: '2022-01-01T16:33:39',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 138476,
    tank_volume_start: 3884.5000000000014,
    tank_volume_stop: 3835.8000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:33:54',
    timestamp_stop: '2022-01-01T16:33:58',
    quantity: 49.3,
    vehicle: 'DEM333',
    id: 138479,
    tank_volume_start: 3835.8000000000015,
    tank_volume_stop: 3779.0000000000014,
  },
  {
    timestamp_start: '2022-01-01T16:34:13',
    timestamp_stop: '2022-01-01T16:34:19',
    quantity: 57.1,
    vehicle: 'DEM888',
    id: 138480,
    tank_volume_start: 3779.0000000000014,
    tank_volume_stop: 3729.8000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:34:34',
    timestamp_stop: '2022-01-01T16:34:39',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 138482,
    tank_volume_start: 3729.8000000000015,
    tank_volume_stop: 3666.7000000000016,
  },
  {
    timestamp_start: '2022-01-01T16:34:54',
    timestamp_stop: '2022-01-01T16:35:00',
    quantity: 53.9,
    vehicle: 'DEM777',
    id: 138484,
    tank_volume_start: 3666.7000000000016,
    tank_volume_stop: 3610.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T16:35:15',
    timestamp_stop: '2022-01-01T16:35:21',
    quantity: 59.2,
    vehicle: 'DEM000',
    id: 138486,
    tank_volume_start: 3610.6000000000017,
    tank_volume_stop: 3554.6000000000017,
  },
  {
    timestamp_start: '2022-01-01T16:35:36',
    timestamp_stop: '2022-01-01T16:35:41',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 138489,
    tank_volume_start: 3554.6000000000017,
    tank_volume_stop: 3502.3000000000015,
  },
  {
    timestamp_start: '2022-01-01T16:35:56',
    timestamp_stop: '2022-01-01T16:36:01',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 138491,
    tank_volume_start: 3502.3000000000015,
    tank_volume_stop: 3447.1000000000017,
  },
  {
    timestamp_start: '2022-01-01T16:36:16',
    timestamp_stop: '2022-01-01T16:36:22',
    quantity: 60.9,
    vehicle: 'DEM333',
    id: 138492,
    tank_volume_start: 3447.1000000000017,
    tank_volume_stop: 3387.000000000002,
  },
  {
    timestamp_start: '2022-01-01T16:36:37',
    timestamp_stop: '2022-01-01T16:36:43',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 138495,
    tank_volume_start: 3387.000000000002,
    tank_volume_stop: 3339.900000000002,
  },
  {
    timestamp_start: '2022-01-01T16:36:58',
    timestamp_stop: '2022-01-01T16:37:03',
    quantity: 53.4,
    vehicle: 'DEM222',
    id: 138496,
    tank_volume_start: 3339.900000000002,
    tank_volume_stop: 3287.200000000002,
  },
  {
    timestamp_start: '2022-01-01T16:37:18',
    timestamp_stop: '2022-01-01T16:37:24',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 138498,
    tank_volume_start: 3287.200000000002,
    tank_volume_stop: 3227.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:37:39',
    timestamp_stop: '2022-01-01T16:37:45',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 138501,
    tank_volume_start: 3227.5000000000023,
    tank_volume_stop: 3180.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:38:00',
    timestamp_stop: '2022-01-01T16:38:05',
    quantity: 52.7,
    vehicle: 'DEM444',
    id: 138503,
    tank_volume_start: 3180.8000000000025,
    tank_volume_stop: 3116.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:38:20',
    timestamp_stop: '2022-01-01T16:38:25',
    quantity: 49.7,
    vehicle: 'DEM888',
    id: 138504,
    tank_volume_start: 3116.5000000000023,
    tank_volume_stop: 3061.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:38:40',
    timestamp_stop: '2022-01-01T16:38:45',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 138506,
    tank_volume_start: 3061.0000000000023,
    tank_volume_stop: 3010.700000000002,
  },
  {
    timestamp_start: '2022-01-01T16:39:00',
    timestamp_stop: '2022-01-01T16:39:06',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 138508,
    tank_volume_start: 3010.700000000002,
    tank_volume_stop: 2953.400000000002,
  },
  {
    timestamp_start: '2022-01-01T16:39:21',
    timestamp_stop: '2022-01-01T16:39:26',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 138510,
    tank_volume_start: 2953.400000000002,
    tank_volume_stop: 2891.500000000002,
  },
  {
    timestamp_start: '2022-01-01T16:39:41',
    timestamp_stop: '2022-01-01T16:39:47',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 138512,
    tank_volume_start: 2891.500000000002,
    tank_volume_stop: 2835.300000000002,
  },
  {
    timestamp_start: '2022-01-01T16:40:02',
    timestamp_stop: '2022-01-01T16:40:07',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 138514,
    tank_volume_start: 2835.300000000002,
    tank_volume_stop: 2780.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:40:22',
    timestamp_stop: '2022-01-01T16:40:28',
    quantity: 53.7,
    vehicle: 'DEM222',
    id: 138516,
    tank_volume_start: 2780.100000000002,
    tank_volume_stop: 2732.200000000002,
  },
  {
    timestamp_start: '2022-01-01T16:40:43',
    timestamp_stop: '2022-01-01T16:40:49',
    quantity: 63.7,
    vehicle: 'DEM111',
    id: 138518,
    tank_volume_start: 2732.200000000002,
    tank_volume_stop: 2672.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:41:04',
    timestamp_stop: '2022-01-01T16:41:09',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 138520,
    tank_volume_start: 2672.0000000000023,
    tank_volume_stop: 2615.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:41:24',
    timestamp_stop: '2022-01-01T16:41:29',
    quantity: 48.7,
    vehicle: 'DEM111',
    id: 138522,
    tank_volume_start: 2615.8000000000025,
    tank_volume_stop: 2556.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:41:44',
    timestamp_stop: '2022-01-01T16:41:49',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 138524,
    tank_volume_start: 2556.4000000000024,
    tank_volume_stop: 2503.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:42:04',
    timestamp_stop: '2022-01-01T16:42:10',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 138526,
    tank_volume_start: 2503.3000000000025,
    tank_volume_stop: 2438.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:42:25',
    timestamp_stop: '2022-01-01T16:42:31',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 138528,
    tank_volume_start: 2438.8000000000025,
    tank_volume_stop: 2391.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:42:46',
    timestamp_stop: '2022-01-01T16:42:51',
    quantity: 58.0,
    vehicle: 'DEM000',
    id: 138530,
    tank_volume_start: 2391.2000000000025,
    tank_volume_stop: 2329.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T16:43:06',
    timestamp_stop: '2022-01-01T16:43:11',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 138532,
    tank_volume_start: 2329.1000000000026,
    tank_volume_stop: 2266.900000000003,
  },
  {
    timestamp_start: '2022-01-01T16:43:26',
    timestamp_stop: '2022-01-01T16:43:32',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 138534,
    tank_volume_start: 2266.900000000003,
    tank_volume_stop: 2215.800000000003,
  },
  {
    timestamp_start: '2022-01-01T16:43:47',
    timestamp_stop: '2022-01-01T16:43:53',
    quantity: 60.1,
    vehicle: 'DEM777',
    id: 138536,
    tank_volume_start: 2215.800000000003,
    tank_volume_stop: 2157.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T16:44:08',
    timestamp_stop: '2022-01-01T16:44:12',
    quantity: 46.8,
    vehicle: 'DEM555',
    id: 138538,
    tank_volume_start: 2157.0000000000027,
    tank_volume_stop: 2094.900000000003,
  },
  {
    timestamp_start: '2022-01-01T16:44:27',
    timestamp_stop: '2022-01-01T16:44:32',
    quantity: 45.9,
    vehicle: 'DEM444',
    id: 138540,
    tank_volume_start: 2094.900000000003,
    tank_volume_stop: 2038.9000000000028,
  },
  {
    timestamp_start: '2022-01-01T16:44:47',
    timestamp_stop: '2022-01-01T16:44:52',
    quantity: 51.4,
    vehicle: 'DEM333',
    id: 138542,
    tank_volume_start: 2038.9000000000028,
    tank_volume_stop: 1992.6000000000029,
  },
  {
    timestamp_start: '2022-01-01T16:45:07',
    timestamp_stop: '2022-01-01T16:45:13',
    quantity: 55.2,
    vehicle: 'DEM777',
    id: 138544,
    tank_volume_start: 3992.600000000003,
    tank_volume_stop: 3932.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T16:45:28',
    timestamp_stop: '2022-01-01T16:45:33',
    quantity: 52.9,
    vehicle: 'DEM222',
    id: 138546,
    tank_volume_start: 3932.4000000000033,
    tank_volume_stop: 3876.000000000003,
  },
  {
    timestamp_start: '2022-01-01T16:45:48',
    timestamp_stop: '2022-01-01T16:45:53',
    quantity: 52.4,
    vehicle: 'DEM333',
    id: 138548,
    tank_volume_start: 3876.000000000003,
    tank_volume_stop: 3824.500000000003,
  },
  {
    timestamp_start: '2022-01-01T16:46:08',
    timestamp_stop: '2022-01-01T16:46:14',
    quantity: 57.1,
    vehicle: 'DEM666',
    id: 138550,
    tank_volume_start: 3824.500000000003,
    tank_volume_stop: 3766.700000000003,
  },
  {
    timestamp_start: '2022-01-01T16:46:29',
    timestamp_stop: '2022-01-01T16:46:34',
    quantity: 50.3,
    vehicle: 'DEM888',
    id: 138552,
    tank_volume_start: 3766.700000000003,
    tank_volume_stop: 3706.100000000003,
  },
  {
    timestamp_start: '2022-01-01T16:46:49',
    timestamp_stop: '2022-01-01T16:46:55',
    quantity: 61.6,
    vehicle: 'DEM555',
    id: 138554,
    tank_volume_start: 3706.100000000003,
    tank_volume_stop: 3654.600000000003,
  },
  {
    timestamp_start: '2022-01-01T16:47:10',
    timestamp_stop: '2022-01-01T16:47:15',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 138556,
    tank_volume_start: 3654.600000000003,
    tank_volume_stop: 3597.700000000003,
  },
  {
    timestamp_start: '2022-01-01T16:47:30',
    timestamp_stop: '2022-01-01T16:47:35',
    quantity: 48.4,
    vehicle: 'DEM333',
    id: 138558,
    tank_volume_start: 3597.700000000003,
    tank_volume_stop: 3541.400000000003,
  },
  {
    timestamp_start: '2022-01-01T16:47:50',
    timestamp_stop: '2022-01-01T16:47:56',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 138560,
    tank_volume_start: 3541.400000000003,
    tank_volume_stop: 3490.900000000003,
  },
  {
    timestamp_start: '2022-01-01T16:48:11',
    timestamp_stop: '2022-01-01T16:48:17',
    quantity: 61.9,
    vehicle: 'DEM444',
    id: 138562,
    tank_volume_start: 3490.900000000003,
    tank_volume_stop: 3428.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T16:48:32',
    timestamp_stop: '2022-01-01T16:48:38',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 138564,
    tank_volume_start: 3428.0000000000027,
    tank_volume_stop: 3368.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T16:48:53',
    timestamp_stop: '2022-01-01T16:48:58',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 138566,
    tank_volume_start: 3368.0000000000027,
    tank_volume_stop: 3310.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:49:13',
    timestamp_stop: '2022-01-01T16:49:19',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 138568,
    tank_volume_start: 3310.2000000000025,
    tank_volume_stop: 3250.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:49:34',
    timestamp_stop: '2022-01-01T16:49:39',
    quantity: 55.2,
    vehicle: 'DEM444',
    id: 138570,
    tank_volume_start: 3250.4000000000024,
    tank_volume_stop: 3194.7000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:49:54',
    timestamp_stop: '2022-01-01T16:50:00',
    quantity: 59.7,
    vehicle: 'DEM888',
    id: 138572,
    tank_volume_start: 3194.7000000000025,
    tank_volume_stop: 3139.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:50:15',
    timestamp_stop: '2022-01-01T16:50:21',
    quantity: 53.9,
    vehicle: 'DEM222',
    id: 138574,
    tank_volume_start: 3139.3000000000025,
    tank_volume_stop: 3082.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:50:36',
    timestamp_stop: '2022-01-01T16:50:41',
    quantity: 54.4,
    vehicle: 'DEM666',
    id: 138576,
    tank_volume_start: 3082.9000000000024,
    tank_volume_stop: 3026.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:50:56',
    timestamp_stop: '2022-01-01T16:51:01',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 138578,
    tank_volume_start: 3026.100000000002,
    tank_volume_stop: 2972.600000000002,
  },
  {
    timestamp_start: '2022-01-01T16:51:16',
    timestamp_stop: '2022-01-01T16:51:21',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 138580,
    tank_volume_start: 2972.600000000002,
    tank_volume_stop: 2911.600000000002,
  },
  {
    timestamp_start: '2022-01-01T16:51:36',
    timestamp_stop: '2022-01-01T16:51:42',
    quantity: 54.0,
    vehicle: 'DEM444',
    id: 138582,
    tank_volume_start: 2911.600000000002,
    tank_volume_stop: 2851.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:51:57',
    timestamp_stop: '2022-01-01T16:52:02',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 138584,
    tank_volume_start: 2851.4000000000024,
    tank_volume_stop: 2799.600000000002,
  },
  {
    timestamp_start: '2022-01-01T16:52:17',
    timestamp_stop: '2022-01-01T16:52:22',
    quantity: 49.8,
    vehicle: 'DEM333',
    id: 138586,
    tank_volume_start: 2799.600000000002,
    tank_volume_stop: 2735.600000000002,
  },
  {
    timestamp_start: '2022-01-01T16:52:37',
    timestamp_stop: '2022-01-01T16:52:43',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 138588,
    tank_volume_start: 2735.600000000002,
    tank_volume_stop: 2685.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:52:58',
    timestamp_stop: '2022-01-01T16:53:04',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 138590,
    tank_volume_start: 2685.0000000000023,
    tank_volume_stop: 2627.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:53:19',
    timestamp_stop: '2022-01-01T16:53:24',
    quantity: 55.0,
    vehicle: 'DEM444',
    id: 138592,
    tank_volume_start: 2627.8000000000025,
    tank_volume_stop: 2567.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:53:39',
    timestamp_stop: '2022-01-01T16:53:44',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 138594,
    tank_volume_start: 2567.3000000000025,
    tank_volume_stop: 2509.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:53:59',
    timestamp_stop: '2022-01-01T16:54:05',
    quantity: 56.0,
    vehicle: 'DEM333',
    id: 138596,
    tank_volume_start: 2509.8000000000025,
    tank_volume_stop: 2454.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:54:20',
    timestamp_stop: '2022-01-01T16:54:25',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 138598,
    tank_volume_start: 2454.3000000000025,
    tank_volume_stop: 2407.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:54:40',
    timestamp_stop: '2022-01-01T16:54:46',
    quantity: 54.3,
    vehicle: 'DEM000',
    id: 138600,
    tank_volume_start: 2407.3000000000025,
    tank_volume_stop: 2357.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:55:01',
    timestamp_stop: '2022-01-01T16:55:06',
    quantity: 52.4,
    vehicle: 'DEM444',
    id: 138602,
    tank_volume_start: 2357.3000000000025,
    tank_volume_stop: 2303.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:55:21',
    timestamp_stop: '2022-01-01T16:55:27',
    quantity: 63.8,
    vehicle: 'DEM000',
    id: 138604,
    tank_volume_start: 2303.3000000000025,
    tank_volume_stop: 2245.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:55:42',
    timestamp_stop: '2022-01-01T16:55:49',
    quantity: 63.2,
    vehicle: 'DEM000',
    id: 138606,
    tank_volume_start: 2245.3000000000025,
    tank_volume_stop: 2192.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:56:04',
    timestamp_stop: '2022-01-01T16:56:08',
    quantity: 47.3,
    vehicle: 'DEM888',
    id: 138608,
    tank_volume_start: 2192.0000000000023,
    tank_volume_stop: 2129.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:56:23',
    timestamp_stop: '2022-01-01T16:56:29',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 138610,
    tank_volume_start: 2129.100000000002,
    tank_volume_stop: 2072.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:56:44',
    timestamp_stop: '2022-01-01T16:56:49',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 138612,
    tank_volume_start: 2072.100000000002,
    tank_volume_stop: 2018.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T16:57:04',
    timestamp_stop: '2022-01-01T16:57:10',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 138614,
    tank_volume_start: 2018.5000000000023,
    tank_volume_stop: 1960.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:57:25',
    timestamp_stop: '2022-01-01T16:57:30',
    quantity: 50.1,
    vehicle: 'DEM000',
    id: 138616,
    tank_volume_start: 3960.4000000000024,
    tank_volume_stop: 3906.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:57:45',
    timestamp_stop: '2022-01-01T16:57:51',
    quantity: 58.8,
    vehicle: 'DEM000',
    id: 138618,
    tank_volume_start: 3906.100000000002,
    tank_volume_stop: 3855.600000000002,
  },
  {
    timestamp_start: '2022-01-01T16:58:06',
    timestamp_stop: '2022-01-01T16:58:11',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 138620,
    tank_volume_start: 3855.600000000002,
    tank_volume_stop: 3808.800000000002,
  },
  {
    timestamp_start: '2022-01-01T16:58:26',
    timestamp_stop: '2022-01-01T16:58:31',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 138622,
    tank_volume_start: 3808.800000000002,
    tank_volume_stop: 3752.100000000002,
  },
  {
    timestamp_start: '2022-01-01T16:58:46',
    timestamp_stop: '2022-01-01T16:58:53',
    quantity: 63.6,
    vehicle: 'DEM666',
    id: 138624,
    tank_volume_start: 3752.100000000002,
    tank_volume_stop: 3694.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:59:08',
    timestamp_stop: '2022-01-01T16:59:13',
    quantity: 57.4,
    vehicle: 'DEM111',
    id: 138626,
    tank_volume_start: 3694.9000000000024,
    tank_volume_stop: 3635.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T16:59:28',
    timestamp_stop: '2022-01-01T16:59:34',
    quantity: 52.1,
    vehicle: 'DEM666',
    id: 138628,
    tank_volume_start: 3635.2000000000025,
    tank_volume_stop: 3575.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T16:59:49',
    timestamp_stop: '2022-01-01T16:59:54',
    quantity: 52.1,
    vehicle: 'DEM222',
    id: 138630,
    tank_volume_start: 3575.9000000000024,
    tank_volume_stop: 3524.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T17:00:09',
    timestamp_stop: '2022-01-01T17:00:15',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 138632,
    tank_volume_start: 3524.5000000000023,
    tank_volume_stop: 3463.200000000002,
  },
  {
    timestamp_start: '2022-01-01T17:00:30',
    timestamp_stop: '2022-01-01T17:00:35',
    quantity: 49.4,
    vehicle: 'DEM888',
    id: 138634,
    tank_volume_start: 3463.200000000002,
    tank_volume_stop: 3403.600000000002,
  },
  {
    timestamp_start: '2022-01-01T17:00:50',
    timestamp_stop: '2022-01-01T17:00:56',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 138636,
    tank_volume_start: 3403.600000000002,
    tank_volume_stop: 3355.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T17:01:11',
    timestamp_stop: '2022-01-01T17:01:17',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 138638,
    tank_volume_start: 3355.9000000000024,
    tank_volume_stop: 3306.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T17:01:32',
    timestamp_stop: '2022-01-01T17:01:37',
    quantity: 49.6,
    vehicle: 'DEM000',
    id: 138640,
    tank_volume_start: 3306.4000000000024,
    tank_volume_stop: 3246.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T17:01:52',
    timestamp_stop: '2022-01-01T17:01:57',
    quantity: 49.3,
    vehicle: 'DEM777',
    id: 138642,
    tank_volume_start: 3246.0000000000023,
    tank_volume_stop: 3196.200000000002,
  },
  {
    timestamp_start: '2022-01-01T17:02:12',
    timestamp_stop: '2022-01-01T17:02:17',
    quantity: 47.0,
    vehicle: 'DEM666',
    id: 138644,
    tank_volume_start: 3196.200000000002,
    tank_volume_stop: 3140.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T17:02:32',
    timestamp_stop: '2022-01-01T17:02:37',
    quantity: 50.1,
    vehicle: 'DEM666',
    id: 138646,
    tank_volume_start: 3140.0000000000023,
    tank_volume_stop: 3086.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:02:52',
    timestamp_stop: '2022-01-01T17:02:58',
    quantity: 62.0,
    vehicle: 'DEM555',
    id: 138648,
    tank_volume_start: 3086.8000000000025,
    tank_volume_stop: 3032.4000000000024,
  },
  {
    timestamp_start: '2022-01-01T17:03:13',
    timestamp_stop: '2022-01-01T17:03:19',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 138650,
    tank_volume_start: 3032.4000000000024,
    tank_volume_stop: 2969.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T17:03:34',
    timestamp_stop: '2022-01-01T17:03:39',
    quantity: 59.2,
    vehicle: 'DEM888',
    id: 138652,
    tank_volume_start: 2969.5000000000023,
    tank_volume_stop: 2917.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:03:54',
    timestamp_stop: '2022-01-01T17:04:01',
    quantity: 62.7,
    vehicle: 'DEM777',
    id: 138654,
    tank_volume_start: 2917.8000000000025,
    tank_volume_stop: 2855.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:04:16',
    timestamp_stop: '2022-01-01T17:04:22',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 138656,
    tank_volume_start: 2855.2000000000025,
    tank_volume_stop: 2795.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:04:37',
    timestamp_stop: '2022-01-01T17:04:42',
    quantity: 51.5,
    vehicle: 'DEM444',
    id: 138658,
    tank_volume_start: 2795.8000000000025,
    tank_volume_stop: 2738.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T17:04:57',
    timestamp_stop: '2022-01-01T17:05:02',
    quantity: 54.9,
    vehicle: 'DEM111',
    id: 138660,
    tank_volume_start: 2738.1000000000026,
    tank_volume_stop: 2685.6000000000026,
  },
  {
    timestamp_start: '2022-01-01T17:05:17',
    timestamp_stop: '2022-01-01T17:05:23',
    quantity: 56.5,
    vehicle: 'DEM666',
    id: 138662,
    tank_volume_start: 2685.6000000000026,
    tank_volume_stop: 2635.7000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:05:38',
    timestamp_stop: '2022-01-01T17:05:43',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 138664,
    tank_volume_start: 2635.7000000000025,
    tank_volume_stop: 2574.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:05:58',
    timestamp_stop: '2022-01-01T17:06:04',
    quantity: 59.8,
    vehicle: 'DEM444',
    id: 138666,
    tank_volume_start: 2574.8000000000025,
    tank_volume_stop: 2521.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:06:19',
    timestamp_stop: '2022-01-01T17:06:24',
    quantity: 53.4,
    vehicle: 'DEM111',
    id: 138668,
    tank_volume_start: 2521.8000000000025,
    tank_volume_stop: 2463.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T17:06:39',
    timestamp_stop: '2022-01-01T17:06:46',
    quantity: 63.2,
    vehicle: 'DEM555',
    id: 138670,
    tank_volume_start: 2463.9000000000024,
    tank_volume_stop: 2416.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T17:07:01',
    timestamp_stop: '2022-01-01T17:07:06',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 138672,
    tank_volume_start: 2416.3000000000025,
    tank_volume_stop: 2354.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T17:07:21',
    timestamp_stop: '2022-01-01T17:07:26',
    quantity: 48.7,
    vehicle: 'DEM111',
    id: 138674,
    tank_volume_start: 2354.1000000000026,
    tank_volume_stop: 2295.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T17:07:41',
    timestamp_stop: '2022-01-01T17:07:47',
    quantity: 56.2,
    vehicle: 'DEM222',
    id: 138676,
    tank_volume_start: 2295.0000000000027,
    tank_volume_stop: 2238.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T17:08:02',
    timestamp_stop: '2022-01-01T17:08:07',
    quantity: 52.7,
    vehicle: 'DEM888',
    id: 138678,
    tank_volume_start: 2238.1000000000026,
    tank_volume_stop: 2189.5000000000027,
  },
  {
    timestamp_start: '2022-01-01T17:08:22',
    timestamp_stop: '2022-01-01T17:08:28',
    quantity: 61.9,
    vehicle: 'DEM555',
    id: 138680,
    tank_volume_start: 2189.5000000000027,
    tank_volume_stop: 2142.300000000003,
  },
  {
    timestamp_start: '2022-01-01T17:08:43',
    timestamp_stop: '2022-01-01T17:08:50',
    quantity: 64.0,
    vehicle: 'DEM333',
    id: 138682,
    tank_volume_start: 2142.300000000003,
    tank_volume_stop: 2089.900000000003,
  },
  {
    timestamp_start: '2022-01-01T17:09:05',
    timestamp_stop: '2022-01-01T17:09:11',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 138684,
    tank_volume_start: 2089.900000000003,
    tank_volume_stop: 2028.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T17:09:26',
    timestamp_stop: '2022-01-01T17:09:31',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 138686,
    tank_volume_start: 2028.0000000000027,
    tank_volume_stop: 1977.5000000000027,
  },
  {
    timestamp_start: '2022-01-01T17:09:46',
    timestamp_stop: '2022-01-01T17:09:51',
    quantity: 48.0,
    vehicle: 'DEM555',
    id: 138688,
    tank_volume_start: 3977.5000000000027,
    tank_volume_stop: 3922.900000000003,
  },
  {
    timestamp_start: '2022-01-01T17:10:06',
    timestamp_stop: '2022-01-01T17:10:12',
    quantity: 60.4,
    vehicle: 'DEM222',
    id: 138690,
    tank_volume_start: 3922.900000000003,
    tank_volume_stop: 3870.800000000003,
  },
  {
    timestamp_start: '2022-01-01T17:10:27',
    timestamp_stop: '2022-01-01T17:10:33',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 138692,
    tank_volume_start: 3870.800000000003,
    tank_volume_stop: 3813.100000000003,
  },
  {
    timestamp_start: '2022-01-01T17:10:48',
    timestamp_stop: '2022-01-01T17:10:52',
    quantity: 45.4,
    vehicle: 'DEM666',
    id: 138694,
    tank_volume_start: 3813.100000000003,
    tank_volume_stop: 3748.600000000003,
  },
  {
    timestamp_start: '2022-01-01T17:11:07',
    timestamp_stop: '2022-01-01T17:11:12',
    quantity: 54.1,
    vehicle: 'DEM666',
    id: 138696,
    tank_volume_start: 3748.600000000003,
    tank_volume_stop: 3691.600000000003,
  },
  {
    timestamp_start: '2022-01-01T17:11:27',
    timestamp_stop: '2022-01-01T17:11:33',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 138698,
    tank_volume_start: 3691.600000000003,
    tank_volume_stop: 3635.700000000003,
  },
  {
    timestamp_start: '2022-01-01T17:11:48',
    timestamp_stop: '2022-01-01T17:11:53',
    quantity: 52.0,
    vehicle: 'DEM555',
    id: 138700,
    tank_volume_start: 3635.700000000003,
    tank_volume_stop: 3588.500000000003,
  },
  {
    timestamp_start: '2022-01-01T17:12:08',
    timestamp_stop: '2022-01-01T17:12:14',
    quantity: 56.8,
    vehicle: 'DEM444',
    id: 138702,
    tank_volume_start: 3588.500000000003,
    tank_volume_stop: 3531.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T17:12:29',
    timestamp_stop: '2022-01-01T17:12:35',
    quantity: 62.5,
    vehicle: 'DEM333',
    id: 138704,
    tank_volume_start: 3531.3000000000034,
    tank_volume_stop: 3476.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T17:12:50',
    timestamp_stop: '2022-01-01T17:12:56',
    quantity: 60.6,
    vehicle: 'DEM111',
    id: 138706,
    tank_volume_start: 3476.9000000000033,
    tank_volume_stop: 3424.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:13:11',
    timestamp_stop: '2022-01-01T17:13:16',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 138708,
    tank_volume_start: 3424.7000000000035,
    tank_volume_stop: 3366.0000000000036,
  },
  {
    timestamp_start: '2022-01-01T17:13:31',
    timestamp_stop: '2022-01-01T17:13:36',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 138710,
    tank_volume_start: 3366.0000000000036,
    tank_volume_stop: 3307.9000000000037,
  },
  {
    timestamp_start: '2022-01-01T17:13:51',
    timestamp_stop: '2022-01-01T17:13:56',
    quantity: 56.9,
    vehicle: 'DEM777',
    id: 138712,
    tank_volume_start: 3307.9000000000037,
    tank_volume_stop: 3259.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:14:11',
    timestamp_stop: '2022-01-01T17:14:17',
    quantity: 54.5,
    vehicle: 'DEM777',
    id: 138714,
    tank_volume_start: 3259.6000000000035,
    tank_volume_stop: 3206.4000000000037,
  },
  {
    timestamp_start: '2022-01-01T17:14:32',
    timestamp_stop: '2022-01-01T17:14:38',
    quantity: 61.2,
    vehicle: 'DEM777',
    id: 138716,
    tank_volume_start: 3206.4000000000037,
    tank_volume_stop: 3153.5000000000036,
  },
  {
    timestamp_start: '2022-01-01T17:14:53',
    timestamp_stop: '2022-01-01T17:14:58',
    quantity: 54.5,
    vehicle: 'DEM333',
    id: 138718,
    tank_volume_start: 3153.5000000000036,
    tank_volume_stop: 3104.2000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:15:13',
    timestamp_stop: '2022-01-01T17:15:19',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 138720,
    tank_volume_start: 3104.2000000000035,
    tank_volume_stop: 3043.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T17:15:34',
    timestamp_stop: '2022-01-01T17:15:39',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 138722,
    tank_volume_start: 3043.8000000000034,
    tank_volume_stop: 2990.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:15:54',
    timestamp_stop: '2022-01-01T17:15:59',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 138724,
    tank_volume_start: 2990.6000000000035,
    tank_volume_stop: 2942.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:16:14',
    timestamp_stop: '2022-01-01T17:16:19',
    quantity: 51.2,
    vehicle: 'DEM666',
    id: 138726,
    tank_volume_start: 2942.7000000000035,
    tank_volume_stop: 2887.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:16:34',
    timestamp_stop: '2022-01-01T17:16:39',
    quantity: 48.3,
    vehicle: 'DEM888',
    id: 138728,
    tank_volume_start: 2887.7000000000035,
    tank_volume_stop: 2839.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T17:16:54',
    timestamp_stop: '2022-01-01T17:16:59',
    quantity: 51.6,
    vehicle: 'DEM444',
    id: 138730,
    tank_volume_start: 2839.8000000000034,
    tank_volume_stop: 2783.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T17:17:14',
    timestamp_stop: '2022-01-01T17:17:19',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 138732,
    tank_volume_start: 2783.6000000000035,
    tank_volume_stop: 2727.5000000000036,
  },
  {
    timestamp_start: '2022-01-01T17:17:34',
    timestamp_stop: '2022-01-01T17:17:39',
    quantity: 47.6,
    vehicle: 'DEM444',
    id: 138734,
    tank_volume_start: 2727.5000000000036,
    tank_volume_stop: 2676.800000000004,
  },
  {
    timestamp_start: '2022-01-01T17:17:54',
    timestamp_stop: '2022-01-01T17:17:59',
    quantity: 51.7,
    vehicle: 'DEM333',
    id: 138736,
    tank_volume_start: 2676.800000000004,
    tank_volume_stop: 2623.700000000004,
  },
  {
    timestamp_start: '2022-01-01T17:18:14',
    timestamp_stop: '2022-01-01T17:18:19',
    quantity: 49.3,
    vehicle: 'DEM000',
    id: 138738,
    tank_volume_start: 2623.700000000004,
    tank_volume_stop: 2569.500000000004,
  },
  {
    timestamp_start: '2022-01-01T17:18:34',
    timestamp_stop: '2022-01-01T17:18:39',
    quantity: 50.1,
    vehicle: 'DEM555',
    id: 138740,
    tank_volume_start: 2569.500000000004,
    tank_volume_stop: 2514.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T17:18:54',
    timestamp_stop: '2022-01-01T17:18:59',
    quantity: 56.1,
    vehicle: 'DEM222',
    id: 138742,
    tank_volume_start: 2514.8000000000043,
    tank_volume_stop: 2468.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T17:19:14',
    timestamp_stop: '2022-01-01T17:19:20',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 138744,
    tank_volume_start: 2468.3000000000043,
    tank_volume_stop: 2408.000000000004,
  },
  {
    timestamp_start: '2022-01-01T17:19:35',
    timestamp_stop: '2022-01-01T17:19:41',
    quantity: 62.5,
    vehicle: 'DEM111',
    id: 138746,
    tank_volume_start: 2408.000000000004,
    tank_volume_stop: 2350.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T17:19:56',
    timestamp_stop: '2022-01-01T17:20:02',
    quantity: 52.8,
    vehicle: 'DEM333',
    id: 138748,
    tank_volume_start: 2350.8000000000043,
    tank_volume_stop: 2293.400000000004,
  },
  {
    timestamp_start: '2022-01-01T17:20:17',
    timestamp_stop: '2022-01-01T17:20:23',
    quantity: 63.4,
    vehicle: 'DEM333',
    id: 138750,
    tank_volume_start: 2293.400000000004,
    tank_volume_stop: 2233.000000000004,
  },
  {
    timestamp_start: '2022-01-01T17:20:38',
    timestamp_stop: '2022-01-01T17:20:44',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 138752,
    tank_volume_start: 2233.000000000004,
    tank_volume_stop: 2173.500000000004,
  },
  {
    timestamp_start: '2022-01-01T17:20:59',
    timestamp_stop: '2022-01-01T17:21:04',
    quantity: 55.6,
    vehicle: 'DEM333',
    id: 138754,
    tank_volume_start: 2173.500000000004,
    tank_volume_stop: 2115.100000000004,
  },
  {
    timestamp_start: '2022-01-01T17:21:19',
    timestamp_stop: '2022-01-01T17:21:25',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 138756,
    tank_volume_start: 2115.100000000004,
    tank_volume_stop: 2053.900000000004,
  },
  {
    timestamp_start: '2022-01-01T17:21:40',
    timestamp_stop: '2022-01-01T17:21:46',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 138758,
    tank_volume_start: 2053.900000000004,
    tank_volume_stop: 2000.9000000000042,
  },
  {
    timestamp_start: '2022-01-01T17:22:01',
    timestamp_stop: '2022-01-01T17:22:06',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 138760,
    tank_volume_start: 2000.9000000000042,
    tank_volume_stop: 1942.7000000000041,
  },
  {
    timestamp_start: '2022-01-01T17:22:21',
    timestamp_stop: '2022-01-01T17:22:28',
    quantity: 63.0,
    vehicle: 'DEM888',
    id: 138762,
    tank_volume_start: 3942.7000000000044,
    tank_volume_stop: 3894.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:22:43',
    timestamp_stop: '2022-01-01T17:22:48',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 138764,
    tank_volume_start: 3894.1000000000045,
    tank_volume_stop: 3838.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T17:23:03',
    timestamp_stop: '2022-01-01T17:23:08',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 138766,
    tank_volume_start: 3838.2000000000044,
    tank_volume_stop: 3791.400000000004,
  },
  {
    timestamp_start: '2022-01-01T17:23:23',
    timestamp_stop: '2022-01-01T17:23:29',
    quantity: 59.7,
    vehicle: 'DEM666',
    id: 138768,
    tank_volume_start: 3791.400000000004,
    tank_volume_stop: 3739.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T17:23:44',
    timestamp_stop: '2022-01-01T17:23:50',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 138770,
    tank_volume_start: 3739.3000000000043,
    tank_volume_stop: 3678.000000000004,
  },
  {
    timestamp_start: '2022-01-01T17:24:05',
    timestamp_stop: '2022-01-01T17:24:10',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 138772,
    tank_volume_start: 3678.000000000004,
    tank_volume_stop: 3629.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T17:24:25',
    timestamp_stop: '2022-01-01T17:24:30',
    quantity: 50.5,
    vehicle: 'DEM666',
    id: 138774,
    tank_volume_start: 3629.8000000000043,
    tank_volume_stop: 3567.900000000004,
  },
  {
    timestamp_start: '2022-01-01T17:24:45',
    timestamp_stop: '2022-01-01T17:24:51',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 138776,
    tank_volume_start: 3567.900000000004,
    tank_volume_stop: 3512.400000000004,
  },
  {
    timestamp_start: '2022-01-01T17:25:06',
    timestamp_stop: '2022-01-01T17:25:12',
    quantity: 62.1,
    vehicle: 'DEM000',
    id: 138778,
    tank_volume_start: 3512.400000000004,
    tank_volume_stop: 3466.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T17:25:27',
    timestamp_stop: '2022-01-01T17:25:33',
    quantity: 62.7,
    vehicle: 'DEM777',
    id: 138780,
    tank_volume_start: 3466.2000000000044,
    tank_volume_stop: 3415.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:25:48',
    timestamp_stop: '2022-01-01T17:25:54',
    quantity: 60.3,
    vehicle: 'DEM888',
    id: 138782,
    tank_volume_start: 3415.1000000000045,
    tank_volume_stop: 3357.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:26:09',
    timestamp_stop: '2022-01-01T17:26:14',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 138784,
    tank_volume_start: 3357.5000000000045,
    tank_volume_stop: 3296.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:26:29',
    timestamp_stop: '2022-01-01T17:26:34',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 138786,
    tank_volume_start: 3296.0000000000045,
    tank_volume_stop: 3246.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:26:49',
    timestamp_stop: '2022-01-01T17:26:55',
    quantity: 53.2,
    vehicle: 'DEM000',
    id: 138788,
    tank_volume_start: 3246.5000000000045,
    tank_volume_stop: 3190.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:27:10',
    timestamp_stop: '2022-01-01T17:27:16',
    quantity: 60.7,
    vehicle: 'DEM222',
    id: 138790,
    tank_volume_start: 3190.6000000000045,
    tank_volume_stop: 3133.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:27:31',
    timestamp_stop: '2022-01-01T17:27:36',
    quantity: 52.2,
    vehicle: 'DEM333',
    id: 138792,
    tank_volume_start: 3133.9000000000046,
    tank_volume_stop: 3080.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:27:51',
    timestamp_stop: '2022-01-01T17:27:56',
    quantity: 50.9,
    vehicle: 'DEM000',
    id: 138794,
    tank_volume_start: 3080.4000000000046,
    tank_volume_stop: 3024.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:28:11',
    timestamp_stop: '2022-01-01T17:28:16',
    quantity: 48.4,
    vehicle: 'DEM333',
    id: 138796,
    tank_volume_start: 3024.6000000000045,
    tank_volume_stop: 2972.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:28:31',
    timestamp_stop: '2022-01-01T17:28:36',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 138798,
    tank_volume_start: 2972.5000000000045,
    tank_volume_stop: 2921.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:28:51',
    timestamp_stop: '2022-01-01T17:28:56',
    quantity: 49.9,
    vehicle: 'DEM555',
    id: 138800,
    tank_volume_start: 2921.1000000000045,
    tank_volume_stop: 2867.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:29:11',
    timestamp_stop: '2022-01-01T17:29:17',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 138802,
    tank_volume_start: 2867.6000000000045,
    tank_volume_stop: 2816.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:29:32',
    timestamp_stop: '2022-01-01T17:29:38',
    quantity: 59.5,
    vehicle: 'DEM888',
    id: 138804,
    tank_volume_start: 2816.9000000000046,
    tank_volume_stop: 2762.700000000005,
  },
  {
    timestamp_start: '2022-01-01T17:29:53',
    timestamp_stop: '2022-01-01T17:29:59',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 138806,
    tank_volume_start: 2762.700000000005,
    tank_volume_stop: 2708.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:30:14',
    timestamp_stop: '2022-01-01T17:30:19',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 138808,
    tank_volume_start: 2708.4000000000046,
    tank_volume_stop: 2653.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T17:30:34',
    timestamp_stop: '2022-01-01T17:30:39',
    quantity: 47.7,
    vehicle: 'DEM111',
    id: 138810,
    tank_volume_start: 2653.8000000000047,
    tank_volume_stop: 2594.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:30:54',
    timestamp_stop: '2022-01-01T17:30:58',
    quantity: 46.8,
    vehicle: 'DEM000',
    id: 138812,
    tank_volume_start: 2594.4000000000046,
    tank_volume_stop: 2542.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:31:13',
    timestamp_stop: '2022-01-01T17:31:19',
    quantity: 55.7,
    vehicle: 'DEM777',
    id: 138814,
    tank_volume_start: 2542.1000000000045,
    tank_volume_stop: 2482.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:31:34',
    timestamp_stop: '2022-01-01T17:31:40',
    quantity: 57.0,
    vehicle: 'DEM666',
    id: 138816,
    tank_volume_start: 2482.6000000000045,
    tank_volume_stop: 2437.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T17:31:55',
    timestamp_stop: '2022-01-01T17:32:00',
    quantity: 51.7,
    vehicle: 'DEM777',
    id: 138818,
    tank_volume_start: 2437.6000000000045,
    tank_volume_stop: 2386.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:32:15',
    timestamp_stop: '2022-01-01T17:32:21',
    quantity: 58.8,
    vehicle: 'DEM555',
    id: 138820,
    tank_volume_start: 2386.9000000000046,
    tank_volume_stop: 2325.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T17:32:36',
    timestamp_stop: '2022-01-01T17:32:41',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 138822,
    tank_volume_start: 2325.9000000000046,
    tank_volume_stop: 2270.200000000005,
  },
  {
    timestamp_start: '2022-01-01T17:32:56',
    timestamp_stop: '2022-01-01T17:33:02',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 138824,
    tank_volume_start: 2270.200000000005,
    tank_volume_stop: 2213.100000000005,
  },
  {
    timestamp_start: '2022-01-01T17:33:17',
    timestamp_stop: '2022-01-01T17:33:22',
    quantity: 48.6,
    vehicle: 'DEM888',
    id: 138826,
    tank_volume_start: 2213.100000000005,
    tank_volume_stop: 2156.200000000005,
  },
  {
    timestamp_start: '2022-01-01T17:33:37',
    timestamp_stop: '2022-01-01T17:33:42',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 138828,
    tank_volume_start: 2156.200000000005,
    tank_volume_stop: 2104.100000000005,
  },
  {
    timestamp_start: '2022-01-01T17:33:57',
    timestamp_stop: '2022-01-01T17:34:02',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 138830,
    tank_volume_start: 2104.100000000005,
    tank_volume_stop: 2040.300000000005,
  },
  {
    timestamp_start: '2022-01-01T17:34:17',
    timestamp_stop: '2022-01-01T17:34:23',
    quantity: 58.6,
    vehicle: 'DEM444',
    id: 138832,
    tank_volume_start: 2040.300000000005,
    tank_volume_stop: 1986.9000000000049,
  },
  {
    timestamp_start: '2022-01-01T17:34:38',
    timestamp_stop: '2022-01-01T17:34:43',
    quantity: 51.7,
    vehicle: 'DEM111',
    id: 138834,
    tank_volume_start: 3986.900000000005,
    tank_volume_stop: 3930.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T17:34:58',
    timestamp_stop: '2022-01-01T17:35:04',
    quantity: 56.7,
    vehicle: 'DEM777',
    id: 138836,
    tank_volume_start: 3930.2000000000053,
    tank_volume_stop: 3872.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:35:19',
    timestamp_stop: '2022-01-01T17:35:24',
    quantity: 52.1,
    vehicle: 'DEM888',
    id: 138838,
    tank_volume_start: 3872.5000000000055,
    tank_volume_stop: 3813.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:35:39',
    timestamp_stop: '2022-01-01T17:35:45',
    quantity: 57.4,
    vehicle: 'DEM444',
    id: 138840,
    tank_volume_start: 3813.9000000000055,
    tank_volume_stop: 3763.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T17:36:00',
    timestamp_stop: '2022-01-01T17:36:06',
    quantity: 55.8,
    vehicle: 'DEM555',
    id: 138842,
    tank_volume_start: 3763.3000000000056,
    tank_volume_stop: 3705.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T17:36:21',
    timestamp_stop: '2022-01-01T17:36:27',
    quantity: 60.4,
    vehicle: 'DEM888',
    id: 138844,
    tank_volume_start: 3705.7000000000057,
    tank_volume_stop: 3657.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T17:36:42',
    timestamp_stop: '2022-01-01T17:36:47',
    quantity: 55.3,
    vehicle: 'DEM555',
    id: 138846,
    tank_volume_start: 3657.7000000000057,
    tank_volume_stop: 3610.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T17:37:02',
    timestamp_stop: '2022-01-01T17:37:07',
    quantity: 51.7,
    vehicle: 'DEM222',
    id: 138848,
    tank_volume_start: 3610.2000000000057,
    tank_volume_stop: 3550.500000000006,
  },
  {
    timestamp_start: '2022-01-01T17:37:22',
    timestamp_stop: '2022-01-01T17:37:28',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 138850,
    tank_volume_start: 3550.500000000006,
    tank_volume_stop: 3490.000000000006,
  },
  {
    timestamp_start: '2022-01-01T17:37:43',
    timestamp_stop: '2022-01-01T17:37:49',
    quantity: 59.2,
    vehicle: 'DEM555',
    id: 138852,
    tank_volume_start: 3490.000000000006,
    tank_volume_stop: 3438.600000000006,
  },
  {
    timestamp_start: '2022-01-01T17:38:04',
    timestamp_stop: '2022-01-01T17:38:09',
    quantity: 51.8,
    vehicle: 'DEM444',
    id: 138854,
    tank_volume_start: 3438.600000000006,
    tank_volume_stop: 3383.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T17:38:24',
    timestamp_stop: '2022-01-01T17:38:30',
    quantity: 56.4,
    vehicle: 'DEM777',
    id: 138856,
    tank_volume_start: 3383.8000000000056,
    tank_volume_stop: 3334.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T17:38:45',
    timestamp_stop: '2022-01-01T17:38:50',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 138858,
    tank_volume_start: 3334.7000000000057,
    tank_volume_stop: 3281.500000000006,
  },
  {
    timestamp_start: '2022-01-01T17:39:05',
    timestamp_stop: '2022-01-01T17:39:10',
    quantity: 53.8,
    vehicle: 'DEM777',
    id: 138860,
    tank_volume_start: 3281.500000000006,
    tank_volume_stop: 3233.600000000006,
  },
  {
    timestamp_start: '2022-01-01T17:39:25',
    timestamp_stop: '2022-01-01T17:39:30',
    quantity: 51.6,
    vehicle: 'DEM888',
    id: 138862,
    tank_volume_start: 3233.600000000006,
    tank_volume_stop: 3177.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T17:39:45',
    timestamp_stop: '2022-01-01T17:39:51',
    quantity: 58.2,
    vehicle: 'DEM444',
    id: 138864,
    tank_volume_start: 3177.8000000000056,
    tank_volume_stop: 3124.600000000006,
  },
  {
    timestamp_start: '2022-01-01T17:40:06',
    timestamp_stop: '2022-01-01T17:40:12',
    quantity: 56.9,
    vehicle: 'DEM888',
    id: 138866,
    tank_volume_start: 3124.600000000006,
    tank_volume_stop: 3070.600000000006,
  },
  {
    timestamp_start: '2022-01-01T17:40:27',
    timestamp_stop: '2022-01-01T17:40:33',
    quantity: 62.2,
    vehicle: 'DEM555',
    id: 138868,
    tank_volume_start: 3070.600000000006,
    tank_volume_stop: 3012.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T17:40:48',
    timestamp_stop: '2022-01-01T17:40:53',
    quantity: 51.6,
    vehicle: 'DEM000',
    id: 138870,
    tank_volume_start: 3012.7000000000057,
    tank_volume_stop: 2951.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:41:08',
    timestamp_stop: '2022-01-01T17:41:14',
    quantity: 54.0,
    vehicle: 'DEM777',
    id: 138872,
    tank_volume_start: 2951.9000000000055,
    tank_volume_stop: 2897.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:41:29',
    timestamp_stop: '2022-01-01T17:41:34',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 138874,
    tank_volume_start: 2897.4000000000055,
    tank_volume_stop: 2843.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T17:41:49',
    timestamp_stop: '2022-01-01T17:41:56',
    quantity: 62.0,
    vehicle: 'DEM222',
    id: 138876,
    tank_volume_start: 2843.6000000000054,
    tank_volume_stop: 2789.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:42:11',
    timestamp_stop: '2022-01-01T17:42:17',
    quantity: 64.1,
    vehicle: 'DEM000',
    id: 138878,
    tank_volume_start: 2789.4000000000055,
    tank_volume_stop: 2733.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T17:42:32',
    timestamp_stop: '2022-01-01T17:42:38',
    quantity: 61.2,
    vehicle: 'DEM222',
    id: 138880,
    tank_volume_start: 2733.5000000000055,
    tank_volume_stop: 2673.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T17:42:53',
    timestamp_stop: '2022-01-01T17:42:58',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 138882,
    tank_volume_start: 2673.3000000000056,
    tank_volume_stop: 2624.100000000006,
  },
  {
    timestamp_start: '2022-01-01T17:43:13',
    timestamp_stop: '2022-01-01T17:43:19',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 138884,
    tank_volume_start: 2624.100000000006,
    tank_volume_stop: 2571.000000000006,
  },
  {
    timestamp_start: '2022-01-01T17:43:34',
    timestamp_stop: '2022-01-01T17:43:39',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 138886,
    tank_volume_start: 2571.000000000006,
    tank_volume_stop: 2515.900000000006,
  },
  {
    timestamp_start: '2022-01-01T17:43:54',
    timestamp_stop: '2022-01-01T17:44:00',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 138888,
    tank_volume_start: 2515.900000000006,
    tank_volume_stop: 2467.700000000006,
  },
  {
    timestamp_start: '2022-01-01T17:44:15',
    timestamp_stop: '2022-01-01T17:44:21',
    quantity: 61.0,
    vehicle: 'DEM555',
    id: 138890,
    tank_volume_start: 2467.700000000006,
    tank_volume_stop: 2410.800000000006,
  },
  {
    timestamp_start: '2022-01-01T17:44:36',
    timestamp_stop: '2022-01-01T17:44:41',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 138892,
    tank_volume_start: 2410.800000000006,
    tank_volume_stop: 2360.000000000006,
  },
  {
    timestamp_start: '2022-01-01T17:44:56',
    timestamp_stop: '2022-01-01T17:45:01',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 138894,
    tank_volume_start: 2360.000000000006,
    tank_volume_stop: 2305.900000000006,
  },
  {
    timestamp_start: '2022-01-01T17:45:16',
    timestamp_stop: '2022-01-01T17:45:22',
    quantity: 62.7,
    vehicle: 'DEM222',
    id: 138896,
    tank_volume_start: 2305.900000000006,
    tank_volume_stop: 2253.900000000006,
  },
  {
    timestamp_start: '2022-01-01T17:45:37',
    timestamp_stop: '2022-01-01T17:45:43',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 138898,
    tank_volume_start: 2253.900000000006,
    tank_volume_stop: 2192.100000000006,
  },
  {
    timestamp_start: '2022-01-01T17:45:58',
    timestamp_stop: '2022-01-01T17:46:03',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 138900,
    tank_volume_start: 2192.100000000006,
    tank_volume_stop: 2138.400000000006,
  },
  {
    timestamp_start: '2022-01-01T17:46:18',
    timestamp_stop: '2022-01-01T17:46:25',
    quantity: 60.5,
    vehicle: 'DEM222',
    id: 138902,
    tank_volume_start: 2138.400000000006,
    tank_volume_stop: 2078.000000000006,
  },
  {
    timestamp_start: '2022-01-01T17:46:40',
    timestamp_stop: '2022-01-01T17:46:45',
    quantity: 52.8,
    vehicle: 'DEM222',
    id: 138904,
    tank_volume_start: 2078.000000000006,
    tank_volume_stop: 2019.3000000000059,
  },
  {
    timestamp_start: '2022-01-01T17:47:00',
    timestamp_stop: '2022-01-01T17:47:04',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 138906,
    tank_volume_start: 2019.3000000000059,
    tank_volume_stop: 1965.700000000006,
  },
  {
    timestamp_start: '2022-01-01T17:47:19',
    timestamp_stop: '2022-01-01T17:47:26',
    quantity: 61.1,
    vehicle: 'DEM555',
    id: 138908,
    tank_volume_start: 3965.700000000006,
    tank_volume_stop: 3910.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T17:47:41',
    timestamp_stop: '2022-01-01T17:47:47',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 138910,
    tank_volume_start: 3910.6000000000063,
    tank_volume_stop: 3847.700000000006,
  },
  {
    timestamp_start: '2022-01-01T17:48:02',
    timestamp_stop: '2022-01-01T17:48:08',
    quantity: 63.5,
    vehicle: 'DEM555',
    id: 138912,
    tank_volume_start: 3847.700000000006,
    tank_volume_stop: 3799.800000000006,
  },
  {
    timestamp_start: '2022-01-01T17:48:23',
    timestamp_stop: '2022-01-01T17:48:28',
    quantity: 55.8,
    vehicle: 'DEM222',
    id: 138914,
    tank_volume_start: 3799.800000000006,
    tank_volume_stop: 3743.000000000006,
  },
  {
    timestamp_start: '2022-01-01T17:48:43',
    timestamp_stop: '2022-01-01T17:48:49',
    quantity: 60.2,
    vehicle: 'DEM111',
    id: 138916,
    tank_volume_start: 3743.000000000006,
    tank_volume_stop: 3687.300000000006,
  },
  {
    timestamp_start: '2022-01-01T17:49:04',
    timestamp_stop: '2022-01-01T17:49:10',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 138918,
    tank_volume_start: 3687.300000000006,
    tank_volume_stop: 3629.800000000006,
  },
  {
    timestamp_start: '2022-01-01T17:49:25',
    timestamp_stop: '2022-01-01T17:49:31',
    quantity: 59.9,
    vehicle: 'DEM333',
    id: 138920,
    tank_volume_start: 3629.800000000006,
    tank_volume_stop: 3572.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T17:49:46',
    timestamp_stop: '2022-01-01T17:49:51',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 138922,
    tank_volume_start: 3572.6000000000063,
    tank_volume_stop: 3520.200000000006,
  },
  {
    timestamp_start: '2022-01-01T17:50:06',
    timestamp_stop: '2022-01-01T17:50:11',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 138924,
    tank_volume_start: 3520.200000000006,
    tank_volume_stop: 3459.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T17:50:26',
    timestamp_stop: '2022-01-01T17:50:32',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 138926,
    tank_volume_start: 3459.6000000000063,
    tank_volume_stop: 3405.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:50:47',
    timestamp_stop: '2022-01-01T17:50:52',
    quantity: 53.5,
    vehicle: 'DEM777',
    id: 138928,
    tank_volume_start: 3405.4000000000065,
    tank_volume_stop: 3357.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T17:51:07',
    timestamp_stop: '2022-01-01T17:51:13',
    quantity: 62.5,
    vehicle: 'DEM111',
    id: 138930,
    tank_volume_start: 3357.7000000000066,
    tank_volume_stop: 3307.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:51:28',
    timestamp_stop: '2022-01-01T17:51:34',
    quantity: 59.4,
    vehicle: 'DEM111',
    id: 138932,
    tank_volume_start: 3307.9000000000065,
    tank_volume_stop: 3261.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T17:51:49',
    timestamp_stop: '2022-01-01T17:51:55',
    quantity: 60.9,
    vehicle: 'DEM444',
    id: 138935,
    tank_volume_start: 3261.2000000000066,
    tank_volume_stop: 3214.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:52:10',
    timestamp_stop: '2022-01-01T17:52:17',
    quantity: 62.5,
    vehicle: 'DEM666',
    id: 138937,
    tank_volume_start: 3214.3000000000065,
    tank_volume_stop: 3164.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:52:32',
    timestamp_stop: '2022-01-01T17:52:38',
    quantity: 64.6,
    vehicle: 'DEM777',
    id: 138939,
    tank_volume_start: 3164.3000000000065,
    tank_volume_stop: 3107.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:52:53',
    timestamp_stop: '2022-01-01T17:52:58',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 138941,
    tank_volume_start: 3107.8000000000065,
    tank_volume_stop: 3055.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:53:13',
    timestamp_stop: '2022-01-01T17:53:19',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 138943,
    tank_volume_start: 3055.6000000000067,
    tank_volume_stop: 2998.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:53:34',
    timestamp_stop: '2022-01-01T17:53:39',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 138945,
    tank_volume_start: 2998.1000000000067,
    tank_volume_stop: 2945.400000000007,
  },
  {
    timestamp_start: '2022-01-01T17:53:54',
    timestamp_stop: '2022-01-01T17:54:00',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 138947,
    tank_volume_start: 2945.400000000007,
    tank_volume_stop: 2897.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:54:15',
    timestamp_stop: '2022-01-01T17:54:20',
    quantity: 57.0,
    vehicle: 'DEM333',
    id: 138949,
    tank_volume_start: 2897.6000000000067,
    tank_volume_stop: 2838.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T17:54:35',
    timestamp_stop: '2022-01-01T17:54:42',
    quantity: 64.2,
    vehicle: 'DEM444',
    id: 138951,
    tank_volume_start: 2838.7000000000066,
    tank_volume_stop: 2780.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:54:57',
    timestamp_stop: '2022-01-01T17:55:02',
    quantity: 53.6,
    vehicle: 'DEM111',
    id: 138953,
    tank_volume_start: 2780.8000000000065,
    tank_volume_stop: 2720.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:55:17',
    timestamp_stop: '2022-01-01T17:55:22',
    quantity: 51.7,
    vehicle: 'DEM555',
    id: 138955,
    tank_volume_start: 2720.9000000000065,
    tank_volume_stop: 2665.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T17:55:37',
    timestamp_stop: '2022-01-01T17:55:43',
    quantity: 55.4,
    vehicle: 'DEM777',
    id: 138957,
    tank_volume_start: 2665.5000000000064,
    tank_volume_stop: 2603.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T17:55:58',
    timestamp_stop: '2022-01-01T17:56:03',
    quantity: 54.8,
    vehicle: 'DEM000',
    id: 138959,
    tank_volume_start: 2603.5000000000064,
    tank_volume_stop: 2553.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T17:56:18',
    timestamp_stop: '2022-01-01T17:56:24',
    quantity: 57.9,
    vehicle: 'DEM111',
    id: 138961,
    tank_volume_start: 2553.8000000000065,
    tank_volume_stop: 2496.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:56:39',
    timestamp_stop: '2022-01-01T17:56:45',
    quantity: 57.2,
    vehicle: 'DEM111',
    id: 138963,
    tank_volume_start: 2496.1000000000067,
    tank_volume_stop: 2433.500000000007,
  },
  {
    timestamp_start: '2022-01-01T17:57:00',
    timestamp_stop: '2022-01-01T17:57:05',
    quantity: 50.6,
    vehicle: 'DEM000',
    id: 138965,
    tank_volume_start: 2433.500000000007,
    tank_volume_stop: 2377.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:57:20',
    timestamp_stop: '2022-01-01T17:57:25',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 138967,
    tank_volume_start: 2377.6000000000067,
    tank_volume_stop: 2318.500000000007,
  },
  {
    timestamp_start: '2022-01-01T17:57:40',
    timestamp_stop: '2022-01-01T17:57:46',
    quantity: 61.8,
    vehicle: 'DEM666',
    id: 138969,
    tank_volume_start: 2318.500000000007,
    tank_volume_stop: 2263.500000000007,
  },
  {
    timestamp_start: '2022-01-01T17:58:01',
    timestamp_stop: '2022-01-01T17:58:07',
    quantity: 56.8,
    vehicle: 'DEM555',
    id: 138971,
    tank_volume_start: 2263.500000000007,
    tank_volume_stop: 2211.000000000007,
  },
  {
    timestamp_start: '2022-01-01T17:58:22',
    timestamp_stop: '2022-01-01T17:58:27',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 138973,
    tank_volume_start: 2211.000000000007,
    tank_volume_stop: 2150.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:58:42',
    timestamp_stop: '2022-01-01T17:58:47',
    quantity: 50.8,
    vehicle: 'DEM000',
    id: 138974,
    tank_volume_start: 2150.6000000000067,
    tank_volume_stop: 2097.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:59:02',
    timestamp_stop: '2022-01-01T17:59:08',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 138976,
    tank_volume_start: 2097.1000000000067,
    tank_volume_stop: 2037.9000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:59:23',
    timestamp_stop: '2022-01-01T17:59:29',
    quantity: 60.8,
    vehicle: 'DEM666',
    id: 138978,
    tank_volume_start: 2037.9000000000067,
    tank_volume_stop: 1984.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T17:59:44',
    timestamp_stop: '2022-01-01T17:59:49',
    quantity: 49.7,
    vehicle: 'DEM000',
    id: 138981,
    tank_volume_start: 3984.6000000000067,
    tank_volume_stop: 3923.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:00:04',
    timestamp_stop: '2022-01-01T18:00:10',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 138982,
    tank_volume_start: 3923.2000000000066,
    tank_volume_stop: 3866.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:00:25',
    timestamp_stop: '2022-01-01T18:00:31',
    quantity: 61.4,
    vehicle: 'DEM000',
    id: 138984,
    tank_volume_start: 3866.2000000000066,
    tank_volume_stop: 3809.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:00:46',
    timestamp_stop: '2022-01-01T18:00:51',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 138986,
    tank_volume_start: 3809.8000000000065,
    tank_volume_stop: 3756.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:01:06',
    timestamp_stop: '2022-01-01T18:01:13',
    quantity: 61.0,
    vehicle: 'DEM555',
    id: 138988,
    tank_volume_start: 3756.5000000000064,
    tank_volume_stop: 3697.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:01:28',
    timestamp_stop: '2022-01-01T18:01:33',
    quantity: 58.8,
    vehicle: 'DEM111',
    id: 138990,
    tank_volume_start: 3697.200000000006,
    tank_volume_stop: 3639.700000000006,
  },
  {
    timestamp_start: '2022-01-01T18:01:48',
    timestamp_stop: '2022-01-01T18:01:54',
    quantity: 52.3,
    vehicle: 'DEM333',
    id: 138992,
    tank_volume_start: 3639.700000000006,
    tank_volume_stop: 3579.900000000006,
  },
  {
    timestamp_start: '2022-01-01T18:02:09',
    timestamp_stop: '2022-01-01T18:02:14',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 138994,
    tank_volume_start: 3579.900000000006,
    tank_volume_stop: 3520.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:02:29',
    timestamp_stop: '2022-01-01T18:02:35',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 138996,
    tank_volume_start: 3520.400000000006,
    tank_volume_stop: 3465.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:02:50',
    timestamp_stop: '2022-01-01T18:02:55',
    quantity: 48.7,
    vehicle: 'DEM777',
    id: 138998,
    tank_volume_start: 3465.600000000006,
    tank_volume_stop: 3406.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:03:10',
    timestamp_stop: '2022-01-01T18:03:14',
    quantity: 45.9,
    vehicle: 'DEM888',
    id: 139000,
    tank_volume_start: 3406.600000000006,
    tank_volume_stop: 3350.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:03:29',
    timestamp_stop: '2022-01-01T18:03:34',
    quantity: 47.3,
    vehicle: 'DEM333',
    id: 139002,
    tank_volume_start: 3350.600000000006,
    tank_volume_stop: 3289.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:03:49',
    timestamp_stop: '2022-01-01T18:03:54',
    quantity: 48.1,
    vehicle: 'DEM111',
    id: 139004,
    tank_volume_start: 3289.400000000006,
    tank_volume_stop: 3229.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:04:09',
    timestamp_stop: '2022-01-01T18:04:14',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 139006,
    tank_volume_start: 3229.600000000006,
    tank_volume_stop: 3174.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:04:29',
    timestamp_stop: '2022-01-01T18:04:34',
    quantity: 50.1,
    vehicle: 'DEM222',
    id: 139008,
    tank_volume_start: 3174.2000000000057,
    tank_volume_stop: 3118.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:04:49',
    timestamp_stop: '2022-01-01T18:04:54',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 139010,
    tank_volume_start: 3118.3000000000056,
    tank_volume_stop: 3067.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:05:09',
    timestamp_stop: '2022-01-01T18:05:16',
    quantity: 64.9,
    vehicle: 'DEM000',
    id: 139012,
    tank_volume_start: 3067.600000000006,
    tank_volume_stop: 3014.500000000006,
  },
  {
    timestamp_start: '2022-01-01T18:05:31',
    timestamp_stop: '2022-01-01T18:05:35',
    quantity: 47.3,
    vehicle: 'DEM888',
    id: 139014,
    tank_volume_start: 3014.500000000006,
    tank_volume_stop: 2954.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:05:50',
    timestamp_stop: '2022-01-01T18:05:56',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 139016,
    tank_volume_start: 2954.2000000000057,
    tank_volume_stop: 2896.100000000006,
  },
  {
    timestamp_start: '2022-01-01T18:06:11',
    timestamp_stop: '2022-01-01T18:06:17',
    quantity: 60.8,
    vehicle: 'DEM888',
    id: 139018,
    tank_volume_start: 2896.100000000006,
    tank_volume_stop: 2836.000000000006,
  },
  {
    timestamp_start: '2022-01-01T18:06:32',
    timestamp_stop: '2022-01-01T18:06:37',
    quantity: 51.8,
    vehicle: 'DEM333',
    id: 139020,
    tank_volume_start: 2836.000000000006,
    tank_volume_stop: 2775.100000000006,
  },
  {
    timestamp_start: '2022-01-01T18:06:52',
    timestamp_stop: '2022-01-01T18:06:58',
    quantity: 57.7,
    vehicle: 'DEM777',
    id: 139022,
    tank_volume_start: 2775.100000000006,
    tank_volume_stop: 2719.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:07:13',
    timestamp_stop: '2022-01-01T18:07:18',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 139024,
    tank_volume_start: 2719.400000000006,
    tank_volume_stop: 2662.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:07:33',
    timestamp_stop: '2022-01-01T18:07:37',
    quantity: 47.3,
    vehicle: 'DEM444',
    id: 139026,
    tank_volume_start: 2662.400000000006,
    tank_volume_stop: 2597.900000000006,
  },
  {
    timestamp_start: '2022-01-01T18:07:52',
    timestamp_stop: '2022-01-01T18:07:58',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 139028,
    tank_volume_start: 2597.900000000006,
    tank_volume_stop: 2548.800000000006,
  },
  {
    timestamp_start: '2022-01-01T18:08:13',
    timestamp_stop: '2022-01-01T18:08:19',
    quantity: 62.9,
    vehicle: 'DEM555',
    id: 139030,
    tank_volume_start: 2548.800000000006,
    tank_volume_stop: 2490.500000000006,
  },
  {
    timestamp_start: '2022-01-01T18:08:34',
    timestamp_stop: '2022-01-01T18:08:40',
    quantity: 57.2,
    vehicle: 'DEM888',
    id: 139032,
    tank_volume_start: 2490.500000000006,
    tank_volume_stop: 2435.300000000006,
  },
  {
    timestamp_start: '2022-01-01T18:08:55',
    timestamp_stop: '2022-01-01T18:09:01',
    quantity: 62.5,
    vehicle: 'DEM888',
    id: 139034,
    tank_volume_start: 2435.300000000006,
    tank_volume_stop: 2379.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:09:16',
    timestamp_stop: '2022-01-01T18:09:22',
    quantity: 57.2,
    vehicle: 'DEM222',
    id: 139036,
    tank_volume_start: 2379.400000000006,
    tank_volume_stop: 2320.500000000006,
  },
  {
    timestamp_start: '2022-01-01T18:09:37',
    timestamp_stop: '2022-01-01T18:09:42',
    quantity: 54.4,
    vehicle: 'DEM111',
    id: 139038,
    tank_volume_start: 2320.500000000006,
    tank_volume_stop: 2266.000000000006,
  },
  {
    timestamp_start: '2022-01-01T18:09:57',
    timestamp_stop: '2022-01-01T18:10:03',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 139040,
    tank_volume_start: 2266.000000000006,
    tank_volume_stop: 2209.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:10:18',
    timestamp_stop: '2022-01-01T18:10:24',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 139042,
    tank_volume_start: 2209.2000000000057,
    tank_volume_stop: 2157.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:10:39',
    timestamp_stop: '2022-01-01T18:10:44',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 139044,
    tank_volume_start: 2157.3000000000056,
    tank_volume_stop: 2107.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:10:59',
    timestamp_stop: '2022-01-01T18:11:04',
    quantity: 48.4,
    vehicle: 'DEM555',
    id: 139046,
    tank_volume_start: 2107.9000000000055,
    tank_volume_stop: 2049.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:11:19',
    timestamp_stop: '2022-01-01T18:11:24',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 139048,
    tank_volume_start: 2049.5000000000055,
    tank_volume_stop: 1986.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:11:39',
    timestamp_stop: '2022-01-01T18:11:43',
    quantity: 48.9,
    vehicle: 'DEM888',
    id: 139050,
    tank_volume_start: 3986.9000000000055,
    tank_volume_stop: 3937.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:11:58',
    timestamp_stop: '2022-01-01T18:12:04',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 139052,
    tank_volume_start: 3937.3000000000056,
    tank_volume_stop: 3883.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:12:19',
    timestamp_stop: '2022-01-01T18:12:24',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 139054,
    tank_volume_start: 3883.2000000000057,
    tank_volume_stop: 3823.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:12:39',
    timestamp_stop: '2022-01-01T18:12:45',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 139056,
    tank_volume_start: 3823.4000000000055,
    tank_volume_stop: 3771.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:13:00',
    timestamp_stop: '2022-01-01T18:13:05',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 139058,
    tank_volume_start: 3771.7000000000057,
    tank_volume_stop: 3717.000000000006,
  },
  {
    timestamp_start: '2022-01-01T18:13:20',
    timestamp_stop: '2022-01-01T18:13:26',
    quantity: 56.5,
    vehicle: 'DEM777',
    id: 139060,
    tank_volume_start: 3717.000000000006,
    tank_volume_stop: 3656.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:13:41',
    timestamp_stop: '2022-01-01T18:13:46',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 139062,
    tank_volume_start: 3656.600000000006,
    tank_volume_stop: 3602.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:14:01',
    timestamp_stop: '2022-01-01T18:14:06',
    quantity: 47.2,
    vehicle: 'DEM333',
    id: 139064,
    tank_volume_start: 3602.3000000000056,
    tank_volume_stop: 3546.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:14:21',
    timestamp_stop: '2022-01-01T18:14:27',
    quantity: 58.8,
    vehicle: 'DEM666',
    id: 139066,
    tank_volume_start: 3546.8000000000056,
    tank_volume_stop: 3495.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:14:42',
    timestamp_stop: '2022-01-01T18:14:47',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 139068,
    tank_volume_start: 3495.7000000000057,
    tank_volume_stop: 3446.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:15:02',
    timestamp_stop: '2022-01-01T18:15:07',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 139070,
    tank_volume_start: 3446.2000000000057,
    tank_volume_stop: 3391.600000000006,
  },
  {
    timestamp_start: '2022-01-01T18:15:22',
    timestamp_stop: '2022-01-01T18:15:27',
    quantity: 51.0,
    vehicle: 'DEM000',
    id: 139072,
    tank_volume_start: 3391.600000000006,
    tank_volume_stop: 3329.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:15:42',
    timestamp_stop: '2022-01-01T18:15:49',
    quantity: 63.7,
    vehicle: 'DEM666',
    id: 139074,
    tank_volume_start: 3329.400000000006,
    tank_volume_stop: 3273.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:16:04',
    timestamp_stop: '2022-01-01T18:16:09',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 139076,
    tank_volume_start: 3273.200000000006,
    tank_volume_stop: 3214.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:16:24',
    timestamp_stop: '2022-01-01T18:16:30',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 139078,
    tank_volume_start: 3214.0000000000064,
    tank_volume_stop: 3156.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:16:45',
    timestamp_stop: '2022-01-01T18:16:51',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 139080,
    tank_volume_start: 3156.9000000000065,
    tank_volume_stop: 3099.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:17:06',
    timestamp_stop: '2022-01-01T18:17:11',
    quantity: 51.2,
    vehicle: 'DEM777',
    id: 139082,
    tank_volume_start: 3099.3000000000065,
    tank_volume_stop: 3044.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:17:26',
    timestamp_stop: '2022-01-01T18:17:31',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 139084,
    tank_volume_start: 3044.8000000000065,
    tank_volume_stop: 2985.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:17:46',
    timestamp_stop: '2022-01-01T18:17:52',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 139086,
    tank_volume_start: 2985.3000000000065,
    tank_volume_stop: 2928.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:18:07',
    timestamp_stop: '2022-01-01T18:18:12',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 139088,
    tank_volume_start: 2928.6000000000067,
    tank_volume_stop: 2877.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:18:27',
    timestamp_stop: '2022-01-01T18:18:33',
    quantity: 55.0,
    vehicle: 'DEM888',
    id: 139090,
    tank_volume_start: 2877.2000000000066,
    tank_volume_stop: 2818.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:18:48',
    timestamp_stop: '2022-01-01T18:18:54',
    quantity: 58.0,
    vehicle: 'DEM888',
    id: 139092,
    tank_volume_start: 2818.6000000000067,
    tank_volume_stop: 2768.400000000007,
  },
  {
    timestamp_start: '2022-01-01T18:19:09',
    timestamp_stop: '2022-01-01T18:19:14',
    quantity: 56.0,
    vehicle: 'DEM000',
    id: 139094,
    tank_volume_start: 2768.400000000007,
    tank_volume_stop: 2715.700000000007,
  },
  {
    timestamp_start: '2022-01-01T18:19:29',
    timestamp_stop: '2022-01-01T18:19:35',
    quantity: 61.2,
    vehicle: 'DEM555',
    id: 139096,
    tank_volume_start: 2715.700000000007,
    tank_volume_stop: 2658.300000000007,
  },
  {
    timestamp_start: '2022-01-01T18:19:50',
    timestamp_stop: '2022-01-01T18:19:56',
    quantity: 55.8,
    vehicle: 'DEM666',
    id: 139098,
    tank_volume_start: 2658.300000000007,
    tank_volume_stop: 2597.000000000007,
  },
  {
    timestamp_start: '2022-01-01T18:20:11',
    timestamp_stop: '2022-01-01T18:20:17',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 139100,
    tank_volume_start: 2597.000000000007,
    tank_volume_stop: 2533.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:20:32',
    timestamp_stop: '2022-01-01T18:20:38',
    quantity: 63.9,
    vehicle: 'DEM555',
    id: 139102,
    tank_volume_start: 2533.500000000007,
    tank_volume_stop: 2479.000000000007,
  },
  {
    timestamp_start: '2022-01-01T18:20:53',
    timestamp_stop: '2022-01-01T18:21:00',
    quantity: 64.7,
    vehicle: 'DEM888',
    id: 139104,
    tank_volume_start: 2479.000000000007,
    tank_volume_stop: 2425.000000000007,
  },
  {
    timestamp_start: '2022-01-01T18:21:15',
    timestamp_stop: '2022-01-01T18:21:20',
    quantity: 58.0,
    vehicle: 'DEM333',
    id: 139106,
    tank_volume_start: 2425.000000000007,
    tank_volume_stop: 2370.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:21:35',
    timestamp_stop: '2022-01-01T18:21:42',
    quantity: 63.3,
    vehicle: 'DEM666',
    id: 139108,
    tank_volume_start: 2370.500000000007,
    tank_volume_stop: 2314.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:21:57',
    timestamp_stop: '2022-01-01T18:22:02',
    quantity: 51.5,
    vehicle: 'DEM222',
    id: 139110,
    tank_volume_start: 2314.6000000000067,
    tank_volume_stop: 2260.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:22:17',
    timestamp_stop: '2022-01-01T18:22:21',
    quantity: 45.3,
    vehicle: 'DEM000',
    id: 139112,
    tank_volume_start: 2260.3000000000065,
    tank_volume_stop: 2207.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:22:36',
    timestamp_stop: '2022-01-01T18:22:42',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 139114,
    tank_volume_start: 2207.0000000000064,
    tank_volume_stop: 2159.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:22:57',
    timestamp_stop: '2022-01-01T18:23:03',
    quantity: 61.5,
    vehicle: 'DEM333',
    id: 139116,
    tank_volume_start: 2159.3000000000065,
    tank_volume_stop: 2103.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:23:18',
    timestamp_stop: '2022-01-01T18:23:24',
    quantity: 55.6,
    vehicle: 'DEM666',
    id: 139118,
    tank_volume_start: 2103.5000000000064,
    tank_volume_stop: 2050.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:23:39',
    timestamp_stop: '2022-01-01T18:23:44',
    quantity: 53.5,
    vehicle: 'DEM666',
    id: 139120,
    tank_volume_start: 2050.200000000006,
    tank_volume_stop: 1995.0000000000061,
  },
  {
    timestamp_start: '2022-01-01T18:23:59',
    timestamp_stop: '2022-01-01T18:24:05',
    quantity: 56.6,
    vehicle: 'DEM555',
    id: 139122,
    tank_volume_start: 3995.0000000000064,
    tank_volume_stop: 3931.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:24:20',
    timestamp_stop: '2022-01-01T18:24:24',
    quantity: 47.3,
    vehicle: 'DEM222',
    id: 139124,
    tank_volume_start: 3931.5000000000064,
    tank_volume_stop: 3876.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:24:39',
    timestamp_stop: '2022-01-01T18:24:45',
    quantity: 53.3,
    vehicle: 'DEM777',
    id: 139126,
    tank_volume_start: 3876.5000000000064,
    tank_volume_stop: 3817.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:25:00',
    timestamp_stop: '2022-01-01T18:25:05',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 139128,
    tank_volume_start: 3817.4000000000065,
    tank_volume_stop: 3765.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:25:20',
    timestamp_stop: '2022-01-01T18:25:25',
    quantity: 55.1,
    vehicle: 'DEM222',
    id: 139130,
    tank_volume_start: 3765.4000000000065,
    tank_volume_stop: 3704.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:25:40',
    timestamp_stop: '2022-01-01T18:25:46',
    quantity: 57.9,
    vehicle: 'DEM777',
    id: 139132,
    tank_volume_start: 3704.2000000000066,
    tank_volume_stop: 3650.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:26:01',
    timestamp_stop: '2022-01-01T18:26:06',
    quantity: 49.2,
    vehicle: 'DEM333',
    id: 139134,
    tank_volume_start: 3650.7000000000066,
    tank_volume_stop: 3596.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:26:21',
    timestamp_stop: '2022-01-01T18:26:27',
    quantity: 64.3,
    vehicle: 'DEM444',
    id: 139136,
    tank_volume_start: 3596.1000000000067,
    tank_volume_stop: 3546.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:26:42',
    timestamp_stop: '2022-01-01T18:26:49',
    quantity: 61.7,
    vehicle: 'DEM777',
    id: 139138,
    tank_volume_start: 3546.2000000000066,
    tank_volume_stop: 3489.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:27:04',
    timestamp_stop: '2022-01-01T18:27:09',
    quantity: 57.3,
    vehicle: 'DEM888',
    id: 139140,
    tank_volume_start: 3489.2000000000066,
    tank_volume_stop: 3442.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:27:24',
    timestamp_stop: '2022-01-01T18:27:31',
    quantity: 63.7,
    vehicle: 'DEM111',
    id: 139142,
    tank_volume_start: 3442.2000000000066,
    tank_volume_stop: 3384.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:27:46',
    timestamp_stop: '2022-01-01T18:27:52',
    quantity: 60.6,
    vehicle: 'DEM222',
    id: 139144,
    tank_volume_start: 3384.8000000000065,
    tank_volume_stop: 3332.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:28:07',
    timestamp_stop: '2022-01-01T18:28:12',
    quantity: 51.1,
    vehicle: 'DEM666',
    id: 139146,
    tank_volume_start: 3332.5000000000064,
    tank_volume_stop: 3280.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T18:28:27',
    timestamp_stop: '2022-01-01T18:28:33',
    quantity: 64.2,
    vehicle: 'DEM777',
    id: 139148,
    tank_volume_start: 3280.1000000000063,
    tank_volume_stop: 3222.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:28:48',
    timestamp_stop: '2022-01-01T18:28:53',
    quantity: 49.7,
    vehicle: 'DEM555',
    id: 139150,
    tank_volume_start: 3222.5000000000064,
    tank_volume_stop: 3158.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:29:08',
    timestamp_stop: '2022-01-01T18:29:14',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 139152,
    tank_volume_start: 3158.8000000000065,
    tank_volume_stop: 3099.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:29:29',
    timestamp_stop: '2022-01-01T18:29:35',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 139154,
    tank_volume_start: 3099.1000000000067,
    tank_volume_stop: 3044.900000000007,
  },
  {
    timestamp_start: '2022-01-01T18:29:50',
    timestamp_stop: '2022-01-01T18:29:56',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 139156,
    tank_volume_start: 3044.900000000007,
    tank_volume_stop: 2992.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:30:11',
    timestamp_stop: '2022-01-01T18:30:16',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 139158,
    tank_volume_start: 2992.1000000000067,
    tank_volume_stop: 2930.900000000007,
  },
  {
    timestamp_start: '2022-01-01T18:30:31',
    timestamp_stop: '2022-01-01T18:30:37',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 139160,
    tank_volume_start: 2930.900000000007,
    tank_volume_stop: 2879.300000000007,
  },
  {
    timestamp_start: '2022-01-01T18:30:52',
    timestamp_stop: '2022-01-01T18:30:57',
    quantity: 50.1,
    vehicle: 'DEM555',
    id: 139162,
    tank_volume_start: 2879.300000000007,
    tank_volume_stop: 2831.700000000007,
  },
  {
    timestamp_start: '2022-01-01T18:31:12',
    timestamp_stop: '2022-01-01T18:31:17',
    quantity: 50.4,
    vehicle: 'DEM777',
    id: 139164,
    tank_volume_start: 2831.700000000007,
    tank_volume_stop: 2774.800000000007,
  },
  {
    timestamp_start: '2022-01-01T18:31:32',
    timestamp_stop: '2022-01-01T18:31:37',
    quantity: 56.1,
    vehicle: 'DEM888',
    id: 139166,
    tank_volume_start: 2774.800000000007,
    tank_volume_stop: 2727.400000000007,
  },
  {
    timestamp_start: '2022-01-01T18:31:52',
    timestamp_stop: '2022-01-01T18:31:58',
    quantity: 55.3,
    vehicle: 'DEM111',
    id: 139168,
    tank_volume_start: 2727.400000000007,
    tank_volume_stop: 2673.000000000007,
  },
  {
    timestamp_start: '2022-01-01T18:32:13',
    timestamp_stop: '2022-01-01T18:32:19',
    quantity: 56.2,
    vehicle: 'DEM555',
    id: 139170,
    tank_volume_start: 2673.000000000007,
    tank_volume_stop: 2613.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:32:34',
    timestamp_stop: '2022-01-01T18:32:40',
    quantity: 60.5,
    vehicle: 'DEM222',
    id: 139172,
    tank_volume_start: 2613.7000000000066,
    tank_volume_stop: 2561.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:32:55',
    timestamp_stop: '2022-01-01T18:33:00',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 139174,
    tank_volume_start: 2561.6000000000067,
    tank_volume_stop: 2511.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:33:15',
    timestamp_stop: '2022-01-01T18:33:20',
    quantity: 54.7,
    vehicle: 'DEM222',
    id: 139176,
    tank_volume_start: 2511.6000000000067,
    tank_volume_stop: 2464.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:33:35',
    timestamp_stop: '2022-01-01T18:33:41',
    quantity: 50.6,
    vehicle: 'DEM666',
    id: 139178,
    tank_volume_start: 2464.6000000000067,
    tank_volume_stop: 2410.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:33:56',
    timestamp_stop: '2022-01-01T18:34:01',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 139180,
    tank_volume_start: 2410.2000000000066,
    tank_volume_stop: 2355.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:34:16',
    timestamp_stop: '2022-01-01T18:34:21',
    quantity: 49.5,
    vehicle: 'DEM666',
    id: 139182,
    tank_volume_start: 2355.500000000007,
    tank_volume_stop: 2300.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:34:36',
    timestamp_stop: '2022-01-01T18:34:42',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 139184,
    tank_volume_start: 2300.500000000007,
    tank_volume_stop: 2244.300000000007,
  },
  {
    timestamp_start: '2022-01-01T18:34:57',
    timestamp_stop: '2022-01-01T18:35:02',
    quantity: 50.0,
    vehicle: 'DEM444',
    id: 139186,
    tank_volume_start: 2244.300000000007,
    tank_volume_stop: 2184.800000000007,
  },
  {
    timestamp_start: '2022-01-01T18:35:17',
    timestamp_stop: '2022-01-01T18:35:23',
    quantity: 61.5,
    vehicle: 'DEM111',
    id: 139188,
    tank_volume_start: 2184.800000000007,
    tank_volume_stop: 2131.900000000007,
  },
  {
    timestamp_start: '2022-01-01T18:35:38',
    timestamp_stop: '2022-01-01T18:35:44',
    quantity: 62.9,
    vehicle: 'DEM555',
    id: 139190,
    tank_volume_start: 2131.900000000007,
    tank_volume_stop: 2080.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:35:59',
    timestamp_stop: '2022-01-01T18:36:05',
    quantity: 58.4,
    vehicle: 'DEM444',
    id: 139192,
    tank_volume_start: 2080.1000000000067,
    tank_volume_stop: 2026.9000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:36:20',
    timestamp_stop: '2022-01-01T18:36:25',
    quantity: 55.5,
    vehicle: 'DEM666',
    id: 139194,
    tank_volume_start: 2026.9000000000067,
    tank_volume_stop: 1972.4000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:36:40',
    timestamp_stop: '2022-01-01T18:36:46',
    quantity: 56.9,
    vehicle: 'DEM333',
    id: 139196,
    tank_volume_start: 3972.400000000007,
    tank_volume_stop: 3914.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:37:01',
    timestamp_stop: '2022-01-01T18:37:07',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 139198,
    tank_volume_start: 3914.500000000007,
    tank_volume_stop: 3854.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:37:22',
    timestamp_stop: '2022-01-01T18:37:27',
    quantity: 54.5,
    vehicle: 'DEM666',
    id: 139200,
    tank_volume_start: 3854.1000000000067,
    tank_volume_stop: 3804.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:37:42',
    timestamp_stop: '2022-01-01T18:37:48',
    quantity: 55.7,
    vehicle: 'DEM666',
    id: 139202,
    tank_volume_start: 3804.7000000000066,
    tank_volume_stop: 3749.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:38:03',
    timestamp_stop: '2022-01-01T18:38:07',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 139204,
    tank_volume_start: 3749.500000000007,
    tank_volume_stop: 3692.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:38:22',
    timestamp_stop: '2022-01-01T18:38:28',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 139206,
    tank_volume_start: 3692.7000000000066,
    tank_volume_stop: 3642.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:38:43',
    timestamp_stop: '2022-01-01T18:38:49',
    quantity: 59.5,
    vehicle: 'DEM333',
    id: 139208,
    tank_volume_start: 3642.2000000000066,
    tank_volume_stop: 3588.500000000007,
  },
  {
    timestamp_start: '2022-01-01T18:39:04',
    timestamp_stop: '2022-01-01T18:39:10',
    quantity: 64.8,
    vehicle: 'DEM555',
    id: 139210,
    tank_volume_start: 3588.500000000007,
    tank_volume_stop: 3532.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T18:39:25',
    timestamp_stop: '2022-01-01T18:39:31',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 139212,
    tank_volume_start: 3532.1000000000067,
    tank_volume_stop: 3470.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:39:46',
    timestamp_stop: '2022-01-01T18:39:51',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 139214,
    tank_volume_start: 3470.2000000000066,
    tank_volume_stop: 3412.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T18:40:06',
    timestamp_stop: '2022-01-01T18:40:12',
    quantity: 55.3,
    vehicle: 'DEM333',
    id: 139216,
    tank_volume_start: 3412.2000000000066,
    tank_volume_stop: 3357.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:40:27',
    timestamp_stop: '2022-01-01T18:40:32',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 139218,
    tank_volume_start: 3357.4000000000065,
    tank_volume_stop: 3301.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:40:47',
    timestamp_stop: '2022-01-01T18:40:53',
    quantity: 58.0,
    vehicle: 'DEM888',
    id: 139220,
    tank_volume_start: 3301.9000000000065,
    tank_volume_stop: 3253.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:41:08',
    timestamp_stop: '2022-01-01T18:41:13',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 139222,
    tank_volume_start: 3253.8000000000065,
    tank_volume_stop: 3201.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:41:28',
    timestamp_stop: '2022-01-01T18:41:33',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 139224,
    tank_volume_start: 3201.9000000000065,
    tank_volume_stop: 3146.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T18:41:48',
    timestamp_stop: '2022-01-01T18:41:53',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 139226,
    tank_volume_start: 3146.1000000000063,
    tank_volume_stop: 3088.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:42:08',
    timestamp_stop: '2022-01-01T18:42:13',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 139228,
    tank_volume_start: 3088.9000000000065,
    tank_volume_stop: 3042.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:42:28',
    timestamp_stop: '2022-01-01T18:42:34',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 139230,
    tank_volume_start: 3042.3000000000065,
    tank_volume_stop: 2991.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:42:49',
    timestamp_stop: '2022-01-01T18:42:55',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 139232,
    tank_volume_start: 2991.5000000000064,
    tank_volume_stop: 2927.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:43:10',
    timestamp_stop: '2022-01-01T18:43:14',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 139234,
    tank_volume_start: 2927.9000000000065,
    tank_volume_stop: 2875.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:43:29',
    timestamp_stop: '2022-01-01T18:43:35',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 139236,
    tank_volume_start: 2875.4000000000065,
    tank_volume_stop: 2817.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T18:43:50',
    timestamp_stop: '2022-01-01T18:43:56',
    quantity: 60.6,
    vehicle: 'DEM888',
    id: 139238,
    tank_volume_start: 2817.9000000000065,
    tank_volume_stop: 2764.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:44:11',
    timestamp_stop: '2022-01-01T18:44:16',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 139240,
    tank_volume_start: 2764.0000000000064,
    tank_volume_stop: 2715.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:44:31',
    timestamp_stop: '2022-01-01T18:44:36',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 139242,
    tank_volume_start: 2715.200000000006,
    tank_volume_stop: 2663.300000000006,
  },
  {
    timestamp_start: '2022-01-01T18:44:51',
    timestamp_stop: '2022-01-01T18:44:57',
    quantity: 55.4,
    vehicle: 'DEM333',
    id: 139244,
    tank_volume_start: 2663.300000000006,
    tank_volume_stop: 2604.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:45:12',
    timestamp_stop: '2022-01-01T18:45:17',
    quantity: 51.3,
    vehicle: 'DEM222',
    id: 139246,
    tank_volume_start: 2604.200000000006,
    tank_volume_stop: 2549.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:45:32',
    timestamp_stop: '2022-01-01T18:45:36',
    quantity: 46.0,
    vehicle: 'DEM333',
    id: 139248,
    tank_volume_start: 2549.5000000000064,
    tank_volume_stop: 2487.200000000006,
  },
  {
    timestamp_start: '2022-01-01T18:45:51',
    timestamp_stop: '2022-01-01T18:45:57',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 139250,
    tank_volume_start: 2487.200000000006,
    tank_volume_stop: 2435.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:46:12',
    timestamp_stop: '2022-01-01T18:46:17',
    quantity: 52.3,
    vehicle: 'DEM222',
    id: 139252,
    tank_volume_start: 2435.400000000006,
    tank_volume_stop: 2390.000000000006,
  },
  {
    timestamp_start: '2022-01-01T18:46:32',
    timestamp_stop: '2022-01-01T18:46:37',
    quantity: 53.0,
    vehicle: 'DEM000',
    id: 139254,
    tank_volume_start: 2390.000000000006,
    tank_volume_stop: 2334.300000000006,
  },
  {
    timestamp_start: '2022-01-01T18:46:52',
    timestamp_stop: '2022-01-01T18:46:59',
    quantity: 61.8,
    vehicle: 'DEM111',
    id: 139256,
    tank_volume_start: 2334.300000000006,
    tank_volume_stop: 2281.800000000006,
  },
  {
    timestamp_start: '2022-01-01T18:47:14',
    timestamp_stop: '2022-01-01T18:47:18',
    quantity: 48.5,
    vehicle: 'DEM000',
    id: 139258,
    tank_volume_start: 2281.800000000006,
    tank_volume_stop: 2226.700000000006,
  },
  {
    timestamp_start: '2022-01-01T18:47:33',
    timestamp_stop: '2022-01-01T18:47:39',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 139260,
    tank_volume_start: 2226.700000000006,
    tank_volume_stop: 2173.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:47:54',
    timestamp_stop: '2022-01-01T18:48:00',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 139262,
    tank_volume_start: 2173.400000000006,
    tank_volume_stop: 2118.500000000006,
  },
  {
    timestamp_start: '2022-01-01T18:48:15',
    timestamp_stop: '2022-01-01T18:48:21',
    quantity: 56.8,
    vehicle: 'DEM222',
    id: 139264,
    tank_volume_start: 2118.500000000006,
    tank_volume_stop: 2065.500000000006,
  },
  {
    timestamp_start: '2022-01-01T18:48:36',
    timestamp_stop: '2022-01-01T18:48:41',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 139266,
    tank_volume_start: 2065.500000000006,
    tank_volume_stop: 2009.900000000006,
  },
  {
    timestamp_start: '2022-01-01T18:48:56',
    timestamp_stop: '2022-01-01T18:49:01',
    quantity: 50.6,
    vehicle: 'DEM444',
    id: 139268,
    tank_volume_start: 2009.900000000006,
    tank_volume_stop: 1953.800000000006,
  },
  {
    timestamp_start: '2022-01-01T18:49:16',
    timestamp_stop: '2022-01-01T18:49:23',
    quantity: 64.8,
    vehicle: 'DEM111',
    id: 139270,
    tank_volume_start: 3953.800000000006,
    tank_volume_stop: 3899.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T18:49:38',
    timestamp_stop: '2022-01-01T18:49:44',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 139272,
    tank_volume_start: 3899.6000000000063,
    tank_volume_stop: 3848.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:49:59',
    timestamp_stop: '2022-01-01T18:50:04',
    quantity: 50.4,
    vehicle: 'DEM222',
    id: 139274,
    tank_volume_start: 3848.0000000000064,
    tank_volume_stop: 3793.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T18:50:19',
    timestamp_stop: '2022-01-01T18:50:24',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 139276,
    tank_volume_start: 3793.0000000000064,
    tank_volume_stop: 3745.700000000006,
  },
  {
    timestamp_start: '2022-01-01T18:50:39',
    timestamp_stop: '2022-01-01T18:50:44',
    quantity: 51.1,
    vehicle: 'DEM555',
    id: 139278,
    tank_volume_start: 3745.700000000006,
    tank_volume_stop: 3686.300000000006,
  },
  {
    timestamp_start: '2022-01-01T18:50:59',
    timestamp_stop: '2022-01-01T18:51:05',
    quantity: 57.8,
    vehicle: 'DEM555',
    id: 139280,
    tank_volume_start: 3686.300000000006,
    tank_volume_stop: 3629.300000000006,
  },
  {
    timestamp_start: '2022-01-01T18:51:20',
    timestamp_stop: '2022-01-01T18:51:25',
    quantity: 48.3,
    vehicle: 'DEM111',
    id: 139282,
    tank_volume_start: 3629.300000000006,
    tank_volume_stop: 3577.800000000006,
  },
  {
    timestamp_start: '2022-01-01T18:51:40',
    timestamp_stop: '2022-01-01T18:51:45',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 139284,
    tank_volume_start: 3577.800000000006,
    tank_volume_stop: 3517.400000000006,
  },
  {
    timestamp_start: '2022-01-01T18:52:00',
    timestamp_stop: '2022-01-01T18:52:06',
    quantity: 57.6,
    vehicle: 'DEM555',
    id: 139286,
    tank_volume_start: 3517.400000000006,
    tank_volume_stop: 3464.900000000006,
  },
  {
    timestamp_start: '2022-01-01T18:52:21',
    timestamp_stop: '2022-01-01T18:52:27',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 139288,
    tank_volume_start: 3464.900000000006,
    tank_volume_stop: 3413.100000000006,
  },
  {
    timestamp_start: '2022-01-01T18:52:42',
    timestamp_stop: '2022-01-01T18:52:47',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 139290,
    tank_volume_start: 3413.100000000006,
    tank_volume_stop: 3365.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T18:53:02',
    timestamp_stop: '2022-01-01T18:53:06',
    quantity: 49.1,
    vehicle: 'DEM777',
    id: 139292,
    tank_volume_start: 3365.7000000000057,
    tank_volume_stop: 3315.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:53:21',
    timestamp_stop: '2022-01-01T18:53:27',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 139294,
    tank_volume_start: 3315.4000000000055,
    tank_volume_stop: 3260.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T18:53:42',
    timestamp_stop: '2022-01-01T18:53:47',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 139296,
    tank_volume_start: 3260.6000000000054,
    tank_volume_stop: 3211.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:54:02',
    timestamp_stop: '2022-01-01T18:54:07',
    quantity: 50.2,
    vehicle: 'DEM888',
    id: 139298,
    tank_volume_start: 3211.9000000000055,
    tank_volume_stop: 3151.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:54:22',
    timestamp_stop: '2022-01-01T18:54:27',
    quantity: 49.0,
    vehicle: 'DEM111',
    id: 139300,
    tank_volume_start: 3151.5000000000055,
    tank_volume_stop: 3099.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T18:54:42',
    timestamp_stop: '2022-01-01T18:54:47',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 139302,
    tank_volume_start: 3099.6000000000054,
    tank_volume_stop: 3050.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T18:55:02',
    timestamp_stop: '2022-01-01T18:55:08',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 139304,
    tank_volume_start: 3050.2000000000053,
    tank_volume_stop: 2992.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:55:23',
    timestamp_stop: '2022-01-01T18:55:28',
    quantity: 45.5,
    vehicle: 'DEM111',
    id: 139306,
    tank_volume_start: 2992.5000000000055,
    tank_volume_stop: 2929.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T18:55:43',
    timestamp_stop: '2022-01-01T18:55:48',
    quantity: 47.7,
    vehicle: 'DEM777',
    id: 139308,
    tank_volume_start: 2929.7000000000053,
    tank_volume_stop: 2871.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T18:56:03',
    timestamp_stop: '2022-01-01T18:56:07',
    quantity: 48.4,
    vehicle: 'DEM555',
    id: 139310,
    tank_volume_start: 2871.2000000000053,
    tank_volume_stop: 2822.900000000005,
  },
  {
    timestamp_start: '2022-01-01T18:56:22',
    timestamp_stop: '2022-01-01T18:56:29',
    quantity: 64.2,
    vehicle: 'DEM222',
    id: 139312,
    tank_volume_start: 2822.900000000005,
    tank_volume_stop: 2762.400000000005,
  },
  {
    timestamp_start: '2022-01-01T18:56:44',
    timestamp_stop: '2022-01-01T18:56:49',
    quantity: 53.6,
    vehicle: 'DEM777',
    id: 139314,
    tank_volume_start: 2762.400000000005,
    tank_volume_stop: 2709.100000000005,
  },
  {
    timestamp_start: '2022-01-01T18:57:04',
    timestamp_stop: '2022-01-01T18:57:10',
    quantity: 62.0,
    vehicle: 'DEM777',
    id: 139316,
    tank_volume_start: 2709.100000000005,
    tank_volume_stop: 2656.900000000005,
  },
  {
    timestamp_start: '2022-01-01T18:57:25',
    timestamp_stop: '2022-01-01T18:57:31',
    quantity: 55.0,
    vehicle: 'DEM333',
    id: 139318,
    tank_volume_start: 2656.900000000005,
    tank_volume_stop: 2600.100000000005,
  },
  {
    timestamp_start: '2022-01-01T18:57:46',
    timestamp_stop: '2022-01-01T18:57:51',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 139320,
    tank_volume_start: 2600.100000000005,
    tank_volume_stop: 2541.000000000005,
  },
  {
    timestamp_start: '2022-01-01T18:58:06',
    timestamp_stop: '2022-01-01T18:58:11',
    quantity: 47.6,
    vehicle: 'DEM888',
    id: 139322,
    tank_volume_start: 2541.000000000005,
    tank_volume_stop: 2480.800000000005,
  },
  {
    timestamp_start: '2022-01-01T18:58:26',
    timestamp_stop: '2022-01-01T18:58:31',
    quantity: 53.3,
    vehicle: 'DEM111',
    id: 139324,
    tank_volume_start: 2480.800000000005,
    tank_volume_stop: 2425.900000000005,
  },
  {
    timestamp_start: '2022-01-01T18:58:46',
    timestamp_stop: '2022-01-01T18:58:52',
    quantity: 59.7,
    vehicle: 'DEM444',
    id: 139326,
    tank_volume_start: 2425.900000000005,
    tank_volume_stop: 2373.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T18:59:07',
    timestamp_stop: '2022-01-01T18:59:12',
    quantity: 51.7,
    vehicle: 'DEM000',
    id: 139328,
    tank_volume_start: 2373.2000000000053,
    tank_volume_stop: 2317.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T18:59:27',
    timestamp_stop: '2022-01-01T18:59:33',
    quantity: 51.2,
    vehicle: 'DEM222',
    id: 139330,
    tank_volume_start: 2317.5000000000055,
    tank_volume_stop: 2259.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T18:59:48',
    timestamp_stop: '2022-01-01T18:59:53',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 139332,
    tank_volume_start: 2259.8000000000056,
    tank_volume_stop: 2202.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:00:08',
    timestamp_stop: '2022-01-01T19:00:14',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 139334,
    tank_volume_start: 2202.9000000000055,
    tank_volume_stop: 2153.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:00:29',
    timestamp_stop: '2022-01-01T19:00:34',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 139336,
    tank_volume_start: 2153.3000000000056,
    tank_volume_stop: 2092.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:00:49',
    timestamp_stop: '2022-01-01T19:00:55',
    quantity: 60.5,
    vehicle: 'DEM444',
    id: 139338,
    tank_volume_start: 2092.0000000000055,
    tank_volume_stop: 2036.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:01:10',
    timestamp_stop: '2022-01-01T19:01:15',
    quantity: 51.5,
    vehicle: 'DEM888',
    id: 139340,
    tank_volume_start: 2036.5000000000055,
    tank_volume_stop: 1978.8000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:01:30',
    timestamp_stop: '2022-01-01T19:01:36',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 139342,
    tank_volume_start: 3978.8000000000056,
    tank_volume_stop: 3927.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:01:51',
    timestamp_stop: '2022-01-01T19:01:57',
    quantity: 60.3,
    vehicle: 'DEM777',
    id: 139344,
    tank_volume_start: 3927.0000000000055,
    tank_volume_stop: 3867.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:02:12',
    timestamp_stop: '2022-01-01T19:02:18',
    quantity: 55.9,
    vehicle: 'DEM666',
    id: 139346,
    tank_volume_start: 3867.3000000000056,
    tank_volume_stop: 3808.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:02:33',
    timestamp_stop: '2022-01-01T19:02:38',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 139348,
    tank_volume_start: 3808.4000000000055,
    tank_volume_stop: 3761.1000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:02:53',
    timestamp_stop: '2022-01-01T19:02:59',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 139350,
    tank_volume_start: 3761.1000000000054,
    tank_volume_stop: 3702.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:03:14',
    timestamp_stop: '2022-01-01T19:03:18',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 139352,
    tank_volume_start: 3702.4000000000055,
    tank_volume_stop: 3640.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:03:33',
    timestamp_stop: '2022-01-01T19:03:39',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 139354,
    tank_volume_start: 3640.8000000000056,
    tank_volume_stop: 3585.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:03:54',
    timestamp_stop: '2022-01-01T19:04:00',
    quantity: 59.4,
    vehicle: 'DEM222',
    id: 139356,
    tank_volume_start: 3585.4000000000055,
    tank_volume_stop: 3530.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:04:15',
    timestamp_stop: '2022-01-01T19:04:21',
    quantity: 64.6,
    vehicle: 'DEM000',
    id: 139358,
    tank_volume_start: 3530.9000000000055,
    tank_volume_stop: 3472.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:04:36',
    timestamp_stop: '2022-01-01T19:04:42',
    quantity: 54.3,
    vehicle: 'DEM333',
    id: 139360,
    tank_volume_start: 3472.0000000000055,
    tank_volume_stop: 3423.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:04:57',
    timestamp_stop: '2022-01-01T19:05:03',
    quantity: 57.8,
    vehicle: 'DEM222',
    id: 139362,
    tank_volume_start: 3423.6000000000054,
    tank_volume_stop: 3366.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:05:18',
    timestamp_stop: '2022-01-01T19:05:23',
    quantity: 53.7,
    vehicle: 'DEM666',
    id: 139364,
    tank_volume_start: 3366.4000000000055,
    tank_volume_stop: 3310.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:05:38',
    timestamp_stop: '2022-01-01T19:05:43',
    quantity: 52.9,
    vehicle: 'DEM111',
    id: 139366,
    tank_volume_start: 3310.8000000000056,
    tank_volume_stop: 3256.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:05:58',
    timestamp_stop: '2022-01-01T19:06:04',
    quantity: 57.3,
    vehicle: 'DEM666',
    id: 139368,
    tank_volume_start: 3256.8000000000056,
    tank_volume_stop: 3207.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:06:19',
    timestamp_stop: '2022-01-01T19:06:24',
    quantity: 55.4,
    vehicle: 'DEM000',
    id: 139370,
    tank_volume_start: 3207.3000000000056,
    tank_volume_stop: 3160.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:06:39',
    timestamp_stop: '2022-01-01T19:06:45',
    quantity: 58.6,
    vehicle: 'DEM333',
    id: 139372,
    tank_volume_start: 3160.4000000000055,
    tank_volume_stop: 3103.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:07:00',
    timestamp_stop: '2022-01-01T19:07:06',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 139374,
    tank_volume_start: 3103.6000000000054,
    tank_volume_stop: 3046.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:07:21',
    timestamp_stop: '2022-01-01T19:07:27',
    quantity: 55.4,
    vehicle: 'DEM444',
    id: 139376,
    tank_volume_start: 3046.800000000005,
    tank_volume_stop: 2992.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:07:42',
    timestamp_stop: '2022-01-01T19:07:47',
    quantity: 57.1,
    vehicle: 'DEM888',
    id: 139378,
    tank_volume_start: 2992.6000000000054,
    tank_volume_stop: 2942.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:08:02',
    timestamp_stop: '2022-01-01T19:08:08',
    quantity: 52.3,
    vehicle: 'DEM000',
    id: 139380,
    tank_volume_start: 2942.6000000000054,
    tank_volume_stop: 2887.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:08:23',
    timestamp_stop: '2022-01-01T19:08:28',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 139382,
    tank_volume_start: 2887.7000000000053,
    tank_volume_stop: 2837.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:08:43',
    timestamp_stop: '2022-01-01T19:08:49',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 139384,
    tank_volume_start: 2837.2000000000053,
    tank_volume_stop: 2783.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:09:04',
    timestamp_stop: '2022-01-01T19:09:09',
    quantity: 53.7,
    vehicle: 'DEM444',
    id: 139386,
    tank_volume_start: 2783.0000000000055,
    tank_volume_stop: 2728.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:09:24',
    timestamp_stop: '2022-01-01T19:09:29',
    quantity: 54.3,
    vehicle: 'DEM777',
    id: 139388,
    tank_volume_start: 2728.3000000000056,
    tank_volume_stop: 2674.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T19:09:44',
    timestamp_stop: '2022-01-01T19:09:49',
    quantity: 49.6,
    vehicle: 'DEM666',
    id: 139390,
    tank_volume_start: 2674.7000000000057,
    tank_volume_stop: 2620.500000000006,
  },
  {
    timestamp_start: '2022-01-01T19:10:04',
    timestamp_stop: '2022-01-01T19:10:11',
    quantity: 62.7,
    vehicle: 'DEM444',
    id: 139392,
    tank_volume_start: 2620.500000000006,
    tank_volume_stop: 2568.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T19:10:26',
    timestamp_stop: '2022-01-01T19:10:31',
    quantity: 52.7,
    vehicle: 'DEM333',
    id: 139394,
    tank_volume_start: 2568.7000000000057,
    tank_volume_stop: 2511.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T19:10:46',
    timestamp_stop: '2022-01-01T19:10:51',
    quantity: 49.1,
    vehicle: 'DEM444',
    id: 139396,
    tank_volume_start: 2511.2000000000057,
    tank_volume_stop: 2450.100000000006,
  },
  {
    timestamp_start: '2022-01-01T19:11:06',
    timestamp_stop: '2022-01-01T19:11:12',
    quantity: 63.2,
    vehicle: 'DEM777',
    id: 139398,
    tank_volume_start: 2450.100000000006,
    tank_volume_stop: 2396.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T19:11:27',
    timestamp_stop: '2022-01-01T19:11:33',
    quantity: 56.2,
    vehicle: 'DEM666',
    id: 139400,
    tank_volume_start: 2396.2000000000057,
    tank_volume_stop: 2347.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:11:48',
    timestamp_stop: '2022-01-01T19:11:53',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 139402,
    tank_volume_start: 2347.8000000000056,
    tank_volume_stop: 2290.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:12:08',
    timestamp_stop: '2022-01-01T19:12:13',
    quantity: 48.6,
    vehicle: 'DEM111',
    id: 139404,
    tank_volume_start: 2290.5000000000055,
    tank_volume_stop: 2241.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:12:28',
    timestamp_stop: '2022-01-01T19:12:35',
    quantity: 63.5,
    vehicle: 'DEM444',
    id: 139406,
    tank_volume_start: 2241.4000000000055,
    tank_volume_stop: 2182.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:12:50',
    timestamp_stop: '2022-01-01T19:12:55',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 139408,
    tank_volume_start: 2182.6000000000054,
    tank_volume_stop: 2134.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:13:10',
    timestamp_stop: '2022-01-01T19:13:15',
    quantity: 48.3,
    vehicle: 'DEM666',
    id: 139410,
    tank_volume_start: 2134.800000000005,
    tank_volume_stop: 2081.1000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:13:30',
    timestamp_stop: '2022-01-01T19:13:36',
    quantity: 63.9,
    vehicle: 'DEM111',
    id: 139412,
    tank_volume_start: 2081.1000000000054,
    tank_volume_stop: 2025.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:13:51',
    timestamp_stop: '2022-01-01T19:13:56',
    quantity: 52.2,
    vehicle: 'DEM555',
    id: 139414,
    tank_volume_start: 2025.0000000000055,
    tank_volume_stop: 1972.1000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:14:11',
    timestamp_stop: '2022-01-01T19:14:17',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 139416,
    tank_volume_start: 3972.1000000000054,
    tank_volume_stop: 3916.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:14:32',
    timestamp_stop: '2022-01-01T19:14:37',
    quantity: 53.9,
    vehicle: 'DEM555',
    id: 139418,
    tank_volume_start: 3916.6000000000054,
    tank_volume_stop: 3863.300000000005,
  },
  {
    timestamp_start: '2022-01-01T19:14:52',
    timestamp_stop: '2022-01-01T19:14:58',
    quantity: 55.0,
    vehicle: 'DEM000',
    id: 139420,
    tank_volume_start: 3863.300000000005,
    tank_volume_stop: 3804.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:15:13',
    timestamp_stop: '2022-01-01T19:15:19',
    quantity: 61.9,
    vehicle: 'DEM777',
    id: 139422,
    tank_volume_start: 3804.7000000000053,
    tank_volume_stop: 3753.300000000005,
  },
  {
    timestamp_start: '2022-01-01T19:15:34',
    timestamp_stop: '2022-01-01T19:15:40',
    quantity: 62.6,
    vehicle: 'DEM444',
    id: 139424,
    tank_volume_start: 3753.300000000005,
    tank_volume_stop: 3699.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:15:55',
    timestamp_stop: '2022-01-01T19:16:01',
    quantity: 59.9,
    vehicle: 'DEM000',
    id: 139426,
    tank_volume_start: 3699.500000000005,
    tank_volume_stop: 3641.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:16:16',
    timestamp_stop: '2022-01-01T19:16:21',
    quantity: 48.8,
    vehicle: 'DEM888',
    id: 139429,
    tank_volume_start: 3641.500000000005,
    tank_volume_stop: 3588.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:16:36',
    timestamp_stop: '2022-01-01T19:16:41',
    quantity: 47.1,
    vehicle: 'DEM222',
    id: 139430,
    tank_volume_start: 3588.600000000005,
    tank_volume_stop: 3541.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:16:56',
    timestamp_stop: '2022-01-01T19:17:01',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 139432,
    tank_volume_start: 3541.8000000000047,
    tank_volume_stop: 3489.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:17:16',
    timestamp_stop: '2022-01-01T19:17:21',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 139434,
    tank_volume_start: 3489.700000000005,
    tank_volume_stop: 3437.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:17:36',
    timestamp_stop: '2022-01-01T19:17:41',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 139436,
    tank_volume_start: 3437.500000000005,
    tank_volume_stop: 3382.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:17:56',
    timestamp_stop: '2022-01-01T19:18:01',
    quantity: 51.0,
    vehicle: 'DEM111',
    id: 139438,
    tank_volume_start: 3382.400000000005,
    tank_volume_stop: 3328.900000000005,
  },
  {
    timestamp_start: '2022-01-01T19:18:16',
    timestamp_stop: '2022-01-01T19:18:22',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 139440,
    tank_volume_start: 3328.900000000005,
    tank_volume_stop: 3266.000000000005,
  },
  {
    timestamp_start: '2022-01-01T19:18:37',
    timestamp_stop: '2022-01-01T19:18:42',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 139442,
    tank_volume_start: 3266.000000000005,
    tank_volume_stop: 3213.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:18:57',
    timestamp_stop: '2022-01-01T19:19:02',
    quantity: 49.5,
    vehicle: 'DEM777',
    id: 139444,
    tank_volume_start: 3213.500000000005,
    tank_volume_stop: 3167.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:19:17',
    timestamp_stop: '2022-01-01T19:19:22',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 139446,
    tank_volume_start: 3167.100000000005,
    tank_volume_stop: 3114.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:19:37',
    timestamp_stop: '2022-01-01T19:19:43',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 139448,
    tank_volume_start: 3114.500000000005,
    tank_volume_stop: 3063.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:19:58',
    timestamp_stop: '2022-01-01T19:20:03',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 139450,
    tank_volume_start: 3063.500000000005,
    tank_volume_stop: 3018.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:20:18',
    timestamp_stop: '2022-01-01T19:20:24',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 139452,
    tank_volume_start: 3018.200000000005,
    tank_volume_stop: 2967.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:20:39',
    timestamp_stop: '2022-01-01T19:20:45',
    quantity: 61.8,
    vehicle: 'DEM333',
    id: 139454,
    tank_volume_start: 2967.8000000000047,
    tank_volume_stop: 2904.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:21:00',
    timestamp_stop: '2022-01-01T19:21:06',
    quantity: 54.6,
    vehicle: 'DEM111',
    id: 139456,
    tank_volume_start: 2904.600000000005,
    tank_volume_stop: 2845.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:21:21',
    timestamp_stop: '2022-01-01T19:21:27',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 139458,
    tank_volume_start: 2845.500000000005,
    tank_volume_stop: 2782.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:21:42',
    timestamp_stop: '2022-01-01T19:21:48',
    quantity: 60.5,
    vehicle: 'DEM222',
    id: 139460,
    tank_volume_start: 2782.100000000005,
    tank_volume_stop: 2726.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:22:03',
    timestamp_stop: '2022-01-01T19:22:08',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 139462,
    tank_volume_start: 2726.100000000005,
    tank_volume_stop: 2664.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:22:23',
    timestamp_stop: '2022-01-01T19:22:29',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 139464,
    tank_volume_start: 2664.100000000005,
    tank_volume_stop: 2608.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:22:44',
    timestamp_stop: '2022-01-01T19:22:50',
    quantity: 63.4,
    vehicle: 'DEM444',
    id: 139466,
    tank_volume_start: 2608.200000000005,
    tank_volume_stop: 2546.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:23:05',
    timestamp_stop: '2022-01-01T19:23:11',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 139468,
    tank_volume_start: 2546.3000000000047,
    tank_volume_stop: 2492.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:23:26',
    timestamp_stop: '2022-01-01T19:23:31',
    quantity: 55.0,
    vehicle: 'DEM777',
    id: 139470,
    tank_volume_start: 2492.700000000005,
    tank_volume_stop: 2434.000000000005,
  },
  {
    timestamp_start: '2022-01-01T19:23:46',
    timestamp_stop: '2022-01-01T19:23:52',
    quantity: 59.1,
    vehicle: 'DEM777',
    id: 139472,
    tank_volume_start: 2434.000000000005,
    tank_volume_stop: 2380.900000000005,
  },
  {
    timestamp_start: '2022-01-01T19:24:07',
    timestamp_stop: '2022-01-01T19:24:12',
    quantity: 51.1,
    vehicle: 'DEM222',
    id: 139474,
    tank_volume_start: 2380.900000000005,
    tank_volume_stop: 2325.000000000005,
  },
  {
    timestamp_start: '2022-01-01T19:24:27',
    timestamp_stop: '2022-01-01T19:24:33',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 139476,
    tank_volume_start: 2325.000000000005,
    tank_volume_stop: 2274.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:24:48',
    timestamp_stop: '2022-01-01T19:24:54',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 139478,
    tank_volume_start: 2274.800000000005,
    tank_volume_stop: 2227.300000000005,
  },
  {
    timestamp_start: '2022-01-01T19:25:09',
    timestamp_stop: '2022-01-01T19:25:14',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 139481,
    tank_volume_start: 2227.300000000005,
    tank_volume_stop: 2169.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:25:29',
    timestamp_stop: '2022-01-01T19:25:35',
    quantity: 58.1,
    vehicle: 'DEM444',
    id: 139483,
    tank_volume_start: 2169.800000000005,
    tank_volume_stop: 2112.900000000005,
  },
  {
    timestamp_start: '2022-01-01T19:25:50',
    timestamp_stop: '2022-01-01T19:25:55',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 139485,
    tank_volume_start: 2112.900000000005,
    tank_volume_stop: 2055.000000000005,
  },
  {
    timestamp_start: '2022-01-01T19:26:10',
    timestamp_stop: '2022-01-01T19:26:16',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 139486,
    tank_volume_start: 2055.000000000005,
    tank_volume_stop: 2008.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:26:31',
    timestamp_stop: '2022-01-01T19:26:37',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 139488,
    tank_volume_start: 2008.400000000005,
    tank_volume_stop: 1951.8000000000052,
  },
  {
    timestamp_start: '2022-01-01T19:26:52',
    timestamp_stop: '2022-01-01T19:26:57',
    quantity: 51.4,
    vehicle: 'DEM444',
    id: 139490,
    tank_volume_start: 3951.800000000005,
    tank_volume_stop: 3893.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:27:12',
    timestamp_stop: '2022-01-01T19:27:18',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 139492,
    tank_volume_start: 3893.7000000000053,
    tank_volume_stop: 3833.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:27:33',
    timestamp_stop: '2022-01-01T19:27:39',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 139494,
    tank_volume_start: 3833.2000000000053,
    tank_volume_stop: 3776.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:27:54',
    timestamp_stop: '2022-01-01T19:27:59',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 139497,
    tank_volume_start: 3776.6000000000054,
    tank_volume_stop: 3715.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:28:14',
    timestamp_stop: '2022-01-01T19:28:19',
    quantity: 55.2,
    vehicle: 'DEM888',
    id: 139498,
    tank_volume_start: 3715.6000000000054,
    tank_volume_stop: 3667.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:28:34',
    timestamp_stop: '2022-01-01T19:28:40',
    quantity: 59.4,
    vehicle: 'DEM444',
    id: 139500,
    tank_volume_start: 3667.800000000005,
    tank_volume_stop: 3614.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:28:55',
    timestamp_stop: '2022-01-01T19:29:00',
    quantity: 48.9,
    vehicle: 'DEM777',
    id: 139502,
    tank_volume_start: 3614.800000000005,
    tank_volume_stop: 3562.1000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:29:15',
    timestamp_stop: '2022-01-01T19:29:21',
    quantity: 60.4,
    vehicle: 'DEM111',
    id: 139504,
    tank_volume_start: 3562.1000000000054,
    tank_volume_stop: 3501.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:29:36',
    timestamp_stop: '2022-01-01T19:29:41',
    quantity: 53.2,
    vehicle: 'DEM333',
    id: 139507,
    tank_volume_start: 3501.0000000000055,
    tank_volume_stop: 3449.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:29:56',
    timestamp_stop: '2022-01-01T19:30:02',
    quantity: 58.1,
    vehicle: 'DEM000',
    id: 139509,
    tank_volume_start: 3449.3000000000056,
    tank_volume_stop: 3392.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:30:17',
    timestamp_stop: '2022-01-01T19:30:23',
    quantity: 56.9,
    vehicle: 'DEM333',
    id: 139511,
    tank_volume_start: 3392.3000000000056,
    tank_volume_stop: 3341.8000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:30:38',
    timestamp_stop: '2022-01-01T19:30:43',
    quantity: 49.9,
    vehicle: 'DEM888',
    id: 139513,
    tank_volume_start: 3341.8000000000056,
    tank_volume_stop: 3292.3000000000056,
  },
  {
    timestamp_start: '2022-01-01T19:30:58',
    timestamp_stop: '2022-01-01T19:31:03',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 139515,
    tank_volume_start: 3292.3000000000056,
    tank_volume_stop: 3241.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:31:18',
    timestamp_stop: '2022-01-01T19:31:23',
    quantity: 50.3,
    vehicle: 'DEM333',
    id: 139517,
    tank_volume_start: 3241.9000000000055,
    tank_volume_stop: 3190.0000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:31:38',
    timestamp_stop: '2022-01-01T19:31:44',
    quantity: 57.3,
    vehicle: 'DEM777',
    id: 139519,
    tank_volume_start: 3190.0000000000055,
    tank_volume_stop: 3130.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:31:59',
    timestamp_stop: '2022-01-01T19:32:04',
    quantity: 51.7,
    vehicle: 'DEM666',
    id: 139521,
    tank_volume_start: 3130.4000000000055,
    tank_volume_stop: 3078.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T19:32:19',
    timestamp_stop: '2022-01-01T19:32:25',
    quantity: 58.4,
    vehicle: 'DEM666',
    id: 139522,
    tank_volume_start: 3078.7000000000057,
    tank_volume_stop: 3025.9000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:32:40',
    timestamp_stop: '2022-01-01T19:32:45',
    quantity: 54.7,
    vehicle: 'DEM000',
    id: 139524,
    tank_volume_start: 3025.9000000000055,
    tank_volume_stop: 2970.6000000000054,
  },
  {
    timestamp_start: '2022-01-01T19:33:00',
    timestamp_stop: '2022-01-01T19:33:05',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 139527,
    tank_volume_start: 2970.6000000000054,
    tank_volume_stop: 2912.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T19:33:20',
    timestamp_stop: '2022-01-01T19:33:25',
    quantity: 48.4,
    vehicle: 'DEM000',
    id: 139528,
    tank_volume_start: 2912.5000000000055,
    tank_volume_stop: 2855.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:33:40',
    timestamp_stop: '2022-01-01T19:33:45',
    quantity: 52.3,
    vehicle: 'DEM555',
    id: 139530,
    tank_volume_start: 2855.2000000000053,
    tank_volume_stop: 2795.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:34:00',
    timestamp_stop: '2022-01-01T19:34:05',
    quantity: 47.7,
    vehicle: 'DEM222',
    id: 139532,
    tank_volume_start: 2795.800000000005,
    tank_volume_stop: 2744.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:34:20',
    timestamp_stop: '2022-01-01T19:34:26',
    quantity: 57.4,
    vehicle: 'DEM000',
    id: 139534,
    tank_volume_start: 2744.800000000005,
    tank_volume_stop: 2686.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:34:41',
    timestamp_stop: '2022-01-01T19:34:47',
    quantity: 59.1,
    vehicle: 'DEM333',
    id: 139536,
    tank_volume_start: 2686.500000000005,
    tank_volume_stop: 2637.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:35:02',
    timestamp_stop: '2022-01-01T19:35:07',
    quantity: 49.5,
    vehicle: 'DEM111',
    id: 139538,
    tank_volume_start: 2637.700000000005,
    tank_volume_stop: 2578.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:35:22',
    timestamp_stop: '2022-01-01T19:35:26',
    quantity: 45.6,
    vehicle: 'DEM555',
    id: 139540,
    tank_volume_start: 2578.200000000005,
    tank_volume_stop: 2523.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:35:41',
    timestamp_stop: '2022-01-01T19:35:47',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 139542,
    tank_volume_start: 2523.500000000005,
    tank_volume_stop: 2470.900000000005,
  },
  {
    timestamp_start: '2022-01-01T19:36:02',
    timestamp_stop: '2022-01-01T19:36:08',
    quantity: 60.7,
    vehicle: 'DEM666',
    id: 139544,
    tank_volume_start: 2470.900000000005,
    tank_volume_stop: 2413.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:36:23',
    timestamp_stop: '2022-01-01T19:36:29',
    quantity: 57.8,
    vehicle: 'DEM888',
    id: 139546,
    tank_volume_start: 2413.400000000005,
    tank_volume_stop: 2358.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:36:44',
    timestamp_stop: '2022-01-01T19:36:49',
    quantity: 52.0,
    vehicle: 'DEM444',
    id: 139548,
    tank_volume_start: 2358.600000000005,
    tank_volume_stop: 2295.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:37:04',
    timestamp_stop: '2022-01-01T19:37:09',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 139550,
    tank_volume_start: 2295.3000000000047,
    tank_volume_stop: 2245.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:37:24',
    timestamp_stop: '2022-01-01T19:37:31',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 139552,
    tank_volume_start: 2245.8000000000047,
    tank_volume_stop: 2184.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:37:46',
    timestamp_stop: '2022-01-01T19:37:51',
    quantity: 55.5,
    vehicle: 'DEM111',
    id: 139554,
    tank_volume_start: 2184.9000000000046,
    tank_volume_stop: 2129.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:38:06',
    timestamp_stop: '2022-01-01T19:38:12',
    quantity: 55.9,
    vehicle: 'DEM555',
    id: 139556,
    tank_volume_start: 2129.200000000005,
    tank_volume_stop: 2077.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:38:27',
    timestamp_stop: '2022-01-01T19:38:32',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 139558,
    tank_volume_start: 2077.500000000005,
    tank_volume_stop: 2025.000000000005,
  },
  {
    timestamp_start: '2022-01-01T19:38:47',
    timestamp_stop: '2022-01-01T19:38:52',
    quantity: 46.8,
    vehicle: 'DEM444',
    id: 139560,
    tank_volume_start: 2025.000000000005,
    tank_volume_stop: 1974.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:39:07',
    timestamp_stop: '2022-01-01T19:39:14',
    quantity: 64.0,
    vehicle: 'DEM888',
    id: 139562,
    tank_volume_start: 3974.7000000000053,
    tank_volume_stop: 3912.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T19:39:29',
    timestamp_stop: '2022-01-01T19:39:35',
    quantity: 63.1,
    vehicle: 'DEM444',
    id: 139564,
    tank_volume_start: 3912.2000000000053,
    tank_volume_stop: 3852.300000000005,
  },
  {
    timestamp_start: '2022-01-01T19:39:50',
    timestamp_stop: '2022-01-01T19:39:55',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 139567,
    tank_volume_start: 3852.300000000005,
    tank_volume_stop: 3799.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:40:10',
    timestamp_stop: '2022-01-01T19:40:15',
    quantity: 46.1,
    vehicle: 'DEM777',
    id: 139569,
    tank_volume_start: 3799.500000000005,
    tank_volume_stop: 3748.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:40:30',
    timestamp_stop: '2022-01-01T19:40:35',
    quantity: 52.4,
    vehicle: 'DEM000',
    id: 139570,
    tank_volume_start: 3748.700000000005,
    tank_volume_stop: 3696.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:40:50',
    timestamp_stop: '2022-01-01T19:40:56',
    quantity: 62.4,
    vehicle: 'DEM000',
    id: 139572,
    tank_volume_start: 3696.200000000005,
    tank_volume_stop: 3648.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:41:11',
    timestamp_stop: '2022-01-01T19:41:18',
    quantity: 61.8,
    vehicle: 'DEM444',
    id: 139575,
    tank_volume_start: 3648.500000000005,
    tank_volume_stop: 3594.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:41:33',
    timestamp_stop: '2022-01-01T19:41:39',
    quantity: 61.0,
    vehicle: 'DEM888',
    id: 139577,
    tank_volume_start: 3594.600000000005,
    tank_volume_stop: 3546.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:41:54',
    timestamp_stop: '2022-01-01T19:41:59',
    quantity: 56.4,
    vehicle: 'DEM222',
    id: 139579,
    tank_volume_start: 3546.100000000005,
    tank_volume_stop: 3490.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:42:14',
    timestamp_stop: '2022-01-01T19:42:20',
    quantity: 52.5,
    vehicle: 'DEM444',
    id: 139581,
    tank_volume_start: 3490.400000000005,
    tank_volume_stop: 3439.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:42:35',
    timestamp_stop: '2022-01-01T19:42:39',
    quantity: 45.8,
    vehicle: 'DEM666',
    id: 139583,
    tank_volume_start: 3439.500000000005,
    tank_volume_stop: 3391.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:42:54',
    timestamp_stop: '2022-01-01T19:43:00',
    quantity: 56.9,
    vehicle: 'DEM666',
    id: 139585,
    tank_volume_start: 3391.400000000005,
    tank_volume_stop: 3336.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:43:15',
    timestamp_stop: '2022-01-01T19:43:21',
    quantity: 61.1,
    vehicle: 'DEM666',
    id: 139587,
    tank_volume_start: 3336.100000000005,
    tank_volume_stop: 3282.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:43:36',
    timestamp_stop: '2022-01-01T19:43:42',
    quantity: 57.7,
    vehicle: 'DEM222',
    id: 139589,
    tank_volume_start: 3282.8000000000047,
    tank_volume_stop: 3223.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:43:57',
    timestamp_stop: '2022-01-01T19:44:02',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 139591,
    tank_volume_start: 3223.600000000005,
    tank_volume_stop: 3174.600000000005,
  },
  {
    timestamp_start: '2022-01-01T19:44:17',
    timestamp_stop: '2022-01-01T19:44:23',
    quantity: 60.0,
    vehicle: 'DEM666',
    id: 139593,
    tank_volume_start: 3174.600000000005,
    tank_volume_stop: 3117.400000000005,
  },
  {
    timestamp_start: '2022-01-01T19:44:38',
    timestamp_stop: '2022-01-01T19:44:43',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 139595,
    tank_volume_start: 3117.400000000005,
    tank_volume_stop: 3058.800000000005,
  },
  {
    timestamp_start: '2022-01-01T19:44:58',
    timestamp_stop: '2022-01-01T19:45:03',
    quantity: 45.6,
    vehicle: 'DEM444',
    id: 139597,
    tank_volume_start: 3058.800000000005,
    tank_volume_stop: 2999.900000000005,
  },
  {
    timestamp_start: '2022-01-01T19:45:18',
    timestamp_stop: '2022-01-01T19:45:24',
    quantity: 55.8,
    vehicle: 'DEM000',
    id: 139599,
    tank_volume_start: 2999.900000000005,
    tank_volume_stop: 2950.300000000005,
  },
  {
    timestamp_start: '2022-01-01T19:45:39',
    timestamp_stop: '2022-01-01T19:45:44',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 139601,
    tank_volume_start: 2950.300000000005,
    tank_volume_stop: 2899.500000000005,
  },
  {
    timestamp_start: '2022-01-01T19:45:59',
    timestamp_stop: '2022-01-01T19:46:05',
    quantity: 59.4,
    vehicle: 'DEM000',
    id: 139603,
    tank_volume_start: 2899.500000000005,
    tank_volume_stop: 2844.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:46:20',
    timestamp_stop: '2022-01-01T19:46:26',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 139605,
    tank_volume_start: 2844.700000000005,
    tank_volume_stop: 2788.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:46:41',
    timestamp_stop: '2022-01-01T19:46:46',
    quantity: 48.1,
    vehicle: 'DEM444',
    id: 139607,
    tank_volume_start: 2788.4000000000046,
    tank_volume_stop: 2739.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:47:01',
    timestamp_stop: '2022-01-01T19:47:07',
    quantity: 55.7,
    vehicle: 'DEM333',
    id: 139609,
    tank_volume_start: 2739.9000000000046,
    tank_volume_stop: 2686.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:47:22',
    timestamp_stop: '2022-01-01T19:47:27',
    quantity: 50.3,
    vehicle: 'DEM000',
    id: 139611,
    tank_volume_start: 2686.6000000000045,
    tank_volume_stop: 2623.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T19:47:42',
    timestamp_stop: '2022-01-01T19:47:47',
    quantity: 58.5,
    vehicle: 'DEM777',
    id: 139613,
    tank_volume_start: 2623.8000000000043,
    tank_volume_stop: 2567.000000000004,
  },
  {
    timestamp_start: '2022-01-01T19:48:02',
    timestamp_stop: '2022-01-01T19:48:08',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 139615,
    tank_volume_start: 2567.000000000004,
    tank_volume_stop: 2513.400000000004,
  },
  {
    timestamp_start: '2022-01-01T19:48:23',
    timestamp_stop: '2022-01-01T19:48:28',
    quantity: 51.7,
    vehicle: 'DEM666',
    id: 139617,
    tank_volume_start: 2513.400000000004,
    tank_volume_stop: 2464.900000000004,
  },
  {
    timestamp_start: '2022-01-01T19:48:43',
    timestamp_stop: '2022-01-01T19:48:49',
    quantity: 56.2,
    vehicle: 'DEM333',
    id: 139619,
    tank_volume_start: 2464.900000000004,
    tank_volume_stop: 2413.900000000004,
  },
  {
    timestamp_start: '2022-01-01T19:49:04',
    timestamp_stop: '2022-01-01T19:49:09',
    quantity: 57.7,
    vehicle: 'DEM000',
    id: 139621,
    tank_volume_start: 2413.900000000004,
    tank_volume_stop: 2358.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T19:49:24',
    timestamp_stop: '2022-01-01T19:49:31',
    quantity: 62.0,
    vehicle: 'DEM000',
    id: 139623,
    tank_volume_start: 2358.8000000000043,
    tank_volume_stop: 2298.400000000004,
  },
  {
    timestamp_start: '2022-01-01T19:49:46',
    timestamp_stop: '2022-01-01T19:49:51',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 139625,
    tank_volume_start: 2298.400000000004,
    tank_volume_stop: 2236.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T19:50:06',
    timestamp_stop: '2022-01-01T19:50:11',
    quantity: 47.4,
    vehicle: 'DEM000',
    id: 139627,
    tank_volume_start: 2236.2000000000044,
    tank_volume_stop: 2180.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T19:50:26',
    timestamp_stop: '2022-01-01T19:50:31',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 139629,
    tank_volume_start: 2180.7000000000044,
    tank_volume_stop: 2119.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:50:46',
    timestamp_stop: '2022-01-01T19:50:51',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 139631,
    tank_volume_start: 2119.5000000000045,
    tank_volume_stop: 2069.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:51:06',
    timestamp_stop: '2022-01-01T19:51:12',
    quantity: 58.2,
    vehicle: 'DEM333',
    id: 139633,
    tank_volume_start: 2069.1000000000045,
    tank_volume_stop: 2019.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T19:51:27',
    timestamp_stop: '2022-01-01T19:51:33',
    quantity: 57.3,
    vehicle: 'DEM333',
    id: 139635,
    tank_volume_start: 2019.2000000000044,
    tank_volume_stop: 1956.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T19:51:48',
    timestamp_stop: '2022-01-01T19:51:54',
    quantity: 60.1,
    vehicle: 'DEM888',
    id: 139637,
    tank_volume_start: 3956.2000000000044,
    tank_volume_stop: 3900.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T19:52:09',
    timestamp_stop: '2022-01-01T19:52:14',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 139639,
    tank_volume_start: 3900.2000000000044,
    tank_volume_stop: 3840.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:52:29',
    timestamp_stop: '2022-01-01T19:52:34',
    quantity: 48.4,
    vehicle: 'DEM555',
    id: 139641,
    tank_volume_start: 3840.1000000000045,
    tank_volume_stop: 3786.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:52:49',
    timestamp_stop: '2022-01-01T19:52:54',
    quantity: 47.3,
    vehicle: 'DEM444',
    id: 139643,
    tank_volume_start: 3786.4000000000046,
    tank_volume_stop: 3732.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:53:09',
    timestamp_stop: '2022-01-01T19:53:14',
    quantity: 55.3,
    vehicle: 'DEM666',
    id: 139645,
    tank_volume_start: 3732.200000000005,
    tank_volume_stop: 3675.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:53:29',
    timestamp_stop: '2022-01-01T19:53:35',
    quantity: 61.3,
    vehicle: 'DEM555',
    id: 139647,
    tank_volume_start: 3675.200000000005,
    tank_volume_stop: 3625.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:53:50',
    timestamp_stop: '2022-01-01T19:53:56',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 139649,
    tank_volume_start: 3625.9000000000046,
    tank_volume_stop: 3570.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:54:11',
    timestamp_stop: '2022-01-01T19:54:16',
    quantity: 47.9,
    vehicle: 'DEM555',
    id: 139651,
    tank_volume_start: 3570.3000000000047,
    tank_volume_stop: 3517.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:54:31',
    timestamp_stop: '2022-01-01T19:54:36',
    quantity: 45.5,
    vehicle: 'DEM222',
    id: 139653,
    tank_volume_start: 3517.0000000000045,
    tank_volume_stop: 3465.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T19:54:51',
    timestamp_stop: '2022-01-01T19:54:56',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 139655,
    tank_volume_start: 3465.6000000000045,
    tank_volume_stop: 3407.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:55:11',
    timestamp_stop: '2022-01-01T19:55:17',
    quantity: 54.0,
    vehicle: 'DEM333',
    id: 139657,
    tank_volume_start: 3407.4000000000046,
    tank_volume_stop: 3358.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:55:32',
    timestamp_stop: '2022-01-01T19:55:38',
    quantity: 64.6,
    vehicle: 'DEM222',
    id: 139659,
    tank_volume_start: 3358.700000000005,
    tank_volume_stop: 3300.100000000005,
  },
  {
    timestamp_start: '2022-01-01T19:55:53',
    timestamp_stop: '2022-01-01T19:55:58',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 139661,
    tank_volume_start: 3300.100000000005,
    tank_volume_stop: 3246.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:56:13',
    timestamp_stop: '2022-01-01T19:56:19',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 139663,
    tank_volume_start: 3246.8000000000047,
    tank_volume_stop: 3193.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:56:34',
    timestamp_stop: '2022-01-01T19:56:41',
    quantity: 62.9,
    vehicle: 'DEM000',
    id: 139665,
    tank_volume_start: 3193.8000000000047,
    tank_volume_stop: 3145.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:56:56',
    timestamp_stop: '2022-01-01T19:57:01',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 139667,
    tank_volume_start: 3145.4000000000046,
    tank_volume_stop: 3086.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:57:16',
    timestamp_stop: '2022-01-01T19:57:21',
    quantity: 51.3,
    vehicle: 'DEM777',
    id: 139669,
    tank_volume_start: 3086.4000000000046,
    tank_volume_stop: 3023.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:57:36',
    timestamp_stop: '2022-01-01T19:57:42',
    quantity: 59.8,
    vehicle: 'DEM222',
    id: 139671,
    tank_volume_start: 3023.3000000000047,
    tank_volume_stop: 2961.700000000005,
  },
  {
    timestamp_start: '2022-01-01T19:57:57',
    timestamp_stop: '2022-01-01T19:58:03',
    quantity: 61.3,
    vehicle: 'DEM666',
    id: 139673,
    tank_volume_start: 2961.700000000005,
    tank_volume_stop: 2903.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:58:18',
    timestamp_stop: '2022-01-01T19:58:24',
    quantity: 57.5,
    vehicle: 'DEM666',
    id: 139675,
    tank_volume_start: 2903.8000000000047,
    tank_volume_stop: 2847.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:58:39',
    timestamp_stop: '2022-01-01T19:58:45',
    quantity: 61.1,
    vehicle: 'DEM444',
    id: 139677,
    tank_volume_start: 2847.4000000000046,
    tank_volume_stop: 2798.200000000005,
  },
  {
    timestamp_start: '2022-01-01T19:59:00',
    timestamp_stop: '2022-01-01T19:59:05',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 139679,
    tank_volume_start: 2798.200000000005,
    tank_volume_stop: 2743.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T19:59:20',
    timestamp_stop: '2022-01-01T19:59:26',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 139681,
    tank_volume_start: 2743.8000000000047,
    tank_volume_stop: 2682.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T19:59:41',
    timestamp_stop: '2022-01-01T19:59:46',
    quantity: 50.9,
    vehicle: 'DEM111',
    id: 139683,
    tank_volume_start: 2682.9000000000046,
    tank_volume_stop: 2625.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:00:01',
    timestamp_stop: '2022-01-01T20:00:07',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 139685,
    tank_volume_start: 2625.6000000000045,
    tank_volume_stop: 2573.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:00:22',
    timestamp_stop: '2022-01-01T20:00:28',
    quantity: 62.4,
    vehicle: 'DEM222',
    id: 139687,
    tank_volume_start: 2573.1000000000045,
    tank_volume_stop: 2521.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:00:43',
    timestamp_stop: '2022-01-01T20:00:48',
    quantity: 53.6,
    vehicle: 'DEM444',
    id: 139689,
    tank_volume_start: 2521.8000000000043,
    tank_volume_stop: 2471.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:01:03',
    timestamp_stop: '2022-01-01T20:01:08',
    quantity: 51.6,
    vehicle: 'DEM777',
    id: 139691,
    tank_volume_start: 2471.3000000000043,
    tank_volume_stop: 2410.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:01:23',
    timestamp_stop: '2022-01-01T20:01:28',
    quantity: 49.7,
    vehicle: 'DEM888',
    id: 139693,
    tank_volume_start: 2410.7000000000044,
    tank_volume_stop: 2357.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:01:43',
    timestamp_stop: '2022-01-01T20:01:49',
    quantity: 57.6,
    vehicle: 'DEM444',
    id: 139695,
    tank_volume_start: 2357.7000000000044,
    tank_volume_stop: 2303.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:02:04',
    timestamp_stop: '2022-01-01T20:02:09',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 139697,
    tank_volume_start: 2303.8000000000043,
    tank_volume_stop: 2249.400000000004,
  },
  {
    timestamp_start: '2022-01-01T20:02:24',
    timestamp_stop: '2022-01-01T20:02:30',
    quantity: 58.3,
    vehicle: 'DEM222',
    id: 139699,
    tank_volume_start: 2249.400000000004,
    tank_volume_stop: 2200.400000000004,
  },
  {
    timestamp_start: '2022-01-01T20:02:45',
    timestamp_stop: '2022-01-01T20:02:51',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 139701,
    tank_volume_start: 2200.400000000004,
    tank_volume_stop: 2148.600000000004,
  },
  {
    timestamp_start: '2022-01-01T20:03:06',
    timestamp_stop: '2022-01-01T20:03:12',
    quantity: 58.3,
    vehicle: 'DEM888',
    id: 139703,
    tank_volume_start: 2148.600000000004,
    tank_volume_stop: 2096.400000000004,
  },
  {
    timestamp_start: '2022-01-01T20:03:27',
    timestamp_stop: '2022-01-01T20:03:32',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 139705,
    tank_volume_start: 2096.400000000004,
    tank_volume_stop: 2040.6000000000042,
  },
  {
    timestamp_start: '2022-01-01T20:03:47',
    timestamp_stop: '2022-01-01T20:03:53',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 139707,
    tank_volume_start: 2040.6000000000042,
    tank_volume_stop: 1987.5000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:04:08',
    timestamp_stop: '2022-01-01T20:04:13',
    quantity: 54.2,
    vehicle: 'DEM000',
    id: 139709,
    tank_volume_start: 3987.5000000000045,
    tank_volume_stop: 3934.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:04:28',
    timestamp_stop: '2022-01-01T20:04:34',
    quantity: 62.3,
    vehicle: 'DEM666',
    id: 139711,
    tank_volume_start: 3934.1000000000045,
    tank_volume_stop: 3874.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:04:49',
    timestamp_stop: '2022-01-01T20:04:55',
    quantity: 54.9,
    vehicle: 'DEM222',
    id: 139713,
    tank_volume_start: 3874.1000000000045,
    tank_volume_stop: 3820.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T20:05:10',
    timestamp_stop: '2022-01-01T20:05:15',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 139715,
    tank_volume_start: 3820.9000000000046,
    tank_volume_stop: 3769.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:05:30',
    timestamp_stop: '2022-01-01T20:05:36',
    quantity: 54.0,
    vehicle: 'DEM222',
    id: 139717,
    tank_volume_start: 3769.6000000000045,
    tank_volume_stop: 3710.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:05:51',
    timestamp_stop: '2022-01-01T20:05:57',
    quantity: 57.5,
    vehicle: 'DEM555',
    id: 139719,
    tank_volume_start: 3710.1000000000045,
    tank_volume_stop: 3656.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:06:12',
    timestamp_stop: '2022-01-01T20:06:17',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 139721,
    tank_volume_start: 3656.2000000000044,
    tank_volume_stop: 3600.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:06:32',
    timestamp_stop: '2022-01-01T20:06:36',
    quantity: 45.7,
    vehicle: 'DEM777',
    id: 139723,
    tank_volume_start: 3600.7000000000044,
    tank_volume_stop: 3549.900000000004,
  },
  {
    timestamp_start: '2022-01-01T20:06:51',
    timestamp_stop: '2022-01-01T20:06:57',
    quantity: 53.9,
    vehicle: 'DEM888',
    id: 139725,
    tank_volume_start: 3549.900000000004,
    tank_volume_stop: 3490.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:07:12',
    timestamp_stop: '2022-01-01T20:07:17',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 139727,
    tank_volume_start: 3490.7000000000044,
    tank_volume_stop: 3435.900000000004,
  },
  {
    timestamp_start: '2022-01-01T20:07:32',
    timestamp_stop: '2022-01-01T20:07:37',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 139729,
    tank_volume_start: 3435.900000000004,
    tank_volume_stop: 3388.500000000004,
  },
  {
    timestamp_start: '2022-01-01T20:07:52',
    timestamp_stop: '2022-01-01T20:07:57',
    quantity: 47.8,
    vehicle: 'DEM555',
    id: 139731,
    tank_volume_start: 3388.500000000004,
    tank_volume_stop: 3332.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:08:12',
    timestamp_stop: '2022-01-01T20:08:19',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 139733,
    tank_volume_start: 3332.200000000004,
    tank_volume_stop: 3273.700000000004,
  },
  {
    timestamp_start: '2022-01-01T20:08:34',
    timestamp_stop: '2022-01-01T20:08:39',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 139735,
    tank_volume_start: 3273.700000000004,
    tank_volume_stop: 3226.000000000004,
  },
  {
    timestamp_start: '2022-01-01T20:08:54',
    timestamp_stop: '2022-01-01T20:09:00',
    quantity: 64.7,
    vehicle: 'DEM666',
    id: 139737,
    tank_volume_start: 3226.000000000004,
    tank_volume_stop: 3163.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:09:15',
    timestamp_stop: '2022-01-01T20:09:20',
    quantity: 49.1,
    vehicle: 'DEM666',
    id: 139739,
    tank_volume_start: 3163.200000000004,
    tank_volume_stop: 3109.500000000004,
  },
  {
    timestamp_start: '2022-01-01T20:09:35',
    timestamp_stop: '2022-01-01T20:09:41',
    quantity: 53.5,
    vehicle: 'DEM444',
    id: 139741,
    tank_volume_start: 3109.500000000004,
    tank_volume_stop: 3055.900000000004,
  },
  {
    timestamp_start: '2022-01-01T20:09:56',
    timestamp_stop: '2022-01-01T20:10:01',
    quantity: 59.4,
    vehicle: 'DEM888',
    id: 139743,
    tank_volume_start: 3055.900000000004,
    tank_volume_stop: 3001.500000000004,
  },
  {
    timestamp_start: '2022-01-01T20:10:16',
    timestamp_stop: '2022-01-01T20:10:22',
    quantity: 51.7,
    vehicle: 'DEM000',
    id: 139745,
    tank_volume_start: 3001.500000000004,
    tank_volume_stop: 2950.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:10:37',
    timestamp_stop: '2022-01-01T20:10:43',
    quantity: 64.3,
    vehicle: 'DEM222',
    id: 139747,
    tank_volume_start: 2950.200000000004,
    tank_volume_stop: 2893.700000000004,
  },
  {
    timestamp_start: '2022-01-01T20:10:58',
    timestamp_stop: '2022-01-01T20:11:03',
    quantity: 52.0,
    vehicle: 'DEM888',
    id: 139749,
    tank_volume_start: 2893.700000000004,
    tank_volume_stop: 2845.500000000004,
  },
  {
    timestamp_start: '2022-01-01T20:11:18',
    timestamp_stop: '2022-01-01T20:11:24',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 139751,
    tank_volume_start: 2845.500000000004,
    tank_volume_stop: 2787.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:11:39',
    timestamp_stop: '2022-01-01T20:11:45',
    quantity: 60.5,
    vehicle: 'DEM111',
    id: 139753,
    tank_volume_start: 2787.3000000000043,
    tank_volume_stop: 2732.400000000004,
  },
  {
    timestamp_start: '2022-01-01T20:12:00',
    timestamp_stop: '2022-01-01T20:12:05',
    quantity: 47.9,
    vehicle: 'DEM666',
    id: 139755,
    tank_volume_start: 2732.400000000004,
    tank_volume_stop: 2670.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:12:20',
    timestamp_stop: '2022-01-01T20:12:25',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 139757,
    tank_volume_start: 2670.8000000000043,
    tank_volume_stop: 2611.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T20:12:40',
    timestamp_stop: '2022-01-01T20:12:47',
    quantity: 60.9,
    vehicle: 'DEM333',
    id: 139759,
    tank_volume_start: 2611.1000000000045,
    tank_volume_stop: 2554.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:13:02',
    timestamp_stop: '2022-01-01T20:13:07',
    quantity: 51.4,
    vehicle: 'DEM888',
    id: 139761,
    tank_volume_start: 2554.7000000000044,
    tank_volume_stop: 2500.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T20:13:22',
    timestamp_stop: '2022-01-01T20:13:28',
    quantity: 60.7,
    vehicle: 'DEM111',
    id: 139763,
    tank_volume_start: 2500.2000000000044,
    tank_volume_stop: 2448.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T20:13:43',
    timestamp_stop: '2022-01-01T20:13:48',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 139765,
    tank_volume_start: 2448.8000000000043,
    tank_volume_stop: 2392.900000000004,
  },
  {
    timestamp_start: '2022-01-01T20:14:03',
    timestamp_stop: '2022-01-01T20:14:09',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 139767,
    tank_volume_start: 2392.900000000004,
    tank_volume_stop: 2335.600000000004,
  },
  {
    timestamp_start: '2022-01-01T20:14:24',
    timestamp_stop: '2022-01-01T20:14:30',
    quantity: 60.5,
    vehicle: 'DEM555',
    id: 139769,
    tank_volume_start: 2335.600000000004,
    tank_volume_stop: 2282.600000000004,
  },
  {
    timestamp_start: '2022-01-01T20:14:45',
    timestamp_stop: '2022-01-01T20:14:50',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 139771,
    tank_volume_start: 2282.600000000004,
    tank_volume_stop: 2235.000000000004,
  },
  {
    timestamp_start: '2022-01-01T20:15:05',
    timestamp_stop: '2022-01-01T20:15:10',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 139773,
    tank_volume_start: 2235.000000000004,
    tank_volume_stop: 2187.700000000004,
  },
  {
    timestamp_start: '2022-01-01T20:15:25',
    timestamp_stop: '2022-01-01T20:15:30',
    quantity: 53.4,
    vehicle: 'DEM000',
    id: 139775,
    tank_volume_start: 2187.700000000004,
    tank_volume_stop: 2131.300000000004,
  },
  {
    timestamp_start: '2022-01-01T20:15:45',
    timestamp_stop: '2022-01-01T20:15:51',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 139777,
    tank_volume_start: 2131.300000000004,
    tank_volume_stop: 2081.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:16:06',
    timestamp_stop: '2022-01-01T20:16:12',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 139779,
    tank_volume_start: 2081.200000000004,
    tank_volume_stop: 2022.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:16:27',
    timestamp_stop: '2022-01-01T20:16:33',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 139781,
    tank_volume_start: 2022.200000000004,
    tank_volume_stop: 1970.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:16:48',
    timestamp_stop: '2022-01-01T20:16:53',
    quantity: 58.0,
    vehicle: 'DEM111',
    id: 139783,
    tank_volume_start: 3970.200000000004,
    tank_volume_stop: 3912.600000000004,
  },
  {
    timestamp_start: '2022-01-01T20:17:08',
    timestamp_stop: '2022-01-01T20:17:14',
    quantity: 57.4,
    vehicle: 'DEM555',
    id: 139785,
    tank_volume_start: 3912.600000000004,
    tank_volume_stop: 3856.300000000004,
  },
  {
    timestamp_start: '2022-01-01T20:17:29',
    timestamp_stop: '2022-01-01T20:17:34',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 139787,
    tank_volume_start: 3856.300000000004,
    tank_volume_stop: 3797.300000000004,
  },
  {
    timestamp_start: '2022-01-01T20:17:49',
    timestamp_stop: '2022-01-01T20:17:56',
    quantity: 64.0,
    vehicle: 'DEM000',
    id: 139789,
    tank_volume_start: 3797.300000000004,
    tank_volume_stop: 3742.5000000000036,
  },
  {
    timestamp_start: '2022-01-01T20:18:11',
    timestamp_stop: '2022-01-01T20:18:16',
    quantity: 56.1,
    vehicle: 'DEM000',
    id: 139791,
    tank_volume_start: 3742.5000000000036,
    tank_volume_stop: 3682.5000000000036,
  },
  {
    timestamp_start: '2022-01-01T20:18:31',
    timestamp_stop: '2022-01-01T20:18:37',
    quantity: 54.1,
    vehicle: 'DEM333',
    id: 139793,
    tank_volume_start: 3682.5000000000036,
    tank_volume_stop: 3628.800000000004,
  },
  {
    timestamp_start: '2022-01-01T20:18:52',
    timestamp_stop: '2022-01-01T20:18:57',
    quantity: 58.7,
    vehicle: 'DEM333',
    id: 139795,
    tank_volume_start: 3628.800000000004,
    tank_volume_stop: 3570.200000000004,
  },
  {
    timestamp_start: '2022-01-01T20:19:12',
    timestamp_stop: '2022-01-01T20:19:19',
    quantity: 60.8,
    vehicle: 'DEM222',
    id: 139797,
    tank_volume_start: 3570.200000000004,
    tank_volume_stop: 3515.800000000004,
  },
  {
    timestamp_start: '2022-01-01T20:19:34',
    timestamp_stop: '2022-01-01T20:19:39',
    quantity: 52.2,
    vehicle: 'DEM333',
    id: 139799,
    tank_volume_start: 3515.800000000004,
    tank_volume_stop: 3453.0000000000036,
  },
  {
    timestamp_start: '2022-01-01T20:19:54',
    timestamp_stop: '2022-01-01T20:19:59',
    quantity: 53.2,
    vehicle: 'DEM666',
    id: 139801,
    tank_volume_start: 3453.0000000000036,
    tank_volume_stop: 3391.2000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:20:14',
    timestamp_stop: '2022-01-01T20:20:20',
    quantity: 54.4,
    vehicle: 'DEM000',
    id: 139803,
    tank_volume_start: 3391.2000000000035,
    tank_volume_stop: 3340.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:20:35',
    timestamp_stop: '2022-01-01T20:20:41',
    quantity: 64.8,
    vehicle: 'DEM555',
    id: 139805,
    tank_volume_start: 3340.9000000000033,
    tank_volume_stop: 3287.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:20:56',
    timestamp_stop: '2022-01-01T20:21:01',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 139807,
    tank_volume_start: 3287.7000000000035,
    tank_volume_stop: 3230.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:21:16',
    timestamp_stop: '2022-01-01T20:21:21',
    quantity: 51.9,
    vehicle: 'DEM555',
    id: 139809,
    tank_volume_start: 3230.6000000000035,
    tank_volume_stop: 3171.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:21:36',
    timestamp_stop: '2022-01-01T20:21:42',
    quantity: 53.7,
    vehicle: 'DEM555',
    id: 139811,
    tank_volume_start: 3171.7000000000035,
    tank_volume_stop: 3110.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:21:57',
    timestamp_stop: '2022-01-01T20:22:03',
    quantity: 63.4,
    vehicle: 'DEM333',
    id: 139813,
    tank_volume_start: 3110.7000000000035,
    tank_volume_stop: 3050.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:22:18',
    timestamp_stop: '2022-01-01T20:22:24',
    quantity: 60.7,
    vehicle: 'DEM777',
    id: 139815,
    tank_volume_start: 3050.4000000000033,
    tank_volume_stop: 2997.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:22:39',
    timestamp_stop: '2022-01-01T20:22:44',
    quantity: 54.2,
    vehicle: 'DEM888',
    id: 139817,
    tank_volume_start: 2997.600000000003,
    tank_volume_stop: 2941.300000000003,
  },
  {
    timestamp_start: '2022-01-01T20:22:59',
    timestamp_stop: '2022-01-01T20:23:05',
    quantity: 53.1,
    vehicle: 'DEM777',
    id: 139819,
    tank_volume_start: 2941.300000000003,
    tank_volume_stop: 2882.400000000003,
  },
  {
    timestamp_start: '2022-01-01T20:23:20',
    timestamp_stop: '2022-01-01T20:23:26',
    quantity: 59.7,
    vehicle: 'DEM888',
    id: 139821,
    tank_volume_start: 2882.400000000003,
    tank_volume_stop: 2820.300000000003,
  },
  {
    timestamp_start: '2022-01-01T20:23:41',
    timestamp_stop: '2022-01-01T20:23:47',
    quantity: 58.9,
    vehicle: 'DEM111',
    id: 139823,
    tank_volume_start: 2820.300000000003,
    tank_volume_stop: 2767.400000000003,
  },
  {
    timestamp_start: '2022-01-01T20:24:02',
    timestamp_stop: '2022-01-01T20:24:07',
    quantity: 55.9,
    vehicle: 'DEM333',
    id: 139825,
    tank_volume_start: 2767.400000000003,
    tank_volume_stop: 2718.6000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:24:22',
    timestamp_stop: '2022-01-01T20:24:27',
    quantity: 52.8,
    vehicle: 'DEM444',
    id: 139827,
    tank_volume_start: 2718.6000000000026,
    tank_volume_stop: 2664.5000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:24:42',
    timestamp_stop: '2022-01-01T20:24:48',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 139829,
    tank_volume_start: 2664.5000000000027,
    tank_volume_stop: 2607.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:25:03',
    timestamp_stop: '2022-01-01T20:25:08',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 139831,
    tank_volume_start: 2607.1000000000026,
    tank_volume_stop: 2557.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:25:23',
    timestamp_stop: '2022-01-01T20:25:29',
    quantity: 58.3,
    vehicle: 'DEM333',
    id: 139833,
    tank_volume_start: 2557.1000000000026,
    tank_volume_stop: 2503.900000000003,
  },
  {
    timestamp_start: '2022-01-01T20:25:44',
    timestamp_stop: '2022-01-01T20:25:50',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 139835,
    tank_volume_start: 2503.900000000003,
    tank_volume_stop: 2453.900000000003,
  },
  {
    timestamp_start: '2022-01-01T20:26:05',
    timestamp_stop: '2022-01-01T20:26:10',
    quantity: 50.9,
    vehicle: 'DEM222',
    id: 139837,
    tank_volume_start: 2453.900000000003,
    tank_volume_stop: 2402.200000000003,
  },
  {
    timestamp_start: '2022-01-01T20:26:25',
    timestamp_stop: '2022-01-01T20:26:31',
    quantity: 57.5,
    vehicle: 'DEM333',
    id: 139839,
    tank_volume_start: 2402.200000000003,
    tank_volume_stop: 2348.100000000003,
  },
  {
    timestamp_start: '2022-01-01T20:26:46',
    timestamp_stop: '2022-01-01T20:26:51',
    quantity: 55.0,
    vehicle: 'DEM888',
    id: 139841,
    tank_volume_start: 2348.100000000003,
    tank_volume_stop: 2293.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:27:06',
    timestamp_stop: '2022-01-01T20:27:12',
    quantity: 62.0,
    vehicle: 'DEM444',
    id: 139843,
    tank_volume_start: 2293.600000000003,
    tank_volume_stop: 2239.100000000003,
  },
  {
    timestamp_start: '2022-01-01T20:27:27',
    timestamp_stop: '2022-01-01T20:27:32',
    quantity: 48.2,
    vehicle: 'DEM222',
    id: 139845,
    tank_volume_start: 2239.100000000003,
    tank_volume_stop: 2179.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:27:47',
    timestamp_stop: '2022-01-01T20:27:53',
    quantity: 54.9,
    vehicle: 'DEM666',
    id: 139847,
    tank_volume_start: 2179.000000000003,
    tank_volume_stop: 2120.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:28:08',
    timestamp_stop: '2022-01-01T20:28:12',
    quantity: 47.3,
    vehicle: 'DEM555',
    id: 139849,
    tank_volume_start: 2120.9000000000033,
    tank_volume_stop: 2067.7000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:28:27',
    timestamp_stop: '2022-01-01T20:28:33',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 139851,
    tank_volume_start: 2067.7000000000035,
    tank_volume_stop: 2011.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:28:48',
    timestamp_stop: '2022-01-01T20:28:53',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 139853,
    tank_volume_start: 2011.8000000000034,
    tank_volume_stop: 1950.6000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:29:08',
    timestamp_stop: '2022-01-01T20:29:14',
    quantity: 54.5,
    vehicle: 'DEM555',
    id: 139855,
    tank_volume_start: 3950.600000000003,
    tank_volume_stop: 3898.100000000003,
  },
  {
    timestamp_start: '2022-01-01T20:29:29',
    timestamp_stop: '2022-01-01T20:29:35',
    quantity: 58.8,
    vehicle: 'DEM000',
    id: 139857,
    tank_volume_start: 3898.100000000003,
    tank_volume_stop: 3843.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:29:50',
    timestamp_stop: '2022-01-01T20:29:56',
    quantity: 60.3,
    vehicle: 'DEM888',
    id: 139859,
    tank_volume_start: 3843.4000000000033,
    tank_volume_stop: 3797.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:30:11',
    timestamp_stop: '2022-01-01T20:30:17',
    quantity: 64.5,
    vehicle: 'DEM333',
    id: 139861,
    tank_volume_start: 3797.000000000003,
    tank_volume_stop: 3751.700000000003,
  },
  {
    timestamp_start: '2022-01-01T20:30:32',
    timestamp_stop: '2022-01-01T20:30:38',
    quantity: 56.8,
    vehicle: 'DEM333',
    id: 139863,
    tank_volume_start: 3751.700000000003,
    tank_volume_stop: 3695.300000000003,
  },
  {
    timestamp_start: '2022-01-01T20:30:53',
    timestamp_stop: '2022-01-01T20:30:58',
    quantity: 53.5,
    vehicle: 'DEM888',
    id: 139865,
    tank_volume_start: 3695.300000000003,
    tank_volume_stop: 3639.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:31:13',
    timestamp_stop: '2022-01-01T20:31:19',
    quantity: 58.6,
    vehicle: 'DEM111',
    id: 139867,
    tank_volume_start: 3639.600000000003,
    tank_volume_stop: 3582.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:31:34',
    timestamp_stop: '2022-01-01T20:31:39',
    quantity: 49.4,
    vehicle: 'DEM333',
    id: 139869,
    tank_volume_start: 3582.000000000003,
    tank_volume_stop: 3519.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:31:54',
    timestamp_stop: '2022-01-01T20:32:00',
    quantity: 64.5,
    vehicle: 'DEM555',
    id: 139871,
    tank_volume_start: 3519.9000000000033,
    tank_volume_stop: 3468.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:32:15',
    timestamp_stop: '2022-01-01T20:32:21',
    quantity: 54.8,
    vehicle: 'DEM222',
    id: 139873,
    tank_volume_start: 3468.9000000000033,
    tank_volume_stop: 3410.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:32:36',
    timestamp_stop: '2022-01-01T20:32:41',
    quantity: 50.5,
    vehicle: 'DEM333',
    id: 139875,
    tank_volume_start: 3410.500000000003,
    tank_volume_stop: 3347.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:32:56',
    timestamp_stop: '2022-01-01T20:33:01',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 139877,
    tank_volume_start: 3347.600000000003,
    tank_volume_stop: 3294.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:33:16',
    timestamp_stop: '2022-01-01T20:33:22',
    quantity: 58.6,
    vehicle: 'DEM111',
    id: 139879,
    tank_volume_start: 3294.9000000000033,
    tank_volume_stop: 3235.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:33:37',
    timestamp_stop: '2022-01-01T20:33:43',
    quantity: 54.4,
    vehicle: 'DEM555',
    id: 139881,
    tank_volume_start: 3235.600000000003,
    tank_volume_stop: 3183.200000000003,
  },
  {
    timestamp_start: '2022-01-01T20:33:58',
    timestamp_stop: '2022-01-01T20:34:04',
    quantity: 64.4,
    vehicle: 'DEM111',
    id: 139883,
    tank_volume_start: 3183.200000000003,
    tank_volume_stop: 3136.100000000003,
  },
  {
    timestamp_start: '2022-01-01T20:34:19',
    timestamp_stop: '2022-01-01T20:34:25',
    quantity: 59.8,
    vehicle: 'DEM888',
    id: 139885,
    tank_volume_start: 3136.100000000003,
    tank_volume_stop: 3072.700000000003,
  },
  {
    timestamp_start: '2022-01-01T20:34:40',
    timestamp_stop: '2022-01-01T20:34:46',
    quantity: 60.2,
    vehicle: 'DEM444',
    id: 139887,
    tank_volume_start: 3072.700000000003,
    tank_volume_stop: 3009.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:35:01',
    timestamp_stop: '2022-01-01T20:35:07',
    quantity: 56.7,
    vehicle: 'DEM111',
    id: 139889,
    tank_volume_start: 3009.000000000003,
    tank_volume_stop: 2948.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:35:22',
    timestamp_stop: '2022-01-01T20:35:28',
    quantity: 59.2,
    vehicle: 'DEM666',
    id: 139891,
    tank_volume_start: 2948.000000000003,
    tank_volume_stop: 2888.000000000003,
  },
  {
    timestamp_start: '2022-01-01T20:35:43',
    timestamp_stop: '2022-01-01T20:35:48',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 139893,
    tank_volume_start: 2888.000000000003,
    tank_volume_stop: 2832.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:36:03',
    timestamp_stop: '2022-01-01T20:36:09',
    quantity: 55.1,
    vehicle: 'DEM000',
    id: 139895,
    tank_volume_start: 2832.3000000000034,
    tank_volume_stop: 2781.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:36:24',
    timestamp_stop: '2022-01-01T20:36:30',
    quantity: 59.2,
    vehicle: 'DEM222',
    id: 139897,
    tank_volume_start: 2781.6000000000035,
    tank_volume_stop: 2720.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:36:45',
    timestamp_stop: '2022-01-01T20:36:50',
    quantity: 53.1,
    vehicle: 'DEM111',
    id: 139899,
    tank_volume_start: 2720.3000000000034,
    tank_volume_stop: 2663.2000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:37:05',
    timestamp_stop: '2022-01-01T20:37:11',
    quantity: 63.1,
    vehicle: 'DEM666',
    id: 139901,
    tank_volume_start: 2663.2000000000035,
    tank_volume_stop: 2608.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:37:26',
    timestamp_stop: '2022-01-01T20:37:33',
    quantity: 63.9,
    vehicle: 'DEM666',
    id: 139903,
    tank_volume_start: 2608.4000000000033,
    tank_volume_stop: 2551.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:37:48',
    timestamp_stop: '2022-01-01T20:37:53',
    quantity: 52.0,
    vehicle: 'DEM111',
    id: 139905,
    tank_volume_start: 2551.500000000003,
    tank_volume_stop: 2498.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:38:08',
    timestamp_stop: '2022-01-01T20:38:13',
    quantity: 53.9,
    vehicle: 'DEM111',
    id: 139907,
    tank_volume_start: 2498.9000000000033,
    tank_volume_stop: 2441.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:38:28',
    timestamp_stop: '2022-01-01T20:38:33',
    quantity: 46.9,
    vehicle: 'DEM111',
    id: 139909,
    tank_volume_start: 2441.600000000003,
    tank_volume_stop: 2390.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:38:48',
    timestamp_stop: '2022-01-01T20:38:54',
    quantity: 59.6,
    vehicle: 'DEM222',
    id: 139911,
    tank_volume_start: 2390.4000000000033,
    tank_volume_stop: 2336.2000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:39:09',
    timestamp_stop: '2022-01-01T20:39:14',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 139913,
    tank_volume_start: 2336.2000000000035,
    tank_volume_stop: 2278.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T20:39:29',
    timestamp_stop: '2022-01-01T20:39:35',
    quantity: 59.1,
    vehicle: 'DEM777',
    id: 139915,
    tank_volume_start: 2278.6000000000035,
    tank_volume_stop: 2229.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:39:50',
    timestamp_stop: '2022-01-01T20:39:55',
    quantity: 45.9,
    vehicle: 'DEM555',
    id: 139917,
    tank_volume_start: 2229.8000000000034,
    tank_volume_stop: 2180.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:40:10',
    timestamp_stop: '2022-01-01T20:40:15',
    quantity: 48.6,
    vehicle: 'DEM777',
    id: 139919,
    tank_volume_start: 2180.3000000000034,
    tank_volume_stop: 2122.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:40:30',
    timestamp_stop: '2022-01-01T20:40:36',
    quantity: 63.4,
    vehicle: 'DEM444',
    id: 139921,
    tank_volume_start: 2122.4000000000033,
    tank_volume_stop: 2068.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:40:51',
    timestamp_stop: '2022-01-01T20:40:57',
    quantity: 57.2,
    vehicle: 'DEM111',
    id: 139923,
    tank_volume_start: 2068.4000000000033,
    tank_volume_stop: 2006.2000000000032,
  },
  {
    timestamp_start: '2022-01-01T20:41:12',
    timestamp_stop: '2022-01-01T20:41:17',
    quantity: 51.3,
    vehicle: 'DEM888',
    id: 139925,
    tank_volume_start: 2006.2000000000032,
    tank_volume_stop: 1950.7000000000032,
  },
  {
    timestamp_start: '2022-01-01T20:41:32',
    timestamp_stop: '2022-01-01T20:41:37',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 139927,
    tank_volume_start: 3950.7000000000035,
    tank_volume_stop: 3897.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:41:52',
    timestamp_stop: '2022-01-01T20:41:57',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 139929,
    tank_volume_start: 3897.4000000000033,
    tank_volume_stop: 3849.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:42:12',
    timestamp_stop: '2022-01-01T20:42:17',
    quantity: 52.9,
    vehicle: 'DEM000',
    id: 139931,
    tank_volume_start: 3849.500000000003,
    tank_volume_stop: 3790.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:42:32',
    timestamp_stop: '2022-01-01T20:42:37',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 139933,
    tank_volume_start: 3790.600000000003,
    tank_volume_stop: 3728.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:42:52',
    timestamp_stop: '2022-01-01T20:42:58',
    quantity: 57.8,
    vehicle: 'DEM777',
    id: 139935,
    tank_volume_start: 3728.500000000003,
    tank_volume_stop: 3681.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:43:13',
    timestamp_stop: '2022-01-01T20:43:18',
    quantity: 51.5,
    vehicle: 'DEM555',
    id: 139937,
    tank_volume_start: 3681.9000000000033,
    tank_volume_stop: 3622.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:43:33',
    timestamp_stop: '2022-01-01T20:43:39',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 139939,
    tank_volume_start: 3622.500000000003,
    tank_volume_stop: 3567.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:43:54',
    timestamp_stop: '2022-01-01T20:43:59',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 139941,
    tank_volume_start: 3567.9000000000033,
    tank_volume_stop: 3520.100000000003,
  },
  {
    timestamp_start: '2022-01-01T20:44:14',
    timestamp_stop: '2022-01-01T20:44:19',
    quantity: 54.0,
    vehicle: 'DEM888',
    id: 139943,
    tank_volume_start: 3520.100000000003,
    tank_volume_stop: 3462.300000000003,
  },
  {
    timestamp_start: '2022-01-01T20:44:34',
    timestamp_stop: '2022-01-01T20:44:39',
    quantity: 46.9,
    vehicle: 'DEM555',
    id: 139945,
    tank_volume_start: 3462.300000000003,
    tank_volume_stop: 3399.300000000003,
  },
  {
    timestamp_start: '2022-01-01T20:44:54',
    timestamp_stop: '2022-01-01T20:44:59',
    quantity: 52.6,
    vehicle: 'DEM000',
    id: 139947,
    tank_volume_start: 3399.300000000003,
    tank_volume_stop: 3340.900000000003,
  },
  {
    timestamp_start: '2022-01-01T20:45:14',
    timestamp_stop: '2022-01-01T20:45:19',
    quantity: 51.3,
    vehicle: 'DEM000',
    id: 139949,
    tank_volume_start: 3340.900000000003,
    tank_volume_stop: 3287.6000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:45:34',
    timestamp_stop: '2022-01-01T20:45:40',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 139951,
    tank_volume_start: 3287.6000000000026,
    tank_volume_stop: 3233.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:45:55',
    timestamp_stop: '2022-01-01T20:46:01',
    quantity: 54.8,
    vehicle: 'DEM444',
    id: 139953,
    tank_volume_start: 3233.0000000000027,
    tank_volume_stop: 3174.7000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:46:16',
    timestamp_stop: '2022-01-01T20:46:22',
    quantity: 56.2,
    vehicle: 'DEM444',
    id: 139955,
    tank_volume_start: 3174.7000000000025,
    tank_volume_stop: 3114.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:46:37',
    timestamp_stop: '2022-01-01T20:46:43',
    quantity: 60.0,
    vehicle: 'DEM777',
    id: 139957,
    tank_volume_start: 3114.0000000000027,
    tank_volume_stop: 3061.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:46:58',
    timestamp_stop: '2022-01-01T20:47:04',
    quantity: 62.3,
    vehicle: 'DEM111',
    id: 139959,
    tank_volume_start: 3061.0000000000027,
    tank_volume_stop: 2997.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:47:19',
    timestamp_stop: '2022-01-01T20:47:23',
    quantity: 45.5,
    vehicle: 'DEM222',
    id: 139961,
    tank_volume_start: 2997.2000000000025,
    tank_volume_stop: 2950.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T20:47:38',
    timestamp_stop: '2022-01-01T20:47:43',
    quantity: 49.4,
    vehicle: 'DEM222',
    id: 139963,
    tank_volume_start: 2950.9000000000024,
    tank_volume_stop: 2889.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:47:58',
    timestamp_stop: '2022-01-01T20:48:03',
    quantity: 52.1,
    vehicle: 'DEM333',
    id: 139965,
    tank_volume_start: 2889.2000000000025,
    tank_volume_stop: 2825.6000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:48:18',
    timestamp_stop: '2022-01-01T20:48:24',
    quantity: 57.1,
    vehicle: 'DEM111',
    id: 139967,
    tank_volume_start: 2825.6000000000026,
    tank_volume_stop: 2766.400000000003,
  },
  {
    timestamp_start: '2022-01-01T20:48:39',
    timestamp_stop: '2022-01-01T20:48:45',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 139969,
    tank_volume_start: 2766.400000000003,
    tank_volume_stop: 2715.800000000003,
  },
  {
    timestamp_start: '2022-01-01T20:49:00',
    timestamp_stop: '2022-01-01T20:49:06',
    quantity: 60.5,
    vehicle: 'DEM333',
    id: 139971,
    tank_volume_start: 2715.800000000003,
    tank_volume_stop: 2658.200000000003,
  },
  {
    timestamp_start: '2022-01-01T20:49:21',
    timestamp_stop: '2022-01-01T20:49:26',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 139973,
    tank_volume_start: 2658.200000000003,
    tank_volume_stop: 2612.400000000003,
  },
  {
    timestamp_start: '2022-01-01T20:49:41',
    timestamp_stop: '2022-01-01T20:49:47',
    quantity: 53.0,
    vehicle: 'DEM333',
    id: 139975,
    tank_volume_start: 2612.400000000003,
    tank_volume_stop: 2556.5000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:50:02',
    timestamp_stop: '2022-01-01T20:50:08',
    quantity: 61.5,
    vehicle: 'DEM222',
    id: 139977,
    tank_volume_start: 2556.5000000000027,
    tank_volume_stop: 2499.0000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:50:23',
    timestamp_stop: '2022-01-01T20:50:28',
    quantity: 53.8,
    vehicle: 'DEM333',
    id: 139979,
    tank_volume_start: 2499.0000000000027,
    tank_volume_stop: 2444.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:50:43',
    timestamp_stop: '2022-01-01T20:50:49',
    quantity: 59.3,
    vehicle: 'DEM333',
    id: 139981,
    tank_volume_start: 2444.1000000000026,
    tank_volume_stop: 2397.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:51:04',
    timestamp_stop: '2022-01-01T20:51:09',
    quantity: 46.7,
    vehicle: 'DEM555',
    id: 139983,
    tank_volume_start: 2397.2000000000025,
    tank_volume_stop: 2340.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:51:24',
    timestamp_stop: '2022-01-01T20:51:30',
    quantity: 61.7,
    vehicle: 'DEM555',
    id: 139985,
    tank_volume_start: 2340.1000000000026,
    tank_volume_stop: 2275.400000000003,
  },
  {
    timestamp_start: '2022-01-01T20:51:45',
    timestamp_stop: '2022-01-01T20:51:50',
    quantity: 55.7,
    vehicle: 'DEM111',
    id: 139987,
    tank_volume_start: 2275.400000000003,
    tank_volume_stop: 2217.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:52:05',
    timestamp_stop: '2022-01-01T20:52:11',
    quantity: 60.0,
    vehicle: 'DEM444',
    id: 139989,
    tank_volume_start: 2217.1000000000026,
    tank_volume_stop: 2168.6000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:52:26',
    timestamp_stop: '2022-01-01T20:52:32',
    quantity: 57.3,
    vehicle: 'DEM333',
    id: 139991,
    tank_volume_start: 2168.6000000000026,
    tank_volume_stop: 2114.7000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:52:47',
    timestamp_stop: '2022-01-01T20:52:53',
    quantity: 54.4,
    vehicle: 'DEM222',
    id: 139993,
    tank_volume_start: 2114.7000000000025,
    tank_volume_stop: 2059.9000000000024,
  },
  {
    timestamp_start: '2022-01-01T20:53:08',
    timestamp_stop: '2022-01-01T20:53:14',
    quantity: 59.0,
    vehicle: 'DEM111',
    id: 139995,
    tank_volume_start: 2059.9000000000024,
    tank_volume_stop: 1998.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:53:29',
    timestamp_stop: '2022-01-01T20:53:35',
    quantity: 61.7,
    vehicle: 'DEM666',
    id: 139997,
    tank_volume_start: 3998.3000000000025,
    tank_volume_stop: 3938.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T20:53:50',
    timestamp_stop: '2022-01-01T20:53:55',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 139999,
    tank_volume_start: 3938.5000000000023,
    tank_volume_stop: 3888.100000000002,
  },
  {
    timestamp_start: '2022-01-01T20:54:10',
    timestamp_stop: '2022-01-01T20:54:14',
    quantity: 49.5,
    vehicle: 'DEM444',
    id: 140001,
    tank_volume_start: 3888.100000000002,
    tank_volume_stop: 3831.100000000002,
  },
  {
    timestamp_start: '2022-01-01T20:54:29',
    timestamp_stop: '2022-01-01T20:54:35',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 140003,
    tank_volume_start: 3831.100000000002,
    tank_volume_stop: 3775.0000000000023,
  },
  {
    timestamp_start: '2022-01-01T20:54:50',
    timestamp_stop: '2022-01-01T20:54:56',
    quantity: 61.0,
    vehicle: 'DEM222',
    id: 140005,
    tank_volume_start: 3775.0000000000023,
    tank_volume_stop: 3724.5000000000023,
  },
  {
    timestamp_start: '2022-01-01T20:55:11',
    timestamp_stop: '2022-01-01T20:55:16',
    quantity: 54.6,
    vehicle: 'DEM666',
    id: 140007,
    tank_volume_start: 3724.5000000000023,
    tank_volume_stop: 3665.8000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:55:31',
    timestamp_stop: '2022-01-01T20:55:37',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 140009,
    tank_volume_start: 3665.8000000000025,
    tank_volume_stop: 3605.3000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:55:52',
    timestamp_stop: '2022-01-01T20:55:57',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 140011,
    tank_volume_start: 3605.3000000000025,
    tank_volume_stop: 3555.2000000000025,
  },
  {
    timestamp_start: '2022-01-01T20:56:12',
    timestamp_stop: '2022-01-01T20:56:18',
    quantity: 56.1,
    vehicle: 'DEM555',
    id: 140013,
    tank_volume_start: 3555.2000000000025,
    tank_volume_stop: 3505.1000000000026,
  },
  {
    timestamp_start: '2022-01-01T20:56:33',
    timestamp_stop: '2022-01-01T20:56:38',
    quantity: 53.6,
    vehicle: 'DEM888',
    id: 140015,
    tank_volume_start: 3505.1000000000026,
    tank_volume_stop: 3443.5000000000027,
  },
  {
    timestamp_start: '2022-01-01T20:56:53',
    timestamp_stop: '2022-01-01T20:56:58',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 140017,
    tank_volume_start: 3443.5000000000027,
    tank_volume_stop: 3385.800000000003,
  },
  {
    timestamp_start: '2022-01-01T20:57:13',
    timestamp_stop: '2022-01-01T20:57:18',
    quantity: 51.9,
    vehicle: 'DEM555',
    id: 140019,
    tank_volume_start: 3385.800000000003,
    tank_volume_stop: 3330.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:57:33',
    timestamp_stop: '2022-01-01T20:57:39',
    quantity: 55.0,
    vehicle: 'DEM888',
    id: 140021,
    tank_volume_start: 3330.600000000003,
    tank_volume_stop: 3281.600000000003,
  },
  {
    timestamp_start: '2022-01-01T20:57:54',
    timestamp_stop: '2022-01-01T20:58:00',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 140023,
    tank_volume_start: 3281.600000000003,
    tank_volume_stop: 3232.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:58:15',
    timestamp_stop: '2022-01-01T20:58:19',
    quantity: 45.4,
    vehicle: 'DEM000',
    id: 140025,
    tank_volume_start: 3232.500000000003,
    tank_volume_stop: 3180.500000000003,
  },
  {
    timestamp_start: '2022-01-01T20:58:34',
    timestamp_stop: '2022-01-01T20:58:40',
    quantity: 53.2,
    vehicle: 'DEM222',
    id: 140027,
    tank_volume_start: 3180.500000000003,
    tank_volume_stop: 3119.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T20:58:55',
    timestamp_stop: '2022-01-01T20:59:00',
    quantity: 49.4,
    vehicle: 'DEM444',
    id: 140029,
    tank_volume_start: 3119.8000000000034,
    tank_volume_stop: 3059.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:59:15',
    timestamp_stop: '2022-01-01T20:59:21',
    quantity: 64.0,
    vehicle: 'DEM444',
    id: 140031,
    tank_volume_start: 3059.9000000000033,
    tank_volume_stop: 3000.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:59:36',
    timestamp_stop: '2022-01-01T20:59:42',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 140033,
    tank_volume_start: 3000.9000000000033,
    tank_volume_stop: 2949.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T20:59:57',
    timestamp_stop: '2022-01-01T21:00:02',
    quantity: 56.3,
    vehicle: 'DEM888',
    id: 140035,
    tank_volume_start: 2949.4000000000033,
    tank_volume_stop: 2898.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:00:17',
    timestamp_stop: '2022-01-01T21:00:23',
    quantity: 54.7,
    vehicle: 'DEM888',
    id: 140037,
    tank_volume_start: 2898.3000000000034,
    tank_volume_stop: 2836.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:00:38',
    timestamp_stop: '2022-01-01T21:00:44',
    quantity: 63.0,
    vehicle: 'DEM666',
    id: 140039,
    tank_volume_start: 2836.6000000000035,
    tank_volume_stop: 2780.2000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:00:59',
    timestamp_stop: '2022-01-01T21:01:04',
    quantity: 47.4,
    vehicle: 'DEM444',
    id: 140041,
    tank_volume_start: 2780.2000000000035,
    tank_volume_stop: 2728.1000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:01:19',
    timestamp_stop: '2022-01-01T21:01:24',
    quantity: 47.6,
    vehicle: 'DEM333',
    id: 140043,
    tank_volume_start: 2728.1000000000035,
    tank_volume_stop: 2678.9000000000037,
  },
  {
    timestamp_start: '2022-01-01T21:01:39',
    timestamp_stop: '2022-01-01T21:01:44',
    quantity: 57.1,
    vehicle: 'DEM888',
    id: 140045,
    tank_volume_start: 2678.9000000000037,
    tank_volume_stop: 2620.1000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:01:59',
    timestamp_stop: '2022-01-01T21:02:05',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 140047,
    tank_volume_start: 2620.1000000000035,
    tank_volume_stop: 2559.4000000000037,
  },
  {
    timestamp_start: '2022-01-01T21:02:20',
    timestamp_stop: '2022-01-01T21:02:26',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 140049,
    tank_volume_start: 2559.4000000000037,
    tank_volume_stop: 2509.9000000000037,
  },
  {
    timestamp_start: '2022-01-01T21:02:41',
    timestamp_stop: '2022-01-01T21:02:46',
    quantity: 52.2,
    vehicle: 'DEM888',
    id: 140051,
    tank_volume_start: 2509.9000000000037,
    tank_volume_stop: 2458.6000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:03:01',
    timestamp_stop: '2022-01-01T21:03:06',
    quantity: 54.9,
    vehicle: 'DEM555',
    id: 140053,
    tank_volume_start: 2458.6000000000035,
    tank_volume_stop: 2407.5000000000036,
  },
  {
    timestamp_start: '2022-01-01T21:03:21',
    timestamp_stop: '2022-01-01T21:03:27',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 140055,
    tank_volume_start: 2407.5000000000036,
    tank_volume_stop: 2344.1000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:03:42',
    timestamp_stop: '2022-01-01T21:03:47',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 140057,
    tank_volume_start: 2344.1000000000035,
    tank_volume_stop: 2286.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:04:02',
    timestamp_stop: '2022-01-01T21:04:08',
    quantity: 53.7,
    vehicle: 'DEM888',
    id: 140059,
    tank_volume_start: 2286.8000000000034,
    tank_volume_stop: 2239.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:04:23',
    timestamp_stop: '2022-01-01T21:04:28',
    quantity: 56.4,
    vehicle: 'DEM555',
    id: 140061,
    tank_volume_start: 2239.4000000000033,
    tank_volume_stop: 2182.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:04:43',
    timestamp_stop: '2022-01-01T21:04:49',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 140063,
    tank_volume_start: 2182.4000000000033,
    tank_volume_stop: 2121.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:05:04',
    timestamp_stop: '2022-01-01T21:05:09',
    quantity: 50.6,
    vehicle: 'DEM888',
    id: 140065,
    tank_volume_start: 2121.3000000000034,
    tank_volume_stop: 2068.8000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:05:24',
    timestamp_stop: '2022-01-01T21:05:29',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 140067,
    tank_volume_start: 2068.8000000000034,
    tank_volume_stop: 2013.1000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:05:44',
    timestamp_stop: '2022-01-01T21:05:49',
    quantity: 55.4,
    vehicle: 'DEM555',
    id: 140069,
    tank_volume_start: 2013.1000000000033,
    tank_volume_stop: 1954.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:06:04',
    timestamp_stop: '2022-01-01T21:06:10',
    quantity: 55.2,
    vehicle: 'DEM111',
    id: 140071,
    tank_volume_start: 3954.4000000000033,
    tank_volume_stop: 3902.000000000003,
  },
  {
    timestamp_start: '2022-01-01T21:06:25',
    timestamp_stop: '2022-01-01T21:06:30',
    quantity: 52.7,
    vehicle: 'DEM777',
    id: 140073,
    tank_volume_start: 3902.000000000003,
    tank_volume_stop: 3845.600000000003,
  },
  {
    timestamp_start: '2022-01-01T21:06:45',
    timestamp_stop: '2022-01-01T21:06:50',
    quantity: 46.0,
    vehicle: 'DEM000',
    id: 140075,
    tank_volume_start: 3845.600000000003,
    tank_volume_stop: 3788.600000000003,
  },
  {
    timestamp_start: '2022-01-01T21:07:05',
    timestamp_stop: '2022-01-01T21:07:11',
    quantity: 57.6,
    vehicle: 'DEM222',
    id: 140077,
    tank_volume_start: 3788.600000000003,
    tank_volume_stop: 3732.100000000003,
  },
  {
    timestamp_start: '2022-01-01T21:07:26',
    timestamp_stop: '2022-01-01T21:07:31',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 140079,
    tank_volume_start: 3732.100000000003,
    tank_volume_stop: 3672.700000000003,
  },
  {
    timestamp_start: '2022-01-01T21:07:46',
    timestamp_stop: '2022-01-01T21:07:52',
    quantity: 56.3,
    vehicle: 'DEM222',
    id: 140081,
    tank_volume_start: 3672.700000000003,
    tank_volume_stop: 3622.900000000003,
  },
  {
    timestamp_start: '2022-01-01T21:08:07',
    timestamp_stop: '2022-01-01T21:08:12',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 140083,
    tank_volume_start: 3622.900000000003,
    tank_volume_stop: 3563.300000000003,
  },
  {
    timestamp_start: '2022-01-01T21:08:27',
    timestamp_stop: '2022-01-01T21:08:32',
    quantity: 53.8,
    vehicle: 'DEM666',
    id: 140085,
    tank_volume_start: 3563.300000000003,
    tank_volume_stop: 3510.600000000003,
  },
  {
    timestamp_start: '2022-01-01T21:08:47',
    timestamp_stop: '2022-01-01T21:08:54',
    quantity: 62.0,
    vehicle: 'DEM888',
    id: 140087,
    tank_volume_start: 3510.600000000003,
    tank_volume_stop: 3454.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:09:09',
    timestamp_stop: '2022-01-01T21:09:14',
    quantity: 56.6,
    vehicle: 'DEM444',
    id: 140089,
    tank_volume_start: 3454.4000000000033,
    tank_volume_stop: 3394.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:09:29',
    timestamp_stop: '2022-01-01T21:09:35',
    quantity: 53.0,
    vehicle: 'DEM333',
    id: 140091,
    tank_volume_start: 3394.3000000000034,
    tank_volume_stop: 3344.9000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:09:50',
    timestamp_stop: '2022-01-01T21:09:55',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 140093,
    tank_volume_start: 3344.9000000000033,
    tank_volume_stop: 3284.4000000000033,
  },
  {
    timestamp_start: '2022-01-01T21:10:10',
    timestamp_stop: '2022-01-01T21:10:16',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 140095,
    tank_volume_start: 3284.4000000000033,
    tank_volume_stop: 3225.3000000000034,
  },
  {
    timestamp_start: '2022-01-01T21:10:31',
    timestamp_stop: '2022-01-01T21:10:37',
    quantity: 59.6,
    vehicle: 'DEM333',
    id: 140097,
    tank_volume_start: 3225.3000000000034,
    tank_volume_stop: 3167.1000000000035,
  },
  {
    timestamp_start: '2022-01-01T21:10:52',
    timestamp_stop: '2022-01-01T21:10:58',
    quantity: 60.2,
    vehicle: 'DEM222',
    id: 140099,
    tank_volume_start: 3167.1000000000035,
    tank_volume_stop: 3110.4000000000037,
  },
  {
    timestamp_start: '2022-01-01T21:11:13',
    timestamp_stop: '2022-01-01T21:11:19',
    quantity: 59.3,
    vehicle: 'DEM444',
    id: 140101,
    tank_volume_start: 3110.4000000000037,
    tank_volume_stop: 3058.9000000000037,
  },
  {
    timestamp_start: '2022-01-01T21:11:34',
    timestamp_stop: '2022-01-01T21:11:39',
    quantity: 52.0,
    vehicle: 'DEM222',
    id: 140103,
    tank_volume_start: 3058.9000000000037,
    tank_volume_stop: 3007.300000000004,
  },
  {
    timestamp_start: '2022-01-01T21:11:54',
    timestamp_stop: '2022-01-01T21:12:00',
    quantity: 64.1,
    vehicle: 'DEM000',
    id: 140105,
    tank_volume_start: 3007.300000000004,
    tank_volume_stop: 2961.600000000004,
  },
  {
    timestamp_start: '2022-01-01T21:12:15',
    timestamp_stop: '2022-01-01T21:12:21',
    quantity: 59.8,
    vehicle: 'DEM333',
    id: 140107,
    tank_volume_start: 2961.600000000004,
    tank_volume_stop: 2902.900000000004,
  },
  {
    timestamp_start: '2022-01-01T21:12:36',
    timestamp_stop: '2022-01-01T21:12:42',
    quantity: 58.0,
    vehicle: 'DEM666',
    id: 140109,
    tank_volume_start: 2902.900000000004,
    tank_volume_stop: 2847.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:12:57',
    timestamp_stop: '2022-01-01T21:13:03',
    quantity: 63.2,
    vehicle: 'DEM222',
    id: 140111,
    tank_volume_start: 2847.2000000000044,
    tank_volume_stop: 2791.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:13:18',
    timestamp_stop: '2022-01-01T21:13:24',
    quantity: 57.1,
    vehicle: 'DEM222',
    id: 140113,
    tank_volume_start: 2791.5000000000045,
    tank_volume_stop: 2737.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:13:39',
    timestamp_stop: '2022-01-01T21:13:44',
    quantity: 46.4,
    vehicle: 'DEM888',
    id: 140115,
    tank_volume_start: 2737.2000000000044,
    tank_volume_stop: 2679.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:13:59',
    timestamp_stop: '2022-01-01T21:14:04',
    quantity: 50.6,
    vehicle: 'DEM222',
    id: 140117,
    tank_volume_start: 2679.0000000000045,
    tank_volume_stop: 2619.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:14:19',
    timestamp_stop: '2022-01-01T21:14:24',
    quantity: 54.8,
    vehicle: 'DEM666',
    id: 140119,
    tank_volume_start: 2619.7000000000044,
    tank_volume_stop: 2568.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:14:39',
    timestamp_stop: '2022-01-01T21:14:45',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 140121,
    tank_volume_start: 2568.3000000000043,
    tank_volume_stop: 2511.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:15:00',
    timestamp_stop: '2022-01-01T21:15:05',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 140123,
    tank_volume_start: 2511.7000000000044,
    tank_volume_stop: 2458.900000000004,
  },
  {
    timestamp_start: '2022-01-01T21:15:20',
    timestamp_stop: '2022-01-01T21:15:26',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 140125,
    tank_volume_start: 2458.900000000004,
    tank_volume_stop: 2402.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:15:41',
    timestamp_stop: '2022-01-01T21:15:46',
    quantity: 55.0,
    vehicle: 'DEM666',
    id: 140127,
    tank_volume_start: 2402.3000000000043,
    tank_volume_stop: 2343.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:16:01',
    timestamp_stop: '2022-01-01T21:16:07',
    quantity: 57.6,
    vehicle: 'DEM777',
    id: 140129,
    tank_volume_start: 2343.3000000000043,
    tank_volume_stop: 2297.500000000004,
  },
  {
    timestamp_start: '2022-01-01T21:16:22',
    timestamp_stop: '2022-01-01T21:16:27',
    quantity: 50.8,
    vehicle: 'DEM444',
    id: 140131,
    tank_volume_start: 2297.500000000004,
    tank_volume_stop: 2241.200000000004,
  },
  {
    timestamp_start: '2022-01-01T21:16:42',
    timestamp_stop: '2022-01-01T21:16:47',
    quantity: 51.2,
    vehicle: 'DEM111',
    id: 140133,
    tank_volume_start: 2241.200000000004,
    tank_volume_stop: 2186.500000000004,
  },
  {
    timestamp_start: '2022-01-01T21:17:02',
    timestamp_stop: '2022-01-01T21:17:07',
    quantity: 52.0,
    vehicle: 'DEM777',
    id: 140135,
    tank_volume_start: 2186.500000000004,
    tank_volume_stop: 2135.500000000004,
  },
  {
    timestamp_start: '2022-01-01T21:17:22',
    timestamp_stop: '2022-01-01T21:17:27',
    quantity: 49.3,
    vehicle: 'DEM333',
    id: 140137,
    tank_volume_start: 2135.500000000004,
    tank_volume_stop: 2074.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:17:42',
    timestamp_stop: '2022-01-01T21:17:47',
    quantity: 49.4,
    vehicle: 'DEM555',
    id: 140139,
    tank_volume_start: 2074.8000000000043,
    tank_volume_stop: 2020.3000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:18:02',
    timestamp_stop: '2022-01-01T21:18:08',
    quantity: 51.5,
    vehicle: 'DEM000',
    id: 140141,
    tank_volume_start: 2020.3000000000043,
    tank_volume_stop: 1962.5000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:18:23',
    timestamp_stop: '2022-01-01T21:18:28',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 140143,
    tank_volume_start: 3962.5000000000045,
    tank_volume_stop: 3910.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:18:43',
    timestamp_stop: '2022-01-01T21:18:49',
    quantity: 56.1,
    vehicle: 'DEM777',
    id: 140145,
    tank_volume_start: 3910.0000000000045,
    tank_volume_stop: 3852.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:19:04',
    timestamp_stop: '2022-01-01T21:19:09',
    quantity: 54.1,
    vehicle: 'DEM000',
    id: 140147,
    tank_volume_start: 3852.3000000000047,
    tank_volume_stop: 3797.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:19:24',
    timestamp_stop: '2022-01-01T21:19:29',
    quantity: 47.9,
    vehicle: 'DEM555',
    id: 140149,
    tank_volume_start: 3797.3000000000047,
    tank_volume_stop: 3742.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:19:44',
    timestamp_stop: '2022-01-01T21:19:49',
    quantity: 54.7,
    vehicle: 'DEM666',
    id: 140151,
    tank_volume_start: 3742.8000000000047,
    tank_volume_stop: 3693.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:20:04',
    timestamp_stop: '2022-01-01T21:20:10',
    quantity: 51.4,
    vehicle: 'DEM555',
    id: 140153,
    tank_volume_start: 3693.0000000000045,
    tank_volume_stop: 3633.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:20:25',
    timestamp_stop: '2022-01-01T21:20:30',
    quantity: 56.3,
    vehicle: 'DEM000',
    id: 140155,
    tank_volume_start: 3633.9000000000046,
    tank_volume_stop: 3576.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:20:45',
    timestamp_stop: '2022-01-01T21:20:50',
    quantity: 51.0,
    vehicle: 'DEM888',
    id: 140157,
    tank_volume_start: 3576.1000000000045,
    tank_volume_stop: 3529.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:21:05',
    timestamp_stop: '2022-01-01T21:21:10',
    quantity: 50.4,
    vehicle: 'DEM000',
    id: 140159,
    tank_volume_start: 3529.2000000000044,
    tank_volume_stop: 3476.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:21:25',
    timestamp_stop: '2022-01-01T21:21:30',
    quantity: 51.0,
    vehicle: 'DEM777',
    id: 140161,
    tank_volume_start: 3476.6000000000045,
    tank_volume_stop: 3418.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:21:45',
    timestamp_stop: '2022-01-01T21:21:50',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 140162,
    tank_volume_start: 3418.2000000000044,
    tank_volume_stop: 3363.400000000004,
  },
  {
    timestamp_start: '2022-01-01T21:22:05',
    timestamp_stop: '2022-01-01T21:22:11',
    quantity: 56.7,
    vehicle: 'DEM888',
    id: 140164,
    tank_volume_start: 3363.400000000004,
    tank_volume_stop: 3305.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:22:26',
    timestamp_stop: '2022-01-01T21:22:32',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 140166,
    tank_volume_start: 3305.8000000000043,
    tank_volume_stop: 3246.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:22:47',
    timestamp_stop: '2022-01-01T21:22:52',
    quantity: 58.1,
    vehicle: 'DEM777',
    id: 140168,
    tank_volume_start: 3246.6000000000045,
    tank_volume_stop: 3184.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:23:07',
    timestamp_stop: '2022-01-01T21:23:12',
    quantity: 46.3,
    vehicle: 'DEM666',
    id: 140170,
    tank_volume_start: 3184.0000000000045,
    tank_volume_stop: 3136.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:23:27',
    timestamp_stop: '2022-01-01T21:23:32',
    quantity: 48.8,
    vehicle: 'DEM333',
    id: 140172,
    tank_volume_start: 3136.3000000000047,
    tank_volume_stop: 3076.700000000005,
  },
  {
    timestamp_start: '2022-01-01T21:23:47',
    timestamp_stop: '2022-01-01T21:23:52',
    quantity: 49.5,
    vehicle: 'DEM333',
    id: 140174,
    tank_volume_start: 3076.700000000005,
    tank_volume_stop: 3022.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:24:07',
    timestamp_stop: '2022-01-01T21:24:12',
    quantity: 54.2,
    vehicle: 'DEM111',
    id: 140176,
    tank_volume_start: 3022.8000000000047,
    tank_volume_stop: 2969.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:24:27',
    timestamp_stop: '2022-01-01T21:24:33',
    quantity: 53.3,
    vehicle: 'DEM222',
    id: 140178,
    tank_volume_start: 2969.3000000000047,
    tank_volume_stop: 2913.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:24:48',
    timestamp_stop: '2022-01-01T21:24:53',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 140180,
    tank_volume_start: 2913.5000000000045,
    tank_volume_stop: 2858.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:25:08',
    timestamp_stop: '2022-01-01T21:25:13',
    quantity: 54.1,
    vehicle: 'DEM888',
    id: 140182,
    tank_volume_start: 2858.4000000000046,
    tank_volume_stop: 2793.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:25:28',
    timestamp_stop: '2022-01-01T21:25:33',
    quantity: 52.5,
    vehicle: 'DEM222',
    id: 140184,
    tank_volume_start: 2793.8000000000047,
    tank_volume_stop: 2747.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:25:48',
    timestamp_stop: '2022-01-01T21:25:54',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 140186,
    tank_volume_start: 2747.0000000000045,
    tank_volume_stop: 2690.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:26:09',
    timestamp_stop: '2022-01-01T21:26:15',
    quantity: 59.1,
    vehicle: 'DEM111',
    id: 140188,
    tank_volume_start: 2690.0000000000045,
    tank_volume_stop: 2635.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:26:30',
    timestamp_stop: '2022-01-01T21:26:36',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 140190,
    tank_volume_start: 2635.4000000000046,
    tank_volume_stop: 2588.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:26:51',
    timestamp_stop: '2022-01-01T21:26:57',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 140192,
    tank_volume_start: 2588.3000000000047,
    tank_volume_stop: 2528.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:27:12',
    timestamp_stop: '2022-01-01T21:27:17',
    quantity: 55.1,
    vehicle: 'DEM888',
    id: 140194,
    tank_volume_start: 2528.200000000005,
    tank_volume_stop: 2473.700000000005,
  },
  {
    timestamp_start: '2022-01-01T21:27:32',
    timestamp_stop: '2022-01-01T21:27:38',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 140196,
    tank_volume_start: 2473.700000000005,
    tank_volume_stop: 2418.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:27:53',
    timestamp_stop: '2022-01-01T21:27:59',
    quantity: 58.2,
    vehicle: 'DEM555',
    id: 140198,
    tank_volume_start: 2418.8000000000047,
    tank_volume_stop: 2362.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:28:14',
    timestamp_stop: '2022-01-01T21:28:19',
    quantity: 50.8,
    vehicle: 'DEM333',
    id: 140200,
    tank_volume_start: 2362.8000000000047,
    tank_volume_stop: 2308.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:28:34',
    timestamp_stop: '2022-01-01T21:28:39',
    quantity: 50.2,
    vehicle: 'DEM777',
    id: 140202,
    tank_volume_start: 2308.0000000000045,
    tank_volume_stop: 2261.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:28:54',
    timestamp_stop: '2022-01-01T21:28:59',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 140204,
    tank_volume_start: 2261.3000000000047,
    tank_volume_stop: 2201.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:29:14',
    timestamp_stop: '2022-01-01T21:29:19',
    quantity: 50.2,
    vehicle: 'DEM555',
    id: 140206,
    tank_volume_start: 2201.9000000000046,
    tank_volume_stop: 2149.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:29:34',
    timestamp_stop: '2022-01-01T21:29:39',
    quantity: 52.8,
    vehicle: 'DEM333',
    id: 140208,
    tank_volume_start: 2149.5000000000045,
    tank_volume_stop: 2104.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:29:54',
    timestamp_stop: '2022-01-01T21:30:00',
    quantity: 52.7,
    vehicle: 'DEM555',
    id: 140210,
    tank_volume_start: 2104.4000000000046,
    tank_volume_stop: 2054.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:30:15',
    timestamp_stop: '2022-01-01T21:30:19',
    quantity: 49.0,
    vehicle: 'DEM000',
    id: 140212,
    tank_volume_start: 2054.6000000000045,
    tank_volume_stop: 1999.9000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:30:34',
    timestamp_stop: '2022-01-01T21:30:40',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 140214,
    tank_volume_start: 3999.900000000004,
    tank_volume_stop: 3938.000000000004,
  },
  {
    timestamp_start: '2022-01-01T21:30:55',
    timestamp_stop: '2022-01-01T21:31:01',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 140216,
    tank_volume_start: 3938.000000000004,
    tank_volume_stop: 3882.400000000004,
  },
  {
    timestamp_start: '2022-01-01T21:31:16',
    timestamp_stop: '2022-01-01T21:31:22',
    quantity: 61.0,
    vehicle: 'DEM111',
    id: 140218,
    tank_volume_start: 3882.400000000004,
    tank_volume_stop: 3827.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:31:37',
    timestamp_stop: '2022-01-01T21:31:42',
    quantity: 51.3,
    vehicle: 'DEM666',
    id: 140220,
    tank_volume_start: 3827.2000000000044,
    tank_volume_stop: 3778.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:31:57',
    timestamp_stop: '2022-01-01T21:32:03',
    quantity: 56.0,
    vehicle: 'DEM777',
    id: 140222,
    tank_volume_start: 3778.1000000000045,
    tank_volume_stop: 3715.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:32:18',
    timestamp_stop: '2022-01-01T21:32:23',
    quantity: 55.4,
    vehicle: 'DEM111',
    id: 140224,
    tank_volume_start: 3715.5000000000045,
    tank_volume_stop: 3655.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:32:38',
    timestamp_stop: '2022-01-01T21:32:44',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 140226,
    tank_volume_start: 3655.9000000000046,
    tank_volume_stop: 3601.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:32:59',
    timestamp_stop: '2022-01-01T21:33:04',
    quantity: 48.2,
    vehicle: 'DEM444',
    id: 140228,
    tank_volume_start: 3601.8000000000047,
    tank_volume_stop: 3552.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:33:19',
    timestamp_stop: '2022-01-01T21:33:24',
    quantity: 51.3,
    vehicle: 'DEM111',
    id: 140230,
    tank_volume_start: 3552.200000000005,
    tank_volume_stop: 3498.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:33:39',
    timestamp_stop: '2022-01-01T21:33:44',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 140232,
    tank_volume_start: 3498.9000000000046,
    tank_volume_stop: 3437.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:33:59',
    timestamp_stop: '2022-01-01T21:34:05',
    quantity: 52.3,
    vehicle: 'DEM111',
    id: 140234,
    tank_volume_start: 3437.4000000000046,
    tank_volume_stop: 3378.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:34:20',
    timestamp_stop: '2022-01-01T21:34:25',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 140236,
    tank_volume_start: 3378.9000000000046,
    tank_volume_stop: 3325.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:34:40',
    timestamp_stop: '2022-01-01T21:34:45',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 140238,
    tank_volume_start: 3325.0000000000045,
    tank_volume_stop: 3264.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:35:00',
    timestamp_stop: '2022-01-01T21:35:06',
    quantity: 55.6,
    vehicle: 'DEM333',
    id: 140240,
    tank_volume_start: 3264.0000000000045,
    tank_volume_stop: 3203.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:35:21',
    timestamp_stop: '2022-01-01T21:35:25',
    quantity: 45.6,
    vehicle: 'DEM111',
    id: 140242,
    tank_volume_start: 3203.2000000000044,
    tank_volume_stop: 3141.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:35:40',
    timestamp_stop: '2022-01-01T21:35:45',
    quantity: 52.9,
    vehicle: 'DEM666',
    id: 140244,
    tank_volume_start: 3141.1000000000045,
    tank_volume_stop: 3087.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:36:00',
    timestamp_stop: '2022-01-01T21:36:06',
    quantity: 53.7,
    vehicle: 'DEM777',
    id: 140246,
    tank_volume_start: 3087.5000000000045,
    tank_volume_stop: 3036.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:36:21',
    timestamp_stop: '2022-01-01T21:36:26',
    quantity: 50.8,
    vehicle: 'DEM555',
    id: 140248,
    tank_volume_start: 3036.0000000000045,
    tank_volume_stop: 2983.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:36:41',
    timestamp_stop: '2022-01-01T21:36:47',
    quantity: 60.6,
    vehicle: 'DEM888',
    id: 140250,
    tank_volume_start: 2983.9000000000046,
    tank_volume_stop: 2926.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:37:02',
    timestamp_stop: '2022-01-01T21:37:07',
    quantity: 53.5,
    vehicle: 'DEM333',
    id: 140252,
    tank_volume_start: 2926.200000000005,
    tank_volume_stop: 2867.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:37:22',
    timestamp_stop: '2022-01-01T21:37:28',
    quantity: 52.8,
    vehicle: 'DEM666',
    id: 140254,
    tank_volume_start: 2867.4000000000046,
    tank_volume_stop: 2818.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:37:43',
    timestamp_stop: '2022-01-01T21:37:48',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 140256,
    tank_volume_start: 2818.6000000000045,
    tank_volume_stop: 2770.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:38:03',
    timestamp_stop: '2022-01-01T21:38:08',
    quantity: 48.9,
    vehicle: 'DEM555',
    id: 140258,
    tank_volume_start: 2770.6000000000045,
    tank_volume_stop: 2707.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:38:23',
    timestamp_stop: '2022-01-01T21:38:28',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 140260,
    tank_volume_start: 2707.0000000000045,
    tank_volume_stop: 2654.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:38:43',
    timestamp_stop: '2022-01-01T21:38:49',
    quantity: 56.1,
    vehicle: 'DEM333',
    id: 140262,
    tank_volume_start: 2654.9000000000046,
    tank_volume_stop: 2595.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:39:04',
    timestamp_stop: '2022-01-01T21:39:10',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 140264,
    tank_volume_start: 2595.9000000000046,
    tank_volume_stop: 2534.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:39:25',
    timestamp_stop: '2022-01-01T21:39:30',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 140266,
    tank_volume_start: 2534.1000000000045,
    tank_volume_stop: 2478.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:39:45',
    timestamp_stop: '2022-01-01T21:39:50',
    quantity: 48.1,
    vehicle: 'DEM333',
    id: 140268,
    tank_volume_start: 2478.4000000000046,
    tank_volume_stop: 2429.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:40:05',
    timestamp_stop: '2022-01-01T21:40:12',
    quantity: 62.8,
    vehicle: 'DEM111',
    id: 140270,
    tank_volume_start: 2429.4000000000046,
    tank_volume_stop: 2379.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:40:27',
    timestamp_stop: '2022-01-01T21:40:32',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 140272,
    tank_volume_start: 2379.8000000000047,
    tank_volume_stop: 2316.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:40:47',
    timestamp_stop: '2022-01-01T21:40:53',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 140274,
    tank_volume_start: 2316.9000000000046,
    tank_volume_stop: 2264.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:41:08',
    timestamp_stop: '2022-01-01T21:41:13',
    quantity: 54.9,
    vehicle: 'DEM333',
    id: 140276,
    tank_volume_start: 2264.200000000005,
    tank_volume_stop: 2210.100000000005,
  },
  {
    timestamp_start: '2022-01-01T21:41:28',
    timestamp_stop: '2022-01-01T21:41:34',
    quantity: 54.6,
    vehicle: 'DEM333',
    id: 140278,
    tank_volume_start: 2210.100000000005,
    tank_volume_stop: 2155.000000000005,
  },
  {
    timestamp_start: '2022-01-01T21:41:49',
    timestamp_stop: '2022-01-01T21:41:55',
    quantity: 63.5,
    vehicle: 'DEM333',
    id: 140280,
    tank_volume_start: 2155.000000000005,
    tank_volume_stop: 2099.100000000005,
  },
  {
    timestamp_start: '2022-01-01T21:42:10',
    timestamp_stop: '2022-01-01T21:42:16',
    quantity: 62.4,
    vehicle: 'DEM333',
    id: 140282,
    tank_volume_start: 2099.100000000005,
    tank_volume_stop: 2044.100000000005,
  },
  {
    timestamp_start: '2022-01-01T21:42:31',
    timestamp_stop: '2022-01-01T21:42:37',
    quantity: 54.8,
    vehicle: 'DEM555',
    id: 140284,
    tank_volume_start: 2044.100000000005,
    tank_volume_stop: 1989.500000000005,
  },
  {
    timestamp_start: '2022-01-01T21:42:52',
    timestamp_stop: '2022-01-01T21:42:57',
    quantity: 54.6,
    vehicle: 'DEM888',
    id: 140286,
    tank_volume_start: 3989.500000000005,
    tank_volume_stop: 3933.500000000005,
  },
  {
    timestamp_start: '2022-01-01T21:43:12',
    timestamp_stop: '2022-01-01T21:43:18',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 140288,
    tank_volume_start: 3933.500000000005,
    tank_volume_stop: 3876.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:43:33',
    timestamp_stop: '2022-01-01T21:43:38',
    quantity: 47.4,
    vehicle: 'DEM333',
    id: 140290,
    tank_volume_start: 3876.200000000005,
    tank_volume_stop: 3819.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:43:53',
    timestamp_stop: '2022-01-01T21:43:58',
    quantity: 55.1,
    vehicle: 'DEM111',
    id: 140292,
    tank_volume_start: 3819.200000000005,
    tank_volume_stop: 3769.000000000005,
  },
  {
    timestamp_start: '2022-01-01T21:44:13',
    timestamp_stop: '2022-01-01T21:44:18',
    quantity: 49.3,
    vehicle: 'DEM888',
    id: 140294,
    tank_volume_start: 3769.000000000005,
    tank_volume_stop: 3716.500000000005,
  },
  {
    timestamp_start: '2022-01-01T21:44:33',
    timestamp_stop: '2022-01-01T21:44:39',
    quantity: 59.9,
    vehicle: 'DEM000',
    id: 140296,
    tank_volume_start: 3716.500000000005,
    tank_volume_stop: 3652.600000000005,
  },
  {
    timestamp_start: '2022-01-01T21:44:54',
    timestamp_stop: '2022-01-01T21:44:59',
    quantity: 49.0,
    vehicle: 'DEM444',
    id: 140298,
    tank_volume_start: 3652.600000000005,
    tank_volume_stop: 3597.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:45:14',
    timestamp_stop: '2022-01-01T21:45:19',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 140300,
    tank_volume_start: 3597.8000000000047,
    tank_volume_stop: 3544.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:45:34',
    timestamp_stop: '2022-01-01T21:45:40',
    quantity: 59.6,
    vehicle: 'DEM888',
    id: 140302,
    tank_volume_start: 3544.4000000000046,
    tank_volume_stop: 3491.200000000005,
  },
  {
    timestamp_start: '2022-01-01T21:45:55',
    timestamp_stop: '2022-01-01T21:46:00',
    quantity: 48.3,
    vehicle: 'DEM333',
    id: 140304,
    tank_volume_start: 3491.200000000005,
    tank_volume_stop: 3439.500000000005,
  },
  {
    timestamp_start: '2022-01-01T21:46:15',
    timestamp_stop: '2022-01-01T21:46:20',
    quantity: 57.7,
    vehicle: 'DEM444',
    id: 140306,
    tank_volume_start: 3439.500000000005,
    tank_volume_stop: 3380.600000000005,
  },
  {
    timestamp_start: '2022-01-01T21:46:35',
    timestamp_stop: '2022-01-01T21:46:41',
    quantity: 56.3,
    vehicle: 'DEM333',
    id: 140308,
    tank_volume_start: 3380.600000000005,
    tank_volume_stop: 3326.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:46:56',
    timestamp_stop: '2022-01-01T21:47:02',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 140310,
    tank_volume_start: 3326.8000000000047,
    tank_volume_stop: 3273.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:47:17',
    timestamp_stop: '2022-01-01T21:47:22',
    quantity: 51.2,
    vehicle: 'DEM333',
    id: 140312,
    tank_volume_start: 3273.4000000000046,
    tank_volume_stop: 3225.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:47:37',
    timestamp_stop: '2022-01-01T21:47:42',
    quantity: 57.9,
    vehicle: 'DEM000',
    id: 140314,
    tank_volume_start: 3225.6000000000045,
    tank_volume_stop: 3167.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:47:57',
    timestamp_stop: '2022-01-01T21:48:04',
    quantity: 63.5,
    vehicle: 'DEM555',
    id: 140316,
    tank_volume_start: 3167.9000000000046,
    tank_volume_stop: 3110.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:48:19',
    timestamp_stop: '2022-01-01T21:48:25',
    quantity: 58.2,
    vehicle: 'DEM666',
    id: 140318,
    tank_volume_start: 3110.4000000000046,
    tank_volume_stop: 3060.700000000005,
  },
  {
    timestamp_start: '2022-01-01T21:48:40',
    timestamp_stop: '2022-01-01T21:48:45',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 140320,
    tank_volume_start: 3060.700000000005,
    tank_volume_stop: 3005.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:49:00',
    timestamp_stop: '2022-01-01T21:49:05',
    quantity: 56.8,
    vehicle: 'DEM111',
    id: 140322,
    tank_volume_start: 3005.8000000000047,
    tank_volume_stop: 2944.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:49:20',
    timestamp_stop: '2022-01-01T21:49:26',
    quantity: 59.5,
    vehicle: 'DEM666',
    id: 140324,
    tank_volume_start: 2944.4000000000046,
    tank_volume_stop: 2887.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:49:41',
    timestamp_stop: '2022-01-01T21:49:47',
    quantity: 55.3,
    vehicle: 'DEM444',
    id: 140326,
    tank_volume_start: 2887.3000000000047,
    tank_volume_stop: 2827.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:50:02',
    timestamp_stop: '2022-01-01T21:50:08',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 140328,
    tank_volume_start: 2827.3000000000047,
    tank_volume_stop: 2772.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:50:23',
    timestamp_stop: '2022-01-01T21:50:28',
    quantity: 52.9,
    vehicle: 'DEM333',
    id: 140330,
    tank_volume_start: 2772.0000000000045,
    tank_volume_stop: 2722.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:50:43',
    timestamp_stop: '2022-01-01T21:50:48',
    quantity: 54.6,
    vehicle: 'DEM444',
    id: 140332,
    tank_volume_start: 2722.3000000000047,
    tank_volume_stop: 2666.600000000005,
  },
  {
    timestamp_start: '2022-01-01T21:51:03',
    timestamp_stop: '2022-01-01T21:51:08',
    quantity: 47.7,
    vehicle: 'DEM222',
    id: 140334,
    tank_volume_start: 2666.600000000005,
    tank_volume_stop: 2609.700000000005,
  },
  {
    timestamp_start: '2022-01-01T21:51:23',
    timestamp_stop: '2022-01-01T21:51:29',
    quantity: 62.2,
    vehicle: 'DEM888',
    id: 140336,
    tank_volume_start: 2609.700000000005,
    tank_volume_stop: 2551.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T21:51:44',
    timestamp_stop: '2022-01-01T21:51:49',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 140338,
    tank_volume_start: 2551.9000000000046,
    tank_volume_stop: 2489.1000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:52:04',
    timestamp_stop: '2022-01-01T21:52:11',
    quantity: 63.2,
    vehicle: 'DEM888',
    id: 140340,
    tank_volume_start: 2489.1000000000045,
    tank_volume_stop: 2430.7000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:52:26',
    timestamp_stop: '2022-01-01T21:52:31',
    quantity: 47.8,
    vehicle: 'DEM222',
    id: 140342,
    tank_volume_start: 2430.7000000000044,
    tank_volume_stop: 2373.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:52:46',
    timestamp_stop: '2022-01-01T21:52:52',
    quantity: 63.3,
    vehicle: 'DEM333',
    id: 140344,
    tank_volume_start: 2373.5000000000045,
    tank_volume_stop: 2312.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:53:07',
    timestamp_stop: '2022-01-01T21:53:13',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 140346,
    tank_volume_start: 2312.2000000000044,
    tank_volume_stop: 2249.400000000004,
  },
  {
    timestamp_start: '2022-01-01T21:53:28',
    timestamp_stop: '2022-01-01T21:53:34',
    quantity: 62.2,
    vehicle: 'DEM222',
    id: 140348,
    tank_volume_start: 2249.400000000004,
    tank_volume_stop: 2196.100000000004,
  },
  {
    timestamp_start: '2022-01-01T21:53:49',
    timestamp_stop: '2022-01-01T21:53:54',
    quantity: 55.9,
    vehicle: 'DEM111',
    id: 140350,
    tank_volume_start: 2196.100000000004,
    tank_volume_stop: 2143.800000000004,
  },
  {
    timestamp_start: '2022-01-01T21:54:09',
    timestamp_stop: '2022-01-01T21:54:15',
    quantity: 58.4,
    vehicle: 'DEM000',
    id: 140352,
    tank_volume_start: 2143.800000000004,
    tank_volume_stop: 2092.600000000004,
  },
  {
    timestamp_start: '2022-01-01T21:54:30',
    timestamp_stop: '2022-01-01T21:54:36',
    quantity: 59.4,
    vehicle: 'DEM777',
    id: 140354,
    tank_volume_start: 2092.600000000004,
    tank_volume_stop: 2033.500000000004,
  },
  {
    timestamp_start: '2022-01-01T21:54:51',
    timestamp_stop: '2022-01-01T21:54:56',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 140356,
    tank_volume_start: 2033.500000000004,
    tank_volume_stop: 1983.500000000004,
  },
  {
    timestamp_start: '2022-01-01T21:55:11',
    timestamp_stop: '2022-01-01T21:55:16',
    quantity: 50.4,
    vehicle: 'DEM666',
    id: 140358,
    tank_volume_start: 3983.500000000004,
    tank_volume_stop: 3931.000000000004,
  },
  {
    timestamp_start: '2022-01-01T21:55:31',
    timestamp_stop: '2022-01-01T21:55:37',
    quantity: 57.3,
    vehicle: 'DEM111',
    id: 140360,
    tank_volume_start: 3931.000000000004,
    tank_volume_stop: 3874.900000000004,
  },
  {
    timestamp_start: '2022-01-01T21:55:52',
    timestamp_stop: '2022-01-01T21:55:58',
    quantity: 56.2,
    vehicle: 'DEM111',
    id: 140362,
    tank_volume_start: 3874.900000000004,
    tank_volume_stop: 3814.8000000000043,
  },
  {
    timestamp_start: '2022-01-01T21:56:13',
    timestamp_stop: '2022-01-01T21:56:18',
    quantity: 50.0,
    vehicle: 'DEM777',
    id: 140364,
    tank_volume_start: 3814.8000000000043,
    tank_volume_stop: 3761.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:56:33',
    timestamp_stop: '2022-01-01T21:56:39',
    quantity: 64.9,
    vehicle: 'DEM444',
    id: 140366,
    tank_volume_start: 3761.2000000000044,
    tank_volume_stop: 3711.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:56:54',
    timestamp_stop: '2022-01-01T21:57:00',
    quantity: 56.7,
    vehicle: 'DEM444',
    id: 140368,
    tank_volume_start: 3711.0000000000045,
    tank_volume_stop: 3657.2000000000044,
  },
  {
    timestamp_start: '2022-01-01T21:57:15',
    timestamp_stop: '2022-01-01T21:57:21',
    quantity: 57.8,
    vehicle: 'DEM444',
    id: 140370,
    tank_volume_start: 3657.2000000000044,
    tank_volume_stop: 3599.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:57:36',
    timestamp_stop: '2022-01-01T21:57:41',
    quantity: 57.8,
    vehicle: 'DEM666',
    id: 140372,
    tank_volume_start: 3599.5000000000045,
    tank_volume_stop: 3552.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:57:56',
    timestamp_stop: '2022-01-01T21:58:02',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 140374,
    tank_volume_start: 3552.0000000000045,
    tank_volume_stop: 3498.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:58:17',
    timestamp_stop: '2022-01-01T21:58:22',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 140376,
    tank_volume_start: 3498.8000000000047,
    tank_volume_stop: 3440.700000000005,
  },
  {
    timestamp_start: '2022-01-01T21:58:37',
    timestamp_stop: '2022-01-01T21:58:43',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 140378,
    tank_volume_start: 3440.700000000005,
    tank_volume_stop: 3391.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T21:58:58',
    timestamp_stop: '2022-01-01T21:59:04',
    quantity: 54.7,
    vehicle: 'DEM555',
    id: 140380,
    tank_volume_start: 3391.3000000000047,
    tank_volume_stop: 3335.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:59:19',
    timestamp_stop: '2022-01-01T21:59:24',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 140382,
    tank_volume_start: 3335.5000000000045,
    tank_volume_stop: 3274.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T21:59:39',
    timestamp_stop: '2022-01-01T21:59:45',
    quantity: 57.7,
    vehicle: 'DEM888',
    id: 140384,
    tank_volume_start: 3274.0000000000045,
    tank_volume_stop: 3214.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T22:00:00',
    timestamp_stop: '2022-01-01T22:00:06',
    quantity: 54.6,
    vehicle: 'DEM555',
    id: 140386,
    tank_volume_start: 3214.0000000000045,
    tank_volume_stop: 3164.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T22:00:21',
    timestamp_stop: '2022-01-01T22:00:26',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 140388,
    tank_volume_start: 3164.0000000000045,
    tank_volume_stop: 3107.5000000000045,
  },
  {
    timestamp_start: '2022-01-01T22:00:41',
    timestamp_stop: '2022-01-01T22:00:46',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 140390,
    tank_volume_start: 3107.5000000000045,
    tank_volume_stop: 3059.6000000000045,
  },
  {
    timestamp_start: '2022-01-01T22:01:01',
    timestamp_stop: '2022-01-01T22:01:06',
    quantity: 58.1,
    vehicle: 'DEM888',
    id: 140392,
    tank_volume_start: 3059.6000000000045,
    tank_volume_stop: 3000.4000000000046,
  },
  {
    timestamp_start: '2022-01-01T22:01:21',
    timestamp_stop: '2022-01-01T22:01:27',
    quantity: 57.9,
    vehicle: 'DEM222',
    id: 140394,
    tank_volume_start: 3000.4000000000046,
    tank_volume_stop: 2944.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T22:01:42',
    timestamp_stop: '2022-01-01T22:01:48',
    quantity: 54.3,
    vehicle: 'DEM555',
    id: 140396,
    tank_volume_start: 2944.3000000000047,
    tank_volume_stop: 2880.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:02:03',
    timestamp_stop: '2022-01-01T22:02:08',
    quantity: 50.5,
    vehicle: 'DEM000',
    id: 140398,
    tank_volume_start: 2880.600000000005,
    tank_volume_stop: 2831.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T22:02:23',
    timestamp_stop: '2022-01-01T22:02:29',
    quantity: 62.7,
    vehicle: 'DEM555',
    id: 140400,
    tank_volume_start: 2831.3000000000047,
    tank_volume_stop: 2773.0000000000045,
  },
  {
    timestamp_start: '2022-01-01T22:02:44',
    timestamp_stop: '2022-01-01T22:02:50',
    quantity: 61.1,
    vehicle: 'DEM222',
    id: 140402,
    tank_volume_start: 2773.0000000000045,
    tank_volume_stop: 2714.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T22:03:05',
    timestamp_stop: '2022-01-01T22:03:11',
    quantity: 57.9,
    vehicle: 'DEM666',
    id: 140404,
    tank_volume_start: 2714.9000000000046,
    tank_volume_stop: 2652.700000000005,
  },
  {
    timestamp_start: '2022-01-01T22:03:26',
    timestamp_stop: '2022-01-01T22:03:32',
    quantity: 56.5,
    vehicle: 'DEM555',
    id: 140406,
    tank_volume_start: 2652.700000000005,
    tank_volume_stop: 2597.000000000005,
  },
  {
    timestamp_start: '2022-01-01T22:03:47',
    timestamp_stop: '2022-01-01T22:03:51',
    quantity: 48.8,
    vehicle: 'DEM333',
    id: 140408,
    tank_volume_start: 2597.000000000005,
    tank_volume_stop: 2538.000000000005,
  },
  {
    timestamp_start: '2022-01-01T22:04:06',
    timestamp_stop: '2022-01-01T22:04:12',
    quantity: 60.0,
    vehicle: 'DEM111',
    id: 140410,
    tank_volume_start: 2538.000000000005,
    tank_volume_stop: 2485.200000000005,
  },
  {
    timestamp_start: '2022-01-01T22:04:27',
    timestamp_stop: '2022-01-01T22:04:32',
    quantity: 47.6,
    vehicle: 'DEM666',
    id: 140412,
    tank_volume_start: 2485.200000000005,
    tank_volume_stop: 2434.8000000000047,
  },
  {
    timestamp_start: '2022-01-01T22:04:47',
    timestamp_stop: '2022-01-01T22:04:53',
    quantity: 60.8,
    vehicle: 'DEM333',
    id: 140414,
    tank_volume_start: 2434.8000000000047,
    tank_volume_stop: 2376.9000000000046,
  },
  {
    timestamp_start: '2022-01-01T22:05:08',
    timestamp_stop: '2022-01-01T22:05:13',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 140416,
    tank_volume_start: 2376.9000000000046,
    tank_volume_stop: 2328.700000000005,
  },
  {
    timestamp_start: '2022-01-01T22:05:28',
    timestamp_stop: '2022-01-01T22:05:34',
    quantity: 57.8,
    vehicle: 'DEM111',
    id: 140418,
    tank_volume_start: 2328.700000000005,
    tank_volume_stop: 2269.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:05:49',
    timestamp_stop: '2022-01-01T22:05:55',
    quantity: 59.4,
    vehicle: 'DEM333',
    id: 140420,
    tank_volume_start: 2269.500000000005,
    tank_volume_stop: 2216.200000000005,
  },
  {
    timestamp_start: '2022-01-01T22:06:10',
    timestamp_stop: '2022-01-01T22:06:16',
    quantity: 58.9,
    vehicle: 'DEM777',
    id: 140422,
    tank_volume_start: 2216.200000000005,
    tank_volume_stop: 2158.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:06:31',
    timestamp_stop: '2022-01-01T22:06:37',
    quantity: 63.4,
    vehicle: 'DEM888',
    id: 140424,
    tank_volume_start: 2158.600000000005,
    tank_volume_stop: 2095.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:06:52',
    timestamp_stop: '2022-01-01T22:06:58',
    quantity: 59.6,
    vehicle: 'DEM555',
    id: 140426,
    tank_volume_start: 2095.500000000005,
    tank_volume_stop: 2043.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:07:13',
    timestamp_stop: '2022-01-01T22:07:19',
    quantity: 53.3,
    vehicle: 'DEM888',
    id: 140428,
    tank_volume_start: 2043.500000000005,
    tank_volume_stop: 1989.400000000005,
  },
  {
    timestamp_start: '2022-01-01T22:07:34',
    timestamp_stop: '2022-01-01T22:07:40',
    quantity: 58.5,
    vehicle: 'DEM666',
    id: 140430,
    tank_volume_start: 3989.400000000005,
    tank_volume_stop: 3941.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T22:07:55',
    timestamp_stop: '2022-01-01T22:08:00',
    quantity: 56.4,
    vehicle: 'DEM111',
    id: 140432,
    tank_volume_start: 3941.7000000000053,
    tank_volume_stop: 3888.400000000005,
  },
  {
    timestamp_start: '2022-01-01T22:08:15',
    timestamp_stop: '2022-01-01T22:08:20',
    quantity: 50.2,
    vehicle: 'DEM111',
    id: 140434,
    tank_volume_start: 3888.400000000005,
    tank_volume_stop: 3827.000000000005,
  },
  {
    timestamp_start: '2022-01-01T22:08:35',
    timestamp_stop: '2022-01-01T22:08:41',
    quantity: 54.1,
    vehicle: 'DEM222',
    id: 140436,
    tank_volume_start: 3827.000000000005,
    tank_volume_stop: 3771.100000000005,
  },
  {
    timestamp_start: '2022-01-01T22:08:56',
    timestamp_stop: '2022-01-01T22:09:02',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 140438,
    tank_volume_start: 3771.100000000005,
    tank_volume_stop: 3713.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:09:17',
    timestamp_stop: '2022-01-01T22:09:23',
    quantity: 57.1,
    vehicle: 'DEM555',
    id: 140440,
    tank_volume_start: 3713.500000000005,
    tank_volume_stop: 3664.200000000005,
  },
  {
    timestamp_start: '2022-01-01T22:09:38',
    timestamp_stop: '2022-01-01T22:09:43',
    quantity: 59.2,
    vehicle: 'DEM333',
    id: 140442,
    tank_volume_start: 3664.200000000005,
    tank_volume_stop: 3604.3000000000047,
  },
  {
    timestamp_start: '2022-01-01T22:09:58',
    timestamp_stop: '2022-01-01T22:10:04',
    quantity: 50.4,
    vehicle: 'DEM555',
    id: 140444,
    tank_volume_start: 3604.3000000000047,
    tank_volume_stop: 3550.200000000005,
  },
  {
    timestamp_start: '2022-01-01T22:10:19',
    timestamp_stop: '2022-01-01T22:10:24',
    quantity: 52.1,
    vehicle: 'DEM555',
    id: 140446,
    tank_volume_start: 3550.200000000005,
    tank_volume_stop: 3493.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:10:39',
    timestamp_stop: '2022-01-01T22:10:44',
    quantity: 49.8,
    vehicle: 'DEM111',
    id: 140448,
    tank_volume_start: 3493.600000000005,
    tank_volume_stop: 3442.700000000005,
  },
  {
    timestamp_start: '2022-01-01T22:10:59',
    timestamp_stop: '2022-01-01T22:11:04',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 140450,
    tank_volume_start: 3442.700000000005,
    tank_volume_stop: 3392.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:11:19',
    timestamp_stop: '2022-01-01T22:11:25',
    quantity: 54.7,
    vehicle: 'DEM444',
    id: 140452,
    tank_volume_start: 3392.500000000005,
    tank_volume_stop: 3339.800000000005,
  },
  {
    timestamp_start: '2022-01-01T22:11:40',
    timestamp_stop: '2022-01-01T22:11:46',
    quantity: 57.5,
    vehicle: 'DEM777',
    id: 140454,
    tank_volume_start: 3339.800000000005,
    tank_volume_stop: 3284.300000000005,
  },
  {
    timestamp_start: '2022-01-01T22:12:01',
    timestamp_stop: '2022-01-01T22:12:07',
    quantity: 62.1,
    vehicle: 'DEM111',
    id: 140456,
    tank_volume_start: 3284.300000000005,
    tank_volume_stop: 3227.900000000005,
  },
  {
    timestamp_start: '2022-01-01T22:12:22',
    timestamp_stop: '2022-01-01T22:12:28',
    quantity: 56.6,
    vehicle: 'DEM333',
    id: 140458,
    tank_volume_start: 3227.900000000005,
    tank_volume_stop: 3173.400000000005,
  },
  {
    timestamp_start: '2022-01-01T22:12:43',
    timestamp_stop: '2022-01-01T22:12:48',
    quantity: 52.4,
    vehicle: 'DEM666',
    id: 140460,
    tank_volume_start: 3173.400000000005,
    tank_volume_stop: 3116.000000000005,
  },
  {
    timestamp_start: '2022-01-01T22:13:03',
    timestamp_stop: '2022-01-01T22:13:08',
    quantity: 48.5,
    vehicle: 'DEM555',
    id: 140462,
    tank_volume_start: 3116.000000000005,
    tank_volume_stop: 3063.100000000005,
  },
  {
    timestamp_start: '2022-01-01T22:13:23',
    timestamp_stop: '2022-01-01T22:13:29',
    quantity: 59.0,
    vehicle: 'DEM666',
    id: 140464,
    tank_volume_start: 3063.100000000005,
    tank_volume_stop: 3010.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:13:44',
    timestamp_stop: '2022-01-01T22:13:49',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 140466,
    tank_volume_start: 3010.600000000005,
    tank_volume_stop: 2956.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:14:04',
    timestamp_stop: '2022-01-01T22:14:09',
    quantity: 53.1,
    vehicle: 'DEM444',
    id: 140468,
    tank_volume_start: 2956.600000000005,
    tank_volume_stop: 2904.500000000005,
  },
  {
    timestamp_start: '2022-01-01T22:14:24',
    timestamp_stop: '2022-01-01T22:14:30',
    quantity: 60.4,
    vehicle: 'DEM555',
    id: 140470,
    tank_volume_start: 2904.500000000005,
    tank_volume_stop: 2857.600000000005,
  },
  {
    timestamp_start: '2022-01-01T22:14:45',
    timestamp_stop: '2022-01-01T22:14:51',
    quantity: 54.3,
    vehicle: 'DEM444',
    id: 140472,
    tank_volume_start: 2857.600000000005,
    tank_volume_stop: 2805.400000000005,
  },
  {
    timestamp_start: '2022-01-01T22:15:06',
    timestamp_stop: '2022-01-01T22:15:11',
    quantity: 51.0,
    vehicle: 'DEM666',
    id: 140474,
    tank_volume_start: 2805.400000000005,
    tank_volume_stop: 2750.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T22:15:26',
    timestamp_stop: '2022-01-01T22:15:32',
    quantity: 57.7,
    vehicle: 'DEM555',
    id: 140476,
    tank_volume_start: 2750.7000000000053,
    tank_volume_stop: 2694.7000000000053,
  },
  {
    timestamp_start: '2022-01-01T22:15:47',
    timestamp_stop: '2022-01-01T22:15:53',
    quantity: 60.5,
    vehicle: 'DEM777',
    id: 140478,
    tank_volume_start: 2694.7000000000053,
    tank_volume_stop: 2639.800000000005,
  },
  {
    timestamp_start: '2022-01-01T22:16:08',
    timestamp_stop: '2022-01-01T22:16:12',
    quantity: 45.6,
    vehicle: 'DEM111',
    id: 140481,
    tank_volume_start: 2639.800000000005,
    tank_volume_stop: 2585.2000000000053,
  },
  {
    timestamp_start: '2022-01-01T22:16:27',
    timestamp_stop: '2022-01-01T22:16:33',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 140482,
    tank_volume_start: 2585.2000000000053,
    tank_volume_stop: 2529.5000000000055,
  },
  {
    timestamp_start: '2022-01-01T22:16:48',
    timestamp_stop: '2022-01-01T22:16:54',
    quantity: 55.2,
    vehicle: 'DEM333',
    id: 140484,
    tank_volume_start: 2529.5000000000055,
    tank_volume_stop: 2476.4000000000055,
  },
  {
    timestamp_start: '2022-01-01T22:17:09',
    timestamp_stop: '2022-01-01T22:17:13',
    quantity: 47.0,
    vehicle: 'DEM888',
    id: 140486,
    tank_volume_start: 2476.4000000000055,
    tank_volume_stop: 2418.7000000000057,
  },
  {
    timestamp_start: '2022-01-01T22:17:28',
    timestamp_stop: '2022-01-01T22:17:34',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 140488,
    tank_volume_start: 2418.7000000000057,
    tank_volume_stop: 2359.100000000006,
  },
  {
    timestamp_start: '2022-01-01T22:17:49',
    timestamp_stop: '2022-01-01T22:17:55',
    quantity: 59.7,
    vehicle: 'DEM555',
    id: 140490,
    tank_volume_start: 2359.100000000006,
    tank_volume_stop: 2295.2000000000057,
  },
  {
    timestamp_start: '2022-01-01T22:18:10',
    timestamp_stop: '2022-01-01T22:18:16',
    quantity: 57.6,
    vehicle: 'DEM333',
    id: 140492,
    tank_volume_start: 2295.2000000000057,
    tank_volume_stop: 2248.500000000006,
  },
  {
    timestamp_start: '2022-01-01T22:18:31',
    timestamp_stop: '2022-01-01T22:18:36',
    quantity: 56.5,
    vehicle: 'DEM444',
    id: 140494,
    tank_volume_start: 2248.500000000006,
    tank_volume_stop: 2187.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:18:51',
    timestamp_stop: '2022-01-01T22:18:56',
    quantity: 49.1,
    vehicle: 'DEM777',
    id: 140496,
    tank_volume_start: 2187.800000000006,
    tank_volume_stop: 2130.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:19:11',
    timestamp_stop: '2022-01-01T22:19:17',
    quantity: 53.1,
    vehicle: 'DEM666',
    id: 140498,
    tank_volume_start: 2130.800000000006,
    tank_volume_stop: 2072.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:19:32',
    timestamp_stop: '2022-01-01T22:19:37',
    quantity: 53.4,
    vehicle: 'DEM444',
    id: 140500,
    tank_volume_start: 2072.6000000000063,
    tank_volume_stop: 2024.3000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:19:52',
    timestamp_stop: '2022-01-01T22:19:58',
    quantity: 60.9,
    vehicle: 'DEM555',
    id: 140502,
    tank_volume_start: 2024.3000000000063,
    tank_volume_stop: 1970.2000000000064,
  },
  {
    timestamp_start: '2022-01-01T22:20:13',
    timestamp_stop: '2022-01-01T22:20:19',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 140504,
    tank_volume_start: 3970.200000000006,
    tank_volume_stop: 3914.300000000006,
  },
  {
    timestamp_start: '2022-01-01T22:20:34',
    timestamp_stop: '2022-01-01T22:20:39',
    quantity: 50.7,
    vehicle: 'DEM333',
    id: 140506,
    tank_volume_start: 3914.300000000006,
    tank_volume_stop: 3854.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:20:54',
    timestamp_stop: '2022-01-01T22:21:00',
    quantity: 60.2,
    vehicle: 'DEM000',
    id: 140508,
    tank_volume_start: 3854.800000000006,
    tank_volume_stop: 3806.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:21:15',
    timestamp_stop: '2022-01-01T22:21:20',
    quantity: 52.2,
    vehicle: 'DEM000',
    id: 140510,
    tank_volume_start: 3806.1000000000063,
    tank_volume_stop: 3748.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:21:35',
    timestamp_stop: '2022-01-01T22:21:41',
    quantity: 55.7,
    vehicle: 'DEM444',
    id: 140512,
    tank_volume_start: 3748.800000000006,
    tank_volume_stop: 3689.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:21:56',
    timestamp_stop: '2022-01-01T22:22:01',
    quantity: 49.5,
    vehicle: 'DEM888',
    id: 140514,
    tank_volume_start: 3689.1000000000063,
    tank_volume_stop: 3637.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:22:16',
    timestamp_stop: '2022-01-01T22:22:21',
    quantity: 47.7,
    vehicle: 'DEM555',
    id: 140516,
    tank_volume_start: 3637.9000000000065,
    tank_volume_stop: 3577.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:22:36',
    timestamp_stop: '2022-01-01T22:22:41',
    quantity: 55.9,
    vehicle: 'DEM111',
    id: 140518,
    tank_volume_start: 3577.8000000000065,
    tank_volume_stop: 3529.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:22:56',
    timestamp_stop: '2022-01-01T22:23:02',
    quantity: 60.4,
    vehicle: 'DEM444',
    id: 140520,
    tank_volume_start: 3529.7000000000066,
    tank_volume_stop: 3475.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:23:17',
    timestamp_stop: '2022-01-01T22:23:23',
    quantity: 58.8,
    vehicle: 'DEM888',
    id: 140522,
    tank_volume_start: 3475.9000000000065,
    tank_volume_stop: 3421.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:23:38',
    timestamp_stop: '2022-01-01T22:23:44',
    quantity: 56.3,
    vehicle: 'DEM777',
    id: 140524,
    tank_volume_start: 3421.1000000000063,
    tank_volume_stop: 3366.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:23:59',
    timestamp_stop: '2022-01-01T22:24:04',
    quantity: 52.7,
    vehicle: 'DEM666',
    id: 140526,
    tank_volume_start: 3366.800000000006,
    tank_volume_stop: 3304.700000000006,
  },
  {
    timestamp_start: '2022-01-01T22:24:19',
    timestamp_stop: '2022-01-01T22:24:25',
    quantity: 59.1,
    vehicle: 'DEM666',
    id: 140528,
    tank_volume_start: 3304.700000000006,
    tank_volume_stop: 3245.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:24:40',
    timestamp_stop: '2022-01-01T22:24:45',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 140530,
    tank_volume_start: 3245.1000000000063,
    tank_volume_stop: 3191.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T22:25:00',
    timestamp_stop: '2022-01-01T22:25:06',
    quantity: 51.4,
    vehicle: 'DEM111',
    id: 140532,
    tank_volume_start: 3191.0000000000064,
    tank_volume_stop: 3132.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T22:25:21',
    timestamp_stop: '2022-01-01T22:25:25',
    quantity: 47.3,
    vehicle: 'DEM666',
    id: 140534,
    tank_volume_start: 3132.5000000000064,
    tank_volume_stop: 3078.5000000000064,
  },
  {
    timestamp_start: '2022-01-01T22:25:40',
    timestamp_stop: '2022-01-01T22:25:46',
    quantity: 56.4,
    vehicle: 'DEM333',
    id: 140536,
    tank_volume_start: 3078.5000000000064,
    tank_volume_stop: 3014.0000000000064,
  },
  {
    timestamp_start: '2022-01-01T22:26:01',
    timestamp_stop: '2022-01-01T22:26:06',
    quantity: 50.6,
    vehicle: 'DEM111',
    id: 140538,
    tank_volume_start: 3014.0000000000064,
    tank_volume_stop: 2959.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:26:21',
    timestamp_stop: '2022-01-01T22:26:28',
    quantity: 65.0,
    vehicle: 'DEM777',
    id: 140540,
    tank_volume_start: 2959.9000000000065,
    tank_volume_stop: 2897.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:26:43',
    timestamp_stop: '2022-01-01T22:26:48',
    quantity: 50.1,
    vehicle: 'DEM888',
    id: 140542,
    tank_volume_start: 2897.2000000000066,
    tank_volume_stop: 2834.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T22:27:03',
    timestamp_stop: '2022-01-01T22:27:08',
    quantity: 54.3,
    vehicle: 'DEM222',
    id: 140544,
    tank_volume_start: 2834.1000000000067,
    tank_volume_stop: 2775.900000000007,
  },
  {
    timestamp_start: '2022-01-01T22:27:23',
    timestamp_stop: '2022-01-01T22:27:29',
    quantity: 56.6,
    vehicle: 'DEM777',
    id: 140546,
    tank_volume_start: 2775.900000000007,
    tank_volume_stop: 2725.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:27:44',
    timestamp_stop: '2022-01-01T22:27:50',
    quantity: 60.9,
    vehicle: 'DEM222',
    id: 140548,
    tank_volume_start: 2725.700000000007,
    tank_volume_stop: 2673.100000000007,
  },
  {
    timestamp_start: '2022-01-01T22:28:05',
    timestamp_stop: '2022-01-01T22:28:10',
    quantity: 53.5,
    vehicle: 'DEM111',
    id: 140550,
    tank_volume_start: 2673.100000000007,
    tank_volume_stop: 2609.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:28:25',
    timestamp_stop: '2022-01-01T22:28:31',
    quantity: 57.6,
    vehicle: 'DEM111',
    id: 140552,
    tank_volume_start: 2609.0000000000073,
    tank_volume_stop: 2547.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T22:28:46',
    timestamp_stop: '2022-01-01T22:28:51',
    quantity: 50.0,
    vehicle: 'DEM333',
    id: 140554,
    tank_volume_start: 2547.9000000000074,
    tank_volume_stop: 2492.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:29:06',
    timestamp_stop: '2022-01-01T22:29:10',
    quantity: 45.7,
    vehicle: 'DEM777',
    id: 140556,
    tank_volume_start: 2492.2000000000075,
    tank_volume_stop: 2445.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T22:29:25',
    timestamp_stop: '2022-01-01T22:29:30',
    quantity: 50.3,
    vehicle: 'DEM777',
    id: 140558,
    tank_volume_start: 2445.6000000000076,
    tank_volume_stop: 2386.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T22:29:45',
    timestamp_stop: '2022-01-01T22:29:50',
    quantity: 47.8,
    vehicle: 'DEM333',
    id: 140560,
    tank_volume_start: 2386.0000000000077,
    tank_volume_stop: 2331.300000000008,
  },
  {
    timestamp_start: '2022-01-01T22:30:05',
    timestamp_stop: '2022-01-01T22:30:10',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 140562,
    tank_volume_start: 2331.300000000008,
    tank_volume_stop: 2279.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T22:30:25',
    timestamp_stop: '2022-01-01T22:30:31',
    quantity: 57.0,
    vehicle: 'DEM888',
    id: 140564,
    tank_volume_start: 2279.5000000000077,
    tank_volume_stop: 2221.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:30:46',
    timestamp_stop: '2022-01-01T22:30:52',
    quantity: 55.9,
    vehicle: 'DEM222',
    id: 140566,
    tank_volume_start: 2221.2000000000075,
    tank_volume_stop: 2162.8000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:31:07',
    timestamp_stop: '2022-01-01T22:31:12',
    quantity: 53.5,
    vehicle: 'DEM555',
    id: 140568,
    tank_volume_start: 2162.8000000000075,
    tank_volume_stop: 2108.5000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:31:27',
    timestamp_stop: '2022-01-01T22:31:33',
    quantity: 57.2,
    vehicle: 'DEM111',
    id: 140570,
    tank_volume_start: 2108.5000000000073,
    tank_volume_stop: 2060.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:31:48',
    timestamp_stop: '2022-01-01T22:31:53',
    quantity: 53.8,
    vehicle: 'DEM111',
    id: 140572,
    tank_volume_start: 2060.700000000007,
    tank_volume_stop: 2009.800000000007,
  },
  {
    timestamp_start: '2022-01-01T22:32:08',
    timestamp_stop: '2022-01-01T22:32:14',
    quantity: 55.5,
    vehicle: 'DEM444',
    id: 140574,
    tank_volume_start: 2009.800000000007,
    tank_volume_stop: 1951.100000000007,
  },
  {
    timestamp_start: '2022-01-01T22:32:29',
    timestamp_stop: '2022-01-01T22:32:34',
    quantity: 52.8,
    vehicle: 'DEM777',
    id: 140576,
    tank_volume_start: 3951.1000000000067,
    tank_volume_stop: 3891.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T22:32:49',
    timestamp_stop: '2022-01-01T22:32:55',
    quantity: 60.1,
    vehicle: 'DEM444',
    id: 140578,
    tank_volume_start: 3891.1000000000067,
    tank_volume_stop: 3827.500000000007,
  },
  {
    timestamp_start: '2022-01-01T22:33:10',
    timestamp_stop: '2022-01-01T22:33:15',
    quantity: 46.6,
    vehicle: 'DEM444',
    id: 140580,
    tank_volume_start: 3827.500000000007,
    tank_volume_stop: 3764.000000000007,
  },
  {
    timestamp_start: '2022-01-01T22:33:30',
    timestamp_stop: '2022-01-01T22:33:35',
    quantity: 54.3,
    vehicle: 'DEM888',
    id: 140582,
    tank_volume_start: 3764.000000000007,
    tank_volume_stop: 3704.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:33:50',
    timestamp_stop: '2022-01-01T22:33:55',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 140584,
    tank_volume_start: 3704.7000000000066,
    tank_volume_stop: 3657.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:34:10',
    timestamp_stop: '2022-01-01T22:34:16',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 140586,
    tank_volume_start: 3657.3000000000065,
    tank_volume_stop: 3608.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:34:31',
    timestamp_stop: '2022-01-01T22:34:36',
    quantity: 51.1,
    vehicle: 'DEM777',
    id: 140588,
    tank_volume_start: 3608.2000000000066,
    tank_volume_stop: 3558.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:34:51',
    timestamp_stop: '2022-01-01T22:34:56',
    quantity: 52.3,
    vehicle: 'DEM000',
    id: 140590,
    tank_volume_start: 3558.2000000000066,
    tank_volume_stop: 3502.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:35:11',
    timestamp_stop: '2022-01-01T22:35:17',
    quantity: 53.2,
    vehicle: 'DEM888',
    id: 140592,
    tank_volume_start: 3502.2000000000066,
    tank_volume_stop: 3450.500000000007,
  },
  {
    timestamp_start: '2022-01-01T22:35:32',
    timestamp_stop: '2022-01-01T22:35:36',
    quantity: 48.4,
    vehicle: 'DEM111',
    id: 140594,
    tank_volume_start: 3450.500000000007,
    tank_volume_stop: 3402.800000000007,
  },
  {
    timestamp_start: '2022-01-01T22:35:51',
    timestamp_stop: '2022-01-01T22:35:58',
    quantity: 63.4,
    vehicle: 'DEM777',
    id: 140596,
    tank_volume_start: 3402.800000000007,
    tank_volume_stop: 3355.100000000007,
  },
  {
    timestamp_start: '2022-01-01T22:36:13',
    timestamp_stop: '2022-01-01T22:36:18',
    quantity: 56.5,
    vehicle: 'DEM111',
    id: 140598,
    tank_volume_start: 3355.100000000007,
    tank_volume_stop: 3298.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:36:33',
    timestamp_stop: '2022-01-01T22:36:39',
    quantity: 59.1,
    vehicle: 'DEM444',
    id: 140600,
    tank_volume_start: 3298.700000000007,
    tank_volume_stop: 3242.200000000007,
  },
  {
    timestamp_start: '2022-01-01T22:36:54',
    timestamp_stop: '2022-01-01T22:37:00',
    quantity: 57.4,
    vehicle: 'DEM222',
    id: 140602,
    tank_volume_start: 3242.200000000007,
    tank_volume_stop: 3188.600000000007,
  },
  {
    timestamp_start: '2022-01-01T22:37:15',
    timestamp_stop: '2022-01-01T22:37:20',
    quantity: 50.0,
    vehicle: 'DEM444',
    id: 140604,
    tank_volume_start: 3188.600000000007,
    tank_volume_stop: 3138.300000000007,
  },
  {
    timestamp_start: '2022-01-01T22:37:35',
    timestamp_stop: '2022-01-01T22:37:41',
    quantity: 58.5,
    vehicle: 'DEM444',
    id: 140606,
    tank_volume_start: 3138.300000000007,
    tank_volume_stop: 3078.300000000007,
  },
  {
    timestamp_start: '2022-01-01T22:37:56',
    timestamp_stop: '2022-01-01T22:38:01',
    quantity: 53.7,
    vehicle: 'DEM000',
    id: 140608,
    tank_volume_start: 3078.300000000007,
    tank_volume_stop: 3019.400000000007,
  },
  {
    timestamp_start: '2022-01-01T22:38:16',
    timestamp_stop: '2022-01-01T22:38:22',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 140610,
    tank_volume_start: 3019.400000000007,
    tank_volume_stop: 2968.300000000007,
  },
  {
    timestamp_start: '2022-01-01T22:38:37',
    timestamp_stop: '2022-01-01T22:38:43',
    quantity: 61.0,
    vehicle: 'DEM666',
    id: 140612,
    tank_volume_start: 2968.300000000007,
    tank_volume_stop: 2915.200000000007,
  },
  {
    timestamp_start: '2022-01-01T22:38:58',
    timestamp_stop: '2022-01-01T22:39:04',
    quantity: 55.5,
    vehicle: 'DEM000',
    id: 140614,
    tank_volume_start: 2915.200000000007,
    tank_volume_stop: 2857.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:39:19',
    timestamp_stop: '2022-01-01T22:39:24',
    quantity: 54.4,
    vehicle: 'DEM444',
    id: 140616,
    tank_volume_start: 2857.700000000007,
    tank_volume_stop: 2804.900000000007,
  },
  {
    timestamp_start: '2022-01-01T22:39:39',
    timestamp_stop: '2022-01-01T22:39:45',
    quantity: 55.8,
    vehicle: 'DEM444',
    id: 140618,
    tank_volume_start: 2804.900000000007,
    tank_volume_stop: 2747.400000000007,
  },
  {
    timestamp_start: '2022-01-01T22:40:00',
    timestamp_stop: '2022-01-01T22:40:05',
    quantity: 48.5,
    vehicle: 'DEM777',
    id: 140620,
    tank_volume_start: 2747.400000000007,
    tank_volume_stop: 2697.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T22:40:20',
    timestamp_stop: '2022-01-01T22:40:25',
    quantity: 50.1,
    vehicle: 'DEM777',
    id: 140622,
    tank_volume_start: 2697.1000000000067,
    tank_volume_stop: 2639.500000000007,
  },
  {
    timestamp_start: '2022-01-01T22:40:40',
    timestamp_stop: '2022-01-01T22:40:45',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 140624,
    tank_volume_start: 2639.500000000007,
    tank_volume_stop: 2587.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T22:41:00',
    timestamp_stop: '2022-01-01T22:41:05',
    quantity: 51.6,
    vehicle: 'DEM222',
    id: 140626,
    tank_volume_start: 2587.1000000000067,
    tank_volume_stop: 2536.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:41:20',
    timestamp_stop: '2022-01-01T22:41:25',
    quantity: 55.2,
    vehicle: 'DEM222',
    id: 140628,
    tank_volume_start: 2536.3000000000065,
    tank_volume_stop: 2485.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:41:40',
    timestamp_stop: '2022-01-01T22:41:46',
    quantity: 57.4,
    vehicle: 'DEM666',
    id: 140630,
    tank_volume_start: 2485.9000000000065,
    tank_volume_stop: 2430.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:42:01',
    timestamp_stop: '2022-01-01T22:42:06',
    quantity: 50.9,
    vehicle: 'DEM777',
    id: 140632,
    tank_volume_start: 2430.1000000000063,
    tank_volume_stop: 2374.300000000006,
  },
  {
    timestamp_start: '2022-01-01T22:42:21',
    timestamp_stop: '2022-01-01T22:42:27',
    quantity: 54.8,
    vehicle: 'DEM111',
    id: 140634,
    tank_volume_start: 2374.300000000006,
    tank_volume_stop: 2318.200000000006,
  },
  {
    timestamp_start: '2022-01-01T22:42:42',
    timestamp_stop: '2022-01-01T22:42:48',
    quantity: 62.0,
    vehicle: 'DEM222',
    id: 140636,
    tank_volume_start: 2318.200000000006,
    tank_volume_stop: 2263.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:43:03',
    timestamp_stop: '2022-01-01T22:43:09',
    quantity: 57.4,
    vehicle: 'DEM555',
    id: 140638,
    tank_volume_start: 2263.800000000006,
    tank_volume_stop: 2210.700000000006,
  },
  {
    timestamp_start: '2022-01-01T22:43:24',
    timestamp_stop: '2022-01-01T22:43:29',
    quantity: 56.1,
    vehicle: 'DEM666',
    id: 140640,
    tank_volume_start: 2210.700000000006,
    tank_volume_stop: 2157.400000000006,
  },
  {
    timestamp_start: '2022-01-01T22:43:44',
    timestamp_stop: '2022-01-01T22:43:50',
    quantity: 57.5,
    vehicle: 'DEM888',
    id: 140642,
    tank_volume_start: 2157.400000000006,
    tank_volume_stop: 2099.500000000006,
  },
  {
    timestamp_start: '2022-01-01T22:44:05',
    timestamp_stop: '2022-01-01T22:44:11',
    quantity: 57.5,
    vehicle: 'DEM111',
    id: 140644,
    tank_volume_start: 2099.500000000006,
    tank_volume_stop: 2044.500000000006,
  },
  {
    timestamp_start: '2022-01-01T22:44:26',
    timestamp_stop: '2022-01-01T22:44:31',
    quantity: 55.4,
    vehicle: 'DEM000',
    id: 140646,
    tank_volume_start: 2044.500000000006,
    tank_volume_stop: 1991.500000000006,
  },
  {
    timestamp_start: '2022-01-01T22:44:46',
    timestamp_stop: '2022-01-01T22:44:52',
    quantity: 60.7,
    vehicle: 'DEM333',
    id: 140648,
    tank_volume_start: 3991.500000000006,
    tank_volume_stop: 3941.300000000006,
  },
  {
    timestamp_start: '2022-01-01T22:45:07',
    timestamp_stop: '2022-01-01T22:45:14',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 140650,
    tank_volume_start: 3941.300000000006,
    tank_volume_stop: 3880.900000000006,
  },
  {
    timestamp_start: '2022-01-01T22:45:29',
    timestamp_stop: '2022-01-01T22:45:34',
    quantity: 56.5,
    vehicle: 'DEM333',
    id: 140652,
    tank_volume_start: 3880.900000000006,
    tank_volume_stop: 3823.800000000006,
  },
  {
    timestamp_start: '2022-01-01T22:45:49',
    timestamp_stop: '2022-01-01T22:45:54',
    quantity: 51.5,
    vehicle: 'DEM666',
    id: 140654,
    tank_volume_start: 3823.800000000006,
    tank_volume_stop: 3765.6000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:46:09',
    timestamp_stop: '2022-01-01T22:46:15',
    quantity: 58.6,
    vehicle: 'DEM777',
    id: 140656,
    tank_volume_start: 3765.6000000000063,
    tank_volume_stop: 3710.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:46:30',
    timestamp_stop: '2022-01-01T22:46:36',
    quantity: 59.7,
    vehicle: 'DEM222',
    id: 140658,
    tank_volume_start: 3710.1000000000063,
    tank_volume_stop: 3656.300000000006,
  },
  {
    timestamp_start: '2022-01-01T22:46:51',
    timestamp_stop: '2022-01-01T22:46:57',
    quantity: 58.2,
    vehicle: 'DEM000',
    id: 140660,
    tank_volume_start: 3656.300000000006,
    tank_volume_stop: 3601.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:47:12',
    timestamp_stop: '2022-01-01T22:47:18',
    quantity: 60.2,
    vehicle: 'DEM888',
    id: 140662,
    tank_volume_start: 3601.1000000000063,
    tank_volume_stop: 3547.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:47:33',
    timestamp_stop: '2022-01-01T22:47:40',
    quantity: 64.9,
    vehicle: 'DEM777',
    id: 140664,
    tank_volume_start: 3547.4000000000065,
    tank_volume_stop: 3492.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:47:55',
    timestamp_stop: '2022-01-01T22:48:00',
    quantity: 54.5,
    vehicle: 'DEM111',
    id: 140666,
    tank_volume_start: 3492.1000000000063,
    tank_volume_stop: 3431.1000000000063,
  },
  {
    timestamp_start: '2022-01-01T22:48:15',
    timestamp_stop: '2022-01-01T22:48:20',
    quantity: 50.9,
    vehicle: 'DEM555',
    id: 140668,
    tank_volume_start: 3431.1000000000063,
    tank_volume_stop: 3373.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:48:35',
    timestamp_stop: '2022-01-01T22:48:40',
    quantity: 48.2,
    vehicle: 'DEM555',
    id: 140670,
    tank_volume_start: 3373.9000000000065,
    tank_volume_stop: 3319.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:48:55',
    timestamp_stop: '2022-01-01T22:49:00',
    quantity: 46.4,
    vehicle: 'DEM777',
    id: 140672,
    tank_volume_start: 3319.8000000000065,
    tank_volume_stop: 3261.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:49:15',
    timestamp_stop: '2022-01-01T22:49:20',
    quantity: 56.5,
    vehicle: 'DEM888',
    id: 140674,
    tank_volume_start: 3261.8000000000065,
    tank_volume_stop: 3210.9000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:49:35',
    timestamp_stop: '2022-01-01T22:49:40',
    quantity: 48.0,
    vehicle: 'DEM333',
    id: 140676,
    tank_volume_start: 3210.9000000000065,
    tank_volume_stop: 3159.4000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:49:55',
    timestamp_stop: '2022-01-01T22:50:00',
    quantity: 46.9,
    vehicle: 'DEM222',
    id: 140678,
    tank_volume_start: 3159.4000000000065,
    tank_volume_stop: 3105.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T22:50:15',
    timestamp_stop: '2022-01-01T22:50:21',
    quantity: 59.8,
    vehicle: 'DEM555',
    id: 140680,
    tank_volume_start: 3105.3000000000065,
    tank_volume_stop: 3056.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T22:50:36',
    timestamp_stop: '2022-01-01T22:50:41',
    quantity: 56.3,
    vehicle: 'DEM222',
    id: 140682,
    tank_volume_start: 3056.7000000000066,
    tank_volume_stop: 3003.000000000007,
  },
  {
    timestamp_start: '2022-01-01T22:50:56',
    timestamp_stop: '2022-01-01T22:51:02',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 140684,
    tank_volume_start: 3003.000000000007,
    tank_volume_stop: 2951.300000000007,
  },
  {
    timestamp_start: '2022-01-01T22:51:17',
    timestamp_stop: '2022-01-01T22:51:23',
    quantity: 59.4,
    vehicle: 'DEM555',
    id: 140686,
    tank_volume_start: 2951.300000000007,
    tank_volume_stop: 2889.400000000007,
  },
  {
    timestamp_start: '2022-01-01T22:51:38',
    timestamp_stop: '2022-01-01T22:51:43',
    quantity: 58.3,
    vehicle: 'DEM555',
    id: 140688,
    tank_volume_start: 2889.400000000007,
    tank_volume_stop: 2833.400000000007,
  },
  {
    timestamp_start: '2022-01-01T22:51:58',
    timestamp_stop: '2022-01-01T22:52:04',
    quantity: 59.5,
    vehicle: 'DEM444',
    id: 140690,
    tank_volume_start: 2833.400000000007,
    tank_volume_stop: 2783.400000000007,
  },
  {
    timestamp_start: '2022-01-01T22:52:19',
    timestamp_stop: '2022-01-01T22:52:25',
    quantity: 61.9,
    vehicle: 'DEM000',
    id: 140692,
    tank_volume_start: 2783.400000000007,
    tank_volume_stop: 2719.900000000007,
  },
  {
    timestamp_start: '2022-01-01T22:52:40',
    timestamp_stop: '2022-01-01T22:52:46',
    quantity: 58.4,
    vehicle: 'DEM111',
    id: 140694,
    tank_volume_start: 2719.900000000007,
    tank_volume_stop: 2656.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:53:01',
    timestamp_stop: '2022-01-01T22:53:08',
    quantity: 61.9,
    vehicle: 'DEM444',
    id: 140696,
    tank_volume_start: 2656.700000000007,
    tank_volume_stop: 2599.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:53:23',
    timestamp_stop: '2022-01-01T22:53:27',
    quantity: 48.4,
    vehicle: 'DEM666',
    id: 140698,
    tank_volume_start: 2599.0000000000073,
    tank_volume_stop: 2543.700000000007,
  },
  {
    timestamp_start: '2022-01-01T22:53:42',
    timestamp_stop: '2022-01-01T22:53:47',
    quantity: 50.0,
    vehicle: 'DEM000',
    id: 140700,
    tank_volume_start: 2543.700000000007,
    tank_volume_stop: 2489.5000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:54:02',
    timestamp_stop: '2022-01-01T22:54:08',
    quantity: 53.0,
    vehicle: 'DEM777',
    id: 140702,
    tank_volume_start: 2489.5000000000073,
    tank_volume_stop: 2440.200000000007,
  },
  {
    timestamp_start: '2022-01-01T22:54:23',
    timestamp_stop: '2022-01-01T22:54:29',
    quantity: 64.2,
    vehicle: 'DEM444',
    id: 140704,
    tank_volume_start: 2440.200000000007,
    tank_volume_stop: 2389.600000000007,
  },
  {
    timestamp_start: '2022-01-01T22:54:44',
    timestamp_stop: '2022-01-01T22:54:49',
    quantity: 53.3,
    vehicle: 'DEM000',
    id: 140706,
    tank_volume_start: 2389.600000000007,
    tank_volume_stop: 2333.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T22:55:04',
    timestamp_stop: '2022-01-01T22:55:09',
    quantity: 46.7,
    vehicle: 'DEM111',
    id: 140708,
    tank_volume_start: 2333.9000000000074,
    tank_volume_stop: 2272.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T22:55:24',
    timestamp_stop: '2022-01-01T22:55:29',
    quantity: 51.3,
    vehicle: 'DEM333',
    id: 140710,
    tank_volume_start: 2272.4000000000074,
    tank_volume_stop: 2217.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:55:44',
    timestamp_stop: '2022-01-01T22:55:50',
    quantity: 62.1,
    vehicle: 'DEM333',
    id: 140712,
    tank_volume_start: 2217.3000000000075,
    tank_volume_stop: 2162.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:56:05',
    timestamp_stop: '2022-01-01T22:56:11',
    quantity: 56.9,
    vehicle: 'DEM444',
    id: 140714,
    tank_volume_start: 2162.3000000000075,
    tank_volume_stop: 2104.7000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:56:26',
    timestamp_stop: '2022-01-01T22:56:32',
    quantity: 57.0,
    vehicle: 'DEM222',
    id: 140716,
    tank_volume_start: 2104.7000000000075,
    tank_volume_stop: 2047.5000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:56:47',
    timestamp_stop: '2022-01-01T22:56:52',
    quantity: 54.9,
    vehicle: 'DEM777',
    id: 140718,
    tank_volume_start: 2047.5000000000075,
    tank_volume_stop: 1990.0000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:57:07',
    timestamp_stop: '2022-01-01T22:57:13',
    quantity: 52.6,
    vehicle: 'DEM777',
    id: 140720,
    tank_volume_start: 3990.0000000000073,
    tank_volume_stop: 3926.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:57:28',
    timestamp_stop: '2022-01-01T22:57:33',
    quantity: 52.1,
    vehicle: 'DEM666',
    id: 140722,
    tank_volume_start: 3926.0000000000073,
    tank_volume_stop: 3869.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T22:57:48',
    timestamp_stop: '2022-01-01T22:57:53',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 140724,
    tank_volume_start: 3869.0000000000073,
    tank_volume_stop: 3820.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:58:08',
    timestamp_stop: '2022-01-01T22:58:14',
    quantity: 61.5,
    vehicle: 'DEM222',
    id: 140726,
    tank_volume_start: 3820.3000000000075,
    tank_volume_stop: 3765.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T22:58:29',
    timestamp_stop: '2022-01-01T22:58:35',
    quantity: 64.1,
    vehicle: 'DEM222',
    id: 140728,
    tank_volume_start: 3765.4000000000074,
    tank_volume_stop: 3712.7000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:58:50',
    timestamp_stop: '2022-01-01T22:58:56',
    quantity: 57.5,
    vehicle: 'DEM444',
    id: 140730,
    tank_volume_start: 3712.7000000000075,
    tank_volume_stop: 3663.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T22:59:11',
    timestamp_stop: '2022-01-01T22:59:17',
    quantity: 54.7,
    vehicle: 'DEM555',
    id: 140732,
    tank_volume_start: 3663.9000000000074,
    tank_volume_stop: 3609.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T22:59:32',
    timestamp_stop: '2022-01-01T22:59:37',
    quantity: 56.0,
    vehicle: 'DEM555',
    id: 140734,
    tank_volume_start: 3609.3000000000075,
    tank_volume_stop: 3556.1000000000076,
  },
  {
    timestamp_start: '2022-01-01T22:59:52',
    timestamp_stop: '2022-01-01T22:59:57',
    quantity: 50.0,
    vehicle: 'DEM777',
    id: 140736,
    tank_volume_start: 3556.1000000000076,
    tank_volume_stop: 3500.400000000008,
  },
  {
    timestamp_start: '2022-01-01T23:00:12',
    timestamp_stop: '2022-01-01T23:00:18',
    quantity: 56.9,
    vehicle: 'DEM000',
    id: 140738,
    tank_volume_start: 3500.400000000008,
    tank_volume_stop: 3443.700000000008,
  },
  {
    timestamp_start: '2022-01-01T23:00:33',
    timestamp_stop: '2022-01-01T23:00:39',
    quantity: 56.0,
    vehicle: 'DEM222',
    id: 140740,
    tank_volume_start: 3443.700000000008,
    tank_volume_stop: 3396.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:00:54',
    timestamp_stop: '2022-01-01T23:00:59',
    quantity: 57.7,
    vehicle: 'DEM333',
    id: 140742,
    tank_volume_start: 3396.200000000008,
    tank_volume_stop: 3345.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:01:14',
    timestamp_stop: '2022-01-01T23:01:20',
    quantity: 52.3,
    vehicle: 'DEM444',
    id: 140744,
    tank_volume_start: 3345.300000000008,
    tank_volume_stop: 3296.400000000008,
  },
  {
    timestamp_start: '2022-01-01T23:01:35',
    timestamp_stop: '2022-01-01T23:01:40',
    quantity: 51.8,
    vehicle: 'DEM888',
    id: 140746,
    tank_volume_start: 3296.400000000008,
    tank_volume_stop: 3239.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:01:55',
    timestamp_stop: '2022-01-01T23:02:01',
    quantity: 59.9,
    vehicle: 'DEM666',
    id: 140748,
    tank_volume_start: 3239.6000000000076,
    tank_volume_stop: 3176.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:02:16',
    timestamp_stop: '2022-01-01T23:02:21',
    quantity: 54.5,
    vehicle: 'DEM000',
    id: 140750,
    tank_volume_start: 3176.0000000000077,
    tank_volume_stop: 3121.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:02:36',
    timestamp_stop: '2022-01-01T23:02:42',
    quantity: 57.6,
    vehicle: 'DEM222',
    id: 140752,
    tank_volume_start: 3121.300000000008,
    tank_volume_stop: 3060.100000000008,
  },
  {
    timestamp_start: '2022-01-01T23:02:57',
    timestamp_stop: '2022-01-01T23:03:03',
    quantity: 60.7,
    vehicle: 'DEM000',
    id: 140754,
    tank_volume_start: 3060.100000000008,
    tank_volume_stop: 3012.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:03:18',
    timestamp_stop: '2022-01-01T23:03:23',
    quantity: 53.0,
    vehicle: 'DEM555',
    id: 140756,
    tank_volume_start: 3012.300000000008,
    tank_volume_stop: 2956.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:03:38',
    timestamp_stop: '2022-01-01T23:03:44',
    quantity: 61.0,
    vehicle: 'DEM444',
    id: 140758,
    tank_volume_start: 2956.300000000008,
    tank_volume_stop: 2904.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:03:59',
    timestamp_stop: '2022-01-01T23:04:05',
    quantity: 59.8,
    vehicle: 'DEM777',
    id: 140760,
    tank_volume_start: 2904.200000000008,
    tank_volume_stop: 2850.800000000008,
  },
  {
    timestamp_start: '2022-01-01T23:04:20',
    timestamp_stop: '2022-01-01T23:04:26',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 140762,
    tank_volume_start: 2850.800000000008,
    tank_volume_stop: 2799.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:04:41',
    timestamp_stop: '2022-01-01T23:04:46',
    quantity: 49.5,
    vehicle: 'DEM333',
    id: 140765,
    tank_volume_start: 2799.900000000008,
    tank_volume_stop: 2751.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:05:01',
    timestamp_stop: '2022-01-01T23:05:06',
    quantity: 53.3,
    vehicle: 'DEM555',
    id: 140766,
    tank_volume_start: 2751.6000000000076,
    tank_volume_stop: 2699.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:05:21',
    timestamp_stop: '2022-01-01T23:05:27',
    quantity: 58.2,
    vehicle: 'DEM111',
    id: 140768,
    tank_volume_start: 2699.0000000000077,
    tank_volume_stop: 2639.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:05:42',
    timestamp_stop: '2022-01-01T23:05:47',
    quantity: 57.5,
    vehicle: 'DEM222',
    id: 140770,
    tank_volume_start: 2639.900000000008,
    tank_volume_stop: 2592.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:06:02',
    timestamp_stop: '2022-01-01T23:06:08',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 140772,
    tank_volume_start: 2592.200000000008,
    tank_volume_stop: 2536.700000000008,
  },
  {
    timestamp_start: '2022-01-01T23:06:23',
    timestamp_stop: '2022-01-01T23:06:28',
    quantity: 52.2,
    vehicle: 'DEM111',
    id: 140774,
    tank_volume_start: 2536.700000000008,
    tank_volume_stop: 2481.000000000008,
  },
  {
    timestamp_start: '2022-01-01T23:06:43',
    timestamp_stop: '2022-01-01T23:06:48',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 140776,
    tank_volume_start: 2481.000000000008,
    tank_volume_stop: 2420.600000000008,
  },
  {
    timestamp_start: '2022-01-01T23:07:03',
    timestamp_stop: '2022-01-01T23:07:09',
    quantity: 58.9,
    vehicle: 'DEM666',
    id: 140778,
    tank_volume_start: 2420.600000000008,
    tank_volume_stop: 2360.100000000008,
  },
  {
    timestamp_start: '2022-01-01T23:07:24',
    timestamp_stop: '2022-01-01T23:07:29',
    quantity: 49.2,
    vehicle: 'DEM777',
    id: 140780,
    tank_volume_start: 2360.100000000008,
    tank_volume_stop: 2303.700000000008,
  },
  {
    timestamp_start: '2022-01-01T23:07:44',
    timestamp_stop: '2022-01-01T23:07:50',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 140782,
    tank_volume_start: 2303.700000000008,
    tank_volume_stop: 2248.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:08:05',
    timestamp_stop: '2022-01-01T23:08:10',
    quantity: 53.2,
    vehicle: 'DEM555',
    id: 140784,
    tank_volume_start: 2248.900000000008,
    tank_volume_stop: 2191.1000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:08:25',
    timestamp_stop: '2022-01-01T23:08:31',
    quantity: 53.0,
    vehicle: 'DEM888',
    id: 140786,
    tank_volume_start: 2191.1000000000076,
    tank_volume_stop: 2140.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:08:46',
    timestamp_stop: '2022-01-01T23:08:51',
    quantity: 56.7,
    vehicle: 'DEM000',
    id: 140788,
    tank_volume_start: 2140.900000000008,
    tank_volume_stop: 2094.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:09:06',
    timestamp_stop: '2022-01-01T23:09:12',
    quantity: 56.7,
    vehicle: 'DEM333',
    id: 140790,
    tank_volume_start: 2094.200000000008,
    tank_volume_stop: 2043.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:09:27',
    timestamp_stop: '2022-01-01T23:09:32',
    quantity: 52.3,
    vehicle: 'DEM777',
    id: 140792,
    tank_volume_start: 2043.900000000008,
    tank_volume_stop: 1986.8000000000081,
  },
  {
    timestamp_start: '2022-01-01T23:09:47',
    timestamp_stop: '2022-01-01T23:09:52',
    quantity: 52.5,
    vehicle: 'DEM666',
    id: 140794,
    tank_volume_start: 3986.8000000000084,
    tank_volume_stop: 3930.7000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:10:07',
    timestamp_stop: '2022-01-01T23:10:12',
    quantity: 49.1,
    vehicle: 'DEM555',
    id: 140796,
    tank_volume_start: 3930.7000000000085,
    tank_volume_stop: 3879.8000000000084,
  },
  {
    timestamp_start: '2022-01-01T23:10:27',
    timestamp_stop: '2022-01-01T23:10:32',
    quantity: 50.5,
    vehicle: 'DEM222',
    id: 140798,
    tank_volume_start: 3879.8000000000084,
    tank_volume_stop: 3823.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:10:47',
    timestamp_stop: '2022-01-01T23:10:53',
    quantity: 58.0,
    vehicle: 'DEM444',
    id: 140800,
    tank_volume_start: 3823.1000000000085,
    tank_volume_stop: 3770.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:11:08',
    timestamp_stop: '2022-01-01T23:11:14',
    quantity: 57.3,
    vehicle: 'DEM333',
    id: 140802,
    tank_volume_start: 3770.1000000000085,
    tank_volume_stop: 3717.6000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:11:29',
    timestamp_stop: '2022-01-01T23:11:35',
    quantity: 57.2,
    vehicle: 'DEM333',
    id: 140804,
    tank_volume_start: 3717.6000000000085,
    tank_volume_stop: 3660.5000000000086,
  },
  {
    timestamp_start: '2022-01-01T23:11:50',
    timestamp_stop: '2022-01-01T23:11:55',
    quantity: 59.4,
    vehicle: 'DEM777',
    id: 140806,
    tank_volume_start: 3660.5000000000086,
    tank_volume_stop: 3609.5000000000086,
  },
  {
    timestamp_start: '2022-01-01T23:12:10',
    timestamp_stop: '2022-01-01T23:12:17',
    quantity: 63.3,
    vehicle: 'DEM666',
    id: 140809,
    tank_volume_start: 3609.5000000000086,
    tank_volume_stop: 3557.2000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:12:32',
    timestamp_stop: '2022-01-01T23:12:38',
    quantity: 62.2,
    vehicle: 'DEM777',
    id: 140811,
    tank_volume_start: 3557.2000000000085,
    tank_volume_stop: 3496.3000000000084,
  },
  {
    timestamp_start: '2022-01-01T23:12:53',
    timestamp_stop: '2022-01-01T23:12:59',
    quantity: 60.6,
    vehicle: 'DEM555',
    id: 140813,
    tank_volume_start: 3496.3000000000084,
    tank_volume_stop: 3443.3000000000084,
  },
  {
    timestamp_start: '2022-01-01T23:13:14',
    timestamp_stop: '2022-01-01T23:13:19',
    quantity: 46.0,
    vehicle: 'DEM000',
    id: 140815,
    tank_volume_start: 3443.3000000000084,
    tank_volume_stop: 3395.4000000000083,
  },
  {
    timestamp_start: '2022-01-01T23:13:34',
    timestamp_stop: '2022-01-01T23:13:40',
    quantity: 58.4,
    vehicle: 'DEM111',
    id: 140817,
    tank_volume_start: 3395.4000000000083,
    tank_volume_stop: 3332.4000000000083,
  },
  {
    timestamp_start: '2022-01-01T23:13:55',
    timestamp_stop: '2022-01-01T23:13:59',
    quantity: 45.4,
    vehicle: 'DEM333',
    id: 140819,
    tank_volume_start: 3332.4000000000083,
    tank_volume_stop: 3281.3000000000084,
  },
  {
    timestamp_start: '2022-01-01T23:14:14',
    timestamp_stop: '2022-01-01T23:14:19',
    quantity: 53.1,
    vehicle: 'DEM000',
    id: 140820,
    tank_volume_start: 3281.3000000000084,
    tank_volume_stop: 3224.7000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:14:34',
    timestamp_stop: '2022-01-01T23:14:40',
    quantity: 55.3,
    vehicle: 'DEM222',
    id: 140822,
    tank_volume_start: 3224.7000000000085,
    tank_volume_stop: 3163.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:14:55',
    timestamp_stop: '2022-01-01T23:15:00',
    quantity: 48.2,
    vehicle: 'DEM111',
    id: 140824,
    tank_volume_start: 3163.1000000000085,
    tank_volume_stop: 3105.4000000000087,
  },
  {
    timestamp_start: '2022-01-01T23:15:15',
    timestamp_stop: '2022-01-01T23:15:21',
    quantity: 61.9,
    vehicle: 'DEM888',
    id: 140826,
    tank_volume_start: 3105.4000000000087,
    tank_volume_stop: 3050.6000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:15:36',
    timestamp_stop: '2022-01-01T23:15:41',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 140828,
    tank_volume_start: 3050.6000000000085,
    tank_volume_stop: 2990.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:15:56',
    timestamp_stop: '2022-01-01T23:16:02',
    quantity: 54.9,
    vehicle: 'DEM000',
    id: 140830,
    tank_volume_start: 2990.1000000000085,
    tank_volume_stop: 2936.5000000000086,
  },
  {
    timestamp_start: '2022-01-01T23:16:17',
    timestamp_stop: '2022-01-01T23:16:22',
    quantity: 52.9,
    vehicle: 'DEM777',
    id: 140832,
    tank_volume_start: 2936.5000000000086,
    tank_volume_stop: 2878.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:16:37',
    timestamp_stop: '2022-01-01T23:16:42',
    quantity: 55.0,
    vehicle: 'DEM111',
    id: 140834,
    tank_volume_start: 2878.1000000000085,
    tank_volume_stop: 2828.2000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:16:57',
    timestamp_stop: '2022-01-01T23:17:02',
    quantity: 48.2,
    vehicle: 'DEM333',
    id: 140836,
    tank_volume_start: 2828.2000000000085,
    tank_volume_stop: 2775.2000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:17:17',
    timestamp_stop: '2022-01-01T23:17:23',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 140838,
    tank_volume_start: 2775.2000000000085,
    tank_volume_stop: 2716.5000000000086,
  },
  {
    timestamp_start: '2022-01-01T23:17:38',
    timestamp_stop: '2022-01-01T23:17:43',
    quantity: 55.2,
    vehicle: 'DEM555',
    id: 140840,
    tank_volume_start: 2716.5000000000086,
    tank_volume_stop: 2662.5000000000086,
  },
  {
    timestamp_start: '2022-01-01T23:17:58',
    timestamp_stop: '2022-01-01T23:18:04',
    quantity: 61.4,
    vehicle: 'DEM333',
    id: 140842,
    tank_volume_start: 2662.5000000000086,
    tank_volume_stop: 2606.9000000000087,
  },
  {
    timestamp_start: '2022-01-01T23:18:19',
    timestamp_stop: '2022-01-01T23:18:24',
    quantity: 51.7,
    vehicle: 'DEM444',
    id: 140844,
    tank_volume_start: 2606.9000000000087,
    tank_volume_stop: 2555.1000000000085,
  },
  {
    timestamp_start: '2022-01-01T23:18:39',
    timestamp_stop: '2022-01-01T23:18:45',
    quantity: 54.5,
    vehicle: 'DEM888',
    id: 140846,
    tank_volume_start: 2555.1000000000085,
    tank_volume_stop: 2502.8000000000084,
  },
  {
    timestamp_start: '2022-01-01T23:19:00',
    timestamp_stop: '2022-01-01T23:19:06',
    quantity: 58.2,
    vehicle: 'DEM111',
    id: 140848,
    tank_volume_start: 2502.8000000000084,
    tank_volume_stop: 2445.000000000008,
  },
  {
    timestamp_start: '2022-01-01T23:19:21',
    timestamp_stop: '2022-01-01T23:19:25',
    quantity: 45.2,
    vehicle: 'DEM111',
    id: 140851,
    tank_volume_start: 2445.000000000008,
    tank_volume_stop: 2397.000000000008,
  },
  {
    timestamp_start: '2022-01-01T23:19:40',
    timestamp_stop: '2022-01-01T23:19:45',
    quantity: 47.2,
    vehicle: 'DEM333',
    id: 140852,
    tank_volume_start: 2397.000000000008,
    tank_volume_stop: 2341.4000000000083,
  },
  {
    timestamp_start: '2022-01-01T23:20:00',
    timestamp_stop: '2022-01-01T23:20:05',
    quantity: 47.0,
    vehicle: 'DEM666',
    id: 140854,
    tank_volume_start: 2341.4000000000083,
    tank_volume_stop: 2290.500000000008,
  },
  {
    timestamp_start: '2022-01-01T23:20:20',
    timestamp_stop: '2022-01-01T23:20:25',
    quantity: 55.5,
    vehicle: 'DEM333',
    id: 140856,
    tank_volume_start: 2290.500000000008,
    tank_volume_stop: 2239.100000000008,
  },
  {
    timestamp_start: '2022-01-01T23:20:40',
    timestamp_stop: '2022-01-01T23:20:46',
    quantity: 56.0,
    vehicle: 'DEM666',
    id: 140858,
    tank_volume_start: 2239.100000000008,
    tank_volume_stop: 2179.700000000008,
  },
  {
    timestamp_start: '2022-01-01T23:21:01',
    timestamp_stop: '2022-01-01T23:21:07',
    quantity: 58.2,
    vehicle: 'DEM222',
    id: 140860,
    tank_volume_start: 2179.700000000008,
    tank_volume_stop: 2124.100000000008,
  },
  {
    timestamp_start: '2022-01-01T23:21:22',
    timestamp_stop: '2022-01-01T23:21:27',
    quantity: 53.4,
    vehicle: 'DEM777',
    id: 140862,
    tank_volume_start: 2124.100000000008,
    tank_volume_stop: 2070.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:21:42',
    timestamp_stop: '2022-01-01T23:21:48',
    quantity: 58.9,
    vehicle: 'DEM222',
    id: 140864,
    tank_volume_start: 2070.200000000008,
    tank_volume_stop: 2012.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:22:03',
    timestamp_stop: '2022-01-01T23:22:08',
    quantity: 54.3,
    vehicle: 'DEM111',
    id: 140866,
    tank_volume_start: 2012.200000000008,
    tank_volume_stop: 1951.000000000008,
  },
  {
    timestamp_start: '2022-01-01T23:22:23',
    timestamp_stop: '2022-01-01T23:22:28',
    quantity: 51.1,
    vehicle: 'DEM000',
    id: 140868,
    tank_volume_start: 3951.000000000008,
    tank_volume_stop: 3903.500000000008,
  },
  {
    timestamp_start: '2022-01-01T23:22:43',
    timestamp_stop: '2022-01-01T23:22:49',
    quantity: 52.8,
    vehicle: 'DEM888',
    id: 140870,
    tank_volume_start: 3903.500000000008,
    tank_volume_stop: 3844.600000000008,
  },
  {
    timestamp_start: '2022-01-01T23:23:04',
    timestamp_stop: '2022-01-01T23:23:09',
    quantity: 50.2,
    vehicle: 'DEM000',
    id: 140872,
    tank_volume_start: 3844.600000000008,
    tank_volume_stop: 3788.700000000008,
  },
  {
    timestamp_start: '2022-01-01T23:23:24',
    timestamp_stop: '2022-01-01T23:23:29',
    quantity: 53.3,
    vehicle: 'DEM666',
    id: 140874,
    tank_volume_start: 3788.700000000008,
    tank_volume_stop: 3733.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:23:44',
    timestamp_stop: '2022-01-01T23:23:49',
    quantity: 52.5,
    vehicle: 'DEM555',
    id: 140876,
    tank_volume_start: 3733.200000000008,
    tank_volume_stop: 3683.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:24:04',
    timestamp_stop: '2022-01-01T23:24:09',
    quantity: 49.9,
    vehicle: 'DEM444',
    id: 140878,
    tank_volume_start: 3683.900000000008,
    tank_volume_stop: 3629.1000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:24:24',
    timestamp_stop: '2022-01-01T23:24:29',
    quantity: 51.1,
    vehicle: 'DEM888',
    id: 140880,
    tank_volume_start: 3629.1000000000076,
    tank_volume_stop: 3572.400000000008,
  },
  {
    timestamp_start: '2022-01-01T23:24:44',
    timestamp_stop: '2022-01-01T23:24:50',
    quantity: 51.0,
    vehicle: 'DEM333',
    id: 140882,
    tank_volume_start: 3572.400000000008,
    tank_volume_stop: 3514.400000000008,
  },
  {
    timestamp_start: '2022-01-01T23:25:05',
    timestamp_stop: '2022-01-01T23:25:10',
    quantity: 53.9,
    vehicle: 'DEM000',
    id: 140884,
    tank_volume_start: 3514.400000000008,
    tank_volume_stop: 3460.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:25:25',
    timestamp_stop: '2022-01-01T23:25:30',
    quantity: 46.7,
    vehicle: 'DEM444',
    id: 140886,
    tank_volume_start: 3460.6000000000076,
    tank_volume_stop: 3403.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:25:45',
    timestamp_stop: '2022-01-01T23:25:50',
    quantity: 53.4,
    vehicle: 'DEM555',
    id: 140888,
    tank_volume_start: 3403.6000000000076,
    tank_volume_stop: 3345.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:26:05',
    timestamp_stop: '2022-01-01T23:26:10',
    quantity: 46.6,
    vehicle: 'DEM222',
    id: 140890,
    tank_volume_start: 3345.5000000000077,
    tank_volume_stop: 3289.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:26:25',
    timestamp_stop: '2022-01-01T23:26:30',
    quantity: 57.2,
    vehicle: 'DEM666',
    id: 140892,
    tank_volume_start: 3289.2000000000075,
    tank_volume_stop: 3228.7000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:26:45',
    timestamp_stop: '2022-01-01T23:26:51',
    quantity: 62.1,
    vehicle: 'DEM111',
    id: 140894,
    tank_volume_start: 3228.7000000000075,
    tank_volume_stop: 3171.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:27:06',
    timestamp_stop: '2022-01-01T23:27:13',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 140896,
    tank_volume_start: 3171.2000000000075,
    tank_volume_stop: 3116.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:27:28',
    timestamp_stop: '2022-01-01T23:27:34',
    quantity: 60.9,
    vehicle: 'DEM444',
    id: 140898,
    tank_volume_start: 3116.9000000000074,
    tank_volume_stop: 3059.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:27:49',
    timestamp_stop: '2022-01-01T23:27:54',
    quantity: 52.0,
    vehicle: 'DEM666',
    id: 140900,
    tank_volume_start: 3059.4000000000074,
    tank_volume_stop: 3001.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:28:09',
    timestamp_stop: '2022-01-01T23:28:13',
    quantity: 46.5,
    vehicle: 'DEM666',
    id: 140902,
    tank_volume_start: 3001.2000000000075,
    tank_volume_stop: 2947.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:28:28',
    timestamp_stop: '2022-01-01T23:28:33',
    quantity: 48.7,
    vehicle: 'DEM111',
    id: 140904,
    tank_volume_start: 2947.3000000000075,
    tank_volume_stop: 2885.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:28:48',
    timestamp_stop: '2022-01-01T23:28:54',
    quantity: 57.2,
    vehicle: 'DEM777',
    id: 140906,
    tank_volume_start: 2885.0000000000073,
    tank_volume_stop: 2827.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:29:09',
    timestamp_stop: '2022-01-01T23:29:14',
    quantity: 50.1,
    vehicle: 'DEM111',
    id: 140908,
    tank_volume_start: 2827.3000000000075,
    tank_volume_stop: 2778.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:29:29',
    timestamp_stop: '2022-01-01T23:29:35',
    quantity: 61.6,
    vehicle: 'DEM000',
    id: 140910,
    tank_volume_start: 2778.6000000000076,
    tank_volume_stop: 2727.900000000008,
  },
  {
    timestamp_start: '2022-01-01T23:29:50',
    timestamp_stop: '2022-01-01T23:29:56',
    quantity: 57.8,
    vehicle: 'DEM333',
    id: 140912,
    tank_volume_start: 2727.900000000008,
    tank_volume_stop: 2672.800000000008,
  },
  {
    timestamp_start: '2022-01-01T23:30:11',
    timestamp_stop: '2022-01-01T23:30:17',
    quantity: 55.1,
    vehicle: 'DEM666',
    id: 140914,
    tank_volume_start: 2672.800000000008,
    tank_volume_stop: 2618.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:30:32',
    timestamp_stop: '2022-01-01T23:30:37',
    quantity: 57.1,
    vehicle: 'DEM777',
    id: 140916,
    tank_volume_start: 2618.0000000000077,
    tank_volume_stop: 2565.7000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:30:52',
    timestamp_stop: '2022-01-01T23:30:58',
    quantity: 54.2,
    vehicle: 'DEM333',
    id: 140918,
    tank_volume_start: 2565.7000000000075,
    tank_volume_stop: 2513.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:31:13',
    timestamp_stop: '2022-01-01T23:31:18',
    quantity: 52.5,
    vehicle: 'DEM111',
    id: 140920,
    tank_volume_start: 2513.0000000000077,
    tank_volume_stop: 2461.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:31:33',
    timestamp_stop: '2022-01-01T23:31:38',
    quantity: 53.0,
    vehicle: 'DEM444',
    id: 140922,
    tank_volume_start: 2461.2000000000075,
    tank_volume_stop: 2404.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:31:53',
    timestamp_stop: '2022-01-01T23:31:59',
    quantity: 58.3,
    vehicle: 'DEM888',
    id: 140924,
    tank_volume_start: 2404.5000000000077,
    tank_volume_stop: 2346.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:32:14',
    timestamp_stop: '2022-01-01T23:32:19',
    quantity: 47.2,
    vehicle: 'DEM444',
    id: 140926,
    tank_volume_start: 2346.300000000008,
    tank_volume_stop: 2296.600000000008,
  },
  {
    timestamp_start: '2022-01-01T23:32:34',
    timestamp_stop: '2022-01-01T23:32:39',
    quantity: 57.0,
    vehicle: 'DEM777',
    id: 140928,
    tank_volume_start: 2296.600000000008,
    tank_volume_stop: 2238.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:32:54',
    timestamp_stop: '2022-01-01T23:33:00',
    quantity: 57.1,
    vehicle: 'DEM000',
    id: 140930,
    tank_volume_start: 2238.300000000008,
    tank_volume_stop: 2181.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:33:15',
    timestamp_stop: '2022-01-01T23:33:20',
    quantity: 45.8,
    vehicle: 'DEM666',
    id: 140932,
    tank_volume_start: 2181.5000000000077,
    tank_volume_stop: 2118.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:33:35',
    timestamp_stop: '2022-01-01T23:33:40',
    quantity: 50.5,
    vehicle: 'DEM111',
    id: 140934,
    tank_volume_start: 2118.0000000000077,
    tank_volume_stop: 2061.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:33:55',
    timestamp_stop: '2022-01-01T23:34:00',
    quantity: 55.5,
    vehicle: 'DEM888',
    id: 140936,
    tank_volume_start: 2061.6000000000076,
    tank_volume_stop: 2008.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:34:15',
    timestamp_stop: '2022-01-01T23:34:22',
    quantity: 63.3,
    vehicle: 'DEM000',
    id: 140938,
    tank_volume_start: 2008.0000000000077,
    tank_volume_stop: 1949.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:34:37',
    timestamp_stop: '2022-01-01T23:34:43',
    quantity: 59.6,
    vehicle: 'DEM666',
    id: 140940,
    tank_volume_start: 3949.0000000000077,
    tank_volume_stop: 3895.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:34:58',
    timestamp_stop: '2022-01-01T23:35:03',
    quantity: 58.1,
    vehicle: 'DEM111',
    id: 140942,
    tank_volume_start: 3895.5000000000077,
    tank_volume_stop: 3839.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:35:18',
    timestamp_stop: '2022-01-01T23:35:24',
    quantity: 51.1,
    vehicle: 'DEM111',
    id: 140944,
    tank_volume_start: 3839.6000000000076,
    tank_volume_stop: 3785.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:35:39',
    timestamp_stop: '2022-01-01T23:35:44',
    quantity: 54.6,
    vehicle: 'DEM000',
    id: 140946,
    tank_volume_start: 3785.2000000000075,
    tank_volume_stop: 3727.1000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:35:59',
    timestamp_stop: '2022-01-01T23:36:04',
    quantity: 54.1,
    vehicle: 'DEM444',
    id: 140948,
    tank_volume_start: 3727.1000000000076,
    tank_volume_stop: 3680.1000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:36:19',
    timestamp_stop: '2022-01-01T23:36:25',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 140950,
    tank_volume_start: 3680.1000000000076,
    tank_volume_stop: 3625.8000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:36:40',
    timestamp_stop: '2022-01-01T23:36:45',
    quantity: 51.0,
    vehicle: 'DEM444',
    id: 140952,
    tank_volume_start: 3625.8000000000075,
    tank_volume_stop: 3569.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:37:00',
    timestamp_stop: '2022-01-01T23:37:05',
    quantity: 57.0,
    vehicle: 'DEM444',
    id: 140954,
    tank_volume_start: 3569.0000000000073,
    tank_volume_stop: 3517.100000000007,
  },
  {
    timestamp_start: '2022-01-01T23:37:20',
    timestamp_stop: '2022-01-01T23:37:27',
    quantity: 61.6,
    vehicle: 'DEM333',
    id: 140956,
    tank_volume_start: 3517.100000000007,
    tank_volume_stop: 3460.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:37:42',
    timestamp_stop: '2022-01-01T23:37:48',
    quantity: 61.0,
    vehicle: 'DEM777',
    id: 140958,
    tank_volume_start: 3460.0000000000073,
    tank_volume_stop: 3398.5000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:38:03',
    timestamp_stop: '2022-01-01T23:38:07',
    quantity: 45.2,
    vehicle: 'DEM111',
    id: 140960,
    tank_volume_start: 3398.5000000000073,
    tank_volume_stop: 3341.200000000007,
  },
  {
    timestamp_start: '2022-01-01T23:38:22',
    timestamp_stop: '2022-01-01T23:38:28',
    quantity: 60.3,
    vehicle: 'DEM666',
    id: 140962,
    tank_volume_start: 3341.200000000007,
    tank_volume_stop: 3288.700000000007,
  },
  {
    timestamp_start: '2022-01-01T23:38:43',
    timestamp_stop: '2022-01-01T23:38:49',
    quantity: 58.0,
    vehicle: 'DEM555',
    id: 140964,
    tank_volume_start: 3288.700000000007,
    tank_volume_stop: 3238.400000000007,
  },
  {
    timestamp_start: '2022-01-01T23:39:04',
    timestamp_stop: '2022-01-01T23:39:11',
    quantity: 64.3,
    vehicle: 'DEM444',
    id: 140966,
    tank_volume_start: 3238.400000000007,
    tank_volume_stop: 3181.200000000007,
  },
  {
    timestamp_start: '2022-01-01T23:39:26',
    timestamp_stop: '2022-01-01T23:39:31',
    quantity: 50.4,
    vehicle: 'DEM111',
    id: 140968,
    tank_volume_start: 3181.200000000007,
    tank_volume_stop: 3118.900000000007,
  },
  {
    timestamp_start: '2022-01-01T23:39:46',
    timestamp_stop: '2022-01-01T23:39:51',
    quantity: 59.1,
    vehicle: 'DEM222',
    id: 140970,
    tank_volume_start: 3118.900000000007,
    tank_volume_stop: 3061.800000000007,
  },
  {
    timestamp_start: '2022-01-01T23:40:06',
    timestamp_stop: '2022-01-01T23:40:13',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 140972,
    tank_volume_start: 3061.800000000007,
    tank_volume_stop: 3000.600000000007,
  },
  {
    timestamp_start: '2022-01-01T23:40:28',
    timestamp_stop: '2022-01-01T23:40:33',
    quantity: 51.2,
    vehicle: 'DEM444',
    id: 140974,
    tank_volume_start: 3000.600000000007,
    tank_volume_stop: 2945.700000000007,
  },
  {
    timestamp_start: '2022-01-01T23:40:48',
    timestamp_stop: '2022-01-01T23:40:53',
    quantity: 53.0,
    vehicle: 'DEM666',
    id: 140976,
    tank_volume_start: 2945.700000000007,
    tank_volume_stop: 2891.100000000007,
  },
  {
    timestamp_start: '2022-01-01T23:41:08',
    timestamp_stop: '2022-01-01T23:41:13',
    quantity: 54.2,
    vehicle: 'DEM666',
    id: 140978,
    tank_volume_start: 2891.100000000007,
    tank_volume_stop: 2836.200000000007,
  },
  {
    timestamp_start: '2022-01-01T23:41:28',
    timestamp_stop: '2022-01-01T23:41:34',
    quantity: 57.5,
    vehicle: 'DEM000',
    id: 140980,
    tank_volume_start: 2836.200000000007,
    tank_volume_stop: 2780.200000000007,
  },
  {
    timestamp_start: '2022-01-01T23:41:49',
    timestamp_stop: '2022-01-01T23:41:55',
    quantity: 58.5,
    vehicle: 'DEM111',
    id: 140982,
    tank_volume_start: 2780.200000000007,
    tank_volume_stop: 2727.600000000007,
  },
  {
    timestamp_start: '2022-01-01T23:42:10',
    timestamp_stop: '2022-01-01T23:42:16',
    quantity: 61.7,
    vehicle: 'DEM444',
    id: 140984,
    tank_volume_start: 2727.600000000007,
    tank_volume_stop: 2669.300000000007,
  },
  {
    timestamp_start: '2022-01-01T23:42:31',
    timestamp_stop: '2022-01-01T23:42:37',
    quantity: 58.5,
    vehicle: 'DEM555',
    id: 140986,
    tank_volume_start: 2669.300000000007,
    tank_volume_stop: 2609.600000000007,
  },
  {
    timestamp_start: '2022-01-01T23:42:52',
    timestamp_stop: '2022-01-01T23:42:57',
    quantity: 50.5,
    vehicle: 'DEM888',
    id: 140988,
    tank_volume_start: 2609.600000000007,
    tank_volume_stop: 2559.300000000007,
  },
  {
    timestamp_start: '2022-01-01T23:43:12',
    timestamp_stop: '2022-01-01T23:43:18',
    quantity: 59.4,
    vehicle: 'DEM666',
    id: 140990,
    tank_volume_start: 2559.300000000007,
    tank_volume_stop: 2508.700000000007,
  },
  {
    timestamp_start: '2022-01-01T23:43:33',
    timestamp_stop: '2022-01-01T23:43:39',
    quantity: 57.0,
    vehicle: 'DEM111',
    id: 140992,
    tank_volume_start: 2508.700000000007,
    tank_volume_stop: 2455.900000000007,
  },
  {
    timestamp_start: '2022-01-01T23:43:54',
    timestamp_stop: '2022-01-01T23:44:00',
    quantity: 61.4,
    vehicle: 'DEM222',
    id: 140994,
    tank_volume_start: 2455.900000000007,
    tank_volume_stop: 2401.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T23:44:15',
    timestamp_stop: '2022-01-01T23:44:20',
    quantity: 46.7,
    vehicle: 'DEM000',
    id: 140996,
    tank_volume_start: 2401.6000000000067,
    tank_volume_stop: 2344.8000000000065,
  },
  {
    timestamp_start: '2022-01-01T23:44:35',
    timestamp_stop: '2022-01-01T23:44:40',
    quantity: 50.4,
    vehicle: 'DEM888',
    id: 140998,
    tank_volume_start: 2344.8000000000065,
    tank_volume_stop: 2290.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T23:44:55',
    timestamp_stop: '2022-01-01T23:45:00',
    quantity: 49.0,
    vehicle: 'DEM111',
    id: 141000,
    tank_volume_start: 2290.3000000000065,
    tank_volume_stop: 2240.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T23:45:15',
    timestamp_stop: '2022-01-01T23:45:21',
    quantity: 63.3,
    vehicle: 'DEM000',
    id: 141002,
    tank_volume_start: 2240.7000000000066,
    tank_volume_stop: 2183.3000000000065,
  },
  {
    timestamp_start: '2022-01-01T23:45:36',
    timestamp_stop: '2022-01-01T23:45:41',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 141004,
    tank_volume_start: 2183.3000000000065,
    tank_volume_stop: 2129.7000000000066,
  },
  {
    timestamp_start: '2022-01-01T23:45:56',
    timestamp_stop: '2022-01-01T23:46:01',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 141006,
    tank_volume_start: 2129.7000000000066,
    tank_volume_stop: 2079.2000000000066,
  },
  {
    timestamp_start: '2022-01-01T23:46:16',
    timestamp_stop: '2022-01-01T23:46:22',
    quantity: 56.8,
    vehicle: 'DEM888',
    id: 141008,
    tank_volume_start: 2079.2000000000066,
    tank_volume_stop: 2031.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T23:46:37',
    timestamp_stop: '2022-01-01T23:46:42',
    quantity: 53.6,
    vehicle: 'DEM777',
    id: 141010,
    tank_volume_start: 2031.6000000000067,
    tank_volume_stop: 1982.1000000000067,
  },
  {
    timestamp_start: '2022-01-01T23:46:57',
    timestamp_stop: '2022-01-01T23:47:03',
    quantity: 52.4,
    vehicle: 'DEM555',
    id: 141012,
    tank_volume_start: 3982.1000000000067,
    tank_volume_stop: 3925.6000000000067,
  },
  {
    timestamp_start: '2022-01-01T23:47:18',
    timestamp_stop: '2022-01-01T23:47:23',
    quantity: 51.7,
    vehicle: 'DEM555',
    id: 141014,
    tank_volume_start: 3925.6000000000067,
    tank_volume_stop: 3866.900000000007,
  },
  {
    timestamp_start: '2022-01-01T23:47:38',
    timestamp_stop: '2022-01-01T23:47:43',
    quantity: 51.9,
    vehicle: 'DEM444',
    id: 141016,
    tank_volume_start: 3866.900000000007,
    tank_volume_stop: 3813.300000000007,
  },
  {
    timestamp_start: '2022-01-01T23:47:58',
    timestamp_stop: '2022-01-01T23:48:03',
    quantity: 51.9,
    vehicle: 'DEM555',
    id: 141018,
    tank_volume_start: 3813.300000000007,
    tank_volume_stop: 3756.100000000007,
  },
  {
    timestamp_start: '2022-01-01T23:48:18',
    timestamp_stop: '2022-01-01T23:48:23',
    quantity: 50.0,
    vehicle: 'DEM444',
    id: 141020,
    tank_volume_start: 3756.100000000007,
    tank_volume_stop: 3709.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:48:38',
    timestamp_stop: '2022-01-01T23:48:44',
    quantity: 53.6,
    vehicle: 'DEM333',
    id: 141022,
    tank_volume_start: 3709.4000000000074,
    tank_volume_stop: 3653.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:48:59',
    timestamp_stop: '2022-01-01T23:49:05',
    quantity: 62.4,
    vehicle: 'DEM666',
    id: 141024,
    tank_volume_start: 3653.4000000000074,
    tank_volume_stop: 3591.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:49:20',
    timestamp_stop: '2022-01-01T23:49:25',
    quantity: 52.6,
    vehicle: 'DEM888',
    id: 141026,
    tank_volume_start: 3591.9000000000074,
    tank_volume_stop: 3535.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:49:40',
    timestamp_stop: '2022-01-01T23:49:46',
    quantity: 58.6,
    vehicle: 'DEM666',
    id: 141028,
    tank_volume_start: 3535.9000000000074,
    tank_volume_stop: 3475.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:50:01',
    timestamp_stop: '2022-01-01T23:50:07',
    quantity: 58.5,
    vehicle: 'DEM333',
    id: 141030,
    tank_volume_start: 3475.3000000000075,
    tank_volume_stop: 3420.4000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:50:22',
    timestamp_stop: '2022-01-01T23:50:27',
    quantity: 48.4,
    vehicle: 'DEM888',
    id: 141032,
    tank_volume_start: 3420.4000000000074,
    tank_volume_stop: 3371.100000000007,
  },
  {
    timestamp_start: '2022-01-01T23:50:42',
    timestamp_stop: '2022-01-01T23:50:47',
    quantity: 49.3,
    vehicle: 'DEM333',
    id: 141034,
    tank_volume_start: 3371.100000000007,
    tank_volume_stop: 3319.800000000007,
  },
  {
    timestamp_start: '2022-01-01T23:51:02',
    timestamp_stop: '2022-01-01T23:51:07',
    quantity: 57.3,
    vehicle: 'DEM000',
    id: 141036,
    tank_volume_start: 3319.800000000007,
    tank_volume_stop: 3270.100000000007,
  },
  {
    timestamp_start: '2022-01-01T23:51:22',
    timestamp_stop: '2022-01-01T23:51:28',
    quantity: 58.0,
    vehicle: 'DEM777',
    id: 141038,
    tank_volume_start: 3270.100000000007,
    tank_volume_stop: 3217.200000000007,
  },
  {
    timestamp_start: '2022-01-01T23:51:43',
    timestamp_stop: '2022-01-01T23:51:48',
    quantity: 49.2,
    vehicle: 'DEM222',
    id: 141040,
    tank_volume_start: 3217.200000000007,
    tank_volume_stop: 3159.5000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:52:03',
    timestamp_stop: '2022-01-01T23:52:09',
    quantity: 61.6,
    vehicle: 'DEM777',
    id: 141042,
    tank_volume_start: 3159.5000000000073,
    tank_volume_stop: 3103.0000000000073,
  },
  {
    timestamp_start: '2022-01-01T23:52:24',
    timestamp_stop: '2022-01-01T23:52:30',
    quantity: 58.2,
    vehicle: 'DEM888',
    id: 141044,
    tank_volume_start: 3103.0000000000073,
    tank_volume_stop: 3043.8000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:52:45',
    timestamp_stop: '2022-01-01T23:52:50',
    quantity: 50.7,
    vehicle: 'DEM555',
    id: 141046,
    tank_volume_start: 3043.8000000000075,
    tank_volume_stop: 2986.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:53:05',
    timestamp_stop: '2022-01-01T23:53:10',
    quantity: 49.6,
    vehicle: 'DEM777',
    id: 141048,
    tank_volume_start: 2986.9000000000074,
    tank_volume_stop: 2930.8000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:53:25',
    timestamp_stop: '2022-01-01T23:53:31',
    quantity: 58.7,
    vehicle: 'DEM666',
    id: 141050,
    tank_volume_start: 2930.8000000000075,
    tank_volume_stop: 2867.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:53:46',
    timestamp_stop: '2022-01-01T23:53:51',
    quantity: 50.0,
    vehicle: 'DEM666',
    id: 141052,
    tank_volume_start: 2867.9000000000074,
    tank_volume_stop: 2811.9000000000074,
  },
  {
    timestamp_start: '2022-01-01T23:54:06',
    timestamp_stop: '2022-01-01T23:54:12',
    quantity: 60.9,
    vehicle: 'DEM000',
    id: 141054,
    tank_volume_start: 2811.9000000000074,
    tank_volume_stop: 2756.2000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:54:27',
    timestamp_stop: '2022-01-01T23:54:33',
    quantity: 63.3,
    vehicle: 'DEM555',
    id: 141056,
    tank_volume_start: 2756.2000000000075,
    tank_volume_stop: 2693.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:54:48',
    timestamp_stop: '2022-01-01T23:54:54',
    quantity: 54.2,
    vehicle: 'DEM777',
    id: 141058,
    tank_volume_start: 2693.5000000000077,
    tank_volume_stop: 2646.0000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:55:09',
    timestamp_stop: '2022-01-01T23:55:14',
    quantity: 56.9,
    vehicle: 'DEM555',
    id: 141060,
    tank_volume_start: 2646.0000000000077,
    tank_volume_stop: 2593.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:55:29',
    timestamp_stop: '2022-01-01T23:55:34',
    quantity: 48.7,
    vehicle: 'DEM333',
    id: 141062,
    tank_volume_start: 2593.300000000008,
    tank_volume_stop: 2545.600000000008,
  },
  {
    timestamp_start: '2022-01-01T23:55:49',
    timestamp_stop: '2022-01-01T23:55:54',
    quantity: 50.8,
    vehicle: 'DEM111',
    id: 141064,
    tank_volume_start: 2545.600000000008,
    tank_volume_stop: 2489.600000000008,
  },
  {
    timestamp_start: '2022-01-01T23:56:09',
    timestamp_stop: '2022-01-01T23:56:15',
    quantity: 58.4,
    vehicle: 'DEM555',
    id: 141066,
    tank_volume_start: 2489.600000000008,
    tank_volume_stop: 2434.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:56:30',
    timestamp_stop: '2022-01-01T23:56:36',
    quantity: 55.8,
    vehicle: 'DEM111',
    id: 141068,
    tank_volume_start: 2434.200000000008,
    tank_volume_stop: 2388.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:56:51',
    timestamp_stop: '2022-01-01T23:56:56',
    quantity: 51.4,
    vehicle: 'DEM777',
    id: 141070,
    tank_volume_start: 2388.300000000008,
    tank_volume_stop: 2332.200000000008,
  },
  {
    timestamp_start: '2022-01-01T23:57:11',
    timestamp_stop: '2022-01-01T23:57:16',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 141072,
    tank_volume_start: 2332.200000000008,
    tank_volume_stop: 2268.300000000008,
  },
  {
    timestamp_start: '2022-01-01T23:57:31',
    timestamp_stop: '2022-01-01T23:57:36',
    quantity: 45.2,
    vehicle: 'DEM111',
    id: 141074,
    tank_volume_start: 2268.300000000008,
    tank_volume_stop: 2213.5000000000077,
  },
  {
    timestamp_start: '2022-01-01T23:57:51',
    timestamp_stop: '2022-01-01T23:57:57',
    quantity: 60.1,
    vehicle: 'DEM333',
    id: 141076,
    tank_volume_start: 2213.5000000000077,
    tank_volume_stop: 2150.6000000000076,
  },
  {
    timestamp_start: '2022-01-01T23:58:12',
    timestamp_stop: '2022-01-01T23:58:18',
    quantity: 63.6,
    vehicle: 'DEM444',
    id: 141078,
    tank_volume_start: 2150.6000000000076,
    tank_volume_stop: 2088.3000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:58:33',
    timestamp_stop: '2022-01-01T23:58:39',
    quantity: 60.6,
    vehicle: 'DEM777',
    id: 141080,
    tank_volume_start: 2088.3000000000075,
    tank_volume_stop: 2031.0000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:58:54',
    timestamp_stop: '2022-01-01T23:58:59',
    quantity: 53.7,
    vehicle: 'DEM333',
    id: 141082,
    tank_volume_start: 2031.0000000000075,
    tank_volume_stop: 1981.5000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:59:14',
    timestamp_stop: '2022-01-01T23:59:20',
    quantity: 61.6,
    vehicle: 'DEM222',
    id: 141084,
    tank_volume_start: 3981.5000000000073,
    tank_volume_stop: 3930.8000000000075,
  },
  {
    timestamp_start: '2022-01-01T23:59:35',
    timestamp_stop: '2022-01-01T23:59:41',
    quantity: 50.7,
    vehicle: 'DEM444',
    id: 141086,
    tank_volume_start: 3930.8000000000075,
    tank_volume_stop: 3872.6000000000076,
  },
];
