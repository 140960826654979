import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowLeft,
  faArrowLeftLong,
  faCaretDown,
  faCarMirrors,
  faCheck,
  faCheckToSlot,
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faCircleInfo,
  faCircleQuestion,
  faEnvelope,
  faFileContract,
  faTimer,
  faX,
  faXmark,
  faCheckSquare,
  faHome,
  faShieldQuartered,
  faLockKeyhole,
} from '@fortawesome/pro-solid-svg-icons';
import {
 faCircleUser, faGasPump, faSquare,
} from '@fortawesome/pro-regular-svg-icons';
// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faLockKeyhole,
  faHome,
  faEnvelope,
  faCheckCircle,
  faShieldQuartered,
  faChevronLeft,
  faChevronRight,
  faGasPump,
  faCircleUser,
  faCircleInfo,
  faCirclePlus,
  faCheckToSlot,
  faXmark,
  faCaretDown,
  faTimer,
  faCheck,
  faFileContract,
  faCarMirrors,
  faCircleQuestion,
  faX,
  faArrowLeft,
  faArrowLeftLong,
  faSquare,
  faCheckSquare,
);

// Register the component globally
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    default: 'light',
    themes: {
      light: {
        primary: {
          lighten1: '#E3EDFE',
          base: '#0065BD',
          darken1: '#00458A',
        },
        secondary: {
          lighten1: '#64D2B2',
          base: '#50A88E',
        },
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#ffc107',
        complementary: '#B5ADCF',
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
  },
});
