<template>
  <v-card class="text-center pa-4 align-center" :height="$vuetify.breakpoint.smAndUp ? '250':'192'">
    <template v-if="usuario">
      <h4 v-if="!$vuetify.breakpoint.smAndUp" class="primary--text text--darken-1 mt-6 mb-2">{{mensaje}}</h4>
      <h3 v-else class="primary--text text--darken-1 mt-12 mb-2">{{mensaje}}</h3>
      <h3 v-if="!$vuetify.breakpoint.smAndUp" class="font-weight-bold primary--text text--darken-1 mb-6">{{ usuario }}</h3>
      <h2 v-else class="font-weight-bold primary--text text--darken-1 mb-6">{{ usuario }}</h2>
      <v-progress-circular size="44" indeterminate color="secondary"></v-progress-circular>
    </template>
    <template v-else>
      <h4 v-if="!$vuetify.breakpoint.smAndUp" class="font-weight-bold primary--text text--darken-1 mt-6 mb-8">{{mensaje}}</h4>
      <h3 v-else class="font-weight-bold primary--text text--darken-1 mt-14 mb-8">{{mensaje}}</h3>
      <v-progress-circular size="44" indeterminate color="secondary"></v-progress-circular>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    mensaje: {
      default: 'Espere...',
      type: String,
    },
    usuario: {
      default: null,
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>

</style>
