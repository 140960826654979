export default [
  {
     timestamp_start: '2022-01-01T03:07:18',
     timestamp_stop: '2022-01-01T03:07:33',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T05:11:39',
     timestamp_stop: '2022-01-01T05:11:54',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T07:16:29',
     timestamp_stop: '2022-01-01T07:16:44',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T09:20:47',
     timestamp_stop: '2022-01-01T09:21:02',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T11:25:37',
     timestamp_stop: '2022-01-01T11:25:52',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T13:31:00',
     timestamp_stop: '2022-01-01T13:31:15',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T15:35:34',
     timestamp_stop: '2022-01-01T15:35:49',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T17:39:39',
     timestamp_stop: '2022-01-01T17:39:54',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T19:43:40',
     timestamp_stop: '2022-01-01T19:43:55',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T21:47:50',
     timestamp_stop: '2022-01-01T21:48:05',
     quantity: 20000,
  },
  {
     timestamp_start: '2022-01-01T23:51:36',
     timestamp_stop: '2022-01-01T23:51:51',
     quantity: 20000,
  },
];
