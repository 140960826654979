export default [
  {
    timestamp_start: '2022-01-01T00:18:57',
    timestamp_stop: '2022-01-01T00:19:12',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T00:31:34',
    timestamp_stop: '2022-01-01T00:31:49',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T00:43:52',
    timestamp_stop: '2022-01-01T00:44:07',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T00:56:32',
    timestamp_stop: '2022-01-01T00:56:47',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T01:09:09',
    timestamp_stop: '2022-01-01T01:09:24',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T01:21:22',
    timestamp_stop: '2022-01-01T01:21:37',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T01:33:57',
    timestamp_stop: '2022-01-01T01:34:12',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T01:46:38',
    timestamp_stop: '2022-01-01T01:46:53',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T01:58:59',
    timestamp_stop: '2022-01-01T01:59:14',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T02:11:35',
    timestamp_stop: '2022-01-01T02:11:50',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T02:23:50',
    timestamp_stop: '2022-01-01T02:24:05',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T02:36:06',
    timestamp_stop: '2022-01-01T02:36:21',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T02:48:46',
    timestamp_stop: '2022-01-01T02:49:01',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T03:01:01',
    timestamp_stop: '2022-01-01T03:01:16',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T03:13:41',
    timestamp_stop: '2022-01-01T03:13:56',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T03:26:17',
    timestamp_stop: '2022-01-01T03:26:32',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T03:38:34',
    timestamp_stop: '2022-01-01T03:38:49',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T03:51:15',
    timestamp_stop: '2022-01-01T03:51:30',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T04:03:33',
    timestamp_stop: '2022-01-01T04:03:48',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T04:15:51',
    timestamp_stop: '2022-01-01T04:16:06',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T04:28:29',
    timestamp_stop: '2022-01-01T04:28:44',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T04:40:47',
    timestamp_stop: '2022-01-01T04:41:02',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T04:53:01',
    timestamp_stop: '2022-01-01T04:53:16',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T05:05:43',
    timestamp_stop: '2022-01-01T05:05:58',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T05:18:02',
    timestamp_stop: '2022-01-01T05:18:17',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T05:30:22',
    timestamp_stop: '2022-01-01T05:30:37',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T05:42:59',
    timestamp_stop: '2022-01-01T05:43:14',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T05:55:19',
    timestamp_stop: '2022-01-01T05:55:34',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T06:07:53',
    timestamp_stop: '2022-01-01T06:08:08',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T06:20:14',
    timestamp_stop: '2022-01-01T06:20:29',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T06:32:52',
    timestamp_stop: '2022-01-01T06:33:07',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T06:45:12',
    timestamp_stop: '2022-01-01T06:45:27',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T06:57:53',
    timestamp_stop: '2022-01-01T06:58:08',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T07:10:33',
    timestamp_stop: '2022-01-01T07:10:48',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T07:23:32',
    timestamp_stop: '2022-01-01T07:23:47',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T07:35:26',
    timestamp_stop: '2022-01-01T07:35:41',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T07:48:03',
    timestamp_stop: '2022-01-01T07:48:18',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T08:00:20',
    timestamp_stop: '2022-01-01T08:00:35',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T08:13:00',
    timestamp_stop: '2022-01-01T08:13:15',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T08:25:18',
    timestamp_stop: '2022-01-01T08:25:33',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T08:37:57',
    timestamp_stop: '2022-01-01T08:38:12',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T08:49:56',
    timestamp_stop: '2022-01-01T08:50:11',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T09:02:12',
    timestamp_stop: '2022-01-01T09:02:27',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T09:14:48',
    timestamp_stop: '2022-01-01T09:15:03',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T09:27:25',
    timestamp_stop: '2022-01-01T09:27:40',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T09:40:06',
    timestamp_stop: '2022-01-01T09:40:21',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T09:52:23',
    timestamp_stop: '2022-01-01T09:52:38',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T10:05:18',
    timestamp_stop: '2022-01-01T10:05:33',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T10:17:34',
    timestamp_stop: '2022-01-01T10:17:49',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T10:29:54',
    timestamp_stop: '2022-01-01T10:30:09',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T10:42:36',
    timestamp_stop: '2022-01-01T10:42:51',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T10:55:14',
    timestamp_stop: '2022-01-01T10:55:29',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T11:07:32',
    timestamp_stop: '2022-01-01T11:07:47',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T11:19:50',
    timestamp_stop: '2022-01-01T11:20:05',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T11:32:48',
    timestamp_stop: '2022-01-01T11:33:03',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T11:45:30',
    timestamp_stop: '2022-01-01T11:45:45',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T11:58:06',
    timestamp_stop: '2022-01-01T11:58:21',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T12:10:43',
    timestamp_stop: '2022-01-01T12:10:58',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T12:23:08',
    timestamp_stop: '2022-01-01T12:23:23',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T12:36:10',
    timestamp_stop: '2022-01-01T12:36:25',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T12:48:45',
    timestamp_stop: '2022-01-01T12:49:00',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T13:01:21',
    timestamp_stop: '2022-01-01T13:01:36',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T13:13:40',
    timestamp_stop: '2022-01-01T13:13:55',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T13:25:59',
    timestamp_stop: '2022-01-01T13:26:14',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T13:38:12',
    timestamp_stop: '2022-01-01T13:38:27',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T13:50:30',
    timestamp_stop: '2022-01-01T13:50:45',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T14:03:07',
    timestamp_stop: '2022-01-01T14:03:22',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T14:15:24',
    timestamp_stop: '2022-01-01T14:15:39',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T14:28:01',
    timestamp_stop: '2022-01-01T14:28:16',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T14:40:44',
    timestamp_stop: '2022-01-01T14:40:59',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T14:53:03',
    timestamp_stop: '2022-01-01T14:53:18',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T15:05:24',
    timestamp_stop: '2022-01-01T15:05:39',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T15:18:26',
    timestamp_stop: '2022-01-01T15:18:41',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T15:30:48',
    timestamp_stop: '2022-01-01T15:31:03',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T15:43:07',
    timestamp_stop: '2022-01-01T15:43:22',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T15:55:21',
    timestamp_stop: '2022-01-01T15:55:36',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T16:07:59',
    timestamp_stop: '2022-01-01T16:08:14',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T16:19:58',
    timestamp_stop: '2022-01-01T16:20:13',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T16:32:37',
    timestamp_stop: '2022-01-01T16:32:52',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T16:44:52',
    timestamp_stop: '2022-01-01T16:45:07',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T16:57:10',
    timestamp_stop: '2022-01-01T16:57:25',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T17:09:31',
    timestamp_stop: '2022-01-01T17:09:46',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T17:22:06',
    timestamp_stop: '2022-01-01T17:22:21',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T17:34:23',
    timestamp_stop: '2022-01-01T17:34:38',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T17:47:04',
    timestamp_stop: '2022-01-01T17:47:19',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T17:59:29',
    timestamp_stop: '2022-01-01T17:59:44',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T18:11:24',
    timestamp_stop: '2022-01-01T18:11:39',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T18:23:44',
    timestamp_stop: '2022-01-01T18:23:59',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T18:36:25',
    timestamp_stop: '2022-01-01T18:36:40',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T18:49:01',
    timestamp_stop: '2022-01-01T18:49:16',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T19:01:15',
    timestamp_stop: '2022-01-01T19:01:30',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T19:13:56',
    timestamp_stop: '2022-01-01T19:14:11',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T19:26:37',
    timestamp_stop: '2022-01-01T19:26:52',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T19:38:52',
    timestamp_stop: '2022-01-01T19:39:07',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T19:51:33',
    timestamp_stop: '2022-01-01T19:51:48',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T20:03:53',
    timestamp_stop: '2022-01-01T20:04:08',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T20:16:33',
    timestamp_stop: '2022-01-01T20:16:48',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T20:28:53',
    timestamp_stop: '2022-01-01T20:29:08',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T20:41:17',
    timestamp_stop: '2022-01-01T20:41:32',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T20:53:14',
    timestamp_stop: '2022-01-01T20:53:29',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T21:05:49',
    timestamp_stop: '2022-01-01T21:06:04',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T21:18:08',
    timestamp_stop: '2022-01-01T21:18:23',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T21:30:19',
    timestamp_stop: '2022-01-01T21:30:34',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T21:42:37',
    timestamp_stop: '2022-01-01T21:42:52',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T21:54:56',
    timestamp_stop: '2022-01-01T21:55:11',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T22:07:19',
    timestamp_stop: '2022-01-01T22:07:34',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T22:19:58',
    timestamp_stop: '2022-01-01T22:20:13',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T22:32:14',
    timestamp_stop: '2022-01-01T22:32:29',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T22:44:31',
    timestamp_stop: '2022-01-01T22:44:46',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T22:56:52',
    timestamp_stop: '2022-01-01T22:57:07',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T23:09:32',
    timestamp_stop: '2022-01-01T23:09:47',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T23:22:08',
    timestamp_stop: '2022-01-01T23:22:23',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T23:34:22',
    timestamp_stop: '2022-01-01T23:34:37',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T23:46:42',
    timestamp_stop: '2022-01-01T23:46:57',
    quantity: 2000,
  },
  {
    timestamp_start: '2022-01-01T23:58:59',
    timestamp_stop: '2022-01-01T23:59:14',
    quantity: 2000,
  },
];
